import { memo, useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

import TableHeadColumn from "ds/components/Table/Head/Column";
import { TableColumnMeta } from "ds/components/Table/types";
import Box from "ds/components/Box";
import TableHeadColumnTitle from "ds/components/Table/Head/Column/Title";
import TableHeadColumnOptionsDropdown from "ds/components/Table/Head/Column/Options";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import { useResizer } from "ds/components/Table/Head/Column/useResizer";
import ButtonIcon from "ds/components/ButtonIcon";
import { Gear } from "components/icons/generated";
import useColumnSort from "ds/components/Table/Head/Column/useColumnSort";
import { SortStateDescriptor } from "components/Filters/types";

import { BLUEPRINTS_COLUMN_ID, FILTERS_ORDER_SETTINGS_KEY, SORT_CONFIG } from "../../constants";
import { showCustomizeViewDrawer } from "../CustomizeViewDrawer";
import useBlueprintsTableStore from "../useBlueprintsTableStore";
import useBlueprintsAnalytics from "../../hooksNew/useBlueprintsAnalytics";

type BlueprintsTableHeadColumnProps = {
  sortCallback: (sortDescriptor: SortStateDescriptor) => void;
  column: TableColumnMeta<BLUEPRINTS_COLUMN_ID>;
};

const canMoveLeft = (columnId: BLUEPRINTS_COLUMN_ID, columnsOrder: string[]) => {
  return columnsOrder.indexOf(columnId) > 1; // skip name (first column)
};

const canMoveRight = (columnId: BLUEPRINTS_COLUMN_ID, columnsOrder: string[]) => {
  return columnsOrder.indexOf(columnId) < columnsOrder.length - 2; // skip options (last column)
};

const BlueprintsTableHeadColumn = ({ sortCallback, column }: BlueprintsTableHeadColumnProps) => {
  const [hideColumn, columnSizes, columnsOrder, setColumnSize, reorderVisibleColumns] =
    useBlueprintsTableStore(
      useShallow((state) => [
        state.hideColumn,
        state.columnsSizes,
        state.columnsOrder,
        state.setColumnSize,
        state.reorderVisibleColumns,
      ])
    );

  const trackAnalytics = useBlueprintsAnalytics();

  const onHide = () => {
    trackAnalytics("Column hidden", { column: column.id });
    hideColumn(column.id);
  };

  const onReorder = (direction: "left" | "right") => {
    trackAnalytics("Column reordered", {
      column: column.id,
      direction,
    });
    reorderVisibleColumns(column.id, direction);
  };

  const saveColumnSize = useCallback(
    (width: number) => {
      trackAnalytics("Column resized", {
        column: column.id,
      });
      setColumnSize(column.id, width);
    },
    [column.id, setColumnSize, trackAnalytics]
  );

  const { ariaSort, handleSort, icon } = useColumnSort(
    SORT_CONFIG[column.id] || column.id,
    FILTERS_ORDER_SETTINGS_KEY
  );

  const savedColumnWidth = columnSizes[column.id];

  const { ResizerControl, columnRef } = useResizer({
    width: savedColumnWidth,
    minWidth: column.minWidth,
    maxWidth: column.maxWidth,
    onResizeClb: saveColumnSize,
  });

  const onSort = useCallback(() => {
    handleSort(sortCallback);
  }, [handleSort, sortCallback]);
  const { resizable, canHide } = column;

  return (
    <TableHeadColumn
      ref={columnRef}
      column={column}
      stickyLeft={column.id === BLUEPRINTS_COLUMN_ID.NAME}
      stickyRight={column.id === BLUEPRINTS_COLUMN_ID.DROPDOWN}
      ariaSort={ariaSort}
    >
      {column.id === BLUEPRINTS_COLUMN_ID.DROPDOWN ? (
        <Box align="center" justify="start" fullHeight>
          <span className="sr-only">Blueprint actions</span>
          <ButtonIcon variant="ghost" onPress={showCustomizeViewDrawer} icon={Gear}>
            Customize table
          </ButtonIcon>
        </Box>
      ) : (
        <Box gap="small" align="center" justify="between" fullHeight>
          <TableHeadColumnTitle
            title={column.title}
            allowsSorting={column.allowsSorting}
            sortIcon={icon}
            onSort={onSort}
          />

          {(canHide || resizable) && (
            <Box align="center">
              {column.canHide && (
                <TableHeadColumnOptionsDropdown>
                  <DropdownMenuItem onAction={onHide}>Hide</DropdownMenuItem>
                  <DropdownMenuItem
                    onAction={() => onReorder("left")}
                    isDisabled={!canMoveLeft(column.id, columnsOrder.visible)}
                  >
                    Move left
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onAction={() => onReorder("right")}
                    isDisabled={!canMoveRight(column.id, columnsOrder.visible)}
                  >
                    Move right
                  </DropdownMenuItem>
                </TableHeadColumnOptionsDropdown>
              )}

              {resizable && ResizerControl}
            </Box>
          )}
        </Box>
      )}
    </TableHeadColumn>
  );
};

export default memo(BlueprintsTableHeadColumn);
