import BulkActionsConfirmActionStep from "components/BulkActions/ConfirmActionStep";
import { BulkActionsVariant } from "components/BulkActions/types";

import { RolesBulkActionsAnalyticsPayload, RolesBulkActionsSelectedAction } from "../types";
import RolesBulkActionsConfirmActionStepDefault from "./Default";
import { RolesBulkActionsConfirmActionStepProps } from "./types";
import { RolesMutationVariables } from "../useRolesBulkActions/types";

type RolesBulkActionsConfirmActionFormProps = {
  action: RolesBulkActionsSelectedAction;
  variant: BulkActionsVariant;
  onConfirm: (
    mutationVariables: RolesMutationVariables,
    analyticsPayload: RolesBulkActionsAnalyticsPayload
  ) => void;
  onCancel: () => void;
};

const RolesBulkActionsConfirmActionForm = ({
  action,
  variant,
  onConfirm,
  onCancel,
}: RolesBulkActionsConfirmActionFormProps) => {
  const props: RolesBulkActionsConfirmActionStepProps = {
    bulkActionsVariant: variant,
    onConfirm,
    onCancel,
    action,
  };

  const actionComponent = <RolesBulkActionsConfirmActionStepDefault {...props} />;

  return <BulkActionsConfirmActionStep>{actionComponent}</BulkActionsConfirmActionStep>;
};

export default RolesBulkActionsConfirmActionForm;
