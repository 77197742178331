import { dropdownEllipsisWidth } from "ds/components/DropdownMenu/constants";
import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";

export const initialSortOption = "name";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const ITEMS_LIMIT = 50;

export const FILTERS_ORDER_SETTINGS_KEY = "webhooksFiltersOrder";
export const COLUMN_ORDER = `minmax(400px, 4fr) 1fr 1fr 1fr ${dropdownEllipsisWidth}`;
export const COLUMN_GAP: Spacing = "large";
