import { useEffect } from "react";

import { Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import { AzureIntegration } from "types/generated";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import FormFieldViewText from "components/FormFields/ViewText";
import { TrackAnalyticsEventProperties } from "shared/Analytics";

type AzureIntegrationDetailsProps = {
  integration: AzureIntegration;
  trackAnalytics: (event: string, props?: TrackAnalyticsEventProperties) => void;
};

const AzureIntegrationDetailsDrawer = createDrawer(
  ({ integration, trackAnalytics }: AzureIntegrationDetailsProps) => {
    useEffect(() => {
      trackAnalytics("Open Integration Details", { type: "azure" });
    }, [trackAnalytics]);

    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Details" />
          <DrawerCloseIcon />
        </DrawerHeader>

        <DrawerBody>
          <FormFieldViewText label="Name" value={integration.name} />

          <FormFieldViewText label="Display name" value={integration.displayName} withCopy />

          <FormField label="Space">
            <MetaInfoListItem
              icon={Space}
              linkText={integration.spaceDetails.name}
              href={`/spaces/${integration.spaceDetails.id}`}
            />
          </FormField>

          <FormFieldViewText label="Tenant ID" value={integration.tenantId} withCopy />

          <FormFieldViewText label="Application ID" value={integration.applicationId} withCopy />

          {integration.defaultSubscriptionId && (
            <FormFieldViewText
              label="Default subscription ID"
              value={integration.defaultSubscriptionId}
              withCopy
            />
          )}

          {integration.labels.length > 0 && (
            <FormField label="Labels">
              <TagsList tags={integration.labels} alwaysExpanded />
            </FormField>
          )}
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showAzureIntegrationDetailsDrawer = createDrawerTrigger(AzureIntegrationDetailsDrawer);
