import { create, show, useModal } from "@ebay/nice-modal-react";
import { FormProvider, useForm } from "react-hook-form";

import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import useTypedFlags from "hooks/useTypedFlags";
import { Blueprint } from "types/generated";
import Box from "ds/components/Box";

import VersionFormFragment, { BlueprintVersionFormFields } from "./components/VersionFormFragment";
import getBlueprintVersionDefaults from "./components/VersionFormFragment/getBlueprintVersionDefaults";

type PublishConfirmationModalProps = {
  onPublish: (versionData: BlueprintVersionFormFields) => void;
  blueprint: Blueprint;
};

const PublishConfirmationModal = create(function PublishConfirmationModal({
  onPublish,
  blueprint,
}: PublishConfirmationModalProps) {
  const modal = useModal();
  const { blueprintsVersioning } = useTypedFlags();
  const builderForm = useForm<BlueprintVersionFormFields>({
    defaultValues: getBlueprintVersionDefaults(blueprint, blueprintsVersioning),
  });
  const { handleSubmit, getValues } = builderForm;

  const handleConfirmAction = () => {
    if (blueprintsVersioning) {
      onPublish(getValues());
    } else {
      onPublish({});
    }

    modal.hide();
  };

  return (
    <ConfirmationModal
      title="Confirm publish"
      onConfirm={handleConfirmAction}
      confirmationButtonVariant="primary"
      confirmationButtonLabel="Publish blueprint"
    >
      <ConfirmationModalMessage>
        Are you sure you want to publish your blueprint?{" "}
        <strong>This action cannot be undone.</strong>
      </ConfirmationModalMessage>
      {blueprintsVersioning && (
        <Box padding="large 0 0 0" direction="column">
          <FormProvider {...builderForm}>
            <form onSubmit={handleSubmit(handleConfirmAction)}>
              <VersionFormFragment />
            </form>
          </FormProvider>
        </Box>
      )}
    </ConfirmationModal>
  );
});

export const showPublishConfirmationModal = (props: PublishConfirmationModalProps) =>
  show(PublishConfirmationModal, props);
