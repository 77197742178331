import { Plus } from "components/icons/generated";

import Link, { type LinkProps } from "./index";

type LinkAddProps = Partial<Pick<LinkProps, "size" | "disabled">> & {
  onClick?: () => void;
  to?: string;
  text: Required<string>;
};

const LinkAdd = ({ text, size = "medium", onClick, to, disabled }: LinkAddProps) => {
  return (
    <Link startIcon={Plus} size={size} onPress={onClick} href={to} disabled={disabled}>
      {text}
    </Link>
  );
};

LinkAdd.displayName = "DS.Link.Add";

export default LinkAdd;
