import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import { Edit } from "components/icons/generated";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import Timestamp from "components/Timestamp";

import { COLUMN_ORDER_MOUNTED_FILES_LIST_ITEM } from "../../constants";
import { ContextContext } from "../../Context";
import styles from "./styles.module.css";
import { ContextMountedFilesListItemHeaderProps } from "./types";

const ContextMountedFilesListItemHeader = ({
  children,
  item,
  onOpenEditDrawer,
  isCollapsed,
}: ContextMountedFilesListItemHeaderProps) => {
  const { canManageContext } = useTypedContext(ContextContext);
  return (
    <CollapsiblePanelHeader
      padding="large"
      align="center"
      gap="large"
      grid
      gridTemplate={COLUMN_ORDER_MOUNTED_FILES_LIST_ITEM}
      role="row"
    >
      <Box role="rowheader" zeroMinWidth gap="medium" align="center">
        <CollapsiblePanelToggleTrigger ariaLevel={3} ariaLabel={`/mnt/workspace/${item?.id}`} />

        <Box direction="column" zeroMinWidth>
          <Box gap="medium" align="center">
            <CollapsiblePanelTitle className={styles.title}>
              <Box>
                <Typography tag="span" variant="p-body2" color="secondary">
                  /mnt/workspace/
                </Typography>
                <TextEllipsis tooltip={item?.id} tooltipWidthMode="maxWidthSm">
                  {(props) => (
                    <Typography tag="span" variant="p-t6" {...props}>
                      {!item.id ? "New file" : item.id}
                    </Typography>
                  )}
                </TextEllipsis>
              </Box>
            </CollapsiblePanelTitle>
            {!isCollapsed && canManageContext && (
              <ButtonIcon icon={Edit} onPress={onOpenEditDrawer} variant="ghost">
                Edit
              </ButtonIcon>
            )}
          </Box>

          {item.description && (
            <ListEntitiesItemDescription id={item.id} description={item.description} />
          )}
        </Box>
      </Box>
      <Typography role="cell" variant="p-body2" tag="p">
        {item.writeOnly ? "Secret" : "Plain"}
      </Typography>

      <Box role="cell" align="center">
        {item.createdAt && <Timestamp variant="p-body2" timestamp={item.createdAt} />}
      </Box>

      {children}
    </CollapsiblePanelHeader>
  );
};

export default ContextMountedFilesListItemHeader;
