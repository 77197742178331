import { mapDictionaryKey } from "components/Filters/helpers";
import {
  searchAnsibleHostsSuggestionsDictionary,
  AnsibleHostsSuggestions,
} from "constants/ansibleHosts";
import { AnsibleTaskStatus } from "types/generated";

import { TASK_SEARCH_KEY } from "./constants";
import { Components, ConfigNode } from "./types";
import { AnsibleHostData, AnsibleTaskData } from "./gql";
import ConfigManagementTreeGridLegendPlaybookIcon from "./PlaybookIcon";

const STATUS_HIERARCHY = [
  AnsibleTaskStatus.Skipped,
  AnsibleTaskStatus.Ok,
  AnsibleTaskStatus.Changed,
  AnsibleTaskStatus.Rescued,
  AnsibleTaskStatus.Ignored,
  AnsibleTaskStatus.Unreachable,
  AnsibleTaskStatus.Failed,
];

export const STATUS_ORDER = [
  AnsibleTaskStatus.Ok,
  AnsibleTaskStatus.Changed,
  AnsibleTaskStatus.Unreachable,
  AnsibleTaskStatus.Failed,
  AnsibleTaskStatus.Skipped,
  AnsibleTaskStatus.Rescued,
  AnsibleTaskStatus.Ignored,
];

const STATUS_DISCTIONARY = {
  [AnsibleTaskStatus.Ok]: "Ok",
  [AnsibleTaskStatus.Changed]: "Changed",
  [AnsibleTaskStatus.Unreachable]: "Unreachable",
  [AnsibleTaskStatus.Failed]: "Failed",
  [AnsibleTaskStatus.Skipped]: "Skipped",
  [AnsibleTaskStatus.Rescued]: "Rescued",
  [AnsibleTaskStatus.Ignored]: "Ignored",
};

const createStatuses = (group?: ConfigNode[]) =>
  Object.entries(
    group?.reduce(
      (prev, next) => {
        return next.status ? { ...prev, [next.status]: (prev[next.status] || 0) + 1 } : prev;
      },
      {} as Record<string, number>
    ) || {}
  )
    .sort(
      ([a], [b]) =>
        STATUS_ORDER.indexOf(a as AnsibleTaskStatus) - STATUS_ORDER.indexOf(b as AnsibleTaskStatus)
    )
    .map((value) => [STATUS_DISCTIONARY[value[0] as AnsibleTaskStatus], value[1]]) as [
    string,
    number,
  ][];

export const createAnsibleNodesByHost = (
  ansibleHosts: AnsibleHostData[],
  isChart: boolean,
  drawerLinkPath?: string
) =>
  ansibleHosts.reduce((acc, next) => {
    const host = {
      id: next.id,
      name: next.name,
      type: Components.Text,
    } as ConfigNode;
    const config = [...acc, host];
    const hostIndex = acc.length;

    const playbooks: Record<string, ConfigNode> = {};
    const roles: Record<string, ConfigNode> = {};
    const tasks: ConfigNode[] = [];

    next.taskExecutions.forEach((taskExecution) => {
      if (
        isChart &&
        config[hostIndex] &&
        (!config[hostIndex].status ||
          STATUS_HIERARCHY.indexOf(config[hostIndex].status) <
            STATUS_HIERARCHY.indexOf(taskExecution.status))
      ) {
        config[hostIndex].status = taskExecution.status;
        config[hostIndex].hasCumulativeStatus = true;
      }

      // create playbook group
      const playbookName = taskExecution.task.playbook.name;
      const playbookPath = taskExecution.task.playbook.path;
      const playbookId = `${next.id}-${playbookPath}`;

      if (!playbooks[playbookId]) {
        playbooks[playbookId] = {
          id: playbookId,
          name: playbookName,
          parent: next.id,
          status: taskExecution.status,
          hasCumulativeStatus: true,
          group: [],
          icon: ConfigManagementTreeGridLegendPlaybookIcon,
          type: isChart ? Components.Group : Components.Text,
        };
      } else if (
        playbooks[playbookId]?.status &&
        STATUS_HIERARCHY.indexOf(playbooks[playbookId].status) <
          STATUS_HIERARCHY.indexOf(taskExecution.status)
      ) {
        playbooks[playbookId].status = taskExecution.status;
      }

      // create role group
      const roleName = taskExecution.task.role?.name;
      const rolePath = taskExecution.task.role?.path;
      const roleId = rolePath ? `${playbookId}-${rolePath}` : undefined;
      if (roleId && roleName && !roles[roleId]) {
        roles[roleId] = {
          id: roleId,
          name: roleName,
          parent: playbookId,
          timestamp: taskExecution.timestamp,
          status: taskExecution.status,
          hasCumulativeStatus: true,
          shouldCountOrder: true,
        };
      } else if (
        roleId &&
        roles[roleId]?.status &&
        STATUS_HIERARCHY.indexOf(roles[roleId].status) <
          STATUS_HIERARCHY.indexOf(taskExecution.status)
      ) {
        roles[roleId].status = taskExecution.status;
      }

      // create task
      const taskId = `${roleId || playbookId}-${taskExecution.task.path}-${taskExecution.timestamp}`;
      const taskName = taskExecution.task.name;
      const task = {
        id: taskId,
        name: taskName,
        taskName,
        parent: roleId || playbookId,
        timestamp: taskExecution.timestamp,
        status: taskExecution.status,
        link: drawerLinkPath
          ? {
              path: drawerLinkPath,
              queryKey: TASK_SEARCH_KEY,
              queryValue: taskId,
            }
          : undefined,
        linkEventTitle: drawerLinkPath ? "Drawer opened" : undefined,
        hasMiddleGroup: !!roleId,
        tooltip: roleName ? (
          <>
            {taskName}
            <br /> Role: {roleName}
          </>
        ) : (
          taskName
        ),
        roleName,
        playbookName,
        hostName: host.name,
        limitMargin: true,
        checkMode: taskExecution.checkMode,
        logs: taskExecution.stdout,
        diff: taskExecution.diff,
        shouldCountOrder: true,
        runId: taskExecution.runId,
        stackId: taskExecution.stackId,
      };

      if (isChart && playbooks[playbookId].group) {
        playbooks[playbookId].group.push(task);
      }

      tasks.push(task);
    });

    Object.values(playbooks).forEach((value) => {
      const statuses = createStatuses(value.group);

      if (statuses.length) {
        config.push({ ...value, statuses });
      } else {
        config.push(value);
      }
    });

    [...Object.values(roles), ...tasks]
      .sort((a, b) => (b.timestamp && a.timestamp ? a.timestamp - b.timestamp : 0))
      .forEach((value) => config.push(value));

    return config;
  }, [] as ConfigNode[]);

export const createAnsibleNodesByTasks = (
  ansibleTasks: AnsibleTaskData[],
  isChart: boolean,
  drawerLinkPath?: string
) =>
  ansibleTasks.reduce((acc, next, i) => {
    const id = `${next.path}-${i}`;
    const task = {
      id,
      name: next.name,
      type: Components.Group,
      group: [],
    } as ConfigNode;

    const config = [...acc, task];

    const playbookPath = next.playbook.path;
    const roleName = next.role?.name;
    const rolePath = next.role?.path;

    next.taskExecutions.forEach((taskExecution) => {
      const playbookId = `${taskExecution.host.id}-${playbookPath}`;
      const roleId = rolePath ? `${playbookId}-${rolePath}` : undefined;

      // create host
      const hostId = `${roleId || playbookId}-${next.path}-${taskExecution.timestamp}`;
      const taskName = next.name;
      const playbookName = next.playbook.name;
      const hostName = taskExecution.host.name;
      const host = {
        id: hostId,
        name: hostName,
        taskName: taskName,
        parent: id,
        timestamp: taskExecution.timestamp,
        status: taskExecution.status,
        link: drawerLinkPath
          ? {
              path: drawerLinkPath,
              queryKey: TASK_SEARCH_KEY,
              queryValue: hostId,
            }
          : undefined,
        linkEventTitle: drawerLinkPath ? "Drawer opened" : undefined,
        roleName,
        description: roleName
          ? `Playbook: ${playbookName}, Role: ${roleName}`
          : `Playbook: ${playbookName}`,
        tooltip: roleName ? (
          <>
            {hostName}
            <br /> Playbook: {playbookName} <br /> Role: {roleName}
          </>
        ) : (
          <>
            {hostName}
            <br /> Playbook: {playbookName}
          </>
        ),
        playbookName,
        hostName,
        checkMode: taskExecution.checkMode,
        logs: taskExecution.stdout,
        runId: taskExecution.runId,
        stackId: taskExecution.stackId,
      };

      if (isChart && task.group) {
        task.group.push(host);
      }

      config.push(host);
    });

    return config.map((value) => {
      const statuses = createStatuses(value.group);

      if (statuses.length) {
        return { ...value, statuses };
      }

      return value;
    });
  }, [] as ConfigNode[]);

export const getFilterKey = (key: AnsibleHostsSuggestions | string) => {
  return mapDictionaryKey(key, searchAnsibleHostsSuggestionsDictionary);
};
