import { Column, ColumnMode } from "components/DragDropList/types";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";

export enum Widget {
  FavoriteStacks = "FavoriteStacks",
  StacksState = "StacksState",
  StacksSize = "StacksSize",
  StacksFailures = "StacksFailures",
  DriftDetectionCoverage = "DriftDetectionCoverage",
  RecentDriftDetectionRuns = "RecentDriftDetectionRuns",
  NextDriftDetectionSchedule = "NextDriftDetectionSchedule",
  RunsThatRequireAttention = "RunsThatRequireAttention",
  AverageRunDuration = "AverageRunDuration",
  MedianRunDuration = "MedianRunDuration",
  // ManagedResourcesOverTime = "ManagedResourcesOverTime",
  ManagedResources = "ManagedResources",
  ResourcesHealth = "ResourcesHealth",
  NumberOfDeployments = "NumberOfDeployments",
  ApprovalPolicyEvaluations = "ApprovalPolicyEvaluations",
}

export enum OverviewStaticWidget {
  LaunchPad = "LaunchPad",
  UserActivity = "UserActivity",
}

export type DashboardColumns = {
  left: Column<Widget>;
  right: Column<Widget>;
};

export type DashboardOverviewStaticColumn = Column<OverviewStaticWidget>;

export type DashboardConfig = Record<
  string,
  {
    overviewColumn?: DashboardOverviewStaticColumn;
    columnMode: ColumnMode;
    title: string;
  } & DashboardColumns
>;

export type DashboardWidgetConfig = {
  title: string;
  component: React.FC;
  moreActions?: {
    title: string;
    analyticsPage: AnalyticsPageDashboard;
    analyticsTitle: string;
    link: string;
  }[];
  infoTooltip?: string;
  hidden?: boolean;
};
