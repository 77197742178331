import useLocalStorage from "@rehooks/local-storage";
import { useMemo } from "react";

import ConfigManagementTreeGrid from "views/Account/ConfigManagement/TreeGrid";
import PageInfo from "components/PageWrapper/Info";
import useTypedContext from "hooks/useTypedContext";
import PageLoading from "components/loading/PageLoading";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import { LayoutMode } from "views/Account/ConfigManagement/types";
import SegmentedControl from "ds/components/SegmentedControl";
import useTitle from "hooks/useTitle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useErrorHandle from "hooks/useErrorHandle";
import ConfigManagementEmptyState from "views/Account/ConfigManagement/EmptyState";
import EmptyState from "ds/components/EmptyState";
import {
  ANSIBLE_GROUP_BY_OPTIONS,
  LAYOUT_MODE_OPTIONS,
} from "views/Account/ConfigManagement/constants";
import SearchInput from "components/SearchInput";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { AnsibleGroupBy } from "views/Account/ConfigManagement/types";
import Typography from "ds/components/Typography";
import Select from "ds/components/Select";
import useSearchAnsibleNodes from "views/Account/ConfigManagement/useSearchAnsibleNodes";
import ConfigManagementFiltersLayout from "views/Account/ConfigManagement/FiltersLayout";

import {
  DEFAULT_GROUP_BY_MODE,
  DEFAULT_LAYOUT_MODE,
  FILTERS_ORDER_SETTINGS_KEY,
  GROUP_BY_MODE_LOCAL_STORAGE_KEY,
  HIDDEN_FILTERS,
  LAYOUT_MODE_LOCAL_STORAGE_KEY,
} from "./constants";
import { getStacksBackUrl } from "../helpers";
import styles from "./styles.module.css";
import StackHeader from "../components/Header";
import { StackContext } from "../Context";

const StackConfigManagement = () => {
  const [groupBy, setGroupBy] = useLocalStorage<AnsibleGroupBy>(
    GROUP_BY_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_GROUP_BY_MODE
  );

  const [layoutMode, setLayoutMode] = useLocalStorage<LayoutMode>(
    LAYOUT_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_LAYOUT_MODE
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksConfigurationManagement,
  });

  const { stack } = useTypedContext(StackContext);

  useTitle(`Configuration Management · ${stack.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  const forcedStackPredicate = useMemo(
    () => ({
      field: "stack",
      exclude: null,
      constraint: {
        booleanEquals: null,
        enumEquals: null,
        stringMatches: [stack.id],
        hierarchyNodeValueEquals: null,
        timeInRange: null,
        timeInLast: null,
      },
    }),
    [stack.id]
  );

  const {
    isPageEmpty,
    predicates,
    nodes,
    hasNoFilteringResults,
    isPageLoading,
    error,
    loadMoreItems,
    hasNextPageToLoad,
  } = useSearchAnsibleNodes(groupBy, layoutMode, forcedStackPredicate);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  return (
    <>
      <StackHeader />
      <PageInfo title="Configuration Management">
        <SearchInput
          placeholder="Search by host or task name"
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        />
        <Select
          value={groupBy}
          options={ANSIBLE_GROUP_BY_OPTIONS}
          onChange={(value) => {
            trackSegmentAnalyticsEvent?.("Group by changed", { groupBy: value });
            setGroupBy(value);
          }}
          renderValueNode={(option) => (
            <Typography variant="p-t7" tag="span">
              Grouped by: {option.label}
            </Typography>
          )}
        />
        <SegmentedControl
          optionClassName={styles.layoutSwitchOption}
          onChange={(value) => {
            trackSegmentAnalyticsEvent?.("View Changed", { type: value });
            setLayoutMode(value);
          }}
          value={layoutMode}
          options={LAYOUT_MODE_OPTIONS}
        />
      </PageInfo>

      <ConfigManagementFiltersLayout
        key={groupBy}
        groupBy={groupBy}
        predicates={predicates}
        hiddenFilters={HIDDEN_FILTERS}
        filtersType="stackConfigurationManagement"
      >
        {isPageLoading && <PageLoading />}

        {hasNoFilteringResults && (
          <EmptyState
            title="No results"
            caption="Try different filters."
            icon={EmptystateSearchNoResultsColored}
            announce
          />
        )}

        {!!nodes.length && (
          <ConfigManagementTreeGrid
            hasNextPageToLoad={hasNextPageToLoad}
            loadMoreItems={loadMoreItems}
            nodes={nodes}
            chartClassName={styles.chart}
            layoutMode={layoutMode}
            analyticsPage={AnalyticsPageStack.StacksConfigurationManagement}
          />
        )}

        {isPageEmpty && <ConfigManagementEmptyState />}
      </ConfigManagementFiltersLayout>
    </>
  );
};

export default StackConfigManagement;
