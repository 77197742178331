import { memo } from "react";

import { Blueprint } from "types/generated";
import TableBodyRow from "ds/components/Table/Row";
import TableCell from "ds/components/Table/Row/Cell";
import useRowKeyboardNavigation from "ds/components/Table/useKeyboardNavigation/useRowKeyboardNavigation";

import useBlueprintsTableStore from "../useBlueprintsTableStore";
import { selectVisibleColumns } from "../useBlueprintsTableStore/selectors";
import BlueprintsRowLabelsCell from "./LabelsCell";
import BlueprintsRowNameCell from "./NameCell";
import BlueprintsRowDescriptionCell from "./DescriptionCell";
import BlueprintsRowSpaceCell from "./SpaceCell";
import BlueprintsRowStatusCell from "./StatusCell";
import { BLUEPRINTS_COLUMN_ID } from "../../constants";
import BlueprintsListTableDropdownCell from "./TableDropdownCell";
import BlueprintsRowUpdatedAtCell from "./UpdatedAtCell";
import BlueprintsRowActionsDropdownCell from "./DropdownActionCell";
import { BLUEPRINT_COLUMNS_WITH_ACTIONS, BlueprintColumnWithActions } from "./useCellActionsConfig";

const CELLS = {
  [BLUEPRINTS_COLUMN_ID.NAME]: BlueprintsRowNameCell,
  [BLUEPRINTS_COLUMN_ID.DESCRIPTION]: BlueprintsRowDescriptionCell,
  [BLUEPRINTS_COLUMN_ID.STATUS]: BlueprintsRowStatusCell,
  [BLUEPRINTS_COLUMN_ID.SPACE]: BlueprintsRowSpaceCell,
  [BLUEPRINTS_COLUMN_ID.LABELS]: BlueprintsRowLabelsCell,
  [BLUEPRINTS_COLUMN_ID.UPDATED_AT]: BlueprintsRowUpdatedAtCell,
  [BLUEPRINTS_COLUMN_ID.DROPDOWN]: BlueprintsRowActionsDropdownCell,
};

type BlueprintRowProps = {
  blueprint: Blueprint;
};

const BlueprintRow = (props: BlueprintRowProps) => {
  const { blueprint } = props;
  const columns = useBlueprintsTableStore((state) => selectVisibleColumns(state));
  const keyboardProps = useRowKeyboardNavigation({ isGroup: false });

  return (
    <TableBodyRow {...keyboardProps}>
      {columns.map(({ id }) => {
        const Component = CELLS[id as BLUEPRINTS_COLUMN_ID];
        const hasActions = BLUEPRINT_COLUMNS_WITH_ACTIONS.includes(
          id as BlueprintColumnWithActions
        );

        const uniqueKey = `${id}-${blueprint.id}`;

        const childrenComponent = <Component blueprint={blueprint} />;

        return hasActions ? (
          <BlueprintsListTableDropdownCell
            key={uniqueKey}
            id={id as BlueprintColumnWithActions}
            blueprint={blueprint}
          >
            {childrenComponent}
          </BlueprintsListTableDropdownCell>
        ) : (
          <TableCell
            key={uniqueKey}
            id={id}
            stickyLeft={id === BLUEPRINTS_COLUMN_ID.NAME}
            stickyRight={id === BLUEPRINTS_COLUMN_ID.DROPDOWN}
          >
            {childrenComponent}
          </TableCell>
        );
      })}
    </TableBodyRow>
  );
};

export default memo(BlueprintRow);
