import { useModal } from "@ebay/nice-modal-react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import { spaceAccessLevelOptions } from "constants/space";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";
import useTypedContext from "hooks/useTypedContext";
import { Space, SpaceAccessLevel } from "types/generated";
import { getDrawerFormFix } from "utils/css";

import { getDefaultValues } from "./helpers";
import { ChangeRoleFields } from "./types";
import useUserRoleChange from "./useUserRoleChange";

type SpacesRoleChangeDrawerProps = {
  space: Space;
};

const SpacesRoleChangeDrawer = createDrawer(({ space }: SpacesRoleChangeDrawerProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const trackAnalyticsSegmentEvent = useAnalytics({
    page: AnalyticsPagePersonal.PersonalSpaces,
  });
  const roleChangeForm = useForm<ChangeRoleFields>({
    defaultValues: getDefaultValues(space),
    mode: "onChange",
  });

  const drawer = useModal();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = roleChangeForm;

  const handleCloseDrawer = () => {
    trackAnalyticsSegmentEvent("Role Change Exit");
  };

  const { onUserRoleChange } = useUserRoleChange();

  const onSubmit: SubmitHandler<ChangeRoleFields> = (formData) => {
    onUserRoleChange(formData)
      .then(() => {
        trackAnalyticsSegmentEvent("Role Change Send Request Click", {
          role: formData.spaceAccessLevel,
        });
        reportSuccess({ message: "Role request has been sent" });
        drawer.hide();
      })
      .catch(onError);
  };

  const spaceAccessLevelOnChangeHandler =
    (callback: (value: SpaceAccessLevel) => void) => (value: SpaceAccessLevel) => {
      callback(value);
      trackAnalyticsSegmentEvent("Role Change Dropdown Select", {
        role: value,
      });
    };

  return (
    <DrawerForm isDirty={isDirty} onClose={handleCloseDrawer}>
      <FormProvider {...roleChangeForm}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Request role change" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <Controller
              name="spaceAccessLevel"
              control={control}
              render={({ field, fieldState }) => (
                <FormField error={fieldState.error?.message} noMargin label="Role">
                  {({ ariaInputProps }) => (
                    <Select
                      value={field.value}
                      options={spaceAccessLevelOptions}
                      onChange={spaceAccessLevelOnChangeHandler(field.onChange)}
                      error={!!fieldState.error?.message}
                      ariaInputProps={ariaInputProps}
                    />
                  )}
                </FormField>
              )}
            />

            <DrawerFooter>
              <DrawerFooterActions>
                <DrawerCancelButton />
                <Button variant="primary" type="submit">
                  Send request
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </form>
      </FormProvider>
    </DrawerForm>
  );
});

export const showSpacesRoleChangeDrawer = createDrawerTrigger(SpacesRoleChangeDrawer);
