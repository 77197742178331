import { gql } from "@apollo/client";

export const GET_AI_SETTINGS = gql`
  query GetAISettings {
    acceptedTermsAndConditionsAI
    hasAIEnabled
    llmVendor
    latestTermsAndConditionsVersionAI {
      content
      version
    }
  }
`;

export const TOGGLE_AI = gql`
  mutation ToggleAI {
    accountToggleEnablingAI
  }
`;

export const ACCEPT_AI_TERMS = gql`
  mutation AccountAcceptTermsAndConditionsVersionForAI($version: Int!) {
    accountAcceptTermsAndConditionsVersionForAI(version: $version)
  }
`;

export const SELECT_LLM_VENDOR = gql`
  mutation ChangeLlmVendor($vendor: LLMVendor!) {
    changeLLMVendor(vendor: $vendor)
  }
`;
