import { ReactNode } from "react";

import { InfoCircle } from "components/icons/generated";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ButtonIcon from "ds/components/ButtonIcon";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import Link from "ds/components/Link";
import Tooltip from "ds/components/Tooltip";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { SeatLimit } from "types/generated";
import SeatLimitsNotification from "views/Account/Settings/SeatLimitsNotification";
import { SEAT_LIMITS_TOOLTIP } from "views/Account/Settings/SeatLimitsNotification/constants";
import { SeatLimitsNotificationEntity } from "views/Account/Settings/SeatLimitsNotification/types";
import useSeatLimit from "views/Account/Settings/SeatLimitsNotification/useSeats";
import { AccountContext } from "views/AccountWrapper";

import { MANAGE_API_KEYS_INFO_KEY } from "../../constants";

type PersonalSettingsApiKeysPageLayoutProps = {
  children: ReactNode;
  onOpenCreateApiKeyDrawer?: () => void;
  onOpenInfoDrawer?: () => void;
  seats?: SeatLimit;
};

const PersonalSettingsApiKeysPageLayout = ({
  children,
  onOpenCreateApiKeyDrawer,
  onOpenInfoDrawer,
  seats,
}: PersonalSettingsApiKeysPageLayoutProps) => {
  const { accountName, viewer } = useTypedContext(AccountContext);

  const { isLimitReached } = useSeatLimit(seats);

  useTitle(`Personal Settings · API keys · ${accountName}`);

  const trackAnalyticsSegmentEvent = useAnalytics({
    page: AnalyticsPagePersonal.PersonalApiKeys,
  });

  return (
    <>
      {seats && (
        <SeatLimitsNotification
          entity={SeatLimitsNotificationEntity.ApiKey}
          seats={seats}
          type="callout"
        />
      )}

      {viewer.admin && (
        <Callout
          variant="info"
          title="Manage API keys in your organization"
          storageKey={MANAGE_API_KEYS_INFO_KEY}
          closeCallback={() => trackAnalyticsSegmentEvent("Organization Settings Callout Close")}
        >
          To manage all API keys created in your organization go to your Organization settings.
          <FeedbackActions>
            <Link
              analyticsPage={AnalyticsPagePersonal.PersonalApiKeys}
              analyticsTitle="Organization Settings Callout Click"
              href="/settings/api-keys"
              size="small"
            >
              Organization settings
            </Link>
          </FeedbackActions>
        </Callout>
      )}

      <ViewHeader firstLevel>
        <Box fullWidth justify="between">
          <Box direction="row" align="center" gap="medium">
            <ViewHeaderTitle tag="h2">API Keys</ViewHeaderTitle>
            {onOpenInfoDrawer && (
              <ButtonIcon onPress={onOpenInfoDrawer} icon={InfoCircle} variant="ghost">
                Open info drawer
              </ButtonIcon>
            )}
          </Box>
          {onOpenCreateApiKeyDrawer && (
            <ViewHeaderWrapper direction="row">
              <Tooltip
                active={isLimitReached}
                on={(props) => (
                  <Button
                    onPress={onOpenCreateApiKeyDrawer}
                    variant="primary"
                    disabled={isLimitReached}
                    {...props}
                  >
                    Create API key
                  </Button>
                )}
              >
                {SEAT_LIMITS_TOOLTIP}
              </Tooltip>
            </ViewHeaderWrapper>
          )}
        </Box>
      </ViewHeader>
      {children}
    </>
  );
};

export default PersonalSettingsApiKeysPageLayout;
