import { mergeRefs } from "@react-aria/utils";
import cx from "classnames";
import { useCallback, useMemo, useState } from "react";

import { ChevronDown } from "components/icons/generated";
import { LABEL_FOLDER_KEY } from "constants/labels";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Tag from "ds/components/Tag";
import Tooltip from "ds/components/Tooltip";
import useElementWidth from "hooks/useElementWidth";

import TagsListFilterableNewSingleTag from "./SingleTag";
import styles from "./styles.module.css";

const SPACE = 4;
const INITIAL_MULTIPLE_TAGS_DROPDOWN_WIDTH = 90;

type TagsListFilterableProps = {
  applyFolderFilter?: (value: string) => void;
  applyLabelFilter: (value: string) => void;
  tags: string[];
};

const TagsListFilterableNew = ({
  tags,
  applyFolderFilter,
  applyLabelFilter,
}: TagsListFilterableProps) => {
  const [tagsSizes, setTagSizes] = useState<Record<string, number>>({});

  const [containerRef, containerSize] = useElementWidth<HTMLDivElement>();
  const [mutlipleTagsDropdownRef, mutlipleTagsDropdownSize] = useElementWidth<HTMLButtonElement>(
    INITIAL_MULTIPLE_TAGS_DROPDOWN_WIDTH
  );

  const hiddenTags = useMemo(() => {
    if (!tags.length) {
      return [];
    }

    const hiddenTags: string[] = [];

    let summOfSizes = mutlipleTagsDropdownSize;

    for (let i = 0; i < tags.length; i++) {
      const size = tagsSizes[tags[i]] + SPACE;
      const isLast = i === tags.length - 1;

      summOfSizes += size;

      if (isLast) {
        summOfSizes -= mutlipleTagsDropdownSize;
      }

      if (containerSize && summOfSizes > containerSize) {
        hiddenTags.push(...tags.slice(i));
        return hiddenTags;
      }
    }

    return hiddenTags;
  }, [containerSize, tags, tagsSizes, mutlipleTagsDropdownSize]);

  const tagOnClickHandler = useCallback(
    (tag: string, callback?: () => void) => {
      const isFolder = tag.startsWith(LABEL_FOLDER_KEY);

      if (isFolder && applyFolderFilter) {
        applyFolderFilter(tag.replace(LABEL_FOLDER_KEY, ""));
      } else {
        applyLabelFilter(tag);
      }

      callback?.();
    },
    [applyFolderFilter, applyLabelFilter]
  );

  if (tags.length === 0) {
    return null;
  }

  if (tags.length === 1) {
    return (
      <Tooltip
        on={({ ref, ...props }) => (
          <Tag
            {...props}
            ref={ref}
            className={styles.tag}
            tag={tags[0]}
            onClick={() => tagOnClickHandler(tags[0])}
          />
        )}
      >
        Add to filters
      </Tooltip>
    );
  }

  const moreTagsTooltip = `${hiddenTags.length === tags.length ? `${hiddenTags.length} labels` : `+${hiddenTags.length}`}`;

  return (
    <Box
      ref={containerRef}
      className={styles.container}
      fullWidth
      gap="small"
      justify="start"
      align="center"
    >
      {tags.map((tag) => (
        <TagsListFilterableNewSingleTag
          setTagSizes={setTagSizes}
          currentSize={tagsSizes[tag]}
          tagOnClickHandler={tagOnClickHandler}
          shouldHide={hiddenTags.includes(tag)}
          key={tag}
          tag={tag}
          containerSize={containerSize}
        />
      ))}
      {!!hiddenTags.length && (
        <DropdownMenu
          className={styles.dropdownSection}
          triggerComponent={
            <Tooltip
              on={({ ref, ...tooltipProps }) => (
                <Button
                  {...tooltipProps}
                  ref={mergeRefs<HTMLButtonElement>(ref, mutlipleTagsDropdownRef)}
                  aria-label="Show more tags"
                  className={cx(
                    styles.button,
                    !!containerSize && styles.active,
                    tooltipProps.className
                  )}
                  variant="secondary"
                  size="small"
                  endIcon={ChevronDown}
                  withTextEllipsis
                >
                  {moreTagsTooltip}
                </Button>
              )}
            >
              Show more labels
            </Tooltip>
          }
        >
          {hiddenTags.map((tag) => {
            return (
              <DropdownMenuItem
                tooltip="Add to filters"
                onAction={() => tagOnClickHandler(tag, close)}
                tooltipPlacement="right"
                key={tag}
              >
                <Tag className={styles.tag} tag={tag} />
              </DropdownMenuItem>
            );
          })}
        </DropdownMenu>
      )}
    </Box>
  );
};

export default TagsListFilterableNew;
