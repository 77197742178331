import cx from "classnames";
import { forwardRef, ReactNode, useCallback } from "react";
import { Link as AriaLink, LinkProps as AriaLinkProps, PressEvent } from "react-aria-components";

import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";
import { IconComponent } from "types/Icon";

import Icon from "../Icon";
import TextEllipsis from "../TextEllipsis";
import Tooltip from "../Tooltip";
import styles from "./styles.module.css";

export type LinkProps = {
  variant?: "primary" | "secondary" | "danger" | "inversed";
  disabled?: boolean;
  size?: "small" | "medium" | "inherit";
  children: ReactNode;
  className?: string;
  endIcon?: IconComponent;
  startIcon?: IconComponent;
  tooltip?: ReactNode;
  inheritFont?: boolean;
  noEllipsis?: boolean;
} & AnalyticsCommonProps &
  AriaLinkProps;

const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  {
    className,
    variant = "primary",
    size = "medium",
    disabled,
    startIcon,
    endIcon,
    children,
    href,
    tooltip,
    onPress,
    analyticsPage,
    analyticsTitle,
    analyticsProps,
    inheritFont = false,
    noEllipsis = false,
    ...rest
  }: LinkProps,
  externalRef
) {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const handleLinkPress = useCallback(
    (e: PressEvent) => {
      if (!disabled && analyticsTitle) {
        trackSegmentAnalyticsEvent(analyticsTitle, analyticsProps);
      }
      onPress?.(e);
    },
    [analyticsProps, analyticsTitle, disabled, onPress, trackSegmentAnalyticsEvent]
  );

  const content = noEllipsis ? (
    <span>{children}</span>
  ) : (
    <TextEllipsis tooltipPlacement="bottom" tooltip={children}>
      {(props) => <span {...props}>{children}</span>}
    </TextEllipsis>
  );

  return (
    <Tooltip
      active={!!tooltip}
      on={(tooltipProps) => (
        <AriaLink
          {...tooltipProps}
          {...rest}
          ref={externalRef}
          onPress={handleLinkPress}
          href={href}
          isDisabled={disabled}
          className={cx(
            styles.link,
            styles[variant],
            styles[size],
            inheritFont && styles.inherit,
            className,
            tooltipProps.className
          )}
        >
          {startIcon && <Icon src={startIcon} className={styles.icon} />}
          {content}
          {endIcon && <Icon src={endIcon} className={styles.icon} />}
        </AriaLink>
      )}
    >
      {tooltip}
    </Tooltip>
  );
});

Link.displayName = "DS.Link";

export default Link;
