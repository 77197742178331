import { addHours } from "date-fns/esm";
import get from "lodash-es/get";

import {
  AccountRunsData,
  FilterKey,
  ModuleRunEntity,
  RunEntity,
  RunsFilters,
  RunsFilterValues,
  StackRunEntity,
} from "./types";
import { DateRange } from "./Wrapper/types";

const GROUP_BY_OPTIONS = [
  { value: "trigger", label: "Trigger" },
  { value: "type", label: "Type" },
  { value: "stack.id", label: "Stack" },
  { value: "module.id", label: "Module" },
  { value: "state", label: "State" },
  { value: "branch", label: "Branch" },
  { value: "commit.repository", label: "Repository" },
];

const FILTER_LABELS = {
  commit: "Commit SHA",
  "commit.repository": "Commit Repository",
  driftDetection: "Drift Detection",
  labels: "Stack labels",
  type: "Type",
  "stack.id": "Stack",
  "module.id": "Module",
  state: "State",
  branch: "Branch",
};

const FILTERS_KEYS = {
  account: [
    "branch",
    "commit",
    "commit.repository",
    "driftDetection",
    "labels",
    "type",
    "module.id",
    "stack.id",
    "state",
  ],
};

const omitTypename = (key: string, value: string) => {
  return key === "__typename" ? undefined : value;
};

export const generateAccountEntities = (data: Partial<AccountRunsData>) => {
  const entities: Array<RunEntity> = [];
  if (data.stacks) {
    for (const stack of data.stacks) {
      if (!stack.runsInRange) return entities;
      stack.runsInRange.forEach((entity) => {
        const entityCopy: StackRunEntity = JSON.parse(JSON.stringify(entity, omitTypename));
        entityCopy.stack = stack;
        entityCopy.labels = stack.labels;
        entityCopy.commit.repository =
          stack.namespace === "" ? stack.repository : `${stack.namespace}/${stack.repository}`;

        entities.push(entityCopy);
      });
    }
  }

  if (data.modules) {
    for (const module of data.modules) {
      if (!module.runsInRange) return entities;
      module.runsInRange.forEach((entity) => {
        const entityCopy: ModuleRunEntity = JSON.parse(JSON.stringify(entity, omitTypename));
        entityCopy.labels = module.labels;
        entityCopy.module = module;
        entityCopy.commit.repository =
          module.namespace === "" ? module.repository : `${module.namespace}/${module.repository}`;
        entityCopy.isModule = true;

        entities.push(entityCopy);
      });
    }
  }

  return entities;
};

export const generateFilters = (
  filterField: Array<FilterKey>,
  filterValues: RunsFilterValues
): RunsFilters => {
  // filters is a list of pairs (key, possible values)
  // the key may repeat
  const filters: RunsFilters = [];
  for (const i in filterField) {
    if (filterValues[i] && filterValues[i].length > 0) {
      filters[i] = [filterField[i], new Set(filterValues[i].map((option) => option.value))];
    } else {
      filters[i] = [filterField[i], new Set()];
    }
  }

  return filters;
};

// getKeyValue finds the value of a property, which can be nested using dots as separators.
// Returns none if it can't find it.
export const getKeyValue = (key: string, object: object) => {
  return get(object, key === "commit" ? "commit.hash" : key, "none");
};

function escapeRegex(string: string | boolean) {
  if (typeof string === "boolean") {
    return String(string);
  }
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}

const visibilityTest = (possibleValue: string | boolean, actualValue: string) =>
  new RegExp(`^${escapeRegex(possibleValue)}$`.replace(/\\\*/g, ".*")).test(actualValue);

export const getVisibleEntities = (entities: RunEntity[], filters: RunsFilters) => {
  // getVisibleEntities gets all the entities which should be rendered to the user as rectangles.
  const output = [];
  entityLoop: for (const entity of entities) {
    if ((entity.type as string) === "module") {
      // TODO: should this be entity.isModule?
      continue;
    }
    for (const i in filters) {
      const [key, possibleValues] = filters[i];

      let found = false;
      if (possibleValues.size === 0) {
        continue;
      }
      const actualValue = getKeyValue(key, entity);
      for (const possibleValue of possibleValues) {
        // This allows us to use percentage signs as wildcards.
        const isValueArray = Array.isArray(actualValue);

        if (
          (!isValueArray && visibilityTest(possibleValue, actualValue)) ||
          (isValueArray && actualValue.some((value) => visibilityTest(possibleValue, value)))
        ) {
          found = true;
          break;
        }
      }
      if (!found) {
        continue entityLoop;
      }
    }
    output.push(entity);
  }
  return output;
};

export const generateKeyPossibleValues = (
  filterField: Array<string>,
  filters: RunsFilters,
  entities: RunEntity[]
) => {
  // This calculates the available key-values for each filter in the pipeline.
  // This way the filter pipeline behaves more like a bunch of piped greps.
  // In short, each filter has a view of the world as if only the preceding filters existed.
  const keyPossibleValues: Array<Record<string, Array<string>>> = [];
  for (let i = 0; i < filterField.length; i++) {
    keyPossibleValues[i] = {};

    const activeFilters = filters.slice(0, i);

    const currentlyVisibleEntities = getVisibleEntities(entities, activeFilters);

    const possibleKeys = FILTERS_KEYS.account;

    for (const key of possibleKeys) {
      const values = new Set<string>();

      for (const entity of currentlyVisibleEntities) {
        const entityValue = getKeyValue(key, entity);

        if ((key === "stack.id" || key === "module.id") && entityValue === "none") continue;
        if (Array.isArray(entityValue)) {
          entityValue.forEach((value) => values.add(value));
        } else {
          values.add(entityValue);
        }
      }
      keyPossibleValues[i][key] = Array.from(values);
    }
  }

  return keyPossibleValues;
};

export const generateGroupByOptions = () => {
  return GROUP_BY_OPTIONS;
};

export const translateLabel = (value: FilterKey) => {
  return FILTER_LABELS[value as keyof typeof FILTER_LABELS];
};

export const getDefaultDateRange = (): DateRange => {
  return {
    startDate: addHours(new Date(), -1),
    endDate: new Date(),
    label: "Last 1 Hour",
  };
};
