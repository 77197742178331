import { Seats } from "types/generated";

export enum UserManagementActivationStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type SettingsContextValue = {
  activationStatus: UserManagementActivationStatus;
  seats: Seats;
};

export enum OrganizationAccessTabs {
  API_KEYS = "Api keys",
  SPACES = "Spaces",
  USERS = "Users",
  IDP_GROUP_MAPPING = "IdP group mapping",
  LOGIN_POLICIES = "Login policies",
  MANAGEMENT_STRATEGY = "Management strategy",
  ROLES = "Roles",
}

export enum PersonalAccessTabs {
  SPACES = "Spaces",
  IDP_GROUPS = "IdP groups",
  API_KEYS = "Api keys",
}

export enum OrganizationAuthTabs {
  SSO = "SSO",
  MFA = "MFA",
}

export enum OrganizationSecurityTabs {
  SECURITY_EMAIL = "Security email",
  AUDIT_TRAIL = "Audit trail",
  SESSIONS = "Sessions",
}

export enum OrganizationOtherTabs {
  INTEGRATIONS = "Integrations",
  BILLING = "Billing",
  LIMITS = "Limits",
  USAGE = "Usage",
  USAGE_EXPORT = "Usage export",
  AI = "AI",
}

export enum ManagementStrategy {
  LOGIN_POLICY = "Login Policy",
  USER_MANAGEMENT = "User Management",
}
