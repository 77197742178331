import { ReactNode } from "react";

import { File } from "components/icons/generated";
import LinkButton from "ds/components/Button/LinkButton";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import { ModuleCreationWizardStep } from "../types";
import styles from "./styles.module.css";

type NewModuleFooterProps = {
  documentationBody: ReactNode;
  documentationTitle?: string;
  documentationLink?: string;
  analyticsDocsLocation?: string;
  currentStep: ModuleCreationWizardStep;
};

const NewModuleFooterDocumentationDrawer = createDrawer(
  ({
    documentationTitle,
    documentationBody,
    documentationLink,
    analyticsDocsLocation,
    currentStep,
  }: NewModuleFooterProps) => {
    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title={documentationTitle || "Documentation"} />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody className={styles.documentationBody}>{documentationBody}</DrawerBody>
        <DrawerFooter>
          <LinkButton
            analyticsPage={AnalyticsPageModule.ModuleNew}
            analyticsTitle="Full documentation click"
            analyticsProps={{
              location: analyticsDocsLocation
                ? `${currentStep} ${analyticsDocsLocation}`
                : currentStep,
            }}
            href={documentationLink}
            variant="secondary"
            size="medium"
            target="_blank"
            startIcon={File}
            className={styles.documentationButton}
          >
            Open documentation
          </LinkButton>
        </DrawerFooter>
      </DrawerSimple>
    );
  }
);
export const showNewModuleFooterDocumentationDrawer = createDrawerTrigger(
  NewModuleFooterDocumentationDrawer
);
