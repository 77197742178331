import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldViewText from "components/FormFields/ViewText";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import useTypedContext from "hooks/useTypedContext";
import { ConfigElement, ConfigType } from "types/generated";
import { getDrawerFormFix } from "utils/css";

import { UPDATE_CONTEXT_CONFIG } from "../FormDrawer/gql";
import { ContextVariablesFormFields } from "../FormDrawer/types";
import { ContextMountedFile } from "../types";

type ContextMountedFilesUpdateFormDrawerProps = {
  contextId: string;
  item?: ContextMountedFile;
};

const ContextMountedFilesUpdateFormDrawer = createDrawer(
  ({ contextId, item }: ContextMountedFilesUpdateFormDrawerProps) => {
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const [addConfig] = useMutation<{
      contextConfigAdd: ConfigElement;
    }>(UPDATE_CONTEXT_CONFIG, {
      refetchQueries: ["GetContext"],
      variables: { contextId },
    });

    const drawer = useModal();

    const closeDrawer = () => {
      drawer.resolve();
      drawer.hide();
    };

    const onSave = (formData: ContextVariablesFormFields) => {
      const input = {
        id: formData.id,
        description: formData.description || "",
        writeOnly: formData.writeOnly,
        type: formData.type,
        value: formData.value,
      };
      addConfig({ variables: { input } })
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: `Config ${data.contextConfigAdd.id} successfully edited`,
            });
            closeDrawer();
          }
        })
        .catch(onError);
    };
    const fileForm = useForm<ContextVariablesFormFields>({
      defaultValues: {
        id: item?.id || "",
        description: item?.description || "",
        writeOnly: item?.writeOnly ? item.writeOnly : false,
        type: ConfigType.FileMount,
        value: item?.value || "",
      },
      mode: "onChange",
    });

    const {
      handleSubmit,
      formState: { isDirty },
    } = fileForm;

    const onSubmit: SubmitHandler<ContextVariablesFormFields> = (formData) => {
      onSave(formData);
    };

    return (
      <DrawerForm isDirty={isDirty}>
        <FormProvider {...fileForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Edit file" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight>
              <Box margin="0 0 large">
                <FormFieldViewText label="Path" value={`/mnt/workspace/${item?.id}`} />
              </Box>
              <ContextConfigFormIsSecretField name="writeOnly" />
              <ContextConfigFormDescriptionField name="description" />

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showContextMountedFilesUpdateFormDrawer = createDrawerTrigger(
  ContextMountedFilesUpdateFormDrawer
);
