import { memo } from "react";

import TableSkeleton from "ds/components/Table/Skeleton";

import BlueprintsTableSkeletonRow from "./Row";

type BlueprintsTableSkeletonProps = {
  rowsPerPage: number;
};

const BlueprintsTableSkeleton = ({ rowsPerPage }: BlueprintsTableSkeletonProps) => {
  return <TableSkeleton rowsPerPage={rowsPerPage} RowElement={BlueprintsTableSkeletonRow} />;
};

export default memo(BlueprintsTableSkeleton);
