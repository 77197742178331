import { gql, TypedDocumentNode } from "@apollo/client";

import {
  Blueprint,
  QueryBlueprintArgs,
  BlueprintIntegrationAttachment,
  MutationBlueprintDetachIntegrationArgs,
} from "types/generated";

type FetchBlueprintIntegrationsData = Pick<Blueprint, "id" | "externalIntegrations">;

export const GET_BLUEPRINT_INTEGRATIONS: TypedDocumentNode<
  FetchBlueprintIntegrationsData,
  QueryBlueprintArgs
> = gql`
  query GetBlueprintIntegrations($id: ID!) {
    blueprint(id: $id) {
      id
      externalIntegrations {
        id
        integrationID
        deleted
        integrationName
      }
    }
  }
`;

type DetachBlueprintIntegrationsData = {
  blueprintAttachToIntegration: Pick<BlueprintIntegrationAttachment, "id">;
};

export const DETACH_BLUEPRINT_INTEGRATION: TypedDocumentNode<
  DetachBlueprintIntegrationsData,
  MutationBlueprintDetachIntegrationArgs
> = gql`
  mutation DetachBlueprintIntegration($id: ID!) {
    blueprintDetachIntegration(id: $id) {
      id
    }
  }
`;
