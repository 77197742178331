import { useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useFormContext } from "react-hook-form";

import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import Counter from "ds/components/Counter";
import { SpacesContext } from "views/Account/SpacesProvider";
import useTypedContext from "hooks/useTypedContext";

import UsersInviteAssignRoleDrawerListItem from "./ListItem";
import UsersInviteAssignRoleDrawerFormAdd from "./AddForm";
import { USER_ROLES_FOR_INVITE } from "./gql";
import { InviteDrawerFields } from "../types";
import { InviteDrawerAssignRoleFormEditFields, InviteDrawerAssignRoleFormFields } from "./types";

type UsersInviteAssignRoleProps = {
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

const UsersInviteAssignRole = ({ setIsDirty }: UsersInviteAssignRoleProps) => {
  const { data, loading } = useQuery(USER_ROLES_FOR_INVITE);

  const { setValue, watch } = useFormContext<InviteDrawerFields>();

  const assignedRoles = watch("assignedRoles");

  const { manageableSpaces } = useTypedContext(SpacesContext);

  const assignedRolesCount = assignedRoles.length;

  const availableRoles = useMemo(() => data?.roles || [], [data?.roles]);

  const allRolesById = useMemo(
    () =>
      data?.roles?.reduce(
        (prev, next) => ({ ...prev, [next.id]: next.name }),
        {} as Record<string, string>
      ) || {},
    [data?.roles]
  );

  const onRoleAdd = useCallback(
    (formData: InviteDrawerAssignRoleFormFields) => {
      const newAssignedRoles = formData.roleIds.map((roleID) => ({
        roleID,
        spaceID: formData.space,
      }));
      setValue("assignedRoles", [
        ...assignedRoles,
        ...newAssignedRoles.filter(
          (newAssignedRoles) =>
            !assignedRoles.find(
              (assignedRole) =>
                assignedRole.roleID === newAssignedRoles.roleID &&
                assignedRole.spaceID === newAssignedRoles.spaceID
            )
        ),
      ]);
    },
    [assignedRoles, setValue]
  );

  const onRoleEdit = useCallback(
    (index: number, formData: InviteDrawerAssignRoleFormEditFields) => {
      setValue(
        "assignedRoles",
        assignedRoles.map((assignedRole, i) =>
          i === index ? { roleID: formData.roleId, spaceID: formData.space } : assignedRole
        )
      );
    },
    [assignedRoles, setValue]
  );

  const onRoleDelete = useCallback(
    (index: number) => {
      setValue(
        "assignedRoles",
        assignedRoles.filter((_, i) => i !== index)
      );
    },
    [assignedRoles, setValue]
  );

  return (
    <Box direction="column" gap="large" padding="x-large 0 0 0">
      <UsersInviteAssignRoleDrawerFormAdd
        onSubmit={onRoleAdd}
        isLoading={loading}
        setIsDirty={setIsDirty}
        availableRoles={availableRoles}
      />

      {assignedRolesCount > 0 && (
        <Box direction="column">
          <Box gap="small">
            <Typography tag="h5" variant="p-t5">
              Roles assigned
            </Typography>

            <Counter count={assignedRolesCount} size="small" />
          </Box>
        </Box>
      )}

      {assignedRoles.map((roleAssignment, i) => (
        <UsersInviteAssignRoleDrawerListItem
          onSubmit={onRoleEdit}
          onDelete={onRoleDelete}
          setIsDirty={setIsDirty}
          assignedRoleIndex={i}
          key={roleAssignment.roleID}
          roleId={roleAssignment.roleID}
          roleName={allRolesById[roleAssignment.roleID]}
          spaceId={roleAssignment.spaceID}
          spaceName={manageableSpaces.find(({ id }) => id === roleAssignment.spaceID)?.name || ""}
          availableRoles={availableRoles}
        />
      ))}
    </Box>
  );
};

export default UsersInviteAssignRole;
