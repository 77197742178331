import cx from "classnames";
import { memo } from "react";

import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import { ChevronRight, Stack } from "components/icons/generated";
import Icon from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { ariaCellInteractionProps } from "ds/components/Table/useKeyboardNavigation/ariaCellInteractionProps";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import styles from "./styles.module.css";
import { type RenderGroupRowCellProps } from ".";

type IacManagementTableGroupRowCellNameProps = RenderGroupRowCellProps;

const IacManagementTableGroupRowCellName = memo(
  ({
    fetchDataLoading,
    loadResources,
    groupMeta,
    row,
  }: IacManagementTableGroupRowCellNameProps) => {
    const isStackRow = row.__typename === "ManagedEntitiesGroupedByStacks";

    const name = isStackRow ? row.stackName : row.id;

    return (
      <IacManagementTableCell role="rowheader" stickyLeft>
        <Box gap="medium" align="center">
          <ButtonIcon
            loading={fetchDataLoading}
            variant="ghost"
            onPress={loadResources}
            {...ariaCellInteractionProps}
            icon={ChevronRight}
            className={cx(styles.chevronButton, {
              [styles.open]: groupMeta?.isOpen,
            })}
          >
            Load Resources
          </ButtonIcon>

          {isStackRow && <Icon src={Stack} noShrink />}

          <TextEllipsis tooltip={name}>
            {(props) => (
              <Typography {...props} variant="p-body3" tag="span">
                {name || row.id || "[empty name]"}
              </Typography>
            )}
          </TextEllipsis>
        </Box>
      </IacManagementTableCell>
    );
  }
);

IacManagementTableGroupRowCellName.displayName = "IacManagementTableGroupRowCellName";

export default IacManagementTableGroupRowCellName;
