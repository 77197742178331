import Link from "ds/components/Link";

import useIntegrationsListAnalytics from "../../useIntegrationsPageAnalytics";
import { CellComponentProps } from "./types";

type IntegrationsListItemSpaceCellProps = CellComponentProps;

const IntegrationsListItemSpaceCell = ({ integration }: IntegrationsListItemSpaceCellProps) => {
  const trackAnalytics = useIntegrationsListAnalytics();

  if (!integration.spaceDetails) {
    return null;
  }

  return (
    <Link
      size="small"
      href={`/spaces/${integration.spaceDetails.id}`}
      onPress={() => trackAnalytics("Space link clicked")}
    >
      {integration.spaceDetails.name}
    </Link>
  );
};

export default IntegrationsListItemSpaceCell;
