import { GitHubAppManifestInput, MutationGithubAppCreateFromManifestArgs } from "types/generated";
import { VCS_INTEGRATION_TYPES } from "views/Account/VCS/constants";
import { ROOT_SPACE_ID } from "constants/space";

import {
  ManageGitHubEnterpriseWizardFinalDetailsFormFields,
  ManageGitHubEnterpriseWizardFormFields,
  ManageGitHubEnterpriseWizardIntegration,
} from "./types";
import { DEFAULT_GITHUB_ENTERPRISE_API_HOST, DEFAULT_GITHUB_ENTERPRISE_HOST } from "./constants";

export const mapGenerateManifestInputValues = (
  values: ManageGitHubEnterpriseWizardFormFields
): GitHubAppManifestInput => ({
  apiHost:
    values.integration === ManageGitHubEnterpriseWizardIntegration.GITHUB
      ? DEFAULT_GITHUB_ENTERPRISE_API_HOST
      : values.apiHost,
  host:
    values.integration === ManageGitHubEnterpriseWizardIntegration.GITHUB
      ? DEFAULT_GITHUB_ENTERPRISE_HOST
      : values.host,
  ownerName: values.ownerName,
  ownerType: values.ownerType,
});

export const mapCreateGitHubAppFromManifestInputValues = (
  values: ManageGitHubEnterpriseWizardFinalDetailsFormFields,
  code: string,
  state: string
): MutationGithubAppCreateFromManifestArgs => {
  const isDefault = values.integrationType === VCS_INTEGRATION_TYPES.DEFAULT;

  return {
    code,
    state,
    customInput: {
      isDefault,
      name: values.name,
      // FYI: set the root to be able to convert to the multiple integration from the default one
      spaceID: isDefault ? ROOT_SPACE_ID : values.space,
      labels: values.labels.map((label) => label.value) || [],
      description: values.description || null,
      vcsChecks: values.vcsChecks,
    },
  };
};
