import { useState } from "react";

import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { StackDependencyReference } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import OutputReferencesDrawerForm from "./Form";
import OutputReferencesDrawerDeleteAction from "./DeleteAction";
import { StackDependenciesOutputReferencesContextApi } from "./Context";

type OutputReferencesDrawerListItemProps = {
  stackDependencyId: string;
  outputReference: StackDependencyReference;
  onGoToOutput: (anchor: string) => void;
  setIsDirty: (isDirty: boolean) => void;
};

const OutputReferencesDrawerListItem = ({
  stackDependencyId,
  outputReference,
  onGoToOutput,
  setIsDirty,
}: OutputReferencesDrawerListItemProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const { checkIsOutputExist } = useTypedContext(StackDependenciesOutputReferencesContextApi);

  const handleSetEditMode = () => setIsEditMode(true);

  const handleUnsetEditMode = () => setIsEditMode(false);

  const handleGoToOutput = () => {
    onGoToOutput(outputReference.outputName);
  };

  const isOutputExist = checkIsOutputExist(outputReference.outputName);

  if (isEditMode) {
    return (
      <OutputReferencesDrawerForm
        setIsDirty={setIsDirty}
        stackDependencyId={stackDependencyId}
        onEditCancel={handleUnsetEditMode}
        onEditSuccess={handleUnsetEditMode}
        outputReference={outputReference}
      />
    );
  }

  return (
    <Box
      direction="row"
      padding="large"
      gap="x-large"
      align="center"
      justify="between"
      className={styles.listItem}
      fullWidth
    >
      <Box direction="column" gap="small" zeroMinWidth>
        <TextEllipsis tooltipWidthMode="maxWidthSm" tooltip={outputReference.inputName}>
          {(props) => (
            <Typography {...props} variant="p-t6" tag="span">
              {outputReference.inputName}
            </Typography>
          )}
        </TextEllipsis>

        <Box direction="column" gap="0" zeroMinWidth>
          <TextEllipsis tooltipWidthMode="maxWidthSm" tooltip={outputReference.outputName}>
            {(props) => (
              <Typography {...props} variant="p-body3" tag="span" color="secondary">
                Output: {outputReference.outputName}
              </Typography>
            )}
          </TextEllipsis>

          {outputReference.triggerAlways && (
            <Typography variant="p-body3" tag="span" color="secondary">
              Always triggers
            </Typography>
          )}

          {!isOutputExist && (
            <Typography variant="p-body3" tag="span" color="warning">
              The output does not exist.
            </Typography>
          )}
        </Box>
      </Box>

      <DropdownMenuEllipsis tooltip="Output reference actions" buttonVariant="ghost">
        <DropdownMenuItem
          tooltip="The output does not exist."
          isTooltipActive={!isOutputExist}
          onAction={handleGoToOutput}
          isDisabled={!isOutputExist}
        >
          Go to the output
        </DropdownMenuItem>
        <DropdownMenuItem onAction={handleSetEditMode}>Edit reference</DropdownMenuItem>
        <OutputReferencesDrawerDeleteAction
          name={outputReference.inputName}
          referenceId={outputReference.id}
        />
      </DropdownMenuEllipsis>
    </Box>
  );
};

export default OutputReferencesDrawerListItem;
