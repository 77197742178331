import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationUserRoleBindingDeleteArgs, UserRoleBinding } from "types/generated";

type UserRoleBindingDeleteData = {
  userRoleBindingDelete: Pick<UserRoleBinding, "id">;
};

export const USER_ROLE_BINDING_DELETE: TypedDocumentNode<
  UserRoleBindingDeleteData,
  MutationUserRoleBindingDeleteArgs
> = gql`
  mutation UserRoleBindingDelete($id: ID!) {
    userRoleBindingDelete(id: $id) {
      id
    }
  }
`;
