import { captureEvent, getRows, handleFocus } from "./utils";

const focusRow = (grid: Element, start: "first" | "last") => {
  const rows = getRows(grid);
  const row = start === "first" ? rows[0] : rows[rows.length - 1];
  handleFocus(row);
};

const useTableKeyboardNavigation = () => {
  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowRight" || e.key === "ArrowDown") {
      captureEvent(e);

      focusRow(e.currentTarget, "first");
      return;
    }

    if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
      captureEvent(e);

      focusRow(e.currentTarget, "last");
      return;
    }
  };

  return { onKeyDown, tabIndex: 0 };
};

export default useTableKeyboardNavigation;
