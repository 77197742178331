import { BulkActionsVariant, BulkEntityActionItem } from "components/BulkActions/types";
import { Role } from "types/generated";

import { RolesMutationVariables } from "./useRolesBulkActions/types";

export enum RolesBulkActions {
  Delete = "Delete",
}

export type RolesBulkActionsAnalyticsPayload = {
  view: BulkActionsVariant;
  metadata: Record<string, string | boolean>;
};

export type RolesBulkActionsSelectedAction = BulkEntityActionItem<
  RolesBulkActions,
  Role,
  RolesMutationVariables
>;
