import { Package, Space, Stack, Trash } from "components/icons/generated";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import Icon from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";
import { AttachedProject } from "./types";

type NewContextAttachedProjectProps = {
  item: AttachedProject;
  onDetach?: (id: string) => void;
};

const NewContextAttachedProject = ({ item, onDetach }: NewContextAttachedProjectProps) => {
  const { name, id, isModule, spaceDetails } = item;
  const canDetach = !!onDetach;

  const handleDetach = () => onDetach?.(id);

  const gridTemplate = canDetach
    ? "1.6rem minmax(0,3fr) minmax(10rem,0.75fr) 2rem"
    : "1.6rem minmax(0,3fr) minmax(10rem,0.75fr)";

  return (
    <Box
      align="center"
      gap="large"
      grid
      gridTemplate={gridTemplate}
      className={styles.item}
      padding={canDetach ? "large" : "medium large"}
      fullWidth
    >
      <Icon src={isModule ? Package : Stack} />
      <TextEllipsis tooltip={name} tooltipWidthMode="maxWidthSm">
        {(props) => (
          <Typography {...props} variant="p-body2" tag="span">
            {name}
          </Typography>
        )}
      </TextEllipsis>

      <div className={styles.badgeWrapper}>
        <BadgeNext
          variant="gray"
          text={spaceDetails.name}
          startIcon={Space}
          textEllipsis
          type="weak"
        />
      </div>
      {canDetach && (
        <ButtonIcon icon={Trash} onPress={handleDetach} variant="ghostDanger">
          Detach
        </ButtonIcon>
      )}
    </Box>
  );
};

export default NewContextAttachedProject;
