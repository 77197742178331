import { StackVendor } from "types/generated";

import IacManagementEmptyCellValue from "../../components/CellEmptyValue";
import IacManagementItemValue from "../../components/CellValue";
import IacManagementTableCell from "../../components/Table/Row/Cell";
import IacManagementTableDropdownCell from "../../components/Table/Row/DropdownCell";
import { getIacManagementStackVendorMeta } from "../../helpers";
import useCellVendorFilterAction from "../useCellVendorFilterAction";

type IacManagementTableVendorCellProps = {
  stackVendor: StackVendor | undefined;
};

const IacManagementTableVendorCell = ({ stackVendor }: IacManagementTableVendorCellProps) => {
  const vendorMeta = stackVendor && getIacManagementStackVendorMeta(stackVendor);

  const vendorFilterAction = useCellVendorFilterAction(vendorMeta?.text);

  if (vendorMeta) {
    return (
      <IacManagementTableDropdownCell actions={vendorFilterAction}>
        <IacManagementItemValue icon={vendorMeta.icon}>{vendorMeta.text}</IacManagementItemValue>
      </IacManagementTableDropdownCell>
    );
  }

  return (
    <IacManagementTableCell>
      <IacManagementEmptyCellValue />
    </IacManagementTableCell>
  );
};

export default IacManagementTableVendorCell;
