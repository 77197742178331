import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { Action, SearchQueryOrderDirection } from "types/generated";

import { Permission } from "./types";

export const PERMISSIONS_COLUMNS_STORAGE_KEY = "permissionsColumnsConfig";
export const FILTERS_ORDER_SETTINGS_KEY = "rolePermissionsFiltersOrder";

export const initialSortOption = "name";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export enum PERMISSIONS_COLUMN_ID {
  ACTION = "ACTION",
  DESCRIPTION = "DESCRIPTION",
  SUBJECT = "SUBJECT",
  DELETE_ACTION = "DELETE_ACTION",
}

export const ROW_HEADER_ID = PERMISSIONS_COLUMN_ID.ACTION;

const MAX_CELL_WIDTH = 600;

export const PERMISSIONS_COLUMN_CONFIG: ColumnConfig = {
  [PERMISSIONS_COLUMN_ID.ACTION]: {
    title: "Action",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    resizable: false,
  },

  [PERMISSIONS_COLUMN_ID.DESCRIPTION]: {
    title: "Description",
    minWidth: 130,
    maxWidth: MAX_CELL_WIDTH,
    resizable: false,
    static: true,
  },

  [PERMISSIONS_COLUMN_ID.SUBJECT]: {
    title: "Subject",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    resizable: false,
  },
};

export const PERMISSIONS_CUSTOM_END_COLUMN_CONFIG: ColumnConfig = {
  [PERMISSIONS_COLUMN_ID.DELETE_ACTION]: {
    title: "Delete action",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
  },
};

export const SORT_CONFIG: Record<string, string> = {
  [PERMISSIONS_COLUMN_ID.ACTION]: "name",
  [PERMISSIONS_COLUMN_ID.SUBJECT]: "subject",
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(PERMISSIONS_COLUMN_CONFIG) as PERMISSIONS_COLUMN_ID[],
  hidden: [],
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageOrganization.OrganizationRole,
};

export const PERMISSIONS: Record<Action, Omit<Permission, "id" | "parentRole">> = {
  [Action.SpaceAdmin]: {
    name: "space:admin",
    subject: "Space",
    description: "Custom description",
  },
  [Action.SpaceRead]: {
    name: "space:read",

    subject: "Space",
    description: "Custom description",
  },
  [Action.SpaceWrite]: {
    name: "space:write",
    subject: "Space",
    description: "Custom description",
  },
};
