import { External, Tag as TagIcon } from "components/icons/generated";
import Link from "ds/components/Link";

import useStackListAnalytics from "../useStackListAnalytics";
import { CellComponentProps } from "./types";

const StackListItemGitTagCell = ({ stack }: CellComponentProps) => {
  const trackAnalytics = useStackListAnalytics();

  const { trackedCommit } = stack;

  if (!trackedCommit?.tag) return null;

  return (
    <Link
      size="small"
      startIcon={TagIcon}
      endIcon={External}
      href={trackedCommit.url}
      target="_blank"
      onPress={() => trackAnalytics("Tag link clicked")}
      tooltip="Tracked git commit tag"
    >
      {trackedCommit.tag}
    </Link>
  );
};

export default StackListItemGitTagCell;
