import { useQuery } from "@apollo/client";
import { CSSProperties, useCallback, useMemo, useRef } from "react";
import { Cell, Row, SortDirection } from "react-aria-components";

import CardWrapper from "components/CardWrapper";
import DocumentationButton from "components/DocumentationButton";
import FlashContext from "components/FlashMessages/FlashContext";
import { EmptystateServicenowColored } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import Table from "components/Table";
import TableContextProvider from "components/Table/Context";
import TableRow from "components/Table/Row";
import TableWrapper from "components/Table/Wrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import useTypedContext from "hooks/useTypedContext";
import { AuthorizationScheme, BillingTierFeature, ExternalIntegration } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";
import useTierFeature from "views/Account/hooks/useTierFeature";

import { SpacesContext } from "../SpacesProvider";
import { showIntegrationDrawerForm } from "./components/IntegrationFormDrawer";
import IntegrationListItem from "./components/ListItem";
import IntegrationsPageLayout from "./components/PageLayout";
import {
  ANALYTICS,
  INITIAL_COLUMNS_VIEW_CONFIG,
  INTEGRATIONS_COLUMN_CONFIG,
  INTEGRATIONS_COLUMNS_STORAGE_KEY,
  INTEGRATIONS_CUSTOM_END_COLUMN_CONFIG,
  ROW_HEADER_ID,
} from "./constants";
import { GET_ACCOUNT_EXTERNAL_INTEGRATIONS } from "./gql";
import { GetAccountExternalIntegrationsGql } from "./types";
import useIntegrationsListAnalytics from "./useIntegrationsPageAnalytics";

const Integrations = () => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const { onError } = useTypedContext(FlashContext);
  const isServiceNowActive = useTierFeature(BillingTierFeature.ServiceNowIntegration);
  const trackAnalytics = useIntegrationsListAnalytics();

  const { loading, data } = useQuery<GetAccountExternalIntegrationsGql>(
    GET_ACCOUNT_EXTERNAL_INTEGRATIONS,
    {
      onError,
    }
  );

  const integrations = useMemo(() => {
    const items = data?.externalIntegrations || [];

    return items;
  }, [data?.externalIntegrations]);

  const isLoginPolicyActive = data?.authorizationScheme === AuthorizationScheme.LoginPolicies;

  const handleOnCreateClick = (location: string) => {
    trackAnalytics("Create integration clicked", { buttonLocation: location });
    showIntegrationDrawerForm({ isLoginPolicyActive, trackAnalytics });
  };

  const canManageIntegrations = isServiceNowActive && hasEntityCreateAccess;

  const sortDescriptor = {
    column: "symbol",
    direction: "ascending" as SortDirection,
  };

  const renderRow = useCallback(
    (
      item: Partial<ExternalIntegration> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.name}
          >
            <IntegrationListItem integration={item as ExternalIntegration} />
          </TableRow>
        );
      }
    },
    []
  );

  return (
    <TableContextProvider
      columnsConfig={INTEGRATIONS_COLUMN_CONFIG}
      endCustomColumns={INTEGRATIONS_CUSTOM_END_COLUMN_CONFIG}
      initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
      rowHeaderId={ROW_HEADER_ID}
      localStorageId={INTEGRATIONS_COLUMNS_STORAGE_KEY}
      setSortDescriptor={() => null}
      sortDescriptor={sortDescriptor}
      {...ANALYTICS}
    >
      <IntegrationsPageLayout onCreate={() => handleOnCreateClick("Header")}>
        {loading && !data && <PageLoading />}

        {!!integrations.length && (
          <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
            <Table
              ref={virtualizedListContainerRef}
              ariaLabel="Integrations list"
              items={integrations}
              loadingContent={loading}
            >
              {renderRow}
            </Table>
          </TableWrapper>
        )}

        {integrations.length === 0 && !loading && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateServicenowColored}
                title="Integrate with ServiceNow"
              >
                <Box gap="medium">
                  <DocumentationButton
                    to={getDocsUrl("/integrations/external-integrations/servicenow")}
                    label="Documentation"
                  />
                  {canManageIntegrations && (
                    <Button variant="primary" onPress={() => handleOnCreateClick("Empty state")}>
                      Create integration
                    </Button>
                  )}
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}
      </IntegrationsPageLayout>
    </TableContextProvider>
  );
};

export default Integrations;
