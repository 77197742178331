import React from "react";
import cx from "classnames";

import Breadcrumbs from "components/Breadcrumbs";
import BreadcrumbStepBack from "components/Breadcrumbs/StepBack";
import useSkipFocusButtons from "ds/components/SkipFocusButton/useSkipFocusButtons";

import styles from "./styles.module.css";
import ViewHeaderScrollCollapse from "./ScrollCollapse";

type ViewHeaderProps = {
  firstLevel?: boolean;
  id?: string;
  noBreadCrumbs?: boolean;
  noSticky?: boolean;
  children: React.ReactNode;
  backConfirmation?: string;
  className?: string;
};

const ViewHeader = ({
  children,
  firstLevel,
  noBreadCrumbs,
  noSticky,
  id,
  backConfirmation,
  className,
}: ViewHeaderProps) => {
  const [skipButton, backButton] = useSkipFocusButtons();

  return (
    <div
      id={id}
      className={cx(
        styles.header,
        {
          [styles.firstLevel]: firstLevel,
          [styles.sticky]: !noSticky,
        },
        className
      )}
    >
      {skipButton({
        label: "Skip header",
      })}
      {!noBreadCrumbs && (
        <ViewHeaderScrollCollapse>
          <Breadcrumbs />
        </ViewHeaderScrollCollapse>
      )}

      <header>
        {!noBreadCrumbs && <BreadcrumbStepBack confirmation={backConfirmation} />}
        {children}
      </header>
      {backButton({
        label: "Back to header",
      })}
    </div>
  );
};

export default ViewHeader;
