import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Datepicker from "ds/components/Datepicker";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import Feedback from "ds/components/Feedback";
import FormField from "ds/components/Form/Field";
import Tile from "ds/components/Tile";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import { ScheduledDelete } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { getNowDateTime } from "utils/date";
import { validateDateIsAfterNow } from "utils/formValidators";

import { getFormDefaultValues, mapCreateFormData } from "./helpers";
import { StackManageScheduledDeleteFormFields } from "./types";
import useCreateScheduledDelete from "./useCreateScheduledDelete";
import useUpdateScheduledDelete from "./useUpdateScheduledDelete";

type StackManageScheduledDeleteProps = {
  integration?: ScheduledDelete;
  stackId: string;
  setIsDirty: (isDirty: boolean) => void;
};

const StackManageScheduledDelete = ({
  integration,
  stackId,
  setIsDirty,
}: StackManageScheduledDeleteProps) => {
  const isEditMode = !!integration;
  const drawer = useModal();

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const minDate = getNowDateTime({ timezone: "UTC" });
  const defaultDateTime = getNowDateTime({ timezone: "UTC", timeShiftMinutes: 15 });

  const scheduleStackDeletionForm = useForm<StackManageScheduledDeleteFormFields>({
    defaultValues: getFormDefaultValues(defaultDateTime, integration),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = scheduleStackDeletionForm;

  const { createScheduledDelete, loading: creationLoading } = useCreateScheduledDelete({ stackId });
  const { updateScheduledDelete, loading: updateLoading } = useUpdateScheduledDelete({ stackId });

  const handlePrimaryAction = (formData: StackManageScheduledDeleteFormFields) => {
    if (isEditMode) {
      updateScheduledDelete(integration.id, mapCreateFormData(formData), () => {
        drawer.hide();
        trackSegmentEvent("Schedule Edit Saved", { type: SCHEDULING_TYPE.DELETE_STACK });
      });
    } else {
      createScheduledDelete(mapCreateFormData(formData), () => {
        drawer.hide();
        trackSegmentEvent("Schedule Created", { type: SCHEDULING_TYPE.DELETE_STACK });
      });
    }
  };

  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);

  return (
    <form onSubmit={handleSubmit(handlePrimaryAction)} {...getDrawerFormFix()}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle
          title={isEditMode ? "Edit stack deletion schedule" : "Create stack deletion schedule"}
        />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody gap="x-large" fullHeight>
        <Feedback type="banner" variant="info">
          Stack deletion schedule only works on private workers.
        </Feedback>

        <Controller
          name="dateTime"
          control={control}
          rules={{
            required: true,
            validate: validateDateIsAfterNow,
          }}
          render={({ field, fieldState }) => (
            <FormField label="Date and time UTC:" error={fieldState.error?.message} noMargin>
              <Datepicker
                withTime
                renderTimezone="UTC"
                date={field.value}
                onChange={field.onChange}
                minDate={minDate}
                hasError={!!fieldState.error?.message}
                fullWidth
              />
            </FormField>
          )}
        />

        <Controller
          name="shouldDeleteResources"
          control={control}
          render={({ field }) => (
            <Box gap="x-large">
              <Tile
                title="Delete resources"
                indicator="radio"
                description="All resources that belong to this stack will be removed with it."
                selected={field.value}
                onClick={() => field.onChange(true)}
              />

              <Tile
                title="Keep resources"
                indicator="radio"
                description="Resources that belong to this stack will remain available when the stack is removed."
                selected={!field.value}
                onClick={() => field.onChange(false)}
              />
            </Box>
          )}
        />

        <DrawerFooter>
          <DrawerFooterActions>
            <DrawerCancelButton />

            <Button variant="primary" type="submit" loading={creationLoading || updateLoading}>
              {isEditMode ? "Save" : "Create"}
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </form>
  );
};

export default StackManageScheduledDelete;
