import useAnalytics from "hooks/useAnalytics";

import { ANALYTICS } from "./constants";

const useBlueprintVersionsAnalytics = () => {
  return useAnalytics({
    page: ANALYTICS.analyticsPage,
  });
};

export default useBlueprintVersionsAnalytics;
