import { ReactNode, useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

import Box from "ds/components/Box";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import TableHeadColumnTitle from "ds/components/Table/Head/Column/Title";
import useColumnSort from "ds/components/Table/Head/Column/useColumnSort";
import TableHeadColumn from "ds/components/Table/Head/Column";
import TableHeadColumnOptionsDropdown from "ds/components/Table/Head/Column/Options";
import { useResizer } from "ds/components/Table/Head/Column/useResizer";
import { TableColumnMeta } from "ds/components/Table/types";
import { FILTERS_ORDER_SETTINGS_KEY } from "views/Account/Stacks/constants";
import useIacManagementAnalytics from "views/Account/IacManagement/useIacManagementAnalytics";

import useIacTableStore from "../useIacTableStore";

const COLUMN_CHANGED_ANALYTIC_EVENT = "Column changed";

const canMoveLeft = (columnId: string, columnsOrder: string[]) => {
  return columnsOrder.indexOf(columnId) > 1; // skip name (first column)
};

const canMoveRight = (columnId: string, columnsOrder: string[]) => {
  return columnsOrder.indexOf(columnId) < columnsOrder.length - 2; // skip options (last column)
};

type IaCManagementTableHeadColumnProps = {
  column: TableColumnMeta;
  stickyLeft?: boolean;
  stickyRight?: boolean;
  children?: ReactNode;
};

const IaCManagementTableHeadColumn = ({
  column,
  stickyLeft,
  stickyRight,
  children,
}: IaCManagementTableHeadColumnProps) => {
  const { analyticPage, trackAnalytics } = useIacManagementAnalytics();

  const { hideColumn, columnsSizes, setColumnSize, columnsOrder, reorderVisibleColumns } =
    useIacTableStore(
      useShallow((state) => ({
        hideColumn: state.hideColumn,
        columnsSizes: state.columnsSizes,
        setColumnSize: state.setColumnSize,
        columnsOrder: state.columnsOrder,
        reorderVisibleColumns: state.reorderVisibleColumns,
      }))
    );

  const savedColumnWidth = columnsSizes[column.id];

  const saveColumnSize = useCallback(
    (width: number) => setColumnSize(column.id, width),
    [column.id, setColumnSize]
  );

  const { icon, handleSort, ariaSort } = useColumnSort(column.id, FILTERS_ORDER_SETTINGS_KEY);
  const { ResizerControl, columnRef } = useResizer({
    width: savedColumnWidth,
    minWidth: column.minWidth,
    maxWidth: column.maxWidth,
    onResizeClb: saveColumnSize,
  });

  const onSort = useCallback(() => {
    handleSort((newSortState) =>
      trackAnalytics("Sort by column", { column: column.id, direction: newSortState })
    );
  }, [column.id, handleSort, trackAnalytics]);

  return (
    <TableHeadColumn
      ref={columnRef}
      column={column}
      stickyLeft={stickyLeft}
      stickyRight={stickyRight}
      ariaSort={ariaSort}
    >
      <Box gap="small" align="center" justify="between" fullHeight>
        {children}
        {!children && column.noHeader && <span className="sr-only">{column.title}</span>}
        {!children && !column.noHeader && (
          <>
            <TableHeadColumnTitle
              title={column.title}
              allowsSorting={column.allowsSorting}
              sortIcon={icon}
              onSort={onSort}
            />

            {(column.canHide || column.resizable) && (
              <Box align="center">
                {column.canHide && (
                  <TableHeadColumnOptionsDropdown>
                    <DropdownMenuItem
                      onAction={() => hideColumn(column.id)}
                      analyticsPage={analyticPage}
                      analyticsTitle={COLUMN_CHANGED_ANALYTIC_EVENT}
                      analyticsProps={{ column: column.id, action: "hide" }}
                    >
                      Hide
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onAction={() => reorderVisibleColumns(column.id, "left")}
                      isDisabled={!canMoveLeft(column.id, columnsOrder.visible)}
                      analyticsPage={analyticPage}
                      analyticsTitle={COLUMN_CHANGED_ANALYTIC_EVENT}
                      analyticsProps={{ column: column.id, action: "move left" }}
                    >
                      Move left
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onAction={() => reorderVisibleColumns(column.id, "right")}
                      isDisabled={!canMoveRight(column.id, columnsOrder.visible)}
                      analyticsPage={analyticPage}
                      analyticsTitle={COLUMN_CHANGED_ANALYTIC_EVENT}
                      analyticsProps={{ column: column.id, action: "move right" }}
                    >
                      Move right
                    </DropdownMenuItem>
                  </TableHeadColumnOptionsDropdown>
                )}

                {column.resizable && ResizerControl}
              </Box>
            )}
          </>
        )}
      </Box>
    </TableHeadColumn>
  );
};

export default IaCManagementTableHeadColumn;
