import Tab from "ds/components/Tab";
import Box from "ds/components/Box";

import usePredefinedFilters, { PredefinedFilterType } from "../usePredefinedFilters";
import styles from "./styles.module.css";

const StackFiltersLayoutTabNavigation = () => {
  const { predefinedFilterTab, setPredefinedFilter } = usePredefinedFilters();

  return (
    <Box
      className={styles.tabNavigation}
      align="center"
      justify="start"
      gap="medium"
      fullWidth
      role="tablist"
    >
      <Tab
        id={PredefinedFilterType.AllStacks}
        onClick={() => setPredefinedFilter(PredefinedFilterType.AllStacks)}
        label="All"
        isActive={!predefinedFilterTab || predefinedFilterTab === PredefinedFilterType.AllStacks}
      />
      <Tab
        id={PredefinedFilterType.NeedsAttention}
        onClick={() => setPredefinedFilter(PredefinedFilterType.NeedsAttention)}
        label="Needs Attention"
        isActive={predefinedFilterTab === PredefinedFilterType.NeedsAttention}
      />
      <Tab
        id={PredefinedFilterType.Failed}
        onClick={() => setPredefinedFilter(PredefinedFilterType.Failed)}
        label="Failed"
        isActive={predefinedFilterTab === PredefinedFilterType.Failed}
      />
      <Tab
        id={PredefinedFilterType.OnHold}
        onClick={() => setPredefinedFilter(PredefinedFilterType.OnHold)}
        label="On Hold"
        isActive={predefinedFilterTab === PredefinedFilterType.OnHold}
      />
      <Tab
        id={PredefinedFilterType.InProgress}
        onClick={() => setPredefinedFilter(PredefinedFilterType.InProgress)}
        label="In progress"
        isActive={predefinedFilterTab === PredefinedFilterType.InProgress}
      />
      <Tab
        id={PredefinedFilterType.Finished}
        onClick={() => setPredefinedFilter(PredefinedFilterType.Finished)}
        label="Finished"
        isActive={predefinedFilterTab === PredefinedFilterType.Finished}
      />
    </Box>
  );
};

export default StackFiltersLayoutTabNavigation;
