import { ExclamationMarkTriangleFilled, Network } from "components/icons/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import MetaInfoListItem from "components/MetaInfoList/Item";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import { PublicWorkerPool } from "types/generated";
import {
  NO_PUBLIC_WORKERS_AVAILABLE_MESSAGE,
  PUBLIC_WORKER_POOL_NAME,
  PUBLIC_WORKER_POOL_URL,
} from "views/Account/WorkerPool/constants";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import styles from "./styles.module.css";

type PublicWorkerPoolListItemProps = {
  publicWorkerPool: PublicWorkerPool;
};

const PublicWorkerPoolListItem = ({ publicWorkerPool }: PublicWorkerPoolListItemProps) => {
  const runsCount = publicWorkerPool.schedulableRunsCount;
  const hasWarning = publicWorkerPool.parallelism === 0 && runsCount > 0;

  return (
    <ListEntitiesItem
      direction="row"
      justify="between"
      align="center"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      className={styles.publicWorkerItem}
      role="row"
    >
      <Box role="rowheader" align="center" gap="large">
        <Box direction="row" align="center" justify="center" className={styles.workerTypeIcon}>
          <Icon size="large" src={Network} />
        </Box>

        <ListEntitiesItemLink
          href={`${PUBLIC_WORKER_POOL_URL}/queue`}
          title={PUBLIC_WORKER_POOL_NAME}
        />
      </Box>

      <MetaInfoListItem> - </MetaInfoListItem>

      <MetaInfoListItem>
        {publicWorkerPool.parallelism > 0 && "up to "}
        {publicWorkerPool.parallelism}
      </MetaInfoListItem>

      <MetaInfoListItem>{publicWorkerPool.busyWorkers}</MetaInfoListItem>

      <MetaInfoListItem>
        {hasWarning ? (
          <Tooltip
            on={(props) => (
              <BadgeNext
                variant="red"
                startIcon={ExclamationMarkTriangleFilled}
                text={`${runsCount}`}
                type="weak"
                {...props}
              />
            )}
          >
            {NO_PUBLIC_WORKERS_AVAILABLE_MESSAGE}
          </Tooltip>
        ) : (
          runsCount
        )}
      </MetaInfoListItem>
    </ListEntitiesItem>
  );
};

export default PublicWorkerPoolListItem;
