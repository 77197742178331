import { ReactNode, useState } from "react";

import Filters from "components/Filters";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import {
  FilterItem,
  FiltersItemsOptionsMap,
  SavedFilterView,
  SortOption,
} from "components/Filters/types";
import PaginationIndicator from "components/PaginationIndicator";
import SearchInput from "components/SearchInput";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import PageInfoHeader from "ds/components/PageInfoHeader";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";

import PoliciesFeatureGateCallout from "../../FeatureGateCallout";
import {
  FILTERS_ORDER_SETTINGS_KEY,
  FILTERS_TYPE,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
} from "../constants";
import PoliciesListFiltersLayoutHeader from "./Header";

type PoliciesListFiltersLayoutProps = {
  children: ReactNode;
  renderedEntitiesCount: number;
  isPageEmpty: boolean;
  loadingIndication: boolean;
  filteredCount: number;
  openCreateDrawer: () => void;
  canCreatePolicy: boolean;
  filters: FilterItem[];
  filtersMap: FiltersItemsOptionsMap;
  sortOptions: SortOption[];
  filtersLoading: boolean;
  refetchActiveSections: (fields: string[]) => void;
};

const PoliciesListFiltersLayout = ({
  filters,
  filtersMap,
  sortOptions,
  filtersLoading,
  refetchActiveSections,
  children,
  renderedEntitiesCount,
  isPageEmpty,
  loadingIndication,
  filteredCount,
  openCreateDrawer,
  canCreatePolicy,
}: PoliciesListFiltersLayoutProps) => {
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);

  return (
    <>
      <PageInfoHeader title="Account policies">
        <Box direction="row" align="center" gap="0 large">
          {!isPageEmpty && (
            <>
              <PaginationIndicator
                currentCount={renderedEntitiesCount}
                totalCount={filteredCount}
                loading={loadingIndication}
                minimumLoadingDuration={200}
              />
              <SearchInput
                placeholder="Search policies"
                filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
              />
            </>
          )}
          {canCreatePolicy && (
            <Button onPress={openCreateDrawer} variant="primary">
              Create policy
            </Button>
          )}
        </Box>
      </PageInfoHeader>

      <PoliciesFeatureGateCallout />

      <Filters
        filters={filters}
        filtersItemsOptionsMap={filtersMap}
        filtersLoading={filtersLoading}
        sortOptions={sortOptions}
        initialSortOption={INITIAL_SORT_OPTION}
        initialSortDirection={INITIAL_SORT_DIRECTION}
        pollActiveSections={refetchActiveSections}
        filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        filtersType={FILTERS_TYPE}
        currentSavedView={currentSavedView}
        setCurrentSavedView={setCurrentSavedView}
      >
        <FiltersSplit>
          <FiltersSidebar analyticsPage={AnalyticsPagePolicy.PoliciesList} />

          <FiltersContentWrapper>
            {!isPageEmpty && <PoliciesListFiltersLayoutHeader />}

            {children}
          </FiltersContentWrapper>
        </FiltersSplit>
      </Filters>
    </>
  );
};

export default PoliciesListFiltersLayout;
