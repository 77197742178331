import Box from "ds/components/Box";
import Button from "ds/components/Button";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import { AnalyticsPageBilling } from "hooks/useAnalytics/pages/billing";
import { BillingInfo } from "types/generated";

import { showUpdateInvoicingDetailsDrawer } from "../UpdateInvoicingDetailsDrawer";

type BillingInvoicingDetailsTileProps = {
  billingInfo: BillingInfo;
};

const BillingInvoicingDetailsTile = ({ billingInfo }: BillingInvoicingDetailsTileProps) => {
  return (
    <>
      <TileWrapper>
        <TileContent>
          <Box justify="between" align="center" gap="medium">
            <TileTitle variant="p-t5">Invoicing details</TileTitle>
            <Button
              onPress={() =>
                showUpdateInvoicingDetailsDrawer({
                  billingInfo,
                })
              }
              variant="secondary"
              size="small"
              analyticsPage={AnalyticsPageBilling.BillingDashboard}
              analyticsTitle="Invoicing Details Edit Button Clicked"
            >
              Edit
            </Button>
          </Box>
          <Box direction="column" gap="small">
            <Typography variant="p-body2" tag="p">
              {billingInfo.fullName}
            </Typography>
            <Typography variant="p-body3" tag="p" color="secondary">
              {billingInfo.email}
            </Typography>
          </Box>
        </TileContent>
      </TileWrapper>
    </>
  );
};

export default BillingInvoicingDetailsTile;
