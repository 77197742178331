import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { USER_IDP_GROUPS_COLUMN_ID } from "../constants";
import UserIdpGroupsListItemNameCell from "./NameCell";
import UserIdpGroupsLoginMethodCell from "./LoginMethodCell";
import { IdpGroup } from "../types";
import UserIdpGroupsListItemSpaceCell from "./SpaceCell";
import UserIdpGroupsListItemStatusCell from "./StatusCell";

const CELLS = {
  [USER_IDP_GROUPS_COLUMN_ID.NAME]: UserIdpGroupsListItemNameCell,
  [USER_IDP_GROUPS_COLUMN_ID.STATUS]: UserIdpGroupsListItemStatusCell,
  [USER_IDP_GROUPS_COLUMN_ID.SPACE]: UserIdpGroupsListItemSpaceCell,
  [USER_IDP_GROUPS_COLUMN_ID.LOGIN_METHOD]: UserIdpGroupsLoginMethodCell,
};

type UserIdpGroupsListItemProps = {
  group: IdpGroup;
};

const UserIdpGroupsListItem = (props: UserIdpGroupsListItemProps) => {
  const { group } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as USER_IDP_GROUPS_COLUMN_ID];
        const uniqueKey = `${id}-${group.id}`;

        const childrenComponent = <Component group={group} />;

        return (
          <TableCell key={uniqueKey} id={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(UserIdpGroupsListItem);
