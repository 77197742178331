import { SortDescriptor } from "react-aria-components";

import { USER_ROLES_COLUMN_ID } from "../constants";
import { RoleBinding } from "../types";

export const sortRoles = (items: RoleBinding[], sortDescriptor: SortDescriptor) => {
  if (!sortDescriptor.column) {
    return items;
  }

  const isAscending = sortDescriptor.direction === "ascending";

  return [...items].sort((a, b) => {
    let valueA: string | undefined;
    let valueB: string | undefined;

    switch (sortDescriptor.column as USER_ROLES_COLUMN_ID) {
      case USER_ROLES_COLUMN_ID.NAME:
        valueA = a.role.name;
        valueB = b.role.name;
        break;
      // TODO: update after BE improvement done
      case USER_ROLES_COLUMN_ID.GRANTED_AT:
        valueA = a.spaceID;
        valueB = b.spaceID;
        break;
      case USER_ROLES_COLUMN_ID.DESCRIPTION:
        valueA = "";
        valueB = "";
        break;
      case USER_ROLES_COLUMN_ID.DROPDOWN:
        valueA = "";
        valueB = "";
        break;
    }

    valueA = valueA || "";
    valueB = valueB || "";

    return isAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
  });
};
