import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import { ExternalIntegration } from "types/generated";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { INTEGRATIONS_COLUMN_ID } from "../../constants";
import IntegrationsListItemDescriptionCell from "./DescriptionCell";
import IntegrationsListItemSpaceCell from "./SpaceCell";
import IntegrationsListItemActionsDropdownCell from "./DropdownActionsCell";
import IntegrationsListItemNameCell from "./NameCell";

const CELLS = {
  [INTEGRATIONS_COLUMN_ID.DESCRIPTION]: IntegrationsListItemDescriptionCell,
  [INTEGRATIONS_COLUMN_ID.NAME]: IntegrationsListItemNameCell,
  [INTEGRATIONS_COLUMN_ID.SPACE]: IntegrationsListItemSpaceCell,
  [INTEGRATIONS_COLUMN_ID.DROPDOWN]: IntegrationsListItemActionsDropdownCell,
};

type IntegrationListItemProps = {
  integration: ExternalIntegration;
};

const IntegrationListItem = (props: IntegrationListItemProps) => {
  const { integration } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  if (!integration.id) {
    return null;
  }

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as INTEGRATIONS_COLUMN_ID];

        const uniqueKey = `${id}-${integration.id}`;

        const childrenComponent = <Component integration={integration} />;

        return (
          <TableCell key={uniqueKey} id={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(IntegrationListItem);
