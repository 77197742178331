import Link from "ds/components/Link";

import useBlueprintIntegrationAnalytics from "../../useBlueprintIntegrationAnalytics";
import { CellComponentProps } from "./types";

type BlueprintIntegrationsListItemSpaceCellProps = CellComponentProps;

const BlueprintIntegrationsListItemSpaceCell = ({
  integration,
}: BlueprintIntegrationsListItemSpaceCellProps) => {
  const trackAnalytics = useBlueprintIntegrationAnalytics();

  if (!integration.space) {
    return null;
  }

  return (
    <Link
      size="small"
      href={`/spaces/${integration.space.id}`}
      onPress={() => trackAnalytics("Space link clicked")}
    >
      {integration.space.name}
    </Link>
  );
};

export default BlueprintIntegrationsListItemSpaceCell;
