import Box from "ds/components/Box";
import Link from "ds/components/Link";

import useUsersListAnalytics from "../useUsersListAnalytics";
import { CellComponentProps } from "../types";

type UsersListItemNameCellProps = CellComponentProps;

const UsersListItemNameCell = ({ user }: UsersListItemNameCellProps) => {
  const trackAnalytics = useUsersListAnalytics();

  const userUrl = `/settings/user/${user.id}`;

  return (
    <Box fullWidth gap="large">
      <Link
        size="small"
        variant="secondary"
        href={userUrl}
        onPress={() => trackAnalytics("Name link clicked")}
      >
        {user.username}
      </Link>
    </Box>
  );
};

export default UsersListItemNameCell;
