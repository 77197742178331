import { memo } from "react";

import TableHead from "ds/components/Table/Head";
import { getColumnKey } from "ds/components/Table/Head/Column/utils";
import { SortStateDescriptor } from "components/Filters/types";

import useBlueprintsTableStore from "../useBlueprintsTableStore";
import { selectVisibleColumns } from "../useBlueprintsTableStore/selectors";
import BlueprintsTableHeadColumn from "./Column";

type BlueprintsTableHeadProps = {
  sortCallback: (sortDescriptor: SortStateDescriptor) => void;
};

const BlueprintsTableHead = ({ sortCallback }: BlueprintsTableHeadProps) => {
  const columns = useBlueprintsTableStore((state) => selectVisibleColumns(state));

  return (
    <TableHead>
      {columns.map((column) => (
        <BlueprintsTableHeadColumn
          key={getColumnKey(column)}
          sortCallback={sortCallback}
          column={column}
        />
      ))}
    </TableHead>
  );
};

export default memo(BlueprintsTableHead);
