import { CSSProperties, useCallback, useMemo, useRef } from "react";
import { Cell, Row } from "react-aria-components";

import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageInfo from "components/PageWrapper/Info";
import SearchInput from "components/SearchInput";
import Table from "components/Table";
import TableContextProvider from "components/Table/Context";
import TableRow from "components/Table/Row";
import TableWrapper from "components/Table/Wrapper";
import useTableSort from "components/Table/useTableSort";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";

import IntegrationsListItem from "./ListItem";
import {
  ANALYTICS,
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_COLUMNS_VIEW_CONFIG,
  initialSortDirection,
  initialSortOption,
  ROW_HEADER_ID,
  SORT_CONFIG,
  USER_INTEGRATIONS_COLUMN_CONFIG,
  USER_INTEGRATIONS_COLUMNS_STORAGE_KEY,
  USER_INTEGRATIONS_CUSTOM_END_COLUMN_CONFIG,
} from "./constants";
import { Integration } from "./types";
import { useFilteredEntities } from "./useFilteredEntities";
import UserIntegrationsEmpty from "./Empty";
import { UserViewDetails } from "../types";

type UserIntegrationsProps = {
  user: UserViewDetails;
};

const UserIntegrations = ({ user }: UserIntegrationsProps) => {
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const { handleSortOptionChange, sortDescriptor } = useTableSort({
    filtersOrderSettingsKey: FILTERS_ORDER_SETTINGS_KEY,
    sortConfig: SORT_CONFIG,
    initialSortOption,
    initialSortDirection,
  });

  // For now we only can have one single integration - Slack
  const integrations = useMemo(() => {
    if (user.slackMemberID) {
      return [{ id: user.slackMemberID, type: "Slack" } as Integration];
    }

    return [];
  }, [user.slackMemberID]);

  const filteredIntegrations = useFilteredEntities(integrations, sortDescriptor);

  const isPageEmpty = filteredIntegrations.length === 0;

  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Organization Settings · User · ${accountName}`);

  useBreadcrumbs(
    [
      {
        title: "Organization Settings Users",
        link: getBreadcrumbsBackUrl("/settings/users", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: user.username,
      },
    ],
    []
  );

  const renderRow = useCallback(
    (
      item: Partial<Integration> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.id}
          >
            <IntegrationsListItem integration={item as Integration} user={user} />
          </TableRow>
        );
      }
    },
    [user]
  );

  return (
    <>
      <PageInfo title="Integrations">
        <SearchInput placeholder="Search..." filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY} />
      </PageInfo>
      <TableContextProvider
        columnsConfig={USER_INTEGRATIONS_COLUMN_CONFIG}
        endCustomColumns={USER_INTEGRATIONS_CUSTOM_END_COLUMN_CONFIG}
        initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
        rowHeaderId={ROW_HEADER_ID}
        localStorageId={USER_INTEGRATIONS_COLUMNS_STORAGE_KEY}
        setSortDescriptor={handleSortOptionChange}
        sortDescriptor={sortDescriptor}
        {...ANALYTICS}
      >
        {!!filteredIntegrations.length && (
          <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
            <Table
              ariaLabel="Integrations"
              items={filteredIntegrations}
              ref={virtualizedListContainerRef}
            >
              {renderRow}
            </Table>
          </TableWrapper>
        )}
        {!integrations?.length && <UserIntegrationsEmpty />}
        {isPageEmpty && !!integrations?.length && (
          <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
        )}
      </TableContextProvider>
    </>
  );
};

export default UserIntegrations;
