import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import FormFieldSegmentedSchedule from "components/FormFields/SegmentedSchedule";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import Feedback from "ds/components/Feedback";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import { ScheduledTask } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { getNowDateTime } from "utils/date";
import { stringIsRequired } from "utils/formValidators";

import { getFormDefaultValues, mapCreateFormData } from "./helpers";
import { ScheduledTaskFormFields } from "./types";
import useCreateScheduledTask from "./useCreateScheduledTask";
import useUpdateScheduledTask from "./useUpdateScheduledTask";

type StackManageScheduledTaskProps = {
  integration?: ScheduledTask;
  stackId: string;
  setIsDirty: (isDirty: boolean) => void;
};

const StackManageScheduledTask = ({
  integration,
  stackId,
  setIsDirty,
}: StackManageScheduledTaskProps) => {
  const isEditMode = !!integration;
  const drawer = useModal();

  const minDate = getNowDateTime({ timezone: "UTC" });
  const defaultDateTime = getNowDateTime({ timezone: "UTC", timeShiftMinutes: 15 });

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const scheduledTaskForm = useForm<ScheduledTaskFormFields>({
    defaultValues: getFormDefaultValues(defaultDateTime, integration),
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty, errors },
    trigger,
  } = scheduledTaskForm;

  const { createScheduledTask, loading: creationLoading } = useCreateScheduledTask({ stackId });
  const { updateScheduledTask, loading: updateLoading } = useUpdateScheduledTask({ stackId });

  const isRecurring = watch("recurring");

  const handlePrimaryAction = (formData: ScheduledTaskFormFields) => {
    if (isEditMode) {
      updateScheduledTask(integration.id, mapCreateFormData(formData), () => {
        drawer.hide();
        trackSegmentEvent("Schedule Edit Saved", { type: SCHEDULING_TYPE.TASK });
      });
    } else {
      createScheduledTask(mapCreateFormData(formData), () => {
        drawer.hide();
        trackSegmentEvent("Schedule Created", { type: SCHEDULING_TYPE.TASK });
      });
    }
  };

  useEffect(() => {
    if (isEditMode && !isRecurring) {
      trigger();
    }
  }, [isEditMode, trigger, isRecurring]);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);

  return (
    <form onSubmit={handleSubmit(handlePrimaryAction)} {...getDrawerFormFix()}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={isEditMode ? "Edit task schedule" : "Create task schedule"} />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody gap="x-large" fullHeight>
        <Feedback type="banner" variant="info">
          Task schedule only works on private workers.
        </Feedback>

        <FormProvider {...scheduledTaskForm}>
          <FormField label="Command" error={errors?.command?.message} noMargin>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Type task command..."
                error={!!errors?.command}
                {...register("command", {
                  validate: stringIsRequired("Command field is required."),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldSegmentedSchedule minDate={minDate} />

          <DrawerFooter>
            <DrawerFooterActions>
              <DrawerCancelButton />
              <Button variant="primary" type="submit" loading={creationLoading || updateLoading}>
                {isEditMode ? "Save" : "Create"}
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </FormProvider>
      </DrawerBody>
    </form>
  );
};

export default StackManageScheduledTask;
