import { ReactNode, useMemo, useState } from "react";

import DocumentationIconButton from "components/DocumentationIconButton";
import { ArrowRight, Link } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ButtonIcon from "ds/components/ButtonIcon";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Icon from "ds/components/Icon";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";

import { StackFormContext } from "../context";
import { StackCreationWizardStep, StackFormFields } from "../types";
import useStackCreationAnalyticsVersion from "../useStackCreationAnalyticsVersion";
import { areAllNextStepsOptional, isSkipToSummaryVisible } from "../utils";
import NewStackFooterBackButton from "./BackButton";
import NewStackFooterContinueButton from "./ContinueButton";
import { showNewStackFooterDocumentationDrawer } from "./DocumentationDrawer";
import styles from "./styles.module.css";
import { GetData } from "./types";
import { useContinueAndCreateStack } from "./useContinueAndCreateStack";
import { useUpdateStack } from "./useUpdateStack";

// this helps mostly stories to render properly
const allowedIntermediateStackCreationSteps = [
  StackCreationWizardStep.Hooks, // default
  StackCreationWizardStep.Behaviour, // for self hosted
  StackCreationWizardStep.Vendor, // for pulumi vendor
];

type NewStackFooterProps = {
  loading?: boolean;
  handleOnCreateStackClick?: () => void;
  processStepData?: GetData;
  shareStackHandler?: () => void;
  documentationBody?: ReactNode;
  documentationTitle?: string;
  documentationLink?: string;
  analyticsDocsLocation?: string;
  isDataChanged?: boolean;
  localFormData?: StackFormFields;
  runFormValidations?: () => Promise<boolean>;
};

const NewStackFooter = ({
  loading,
  handleOnCreateStackClick,
  shareStackHandler,
  documentationTitle,
  documentationBody,
  documentationLink,
  analyticsDocsLocation,
  isDataChanged,
  localFormData,
  processStepData,
  runFormValidations = async () => true,
}: NewStackFooterProps) => {
  const {
    availableSteps,
    currentStep,
    currentStepIndex,
    createdStackId,
    setIsIntermediateStepActive,
    isIntermediateStepActive,
    goToNextStep,
    goToPreviousStep,
    goToSummary,
    formData,
  } = useTypedContext(StackFormContext);
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const { isLastStep, isFirstStep } = useMemo(() => {
    return {
      isLastStep: currentStep === StackCreationWizardStep.Summary,
      isFirstStep: currentStepIndex === 0,
    };
  }, [currentStep, currentStepIndex]);

  const isCreateAndContinueButtonEnabled =
    areAllNextStepsOptional(currentStep, localFormData || formData, availableSteps) &&
    !createdStackId &&
    allowedIntermediateStackCreationSteps.includes(currentStep);

  const skipToSummaryFromIntermediate = () => {
    setIsIntermediateStepActive(false);
    goToSummary();
  };

  const updateStackHandler = useUpdateStack(isDataChanged);

  const skipToSummaryHandler = async () => {
    const isValidForm = await runFormValidations();
    if (!isValidForm) return;

    if (isIntermediateStepActive) {
      skipToSummaryFromIntermediate();
      return;
    }

    updateStackHandler({
      getData: processStepData,
      setLoading: setIsSkipLoading,
      callback: goToSummary,
    });
  };
  const onContinue = async () => {
    const isValidForm = await runFormValidations();
    if (isValidForm) {
      updateStackHandler({
        setLoading: setIsContinueLoading,
        callback: goToNextStep,
        getData: processStepData,
      });
    }
  };

  const continueAndCreateStack = useContinueAndCreateStack(setIsContinueLoading, processStepData);

  const continueAndCreateStackHandler = async () => {
    const isValidForm = await runFormValidations();
    if (isValidForm) {
      continueAndCreateStack();
    }
  };

  const showDocumentationDrawer = () => {
    if (documentationTitle) {
      showNewStackFooterDocumentationDrawer({
        documentationTitle,
        documentationBody,
        documentationLink,
        analyticsDocsLocation,
        currentStep,
      });
    }
  };

  const isSkipToSummaryEnabled =
    isSkipToSummaryVisible(currentStep, localFormData || formData, availableSteps) &&
    createdStackId;

  const isUpdateActive = createdStackId && isDataChanged;

  return (
    <FullScreenModalFooter className={styles.footerWrapper} justify="between">
      <Box gap="medium">
        {documentationTitle && documentationBody && !isLastStep && (
          <>
            <DocumentationIconButton
              onClick={showDocumentationDrawer}
              tooltipText="Open documentation"
              analyticsPage={AnalyticsPageStack.StackNew}
              analyticsTitle="Docs button click"
              analyticsProps={{
                location: currentStep,
                version: analyticsVersion,
              }}
            />
          </>
        )}

        {shareStackHandler && isLastStep && (
          <ButtonIcon size="medium" variant="secondary" onPress={shareStackHandler} icon={Link}>
            Copy stack link to share it with your team to quickly create the same stack.
          </ButtonIcon>
        )}
      </Box>
      <Box direction="row" gap="medium">
        {isSkipToSummaryEnabled && (
          <NewStackFooterContinueButton
            analyticsTitle="Skip to summary click"
            analyticsProps={{
              location: currentStep,
              version: analyticsVersion,
            }}
            variant="secondary"
            loading={isSkipLoading}
            disabled={loading || isContinueLoading || isSkipLoading}
            onClickHandler={skipToSummaryHandler}
          >
            {isUpdateActive ? "Save & skip to summary" : "Skip to summary"}
          </NewStackFooterContinueButton>
        )}

        {!isFirstStep && (
          <NewStackFooterBackButton
            disabled={loading || isSkipLoading || isContinueLoading}
            callback={goToPreviousStep}
          />
        )}

        {!isLastStep && !isCreateAndContinueButtonEnabled && (
          <NewStackFooterContinueButton
            analyticsTitle={`Continue click on ${currentStep} step`}
            analyticsProps={{
              version: analyticsVersion,
            }}
            variant="primary"
            onClickHandler={onContinue}
            loading={loading || isContinueLoading}
            disabled={isSkipLoading || loading || isContinueLoading}
            endIcon={({ className }) => <Icon src={ArrowRight} className={className} />}
          >
            {isUpdateActive ? "Save & continue" : "Continue"}
          </NewStackFooterContinueButton>
        )}

        {isCreateAndContinueButtonEnabled && (
          <NewStackFooterContinueButton
            analyticsTitle={`Create and continue click on ${currentStep} step`}
            analyticsProps={{
              version: analyticsVersion,
            }}
            variant="primary"
            onClickHandler={continueAndCreateStackHandler}
            loading={loading || isContinueLoading}
            disabled={loading || isContinueLoading}
            endIcon={({ className }) => <Icon src={ArrowRight} className={className} />}
          >
            Create & continue
          </NewStackFooterContinueButton>
        )}

        {isLastStep && (
          <Button
            variant="primary"
            onPress={handleOnCreateStackClick}
            loading={loading}
            disabled={loading}
          >
            Confirm
          </Button>
        )}
      </Box>
    </FullScreenModalFooter>
  );
};

export default NewStackFooter;
