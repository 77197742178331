import { addHours, addDays, differenceInDays } from "date-fns/esm";

import DatepickerRange from "ds/components/Datepicker/Range";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";
import { DateRange } from "./types";

const PERIODS = {
  last1Hour: {
    label: "Last 1 Hour",
    range: () => ({
      start: addHours(new Date(), -1),
      end: new Date(),
    }),
  },
  last4Hours: {
    label: "Last 4 Hours",
    range: () => ({
      start: addHours(new Date(), -4),
      end: new Date(),
    }),
  },
  last24Hours: {
    label: "Last 24 Hours",
    range: () => ({
      start: addDays(new Date(), -1),
      end: new Date(),
    }),
  },
  last7Days: {
    label: "Last 7 days",
    range: () => ({
      start: addDays(new Date(), -7),
      end: new Date(),
    }),
  },
};

type ResourcesWrapperDatepickerProps = {
  dateRange: DateRange;
  handleDateRangeChange: (
    startDate: DateRange["startDate"],
    endDate: DateRange["endDate"],
    label?: DateRange["label"]
  ) => void;
};
const ResourcesWrapperDatepicker = ({
  dateRange,
  handleDateRangeChange,
}: ResourcesWrapperDatepickerProps) => {
  const { reportError } = useTypedContext(FlashContext);

  return (
    <div className={styles.resourcesRunDatePicker}>
      <Box direction="column" gap="small">
        <Typography tag="span" variant="p-t7">
          Date Range:
        </Typography>
        <DatepickerRange
          periods={PERIODS}
          withTime
          initialPeriod={dateRange.label}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          maxDate={new Date()}
          onChange={({ start: startDate, end: endDate }, period) => {
            if (differenceInDays(endDate, startDate) > 8) {
              reportError({ message: "Range is too large, max is 8 days" });
              return;
            }

            handleDateRangeChange(startDate, endDate, period);
          }}
        />
      </Box>
    </div>
  );
};

export default ResourcesWrapperDatepicker;
