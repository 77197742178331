import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  AwsIntegrationStackAttachment,
  AzureIntegrationStackAttachment,
  Maybe,
  StackIntegrationGcp,
} from "types/generated";

import {
  DETACH_AWS_CLOUD_INTEGRATION,
  DETACH_AZURE_CLOUD_INTEGRATION,
  DETACH_GCP_CLOUD_INTEGRATION,
} from "./gql";
import { CLOUD_INTEGRATIONS } from "./types";

type AwsParams = {
  type: CLOUD_INTEGRATIONS.AWS;
  attachmentId: string;
};

type AzureParams = {
  type: CLOUD_INTEGRATIONS.Azure;
  attachmentId: string;
};

type GcpParams = {
  type: CLOUD_INTEGRATIONS.GCP;
  entityId: string;
};

type Params = AwsParams | AzureParams | GcpParams;

type UseDetachCloudOptions = {
  refetchQueries?: string[];
};

const useDetachCloudIntegration = (options: UseDetachCloudOptions) => {
  const refetchQueries = options.refetchQueries;
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [detachAwsIntegration, { loading: detachAwsLoading }] = useMutation<{
    awsIntegrationDetach: Maybe<AwsIntegrationStackAttachment>;
  }>(DETACH_AWS_CLOUD_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const detachAws = useCallback(
    ({ attachmentId }: AwsParams, successCallback?: () => void) => {
      detachAwsIntegration({ variables: { attachmentId } })
        .then(({ data }) => {
          if (data?.awsIntegrationDetach?.id) {
            reportSuccess({ message: "AWS integration was detached" });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [detachAwsIntegration, onError, reportSuccess]
  );

  const [detachAzureIntegration, { loading: detachAzureLoading }] = useMutation<{
    azureIntegrationDetach: Maybe<AzureIntegrationStackAttachment>;
  }>(DETACH_AZURE_CLOUD_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const detachAzure = useCallback(
    ({ attachmentId }: AzureParams, successCallback?: () => void) => {
      detachAzureIntegration({ variables: { attachmentId } })
        .then(({ data }) => {
          if (data?.azureIntegrationDetach?.id) {
            reportSuccess({ message: "Azure integration was detached" });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [detachAzureIntegration, onError, reportSuccess]
  );

  const [detachGcpIntegration, { loading: detachGcpLoading }] = useMutation<{
    stackIntegrationGcpDelete: Maybe<StackIntegrationGcp>;
  }>(DETACH_GCP_CLOUD_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const detachGcp = useCallback(
    ({ entityId }: GcpParams, successCallback?: () => void) => {
      detachGcpIntegration({ variables: { entityId } })
        .then(({ data }) => {
          if (data?.stackIntegrationGcpDelete?.activated === false) {
            reportSuccess({ message: "GCP integration was detached" });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [detachGcpIntegration, onError, reportSuccess]
  );

  const detach = useCallback(
    (params: Params, successCallback?: () => void) => {
      switch (params.type) {
        case CLOUD_INTEGRATIONS.AWS:
          return detachAws(params, successCallback);
        case CLOUD_INTEGRATIONS.Azure:
          return detachAzure(params, successCallback);
        case CLOUD_INTEGRATIONS.GCP:
          return detachGcp(params, successCallback);
      }
    },
    [detachAws, detachAzure, detachGcp]
  );

  return {
    detach,
    detachLoading: detachAwsLoading || detachAzureLoading || detachGcpLoading,
  };
};

export default useDetachCloudIntegration;
