import { create, show, useModal } from "@ebay/nice-modal-react";

import { ConfettiColored } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Icon from "ds/components/Icon";
import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";
import Typography from "ds/components/Typography";
import TypographyHeading from "ds/components/Typography/Heading";

const InviteSuccessModal = create(function InviteSuccessModal() {
  const modal = useModal();

  const handleConfirm = () => {
    modal.resolve();
    modal.hide();
  };

  return (
    <Modal role="alertdialog" isOpen={modal.visible} onExit={modal.remove}>
      <ModalContent>
        <Box direction="column" align="center" justify="center" padding="x-large" fullWidth>
          <Icon src={ConfettiColored} size="xx-large" />
          <TypographyHeading variant="p-t2" margin="x-large 0 small" slot="title">
            Request sent
          </TypographyHeading>
          <Typography variant="p-body2" color="secondary" tag="p" margin="0 0 x-large">
            We have let your admin know about your request.
          </Typography>
          <Button variant="primary" onPress={handleConfirm}>
            Okay
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export const showInviteSuccessModal = () => show(InviteSuccessModal);
