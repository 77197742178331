import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { AwsIntegration } from "types/generated";

import { GET_EXTERNAL_ID_FOR_ENTITY } from "./gql";

const useGetCloudIntegrationExternalId = () => {
  const { onError } = useTypedContext(FlashContext);

  const [fetchExternalId, { data, loading }] = useLazyQuery<{
    awsIntegration: AwsIntegration;
  }>(GET_EXTERNAL_ID_FOR_ENTITY, {
    onError,
  });

  const externalId = data?.awsIntegration.externalIdForStack?.externalId;
  const assumeRolePolicyStatement =
    data?.awsIntegration.externalIdForStack?.assumeRolePolicyStatement;

  const parsedAssumeRolePolicyStatement = useMemo(() => {
    try {
      if (assumeRolePolicyStatement) {
        return JSON.stringify(JSON.parse(assumeRolePolicyStatement), null, 2);
      }
    } catch (error) {
      onError(error);
    }

    return undefined;
  }, [onError, assumeRolePolicyStatement]);

  return {
    fetchExternalId,
    loading,
    externalId,
    parsedAssumeRolePolicyStatement,
  };
};

export default useGetCloudIntegrationExternalId;
