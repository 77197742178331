import { ReactNode } from "react";

import { InfoCircle } from "components/icons/generated";
import ListContentWrapper from "components/ListContentWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ButtonIcon from "ds/components/ButtonIcon";
import Tooltip from "ds/components/Tooltip";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTypedContext from "hooks/useTypedContext";

import { SettingsContext } from "../Context";
import SeatLimitsNotification from "../SeatLimitsNotification";
import { SEAT_LIMITS_TOOLTIP } from "../SeatLimitsNotification/constants";
import { SeatLimitsNotificationEntity } from "../SeatLimitsNotification/types";
import useSeatLimit from "../SeatLimitsNotification/useSeats";
import { UserManagementActivationStatus } from "../types";
import UserManagementActivationBadge from "../UserManagementActivationBadge";
import UserManagementWarningCallout from "../UserManagementWarningCallout";

type UsersPageLayoutProps = {
  children: ReactNode;
  openInviteDrawer?: () => void;
  openInfoDrawer: () => void;
};

const UsersPageLayout = ({ children, openInviteDrawer, openInfoDrawer }: UsersPageLayoutProps) => {
  const { seats } = useTypedContext(SettingsContext);
  const { isLimitReached } = useSeatLimit(seats.user);
  const { activationStatus } = useTypedContext(SettingsContext);

  return (
    <>
      {activationStatus === UserManagementActivationStatus.ACTIVE && (
        <SeatLimitsNotification
          entity={SeatLimitsNotificationEntity.User}
          seats={seats.user}
          type="callout"
        />
      )}
      <UserManagementWarningCallout analyticsPage={AnalyticsPageOrganization.OrganizationUsers} />
      <ViewHeader firstLevel>
        <Box direction="row" align="center" __deprecatedGap="1.1rem">
          <ViewHeaderTitle tag="h2">Users</ViewHeaderTitle>
          <ButtonIcon onPress={openInfoDrawer} icon={InfoCircle} variant="ghost">
            Open info drawer
          </ButtonIcon>
          <UserManagementActivationBadge />
        </Box>

        <ViewHeaderWrapper direction="row">
          {openInviteDrawer && (
            <Tooltip
              active={isLimitReached}
              on={(props) => (
                <Button
                  onPress={openInviteDrawer}
                  variant="primary"
                  disabled={isLimitReached}
                  {...props}
                >
                  Invite user
                </Button>
              )}
            >
              {SEAT_LIMITS_TOOLTIP}
            </Tooltip>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>
      <ListContentWrapper>{children}</ListContentWrapper>
    </>
  );
};

export default UsersPageLayout;
