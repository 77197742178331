import { gql, TypedDocumentNode } from "@apollo/client";

import { ManagedUser, QueryManagedUserArgs } from "types/generated";

import { IdpGroup } from "./types";

type GetUserGroupsData = {
  managedUser: Pick<ManagedUser, "latestGroups" | "id">;
  managedUserGroups: IdpGroup[];
};

export const GET_USER_GROUPS: TypedDocumentNode<GetUserGroupsData, QueryManagedUserArgs> = gql`
  query GetUserGroups($id: ID!) {
    managedUser(id: $id) {
      id
      latestGroups
    }
    managedUserGroups {
      id
      identityProvider
      groupName
      status
      spaceCount
    }
  }
`;
