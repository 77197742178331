import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageIntegration } from "hooks/useAnalytics/pages/integrations";

export const INTEGRATION_USED_BY_COLUMNS_STORAGE_KEY = "integrationUsedByColumnsConfig";

export enum INTEGRATION_USED_BY_COLUMN_ID {
  NAME = "NAME",
  SPACE = "SPACE",
}

export const ROW_HEADER_ID = INTEGRATION_USED_BY_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 600;

export const INTEGRATION_USED_BY_COLUMN_CONFIG: ColumnConfig = {
  [INTEGRATION_USED_BY_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    defaultWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
  [INTEGRATION_USED_BY_COLUMN_ID.SPACE]: {
    title: "Space",
    minWidth: 112,
    defaultWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageIntegration.IntegrationUsedBy,
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(INTEGRATION_USED_BY_COLUMN_CONFIG) as INTEGRATION_USED_BY_COLUMN_ID[],
  hidden: [],
};
