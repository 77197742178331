import { NetworkStatus } from "@apollo/client";
import { useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

import usePolledQuery from "apollo/usePolledQuery";
import { getSortOptionFromURI } from "components/Filters/helpers";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import useURLParams from "hooks/useURLParams";
import { Worker, WorkerPool } from "types/generated";
import { uniqByKey } from "utils/uniq";
import { PollingIntervalGroups } from "apollo/constants";

import { INITIAL_SORT_DIRECTION, INITIAL_SORT_OPTION, ITEMS_LIMIT } from "./constants";
import { SEARCH_PRIVATE_WORKER_POOL_WORKERS } from "./gql";

const useSearchWorkers = (workerPoolId?: string) => {
  const { state: sharedPrivateWorkerPoolState }: { state: WorkerPool | undefined } =
    useLocation(); /* <WorkerPool | undefined>TODO: use generic once react-router-dom is updated to v6 */

  const { onError } = useTypedContext(FlashContext);
  const cachedEdges = useRef<Worker[]>([]);

  const urlParams = useURLParams();

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const { error, loading, data, fetchMore, refetch, previousData, networkStatus } = usePolledQuery<{
    workerPool: WorkerPool;
  }>(SEARCH_PRIVATE_WORKER_POOL_WORKERS, {
    variables: {
      workerPoolId,
      input: {
        predicates: null,
        fullTextSearch: null,
        first: ITEMS_LIMIT,
        after: null,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    onError,
    pollingGroup: PollingIntervalGroups.Lists,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [workerPool, rawSearchWorkers] = useMemo(() => {
    if (data?.workerPool) {
      const { searchWorkers, ...workerPool } = data.workerPool;

      // FYI: data.workerPool could contain the huge qty of searchWorkers fields
      // so we need to separate them from the workerPool object that stays to has WorkerPool type
      // TODO: implement dynamic types base on a query
      return [workerPool as WorkerPool, searchWorkers];
    }

    return [undefined, undefined];
  }, [data?.workerPool]);

  const memoizedEntities = useMemo(() => {
    const sourceEdges = rawSearchWorkers?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedEdges.current : sourceEdges;

    if (!loading) {
      cachedEdges.current = sourceEdges;
    }

    return edges;
  }, [rawSearchWorkers?.edges, loading]);

  const loadMoreItems = async () => {
    try {
      if (rawSearchWorkers?.pageInfo.endCursor && rawSearchWorkers?.pageInfo.hasNextPage) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              fetchMoreResult?.workerPool.searchWorkers &&
              fetchMoreResult.workerPool.searchWorkers.edges.length > 0
            ) {
              return {
                workerPool: {
                  ...prev.workerPool,
                  ...fetchMoreResult.workerPool,
                  searchWorkers: {
                    ...fetchMoreResult.workerPool.searchWorkers,
                    edges: uniqByKey(
                      [
                        ...(prev.workerPool.searchWorkers?.edges || []),
                        ...fetchMoreResult.workerPool.searchWorkers.edges,
                      ],
                      "cursor"
                    ),
                  },
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: rawSearchWorkers.pageInfo.endCursor,
              fullTextSearch: null,
              predicates: null,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (error) {
      onError(error);
    }
  };

  const workerPoolResult = workerPool || previousData?.workerPool || sharedPrivateWorkerPoolState;

  return {
    workerPool: workerPoolResult,
    entities: memoizedEntities,
    error,
    loading,
    isPageEmpty: data && !memoizedEntities.length,
    loadMoreItems,
    refetch: handleRefetch,
    isPageLoading: loading && !workerPoolResult && networkStatus === NetworkStatus.loading,
  };
};

export default useSearchWorkers;
