import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SortDescriptor } from "react-aria-components";

import { getSearchQuery } from "components/SearchInput/helpers";

import { sortIntegrations } from "./sort";
import { CloudIntegration } from "../../types";

export const useFilteredIntegrations = (
  integrations: CloudIntegration[],
  sortDescriptor: SortDescriptor
) => {
  const [searchParams] = useSearchParams();
  const searchInput = getSearchQuery(searchParams);

  return useMemo(() => {
    let filteredIntegrations = [...integrations];
    // Apply search filter if exists
    filteredIntegrations = filteredIntegrations.filter((integration) =>
      integration.name.toLowerCase().includes(searchInput.toLowerCase())
    );

    // Apply sorting if descriptor exists
    if (sortDescriptor.column) {
      filteredIntegrations = sortIntegrations(filteredIntegrations, sortDescriptor);
    }

    return filteredIntegrations;
  }, [integrations, searchInput, sortDescriptor]);
};
