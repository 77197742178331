import { ReactNode } from "react";
import cx from "classnames";

type SubSidebarProps = {
  children: ReactNode;
  className?: string;
};

import useSkipFocusButtons from "ds/components/SkipFocusButton/useSkipFocusButtons";

import styles from "./styles.module.css";

const SubSidebar = ({ children, className }: SubSidebarProps) => {
  const [skipButton, backButton] = useSkipFocusButtons();
  return (
    <div className={cx(styles.wrapper, className)}>
      {skipButton({ label: "Skip subsidebar" })}
      {children}
      {backButton({ label: "Back to subsidebar", className: styles.backToNavigationButton })}
    </div>
  );
};

export default SubSidebar;
