import { memo } from "react";

import { RenderResourceRowCellProps } from ".";
import IacManagementTableVendorCell from "../components/VendorCell";

type IacManagementTableResourceRowCellVendorProps = Pick<RenderResourceRowCellProps, "row">;

const IacManagementTableResourceRowCellVendor = memo(
  ({ row }: IacManagementTableResourceRowCellVendorProps) => {
    return <IacManagementTableVendorCell stackVendor={row.stackVendor} />;
  }
);

IacManagementTableResourceRowCellVendor.displayName = "IacManagementTableResourceRowCellVendor";

export default IacManagementTableResourceRowCellVendor;
