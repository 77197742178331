import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";
import { Role } from "types/generated";

import { ROLES_COLUMN_ID } from "../constants";
import RolesListItemDescriptionCell from "./DescriptionCell";
import RolesListItemActionsDropdownCell from "./DropdownActionsCell";
import RolesListItemNameCell from "./NameCell";

const CELLS = {
  [ROLES_COLUMN_ID.DESCRIPTION]: RolesListItemDescriptionCell,
  [ROLES_COLUMN_ID.NAME]: RolesListItemNameCell,
  [ROLES_COLUMN_ID.DROPDOWN]: RolesListItemActionsDropdownCell,
};

type RolesListItemProps = {
  role: Role;
};

const RolesListItem = (props: RolesListItemProps) => {
  const { role } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as ROLES_COLUMN_ID];
        const uniqueKey = `${id}-${role.id}`;

        const childrenComponent = <Component role={role} />;

        return (
          <TableCell key={uniqueKey} id={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(RolesListItem);
