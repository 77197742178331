import React from "react";

import { Run, VcsProvider } from "types/generated";
import { Commit } from "components/icons/generated";
import Counter from "ds/components/Counter";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import MetaInfoListItem from "components/MetaInfoList/Item";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import Box from "ds/components/Box";
import { useToggle } from "hooks/useToggle";
import { getCommmitDigitsCount } from "utils/commit";
import Typography from "ds/components/Typography";
import TimestampMetaInfo from "components/Timestamp/MetaInfo";

import styles from "./styles.module.css";
import CommitAuthorLink from "./CommitAuthorLink";

type RunsGroupProps = {
  run: Run;
  provider: VcsProvider;
  count: number;
  isOpen?: boolean;
  children?: React.ReactNode;
  someRunsShouldBeApproved: boolean;
};

const RunsGroup = (props: RunsGroupProps) => {
  const { run, provider, children, count, isOpen = false, someRunsShouldBeApproved } = props;
  const [isCollapsed, toggle] = useToggle(!isOpen);

  const { stack } = useTypedContext(StackContext);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={toggle} withTransition>
      <CollapsiblePanelHeader
        padding="large"
        gap="medium"
        grid
        gridTemplate="auto 1fr"
        align="center"
        gridAreas={`"indicator commit" ". meta"`}
      >
        <Box gridArea="indicator">
          <CollapsiblePanelToggleTrigger ariaLevel={2} ariaLabel={run.title} />
        </Box>

        <Box justify="between">
          <Box align="center" gridArea="commit">
            <CollapsiblePanelTitle>{run.title}</CollapsiblePanelTitle>
            <Box padding="0 medium">
              <Counter count={count} />
            </Box>
          </Box>
          {someRunsShouldBeApproved && (
            <Typography tag="span" variant="p-body2" color="warning">
              Needs approval
            </Typography>
          )}
        </Box>

        <Box gridArea="meta" gap="large">
          <TimestampMetaInfo
            prefix="Committed"
            timestamp={run.commit.timestamp}
            postfix={
              <>
                by&nbsp;
                <CommitAuthorLink
                  login={run.commit.authorLogin || undefined}
                  name={run.commit.authorName}
                  provider={provider}
                />
              </>
            }
          />
          <MetaInfoListItem
            href={run.commit.url}
            linkText={run.commit.hash.slice(0, getCommmitDigitsCount(provider))}
            icon={Commit}
            target="_blank"
            tooltip={stack.trackedCommit?.hash === run.commit.hash ? "Tracked commit" : null}
          />
        </Box>
      </CollapsiblePanelHeader>

      <CollapsiblePanelContent>
        <div className={styles.wrapper}>{children}</div>
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default RunsGroup;
