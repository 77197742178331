import { ReactNode, useCallback, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import Box, { BoxProps } from "ds/components/Box";
import PieChart from "ds/components/Charts/PieChart";
import { Datum } from "ds/components/Charts/PieChart/types";
import { IconComponent } from "types/Icon";
import Tooltip from "ds/components/Tooltip";
import TileWrapperButton from "ds/components/Tile/Wrapper/Button";
import useContainerSize from "hooks/useContainerSize";
import { TileContext } from "ds/components/Tile/Context";

import { getPercentages } from "./getPercentages";
import styles from "./styles.module.css";
import DashboardResourcesChartCentralInfo from "./CentralInfo";
import DashboardResourcesChartTileContent from "./TileContent";
import DashboardResourcesChartSkeletonChartTile from "./SkeletonChartTile";
import DashboardResourcesChartEmptyNoResources from "./EmptyNoResources";
import DashboardResourcesChartEmptyNoStacks from "./EmptyNoStacks";
import DashboardResourcesChartEmptyNoDrift from "./EmptyNoDift";
import { Widget } from "../types";

const CONTAINER_SIZE_LIMIT_FOR_EXTENDED_VIEW = 600;

type DashboardResourcesChartProps = {
  data: Datum[];
  logos: Record<
    string,
    {
      logo: IconComponent;
      color: string;
      activeColor: string;
      inactiveColor: string;
    }
  >;
  hasNoResources?: boolean;
  hasNoStacks?: boolean;
  hasDriftedDisabled?: boolean;
  align?: BoxProps["align"];
  renderCentralInfo?: (value?: Datum, hasSmallContainer?: boolean) => JSX.Element;
  isLoading?: boolean;
  navigateToResource: (value: Datum) => void;
  widget?: Widget;
  errorBanner?: ReactNode;
};

const emptyContext = { titleId: "", descriptionId: "" };

const DashboardResourcesChart = ({
  data,
  hasNoResources,
  hasNoStacks,
  hasDriftedDisabled,
  logos,
  align,
  isLoading,
  renderCentralInfo,
  navigateToResource,
  widget,
  errorBanner,
}: DashboardResourcesChartProps) => {
  const [hoveredId, setHoveredId] = useState<string>();

  const hoveredOnTile = useRef(false);

  const handleArcMouseEnter = useCallback((data: Datum) => {
    setHoveredId(data.id);
  }, []);

  const handleLegendItemMouseEnter = useCallback((id: string) => {
    hoveredOnTile.current = true;
    setHoveredId(id);
  }, []);

  const handleArcMouseLeave = useCallback(() => {
    if (!hoveredOnTile.current) {
      setHoveredId(undefined);
    }
  }, []);

  const handleLegendItemMouseLeave = useCallback(() => {
    setHoveredId(undefined);
    hoveredOnTile.current = false;
  }, []);

  const total = data.reduce((acc, next) => acc + next.value, 0);
  const indexToHighLight = data.findIndex(({ id }) => id === hoveredId);
  const percentages = getPercentages(data, total);

  const hasNoData = !!errorBanner || hasNoResources || hasNoStacks || hasDriftedDisabled;

  const getEmptyComponentForCompactView = () => {
    if (errorBanner) {
      return errorBanner;
    }

    if (hasNoStacks) {
      return <DashboardResourcesChartEmptyNoStacks widget={widget} />;
    }

    if (hasNoResources) {
      return <DashboardResourcesChartEmptyNoResources />;
    }

    return null;
  };

  const getEmptyComponentForExtendedView = () => {
    if (hasNoStacks || hasNoResources || !!errorBanner) {
      return getEmptyComponentForCompactView();
    }

    if (hasDriftedDisabled) {
      return <DashboardResourcesChartEmptyNoDrift />;
    }

    return null;
  };

  const { containerRef, width } = useContainerSize();

  const compactEmptyView =
    width <= CONTAINER_SIZE_LIMIT_FOR_EXTENDED_VIEW &&
    (hasNoStacks || hasNoResources || !!errorBanner) &&
    !isLoading;

  return (
    <Box fullWidth ref={containerRef}>
      {compactEmptyView ? (
        getEmptyComponentForCompactView()
      ) : (
        <Box className={styles.wrapper} align={align} justify="center" padding="x-large" fullWidth>
          <Box className={styles.chart}>
            {isLoading ? (
              <Skeleton count={1} height={299} width={299} circle />
            ) : (
              <PieChart
                legendEnabled={width <= CONTAINER_SIZE_LIMIT_FOR_EXTENDED_VIEW}
                data={data}
                renderTooltip={(data: Datum) => (
                  <TileContext.Provider value={emptyContext}>
                    <DashboardResourcesChartTileContent
                      logos={logos}
                      data={data}
                      percentage={percentages[data.id]}
                    />
                  </TileContext.Provider>
                )}
                centralInfo={
                  renderCentralInfo ? (
                    renderCentralInfo(
                      data[indexToHighLight],
                      width <= CONTAINER_SIZE_LIMIT_FOR_EXTENDED_VIEW
                    )
                  ) : (
                    <DashboardResourcesChartCentralInfo count={total} />
                  )
                }
                forcedHoveredId={hoveredId}
                showTooltipCallback={handleArcMouseEnter}
                hideTooltipCallback={handleArcMouseLeave}
                onLegendItemMouseEnter={handleLegendItemMouseEnter}
                onLegendItemMouseLeave={handleLegendItemMouseLeave}
                tooltipReactToScroll
                disableTooltip={width > CONTAINER_SIZE_LIMIT_FOR_EXTENDED_VIEW}
                onLegendClick={navigateToResource}
              />
            )}
          </Box>
          {width > CONTAINER_SIZE_LIMIT_FOR_EXTENDED_VIEW && (
            <Box direction="column" gap="medium" grow="1" className={styles.legendContainer}>
              {isLoading && <DashboardResourcesChartSkeletonChartTile />}

              {!isLoading && hasNoData && getEmptyComponentForExtendedView()}

              {!isLoading &&
                !hasNoData &&
                data.map((item) => (
                  <Tooltip
                    key={item.id}
                    on={(props) => (
                      <div {...props}>
                        <TileWrapperButton
                          onClick={() => navigateToResource(item)}
                          highlighted={hoveredId === item.id}
                          onMouseEnter={() => handleLegendItemMouseEnter(item.id)}
                          onMouseLeave={handleLegendItemMouseLeave}
                        >
                          <DashboardResourcesChartTileContent
                            hovered={hoveredId === item.id}
                            logos={logos}
                            data={item}
                            percentage={percentages[item.id]}
                          />
                        </TileWrapperButton>
                      </div>
                    )}
                  >
                    Go to {item.label}
                  </Tooltip>
                ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DashboardResourcesChart;
