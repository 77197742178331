import { useCallback } from "react";

import useErrorHandle from "hooks/useErrorHandle";
import WorkerPoolQueuedRunsView from "views/Account/WorkerPool/QueuedRunsView";
import useTitle from "hooks/useTitle";
import { PUBLIC_WORKER_POOL_NAME } from "views/Account/WorkerPool/constants";

import useSearchPublicQueuedRuns from "./useSearchPublicQueuedRuns";
import useSearchPublicQueuedRunsSuggestions from "./useSearchPublicQueuedRunsSuggestions";

const PublicWorkerPoolQueuedRuns = () => {
  const { entities, error, isPageEmpty, hasNoFilteringResults, loading, refetch } =
    useSearchPublicQueuedRuns();

  const {
    filters,
    filtersMap,
    sortOptions,
    loading: filtersLoading,
    refetchActiveSections,
    filteredCount,
  } = useSearchPublicQueuedRunsSuggestions();

  const onBulkActionsFinish = useCallback(async () => refetch(), [refetch]);

  useTitle(`Queue · ${PUBLIC_WORKER_POOL_NAME}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  return (
    <WorkerPoolQueuedRunsView
      isPublic
      entities={entities}
      renderedEntitiesCount={entities.length}
      hasNoFilteringResults={hasNoFilteringResults}
      isPageEmpty={isPageEmpty}
      onBulkActionsFinish={onBulkActionsFinish}
      loadingIndication={loading}
      filters={filters}
      filtersMap={filtersMap}
      sortOptions={sortOptions}
      filtersLoading={filtersLoading}
      refetchActiveSections={refetchActiveSections}
      filteredCount={filteredCount}
    />
  );
};

export default PublicWorkerPoolQueuedRuns;
