import EmptyState from "ds/components/EmptyState";
import { getDocsUrl } from "utils/getDocsUrl";
import { CloudArrows } from "components/icons/generated";
import Box from "ds/components/Box";
import DocumentationButton from "components/DocumentationButton";

import CloudIntegrationsSetupDropdownButton from "./SetupDropdownButton";

const CloudIntegrationsEmpty = () => {
  return (
    <EmptyState
      title="You don't have any integrations yet."
      caption="Cloud integrations allow Spacelift to manage your resources without the need for long-lived static credentials."
      icon={CloudArrows}
    >
      <Box gap="medium">
        <CloudIntegrationsSetupDropdownButton />
        <DocumentationButton
          to={getDocsUrl("/integrations/cloud-providers")}
          label="Documentation"
        />
      </Box>
    </EmptyState>
  );
};

export default CloudIntegrationsEmpty;
