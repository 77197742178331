import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ErrorCircleColored } from "components/icons/generated";
import SystemMessage from "components/SystemMessage";
import SystemMessagePageWrapper from "components/SystemMessage/PageWrapper";
import LinkButton from "ds/components/Button/LinkButton";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

const NoAccessPage = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    localStorage.setItem("redirectTo", `${pathname}${search}`);
  }, [pathname, search]);

  return (
    <SystemMessagePageWrapper direction="column">
      <Callout variant="info" className={styles.calloutPosition}>
        A login policy for this account may have been created, updated, or deleted, or a user
        management setting might have changed, causing all active sessions to be invalidated. In
        this case, you can try to login again. You can find more information about login policies
        and user management settings in the documentation.
        <FeedbackActions>
          <LinkButton to="/login" variant="contrast" size="small">
            Login again
          </LinkButton>
          <LinkButton
            href={getDocsUrl("/concepts/policy/login-policy")}
            rel="noopener noreferrer"
            variant="secondary"
            size="small"
            target="_blank"
          >
            Documentation
          </LinkButton>
        </FeedbackActions>
      </Callout>

      <SystemMessage
        withPageWrapper={false}
        title="Access denied"
        message={
          <>
            Sorry, it looks like you don't have permission to see this page. Please reach out to one
            of your friendly account admins to grant you access.
          </>
        }
        icon={ErrorCircleColored}
        className={styles.systemMessagePosition}
      >
        <LinkButton variant="primary" size="medium" to="/">
          Go to Homepage
        </LinkButton>
      </SystemMessage>
    </SystemMessagePageWrapper>
  );
};

export default NoAccessPage;
