import { useMemo } from "react";

import usePolledQuery from "apollo/usePolledQuery";
import { useCachedFilterFields } from "components/Filters/hooks";
import { SortOption } from "components/Filters/types";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { SearchSuggestionsOutput } from "types/generated";
import { PollingIntervalGroups } from "apollo/constants";

import { SEARCH_WORKER_POOLS_SUGGESTIONS } from "../gql";

export const useSearchWorkerPoolsSuggestions = () => {
  const { onError } = useTypedContext(FlashContext);

  const { data: filteringData } = usePolledQuery<{
    searchWorkerPoolsSuggestions: SearchSuggestionsOutput;
  }>(SEARCH_WORKER_POOLS_SUGGESTIONS, {
    variables: {
      input: {
        fullTextSearch: null,
        predicates: null,
        fields: null,
      },
    },
    onError,
    pollingGroup: PollingIntervalGroups.Lists,
  });

  const cachedFiltersData = useCachedFilterFields(
    filteringData?.searchWorkerPoolsSuggestions?.fields
  );

  const sortOptions = useMemo((): SortOption[] => {
    return cachedFiltersData
      .filter((field) => field.orderable)
      .map((field) => {
        return {
          value: field.name,
          label: field.name,
        };
      });
  }, [cachedFiltersData]);

  return {
    sortOptions,
  };
};
