import cx from "classnames";

import Box from "ds/components/Box";
import Markdown from "components/markdown/Markdown";

import styles from "./styles.module.css";

type MessageProps = {
  text: string;
  type: "deny" | "warn";
};

function Message({ text, type }: MessageProps) {
  return (
    <Box className={styles.item} gap="large" fullWidth align="center">
      <span className={cx(styles.type, styles[type])} />
      <Markdown markup={text} />
    </Box>
  );
}

export default Message;
