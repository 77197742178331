import { ReactNode } from "react";

import LinkButton from "ds/components/Button/LinkButton";
import Feedback from "ds/components/Feedback";
import FeedbackActions from "ds/components/Feedback/Actions";
import { FeedbackType } from "ds/components/Feedback/types";
import useTypedContext from "hooks/useTypedContext";
import { pluralize } from "shared/Pluralize";
import { BillingTier, SeatLimit } from "types/generated";
import { SubscriptionContext } from "views/Account/SubscriptionWrapper";
import { AccountContext } from "views/AccountWrapper";

import { SeatLimitsNotificationEntity } from "./types";
import useSeatLimit from "./useSeats";

type SeatLimitsNotificationProps = {
  type: FeedbackType;
  entity: SeatLimitsNotificationEntity;
  seats: SeatLimit;
};

const SeatLimitsNotification = ({ type, entity, seats }: SeatLimitsNotificationProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { tier } = useTypedContext(SubscriptionContext);

  const { limit, inUse, isUnlimited, isOneSeatLeft, isLimitReached } = useSeatLimit(seats);

  if (isUnlimited || inUse === 0) {
    return null;
  }

  const isFreeTier = tier === BillingTier.V4Free;
  const isStarterTier = tier === BillingTier.V4Starter;

  let feedbackTitle = "";
  let feedbackDescription: ReactNode = "";

  if (isOneSeatLeft) {
    feedbackDescription = (
      <>
        You're using{" "}
        <strong>
          {inUse} of {limit}
        </strong>{" "}
        available seats.
      </>
    );
  }

  if (isLimitReached) {
    feedbackTitle = "Upgrade to unlock more seats";
    feedbackDescription = (
      <>
        Your account has hit the limit of <strong>{limit}</strong>{" "}
        {isFreeTier ? pluralize(entity, limit) : pluralize("seat", limit)}.
      </>
    );
  }

  if (!feedbackDescription) {
    return null;
  }

  return (
    <Feedback type={type} variant="promo" title={feedbackTitle}>
      {feedbackDescription} To collaborate effectively with your team, consider{" "}
      {isStarterTier ? (
        <>
          updating subscription with <strong>Unlimited users add on</strong> or{" "}
          <strong>upgrading to Starter+</strong>.
        </>
      ) : (
        "upgrading to a paid plan."
      )}
      {viewer.admin && (
        <FeedbackActions>
          <LinkButton variant="contrast" size="small" to="/settings/billing">
            Upgrade
          </LinkButton>
          {isStarterTier && (
            <LinkButton variant="secondary" size="small" to="/settings/billing/subscription">
              Manage subscription
            </LinkButton>
          )}
        </FeedbackActions>
      )}
    </Feedback>
  );
};

export default SeatLimitsNotification;
