import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import Button from "ds/components/Button";
import ComboBox from "ds/components/ComboBox";
import ComboBoxDefaultItem from "ds/components/ComboBox/DefaultItem";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import SelectNew from "ds/components/Select/New";
import useTypedContext from "hooks/useTypedContext";
import { getDrawerFormFix } from "utils/css";

import { ATTACH_BLUEPRINT_INTEGRATION } from "./gql";
import useGetAttachableIntegrations from "./useAttachableIntegrations";

type IntegrationDrawerFormProps = {
  blueprintId: string;
  spaceId: string;
};

type FormValues = {
  externalIntegration: string;
  type: string;
};

const IntegrationDrawerForm = createDrawer(
  ({ blueprintId, spaceId }: IntegrationDrawerFormProps) => {
    const drawer = useModal();

    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const builderForm = useForm<FormValues>({
      defaultValues: {
        externalIntegration: "",
        type: "serviceNow",
      },
      mode: "onChange",
    });

    const {
      control,
      handleSubmit,
      formState: { isDirty },
    } = builderForm;

    const { integrations, loading } = useGetAttachableIntegrations({
      spaceId,
      attachedIntegrationsIds: [],
    });

    const [createIntegration, { loading: creatingIntegration }] = useMutation(
      ATTACH_BLUEPRINT_INTEGRATION,
      {
        refetchQueries: ["GetBlueprintIntegrations"],
      }
    );

    const handleCloseDrawer = () => {
      drawer.hide();
    };

    const onCreateSubmit = (formData: FormValues) => {
      createIntegration({
        variables: {
          id: blueprintId,
          input: {
            externalIntegration: formData.externalIntegration,
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintAttachToIntegration?.id) {
            reportSuccess({
              message: "Integration was successfully attached",
            });
            handleCloseDrawer();
          }
        })
        .catch(onError);
    };

    const onSubmit = (formData: FormValues) => {
      onCreateSubmit(formData);
    };

    return (
      <DrawerForm isDirty={isDirty}>
        <FormProvider {...builderForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Attach integration" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight gap="x-large">
              <Controller
                name="type"
                rules={{
                  required: "Integration type is required",
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <SelectNew
                      error={fieldState.error?.message}
                      label="Integration type"
                      isDisabled
                      value={field.value}
                      items={[{ value: "serviceNow", label: "ServiceNow" }]}
                      placeholder="Select integration type"
                      onChange={field.onChange}
                    />
                  </>
                )}
              />

              <Controller
                name="externalIntegration"
                control={control}
                rules={{ required: "Integration is required." }}
                render={({ field, fieldState }) => (
                  <ComboBox
                    label="Select integration"
                    error={fieldState.error?.message}
                    items={integrations}
                    value={field.value}
                    isLoading={loading}
                    onChange={field.onChange}
                  >
                    {(item) => <ComboBoxDefaultItem id={item.value} label={item.label} />}
                  </ComboBox>
                )}
              />
            </DrawerBody>
            <DrawerFooter>
              <DrawerFooterActions>
                <DrawerCancelButton />
                <Button
                  variant="primary"
                  type="submit"
                  disabled={creatingIntegration}
                  loading={creatingIntegration}
                >
                  Attach
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showIntegrationDrawerForm = createDrawerTrigger(IntegrationDrawerForm);
