import { NetworkStatus, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import { Lock, Space } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ViewHeader from "components/ViewHeader";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { hasSpaceManageAccess } from "utils/user";

import { FILTERS_ORDER_SETTINGS_KEY } from "../Policies/List/constants";
import IntegrationActionDropdown from "./ActionDropdown";
import { showIntegrationDetailsDrawer } from "./Drawer";
import { GET_EXTERNAL_INTEGRATION } from "./gql";
import { ExternalIntegrationQueryResponse } from "./types";
import IntegrationUsedBy from "./UsedBy";
import useIntegrationUsedByAnalytics from "./UsedBy/useIntegrationUsedByPageAnalytics";

const Integration = () => {
  const { onError } = useTypedContext(FlashContext);
  const params = useParams<{ integrationId?: string }>();

  const trackAnalytics = useIntegrationUsedByAnalytics();

  const { integrationId } = params;

  const { loading, data, networkStatus, error } = useQuery<ExternalIntegrationQueryResponse>(
    GET_EXTERNAL_INTEGRATION,
    {
      onError,
      variables: {
        integrationId,
      },
    }
  );

  const openDetailsDrawer = () => {
    if (data?.externalIntegration) {
      showIntegrationDetailsDrawer({
        integration: data.externalIntegration,
        id: data.externalIntegration.id,
      });
    }
  };

  useBreadcrumbs(
    [
      {
        title: "Integrations",
        link: getBreadcrumbsBackUrl("/integrations", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: data?.externalIntegration?.name || "",
      },
    ],
    [data?.externalIntegration?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && networkStatus !== NetworkStatus.refetch && !data?.externalIntegration) {
    return <PageLoading />;
  }

  if (!integrationId || !data?.externalIntegration) {
    return <NotFoundPage />;
  }

  const canManageIntegration = hasSpaceManageAccess(
    data.externalIntegration.spaceDetails.accessLevel
  );

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{data.externalIntegration.name}</ViewHeaderTitle>
          </ViewHeaderWrapper>
          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <Button variant="secondary" onPress={openDetailsDrawer}>
              Details
            </Button>
            <IntegrationActionDropdown
              size="medium"
              integration={data.externalIntegration}
              refetchQueriesOnDelete={["GetExternalIntegrations"]}
              canManageIntegration={canManageIntegration}
              trackAnalytics={trackAnalytics}
              showDetails={false}
            />
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={Space}
              linkText={data.externalIntegration.spaceDetails.name}
              href={`/spaces/${data.externalIntegration.spaceDetails.id}`}
            />
            <MetaInfoListItem icon={Lock} tooltip="Authentication type">
              Basic
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
      </ViewHeader>
      <IntegrationUsedBy
        name={data.externalIntegration.name}
        attachedBluePrints={data.externalIntegration.attachedBlueprints}
        loading={loading}
      />
    </>
  );
};

export default Integration;
