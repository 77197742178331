import { CSSProperties, useCallback, useRef } from "react";
import { Cell, Row } from "react-aria-components";
import { useParams } from "react-router-dom";

import TableContextProvider from "components/Table/Context";
import Table from "components/Table";
import TableRow from "components/Table/Row";
import useTableSort from "components/Table/useTableSort";
import PageInfo from "components/PageWrapper/Info";
import SearchInput from "components/SearchInput";
import useTitle from "hooks/useTitle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import NotFoundPage from "components/error/NotFoundPage";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import TableWrapper from "components/Table/Wrapper";
import useErrorHandle from "hooks/useErrorHandle";

import IdpGroupListItem from "./ListItem";
import {
  ANALYTICS,
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_COLUMNS_VIEW_CONFIG,
  initialSortDirection,
  initialSortOption,
  USER_IDP_GROUPS_COLUMN_CONFIG,
  USER_IDP_GROUPS_COLUMNS_STORAGE_KEY,
  ROW_HEADER_ID,
  SORT_CONFIG,
} from "./constants";
import useGetUserGroups from "./useGetUserGroups";
import { IdpGroup } from "./types";
import UserIdpGroupsEmpty from "./Empty";
import { useFilteredEntities } from "./useFilteredEntities";

type UserIdpGroupsProps = {
  userName: string;
};

const UserIdpGroups = ({ userName }: UserIdpGroupsProps) => {
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const { handleSortOptionChange, sortDescriptor } = useTableSort({
    filtersOrderSettingsKey: FILTERS_ORDER_SETTINGS_KEY,
    sortConfig: SORT_CONFIG,
    initialSortOption,
    initialSortDirection,
  });
  const { userId } = useParams<{ userId: string }>();

  const { groups, loading, error, user } = useGetUserGroups({ id: userId });

  const filteredGroups = useFilteredEntities(groups || [], sortDescriptor);

  const isPageEmpty = filteredGroups.length === 0;

  useTitle(`Organization Settings · User · ${userName} · IdP groups`);

  useBreadcrumbs(
    [
      {
        title: "Organization Settings Users",
        link: getBreadcrumbsBackUrl("/settings/users", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: userName,
      },
    ],
    []
  );

  const renderRow = useCallback(
    (
      item: Partial<IdpGroup> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.id}
          >
            <IdpGroupListItem group={item as IdpGroup} />
          </TableRow>
        );
      }
    },
    []
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (!loading && !user) {
    return <NotFoundPage />;
  }

  return (
    <>
      <PageInfo title="IdP groups">
        {!!groups?.length && (
          <SearchInput
            placeholder="Search..."
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />
        )}
      </PageInfo>
      <TableContextProvider
        columnsConfig={USER_IDP_GROUPS_COLUMN_CONFIG}
        initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
        rowHeaderId={ROW_HEADER_ID}
        localStorageId={USER_IDP_GROUPS_COLUMNS_STORAGE_KEY}
        setSortDescriptor={handleSortOptionChange}
        sortDescriptor={sortDescriptor}
        {...ANALYTICS}
      >
        {!!filteredGroups?.length && (
          <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
            <Table
              loadingContent={loading}
              ariaLabel="IdP groups"
              items={filteredGroups}
              ref={virtualizedListContainerRef}
            >
              {renderRow}
            </Table>
          </TableWrapper>
        )}
        {!groups?.length && !loading && <UserIdpGroupsEmpty />}
        {isPageEmpty && !!groups?.length && (
          <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
        )}
      </TableContextProvider>
    </>
  );
};

export default UserIdpGroups;
