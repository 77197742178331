import { Fragment } from "react";

import Box from "ds/components/Box";
import SpaceliftLogo from "ds/components/SpaceliftLogo";

import SystemMessageLinks from "./Links";
import SystemMessagePageWrapper from "./PageWrapper";
import SystemMessageCard, { SystemMessageCardProps } from "./Card";

type SystemMessageProps = Pick<
  SystemMessageCardProps,
  "title" | "message" | "icon" | "children"
> & {
  withPageWrapper?: boolean;
  className?: string;
};

const SystemMessage = ({
  title,
  message,
  icon,
  children,
  withPageWrapper = true,
  className,
}: SystemMessageProps) => {
  const PageWrapper = withPageWrapper ? SystemMessagePageWrapper : Fragment;

  return (
    <PageWrapper>
      <Box
        direction="column"
        gap="xx-large"
        align="center"
        justify="center"
        fullWidth
        className={className}
      >
        <SpaceliftLogo />

        <SystemMessageCard title={title} message={message} icon={icon}>
          {children}
        </SystemMessageCard>

        <SystemMessageLinks />
      </Box>
    </PageWrapper>
  );
};

export default SystemMessage;
