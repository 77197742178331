import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";

import CardWrapper from "components/CardWrapper";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormNameField from "components/ContextConfigForm/NameField";
import ContextConfigFormValueField from "components/ContextConfigForm/ValueField";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";

import { CONTEXT_ANALYTICS_VERSION } from "../constants";
import { ContextAttachedVariable } from "../types";

type ContextVariablesAddFormProps = {
  onSave: (item: ContextAttachedVariable) => void;
};

const ContextVariablesAddForm = ({ onSave }: ContextVariablesAddFormProps) => {
  const builderForm = useForm<ContextAttachedVariable>({
    defaultValues: {
      variableName: "",
      value: "",
      isSecret: true,
      description: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
    watch,
  } = builderForm;

  const variableName = watch("variableName");
  const value = watch("value");

  useObserveForWarning(
    !!(variableName && value),
    <>
      You have not added the <strong>variable</strong>. Do you want to continue without adding it?
    </>
  );

  const onSubmit = useCallback(
    () =>
      handleSubmit((formData: ContextAttachedVariable) => {
        onSave(formData);
        reset();
      })(),
    [onSave, reset, handleSubmit]
  );

  return (
    <CardWrapper variant="filled" direction="column">
      <FormProvider {...builderForm}>
        <Box gap="medium" fullWidth margin="0 0 large">
          <ContextConfigFormNameField name="variableName" />
          <ContextConfigFormValueField name="value" isSecret={watch("isSecret")} />
        </Box>
        <ContextConfigFormIsSecretField name="isSecret" />
        <ContextConfigFormDescriptionField name="description" />
        <Box justify="end" fullWidth margin="large 0 0">
          <Button
            analyticsTitle="Add variable click"
            analyticsPage={AnalyticsPageContext.ContextNew}
            analyticsProps={{
              version: CONTEXT_ANALYTICS_VERSION,
            }}
            variant="contrast"
            type="submit"
            onPress={onSubmit}
            disabled={!isValid}
          >
            Add variable
          </Button>
        </Box>
      </FormProvider>
    </CardWrapper>
  );
};

export default ContextVariablesAddForm;
