import { useCallback } from "react";

import { SearchQueryOrderDirection } from "types/generated";
import { getAriaSort, getSortIcon } from "ds/components/Table/Head/helpers";
import useSortState from "components/Filters/useSortState";
import { SortStateDescriptor } from "components/Filters/types";

const useColumnSort = (columnSortKey: string, filtersStorageKey: string) => {
  const {
    sortState: { sortDirection, sortKey },
    applySortState,
  } = useSortState();

  const isColumnSorted = sortKey === columnSortKey;

  const handleSort = useCallback(
    (callback?: (sortState: SortStateDescriptor) => void) => {
      const newSortState = {
        sortKey: columnSortKey,
        sortDirection:
          sortDirection === SearchQueryOrderDirection.Asc
            ? SearchQueryOrderDirection.Desc
            : SearchQueryOrderDirection.Asc,
      };

      applySortState(newSortState, filtersStorageKey);
      callback?.(newSortState);
    },
    [applySortState, columnSortKey, filtersStorageKey, sortDirection]
  );

  return {
    handleSort,
    icon: getSortIcon(isColumnSorted ? sortDirection : undefined),
    ariaSort: getAriaSort(isColumnSorted ? sortDirection : undefined),
  };
};

export default useColumnSort;
