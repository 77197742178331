import { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import SystemMessage from "components/SystemMessage";
import NotFoundPage from "components/error/NotFoundPage";
import SpaceliftLoader from "components/loading/SpaceliftLoader";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { isSelfHostedDistribution } from "utils/distribution";
import { validateRequiredAccountName } from "utils/formValidators";

import { SelfHostedInstanceContext } from "../InstanceProvider/Context";
import useInstanceProviderGateRules from "../InstanceProvider/useInstanceProviderGateRules";
import { STORAGE_KEY_SELF_HOSTED_IS_ADMIN_USER_LOGGED_IN } from "../constants";

const isSelfHosted = isSelfHostedDistribution();

type SelfHostedCreateAccountFormFields = {
  accountName: string;
};

const SelfHostedCreateAccount = () => {
  useTitle("Create account");

  const navigate = useNavigate();
  const { reportSuccess, reportError } = useTypedContext(FlashContext);
  const { triggerFetchInstanceInfo } = useTypedContext(SelfHostedInstanceContext);

  const [isLoading, setIsLoading] = useState(false);
  const { isInstanceInfoLoading, shouldLogin, isSelfHostedInstalled } =
    useInstanceProviderGateRules();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SelfHostedCreateAccountFormFields>({
    mode: "onChange",
  });

  const handleCreateAccount = (formData: SelfHostedCreateAccountFormFields) => {
    setIsLoading(true);

    const body = new URLSearchParams();
    body.append("account_name", formData.accountName);

    fetch("/create_account", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body,
    })
      .then(async (response) => {
        if (response.ok) {
          triggerFetchInstanceInfo(() => {
            reportSuccess({ message: "Account created successfully" });
            navigate("/launchpad", { replace: true });
          });
        } else {
          const responseMessage = await response.text();

          reportError({
            title: "Account creation",
            message: responseMessage,
          });
        }
      })
      .catch(() => {
        reportError({ title: "Account creation", message: "Something went wrong" });
      })
      .finally(() => {
        sessionStorage.removeItem(STORAGE_KEY_SELF_HOSTED_IS_ADMIN_USER_LOGGED_IN);
        setIsLoading(false);
      });
  };

  if (isInstanceInfoLoading) {
    return <SpaceliftLoader />;
  }

  if (!isSelfHostedInstalled && shouldLogin) {
    return <Navigate to="/admin-login" replace />;
  }

  if (isSelfHostedInstalled || !isSelfHosted) {
    return <NotFoundPage />;
  }

  return (
    <SystemMessage
      title="Create a Spacelift account"
      message="Define your account name which is used for certain things like creating external IDs as part of role assumption."
    >
      <form onSubmit={handleSubmit(handleCreateAccount)}>
        <Box direction="column" gap="xx-large">
          <FormField label="Account name" error={errors?.accountName?.message} noMargin>
            {({ ariaInputProps }) => (
              <Input
                error={!!errors?.accountName}
                {...register("accountName", {
                  validate: validateRequiredAccountName({
                    requiredStringError: "Account name field is required",
                  }),
                  setValueAs: (value) => value.trim(),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <Button
            type="submit"
            variant="primary"
            loading={isLoading}
            disabled={isLoading}
            fullWidth
          >
            Create & Explore Spacelift
          </Button>
        </Box>
      </form>
    </SystemMessage>
  );
};

export default SelfHostedCreateAccount;
