import { useModal, create, show } from "@ebay/nice-modal-react";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

type VersionDeleteConfirmationProps = {
  version: string;
};

const VersionDeleteConfirmation = create(function VersionDeleteConfirmation({
  version,
}: VersionDeleteConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  // TODO: mutation to delete blueprint version

  const handleDeleteBlueprintVersion = () => {
    // TODO: delete version
    Promise.resolve()
      .then(() => {
        reportSuccess({
          message: `Blueprint "${version}" was successfully deleted`,
        });
        modal.resolve();
        modal.hide();
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete blueprint version"
      name={version}
      onConfirm={handleDeleteBlueprintVersion}
      // isLoading={loading}
      // isDismissable={!loading && !data?.blueprintDelete?.id}
    />
  );
});

export const showVersionDeleteConfirmation = (props: VersionDeleteConfirmationProps) =>
  show(VersionDeleteConfirmation, props);
