import { formatDistance, format, isThisYear } from "date-fns/esm";

import Tooltip from "ds/components/Tooltip";
import Typography, { TypographyProps } from "ds/components/Typography";
import { getNowTimeMs, unixTimestampToMs } from "utils/time";

type TimestampHumanProps = {
  timestamp: number;
  color?: TypographyProps["color"];
  variant?: TypographyProps["variant"];
};

const TimestampHuman = ({ timestamp, color, variant = "p-body3" }: TimestampHumanProps) => {
  const timestampMs = unixTimestampToMs(timestamp);
  const shouldShowYear = !isThisYear(timestampMs);
  const finalFormat = shouldShowYear ? "MMM do, yyyy, pp" : "MMM do, pp";
  const now = getNowTimeMs();

  return (
    <Tooltip
      on={(props) => (
        <Typography {...props} tag="span" variant={variant} color={color}>
          {formatDistance(timestampMs, now, { addSuffix: true, includeSeconds: true })}
        </Typography>
      )}
    >
      {format(timestampMs, finalFormat)}
    </Tooltip>
  );
};

export default TimestampHuman;
