import { useModal } from "@ebay/nice-modal-react";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpaces from "components/FormFields/Spaces";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import FormField from "ds/components/Form/Field";
import TabWithCounter from "ds/components/Tab/WithCounter";
import TabPanel from "ds/components/TabPanel";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUserGroup } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { getProviderName } from "views/Account/Settings/Users/helpers";

import { getManagementStrategy } from "../../helpers";
import { UserManagementActivationStatus } from "../../types";
import UserGroupStatusBadge from "../UserGroupStatusBadge";
import styles from "./styles.module.css";
import { GroupsDrawerTabs, UpdateGroupFields } from "./types";
import useUpdateGroup from "./useUpdateGroup";

type IdpGroupMappingDetailsDrawerContentProps = {
  group: ManagedUserGroup;
  activationStatus: UserManagementActivationStatus;
  setIsDirty: (isDirty: boolean) => void;
};

const IdpGroupMappingDetailsDrawerContent = ({
  group,
  activationStatus,
  setIsDirty,
}: IdpGroupMappingDetailsDrawerContentProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [currentTab, setCurrentTab] = useState("spaces");
  const drawer = useModal();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIdpGroupMapping,
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const groupAddForm = useForm<UpdateGroupFields>({
    defaultValues: {
      spaces: group.accessRules.length > 0 ? group.accessRules : [],
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = groupAddForm;

  const { onUpdate } = useUpdateGroup();

  const providerName = getProviderName(group.identityProvider);

  const onSubmit: SubmitHandler<UpdateGroupFields> = (formData) => {
    trackSegmentAnalyticsEvent("Update group details");
    const input = {
      id: group.id,
      accessRules: formData.spaces.filter((space) => !!space.space && !!space.spaceAccessLevel),
    };
    onUpdate(input)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `Group access was updated`,
          });
          drawer.hide();
        }
      })
      .catch(onError);
  };

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  return (
    <FormProvider {...groupAddForm}>
      <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Access details" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight hasStickyFooter>
          <FormFieldViewText label="ID" value={group.id} withCopy />
          <FormFieldViewText label="Name" value={group.groupName} />
          <FormFieldViewText label="Login method" value={providerName} noTextEllipsis />
          {group.lastLoginTime && (
            <FormFieldTimestamp timestamp={group.lastLoginTime} label="Last login" />
          )}

          <FormField label="Status" className={styles.status}>
            <UserGroupStatusBadge status={group.status} />
          </FormField>

          <Box direction="row" align="center" gap="medium" className={styles.tabs}>
            <TabWithCounter
              onClick={setCurrentTab}
              isActive={currentTab === GroupsDrawerTabs.Spaces}
              id={GroupsDrawerTabs.Spaces}
              label="Spaces"
              count={group.spaceCount}
            />
            <TabWithCounter
              onClick={setCurrentTab}
              isActive={currentTab === GroupsDrawerTabs.Users}
              id={GroupsDrawerTabs.Users}
              label="Users"
              count={group.memberCount || 0}
            />
          </Box>

          <TabPanel isActive={currentTab === GroupsDrawerTabs.Spaces} id={GroupsDrawerTabs.Spaces}>
            <div className={styles.listWrapper}>
              <FormFieldSpaces
                analyticsPage={AnalyticsPageOrganization.OrganizationIdpGroupMapping}
                allowEmpty
              />
            </div>
          </TabPanel>
          <TabPanel isActive={currentTab === GroupsDrawerTabs.Users} id={GroupsDrawerTabs.Users}>
            <div className={styles.listWrapper}>
              {group.members.map((item, index) => {
                return (
                  <ListEntitiesItem key={`${item}-${index}`} className={styles.listItem}>
                    <Typography tag="span" variant="p-t6">
                      {item}
                    </Typography>
                  </ListEntitiesItem>
                );
              })}
            </div>
          </TabPanel>
          <DrawerFooter sticky>
            <DrawerFooterActions>
              <DrawerCancelButton />
              <Button variant="primary" type="submit">
                Save changes
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </form>
    </FormProvider>
  );
};

export default IdpGroupMappingDetailsDrawerContent;
