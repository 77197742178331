import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { USER_ROLES_COLUMN_ID } from "../constants";
import UserRolesListItemDescriptionCell from "./DescriptionCell";
import UserRolesListItemActionsDropdownCell from "./DropdownActionsCell";
import UserRolesListItemNameCell from "./NameCell";
import { RoleBinding } from "../types";
import UserRolesListItemGrantedAtCell from "./GrantedAtCell";

const CELLS = {
  [USER_ROLES_COLUMN_ID.DESCRIPTION]: UserRolesListItemDescriptionCell,
  [USER_ROLES_COLUMN_ID.NAME]: UserRolesListItemNameCell,
  [USER_ROLES_COLUMN_ID.DROPDOWN]: UserRolesListItemActionsDropdownCell,
  [USER_ROLES_COLUMN_ID.GRANTED_AT]: UserRolesListItemGrantedAtCell,
};

type UserRolesListItemProps = {
  roleBinding: RoleBinding;
};

const UserRolesListItem = (props: UserRolesListItemProps) => {
  const { roleBinding } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as USER_ROLES_COLUMN_ID];
        const uniqueKey = `${id}-${roleBinding.id}`;

        const childrenComponent = <Component roleBinding={roleBinding} />;

        return (
          <TableCell key={uniqueKey} id={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(UserRolesListItem);
