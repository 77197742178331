import { NetworkStatus } from "@apollo/client";
// import Link from "ds/components/Link";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import { ConfettiColored, EmptystateStacksColored, Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import MissingDataBanner from "components/MissingDataBanner";
import {
  URL_FILTER_KEYS_KEY,
  URL_FILTER_TYPES_KEY,
  URL_FILTER_VALUES_KEY,
} from "constants/url_query_keys";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";
import TileContent from "ds/components/Tile/Content";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import { StackState } from "types/generated";
import { IconComponent } from "types/Icon";

import { Widget } from "../../../types";
import DashboardWidgetsEmptyStateStacks from "../../EmptyStateStacks";
import { GET_STACKS_BY_STATE } from "./gql";
import DashboardWidgetsStackStateRecentItemsSkeleton from "./Skeleton";
import styles from "./styles.module.css";
import { GetStacksByState } from "./types";
const LIMIT = 3;

const EMPTY_STATES: Partial<Record<StackState, { title: string; icon: IconComponent }>> = {
  [StackState.Failed]: {
    title: "You don’t have any failed stacks!",
    icon: ConfettiColored,
  },
  [StackState.Unconfirmed]: {
    title: "You don’t have any unconfirmed stacks!",
    icon: ConfettiColored,
  },
  [StackState.Finished]: {
    title: "You don’t have any finished stacks!",
    icon: EmptystateStacksColored,
  },
};

type DashboardWidgetsStackStateRecentItemsProps = {
  stackState: StackState;
};
const DashboardWidgetsStackStateRecentItems = ({
  stackState,
}: DashboardWidgetsStackStateRecentItemsProps) => {
  const emptyState = stackState && EMPTY_STATES[stackState];

  const { loading, data, refetch, error, networkStatus } = usePolledQuery<GetStacksByState>(
    GET_STACKS_BY_STATE,
    {
      pollingGroup: PollingIntervalGroups.Dashboard,
      variables: {
        states: [stackState],
      },
    }
  );

  const showErrorBanner = !!error;

  const hasStacks = !!data?.metrics?.hasStacks;
  const hasItems = hasStacks && stackState && !!data?.metrics?.recentStacks?.length;
  const isLoading = (loading && !data?.metrics) || networkStatus === NetworkStatus.refetch;

  return (
    <Box direction="column" margin="large 0 0 0" padding="large 0 0 0">
      <Box justify="between" align="center" grow="1">
        <Typography tag="span" variant="p-t6">
          {`Recently ${stackState.toLowerCase()}`}
        </Typography>

        {!showErrorBanner && hasItems && (
          <Link
            href={`/stacks?${URL_FILTER_KEYS_KEY}=state&${URL_FILTER_TYPES_KEY}=ENUM&${URL_FILTER_VALUES_KEY}=${btoa(
              encodeURIComponent(JSON.stringify([[stackState.toUpperCase()]]))
            )}`}
            analyticsPage={AnalyticsPageDashboard.Dashboard}
            analyticsTitle="Stack State Widget - See all clicked"
            analyticsProps={{
              stackState: stackState,
            }}
          >
            See all
          </Link>
        )}
      </Box>

      {showErrorBanner && (
        <Box margin="x-large 0 0 0" direction="column" fullWidth>
          <MissingDataBanner
            text={`Couldn’t load ${stackState.toLowerCase()} stacks. Please try to refresh or come back later.`}
            refreshHandler={refetch}
          />
        </Box>
      )}

      {!showErrorBanner && !hasStacks && !isLoading && (
        <Box margin="x-large 0 0 0" fullWidth>
          <DashboardWidgetsEmptyStateStacks widget={Widget.StacksState} />
        </Box>
      )}
      {!showErrorBanner && (hasStacks || isLoading) && (
        <Box direction="column" gap="medium" padding="x-large 0 0 0">
          {isLoading && <DashboardWidgetsStackStateRecentItemsSkeleton />}
          {!isLoading &&
            hasItems &&
            data?.metrics?.recentStacks.slice(0, LIMIT).map(({ name, slug, spaceTile }, i) => (
              <TileWrapper key={i}>
                <TileContent direction="row" justify="between" align="center" gap="x-large">
                  <TextEllipsis tooltip={name}>
                    {(props) => (
                      <Link
                        analyticsTitle="Stack State Widget - Stack Clicked"
                        analyticsPage={AnalyticsPageDashboard.Dashboard}
                        analyticsProps={{
                          stackState,
                        }}
                        variant="secondary"
                        href={`/stack/${slug}`}
                        className={styles.stackLink}
                        {...props}
                      >
                        <Typography tag="span" variant="p-body2">
                          {name}
                        </Typography>
                      </Link>
                    )}
                  </TextEllipsis>

                  <Box justify="end" gap="medium" className={styles.spaceLink}>
                    <MetaInfoListItem
                      fullWidth
                      analyticsTitle="Stack State Widget - Space Clicked"
                      analyticsPage={AnalyticsPageDashboard.Dashboard}
                      analyticsProps={{
                        stackState,
                      }}
                      to={`/spaces/${spaceTile.slug}`}
                      linkText={spaceTile.name}
                      icon={Space}
                    />
                  </Box>
                </TileContent>
              </TileWrapper>
            ))}
          {!isLoading && !data?.metrics?.recentStacks?.length && (
            <TileWrapper>
              <Box padding="small" fullWidth justify="center">
                <EmptyState icon={emptyState?.icon} caption={emptyState?.title} />
              </Box>
            </TileWrapper>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DashboardWidgetsStackStateRecentItems;
