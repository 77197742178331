import { gql, TypedDocumentNode } from "@apollo/client";

import { ExternalIntegration, QueryExternalIntegrationArgs } from "types/generated";

type FetchExternalIntegrationData = Omit<ExternalIntegration, "apiKey" | "deleted">;

export const GET_EXTERNAL_INTEGRATION: TypedDocumentNode<
  FetchExternalIntegrationData,
  QueryExternalIntegrationArgs
> = gql`
  query GetExternalIntegration($integrationId: ID!) {
    externalIntegration(id: $integrationId) {
      id
      attachedBlueprints {
        id
        blueprintID
        blueprintName
        blueprintSpaceDetails {
          id
          name
        }
      }
      createdAt
      customConfig {
        __typename
        ... on ExternalIntegrationConfigServiceNow {
          baseURL
          username
        }
      }

      description
      name
      spaceDetails {
        id
        name
        accessLevel
      }
      type
    }
  }
`;
