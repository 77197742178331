import { gql } from "@apollo/client";

import { stackVendorConfigFragment } from "components/form/graphql";

export const GET_STACK = gql`
  ${stackVendorConfigFragment}
  query GetStack($id: ID!) {
    stack(id: $id) {
      administrative
      id
      apiHost
      blocker {
        id
        state
        type
      }
      branch
      blueprint {
        ulid
        name
      }
      canWrite
      isDisabled
      isStateRollback
      dependsOn {
        id
        stack {
          id
          name
          space {
            id
            name
            accessLevel
          }
          vendorConfig {
            __typename
          }
        }
        dependsOnStack {
          id
          name
          space {
            id
            name
            accessLevel
          }
          vendorConfig {
            __typename
          }
        }
        referenceCount
        references {
          id
          inputName
          outputName
          triggerAlways
        }
      }
      description
      isDependedOnBy {
        id
        stack {
          id
          name
          space {
            id
            name
            accessLevel
          }
          vendorConfig {
            __typename
          }
        }
        dependsOnStack {
          id
          name
          space {
            id
            name
            accessLevel
          }
          vendorConfig {
            __typename
          }
        }
        referenceCount
        references {
          id
          inputName
          outputName
          triggerAlways
        }
      }
      effectiveTerraformVersion
      labels
      lockedAt
      lockedBy
      lockNote
      managesStateFile
      enableWellKnownSecretMasking
      enableSensitiveOutputUpload
      name
      namespace
      projectRoot
      provider
      repository
      repositoryURL
      runnerImage
      spaceDetails {
        id
        name
        accessLevel
      }
      starred
      state
      stateIsTerminal
      stateSetAt
      trackedCommit {
        url
        hash
      }
      trackedBranchHead {
        url
        hash
      }
      trackedCommitSetBy
      ...stackVendorConfig
      vcsDetached
      vcsIntegration {
        id
        name
        provider
      }
      workerPool {
        id
        name
        busyWorkers
        pendingRuns
        workers {
          id
          busy
          drained
        }
      }
      additionalProjectGlobs
    }
    tier
    tierFeatures
  }
`;

export const IMPORT_STATE = gql`
  mutation StackManagedStateImport($stackId: ID!, $state: String!) {
    stackManagedStateImport(stackId: $stackId, state: $state)
  }
`;
