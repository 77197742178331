import Box from "ds/components/Box";
import Link from "ds/components/Link";
import BadgeNext from "ds/components/BadgeNext";
import Typography from "ds/components/Typography";

import useRolesListAnalytics from "../useRolesListAnalytics";
import { CellComponentProps } from "../types";

type RolesListItemNameCellProps = CellComponentProps;

const RolesListItemNameCell = ({ role }: RolesListItemNameCellProps) => {
  const trackAnalytics = useRolesListAnalytics();

  const roleUrl = `/settings/role/${role.id}`;

  return (
    <Box fullWidth gap="large">
      {role.isSystem ? (
        <>
          <Typography tag="p" variant="p-body3">
            {role.name}
          </Typography>
          <BadgeNext type="strong" variant="blue" text="SYSTEM" />
        </>
      ) : (
        <Link
          size="small"
          variant="secondary"
          href={roleUrl}
          onPress={() => trackAnalytics("Name link clicked")}
        >
          {role.name}
        </Link>
      )}
    </Box>
  );
};

export default RolesListItemNameCell;
