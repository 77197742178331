import { External } from "components/icons/generated";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import LinkButton from "ds/components/Button/LinkButton";
import Feedback from "ds/components/Feedback";
import FeedbackActions from "ds/components/Feedback/Actions";
import { FeedbackType } from "ds/components/Feedback/types";
import useTypedContext from "hooks/useTypedContext";
import { AzureIntegration } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";
import { AccountContext } from "views/AccountWrapper";
import { hasCloudIntegrationManageAccess } from "views/Account/CloudIntegrations/utils";

type CloudIntegrationsAzureConsentFeedbackProps = {
  integration: AzureIntegration;
  type: FeedbackType;
  adminConsentFailed?: boolean;
};

const CloudIntegrationsAzureConsentFeedback = ({
  integration,
  type,
  adminConsentFailed,
}: CloudIntegrationsAzureConsentFeedbackProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const hasAccessToManageIntegration = hasCloudIntegrationManageAccess(viewer, integration);

  if (hasAccessToManageIntegration && adminConsentFailed) {
    return (
      <Feedback type={type} variant="danger">
        The Azure admin consent process has failed. This can be caused by a delay before the
        Microsoft Entra application for your integration has been fully replicated within Azure.
        Please try again in a few minutes. If the problem persists, please contact us so that we can
        investigate.
        <FeedbackActions>
          <LinkButton
            variant="secondary"
            size="small"
            href={integration.adminConsentURL}
            endIcon={External}
          >
            {integration.adminConsentProvided ? "Re-consent" : "Consent"}
          </LinkButton>
        </FeedbackActions>
      </Feedback>
    );
  }

  if (!integration.adminConsentProvided) {
    return (
      <Feedback type={type} variant="warning">
        To use the integration please{" "}
        {hasAccessToManageIntegration
          ? "grant admin consent"
          : "ask your admin to grant admin consent"}{" "}
        to the Microsoft Entra application created for your integration. This installs the
        application in your directory tenant, allowing you to grant RBAC permissions to it.
        <FeedbackActions>
          {hasAccessToManageIntegration && (
            <LinkButton
              variant="secondary"
              size="small"
              href={integration.adminConsentURL}
              endIcon={External}
            >
              Consent
            </LinkButton>
          )}

          <ReadMoreDocsLink
            docsUrl={getDocsUrl("/integrations/cloud-providers/azure#providing-admin-consent")}
          />
        </FeedbackActions>
      </Feedback>
    );
  }

  return null;
};

export default CloudIntegrationsAzureConsentFeedback;
