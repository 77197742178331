import { useCallback, useEffect, useMemo, useRef } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import ListEntitiesItem from "components/ListEntitiesItem";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Toggle from "ds/components/Toggle";
import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import useSetWorkerDrain from "shared/WorkerPool/useSetWorkerDrain";
import { Worker } from "types/generated";

import PrivateWorkerPoolMetaTagsList from "../../MetaTagsList";
import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import WorkerStatusBadge from "../StatusBadge";
import styles from "./styles.module.css";

export type PrivateWorkerPoolWorkersListItemProps = {
  workerPoolId: string;
  worker: Worker;
  setRowHeight?: (size: number) => void;
  canManageWorkerPools: boolean;
  checked: boolean;
  onCheckItem: (workerId: string, isSelected: boolean) => void;
};

const PrivateWorkerPoolWorkersListItem = ({
  workerPoolId,
  worker,
  setRowHeight,
  canManageWorkerPools,
  checked,
  onCheckItem,
}: PrivateWorkerPoolWorkersListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [setWorkerDrain, { loading }] = useSetWorkerDrain({
    refetchQueries: ["SearchPrivateWorkerPoolWorkers"],
  });

  const handleDrain = () => {
    setWorkerDrain({ workerPool: workerPoolId, drain: true, id: worker.id }, (data) => {
      if (data?.workerDrainSet) {
        reportSuccess({ message: `Worker ${data.workerDrainSet.id} has been drained` });
      }
    });
  };

  const handleUndrain = () => {
    setWorkerDrain({ workerPool: workerPoolId, drain: false, id: worker.id }, (data) => {
      if (data?.workerDrainSet) {
        reportSuccess({ message: `Worker ${data.workerDrainSet.id} has been undrained` });
      }
    });
  };

  const parseMetadata = useCallback(
    (metadata: string) => {
      try {
        return JSON.parse(metadata) as Record<string, string>;
      } catch (error) {
        onError(error);
        return undefined;
      }
    },
    [onError]
  );

  const metadata = useMemo(() => {
    const parsedMetadata = (worker.metadata && parseMetadata(worker.metadata)) || {};

    return Object.entries(parsedMetadata).map(([key, value]) => `${key}: ${value}`);
  }, [parseMetadata, worker.metadata]);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  const checkboxAriaLabel = useCallback(
    (checked: boolean) =>
      checked ? `Unselect ${worker.id} worker pool` : `Select ${worker.id} worker pool`,
    [worker.id]
  );

  return (
    <ListEntitiesItem
      direction="row"
      align="start"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
    >
      <Box direction="row" align="center" className={styles.fixedHeightRow}>
        <Toggle
          variant="checkbox"
          id={worker.id}
          isSelected={checked}
          onChange={(isSelected) => onCheckItem(worker.id, isSelected)}
          aria-label={checkboxAriaLabel(checked)}
        />
      </Box>

      <Box direction="row" align="center" className={styles.fixedHeightRow}>
        <WorkerStatusBadge status={worker.status} />
      </Box>

      <Box direction="row" align="center" className={styles.fixedHeightRow}>
        <Typography tag="span" variant="p-body2">
          {worker.id}
        </Typography>
      </Box>

      <PrivateWorkerPoolMetaTagsList tags={metadata} onExpand={handleRowHeight} />

      <Box direction="row" align="center" className={styles.fixedHeightRow}>
        <MetaInfoListItem>
          <Timestamp timestamp={worker.createdAt} />
        </MetaInfoListItem>
      </Box>

      {canManageWorkerPools && (
        <Box direction="row" align="center" justify="end" className={styles.fixedHeightRow}>
          {worker.drained ? (
            <Tooltip
              on={(props) => (
                <Button
                  {...props}
                  onPress={handleUndrain}
                  loading={loading}
                  disabled={loading}
                  variant="secondary"
                  size="small"
                >
                  Undrain
                </Button>
              )}
            >
              Enable scheduling future runs on this worker
            </Tooltip>
          ) : (
            <Tooltip
              on={(props) => (
                <Button
                  {...props}
                  onPress={handleDrain}
                  loading={loading}
                  disabled={loading}
                  variant="dangerPrimary"
                  size="small"
                >
                  Drain
                </Button>
              )}
            >
              Disable scheduling future runs on this worker
            </Tooltip>
          )}
        </Box>
      )}
    </ListEntitiesItem>
  );
};

export default PrivateWorkerPoolWorkersListItem;
