import cx from "classnames";
import { format, fromUnixTime, getUnixTime, parse } from "date-fns/esm";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Cross } from "components/icons/generated";
import FormToggleField from "ds/components/Form/ToggleField";
import Icon from "ds/components/Icon";
import SelectNew from "ds/components/Select/New";
import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { AnalyticsPageRuns } from "hooks/useAnalytics/pages/runs";
import Box from "ds/components/Box";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { getDocsUrl } from "utils/getDocsUrl";

import RunsBulkActions from "../BulkActions";
import Chart from "../Chart";
import Filters from "../Filters";
import { FilterOption } from "../Filters/types";
import {
  generateAccountEntities,
  generateFilters,
  generateGroupByOptions,
  generateKeyPossibleValues,
  getDefaultDateRange,
} from "../helpers";
import SideBar, { ResourcesSideBarProps } from "../Sidebar";
import { AccountRunsData, RunEntity, RunsFilterValues } from "../types";
import Datepicker from "./Datepicker";
import "views/Account/Resources/Wrapper/styles.css";
import styles from "./styles.module.css";
import { DateRange } from "./types";

type RunsWrapperProps = {
  data: Partial<AccountRunsData>;
  search: string;
  dateRange: DateRange;
  setDateRange: (value: DateRange) => void;
  isAccountWide?: boolean;
  refetchData: () => void;
};

const RunsWrapper = (props: RunsWrapperProps) => {
  const { data, search, dateRange, setDateRange, isAccountWide, refetchData } = props;

  const [filterField, setFilterField] = useState([""]);
  const [filterValues, setFilterValues] = useState<RunsFilterValues>([[]]);
  const [groupByValue, setGroupByValue] = useState("trigger");
  const [entityDetails, setEntityDetails] = useState<ResourcesSideBarProps["entityDetails"]>(
    {} as ResourcesSideBarProps["entityDetails"]
  );
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [zoomTarget, setZoomTarget] = useState<string | null>("");
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleItems, transferVisibleItems] = useState<Array<RunEntity>>([]);
  const [bulkActionsEnabled, setBulkActionsEnabled] = useState(false);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params: {
      filterFields?: string;
      filterValues?: string;
      groupByValue?: string;
      zoomTarget?: string;
      dateRange?: string;
    } = queryString.parse(search);

    if (Object.keys(params).length === 0) return;

    if (params.filterFields) {
      try {
        setFilterField(JSON.parse(atob(params.filterFields)));
      } catch {
        // Filter fields are malformed, reset to default
        setFilterField([""]);
      }
    }

    if (params.filterValues) {
      try {
        setFilterValues(JSON.parse(decodeURIComponent(atob(params.filterValues))));
      } catch {
        // Filter values are malformed, reset to default
        setFilterValues([[]]);
      }
    }

    if (params.zoomTarget) {
      try {
        setZoomTarget(JSON.parse(atob(params.zoomTarget)));
      } catch {
        // Zoom target is malformed, reset to default
        setZoomTarget("");
      }
    }

    if (params.dateRange) {
      try {
        const dateRangeValue = JSON.parse(atob(params.dateRange));
        const startDateFromUnix = fromUnixTime(dateRangeValue.startDate);
        const endDateFromUnix = fromUnixTime(dateRangeValue.endDate);
        const fullDateFormat = "yyyy-MM-dd HH:mm:ss";
        const labelDateFormat = "dd-MM-yyyy HH:mm";

        setDateRange({
          startDate: parse(format(startDateFromUnix, fullDateFormat), fullDateFormat, new Date()),
          endDate: parse(format(endDateFromUnix, fullDateFormat), fullDateFormat, new Date()),
          label: `${format(startDateFromUnix, labelDateFormat)} - ${format(
            endDateFromUnix,
            labelDateFormat
          )}`,
        });
      } catch {
        // Date range is malformed, reset to default
        setDateRange(getDefaultDateRange());
      }
    }

    setGroupByValue(params.groupByValue || "trigger");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entities = useMemo(() => generateAccountEntities(data), [data]);

  const filters = useMemo(
    () => generateFilters(filterField, filterValues),
    [filterField, filterValues]
  );

  const keyPossibleValues = useMemo(
    () => generateKeyPossibleValues(filterField, filters, entities),
    [filterField, filters, entities]
  );
  const groupByOptions = generateGroupByOptions();

  const setParams = useCallback(
    (
      fields: Array<string>,
      values: Array<Array<{ label: string; value: string }>>,
      sort?: string,
      zoomTargetChange?: string,
      dateRangeChange?: DateRange
    ) => {
      const dateRangeValue = dateRangeChange || dateRange;
      const dateRangeParam = {
        startDate: getUnixTime(dateRangeValue.startDate),
        endDate: getUnixTime(dateRangeValue.endDate),
        label: dateRangeValue.label,
      };

      const newSearchParams = new URLSearchParams({
        filterValues: btoa(encodeURIComponent(JSON.stringify(values))),
        filterFields: btoa(JSON.stringify(fields)),
        groupByValue: sort || groupByValue,
        zoomTarget: btoa(JSON.stringify(zoomTargetChange || zoomTarget)),
        dateRange: btoa(JSON.stringify(dateRangeParam)),
      });

      setSearchParams(newSearchParams);
    },
    [dateRange, setSearchParams, groupByValue, zoomTarget]
  );

  const addEmptyFilter = () => {
    setFilterField((filterField) => [...filterField, ""]);
    setFilterValues((filterValues) => [...filterValues, []]);
  };

  const handleFilterAdd = useCallback(
    (key: string, value: string) => {
      const [lastFilterField] = filterField.slice(-1);
      if (lastFilterField === "") {
        const newFilterField = filterField.map((item, index) => {
          return index + 1 === filterField.length ? key : item;
        });
        setFilterField(newFilterField);

        const newFilterValues = filterValues.map((item, index) => {
          return index + 1 === filterValues.length ? [{ label: value, value }] : item;
        });

        setFilterValues(newFilterValues);

        setParams(newFilterField, newFilterValues);
      } else {
        setFilterField((filterField) => [...filterField, key]);
        setFilterValues((filterValues) => [...filterValues, [{ label: value, value }]]);
        setParams([...filterField, key], [...filterValues, [{ label: value, value }]]);
      }
    },
    [filterField, filterValues, setParams]
  );

  const handleFilterChange = useCallback(
    (option: FilterOption | null, index: number) => {
      const newFilterField = [...filterField];
      const newFilterValues = [...filterValues];

      if (option === null && filterField.length > 1) {
        // Cleared - remove this selector. There's an empty selector following us.

        newFilterField.splice(index, 1);
        newFilterValues.splice(index, 1);
      } else {
        newFilterField[index] = option?.value || "";
        if (newFilterField[index] !== filterField[index]) {
          // if the option changed, clear the values selector
          newFilterValues[index] = [];
        }
      }

      setFilterField(newFilterField);
      setFilterValues(newFilterValues);
      setParams(newFilterField, newFilterValues);
    },
    [filterField, filterValues, setParams]
  );

  const handleMultiFilterChange = useCallback(
    (newValues: Array<FilterOption>, index: number) => {
      const newFilterValues = filterValues.slice();
      newFilterValues[index] = newValues;

      setFilterValues(newFilterValues);
      setParams(filterField, newFilterValues);
    },
    [filterValues, filterField, setParams]
  );

  const handleGroupByChange = useCallback(
    (value: string) => {
      setGroupByValue(value);
      setParams(filterField, filterValues, value);
    },
    [filterField, filterValues, setParams]
  );

  const handleZoomTargetChange = useCallback(
    (value: string) => {
      setZoomTarget(value);
      setParams(filterField, filterValues, undefined, value);
    },
    [filterField, filterValues, setParams]
  );

  const toggleFullScreen = useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen]);

  const handleDateRangeChange = useCallback(
    (startDate: Date, endDate: Date, label?: string) => {
      const value = {
        startDate: startDate,
        endDate: endDate,
        label: label,
      };
      setDateRange(value);

      setParams(filterField, filterValues, undefined, undefined, value);
    },
    [filterField, filterValues, setDateRange, setParams]
  );

  const shouldShowAddButton = useMemo(() => {
    return filterField.slice(-1)[0] !== "" && filterValues.slice(-1)[0].length > 0;
  }, [filterField, filterValues]);

  const wrapperClass = cx(styles.wrapper, {
    "resources--fullscreen": fullScreen,
  });

  const handleBulkActionsFinish = async () => {
    await refetchData?.();
  };

  const bulkActionItems = useMemo(() => {
    return visibleItems.filter((item) => !item.isModule);
  }, [visibleItems]);

  const bulkActionsSet = useMemo(() => {
    return new Set(bulkActionItems.map((item) => item.id));
  }, [bulkActionItems]);

  const bulkActionsMap = useMemo(() => {
    return new Map(bulkActionItems.map((item) => [item.id, item]));
  }, [bulkActionItems]);

  return (
    <div className={wrapperClass}>
      <SideBar
        entityDetails={entityDetails}
        fullScreen={fullScreen}
        setMenuVisible={setMenuVisible}
        setZoomTarget={setZoomTarget}
        isVisible={isMenuVisible}
        handleFilterNewChange={handleFilterAdd}
      />
      <div>
        {fullScreen && (
          <div className="resources__close">
            <Tooltip
              placement="bottom"
              on={(props) => <Icon {...props} src={Cross} onClick={toggleFullScreen} />}
            >
              Hide full screen
            </Tooltip>
          </div>
        )}
        <div className="resources-controls">
          <div className="resources-filters resources-filters--run">
            <Typography tag="div" variant="p-t7">
              Filter by:
            </Typography>
            <div className="resources-filters__items">
              {filterField.map((_value, index) => (
                <Filters
                  key={`filter-${index}`}
                  keyPossibleValues={keyPossibleValues[index]}
                  filterField={filterField}
                  filterValues={filterValues}
                  index={index}
                  handleFilterChange={handleFilterChange}
                  handleMultiFilterChange={handleMultiFilterChange}
                />
              ))}
              {shouldShowAddButton && (
                <button className="resources-filters__button" onClick={addEmptyFilter}>
                  + Add filter
                </button>
              )}
            </div>
          </div>
          <Datepicker dateRange={dateRange} handleDateRangeChange={handleDateRangeChange} />
          <div className="resources-group-by">
            <SelectNew
              value={
                groupByOptions.find((option) => option.value === groupByValue)?.value ||
                groupByOptions[0].value
              }
              items={groupByOptions}
              onChange={handleGroupByChange}
              label="Group by:"
            />
          </div>
          <Box padding="0 0 0 x-large" align="center">
            <FormToggleField
              variant="checkbox"
              onChange={setBulkActionsEnabled}
              checked={bulkActionsEnabled}
              title="Select all visible"
              analyticsPage={AnalyticsPageRuns.Runs}
              analyticsTitle="Runs bulk actions toggled"
              tooltipInfo={
                <>
                  <TooltipModalTitle>Enable bulk actions</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    <Typography tag="p" variant="p-body3">
                      Once selected, you can perform bulk actions on the filtered runs.{" "}
                      <ReadMoreDocsLink
                        docsUrl={getDocsUrl(
                          "/product/bulk-actions.html#runs-view-available-actions"
                        )}
                      />
                    </Typography>
                  </TooltipModalBody>
                </>
              }
            />
          </Box>
        </div>
        <Chart
          isAccountWide={isAccountWide}
          data={entities}
          groupByKey={groupByValue}
          filters={filters}
          setEntityDetails={setEntityDetails}
          setMenuVisible={setMenuVisible}
          zoomTarget={zoomTarget}
          setZoomTarget={handleZoomTargetChange}
          fullScreen={fullScreen}
          toggleFullScreen={toggleFullScreen}
          handleFilterAdd={handleFilterAdd}
          transferVisibleItems={transferVisibleItems}
        />

        {bulkActionsEnabled && (
          <RunsBulkActions
            runsMap={bulkActionsMap}
            selectedSet={bulkActionsSet}
            onFinish={handleBulkActionsFinish}
          />
        )}
      </div>
    </div>
  );
};

export default RunsWrapper;
