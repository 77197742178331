import Skeleton from "react-loading-skeleton";

const height = 32;

const TemplatePreviewSkeleton = () => {
  return (
    <>
      <Skeleton count={1} height={height} />
      <Skeleton count={1} height={height} />
      <Skeleton count={1} height={height} />
      <Skeleton count={1} height={height} />
      <Skeleton count={1} height={height} />
    </>
  );
};

export default TemplatePreviewSkeleton;
