import { gql, TypedDocumentNode } from "@apollo/client";

import { Role } from "types/generated";

type UserRoleData = {
  roles: Role[];
};

export const USER_ROLES_FOR_INVITE: TypedDocumentNode<UserRoleData> = gql`
  query UserRolesForInvite {
    roles {
      id
      name
    }
  }
`;
