import { Space } from "types/generated";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import { SpacesDrawerWriteMode, SpacesLayoutMode } from "../types";
import SpaceDrawerBuilder from "./Builder";
import SpaceDrawerView from "./View";

type SpaceDrawerProps = {
  drawerWriteMode?: SpacesDrawerWriteMode;
  space?: Space;
  layoutMode: SpacesLayoutMode;
  onClose: () => void;
  isRootAdmin: boolean;
};

const SpaceDrawer = createDrawer(
  ({ drawerWriteMode, space, layoutMode, onClose, isRootAdmin }: SpaceDrawerProps) => {
    return drawerWriteMode ? (
      <SpaceDrawerBuilder
        drawerWriteMode={drawerWriteMode}
        space={space}
        layoutMode={layoutMode}
        onClose={onClose}
        isRootAdmin={isRootAdmin}
      />
    ) : (
      <SpaceDrawerView space={space} isRootAdmin={isRootAdmin} onClose={onClose} />
    );
  }
);

export const showSpaceDrawer = createDrawerTrigger(SpaceDrawer);
