import { forwardRef, InputHTMLAttributes, useState } from "react";

import { Eye, EyeCrossed } from "components/icons/generated";
import useEnsureId from "hooks/useEnsureId";

import ButtonIcon from "../ButtonIcon";
import Input, { InputProps } from "../Input";
import styles from "./styles.module.css";

type SecretInputProps = {
  disablePreview?: boolean;
  size?: InputProps["size"];
  error?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "color">;

const SecretInput = forwardRef(function SecretInput(
  props: SecretInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { size = "regular", error = false, disablePreview, id: propsId, ...restProps } = props;
  const [isVisible, setIsVisible] = useState(false);
  const id = useEnsureId(propsId);

  return (
    <div className={styles.wrapper}>
      <Input
        id={id}
        size={size}
        className={styles.input}
        error={error}
        type={isVisible && !disablePreview ? "text" : "password"}
        {...restProps}
        ref={ref}
      />

      {!disablePreview && (
        <ButtonIcon
          variant="ghost"
          icon={isVisible ? EyeCrossed : Eye}
          aria-controls={id}
          aria-pressed={isVisible}
          onPress={() => setIsVisible(!isVisible)}
          className={styles.button}
        >
          {isVisible ? "Hide secret value" : "Show secret value"}
        </ButtonIcon>
      )}
    </div>
  );
});

SecretInput.displayName = "DS.SecretInput";

export default SecretInput;
