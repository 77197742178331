import { RefObject, useCallback, useLayoutEffect, useMemo, useRef } from "react";
import cx from "classnames";

import getKeyboardWidthChange from "./getKeyboardWidthChange";
import getMouseWidthChange from "./getMouseWidthChange";
import styles from "./styles.module.css";

type ResizeBarProps = {
  /** passed from useResizeBarWidthManager */
  inputRef: RefObject<HTMLInputElement>;
  widthRef: RefObject<number>;
  isCollapsedRef: RefObject<boolean>;
  onWidthChange: (newWidth: number) => void;
  onResizeFinish?: (newWidth: number) => void;
  onCollapse?: () => number;
  onExpand?: () => number;
  minWidth: number;
  maxWidth: number;
  /**
   * Width to which the panel will collapse.
   */
  collapseWidth?: number;
  /** passed from useResizeBarWidthManager */

  /**
   * If true, the resize bar will shrink the panel when dragged to the right.
   */
  reverse?: boolean;
  step?: number;
  className?: string;
};

const ResizeBar = ({
  className,
  reverse = false,
  widthRef,
  inputRef,
  isCollapsedRef,
  onWidthChange,
  onResizeFinish,
  minWidth,
  maxWidth,
  step = 10,
  collapseWidth = 33,
  onExpand,
  onCollapse,
}: ResizeBarProps) => {
  const startResizingPos = useRef<number>(0);
  const startResizingWidth = useRef<number>(0);
  const isResizing = useRef(false);

  const handleMouseMove = useMemo(
    () => (e: MouseEvent) => {
      if (!isResizing.current) return;

      const newWidth = getMouseWidthChange({
        startResizingPos: startResizingPos.current,
        currentMousePos: e.clientX,
        startResizingWidth: startResizingWidth.current,
        collapseWidth,
        isCollapsed: !!isCollapsedRef.current,
        reverse,
        minWidth,
        maxWidth,
        onCollapse,
        onExpand,
      });

      if (newWidth !== widthRef.current) {
        onWidthChange(newWidth);
      }
    },
    [
      widthRef,
      collapseWidth,
      isCollapsedRef,
      reverse,
      minWidth,
      maxWidth,
      onCollapse,
      onExpand,
      onWidthChange,
    ]
  );

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    startResizingPos.current = e.clientX;
    startResizingWidth.current = widthRef.current || 0;
    inputRef.current?.focus();
    isResizing.current = true;
  };

  const handleMouseUp = useCallback(() => {
    if (isResizing.current) {
      inputRef.current?.blur();
      isResizing.current = false;
      if (onResizeFinish && widthRef.current) {
        onResizeFinish(widthRef.current);
      }
    }
  }, [inputRef, onResizeFinish, widthRef]);

  useLayoutEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newWidth = getKeyboardWidthChange({
      key: e.key,
      isCollapsed: !!isCollapsedRef.current,
      reverse,
      previousWidth: widthRef.current || 0,
      minWidth,
      maxWidth,
      step,
      onCollapse,
      onExpand,
    });

    if (newWidth !== widthRef.current) {
      onWidthChange(newWidth);
      if (onResizeFinish && widthRef.current) {
        onResizeFinish(widthRef.current);
      }
    }
  };

  return (
    <div
      className={cx(styles.resizer, className)}
      role="presentation"
      onMouseDown={handleMouseDown}
    >
      <input
        ref={inputRef}
        className={styles.input}
        aria-valuetext={`${widthRef.current} pixels`}
        aria-label="Resizer"
        type="range"
        min={minWidth}
        max={maxWidth}
        value={widthRef.current || 0}
        onKeyDown={handleKeyDown}
        readOnly
      />
    </div>
  );
};

export default ResizeBar;
