import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { SEARCH_MANAGED_ENTITIES_GROUP_BY_PROVIDERS } from "./gql";

const useIacManagementProvidersGroups = () => {
  const { onError } = useTypedContext(FlashContext);
  const [lazyFetch, { data, previousData, loading }] = useLazyQuery(
    SEARCH_MANAGED_ENTITIES_GROUP_BY_PROVIDERS,
    {
      onError,
    }
  );

  const groups = useMemo(
    () =>
      data?.searchManagedEntitiesGroupByProviders?.edges.map((edge) => edge.node) ||
      previousData?.searchManagedEntitiesGroupByProviders?.edges.map((edge) => edge.node) ||
      [],
    [
      data?.searchManagedEntitiesGroupByProviders?.edges,
      previousData?.searchManagedEntitiesGroupByProviders?.edges,
    ]
  );

  const dataIsEmpty =
    data && "searchManagedEntitiesGroupByProviders" in data && !groups.length && !loading;

  return {
    lazyFetch,
    groups,
    loading,
    endCursor: data?.searchManagedEntitiesGroupByProviders?.pageInfo.endCursor,
    startCursor: data?.searchManagedEntitiesGroupByProviders?.pageInfo.startCursor,
    dataIsEmpty,
  };
};

export default useIacManagementProvidersGroups;
