import { useEffect } from "react";

import IgnoredRunPoliciesList from "components/IgnoredRunPoliciesList";
import { HIDE_IGNORED_RUN_NOTICE_LOCAL_STORAGE_KEY } from "constants/local_storage_keys";
import LinkButton from "ds/components/Button/LinkButton";
import Callout, { CalloutProps } from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import useAnalytics from "hooks/useAnalytics";
import { RunIgnoredTrigger, Stack } from "types/generated";

import StackRunsIgnoredRunCalloutPermanentCloseConfirmationTooltip from "./PermanentCloseConfirmationTooltip";
import StackRunsIgnoredRunCalloutPrimaryAction from "./PrimaryAction";
import StackRunsIgnoredRunCalloutTitle from "./Title";

type StackRunsIgnoredRunCalloutProps = {
  stack: Stack;
  ignoredRun: RunIgnoredTrigger;
};

const StackRunsIgnoredRunCallout = ({ stack, ignoredRun }: StackRunsIgnoredRunCalloutProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics();

  useEffect(() => {
    trackSegmentAnalyticsEvent("Ignored run warning viewed", {
      warningType: ignoredRun.reasonType,
    });
  }, [ignoredRun.reasonType, trackSegmentAnalyticsEvent]);

  const handleDismiss: CalloutProps["closeCallback"] = ({ closedPermanently }) => {
    trackSegmentAnalyticsEvent("Ignored run warning dismissed", {
      warningType: ignoredRun.reasonType,
      dismissedPermanently: closedPermanently,
    });
  };

  return (
    <Callout
      variant="warning"
      title={<StackRunsIgnoredRunCalloutTitle stack={stack} ignoredRun={ignoredRun} />}
      storageKey={HIDE_IGNORED_RUN_NOTICE_LOCAL_STORAGE_KEY}
      permanentCloseConfirmationTooltipInfo={
        <StackRunsIgnoredRunCalloutPermanentCloseConfirmationTooltip />
      }
      closeCallback={handleDismiss}
      withPermanentCloseConfirmation
    >
      <IgnoredRunPoliciesList ignoredRun={ignoredRun} variant="p-body3" />
      If you want to force the run, sync and trigger it manually.
      <FeedbackActions>
        <StackRunsIgnoredRunCalloutPrimaryAction stack={stack} ignoredRun={ignoredRun} />
        <LinkButton
          variant="secondary"
          size="small"
          to={`/stack/${stack.id}/ignored-runs`}
          analyticsTitle="Ignored run secondary action clicked"
          analyticsProps={{
            warningType: ignoredRun.reasonType,
          }}
        >
          Go to ignored runs
        </LinkButton>
      </FeedbackActions>
    </Callout>
  );
};

export default StackRunsIgnoredRunCallout;
