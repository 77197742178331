import { ReactNode, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import { SpacesContext } from "views/Account/SpacesProvider";
import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageInfo from "components/PageWrapper/Info";
import Button from "ds/components/Button";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import { showDeleteConfirmation } from "./DeleteConfirmation";

type RolePageLayoutProps = {
  children: ReactNode;
  name: string;
  id: string;
  roleBindingsCount: number;
};

const RolePageLayout = ({ children, name, id, roleBindingsCount }: RolePageLayoutProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const navigate = useNavigate();

  useTitle(`Role · ${name}`);

  const handleDelete = useCallback(() => {
    showDeleteConfirmation({
      id,
      name,
      successCallback: () => navigate("/settings/roles", { replace: true }),
      roleBindingsCount,
    });
  }, [id, name, navigate, roleBindingsCount]);

  useBreadcrumbs(
    [
      {
        title: "Organization Settings Roles",
        link: getBreadcrumbsBackUrl("/settings/roles", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: name,
      },
    ],
    []
  );

  return (
    <>
      <ViewHeader firstLevel>
        <Box fullWidth justify="between" align="center">
          <ViewHeaderTitle tag="h2">{name}</ViewHeaderTitle>

          {hasEntityCreateAccess && (
            // TODO: [AAC] add action
            <Button variant="dangerSecondary" onPress={handleDelete}>
              Delete role
            </Button>
          )}
        </Box>
      </ViewHeader>

      <PageInfo title="Permissions">
        {/* TODO: [AAC] filtersOrderSettingsKey and search reasults when BE done */}
        <SearchInput placeholder="Search..." filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY} />

        {hasEntityCreateAccess && (
          <LinkButton variant="primary" to={`/settings/role/${id}/edit`}>
            Add permissions
          </LinkButton>
        )}
      </PageInfo>

      {children}
    </>
  );
};

export default RolePageLayout;
