import Box from "ds/components/Box";
import ListEntitiesItemDescriptionMultipleLines from "components/ListEntitiesItem/Description/MultipleLines";

import useBlueprintVersionsAnalytics from "../../useBlueprintVersionsAnalytics";
import { CellComponentProps } from "./types";

type BlueprintVersionsListItemDescriptionCellProps = CellComponentProps;

const BlueprintVersionsListItemDescriptionCell = ({
  version,
}: BlueprintVersionsListItemDescriptionCellProps) => {
  const trackAnalytics = useBlueprintVersionsAnalytics();

  const handleShowFullDescription = () => {
    trackAnalytics("Show full description clicked");
  };

  if (!version.description) {
    return null;
  }

  return (
    <Box direction="row" align="start">
      <ListEntitiesItemDescriptionMultipleLines
        lines={1}
        hasChevronLink
        description={version.description}
        onShowFullDescription={handleShowFullDescription}
      />
    </Box>
  );
};

export default BlueprintVersionsListItemDescriptionCell;
