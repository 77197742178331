import { memo, ReactNode } from "react";

import useRowKeyboardNavigation from "ds/components/Table/useKeyboardNavigation/useRowKeyboardNavigation";

import styles from "./styles.module.css";

type TableHeadProps = {
  children: ReactNode;
};

const TableHead = memo(({ children }: TableHeadProps) => {
  const keyboardProps = useRowKeyboardNavigation({ isGroup: false });

  return (
    <div className={styles.tableHeader} role="rowgroup">
      <div role="row" className={styles.headerRow} {...keyboardProps}>
        {children}
      </div>
    </div>
  );
});

TableHead.displayName = "DS.Table.Head";

export default TableHead;
