import { memo } from "react";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import IacManagementEmptyCellValue from "../../components/CellEmptyValue";

const IacManagementTableResourceRowCellSpace = memo(() => {
  return (
    <IacManagementTableCell>
      <IacManagementEmptyCellValue />
    </IacManagementTableCell>
  );
});

IacManagementTableResourceRowCellSpace.displayName = "IacManagementTableResourceRowCellSpace";

export default IacManagementTableResourceRowCellSpace;
