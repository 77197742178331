import { External } from "components/icons/generated";
import Link from "ds/components/Link";
import { getCommmitDigitsCount } from "utils/commit";
import { generateBranchUrl } from "utils/urls";

import useStackListAnalytics from "../useStackListAnalytics";
import styles from "./styles.module.css";
import { CellComponentProps } from "./types";

type StackListItemCommitCellProps = CellComponentProps;

const StackListItemCommitCell = ({ stack }: StackListItemCommitCellProps) => {
  const {
    apiHost,
    branch,
    namespace,
    provider,
    repository,
    repositoryURL,
    trackedCommit,
    trackedBranchHead,
    trackedCommitSetBy,
  } = stack;

  const trackAnalytics = useStackListAnalytics();

  if (!apiHost) return null;

  if (trackedCommit) {
    const trackedCommitIsOutdated =
      trackedCommit && trackedBranchHead && trackedCommit.hash !== trackedBranchHead.hash;

    return (
      <div className={styles.trackedCommitWrapper}>
        <Link
          size="small"
          endIcon={External}
          target="_blank"
          href={trackedCommit.url}
          onPress={() => trackAnalytics("Commit link clicked")}
          tooltip={
            (trackedCommitSetBy || trackedCommitIsOutdated) && (
              <>
                {trackedCommitSetBy && `${trackedCommitSetBy}.`}
                {trackedCommitIsOutdated && (
                  <span className={styles.outdatedMessage}>
                    Stack's tracked commit is not equal to the head of the tracked branch.
                  </span>
                )}
              </>
            )
          }
        >
          <>
            {trackedCommitIsOutdated && <span className={styles.outdatedIndicator} />}
            {trackedCommit.hash.slice(0, getCommmitDigitsCount(provider))}
          </>
        </Link>
      </div>
    );
  }

  return (
    <Link
      size="small"
      endIcon={External}
      href={generateBranchUrl({
        apiHost,
        namespace,
        repository,
        repositoryURL,
        provider,
        branch,
      })}
      onPress={() => trackAnalytics("Commit link clicked")}
      tooltip={trackedCommitSetBy}
    >
      HEAD
    </Link>
  );
};

export default StackListItemCommitCell;
