import { ReactNode } from "react";
import { CSSTransition } from "react-transition-group";
import cx from "classnames";

import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";

import styles from "./styles.module.css";

type FadeTransitionProps = {
  children: ReactNode;
  visible: boolean;
};

const FadeTransition = ({ children, visible }: FadeTransitionProps) => {
  const { reducedMotion } = useTypedContext(ThemeContext);

  if (reducedMotion) {
    return visible ? children : null;
  }

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames={{
        enter: styles.enter,
        enterActive: cx(styles.enterActive),
        exit: styles.exit,
        exitActive: cx(styles.exitActive),
      }}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
};

export default FadeTransition;
