import { create, show, useModal } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { AzureIntegration } from "types/generated";
import { TrackAnalyticsEventProperties } from "shared/Analytics";

import { DELETE_AZURE_INTEGRATION } from "./gql";
import AzureIntegrationDeleteConfirmationContent from "./Content";

type DeleteConfirmationProps = {
  integration: AzureIntegration;
  trackAnalytics: (event: string, props?: TrackAnalyticsEventProperties) => void;
  onDeleteSuccess?: () => void;
  refetchQueriesOnDelete?: string[];
};

const AzureIntegrationDeleteConfirmation = create(
  ({
    integration,
    trackAnalytics,
    onDeleteSuccess,
    refetchQueriesOnDelete,
  }: DeleteConfirmationProps) => {
    const modal = useModal();
    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const [deleteIntegration, { loading }] = useMutation(DELETE_AZURE_INTEGRATION, {
      awaitRefetchQueries: true,
      refetchQueries: refetchQueriesOnDelete,
    });

    useEffect(() => {
      trackAnalytics("Open Delete Confirmation", { type: "azure" });
    }, [trackAnalytics]);

    const onConfirm = () => {
      deleteIntegration({
        variables: {
          id: integration.id,
        },
      })
        .then(({ data }) => {
          if (data?.azureIntegrationDelete?.id) {
            reportSuccess({ message: "Azure integration was successfully deleted" });
            modal.hide();
            onDeleteSuccess?.();
            trackAnalytics("Integration deleted", { type: "azure" });
          }
        })
        .catch(onError);
    };

    const onClose = () => {
      trackAnalytics("Cancel Delete", { type: "azure" });
    };

    return (
      <DeleteConfirmationModal
        title="Delete integration"
        size="large"
        onConfirm={onConfirm}
        onClose={onClose}
        isLoading={loading}
        isDismissable={!loading}
      >
        <AzureIntegrationDeleteConfirmationContent integration={integration} />
      </DeleteConfirmationModal>
    );
  }
);

export const showAzureIntegrationDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(AzureIntegrationDeleteConfirmation, props);
