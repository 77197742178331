import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUserInviteInputV2 } from "types/generated";

import { INVITE_MANAGED_USER_V2 } from "../gql";

const useInviteUserV2 = () => {
  const { onError } = useTypedContext(FlashContext);

  const [inviteManagedUser, { loading }] = useMutation(INVITE_MANAGED_USER_V2, {
    onError,
    refetchQueries: ["GetManagedUsers", "GetAccountSettings"],
  });

  const onCreate = (input: ManagedUserInviteInputV2) => {
    return inviteManagedUser({
      variables: {
        input,
      },
    });
  };

  return { onCreate, loading };
};

export default useInviteUserV2;
