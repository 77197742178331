export const generateExampleTrustPolicyStatement = (accountName: string, awsAccountId: string) => ({
  Version: "2012-10-17",
  Statement: [
    {
      Action: "sts:AssumeRole",
      Condition: {
        StringLike: {
          "sts:ExternalId": `${accountName}@*`,
        },
      },
      Effect: "Allow",
      Principal: {
        AWS: awsAccountId,
      },
    },
  ],
});
