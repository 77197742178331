import { useCallback, useLayoutEffect, useMemo, useRef } from "react";

import useDefaultWidthManager from "components/ResizeBar/useDefaultWidthManager";
import ResizeBar from "components/ResizeBar";

import styles from "./styles.module.css";

type UseResizerProps = {
  width: number;
  minWidth: number;
  maxWidth?: number;
  onResizeClb?: (newWidth: number) => void;
};

export const useResizer = ({ width, minWidth, maxWidth, onResizeClb }: UseResizerProps) => {
  const columnRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback((width: number) => {
    if (columnRef.current) {
      columnRef.current.style.width = `${width}px`;
    }
  }, []);

  useLayoutEffect(() => {
    handleResize(width);
  }, [handleResize, width]);

  const sizeProps = useMemo(
    () => ({
      initialWidth: width,
      minWidth: minWidth,
      maxWidth: maxWidth,
    }),
    [width, maxWidth, minWidth]
  );

  const { resizeBarProps } = useDefaultWidthManager({
    ...sizeProps,
    onWidthChange: handleResize,
  });

  return {
    columnRef,
    ResizerControl: (
      <ResizeBar
        className={styles.resizer}
        onResizeFinish={onResizeClb}
        {...sizeProps}
        {...resizeBarProps}
      />
    ),
  };
};
