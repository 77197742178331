import { Route, Routes, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import useLocalStorage from "@rehooks/local-storage";

import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import { hasSpaceManageAccess } from "utils/user";
import { AccountContext } from "views/AccountWrapper";
import TierInfo from "components/TierInfo";
import useTypedFlags from "hooks/useTypedFlags";
import { isAnsibleStackVendor } from "utils/stack";
import { canManageStacksAndRuns, hasAtLeastStackWriteAccess } from "shared/Stack/utils";
import useFavicon from "hooks/useFavicon";
import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import IacManagementResourceDetails from "views/Account/IacManagement/ResourceDetails";

import { StackGql, ParamTypes } from "./Types";
import PullRequests from "./PullRequests";
import Run from "./Run";
import Runs from "./Runs";
import StackIgnoredRuns from "./IgnoredRuns";
import StackSettings from "./Settings";
import Tasks from "./Tasks";
import Environment from "./Environment";
import Resources from "./Resources";
import StackResourcesNew from "./ResourcesNew";
import Outputs from "./Outputs";
import Notifications from "./Notifications";
import StackDependencies from "./Dependencies";
import StackDependenciesGraph from "./DependenciesGraph";
import { StackContext } from "./Context";
import { VIEW_STACK_LOCALSTORAGE_KEY } from "./constants";
import Contexts from "./Contexts";
import StackStateHistory from "./StateHistory";
import { GET_STACK } from "./gql";
import Hooks from "./Hooks";
import StackScheduling from "./Scheduling";
import StackPolicies from "./Policies";
import StackConfigManagement from "./ConfigManagement";

const Stack = () => {
  const { vcsEventsShowIgnoredRuns, resourcesRedesign } = useTypedFlags();

  const { viewer } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);
  const { stackId } = useParams<ParamTypes>();
  const stackUrl = useResolvedPath("..").pathname;
  const navigate = useNavigate();
  const [configurationManagementViewEnabled, setConfigurationManagementViewEnabled] =
    useLocalStorage(VIEW_STACK_LOCALSTORAGE_KEY, true);

  const handleSetConfigurationManagementViewEnabled = (enabled: boolean) => {
    setConfigurationManagementViewEnabled(enabled);
    if (enabled) {
      navigate("../configuration-management");
    } else {
      navigate("../resources");
    }
  };

  const { data, error, loading } = usePolledQuery<StackGql>(GET_STACK, {
    variables: {
      id: stackId,
    },
    onError,
    // FYI: `fetchPolicy: "no-cache"` is used to avoid cache collisions,
    // a stack could be partially cached with getting `searchStacks` query on the "Dependencies" tab, etc.
    fetchPolicy: "no-cache",
    pollingGroup: PollingIntervalGroups.Default,
  });

  useFavicon(data?.stack?.state);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  if (!data?.stack) {
    return <NotFoundPage />;
  }

  const stackContext = {
    // TODO: refactor it, split based on "dynamic" and "static" data or use "selector" pattern
    stack: data.stack,
    stackUrl,
    handleSetConfigurationManagementViewEnabled,
    configurationManagementViewEnabled,
    /*
        FYI: the "canManageSpace" is not useful for legacy stacks
             and is used mostly with additional checking
             of the "viewer.admin" (because access policies don't affect space access level)
             *****************************************************
             we can't add the "viewer.admin" check to the "canManageSpace" by default
             because we still have a few cases where we have
             to differentiate them (e.g. "StackSettings -> Integrations" component)
             that's why we have the "canManageStackAndRuns"
      */
    canManageSpace: hasSpaceManageAccess(data.stack?.spaceDetails?.accessLevel),
    canManageStackAndRuns: canManageStacksAndRuns(data.stack, viewer),
    hasIgnoredRunsAccess: vcsEventsShowIgnoredRuns,
    hasAtLeastWriteAccess: hasAtLeastStackWriteAccess(data.stack),
  };

  return (
    <StackContext.Provider value={stackContext}>
      {data.stack && (
        <>
          {data.stack.workerPool && (
            <TierInfo type="callout" variant="danger" feature={BillingTierFeature.PrivateWorkers}>
              This stack is set up to use a private worker pool, but the current plan does not
              support this functionality. Jobs will not be processed until you upgrade to a plan
              that supports private workers.
            </TierInfo>
          )}

          <Routes>
            <Route index element={<Runs />} />
            {vcsEventsShowIgnoredRuns && (
              <Route path="ignored-runs" element={<StackIgnoredRuns />} />
            )}
            <Route path="tasks" element={<Tasks />} />
            <Route path="prs" element={<PullRequests />} />
            <Route path="environment" element={<Environment />} />

            {!resourcesRedesign &&
              (!configurationManagementViewEnabled || !isAnsibleStackVendor(data.stack)) && (
                <Route path="resources" element={<Resources />} />
              )}
            {resourcesRedesign &&
              (!configurationManagementViewEnabled || !isAnsibleStackVendor(data.stack)) && (
                <Route path="resources" element={<StackResourcesNew />}>
                  <Route path="resource/:resourceId" element={<IacManagementResourceDetails />} />
                </Route>
              )}
            {configurationManagementViewEnabled && isAnsibleStackVendor(data.stack) && (
              <Route path="configuration-management" element={<StackConfigManagement />} />
            )}

            <Route path="outputs" element={<Outputs />} />

            <Route path="settings">
              <Route index path="*" element={<StackSettings />} />
            </Route>

            <Route path="hooks" element={<Hooks />} />
            <Route path="contexts" element={<Contexts />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="run/:runId">
              <Route index path="*" element={<Run />} />
            </Route>
            <Route path="dependencies" element={<StackDependencies />} />
            <Route path="dependencies/graph" element={<StackDependenciesGraph />} />
            <Route path="state-history" element={<StackStateHistory />} />
            <Route path="scheduling" element={<StackScheduling />} />
            <Route path="policies" element={<StackPolicies />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </>
      )}
    </StackContext.Provider>
  );
};

export default Stack;
