import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import { ButtonProps } from "ds/components/Button";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useTypedContext from "hooks/useTypedContext";
import { NamedWebhooksIntegration, SpaceAccessLevel } from "types/generated";
import { AccountContext } from "views/AccountWrapper";

type WebhookOptionDropdownProps = {
  id: NamedWebhooksIntegration["id"];
  space: NamedWebhooksIntegration["space"];
  dotSize?: ButtonProps["size"];
  onDeleteClick: () => void;
};

const WebhookOptionDropdown = (props: WebhookOptionDropdownProps) => {
  const { dotSize, id, space, onDeleteClick } = props;
  const { viewer } = useTypedContext(AccountContext);

  const canManageWebhook =
    space.accessLevel === SpaceAccessLevel.Admin || space.accessLevel === SpaceAccessLevel.Write;

  return (
    <DropdownMenuEllipsis tooltip="Webhook actions" dotsSize={dotSize}>
      <CopyFieldDropdownMenuItem title="Copy webhook ID" value={id} />

      {(viewer.admin || canManageWebhook) && (
        <>
          <DropdownMenuItem href={`/webhook/${id}/edit`}>Edit webhook</DropdownMenuItem>
          <DropdownMenuItem onAction={onDeleteClick} danger>
            Delete webhook
          </DropdownMenuItem>
        </>
      )}
    </DropdownMenuEllipsis>
  );
};

export default WebhookOptionDropdown;
