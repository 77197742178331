import { ReactNode } from "react";

import Box from "ds/components/Box";

import IacManagementTableCell, { IacManagementTableCellProps } from "../Cell";
import styles from "../styles.module.css";

type IacManagementTableRowServiceCellProps = {
  children?: ReactNode;
} & Pick<IacManagementTableCellProps, "stickyLeft" | "stickyRight">;

const IacManagementTableRowServiceCell = ({
  children,
  ...props
}: IacManagementTableRowServiceCellProps) => {
  return (
    <IacManagementTableCell direction="column" padding="0" align="stretch" {...props}>
      <Box padding="x-large x-large x-large 0" className={styles.serviceCellWrapper}>
        {children}
      </Box>
    </IacManagementTableCell>
  );
};

export default IacManagementTableRowServiceCell;
