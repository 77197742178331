import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SortDescriptor } from "react-aria-components";

import { getSearchQuery } from "components/SearchInput/helpers";

import { sortGroups } from "./sort";
import { IdpGroup } from "../types";

export const useFilteredEntities = (entities: IdpGroup[], sortDescriptor: SortDescriptor) => {
  const [searchParams] = useSearchParams();
  const searchInput = getSearchQuery(searchParams);

  return useMemo(() => {
    let filteredEntities = [...entities];
    // Apply search filter if exists
    filteredEntities = filteredEntities.filter((entity) =>
      entity.groupName.toLowerCase().includes(searchInput.toLowerCase())
    );

    // Apply sorting if descriptor exists
    if (sortDescriptor.column) {
      filteredEntities = sortGroups(filteredEntities, sortDescriptor);
    }

    return filteredEntities;
  }, [entities, searchInput, sortDescriptor]);
};
