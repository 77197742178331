import { ReactNode } from "react";
import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";
import useCellKeyboardNavigation from "ds/components/Table/useKeyboardNavigation/useCellKeyboardNavigation";

import styles from "./styles.module.css";

export type TableCellProps = {
  children: ReactNode;
  role?: string;
  stickyRight?: boolean;
  stickyLeft?: boolean;
} & BoxProps;

const TableCell = ({
  children,
  role = "gridcell",
  stickyRight,
  stickyLeft,
  className,
  style,
  ...boxProps
}: TableCellProps) => {
  const keyboardProps = useCellKeyboardNavigation();

  return (
    <div
      {...keyboardProps}
      className={cx(
        styles.cell,
        {
          [styles.stickyRight]: stickyRight,
          [styles.stickyLeft]: stickyLeft,
        },
        className
      )}
      style={style}
      role={role}
    >
      <Box className={styles.cellWrapper} padding="x-large" fullWidth {...boxProps}>
        {children}
      </Box>
      <span className={styles.focusOutline} aria-hidden />
    </div>
  );
};

TableCell.displayName = "DS.Table.Cell";

export default TableCell;
