import { pluralize } from "shared/Pluralize";

import { MIN_DURATION_IN_SECONDS } from "./constants";
import { MAX_DURATION_IN_SECONDS } from "./constants";
import { MAX_DURATION_IN_SECONDS_FOR_WORKER } from "./constants";

export const getDurationValidator =
  (generateCredentialsInWorker: boolean) => (valueInSeconds: number) => {
    const maxDuration = generateCredentialsInWorker
      ? MAX_DURATION_IN_SECONDS_FOR_WORKER
      : MAX_DURATION_IN_SECONDS;

    const hours = Math.floor(maxDuration / 3600);

    if (valueInSeconds < MIN_DURATION_IN_SECONDS || valueInSeconds > maxDuration) {
      return `Duration must be between 15 minutes and ${hours} ${pluralize("hour", hours)}`;
    }

    return true;
  };
