import Box from "../Box";
import LoadingIndicator from "../LoadingIndicator";
import Typography from "../Typography";

type ComboBoxEmptyCollectionProps = {
  isLoading?: boolean;
};

const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

const events = {
  /**
   * react-aria somehow missed to detect empty state as a click within the combobox, unfocusing it.
   * This workaround prevents the combobox from unfocusing when clicking on the empty state.
   * Unfocusing was annoying as on the mousedown it prefilled all items as there was no search entry.
   * Mouse up selected the "appeared" element.
   */
  onMouseDown: stopPropagation,
  onMouseUp: stopPropagation,
  onClick: stopPropagation,
  onDoubleClick: stopPropagation,
};

const ComboBoxEmptyCollection = ({ isLoading }: ComboBoxEmptyCollectionProps) =>
  isLoading ? (
    <Box fullWidth justify="center" {...events}>
      <LoadingIndicator size="large" loading />
    </Box>
  ) : (
    <Box padding="small" {...events}>
      <Typography tag="span" variant="p-body4">
        No options found.
      </Typography>
    </Box>
  );

ComboBoxEmptyCollection.displayName = "DS.ComboBox.EmptyCollection";

export default ComboBoxEmptyCollection;
