import { BadgeVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { UserRole } from "types/generated";

type UserRoleConfig = {
  variant: BadgeVariants;
  text: string;
};

const USER_ROLES: Record<UserRole, UserRoleConfig> = {
  [UserRole.Admin]: { variant: "green", text: "Admin" },
  [UserRole.Owner]: { variant: "yellow", text: "Owner" },
  [UserRole.User]: { variant: "blue", text: "User" },
};

type UsersListItemRoleBadgeProps = {
  role: UserRole;
  className?: string;
};

const UsersListItemRoleBadge = ({ role, className }: UsersListItemRoleBadgeProps) => {
  return (
    <BadgeNext
      variant={USER_ROLES[role].variant}
      className={className}
      text={USER_ROLES[role].text}
      type="weak"
    />
  );
};

export default UsersListItemRoleBadge;
// TODO [AAC] create UsersListItemRoleBadge story before removing old list
