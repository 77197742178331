import cx from "classnames";
import { AriaRole, forwardRef, useId } from "react";

import Box from "ds/components/Box";
import { capitalizeFirstLetter } from "utils/strings";
import { ThemeContext } from "views/Theme";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { CollapsiblePanelContext } from "./Context";

type CollapsiblePanelProps = {
  className?: string;
  isCollapsed: boolean;
  onToggle: () => void;
  children: React.ReactNode;
  withTransition?: boolean;
  variant?: "default" | "section";
  noBorder?: boolean;
  role?: AriaRole;
};

const CollapsiblePanel = forwardRef(function CollapsiblePanel(
  {
    isCollapsed,
    children,
    onToggle,
    withTransition,
    className,
    variant = "default",
    noBorder,
    role,
  }: CollapsiblePanelProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const contentId = useId();

  const { reducedMotion } = useTypedContext(ThemeContext);

  return (
    <CollapsiblePanelContext.Provider value={{ isCollapsed, onToggle, contentId }}>
      <Box
        direction="column"
        fullWidth
        role={role}
        className={cx(
          styles.collapsiblePanel,
          className,
          {
            [styles.withTransition]: reducedMotion ? false : withTransition,
            [styles.noBorder]: noBorder,
          },
          styles[`variant${capitalizeFirstLetter(variant)}`]
        )}
        ref={ref}
      >
        {children}
      </Box>
    </CollapsiblePanelContext.Provider>
  );
});

export default CollapsiblePanel;
