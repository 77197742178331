import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";

export const VCS_INTEGRATION_VIEW_FILTERS_KEY = "VCSIntegrationViewFiltersKey";

export const ITEMS_LIMIT = 50;

export enum SortableColumns {
  TYPE = "isModule",
  NAME = "name",
  SPACE = "space",
}

export const INITIAL_SORT_OPTION = SortableColumns.NAME;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Desc;

export const COLUMN_ORDER = "12rem minmax(12rem, 4.5fr) minmax(9rem, 1fr)";
export const COLUMN_GAP: Spacing = "large";
