import BadgeNext from "ds/components/BadgeNext";

import { BlueprintVersion } from "../types";

const getBadgeVariant = (status: BlueprintVersion["status"]) => {
  switch (status) {
    case "draft":
      return "gray";
    case "published":
      return "green";
    case "deprecated":
      return "red";
    default:
      return "gray";
  }
};

type BlueprintVersionStatusBadgeProps = {
  status: BlueprintVersion["status"];
};

const BlueprintVersionStatusBadge = ({ status }: BlueprintVersionStatusBadgeProps) => {
  return <BadgeNext type="strong" variant={getBadgeVariant(status)} text={status} />;
};

export default BlueprintVersionStatusBadge;
