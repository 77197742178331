import { memo } from "react";

import TableHead from "ds/components/Table/Head";

import { RenderHeadRowContent } from "../types";
import useIacTableStore from "../useIacTableStore";
import { selectVisibleColumns } from "../useIacTableStore/tableMetaSelectors";

type IaCManagementTableHeadProps = {
  renderHeadRow: RenderHeadRowContent;
};

const IaCManagementTableHead = ({ renderHeadRow }: IaCManagementTableHeadProps) => {
  const columnsList = useIacTableStore(selectVisibleColumns);

  return <TableHead>{renderHeadRow({ columns: columnsList })}</TableHead>;
};

export default memo(IaCManagementTableHead);
