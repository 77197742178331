import { useCallback, useRef } from "react";

// apply sticky transform to the element in most efficient way with rAF
const useStickyTransform = () => {
  const rafIdRef = useRef<number | null>(null);
  const needsTransformRef = useRef(false);
  const offsetTopRef = useRef(0);

  const applyTransform = useCallback((element: HTMLElement | null) => {
    if (element && needsTransformRef.current) {
      element.dataset.isTransformed = String(offsetTopRef.current !== 0);
      element.style.transform = `translateY(${offsetTopRef.current}px)`;
      needsTransformRef.current = false;
    }
    rafIdRef.current = null;
  }, []);

  const scheduleUpdate = useCallback(
    (element: HTMLElement | null) => {
      if (rafIdRef.current === null) {
        rafIdRef.current = requestAnimationFrame(() => applyTransform(element));
      }
    },
    [applyTransform]
  );

  const updateTransform = useCallback(
    (element: HTMLElement | null, scrollTop: number, nextAfterStickyPosition?: number) => {
      if (!nextAfterStickyPosition) return;

      const offset = nextAfterStickyPosition - scrollTop;
      offsetTopRef.current = offset < 0 ? offset : 0;
      needsTransformRef.current = true;
      scheduleUpdate(element);
    },
    [scheduleUpdate]
  );

  const cleanup = useCallback(() => {
    if (rafIdRef.current !== null) {
      cancelAnimationFrame(rafIdRef.current);
      rafIdRef.current = null;
    }
  }, []);

  return {
    updateTransform,
    cleanup,
  };
};

export default useStickyTransform;
