import { useRunSummary } from "components/RunSummarization/hooks/useRunSummary";
import RunSummarizationBox from "components/RunSummarization/RunSummarizationBox";
import RunSummarizationTrigger from "components/RunSummarization/RunSummarizationTrigger";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import useTypedFlags from "hooks/useTypedFlags";

import PerformTaskAgainButton from "../PerformTaskAgainButton";
import RetryRunButton from "../RetryRunButton";

type UnfinishedRunActionsProps = {
  stackId: string;
  runId: string;
  canRetry: boolean;
  runRetryBlocker: string | null;
  isTaskRun: boolean;
  isModuleRun: boolean;
  canPerformAgain: boolean;
  runTaskCommand: string | null;
};

const UnfinishedRunActions = ({
  stackId,
  runId,
  canRetry,
  runRetryBlocker,
  isTaskRun,
  isModuleRun,
  canPerformAgain,
  runTaskCommand,
}: UnfinishedRunActionsProps) => {
  const { runSummarizationAi } = useTypedFlags();
  const {
    acceptedTermsAndConditionsAI,
    canTriggerSummary,
    date,
    loading,
    showSummary,
    showTrigger,
    summary,
    summaryContainerRef,
    trigger,
  } = useRunSummary();

  const disableAiTrigger = !canTriggerSummary || !acceptedTermsAndConditionsAI;

  const generateTooltipMessage = () => {
    if (!acceptedTermsAndConditionsAI) {
      return "An account admin must accept the terms and conditions before using Saturnhead assist";
    }

    return (
      <Box direction="column" gap="small">
        <Typography tag="p">Ask Saturnhead</Typography>
        <Typography tag="p">
          Saturnhead combines information from <b>all execution phases</b> to give a comprehensive
          run summary, which may take longer to process than the phase summaries.
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box gap="medium">
        {canPerformAgain && <PerformTaskAgainButton command={runTaskCommand} stackId={stackId} />}
        {(canRetry || (runRetryBlocker && !isTaskRun && !isModuleRun)) && (
          <RetryRunButton runId={runId} stackId={stackId} runRetryBlocker={runRetryBlocker} />
        )}
        {runSummarizationAi && showTrigger && (
          <RunSummarizationTrigger
            onPress={trigger}
            disabled={disableAiTrigger}
            tooltipText={generateTooltipMessage()}
          >
            Explain
          </RunSummarizationTrigger>
        )}
      </Box>
      {runSummarizationAi && showSummary && (
        <RunSummarizationBox
          type="run"
          ref={summaryContainerRef}
          content={summary}
          loading={loading}
          date={date}
        />
      )}
    </>
  );
};

export default UnfinishedRunActions;
