import { gql, TypedDocumentNode } from "@apollo/client";

import type { MutationRoleDeleteArgs, Role } from "types/generated";

type RoleDeleteData = { roleDelete: Pick<Role, "id"> };
export const ROLE_DELETE: TypedDocumentNode<RoleDeleteData, MutationRoleDeleteArgs> = gql`
  mutation RoleDelete($id: ID!) {
    roleDelete(id: $id) {
      id
    }
  }
`;
