import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import { pluralize, pluralizeVerb } from "shared/Pluralize";
import Link from "ds/components/Link";

import { DELETE_EXTERNAL_INTEGRATION } from "../../gql";

type DeleteConfirmationProps = {
  id: string;
  name: string;
  refetchQueriesOnDelete?: string[];
  attachedEntities: number;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  name,
  refetchQueriesOnDelete,
  attachedEntities,
}: DeleteConfirmationProps) {
  const modal = useModal();
  const navigate = useNavigate();

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [deleteIntegration, { loading, data }] = useMutation(DELETE_EXTERNAL_INTEGRATION, {
    refetchQueries: refetchQueriesOnDelete,
    awaitRefetchQueries: true,
    variables: {
      id,
    },
  });

  const handleDeleteIntegration = () => {
    deleteIntegration()
      .then(() => {
        modal.resolve(true);
        modal.hide();
        reportSuccess({ message: "Integration successfully deleted" });
      })
      .catch(onError);
  };

  const navigateToUsedByPage = () => {
    modal.hide();
    navigate(`/integration/${id}`);
  };

  return (
    <DeleteConfirmationModal
      title="Delete integration"
      onConfirm={handleDeleteIntegration}
      isLoading={loading}
      isDismissable={!loading && !data?.externalIntegrationDelete?.id}
    >
      <ConfirmationModalMessage>
        <Box gap="large" direction="column">
          <span>
            Are you sure you want to delete <strong>{name}</strong>?
          </span>
          {attachedEntities > 0 && (
            <span>
              <strong>{attachedEntities}</strong> {pluralize("blueprint", attachedEntities)} still{" "}
              {pluralizeVerb("use", attachedEntities)} this integration. {` `}
              <Link onPress={navigateToUsedByPage}>Go to Used by page</Link>
            </span>
          )}
        </Box>
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
