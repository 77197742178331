import { useMemo } from "react";

import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI } from "components/Filters/helpers";
import { SearchQueryPredicate } from "types/generated";

import { AnsibleGroupBy } from "./types";
import { LayoutMode } from "./types";
import useSearchAnsibleHosts from "./useSearchAnsibleHosts";
import useSearchAnsibleTasks from "./useSearchAnsibleTasks";
import { ANSIBLE_ITEMS_LIMIT, DEPRECATED_FILTER_NAMES } from "./constants";

const useSearchAnsibleNodes = (
  groupBy: AnsibleGroupBy,
  layoutMode: LayoutMode,
  forcedPredicate?: SearchQueryPredicate
) => {
  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams);

    const filteredPredicates = [...(predicatesMap?.values() || [])].filter(
      (value) => !DEPRECATED_FILTER_NAMES.includes(value.field)
    );

    if (forcedPredicate) {
      return [...filteredPredicates, forcedPredicate];
    }

    return filteredPredicates;
  }, [urlParams, forcedPredicate]);

  const input = useMemo(
    () => ({
      first: ANSIBLE_ITEMS_LIMIT,
      after: null,
      requestedPage: null,
      fullTextSearch: searchInput,
      predicates,
      orderBy: null,
    }),
    [predicates, searchInput]
  );

  const hostNodes = useSearchAnsibleHosts(input, layoutMode, groupBy === AnsibleGroupBy.Tasks);
  const taskNodes = useSearchAnsibleTasks(input, layoutMode, groupBy === AnsibleGroupBy.Hosts);

  if (groupBy === AnsibleGroupBy.Tasks) {
    return taskNodes;
  }

  if (groupBy === AnsibleGroupBy.Hosts) {
    return hostNodes;
  }

  return {
    hasNextPageToLoad: undefined,
    isPageEmpty: true,
    predicates,
    nodes: [],
    hasNoFilteringResults: false,
    isPageLoading: false,
    error: null,
    loadMoreItems: async () => undefined,
  };
};

export default useSearchAnsibleNodes;
