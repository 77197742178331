import cx from "classnames";
import { useCallback, useState } from "react";
import { Column } from "react-aria-components";
import { shallow } from "zustand/shallow";

import { ChevronRight } from "components/icons/generated";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Toggle from "ds/components/Toggle";

import styles from "./styles.module.css";
import useSelectionStore from "./useSelectionStore";

const COLUMN_WIDTH = 70;
const NO_OPTIONS_COLUMN_WIDTH = 52;

const getTooltip = (isSelected: boolean) =>
  isSelected ? "Unselect all items" : "Select all items on this page";

type TableColumnSelectAllProps<SelectAllType = string> = {
  allIds: string[];
  options?: Array<{ id: SelectAllType; title: string }>;
};
const TableColumnSelectAll = ({ allIds, options }: TableColumnSelectAllProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [addSelectedIds, unselectIds, selected, isSelectionOn, setSelectedAllOption] =
    useSelectionStore(
      ({ unselectIds, selected, setSelectedAllOption, addSelectedIds }) => [
        addSelectedIds,
        unselectIds,
        allIds.every((id) => selected.has(id)),
        selected.size > 0 && allIds.some((id) => selected.has(id)),
        setSelectedAllOption,
      ],
      shallow
    );

  const isIndeterminate = isSelectionOn && !selected;

  const onChange = useCallback(
    () => (selected || isIndeterminate ? unselectIds(allIds) : addSelectedIds(allIds)),
    [addSelectedIds, unselectIds, isIndeterminate, selected, allIds]
  );

  const columnWidth = options?.length ? COLUMN_WIDTH : NO_OPTIONS_COLUMN_WIDTH;

  return (
    <Column
      textValue="Select all items"
      id="selection"
      className={cx(styles.checkboxColumn, isSelectionOn && styles.checkboxColumnSticky)}
      maxWidth={columnWidth}
      minWidth={columnWidth}
      width={columnWidth}
    >
      {!!allIds.length && (
        <Box align="center" gap="small">
          <Toggle
            isIndeterminate={isIndeterminate}
            tooltip={getTooltip}
            slot={null}
            variant="checkbox"
            aria-label="Select all items on this page"
            isSelected={selected}
            onChange={onChange}
          />
          {options?.length && (
            <DropdownMenu
              onOpenChange={setDropdownOpen}
              isOpen={isDropdownOpen}
              triggerComponent={
                <ButtonIcon
                  iconRotate={isDropdownOpen ? "270" : "90"}
                  icon={ChevronRight}
                  variant="ghost"
                >
                  Select all options
                </ButtonIcon>
              }
            >
              {options.map(({ id, title }) => (
                <DropdownMenuItem
                  key={id}
                  onAction={() => {
                    setSelectedAllOption?.(id);
                  }}
                >
                  {title}
                </DropdownMenuItem>
              ))}
            </DropdownMenu>
          )}
        </Box>
      )}
    </Column>
  );
};

export default TableColumnSelectAll;
