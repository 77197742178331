import { useEffect, useState } from "react";

import Typography from "ds/components/Typography";
import FormLoading from "components/form/components/loading";
import CardWrapper from "components/CardWrapper";
import EmptyState from "ds/components/EmptyState";
import DocumentationButton from "components/DocumentationButton";
import { EmptystateCloudColored } from "components/icons/generated";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import useErrorHandlerSourceCode from "components/Forms/SourceCode/useErrorHandlerSourceCode";
import useTypedContext from "hooks/useTypedContext";
import { getDocsUrl } from "utils/getDocsUrl";
import AttachCloudDetails from "components/AttachCloud/Details";
import AttachCloudForm from "components/AttachCloud/Form";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";
import useAttachableCloudIntegrations from "shared/CloudIntegration/useAttachableCloudIntegrations";
import { AttachCloudEntityType } from "components/AttachCloud/Form/types";

import NewModuleFooter from "../Footer";
import NewModuleAttachCloudDocumentation from "./Documentation";
import useAttachedCloudIntegration from "./hooks/useAttachedCloudIntegration";
import { ModuleCreationWizardStep } from "../types";
import { ModuleFormContext } from "../context";
import { useNewModuleAnalyticsSegmentEvent } from "../useNewModuleAnalyticsSegmentEvent";

const refetchQueries = ["GetAttachedModuleIntegrations"];

const NewModuleAttachCloud = () => {
  const { setAccountOrAuthErrorContent, createdModuleId, formData } =
    useTypedContext(ModuleFormContext);

  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const onSuccessDetach = (type: CLOUD_INTEGRATIONS) =>
    trackSegmentEvent("Cloud integration detached", { type });

  const onSuccess = () => {
    trackSegmentEvent("Cloud integration attached", { type: cloud });
  };

  const {
    loading: cloudConfigLoading,
    cloudConfig,
    attachableAzureIntegrations,
    attachableAwsIntegrations,
    hasData,
    refetch,
    refetching,
    error,
  } = useAttachableCloudIntegrations(formData[ModuleCreationWizardStep.Vcs].space);

  const [cloud, setCloud] = useState<CLOUD_INTEGRATIONS>(cloudConfig[0].type);

  useEffect(() => {
    setCloud(cloudConfig[0].type);
  }, [cloudConfig]);

  useErrorHandlerSourceCode(setAccountOrAuthErrorContent, error);

  const { loading: integrationLoading, ...integration } =
    useAttachedCloudIntegration(createdModuleId);

  if (cloudConfigLoading || integrationLoading) {
    return <FormLoading />;
  }

  const hasNoClouds =
    hasData &&
    !attachableAwsIntegrations.length &&
    !attachableAzureIntegrations.length &&
    (!integration.attachedIntegration || !integration.attachedIntegrationType);

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Attach cloud integration (optional)
        </Typography>

        {hasNoClouds ? (
          <Box direction="column" gap="large" margin="large 0 0 0">
            <Banner variant="info">
              You can only attach integrations from the current space and parent spaces that you
              inherit from.
            </Banner>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateCloudColored}
                title="You do not have any cloud integrations yet"
                caption={
                  <>
                    Cloud Integrations are used to dynamically generate short-lived credentials to
                    authenticate to cloud providers (AWS/Azure/GCP currently supported). In this way
                    you avoid using static credentials and by doing so, you reduce breaches.
                    <br /> Read more in the Documentation.
                  </>
                }
              >
                <DocumentationButton
                  to={getDocsUrl("/integrations/cloud-providers")}
                  label="Documentation"
                />
              </EmptyState>
            </CardWrapper>
          </Box>
        ) : (
          <>
            <Typography
              tag="p"
              variant="p-body2"
              margin="small 0 x-large 0"
              align="center"
              color="secondary"
            >
              Integrate module with your cloud provider
            </Typography>

            {integration.attachedIntegration && integration.attachedIntegrationType ? (
              <AttachCloudDetails
                cloudConfig={cloudConfig}
                refetchQueries={refetchQueries}
                onSuccessDetach={() => onSuccessDetach(integration.attachedIntegrationType)}
                {...integration}
              />
            ) : (
              <>
                <Banner variant="info">
                  You can only attach integrations from the current space and parent spaces that you
                  inherit from.
                </Banner>
                <AttachCloudForm
                  entityId={createdModuleId}
                  entityType={AttachCloudEntityType.Module}
                  hasData={hasData}
                  refetch={refetch}
                  refetching={refetching}
                  setCloud={setCloud}
                  cloud={cloud}
                  cloudConfig={cloudConfig}
                  attachableAzureIntegrations={attachableAzureIntegrations}
                  attachableAwsIntegrations={attachableAwsIntegrations}
                  refetchQueries={refetchQueries}
                  onSuccess={onSuccess}
                />
              </>
            )}
          </>
        )}
      </FullScreenModalBody>

      <NewModuleAttachCloudDocumentation cloud={cloud}>
        {({ body, link }) => (
          <NewModuleFooter
            documentationLink={link}
            documentationTitle="Cloud integration"
            documentationBody={body}
          />
        )}
      </NewModuleAttachCloudDocumentation>
    </>
  );
};

export default NewModuleAttachCloud;
