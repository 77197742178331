import { ReactNode } from "react";

import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import Tooltip from "ds/components/Tooltip";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import { IconComponent } from "types/Icon";

type IacManagementItemValueProps = {
  tooltip?: ReactNode;
  children?: ReactNode;
  icon?: IconComponent;
};

const IacManagementItemValue = ({ tooltip, children, icon }: IacManagementItemValueProps) => {
  return (
    <Tooltip
      active={!!tooltip}
      on={(tooltipProps) => (
        <Box {...tooltipProps} justify="start" gap="small">
          {icon && <Icon src={icon} noShrink />}
          {children && (
            <TextEllipsis tooltipPlacement="bottom" tooltip={children}>
              {(props) => (
                <Typography {...props} tag="p" variant="p-body3">
                  {children}
                </Typography>
              )}
            </TextEllipsis>
          )}
        </Box>
      )}
    >
      {tooltip}
    </Tooltip>
  );
};

export default IacManagementItemValue;
