import React, { ButtonHTMLAttributes, forwardRef } from "react";
import cx from "classnames";

import { Spinner } from "components/Spinner";
import Icon from "ds/components/Icon";

import styles from "./styles/button.module.css";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  full?: boolean;
  pill?: boolean;
  left?: boolean;
  right?: boolean;
  sm?: boolean;
  sm2?: boolean;
  md?: boolean;
  danger?: boolean;
  success?: boolean;
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
  flex?: boolean;
};

/**
 * @deprecated use DS Button instead
 */
const Button = forwardRef(function Button(
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    children,
    onClick = () => undefined,
    type = "button",
    className = "",
    full = false,
    pill = false,
    left = false,
    right = false,
    sm = false,
    sm2 = false,
    md = false,
    danger = false,
    success = false,
    disabled = false,
    loading = false,
    flex,
    id,
  } = props;

  const callback = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (type !== "submit") {
      e.preventDefault();
    }
    onClick(e);
  };

  return (
    <button
      type={type}
      className={cx(styles.button, styles["buttonDefault"], className, {
        [styles["buttonDisabled"]]: disabled && !loading,
        [styles["buttonFull"]]: full,
        [styles["buttonPill"]]: pill,
        [styles["buttonLeft"]]: left,
        [styles["buttonRight"]]: right,
        [styles["buttonSm"]]: sm,
        [styles["buttonSm2"]]: sm2,
        [styles["buttonMd"]]: md,
        [styles["buttonDanger"]]: danger,
        [styles["buttonSuccess"]]: success,
        [styles["buttonFlex"]]: flex,
      })}
      onClick={callback}
      disabled={disabled}
      id={id}
      ref={ref}
    >
      {loading ? <Icon src={Spinner} className={styles.spinner} size="large" /> : children}
    </button>
  );
});

export default Button;
