import Box from "ds/components/Box";

import BlueprintVersionStatusBadge from "../VersionStatusBadge";
import { CellComponentProps } from "./types";

type BlueprintVersionsListItemStatusCellProps = CellComponentProps;

const BlueprintVersionsListItemStatusCell = ({
  version,
}: BlueprintVersionsListItemStatusCellProps) => {
  return (
    <Box fullWidth>
      <BlueprintVersionStatusBadge status={version.status} />
    </Box>
  );
};

export default BlueprintVersionsListItemStatusCell;
