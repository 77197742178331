import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import { BlueprintIntegrationAttachment } from "types/generated";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { INTEGRATION_USED_BY_COLUMN_ID } from "../constants";
import IntegrationsListItemSpaceCell from "./SpaceCell";
import IntegrationsListItemNameCell from "./NameCell";

const CELLS = {
  [INTEGRATION_USED_BY_COLUMN_ID.NAME]: IntegrationsListItemNameCell,
  [INTEGRATION_USED_BY_COLUMN_ID.SPACE]: IntegrationsListItemSpaceCell,
};

type IntegrationListItemProps = {
  blueprint: BlueprintIntegrationAttachment;
};

const IntegrationUsedByListItem = (props: IntegrationListItemProps) => {
  const { blueprint } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  if (!blueprint.id) {
    return null;
  }

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as INTEGRATION_USED_BY_COLUMN_ID];

        const uniqueKey = `${id}-${blueprint.id}`;

        const childrenComponent = <Component blueprint={blueprint} />;

        return (
          <TableCell key={uniqueKey} id={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(IntegrationUsedByListItem);
