import { ReactNode, useCallback } from "react";

import { InfoCircle } from "components/icons/generated";
import ListContentWrapper from "components/ListContentWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import LinkButton from "ds/components/Button/LinkButton";
import ButtonIcon from "ds/components/ButtonIcon";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTypedContext from "hooks/useTypedContext";

import { SettingsContext } from "../Context";
import { getManagementStrategy } from "../helpers";
import SeatLimitsNotificationLoginPolicies from "../SeatLimitsNotification/LoginPolicies";
import { UserManagementActivationStatus } from "../types";
import { LOGIN_POLICY_INACTIVE_INFO_STORAGE_KEY } from "./constants";
import { showLoginPoliciesCreateDrawer } from "./CreateDrawer";
import { showLoginPoliciesInfoDrawer } from "./InfoDrawer";

type LoginPoliciesPageLayoutProps = {
  children: ReactNode;
  takenPolicyNames: string[];
};

const LoginPoliciesPageLayout = ({ children, takenPolicyNames }: LoginPoliciesPageLayoutProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);

  const isLoginPolicyActive = activationStatus === UserManagementActivationStatus.INACTIVE;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationLoginPolicies,

    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const openInfoDrawer = useCallback(() => {
    trackSegmentAnalyticsEvent("Info Click");
    showLoginPoliciesInfoDrawer();
  }, [trackSegmentAnalyticsEvent]);

  const openCreateDrawer = useCallback(() => {
    trackSegmentAnalyticsEvent("Create Policy Start");
    showLoginPoliciesCreateDrawer({
      activationStatus,
      takenPolicyNames,
    });
  }, [activationStatus, takenPolicyNames, trackSegmentAnalyticsEvent]);

  return (
    <>
      {!isLoginPolicyActive && (
        <Callout
          title="Login policy is inactive"
          variant="warning"
          storageKey={LOGIN_POLICY_INACTIVE_INFO_STORAGE_KEY}
        >
          Login policies are not evaluated when user management is active. Change management
          strategy to use login policy.
          <FeedbackActions>
            <LinkButton to="/settings/management-strategy/" variant="contrast" size="small">
              Choose strategy
            </LinkButton>
          </FeedbackActions>
        </Callout>
      )}
      <SeatLimitsNotificationLoginPolicies />
      <ViewHeader firstLevel>
        <Box align="center" __deprecatedGap="1.1rem">
          <ViewHeaderTitle tag="h2">Login policy</ViewHeaderTitle>
          <ButtonIcon onPress={openInfoDrawer} icon={InfoCircle} variant="ghost">
            Open info drawer
          </ButtonIcon>
          <BadgeNext
            variant={isLoginPolicyActive ? "green" : "gray"}
            type="strong"
            text={isLoginPolicyActive ? "ACTIVE" : "INACTIVE"}
          />
        </Box>
        <ViewHeaderWrapper direction="row">
          {openCreateDrawer && (
            <Button onPress={openCreateDrawer} variant="primary">
              Create policy
            </Button>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>
      <ListContentWrapper>{children}</ListContentWrapper>
    </>
  );
};

export default LoginPoliciesPageLayout;
