import { isEqual } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import BeforeAfterCommands from "components/BeforeAfterCommands";
import { Commands } from "components/BeforeAfterCommands/types";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Counter from "ds/components/Counter";
import { useToggle } from "hooks/useToggle";
import useTypedContext from "hooks/useTypedContext";
import { phases } from "utils/hooks";

import { ContextContext } from "../Context";
import styles from "./styles.module.css";
import { ContextHooksFormFields, ContextHooksListItemRegularProps } from "./types";

const ContextHooksItem = ({
  item,
  onSave,
  setChangedHooks,
}: ContextHooksListItemRegularProps & {
  setChangedHooks: (cb: (disabled: Set<string>) => Set<string>) => void;
}) => {
  const { canManageContext } = useTypedContext(ContextContext);
  const { setValue, watch } = useFormContext<ContextHooksFormFields>();
  const allCommands = watch("hooks");

  const [isCollapsed, handleToggle] = useToggle(true);
  const [commands, setCommands] = useState<Commands>(allCommands[item]);

  const commandsCounter = useMemo(() => {
    return Object.values(commands).flat().length;
  }, [commands]);

  const handleCommandsChange = (commands: Commands) => {
    if (commands.before) {
      setCommands((prevState: Commands) => ({
        ...prevState,
        before: commands.before,
      }));
    }

    if (commands.after) {
      setCommands((prevState: Commands) => ({
        ...prevState,
        after: commands.after,
      }));
    }
  };

  const handleSave = () => {
    if (commands.before) {
      setValue(`hooks.${item}.before`, commands.before);
    }

    if (commands.after) {
      setValue(`hooks.${item}.after`, commands.after);
    }

    onSave(handleToggle);
  };

  const isDataChanged = !isEqual(allCommands[item], commands);

  useEffect(() => {
    setChangedHooks((prevSet) => {
      const newSet = new Set(prevSet);
      if (newSet.has(item) && !isDataChanged) {
        newSet.delete(item);
      } else if (!newSet.has(item) && isDataChanged) {
        newSet.add(item);
      }

      return isEqual(newSet, prevSet) ? prevSet : newSet;
    });
  }, [setChangedHooks, isDataChanged, item]);

  useEffect(() => {
    // we want to update current state to be correct if somebody clicks cancel
    if (isCollapsed) {
      setCommands(allCommands[item]);
    }
  }, [isCollapsed, allCommands, item]);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={handleToggle} withTransition>
      <CollapsiblePanelHeader padding="medium large" gap="medium">
        <Box gap="medium" fullWidth className={styles.title}>
          <Box gap="medium" grow="1" align="center">
            <CollapsiblePanelToggleTrigger ariaLevel={3} ariaLabel={phases[item]} />
            <CollapsiblePanelTitle>
              <Box gap="medium" align="center">
                {phases[item]}
                {commandsCounter > 0 && <Counter count={commandsCounter} />}
              </Box>
            </CollapsiblePanelTitle>
          </Box>
          <Box align="center" gap="medium">
            {!isCollapsed && canManageContext && (
              <>
                <Button variant="secondary" onPress={() => handleToggle()}>
                  Cancel
                </Button>
                <Button onPress={handleSave} variant="primary">
                  Save
                </Button>
              </>
            )}
          </Box>
        </Box>
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent padding="medium large large">
        <BeforeAfterCommands
          commands={commands}
          onChange={handleCommandsChange}
          readOnly={!canManageContext}
        />
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default ContextHooksItem;
