import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import { BlueprintIntegrationAttachment, Space } from "types/generated";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { INTEGRATIONS_COLUMN_ID } from "../../constants";
import BlueprintIntegrationsListItemSpaceCell from "./SpaceCell";
import BlueprintIntegrationsListItemActionsDropdownCell from "./DropdownActionCell";
import BlueprintIntegrationsListItemNameCell from "./NameCell";
import BlueprintIntegrationsListItemTypeCell from "./TypeCell";
import styles from "./styles.module.css";

const CELLS = {
  [INTEGRATIONS_COLUMN_ID.NAME]: BlueprintIntegrationsListItemNameCell,
  [INTEGRATIONS_COLUMN_ID.TYPE]: BlueprintIntegrationsListItemTypeCell,
  [INTEGRATIONS_COLUMN_ID.SPACE]: BlueprintIntegrationsListItemSpaceCell,
  [INTEGRATIONS_COLUMN_ID.DROPDOWN]: BlueprintIntegrationsListItemActionsDropdownCell,
};

type BlueprintIntegrationListItemProps = {
  integration: BlueprintIntegrationAttachment & { space: Space };
};

const BlueprintIntegrationListItem = (props: BlueprintIntegrationListItemProps) => {
  const { integration } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as INTEGRATIONS_COLUMN_ID];

        const uniqueKey = `${id}-${integration.id}`;

        const childrenComponent = <Component integration={integration} />;

        return (
          <TableCell key={uniqueKey} id={id} className={styles.cell}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(BlueprintIntegrationListItem);
