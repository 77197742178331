import { gql, TypedDocumentNode } from "@apollo/client";

import { AwsIntegration, AzureIntegration } from "types/generated";

type SearchCloudIntegrationsData = {
  azureIntegrations: AzureIntegration[];
  awsIntegrations: AwsIntegration[];
};

export const SEARCH_CLOUD_INTEGRATIONS: TypedDocumentNode<SearchCloudIntegrationsData> = gql`
  query SearchCloudIntegrations {
    azureIntegrations {
      adminConsentProvided
      adminConsentURL
      applicationId
      displayName
      id
      labels
      name
      tenantId
      defaultSubscriptionId
      spaceDetails {
        id
        name
        accessLevel
      }
      hasAttachedStacks
    }
    awsIntegrations(includeLegacy: false) {
      id
      name
      roleArn
      durationSeconds
      generateCredentialsInWorker
      externalId
      labels
      spaceDetails {
        id
        name
        accessLevel
      }
      hasAttachedStacks
    }
  }
`;
