import JsonView from "@uiw/react-json-view";
import { darkTheme } from "@uiw/react-json-view/dark";
import { lightTheme } from "@uiw/react-json-view/light";
import { memo, useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";

import JsonViewArrow from "./JsonViewArrow";
import Panel from "./Panel";
import styles from "./styles.module.css";

type SchemaPreviewProps = {
  schema: string;
};

const SchemaPreview = ({ schema }: SchemaPreviewProps) => {
  const { isDarkMode } = useTypedContext(ThemeContext);

  const style = useMemo(
    () => ({
      ...(isDarkMode ? darkTheme : lightTheme),
      "--w-rjv-background-color": "transparent",
      "--w-rjv-font-family": "monospace",
      "--w-rjv-info-color": "var(--semantic-color-foreground-subdued)",
      fontSize: "1.2rem",
      fontWeight: 400,
    }),
    [isDarkMode]
  );

  const jsonSchema = useMemo(() => {
    try {
      return JSON.parse(schema);
    } catch {
      //do nothing
      return { error: "Invalid schema" };
    }
  }, [schema]);

  return (
    <Panel title="Schema graph" side="left">
      <JsonView
        value={jsonSchema}
        collapsed={2}
        keyName="root"
        displayDataTypes={false}
        enableClipboard={false}
        style={style}
        className={styles.jsonViewer}
      >
        <JsonViewArrow />
      </JsonView>
    </Panel>
  );
};

export default memo(SchemaPreview);
