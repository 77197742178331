import { ReactNode } from "react";

import CardWrapper from "components/CardWrapper";
import { IconComponent } from "types/Icon";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

export type SystemMessageCardProps = {
  title: string;
  message: ReactNode;
  icon?: IconComponent;
  children?: ReactNode;
};

const SystemMessageCard = ({ title, message, icon, children }: SystemMessageCardProps) => {
  return (
    <CardWrapper
      direction="column"
      gap="x-large"
      variant="outlined"
      padding="xx-large"
      surfaceColor="primary"
      className={styles.cardWrapper}
    >
      {icon && (
        <Box justify="center">
          <Icon src={icon} size="x-large" />
        </Box>
      )}

      <Box direction="column" gap="small">
        <Typography tag="h1" variant="p-t5" align="center">
          {title}
        </Typography>
        {message && (
          <Typography tag="p" variant="p-body2" align="center">
            {message}
          </Typography>
        )}
      </Box>

      {children}
    </CardWrapper>
  );
};

export default SystemMessageCard;
