import { BadgeVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { UserStatus } from "types/generated";

type UserStatusBadgeVariant = {
  variant: BadgeVariants;
  text: string;
};

const USER_STATUSES: Record<UserStatus, UserStatusBadgeVariant> = {
  [UserStatus.Active]: { variant: "green", text: "Active" },
  [UserStatus.Inactive]: { variant: "gray", text: "Inactive" },
  [UserStatus.Pending]: { variant: "yellow", text: "Pending" },
  [UserStatus.Queued]: { variant: "gray", text: "Queued" },
};

type UserStatusBadgeProps = {
  status: UserStatus;
  className?: string;
};

const UserStatusBadge = ({ status, className }: UserStatusBadgeProps) => {
  return (
    <BadgeNext
      variant={USER_STATUSES[status].variant}
      type="strong"
      className={className}
      text={USER_STATUSES[status].text}
    />
  );
};

export default UserStatusBadge;
// TODO [AAC] create UserStatusBadge story before removing old list
