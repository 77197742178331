import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { USER_INTEGRATIONS_COLUMN_ID } from "../constants";
import UserIntegrationsListItemActionsDropdownCell from "./DropdownActionsCell";
import { Integration } from "../types";
import UserIntegrationsListItemTypeCell from "./TypeCell";
import UserIntegrationsListItemIdCell from "./IdCell";
import { UserViewDetails } from "../../types";

const CELLS = {
  [USER_INTEGRATIONS_COLUMN_ID.ID]: UserIntegrationsListItemIdCell,
  [USER_INTEGRATIONS_COLUMN_ID.DROPDOWN]: UserIntegrationsListItemActionsDropdownCell,
  [USER_INTEGRATIONS_COLUMN_ID.TYPE]: UserIntegrationsListItemTypeCell,
};

type UserIntegrationsListItemProps = {
  integration: Integration;
  user: UserViewDetails;
};

const UserIntegrationsListItem = (props: UserIntegrationsListItemProps) => {
  const { integration, user } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as USER_INTEGRATIONS_COLUMN_ID];
        const uniqueKey = `${id}-${integration.id}`;

        const childrenComponent = <Component user={user} integration={integration} />;

        return (
          <TableCell key={uniqueKey} id={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(UserIntegrationsListItem);
