import {
  OrganizationAccessTabs,
  OrganizationAuthTabs,
  OrganizationOtherTabs,
  OrganizationSecurityTabs,
} from "./types";

export const pathConfig = {
  [OrganizationAccessTabs.USERS]: "users",
  [OrganizationAccessTabs.IDP_GROUP_MAPPING]: "idp-group-mapping",
  [OrganizationAccessTabs.API_KEYS]: "api-keys",
  [OrganizationAccessTabs.SPACES]: "spaces",
  [OrganizationAccessTabs.LOGIN_POLICIES]: "login-policy",
  [OrganizationAccessTabs.ROLES]: "roles",
  [OrganizationAccessTabs.MANAGEMENT_STRATEGY]: "management-strategy",
  [OrganizationAuthTabs.SSO]: "sso",
  [OrganizationAuthTabs.MFA]: "mfa",
  [OrganizationSecurityTabs.SECURITY_EMAIL]: "security",
  [OrganizationSecurityTabs.AUDIT_TRAIL]: "audit-trail",
  [OrganizationSecurityTabs.SESSIONS]: "sessions",
  [OrganizationOtherTabs.INTEGRATIONS]: "integrations",
  [OrganizationOtherTabs.BILLING]: "billing",
  [OrganizationOtherTabs.LIMITS]: "limits",
  [OrganizationOtherTabs.USAGE]: "usage",
  [OrganizationOtherTabs.USAGE_EXPORT]: "usage-export",
  [OrganizationOtherTabs.AI]: "ai",
};
