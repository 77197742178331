import {
  LogoAnsible,
  LogoCloudformation,
  LogoKubernetes,
  LogoOpentofu,
  LogoPulumi,
  LogoTerraform,
  LogoTerragrunt,
} from "components/icons/generated";
import { VENDOR_DICTIONARY } from "constants/vendor";
import { StackVendor } from "types/generated";
import { makeStringMatchesPredicate } from "utils/predicates";

export const getStackPredicate = (stackId: string) =>
  makeStringMatchesPredicate("stack", [stackId]);

export const getIacManagementStackVendorMeta = (stackVendor: StackVendor) => {
  switch (stackVendor) {
    case StackVendor.Terragrunt:
      return {
        icon: LogoTerragrunt,
        text: VENDOR_DICTIONARY.TERRAGRUNT,
      };

    case StackVendor.Terraform:
      return {
        icon: LogoTerraform,
        text: VENDOR_DICTIONARY.TERRAFORM,
      };

    case StackVendor.OpenTofu:
      return {
        icon: LogoOpentofu,
        text: VENDOR_DICTIONARY.OPEN_TOFU,
      };

    case StackVendor.Pulumi:
      return {
        icon: LogoPulumi,
        text: VENDOR_DICTIONARY.PULUMI,
      };

    case StackVendor.CloudFormation:
      return {
        icon: LogoCloudformation,
        text: VENDOR_DICTIONARY.CLOUD_FORMATION,
      };

    case StackVendor.Ansible:
      return {
        icon: LogoAnsible,
        text: VENDOR_DICTIONARY.ANSIBLE,
      };

    case StackVendor.Kubernetes:
      return {
        icon: LogoKubernetes,
        text: VENDOR_DICTIONARY.KUBERNETES,
      };

    default:
      return undefined;
  }
};
