import { create, show, useModal } from "@ebay/nice-modal-react";

import Button from "ds/components/Button";
import ModalCancelButton from "ds/components/Modal/CancelButton";
import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import ModalHeader from "ds/components/Modal/Header";
import ModalSimple from "ds/components/ModalSimple";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type FiltersSavedViewsUpdateConfirmationModalProps = {
  onConfirm: () => void;
  name?: string;
};

const FiltersSavedViewsUpdateConfirmationModal = create(
  function FiltersSavedViewsUpdateConfirmationModal({
    onConfirm,
    name,
  }: FiltersSavedViewsUpdateConfirmationModalProps) {
    const modal = useModal();

    const handleOnConfirm = () => {
      onConfirm();
      modal.hide();
    };

    return (
      <ModalSimple>
        <ModalHeader title="Update view" />

        <ModalContent>
          <Typography variant="p-body2" tag="div">
            Would you like to update <span className={styles.text}>{`${name}`}</span> view with the
            new settings?
          </Typography>
        </ModalContent>

        <ModalFooter>
          <ModalCancelButton />
          <Button variant="primary" onPress={handleOnConfirm}>
            Update
          </Button>
        </ModalFooter>
      </ModalSimple>
    );
  }
);

export const showFiltersSavedViewsUpdateConfirmationModal = (
  props: FiltersSavedViewsUpdateConfirmationModalProps
) => show(FiltersSavedViewsUpdateConfirmationModal, props);
