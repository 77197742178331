import { mergeRefs } from "@react-aria/utils";
import cx from "classnames";
import { fromUnixTime } from "date-fns/esm";
import { useMemo } from "react";

import {
  Administrative,
  ArrowsLeftRight,
  CalendarCross,
  ChevronDown,
  CircleCrossed,
  ClockRewind,
  LinkUnlinked,
  Lock,
} from "components/icons/generated";
import Timestamp from "components/time/Timestamp";
import Box from "ds/components/Box";
import { default as Button } from "ds/components/Button";
import ButtonIcon from "ds/components/ButtonIcon";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Tooltip from "ds/components/Tooltip";
import useElementWidth from "hooks/useElementWidth";
import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature } from "types/generated";
import { truncate } from "utils/strings";
import { SubscriptionContext } from "views/Account/SubscriptionWrapper";
import { AccountContext } from "views/AccountWrapper";

import styles from "./styles.module.css";
import { CellComponentProps } from "./types";

const INITIAL_MULTIPLE_ITEMS_DROPDOWN_WIDTH = 54;
const SPACE = 4;
const ITEM_SIZE = 24;

type StackListItemSettingsCellProps = CellComponentProps;

const StackListItemSettingsCell = ({ stack }: StackListItemSettingsCellProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { tierFeatures } = useTypedContext(SubscriptionContext);

  const items = useMemo(() => {
    const nextDeleteSchedule = stack.scheduledDeletes.reduce(
      (acc, next) => {
        if (!acc && next.nextSchedule) {
          return next.nextSchedule;
        }

        if (next.nextSchedule && acc) {
          return Math.min(acc, next.nextSchedule);
        }

        return acc;
      },
      undefined as number | undefined
    );

    return [
      stack.vcsDetached && (
        <ButtonIcon key="key.stack.vcsDetached" variant="ghost" icon={LinkUnlinked}>
          Source code integration has been detached
        </ButtonIcon>
      ),
      stack.administrative && (
        <ButtonIcon key="key.stack.administrative" variant="ghost" icon={Administrative}>
          Administrative
        </ButtonIcon>
      ),
      tierFeatures.includes(BillingTierFeature.DriftDetection) &&
        stack.integrations?.driftDetection?.nextSchedule && (
          <ButtonIcon key="key.stack.driftDetection" variant="ghost" icon={ArrowsLeftRight}>
            {`Drift detection on. Next schedule: ${fromUnixTime(
              stack.integrations?.driftDetection?.nextSchedule
            ).toLocaleDateString(undefined, {
              hour: "numeric",
              minute: "numeric",
            })}`}
          </ButtonIcon>
        ),
      nextDeleteSchedule && (
        <ButtonIcon key="key.stack.nextDeleteSchedule" variant="ghost" icon={CalendarCross}>
          {`Stack scheduled for deletion on: ${fromUnixTime(nextDeleteSchedule).toLocaleDateString(
            undefined,
            {
              hour: "numeric",
              minute: "numeric",
            }
          )}`}
        </ButtonIcon>
      ),
      stack.isStateRollback && stack.managesStateFile && (
        <ButtonIcon key="key.stack.isStateRollback" variant="ghost" icon={ClockRewind}>
          State rolled back
        </ButtonIcon>
      ),
      stack.isDisabled && (
        <ButtonIcon key="key.stack.isDisabled" variant="ghost" icon={CircleCrossed}>
          Stack is disabled
        </ButtonIcon>
      ),
      !!stack.lockedBy && (
        <Tooltip
          key="key.stack.lockedBy"
          widthMode="maxWidthSm"
          on={(tooltipProps) => (
            <Button
              key="key.stack.lockedByButton"
              variant="ghost"
              className={styles.buttonIconSmall}
              size="small"
              startIcon={Lock}
              aria-label="Stack is locked"
              {...tooltipProps}
            >
              {
                "" /* Use empty string, as button has required children for a11y purposes, ButtonIcon handles label via aria-label */
              }
            </Button>
          )}
          disableAriaLabel
        >
          Stack is locked
          {stack.lockedBy === viewer?.id ? " by You" : ` by ${stack.lockedBy}`}
          {stack.lockedAt && (
            <>
              {" "}
              <Timestamp timestamp={stack.lockedAt} />
            </>
          )}
          {stack.lockNote && <>: {truncate(stack.lockNote, 150)}</>}
        </Tooltip>
      ),
    ].filter((v) => !!v);
  }, [
    stack.administrative,
    stack.integrations?.driftDetection?.nextSchedule,
    stack.isDisabled,
    stack.isStateRollback,
    stack.lockNote,
    stack.lockedAt,
    stack.lockedBy,
    stack.managesStateFile,
    stack.scheduledDeletes,
    stack.vcsDetached,
    tierFeatures,
    viewer?.id,
  ]);

  const [containerRef, containerSize] = useElementWidth<HTMLDivElement>();
  const [mutlipleTagsDropdownRef, mutlipleTagsDropdownSize] = useElementWidth<HTMLButtonElement>(
    INITIAL_MULTIPLE_ITEMS_DROPDOWN_WIDTH
  );

  const hiddenItems = useMemo(() => {
    if (!items.length) {
      return [];
    }

    const hiddenItems: JSX.Element[] = [];

    let summOfSizes = mutlipleTagsDropdownSize;

    for (let i = 0; i < items.length; i++) {
      const size = ITEM_SIZE + SPACE;
      const isLast = i === items.length - 1;

      summOfSizes += size;

      if (isLast) {
        summOfSizes -= mutlipleTagsDropdownSize;
      }

      if (containerSize && summOfSizes > containerSize) {
        hiddenItems.push(...items.slice(i));
        return hiddenItems;
      }
    }

    return hiddenItems;
  }, [containerSize, items, mutlipleTagsDropdownSize]);

  if (items.length === 0) {
    return null;
  }

  if (items.length === 1) {
    return items[0];
  }

  const moreTagsTooltip = `${hiddenItems.length === items.length ? `${hiddenItems.length} items` : `+${hiddenItems.length}`}`;

  return (
    <Box
      ref={containerRef}
      className={styles.container}
      fullWidth
      justify="start"
      align="center"
      gap="small"
    >
      {items.filter((item) => !hiddenItems.includes(item)).map((item) => item)}
      {!!hiddenItems.length && (
        <DropdownMenu
          placement="bottom right"
          className={styles.settingsDropdownSection}
          triggerComponent={
            <Tooltip
              on={({ ref, ...tooltipProps }) => (
                <Button
                  {...tooltipProps}
                  ref={mergeRefs<HTMLButtonElement>(ref, mutlipleTagsDropdownRef)}
                  aria-label="Show more items"
                  className={cx(
                    styles.button,
                    !!containerSize && styles.active,
                    tooltipProps.className
                  )}
                  variant="secondary"
                  size="small"
                  endIcon={ChevronDown}
                  withTextEllipsis
                >
                  {moreTagsTooltip}
                </Button>
              )}
            >
              Show more items
            </Tooltip>
          }
        >
          {hiddenItems.map((item, i) => (
            <DropdownMenuItem key={i}>{item}</DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </Box>
  );
};

export default StackListItemSettingsCell;
