import { useMemo } from "react";

import useTypedFlags from "hooks/useTypedFlags";

import { STACKS_COLUMN_CONFIG, STACKS_COLUMN_ID } from "./constants";

export const useStacksColumnConfig = () => {
  const { stackListTagColumn } = useTypedFlags();

  return useMemo(() => {
    const config = { ...STACKS_COLUMN_CONFIG };

    if (!stackListTagColumn) {
      delete config[STACKS_COLUMN_ID.GIT_TAG];
    }

    return config;
  }, [stackListTagColumn]);
};
