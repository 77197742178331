import Box from "ds/components/Box";
import IntegrationActionDropdown from "views/Account/Integration/ActionDropdown";
import { hasSpaceManageAccess } from "utils/user";

import { CellComponentProps } from "./types";
import useIntegrationsListAnalytics from "../../useIntegrationsPageAnalytics";

type IntegrationsListItemActionsDropdownCellProps = CellComponentProps;

const IntegrationsListItemActionsDropdownCell = ({
  integration,
}: IntegrationsListItemActionsDropdownCellProps) => {
  const refetchQueriesOnDelete = ["GetAccountExternalIntegrations"];
  const canManageIntegration = hasSpaceManageAccess(integration.spaceDetails.accessLevel);
  const trackAnalytics = useIntegrationsListAnalytics();

  return (
    <Box justify="end">
      <IntegrationActionDropdown
        integration={integration}
        canManageIntegration={canManageIntegration}
        refetchQueriesOnDelete={refetchQueriesOnDelete}
        trackAnalytics={trackAnalytics}
      />
    </Box>
  );
};

export default IntegrationsListItemActionsDropdownCell;
