import { gql } from "@apollo/client";

export default gql`
  fragment actionFields on Run {
    id
    finished
    history {
      hasLogs
      note
      state
      terminal
      timestamp
      until
      username
      stateVersion
      summarizable
      summary {
        summary
        createdAt
        processingStatus
      }
    }
    state
  }
`;
