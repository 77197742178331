import { useState } from "react";

import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Link from "ds/components/Link";

import styles from "./styles.module.css";
import AssignRoleDrawerFormEdit from "./EditForm";
import AssignRoleDrawerDeleteAction from "./DeleteAction";

type AssignRoleDrawerListItemProps = {
  roleId: string;
  roleName: string;
  spaceId: string;
  spaceName: string;
  roleAssignmentId: string;
  userId: string;
  availableRoles: Array<{ id: string; name: string }>;
  setIsDirty: (id: string, isDirty: boolean) => void;
};

const AssignRoleDrawerListItem = ({
  roleId,
  spaceName,
  spaceId,
  roleName,
  roleAssignmentId,
  availableRoles,
  setIsDirty,
  userId,
}: AssignRoleDrawerListItemProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleSetEditMode = () => setIsEditMode(true);

  const handleUnsetEditMode = () => setIsEditMode(false);

  if (isEditMode) {
    return (
      <AssignRoleDrawerFormEdit
        roleAssignmentId={roleAssignmentId}
        userId={userId}
        setIsDirty={setIsDirty}
        availableRoles={availableRoles}
        roleId={roleId}
        spaceId={spaceId}
        onEditCancel={handleUnsetEditMode}
        onEditSuccess={handleUnsetEditMode}
      />
    );
  }

  return (
    <Box
      direction="row"
      padding="large"
      gap="x-large"
      align="center"
      justify="between"
      className={styles.listItem}
      fullWidth
    >
      <Box direction="column" gap="small" zeroMinWidth>
        <TextEllipsis tooltipWidthMode="maxWidthSm" tooltip={roleName}>
          {(props) => (
            <Typography {...props} variant="p-t6" tag="span">
              {roleName}
            </Typography>
          )}
        </TextEllipsis>
      </Box>
      <Box direction="row" gap="x-large" align="center">
        <Link size="small" href={`/spaces/${spaceId}`}>
          {spaceName}
        </Link>
        <DropdownMenuEllipsis tooltip="Output reference actions" buttonVariant="ghost">
          <DropdownMenuItem onAction={handleSetEditMode}>Edit role</DropdownMenuItem>
          <AssignRoleDrawerDeleteAction id={roleAssignmentId} name={roleName} />
        </DropdownMenuEllipsis>
      </Box>
    </Box>
  );
};

export default AssignRoleDrawerListItem;
