import { memo, ReactNode, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Filters from "components/Filters";
import {
  BillingTierFeature,
  SearchQueryPredicate,
  SearchSuggestionsFieldType,
  SearchSuggestionsOutput,
} from "types/generated";
import {
  FilterItem,
  FiltersItemsOptionsMap,
  SavedFilterView,
  SortOption,
} from "components/Filters/types";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { useCachedFilterFields } from "components/Filters/hooks";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import useTierFeature from "views/Account/hooks/useTierFeature";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { getSearchQuery } from "components/SearchInput/helpers";
import usePolledQuery from "apollo/usePolledQuery";
import { PollingIntervalGroups } from "apollo/constants";

import { SEARCH_AUDIT_TRAIL_ENTRIES_SUGGESTIONS } from "../../gql";
import { getFilterKey } from "../../helpers";
import { FILTERS_ORDER_SETTINGS_KEY } from "../../constants";
import {
  FILTER_ITEMS_DICTIONARY,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
} from "../../constants";

type AuditTrialEntriesFiltersLayoutProps = {
  children: ReactNode;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => unknown;
  predicates: SearchQueryPredicate[];
};

const AuditTrialEntriesFiltersLayout = ({
  children,
  currentSavedView,
  setCurrentSavedView,
  predicates,
}: AuditTrialEntriesFiltersLayoutProps) => {
  const [fields, setFields] = useState<string[] | null>(null);

  const [urlParams] = useSearchParams();
  const searchInput = getSearchQuery(urlParams);

  const { onError } = useTypedContext(FlashContext);

  const isActive = useTierFeature(BillingTierFeature.Auditing);

  const { data: filteringData, loading } = usePolledQuery<{
    searchAuditTrailEntriesSuggestions: SearchSuggestionsOutput;
  }>(SEARCH_AUDIT_TRAIL_ENTRIES_SUGGESTIONS, {
    pollingGroup: PollingIntervalGroups.Lists,
    variables: {
      input: {
        fullTextSearch: searchInput,
        predicates,
        fields,
      },
    },
    skip: !isActive,
    onError,
  });

  const cachedFiltersData = useCachedFilterFields(
    filteringData?.searchAuditTrailEntriesSuggestions?.fields
  );

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter((field) => field.orderable)
        .map((field) => {
          return {
            value: field.name,
            label: getFilterKey(field.name),
          };
        }) || []
    );
  }, [cachedFiltersData]);

  const [filters, filtersItemsOptionsMap] = useMemo<[FilterItem[], FiltersItemsOptionsMap]>(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        // createdAt is not a filter, it's a date range option
        .filter((field) => field.filterable && field.name !== "createdAt")
        .map((field) => {
          let key = field.name;

          if (key === "label") {
            labelsCounter += 1;
            key = `label${labelsCounter}`;
          } else {
            key = getFilterKey(key);
          }

          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key,
            filterName: field.name,
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const handlePollingActiveSections = (fields: string[]) => {
    if (fields.length === 0) {
      return;
    }

    setFields(fields);
  };

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={filtersItemsOptionsMap}
      filtersLoading={loading}
      sortOptions={sortOptions}
      initialSortOption={INITIAL_SORT_OPTION}
      initialSortDirection={INITIAL_SORT_DIRECTION}
      filtersDictionary={FILTER_ITEMS_DICTIONARY}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType="auditTrailEntries"
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
    >
      <FiltersSplit>
        <FiltersSidebar />

        <FiltersContentWrapper>{children}</FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default memo(AuditTrialEntriesFiltersLayout);
