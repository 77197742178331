import { SyntheticEvent, KeyboardEvent as ReactKeyboardEvent } from "react";

/**
 * Copied from react-aria types, they don't export this one...
 */
type BaseEvent<T extends SyntheticEvent> = T & {
  stopPropagation(): void;
  continuePropagation(): void;
};

type KeyboardEvent = BaseEvent<ReactKeyboardEvent<HTMLElement>>;

/**
 * React aria replaces stopPropagation with continuePropagation instead. For interactions within cells, we want to propagate the KeyDown event to the parent cell to handle navigation.
 * The exception would be the dropdown component, which handles the interaction nicely on its own.
 */
export const ariaCellInteractionProps = {
  onKeyDown: (e: KeyboardEvent) => {
    e.continuePropagation();
  },
};
