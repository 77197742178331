import Typography from "ds/components/Typography";

import { CellComponentProps } from "../types";

type UserIntegrationsListItemIdCellProps = CellComponentProps;

const UserIntegrationsListItemIdCell = ({ integration }: UserIntegrationsListItemIdCellProps) => {
  return (
    <Typography tag="p" variant="p-body3">
      {integration.id}
    </Typography>
  );
};

export default UserIntegrationsListItemIdCell;
