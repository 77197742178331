import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import Banner from "ds/components/Banner";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Box from "ds/components/Box";
import CopyFieldIcon from "components/CopyField/Icon";

import { GET_SPACELIFT_AWS_ACCOUNT_ID } from "./gql";
import { generateExampleTrustPolicyStatement } from "./constants";
import styles from "./styles.module.css";

const AwsIntegrationTrustPolicyExample = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { data } = useQuery(GET_SPACELIFT_AWS_ACCOUNT_ID);

  const parsedAssumeRolePolicyStatement = useMemo(() => {
    if (data?.spaceliftAwsAccountId) {
      return JSON.stringify(
        generateExampleTrustPolicyStatement(accountName, data.spaceliftAwsAccountId),
        null,
        2
      );
    }

    return null;
  }, [accountName, data?.spaceliftAwsAccountId]);

  return (
    <Banner variant="info" title="Configure the trust policy for the IAM role">
      <Typography tag="p" variant="p-body3" margin="large 0 0 0">
        Here's an example trust policy statement you can use, that allows any stack within your
        Spacelift account to use the IAM Role:
      </Typography>

      {parsedAssumeRolePolicyStatement && (
        <Box className={styles.codeWrapper} margin="small 0 0 0">
          <CopyFieldIcon
            className={styles.codeCopyButton}
            value={parsedAssumeRolePolicyStatement}
            title="Copy"
            variant="secondary"
          />
          <pre className={styles.code}>{parsedAssumeRolePolicyStatement}</pre>
        </Box>
      )}
    </Banner>
  );
};

export default AwsIntegrationTrustPolicyExample;
