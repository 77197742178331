import { gql } from "@apollo/client";

export const VALIDATE_BLUEPRINT_TEMPLATE = gql`
  mutation ValidateBlueprintTemplate($template: String!) {
    blueprintParseTemplate(template: $template) {
      errors
      inputs {
        id
        name
        default
        description
        options
        type
      }
    }
  }
`;
