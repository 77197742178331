import { EmptystateUsedByColored } from "components/icons/generated";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import EmptyState from "ds/components/EmptyState";
import { getDocsUrl } from "utils/getDocsUrl";

const WorkerPoolUsedByEmpty = () => {
  return (
    <EmptyState
      title="No usage found"
      icon={EmptystateUsedByColored}
      caption={
        <>
          Learn how to make a{" "}
          <ReadMoreDocsLink
            docsUrl={getDocsUrl("/concepts/worker-pools#using-worker-pools")}
            title="stack"
          />{" "}
          or a{" "}
          <ReadMoreDocsLink
            docsUrl={getDocsUrl("/concepts/worker-pools#using-worker-pools")}
            title="module"
          />{" "}
          use a worker pool
        </>
      }
    />
  );
};

export default WorkerPoolUsedByEmpty;
