import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageResources } from "hooks/useAnalytics/pages/resources";

import useIacManagementStore from "./useIacManagementStore";

const useIacManagementAnalytics = () => {
  const parentStackId = useIacManagementStore((state) => state.parentStackId);
  const analyticPage =
    (!!parentStackId && AnalyticsPageResources.StackIacManagement) ||
    AnalyticsPageResources.AccountIacManagement;

  return {
    trackAnalytics: useAnalytics({
      page: analyticPage,
    }),
    analyticPage,
  };
};

export default useIacManagementAnalytics;
