import { gql, TypedDocumentNode } from "@apollo/client";

import { ManagedEntitiesGroupedByProvidersConnection, PageInfo } from "types/generated";

type SearchManagedEntitiesGroupByProvidersData = {
  searchManagedEntitiesGroupByProviders: {
    edges: Array<ManagedEntitiesGroupedByProvidersConnection>;
    pageInfo: Pick<PageInfo, "endCursor" | "startCursor" | "hasNextPage">;
  };
};

export const SEARCH_MANAGED_ENTITIES_GROUP_BY_PROVIDERS: TypedDocumentNode<SearchManagedEntitiesGroupByProvidersData> = gql`
  query SearchManagedEntitiesGroupByProviders($input: SearchInput!) {
    searchManagedEntitiesGroupByProviders(input: $input) {
      edges {
        cursor
        node {
          id
          count
          drifted
          updatedAt
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
`;
