import { create, show, useModal } from "@ebay/nice-modal-react";

import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import useDeleteUser from "./useDeleteUser";

type UserListRevokeAccessConfirmationProps = {
  userName: string;
  id: string;
};

const UserListRevokeAccessConfirmation = create(function UserListRevokeAccessConfirmation({
  userName,
  id,
}: UserListRevokeAccessConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const modal = useModal();

  const { onDelete, loading } = useDeleteUser();

  const handleRemoveAccesses = () => {
    onDelete(id)
      .then(({ data }) => {
        if (data?.managedUserDelete?.id) {
          reportSuccess({
            message: `User access was revoked`,
          });
          modal.hide();
        }
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Remove all rules"
      size="large"
      onConfirm={handleRemoveAccesses}
      confirmationButtonLabel="Revoke access"
      isLoading={loading}
    >
      <ConfirmationModalMessage>
        Are you sure you want to revoke access for {userName}?
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showUserListRevokeAccessConfirmation = (
  props: UserListRevokeAccessConfirmationProps
) => show(UserListRevokeAccessConfirmation, props);
