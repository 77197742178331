import cx from "classnames";
import { memo, RefObject, useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

import useIacManagementResources from "views/Account/IacManagement/useIacManagementResources";
import useIacManagementStore from "views/Account/IacManagement/useIacManagementStore";
import useRowKeyboardNavigation from "ds/components/Table/useKeyboardNavigation/useRowKeyboardNavigation";

import IacManagementTableBodyRow from "..";
import useIacTableStore from "../../useIacTableStore";
import styles from "../styles.module.css";
import useStickyRow from "./useStickyRow";
import { RenderGroupRowContent } from "../../types";
import { selectGroupsMeta, selectVisibleColumns } from "../../useIacTableStore/tableMetaSelectors";
import {
  selectHasGroupResources,
  selectTableRowByIndex,
} from "../../useIacTableStore/tableDataSelectors";

type IacManagementTableRowGroupProps = {
  rowIndex: number;
  measureRef: (node: HTMLDivElement) => void;
  tableRef?: RefObject<HTMLDivElement>;
  isActiveSticky?: boolean;
  nextAfterSticky?: number;
  renderContent: RenderGroupRowContent;
  rowsPerPage: number;
};

const IacManagementTableRowGroup = ({
  rowIndex,
  measureRef,
  isActiveSticky,
  nextAfterSticky,
  tableRef,
  renderContent,
  rowsPerPage,
}: IacManagementTableRowGroupProps) => {
  const { columns, row, loadResources, toggleGroupMeta } = useIacTableStore(
    useShallow((state) => ({
      columns: selectVisibleColumns(state),
      row: selectTableRowByIndex(state, rowIndex),
      loadResources: state.loadResources,
      toggleGroupMeta: state.toggleGroupMeta,
      openGroup: state.openGroup,
    }))
  );

  const { groupMeta, hasGroupResources } = useIacTableStore(
    useShallow((state) => ({
      groupMeta: selectGroupsMeta(state, row.id),
      hasGroupResources: selectHasGroupResources(state, row.id),
    }))
  );

  const { groupBy, parentStackId } = useIacManagementStore(
    useShallow((state) => ({ groupBy: state.groupBy, parentStackId: state.parentStackId }))
  );
  const stackViewMode = !!parentStackId;

  const rowRef = useStickyRow(isActiveSticky, nextAfterSticky, tableRef);
  const keyboardProps = useRowKeyboardNavigation({ isGroup: true });

  const { fetchData, loading } = useIacManagementResources();

  const handleLoadResources = useCallback(() => {
    toggleGroupMeta(row.id);
    if (!hasGroupResources) {
      loadResources({ groupId: row.id, groupBy, parentStackId, rowsPerPage }, fetchData);
    }
  }, [
    fetchData,
    groupBy,
    hasGroupResources,
    loadResources,
    row.id,
    toggleGroupMeta,
    parentStackId,
    rowsPerPage,
  ]);

  if (!("count" in row)) {
    return null;
  }

  return (
    <IacManagementTableBodyRow
      {...keyboardProps}
      dataIndex={rowIndex}
      className={cx(styles.groupRow, {
        [styles.open]: groupMeta?.isOpen,
        [styles.isActiveSticky]: isActiveSticky,
      })}
      forwardRef={(node: HTMLDivElement) => {
        if (measureRef) {
          measureRef(node);
        }
        if (rowRef) {
          rowRef.current = node;
        }
      }}
    >
      {renderContent({
        columns,
        row,
        loadResources: handleLoadResources,
        stackViewMode,
        fetchDataLoading: loading,
        groupMeta,
      })}
    </IacManagementTableBodyRow>
  );
};

export default memo(IacManagementTableRowGroup);
