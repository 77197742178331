import { useModal } from "@ebay/nice-modal-react";
import cx from "classnames";
import { useCallback, useEffect, useRef } from "react";

import { Cross, Plus } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ButtonIcon from "ds/components/ButtonIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import Icon from "ds/components/Icon";
import Tab from "ds/components/Tab";
import TabPanel from "ds/components/TabPanel";
import TextEllipsis from "ds/components/TextEllipsis";
import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";

import { DEFAULT_TAB_IDS, NEW_TAB_IN_DRAWER_ID } from "../constants";
import DashboardManageDrawerSkeleton from "./Skeleton";
import styles from "./styles.module.css";
import DashboardManageDrawerTab from "./Tab";
import useConfig from "./useConfig";

type DashboardManageDrawerProps = {
  tab?: string;
  initializeNewTab?: boolean;
};

const DashboardManageDrawer = createDrawer(
  ({ tab, initializeNewTab }: DashboardManageDrawerProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const tabToScrollIntoRef = useRef<HTMLDivElement>(null);
    const drawer = useModal();

    const {
      saveManagementDrawerChanges,
      isManagementDrawerConfigModified,
      managementDrawerTabs,
      addNewTabInDrawer,
      removeTabInDrawer,
      setCurrentManageDrawerTab,
      currentManageDrawerTab,
      canAddMoreManagementDrawerViews,
      managementDrawerConfig,
      updateManagementDrawerTabColumnsConfig,
      updateManagementDrawerTabColumnModeConfig,
      updateManagementDrawerTabOverviewColumnConfig,
      addNewTab,
      changeTabName,
      shouldFocusOnInput,
      configLoading,
    } = useConfig();

    // Activate tab when drawer opened
    useEffect(() => {
      if (tab && tab !== currentManageDrawerTab) {
        setCurrentManageDrawerTab(tab);
      }
    }, [tab, setCurrentManageDrawerTab, currentManageDrawerTab]);

    // Add new tab when drawer opened
    useEffect(() => {
      if (initializeNewTab) {
        addNewTab();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Scroll tab to current one
    useEffect(() => {
      if (tabToScrollIntoRef.current) {
        tabToScrollIntoRef.current.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }
    }, [currentManageDrawerTab]);

    // Focus on input when adding new tab
    useEffect(() => {
      if (inputRef.current && shouldFocusOnInput) {
        inputRef.current.focus();
      }
    }, [currentManageDrawerTab, shouldFocusOnInput]);

    const handleSave = useCallback(() => {
      saveManagementDrawerChanges();
      drawer.hide();
    }, [saveManagementDrawerChanges, drawer]);

    const handleTabDelete = (id: string) => () => {
      removeTabInDrawer(id);
    };

    const hasTitleError = managementDrawerTabs.some(
      (tab) => !DEFAULT_TAB_IDS.includes(tab.id) && !tab.title
    );

    return (
      <DrawerForm isDirty={isManagementDrawerConfigModified}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Manage view" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight shouldFocusOnEnter={false}>
          {configLoading && <DashboardManageDrawerSkeleton />}
          {!configLoading && (
            <Box padding="0 0 x-large 0">
              <Box className={styles.tabsContainerScollable}>
                <Box direction="row" gap="medium" align="center" className={styles.tabsContainer}>
                  {managementDrawerTabs.map(({ id, title }) => (
                    <div
                      key={id}
                      className={cx(!DEFAULT_TAB_IDS.includes(id) && styles.tabLabelWrapper)}
                    >
                      <Tab
                        innerRef={currentManageDrawerTab === id ? tabToScrollIntoRef : undefined}
                        onClick={setCurrentManageDrawerTab}
                        isActive={currentManageDrawerTab === id}
                        id={id}
                        className={cx(!DEFAULT_TAB_IDS.includes(id) && styles.tabLabel)}
                        aria-label={title}
                        label={
                          DEFAULT_TAB_IDS.includes(id) ? (
                            title
                          ) : (
                            <TextEllipsis tooltip={title} tooltipWidthMode="maxWidthSm">
                              {(props) => (
                                <Typography {...props} tag="span" variant="p-t7">
                                  {title}
                                </Typography>
                              )}
                            </TextEllipsis>
                          )
                        }
                      />
                      {!DEFAULT_TAB_IDS.includes(id) && (
                        <ButtonIcon
                          className={styles.tabLabelAction}
                          icon={Cross}
                          onPress={handleTabDelete(id)}
                          variant="ghost"
                        >
                          Delete tab
                        </ButtonIcon>
                      )}
                    </div>
                  ))}
                  <Tab
                    id={NEW_TAB_IN_DRAWER_ID}
                    aria-label={
                      canAddMoreManagementDrawerViews ? "Add new tab" : "Views limit exceeded"
                    }
                    onClick={canAddMoreManagementDrawerViews ? addNewTabInDrawer : undefined}
                    isActive={false}
                    label={
                      <Tooltip on={(props) => <Icon {...props} src={Plus} />}>
                        {canAddMoreManagementDrawerViews ? "Add new tab" : "Views limit exceeded"}
                      </Tooltip>
                    }
                    disabled={!canAddMoreManagementDrawerViews}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {!configLoading &&
            managementDrawerTabs.map(({ id }) => (
              <TabPanel key={id} isActive={currentManageDrawerTab === id} id={id}>
                <DashboardManageDrawerTab
                  inputRef={inputRef}
                  tab={id}
                  managementDrawerConfig={managementDrawerConfig}
                  updateManagementDrawerTabColumnsConfig={updateManagementDrawerTabColumnsConfig}
                  updateManagementDrawerTabColumnModeConfig={
                    updateManagementDrawerTabColumnModeConfig
                  }
                  updateManagementDrawerTabOverviewColumnConfig={
                    updateManagementDrawerTabOverviewColumnConfig
                  }
                  changeTabName={changeTabName}
                />
              </TabPanel>
            ))}
        </DrawerBody>
        {!configLoading && (
          <DrawerFooter sticky gap="medium">
            <DrawerFooterActions>
              <DrawerCancelButton />
              <Button
                variant="primary"
                disabled={!isManagementDrawerConfigModified || hasTitleError}
                onPress={handleSave}
              >
                Save
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        )}
      </DrawerForm>
    );
  }
);

export const showDashboardManageDrawer = createDrawerTrigger(DashboardManageDrawer);
