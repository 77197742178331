import { useModal } from "@ebay/nice-modal-react";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpaces from "components/FormFields/Spaces";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import ListEntitiesItem from "components/ListEntitiesItem";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import LinkButton from "ds/components/Button/LinkButton";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import FeedbackActions from "ds/components/Feedback/Actions";
import FormField from "ds/components/Form/Field";
import Icon from "ds/components/Icon";
import Input from "ds/components/Input";
import Link from "ds/components/Link";
import TabWithCounter from "ds/components/Tab/WithCounter";
import TabPanel from "ds/components/TabPanel";
import Tooltip from "ds/components/Tooltip";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUserGroup, UserRole } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { getDocsUrl } from "utils/getDocsUrl";
import { getProviderLogo, getUserIntegrationFormValue } from "views/Account/Settings/Users/helpers";
import useTypedFlags from "hooks/useTypedFlags";

import { getManagementStrategy } from "../../helpers";
import { UserManagementActivationStatus } from "../../types";
import UserStatusBadge from "../UsersList/UserStatusBadge";
import styles from "./styles.module.css";
import { UpdateUserFields, UserDetails, UsersDrawerTabs } from "./types";
import useUpdateUser from "./useUpdateUser";

type UsersDetailsDrawerContentProps = {
  user: UserDetails;
  activationStatus: UserManagementActivationStatus;
  managedUserGroups?: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
  setIsDirty: (isDirty: boolean) => void;
};

const UsersDetailsDrawerContent = ({
  activationStatus,
  user,
  managedUserGroups,
  setIsDirty,
}: UsersDetailsDrawerContentProps) => {
  const drawer = useModal();
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { customAccessRoles } = useTypedFlags();
  const [currentTab, setCurrentTab] = useState("spaces");

  const userIntegrationOptions = getUserIntegrationFormValue(user);

  const userAddForm = useForm<UpdateUserFields>({
    defaultValues: {
      spaces: user.accessRules.length > 0 ? user.accessRules : [],
      slackMemberID: user.slackMemberID || "",
    },
    mode: "onChange",
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationUsers,
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = userAddForm;

  const { onUpdate } = useUpdateUser(user.id);
  const ProviderIcon = getProviderLogo(user.loginMethod);

  const closeDrawer = () => {
    drawer.hide();
  };

  const onSubmit: SubmitHandler<UpdateUserFields> = (formData) => {
    trackSegmentAnalyticsEvent("Save Changes");

    const input = {
      accessRules: formData.spaces.filter((space) => !!space.space && !!space.spaceAccessLevel),
      id: user.id,
      slackMemberID: formData.slackMemberID,
    };

    onUpdate(input)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `User access was updated`,
          });
          closeDrawer();
        }
      })
      .catch(onError);
  };

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  return (
    <FormProvider {...userAddForm}>
      <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title={customAccessRoles ? "User details" : "Access details"} />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight hasStickyFooter>
          {activationStatus === UserManagementActivationStatus.INACTIVE && (
            <Banner variant="warning" title="User management is inactive">
              Access rules will not take effect until you activate user management.
              <FeedbackActions>
                <LinkButton
                  to="/settings/management-strategy/"
                  analyticsPage={AnalyticsPageOrganization.OrganizationUsers}
                  analyticsTitle="Callout Strategy Click"
                  variant="contrast"
                  size="small"
                >
                  Choose strategy
                </LinkButton>
              </FeedbackActions>
            </Banner>
          )}

          <FormFieldViewText label="Username" value={user.username} />

          <FormField label="Login method">
            <Typography tag="span" variant="p-body2">
              {typeof ProviderIcon === "string" ? (
                ProviderIcon
              ) : (
                <Icon size="large" src={ProviderIcon} />
              )}
            </Typography>
          </FormField>
          {user.invitationEmail && <FormFieldViewText label="Email" value={user.invitationEmail} />}
          {user.lastLoginTime && (
            <FormFieldTimestamp timestamp={user.lastLoginTime} label="Last login" />
          )}

          <FormField label="Status" className={styles.status}>
            <UserStatusBadge status={user.status} />
          </FormField>

          {!customAccessRoles && (
            <>
              <Box direction="row" gap="medium" align="center" className={styles.tabs}>
                <TabWithCounter
                  onClick={setCurrentTab}
                  isActive={currentTab === UsersDrawerTabs.Spaces}
                  id={UsersDrawerTabs.Spaces}
                  label="Spaces"
                  count={user.spaceCount}
                />
                <TabWithCounter
                  onClick={setCurrentTab}
                  isActive={currentTab === UsersDrawerTabs.Groups}
                  id={UsersDrawerTabs.Groups}
                  label="Groups"
                  count={user.groupCount || 0}
                />
                <TabWithCounter
                  onClick={setCurrentTab}
                  isActive={currentTab === UsersDrawerTabs.Integrations}
                  id={UsersDrawerTabs.Integrations}
                  label="Integrations"
                  count={userIntegrationOptions.length}
                />
              </Box>

              <TabPanel
                isActive={currentTab === UsersDrawerTabs.Spaces}
                id={UsersDrawerTabs.Spaces}
              >
                <div className={styles.listWrapper}>
                  <FormFieldSpaces
                    analyticsPage={AnalyticsPageOrganization.OrganizationUsers}
                    isDisabled={user.role === UserRole.Owner}
                    allowEmpty
                  />
                </div>
              </TabPanel>
              <TabPanel
                isActive={currentTab === UsersDrawerTabs.Groups}
                id={UsersDrawerTabs.Groups}
              >
                <div className={styles.listWrapper}>
                  {user.latestGroups.map((item, index) => {
                    return (
                      <ListEntitiesItem
                        key={`${item}-${index}`}
                        className={styles.listItem}
                        align="start"
                      >
                        {managedUserGroups?.find(
                          ({ identityProvider, groupName }) =>
                            identityProvider === user.loginMethod && groupName === item
                        ) ? (
                          <Link
                            href={`/settings/idp-group-mapping?identityProvider=${user.loginMethod}&groupName=${item}`}
                            variant="secondary"
                          >
                            <Typography tag="span" variant="p-t6">
                              {item}
                            </Typography>
                          </Link>
                        ) : (
                          <Tooltip
                            on={(props) => (
                              <Typography
                                {...props}
                                tag="span"
                                className={styles.itemTitle}
                                variant="p-t6"
                              >
                                {item}
                              </Typography>
                            )}
                            widthMode="maxWidthSm"
                          >
                            IdP group does not have any permissions assigned yet. You can do it in
                            the IdP group mapping section
                          </Tooltip>
                        )}
                      </ListEntitiesItem>
                    );
                  })}
                </div>
              </TabPanel>

              <TabPanel
                isActive={currentTab === UsersDrawerTabs.Integrations}
                id={UsersDrawerTabs.Integrations}
              >
                <Box padding="x-large 0 0 0" fullWidth grow="1" direction="column">
                  <FormField
                    tooltipMode="parent"
                    label="Slack member ID"
                    error={errors?.slackMemberID?.message}
                    tooltipInfoVariant="modal"
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Slack member ID</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          Id of Slack account that belongs to this user
                          <ReadMoreDocsLink
                            docsUrl={getDocsUrl(
                              "/concepts/user-management/admin#slack-integration"
                            )}
                          />
                        </TooltipModalBody>
                      </>
                    }
                  >
                    {({ ariaInputProps }) => (
                      <Input
                        placeholder="Enter member ID"
                        error={!!errors?.slackMemberID}
                        {...register("slackMemberID", {})}
                        {...ariaInputProps}
                      />
                    )}
                  </FormField>
                </Box>
              </TabPanel>

              <DrawerFooter sticky>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button variant="primary" type="submit">
                    Save changes
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </>
          )}
        </DrawerBody>
      </form>
    </FormProvider>
  );
};

export default UsersDetailsDrawerContent;
