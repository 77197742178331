import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";

import { BulkActionsVariant } from "../types";
import { BUTTON_PROPS_BY_VARIANT } from "./helpers";

type BulkActionsChooseActionStepEmptyProps = {
  onCancel?: () => void;
  variant: BulkActionsVariant;
};

const BulkActionsChooseActionStepEmpty = ({
  onCancel,
  variant,
}: BulkActionsChooseActionStepEmptyProps) => {
  return (
    <Box align="center" justify="between" fullWidth>
      <Typography tag="span" variant="p-body3">
        No actions available.
      </Typography>

      {onCancel && (
        <Button onPress={onCancel} {...BUTTON_PROPS_BY_VARIANT[variant]}>
          Cancel
        </Button>
      )}
    </Box>
  );
};

export default BulkActionsChooseActionStepEmpty;
