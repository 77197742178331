import { gql, TypedDocumentNode } from "@apollo/client";

import { BlueprintStackCreation, MutationBlueprintCreateStackArgs } from "types/generated";

type FetchBlueprintCreateStack = {
  blueprintCreateStack: BlueprintStackCreation;
};

export const BLUEPRINT_CREATE_STACK: TypedDocumentNode<
  FetchBlueprintCreateStack,
  MutationBlueprintCreateStackArgs
> = gql`
  mutation BlueprintCreateStack($id: ID!, $input: BlueprintStackCreateInput!) {
    blueprintCreateStack(id: $id, input: $input) {
      stackID
      runID
    }
  }
`;
