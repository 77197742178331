import { ReactNode } from "react";
import cx from "classnames";

import useFocusOnModalEnter from "hooks/useFocusOnModalEnter";

import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";

type DrawerBodyProps = {
  children: ReactNode;
  fullHeight?: boolean;
  hasStickyFooter?: boolean;
  hasDivider?: boolean;
  shouldFocusOnEnter?: boolean;
  noPadding?: boolean;
  className?: string;
} & Partial<BoxProps>;

const DrawerBody = ({
  children,
  direction = "column",
  justify = "start",
  fullHeight,
  hasStickyFooter,
  noPadding,
  hasDivider,
  className,
  shouldFocusOnEnter,
  ...restProps
}: DrawerBodyProps) => {
  const bodyRef = useFocusOnModalEnter({
    shouldFocusOnEnter,
  });

  return (
    <Box
      ref={bodyRef}
      direction={direction}
      justify={justify}
      fullWidth
      className={cx(
        styles.body,
        {
          [styles.fullHeight]: fullHeight,
          [styles.hasStickyFooter]: hasStickyFooter,
          [styles.hasDivider]: hasDivider,
          [styles.noPadding]: noPadding,
        },
        className
      )}
      {...restProps}
    >
      {children}
    </Box>
  );
};

DrawerBody.displayName = "DS.Drawer.Body";

export default DrawerBody;
