import useLocalStorage from "@rehooks/local-storage";
import { useEffect } from "react";

import { CustomizeViewConfig } from "components/CustomizeView/types";

export const useSyncColumnsConfig = <T extends string>({
  storageKey,
  defaultConfig,
}: {
  storageKey: string;
  defaultConfig: CustomizeViewConfig<T>;
}) => {
  const [storageConfig, setStorageConfig] = useLocalStorage<CustomizeViewConfig<T>>(
    storageKey,
    defaultConfig
  );

  useEffect(() => {
    if (!storageConfig) {
      return;
    }

    const newConfig = { hidden: [...storageConfig.hidden], visible: [...storageConfig.visible] };

    let changed = false;

    // TODO: extract and test
    defaultConfig.visible.forEach((value, i) => {
      if (!newConfig.hidden?.includes(value) && !newConfig.visible?.includes(value)) {
        changed = true;
        newConfig.visible.splice(i, 0, value);
      }
    });

    newConfig.visible.forEach((value, i) => {
      if (!defaultConfig.hidden?.includes(value) && !defaultConfig.visible?.includes(value)) {
        changed = true;
        newConfig.visible?.splice(i, 1);
      }
    });

    newConfig.hidden.forEach((value, i) => {
      if (!defaultConfig.hidden?.includes(value) && !defaultConfig.visible?.includes(value)) {
        changed = true;
        newConfig.hidden?.splice(i, 1);
      }
    });

    if (changed) {
      setStorageConfig(newConfig as CustomizeViewConfig<T>);
    }
  }, [defaultConfig, storageConfig, setStorageConfig]);
};
