import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import IconTile from "ds/components/IconTile";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";

type LlmSelectorTileProps = {
  icon: IconComponent;
  onSelect: () => void;
  name: string;
  isSelected: boolean;
};

const LlmSelectorTile = ({ icon, onSelect, name, isSelected }: LlmSelectorTileProps) => {
  return (
    <TileWrapper className={styles.llmSelectorTileWrapper} justify="between" align="center">
      <Box gap="large" align="center">
        <IconTile icon={icon} variant="secondary" />
        <TileTitle>{name}</TileTitle>
        {isSelected && <BadgeNext variant="green" type="strong" text="Selected" />}
      </Box>
      {!isSelected && (
        <Button variant="secondary" onPress={onSelect}>
          Enable
        </Button>
      )}
    </TileWrapper>
  );
};

export default LlmSelectorTile;
