import LinkButton from "ds/components/Button/LinkButton";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import { BillingSubscription } from "types/generated";

import { StripeSubscriptionStatus } from "../../types";
import { isPaymentFailed } from "../../utils";

type BillingPaymentFailureCalloutProps = {
  subscription: BillingSubscription;
};

const BillingPaymentFailureCallout = ({ subscription }: BillingPaymentFailureCalloutProps) => {
  if (!isPaymentFailed(subscription.status as StripeSubscriptionStatus)) {
    return null;
  }

  return (
    <Callout title="Your subscription payment has failed" variant="danger">
      We discovered a billing issue on your last invoice. Please review and update your payment
      details.
      {!!subscription.selfServePortalUrl && (
        <FeedbackActions>
          <LinkButton
            size="small"
            variant="contrast"
            href={subscription.selfServePortalUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            Manage payments
          </LinkButton>
        </FeedbackActions>
      )}
    </Callout>
  );
};

export default BillingPaymentFailureCallout;
