import Button from "ds/components/Button";

import useConnectSlackIntegration from "./useConnectSlackIntegration";

const SettingsIntegrationsSlackConnectButton = () => {
  const { connectSlackIntegration, loading } = useConnectSlackIntegration();

  return (
    <Button variant="primary" onPress={connectSlackIntegration} loading={loading}>
      Connect
    </Button>
  );
};

export default SettingsIntegrationsSlackConnectButton;
