import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import CodeEditor from "components/CodeEditor";
import FormFieldSegmentedSchedule from "components/FormFields/SegmentedSchedule";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import Feedback from "ds/components/Feedback";
import FormField from "ds/components/Form/Field";
import FormToggleField from "ds/components/Form/ToggleField";
import Input from "ds/components/Input";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import useStackScheduledRunCreate from "shared/Stack/Scheduling/useStackScheduledRunCreate";
import useStackScheduledRunUpdate from "shared/Stack/Scheduling/useStackScheduledRunUpdate";
import { ScheduledRun } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { getNowDateTime } from "utils/date";

import { getFormDefaultValues, mapCreateFormData } from "./helpers";
import { ScheduledRunFormFields } from "./types";

const REFETCH_QUERIES = ["GetStackScheduling"];

type StackManageScheduledRunProps = {
  integration?: ScheduledRun;
  stackId: string;
  setIsDirty: (isDirty: boolean) => void;
};

const StackManageScheduledRun = ({
  integration,
  stackId,
  setIsDirty,
}: StackManageScheduledRunProps) => {
  const isEditMode = !!integration;
  const drawer = useModal();

  const minDate = getNowDateTime({ timezone: "UTC" });
  const defaultDateTime = getNowDateTime({ timezone: "UTC", timeShiftMinutes: 15 });

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const scheduledRunForm = useForm<ScheduledRunFormFields>({
    defaultValues: getFormDefaultValues(defaultDateTime, integration),
    mode: "onChange",
  });

  const {
    register,
    formState: { errors, isDirty },
    control,
    watch,
    handleSubmit,
    trigger,
  } = scheduledRunForm;

  const { createScheduledRun, loading: creationLoading } = useStackScheduledRunCreate({
    refetchQueries: REFETCH_QUERIES,
  });
  const { updateScheduledRun, loading: updateLoading } = useStackScheduledRunUpdate({
    refetchQueries: REFETCH_QUERIES,
  });

  const loading = creationLoading || updateLoading;

  const isRecurring = watch("recurring");
  const withCustomRuntimeConfigWatched = watch("withCustomRuntimeConfig");

  const handlePrimaryAction = (formData: ScheduledRunFormFields) => {
    const input = mapCreateFormData(formData);

    if (isEditMode) {
      updateScheduledRun(stackId, integration.id, input, () => {
        drawer.hide();
        trackSegmentEvent("Schedule Edit Saved", { type: SCHEDULING_TYPE.RUN });
      });
    } else {
      createScheduledRun(stackId, input, () => {
        drawer.hide();
        trackSegmentEvent("Schedule Created", { type: SCHEDULING_TYPE.RUN });
      });
    }
  };

  useEffect(() => {
    if (isEditMode && !isRecurring) {
      trigger();
    }
  }, [isEditMode, trigger, isRecurring]);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);

  return (
    <form onSubmit={handleSubmit(handlePrimaryAction)} {...getDrawerFormFix()}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={isEditMode ? "Edit run schedule" : "Create run schedule"} />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody gap="x-large" fullHeight>
        <Feedback type="banner" variant="info">
          Run schedule only works on private workers.
        </Feedback>

        <FormProvider {...scheduledRunForm}>
          <FormField label="Schedule name" isOptional error={errors?.name?.message} noMargin>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Type schedule name..."
                error={!!errors?.name}
                {...register("name")}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <Controller
            name="withCustomRuntimeConfig"
            control={control}
            render={({ field }) => (
              <FormToggleField
                variant="switch"
                title="Attach custom runtime config"
                onChange={field.onChange}
                checked={field.value}
              />
            )}
          />

          {withCustomRuntimeConfigWatched && (
            <Controller
              control={control}
              name="customRuntimeConfig"
              render={({ field }) => (
                <FormField label="Custom config" noMargin>
                  <CodeEditor
                    body={field.value}
                    onChange={field.onChange}
                    language="yaml"
                    skeletonCount={7}
                    fixedHeight={180}
                    rounded
                  />
                </FormField>
              )}
            />
          )}

          <FormFieldSegmentedSchedule minDate={minDate} />

          <DrawerFooter>
            <DrawerFooterActions>
              <DrawerCancelButton />

              <Button variant="primary" loading={loading} disabled={loading} type="submit">
                {isEditMode ? "Save" : "Create"}
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </FormProvider>
      </DrawerBody>
    </form>
  );
};

export default StackManageScheduledRun;
