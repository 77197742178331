import { Route, Routes } from "react-router-dom";

import NotFoundPage from "components/error/NotFoundPage";

import CloudIntegrationsList from "./List";
import CloudIntegrationsAwsIntegration from "./AwsIntegration";
import CloudIntegrationsAzureIntegration from "./AzureIntegration";

const CloudIntegrations = () => {
  return (
    <Routes>
      <Route index element={<CloudIntegrationsList />} />
      <Route path="/aws/:integrationId" element={<CloudIntegrationsAwsIntegration />} />
      <Route path="/azure/:integrationId" element={<CloudIntegrationsAzureIntegration />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default CloudIntegrations;
