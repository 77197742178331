import { useEffect, useState } from "react";
import { useModal } from "@ebay/nice-modal-react";

import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormFieldViewText from "components/FormFields/ViewText";
import Box from "ds/components/Box";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewCode from "components/FormFields/ViewCode";
import DrawerShareButton from "ds/components/DrawerNew/ShareButton";
import FormFieldViewLink from "components/FormFields/FormFieldViewLink";
import { ErrorCircleColored, Stack } from "components/icons/generated";
import SystemMessageCard from "components/SystemMessage/Card";
import Button from "ds/components/Button";

import useIacTableStore from "../components/Table/useIacTableStore";
import {
  makeThirdPartyMetaData,
  makeVendorSpecificData,
  shouldCloseOnInteractOutside,
} from "./helpers";
import IacManagementResourceDetailsDrawerSkeleton from "./Skeleton";
import useMakeResourceDetailsUrlPath from "../useMakeResourceDetailsUrlPath";
import { selectResource } from "../components/Table/useIacTableStore/tableDataSelectors";
import useIacManagementResources from "../useIacManagementResources";
import FormFieldViewResourceVendor from "../components/ViewResourceVendor";
import useIacManagementAnalytics from "../useIacManagementAnalytics";

type IacManagementResourceDetailsDrawerProps = {
  resourceId: string;
  onClose: () => void;
};

const IacManagementResourceDetailsDrawer = createDrawer(
  ({ resourceId, onClose }: IacManagementResourceDetailsDrawerProps) => {
    const modal = useModal();
    const resourceFromStore = useIacTableStore((state) => selectResource(state, resourceId));
    const [resource, setResource] = useState(resourceFromStore);
    const { trackAnalytics } = useIacManagementAnalytics();

    const { fetchResourceById, loading } = useIacManagementResources();
    const { fullLink } = useMakeResourceDetailsUrlPath(resourceId);
    const vendorSpecificData = makeVendorSpecificData(resource?.vendor);
    const thirdPartyIntegrationsMetaData = makeThirdPartyMetaData(
      resource?.thirdPartyMetadata || undefined
    );

    const onCopyCallback = (field: string) => () => {
      trackAnalytics("Copy Element clicked", { field });
    };

    const handleClose = () => {
      modal.hide();
      onClose();
    };

    useEffect(() => {
      const fetchData = async () => {
        if (!resource) {
          const result = await fetchResourceById(resourceId);
          setResource(result.data);
        }
      };
      fetchData();
    }, [fetchResourceById, resource, resourceId]);

    useEffect(() => {
      if (resourceFromStore) {
        setResource(resourceFromStore);
      }
    }, [resourceFromStore]);

    const notFound = !loading && !resource;

    return (
      <DrawerSimple onClose={onClose} shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Details" />
          <Box gap="medium">
            {!notFound && <DrawerShareButton link={fullLink} title="Share resource details" />}
            <DrawerCloseIcon />
          </Box>
        </DrawerHeader>
        <DrawerBody noPadding>
          <Box direction="column" padding="x-large" gap="xx-large">
            {loading && <IacManagementResourceDetailsDrawerSkeleton />}
            {notFound && (
              <Box justify="center" padding="xx-large">
                <SystemMessageCard
                  title="Resource not found"
                  message={
                    <>
                      Sorry, we couldn't find what you're looking for. If you're certain something
                      should be here, you may try reloading the page.
                    </>
                  }
                  icon={ErrorCircleColored}
                >
                  <Button variant="primary" size="medium" onPress={handleClose}>
                    Close
                  </Button>
                </SystemMessageCard>
              </Box>
            )}
            {resource && (
              <>
                {/* TODO: add filter actions */}
                <FormFieldViewText
                  label="Resource name"
                  value={resource.name}
                  onCopyCallback={onCopyCallback("Resource name")}
                  withCopy
                  noMargin
                />
                <FormFieldViewText
                  label="ID"
                  value={resource.id}
                  onCopyCallback={onCopyCallback("Resource id")}
                  withCopy
                  noMargin
                />
                <FormFieldViewText
                  label="Type"
                  value={resource.type}
                  onCopyCallback={onCopyCallback("Resource type")}
                  withCopy
                  noMargin
                />
                {resource.drifted && (
                  <FormFieldTimestamp label="Drifted" timestamp={resource.drifted} noMargin />
                )}
                {!resource.drifted && <FormFieldViewText label="Drifted" value="-" noMargin />}
                <FormFieldViewLink
                  label="Stack"
                  link={`/stack/${resource.stackId}`}
                  value={resource.stackName}
                  icon={Stack}
                  noMargin
                />
                {resource.creator?.id && (
                  <FormFieldViewLink
                    label="Created by"
                    link={`/stack/${resource.stackId}/run/${resource.creator.id}`}
                    value={resource.creator.id}
                    noMargin
                  />
                )}
                {resource.updater?.id && (
                  <FormFieldViewLink
                    label="Updated by"
                    link={`/stack/${resource.stackId}/run/${resource.updater.id}`}
                    value={resource.updater.id}
                    noMargin
                  />
                )}
                <FormFieldViewText
                  label="Parent"
                  value={resource.parent}
                  onCopyCallback={onCopyCallback("Resource parent")}
                  withCopy
                  noMargin
                />
                <FormFieldViewText
                  label="Address"
                  value={resource.address}
                  onCopyCallback={onCopyCallback("Resource address")}
                  withCopy
                  noMargin
                />
                <FormFieldViewResourceVendor
                  label="Vendor"
                  vendor={resource.stackVendor}
                  noMargin
                />
                {vendorSpecificData && (
                  <FormFieldViewCode
                    label="Vendor specific data"
                    code={vendorSpecificData}
                    onCopyCallback={onCopyCallback("Vendor specific data")}
                    maxHeight="40rem"
                    noMargin
                  />
                )}
                {thirdPartyIntegrationsMetaData.map((integration) => (
                  <FormFieldViewCode
                    key={integration.key}
                    label={`Third-party metadata (${integration.key})`}
                    code={integration.data}
                    onCopyCallback={onCopyCallback(`Third-party metadata (${integration.key})`)}
                    maxHeight="40rem"
                    noMargin
                  />
                ))}
              </>
            )}
          </Box>
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showIacManagementResourceDetailsDrawer = createDrawerTrigger(
  IacManagementResourceDetailsDrawer
);
