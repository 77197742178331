import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { IconComponent } from "types/Icon";

type TableHeadColumnTitleProps =
  | {
      title: string;
      allowsSorting?: false;
      sortIcon?: unknown;
      onSort?: unknown;
    }
  | {
      title: string;
      allowsSorting: true;
      sortIcon: IconComponent;
      onSort: () => void;
    };

const TableHeadColumnTitle = ({
  title,
  allowsSorting,
  sortIcon,
  onSort,
}: TableHeadColumnTitleProps) => {
  return (
    <Box align="center" gap="small">
      <TextEllipsis tooltip={title}>
        {(props) => (
          <Typography color="secondary" tag="span" variant="p-t7" {...props}>
            {title}
          </Typography>
        )}
      </TextEllipsis>

      {allowsSorting && (
        <ButtonIcon
          variant="ghost"
          icon={sortIcon}
          onPress={onSort}
          aria-label={`Sort by ${title}`}
        >
          Toggle sort
        </ButtonIcon>
      )}
    </Box>
  );
};

TableHeadColumnTitle.displayName = "DS.Table.Head.Column.Title";

export default TableHeadColumnTitle;
