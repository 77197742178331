import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageCloudIntegrations } from "hooks/useAnalytics/pages/cloudIntegrations";

export const FILTERS_ORDER_SETTINGS_KEY_CLOUD_INTEGRATIONS = "cloudIntegrationsFiltersOrder";

export const TABLE_COLUMNS_STORAGE_KEY = "cloudIntegrationsColumns";

export enum CLOUD_INTEGRATIONS_COLUMN_ID {
  TYPE = "TYPE",
  NAME = "NAME",
  DROPDOWN = "DROPDOWN",
  SPACE = "SPACE",
}

export const ROW_HEADER_ID = CLOUD_INTEGRATIONS_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 1200;

export const CLOUD_INTEGRATIONS_COLUMN_CONFIG: ColumnConfig = {
  [CLOUD_INTEGRATIONS_COLUMN_ID.TYPE]: {
    title: "Type",
    minWidth: 70,
    defaultWidth: 70,
    maxWidth: 70,
    allowsSorting: true,
    resizable: false,
    static: true,
  },
  [CLOUD_INTEGRATIONS_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    static: true,
  },
  [CLOUD_INTEGRATIONS_COLUMN_ID.SPACE]: {
    title: "Space",
    minWidth: 112,
    defaultWidth: 250,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    static: true,
  },
};

export const CLOUD_INTEGRATIONS_END_COLUMN_CONFIG: ColumnConfig = {
  [CLOUD_INTEGRATIONS_COLUMN_ID.DROPDOWN]: {
    title: "More actions",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
  },
};

export const INITIAL_COLUMNS_CONFIG = {
  visible: Object.keys(CLOUD_INTEGRATIONS_COLUMN_CONFIG) as CLOUD_INTEGRATIONS_COLUMN_ID[],
  hidden: [],
};

export const SORT_CONFIG: Record<string, string> = {
  [CLOUD_INTEGRATIONS_COLUMN_ID.TYPE]: "type",
  [CLOUD_INTEGRATIONS_COLUMN_ID.NAME]: "name",
  [CLOUD_INTEGRATIONS_COLUMN_ID.SPACE]: "space",
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageCloudIntegrations.CloudIntegrationsList,
};
