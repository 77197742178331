import { InputHTMLAttributes, WheelEvent } from "react";

/**
 * By default scrolling on top of the type="number" field will change the value.
 * In most cases this is not the desired behavior. Therefore we're preventing it by bluring the field.
 * @param type
 */
const getNumberFieldFixup = (type: InputHTMLAttributes<HTMLInputElement>["type"]) => {
  if (type === "number") {
    return {
      onWheel: (e: WheelEvent<HTMLInputElement>) => {
        if (document.activeElement === e.target && e.target instanceof HTMLInputElement) {
          e.target.blur();
        }
      },
    };
  }
  return {};
};

export default getNumberFieldFixup;
