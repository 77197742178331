import { useApolloClient } from "@apollo/client";
import debounce from "lodash-es/debounce";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import CardWrapper from "components/CardWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldSpaceTooltipInfo from "components/FormFields/Space/TooltipInfo";
import FormFieldTags from "components/FormFields/Tags";
import LabelsInfo from "components/LabelsInfo";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import FeedbackActions from "ds/components/Feedback/Actions";
import FormField from "ds/components/Form/Field";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import Typography from "ds/components/Typography";
import { useFormValidations } from "hooks/useFormValidations";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";
import useTypedContext from "hooks/useTypedContext";
import { Query } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";

import NewContextAutoAttachedProjects from "../AutoAttachedProjects";
import { CONTEXT_ANALYTICS_VERSION, ContextCreationWizardStepName } from "../constants";
import { ContextFormContext } from "../context";
import NewContextFooter from "../Footer";
import { useAutoAttachmentLabels } from "../hooks/useAutoAttachmentLabels";
import { useSaveContext } from "../hooks/useSaveContext";
import { ContextFormFields } from "../types";
import { SEARCH_CONTEXTS_NAME_SUGGESTIONS } from "./gql";

const NewContextDetails = () => {
  const { goToNextStep } = useTypedContext(ContextFormContext);
  const { onError } = useTypedContext(FlashContext);
  const [hasUnsubmittedLabelsChanges, setHasUnsubmittedLabelsChanges] = useState(false);

  useObserveForWarning(
    hasUnsubmittedLabelsChanges,
    <>
      You have not saved changes in <strong>labels</strong> field. Do you want to continue without
      saving them?
    </>
  );

  const builderForm = useFormContext<ContextFormFields>();
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = builderForm;
  const runFormValidations = useFormValidations(builderForm);

  const [saveContext, isSavingContext] = useSaveContext(ContextCreationWizardStepName.Details);

  const client = useApolloClient();

  const validateName = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const name = e.target.value.trim();

        const { data } = await client.query<Pick<Query, "searchContextsSuggestions">>({
          query: SEARCH_CONTEXTS_NAME_SUGGESTIONS,
          variables: {
            input: {
              fullTextSearch: name,
              predicates: [],
              fields: ["name"],
            },
          },
        });

        const possibleValues =
          data.searchContextsSuggestions.fields[0].possibleValuesString?.values || [];

        const isNameUsed = possibleValues.some((item) => item.toLowerCase() === name.toLowerCase());

        if (isNameUsed) {
          setError("contextName", {
            type: "custom",
            message: "Context with this name already exists.",
          });
          return;
        }

        clearErrors("contextName");
      } catch (error) {
        onError(error);
      }
    },
    [client, onError, setError, clearErrors]
  );

  const debouncedNameValidation = useMemo(() => debounce(validateName, 300), [validateName]);

  const spaceValue = watch("space");
  const labelsValue = useAutoAttachmentLabels(getValues("labels"));

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Add context details
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary">
          Communicate the purpose of the context using name and description, as well as labels. Use
          auto-attach label to attach context automatically.
        </Typography>
        <CardWrapper variant="filled" direction="column" margin="x-large 0 large 0">
          <FormField
            label="Name"
            error={errors?.contextName?.message}
            tooltipInfo="Name is what you’ll see in the context list and while attaching the context."
          >
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter context name here..."
                error={!!errors?.contextName}
                {...register("contextName", {
                  required: "Name field is required.",
                  onChange: debouncedNameValidation,
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
          <FormFieldSpace
            tooltipInfo={
              <FormFieldSpaceTooltipInfo>
                You will be able to attach context only to stacks and modules within this Space.
              </FormFieldSpaceTooltipInfo>
            }
          />
          <FormField label="Description" isOptional>
            {({ ariaInputProps }) => (
              <Textarea
                placeholder="Enter context description here..."
                {...register("description")}
                {...ariaInputProps}
              />
            )}
          </FormField>
          <FormFieldTags
            label={
              <Box gap="small" align="center">
                Labels
                <Typography tag="span" variant="p-body3" color="secondary">
                  (Optional)
                </Typography>
                <LabelsInfo />
              </Box>
            }
            tagName="label"
            name="labels"
            inputSize="regular"
            onInputChange={(value) => setHasUnsubmittedLabelsChanges(!!value)}
          />
        </CardWrapper>
        <Banner variant="info">
          Use <strong>autoattach:label</strong> to attach contexts to stacks automatically or attach
          manually in the next step
          <FeedbackActions>
            <ReadMoreDocsLink
              size="small"
              // TODO: Add docs about auto-attachments
              docsUrl={getDocsUrl("/concepts/configuration/context.html#auto-attachments")}
              analyticsTitle="Learn More Click"
              analyticsPage={AnalyticsPageContext.ContextNew}
              analyticsProps={{
                version: CONTEXT_ANALYTICS_VERSION,
                location: ContextCreationWizardStepName.Details,
              }}
            />
          </FeedbackActions>
        </Banner>

        <Box margin="x-large 0">
          <NewContextAutoAttachedProjects space={spaceValue} labels={labelsValue} />
        </Box>
      </FullScreenModalBody>
      <NewContextFooter
        onCreateContextClick={handleSubmit(saveContext)}
        onContinueClick={handleSubmit(goToNextStep)}
        isCreatingContext={isSavingContext}
        runFormValidations={runFormValidations}
      />
    </>
  );
};

export default NewContextDetails;
