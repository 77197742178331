import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";

import { KubernetesWorkflowTool } from "types/generated";
import { KubernetesWorkflowToolOptions } from "constants/kubernetes_workflow_tools";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Select from "ds/components/Select";
import MissingDataBanner from "components/MissingDataBanner";
import useGetVendorResources from "shared/Stack/Vendor/useGetVendorResources";
import { VENDOR_DICTIONARY, VENDOR_KEY } from "constants/vendor";
import useTypedContext from "hooks/useTypedContext";
import useBlockNavigationModal from "hooks/useBlockNavigationModal";

import { getFormDefaultValues, mapCreateStackVendorUpdateInput } from "./helpers";
import { StackSettingsContextData } from "../../Context";
import StackSettingsFormFooter from "../../components/FormFooter";
import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";
import {
  getKubectlVersionTooltip,
  getNamespaceTooltip,
  getWorkflowToolTooltip,
} from "./getTooltips";

export type StackSettingsKubernetesVendorFormFields = {
  namespace: string;
  kubectlVersion: string;
  kubernetesWorkflowTool: KubernetesWorkflowTool;
};

type StackSettingsVendorKubernetesEditProps = StackSettingsVendorComponentBaseProps;

const StackSettingsVendorKubernetesEdit = ({
  onStackUpdate,
  updateLoading,
}: StackSettingsVendorKubernetesEditProps) => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const { kubectlVersions, isRefetching, refetch } = useGetVendorResources({
    vendorSpecific: VENDOR_KEY.KUBERNETES,
  });

  const kubernetesVendorForm = useForm<StackSettingsKubernetesVendorFormFields>({
    defaultValues: getFormDefaultValues(stackSettings),
    mode: "onChange",
  });
  const {
    register,
    control,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
  } = kubernetesVendorForm;

  useBlockNavigationModal({ isNavigationBlocked: isDirty });

  const watchedKubernetesWorkflowTool = watch("kubernetesWorkflowTool");

  const handleWorkflowToolChange = useCallback(
    (onFieldChange: (value: KubernetesWorkflowTool) => void) => (value: KubernetesWorkflowTool) => {
      if (watchedKubernetesWorkflowTool === value) {
        return undefined;
      }

      let version = "";

      if (value === KubernetesWorkflowTool.Kubernetes) {
        version = kubectlVersions ? kubectlVersions[0] : "";
      }

      setValue("kubectlVersion", version);
      onFieldChange(value);

      trigger();
    },
    [kubectlVersions, setValue, trigger, watchedKubernetesWorkflowTool]
  );

  const isVersionInputEnabled = watchedKubernetesWorkflowTool !== KubernetesWorkflowTool.Custom;

  const versionOptions = useMemo(
    () =>
      kubectlVersions?.map((value) => ({
        value,
        label: value,
      })),
    [kubectlVersions]
  );

  const onSubmit = (formData: StackSettingsKubernetesVendorFormFields) => {
    onStackUpdate(mapCreateStackVendorUpdateInput(formData));
  };

  useEffect(() => {
    reset(getFormDefaultValues(stackSettings));
  }, [reset, stackSettings]);

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.KUBERNETES} />
      <FormField
        label="Namespace"
        error={errors?.namespace?.message}
        {...getNamespaceTooltip()}
        isOptional
        noMargin
      >
        {({ ariaInputProps }) => (
          <Input
            placeholder="Kubernetes namespace"
            {...register("namespace")}
            error={!!errors?.namespace?.message}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <Controller
        name="kubernetesWorkflowTool"
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            error={fieldState.error?.message}
            label="Workflow tool"
            {...getWorkflowToolTooltip()}
            noMargin
          >
            {({ ariaInputProps }) => (
              <Select
                value={field.value}
                options={KubernetesWorkflowToolOptions}
                onChange={handleWorkflowToolChange(field.onChange)}
                error={!!fieldState.error?.message}
                ariaInputProps={ariaInputProps}
              />
            )}
          </FormField>
        )}
      />
      {isVersionInputEnabled && (
        <Controller
          name="kubectlVersion"
          control={control}
          rules={{ required: "Kubectl version field is required." }}
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              label="Kubectl version"
              {...getKubectlVersionTooltip()}
              noMargin
            >
              {({ ariaInputProps }) =>
                versionOptions ? (
                  <Select
                    value={field.value}
                    options={versionOptions}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    error={!!fieldState.error?.message}
                    ariaInputProps={ariaInputProps}
                  />
                ) : (
                  <MissingDataBanner refreshLoading={isRefetching} refreshHandler={refetch} />
                )
              }
            </FormField>
          )}
        />
      )}
      <StackSettingsFormFooter
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset(getFormDefaultValues(stackSettings))}
        isDirty={isDirty}
        isSubmitDisabled={updateLoading}
        submitLoading={updateLoading}
      />
    </>
  );
};

export default StackSettingsVendorKubernetesEdit;
