import Box from "ds/components/Box";

import { CellComponentProps } from "../types";
import UsersListItemRoleBadge from "./RoleBadge";

type UsersListItemRoleCellProps = CellComponentProps;

const UsersListItemRoleCell = ({ user }: UsersListItemRoleCellProps) => {
  return (
    <Box direction="row" align="center">
      <UsersListItemRoleBadge role={user.role} />
    </Box>
  );
};

export default UsersListItemRoleCell;
