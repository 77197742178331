import { ReactNode } from "react";

import { InfoCircle } from "components/icons/generated";
import ListContentWrapper from "components/ListContentWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ButtonIcon from "ds/components/ButtonIcon";
import Tooltip from "ds/components/Tooltip";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTypedContext from "hooks/useTypedContext";
import useTypedFlags from "hooks/useTypedFlags";

import { SettingsContext } from "../Context";
import SeatLimitsNotification from "../SeatLimitsNotification";
import { SEAT_LIMITS_TOOLTIP } from "../SeatLimitsNotification/constants";
import { SeatLimitsNotificationEntity } from "../SeatLimitsNotification/types";
import useSeatLimit from "../SeatLimitsNotification/useSeats";
import { UserManagementActivationStatus } from "../types";
import UserManagementWarningCallout from "../UserManagementWarningCallout";
import NonRootApiKeysToggle from "./NonRootApiKeysToggle";

type ApiKeysPageLayoutProps = {
  children: ReactNode;
  openCreateApiKeyDrawer?: () => void;
  openInfoDrawer: () => void;
};

const ApiKeysPageLayout = ({
  children,
  openCreateApiKeyDrawer,
  openInfoDrawer,
}: ApiKeysPageLayoutProps) => {
  const { enableApiKeyCreationForNonRootAdminsFrontend } = useTypedFlags();
  const { seats, activationStatus } = useTypedContext(SettingsContext);
  const { isLimitReached } = useSeatLimit(seats.apiKey);

  return (
    <>
      {activationStatus === UserManagementActivationStatus.ACTIVE && (
        <SeatLimitsNotification
          entity={SeatLimitsNotificationEntity.ApiKey}
          seats={seats.apiKey}
          type="callout"
        />
      )}
      <UserManagementWarningCallout analyticsPage={AnalyticsPageOrganization.OrganizationApiKeys} />
      <ViewHeader firstLevel>
        <Box direction="column" fullWidth>
          <Box fullWidth justify="between">
            <Box direction="row" align="center" __deprecatedGap="1.1rem">
              <ViewHeaderTitle tag="h2">API Keys</ViewHeaderTitle>
              <ButtonIcon onPress={openInfoDrawer} icon={InfoCircle} variant="ghost">
                Open info drawer
              </ButtonIcon>
            </Box>
            <ViewHeaderWrapper direction="row">
              {openCreateApiKeyDrawer && (
                <Tooltip
                  active={isLimitReached}
                  on={(props) => (
                    <Button
                      onPress={openCreateApiKeyDrawer}
                      variant="primary"
                      disabled={isLimitReached}
                      {...props}
                    >
                      Create API key
                    </Button>
                  )}
                >
                  {SEAT_LIMITS_TOOLTIP}
                </Tooltip>
              )}
            </ViewHeaderWrapper>
          </Box>
          {enableApiKeyCreationForNonRootAdminsFrontend && (
            <Box margin="large 0 small">
              <NonRootApiKeysToggle />
            </Box>
          )}
        </Box>
      </ViewHeader>
      <ListContentWrapper>{children}</ListContentWrapper>
    </>
  );
};

export default ApiKeysPageLayout;
