import Box from "ds/components/Box";
import Tooltip from "ds/components/Tooltip";
import { UserStatus } from "types/generated";
import { SettingsContext } from "views/Account/Settings/Context";
import useTypedContext from "hooks/useTypedContext";
import { UserManagementActivationStatus } from "views/Account/Settings/types";

import UserStatusBadge from "../../../../../../components/UserStatusBadge";
import { CellComponentProps } from "../types";

type UsersListItemStatusCellProps = CellComponentProps;

const UsersListItemStatusCell = ({ user }: UsersListItemStatusCellProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;
  const hasQueuedStatus = user.status === UserStatus.Queued;
  const hasPendingStatue = user.status === UserStatus.Pending;

  return (
    <Box direction="row" align="center">
      {hasQueuedStatus && (
        <Tooltip
          widthMode="maxWidthSm"
          on={(props) => <UserStatusBadge status={user.status} {...props} />}
        >
          {!isUserManagementActive
            ? "Invite will be sent once management strategy is changed to user management"
            : ""}
        </Tooltip>
      )}
      {hasPendingStatue && (
        <Tooltip on={(props) => <UserStatusBadge status={user.status} {...props} />}>
          Waiting for the user to accept the invite
        </Tooltip>
      )}
      {!(hasQueuedStatus || hasPendingStatue) && <UserStatusBadge status={user.status} />}
    </Box>
  );
};

export default UsersListItemStatusCell;
