import useTypedContext from "hooks/useTypedContext";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTitle from "hooks/useTitle";

import { StackContext } from "../Context";
import StackHeader from "../components/Header";
import { getStacksBackUrl } from "../helpers";
import StackViewResourcesIacManagement from "./IacManagement";

const StackResourcesNew = () => {
  const { stack } = useTypedContext(StackContext);

  useTitle(`IaC Management · ${stack.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  return (
    <>
      <StackHeader />
      <StackViewResourcesIacManagement stackId={stack.id} />
    </>
  );
};

export default StackResourcesNew;
