import useAnalytics from "hooks/useAnalytics";

import { ANALYTICS } from "./constants";

const useIntegrationsListAnalytics = () => {
  return useAnalytics({
    page: ANALYTICS.analyticsPage,
  });
};

export default useIntegrationsListAnalytics;
