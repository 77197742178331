import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import useTypedContext from "hooks/useTypedContext";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { AzureIntegration } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { SpacesContext } from "views/Account/SpacesProvider";
import { DEFAULT_SPACE_NAME } from "views/constants";

import { CREATE_AZURE_INTEGRATION, UPDATE_AZURE_INTEGRATION } from "./gql";

type FormValues = {
  name: string;
  tenantId: string;
  defaultSubscriptionId: string;
  labels: { value: string }[];
  space: string;
};

type AzureIntegrationFormDrawerProps = {
  trackAnalytics: (event: string, props?: TrackAnalyticsEventProperties) => void;
  integration?: AzureIntegration;
  refetchQueriesOnEdit?: string[];
};

const AzureIntegrationFormDrawer = createDrawer(
  ({ integration, trackAnalytics, refetchQueriesOnEdit }: AzureIntegrationFormDrawerProps) => {
    const drawer = useModal();
    const navigate = useNavigate();
    const isEditMode = !!integration?.id;
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const { manageableSpaces } = useTypedContext(SpacesContext);

    const builderForm = useForm<FormValues>({
      defaultValues: {
        name: integration?.name || "",
        tenantId: integration?.tenantId || "",
        defaultSubscriptionId: integration?.defaultSubscriptionId || "",
        labels: integration?.labels?.map((label) => ({ value: label })) || [],
        space:
          integration?.spaceDetails?.id ||
          manageableSpaces.find((s) => s.id === DEFAULT_SPACE_NAME)?.id ||
          manageableSpaces[0]?.id,
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { errors, isDirty },
    } = builderForm;

    const [createIntegration, { loading: creatingIntegration }] =
      useMutation(CREATE_AZURE_INTEGRATION);

    const [updateIntegration, { loading: updatingIntegration }] = useMutation(
      UPDATE_AZURE_INTEGRATION,
      {
        awaitRefetchQueries: true,
        refetchQueries: refetchQueriesOnEdit,
      }
    );

    useEffect(() => {
      trackAnalytics("Open Integration Form", {
        type: "azure",
        mode: isEditMode ? "edit" : "create",
      });
    }, [integration, isEditMode, trackAnalytics]);

    const handleCloseDrawer = () => {
      drawer.hide();
    };

    const onCreateSubmit = (formData: FormValues) => {
      createIntegration({
        variables: {
          name: formData.name.trim(),
          tenantID: formData.tenantId.trim(),
          defaultSubscriptionId: formData.defaultSubscriptionId.trim(),
          labels: formData.labels.map((label) => label.value),
          space: formData.space,
        },
      })
        .then(({ data }) => {
          if (data?.azureIntegrationCreate?.id) {
            handleCloseDrawer();
            reportSuccess({ message: "Azure integration was successfully created" });
            trackAnalytics("Create Integration", { type: "azure" });
            navigate(`/cloud-integrations/azure/${data.azureIntegrationCreate.id}`);
          }
        })
        .catch(onError);
    };

    const onUpdateSubmit = (formData: FormValues) => {
      if (!integration) {
        return;
      }

      updateIntegration({
        variables: {
          id: integration.id,
          name: formData.name.trim(),
          defaultSubscriptionId: formData.defaultSubscriptionId.trim(),
          labels: formData.labels.map((label) => label.value),
          space: formData.space,
        },
      })
        .then(({ data }) => {
          if (data?.azureIntegrationUpdate?.id) {
            reportSuccess({ message: "Azure integration was successfully updated" });
            handleCloseDrawer();
            trackAnalytics("Update Integration", { type: "azure" });
          }
        })
        .catch(onError);
    };

    const onSubmit = (formData: FormValues) => {
      if (isEditMode) {
        onUpdateSubmit(formData);
      } else {
        onCreateSubmit(formData);
      }
    };

    const onClose = () => {
      trackAnalytics("Close Integration Form", {
        type: "azure",
        mode: isEditMode ? "edit" : "create",
        dirty: isDirty,
      });
    };

    return (
      <DrawerForm isDirty={isDirty} onClose={onClose}>
        <FormProvider {...builderForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle
                title={isEditMode ? "Edit Azure integration" : "Set up Azure integration"}
              />
              <DrawerCloseIcon />
            </DrawerHeader>

            <DrawerBody gap="x-large" fullHeight hasStickyFooter>
              <FormField label="Name" error={errors?.name?.message} noMargin>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Name your integration"
                    error={!!errors?.name}
                    {...register("name", { required: "Name field is required." })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldSpace noMargin />

              {!isEditMode && (
                <FormField label="Tenant ID" error={errors?.tenantId?.message} noMargin>
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Microsoft Entra Tenant ID"
                      error={!!errors?.tenantId}
                      {...register("tenantId", { required: "Tenant ID is required." })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
              )}

              <FormField
                label="Default Subscription ID"
                error={errors?.defaultSubscriptionId?.message}
                isOptional
                noMargin
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Default Subscription ID</TooltipModalTitle>
                    <TooltipModalBody>
                      The default Azure subscription to use if not overridden at the stack level
                    </TooltipModalBody>
                  </>
                }
                tooltipInfoVariant="modal"
              >
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter subscription ID here"
                    {...register("defaultSubscriptionId")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <Box direction="column">
                <FormFieldTags
                  stopEnterPropagation
                  label="Labels"
                  tagName="label"
                  name="labels"
                  isOptional
                  noMargin
                />
              </Box>
              <DrawerFooter sticky>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={creatingIntegration || updatingIntegration}
                    loading={creatingIntegration || updatingIntegration}
                  >
                    {isEditMode ? "Save" : "Set up"}
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showAzureIntegrationFormDrawer = createDrawerTrigger(AzureIntegrationFormDrawer);
