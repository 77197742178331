import {
  addDays,
  differenceInDays,
  fromUnixTime,
  getUnixTime,
  isSameDay,
  subDays,
} from "date-fns/esm";
import { useMemo, useRef } from "react";
import { NetworkStatus } from "@apollo/client";

import { EmptystateChartColored } from "components/icons/generated";
import { Datum } from "ds/components/Charts/BarChart/Chart";
import MissingDataBanner from "components/MissingDataBanner";
import usePolledQuery from "apollo/usePolledQuery";
import { PollingIntervalGroups } from "apollo/constants";

import DashboardWidgetsEmptyStateRuns from "../EmptyStateRuns";
import { GET_MEDIAN_RUN_DURATION } from "./gql";
import { Widget } from "../../types";
import DashboardWidgetsRunDuration from "../RunDuration";
import { GetMedianRunDuration } from "./types";

const DATA_RANGE_DAYS = 30;

const DashboardWidgetsMedianRunDuration = () => {
  const startTime = useRef(subDays(new Date(), DATA_RANGE_DAYS));
  const endTime = useRef(new Date());

  const { loading, data, error, refetch, networkStatus } = usePolledQuery<GetMedianRunDuration>(
    GET_MEDIAN_RUN_DURATION,
    {
      variables: {
        startTime: getUnixTime(startTime.current),
        endTime: getUnixTime(endTime.current),
      },
      pollingGroup: PollingIntervalGroups.Dashboard,
    }
  );

  const showErrorBanner = !!error;

  const isLoading = (loading && !data?.metricsRange) || networkStatus === NetworkStatus.refetch;

  const medianRunDuration = showErrorBanner
    ? 0
    : (data?.metricsRange?.medianRunDuration?.[0]?.value ?? 0);

  const hasRuns = !!data?.metricsRange?.hasRuns;

  let emptyState;

  if (!hasRuns) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns
        title="There's no data to show yet"
        caption="Trigger your first run so we could display median run duration here."
        icon={EmptystateChartColored}
        widget={Widget.MedianRunDuration}
        showDocs
      />
    );
  }

  let errorBanner;

  if (showErrorBanner) {
    emptyState = undefined;
    errorBanner = (
      <MissingDataBanner
        text="Couldn’t load runs data. Please try to refresh or come back later."
        refreshHandler={refetch}
      />
    );
  }

  const chartData = useMemo(() => {
    const startDate = startTime.current;
    const endDate = endTime.current;
    // Create array of timestamps starting from startTime to endTime
    const timestamps = Array.from({ length: differenceInDays(endDate, startDate) }, (_, i) =>
      addDays(startDate, i + 1)
    );

    return timestamps
      .map((date) => {
        const foundValue = data?.metricsRange?.medianRunDurationRange?.find((item) =>
          isSameDay(fromUnixTime(item.timestamp), date)
        )?.value;

        const value = foundValue || 0;

        return {
          // TODO: BE return value in seconds but our chart has minutes
          value: value / 60,
          timestamp: getUnixTime(date),
        };
      })
      .sort((a, b) => a.timestamp - b.timestamp) as Datum[];
  }, [data?.metricsRange?.medianRunDurationRange]);

  return (
    <DashboardWidgetsRunDuration
      cardTitle="30d median"
      tooltipHeader="Median:"
      data={chartData}
      duration={medianRunDuration}
      isLoading={isLoading}
      emptyState={emptyState}
      errorBanner={errorBanner}
    />
  );
};

export default DashboardWidgetsMedianRunDuration;
