import { VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import {
  ConfigElement,
  ConfigType,
  Hooks,
  Stack,
  StackConfigVendorAnsible,
  StackConfigVendorCloudFormation,
  StackConfigVendorKubernetes,
  StackConfigVendorPulumi,
  StackConfigVendorTerraform,
  StackConfigVendorTerragrunt,
  StackContextAttachment,
  StackState,
  VcsProvider,
} from "types/generated";

import { StackConfig } from "./types";

export const stackToCode = (stack: Stack, stackConfig?: StackConfig): string => {
  if (!stackConfig) {
    return "";
  }

  const sections = [
    `resource "spacelift_stack" "${stack.id}" {
  name = "${stack.name}"
  space_id = "${stack.spaceDetails.id}"`,
    getVcsDetails(stack),
    getVendorDetails(stack),
    additionalProperties(stack),
    hooks(stackConfig.hooks),
  ].filter((section) => section.trim().length > 0); // Filter out empty sections

  sections[sections.length - 1] += "\n}";

  const optionalSections = [
    environmentVariables(stack.id, stackConfig.config),
    contextResources(stack.id, stackConfig.attachedContexts),
  ].filter((section) => section.trim().length > 0); // Filter out empty sections

  return [...sections, ...optionalSections].join("\n\n");
};

const whitespace = (indent: number): string => " ".repeat(indent);

const getVcsDetails = (stack: Stack): string => {
  if (!stack.provider) return "";

  const vcsIntegration = stack.vcsIntegration ? `id = "${stack.vcsIntegration.id}"` : "";

  const vcsDetailsMap: Record<VcsProvider, string> = {
    [VcsProvider.AzureDevops]: `azure_devops {\n${indent(4, `${vcsIntegration}\nproject = "${stack.namespace}"`)}\n  }`,
    [VcsProvider.BitbucketCloud]: `bitbucket_cloud {\n${indent(4, `${vcsIntegration}\nnamespace = "${stack.namespace}"`)}\n  }`,
    [VcsProvider.BitbucketDatacenter]: `bitbucket_datacenter {\n${indent(4, `${vcsIntegration}\nnamespace = "${stack.namespace}"`)}\n  }`,
    [VcsProvider.Git]: `raw_git {\n${indent(4, `namespace = "${stack.namespace}"\nurl = "${stack.repositoryURL}"`)}\n  }`,
    [VcsProvider.Github]: `namespace = "${stack.namespace}"`,
    [VcsProvider.GithubEnterprise]: `github_enterprise {\n${indent(4, `${vcsIntegration}\nnamespace = "${stack.namespace}"`)}\n  }`,
    [VcsProvider.Gitlab]: `gitlab {\n${indent(4, `${vcsIntegration}\nnamespace = "${stack.namespace}"`)}\n  }`,
    [VcsProvider.Showcase]: `showcase {\n${indent(4, `namespace = "${stack.namespace}"`)}\n  }`,
  };

  return [
    vcsDetailsMap[stack.provider] || "",
    `repository = "${stack.repository}"`,
    `branch = "${stack.branch}"`,
    stack.projectRoot ? `project_root = "${stack.projectRoot}"` : "",
  ]
    .filter(Boolean)
    .map((line) => whitespace(2) + line)
    .join("\n");
};

const indent = (level: number, content: string): string =>
  content
    .split("\n")
    .map((line) => `${whitespace(level)}${line}`)
    .join("\n");

const getVendorDetails = (stack: Stack): string => {
  if (!stack.vendorConfig) return "";

  const vendorDetailsMap: Record<VENDOR_CONFIG_TYPENAME, string> = {
    [VENDOR_CONFIG_TYPENAME.ANSIBLE]: `ansible {\n${indent(4, `playbook = "${(stack.vendorConfig as StackConfigVendorAnsible).playbook}"`)}\n}`,
    [VENDOR_CONFIG_TYPENAME.CLOUD_FORMATION]: `cloudformation {\n${indent(
      2,
      [
        `entry_template_file = "${(stack.vendorConfig as StackConfigVendorCloudFormation).entryTemplateFile}"`,
        `region = "${(stack.vendorConfig as StackConfigVendorCloudFormation).region}"`,
        `stack_name = "${(stack.vendorConfig as StackConfigVendorCloudFormation).stackName}"`,
        `template_bucket = "${(stack.vendorConfig as StackConfigVendorCloudFormation).templateBucket}"`,
      ].join("\n")
    )}\n}`,
    [VENDOR_CONFIG_TYPENAME.KUBERNETES]: `kubernetes {\n${indent(
      2,
      [
        `kubectl_version = "${(stack.vendorConfig as StackConfigVendorKubernetes).kubectlVersion}"`,
        `namespace = "${(stack.vendorConfig as StackConfigVendorKubernetes).namespace}"`,
      ].join("\n")
    )}\n}`,
    [VENDOR_CONFIG_TYPENAME.PULUMI]: `pulumi {\n${indent(
      2,
      [
        `login_url = "${(stack.vendorConfig as StackConfigVendorPulumi).loginURL}"`,
        `stack_name = "${(stack.vendorConfig as StackConfigVendorPulumi).stackName}"`,
      ].join("\n")
    )}\n}`,
    [VENDOR_CONFIG_TYPENAME.TERRAFORM]: getTerraformDetails(
      stack.vendorConfig as StackConfigVendorTerraform
    ),
    [VENDOR_CONFIG_TYPENAME.TERRAGRUNT]: `terragrunt {\n${indent(
      2,
      [
        `terraform_version = "${(stack.vendorConfig as StackConfigVendorTerragrunt).terraformVersion}"`,
        `terragrunt_version = "${(stack.vendorConfig as StackConfigVendorTerragrunt).terragruntVersion}"`,
        `tool = "${(stack.vendorConfig as StackConfigVendorTerragrunt).tool}"`,
        `use_run_all = ${(stack.vendorConfig as StackConfigVendorTerragrunt).useRunAll}`,
        `use_smart_sanitization = ${(stack.vendorConfig as StackConfigVendorTerragrunt).useSmartSanitization}`,
      ].join("\n")
    )}\n}`,
  };

  return indent(2, vendorDetailsMap[stack.vendorConfig.__typename!] || "");
};

const getTerraformDetails = (vendorConfig: StackConfigVendorTerraform): string => {
  return [
    vendorConfig.version ? `terraform_version = "${vendorConfig.version}"` : "",
    vendorConfig.workflowTool ? `terraform_workflow_tool = "${vendorConfig.workflowTool}"` : "",
    vendorConfig.externalStateAccessEnabled ? `terraform_external_state_access = true` : "",
    vendorConfig.useSmartSanitization ? `terraform_smart_sanitization = true` : "",
    vendorConfig.workspace ? `terraform_workspace = "${vendorConfig.workspace}"` : "",
  ]
    .filter(Boolean)
    .join("\n");
};

const additionalProperties = (stack: Stack): string => {
  const propertiesMap = {
    description: stack.description ? `"${stack.description}"` : "",
    labels: stack.labels.length ? `[${stack.labels.map((label) => `"${label}"`).join(", ")}]` : "",
    autodeploy: stack.autodeploy ? "true" : "",
    autoretry: stack.autoretry ? "true" : "",
    additional_project_globs: stack.additionalProjectGlobs.length
      ? `[${stack.additionalProjectGlobs.map((glob) => `"${glob}"`).join(", ")}]`
      : "",
    administrative: stack.administrative ? "true" : "",
    enable_local_preview: stack.localPreviewEnabled ? "true" : "",
    enable_well_known_secret_masking: stack.enableWellKnownSecretMasking ? "true" : "",
    github_action_deploy: stack.githubActionDeploy ? "" : "false",
    manage_state: stack.state !== StackState.None && !stack.managesStateFile ? "false" : "",
    import_state_file:
      stack.state !== StackState.None && !stack.managesStateFile
        ? `"<file-path-to-state-file>"`
        : "",
    protect_from_deletion: stack.protectFromDeletion ? "true" : "",
    runner_image: stack.runnerImage ? `"${stack.runnerImage}"` : "",
    worker_pool_id: stack.workerPool?.id,
  };

  return Object.entries(propertiesMap)
    .filter(([, value]) => value)
    .map(([key, value]) => `${whitespace(2)}${key} = ${value}`)
    .join("\n");
};

const hooks = (hooks: Hooks): string => {
  const hookTypes: (keyof Hooks)[] = [
    "afterApply",
    "afterDestroy",
    "afterInit",
    "afterPerform",
    "afterPlan",
    "afterRun",
    "beforeApply",
    "beforeDestroy",
    "beforeInit",
    "beforePerform",
    "beforePlan",
  ];

  const hookTypesToTerraform: Record<keyof Hooks, string> = {
    __typename: "",
    afterApply: "after_apply",
    afterDestroy: "after_destroy",
    afterInit: "after_init",
    afterPerform: "after_perform",
    afterPlan: "after_plan",
    afterRun: "after_run",
    beforeApply: "before_apply",
    beforeDestroy: "before_destroy",
    beforeInit: "before_init",
    beforePerform: "before_perform",
    beforePlan: "before_plan",
  };

  return hookTypes
    .filter((hookType) => hooks?.[hookType]?.length)
    .map(
      (hookType) =>
        `${whitespace(2)}${hookTypesToTerraform[hookType]} = [${(hooks?.[hookType] as string[]).map((hook) => `"${hook}"`).join(", ")}]`
    )
    .join("\n");
};

function contextResources(stackId: string, attachedContexts: StackContextAttachment[]): string {
  if (!attachedContexts || !attachedContexts.length) {
    return "";
  }

  return attachedContexts
    .filter((context) => !context.isAutoattached)
    .map(
      (context) => `resource "spacelift_context_attachment" "${context.contextId}" {
  context_id = "${context.id}"
  stack_id = spacelift_stack.${stackId}.id${context.priority ? `\n  priority = ${context.priority}` : ""}
}`
    )
    .join("\n\n");
}

function environmentVariables(stackId: string, config: ConfigElement[]): string {
  if (!config || !config.length) {
    return "";
  }

  return config
    .filter((element) => element.createdAt) // Filter out runtime variables
    .map((element) =>
      element.type == ConfigType.EnvironmentVariable
        ? `resource "spacelift_environment_variable" "${element.id}" {
  name = "${element.id}"${element.description ? `\n  description = "${element.description}"` : ""}
  write_only = ${element.writeOnly}
  stack_id = spacelift_stack.${stackId}.id${element.value?.length ? `\n  value = "${element.value}"` : `\n  value = "<secret-to-fill>"`}
}`
        : `resource "spacelift_mounted_file" "${element.id}" {
  relative_path = "${element.id}"
  stack_id = spacelift_stack.${stackId}.id
  content = ${element.value ? `"${element.value}"` : `"<file-content-here>"`}
  write_only = ${element.writeOnly}${element.description ? `\n  description = "${element.description}"` : ""}
}`
    )
    .join("\n\n");
}
