import { SearchQueryPredicate } from "types/generated";

export const makeStringMatchesPredicate = (
  field: string,
  values: string[]
): SearchQueryPredicate => ({
  field: field,
  exclude: null,
  constraint: {
    stringMatches: values,
    booleanEquals: null,
    enumEquals: null,
    hierarchyNodeValueEquals: null,
    timeInLast: null,
    timeInRange: null,
  },
});
