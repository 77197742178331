import { useModal } from "@ebay/nice-modal-react";
import { useCallback, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useShallow } from "zustand/react/shallow";

import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import CustomizeViewItems from "components/CustomizeView/Items";
import Box from "ds/components/Box";
import { reorderMultipleDNDLists } from "utils/dnd";
import { TableColumnMeta } from "ds/components/Table/types";

import { showResetConfirmation } from "./ResetConfirmation";
import useIacTableStore from "../Table/useIacTableStore";
import { selectColumnsConfig } from "../Table/useIacTableStore/tableMetaSelectors";
import useIacManagementAnalytics from "../../useIacManagementAnalytics";

const getLabel = (columns: Record<string, TableColumnMeta>) => (key: string) =>
  columns[key].title || "";

const TABLE_CONFIG_CHANGED_ANALYTIC_EVENT = "Table configuration changed";

const IacManagementTableSettingsDrawer = createDrawer(() => {
  const drawer = useModal();
  const { trackAnalytics } = useIacManagementAnalytics();

  const { columnsConfig, columnsState, updateColumnsConfig, resetColumnsConfig } = useIacTableStore(
    useShallow((state) => ({
      columnsConfig: selectColumnsConfig(state),
      columnsState: state.columnsState,
      updateColumnsConfig: state.updateColumnsConfig,
      resetColumnsConfig: state.resetColumnsConfig,
    }))
  );

  const [isDragActive, setIsDragActive] = useState(false);

  const resetConfig = useCallback(() => {
    resetColumnsConfig();
    trackAnalytics(TABLE_CONFIG_CHANGED_ANALYTIC_EVENT, { reset: true });
    drawer.hide();
  }, [drawer, resetColumnsConfig, trackAnalytics]);

  const handleDragStart = useCallback(() => {
    setIsDragActive(true);
  }, []);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      setIsDragActive(false);

      if (!result.destination) {
        return;
      }

      const newConfig = reorderMultipleDNDLists(
        {
          visible: columnsConfig.visibleColumns,
          hidden: columnsConfig.hiddenColumns,
        },
        result.source.droppableId,
        result.destination.droppableId,
        result.source.index,
        result.destination.index
      );

      /**
       * FYI: it's an official "hard code" to keep the first and last columns on the same positions,
       * at least till we decide how to deal with it [don't copy it]
       */
      if (
        newConfig.visible[0] !== columnsConfig.staticColumns[0] ||
        newConfig.visible[newConfig.visible.length - 1] !==
          columnsConfig.staticColumns[columnsConfig.staticColumns.length - 1]
      ) {
        return undefined;
      }

      trackAnalytics(TABLE_CONFIG_CHANGED_ANALYTIC_EVENT, { ...newConfig, reset: false });
      updateColumnsConfig(newConfig.visible, newConfig.hidden);
    },
    [
      columnsConfig.hiddenColumns,
      columnsConfig.staticColumns,
      columnsConfig.visibleColumns,
      trackAnalytics,
      updateColumnsConfig,
    ]
  );

  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Customize list" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody noPadding>
        <Box direction="column" grow="1" fullWidth>
          <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <Box direction="column" padding="large" gap="large" fullWidth>
              <CustomizeViewItems
                title="Visible columns"
                type="visible"
                items={columnsConfig.visibleColumns}
                getItemLabel={getLabel(columnsState)}
                disabledItems={columnsConfig.staticColumns}
              />

              <CustomizeViewItems
                title="Hidden columns"
                type="hidden"
                items={columnsConfig.hiddenColumns}
                disabledItems={columnsConfig.staticColumns}
                isDragActive={isDragActive}
                getItemLabel={getLabel(columnsState)}
              />
            </Box>
          </DragDropContext>
        </Box>
      </DrawerBody>
      <DrawerFooter>
        <Button onPress={() => showResetConfirmation({ onReset: resetConfig })} variant="secondary">
          Reset to default
        </Button>
      </DrawerFooter>
    </DrawerSimple>
  );
});

export const showIacManagementTableSettingsDrawer = createDrawerTrigger(
  IacManagementTableSettingsDrawer
);
