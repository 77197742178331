import { gql } from "@apollo/client";

import usePolledQuery from "apollo/usePolledQuery";
import { Bell } from "components/icons/generated";
import { LayoutContext } from "components/layout/Context";
import Counter from "ds/components/Counter";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { PollingIntervalGroups } from "apollo/constants";

import MenuItem from "../MenuItem";
import styles from "./styles.module.css";

const NOTIFICATIONS_COUNT = gql`
  query NotificationsCount {
    notificationCount
  }
`;

const NOTIFICATION_MENU_ITEM_RULE = ["/notifications"];

const NotificationsMenuItem = () => {
  const { isExpandedMode } = useTypedContext(LayoutContext);
  const { viewer } = useTypedContext(AccountContext);

  const { data } = usePolledQuery<{ notificationCount: number }>(NOTIFICATIONS_COUNT, {
    pollingGroup: PollingIntervalGroups.Long,
    // APOLLO CLIENT UPDATE
  });

  const count = data?.notificationCount;

  if (!viewer.admin) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <MenuItem
        short={!isExpandedMode}
        icon={Bell}
        to="/notifications"
        rule={NOTIFICATION_MENU_ITEM_RULE}
        ariaLabel="Notification inbox"
      >
        Notification Inbox
      </MenuItem>

      {!!count && (
        <Counter
          variant="highlighted"
          className={styles.count}
          count={count > 99 ? "99+" : count}
          size="small"
        />
      )}
    </div>
  );
};

export default NotificationsMenuItem;
