import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";

import { ANALYTICS } from "../constants";
import { CellComponentProps } from "../types";
import { showUnassignConfirmation } from "../UnassignConfirmation";

type UserRolesListItemActionsDropdownCellProps = CellComponentProps;

const UserRolesListItemActionsDropdownCell = ({
  roleBinding,
}: UserRolesListItemActionsDropdownCellProps) => {
  const { hasRootSpaceAccess } = useTypedContext(SpacesContext);

  const roleUrl = `/settings/role/${roleBinding.roleID}`;

  return (
    <DropdownMenuEllipsis tooltip="Role actions">
      {!roleBinding.role.isSystem && (
        <DropdownMenuItem
          {...ANALYTICS}
          analyticsTitle="Preview permissions clicked"
          href={roleUrl}
        >
          Preview permissions
        </DropdownMenuItem>
      )}
      <DropdownMenuItem
        {...ANALYTICS}
        danger
        analyticsTitle="Delete clicked"
        tooltip="You don't have an access to manage user roles"
        isTooltipActive={!hasRootSpaceAccess}
        isDisabled={!hasRootSpaceAccess}
        onAction={() =>
          showUnassignConfirmation({ id: roleBinding.id, name: roleBinding.role.name })
        }
      >
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default UserRolesListItemActionsDropdownCell;
