import { useCallback, KeyboardEvent } from "react";
import { Node } from "reactflow";

import { SpaceItemType } from "./types";

type UseKeyboardNavigationProps = {
  visibleNodes: Node<SpaceItemType>[];
  onSelect?: (id: string) => void;
  onFocus?: (id: string) => void;
};

const useKeyboardNavigation = ({ visibleNodes, onSelect, onFocus }: UseKeyboardNavigationProps) => {
  return useCallback(
    (e: KeyboardEvent) => {
      const id = "getAttribute" in e.target && (e.target as HTMLElement).getAttribute("data-id");

      if (!id) {
        return;
      }

      if (e.key === "Enter") {
        if (!onSelect) {
          return;
        }

        onSelect(id);

        return;
      }

      const currentIndex = visibleNodes.findIndex((node) => node.id === id);

      if (currentIndex < 0 || !visibleNodes[currentIndex]) {
        return;
      }

      if (e.key === "ArrowDown") {
        e.preventDefault();
        const nextNode = visibleNodes[currentIndex].data.children[0];

        if (!nextNode) {
          return;
        }

        onFocus?.(nextNode);
      }

      if (e.key === "ArrowUp") {
        e.preventDefault();
        const prevNode = visibleNodes[currentIndex].data.item.parentSpace;

        if (!prevNode) {
          return;
        }

        onFocus?.(prevNode);
      }

      if (e.key === "ArrowRight") {
        e.preventDefault();
        const nextNode = visibleNodes[currentIndex + 1];
        if (
          !nextNode ||
          visibleNodes[currentIndex].data.item.parentSpace !== nextNode.data.item.parentSpace
        ) {
          return;
        }

        onFocus?.(nextNode.id);
      }

      if (e.key === "ArrowLeft") {
        const nextNode = visibleNodes[currentIndex - 1];

        if (
          !nextNode ||
          visibleNodes[currentIndex].data.item.parentSpace !== nextNode.data.item.parentSpace
        ) {
          return;
        }

        onFocus?.(nextNode.id);
      }

      if (e.key === "Home") {
        const firstNode = visibleNodes[0];

        if (!firstNode) {
          return;
        }

        onFocus?.(firstNode.id);
      }

      if (e.key === "End") {
        const lastNode = visibleNodes[visibleNodes.length - 1];

        if (!lastNode) {
          return;
        }

        onFocus?.(lastNode.id);
      }
    },
    [visibleNodes, onSelect, onFocus]
  );
};

export default useKeyboardNavigation;
