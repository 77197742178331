import Link, { type LinkProps } from "ds/components/Link";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import { DocsUrl } from "utils/getDocsUrl";

type ReadMoreDocsLinkProps = {
  /** Use the `getDocsUrl` function from the `utils/getDocsUrl` module to generate the URL. */
  docsUrl: DocsUrl;
  title?: string;
  size?: LinkProps["size"];
} & AnalyticsCommonProps;

const ReadMoreDocsLink = ({
  docsUrl,
  title = "Read more",
  size = "small",
  ...rest
}: ReadMoreDocsLinkProps) => {
  return (
    <Link href={docsUrl} size={size} target="_blank" rel="noopener noreferrer" {...rest}>
      {title}
    </Link>
  );
};

export default ReadMoreDocsLink;
