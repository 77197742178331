import ProjectTypeBadge from "components/ProjectTypeBadge";
import { ProjectType } from "components/ProjectTypeBadge";
import Box from "ds/components/Box";

import { CellComponentProps } from "./types";
import styles from "./styles.module.css";

type UsedByTypeCellProps = CellComponentProps;

const UsedByTypeCell = ({ item }: UsedByTypeCellProps) => {
  return (
    <Box>
      <ProjectTypeBadge
        className={styles.typeCell}
        projectType={item.isModule ? ProjectType.Module : ProjectType.Stack}
      />
    </Box>
  );
};

export default UsedByTypeCell;
