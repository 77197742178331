import { gql, useMutation } from "@apollo/client";

import NewConfigElement from "components/config/edit/NewConfigElement";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { trackSegmentEvent } from "shared/Analytics";
import { ConfigInput, ConfigType } from "types/generated";

import { ENVIRONMENT_QUERY } from "../constants";
import { showNewConfigValueConfirmationModal } from "./ConfirmationModal";
import {
  AddStackConfigGqlResponse,
  AddStackConfigGqlVariables,
  EnvironmentNewConfigProps,
} from "./types";

const ADD_STACK_CONFIG = gql`
  mutation AddStackConfig($stackId: ID!, $input: ConfigInput!) {
    stackConfigAdd(stack: $stackId, config: $input) {
      id
      type
    }
  }
`;

const getExistingKeys = (
  input: ConfigInput,
  {
    existingEnvironmentVariableNames,
    existingMountedFilesNames,
  }: Pick<
    EnvironmentNewConfigProps,
    "existingEnvironmentVariableNames" | "existingMountedFilesNames"
  >
) => {
  switch (input.type) {
    case ConfigType.EnvironmentVariable:
      return existingEnvironmentVariableNames;
    case ConfigType.FileMount:
      return existingMountedFilesNames;
    default:
      return [];
  }
};

const EnvironmentNewConfig = ({
  stackId,
  type,
  existingMountedFilesNames,
  existingEnvironmentVariableNames,
}: EnvironmentNewConfigProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [addConfig, { loading }] = useMutation<
    AddStackConfigGqlResponse,
    Partial<AddStackConfigGqlVariables>
  >(ADD_STACK_CONFIG, {
    refetchQueries: [ENVIRONMENT_QUERY[type]],
    variables: { stackId },
  });

  const onSave = (input: ConfigInput, reset: () => unknown) => {
    const addConfigAction = () => {
      addConfig({ variables: { input } })
        .then(({ data }) => {
          trackSegmentEvent("Environment Variable Created");
          reportSuccess({ message: `Config ${data?.stackConfigAdd.id} successfully added` });
          reset();
        })
        .catch(onError);
    };

    const existingKeys = getExistingKeys(input, {
      existingEnvironmentVariableNames,
      existingMountedFilesNames,
    });

    if (existingKeys.includes(input.id)) {
      showNewConfigValueConfirmationModal({ onConfirm: addConfigAction });
    } else {
      addConfigAction();
    }
  };

  return <NewConfigElement loading={loading} onSave={onSave} />;
};

export default EnvironmentNewConfig;
