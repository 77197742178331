import React, { useCallback, useState } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import useReviewRun from "shared/Run/useReviewRun";
import { RunReviewDecision } from "types/generated";

import { useRunShortcut } from "../../hooks/useRunShortcut";

type ReviewFormProps = {
  runId: string;
  stackId: string;
  runQueryToRefetch: string;
};

const ReviewForm = ({ runId, stackId, runQueryToRefetch }: ReviewFormProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);
  const [addRunReview, { loading }] = useReviewRun({
    refetchQueries: [runQueryToRefetch],
  });
  const [decision, setDecision] = useState<RunReviewDecision | null>(null);

  const [note, setNote] = useState("");

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const submit = useCallback(
    (decision: RunReviewDecision) => {
      setDecision(decision);
      return addRunReview({ stack: stackId, run: runId, note, decision }, (data) => {
        if (data?.runReview.id) {
          reportSuccess({ message: "Review was successfully submitted." });
          setNote("");
        }
      });
    },
    [addRunReview, stackId, runId, note, reportSuccess]
  );

  const approve = useCallback(() => submit(RunReviewDecision.Approve), [submit]);
  const reject = useCallback(() => submit(RunReviewDecision.Reject), [submit]);

  const isApproving = loading && decision === RunReviewDecision.Approve;
  const isRejecting = loading && decision === RunReviewDecision.Reject;

  useRunShortcut({
    label: "Approve",
    code: "mod+alt+a",
    callback: approve,
    loading: isApproving,
  });

  useRunShortcut({
    label: "Reject",
    code: "mod+alt+r",
    callback: reject,
    loading: isRejecting,
  });

  return (
    <Box direction="column" gap="medium" padding="small 0">
      <FormField label="Note" isOptional>
        {({ ariaInputProps }) => (
          <Textarea
            placeholder="Enter a note here..."
            value={note}
            onChange={handleTextareaChange}
            minRows={5}
            maxRows={10}
            disabled={loading}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <Box gap="medium">
        <Button
          variant="primary"
          size="small"
          onPress={approve}
          loading={isApproving}
          disabled={loading}
        >
          Approve
        </Button>
        <Button
          variant="secondary"
          size="small"
          onPress={reject}
          loading={isRejecting}
          disabled={loading}
        >
          Reject
        </Button>
      </Box>
    </Box>
  );
};

export default ReviewForm;
