import DescriptionDetails from "components/DescriptionDetails";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import MetaInfoCommit from "components/MetaInfoList/Commit";
import Box from "ds/components/Box";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";
import { VcsProvider, Version } from "types/generated";
import UserLinkNew from "views/Account/Module/components/UserLinkNew";

type ModuleVersionDetailsDrawerProps = {
  moduleProvider: VcsProvider;
  version: Version;
};

const ModuleVersionDetailsDrawer = createDrawer(
  ({ moduleProvider, version }: ModuleVersionDetailsDrawerProps) => {
    const onOpenFullDescription = useShowFullDescriptionWithBackArrow(version.notes);
    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Version details" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody>
          <FormFieldViewText label="Name/ID" value={version.id} withCopy />

          <FormFieldTimestamp label="Created at" timestamp={version.createdAt} />

          {version.triggeredBy && (
            <FormFieldViewText label="Triggered by" value={version.triggeredBy} />
          )}

          <DescriptionDetails
            description={version.notes}
            onOpenFullDescription={onOpenFullDescription}
          />

          <FormField label="Committed by">
            <Box align="start">
              <UserLinkNew
                login={version.commit.authorLogin}
                name={version.commit.authorName}
                provider={VcsProvider.Github}
              />
            </Box>
          </FormField>

          <FormField label="Commit">
            <MetaInfoCommit commit={version.commit} provider={moduleProvider} />
          </FormField>

          <FormFieldTimestamp label="Committed at" timestamp={version.commit.timestamp} />
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showModuleVersionDetailsDrawer = createDrawerTrigger(ModuleVersionDetailsDrawer);
