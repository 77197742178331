import { gql, TypedDocumentNode } from "@apollo/client";

import { ManagedEntitiesGroupedByStacksConnection, PageInfo } from "types/generated";

type SearchManagedEntitiesGroupByStacksData = {
  searchManagedEntitiesGroupByStacks: {
    edges: Array<ManagedEntitiesGroupedByStacksConnection>;
    pageInfo: Pick<PageInfo, "endCursor" | "startCursor" | "hasNextPage">;
  };
};

export const SEARCH_MANAGED_ENTITIES_GROUP_BY_STACKS: TypedDocumentNode<SearchManagedEntitiesGroupByStacksData> = gql`
  query SearchManagedEntitiesGroupByStacks($input: SearchInput!) {
    searchManagedEntitiesGroupByStacks(input: $input) {
      edges {
        cursor
        node {
          id
          count
          drifted
          updatedAt
          stackName
          stackSpaceID
          stackSpaceName
          stackVendor
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
`;
