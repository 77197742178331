import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import DocumentationIconButton from "components/DocumentationIconButton";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ViewHeader from "components/ViewHeader";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { LogoAws, LogoMsAzure, Space } from "components/icons/generated";
import Button from "ds/components/Button";
import { getDocsUrl } from "utils/getDocsUrl";
import TimestampMetaInfo from "components/Timestamp/MetaInfo";

import { CloudIntegration } from "../../types";
import { isAwsIntegration, isAzureIntegration } from "../../utils";
import CloudIntegrationsActionsDropdown from "../ActionsDropdown";
import { showAwsIntegrationDetailsDrawer } from "../Aws/DetailsDrawer";
import { showAzureIntegrationDetailsDrawer } from "../Azure/DetailsDrawer";
import { ANALYTICS } from "./UsedBy/constants";
import useCloudIntegrationsUsedByAnalytics from "./UsedBy/useCloudIntegrationsUsedByAnalytics";

type IntegrationHeaderProps = {
  integration: CloudIntegration;
};

const IntegrationHeader = ({ integration }: IntegrationHeaderProps) => {
  const trackAnalytics = useCloudIntegrationsUsedByAnalytics();
  const icon = isAwsIntegration(integration) ? LogoAws : LogoMsAzure;
  const navigate = useNavigate();

  const handleDetails = () => {
    if (isAwsIntegration(integration)) {
      showAwsIntegrationDetailsDrawer({ integration, trackAnalytics });
    } else {
      showAzureIntegrationDetailsDrawer({ integration, trackAnalytics });
    }
  };

  const handleDeleteSuccess = useCallback(() => {
    navigate("/cloud-integrations", { replace: true });
  }, [navigate]);

  return (
    <ViewHeader>
      <ViewHeaderWrapper direction="row" justify="between" fullWidth>
        <ViewHeaderWrapper direction="row" align="center">
          <ViewHeaderTitle>{integration.name}</ViewHeaderTitle>
        </ViewHeaderWrapper>

        <ViewHeaderWrapper direction="row" align="end" shrink="0">
          <DocumentationIconButton
            href={getDocsUrl(
              `/integrations/cloud-providers/${isAwsIntegration(integration) ? "aws" : "azure"}`
            )}
            tooltipText="Go to cloud integrations documentation"
            analyticsPage={ANALYTICS.analyticsPage}
            analyticsTitle="Documentation button clicked"
            analyticsProps={{ type: isAwsIntegration(integration) ? "aws" : "azure" }}
          />

          <Button variant="secondary" onPress={handleDetails}>
            Details
          </Button>

          <CloudIntegrationsActionsDropdown
            integration={integration}
            trackAnalytics={trackAnalytics}
            hideSeeDetails
            onDeleteSuccess={handleDeleteSuccess}
            dotsSize="medium"
          />
        </ViewHeaderWrapper>
      </ViewHeaderWrapper>

      <ViewHeaderScrollCollapse>
        <MetaInfoList>
          <MetaInfoListItem
            icon={Space}
            linkText={integration.spaceDetails.name}
            href={`/spaces/${integration.spaceDetails.id}`}
          />
          <MetaInfoListItem icon={icon}>
            {isAwsIntegration(integration) ? "AWS" : "Azure"}
          </MetaInfoListItem>

          {isAzureIntegration(integration) && (
            <TimestampMetaInfo timestamp={integration.updatedAt} />
          )}
        </MetaInfoList>
      </ViewHeaderScrollCollapse>
    </ViewHeader>
  );
};

export default IntegrationHeader;
