import { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Banner from "ds/components/Banner";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTypedContext from "hooks/useTypedContext";
import { IdentityProvider, ManagedUser } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { validateEmail } from "utils/formValidators";
import { getDocsUrl } from "utils/getDocsUrl";
import { AccountContext } from "views/AccountWrapper";
import DrawerForm from "ds/components/DrawerNew/Form";

import { getManagementStrategy } from "../../helpers";
import { UserManagementActivationStatus } from "../../types";
import { showInviteSuccessModal } from "../InviteSuccessModal";
import { InviteDrawerFields } from "./types";
import UsersInviteAssignRole from "./AssignRole";
import useInviteUserV2 from "./useInviteUserV2";

type UsersInviteDrawerProps = {
  user?: ManagedUser;
  usersCount: number;
  activeUsersCount: number;
  activationStatus: UserManagementActivationStatus;
};

const UsersInviteDrawer = createDrawer(
  ({ activationStatus, activeUsersCount, usersCount }: UsersInviteDrawerProps) => {
    const drawer = useModal();
    const { viewer } = useTypedContext(AccountContext);
    const { onError } = useTypedContext(FlashContext);
    const [isDirtyRoles, setIsDirtyRoles] = useState(false);

    const trackSegmentAnalyticsEvent = useAnalytics({
      page: AnalyticsPageOrganization.OrganizationUsers,
      defaultCallbackTrackProperties: {
        managementStrategy: getManagementStrategy(activationStatus),
        usersCount,
        activeUsersCount,
      },
    });

    const userInviteForm = useForm<InviteDrawerFields>({
      defaultValues: {
        email: "",
        userName: "",
        assignedRoles: [],
        slackMemberID: "",
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { errors, isDirty },
      trigger,
    } = userInviteForm;

    const { onCreate } = useInviteUserV2();

    const handleCloseDrawer = () => {
      drawer.hide();
    };

    const onSubmit: SubmitHandler<InviteDrawerFields> = (formData) => {
      trackSegmentAnalyticsEvent("Drawer Send Invite");

      const input = {
        username: formData.userName || formData.email,
        invitationEmail: formData.email,
        roleBindings: formData.assignedRoles,
        slackMemberID: formData.slackMemberID,
      };

      onCreate(input)
        .then(({ data }) => {
          if (data) {
            handleCloseDrawer();
            showInviteSuccessModal({ activationStatus });
          }
        })
        .catch(onError);
    };

    const isGithubUser = viewer.identityProvider === IdentityProvider.Github;

    const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;

    return (
      <DrawerForm isDirty={isDirty || isDirtyRoles}>
        <FormProvider {...userInviteForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Invite to Spacelift" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight hasStickyFooter>
              {!isUserManagementActive && (
                <Banner variant="warning" title="User management is inactive">
                  Invites will be sent and access rules will take effect once you change management
                  strategy from login policy to user management
                </Banner>
              )}
              <FormField
                label="Username"
                helperText={
                  isGithubUser
                    ? "Provide GitHub username"
                    : "Provide username if it is different than email"
                }
                error={errors?.userName?.message}
                isOptional={!isGithubUser}
              >
                {({ ariaInputProps }) => (
                  <Input
                    placeholder={isGithubUser ? "Enter GitHub username" : "Enter username"}
                    error={!!errors?.userName}
                    {...register("userName", {
                      ...(isGithubUser && { required: "Username field is required." }),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
              <FormField label="Email" error={errors?.email?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter email"
                    error={!!errors?.email}
                    {...register("email", {
                      required: "Email field is required.",
                      validate: validateEmail,
                      // fixes validation when user paste url
                      onChange: () => trigger(),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
              <FormField
                label="Slack member ID"
                error={errors?.slackMemberID?.message}
                isOptional
                helperText="Give this user permissions while using third-party applications."
                tooltipMode="parent"
                tooltipInfoVariant="modal"
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Slack member ID</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      Id of Slack account that belongs to this user
                      <ReadMoreDocsLink
                        docsUrl={getDocsUrl("/concepts/user-management/admin#slack-integration")}
                      />
                    </TooltipModalBody>
                  </>
                }
              >
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter member ID"
                    error={!!errors?.slackMemberID}
                    {...register("slackMemberID", {})}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <UsersInviteAssignRole setIsDirty={setIsDirtyRoles} />

              <DrawerFooter sticky>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button variant="contrast" type="submit">
                    {isUserManagementActive ? "Invite" : "Prepare invite"}
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showUsersInviteDrawerNew = createDrawerTrigger(UsersInviteDrawer);
