import { ReactNode } from "react";

import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";

import { showResetConfirmation } from "./ResetConfirmation";

type CustomizeViewDrawerLayoutProps = {
  children: ReactNode;
  resetConfig: () => void;
};
const CustomizeViewDrawerLayout = ({ children, resetConfig }: CustomizeViewDrawerLayoutProps) => {
  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Customize list" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody noPadding>{children}</DrawerBody>
      <DrawerFooter>
        <Button onPress={() => showResetConfirmation({ onReset: resetConfig })} variant="secondary">
          Reset to default
        </Button>
      </DrawerFooter>
    </DrawerSimple>
  );
};

CustomizeViewDrawerLayout.displayName = "DS.CustomizeViewDrawer.Layout";

export default CustomizeViewDrawerLayout;
