import { EmptystateUsersColored } from "components/icons/generated";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import EmptyState from "ds/components/EmptyState";
import { getDocsUrl } from "utils/getDocsUrl";

const UsersEmpty = () => {
  return (
    <EmptyState
      title="You don’t have any collaborators yet"
      icon={EmptystateUsersColored}
      caption={
        <>
          Invite your team to Spacelift and manage their access.
          <br></br>
          <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/blueprint")} />
          {` `}
          about access management.
        </>
      }
    />
  );
};

export default UsersEmpty;
