import { Route, Routes, useParams } from "react-router-dom";

import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";

import useGetUser from "./useGetUser";
import UserPageLayout from "./PageLayout";
import UserRoles from "./Roles";
import UserIdpGroups from "./IdpGroups";
import UserIntegrations from "./Integrations";

const User = () => {
  const { userId } = useParams<{ userId: string }>();
  const { user, error, loading } = useGetUser({
    id: userId,
  });

  if (loading && !user) {
    return <PageLoading />;
  }

  if (!user) {
    return <NotFoundPage />;
  }

  if (error) {
    return null;
  }

  return (
    <UserPageLayout user={user}>
      <Routes>
        <Route
          index
          element={
            <UserRoles
              userId={user.id}
              roleBindings={user.userRoleBindings}
              userName={user.username}
            />
          }
        />
        <Route path="idp-groups" element={<UserIdpGroups userName={user.username} />} />
        <Route path="integrations" element={<UserIntegrations user={user} />} />
      </Routes>
    </UserPageLayout>
  );
};

export default User;
