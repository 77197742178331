import { memo } from "react";
import { useShallow } from "zustand/react/shallow";

import PageInfo from "components/PageWrapper/Info";
import SearchInput from "components/SearchInput";
import Select from "ds/components/Select";
import Typography from "ds/components/Typography";

import { IAC_MANAGEMENT_GROUP_BY_OPTIONS } from "../../constants";
import useIacManagementStore from "../../useIacManagementStore";
import { FILTERS_ORDER_SETTINGS_KEY } from "../../ListLayout/constants";
import useIacManagementAnalytics from "../../useIacManagementAnalytics";
import { IacManagementGroupBy } from "../../types";

const IacManagementHeader = () => {
  const { groupBy, setGroupBy, pageIsEmpty } = useIacManagementStore(
    useShallow((state) => ({
      groupBy: state.groupBy,
      setGroupBy: state.setGroupBy,
      pageIsEmpty: state.pageIsEmpty,
    }))
  );

  const { trackAnalytics } = useIacManagementAnalytics();

  const handleGroupByChange = (groupBy: IacManagementGroupBy) => {
    setGroupBy(groupBy);
    trackAnalytics("Group By Selected", { groupBy });
  };

  return (
    <PageInfo title="IaC Management">
      <SearchInput
        placeholder="Search resources..."
        filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        disabled={pageIsEmpty}
        callback={() => trackAnalytics("Search Entered")}
      />
      <Select
        disabled={pageIsEmpty}
        value={groupBy}
        options={IAC_MANAGEMENT_GROUP_BY_OPTIONS}
        onChange={handleGroupByChange}
        renderValueNode={(option) => (
          <Typography variant="p-t7" tag="span">
            Grouped by: {option.label}
          </Typography>
        )}
      />
    </PageInfo>
  );
};

export default memo(IacManagementHeader);
