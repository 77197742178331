import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  useSortable,
  UseSortableArguments,
} from "@dnd-kit/sortable";
import { ReactNode } from "react";

type DraggableProps = UseSortableArguments & {
  children: (props: ReturnType<typeof useSortable>) => ReactNode;
};

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const Draggable = (props: DraggableProps) => {
  const rest = useSortable({
    id: props.id,
    disabled: props.disabled,
    animateLayoutChanges,
  });

  return props.children(rest);
};

export default Draggable;
