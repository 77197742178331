import { addYears, endOfDay, format, getUnixTime, startOfDay } from "date-fns/esm";

// Convert dates to UTC timestamps for API
export const dateToUTCTimestamp = (date: Date, isEndDate = false): number => {
  // Create a new date with the same year, month, day in UTC
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  // For start date: 00:00:00 UTC of the selected day
  // For end date: 00:00:00 UTC of the next day (to include the full day)
  const utcDate = new Date(Date.UTC(year, month, isEndDate ? day + 1 : day, 0, 0, 0, 0));

  return getUnixTime(utcDate);
};

export const getMaxDate = (): Date => {
  const maxDate = endOfDay(new Date());
  return maxDate;
};

export const getMinDate = (): Date => {
  const minDate = startOfDay(addYears(getMaxDate(), -1));
  return minDate;
};

// Format for filename
export const formatDateString = (date: Date): string => {
  return format(date, "yyyy-MM-dd");
};
