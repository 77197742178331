import { AnalyticsPageBlueprints } from "hooks/useAnalytics/pages/blueprints";
import { ColumnConfig } from "components/Table/types";

export const INTEGRATIONS_COLUMNS_STORAGE_KEY = "blueprintIntegrationsColumnsConfig";

export enum INTEGRATIONS_COLUMN_ID {
  NAME = "NAME",
  TYPE = "TYPE",
  SPACE = "SPACE",
  DROPDOWN = "DROPDOWN",
}

export const ROW_HEADER_ID = INTEGRATIONS_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 600;

export const INTEGRATIONS_COLUMN_CONFIG: ColumnConfig = {
  [INTEGRATIONS_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    defaultWidth: 300,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
  [INTEGRATIONS_COLUMN_ID.TYPE]: {
    title: "Type",
    minWidth: 130,
    defaultWidth: 240,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
  [INTEGRATIONS_COLUMN_ID.SPACE]: {
    title: "Space",
    minWidth: 112,
    defaultWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
};

export const INTEGRATIONS_CUSTOM_END_COLUMN_CONFIG: ColumnConfig = {
  [INTEGRATIONS_COLUMN_ID.DROPDOWN]: {
    title: "More actions",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
  },
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageBlueprints.BlueprintIntegrationsList,
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(INTEGRATIONS_COLUMN_CONFIG) as INTEGRATIONS_COLUMN_ID[],
  hidden: [],
};
