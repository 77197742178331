import { Pages } from "./types";

export const enum AnalyticsPageResources {
  ResourcesRoot = "ResourcesRoot", // TODO: remove with "resources-redesign" | "resourcesRedesign" FF
  Resources = "Resources", // TODO: remove with "resources-redesign" | "resourcesRedesign" FF
  StackIacManagement = "StackIacManagement",
  AccountIacManagement = "AccountIacManagement",
  ConfigurationManagement = "ConfigurationManagement",
}

const RESOURCES_TITLE = "Resources";

export const pagesResources: Pages<AnalyticsPageResources> = {
  [AnalyticsPageResources.ResourcesRoot]: [RESOURCES_TITLE], // TODO: remove with "resources-redesign" | "resourcesRedesign" FF
  [AnalyticsPageResources.Resources]: [RESOURCES_TITLE, "Resources view"], // TODO: remove with "resources-redesign" | "resourcesRedesign" FF
  [AnalyticsPageResources.StackIacManagement]: ["Stack", "Iac Management"],
  [AnalyticsPageResources.AccountIacManagement]: [RESOURCES_TITLE, "Iac Management"],
  [AnalyticsPageResources.ConfigurationManagement]: [RESOURCES_TITLE, "Configuration Management"],
};
