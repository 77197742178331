import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActions/ConfirmActionStep/InlineConfirmation";

import { RolesBulkActionsConfirmActionStepProps } from "./types";

type RolesBulkActionsConfirmActionStepDefaultProps = RolesBulkActionsConfirmActionStepProps;

const RolesBulkActionsConfirmActionStepDefault = ({
  action,
  bulkActionsVariant,
  onConfirm,
  onCancel,
}: RolesBulkActionsConfirmActionStepDefaultProps) => {
  return (
    <BulkActionsConfirmActionStepInlineConfirmation
      variant={bulkActionsVariant}
      actionLabel={action.title.toLowerCase()}
      onConfirm={() =>
        onConfirm(
          {},
          {
            view: bulkActionsVariant,
            metadata: {},
          }
        )
      }
      onCancel={onCancel}
    />
  );
};

export default RolesBulkActionsConfirmActionStepDefault;
