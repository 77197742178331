import { useModal } from "@ebay/nice-modal-react";
import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";

import CustomizeView from "components/CustomizeView";
import { CustomizeViewConfig } from "components/CustomizeView/types";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import CustomizeViewDrawerLayout from "ds/components/Table/CustomizeViewDrawer/CustomizeViewDrawerLayout";

import { ROW_HEADER_ID, BLUEPRINTS_COLUMN_ID, BLUEPRINTS_COLUMN_CONFIG } from "../../constants";
import useBlueprintsTableStore from "../useBlueprintsTableStore";
import useBlueprintsAnalytics from "../../hooksNew/useBlueprintsAnalytics";

const getLabel = (item: BLUEPRINTS_COLUMN_ID) => {
  const title = BLUEPRINTS_COLUMN_CONFIG[item]?.title;
  return title || "";
};

const ALWAYS_VISIBLE_ITEMS = [ROW_HEADER_ID];

const STATIC_COLUMNS = Object.keys(BLUEPRINTS_COLUMN_CONFIG)
  .filter((key) => !BLUEPRINTS_COLUMN_CONFIG[key as BLUEPRINTS_COLUMN_ID].canHide)
  .map((key) => key as BLUEPRINTS_COLUMN_ID);

const CustomizeViewDrawer = createDrawer(() => {
  const drawer = useModal();
  const [columnsOrder, handleResetConfig, setColumnsOrder] = useBlueprintsTableStore(
    useShallow((state) => [state.columnsOrder, state.resetConfig, state.setColumnsOrder])
  );

  const trackAnalytics = useBlueprintsAnalytics();

  const resetConfig = useCallback(() => {
    trackAnalytics("Table Configuration Reset");
    handleResetConfig();
    drawer.hide();
  }, [drawer, handleResetConfig, trackAnalytics]);

  const handleOnChange = useCallback(
    (config: CustomizeViewConfig<BLUEPRINTS_COLUMN_ID>) => {
      setColumnsOrder(config.hidden, [...config.visible, BLUEPRINTS_COLUMN_ID.DROPDOWN]);
      trackAnalytics("Table configuration changed", { ...config, method: "customize drawer" });
    },
    [trackAnalytics, setColumnsOrder]
  );

  const config = useMemo(() => {
    return {
      visible: columnsOrder.visible.filter(
        (columnId) => columnId !== BLUEPRINTS_COLUMN_ID.DROPDOWN
      ),
      hidden: columnsOrder.hidden,
    };
  }, [columnsOrder.hidden, columnsOrder.visible]);

  return (
    <CustomizeViewDrawerLayout resetConfig={resetConfig}>
      <CustomizeView
        visibleSectionTitle="Visible items"
        hiddenSectionTitle="Hidden items"
        config={config}
        staticItems={STATIC_COLUMNS}
        getLabel={getLabel}
        onChange={handleOnChange}
        alwaysVisibleItems={ALWAYS_VISIBLE_ITEMS}
      />
    </CustomizeViewDrawerLayout>
  );
});

export const showCustomizeViewDrawer = createDrawerTrigger(CustomizeViewDrawer);
