import { ArrowLeft } from "components/icons/generated";
import Button from "ds/components/Button";
import Icon from "ds/components/Icon";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import useTypedContext from "hooks/useTypedContext";

import { ModuleFormContext } from "../context";
import { useNewModuleAnalyticsSegmentEvent } from "../useNewModuleAnalyticsSegmentEvent";

type NewModuleFooterBackButtonProps = {
  callback?: () => void;
  disabled?: boolean;
  loading?: boolean;
};

const NewModuleFooterBackButton = ({
  callback,
  loading,
  disabled,
}: NewModuleFooterBackButtonProps) => {
  const analyticsVersion = useNewModuleAnalyticsSegmentEvent();

  const { currentStep, goToPreviousStep } = useTypedContext(ModuleFormContext);

  const goBack = () => {
    callback?.();
    goToPreviousStep();
  };

  return (
    <Button
      analyticsTitle="Back click"
      analyticsPage={AnalyticsPageModule.ModuleNew}
      analyticsProps={{
        location: currentStep,
        version: analyticsVersion,
      }}
      variant="secondary"
      onPress={goBack}
      loading={loading}
      disabled={disabled}
      startIcon={({ className }) => <Icon src={ArrowLeft} className={className} />}
    >
      Back
    </Button>
  );
};

export default NewModuleFooterBackButton;
