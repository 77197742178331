import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import EmptyState from "ds/components/EmptyState";
import { LogoGcpColored } from "components/icons/generated";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import Box from "ds/components/Box";

import { ANALYTICS } from "../../List/constants";

const GcpDrawer = createDrawer(() => {
  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Set up GCP integration" />
        <DrawerCloseIcon />
      </DrawerHeader>

      <DrawerBody fullHeight>
        <Box>
          <EmptyState
            title="Google Cloud Platform integration"
            caption="For GCP integrations the preferred approach is to configure workload identity. Learn how to do it by clicking on the button below."
            icon={LogoGcpColored}
            padding="x-large"
          >
            <DocumentationButton
              analyticsPage={ANALYTICS.analyticsPage}
              analyticsTitle="GCP documentation button clicked"
              to={getDocsUrl("/integrations/cloud-providers/oidc/gcp-oidc")}
              label="Documentation"
            />
          </EmptyState>
        </Box>
      </DrawerBody>
      <DrawerFooter>
        <DrawerFooterActions>
          <DrawerCancelButton />
        </DrawerFooterActions>
      </DrawerFooter>
    </DrawerSimple>
  );
});

export const showGcpDrawer = createDrawerTrigger(GcpDrawer);
