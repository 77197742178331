import { SortDescriptor } from "react-aria-components";

import { USER_INTEGRATIONS_COLUMN_ID } from "../constants";
import { Integration } from "../types";

export const sortIntegrations = (items: Integration[], sortDescriptor: SortDescriptor) => {
  if (!sortDescriptor.column) {
    return items;
  }

  const isAscending = sortDescriptor.direction === "ascending";

  return [...items].sort((a, b) => {
    let valueA: string | undefined;
    let valueB: string | undefined;

    switch (sortDescriptor.column as USER_INTEGRATIONS_COLUMN_ID) {
      case USER_INTEGRATIONS_COLUMN_ID.ID:
        valueA = a.id;
        valueB = b.id;
        break;
      case USER_INTEGRATIONS_COLUMN_ID.TYPE:
        valueA = a.type;
        valueB = b.type;
        break;
      case USER_INTEGRATIONS_COLUMN_ID.DROPDOWN:
        valueA = "";
        valueB = "";
        break;
    }

    valueA = valueA || "";
    valueB = valueB || "";

    return isAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
  });
};
