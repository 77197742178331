import { useEffect, useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FormFieldSpace from "components/FormFields/Space";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ComboBox from "ds/components/ComboBox";
import AssignRoleDrawerComboBoxItem from "views/Account/Settings/User/AssignRoleDrawer/ComboBoxItem";

import styles from "./styles.module.css";
import { InviteDrawerAssignRoleFormEditFields } from "./types";

type InviteDrawerAssignRoleFormEditProps = {
  roleId: string;
  spaceId: string;
  availableRoles: Array<{ id: string; name: string }>;
  onEditCancel?: () => void;
  onEditSuccess?: () => void;
  setIsDirty: (isDirty: boolean) => void;
  assignedRoleIndex: number;
  onSubmit: (index: number, formData: InviteDrawerAssignRoleFormEditFields) => void;
};

const InviteDrawerAssignRoleFormEdit = ({
  roleId,
  spaceId,
  onEditCancel,
  onEditSuccess,
  availableRoles,
  assignedRoleIndex,
  setIsDirty,
  onSubmit,
}: InviteDrawerAssignRoleFormEditProps) => {
  const assignRoleForm = useForm<InviteDrawerAssignRoleFormEditFields>({
    defaultValues: {
      roleId,
      space: spaceId,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = assignRoleForm;

  const onSubmitHandler: SubmitHandler<InviteDrawerAssignRoleFormEditFields> = (formData) => {
    onSubmit(assignedRoleIndex, formData);
    reset();
    onEditSuccess?.();
  };

  const options = useMemo(
    () => availableRoles.map(({ id, name }) => ({ value: id, label: name })),
    [availableRoles]
  );

  // TODO consider add a not existing space option to the list of spaces in case editing an output reference with not existing output

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  return (
    <FormProvider {...assignRoleForm}>
      <Box direction="column" padding="x-large" gap="x-large" className={styles.formWrapper}>
        <Controller
          name="roleId"
          control={control}
          rules={{ required: "Role field is required." }}
          render={({ field, fieldState }) => (
            <ComboBox
              items={options}
              label="Select role"
              error={fieldState.error?.message}
              value={field.value}
              onChange={field.onChange}
            >
              {(item) => <AssignRoleDrawerComboBoxItem id={item.value} {...item} />}
            </ComboBox>
          )}
        />
        <FormFieldSpace label="Space" noMargin />

        <Box direction="row" justify="end" gap="medium">
          <Button variant="secondary" size="small" onPress={onEditCancel}>
            Cancel
          </Button>

          <Button variant="primary" size="small" onPress={() => handleSubmit(onSubmitHandler)()}>
            Save
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default InviteDrawerAssignRoleFormEdit;
