import Box from "ds/components/Box";
import Link from "ds/components/Link";

import useIntegrationUsedByAnalytics from "../useIntegrationUsedByPageAnalytics";
import { CellComponentProps } from "./types";

type IntegrationUsedByListItemNameCellProps = CellComponentProps;

const IntegrationUsedByListItemNameCell = ({
  blueprint,
}: IntegrationUsedByListItemNameCellProps) => {
  const trackAnalytics = useIntegrationUsedByAnalytics();

  const blueprintUrl = `/blueprint/${blueprint.blueprintID}`;

  return (
    <Box fullWidth>
      <Link
        size="small"
        variant="secondary"
        href={blueprintUrl}
        onPress={() => trackAnalytics("Name link clicked")}
      >
        {blueprint.blueprintName}
      </Link>
    </Box>
  );
};

export default IntegrationUsedByListItemNameCell;
