import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { SearchQueryOrderDirection } from "types/generated";

export const USERS_COLUMNS_STORAGE_KEY = "usersColumnsConfig";
export const FILTERS_ORDER_SETTINGS_KEY = "usersFiltersOrder";

export enum USERS_COLUMN_ID {
  NAME = "NAME",
  DROPDOWN = "DROPDOWN",
  GROUPS = "GROUPS",
  METHOD = "METHOD",
  LAST_LOGIN = "LAST_LOGIN",
  STATUS = "STATUS",
  ROLE = "ROLE",
}

export const initialSortOption = "name";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const ROW_HEADER_ID = USERS_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 600;

export const USERS_COLUMN_CONFIG: ColumnConfig = {
  [USERS_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
  },

  [USERS_COLUMN_ID.ROLE]: {
    title: "Role",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
  },

  [USERS_COLUMN_ID.GROUPS]: {
    title: "Groups",
    minWidth: 50,
    maxWidth: MAX_CELL_WIDTH,
  },

  [USERS_COLUMN_ID.METHOD]: {
    title: "Method",
    minWidth: 50,
    maxWidth: MAX_CELL_WIDTH,
  },

  [USERS_COLUMN_ID.STATUS]: {
    title: "Status",
    minWidth: 130,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
  [USERS_COLUMN_ID.LAST_LOGIN]: {
    title: "Last login",
    minWidth: 130,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
};

export const USERS_CUSTOM_END_COLUMN_CONFIG: ColumnConfig = {
  [USERS_COLUMN_ID.DROPDOWN]: {
    title: "More actions",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
  },
};

export const SORT_CONFIG: Record<string, string> = {
  [USERS_COLUMN_ID.NAME]: "name",
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(USERS_COLUMN_CONFIG) as USERS_COLUMN_ID[],
  hidden: [],
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageOrganization.OrganizationUsers,
};
