import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { isProductionEnvironment } from "utils/env";

import { TableColumnMeta } from "../types";
import makeTableColumnsSlice from "./makeTableColumnsSlice";
import { TableColumnsState } from "./types";

type UseTableStoreConfig<Columns extends string> = {
  storageKey: string;
  config: Record<Columns, TableColumnMeta<Columns>>;
};

const createTableStore = <Columns extends string>({
  storageKey,
  config,
}: UseTableStoreConfig<Columns>) =>
  create<TableColumnsState<Columns>>()(
    devtools(
      persist(
        immer((...a) => ({
          ...makeTableColumnsSlice<Columns>({
            initialSizes: Object.fromEntries(
              (
                Object.entries<TableColumnMeta<Columns>>(config) as unknown as [
                  Columns,
                  TableColumnMeta<Columns>,
                ][]
              ).map(([key, value]) => [key, value.defaultWidth])
            ) as Record<Columns, number>,
            initialOrder: {
              visible: Object.keys(config) as Columns[],
              hidden: [],
            },
            config,
          })(...a),
        })),
        {
          name: storageKey,
          partialize: (state) => ({
            columnsState: state.columnsState,
            columnsOrder: state.columnsOrder,
            columnsSizes: state.columnsSizes,
          }),
        }
      ),
      { enabled: isProductionEnvironment() === false }
    )
  );

export default createTableStore;
