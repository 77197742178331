import AnnouncementTile, { AnnouncementTileBackgroundType } from "components/AnnouncementTile";
import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import TileContent from "ds/components/Tile/Content";
import Typography from "ds/components/Typography";
import { AnalyticsPageBilling } from "hooks/useAnalytics/pages/billing";

import { MarketingTileData } from "./types";

type BillingMarketingTileProps = {
  data: MarketingTileData;
  className?: string;
};

const BillingMarketingTile = ({ data, className }: BillingMarketingTileProps) => {
  return (
    <AnnouncementTile className={className} backgroundType={AnnouncementTileBackgroundType.Planets}>
      <TileContent direction="column" gap="large">
        <Box direction="column">
          <Box justify="between" align="center">
            <Typography tag="p" variant="p-t7" color="tertiary">
              {data.title}
            </Typography>

            <LinkButton
              variant="primary"
              to={data.ctaTo}
              href={data.ctaHref}
              target={data.ctaHref ? "_blank" : undefined}
              analyticsPage={AnalyticsPageBilling.BillingDashboard}
              analyticsTitle="Marketing CTA Button Clicked"
              analyticsProps={{
                buttonText: data.ctaText,
                title: data.title,
              }}
            >
              {data.ctaText}
            </LinkButton>
          </Box>
          <Typography tag="p" variant="p-t4" color="on-solid-primary">
            {data.subtitle}
          </Typography>
        </Box>

        <Typography tag="p" variant="p-body3" color="on-solid-primary">
          {data.description}
        </Typography>
      </TileContent>
    </AnnouncementTile>
  );
};

export default BillingMarketingTile;
