import { useModal } from "@ebay/nice-modal-react";

import FlashContext from "components/FlashMessages/FlashContext";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import useTypedContext from "hooks/useTypedContext";

import { BlueprintVersionFormFields } from "../components/VersionFormFragment";
import DetailsFormDrawer from "./components/DetailsFormDrawer";
import { BlueprintVersion } from "./types";

type BlueprintVersionEditDetailsProps = {
  version: BlueprintVersion;
};

const BlueprintVersionEditDetailsDrawer = createDrawer(
  ({ version }: BlueprintVersionEditDetailsProps) => {
    const drawer = useModal();
    const { onError, reportSuccess } = useTypedContext(FlashContext);

    // TODO: mutation to save blueprint version details

    const onSubmit = (formData: BlueprintVersionFormFields) => {
      // TODO: save blueprint version details
      return Promise.resolve(formData)
        .then((data) => {
          console.log(data);
          if (data?.version) {
            reportSuccess({
              message: `Blueprint version ${data.version} is successfully updated`,
            });
            drawer.hide();
          }
        })
        .catch(onError);
    };

    return (
      <DetailsFormDrawer
        title="Edit version details"
        buttonText="Save"
        onSubmit={onSubmit}
        defaultValues={version}
      />
    );
  }
);

export const showBlueprintVersionEditDetailsDrawer = createDrawerTrigger(
  BlueprintVersionEditDetailsDrawer
);
