import { useModal } from "@ebay/nice-modal-react";

import { ArrowLeft } from "components/icons/generated";
import Markdown from "components/markdown/Markdown";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import useAnalytics from "hooks/useAnalytics";

import Box from "../Box";
import ButtonIcon from "../ButtonIcon";
import DrawerSimple from "../DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "../DrawerNew/utils";

type FullDescriptionDrawerProps = {
  showBackButton?: boolean;
  description: string | null | undefined;
  onClose?: () => void;
};

const FullDescriptionDrawer = createDrawer(function FullDescriptionDrawer({
  showBackButton,
  description,
  onClose,
}: FullDescriptionDrawerProps) {
  const drawer = useModal();
  const trackSegmentAnalyticsEvent = useAnalytics();

  const handleClose = () => {
    drawer.resolve();
    onClose?.();
    trackSegmentAnalyticsEvent("Close full description drawer");
  };

  const handleGoBack = () => {
    handleClose();
    drawer.hide();
  };

  return (
    <DrawerSimple variant="wide" onClose={handleClose}>
      <DrawerHeader gap="medium" justify="between">
        <Box gap="medium" align="center">
          {showBackButton && (
            <ButtonIcon icon={ArrowLeft} onPress={handleGoBack} variant="ghost">
              Go back
            </ButtonIcon>
          )}
          <DrawerHeaderTitle title="Description" />
        </Box>
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Markdown markup={description || ""} />
      </DrawerBody>
    </DrawerSimple>
  );
});

FullDescriptionDrawer.displayName = "DS.FullDescriptionDrawer";

export const showFullDescription = createDrawerTrigger(FullDescriptionDrawer);
