import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { Action } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";

import RoleFullScreenForm from "../FullScreenForm";
import { ROLE_CREATE } from "./gql";
import { FormValues } from "../FullScreenForm/types";

const RoleCreate = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const navigate = useNavigate();

  const [createRole, { loading }] = useMutation(ROLE_CREATE);

  const onSubmit = useCallback(
    (formValues: FormValues) => {
      const actions = Object.keys(formValues.permissions).reduce((acc, next) => {
        const value = formValues.permissions[next];

        return [...acc, ...(Object.keys(value) as Action[]).filter((key) => !!value[key])];
      }, [] as Action[]);

      const input = {
        name: formValues.name,
        description: formValues.description,
        actions,
      };

      createRole({
        variables: {
          input,
        },
      })
        .then(({ data }) => {
          if (data?.roleCreate.id) {
            navigate(`/settings/role/${data?.roleCreate.id}`, { replace: true });
            reportSuccess({ message: "Role has been successfully created" });
          }
        })
        .catch(onError);
    },
    [createRole, navigate, reportSuccess, onError]
  );

  useTitle(`Settings · New role · ${accountName}`);

  return (
    <RoleFullScreenForm
      title="Create role"
      saveButtonTitle="Create"
      leaveTitle="Do you want to leave role creation process?"
      analyticsPage={AnalyticsPageOrganization.OrganizationRoleCreate}
      submitLoading={loading}
      onSubmit={onSubmit}
    />
  );
};

export default RoleCreate;
