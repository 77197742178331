import useAnalytics from "hooks/useAnalytics";

import { ANALYTICS } from "../constants";

const useBlueprintsAnalytics = () => {
  return useAnalytics({
    page: ANALYTICS.analyticsPage,
  });
};

export default useBlueprintsAnalytics;
