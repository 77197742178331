import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Feedback from "ds/components/Feedback";
import { DocsUrl } from "utils/getDocsUrl";

type VCSIntegrationFeedbackWebhooksInfoProps = {
  provider: string;
  docLink: DocsUrl;
};

const VCSIntegrationFeedbackWebhooksInfo = ({
  provider,
  docLink,
}: VCSIntegrationFeedbackWebhooksInfoProps) => {
  return (
    <Feedback type="callout" variant="info">
      Webhooks should be manually configured in {provider}, find out more{" "}
      <ReadMoreDocsLink docsUrl={docLink} title="here" />.
    </Feedback>
  );
};

export default VCSIntegrationFeedbackWebhooksInfo;
