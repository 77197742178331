import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { SettingsSlackAppConfig } from "../gql";
import SettingsIntegrationsSlackConnectButton from "./ConnectButton";
import { showDeletingConfirmation } from "./DeleteSelfHosted";
import SettingsIntegrationsSlackManageAccessMenuItem from "./ManageAccessMenuItem";
import { openSetUpDrawer } from "./SetUp";

type SettingsIntegrationsSlackTileIndicatorSelfHostedProps = {
  hasIntegration: boolean;
  appConfig?: SettingsSlackAppConfig;
};

const SettingsIntegrationsSlackTileIndicatorSelfHosted = ({
  hasIntegration,
  appConfig,
}: SettingsIntegrationsSlackTileIndicatorSelfHostedProps) => {
  const hasAppConfig = !!appConfig?.clientId;

  const handleSetUpSlackAppForSH = () => {
    openSetUpDrawer();
  };

  const handleEditCredentials = () => {
    if (appConfig?.canUpdate) {
      openSetUpDrawer({ appConfig });
    }
  };

  const handleDeletion = () => {
    showDeletingConfirmation();
  };

  return (
    <Box gap="medium">
      {!hasIntegration && hasAppConfig && <SettingsIntegrationsSlackConnectButton />}

      {!hasAppConfig && !hasIntegration && (
        <Button variant="secondary" onPress={handleSetUpSlackAppForSH}>
          Set up
        </Button>
      )}

      {hasAppConfig && (
        <DropdownMenuEllipsis tooltip="Slack actions">
          {hasIntegration && <SettingsIntegrationsSlackManageAccessMenuItem />}
          <DropdownMenuItem
            onAction={handleEditCredentials}
            isDisabled={!appConfig.canUpdate}
            tooltip={
              !appConfig.canUpdate
                ? "Your Slack App settings were provided via configuration when deploying Spacelift and cannot be updated at runtime."
                : undefined
            }
            tooltipWidthMode="maxWidthSm"
          >
            Edit credentials
          </DropdownMenuItem>
          {/* TODO: add the disabled state, waiting for the `appConfig.canDelete` property */}
          <DropdownMenuItem onAction={handleDeletion} danger>
            Delete
          </DropdownMenuItem>
        </DropdownMenuEllipsis>
      )}
    </Box>
  );
};

export default SettingsIntegrationsSlackTileIndicatorSelfHosted;
