import useTypedContext from "hooks/useTypedContext";
import { ApplyFiltersContext } from "components/Filters";
import { TableDropdownAction } from "ds/components/Table/components/DropdownCell";

import { ADD_TO_FILTERS } from "./constants";
import { ResourcesSuggestions } from "../useIacManagementFilters/constants";
import useIacManagementAnalytics from "../useIacManagementAnalytics";

const useCellSpaceFilterAction = (spaceId?: string): TableDropdownAction[] => {
  const { applySpaceFilter } = useTypedContext(ApplyFiltersContext);
  const { trackAnalytics } = useIacManagementAnalytics();

  if (!spaceId) return [];

  return [
    {
      title: ADD_TO_FILTERS,
      action: () => {
        applySpaceFilter(ResourcesSuggestions.Space, {})(spaceId);
        trackAnalytics("Add to filters clicked", { type: ResourcesSuggestions.Space });
      },
    },
  ];
};

export default useCellSpaceFilterAction;
