import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";

export const COLUMN_ORDER =
  "fit-content(3rem) minmax(4rem, 1fr) 30rem minmax(15rem, 4fr) minmax(9rem, 1fr) 6.5rem";

export const COLUMN_GAP: Spacing = "large";

export const FILTERS_TYPE = "workerPoolWorkers";
export const FILTERS_ORDER_SETTINGS_KEY = "workerPoolWorkersFiltersOrder";

export enum SortableColumns {
  STATUS = "status",
  ULID = "ulid",
  CREATED_AT = "createdAt",
}

export const INITIAL_SORT_OPTION = SortableColumns.CREATED_AT;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Desc;

export const ITEMS_LIMIT = 50;
