import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteLoader from "react-window-infinite-loader";

import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import ListEntitiesNew from "components/ListEntitiesNew";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import MetaInfoProvider from "components/MetaInfoList/Provider";
import PageInfo from "components/PageWrapper/Info";
import PaginationIndicator from "components/PaginationIndicator";
import SearchInput from "components/SearchInput";
import ViewHeader from "components/ViewHeader";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import NotFoundPage from "components/error/NotFoundPage";
import { Clock, Space } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import Timestamp from "components/time/Timestamp";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import { hasSpaceManageAccess } from "utils/user";

import { showVCSIntegrationDetailsDrawer } from "../components/Details";
import VCSIntegrationFeedback from "../components/IntegrationFeedback";
import { VCS_INTEGRATIONS_LIST_FILTERS_KEY, VCS_INTEGRATIONS_PATH } from "../constants";
import { getEditIntegrationURL } from "../helpers";
import VCSIntegrationDropdownActions from "./DropdownActions";
import VCSIntegrationEmpty from "./Empty";
import VCSIntegrationFiltersLayout from "./FiltersLayout";
import VCSIntegrationUsedByVirtualizedListItem from "./ListItem/Virtualized";
import { ITEMS_LIMIT, VCS_INTEGRATION_VIEW_FILTERS_KEY } from "./constants";
import { useSearchVCSIntegrationUsedBy } from "./useSearchVCSIntegrationUsedBy";

const VCSIntegration = () => {
  const navigate = useNavigate();

  const {
    integration,
    usedByItems,
    isPageLoading,
    isPageEmpty,
    isPageRefetching,
    totalEntitiesCount,
    error,
    hasNextPage,
    loadMoreItems,
  } = useSearchVCSIntegrationUsedBy();

  const handleOpenDetailsDrawer = () => {
    showVCSIntegrationDetailsDrawer({
      item: integration,
      canEdit: canManageVCSIntegrations,
    });
  };

  const handleIntegrationEdit = useCallback(() => {
    if (integration?.id) {
      navigate(getEditIntegrationURL(integration.provider, integration.id));
    }
  }, [navigate, integration]);

  const isItemLoaded = (value: number) => !hasNextPage || value < usedByItems.length;

  useTitle(integration?.name ? `Source code · ${integration.name}` : "Source code");

  useBreadcrumbs(
    [
      {
        title: "Source code",
        link: getBreadcrumbsBackUrl(VCS_INTEGRATIONS_PATH, VCS_INTEGRATIONS_LIST_FILTERS_KEY),
      },
      {
        title: integration?.name || "",
      },
    ],
    [integration?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (!integration) {
    return <NotFoundPage />;
  }

  // FYI: incorect type as integration.space can be undefined in certain circumstances
  const canManageVCSIntegrations =
    integration.space && hasSpaceManageAccess(integration.space.accessLevel);

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{integration.name}</ViewHeaderTitle>

            {integration.isDefault && <BadgeNext variant="gray" type="strong" text="DEFAULT" />}
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <Button variant="secondary" onPress={handleOpenDetailsDrawer}>
              Details
            </Button>

            {canManageVCSIntegrations && (
              <VCSIntegrationDropdownActions
                integration={integration}
                onEdit={handleIntegrationEdit}
                totalEntitiesCount={totalEntitiesCount}
              />
            )}
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={Space}
              linkText={integration.space?.name}
              href={`/spaces/${integration.space?.id}`}
            />
            <MetaInfoProvider provider={integration.provider} />
            <MetaInfoListItem icon={Clock}>
              <Timestamp timestamp={integration.updatedAt} />
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
      </ViewHeader>

      <VCSIntegrationFeedback integration={integration} viewPageMode />

      <PageInfo title="Used by">
        {!isPageEmpty && (
          <Box direction="row" align="center" gap="0 large">
            <PaginationIndicator
              currentCount={usedByItems.length}
              totalCount={totalEntitiesCount}
              loading={isPageRefetching}
              minimumLoadingDuration={200}
            />

            <SearchInput
              placeholder="Search by name"
              filtersOrderSettingsKey={VCS_INTEGRATION_VIEW_FILTERS_KEY}
            />
          </Box>
        )}
      </PageInfo>

      {!isPageEmpty && <VCSIntegrationFiltersLayout />}
      {isPageEmpty && <VCSIntegrationEmpty />}

      {!isPageEmpty && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={hasNextPage ? usedByItems.length + ITEMS_LIMIT : usedByItems.length}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <ListEntitiesNew
              itemCount={usedByItems.length}
              itemProps={{
                items: usedByItems,
              }}
              virtualizedItem={VCSIntegrationUsedByVirtualizedListItem}
              itemKey={(index) => usedByItems[index].stackId}
              onItemsRendered={onItemsRendered}
            />
          )}
        </InfiniteLoader>
      )}
    </>
  );
};

export default VCSIntegration;
