import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import SystemMessage from "components/SystemMessage";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import SecretInput from "ds/components/SecretInput";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { FetchError } from "utils/errors";
import { stringIsRequired } from "utils/formValidators";

type ApiKeyTokenFormFields = {
  id: string;
  secret: string;
};

const ApiKeyToken = () => {
  useTitle("Exchange API key");

  const navigate = useNavigate();
  const { reportError, reportSuccess } = useTypedContext(FlashContext);

  const [isLoading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ApiKeyTokenFormFields>({
    mode: "onChange",
  });

  const getCSRFToken = async (): Promise<string> => {
    const response = await fetch("/api_key_user/token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const responseMessage = await response.text();
      throw new FetchError(response.status, responseMessage || "Failed to get CSRF token");
    }

    const data = await response.json();
    return data.token;
  };

  const onSubmit = async (formData: ApiKeyTokenFormFields) => {
    setLoading(true);

    try {
      // First get the CSRF token
      const token = await getCSRFToken();

      // Then submit the form with credentials and token
      const params = new URLSearchParams();
      params.append("id", formData.id);
      params.append("secret", formData.secret);
      params.append("token", token);

      const response = await fetch("/api_key_user", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params,
      });

      if (!response.ok) {
        const responseMessage = await response.text();
        throw new FetchError(response.status, responseMessage || "Invalid credentials");
      }

      navigate("/", { replace: true });
      reportSuccess({ message: "Logged in using an API key" });
    } catch (error) {
      reportError({
        title: "Exchange API key",
        message: error instanceof FetchError ? error.message : "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SystemMessage title="Exchange API key for a token" message="Debug mode">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box direction="column" gap="xx-large">
          <Box direction="column" gap="x-large">
            <FormField label="API key ID" error={errors?.id?.message} noMargin>
              {({ ariaInputProps }) => (
                <Input
                  error={!!errors?.id}
                  {...register("id", {
                    validate: stringIsRequired("Username field is required."),
                    setValueAs: (value) => value.trim(),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
            <FormField label="API key secret" error={errors?.secret?.message} noMargin>
              {({ ariaInputProps }) => (
                <SecretInput
                  error={!!errors?.secret}
                  {...register("secret", {
                    validate: stringIsRequired("Secret field is required."),
                    setValueAs: (value) => value.trim(),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </Box>

          <Button type="submit" variant="primary" loading={isLoading} fullWidth>
            Exchange
          </Button>
        </Box>
      </form>
    </SystemMessage>
  );
};

export default ApiKeyToken;
