import { gql, TypedDocumentNode } from "@apollo/client";

import { QueryManagedUserArgs } from "types/generated";

import { UserDetails } from "./types";

type GetUserData = {
  managedUser: UserDetails;
};

export const GET_USER_DETAILS: TypedDocumentNode<GetUserData, QueryManagedUserArgs> = gql`
  query GetUserDetails($id: ID!) {
    managedUser(id: $id) {
      id
      invitationEmail
      status
      username
      role
      latestGroups
      accessRules {
        space
        spaceAccessLevel
      }
      slackMemberID
      lastLoginTime
      spaceCount
      groupCount
      loginMethod
    }
  }
`;
