import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import PageWrapper from "components/PageWrapper";
import SubSidebar from "components/SubSidebar";
import SubSidebarSection from "components/SubSidebar/Section";
import SubSidebarSectionItem from "components/SubSidebar/SectionItem";
import SubSidebarSectionTitle from "components/SubSidebar/SectionTitle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Callout from "ds/components/Callout";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import useTypedFlags from "hooks/useTypedFlags";
import {
  ApiKey,
  AuthorizationScheme,
  AzureIntegration,
  Maybe,
  Seats,
  SlackIntegration,
} from "types/generated";
import { isSaasDistribution } from "utils/distribution";
import { isSelfHostedDistribution } from "utils/distribution";

import { SpacesContext } from "../SpacesProvider";
import SettingsAccountDeleteWarning from "./AccountDeleteWarning";
import ConfirmDeleteButton from "./ConfirmDeleteButton";
import { pathConfig } from "./constants";
import { SettingsContext } from "./Context";
import { getAnalyticsTab } from "./getAnalyticsTab";
import { GET_ACCOUNT_SETTINGS, TOGGLE_ACCOUNT_DELETION_MARK } from "./gql";
import SettingsRoutes from "./Routes";
import styles from "./styles.module.css";
import {
  OrganizationAccessTabs,
  OrganizationAuthTabs,
  OrganizationOtherTabs,
  OrganizationSecurityTabs,
  UserManagementActivationStatus,
} from "./types";

const isSaas = isSaasDistribution();
const isSelfHosted = isSelfHostedDistribution();

type GetAccountSettingsGqlSelfHosted = {
  authorizationScheme: AuthorizationScheme;
  slackIntegration: Maybe<SlackIntegration>;
  accountCanBeDeletedAt: number;
  accountCanBeDeleted: boolean;
  apiKeys: ApiKey[];
  markedForDeletion: boolean;
  azureIntegrations: AzureIntegration[];
  securityEmail: undefined;
  seats: Seats;
};

type GetAccountSettingsGqlSaaS = GetAccountSettingsGqlSelfHosted & {
  securityEmail: string;
};

type GetAccountSettingsGql = GetAccountSettingsGqlSaaS | GetAccountSettingsGqlSelfHosted;

const Settings = () => {
  const { billingUsage, runSummarizationAi, customAccessRoles } = useTypedFlags();

  const { hasRootSpaceAccess } = useTypedContext(SpacesContext);

  const { onError } = useTypedContext(FlashContext);
  const location = useLocation();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.Organization,
  });

  const { loading, data, error, networkStatus } = useQuery<GetAccountSettingsGql>(
    GET_ACCOUNT_SETTINGS,
    {
      variables: {
        isSaas,
      },
      onError,
    }
  );

  const [toggleAccountDeletionMark, { loading: loadingMarking }] = useMutation(
    TOGGLE_ACCOUNT_DELETION_MARK,
    {
      refetchQueries: [{ query: GET_ACCOUNT_SETTINGS, variables: { isSaas } }],
    }
  );

  const toggleForDeletionMark = () => {
    trackSegmentAnalyticsEvent(
      data?.markedForDeletion ? "Mark for Deletion Click" : "Do not Delete Click",
      {
        tab: getAnalyticsTab(location.pathname),
      }
    );
    toggleAccountDeletionMark().then().catch(onError);
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  const isLoading = loading && networkStatus !== NetworkStatus.refetch;

  if (isLoading || !data) {
    return <PageLoading />;
  }

  const activationStatus =
    data?.authorizationScheme === AuthorizationScheme.ManagedUsers
      ? UserManagementActivationStatus.ACTIVE
      : UserManagementActivationStatus.INACTIVE;

  const settingsContextValue = {
    activationStatus,
    seats: data.seats,
  };

  return (
    <SettingsContext.Provider value={settingsContextValue}>
      <ViewHeader>
        <ViewHeaderWrapper direction="row">
          <ViewHeaderTitle titleSize="p-t3">Organization settings</ViewHeaderTitle>
        </ViewHeaderWrapper>
        {isSaas && (
          <ViewHeaderWrapper direction="row" align="end">
            {data.markedForDeletion ? (
              <>
                {data.accountCanBeDeleted && <ConfirmDeleteButton />}
                <Button variant="primary" onPress={toggleForDeletionMark}>
                  Do not delete
                </Button>
              </>
            ) : (
              <Button
                variant="dangerSecondary"
                loading={loadingMarking}
                onPress={toggleForDeletionMark}
              >
                Mark for deletion
              </Button>
            )}
          </ViewHeaderWrapper>
        )}
      </ViewHeader>
      <Box className={styles.content}>
        <SubSidebar>
          {!customAccessRoles && (
            <SubSidebarSection>
              <SubSidebarSectionTitle>ACCESS</SubSidebarSectionTitle>
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationAccessTabs.USERS,
                }}
                to={`../${pathConfig[OrganizationAccessTabs.USERS]}`}
              >
                Users
              </SubSidebarSectionItem>
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationAccessTabs.IDP_GROUP_MAPPING,
                }}
                to={`../${pathConfig[OrganizationAccessTabs.IDP_GROUP_MAPPING]}`}
              >
                IdP group mapping
              </SubSidebarSectionItem>
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationAccessTabs.API_KEYS,
                }}
                to={`../${pathConfig[OrganizationAccessTabs.API_KEYS]}`}
              >
                API keys
              </SubSidebarSectionItem>
              {hasRootSpaceAccess && (
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationAccessTabs.SPACES,
                  }}
                  to={`../${pathConfig[OrganizationAccessTabs.SPACES]}`}
                >
                  Spaces
                </SubSidebarSectionItem>
              )}

              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationAccessTabs.LOGIN_POLICIES,
                }}
                to={`../${pathConfig[OrganizationAccessTabs.LOGIN_POLICIES]}`}
              >
                Login policy
              </SubSidebarSectionItem>

              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationAccessTabs.MANAGEMENT_STRATEGY,
                }}
                to={`../${pathConfig[OrganizationAccessTabs.MANAGEMENT_STRATEGY]}`}
              >
                Management strategy
              </SubSidebarSectionItem>
            </SubSidebarSection>
          )}
          {customAccessRoles && (
            <>
              <SubSidebarSection>
                <SubSidebarSectionTitle>Access control center</SubSidebarSectionTitle>
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationAccessTabs.MANAGEMENT_STRATEGY,
                  }}
                  to={`../${pathConfig[OrganizationAccessTabs.MANAGEMENT_STRATEGY]}`}
                >
                  Management strategy
                </SubSidebarSectionItem>
                {hasRootSpaceAccess && (
                  <SubSidebarSectionItem
                    analyticsPage={AnalyticsPageOrganization.Organization}
                    analyticsTitle="Switch View"
                    analyticsProps={{
                      tab: OrganizationAccessTabs.SPACES,
                    }}
                    to={`../${pathConfig[OrganizationAccessTabs.SPACES]}`}
                  >
                    Spaces
                  </SubSidebarSectionItem>
                )}
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationAccessTabs.LOGIN_POLICIES,
                  }}
                  to={`../${pathConfig[OrganizationAccessTabs.LOGIN_POLICIES]}`}
                >
                  Login policy
                </SubSidebarSectionItem>
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.OrganizationRoles}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationAccessTabs.ROLES,
                  }}
                  to={`../${pathConfig[OrganizationAccessTabs.ROLES]}`}
                >
                  Roles
                </SubSidebarSectionItem>
              </SubSidebarSection>

              <SubSidebarSection>
                <SubSidebarSectionTitle>Identity management</SubSidebarSectionTitle>
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationAccessTabs.USERS,
                  }}
                  to={`../${pathConfig[OrganizationAccessTabs.USERS]}`}
                >
                  Users
                </SubSidebarSectionItem>
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationAccessTabs.IDP_GROUP_MAPPING,
                  }}
                  to={`../${pathConfig[OrganizationAccessTabs.IDP_GROUP_MAPPING]}`}
                >
                  IdP group mapping
                </SubSidebarSectionItem>
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationAccessTabs.API_KEYS,
                  }}
                  to={`../${pathConfig[OrganizationAccessTabs.API_KEYS]}`}
                >
                  API keys
                </SubSidebarSectionItem>
              </SubSidebarSection>
            </>
          )}
          <SubSidebarSection>
            <SubSidebarSectionTitle>Authentication</SubSidebarSectionTitle>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationAuthTabs.SSO,
              }}
              to={`../${pathConfig[OrganizationAuthTabs.SSO]}`}
            >
              Single Sign-on
            </SubSidebarSectionItem>

            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationAuthTabs.MFA,
              }}
              to={`../${pathConfig[OrganizationAuthTabs.MFA]}`}
            >
              Multi-factor authentication
            </SubSidebarSectionItem>
          </SubSidebarSection>

          <SubSidebarSection>
            <SubSidebarSectionTitle>SECURITY</SubSidebarSectionTitle>

            {isSaas && (
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationSecurityTabs.SECURITY_EMAIL,
                }}
                to={`../${pathConfig[OrganizationSecurityTabs.SECURITY_EMAIL]}`}
              >
                Security email
              </SubSidebarSectionItem>
            )}
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationSecurityTabs.AUDIT_TRAIL,
              }}
              to={`../${pathConfig[OrganizationSecurityTabs.AUDIT_TRAIL]}`}
            >
              Audit Trail
            </SubSidebarSectionItem>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationSecurityTabs.SESSIONS,
              }}
              to={`../${pathConfig[OrganizationSecurityTabs.SESSIONS]}`}
            >
              Sessions
            </SubSidebarSectionItem>
          </SubSidebarSection>
          <SubSidebarSection>
            <SubSidebarSectionTitle>OTHER</SubSidebarSectionTitle>

            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationOtherTabs.INTEGRATIONS,
              }}
              to={`../${pathConfig[OrganizationOtherTabs.INTEGRATIONS]}`}
            >
              Integrations
            </SubSidebarSectionItem>

            {isSaas && (
              <>
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationOtherTabs.BILLING,
                  }}
                  to={`../${pathConfig[OrganizationOtherTabs.BILLING]}`}
                >
                  Billing
                </SubSidebarSectionItem>
                {billingUsage && (
                  <SubSidebarSectionItem
                    analyticsPage={AnalyticsPageOrganization.Organization}
                    analyticsTitle="Switch View"
                    analyticsProps={{
                      tab: OrganizationOtherTabs.USAGE,
                    }}
                    to={`../${pathConfig[OrganizationOtherTabs.USAGE]}`}
                  >
                    Usage
                  </SubSidebarSectionItem>
                )}
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationOtherTabs.LIMITS,
                  }}
                  to={`../${pathConfig[OrganizationOtherTabs.LIMITS]}`}
                >
                  Limits
                </SubSidebarSectionItem>
              </>
            )}
            {isSelfHosted && (
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationOtherTabs.USAGE_EXPORT,
                }}
                to={`../${pathConfig[OrganizationOtherTabs.USAGE_EXPORT]}`}
              >
                Usage export
              </SubSidebarSectionItem>
            )}
            {runSummarizationAi && (
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationOtherTabs.AI,
                }}
                to={`../${pathConfig[OrganizationOtherTabs.AI]}`}
              >
                Artificial Intelligence
              </SubSidebarSectionItem>
            )}
          </SubSidebarSection>
        </SubSidebar>

        <PageWrapper className={styles.wrapper}>
          {data.markedForDeletion && (
            <Callout variant="danger" title="Account marked for deletion">
              <SettingsAccountDeleteWarning
                accountCanBeDeleted={data.accountCanBeDeleted}
                accountCanBeDeletedAt={data.accountCanBeDeletedAt}
              />
            </Callout>
          )}

          <SettingsRoutes apiKeys={data.apiKeys} securityEmail={data.securityEmail} />
        </PageWrapper>
      </Box>
    </SettingsContext.Provider>
  );
};

export default Settings;
