import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageCloudIntegrations } from "hooks/useAnalytics/pages/cloudIntegrations";

export const FILTERS_ORDER_SETTINGS_KEY_USED_BY = "cloudIntegrationsUsedByFiltersOrder";

export const TABLE_COLUMNS_STORAGE_KEY = "cloudIntegrationsUsedByColumns";

export enum USED_BY_COLUMN_ID {
  TYPE = "TYPE",
  NAME = "NAME",
}

export const ROW_HEADER_ID = USED_BY_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 600;

export const USED_BY_COLUMN_CONFIG: ColumnConfig = {
  [USED_BY_COLUMN_ID.TYPE]: {
    title: "Type",
    minWidth: 80,
    defaultWidth: 80,
    maxWidth: 80,
    allowsSorting: true,
    resizable: false,
    static: true,
  },
  [USED_BY_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    static: true,
  },
};

export const INITIAL_COLUMNS_CONFIG = {
  visible: Object.keys(USED_BY_COLUMN_CONFIG) as USED_BY_COLUMN_ID[],
  hidden: [],
};

export const SORT_CONFIG: Record<string, string> = {
  [USED_BY_COLUMN_ID.TYPE]: "type",
  [USED_BY_COLUMN_ID.NAME]: "name",
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageCloudIntegrations.CloudIntegrationUsedBy,
};
