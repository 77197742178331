import { useEffect, useMemo, useState } from "react";
import debounce from "lodash-es/debounce";
import { FormProvider } from "react-hook-form";

import useTypedContext from "hooks/useTypedContext";
import { Blueprint, BlueprintParseResult } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import { showFullDescription } from "ds/components/FullDescriptionDrawer";
import LoadingIndicator from "ds/components/LoadingIndicator";
import SkeletonLoader from "components/SkeletonLoader";

import BlueprintTemplateForm from "../components/TemplateForm";
import Panel from "./Panel";
import useBlueprintTemplateForm from "../components/TemplateForm/useBlueprintTemplateForm";

type FormPreviewProps = {
  body: string;
  blueprint: Blueprint;
  validate: (template: string) => Promise<void | BlueprintParseResult>;
};

const DEBOUNCE_PREVIEW = 500;

const FormPreview = ({ body, blueprint, validate }: FormPreviewProps) => {
  const [loading, setLoading] = useState(true);
  const [isExpanded, setExpanded] = useState(true);
  const { onError } = useTypedContext(FlashContext);
  const { builderForm, hasErrors, hasPreview, errors, setErrors, inputs, setInputs } =
    useBlueprintTemplateForm({
      blueprint,
    });

  const handleShowFullDescription = () => {
    showFullDescription({ description: blueprint.description });
  };

  const debouncedValidate = useMemo(
    () =>
      debounce(
        (template: string) =>
          validate(template)
            .then((result) => {
              setErrors(result?.errors || []);
              setInputs(result?.inputs || []);
            })
            .catch(onError)
            .finally(() => setLoading(false)),
        DEBOUNCE_PREVIEW
      ),
    [onError, setErrors, setInputs, validate]
  );

  useEffect(() => {
    if (isExpanded) {
      setLoading(true);
      debouncedValidate(body);
    }
  }, [body, debouncedValidate, isExpanded]);

  return (
    <Panel
      title="Preview"
      actions={<LoadingIndicator loading={loading} size="default" />}
      side="right"
      onExpanded={setExpanded}
    >
      <FormProvider {...builderForm}>
        {loading && !inputs.length ? (
          <SkeletonLoader />
        ) : (
          <BlueprintTemplateForm
            item={blueprint}
            isPublished={false}
            onOpenFullDescription={handleShowFullDescription}
            hasErrors={hasErrors}
            hasPreview={hasPreview}
            errors={errors}
            inputs={inputs}
          />
        )}
      </FormProvider>
    </Panel>
  );
};

export default FormPreview;
