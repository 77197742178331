import useTypedFlags from "hooks/useTypedFlags";

import BlueprintsLegacy from "./BlueprintsLegacy";
import BlueprintsNew from "./BlueprintsNew";

const Blueprints = () => {
  const { blueprintsTableRedesign } = useTypedFlags();

  return blueprintsTableRedesign ? <BlueprintsNew /> : <BlueprintsLegacy />;
};

export default Blueprints;
