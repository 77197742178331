import DocumentationButton from "components/DocumentationButton";
import { EmptystateChartColored } from "components/icons/generated";
import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import EmptyState from "ds/components/EmptyState";
import TileWrapper from "ds/components/Tile/Wrapper";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import useTypedContext from "hooks/useTypedContext";
import { getDocsUrl } from "utils/getDocsUrl";
import { SpacesContext } from "views/Account/SpacesProvider";

import { Widget } from "../../types";

type DashboardWidgetsStackFailuresEmptyProps = {
  notEnoughData?: boolean;
};

const DashboardWidgetsStackFailuresEmpty = ({
  notEnoughData,
}: DashboardWidgetsStackFailuresEmptyProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  return (
    <TileWrapper>
      <Box padding="small" fullWidth justify="center">
        <EmptyState
          title="There's no data to show yet"
          icon={EmptystateChartColored}
          caption={
            notEnoughData
              ? "Create more stacks so we can display statistics over stack failures here."
              : "Start creating stacks so we can display statistics over stack failures here."
          }
        >
          <Box gap="medium">
            <DocumentationButton
              to={getDocsUrl("/concepts/stack")}
              label="Documentation"
              analyticsPage={AnalyticsPageDashboard.Dashboard}
              analyticsTitle="Widget - Documentation Clicked"
              analyticsProps={{ widget: Widget.StacksFailures }}
            />
            {hasEntityCreateAccess && (
              <LinkButton variant="primary" to="/new/stack">
                Create stack
              </LinkButton>
            )}
          </Box>
        </EmptyState>
      </Box>
    </TileWrapper>
  );
};

export default DashboardWidgetsStackFailuresEmpty;
