import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import Link from "ds/components/Link";
import LoadingIndicator from "ds/components/LoadingIndicator";
import Typography from "ds/components/Typography";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { Stack } from "types/generated";

import { GET_STACK_CONFIGS } from "./gql";
import { stackToCode } from "./stackToCode";
import styles from "./styles.module.css";
import { StackConfigGql } from "./types";

type TerraformGeneratedCodeDrawerProps = {
  stack: Stack;
};

const TerraformGeneratedCodeDrawer = createDrawer(
  ({ stack }: TerraformGeneratedCodeDrawerProps) => {
    const { data, error, loading } = useQuery<StackConfigGql>(GET_STACK_CONFIGS, {
      variables: {
        id: stack.id,
      },
    });

    const generatedCode = useMemo(() => stackToCode(stack, data?.stack), [stack, data]);
    const handleCopy = useCopyToClipboard(generatedCode);

    return (
      <DrawerSimple variant="wide">
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Terraform code" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody>
          {loading ? (
            <Box grow="1" justify="center" align="center">
              <LoadingIndicator size="x-large" loading />
            </Box>
          ) : (
            <Box gap="large" direction="column">
              {error ? (
                <Banner variant="danger">
                  <pre>{JSON.stringify(error, null, 2)}</pre>
                </Banner>
              ) : (
                <>
                  <Typography variant="p-body2" tag="p">
                    Copy and paste the below code to your{" "}
                    <Link
                      href="https://docs.spacelift.io/vendors/terraform/terraform-provider"
                      size="medium"
                    >
                      terraform-provider-spacelift
                    </Link>{" "}
                    project.
                  </Typography>
                  <Box surfaceColor="quaternary" className={styles.code}>
                    <Box className={styles.codeInner} padding="x-large">
                      <Typography variant="p-body3" tag="pre">
                        <code>{generatedCode}</code>
                      </Typography>
                    </Box>

                    <Button
                      variant="secondary"
                      onPress={handleCopy}
                      size="small"
                      className={styles.copyToClipboardButton}
                    >
                      Copy
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          )}
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showTerraformGeneratedCodeDrawer = createDrawerTrigger(TerraformGeneratedCodeDrawer);
