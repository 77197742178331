import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";

import { CellComponentProps } from "../types";
import { getProviderLogo } from "../../helpers";

type UsersListItemMethodCellProps = CellComponentProps;

const UsersListItemMethodCell = ({ user }: UsersListItemMethodCellProps) => {
  const ProviderIcon = getProviderLogo(user.loginMethod);
  return (
    <Box direction="row" align="center">
      {ProviderIcon && (
        <>
          {typeof ProviderIcon === "string" ? (
            <Typography tag="span" variant="p-body2">
              {ProviderIcon}
            </Typography>
          ) : (
            <Icon src={ProviderIcon} size="large" />
          )}
        </>
      )}
    </Box>
  );
};

export default UsersListItemMethodCell;
