import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldTags from "components/FormFields/Tags";
import FormFieldViewText from "components/FormFields/ViewText";
import SpaceSelect from "components/SpaceSelect";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import FormField from "ds/components/Form/Field";
import FormToggleField from "ds/components/Form/ToggleField";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageSpaces } from "hooks/useAnalytics/pages/spaces";
import useTypedContext from "hooks/useTypedContext";
import { Space } from "types/generated";

import { showDeleteConfirmation } from "../DeleteConfirmation";
import { SPACE_CREATE, SPACE_UPDATE } from "../gql";
import { SpacesDrawerWriteMode, SpacesLayoutMode } from "../types";
import { getDefaultValues, isOnOutsideClickElementInteractive } from "./helpers";
import styles from "./styles.module.css";

type SpaceDrawerFormFields = {
  name: string;
  description?: string;
  inheritEntities: boolean;
  parentSpace: string;
  labels: Record<"value", string>[];
};

type SpaceDrawerBuilderProps = {
  drawerWriteMode: SpacesDrawerWriteMode;
  space?: Space;
  layoutMode: SpacesLayoutMode;
  onClose: () => void;
  isRootAdmin: boolean;
};

const SpaceDrawerBuilder = ({
  drawerWriteMode,
  space,
  layoutMode,
  onClose,
  isRootAdmin,
}: SpaceDrawerBuilderProps) => {
  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageSpaces.Spaces,
  });
  const modal = useModal();
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const isEditMode = drawerWriteMode === "edit";
  const isCreateMode = drawerWriteMode === "create";

  const builderForm = useForm<SpaceDrawerFormFields>({
    defaultValues: getDefaultValues(isEditMode, space),
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = builderForm;

  const [spaceCreate, { loading: isSpaceCreateLoading }] = useMutation<{ spaceCreate: Space }>(
    SPACE_CREATE,
    {
      awaitRefetchQueries: true,
      refetchQueries: ["GetSpaces"],
    }
  );
  const [spaceUpdate, { loading: isSpaceUpdateLoading }] = useMutation<{ spaceUpdate: Space }>(
    SPACE_UPDATE,
    {
      awaitRefetchQueries: true,
      refetchQueries: ["GetSpaces"],
    }
  );

  const hiddenIds = useMemo(() => {
    if (!isCreateMode && space?.id) {
      return [space.id];
    }

    return [];
  }, [space?.id, isCreateMode]);

  const onSubmit: SubmitHandler<SpaceDrawerFormFields> = (data) => {
    if (isEditMode && space?.id) {
      spaceUpdate({
        variables: {
          spaceId: space.id,
          input: {
            name: data.name,
            description: data.description,
            inheritEntities: data.inheritEntities,
            parentSpace: data.parentSpace,
            labels: data.labels.map((item) => item.value),
          },
        },
      })
        .then(({ data }) => {
          if (data?.spaceUpdate?.name) {
            reportSuccess({
              message: `Space "${data.spaceUpdate.name}" is successfully updated`,
            });
            modal.hide();
          }
        })
        .catch(onError);
    } else {
      spaceCreate({
        variables: {
          input: {
            name: data.name,
            description: data.description,
            inheritEntities: data.inheritEntities,
            parentSpace: data.parentSpace,
            labels: data.labels.map((item) => item.value),
          },
        },
      })
        .then(({ data }) => {
          if (data?.spaceCreate?.name) {
            reportSuccess({
              message: `Space "${data.spaceCreate.name}" is successfully created`,
            });
            modal.hide();

            const baseAnalyticsProps = {
              id: data.spaceCreate.id,
              parentSpace: data.spaceCreate.parentSpace ?? undefined,
              inheritEntities: data.spaceCreate.inheritEntities,
              labels: data.spaceCreate.labels,
            };

            if (space) {
              trackSegmentEvent("Child Space Added", {
                ...baseAnalyticsProps,
                view: layoutMode ?? undefined,
              });
            } else {
              trackSegmentEvent("Space Created", baseAnalyticsProps);
            }
          }
        })
        .catch(onError);
    }
  };

  const handleSpaceDeleteConfirmation = () => {
    if (!space?.id) {
      return;
    }

    showDeleteConfirmation({ name: space.name, id: space.id }).then(modal.hide);
  };

  useEffect(() => {
    reset(getDefaultValues(isEditMode, space));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space, isEditMode]);

  const isPrimaryActionLoading = isEditMode ? isSpaceUpdateLoading : isSpaceCreateLoading;

  const shouldCloseOnInteractOutside = useCallback(
    (el: Element) => {
      // It allows to open leave confirmation
      if (isDirty) {
        return true;
      }

      return isOnOutsideClickElementInteractive(el);
    },
    [isDirty]
  );

  return (
    <DrawerForm
      blockNavigation
      isDirty={isDirty}
      onClose={onClose}
      shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
    >
      <FormProvider {...builderForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title={isCreateMode ? "Create a new space" : "Space edit"} />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody>
          {isEditMode && space?.id && <FormFieldViewText label="ID" value={space.id} withCopy />}

          <FormField label="Name" error={errors?.name?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter space name here..."
                error={!!errors?.name}
                {...register("name", { required: "Name field is required." })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormField label="Description" isOptional>
            {({ ariaInputProps }) => (
              <Textarea
                placeholder="Enter space description here..."
                {...register("description")}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldTags label="Labels" tagName="label" name="labels" />

          <Controller
            name="parentSpace"
            control={control}
            rules={{ required: "Parent space field is required." }}
            render={({ field, fieldState }) => (
              <FormField label="Parent space" error={fieldState.error?.message}>
                {({ ariaInputProps }) => (
                  <SpaceSelect
                    value={field.value}
                    onChange={field.onChange}
                    error={!!fieldState.error?.message}
                    hiddenIds={hiddenIds}
                    disabled={isCreateMode && !!space}
                    ariaProps={ariaInputProps}
                  />
                )}
              </FormField>
            )}
          />

          <Box padding="x-large 0 0 0">
            <Controller
              name="inheritEntities"
              control={control}
              render={({ field }) => (
                <FormToggleField
                  variant="checkbox"
                  onChange={field.onChange}
                  checked={field.value}
                  title="Enable inheritance"
                  description="Should read access and attachable entities be inherited from the parent space."
                  disabled={!isRootAdmin}
                  tooltip={
                    !isRootAdmin
                      ? () => "Modifying inheritance is possible only for Root admin"
                      : undefined
                  }
                />
              )}
            />
          </Box>
        </DrawerBody>
        <DrawerFooter>
          {isEditMode && (
            <Button
              className={styles.deleteSpaceLink}
              variant="dangerSecondary"
              onPress={handleSpaceDeleteConfirmation}
            >
              Delete space
            </Button>
          )}
          <DrawerFooterActions>
            <DrawerCancelButton />

            <Button
              variant="primary"
              onPress={() => handleSubmit(onSubmit)()}
              disabled={isPrimaryActionLoading}
              loading={isPrimaryActionLoading}
            >
              {isEditMode ? "Save" : "Create"}
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </FormProvider>
    </DrawerForm>
  );
};

export default SpaceDrawerBuilder;
