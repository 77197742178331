import { Time } from "@internationalized/date";

export const MIN_DURATION_IN_SECONDS = 900;
export const MAX_DURATION_IN_SECONDS = 3600;
export const MAX_DURATION_IN_SECONDS_FOR_WORKER = 43200;

export const DURATION_OPTIONS_DEFAULT = [
  new Time(0, 15),
  new Time(0, 30),
  new Time(0, 45),
  new Time(1),
];

export const DURATION_OPTIONS_FOR_WORKER = [
  new Time(0, 15),
  new Time(1),
  new Time(2),
  new Time(4),
  new Time(8),
  new Time(12),
];
