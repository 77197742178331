import { CSSProperties, forwardRef, memo, ReactNode } from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type TableBodyRowProps = {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
};

const TableBodyRow = memo(
  forwardRef<HTMLDivElement, TableBodyRowProps>(function TableBodyRow(
    { children, className, ...props },
    ref
  ) {
    return (
      <div {...props} className={cx(styles.row, className)} role="row" tabIndex={-1} ref={ref}>
        {children}
      </div>
    );
  })
);

TableBodyRow.displayName = "DS.Table.Body.Row";

export default TableBodyRow;
