import { gql, TypedDocumentNode } from "@apollo/client";

import { AzureIntegration, MutationAzureIntegrationDeleteArgs } from "types/generated";

export const DELETE_AZURE_INTEGRATION: TypedDocumentNode<
  {
    azureIntegrationDelete: Pick<AzureIntegration, "id">;
  },
  MutationAzureIntegrationDeleteArgs
> = gql`
  mutation DeleteAzureIntegration($id: ID!) {
    azureIntegrationDelete(id: $id) {
      id
    }
  }
`;
