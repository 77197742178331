import { Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import { Blueprint } from "types/generated";
import DescriptionDetails from "components/DescriptionDetails";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import Box from "ds/components/Box";

import BlueprintVersionStatusBadge from "./components/VersionStatusBadge";
import getVersionText from "./getVersionText";
import { BlueprintVersion } from "./types";

type BlueprintVersionDetailsProps = {
  blueprint: Blueprint;
  version: BlueprintVersion;
};

const BlueprintVersionDetailsDrawer = createDrawer(
  ({ blueprint, version }: BlueprintVersionDetailsProps) => {
    const onOpenFullDescription = useShowFullDescriptionWithBackArrow(blueprint.description);

    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Version details" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody>
          <FormFieldViewText label="Name" value={blueprint.name} />

          <FormField label="Space">
            <MetaInfoListItem
              icon={Space}
              linkText={blueprint.space.name}
              href={`/spaces/${blueprint.space.id}`}
            />
          </FormField>

          <DescriptionDetails
            description={blueprint.description}
            onOpenFullDescription={onOpenFullDescription}
          />

          <FormFieldTimestamp label="Last updated" timestamp={blueprint.updatedAt} />

          {blueprint.labels.length > 0 && (
            <FormField label="Labels">
              <TagsList tags={blueprint.labels} alwaysExpanded />
            </FormField>
          )}

          <FormFieldViewText label="Unique version identifier" value={getVersionText(version)} />

          <FormFieldViewText label="Version description" value={version.description} />

          <FormField label="Status">
            <Box>
              <BlueprintVersionStatusBadge status={version.status} />
            </Box>
          </FormField>

          {version.deprecationNote && (
            <DescriptionDetails label="Deprecation note" description={version.deprecationNote} />
          )}
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showBlueprintVersionDetailsDrawer = createDrawerTrigger(BlueprintVersionDetailsDrawer);
