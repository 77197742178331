import { DropdownMenuEllipsisProps } from "ds/components/DropdownMenu/Ellipsis";
import { TrackAnalyticsEventProperties } from "shared/Analytics";

import { isAzureIntegration } from "../../utils";
import { isAwsIntegration } from "../../utils";
import { CloudIntegration } from "../../types";
import CloudIntegrationsAwsActionsDropdown from "../Aws/ActionsDropdown";
import CloudIntegrationsAzureActionsDropdown from "../Azure/ActionsDropdown";

type CloudIntegrationsActionsDropdownProps = {
  integration: CloudIntegration;
  trackAnalytics: (event: string, props?: TrackAnalyticsEventProperties) => void;
  refetchQueriesOnEdit?: string[];
  refetchQueriesOnDelete?: string[];
  hideSeeDetails?: boolean;
  onDeleteSuccess?: () => void;
  dotsSize?: DropdownMenuEllipsisProps["dotsSize"];
};

const CloudIntegrationsActionsDropdown = ({
  integration,
  trackAnalytics,
  refetchQueriesOnEdit,
  refetchQueriesOnDelete,
  hideSeeDetails,
  onDeleteSuccess,
  dotsSize = "small",
}: CloudIntegrationsActionsDropdownProps) => {
  if (isAwsIntegration(integration)) {
    return (
      <CloudIntegrationsAwsActionsDropdown
        integration={integration}
        trackAnalytics={trackAnalytics}
        hideSeeDetails={hideSeeDetails}
        onDeleteSuccess={onDeleteSuccess}
        dotsSize={dotsSize}
        refetchQueriesOnEdit={refetchQueriesOnEdit || ["GetAwsIntegration"]}
        refetchQueriesOnDelete={refetchQueriesOnDelete}
      />
    );
  }

  if (isAzureIntegration(integration)) {
    return (
      <CloudIntegrationsAzureActionsDropdown
        integration={integration}
        trackAnalytics={trackAnalytics}
        hideSeeDetails={hideSeeDetails}
        onDeleteSuccess={onDeleteSuccess}
        dotsSize={dotsSize}
        refetchQueriesOnEdit={refetchQueriesOnEdit || ["GetAzureIntegration"]}
        refetchQueriesOnDelete={refetchQueriesOnDelete}
      />
    );
  }

  return null;
};

export default CloudIntegrationsActionsDropdown;
