import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { SearchQueryOrderDirection } from "types/generated";

export const USER_IDP_GROUPS_COLUMNS_STORAGE_KEY = "userIdpGroupsColumnsConfig";
export const FILTERS_ORDER_SETTINGS_KEY = "userIdpGroupsFiltersOrder";

export const initialSortOption = "name";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export enum USER_IDP_GROUPS_COLUMN_ID {
  NAME = "NAME",
  SPACE = "SPACE",
  LOGIN_METHOD = "LOGIN_METHOD",
  STATUS = "STATUS",
}

export const ROW_HEADER_ID = USER_IDP_GROUPS_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 600;

export const USER_IDP_GROUPS_COLUMN_CONFIG: ColumnConfig = {
  [USER_IDP_GROUPS_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    static: true,
    resizable: false,
  },

  [USER_IDP_GROUPS_COLUMN_ID.LOGIN_METHOD]: {
    title: "Login method",
    minWidth: 130,
    maxWidth: MAX_CELL_WIDTH,
    resizable: false,
    static: true,
  },

  [USER_IDP_GROUPS_COLUMN_ID.SPACE]: {
    title: "Space",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    static: true,
    resizable: false,
  },
  [USER_IDP_GROUPS_COLUMN_ID.STATUS]: {
    title: "Status",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    static: true,
    resizable: false,
  },
};

export const SORT_CONFIG: Record<string, string> = {
  [USER_IDP_GROUPS_COLUMN_ID.NAME]: "name",
  [USER_IDP_GROUPS_COLUMN_ID.SPACE]: "space",
  [USER_IDP_GROUPS_COLUMN_ID.STATUS]: "status",
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(USER_IDP_GROUPS_COLUMN_CONFIG) as USER_IDP_GROUPS_COLUMN_ID[],
  hidden: [],
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageOrganization.OrganizationUserIdpGroups,
};
