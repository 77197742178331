import { ReactNode } from "react";
import { PopoverProps } from "react-aria-components";

import { Dots } from "components/icons/generated";

import DropdownMenu from ".";
import ButtonIcon, { ButtonIconProps } from "../ButtonIcon";

export type DropdownMenuEllipsisProps = {
  children: ReactNode;
  dotsSize?: ButtonIconProps["size"];
  buttonVariant?: ButtonIconProps["variant"];
  placement?: PopoverProps["placement"];
  disabled?: boolean;
  tooltip: string;
  onPress?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
  className?: string;
  id?: string;
};

const DropdownMenuEllipsis = ({
  children,
  dotsSize = "small",
  buttonVariant = "secondary",
  placement = "bottom right",
  disabled,
  tooltip,
  onPress,
  onOpenChange,
  className,
  id,
}: DropdownMenuEllipsisProps) => {
  return (
    <DropdownMenu
      onOpenChange={onOpenChange}
      placement={placement}
      triggerComponent={
        <ButtonIcon
          id={id}
          disabled={disabled}
          onPress={onPress}
          variant={buttonVariant}
          icon={Dots}
          size={dotsSize}
          className={className}
        >
          {tooltip}
        </ButtonIcon>
      }
    >
      {children}
    </DropdownMenu>
  );
};

DropdownMenuEllipsis.displayName = "DS.DropdownMenu.Ellipsis";

export default DropdownMenuEllipsis;
