import { gql, TypedDocumentNode } from "@apollo/client";

import {
  ManagedEntitiesConnection,
  PageInfo,
  QuerySearchManagedEntitiesArgs,
} from "types/generated";

type SearchManagedEntitiesData = {
  searchManagedEntities: {
    edges: Array<ManagedEntitiesConnection>;
    pageInfo: Pick<PageInfo, "endCursor" | "startCursor" | "hasNextPage">;
  };
};

export const SEARCH_MANAGED_ENTITIES: TypedDocumentNode<
  SearchManagedEntitiesData,
  QuerySearchManagedEntitiesArgs
> = gql`
  query SearchManagedEntities($input: SearchInput!) {
    searchManagedEntities(input: $input) {
      edges {
        cursor
        node {
          id
          address
          creator {
            id
            title
            updatedAt
          }
          drifted
          name
          parent
          stackId
          stackName
          stackVendor
          thirdPartyMetadata
          type
          updater {
            id
            title
            updatedAt
          }
          vendor {
            ... on EntityVendorAnsible {
              ansible {
                ... on AnsibleResource {
                  __typename
                }
              }
            }
            ... on EntityVendorTerraform {
              terraform {
                ... on TerraformResource {
                  module
                  address
                  provider
                  tainted
                  values
                }
                ... on TerraformOutput {
                  hash
                  sensitive
                  value
                }
                ... on TerraformModule {
                  __typename
                }
              }
            }
            ... on EntityVendorCloudFormation {
              cloudFormation {
                ... on CloudFormationResource {
                  logicalResourceId
                  physicalResourceId
                  template
                }
              }
            }
            ... on EntityVendorKubernetes {
              kubernetes {
                ... on KubernetesResource {
                  data
                }
              }
            }
            ... on EntityVendorPulumi {
              pulumi {
                ... on PulumiResource {
                  urn
                  id
                  provider
                  parent
                  outputs
                }
                ... on PulumiOutput {
                  hash
                  sensitive
                  value
                }
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
`;
