import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import CardWrapper from "components/CardWrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import AccountsField from "views/Account/NewModule/Share/AccountsField";
import useBlockNavigationModal from "hooks/useBlockNavigationModal";

import { ModuleSettingsContext } from "../Context";
import { useUpdateModule } from "../hooks/useUpdateModule";
import { getDefaultValues } from "./utils";

type FormValues = {
  sharedAccounts: Array<{ value: string }>;
};

const ModuleSettingsSharingEditForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);
  const { updateModule, loading } = useUpdateModule({ module });

  const builderForm = useForm<FormValues>({
    defaultValues: getDefaultValues(module),
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    reset,
  } = builderForm;

  useBlockNavigationModal({ isNavigationBlocked: isDirty });

  const onSubmit = useCallback(
    () =>
      handleSubmit((values: FormValues) => {
        const sharedAccounts = values.sharedAccounts
          .map(({ value }) => value)
          .filter((value) => value.trim() !== "");

        return updateModule({
          sharedAccounts,
        });
      })(),
    [updateModule, handleSubmit]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getDefaultValues(module));
    }
  }, [isSubmitSuccessful, module, reset]);

  return (
    <>
      <FormProvider {...builderForm}>
        <CardWrapper variant="filled">
          <AccountsField />
        </CardWrapper>
      </FormProvider>
      <Box justify="end" margin="large 0 0">
        <Button variant="primary" onPress={onSubmit} disabled={loading} loading={loading}>
          Save
        </Button>
      </Box>
    </>
  );
};

export default ModuleSettingsSharingEditForm;
