import { ReactNode } from "react";

import { ChevronDown } from "components/icons/generated";
import Button from "ds/components/Button";
import LinkButton from "ds/components/Button/LinkButton";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import { AnalyticsPageLaunchpad } from "hooks/useAnalytics/pages/launchpad";
import { DocsUrl } from "utils/getDocsUrl";

import { KnowledgeBaseItemActionProps } from "./content";

type KnowledgeBaseTileIndicatorProps = {
  title: string;
  buttonTitle: string;
  onDrawer: (content: ReactNode, docsLink: DocsUrl) => void;
} & KnowledgeBaseItemActionProps;

const ANALYTICS_TITLE = "Knowledge Base Click";

const LaunchpadKnowledgeBaseTileIndicator = ({
  title,
  buttonTitle,
  buttonLink,
  drawerContent,
  onDrawer,
  externalLinks,
  drawerFullDocs,
}: KnowledgeBaseTileIndicatorProps) => {
  const dropdownClickHandler = (url: string) => () => {
    window.open(url, "_blank");
  };

  if (buttonLink) {
    const link = buttonLink(location.origin);
    const href = link.startsWith(location.origin) ? undefined : link;
    const to = link.startsWith(location.origin) ? link.replace(location.origin, "") : undefined;

    return (
      <LinkButton
        variant="secondary"
        href={href}
        to={to}
        analyticsPage={AnalyticsPageLaunchpad.Launchpad}
        analyticsTitle={ANALYTICS_TITLE}
        analyticsProps={{ title }}
      >
        {buttonTitle}
      </LinkButton>
    );
  }

  if (drawerContent) {
    return (
      <Button
        variant="secondary"
        onPress={() => {
          onDrawer?.(drawerContent, drawerFullDocs);
        }}
        analyticsPage={AnalyticsPageLaunchpad.Launchpad}
        analyticsTitle={ANALYTICS_TITLE}
        analyticsProps={{ title }}
      >
        {buttonTitle}
      </Button>
    );
  }

  if (externalLinks) {
    return (
      <DropdownMenu
        placement="bottom end"
        triggerComponent={
          <Button variant="secondary" endIcon={ChevronDown}>
            {buttonTitle}
          </Button>
        }
      >
        {externalLinks.map(({ title: linkTitle, link }) => (
          <DropdownMenuItem
            key={`${title} - ${linkTitle}`}
            onAction={dropdownClickHandler(link)}
            analyticsPage={AnalyticsPageLaunchpad.Launchpad}
            analyticsTitle={ANALYTICS_TITLE}
            analyticsProps={{ title: `${title} - ${linkTitle}` }}
          >
            {linkTitle}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    );
  }

  return null;
};

export default LaunchpadKnowledgeBaseTileIndicator;
