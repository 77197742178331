import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

import AttachedContext from "components/AttachedContext";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import EnvironmentEditMode from "components/Environment/edit/EditMode";
import EnvironmentViewMode from "components/Environment/view/ViewMode";
import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import Button from "ds/components/Button";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import { MODULE_TYPE } from "views/shared/constants";

import { ModuleContext } from "../Context";

const GET_MODULE_ENVIRONMENT = gql`
  query GetModuleEnvironment($moduleId: ID!) {
    module(id: $moduleId) {
      id
      attachedContexts {
        id
        contextName
        config {
          ...configElement
        }
      }
      canWrite
      name
      runtimeConfig {
        context {
          contextId
          contextName
        }
        element {
          ...configElement
        }
      }
    }
  }
  fragment configElement on ConfigElement {
    createdAt
    id
    runtime
    type
    value
    writeOnly
  }
`;

type GetModuleEnvironmentGql = {
  module: Module;
};

const ModuleEnvironment = () => {
  const { onError } = useTypedContext(FlashContext);
  const { module: stackCtx } = useTypedContext(ModuleContext);
  const [editing, setEditing] = useState(false);
  const toggleEdit = () => setEditing(!editing);

  const { error, loading, data } = useQuery<GetModuleEnvironmentGql>(GET_MODULE_ENVIRONMENT, {
    variables: { moduleId: stackCtx.id },
    onError,
  });

  useBreadcrumbs([
    {
      title: "Modules",
      link: "/modules",
    },
    {
      title: stackCtx.id,
    },
  ]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module) {
    return <NotFoundPage />;
  }

  const stack = { ...stackCtx, ...data.module };

  return (
    <>
      <PageInfo title="Environment">
        {stack.canWrite && (
          <Button onPress={toggleEdit} variant={editing ? "primary" : "secondary"}>
            {editing ? "Done" : "Edit"}
          </Button>
        )}
      </PageInfo>
      <PageWrapper>
        <div className="environment">
          <div className="environment-runtime">
            {editing ? (
              <EnvironmentEditMode stack={stack} type={MODULE_TYPE} />
            ) : (
              <EnvironmentViewMode stack={stack} />
            )}
          </div>
          {stack.attachedContexts && (
            <>
              <h4 className="environment__attached-contexts">Attached contexts</h4>
              {stack.attachedContexts.map((attachment) => (
                <AttachedContext key={attachment.id} {...attachment} />
              ))}
            </>
          )}
        </div>
      </PageWrapper>
    </>
  );
};

export default ModuleEnvironment;
