import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import PageWrapper from "components/PageWrapper";

type SpacesPageLayoutProps = {
  children: ReactNode;
};

const SpacesPageLayout = ({ children }: SpacesPageLayoutProps) => {
  return (
    <>
      <ViewHeader firstLevel>
        <Box fullWidth justify="between" align="center">
          <ViewHeaderTitle tag="h2">Spaces</ViewHeaderTitle>
        </Box>
      </ViewHeader>
      <PageWrapper>{children}</PageWrapper>
    </>
  );
};

export default SpacesPageLayout;
