import { memo } from "react";

import { type RenderGroupRowCellProps } from ".";
import IacManagementTableVendorCell from "../components/VendorCell";

type IacManagementTableGroupRowCellVendorProps = Pick<RenderGroupRowCellProps, "row">;

const IacManagementTableGroupRowCellVendor = memo(
  ({ row }: IacManagementTableGroupRowCellVendorProps) => {
    const isStackRow = row.__typename === "ManagedEntitiesGroupedByStacks";

    const vendor = (isStackRow && row.stackVendor) || undefined;

    return <IacManagementTableVendorCell stackVendor={vendor} />;
  }
);

IacManagementTableGroupRowCellVendor.displayName = "IacManagementTableGroupRowCellVendor";

export default IacManagementTableGroupRowCellVendor;
