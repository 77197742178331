import { memo, useEffect, useRef } from "react";
import { useShallow } from "zustand/react/shallow";

import TablePagination from "components/Table/Pagination";
import usePagination from "components/Table/Pagination/usePagination";
import { SearchQueryRequestedPage } from "types/generated";

import { GROUPS_LIMIT, GROUPS_LIMIT_STORAGE_KEY, PAGINATION_OPTIONS } from "./constants";
import useIacTableStore from "../components/Table/useIacTableStore";
import IacManagementTable from "../components/Table";
import useIacManagementStore from "../useIacManagementStore";
import useFiltersVariables from "../useFiltersVariables";
import IacManagementEmpty from "../components/Empty";
import useIacManagementGroups from "../useIacManagementGroups";
import { IacManagementGroup } from "../components/Table/useIacTableStore/makeIacTableDataSlice/types";
import IacManagementTableGroupRow from "./TableGroupRow";
import IacManagementTableResourceRow from "./TableResourceRow";
import IacManagementTableHeadRow from "./TableHeadRow";
import useIacManagementAnalytics from "../useIacManagementAnalytics";
import { IacManagementGroupBy } from "../types";
import useIacManagementResources from "../useIacManagementResources";

const IacManagementListLayout = () => {
  const { analyticPage } = useIacManagementAnalytics();

  const { setGroups, flushTable, loadResources, openGroup } = useIacTableStore(
    useShallow((state) => ({
      setGroups: state.setGroups,
      flushTable: state.flushTable,
      loadResources: state.loadResources,
      openGroup: state.openGroup,
    }))
  );

  const { allGroupsQty, setPageIsEmpty, groupBy, parentStackId } = useIacManagementStore(
    useShallow((state) => ({
      allGroupsQty: state.allItemsQty,
      setPageIsEmpty: state.setPageIsEmpty,
      groupBy: state.groupBy,
      parentStackId: state.parentStackId,
    }))
  );

  const { groups, loading, fetchData, endCursor, startCursor, isEmpty, searchIsEmpty } =
    useIacManagementGroups({ groupBy, parentStackId });
  const { fetchData: fetchResources } = useIacManagementResources();

  const {
    rowsPerPage,
    setRowsPerPage,
    from,
    to,
    lastPage,
    page,
    goToNextPage,
    goToPrevPage,
    goToFirstPage,
    goToLastPage,
    resetToInitialPage,
  } = usePagination<IacManagementGroup>({
    fetchItems: fetchData,
    endCursor,
    startCursor,
    items: groups,
    initialLimit: GROUPS_LIMIT,
    itemsCount: allGroupsQty,
    storageKey: GROUPS_LIMIT_STORAGE_KEY,
    analyticsPage: analyticPage,
  });

  const { filterVariables } = useFiltersVariables();

  useEffect(() => {
    setPageIsEmpty(isEmpty);
  }, [isEmpty, setPageIsEmpty]);

  // When filters & groupBy change, we need to reset the table and fetch fresh data
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    flushTable();
    resetToInitialPage();
  }, [flushTable, resetToInitialPage, filterVariables, groupBy]);

  // Fetch initial data or reset data when filters change
  useEffect(() => {
    fetchData({
      cursor: null,
      requestedPage: SearchQueryRequestedPage.First,
      rowsPerPage,
    });
  }, [fetchData, filterVariables, rowsPerPage]);

  // Update groups when data changes. filterVariables - to force update when filters change
  useEffect(() => {
    setGroups(groups);
  }, [setGroups, groups, filterVariables]);

  /**
   * Stack views: automatically open the Stack group and load resources
   * * FYI:`filterVariables` is used to force the effect to run when filters change on Stack view
   */
  useEffect(() => {
    if (parentStackId && groupBy === IacManagementGroupBy.Stack) {
      openGroup(parentStackId);
      loadResources(
        {
          // it's okay to use parentStackId for both arguments, the fetchData function will handle it
          groupId: parentStackId,
          parentStackId,
          groupBy,
          rowsPerPage,
        },
        fetchResources
      );
    }
  }, [
    filterVariables,
    fetchResources,
    groupBy,
    loadResources,
    parentStackId,
    openGroup,
    rowsPerPage,
  ]);

  return (
    <>
      {isEmpty && <IacManagementEmpty />}
      {searchIsEmpty && <IacManagementEmpty hasNoSearchResult />}
      {!searchIsEmpty && !isEmpty && (
        <IacManagementTable
          groupsLoading={loading}
          rowsPerPage={rowsPerPage}
          renderHeadRow={IacManagementTableHeadRow}
          renderGroupRow={IacManagementTableGroupRow}
          renderResourceRow={IacManagementTableResourceRow}
        />
      )}

      {!isEmpty && (
        <TablePagination
          from={from}
          to={to}
          loading={loading}
          goToNextPage={goToNextPage}
          setRowsPerPage={setRowsPerPage}
          goToPrevPage={goToPrevPage}
          goToFirstPage={goToFirstPage}
          goToLastPage={goToLastPage}
          rowsPerPage={rowsPerPage}
          itemsCount={allGroupsQty}
          lastPage={lastPage}
          page={page}
          paginationOptions={PAGINATION_OPTIONS}
        />
      )}
    </>
  );
};

export default memo(IacManagementListLayout);
