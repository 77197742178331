import { StateCreator } from "zustand";

import { type IacTableDataSlice } from "./types";
import { IacTableStoreState } from "../types";

const makeIacTableDataSlice: StateCreator<
  IacTableStoreState,
  [["zustand/immer", never]],
  [["zustand/persist", never]],
  IacTableDataSlice
> = (set, get) => ({
  groups: [],
  setGroups: (groups) =>
    set((state) => {
      state.groups = groups;
    }),
  resources: {},
  resourcesIdsPerGroup: {},
  loadResources: async (queryProps, asyncQuery) => {
    const after = get().groupsMeta[queryProps.groupId]?.endCursor || null;
    const { data, hasMore, endCursor } = await asyncQuery({
      groupId: queryProps.groupId,
      groupBy: queryProps.groupBy,
      parentStackId: queryProps.parentStackId,
      cursor: after,
      rowsPerPage: queryProps.rowsPerPage,
    });
    get().updateGroupMeta(queryProps.groupId, { hasMore, endCursor });

    set((state) => {
      const prevGroupResourceIds = state.resourcesIdsPerGroup[queryProps.groupId] || [];
      const resourcesIds = data.map((resource) => resource.id);

      data.forEach((resource) => {
        state.resources[resource.id] = resource;
      });
      // Use a Set to ensure resource IDs are unique
      state.resourcesIdsPerGroup[queryProps.groupId] = Array.from(
        new Set([...prevGroupResourceIds, ...resourcesIds])
      );
    });
  },
  flushTable: () => {
    set((state) => {
      state.resources = {};
      state.resourcesIdsPerGroup = {};
      state.groups = [];
      state.groupsMeta = {};
    });
  },
});

export default makeIacTableDataSlice;
