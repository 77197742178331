import { forwardRef, ReactNode } from "react";
import cx from "classnames";
import camelCase from "lodash-es/camelCase";

import { SpacingShorthand } from "types/Spacing";
import { getSpacingCSS } from "utils/css";
import { FlexAlign, FlexJustify } from "types/Flex";
import { SurfaceColors } from "types/Colors";
import {
  FLEX_ALIGN_PREFIX,
  FLEX_JUSTIFY_PREFIX,
  LIMIT_WIDTH_PREFIX,
  SURFACE_COLOR_PREFIX,
} from "constants/style";

import styles from "./styles.module.css";

export type BoxProps = {
  className?: string;
  direction?: "row" | "row-reverse" | "column";
  align?: FlexAlign;
  justify?: FlexJustify;
  shrink?: "0" | "1";
  grow?: "0" | "1";
  fullWidth?: boolean;
  fullHeight?: boolean;
  limitWidth?: "medium";
  grid?: boolean;
  gridTemplate?: string;
  gridColStart?: string;
  gridAreas?: string;
  gridArea?: string;
  wrap?: boolean;
  padding?: SpacingShorthand;
  margin?: SpacingShorthand;
  gap?: SpacingShorthand;
  /**
   * @deprecated use "gap" instead.
   */
  __deprecatedGap?: string;
  relative?: boolean;
  zeroMinWidth?: boolean;
  scrollable?: boolean;
  overflowHidden?: boolean;
  children: ReactNode;
  surfaceColor?: SurfaceColors;
} & React.HTMLAttributes<HTMLDivElement>;

const Box = forwardRef(function Box(props: BoxProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    children,
    className,
    direction = "row",
    align = "stretch",
    justify = "start",
    shrink = "1",
    grow = "0",
    gap,
    __deprecatedGap,
    fullWidth,
    fullHeight,
    limitWidth,
    grid,
    gridTemplate,
    gridColStart,
    gridAreas,
    gridArea,
    relative,
    padding,
    margin,
    style,
    wrap,
    zeroMinWidth,
    scrollable,
    surfaceColor,
    overflowHidden,
    ...restProps
  } = props;

  return (
    <div
      ref={ref}
      className={cx(
        styles.box,
        styles[camelCase(direction)],
        styles[camelCase(`${FLEX_ALIGN_PREFIX}-${align}`)],
        styles[camelCase(`${FLEX_JUSTIFY_PREFIX}-${justify}`)],
        surfaceColor && styles[camelCase(`${SURFACE_COLOR_PREFIX}-${surfaceColor}`)],
        {
          [styles.noShrink]: shrink === "0",
          [styles.grow]: grow === "1",
          [styles.fullWidth]: fullWidth,
          [styles.fullHeight]: fullHeight,
          [styles.grid]: grid,
          [styles.minWidthZero]: zeroMinWidth,
          [styles.wrap]: wrap,
          [styles.scrollable]: scrollable,
          [styles.overflowHidden]: overflowHidden,
        },
        relative && styles.relative,
        limitWidth && styles[camelCase(`${LIMIT_WIDTH_PREFIX}-${limitWidth}`)],
        className
      )}
      style={{
        ...(grid && gridTemplate
          ? ({ "--grid-column-template": gridTemplate } as React.CSSProperties)
          : {}),
        ...(grid && gridAreas
          ? ({ "--grid-template-areas": gridAreas } as React.CSSProperties)
          : {}),
        ...(gridColStart && { gridColumnStart: gridColStart }),
        ...(gridArea && { gridArea }),
        ...((margin && getSpacingCSS("margin", margin)) || {}),
        ...((padding && getSpacingCSS("padding", padding)) || {}),
        ...((gap && getSpacingCSS("gap", gap)) || {}),
        ...(__deprecatedGap && { gap: __deprecatedGap }),
        ...style,
      }}
      {...restProps}
    >
      {children}
    </div>
  );
});

Box.displayName = "DS.Box";

export default Box;
