import { useContext } from "react";
import { ComboBoxStateContext } from "react-aria-components";

import { Cross } from "components/icons/generated";

import ButtonIcon from "../ButtonIcon";

type ComboBoxClearButtonProps = {
  isDisabled?: boolean;
};
const ComboBoxClearButton = ({ isDisabled }: ComboBoxClearButtonProps) => {
  const state = useContext(ComboBoxStateContext);

  if (state?.inputValue === "") {
    return null;
  }

  return (
    <ButtonIcon
      // Don't inherit default Button behavior from ComboBox.
      variant="ghost"
      slot={null}
      aria-label="Clear"
      icon={Cross}
      disabled={isDisabled}
      onPress={() => state?.setSelectedKey(null)}
    >
      Clear
    </ButtonIcon>
  );
};

ComboBoxClearButton.displayName = "DS.ComboBox.ClearButton";

export default ComboBoxClearButton;
