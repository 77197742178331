import { Pages } from "./types";

export const enum AnalyticsPageBlueprints {
  BlueprintsList = "BlueprintsList",
  BlueprintVersionsList = "BlueprintVersionsList",
  BlueprintIntegrationsList = "BlueprintIntegrationsList",
}

const blueprints = "Blueprints";

export const pagesBlueprints: Pages<AnalyticsPageBlueprints> = {
  [AnalyticsPageBlueprints.BlueprintsList]: [blueprints, "Blueprints list"],
  [AnalyticsPageBlueprints.BlueprintIntegrationsList]: [blueprints, "Blueprint integrations list"],
  [AnalyticsPageBlueprints.BlueprintVersionsList]: [blueprints, "Blueprint versions list"],
};
