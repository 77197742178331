import Box from "ds/components/Box";
import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";

import styles from "./styles.module.css";

type DependencyLinkProps = {
  href: string;
  stackName: string;
  onPress: () => void;
};

export const DependencyLinkItem = ({ href, stackName, onPress }: DependencyLinkProps) => {
  return (
    <li>
      <Box fullWidth className={styles.stackName}>
        <TextEllipsis tooltip={stackName} tooltipWidthMode="maxWidthSm">
          {(tooltipReferenceProps) => (
            <Link variant="secondary" href={href} onPress={onPress} {...tooltipReferenceProps}>
              <b>{stackName}</b>
            </Link>
          )}
        </TextEllipsis>
      </Box>
    </li>
  );
};
