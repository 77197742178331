import { useModal } from "@ebay/nice-modal-react";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import useTypedContext from "hooks/useTypedContext";

import { BlueprintVersionFormFields } from "../components/VersionFormFragment";
import DetailsFormDrawer from "./components/DetailsFormDrawer";
import { BlueprintVersion } from "./types";

type BlueprintVersionDuplicateProps = {
  version: BlueprintVersion;
};

const BlueprintVersionDuplicateDrawer = createDrawer(
  ({ version }: BlueprintVersionDuplicateProps) => {
    const drawer = useModal();
    const { onError, reportSuccess } = useTypedContext(FlashContext);

    // TODO: mutation to duplicate blueprint version

    const onSubmit = (formData: BlueprintVersionFormFields) => {
      // TODO: duplicate version
      return Promise.resolve(formData)
        .then((data) => {
          console.log(data);
          if (data?.version) {
            reportSuccess({
              message: `Blueprint version ${data.version} is successfully duplicated`,
            });
            drawer.hide();

            // TODO: navigate to new version
          }
        })
        .catch(onError);
    };

    const defaultValues = useMemo(
      () => ({
        version: `${version.version}-copy`,
        description: version.description,
      }),
      [version]
    );

    return (
      <DetailsFormDrawer
        title="Duplicate blueprint version"
        buttonText="Duplicate"
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
    );
  }
);

export const showBlueprintVersionDuplicateDrawer = createDrawerTrigger(
  BlueprintVersionDuplicateDrawer
);
