import { mergeRefs } from "@react-aria/utils";
import cx from "classnames";
import { useMemo, useState } from "react";

import { ChevronDown } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Tooltip from "ds/components/Tooltip";
import useElementWidth from "hooks/useElementWidth";

import DeltaCountsCell from "./Cell";
import { deltaConfig } from "./constants";
import DeltaCountsSingleDelta from "./SingleDelta";
import styles from "./styles.module.css";
import { DeltaType } from "./types";

type Delta = { type: DeltaType; count: number };

type DeltaCountsResponsiveProps = {
  deltas: Delta[];
};

const SPACE = 10;
const INITIAL_MULTIPLE_DELTAS_DROPDOWN_WIDTH = 54;
const INITIAL_DELTA_WIDTH = 54;

const DeltaCountsResponsive = ({ deltas }: DeltaCountsResponsiveProps) => {
  const [deltasSizes, setDeltasSizes] = useState<number[]>(deltas.map(() => INITIAL_DELTA_WIDTH));

  const [containerRef, containerSize] = useElementWidth<HTMLDivElement>();
  const [mutlipleTagsDropdownRef, mutlipleDeltasDropdownSize] = useElementWidth<HTMLButtonElement>(
    INITIAL_MULTIPLE_DELTAS_DROPDOWN_WIDTH
  );

  const hiddenDeltas = useMemo(() => {
    if (!deltas.length) {
      return [];
    }

    const hiddenDeltas: Delta[] = [];

    let summOfSizes = mutlipleDeltasDropdownSize;

    for (let i = 0; i < deltas.length; i++) {
      const size = deltasSizes[i] + SPACE;
      const isLast = i === deltas.length - 1;

      summOfSizes += size;

      if (isLast) {
        summOfSizes -= mutlipleDeltasDropdownSize;
      }

      if (containerSize && summOfSizes > containerSize) {
        hiddenDeltas.push(...deltas.slice(i));
        return hiddenDeltas;
      }
    }

    return hiddenDeltas;
  }, [containerSize, deltas, deltasSizes, mutlipleDeltasDropdownSize]);

  if (deltas.length === 0) {
    return null;
  }

  const moreTagsTooltip = `${hiddenDeltas.length === deltas.length ? `${hiddenDeltas.length} deltas` : `+${hiddenDeltas.length}`}`;

  return (
    <Box
      ref={containerRef}
      className={styles.container}
      fullWidth
      gap="medium"
      justify="start"
      align="center"
    >
      {deltas.length !== hiddenDeltas.length && (
        <Box className={cx(styles.flexible, styles.medium)}>
          <div className={styles.group}>
            {deltas.map((delta, i) => (
              <DeltaCountsSingleDelta
                setDeltasSizes={setDeltasSizes}
                currentSize={deltasSizes[i]}
                shouldHide={hiddenDeltas.includes(delta)}
                key={i}
                containerSize={containerSize}
                deltaIndex={i}
                type={delta.type}
                count={delta.count}
              />
            ))}
          </div>
        </Box>
      )}
      {!!hiddenDeltas.length && (
        <DropdownMenu
          placement="bottom end"
          className={cx(styles.medium, styles.flexible, styles.dropdownList)}
          triggerComponent={
            <Tooltip
              on={({ ref, ...tooltipProps }) => (
                <Button
                  {...tooltipProps}
                  ref={mergeRefs<HTMLButtonElement>(ref, mutlipleTagsDropdownRef)}
                  aria-label="Show more deltas"
                  className={cx(
                    styles.button,
                    !!containerSize && styles.active,
                    tooltipProps.className
                  )}
                  variant="secondary"
                  size="small"
                  endIcon={ChevronDown}
                  withTextEllipsis
                >
                  {moreTagsTooltip}
                </Button>
              )}
            >
              Show more deltas
            </Tooltip>
          }
        >
          {hiddenDeltas.map((delta) => (
            <DropdownMenuItem key={delta.type}>
              <DeltaCountsCell single {...deltaConfig[delta.type](delta.count)} />
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </Box>
  );
};

export default DeltaCountsResponsive;
