import TableBodyRow from "ds/components/Table/Row";
import TableCell from "ds/components/Table/Row/Cell";
import TableSkeletonItem from "ds/components/Table/Skeleton/Item";

import useBlueprintsTableStore from "../useBlueprintsTableStore";

const BlueprintsTableSkeletonRow = () => {
  const visibleColumns = useBlueprintsTableStore((state) => state.columnsOrder.visible);
  return (
    <TableBodyRow>
      {visibleColumns.map((column) => (
        <TableCell key={column} direction="column">
          <TableSkeletonItem />
        </TableCell>
      ))}
    </TableBodyRow>
  );
};

export default BlueprintsTableSkeletonRow;
