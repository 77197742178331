// TODO: Extract to a common hook?
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";

import { FILTER_TYPE, initialSortDirection, initialSortOption } from "../constants";
import useInitialDefaultView from "./useInitialDefaultView";

const useFiltersVariables = () => {
  const [searchParams] = useSearchParams();

  const defaultView = useInitialDefaultView(FILTER_TYPE);

  const defaultFilterVariables = useMemo(
    () => ({
      searchInput: defaultView.data?.search || "",
      sortOptionFields:
        defaultView.data?.sortOption && defaultView.data?.sortDirection
          ? [{ field: defaultView.data?.sortOption, direction: defaultView.data?.sortDirection }]
          : [{ field: initialSortOption, direction: initialSortDirection }],
      predicates: [...(defaultView.data?.predicates?.values() || [])],
    }),
    [defaultView.data]
  );

  const filterVariables = useMemo(
    () => ({
      searchInput: getSearchQuery(searchParams),
      sortOptionFields: getSortOptionFromURI(searchParams, initialSortOption, initialSortDirection),
      predicates: [...(getFiltersPredicationFromURI(searchParams)?.values() || [])],
    }),
    [searchParams]
  );

  if (!searchParams.size) {
    return { filterVariables: defaultFilterVariables, filterVariablesLoaded: defaultView.loaded };
  }

  return { filterVariables, filterVariablesLoaded: defaultView.loaded };
};

export default useFiltersVariables;
