import { useCallback, useMemo } from "react";

import { Blueprint } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { ApplyFiltersContext } from "components/Filters";
import { BlueprintSuggestions, searchBlueprintSuggestionsDictionary } from "constants/blueprint";

import { BLUEPRINTS_COLUMN_ID } from "../../constants";
import useBlueprintsAnalytics from "../../hooksNew/useBlueprintsAnalytics";

const ADD_TO_FILTERS = "Add to filters";

export const BLUEPRINT_COLUMNS_WITH_ACTIONS = [
  BLUEPRINTS_COLUMN_ID.STATUS,
  BLUEPRINTS_COLUMN_ID.SPACE,
] as const;

export type BlueprintColumnWithActions = (typeof BLUEPRINT_COLUMNS_WITH_ACTIONS)[number];

type BlueprintColumnActionsMeta = {
  title: string;
  action: () => void;
};

const useCellActionsConfig = (
  blueprint: Blueprint
): Record<BlueprintColumnWithActions, BlueprintColumnActionsMeta[] | undefined> => {
  const { applySpaceFilter, applyFilter } = useTypedContext(ApplyFiltersContext);
  const trackAnalytics = useBlueprintsAnalytics();

  const trackFilterClickedAnalytics = useCallback(
    (type: string) => {
      trackAnalytics("Add to filters clicked", { type });
    },
    [trackAnalytics]
  );

  const cellActionsConfig = useMemo(() => {
    return {
      [BLUEPRINTS_COLUMN_ID.STATUS]: [
        {
          title: ADD_TO_FILTERS,
          action: () => {
            applyFilter(
              BlueprintSuggestions.State,
              searchBlueprintSuggestionsDictionary
            )(blueprint.state);
            trackFilterClickedAnalytics(BlueprintSuggestions.State);
          },
        },
      ],
      [BLUEPRINTS_COLUMN_ID.SPACE]: [
        {
          title: ADD_TO_FILTERS,
          action: () => {
            applySpaceFilter(
              BlueprintSuggestions.Space,
              searchBlueprintSuggestionsDictionary
            )(blueprint.space.id);
            trackFilterClickedAnalytics(BlueprintSuggestions.Space);
          },
        },
      ],
    };
  }, [
    applyFilter,
    applySpaceFilter,
    blueprint.space.id,
    blueprint.state,
    trackFilterClickedAnalytics,
  ]);

  return cellActionsConfig;
};

export default useCellActionsConfig;
