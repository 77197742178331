import DocumentationButton from "components/DocumentationButton";
import { EmptystateDataFlowColored } from "components/icons/generated";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import { getDocsUrl } from "utils/getDocsUrl";

type IacManagementEmptyProps = {
  hasNoSearchResult?: boolean;
};

const IacManagementEmpty = ({ hasNoSearchResult }: IacManagementEmptyProps) => {
  return (
    <Box align="center" justify="center" grow="1">
      {hasNoSearchResult && (
        <EmptyState
          title="No results"
          icon={EmptystateDataFlowColored}
          caption={`Don’t give up! Check the spelling or try something less specific.`}
          announce
        />
      )}

      {!hasNoSearchResult && (
        <EmptyState title="You don’t have any resources yet" icon={EmptystateDataFlowColored}>
          <Box gap="medium">
            <DocumentationButton
              to={getDocsUrl("/concepts/resources#account-level-resources")}
              label="Documentation"
            />
          </Box>
        </EmptyState>
      )}
    </Box>
  );
};

export default IacManagementEmpty;
