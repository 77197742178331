import Link from "ds/components/Link";

import useStackListAnalytics from "../useStackListAnalytics";
import { CellComponentProps } from "./types";

type StackListItemSpaceCellProps = CellComponentProps;

const StackListItemSpaceCell = ({ stack }: StackListItemSpaceCellProps) => {
  const trackAnalytics = useStackListAnalytics();

  if (!stack.spaceDetails) {
    return null;
  }

  return (
    <Link
      size="small"
      href={`/spaces/${stack.spaceDetails.id}`}
      onPress={() => trackAnalytics("Space link clicked")}
    >
      {stack.spaceDetails.name}
    </Link>
  );
};

export default StackListItemSpaceCell;
