import { SortDescriptor } from "react-aria-components";

import { Role } from "types/generated";

import { ROLES_COLUMN_ID } from "../constants";

export const sortRoles = (items: Role[], sortDescriptor: SortDescriptor) => {
  if (!sortDescriptor.column) {
    return items;
  }

  const isAscending = sortDescriptor.direction === "ascending";

  const systemRoles: Role[] = [];
  const customRoles: Role[] = [];

  [...items].forEach((item) => {
    if (item.isSystem) {
      systemRoles.push(item);
    } else {
      customRoles.push(item);
    }
  });

  return [
    ...systemRoles,
    ...customRoles.sort((a, b) => {
      let valueA: string | undefined;
      let valueB: string | undefined;

      switch (sortDescriptor.column as ROLES_COLUMN_ID) {
        case ROLES_COLUMN_ID.NAME:
          valueA = a.name;
          valueB = b.name;
          break;
        case ROLES_COLUMN_ID.DESCRIPTION:
          valueA = "";
          valueB = "";
          break;
        case ROLES_COLUMN_ID.DROPDOWN:
          valueA = "";
          valueB = "";
          break;
      }

      valueA = valueA || "";
      valueB = valueB || "";

      return isAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }),
  ];
};
