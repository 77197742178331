import { useModal, create, show } from "@ebay/nice-modal-react";
import { FormProvider, useForm } from "react-hook-form";

import DrawerForm from "ds/components/DrawerNew/Form";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { getDocsUrl } from "utils/getDocsUrl";
import Input from "ds/components/Input";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import Button from "ds/components/Button";
import { getDrawerFormFix } from "utils/css";
import useUpdateUser from "views/Account/Settings/Users/DetailsDrawer/useUpdateUser";

import { UserViewDetails } from "../../types";

type FormValues = {
  id: string;
};

type EditSlackIntegrationProps = {
  id: string;
  user: UserViewDetails;
};

const EditSlackIntegrationDrawer = create(function EditSlackIntegration({
  id,
  user,
}: EditSlackIntegrationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const builderForm = useForm<FormValues>({
    defaultValues: {
      id,
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = builderForm;

  const modal = useModal();

  const { onUpdate, loading } = useUpdateUser(user.id);

  const onSubmit = (formData: FormValues) => {
    onUpdate({ slackMemberID: formData.id, accessRules: user.accessRules, id: user.id })
      .then(() => {
        reportSuccess({ message: "Slack integration successfully updated" });
        modal.hide();
      })
      .catch(onError);
    modal.hide();
  };

  return (
    <DrawerForm isDirty={isDirty}>
      <FormProvider {...builderForm}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Edit integration" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight gap="x-large">
            <FormField
              label="Slack member ID"
              error={errors?.id?.message}
              helperText="Give this user permissions while using third-party applications."
              isOptional
              tooltipMode="parent"
              tooltipInfoVariant="modal"
              tooltipInfo={
                <>
                  <TooltipModalTitle>Slack member ID</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    Id of Slack account that belongs to this user
                    <ReadMoreDocsLink
                      docsUrl={getDocsUrl("/concepts/user-management/admin#slack-integration")}
                    />
                  </TooltipModalBody>
                </>
              }
            >
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter member ID"
                  error={!!errors?.id}
                  {...register("id")}
                  {...ariaInputProps}
                />
              )}
            </FormField>
            <DrawerFooter>
              <DrawerFooterActions>
                <DrawerCancelButton />
                <Button variant="primary" type="submit" disabled={loading} loading={loading}>
                  Save
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </form>
      </FormProvider>
    </DrawerForm>
  );
});

export const showEditSlackIntegration = (props: EditSlackIntegrationProps) =>
  show(EditSlackIntegrationDrawer, props);
