import { captureEvent, getSiblingRow, handleFocus } from "./utils";

const getVerticalCellSibling = (
  currentCell: Element,
  direction: "prev" | "next"
): Element | null => {
  const currentRow = currentCell.parentElement;
  if (!currentRow) return null;

  const currentIndex = Array.from(currentRow.children).indexOf(currentCell);
  const siblingRow = getSiblingRow(currentRow, direction);
  if (!siblingRow) return null;

  return siblingRow.children[currentIndex] as Element;
};

const handleCellFocus = (cell?: Element | null) => {
  if (!cell) {
    return;
  }
  handleFocus(cell);

  cell.scrollIntoView({
    behavior: "instant",
    inline: "center",
    block: "center",
  });
};

const useCellKeyboardNavigation = () => {
  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowLeft") {
      captureEvent(e);

      if (e.currentTarget.previousElementSibling) {
        // go to previous cell
        handleCellFocus(e.currentTarget.previousElementSibling);
        return;
      }

      // go to row
      handleCellFocus(e.currentTarget.parentElement);
      return;
    }

    if (e.key === "ArrowRight") {
      captureEvent(e);

      if (e.currentTarget.nextElementSibling) {
        // go to next cell
        handleCellFocus(e.currentTarget.nextElementSibling);
        return;
      }

      if (e.currentTarget.parentElement?.nextElementSibling) {
        // go back to the first cell
        handleCellFocus(e.currentTarget.parentElement.firstElementChild);
      }
    }

    if (e.key === "ArrowUp") {
      captureEvent(e);

      handleCellFocus(getVerticalCellSibling(e.currentTarget, "prev"));
      return;
    }

    if (e.key === "ArrowDown") {
      captureEvent(e);

      handleCellFocus(getVerticalCellSibling(e.currentTarget, "next"));
      return;
    }
  };

  return { onKeyDown, tabIndex: -1 };
};

export default useCellKeyboardNavigation;
