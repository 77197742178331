import { gql } from "@apollo/client";

export const TRIGGER_PHASE_SUMMARIZATION = gql`
  mutation TriggerPhaseSummarization($stack: ID!, $run: ID!, $stateVersion: Int!) {
    summarizeRunState(stack: $stack, run: $run, stateVersion: $stateVersion) {
      summary
      processingStatus
    }
  }
`;

export const GET_PHASE_SUMMARIZATION = gql`
  query GetPhaseSummarization($stack: ID!, $run: ID!) {
    stack(id: $stack) {
      run(id: $run) {
        id
        history {
          stateVersion
          summary {
            processingStatus
            createdAt
            summary
          }
        }
      }
    }
  }
`;

export const TRIGGER_RUN_SUMMARIZATION = gql`
  mutation TriggerRunSummarization($stack: ID!, $run: ID!) {
    summarizeRun(stack: $stack, run: $run) {
      summary
      processingStatus
    }
  }
`;

export const GET_RUN_SUMMARIZATION = gql`
  query GetRunSummarization($stack: ID!, $run: ID!) {
    stack(id: $stack) {
      run(id: $run) {
        id
        summary {
          createdAt
          summary
          processingStatus
        }
      }
    }
  }
`;
