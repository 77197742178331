import Box from "ds/components/Box";
import FormField, { FormFieldProps } from "ds/components/Form/Field";
import Icon from "ds/components/Icon";
import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { IconComponent } from "types/Icon";

type FormFieldViewLinkProps = {
  value: string;
  link: string;
  icon?: IconComponent;
} & Pick<FormFieldProps, "label" | "tooltipInfo" | "tooltipInfoVariant" | "noMargin">;

const FormFieldViewLink = ({
  value,
  link,
  icon,
  label,
  tooltipInfo,
  tooltipInfoVariant,
  noMargin,
}: FormFieldViewLinkProps) => {
  return (
    <FormField
      label={label}
      tooltipInfo={tooltipInfo}
      tooltipInfoVariant={tooltipInfoVariant}
      noMargin={noMargin}
    >
      <TextEllipsis tooltip={value} tooltipWidthMode="maxWidthSm">
        {(props) => (
          <Link href={link}>
            <Box gap="small" align="center">
              {icon && <Icon src={icon} noShrink />}
              <Typography {...props} variant="p-body2" tag="span">
                {value}
              </Typography>
            </Box>
          </Link>
        )}
      </TextEllipsis>
    </FormField>
  );
};

export default FormFieldViewLink;
