import { useModal } from "@ebay/nice-modal-react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import HeadersField from "components/Forms/HeadersField";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import SecretInput from "ds/components/SecretInput";
import Toggle from "ds/components/Toggle";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageAuditTrail } from "hooks/useAnalytics/pages/auditTrail";
import { getDrawerFormFix } from "utils/css";
import { validateRequiredURL } from "utils/formValidators";

import parseAuditTrailFormFields from "./parseAuditTrailFormFields";
import { AuditTrailFormFields, AuditTrailFormProps } from "./types";
import { useSubmitAuditTrailWebhook } from "./useSubmitAuditTrailWebhook";

const EditDrawer = createDrawer(({ integration }: AuditTrailFormProps) => {
  const {
    enabled = true,
    endpoint,
    secret,
    customHeaders,
    includeRuns = false,
  } = integration || {};
  const isEdit = typeof endpoint !== "undefined";
  const defaultValues = {
    endpoint: endpoint || "",
    secret: secret || "",
    enabled: enabled,
    includeRuns: includeRuns,
    headers: [],
    enabledHeaders: (customHeaders?.length || 0) > 0,
  };

  const form = useForm<AuditTrailFormFields>({ defaultValues, mode: "onChange" });

  const { control, register, handleSubmit, formState } = form;

  const drawer = useModal();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageAuditTrail.Configuration,
  });

  const handleCancel = () => {
    trackSegmentAnalyticsEvent(typeof endpoint === "undefined" ? "Cancel" : "Edit Cancel");
  };

  const { onSubmitAuditTrail, loading } = useSubmitAuditTrailWebhook({
    isEdit,
    trackSegmentAnalyticsEvent,
    onFormClose: drawer.hide,
  });

  const onSubmit = (formFields: AuditTrailFormFields) => {
    const inputs = parseAuditTrailFormFields({
      formFields,
      customHeaderKeys: customHeaders || [],
      isEdit,
    });

    onSubmitAuditTrail(inputs);
  };

  const isDisabledSubmit = !formState.isDirty || loading;

  return (
    <DrawerForm isDirty={formState.isDirty} onClose={handleCancel}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title={isEdit ? "Edit audit trail" : "Set up audit trail"} />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody gap="x-large" hasDivider>
            <FormField
              noMargin
              label="Endpoint"
              tooltipInfo="Full endpoint to send a POST request to"
              error={formState.errors.endpoint?.message}
            >
              {({ ariaInputProps }) => (
                <Input
                  error={!!formState.errors.endpoint}
                  placeholder="Enter the endpoint here"
                  {...register("endpoint", { validate: validateRequiredURL() })}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            {!isEdit && (
              <FormField noMargin label="Secret" tooltipInfo="Secret to verify payload" isOptional>
                {({ ariaInputProps }) => (
                  <SecretInput
                    placeholder="Enter the secret here"
                    {...register("secret")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            )}

            <Controller
              name="enabled"
              control={control}
              render={({ field }) => (
                <Toggle variant="switch" isSelected={field.value} onChange={field.onChange}>
                  Enable
                </Toggle>
              )}
            />
          </DrawerBody>
          <DrawerBody gap="x-large" hasDivider>
            <Box gap="small" direction="column">
              <Typography variant="p-t5" tag="p">
                Include runs
              </Typography>
              <Typography variant="p-body2" tag="p" color="secondary">
                Controls whether run state change events will be sent to the audit webhook in
                addition to standard audit events.
              </Typography>
            </Box>
            <Controller
              name="includeRuns"
              control={control}
              render={({ field }) => (
                <Toggle variant="switch" isSelected={field.value} onChange={field.onChange}>
                  Include
                </Toggle>
              )}
            />
          </DrawerBody>
          <DrawerBody gap="x-large">
            <HeadersField isEditMode previousHeaderKeys={customHeaders} />
          </DrawerBody>
          <DrawerFooter>
            <DrawerFooterActions>
              <DrawerCancelButton />
              <Button variant="primary" type="submit" disabled={isDisabledSubmit} loading={loading}>
                Save
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerForm>
  );
});

export const showEditDrawer = createDrawerTrigger(EditDrawer);
