import { SpaceAccessLevel } from "types/generated";
import { Book, Edit, Glasses } from "components/icons/generated";

export const getOptionIconSrc = (value: SpaceAccessLevel) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (value) {
    case SpaceAccessLevel.Admin:
      return Glasses;
    case SpaceAccessLevel.Write:
      return Edit;
    case SpaceAccessLevel.Read:
      return Book;
    default:
      return undefined;
  }
};
