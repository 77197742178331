import DocumentationIconButton from "components/DocumentationIconButton";
import { Incognito, Space } from "components/icons/generated";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import TabWithCounter from "ds/components/Tab/WithCounter";
import { WorkerPool } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";
import { hasSpaceManageAccess } from "utils/user";
import TimestampMetaInfo from "components/Timestamp/MetaInfo";

import { NO_PRIVATE_WORKERS_AVAILABLE_MESSAGE } from "../WorkerPool/constants";
import { showWorkerPoolFormDrawer } from "../WorkerPools/CreateWorkerPool";
import PrivateWorkerPoolActionsDropdown from "./ActionsDropdown";
import { showPrivateWorkerPoolDetailsDrawer } from "./DetailsDrawer";

type PrivateWorkerPoolHeaderProps = {
  workerPool: WorkerPool;
  refetchQuery?: string;
};

const PrivateWorkerPoolHeader = ({ workerPool, refetchQuery }: PrivateWorkerPoolHeaderProps) => {
  const canManageWorkerPools = hasSpaceManageAccess(workerPool.spaceDetails.accessLevel);

  const showDetailsDrawer = () => {
    showPrivateWorkerPoolDetailsDrawer({
      workerPool,
    });
  };

  const showEditDrawer = () => {
    showWorkerPoolFormDrawer({ workerPool, refetchQuery });
  };

  const hasUsers = workerPool.usersCount > 0;
  const hasBusyWorkers = workerPool.busyWorkers > 0;

  const workerPoolUrl = `/worker-pool/${workerPool.id}`;
  const runsCount = workerPool.schedulableRunsCount;
  const hasWarning = workerPool.workersCount === 0 && runsCount > 0;

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{workerPool.name}</ViewHeaderTitle>
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <DocumentationIconButton
              href={getDocsUrl("/concepts/worker-pools")}
              tooltipText="Go to worker pools documentation"
            />

            <Button variant="secondary" onPress={showDetailsDrawer}>
              Details
            </Button>

            <PrivateWorkerPoolActionsDropdown
              workerPoolId={workerPool.id}
              workerPoolName={workerPool.name}
              canManageWorkerPools={canManageWorkerPools}
              hasUsers={hasUsers}
              hasBusyWorkers={hasBusyWorkers}
              onEdit={showEditDrawer}
            />
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={Space}
              linkText={workerPool.spaceDetails.name}
              href={`/spaces/${workerPool.spaceDetails.id}`}
            />
            <MetaInfoListItem icon={Incognito}>Private</MetaInfoListItem>
            <TimestampMetaInfo timestamp={workerPool.updatedAt} />
          </MetaInfoList>
        </ViewHeaderScrollCollapse>

        <ViewHeaderNavigation>
          <TabWithCounter
            end
            to={workerPoolUrl}
            state={workerPool}
            count={workerPool.workersCount}
            label="Workers"
          />

          <TabWithCounter
            to={`${workerPoolUrl}/queue`}
            state={workerPool}
            count={runsCount}
            counterExceptionVariant="danger"
            tooltip={hasWarning ? NO_PRIVATE_WORKERS_AVAILABLE_MESSAGE : undefined}
            label="Queue"
          />

          <TabWithCounter
            to={`${workerPoolUrl}/used-by`}
            state={workerPool}
            count={workerPool.usersCount}
            label="Used by"
          />
        </ViewHeaderNavigation>
      </ViewHeader>
    </>
  );
};

export default PrivateWorkerPoolHeader;
