import { useModal, create, show } from "@ebay/nice-modal-react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import useRoleDelete from "shared/Role/useDeleteRole";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import { pluralize } from "shared/Pluralize";

type DeleteConfirmationProps = {
  id: string;
  name: string;
  roleBindingsCount: number;
  successCallback?: () => void;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  name,
  roleBindingsCount,
  successCallback,
}: DeleteConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteView, { loading, data }] = useRoleDelete({ refetchQueries: ["GetRoles"] });

  const onDelete = () => {
    deleteView({
      id,
    })
      .then(() => {
        reportSuccess({ message: `Role ${name} successfully deleted` });
        modal.resolve();
        modal.hide();
        successCallback?.();
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete role"
      onConfirm={onDelete}
      isLoading={loading}
      isDismissable={!loading && !data?.roleDelete?.id}
    >
      <ConfirmationModalMessage>
        {roleBindingsCount > 0 && (
          <>
            By deleting <strong>{name}</strong> you will detach it from{" "}
            <strong>
              {roleBindingsCount} {pluralize("user", roleBindingsCount)}.
            </strong>{" "}
          </>
        )}
        Are you sure you want to remove <strong>{name}</strong>?
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
