import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  closestCenter,
  CollisionDetection,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
} from "@dnd-kit/core";
import { isEqual } from "lodash-es";

import { reorderMultipleDNDLists } from "utils/dnd";
import usePrevious from "hooks/usePrevious";

import { Column, Options, Side } from "./types";
import { sliceHiddenItems } from "./utils";

export const CONTAINERS = ["left", "right"];

type UseTwoColumnDND<T extends string> = {
  onChange: (props: { left: Column<T>; right: Column<T> }) => void;
  options: Options<T>;
  leftColumn: Column<T>;
  rightColumn: Column<T>;
};

const useTwoColumnDND = <T extends string>({
  onChange,
  leftColumn,
  rightColumn,
  options,
}: UseTwoColumnDND<T>) => {
  const lastOverId = useRef<T | null>(null);
  const [activeId, setActiveId] = useState<null | T>(null);

  const recentlyMovedToNewContainer = useRef(false);

  const hideItem = useCallback(
    (side: Side, id: T) => {
      const result = { left: leftColumn, right: rightColumn };
      if (side === "left") {
        result.left = result.left.map((item) =>
          item.value === id ? { ...item, hidden: true } : item
        );
      } else {
        result.right = result.right.map((item) =>
          item.value === id ? { ...item, hidden: true } : item
        );
      }

      onChange(result);
    },
    [leftColumn, rightColumn, onChange]
  );

  const { visible: leftVisible, hidden: leftHidden } = sliceHiddenItems(leftColumn, options);
  const { visible: rightVisible, hidden: rightHidden } = sliceHiddenItems(rightColumn, options);

  const containersInitial: Record<string, T[]> = useMemo(
    () => ({ left: leftVisible.map((v) => v.value), right: rightVisible.map((v) => v.value) }),
    [leftVisible, rightVisible]
  );

  const [containersState, setContainersState] = useState(containersInitial);
  const previousInitialState = usePrevious(containersInitial);
  useEffect(() => {
    if (
      !isEqual(previousInitialState, containersInitial) &&
      !isEqual(containersState, containersInitial) &&
      !activeId
    ) {
      setContainersState(containersInitial);
    }
  }, [containersInitial, containersState, activeId, previousInitialState]);

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [containersState]);

  const isSortingContainer = activeId != null ? activeId in containersState : false;

  const handleDragEnd = (event: DragEndEvent) => {
    setActiveId(null);

    if (!event.over) {
      return;
    }

    const oldItems = {
      left: [...leftVisible, ...leftHidden],
      right: [...rightVisible, ...rightHidden],
    };

    const old: Record<Side, T[]> = {
      left: leftVisible.map(({ value }) => value),
      right: rightVisible.map(({ value }) => value),
    };

    const sourceContainer = Object.keys(old).find((key) =>
      old[key as Side].includes(event.active.id as T)
    );
    const targetContainer = findContainer(event.active.id as T);

    if (!sourceContainer || !targetContainer) {
      return;
    }

    const sourceIndex = old[sourceContainer as Side].indexOf(event.active.id as T);
    const targetIndex = containersState[targetContainer].indexOf(event.over.id as T);
    const { left, right } = reorderMultipleDNDLists(
      oldItems,
      sourceContainer,
      targetContainer,
      sourceIndex,
      targetIndex
    );

    setContainersState({
      left: left.reduce((acc, next) => (next.hidden ? acc : [...acc, next.value]), [] as T[]),
      right: right.reduce((acc, next) => (next.hidden ? acc : [...acc, next.value]), [] as T[]),
    });
    onChange({ left, right });
  };

  const handleDragCancel = () => {
    setContainersState(containersInitial);
  };

  const items = useMemo(
    () => [...leftVisible, ...rightVisible].map((v) => v.value),
    [leftVisible, rightVisible]
  );

  const findContainer = useCallback(
    (id: T) => {
      return Object.keys(containersState).find((key) => containersState[key].includes(id));
    },
    [containersState]
  );

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in containersState) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId != null) {
        if (overId in containersState) {
          const containerItems = containersState[overId as T];

          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) => container.id !== overId && containerItems.includes(container.id as T)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId as T;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items, containersState]
  );

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      const overId = over?.id;

      if (overId == null || active.id in items) {
        return;
      }

      const overContainer = CONTAINERS.includes(overId as string)
        ? overId
        : findContainer(overId as T);
      const activeContainer = findContainer(active.id as T);

      if (!overContainer || !activeContainer) {
        return;
      }

      if (activeContainer !== overContainer) {
        setContainersState((containers) => {
          const activeItems = containers[activeContainer];
          const overItems = containers[overContainer];
          const overIndex = overItems.indexOf(overId as T);
          const activeIndex = activeItems.indexOf(active.id as T);

          let newIndex: number;

          if (overId in items) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          recentlyMovedToNewContainer.current = true;

          return {
            ...containers,
            [activeContainer]: containers[activeContainer].filter((item) => item !== active.id),
            [overContainer]: [
              ...containers[overContainer].slice(0, newIndex),
              containers[activeContainer][activeIndex],
              ...containers[overContainer].slice(newIndex, containers[overContainer].length),
            ],
          };
        });
      }
    },
    [findContainer, items]
  );

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    setActiveId(active.id as T);
  }, []);

  return {
    containersState,
    hideItem,
    handleDragStart,
    handleDragOver,
    handleDragCancel,
    handleDragEnd,
    collisionDetectionStrategy,
    isSortingContainer,
    activeId,
    isEmpty: !containersState["left"].length && !containersState["right"].length,
  };
};

export default useTwoColumnDND;
