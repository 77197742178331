import Table from "ds/components/Table";
import { Blueprint } from "types/generated";
import { SortStateDescriptor } from "components/Filters/types";
import BlueprintsTableWrapper from "ds/components/Table/Wrapper";

import BlueprintsTableHead from "./Head";
import BlueprintsTableBody from "./Body";
import BlueprintsTableSkeleton from "./Skeleton";

type BlueprintsTableProps = {
  blueprints: Array<Blueprint>;
  loadingContent?: boolean;
  rowsPerPage: number;
  sortCallback: (sortDescriptor: SortStateDescriptor) => void;
};

const BlueprintsTable = ({
  blueprints,
  loadingContent,
  rowsPerPage,
  sortCallback,
}: BlueprintsTableProps) => {
  return (
    <BlueprintsTableWrapper>
      <Table ariaLabel="Blueprints table">
        <BlueprintsTableHead sortCallback={sortCallback} />
        {loadingContent && <BlueprintsTableSkeleton rowsPerPage={rowsPerPage} />}
        <BlueprintsTableBody blueprints={blueprints} />
      </Table>
    </BlueprintsTableWrapper>
  );
};

export default BlueprintsTable;
