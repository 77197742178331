// calculates virtualization range to keep sticky groups on the visible area
export const calculateStickyRanges = (
  visibleRange: [number, number],
  defaultRange: number[],
  stickyIndexes: [number, number][]
) => {
  const stickyGroups = new Map<number, number>();
  // the only one sticky group could be outside the virtual range
  let stickyGroupOutsideVirtualRange: number | undefined = undefined;

  if (!stickyIndexes.length) {
    return {
      stickyGroups,
      stickyGroupOutsideVirtualRange,
      newRange: defaultRange,
    };
  }

  for (const [folderIndex, lastChild] of stickyIndexes) {
    const intersectionStart = Math.max(folderIndex, visibleRange[0]);
    const intersectionEnd = Math.min(lastChild, visibleRange[1]);

    // find all possible intersections
    if (intersectionStart <= intersectionEnd) {
      stickyGroups.set(folderIndex, lastChild);

      // find sticky groups that are outside the top of virtual range
      if (folderIndex < defaultRange[0]) {
        stickyGroupOutsideVirtualRange = folderIndex;
      }
    }
  }

  return {
    stickyGroups,
    stickyGroupOutsideVirtualRange,
    newRange: [...new Set([...stickyGroups.keys(), ...defaultRange])].sort((a, b) => a - b),
  };
};

// calculate before & after padding to keep the right scroll position
export const calculateVirtualPadding = (
  virtualizedItems: { start: number; size: number; end: number }[],
  totalSize: number,
  scrollMargin = 0,
  outsideElementsCount = 0
) => {
  if (!virtualizedItems.length) {
    return [0, 0];
  }

  // calculate space before first visible item
  const before =
    virtualizedItems[outsideElementsCount ? 1 : 0].start -
    (scrollMargin + (outsideElementsCount ? virtualizedItems[0]?.size : 0));

  // calculate space after last visible item
  const after = totalSize - virtualizedItems[virtualizedItems.length - 1].end;

  return [Math.max(0, before), Math.max(0, after)];
};
