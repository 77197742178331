import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import { BillingInfo } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { stringIsRequired, validateEmail } from "utils/formValidators";

import BillingAddressFormFields from "../AddressFormFields";
import { InvoicingDetailsFields } from "../types";
import { UPDATE_BILLING_INFO } from "./gql";

type UpdateInvoicingDetailsDrawerProps = {
  billingInfo: BillingInfo;
};

const UpdateInvoicingDetailsDrawer = createDrawer(
  ({ billingInfo }: UpdateInvoicingDetailsDrawerProps) => {
    const drawer = useModal();
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const form = useForm<InvoicingDetailsFields>({
      defaultValues: billingInfo,
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isDirty },
      getValues,
    } = form;

    const [updateBillingInfo, { loading }] = useMutation(UPDATE_BILLING_INFO, {
      refetchQueries: ["GetBilling"],
      awaitRefetchQueries: true,
    });

    useEffect(() => {
      if (billingInfo) {
        const currentValues = getValues();

        // TODO: update when react-hook-form upgraded
        reset({
          ...currentValues,
          ...billingInfo,
        });
      }
    }, [billingInfo, getValues, reset]);

    const onSubmit = (formData: InvoicingDetailsFields) => {
      updateBillingInfo({
        variables: {
          input: formData,
        },
      })
        .then(() => {
          reportSuccess({
            message: `Invoicing details were successfully updated`,
          });
          drawer.hide();
        })
        .catch(onError);
    };

    return (
      <DrawerForm isDirty={isDirty}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Invoicing details" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody gap="x-large" fullHeight>
              <FormField label="Full name" error={errors?.fullName?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="e.g. John Smith"
                    error={!!errors?.fullName}
                    {...register("fullName", {
                      validate: stringIsRequired("Full name is required."),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormField
                label="Email"
                error={errors?.email?.message}
                noMargin
                tooltipInfoVariant="modal"
              >
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Email"
                    error={!!errors?.email}
                    {...register("email", {
                      required: "Email is required.",
                      validate: validateEmail,
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <BillingAddressFormFields />

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button variant="primary" type="submit" loading={loading} disabled={loading}>
                    Update
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showUpdateInvoicingDetailsDrawer = createDrawerTrigger(UpdateInvoicingDetailsDrawer);
