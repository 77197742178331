import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";

export const COLUMN_ORDER = "12rem minmax(4rem, 6fr) minmax(4rem, 1fr)";

export const COLUMN_GAP: Spacing = "large";

export const FILTERS_ORDER_SETTINGS_KEY = "workerPoolUsedByFiltersOrder";

export enum FilterItemKeys {
  TYPE = "isModule",
  NAME = "name",
  SPACE = "space",
}

export const FILTER_ITEMS_DICTIONARY: Partial<Record<FilterItemKeys, string>> = {
  [FilterItemKeys.TYPE]: "type",
};

export const INITIAL_SORT_OPTION = FilterItemKeys.NAME;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Desc;

export const ITEMS_LIMIT = 50;
