import { Outlet } from "react-router-dom";

import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import SubSidebar from "components/SubSidebar";
import SubSidebarSection from "components/SubSidebar/Section";
import SubSidebarSectionItem from "components/SubSidebar/SectionItem";
import SubSidebarSectionTitle from "components/SubSidebar/SectionTitle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import Callout from "ds/components/Callout";
import Link from "ds/components/Link";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";

import { FILTERS_ORDER_SETTINGS_KEY } from "../../../Modules/constants";
import { ModuleSettingsContext } from "../../Context";
import styles from "./styles.module.css";

const MODULE_SETTINGS_INFO_KEY = "moduleSettingsInfoCallout";

const ModuleSettingsRouteLayout = () => {
  const { module, canManageModule } = useTypedContext(ModuleSettingsContext);

  useTitle(module?.name ? `Settings · ${module.name}` : "Settings");

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: getBreadcrumbsBackUrl("/modules", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: module?.name || "",
        link: `/module/${module?.id}`,
      },
      {
        title: "Module settings",
      },
    ],
    [module?.name, module?.id]
  );

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper>
          <ViewHeaderTitle titleSize="p-t3">Module settings</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeader>
      <Callout variant="warning" className={styles.banner} storageKey={MODULE_SETTINGS_INFO_KEY}>
        All your attached{" "}
        <Link href={`/module/${module.id}/policies`} size="small">
          policies
        </Link>{" "}
        and{" "}
        <Link href={`/module/${module.id}/contexts`} size="small">
          contexts
        </Link>{" "}
        are now placed in dedicated tabs in the Module navigation.
      </Callout>
      <Box grow="1">
        <SubSidebar>
          <SubSidebarSection>
            <SubSidebarSectionItem to=".." end>
              Module details
            </SubSidebarSectionItem>
            <SubSidebarSectionItem to="../source-code">Source code</SubSidebarSectionItem>
            <SubSidebarSectionItem to="../behavior">Behavior</SubSidebarSectionItem>
            <SubSidebarSectionItem to="../integrations">Integrations</SubSidebarSectionItem>
            <SubSidebarSectionItem to="../sharing">Sharing</SubSidebarSectionItem>
          </SubSidebarSection>

          {canManageModule && (
            <SubSidebarSection>
              <SubSidebarSectionTitle>Danger area</SubSidebarSectionTitle>
              <SubSidebarSectionItem to="../deletion">Module deletion</SubSidebarSectionItem>
            </SubSidebarSection>
          )}
        </SubSidebar>

        <Box direction="column" className={styles.wrapper}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default ModuleSettingsRouteLayout;
