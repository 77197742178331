import { useCallback } from "react";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import CollapsibleSectionPanel from "components/CollapsibleSectionPanel";
import ContextAttachment from "components/ContextAttachment";
import NotFoundPage from "components/error/NotFoundPage";
import { EmptystateLinkColored, EmptystateMagnetColored } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import PageInfo from "components/PageWrapper/Info";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import useAttachContext from "shared/Context/useAttachContext";
import useDetachContext from "shared/Context/useDetachContext";
import useGetAttachedContexts from "shared/Context/useGetAttachedContexts";
import { SpacesContext } from "views/Account/SpacesProvider";

import StackHeader from "../components/Header";
import { StackContext } from "../Context";
import { getStacksBackUrl } from "../helpers";
import { showAttachContextDrawer } from "./components/AttachContextDrawer";

const StackContexts = () => {
  const { stack } = useTypedContext(StackContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  const {
    manuallyAttachedContexts,
    autoAttachedContexts,
    manuallyAttachedContextIds,
    isPageLoading,
    isPageNotFound,
    error,
  } = useGetAttachedContexts({
    entityId: stack.id,
    entityType: "stack",
  });

  useTitle(`Contexts · ${stack.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  const { attachContext } = useAttachContext(
    ["GetStackAttachedContexts"],
    "Context priority successfully changed"
  );

  const { detachContext } = useDetachContext(["GetStackAttachedContexts"]);

  const handleDetachContext = useCallback(
    (id: string) => {
      detachContext(id);
    },
    [detachContext]
  );

  const handleChangePriority = useCallback(
    (id: string, priority: number) => {
      attachContext(id, stack.id, priority);
    },
    [attachContext, stack.id]
  );

  const handleOpenAttachContextDrawer = () => {
    showAttachContextDrawer({
      attachedContextIds: manuallyAttachedContextIds,
      stackId: stack.id,
      spaceId: stack.spaceDetails.id,
    });
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <>
      <StackHeader />
      <PageInfo title="Contexts">
        {hasEntityCreateAccess && (
          <Button variant="primary" onPress={handleOpenAttachContextDrawer}>
            Attach context
          </Button>
        )}
      </PageInfo>
      <Box gap="x-large" direction="column">
        <CollapsibleSectionPanel
          title="Manually attached"
          ariaLevel={3}
          count={manuallyAttachedContexts.length}
          initialIsCollapsed={false}
        >
          {manuallyAttachedContexts.map((context) => (
            <ContextAttachment
              key={context.id}
              context={context}
              onPriorityChange={handleChangePriority}
              onDetach={handleDetachContext}
            />
          ))}

          {manuallyAttachedContexts.length === 0 && (
            <EmptyState
              padding="0 0 medium"
              icon={EmptystateLinkColored}
              title="No contexts attached yet"
              caption={
                <>
                  This stack does not have any contexts attached yet.
                  <br />
                  Use attach context button to attach contexts to the stack.
                </>
              }
            />
          )}
        </CollapsibleSectionPanel>

        <CollapsibleSectionPanel
          ariaLevel={3}
          title="Auto-attached"
          count={autoAttachedContexts.length}
          initialIsCollapsed={false}
        >
          {autoAttachedContexts.map((context) => (
            <ContextAttachment key={context.id} context={context} stackLabels={stack.labels} />
          ))}

          {autoAttachedContexts.length === 0 && (
            <EmptyState
              padding="0 0 medium"
              icon={EmptystateMagnetColored}
              title="No contexts auto-attached yet"
              caption={
                <>
                  This is where auto-attached contexts will appear.
                  <br />
                  Use autoattach label to attach context to many stacks at the same time.
                </>
              }
            />
          )}
        </CollapsibleSectionPanel>
      </Box>
    </>
  );
};

export default StackContexts;
