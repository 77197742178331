import { ShouldCloseOnLocationChangeProps } from "ds/components/DrawerNew/utils";
import { EntityVendor } from "types/generated";

import { RESOURCE_DRAWER_TARGET_WHITE_LIST } from "../constants";

export const shouldCloseOnLocationChange =
  (parentStackId?: string) =>
  ({ current }: ShouldCloseOnLocationChangeProps) => {
    return parentStackId
      ? !current.pathname.includes(`/stack/${parentStackId}/resource`)
      : !current.pathname.includes("/iac-management/resource");
  };

export const shouldCloseOnInteractOutside = (el: Element) => {
  const dataTreeInteractiveElements = document.querySelectorAll(
    `[${RESOURCE_DRAWER_TARGET_WHITE_LIST}]`
  );

  if (Array.from(dataTreeInteractiveElements).find((parent) => parent.contains(el))) {
    return false;
  }

  return true;
};

const omitTypename = (key: string, value: string) => {
  return key === "__typename" ? undefined : value;
};

const decodeJSONStrings = (vendorConfig: EntityVendor) => {
  const vendorClone = JSON.parse(JSON.stringify(vendorConfig, omitTypename));

  if (vendorClone["terraform"]) {
    if (
      vendorClone["terraform"]["values"] &&
      typeof vendorClone["terraform"]["values"] === "string"
    ) {
      vendorClone["terraform"]["values"] = JSON.parse(vendorClone["terraform"]["values"]);
    }
    if (
      vendorClone["terraform"]["value"] &&
      typeof vendorClone["terraform"]["value"] === "string"
    ) {
      vendorClone["terraform"]["value"] = JSON.parse(vendorClone["terraform"]["value"]);
    }
  }
  if (vendorClone["pulumi"]) {
    if (vendorClone["pulumi"]["outputs"] && typeof vendorClone["pulumi"]["outputs"] === "string") {
      vendorClone["pulumi"]["outputs"] = JSON.parse(vendorClone["pulumi"]["outputs"]);
    }
  }
  if (vendorClone["cloudFormation"]) {
    if (
      vendorClone["cloudFormation"]["template"] &&
      typeof vendorClone["cloudFormation"]["template"] === "string"
    ) {
      vendorClone["cloudFormation"]["template"] = JSON.parse(
        vendorClone["cloudFormation"]["template"]
      );
    }
  }
  if (vendorClone["kubernetes"]) {
    if (
      vendorClone["kubernetes"]["data"] &&
      typeof vendorClone["kubernetes"]["data"] === "string"
    ) {
      vendorClone["kubernetes"]["data"] = JSON.parse(vendorClone["kubernetes"]["data"]);
    }
  }

  return vendorClone;
};

export const makeVendorSpecificData = (vendor: EntityVendor | undefined) => {
  if (!vendor) {
    return undefined;
  }

  try {
    return JSON.stringify(Object.values(decodeJSONStrings(vendor))[0], null, 2);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const makeThirdPartyMetaData = (
  json: string | undefined
): { key: string; data: string }[] => {
  if (json) {
    try {
      const data = JSON.parse(json);

      return Object.keys(data).map((integration) => ({
        key: integration,
        data: JSON.stringify(data[integration], null, 2),
      }));
    } catch (e) {
      console.error(e);
    }
  }

  return [];
};
