import { TableColumnMeta } from "ds/components/Table/types";

export type IacTableGroupMeta = {
  isOpen: boolean;
  hasMore: boolean;
  endCursor?: string;
};

export type IacTableColumnsConfig = {
  visibleColumns: TableColumnMeta["id"][];
  hiddenColumns: TableColumnMeta["id"][];
  staticColumns: TableColumnMeta["id"][];
};

export type IacTableMetaSlice = {
  columnsState: Record<TableColumnMeta["id"], TableColumnMeta>;
  columnsOrder: {
    visible: TableColumnMeta["id"][];
    hidden: TableColumnMeta["id"][];
  };
  reorderVisibleColumns: (columnId: string, direction: "left" | "right") => void;
  columnsSizes: Record<string, number>;
  setColumnSize: (columnId: string, size: number) => void;
  hideColumn: (columnId: string) => void;
  updateColumnsConfig: (
    visibleColumns: TableColumnMeta["id"][],
    hiddenColumns: TableColumnMeta["id"][]
  ) => void;
  resetColumnsConfig: () => void;
  groupsMeta: Record<string, IacTableGroupMeta>;
  toggleGroupMeta: (groupId: string) => void;
  openGroup: (groupId: string) => void;
  updateGroupMeta: (groupId: string, meta: Partial<IacTableGroupMeta>) => void;
};

export enum IacTableServiceRowType {
  LoadMoreResources = "load-more-resources",
  LoadMoreGroups = "load-more-groups",
}

export type IacTableServiceRow = {
  id: string;
  serviceType: IacTableServiceRowType;
  groupId: string;
};
