import cx from "classnames";

import { ChevronRight } from "components/icons/generated";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import { showFullDescription } from "ds/components/FullDescriptionDrawer";
import TextEllipsis from "ds/components/TextEllipsis";
import useAnalytics from "hooks/useAnalytics";

import ListEntitiesItemDescriptionContent from "./Content";
import styles from "./styles.module.css";

type ListEntitiesItemDescriptionProps =
  | {
      description: string;
      tooltipMode?: false;
      className?: string;
      noPadding?: boolean;
      id?: never;
    }
  | {
      description: string;
      tooltipMode: true;
      className?: string;
      noPadding?: boolean;
      id?: never;
    }
  | {
      description: string;
      tooltipMode?: false;
      className?: string;
      noPadding?: boolean;

      id: string;
    };

const ListEntitiesItemDescription = ({
  description,
  tooltipMode,
  className,
  noPadding,
  id,
}: ListEntitiesItemDescriptionProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics();

  const handleShowFullDescription = () => {
    trackSegmentAnalyticsEvent("Open full description drawer");
    showFullDescription({
      description,
      id,
    });
  };

  return (
    <TextEllipsis tooltip={description} tooltipWidthMode="maxWidthXl" delay={400}>
      {(props, isActive) => (
        <Box
          direction="row"
          gap="medium"
          className={cx(styles.wrapper, noPadding && styles.noPadding)}
          align="center"
        >
          <ListEntitiesItemDescriptionContent
            {...props}
            description={description}
            className={cx(className, props.className)}
            color="primary"
          />

          {!tooltipMode && isActive && (
            <ButtonIcon variant="ghost" icon={ChevronRight} onPress={handleShowFullDescription}>
              Show full description
            </ButtonIcon>
          )}
        </Box>
      )}
    </TextEllipsis>
  );
};

export default ListEntitiesItemDescription;
