import { useEffect, useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import FormFieldSpace from "components/FormFields/Space";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ComboBox from "ds/components/ComboBox";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import AssignRoleDrawerComboBoxItem from "./ComboBoxItem";
import styles from "./styles.module.css";
import { USER_ROLE_BINDING_UPDATE } from "./gql";

type AssignRoleFormEditFields = {
  roleId: string;
  space: string;
};

type AssignRoleDrawerFormEditProps = {
  roleAssignmentId: string;
  roleId: string;
  spaceId: string;
  userId: string;
  availableRoles: Array<{ id: string; name: string }>;
  onEditCancel?: () => void;
  onEditSuccess?: () => void;
  setIsDirty: (id: string, isDirty: boolean) => void;
};

const AssignRoleDrawerFormEdit = ({
  roleAssignmentId,
  roleId,
  spaceId,
  userId,
  onEditCancel,
  onEditSuccess,
  availableRoles,
  setIsDirty,
}: AssignRoleDrawerFormEditProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const assignRoleForm = useForm<AssignRoleFormEditFields>({
    defaultValues: {
      roleId,
      space: spaceId,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = assignRoleForm;

  const [updateRoleBinding] = useMutation(USER_ROLE_BINDING_UPDATE, {
    refetchQueries: ["UserRoleBindingsWithRoles"],
    awaitRefetchQueries: true,
  });

  const onSubmit: SubmitHandler<AssignRoleFormEditFields> = (formData) => {
    updateRoleBinding({
      variables: {
        id: roleAssignmentId,
        input: {
          roleID: formData.roleId,
          spaceID: formData.space,
          userID: userId,
        },
      },
    })
      .then(() => {
        reportSuccess({ message: "Role assignment successfully updated" });
        setIsDirty(roleAssignmentId, false);
      })
      .catch(onError);

    reset();
    onEditSuccess?.();
  };

  const options = useMemo(
    () => availableRoles.map(({ id, name }) => ({ value: id, label: name })),
    [availableRoles]
  );

  // TODO consider add a not existing space option to the list of spaces in case editing an output reference with not existing output
  useEffect(() => {
    setIsDirty(roleAssignmentId, isDirty);
  }, [isDirty, setIsDirty, roleAssignmentId]);

  return (
    <FormProvider {...assignRoleForm}>
      <Box direction="column" padding="x-large" gap="x-large" className={styles.formWrapper}>
        <Controller
          name="roleId"
          control={control}
          rules={{ required: "Role field is required." }}
          render={({ field, fieldState }) => (
            <ComboBox
              items={options}
              label="Select role"
              error={fieldState.error?.message}
              value={field.value}
              onChange={field.onChange}
            >
              {(item) => <AssignRoleDrawerComboBoxItem id={item.value} {...item} />}
            </ComboBox>
          )}
        />
        <FormFieldSpace label="Space" noMargin />

        <Box direction="row" justify="end" gap="medium">
          <Button variant="secondary" size="small" onPress={onEditCancel}>
            Cancel
          </Button>

          <Button variant="contrast" size="small" onPress={() => handleSubmit(onSubmit)()}>
            Save
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default AssignRoleDrawerFormEdit;
