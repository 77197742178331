import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import FormSourceCode from "components/Forms/SourceCode";
import { SourceCodeProjects } from "components/Forms/SourceCode/types";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { VcsProvider } from "types/generated";
import useBlockNavigationModal from "hooks/useBlockNavigationModal";

import { ModuleSettingsContext } from "../Context";
import { useUpdateModule } from "../hooks/useUpdateModule";
import { getDefaultValues } from "./utils";

type FormValues = {
  namespace: string;
  repository: string;
  projectRoot: string;
  branch: string;
  provider: VcsProvider;
  vcsIntegrationId: string | undefined;
  repositoryURL: string;
};

const ModuleSettingsSourceCodeEditForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);
  const { updateModule, loading } = useUpdateModule({ module });

  const builderForm = useForm<FormValues>({
    defaultValues: getDefaultValues(module),
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isSubmitSuccessful },
    trigger,
  } = builderForm;

  useBlockNavigationModal({ isNavigationBlocked: isDirty });

  const onSubmit = useCallback(
    () =>
      handleSubmit((values: FormValues) => {
        return updateModule({
          ...values,
          projectRoot: values.projectRoot || null,
          repositoryURL: values.repositoryURL || null,
        });
      })(),
    [updateModule, handleSubmit]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getDefaultValues(module));
    }
  }, [isSubmitSuccessful, module, reset]);

  const resetFormFields = (type: VcsProvider) => {
    reset({
      repository: "",
      branch: "",
      projectRoot: "",
      repositoryURL: undefined,
      provider: type,
      vcsIntegrationId: undefined,
    });

    trigger();
  };

  const values = watch();

  return (
    <FormProvider {...builderForm}>
      <FormSourceCode
        spaceId={module.spaceDetails.id}
        provider={values.provider}
        vcsIntegrationId={values.vcsIntegrationId}
        repository={values.repository}
        resetFormFields={resetFormFields}
        projectType={SourceCodeProjects.Module}
      />
      <Box justify="end">
        <Button variant="primary" onPress={onSubmit} disabled={loading} loading={loading}>
          Save
        </Button>
      </Box>
    </FormProvider>
  );
};

export default ModuleSettingsSourceCodeEditForm;
