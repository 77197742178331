import ConfigElement from "components/config/view/ConfigElement";
import ConfigTimestamp from "components/config/view/ConfigTimestamp";
import Label from "components/label/Label";
import { COMPUTED_VALUE } from "constants/environment";
import Link from "ds/components/Link";
import useTypedContext from "hooks/useTypedContext";
import { ConfigElementWithSource } from "types/generated";
import { AccountContext } from "views/AccountWrapper";

type EnvironmentViewConfigMapProps = {
  runtimeConfig: ConfigElementWithSource[];
};

const EnvironmentViewConfigMap = ({ runtimeConfig }: EnvironmentViewConfigMapProps) => {
  const { viewer } = useTypedContext(AccountContext);

  return (
    <>
      {runtimeConfig.map(({ context, element }) => {
        const props = {
          ...element,
          value: element.runtime ? COMPUTED_VALUE : element.value,
        };

        const label = context && <Label content={context.contextName} />;

        return (
          <ConfigElement key={element.id + element.type} {...props}>
            {context && viewer.admin && (
              <Link href={`/context/${context.contextId}`} noEllipsis>
                {label}
              </Link>
            )}
            {!viewer.admin && label}
            {element.createdAt && <ConfigTimestamp createdAt={element.createdAt} />}
          </ConfigElement>
        );
      })}
    </>
  );
};

export default EnvironmentViewConfigMap;
