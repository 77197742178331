import { Link } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import useCopyToClipboard from "hooks/useCopyToClipboard";

type DrawerShareButtonProps = {
  link: string;
  title: string;
} & AnalyticsCommonProps;

const DrawerShareButton = ({ link, title, ...analyticsProps }: DrawerShareButtonProps) => {
  const shareLink = useCopyToClipboard(link);

  return (
    <ButtonIcon icon={Link} onPress={shareLink} size="small" variant="ghost" {...analyticsProps}>
      {title}
    </ButtonIcon>
  );
};

DrawerShareButton.displayName = "DS.DrawerNew.ShareButton";

export default DrawerShareButton;
