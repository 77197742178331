import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { SEARCH_MANAGED_ENTITIES_GROUP_BY_TYPES } from "./gql";

const useIacManagementTypesGroups = () => {
  const { onError } = useTypedContext(FlashContext);
  const [lazyFetch, { data, previousData, loading }] = useLazyQuery(
    SEARCH_MANAGED_ENTITIES_GROUP_BY_TYPES,
    {
      onError,
    }
  );

  const groups = useMemo(
    () =>
      data?.searchManagedEntitiesGroupByTypes?.edges.map((edge) => edge.node) ||
      previousData?.searchManagedEntitiesGroupByTypes?.edges.map((edge) => edge.node) ||
      [],
    [
      data?.searchManagedEntitiesGroupByTypes?.edges,
      previousData?.searchManagedEntitiesGroupByTypes?.edges,
    ]
  );

  const dataIsEmpty =
    data && "searchManagedEntitiesGroupByTypes" in data && !groups.length && !loading;

  return {
    lazyFetch,
    groups,
    loading,
    endCursor: data?.searchManagedEntitiesGroupByTypes?.pageInfo.endCursor,
    startCursor: data?.searchManagedEntitiesGroupByTypes?.pageInfo.startCursor,
    dataIsEmpty,
  };
};

export default useIacManagementTypesGroups;
