import {
  addDays,
  differenceInDays,
  format,
  fromUnixTime,
  getTime,
  getUnixTime,
  isSameDay,
  subDays,
} from "date-fns/esm";
import { NetworkStatus } from "@apollo/client";
import { useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";

import Box from "ds/components/Box";
import LineChart from "ds/components/Charts/LineChart";
import { Datum } from "ds/components/Charts/LineChart/types";
import MissingDataBanner from "components/MissingDataBanner";
import usePolledQuery from "apollo/usePolledQuery";
import { PollingIntervalGroups } from "apollo/constants";

import styles from "./styles.module.css";
import NumberOfDeploymentsTooltip from "./Tooltip";
import DashboardWidgetsNumberOfDeploymentsEmpty from "./Empty";
import { GET_NUMBER_OF_DEPLOYMENTS } from "./gql";

const DATA_RANGE_DAYS = 30;

const DashboardWidgetsNumberOfDeployments = () => {
  const startTime = useRef(subDays(new Date(), DATA_RANGE_DAYS));
  const endTime = useRef(new Date());

  const { data, loading, error, networkStatus, refetch } = usePolledQuery(
    GET_NUMBER_OF_DEPLOYMENTS,
    {
      variables: {
        startTime: getUnixTime(startTime.current),
        endTime: getUnixTime(endTime.current),
      },
      pollingGroup: PollingIntervalGroups.Dashboard,
    }
  );

  const showErrorBanner = !!error;

  const numberOfDeployments = data?.metricsRange?.numberOfDeploymentsRange;

  const isLoading = (loading && !numberOfDeployments) || networkStatus === NetworkStatus.refetch;

  const adaptedData = useMemo(() => {
    const startDate = startTime.current;
    const endDate = endTime.current;
    // Create array of timestamps starting from startTime to endTime
    const timestamps = Array.from({ length: differenceInDays(endDate, startDate) }, (_, i) =>
      addDays(startDate, i + 1)
    );

    return timestamps.map((date) => {
      const foundValue = numberOfDeployments?.find((item) =>
        isSameDay(fromUnixTime(item.timestamp), date)
      )?.value;

      const value = foundValue || 0;

      return {
        value,
        timestamp: getTime(date),
      };
    });
  }, [numberOfDeployments]);

  if (isLoading) {
    return <Skeleton count={1} height={360} containerClassName={styles.skeleton} />;
  }

  const noData = !numberOfDeployments?.length;

  if (showErrorBanner) {
    return <MissingDataBanner refreshHandler={refetch} fullWidth />;
  }

  if (noData) {
    return <DashboardWidgetsNumberOfDeploymentsEmpty />;
  }

  return (
    <Box direction="column" className={styles.wrapper}>
      <LineChart
        renderTooltip={(data: Datum) => <NumberOfDeploymentsTooltip value={data.value} />}
        data={adaptedData}
        items={["value"]}
        // TODO: use semantic color
        colors={["var(--semantic-color-background-interactive-data-non-semantic-1-rest)"]}
        xKey="timestamp"
        formatXAxisLabel={(value) => format(value as number, "E dd MMM")}
        leftAxisLabel="Count"
        tooltipReactToScroll
      />
    </Box>
  );
};

export default DashboardWidgetsNumberOfDeployments;
