import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { showIacManagementResourceDetailsDrawer } from "./Drawer";
import { shouldCloseOnLocationChange } from "./helpers";
import useIacManagementStore from "../useIacManagementStore";

const IacManagementResourceDetails = () => {
  const { resourceId } = useParams<{ resourceId?: string }>();
  const navigate = useNavigate();
  const parentStackId = useIacManagementStore((state) => state.parentStackId);

  const handleClosingDrawer = useCallback(() => {
    const path = parentStackId ? `/stack/${parentStackId}/resources` : "/resources/iac-management";
    navigate(`${path}${window.location.search}`);
  }, [navigate, parentStackId]);

  useEffect(() => {
    if (resourceId) {
      showIacManagementResourceDetailsDrawer({
        shouldCloseOnLocationChange: shouldCloseOnLocationChange(parentStackId),
        resourceId,
        onClose: handleClosingDrawer,
      });
    }
  }, [resourceId, handleClosingDrawer, parentStackId]);

  return null;
};

export default IacManagementResourceDetails;
