import { External } from "components/icons/generated";
import { TIER_PLANS_LABELS } from "constants/tiers";
import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import Link from "ds/components/Link";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import { AnalyticsPageBilling } from "hooks/useAnalytics/pages/billing";

import BillingFeatureItem from "../../components/FeatureItem";
import BillingTierBadge from "../../components/TierBadge";
import { TALK_TO_SALES_LINK } from "../../constants";
import { getPreviousBillingTier } from "../../utils";
import { ADDITIONAL_FEATURES_BY_TIER, DETAILS_BY_TIER } from "../constants";
import styles from "./styles.module.css";
import { BillingUpgradeOptionsCardProps } from "./types";

const BillingUpgradeOptionsCard = ({
  tier,
  title = "Starting from",
  titleColor = "secondary",
  price,
  description,
  action,
  ctaButton,
  className,
}: BillingUpgradeOptionsCardProps) => {
  const details = DETAILS_BY_TIER[tier];

  const previousTier = getPreviousBillingTier(tier);

  const previousTierLabel = TIER_PLANS_LABELS[previousTier];

  const ctaButtonTitle = "Talk to sales";

  return (
    <TileWrapper direction="column" gap="x-large" align="start" className={className}>
      <Box justify="between" gap="large" fullWidth>
        <BillingTierBadge tier={tier} />
        {action}
      </Box>
      <Box direction="column" gap="medium">
        <Typography tag="p" variant="p-t8" color={titleColor}>
          {title}
        </Typography>
        <Typography tag="p" variant="p-t2">
          {price}
        </Typography>
        <Typography tag="p" variant="p-body4" color="secondary">
          {description}
        </Typography>
      </Box>
      {ctaButton || (
        <LinkButton
          variant="secondary"
          href={TALK_TO_SALES_LINK}
          target="_blank"
          size="small"
          analyticsPage={AnalyticsPageBilling.BillingDashboard}
          analyticsTitle="Upgrade Options CTA Button Clicked"
          analyticsProps={{
            ctaButtonTitle,
            plan: tier,
          }}
        >
          {ctaButtonTitle}
        </LinkButton>
      )}
      <Box direction="column" gap="medium">
        {details?.map(({ text, icon }, index) => (
          <BillingFeatureItem key={index} text={text} iconComponent={icon} />
        ))}
      </Box>
      <hr className={styles.divider} />
      <Box direction="column" gap="medium" fullWidth>
        <Box justify="between" align="center" gap="large" fullWidth>
          <Typography tag="p" variant="p-t7">
            All from {previousTierLabel} and
          </Typography>

          <Box gap="small">
            <Link
              target="_blank"
              href="https://spacelift.io/pricing"
              endIcon={External}
              analyticsPage={AnalyticsPageBilling.BillingDashboard}
              analyticsTitle="Upgrade Options - See All Link Clicked"
              analyticsProps={{
                plan: tier,
              }}
            >
              <Typography tag="span" variant="p-body3">
                See all
              </Typography>
            </Link>
          </Box>
        </Box>
        {ADDITIONAL_FEATURES_BY_TIER[tier]?.map((feature, index) => (
          <BillingFeatureItem key={index} text={feature.text} infoTooltip={feature.infoTooltip} />
        ))}
      </Box>
    </TileWrapper>
  );
};

export default BillingUpgradeOptionsCard;
