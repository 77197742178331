import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import SecretFieldDetails from "ds/components/SecretFieldDetails";
import { GithubEnterpriseIntegration } from "types/generated";

import { VCSCheckLabels } from "./helpers";

type VCSIntegrationsDetailsGitHubEnterpriseProps = {
  details: GithubEnterpriseIntegration;
};

const VCSIntegrationsDetailsGitHubEnterprise = ({
  details,
}: VCSIntegrationsDetailsGitHubEnterpriseProps) => {
  return (
    <>
      {/* TODO: wait for API changes, the current name is integrations name not a GitHub App name */}
      <FormFieldViewText label="GitHub app name" value={details.name} />

      <FormFieldViewText label="API host URL" value={details.apiHost} />

      <FormFieldViewText label="User facing host URL" value={details.userFacingHost} />

      <FormFieldViewText label="App ID" value={details.appID} />

      <FormField label="Webhook secret">
        <SecretFieldDetails secret={details.webhookSecret} />
      </FormField>

      <FormFieldViewText
        label="Webhook endpoint"
        value={details.webhookUrl}
        withCopy
        noTextEllipsis
        textWordBreak
      />

      <FormFieldViewText label="VCS checks" value={VCSCheckLabels[details.vcsChecks]} />
    </>
  );
};

export default VCSIntegrationsDetailsGitHubEnterprise;
