import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { forwardRef } from "react";

import { DotsVertical } from "components/icons/generated";

import Box, { BoxProps } from "../Box";
import Icon from "../Icon";
import Typography, { TypographyProps } from "../Typography";
import styles from "./styles.module.css";
import Tooltip from "../Tooltip";

type DraggableItemHandleProps = Partial<BoxProps> &
  Partial<Pick<TypographyProps, "variant">> & {
    dragHandleProps?: DraggableProvidedDragHandleProps | null;
    dragging?: boolean;
    handleListeners?: SyntheticListenerMap;
    handleAttributes?: DraggableAttributes;
  };

const DraggableItemHandle = forwardRef<HTMLDivElement, DraggableItemHandleProps>(
  function DraggableItemHandle(
    {
      children,
      variant = "p-body3",
      dragHandleProps,
      dragging,
      handleListeners,
      handleAttributes,
      ...rest
    },
    ref
  ) {
    return (
      <Box align="center" gap="medium" {...rest}>
        <Tooltip
          ref={ref}
          active={!dragging}
          on={(tooltipProps) => (
            <button
              {...tooltipProps}
              {...dragHandleProps}
              {...handleAttributes}
              {...handleListeners}
              aria-label="Drag and drop"
              className={styles.handle}
              tabIndex={0}
            >
              <Icon src={DotsVertical} className={styles.handleIcon} size="default" />
            </button>
          )}
        >
          Drag and drop
        </Tooltip>
        {typeof children === "string" ? (
          <Typography tag="span" variant={variant}>
            {children}
          </Typography>
        ) : (
          children
        )}
      </Box>
    );
  }
);

DraggableItemHandle.displayName = "DS.DraggableItem.Handle";

export default DraggableItemHandle;
