import { useModal } from "@ebay/nice-modal-react";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import useTypedContext from "hooks/useTypedContext";

import { BlueprintVersionFormFields } from "../components/VersionFormFragment";
import DetailsFormDrawer from "./components/DetailsFormDrawer";
import { BlueprintVersion } from "./types";

type BlueprintVersionCreateProps = {
  version: BlueprintVersion;
};

const BlueprintVersionCreateDrawer = createDrawer(({ version }: BlueprintVersionCreateProps) => {
  const drawer = useModal();
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  // TODO: mutation to create blueprint version

  const onSubmit = (formData: BlueprintVersionFormFields) => {
    // TODO: create version
    return Promise.resolve(formData)
      .then((data) => {
        console.log(data);
        if (data?.version) {
          reportSuccess({
            message: `Blueprint version ${data.version} is successfully created`,
          });
          drawer.hide();

          // TODO: navigate to new version
        }
      })
      .catch(onError);
  };

  const defaultValues = useMemo(
    () => ({
      version: `${version.version}-copy`,
      description: version.description,
    }),
    [version]
  );

  return (
    <DetailsFormDrawer
      title="Create new version"
      buttonText="Create blueprint draft"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
});

export const showBlueprintVersionCreateDrawer = createDrawerTrigger(BlueprintVersionCreateDrawer);
