import cx from "classnames";
import { useContext, useMemo, useState } from "react";
import { ComboBoxStateContext } from "react-aria-components";

import Tag from "ds/components/Tag";
import Box from "ds/components/Box";
import AriaInput, { AriaInputProps } from "ds/components/Input/AriaInput";
import ComboBoxClearButton from "ds/components/ComboBox/ClearButton";
import ComboBoxOpenButton from "ds/components/ComboBox/OpenButton";
import LoadingIndicator from "ds/components/LoadingIndicator";
import useElementWidth from "hooks/useElementWidth";
import { BaseCollection, SectionBaseCollection } from "ds/components/ComboBox/types";

import styles from "./styles.module.css";
import TagValue from "./TagValue";

const MIN_INPUT_SIZE = 200;
const SPACE = 4;
const SPACE_FOR_PLUS_ITEM = 50;

export type ComboBoxMultipleInputProps<
  Collection extends BaseCollection | SectionBaseCollection<BaseCollection, SectionProps>,
  SectionProps extends object = object,
> = {
  id: string;
  size: AriaInputProps["size"];
  color: AriaInputProps["color"];
  placeholder?: string;
  isDisabled?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  values?: string[] | null;
  items: Array<Collection>;
};

const ComboBoxMultipleInput = <
  Collection extends BaseCollection | SectionBaseCollection<BaseCollection, SectionProps>,
  SectionProps extends object = object,
>({
  id,
  placeholder,
  isDisabled,
  isError,
  isLoading,
  size,
  color,
  values = [],
  items,
}: ComboBoxMultipleInputProps<Collection, SectionProps>) => {
  const state = useContext(ComboBoxStateContext);
  const [tagsSizes, setTagsSizes] = useState<Record<string, number>>({});
  const [inputRef, inputSize] = useElementWidth<HTMLInputElement>();
  const [tagsWrapperRef, tagsWrappeSize] = useElementWidth<HTMLDivElement>();

  const hiddenTags = useMemo(() => {
    if (!values?.length) {
      return [];
    }

    const hiddenTags: string[] = [];

    let summOfSizes = SPACE_FOR_PLUS_ITEM;

    for (let i = 0; i < values.length; i++) {
      const size = SPACE + (tagsSizes[values[i]] || 0);
      const isLast = i === values.length - 1;

      summOfSizes += size;

      if (isLast) {
        summOfSizes -= SPACE_FOR_PLUS_ITEM;
      }

      if (inputSize && summOfSizes > inputSize - MIN_INPUT_SIZE) {
        hiddenTags.push(...values.slice(i));
        return hiddenTags;
      }
    }

    return hiddenTags;
  }, [values, inputSize, tagsSizes]);

  const tagsToRender = useMemo(() => {
    return values?.reduce(
      (acc, next) => {
        if (hiddenTags.includes(next)) {
          return acc;
        }

        const label = items.find((item) => "value" in item && item.value === next)?.label;

        if (!label) {
          return acc;
        }

        return [...acc, { label, value: next }];
      },
      [] as Array<{ label: string; value: string }>
    );
  }, [values, hiddenTags, items]);

  return (
    <Box className={styles.inputWrapper} data-values={values?.length} fullWidth>
      <Box ref={tagsWrapperRef} className={styles.tags} gap="small">
        {tagsToRender?.map(({ value, label }, i) => (
          <TagValue
            setTagSizes={setTagsSizes}
            currentSize={tagsSizes[label]}
            key={i}
            id={value}
            tag={label}
            containerSize={tagsWrappeSize}
            onRemove={() => state.setSelectedKey(value)}
          />
        ))}
        {!!hiddenTags.length && <Tag tag={`+${hiddenTags.length}`} />}
      </Box>
      <AriaInput
        ref={inputRef}
        id={id}
        style={{ paddingLeft: `${12 + tagsWrappeSize}px` }}
        className={cx(styles.input, {
          [styles.withClear]: state?.inputValue !== "",
          [styles.withLoading]: isLoading,
        })}
        disabled={isDisabled}
        error={isError}
        placeholder={placeholder}
        size={size}
        color={color}
      />
      <Box className={styles.buttonWrapper} gap="small" align="center">
        {isLoading && <LoadingIndicator loading />}
        <ComboBoxClearButton isDisabled={isDisabled} />
        <ComboBoxOpenButton isDisabled={isDisabled} />
      </Box>
    </Box>
  );
};

ComboBoxMultipleInput.displayName = "DS.ComboBox.Input";

export default ComboBoxMultipleInput;
