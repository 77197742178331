import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationAwsIntegrationDeleteArgs } from "types/generated";
import { AwsIntegration } from "types/generated";

export const DELETE_AWS_INTEGRATION: TypedDocumentNode<
  {
    awsIntegrationDelete: Pick<AwsIntegration, "id">;
  },
  MutationAwsIntegrationDeleteArgs
> = gql`
  mutation DeleteAwsIntegration($id: ID!) {
    awsIntegrationDelete(id: $id) {
      id
    }
  }
`;
