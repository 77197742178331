type GetKeyboardWidthChangeOptions = {
  key: string;
  isCollapsed: boolean;
  reverse: boolean;
  previousWidth: number;
  minWidth: number;
  maxWidth: number;
  step: number;
  onCollapse?: () => number;
  onExpand?: () => number;
};

const getKeyboardWidthChange = ({
  key,
  isCollapsed,
  reverse,
  previousWidth,
  minWidth,
  maxWidth,
  step,
  onCollapse,
  onExpand,
}: GetKeyboardWidthChangeOptions): number => {
  const isCollapsible = onCollapse && onExpand;
  if (key === "ArrowUp") {
    if (isCollapsible && isCollapsed) {
      return onExpand();
    }
    return maxWidth;
  }

  if (key === "ArrowDown") {
    if (isCollapsible && !isCollapsed) {
      return onCollapse();
    }
    if (isCollapsed) {
      return previousWidth;
    }
    return minWidth;
  }

  if (key !== "ArrowLeft" && key !== "ArrowRight") {
    return previousWidth;
  }

  const isShrinking = reverse ? key === "ArrowRight" : key === "ArrowLeft";

  if (isShrinking) {
    if (isCollapsible && isCollapsed) {
      return previousWidth;
    }

    if (isCollapsible && previousWidth === minWidth) {
      return onCollapse();
    }

    return Math.max(minWidth, previousWidth - step);
  }

  if (isCollapsible && isCollapsed) {
    return onExpand();
  }

  return Math.min(maxWidth, previousWidth + step);
};

export default getKeyboardWidthChange;
