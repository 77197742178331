import { NetworkStatus } from "@apollo/client";

import Box from "ds/components/Box";
import { EmptystateArrowsLeftRightBothColored } from "components/icons/generated";
import MissingDataBanner from "components/MissingDataBanner";
import usePolledQuery from "apollo/usePolledQuery";
import { PollingIntervalGroups } from "apollo/constants";

import DashboardWidgetsEmptyStateRuns from "../EmptyStateRuns";
import DashboardWidgetsRunTile from "../RunTile";
import { GetRecentDriftDetectionRuns } from "./types";
import { GET_RECENT_DRIFT_DETECTION_RUNS } from "./gql";
import DashboardWidgetsRunsSkeleton from "../RunsSkeleton";
import { Widget } from "../../types";

// TODO: [dashboard] add limit to query once added to API
const LIMIT = 3;

const DashboardWidgetsRecentDriftDetectionRuns = () => {
  const { loading, data, error, refetch, networkStatus } =
    usePolledQuery<GetRecentDriftDetectionRuns>(GET_RECENT_DRIFT_DETECTION_RUNS, {
      pollingGroup: PollingIntervalGroups.Dashboard,
    });

  if (error) {
    return (
      <MissingDataBanner
        text="Couldn’t load recent drift detection runs. Please try to refresh or come back later."
        refreshHandler={refetch}
      />
    );
  }

  const isLoading =
    (loading && !data?.metrics?.recentDriftDetectionProposedRuns) ||
    networkStatus === NetworkStatus.refetch;

  const runs = data?.metrics?.recentDriftDetectionProposedRuns?.slice(0, LIMIT);

  let emptyState;

  const isEmpty = !isLoading && !runs?.length;

  const hasRuns = !!data?.metrics?.hasRuns;

  if (!hasRuns) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns widget={Widget.RecentDriftDetectionRuns} showDocs />
    );
  }

  if (hasRuns && isEmpty) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns
        icon={EmptystateArrowsLeftRightBothColored}
        title="You don’t have any Drift Detection runs"
        caption="Navigate to Stack view / Scheduling to create your first schedule."
        widget={Widget.RecentDriftDetectionRuns}
        showDocs
      />
    );
  }

  if (isLoading) {
    return <DashboardWidgetsRunsSkeleton noCaption />;
  }

  return (
    <Box direction="column" gap="medium" fullWidth>
      {emptyState}

      {!emptyState &&
        runs?.map(({ id, title, state, stackTile }, i) => (
          <DashboardWidgetsRunTile
            key={i}
            id={id}
            title={title}
            state={state}
            stackTile={stackTile}
            analyticsTitle="Recent Drift Detection Runs Widget"
          />
        ))}
    </Box>
  );
};

export default DashboardWidgetsRecentDriftDetectionRuns;
