import useLocalStorage from "@rehooks/local-storage";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";

import SystemMessage from "components/SystemMessage";
import { MFA_REGISTER_KEY_COMPATIBILITY_MESSAGE } from "constants/mfa";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import useMFA from "hooks/useMFA";
import useTitle from "hooks/useTitle";
import { getAuthStatusRedirectUrl } from "utils/auth";

type RegisterKeyFormFields = {
  name: string;
};

const NameFieldTooltipInfo = () => (
  <>
    <TooltipModalTitle>Security keys</TooltipModalTitle>
    <TooltipModalBody>
      Security keys are hardware devices that can be used as your second factor of authentication.
      Name your security key to be able to manage them after.
    </TooltipModalBody>
  </>
);

const EnableMFA = () => {
  useTitle("Enable MFA");
  const [error, setError] = useState<string | null>(null);
  const [nextUrl, setNextUrl] = useState<string | undefined>();

  const {
    handleRegister,
    isLoading,
    shouldRegisterSecurityKey,
    isUserVerifyingPlatformAuthenticatorAvailable,
  } = useMFA();

  const form = useForm<RegisterKeyFormFields>({
    mode: "onChange",
  });

  const [redirectTo] = useLocalStorage("redirectTo");

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = form;

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  const onSubmit: SubmitHandler<RegisterKeyFormFields> = async (formData) => {
    const { success, message, status } = await handleRegister(formData.name);

    if (success) {
      setError(null);
      setNextUrl("/mfa");
    } else {
      const authRedirectUrl = getAuthStatusRedirectUrl(status);

      if (authRedirectUrl) {
        setNextUrl(authRedirectUrl);
      } else {
        setError(message);
      }
    }
  };

  if (nextUrl) {
    return <Navigate to={nextUrl} replace />;
  }

  if (!shouldRegisterSecurityKey) {
    return <Navigate to={redirectTo || "/"} replace />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SystemMessage
        title="Enable multi-factor authentication"
        message="Your organization admin required you to log in using multi-factor authentication. Register your security key. "
      >
        <FormProvider {...form}>
          <Box direction="column" gap="x-large" fullWidth>
            {isUserVerifyingPlatformAuthenticatorAvailable === false && (
              <Banner variant="info" fullWidth>
                {MFA_REGISTER_KEY_COMPATIBILITY_MESSAGE}
              </Banner>
            )}
            <FormField
              label="Key name"
              tooltipInfo={<NameFieldTooltipInfo />}
              tooltipInfoVariant="modal"
              error={errors?.name?.message}
              helperText="Name your security key"
              noMargin
            >
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter key name"
                  error={!!errors?.name?.message}
                  disabled={isLoading}
                  {...register("name", { required: "Name is required." })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
            {error && (
              <Banner variant="danger" fullWidth>
                {error}
              </Banner>
            )}
            <Button
              variant="primary"
              fullWidth
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Register security key
            </Button>
          </Box>
        </FormProvider>
      </SystemMessage>
    </form>
  );
};

export default EnableMFA;
