import { TableColumnMeta } from "ds/components/Table/types";
import { SearchQueryOrderDirection } from "types/generated";

import { IAC_MANAGEMENTS_TABLE_COLUMNS } from "./types";
import { ResourcesSuggestions } from "../useIacManagementFilters/constants";

const MAX_CELL_WIDTH = 600;

export const PAGINATION_OPTIONS = [10, 25, 50];
export const GROUPS_LIMIT = 50;
export const GROUPS_LIMIT_STORAGE_KEY = "groupsLimit";
export const ADD_TO_FILTERS = "Add to filters";

export const TABLE_COLUMNS: Record<TableColumnMeta["id"], TableColumnMeta> = {
  [IAC_MANAGEMENTS_TABLE_COLUMNS.NAME]: {
    id: IAC_MANAGEMENTS_TABLE_COLUMNS.NAME,
    title: "Name",
    minWidth: 270,
    defaultWidth: 350,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    resizable: true,
    canHide: false,
  },
  [IAC_MANAGEMENTS_TABLE_COLUMNS.RESOURCES]: {
    id: IAC_MANAGEMENTS_TABLE_COLUMNS.RESOURCES,
    title: "Resources",
    minWidth: 140,
    defaultWidth: 200,
    maxWidth: MAX_CELL_WIDTH / 2,
    allowsSorting: true,
    resizable: true,
    canHide: true,
  },
  [IAC_MANAGEMENTS_TABLE_COLUMNS.DRIFT_DETECTED]: {
    id: IAC_MANAGEMENTS_TABLE_COLUMNS.DRIFT_DETECTED,
    title: "Drift detected",
    minWidth: 160,
    defaultWidth: 200,
    maxWidth: MAX_CELL_WIDTH / 2,
    allowsSorting: true,
    resizable: true,
    canHide: true,
  },
  [IAC_MANAGEMENTS_TABLE_COLUMNS.SPACE]: {
    id: IAC_MANAGEMENTS_TABLE_COLUMNS.SPACE,
    title: "Space",
    minWidth: 120,
    defaultWidth: 250,
    maxWidth: MAX_CELL_WIDTH / 2,
    allowsSorting: true,
    resizable: true,
    canHide: true,
  },
  [IAC_MANAGEMENTS_TABLE_COLUMNS.VENDOR]: {
    id: IAC_MANAGEMENTS_TABLE_COLUMNS.VENDOR,
    title: "Vendor",
    minWidth: 120,
    defaultWidth: 250,
    maxWidth: MAX_CELL_WIDTH / 2,
    allowsSorting: true,
    resizable: true,
    canHide: true,
  },
  [IAC_MANAGEMENTS_TABLE_COLUMNS.UPDATED_AT]: {
    id: IAC_MANAGEMENTS_TABLE_COLUMNS.UPDATED_AT,
    title: "Updated at",
    minWidth: 145,
    defaultWidth: 200,
    maxWidth: MAX_CELL_WIDTH / 2,
    allowsSorting: true,
    resizable: true,
    canHide: true,
  },
  [IAC_MANAGEMENTS_TABLE_COLUMNS.ACTIONS]: {
    id: IAC_MANAGEMENTS_TABLE_COLUMNS.ACTIONS,
    title: "Row actions",
    noHeader: true,
    minWidth: 60,
    defaultWidth: 60,
    maxWidth: 60,
    allowsSorting: false,
    resizable: false,
    canHide: false,
  },
};

export const initialSortOption = IAC_MANAGEMENTS_TABLE_COLUMNS.NAME;
export const initialSortDirection = SearchQueryOrderDirection.Desc;
export const FILTERS_TYPE = "resources";
export const FILTERS_ORDER_SETTINGS_KEY = "iacManagementFiltersOrder";

export const SORT_OPTIONS_STACK_GROUP: Record<TableColumnMeta["id"], string> = {
  [IAC_MANAGEMENTS_TABLE_COLUMNS.NAME]: ResourcesSuggestions.EntityId,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.RESOURCES]: ResourcesSuggestions.ResourcesCount,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.DRIFT_DETECTED]: ResourcesSuggestions.Drifted,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.SPACE]: ResourcesSuggestions.Space,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.VENDOR]: ResourcesSuggestions.Vendor,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.UPDATED_AT]: ResourcesSuggestions.UpdatedAt,
};

export const SORT_OPTIONS_PROVIDER_GROUP: Record<TableColumnMeta["id"], string | undefined> = {
  [IAC_MANAGEMENTS_TABLE_COLUMNS.NAME]: ResourcesSuggestions.EntityId,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.RESOURCES]: ResourcesSuggestions.ResourcesCount,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.DRIFT_DETECTED]: ResourcesSuggestions.Drifted,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.SPACE]: undefined,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.VENDOR]: undefined,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.UPDATED_AT]: ResourcesSuggestions.UpdatedAt,
};

export const SORT_OPTIONS_RESOURCE_TYPE_GROUP: Record<TableColumnMeta["id"], string | undefined> = {
  [IAC_MANAGEMENTS_TABLE_COLUMNS.NAME]: ResourcesSuggestions.EntityId,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.RESOURCES]: ResourcesSuggestions.ResourcesCount,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.DRIFT_DETECTED]: ResourcesSuggestions.Drifted,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.SPACE]: undefined,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.VENDOR]: undefined,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.UPDATED_AT]: ResourcesSuggestions.UpdatedAt,
};

export const SORT_OPTIONS_RESOURCES: Record<TableColumnMeta["id"], string | undefined> = {
  [IAC_MANAGEMENTS_TABLE_COLUMNS.NAME]: ResourcesSuggestions.EntityId,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.RESOURCES]: undefined,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.DRIFT_DETECTED]: ResourcesSuggestions.Drifted,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.SPACE]: ResourcesSuggestions.Space,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.VENDOR]: ResourcesSuggestions.Vendor,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.UPDATED_AT]: ResourcesSuggestions.UpdatedAt,
};
