import prettyBytes from "pretty-bytes";

import TriggerByInfo from "components/TriggerByInfo";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { ManagedStateVersion, RunType } from "types/generated";
import { StackContext } from "views/Stack/Context";

type StackStateHistoryListItemEventTitleProps = {
  item: ManagedStateVersion;
};

const StackStateHistoryListItemEventTitle = ({
  item,
}: StackStateHistoryListItemEventTitleProps) => {
  const { stack, stackUrl } = useTypedContext(StackContext);
  const run = item.run;

  if (!run) {
    return (
      // TODO: provide user name, waiting for BE changes
      <Typography tag="span" variant="p-body2">
        Initial state uploaded during the stack creation. State file size: {prettyBytes(item.size)}
      </Typography>
    );
  }

  const hasTaskType = run.type === RunType.Task;

  const Prefix = hasTaskType ? <>Task {run.title}</> : <>Run triggered</>;

  const Title = (
    <>
      {Prefix}{" "}
      <TriggerByInfo
        stackId={stack.id}
        triggeredBy={run.triggeredBy || undefined}
        driftDetection={run.driftDetection}
      />
    </>
  );

  return (
    <Link href={`${stackUrl}/run/${run.id}`}>
      <Typography tag="span" variant="p-body2">
        {Title}
      </Typography>
    </Link>
  );
};

export default StackStateHistoryListItemEventTitle;
