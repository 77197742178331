import { dropdownEllipsisWidth } from "ds/components/DropdownMenu/constants";
import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";

export const COLUMN_ORDER = `minmax(4rem, 6fr) minmax(10rem, 1fr) minmax(20rem, 1fr) ${dropdownEllipsisWidth}`;

export const COLUMN_GAP: Spacing = "large";

export enum FilterItemKeys {
  Name = "name",
  Space = "space",
  CreatedAt = "createdAt",
}

export const FILTER_ITEMS_DICTIONARY: Partial<Record<FilterItemKeys, string>> = {
  [FilterItemKeys.Name]: "Name",
  [FilterItemKeys.Space]: "Space",
  [FilterItemKeys.CreatedAt]: "Created at",
};

export const INITIAL_SORT_OPTION = FilterItemKeys.CreatedAt;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Desc;
export const FILTERS_ORDER_SETTINGS_KEY = "contextsFiltersOrder";

export const CONTEXTS_PER_PAGE = 50;

export const CONTEXTS_LIST_ITEM_TEST_ID = "contexts-list-item";

export const CONTEXT_ANALYTICS_VERSION = "Context v1";
