import { useEffect, useRef } from "react";

type useFocusOnModalEnterOptions = {
  shouldFocusOnEnter?: boolean;
};
const useFocusOnModalEnter = ({ shouldFocusOnEnter = true }: useFocusOnModalEnterOptions = {}) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!shouldFocusOnEnter) {
      return;
    }
    const focusableElements = bodyRef.current?.querySelectorAll<HTMLElement>(
      'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
    );

    const focusElement = () => focusableElements?.[0]?.focus();

    const modal = document.querySelector("[data-modal]");

    if (modal) {
      modal.addEventListener("animationend", focusElement);
      return () => modal.removeEventListener("animationend", focusElement);
    }

    focusableElements?.[0]?.focus();

    return;
  }, [shouldFocusOnEnter]);

  return bodyRef;
};

export default useFocusOnModalEnter;
