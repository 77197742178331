import { ManagedUser } from "types/generated";

import { SpaceAccessField } from "../InviteDrawer/types";

export type UpdateUserFields = {
  id: string;
  spaces: SpaceAccessField[];
  slackMemberID: string;
};

export enum UsersDrawerTabs {
  Spaces = "spaces",
  Groups = "groups",
  Integrations = "integrations",
}

export type UserDetails = Pick<
  ManagedUser,
  | "id"
  | "invitationEmail"
  | "status"
  | "username"
  | "role"
  | "latestGroups"
  | "accessRules"
  | "slackMemberID"
  | "lastLoginTime"
  | "spaceCount"
  | "groupCount"
  | "loginMethod"
>;
