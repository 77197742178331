import WarningContext from "components/WarningContext";
import Button, { ButtonProps } from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";

import { showNewContextFooterContinueConfirmation } from "./ContinueConfirmation";

type NewContextFooterCreateContextButtonProps = {
  onClickHandler: () => void;
  isConfirmationActive: boolean;
} & ButtonProps;

const NewContextFooterCreateContextButton = ({
  onClickHandler,
  isConfirmationActive,
  ...buttonProps
}: NewContextFooterCreateContextButtonProps) => {
  const { warning, clearWarning } = useTypedContext(WarningContext);
  const onCreateContext = () => {
    onClickHandler();

    if (warning) {
      clearWarning();
    }
  };

  const onModalShow = () => {
    showNewContextFooterContinueConfirmation({
      onConfirm: onCreateContext,
      warning,
      confirmationButtonLabel: "Create context",
    });
  };

  return (
    <Button
      {...buttonProps}
      onPress={warning && isConfirmationActive ? onModalShow : onCreateContext}
    >
      Create context
    </Button>
  );
};

export default NewContextFooterCreateContextButton;
