import Link from "ds/components/Link";
import Box from "ds/components/Box";

import useCloudIntegrationsUsedByAnalytics from "../useCloudIntegrationsUsedByAnalytics";
import { CellComponentProps } from "./types";

type UsedByNameCellProps = CellComponentProps;

const UsedByNameCell = ({ item }: UsedByNameCellProps) => {
  const trackAnalytics = useCloudIntegrationsUsedByAnalytics();

  return (
    <Box align="center" fullWidth>
      <Link
        size="small"
        variant="secondary"
        href={`/${item.isModule ? "module" : "stack"}/${item.stackId}`}
        onPress={() =>
          trackAnalytics("Stack link clicked", {
            name: item.stackName,
            type: item.isModule ? "module" : "stack",
          })
        }
      >
        {item.stackName}
      </Link>
    </Box>
  );
};

export default UsedByNameCell;
