type GetMouseWidthChangeOptions = {
  startResizingPos: number;
  startResizingWidth: number;
  currentMousePos: number;
  collapseWidth?: number;
  isCollapsed: boolean;
  reverse: boolean;
  minWidth: number;
  maxWidth: number;
  onCollapse?: () => number;
  onExpand?: () => number;
};

const EXPAND_THRESHOLD = 20;

const getMouseWidthChange = ({
  startResizingPos,
  startResizingWidth,
  currentMousePos,
  collapseWidth,
  isCollapsed,
  reverse,
  minWidth,
  maxWidth,
  onCollapse,
  onExpand,
}: GetMouseWidthChangeOptions): number => {
  const distance = Math.floor(startResizingPos - currentMousePos);
  const absDistance = Math.abs(distance);
  const isCollapsible = onCollapse && onExpand && collapseWidth;

  const isShrinking = reverse ? distance < 0 : distance > 0;

  const newWidth = isShrinking
    ? startResizingWidth - absDistance
    : startResizingWidth + absDistance;

  if (isCollapsible && isCollapsed && newWidth > collapseWidth + EXPAND_THRESHOLD) {
    return onExpand();
  }
  if (isCollapsible && isCollapsed && newWidth < minWidth) {
    return collapseWidth;
  }

  if (isCollapsible && isShrinking && newWidth <= collapseWidth) {
    return onCollapse();
  }

  return Math.max(minWidth, Math.min(maxWidth, newWidth));
};

export default getMouseWidthChange;
