import { ReactNode } from "react";
import cx from "classnames";

type StepperProps = {
  children: ReactNode;
  className?: string;
};

import styles from "./styles.module.css";

const Stepper = ({ children, className }: StepperProps) => {
  return <div className={cx(styles.wrapper, className)}>{children}</div>;
};

export default Stepper;
