import {
  CSSProperties,
  forwardRef,
  memo,
  ReactElement,
  Ref,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useVirtualizer, notUndefined } from "@tanstack/react-virtual";
import {
  ResizableTableContainer,
  Table as AriaTable,
  TableBody,
  TableHeader,
  TableBodyProps,
} from "react-aria-components";

import useTypedContext from "hooks/useTypedContext";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";

import TableColumnSelectAll from "./ColumnSelectAll";
import { TableContext } from "./Context";
import styles from "./styles.module.css";
import TableColumn from "./Column";

type TableProps<ItemType extends object & { id: string }, SelectAllType = string> = {
  ariaLabel?: string;
  children: TableBodyProps<
    | (ItemType & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      })
    | { id: string; height: number }
  >["children"];
  items: ItemType[];
  selectAllOptions?: Array<{ id: SelectAllType; title: string }>;
  loadingContent?: boolean;
  totalSize?: number;
  nonSelectableIds?: string[];
};

const Table = <ItemType extends object & { id: string }>(
  {
    children,
    ariaLabel,
    items,
    selectAllOptions,
    loadingContent,
    nonSelectableIds,
  }: TableProps<ItemType>,
  ref?: Ref<HTMLDivElement>
) => {
  const headerRef = useRef<HTMLTableSectionElement>(null);
  const bodyRef = useRef<HTMLTableSectionElement>(null);

  const virtualizer = useVirtualizer({
    count: items.length,
    estimateSize: () => 57,
    overscan: 10,
    getScrollElement: () => (ref && "current" in ref ? ref.current : null),
  });

  const itemsVirtualized = virtualizer.getVirtualItems();

  const itemsToRender = useMemo(
    () =>
      itemsVirtualized.map((virt) => ({
        ...items[virt.index],
        virtIndex: virt.index,
        virtKey: virt.key,
        ref: virtualizer.measureElement,
      })),
    [itemsVirtualized, items, virtualizer.measureElement]
  );

  const [artificialBeforeItemHeight, artificialAfterItemHeight] = useMemo(
    () =>
      itemsVirtualized.length > 0
        ? [
            notUndefined(itemsVirtualized[0]).start - virtualizer.options.scrollMargin,
            virtualizer.getTotalSize() -
              notUndefined(itemsVirtualized[itemsVirtualized.length - 1]).end,
          ]
        : [0, 0],
    [itemsVirtualized, virtualizer]
  );

  const itemsReadyToRender = useMemo(
    () =>
      [
        artificialBeforeItemHeight > 0 && { id: "before", height: artificialBeforeItemHeight },
        ...itemsToRender,
        artificialAfterItemHeight > 0 && { id: "after", height: artificialAfterItemHeight },
      ].filter((v) => !!v),
    [artificialBeforeItemHeight, artificialAfterItemHeight, itemsToRender]
  );

  const {
    visibleColumns,
    rowHeaderId,
    selectable,
    onResizeEnd,
    columnSizesConfig,
    sortDescriptor,
    setSortDescriptor,
    setSavedColumnConfig,
    savedColumnConfig,
    configurableColumnIds,
  } = useTypedContext(TableContext);

  const hideColumn = useCallback(
    (id: string) => {
      const method = "column dropdown";
      if (savedColumnConfig) {
        setSavedColumnConfig(
          {
            visible: savedColumnConfig.visible.filter((value) => value !== id),
            hidden: [...savedColumnConfig.hidden, id],
          },
          method
        );
      } else {
        setSavedColumnConfig(
          {
            visible: configurableColumnIds.filter((value) => value !== id),
            hidden: [id],
          },
          method
        );
      }
    },
    [configurableColumnIds, setSavedColumnConfig, savedColumnConfig]
  );

  const allSelectableIds = useMemo(
    () =>
      items.reduce(
        (prev, next) => (nonSelectableIds?.includes(next.id) ? prev : [...prev, next.id]),
        [] as string[]
      ),
    [items, nonSelectableIds]
  );

  return (
    <ResizableTableContainer ref={ref} className={styles.tableContainer} onResizeEnd={onResizeEnd}>
      <AriaTable
        aria-label={ariaLabel}
        className={styles.table}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        selectionMode="none"
      >
        <TableHeader ref={headerRef} className={styles.tableHeader}>
          {selectable && (
            <TableColumnSelectAll allIds={allSelectableIds} options={selectAllOptions} />
          )}
          {visibleColumns.map(({ id, resizable = true, ...columnProps }, i) => {
            return (
              <TableColumn
                key={id}
                value={{
                  id,
                  resizable: resizable && i < visibleColumns.length - 1,
                  ...columnProps,
                }}
                size={columnSizesConfig ? columnSizesConfig?.[id] : undefined}
                isRowHeader={id === rowHeaderId}
                hideColumn={hideColumn}
              />
            );
          })}
        </TableHeader>
        <TableBody className={styles.tableBody} items={itemsReadyToRender} ref={bodyRef}>
          {!loadingContent && children}
        </TableBody>
      </AriaTable>

      {loadingContent && <PageLayoutSkeleton hideHeader className={styles.skeleton} />}
    </ResizableTableContainer>
  );
};

export default memo(forwardRef(Table)) as <
  ItemType extends object & { id: string },
  SelectAllType = string,
>(
  p: TableProps<ItemType, SelectAllType> & { ref?: Ref<HTMLDivElement> }
) => ReactElement;
