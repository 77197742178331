import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { makeStringMatchesPredicate } from "utils/predicates";

import { SEARCH_MANAGED_ENTITIES } from "./gql";
import useFiltersVariables from "../useFiltersVariables";
import { IacManagementGroupBy } from "../types";
import { makeResourcesSortOption } from "./helpers";

export type UseIacManagementResourcesFetchProps = {
  cursor: string | null;
  groupId: string;
  rowsPerPage: number;
  groupBy: IacManagementGroupBy;
  parentStackId?: string;
};

const useIacManagementResources = () => {
  const { onError } = useTypedContext(FlashContext);
  const [searchStackGroups, { loading }] = useLazyQuery(SEARCH_MANAGED_ENTITIES, {
    onError,
    fetchPolicy: "network-only",
  });

  const { filterVariables, sortingMounted } = useFiltersVariables();

  const fetchData = useCallback(
    async ({
      groupId,
      groupBy,
      parentStackId,
      cursor,
      rowsPerPage,
    }: UseIacManagementResourcesFetchProps) => {
      const predicatesPerGroup = [
        ...filterVariables.predicates,
        makeStringMatchesPredicate(groupBy, [groupId]),
      ];

      // add the stack predicate for all queries in Stack view except for the Stack grouping option, to avoid duplicating it
      if (parentStackId && groupBy !== IacManagementGroupBy.Stack) {
        predicatesPerGroup.push(makeStringMatchesPredicate("stack", [parentStackId]));
      }

      if (!sortingMounted) {
        return {
          data: [],
          hasMore: false,
          endCursor: undefined,
        };
      }

      const { data } = await searchStackGroups({
        variables: {
          ...{
            input: {
              after: cursor,
              first: rowsPerPage,
              fullTextSearch: filterVariables.searchInput || null,
              orderBy: makeResourcesSortOption(filterVariables.sortOptionFields),
              predicates: predicatesPerGroup,
              requestedPage: null,
            },
          },
        },
      });

      return {
        data: data?.searchManagedEntities?.edges.map((edge) => edge.node) || [],
        hasMore: data?.searchManagedEntities?.pageInfo?.hasNextPage || false,
        endCursor: data?.searchManagedEntities?.pageInfo?.endCursor,
      };
    },
    [
      filterVariables.predicates,
      filterVariables.searchInput,
      filterVariables.sortOptionFields,
      searchStackGroups,
      sortingMounted,
    ]
  );

  const fetchResourceById = useCallback(
    async (resourceId: string) => {
      const predicatesPerGroup = [
        ...filterVariables.predicates,
        makeStringMatchesPredicate("id", [resourceId]),
      ];

      const { data } = await searchStackGroups({
        variables: {
          ...{
            input: {
              after: null,
              first: 1,
              fullTextSearch: null,
              orderBy: null,
              predicates: predicatesPerGroup,
              requestedPage: null,
            },
          },
        },
      });

      return {
        data: data?.searchManagedEntities?.edges.map((edge) => edge.node)[0] || undefined,
      };
    },
    [filterVariables.predicates, searchStackGroups]
  );

  return { fetchData, fetchResourceById, loading };
};

export default useIacManagementResources;
