import IconTile from "ds/components/IconTile";
import { LogoAwsColored, LogoMsAzureColored } from "components/icons/generated";

import { CellComponentProps } from "./types";
import styles from "./styles.module.css";

type CloudIntegrationsListTypeCellProps = CellComponentProps;

const CloudIntegrationsListTypeCell = ({ item }: CloudIntegrationsListTypeCellProps) => {
  const icon = item.__typename === "AzureIntegration" ? LogoMsAzureColored : LogoAwsColored;
  return <IconTile className={styles.typeIcon} icon={icon} />;
};

export default CloudIntegrationsListTypeCell;
