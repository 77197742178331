import cx from "classnames";
import debounce from "lodash-es/debounce";
import { useEffect, useRef, useState } from "react";

import Box from "ds/components/Box";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";
import { useToggle } from "hooks/useToggle";

import styles from "./styles.module.css";

type PrivateWorkerPoolMetaTagsListProps = {
  tags: string[];
  onExpand?: () => void;
};

const PrivateWorkerPoolMetaTagsList = ({ tags, onExpand }: PrivateWorkerPoolMetaTagsListProps) => {
  const detectorRef = useRef<HTMLDivElement>(null);
  const [isExpanded, toggle] = useToggle(false);
  const [visibleMoreLessControls, setVisibilityMoreLessControls] = useState(false);

  const indicateHiddenLabels = () => {
    if (detectorRef.current) {
      const element = detectorRef.current;

      const isElementHiddenVertically = element.scrollHeight > element.clientHeight;
      const isMultiLine = element.clientHeight > 30;

      setVisibilityMoreLessControls(isElementHiddenVertically || isMultiLine);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", debounce(indicateHiddenLabels, 100));

    return () => {
      window.removeEventListener("resize", indicateHiddenLabels);
    };
  }, []);

  useEffect(() => {
    indicateHiddenLabels();
  }, [tags]);

  useEffect(() => {
    onExpand?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <Box direction="row" fullWidth>
      <Box
        direction="row"
        justify="between"
        className={cx(styles.tagListWrapper, { [styles.isExpanded]: isExpanded })}
        ref={detectorRef}
      >
        {tags.map((tag) => (
          <div key={tag} className={styles.tagWrapper}>
            <Typography variant="s-body3" tag="code">
              {tag}
            </Typography>
          </div>
        ))}

        <Link
          onPress={() => toggle(false)}
          className={cx(styles.toggle, { [styles.hide]: !isExpanded || !visibleMoreLessControls })}
          size="small"
        >
          Show less
        </Link>
      </Box>

      <Link
        onPress={() => toggle(true)}
        className={cx(styles.toggle, styles.more, {
          [styles.hide]: isExpanded || !visibleMoreLessControls,
        })}
        size="small"
      >
        Show more
      </Link>
    </Box>
  );
};

export default PrivateWorkerPoolMetaTagsList;
