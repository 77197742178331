import { useFormContext } from "react-hook-form";

import Banner from "ds/components/Banner";

const AttachCloudNoPermissionError = () => {
  const {
    watch,
    formState: { isSubmitted },
  } = useFormContext<{ read: boolean; write: boolean }>();

  const [read, write] = watch(["read", "write"]);

  const showWarning = !read && !write && isSubmitted;

  if (!showWarning) {
    return null;
  }

  return (
    <Banner variant="danger" title="No permission selected">
      Please select at least one permission to attach the integration.
    </Banner>
  );
};

export default AttachCloudNoPermissionError;
