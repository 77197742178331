import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { FormProvider, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Space";
import BadgeNext from "ds/components/BadgeNext";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import SecretInput from "ds/components/SecretInput";
import Textarea from "ds/components/Textarea";
import Tile from "ds/components/Tile";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { ExternalIntegration, IntegrationCustomConfigData } from "types/generated";
import { getDrawerFormFix } from "utils/css";

import { CREATE_EXTERNAL_INTEGRATION, UPDATE_WEBHOOK_INTEGRATION } from "./gql";
import styles from "./styles.module.css";

type IntegrationDrawerFormProps = {
  integration?: ExternalIntegration;
  isLoginPolicyActive: boolean;
  trackAnalytics: (event: string, props?: TrackAnalyticsEventProperties) => void;
};

type FormValues = {
  space: string;
  name: string;
  description: string;
  type: string;
  customConfigData: IntegrationCustomConfigData;
};

const IntegrationDrawerForm = createDrawer(
  ({ integration, isLoginPolicyActive, trackAnalytics }: IntegrationDrawerFormProps) => {
    const drawer = useModal();
    const isEditMode = !!integration?.id;

    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const builderForm = useForm<FormValues>({
      defaultValues: {
        space: "",
        name: "",
        description: "",
        type: "SERVICE_NOW",
        customConfigData: {},
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isDirty },
    } = builderForm;

    const [createIntegration, { loading: creatingIntegration }] = useMutation(
      CREATE_EXTERNAL_INTEGRATION,
      {
        refetchQueries: ["GetAccountExternalIntegrations"],
      }
    );

    // This is just a placeholder, the actual query is not provided
    const [updateIntegration, { loading: updatingIntegration }] = useMutation(
      UPDATE_WEBHOOK_INTEGRATION,
      {
        refetchQueries: ["GetAccountExternalIntegrations"],
        variables: {
          id: integration?.id,
          moduleId: "1",
        },
      }
    );

    const handleCloseDrawer = () => {
      drawer.hide();
    };

    const onCreateSubmit = (formData: FormValues) => {
      createIntegration({
        variables: {
          input: {
            space: formData.space,
            name: formData.name,
            description: formData.description,
            type: formData.type,
            customConfigData: formData.customConfigData,
          },
        },
      })
        .then(({ data }) => {
          if (data?.externalIntegrationCreate?.id) {
            reportSuccess({
              message: `Integration was successfully created`,
            });
            reset();
            handleCloseDrawer();
          }
        })
        .catch(onError);
    };

    const onUpdateSubmit = (formData: FormValues) => {
      updateIntegration({
        variables: {
          input: {
            space: formData.space,
            name: formData.name,
            description: formData.description,
            type: formData.type,
            customConfigData: formData.customConfigData,
          },
        },
      })
        .then(({ data }) => {
          if (data?.webhooksIntegrationUpdate?.id) {
            reportSuccess({
              message: `Integration was successfully updated`,
            });
            reset();
            handleCloseDrawer();
          }
        })
        .catch(onError);
    };

    const onSubmit = (formData: FormValues) => {
      if (isEditMode) {
        onUpdateSubmit(formData);
      } else {
        onCreateSubmit(formData);
      }
    };

    const onClose = () => {
      trackAnalytics("Close Integration Form", {
        type: "serviceNow",
        mode: isEditMode ? "edit" : "create",
        dirty: isDirty,
      });
    };

    return (
      <DrawerForm isDirty={isDirty} onClose={onClose}>
        <FormProvider {...builderForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title={isEditMode ? "Edit integration" : "Create integration"} />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight>
              {isLoginPolicyActive && (
                <Banner variant="warning" title="User management is inactive">
                  Integration will create API key with account admin privileges.
                </Banner>
              )}
              <Box gap="x-large" direction="column" margin="large 0 0">
                <FormField label="Name" error={errors?.name?.message} noMargin>
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Name your integration"
                      error={!!errors?.name}
                      {...register("name", { required: "Name field is required." })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
                <FormFieldSpace noMargin />
                <FormField label="Description" isOptional noMargin>
                  {({ ariaInputProps }) => (
                    <Textarea
                      placeholder="Enter the integration description "
                      {...register("description")}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
                <FormField
                  label="Endpoint URL"
                  error={errors?.customConfigData?.serviceNow?.baseURL?.message}
                  noMargin
                >
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Base URL of the integration"
                      error={!!errors?.customConfigData?.serviceNow?.baseURL}
                      {...register("customConfigData.serviceNow.baseURL", {
                        required: "Endpoint URL is required.",
                      })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
                <Typography
                  variant="p-t5"
                  tag="span"
                  margin="x-large 0 0 0"
                  className={styles.header}
                >
                  Authentication
                </Typography>
                <Box gap="x-large">
                  <Tile
                    title="Basic"
                    indicator="radio"
                    description="Authenticate with your username and password for simple, direct access."
                    onClick={undefined}
                    selected
                  />
                  <Tile
                    title={
                      <Box gap="small" align="center">
                        <Typography variant="p-t7" color="secondary" tag="span">
                          OAuth
                        </Typography>
                        <BadgeNext type="strong" text="soon" variant="blue" />
                      </Box>
                    }
                    indicator="radio"
                    description="Securely authenticate using a Client ID and Client Secret for token-based access."
                    onClick={undefined}
                    disabled
                  />
                </Box>

                <FormField
                  label="Username"
                  error={errors?.customConfigData?.serviceNow?.username?.message}
                  noMargin
                >
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Enter the username"
                      error={!!errors?.customConfigData?.serviceNow?.username}
                      {...register("customConfigData.serviceNow.username", {
                        required: "Username field is required.",
                      })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>

                <FormField
                  label="Password"
                  error={errors?.customConfigData?.serviceNow?.password?.message}
                  noMargin
                >
                  {({ ariaInputProps }) => (
                    <SecretInput
                      placeholder="Enter the password"
                      error={!!errors?.customConfigData?.serviceNow?.password}
                      {...register("customConfigData.serviceNow.password", {
                        required: "Password field is required.",
                      })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
              </Box>

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={creatingIntegration || updatingIntegration}
                    loading={creatingIntegration || updatingIntegration}
                  >
                    {isEditMode ? "Save" : "Create"}
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showIntegrationDrawerForm = createDrawerTrigger(IntegrationDrawerForm);
