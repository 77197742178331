import { RefObject, useEffect, useRef } from "react";

import useStickyTransform from "./useStickyTransform";

// hook to handle sticky row scroll effects
const useStickyRow = (
  isActiveSticky?: boolean,
  nextAfterSticky?: number,
  tableRef?: RefObject<HTMLDivElement>
) => {
  const rowRef = useRef<HTMLDivElement | null>(null);
  const { updateTransform, cleanup } = useStickyTransform();

  useEffect(() => {
    const tableElement = tableRef?.current;
    const rowElement = rowRef.current;

    if (!isActiveSticky || !nextAfterSticky || !tableElement) {
      return undefined;
    }

    const handleScrollEvent = (event: Event) => {
      updateTransform(
        rowElement,
        (event.currentTarget as HTMLDivElement).scrollTop,
        nextAfterSticky
      );
    };

    tableElement.addEventListener("scroll", handleScrollEvent, { passive: true });

    return () => {
      tableElement.removeEventListener("scroll", handleScrollEvent);
      cleanup();
    };
  }, [isActiveSticky, nextAfterSticky, tableRef, updateTransform, cleanup]);

  return rowRef;
};

export default useStickyRow;
