import { useCallback, useEffect, useId, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import NotFoundPage from "components/error/NotFoundPage";
import { SavedFilterView, SortStateDescriptor } from "components/Filters/types";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import { getSearchQuery } from "components/SearchInput/helpers";
import useErrorHandle from "hooks/useErrorHandle";
import useURLParams from "hooks/useURLParams";
import TablePagination from "components/Table/Pagination";
import usePagination from "components/Table/Pagination/usePagination";
import { PAGINATION_OPTIONS } from "components/Table/Pagination/constants";

import { ANALYTICS, BLUEPRINTS_LIMIT, BLUEPRINTS_LIMIT_STORAGE_KEY } from "./constants";
import { showCreateBlueprintDrawer } from "./CreateBlueprintDrawer";
import BlueprintsEmpty from "./Empty";
import BlueprintFeatureGateCallout from "./FeatureGate/Callout";
import FiltersLayoutNew from "./FiltersLayout/New";
import useBlueprintsAnalytics from "./hooksNew/useBlueprintsAnalytics";
import BlueprintsPageLayout from "./PageLayout";
import useBlueprints from "./hooksNew/useBlueprints";
import BlueprintsTable from "./Table";

const Blueprints = () => {
  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);
  const [itemsCount, setItemsCount] = useState<number>(0);
  const [searchParams] = useSearchParams();

  const paginationAnalyticsProps = useMemo(
    () => ({
      rowTotalCount: itemsCount,
    }),
    [itemsCount]
  );

  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);

  const {
    initialLoading,
    error,
    blueprints,
    loadBlueprints,
    showUpSell,
    endCursor,
    startCursor,
    noResults,
    isEmpty,
    loadingContent,
    predicates,
  } = useBlueprints();

  const {
    rowsPerPage,
    setRowsPerPage,
    from,
    to,
    lastPage,
    page,
    goToNextPage,
    goToPrevPage,
    goToFirstPage,
    goToLastPage,
    resetToInitialPage: resetPaginationToInitialPage,
  } = usePagination({
    ...ANALYTICS,
    analyticsProps: paginationAnalyticsProps,
    fetchItems: loadBlueprints,
    endCursor,
    startCursor,
    items: blueprints,
    initialLimit: BLUEPRINTS_LIMIT,
    itemsCount,
    storageKey: BLUEPRINTS_LIMIT_STORAGE_KEY,
  });

  const trackAnalytics = useBlueprintsAnalytics();

  const handleSortCallback = useCallback(
    (descriptor: SortStateDescriptor) => {
      trackAnalytics("Sort by column", { ...descriptor });
      resetPaginationToInitialPage();
    },
    [trackAnalytics, resetPaginationToInitialPage]
  );

  useEffect(() => {
    if (searchParams.size === 0 && page !== 1) {
      goToFirstPage();
    }
  }, [searchParams, page, goToFirstPage]);

  const handleOpenCreateBlueprintDrawer = () => {
    showCreateBlueprintDrawer();
  };

  const ErrorContent = useErrorHandle(error);

  const titleId = useId();

  if (ErrorContent) {
    return ErrorContent;
  }

  if (initialLoading) {
    return (
      <BlueprintsPageLayout titleId={titleId} hideActions>
        <PageLayoutSkeleton />
      </BlueprintsPageLayout>
    );
  }

  if (noResults) {
    return <NotFoundPage />;
  }

  const hasNoResultsWithFiltering = searchInput.length > 0 || predicates.length > 0;

  return (
    <BlueprintsPageLayout
      titleId={titleId}
      onCreate={handleOpenCreateBlueprintDrawer}
      showUpSell={showUpSell}
    >
      <BlueprintFeatureGateCallout />
      <FiltersLayoutNew
        predicates={predicates}
        currentSavedView={currentSavedView}
        setCurrentSavedView={setCurrentSavedView}
        itemsCount={itemsCount}
        setItemsCount={setItemsCount}
        titleId={titleId}
      >
        {isEmpty && (
          <BlueprintsEmpty
            hasNoResults={hasNoResultsWithFiltering}
            onCreate={handleOpenCreateBlueprintDrawer}
          />
        )}

        {blueprints.length > 0 && (
          <>
            <BlueprintsTable
              blueprints={blueprints}
              loadingContent={loadingContent}
              rowsPerPage={rowsPerPage}
              sortCallback={handleSortCallback}
            />

            <TablePagination
              from={from}
              to={to}
              loading={loadingContent}
              goToNextPage={goToNextPage}
              setRowsPerPage={setRowsPerPage}
              goToPrevPage={goToPrevPage}
              goToFirstPage={goToFirstPage}
              goToLastPage={goToLastPage}
              rowsPerPage={rowsPerPage}
              itemsCount={itemsCount}
              lastPage={lastPage}
              page={page}
              paginationOptions={PAGINATION_OPTIONS}
            />
          </>
        )}
      </FiltersLayoutNew>
    </BlueprintsPageLayout>
  );
};

export default Blueprints;
