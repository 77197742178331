import { SortDescriptor } from "react-aria-components";

import { CloudIntegration } from "../../types";
import { CLOUD_INTEGRATIONS_COLUMN_ID } from "../constants";

export const sortIntegrations = (items: CloudIntegration[], sortDescriptor: SortDescriptor) => {
  if (!sortDescriptor.column) {
    return items;
  }

  const isAscending = sortDescriptor.direction === "ascending";

  return [...items].sort((a, b) => {
    let valueA: string | undefined;
    let valueB: string | undefined;

    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (sortDescriptor.column as CLOUD_INTEGRATIONS_COLUMN_ID) {
      case CLOUD_INTEGRATIONS_COLUMN_ID.TYPE:
        valueA = a.__typename;
        valueB = b.__typename;
        break;
      case CLOUD_INTEGRATIONS_COLUMN_ID.NAME:
        valueA = a.name;
        valueB = b.name;
        break;
      case CLOUD_INTEGRATIONS_COLUMN_ID.SPACE:
        valueA = a.spaceDetails?.name;
        valueB = b.spaceDetails?.name;
        break;
    }

    valueA = valueA || "";
    valueB = valueB || "";

    return isAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
  });
};
