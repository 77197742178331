// TODO: check this file functionality, copied from StackNew
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import { decodeURIParam } from "utils/urls";
import { URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";

import useInitialDefaultView from "./useInitialDefaultView";
import { FILTERS_TYPE, initialSortDirection, initialSortOption } from "./ListLayout/constants";

const useFiltersVariables = () => {
  const [searchParams] = useSearchParams();

  const defaultView = useInitialDefaultView(FILTERS_TYPE);

  const defaultFilterVariables = useMemo(
    () => ({
      searchInput: defaultView.data?.search,
      sortOptionFields:
        defaultView.data?.sortOption && defaultView.data?.sortDirection
          ? [{ field: defaultView.data?.sortOption, direction: defaultView.data?.sortDirection }]
          : [{ field: initialSortOption, direction: initialSortDirection }],
      predicates: [...(defaultView.data?.predicates?.values() || [])],
    }),
    [defaultView.data]
  );

  const filterVariables = useMemo(
    () => ({
      searchInput: getSearchQuery(searchParams),
      sortOptionFields: getSortOptionFromURI(searchParams, initialSortOption, initialSortDirection),
      predicates: [...(getFiltersPredicationFromURI(searchParams)?.values() || [])],
    }),
    [searchParams]
  );

  const sortOption = decodeURIParam(searchParams.get(URL_SORT_KEY));
  const sortDirection = decodeURIParam(searchParams.get(URL_SORT_DIRECTION));

  const sortingMounted = !!sortOption && !!sortDirection;

  if (!searchParams.size) {
    return {
      filterVariables: defaultFilterVariables,
      filterVariablesLoaded: defaultView.loaded,
      sortingMounted,
    };
  }

  return { filterVariables, filterVariablesLoaded: defaultView.loaded, sortingMounted };
};

export default useFiltersVariables;
