import cx from "classnames";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type TableWrapperProps = {
  children: React.ReactNode;
  paginationSlot?: React.ReactNode;
  virtualizedListContainerRef: React.RefObject<HTMLDivElement>;
};

const TableWrapper = ({
  children,
  paginationSlot,
  virtualizedListContainerRef,
}: TableWrapperProps) => {
  return (
    <Box
      direction="column"
      zeroMinWidth
      justify="between"
      className={styles.contentWrapper}
      style={{
        height: `calc(100vh - ${virtualizedListContainerRef.current?.getBoundingClientRect().top}px)`,
      }}
    >
      <Box className={cx(styles.tableWrapper, { [styles.hasPagination]: !!paginationSlot })}>
        {children}
      </Box>
      {paginationSlot}
    </Box>
  );
};

export default TableWrapper;
