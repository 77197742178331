import { memo } from "react";
import { useShallow } from "zustand/react/shallow";

import Button from "ds/components/Button";
import useIacManagementResources from "views/Account/IacManagement/useIacManagementResources";
import useIacManagementStore from "views/Account/IacManagement/useIacManagementStore";
import useRowKeyboardNavigation from "ds/components/Table/useKeyboardNavigation/useRowKeyboardNavigation";
import useIacManagementAnalytics from "views/Account/IacManagement/useIacManagementAnalytics";

import IacManagementTableBodyRow from "..";
import useIacTableStore from "../../useIacTableStore";
import IacManagementTableRowServiceCell from "./Cell";
import styles from "../styles.module.css";
import { selectVisibleColumns } from "../../useIacTableStore/tableMetaSelectors";
import { selectTableRowByIndex } from "../../useIacTableStore/tableDataSelectors";

type IacManagementTableRowServiceProps = {
  rowIndex: number;
  measureRef: (node: HTMLDivElement) => void;
  rowsPerPage: number;
};

const IacManagementTableRowService = ({
  rowIndex,
  measureRef,
  rowsPerPage,
}: IacManagementTableRowServiceProps) => {
  const { analyticPage } = useIacManagementAnalytics();

  const { columns, row, loadResources } = useIacTableStore(
    useShallow((state) => ({
      columns: selectVisibleColumns(state),
      row: selectTableRowByIndex(state, rowIndex),
      loadResources: state.loadResources,
    }))
  );

  const { groupBy, parentStackId } = useIacManagementStore(
    useShallow((state) => ({
      groupBy: state.groupBy,
      parentStackId: state.parentStackId,
    }))
  );

  const { fetchData, loading } = useIacManagementResources();
  const keyboardProps = useRowKeyboardNavigation({ isGroup: false });

  const handleLoadMore = () => {
    if (!("groupId" in row)) {
      return;
    }

    loadResources({ groupId: row.groupId, groupBy, parentStackId, rowsPerPage }, fetchData);
  };

  return (
    <IacManagementTableBodyRow
      {...keyboardProps}
      dataIndex={rowIndex}
      forwardRef={measureRef}
      className={styles.serviceRow}
    >
      {columns.map((column, i) => (
        <IacManagementTableRowServiceCell
          key={`${row.id}-${column.id}-service-cell`}
          stickyLeft={i === 0}
        >
          {i === 0 && (
            <Button
              variant="secondary"
              onPress={handleLoadMore}
              loading={loading}
              size="medium"
              analyticsPage={analyticPage}
              analyticsTitle="Load more rows clicked"
            >
              Load {rowsPerPage} more resources
            </Button>
          )}
        </IacManagementTableRowServiceCell>
      ))}
    </IacManagementTableBodyRow>
  );
};

export default memo(IacManagementTableRowService);
