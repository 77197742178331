import cx from "classnames";
import { ReactNode } from "react";

import { SparksFilled } from "components/icons/generated";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";

import styles from "./styles.module.css";

type RunSummarizationTriggerProps = {
  className?: string;
  disabled?: boolean;
  size?: "small" | "medium";
  onPress: () => void;
  children: string;
  tooltipText?: ReactNode;
};

const RunSummarizationTrigger = ({
  className,
  children,
  disabled,
  size = "small",
  onPress,
  tooltipText,
}: RunSummarizationTriggerProps) => {
  return (
    <Tooltip
      active={!!tooltipText}
      on={(props) => (
        <Button
          className={cx(styles.button, className)}
          startIcon={SparksFilled}
          disabled={disabled}
          size={size}
          variant="contrast"
          onPress={onPress}
          {...props}
        >
          {children}
        </Button>
      )}
      widthMode="maxWidthSm"
    >
      {tooltipText}
    </Tooltip>
  );
};

export default RunSummarizationTrigger;
