import { useEffect, useRef, useState } from "react";
import cx from "classnames";

import MetaInfoListItem from "components/MetaInfoList/Item";
import Markdown from "components/markdown/Markdown";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import LinkAdd from "ds/components/Link/LinkAdd";
import Typography from "ds/components/Typography";
import { Maybe } from "types/generated";
import { isElementOverflown } from "utils/element";

import styles from "./styles.module.css";

type DescriptionDetailsProps = {
  label?: string;
  description?: Maybe<string>;
  onAddDescription?: () => void;
  addDescriptionUrl?: string;
  onOpenFullDescription?: () => void;
  noMargin?: boolean;
};

const DescriptionDetails = ({
  label = "Description",
  description,
  onAddDescription,
  addDescriptionUrl,
  onOpenFullDescription,
  noMargin = false,
}: DescriptionDetailsProps) => {
  const markdownRef = useRef<HTMLElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  const hasAction = Boolean(onAddDescription || addDescriptionUrl);

  useEffect(() => {
    if (markdownRef.current) {
      setIsOverflown(isElementOverflown(markdownRef.current));
    }
  }, [description]);

  return (
    <FormField label={label} noMargin={noMargin}>
      {description && (
        <Box fullWidth relative>
          <Markdown
            ref={markdownRef}
            markup={description}
            className={cx(styles.previewDescription, {
              [styles.showFull]: !onOpenFullDescription,
            })}
          />
          {isOverflown && onOpenFullDescription && (
            <>
              <div className={styles.gradientFrame}>&nbsp;</div>
              <Button
                variant="secondary"
                onPress={onOpenFullDescription}
                className={styles.showButton}
              >
                Show full description
              </Button>
            </>
          )}
        </Box>
      )}

      {!description && !hasAction && (
        <MetaInfoListItem>
          <Typography tag="span" variant="p-body2" color="disabled">
            No description
          </Typography>
        </MetaInfoListItem>
      )}

      {!description && hasAction && (
        <MetaInfoListItem>
          <LinkAdd text="Add description" onClick={onAddDescription} to={addDescriptionUrl} />
        </MetaInfoListItem>
      )}
    </FormField>
  );
};

export default DescriptionDetails;
