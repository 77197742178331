import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { PERMISSIONS_COLUMN_ID } from "../constants";
import PermissionsListItemDescriptionCell from "./DescriptionCell";
import PermissionsListItemDeleteActionCell from "./DeleteActionCell";
import PermissionsListItemActionCell from "./ActionCell";
import PermissionsListItemSubjectCell from "./SubjectCell";
import { Permission } from "../types";

const CELLS = {
  [PERMISSIONS_COLUMN_ID.DESCRIPTION]: PermissionsListItemDescriptionCell,
  [PERMISSIONS_COLUMN_ID.ACTION]: PermissionsListItemActionCell,
  [PERMISSIONS_COLUMN_ID.SUBJECT]: PermissionsListItemSubjectCell,
  [PERMISSIONS_COLUMN_ID.DELETE_ACTION]: PermissionsListItemDeleteActionCell,
};

type PermissionsListItemProps = {
  permission: Permission;
};

const PermissionsListItem = (props: PermissionsListItemProps) => {
  const { permission } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as PERMISSIONS_COLUMN_ID];
        const uniqueKey = `${id}-${permission.id}`;

        const childrenComponent = <Component permission={permission} />;

        return (
          <TableCell key={uniqueKey} id={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(PermissionsListItem);
