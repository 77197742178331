import { useCallback, useEffect, useRef } from "react";
import useLocalStorage from "@rehooks/local-storage";

import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageSpaces } from "hooks/useAnalytics/pages/spaces";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";

import SpacesPageLayout from "./PageLayout";
import { SpacesLayoutMode } from "./types";
import { SpacesViewContextProvider } from "./Context";
import { DEFAULT_SPACES_LAYOUT_MODE, SPACES_LAYOUT_MODE_LOCAL_STORAGE_KEY } from "./constants";
import SpacesDiagram from "./Diagram";
import SpacesTreeGrid from "./TreeGrid";
import { SpacesContext } from "../SpacesProvider";
import { SpacesViewActionsProvider } from "./ActionsContext";

const Spaces = () => {
  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageSpaces.Spaces,
  });

  const [layoutMode, setLayoutMode] = useLocalStorage<SpacesLayoutMode>(
    SPACES_LAYOUT_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_SPACES_LAYOUT_MODE
  );

  const { spaces, error, startSpacesPolling, stopSpacesPolling } = useTypedContext(SpacesContext);

  const urlParams = useURLParams();

  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const searchQueryRef = useRef(searchQuery);

  useEffect(() => {
    if (
      layoutMode === SpacesLayoutMode.List &&
      searchQuery &&
      searchQuery !== searchQueryRef.current
    ) {
      trackSegmentEvent("List Searched");
    }

    searchQueryRef.current = searchQuery;
  }, [layoutMode, searchQuery, trackSegmentEvent]);

  useEffect(() => {
    startSpacesPolling();

    return () => {
      stopSpacesPolling();
    };
  }, [startSpacesPolling, stopSpacesPolling]);

  const handleSetLayoutMode = useCallback(
    (mode: SpacesLayoutMode) => {
      setLayoutMode(mode);

      trackSegmentEvent("View Changed", {
        view: mode,
      });
    },
    [setLayoutMode, trackSegmentEvent]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (!spaces) {
    return <NotFoundPage />;
  }

  return (
    <SpacesViewContextProvider>
      <SpacesViewActionsProvider>
        <SpacesPageLayout setLayoutMode={handleSetLayoutMode} layoutMode={layoutMode}>
          {layoutMode === SpacesLayoutMode.List ? <SpacesTreeGrid /> : <SpacesDiagram />}
        </SpacesPageLayout>
      </SpacesViewActionsProvider>
    </SpacesViewContextProvider>
  );
};

export default Spaces;
