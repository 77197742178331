import { useNavigate, useParams } from "react-router-dom";

import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import NotFoundPage from "components/error/NotFoundPage";
import { Clock, Space } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import ViewHeader from "components/ViewHeader";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useErrorHandle from "hooks/useErrorHandle";
import useBlueprint from "shared/Blueprint/useBlueprint";
import { BillingTierFeature } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import { FILTERS_ORDER_SETTINGS_KEY } from "views/Account/Blueprints/constants";
import BlueprintFeatureGateCallout from "views/Account/Blueprints/FeatureGate/Callout";
import useTierFeature from "views/Account/hooks/useTierFeature";

import BlueprintTemplateBody from "../../TemplateBody";
import { versions } from "../gql";
import { showVersionDeleteConfirmation } from "../VersionDeleteConfirmation";
import { showBlueprintVersionDeprecateModal } from "../VersionDeprecateConfirmation";
import { showBlueprintVersionDetailsDrawer } from "../VersionDetailsDrawer";
import BlueprintVersionStatusBadge from "../components/VersionStatusBadge";
import { showBlueprintVersionEditDetailsDrawer } from "../VersionEditDetailsDrawer";
import { showBlueprintVersionDuplicateDrawer } from "../VersionDuplicateDrawer";

const BlueprintVersion = () => {
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);
  const navigate = useNavigate();

  const { blueprintId, version } = useParams<{ blueprintId: string; version: string }>();

  const { error, loading, data } = useBlueprint(blueprintId); // TODO: load version instead
  const versionData = versions.find((v) => v.version === version) || versions[0]; // TODO: Replace with real data

  const handleOpenEditDrawer = () => {
    showBlueprintVersionEditDetailsDrawer({ version: versionData });
  };

  const handleOpenDuplicateDrawer = () => {
    showBlueprintVersionDuplicateDrawer({ version: versionData });
  };

  const handleOpenDetailsDrawer = () => {
    if (data?.blueprint) {
      showBlueprintVersionDetailsDrawer({
        blueprint: data.blueprint,
        version: versionData,
      });
    }
  };

  const handleDeprecateClick = () => {
    showBlueprintVersionDeprecateModal({ version: versionData });
  };

  useBreadcrumbs(
    [
      {
        title: "Blueprints",
        link: getBreadcrumbsBackUrl(`/blueprint/${blueprintId}`, FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: data?.blueprint?.name
          ? `${data.blueprint.name} - ${version /* TODO: get from api */}`
          : "",
      },
    ],
    [data?.blueprint?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.blueprint) {
    return <PageLoading />;
  }

  if (!data?.blueprint) {
    return <NotFoundPage />;
  }

  const blueprint = data.blueprint;
  const blueprintSchema = data.blueprintSchema;
  const canManageBlueprint =
    hasSpaceManageAccess(blueprint.space.accessLevel) && !blueprint.deleted;

  const handleDeleteClick = () => {
    // TODO: implement
    showVersionDeleteConfirmation({ version: versionData.version }).then(() => navigate(".."));
  };

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{versionData.version}</ViewHeaderTitle>
            <BlueprintVersionStatusBadge status={versionData.status} />
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <Button variant="secondary" onPress={handleOpenDetailsDrawer}>
              Details
            </Button>

            {canManageBlueprint && (
              <DropdownMenuEllipsis tooltip="Blueprint actions" dotsSize="medium">
                {isFeatureActive && (
                  <DropdownMenuItem onAction={handleOpenEditDrawer}>
                    Edit version details
                  </DropdownMenuItem>
                )}

                {isFeatureActive && (
                  <DropdownMenuItem onAction={handleOpenDuplicateDrawer}>
                    Duplicate
                  </DropdownMenuItem>
                )}
                {isFeatureActive && (
                  <DropdownMenuItem onAction={handleDeprecateClick} danger>
                    Deprecate
                  </DropdownMenuItem>
                )}

                <DropdownMenuItem onAction={handleDeleteClick} danger>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuEllipsis>
            )}
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={Space}
              linkText={blueprint.space.name}
              href={`/spaces/${blueprint.space.id}`}
            />
            <MetaInfoListItem icon={Clock}>
              <Timestamp timestamp={versionData.updatedAt} />
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
      </ViewHeader>
      <BlueprintFeatureGateCallout />
      <BlueprintTemplateBody blueprint={blueprint} blueprintSchema={blueprintSchema} />
    </>
  );
};

export default BlueprintVersion;
