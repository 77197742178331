import cx from "classnames";

import DraggableItem from "ds/components/DraggableItem";

import DragDropListItem from "./Item";
import { OptionValue } from "./types";
import styles from "./styles.module.css";
type DragDropListOverlayProps = {
  option?: OptionValue;
  className?: string;
};

const DragDropListOverlay = ({ option, className }: DragDropListOverlayProps) => {
  if (!option) {
    return null;
  }

  const Component = option.component;

  return (
    <DraggableItem
      noBorder
      size="small"
      margin="0 0 x-large 0"
      className={cx(styles.draggable, className)}
      tabIndex={-1}
    >
      <DragDropListItem
        text={option.title}
        moreActions={option.moreActions}
        infoTooltip={option.infoTooltip}
      >
        <Component />
      </DragDropListItem>
    </DraggableItem>
  );
};

export default DragDropListOverlay;
