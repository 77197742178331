import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUser } from "types/generated";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useTypedFlags from "hooks/useTypedFlags";
import { showAssignRoleDrawer } from "views/Account/Settings/User/AssignRoleDrawer";

import { showUserListRevokeAccessConfirmation } from "./ConfirmationModal";
import styles from "./styles.module.css";

type UsersListItemDropdownProps = {
  handleUserDetailsClick: (details: ManagedUser) => unknown;
  item: ManagedUser;
};

const UsersListItemDropdown = ({ handleUserDetailsClick, item }: UsersListItemDropdownProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { customAccessRoles } = useTypedFlags();

  const confirmationModalShow = () => {
    showUserListRevokeAccessConfirmation({
      id: item.id,
      userName: item.username,
    });
  };

  const handleAccessDetailsClick = () => {
    handleUserDetailsClick(item);
  };

  const assignRoles = () => {
    showAssignRoleDrawer({ userId: item.id });
  };

  return (
    <div className={styles.wrapper}>
      <DropdownMenuEllipsis tooltip="User actions">
        <DropdownMenuItem onAction={handleAccessDetailsClick}>
          {customAccessRoles ? "See details" : "Access details"}
        </DropdownMenuItem>
        {customAccessRoles && (
          <DropdownMenuItem onAction={assignRoles}>Manage roles</DropdownMenuItem>
        )}
        {viewer.id !== item.username && (
          <DropdownMenuItem danger onAction={confirmationModalShow}>
            Revoke access
          </DropdownMenuItem>
        )}
      </DropdownMenuEllipsis>
    </div>
  );
};

export default UsersListItemDropdown;
