import { memo } from "react";

import BadgeNext from "ds/components/BadgeNext";
import { ArrowsLeftRight } from "components/icons/generated";

import { RenderGroupRowProps } from "../../components/Table/types";
import IacManagementEmptyCellValue from "../../components/CellEmptyValue";
import useCellDriftedFilterAction from "../useCellDriftedFilterAction";
import IacManagementTableDropdownCell from "../../components/Table/Row/DropdownCell";

type IacManagementTableGroupRowCellDriftedProps = Pick<RenderGroupRowProps, "row">;

const IacManagementTableGroupRowCellDrifted = memo(
  ({ row }: IacManagementTableGroupRowCellDriftedProps) => {
    const driftedFilterAction = useCellDriftedFilterAction(!!row.drifted);

    return (
      <IacManagementTableDropdownCell actions={driftedFilterAction}>
        {(row.drifted && (
          <BadgeNext
            type="weak"
            variant="yellow"
            startIcon={ArrowsLeftRight}
            text={`${row.drifted}`}
          />
        )) || <IacManagementEmptyCellValue />}
      </IacManagementTableDropdownCell>
    );
  }
);

IacManagementTableGroupRowCellDrifted.displayName = "IacManagementTableGroupRowCellDrifted";

export default IacManagementTableGroupRowCellDrifted;
