import { memo } from "react";

import BadgeNext from "ds/components/BadgeNext";
import { ArrowsLeftRight } from "components/icons/generated";

import { type RenderResourceRowCellProps } from ".";
import IacManagementEmptyCellValue from "../../components/CellEmptyValue";
import IacManagementTableDropdownCell from "../../components/Table/Row/DropdownCell";
import useCellDriftedFilterAction from "../useCellDriftedFilterAction";

type IacManagementTableResourceRowCellDriftedProps = Pick<RenderResourceRowCellProps, "row">;

const IacManagementTableResourceRowCellDrifted = memo(
  ({ row }: IacManagementTableResourceRowCellDriftedProps) => {
    const driftedFilterAction = useCellDriftedFilterAction(!!row.drifted);

    return (
      <IacManagementTableDropdownCell actions={driftedFilterAction}>
        {(row.drifted && (
          <BadgeNext type="weak" variant="yellow" startIcon={ArrowsLeftRight} iconOnly />
        )) || <IacManagementEmptyCellValue />}
      </IacManagementTableDropdownCell>
    );
  }
);

IacManagementTableResourceRowCellDrifted.displayName = "IacManagementTableResourceRowCellDrifted";

export default IacManagementTableResourceRowCellDrifted;
