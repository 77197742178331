import NiceModal from "@ebay/nice-modal-react";
import { useRef } from "react";

import IacManagement from "views/Account/IacManagement";
import makeIacTableStore from "views/Account/IacManagement/components/Table/useIacTableStore/makeIacTableStore";
import { TABLE_COLUMNS } from "views/Account/IacManagement/ListLayout/constants";
import { IacManagementPageStoreContext } from "views/Account/IacManagement/PageStoreContext";
import { IacManagementTableStoreContext } from "views/Account/IacManagement/TableStoreContext";
import makeIacManagementStore from "views/Account/IacManagement/useIacManagementStore/makeIacManagementStore";

type StackViewResourcesIacManagementProps = {
  stackId: string;
};

const StackViewResourcesIacManagement = ({ stackId }: StackViewResourcesIacManagementProps) => {
  const pageStore = useRef(
    makeIacManagementStore({
      storageKey: "stack-view-iac-resources-page-store",
      parentStackId: stackId,
    })
  ).current;
  const tableStore = useRef(
    makeIacTableStore({
      storageKey: "stack-view-iac-resources-table-store",
      initialColumns: TABLE_COLUMNS,
    })
  ).current;

  return (
    <IacManagementPageStoreContext.Provider value={pageStore}>
      <IacManagementTableStoreContext.Provider value={tableStore}>
        <NiceModal.Provider>
          <IacManagement />
        </NiceModal.Provider>
      </IacManagementTableStoreContext.Provider>
    </IacManagementPageStoreContext.Provider>
  );
};

export default StackViewResourcesIacManagement;
