import cx from "classnames";
import { forwardRef } from "react";

import Button from "../Button";
import styles from "./styles.module.css";

export type SkipFocusButtonProps = {
  label: string;
  skipTo: HTMLElement | null;
  className?: string;
};

const SkipFocusButton = forwardRef(function SkipFocusButton(
  { label, skipTo, className, ...props }: SkipFocusButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const onPress = () => {
    skipTo?.focus();
  };

  return (
    <Button
      ref={ref}
      className={cx(className, styles.skipFocusButton)}
      onPress={onPress}
      variant="secondary"
      {...props}
    >
      {label}
    </Button>
  );
});

SkipFocusButton.displayName = "DS.SkipFocusButton";

export default SkipFocusButton;
