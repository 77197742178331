import { GET_STARTED_FEATURES } from "views/Account/LaunchPad/ChecklistSelfHosted/features";
import useSelfHostedOnboardingProgress from "views/Account/LaunchPad/ChecklistSelfHosted/useSelfHostedOnboardingProgress";

import DashboardOverviewColumnOnboardingLayout from "../OnboardingLayout";

const DashboardOverviewColumnOnboardingSelfHosted = () => {
  // TODO: wait for API to be implemented
  // const [isOnboardingFinishedLocalStorage] = useLocalStorage(
  //   "dashboardWidgetOnboardingFinished",
  //   false
  // );

  const {
    onboardingProgressInitialLoading,
    progressByFeature,
    isOnboardingComplete,
    error,
    refetch,
  } = useSelfHostedOnboardingProgress();

  return (
    <DashboardOverviewColumnOnboardingLayout
      onboardingProgressInitialLoading={onboardingProgressInitialLoading}
      progressByFeature={progressByFeature}
      isOnboardingComplete={isOnboardingComplete}
      error={error}
      refetch={refetch}
      features={GET_STARTED_FEATURES}
    />
  );
};

export default DashboardOverviewColumnOnboardingSelfHosted;
