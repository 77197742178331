import { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpaces from "components/FormFields/Spaces";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTypedContext from "hooks/useTypedContext";
import { getDrawerFormFix } from "utils/css";
import { getDocsUrl } from "utils/getDocsUrl";

import { getManagementStrategy } from "../../helpers";
import { UserManagementActivationStatus } from "../../types";
import { ADD_GROUP_DRAWER_TEST_ID } from "./constants";
import { AddGroupFields } from "./types";
import useAddGroup from "./useAddGroup";

type IdpGroupMappingMapGroupDrawerProps = {
  activationStatus: UserManagementActivationStatus;
};

const IdpGroupMappingMapGroupDrawer = createDrawer(
  ({ activationStatus }: IdpGroupMappingMapGroupDrawerProps) => {
    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const trackSegmentAnalyticsEvent = useAnalytics({
      page: AnalyticsPageOrganization.OrganizationIdpGroupMapping,
      defaultCallbackTrackProperties: {
        managementStrategy: getManagementStrategy(activationStatus),
      },
    });

    const groupMapForm = useForm<AddGroupFields>({
      defaultValues: {
        name: "",
        spaces: [{ space: undefined, spaceAccessLevel: undefined }],
      },
      mode: "onChange",
    });
    const drawer = useModal();

    const {
      register,
      handleSubmit,
      formState: { errors, isDirty },
    } = groupMapForm;

    const { onCreate } = useAddGroup();

    const handleCloseDrawer = () => {
      trackSegmentAnalyticsEvent("Close map IdP group");
    };

    const onSubmit: SubmitHandler<AddGroupFields> = (formData) => {
      trackSegmentAnalyticsEvent("Submit map IdP group");

      const input = {
        groupName: formData.name,
        accessRules: formData.spaces,
      };
      onCreate(input)
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: `Group access was added`,
            });

            handleCloseDrawer();
            drawer.hide();
          }
        })
        .catch(onError);
    };

    return (
      <DrawerForm
        isDirty={isDirty}
        dataTestId={ADD_GROUP_DRAWER_TEST_ID}
        onClose={handleCloseDrawer}
      >
        <FormProvider {...groupMapForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Map IdP group" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight hasStickyFooter>
              <FormField
                label="Group name"
                tooltipMode="parent"
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Group name</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      Type in any group name that you have defined in your IdP (e.g. GitHub). It has
                      to be exactly the same.
                      <ReadMoreDocsLink
                        docsUrl={getDocsUrl("/concepts/user-management/user.html#groups")}
                      />
                    </TooltipModalBody>
                  </>
                }
                tooltipInfoVariant="modal"
                error={errors?.name?.message}
                helperText="Name needs to match the one from your IdP"
              >
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter the name here"
                    error={!!errors?.name}
                    {...register("name", {
                      required: "Name field is required.",
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldSpaces
                analyticsPage={AnalyticsPageOrganization.OrganizationIdpGroupMapping}
              />

              <DrawerFooter sticky>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button variant="primary" type="submit">
                    Add
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showIdpGroupMappingMapGroupDrawer = createDrawerTrigger(IdpGroupMappingMapGroupDrawer);
