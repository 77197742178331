import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import Typography from "ds/components/Typography";

import { USER_ROLE_BINDING_DELETE } from "./gql";

type UnassignConfirmationProps = {
  id: string;
  name: string;
};

const UnassignConfirmation = create(function UnassignConfirmation({
  id,
  name,
}: UnassignConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteView, { loading, data }] = useMutation(USER_ROLE_BINDING_DELETE, {
    variables: { id },
    refetchQueries: ["UserRoleBindingsWithRoles"],
    awaitRefetchQueries: true,
  });

  const onDelete = () => {
    deleteView()
      .then(() => {
        reportSuccess({ message: `${name} role successfully unassigned` });
        modal.resolve();
        modal.hide();
      })
      .catch(onError);
  };

  const handleOnConfirm = () => {
    onDelete();
    modal.hide();
  };

  return (
    <DeleteConfirmationModal
      isLoading={loading}
      isDismissable={!loading && !data?.userRoleBindingDelete?.id}
      title="Unassign role"
      confirmationButtonLabel="Delete role"
      onConfirm={handleOnConfirm}
    >
      <ConfirmationModalMessage>
        Are you sure you want to unassign the role{" "}
        <Typography tag="span" variant="p-t5">
          {name}
        </Typography>
        ? You cannot undo this action.
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showUnassignConfirmation = (props: UnassignConfirmationProps) =>
  show(UnassignConfirmation, props);
