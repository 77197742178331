import { Fragment } from "react/jsx-runtime";

import Box from "ds/components/Box";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

const links = [
  { title: "Terms", href: getDocsUrl("/legal/terms") },
  { title: "Privacy", href: getDocsUrl("/legal/privacy") },
  { title: "Security", href: getDocsUrl("/product/security") },
  { title: "Contact", href: "https://spacelift.io/contact" },
];

const SystemMessageLinks = () => {
  return (
    <Box gap="large">
      {links.map((link, index) => (
        <Fragment key={link.title}>
          {index !== 0 && (
            <Typography tag="span" variant="p-body2" color="secondary">
              &bull;
            </Typography>
          )}
          <Link href={link.href} target="_blank" rel="noopener noreferrer">
            {link.title}
          </Link>
        </Fragment>
      ))}
    </Box>
  );
};

export default SystemMessageLinks;
