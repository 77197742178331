import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useState } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import { reorderMultipleDNDLists } from "utils/dnd";
import { ColumnMode } from "components/DragDropList/types";
import Tile from "ds/components/Tile";
import CardWrapper from "components/CardWrapper";
import Input from "ds/components/Input";
import FormField from "ds/components/Form/Field";
import Toggle from "ds/components/Toggle";

import DashboardManageDrawerItems from "./Items";
import {
  DashboardColumns,
  DashboardConfig,
  DashboardOverviewStaticColumn,
  OverviewStaticWidget,
  Widget,
} from "../types";
import styles from "./styles.module.css";
import { DEFAULT_TAB_IDS, DefaultDashboardTabs, OVERVIEW_STATIC_WIDGETS } from "../constants";

type DashboardManageDrawerTabProps = {
  tab: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  managementDrawerConfig: DashboardConfig;
  updateManagementDrawerTabColumnsConfig: (tab: string, columns: DashboardColumns) => void;
  updateManagementDrawerTabColumnModeConfig: (tab: string, columns: ColumnMode) => void;
  updateManagementDrawerTabOverviewColumnConfig: (column: DashboardOverviewStaticColumn) => void;
  changeTabName: (id: string, value: string) => void;
};

const DashboardManageDrawerTab = ({
  tab,
  inputRef,
  managementDrawerConfig,
  updateManagementDrawerTabColumnsConfig,
  updateManagementDrawerTabColumnModeConfig,
  updateManagementDrawerTabOverviewColumnConfig,
  changeTabName,
}: DashboardManageDrawerTabProps) => {
  const [isDragActive, setIsDragActive] = useState(false);

  const item = managementDrawerConfig[tab];

  const handleDragStart = () => {
    setIsDragActive(true);
  };

  const handleDragEnd = (result: DropResult) => {
    setIsDragActive(false);

    if (!result.destination || !item) {
      return;
    }

    const { left, right } = reorderMultipleDNDLists(
      {
        left: item?.left,
        right: item?.right,
      },
      result.source.droppableId,
      result.destination.droppableId,
      result.source.index,
      result.destination.index
    );

    updateManagementDrawerTabColumnsConfig(tab, {
      left,
      right,
    });
  };

  const handleVisibilityChange = (side: "left" | "right") => (id: Widget, hidden: boolean) => {
    updateManagementDrawerTabColumnsConfig(
      tab,
      side === "left"
        ? {
            left: item.left.map((item) => (item.value === id ? { ...item, hidden } : item)),
            right: item.right,
          }
        : {
            left: item.left,
            right: item.right.map((item) => (item.value === id ? { ...item, hidden } : item)),
          }
    );
  };

  const handleColumnModeChange = (columnMode: ColumnMode) => {
    updateManagementDrawerTabColumnModeConfig(tab, columnMode);
  };

  const handleOverviewWidgetVisibility = (value: OverviewStaticWidget, hidden: boolean) => {
    if (managementDrawerConfig["overview"].overviewColumn) {
      updateManagementDrawerTabOverviewColumnConfig(
        managementDrawerConfig["overview"].overviewColumn?.map((item) =>
          item.value === value ? { ...item, hidden } : item
        )
      );
    }
  };

  const titleError =
    !DEFAULT_TAB_IDS.includes(tab) && !item.title ? "Title is required." : undefined;

  return (
    <Box direction="column" grow="1" fullWidth>
      {!DEFAULT_TAB_IDS.includes(tab) && (
        <CardWrapper variant="filled">
          <FormField error={titleError} label="Title" fullWidth noMargin>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Type view title here"
                error={!!titleError}
                ref={inputRef}
                value={item.title}
                onChange={(e) => changeTabName(tab, e.target.value)}
                {...ariaInputProps}
              />
            )}
          </FormField>
        </CardWrapper>
      )}
      <Box gap="x-large" padding={!DEFAULT_TAB_IDS.includes(tab) ? "x-large 0 0 0" : undefined}>
        <Tile
          title="Full width layout"
          selected={item.columnMode === ColumnMode.Single}
          indicator="radio"
          onClick={() => handleColumnModeChange(ColumnMode.Single)}
        >
          <Box padding="large" margin="medium 0 0 0" className={cx(styles.columnMode, styles.full)}>
            <div className={styles.cell} />
            <div className={styles.cell} />
          </Box>
        </Tile>
        <Tile
          title="Two columns layout"
          selected={item.columnMode === ColumnMode.Double}
          indicator="radio"
          onClick={() => handleColumnModeChange(ColumnMode.Double)}
        >
          <Box
            padding="large"
            margin="medium 0 0 0"
            className={cx(styles.columnMode, styles.double)}
          >
            <div className={styles.cell} />
            <div className={styles.cell} />
            <div className={styles.cell} />
            <div className={styles.cell} />
          </Box>
        </Tile>
      </Box>
      {tab === DefaultDashboardTabs.Overview && item.overviewColumn?.length && (
        <Box direction="column" gap="x-large" padding="x-large 0 0 0">
          {item.overviewColumn.map((item) => (
            <Toggle
              key={item.value}
              isSelected={!item.hidden}
              onChange={(isSelected) => handleOverviewWidgetVisibility(item.value, !isSelected)}
              variant="switch"
            >
              {`Show ${OVERVIEW_STATIC_WIDGETS[item.value]}`}
            </Toggle>
          ))}
        </Box>
      )}
      {item && (
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <Box direction="column" padding="x-large 0 0 0" gap="large" fullWidth>
            <DashboardManageDrawerItems
              isDragActive={isDragActive}
              title={item.columnMode === ColumnMode.Double ? "Column 1" : undefined}
              type="left"
              items={item?.left}
              onVisibilityChange={handleVisibilityChange("left")}
            />
            {item.columnMode === ColumnMode.Double && (
              <DashboardManageDrawerItems
                isDragActive={isDragActive}
                title="Column 2"
                type="right"
                items={item?.right}
                onVisibilityChange={handleVisibilityChange("right")}
              />
            )}
          </Box>
        </DragDropContext>
      )}
    </Box>
  );
};

export default DashboardManageDrawerTab;
