import React from "react";

import DocumentationIconButton from "components/DocumentationIconButton";
import SearchInput from "components/SearchInput";
import { WebhooksTierInfo } from "components/TierInfo/WebhooksTierInfo";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import LinkButton from "ds/components/Button/LinkButton";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { getDocsUrl } from "utils/getDocsUrl";
import { AccountContext } from "views/AccountWrapper";

import { SpacesContext } from "../SpacesProvider";
import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";

type WebhooksPageLayoutProps = {
  children: React.ReactNode;
};

const WebhooksPageLayout = ({ children }: WebhooksPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Webhooks · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Webhooks</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <SearchInput
            placeholder="Search webhooks..."
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />

          <DocumentationIconButton
            href={getDocsUrl("/integrations/webhooks.html")}
            tooltipText="Go to webhooks documentation"
          />

          {hasEntityCreateAccess && (
            <LinkButton to="/new/webhook" variant="primary">
              Create webhook
            </LinkButton>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>

      <WebhooksTierInfo />

      {children}
    </>
  );
};

export default WebhooksPageLayout;
