import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";

import CollapsibleList from "components/CollapsibleList";
import FormToggleField from "ds/components/Form/ToggleField";
import Input from "ds/components/Input";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import { AccessSection } from "./types";

type RoleFullScreenFormSectionProps = AccessSection;

const RoleFullScreenFormSection = ({ title, id, permissions }: RoleFullScreenFormSectionProps) => {
  const [searchValue, setSearchValue] = useState("");
  const { control, watch, setValue } = useFormContext();
  const sectionsValues = watch(`permissions.${id}`);
  const permissionsCount = permissions.length;
  const selectedCount = (Object.values(sectionsValues || {}) as boolean[]).filter(Boolean).length;

  const selectAllFields = useCallback(() => {
    const newValue = selectedCount < permissionsCount;
    permissions.forEach((role) => setValue(`permissions.${id}.${role.action}`, newValue));
  }, [selectedCount, permissionsCount, permissions, id, setValue]);

  // TODO: update when available permissions list returned from BE
  const filteredPermissions = useMemo(
    () => permissions.filter((role) => `${id}:${role.id}`.includes(searchValue.trim())),
    [permissions, searchValue, id]
  );

  return (
    <CollapsibleList scrollIntoView ariaLevel={3} title={title} count={selectedCount}>
      <Box direction="column" gap="x-large">
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Type to search permissions"
        />

        {!!filteredPermissions.length && (
          <FormToggleField
            variant="checkbox"
            title="Select all"
            titleVariant="p-body3"
            checked={selectedCount === permissionsCount}
            isIndeterminate={selectedCount > 0 && selectedCount !== permissionsCount}
            onChange={() => selectAllFields()}
          />
        )}

        {filteredPermissions.map((role) => (
          <Controller
            key={role.id}
            name={`permissions.${id}.${role.action}`}
            control={control}
            render={({ field }) => (
              <FormToggleField
                variant="checkbox"
                title={`${id}:${role.id}`}
                checked={field.value}
                onChange={field.onChange}
                description={role.description}
              />
            )}
          />
        ))}

        {searchValue && !filteredPermissions.length && (
          <Typography tag="p" variant="p-body3">
            There are no matching permissions. Try with different keyword.
          </Typography>
        )}
      </Box>
    </CollapsibleList>
  );
};

export default RoleFullScreenFormSection;
