import { useCallback, useEffect, useRef } from "react";

type UseWidthManagerOptions = {
  initialWidth: number;
  minWidth?: number;
  maxWidth?: number;
  collapseWidth?: number;
  onWidthChange?: (newWidth: number) => void;
  /**
   * Callback when the panel is collapsed.
   */
  onCollapse?: () => void;
  /**
   * Callback when the panel is expanded.
   */
  onExpand?: () => void;
};

const useCollapsibleBarWidthManager = ({
  initialWidth,
  minWidth = 200,
  maxWidth = 600,
  collapseWidth = 33,
  onWidthChange,
  onExpand,
  onCollapse,
}: UseWidthManagerOptions) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const widthRef = useRef<number>(initialWidth);
  const isCollapsedRef = useRef(false);

  const setWidth = useCallback(
    (width: number) => {
      if (inputRef.current) {
        inputRef.current.setAttribute("aria-valuetext", `${width} pixels`);
        inputRef.current.setAttribute("value", `${width}`);
      }
      widthRef.current = width;

      onWidthChange?.(width);
    },
    [onWidthChange]
  );

  const handleExpand = useCallback(() => {
    isCollapsedRef.current = false;
    onExpand?.();
    return initialWidth;
  }, [onExpand, initialWidth]);

  const handleCollapse = useCallback(() => {
    isCollapsedRef.current = true;
    onCollapse?.();
    return collapseWidth;
  }, [onCollapse, collapseWidth]);

  const toggleExpand = useCallback(() => {
    if (isCollapsedRef.current) {
      setWidth(handleExpand());
    } else {
      setWidth(handleCollapse());
    }
  }, [handleCollapse, handleExpand, setWidth]);

  useEffect(() => {
    setWidth(initialWidth);
  }, [initialWidth, setWidth]);

  return {
    setWidth,
    toggleExpand,
    resizeBarProps: {
      inputRef,
      widthRef,
      isCollapsedRef,
      minWidth,
      maxWidth,
      collapseWidth,
      onCollapse: handleCollapse,
      onExpand: handleExpand,
      onWidthChange: setWidth,
    },
  };
};

export default useCollapsibleBarWidthManager;
