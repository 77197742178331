import { CSSProperties, useCallback, useMemo, useRef } from "react";
import { Cell, Row, SortDirection } from "react-aria-components";

import TableRow from "components/Table/Row";
import CardWrapper from "components/CardWrapper";
import { EmptystateMagnetColored } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import TableContextProvider from "components/Table/Context";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { Blueprint } from "types/generated";
import Table from "components/Table";
import TableWrapper from "components/Table/Wrapper";

import VersionListItem from "./components/ListItem";
import BlueprintVersionsPageLayout from "./components/PageLayout";
import {
  ANALYTICS,
  INITIAL_COLUMNS_VIEW_CONFIG,
  ROW_HEADER_ID,
  VERSIONS_COLUMN_CONFIG,
  VERSIONS_COLUMNS_STORAGE_KEY,
  VERSIONS_CUSTOM_END_COLUMN_CONFIG,
} from "./constants";
import useMockQuery from "./gql";
import { BlueprintVersion } from "./types";
import { showBlueprintVersionCreateDrawer } from "./VersionCreateDrawer";

type BlueprintVersionsProps = {
  blueprint: Blueprint;
};

const sortDescriptor = {
  column: "symbol",
  direction: "ascending" as SortDirection,
};

const setSortDescriptor = () => null;

const BlueprintVersions = ({ blueprint }: BlueprintVersionsProps) => {
  const { onError } = useTypedContext(FlashContext);
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);

  const { loading, data } = useMockQuery({
    onError,
    variables: {
      id: blueprint.id,
    },
  });
  const versions = useMemo(() => data?.blueprint.versions || [], [data?.blueprint.versions]);

  const handleOnCreateClick = () => {
    // TODO: get Current version
    const version = versions[0];
    showBlueprintVersionCreateDrawer({ version });
  };

  const renderRow = useCallback(
    (
      item: Partial<BlueprintVersion> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            data-index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.description}
          >
            <VersionListItem version={item as BlueprintVersion} />
          </TableRow>
        );
      }
    },
    []
  );

  return (
    <TableContextProvider
      columnsConfig={VERSIONS_COLUMN_CONFIG}
      endCustomColumns={VERSIONS_CUSTOM_END_COLUMN_CONFIG}
      initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
      rowHeaderId={ROW_HEADER_ID}
      localStorageId={VERSIONS_COLUMNS_STORAGE_KEY}
      setSortDescriptor={setSortDescriptor}
      sortDescriptor={sortDescriptor}
      {...ANALYTICS}
    >
      <BlueprintVersionsPageLayout onCreate={handleOnCreateClick} blueprintName={blueprint.name}>
        {loading && !data && <PageLoading />}

        {!!versions.length && (
          <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
            <Table
              ref={virtualizedListContainerRef}
              ariaLabel="Versions list"
              items={versions}
              loadingContent={loading}
            >
              {renderRow}
            </Table>
          </TableWrapper>
        )}

        {versions.length === 0 && !loading && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateMagnetColored}
                title={"No versions yet."}
              />
            </CardWrapper>
          </Box>
        )}
      </BlueprintVersionsPageLayout>
    </TableContextProvider>
  );
};

export default BlueprintVersions;
