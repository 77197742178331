import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";

import { ANALYTICS } from "../constants";
import { CellComponentProps } from "../types";
import { showEditSlackIntegration } from "./EditSlackIntegrationDrawer";

type UserIntegrationsListItemActionsDropdownCellProps = CellComponentProps;

const UserIntegrationsListItemActionsDropdownCell = ({
  integration,
  user,
}: UserIntegrationsListItemActionsDropdownCellProps) => {
  const { hasRootSpaceAccess } = useTypedContext(SpacesContext);

  return (
    <DropdownMenuEllipsis tooltip="Integration actions">
      <CopyFieldDropdownMenuItem title="Copy ID" value={integration.id} />

      <DropdownMenuItem
        {...ANALYTICS}
        analyticsTitle="Edit clicked"
        tooltip="You don't have an access to manage user integrations"
        isTooltipActive={!hasRootSpaceAccess}
        isDisabled={!hasRootSpaceAccess}
        onAction={() => showEditSlackIntegration({ id: integration.id, user })}
      >
        Edit
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default UserIntegrationsListItemActionsDropdownCell;
