import { create, show, useModal } from "@ebay/nice-modal-react";
import { DiffEditor } from "@monaco-editor/react";
import { useState } from "react";

import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import ModalHeader from "ds/components/Modal/Header";
import ModalSimple from "ds/components/ModalSimple";
import Toggle from "ds/components/Toggle";
import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";

import { prettify } from "./helpers";
import styles from "./styles.module.css";
import { EncodedJsonValueTuple } from "./types";

type JsonDiffModalProps = {
  value: EncodedJsonValueTuple;
};

const JsonDiffModal = create(function JsonDiffModal({ value }: JsonDiffModalProps) {
  const [sideBySide, setSideBySide] = useState(true);

  const { isDarkMode, currentDarkModeSyntaxTheme, currentLightModeSyntaxTheme } =
    useTypedContext(ThemeContext);

  const handleSideBySideChange = () => setSideBySide((currentValue) => !currentValue);
  const modal = useModal();

  const handleClose = () => {
    modal.hide();
    modal.resolve();
  };

  return (
    <ModalSimple size="x-large">
      <ModalHeader title="JSON Diff" />
      <ModalContent>
        <DiffEditor
          original={prettify(value[0])}
          modified={prettify(value[1])}
          language="json"
          className={styles.jsonViewEditor}
          theme={isDarkMode ? currentDarkModeSyntaxTheme : currentLightModeSyntaxTheme}
          options={{
            renderSideBySide: sideBySide,
            glyphMargin: false,
            readOnly: true,
            scrollBeyondLastLine: false,
            scrollbar: {
              verticalScrollbarSize: 4,
              horizontalScrollbarSize: 4,
              alwaysConsumeMouseWheel: false,
            },
            unicodeHighlight: {
              invisibleCharacters: false,
            },
          }}
        />
      </ModalContent>
      <ModalFooter>
        <Box justify="between" fullWidth>
          <Toggle
            variant="checkbox"
            id="sidebyside"
            isSelected={sideBySide}
            onChange={handleSideBySideChange}
            aria-label={
              sideBySide
                ? "Show the differences in a single editor"
                : "Show the differences in two side-by-side editors"
            }
          >
            Show side by side
          </Toggle>
          <Button variant="primary" onPress={handleClose}>
            Close
          </Button>
        </Box>
      </ModalFooter>
    </ModalSimple>
  );
});

export const showJsonDiffModal = (props: JsonDiffModalProps) => show(JsonDiffModal, props);
