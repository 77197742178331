import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { useEffect, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import FormToggleField from "ds/components/Form/ToggleField";
import Input from "ds/components/Input";
import TimePicker from "ds/components/TimePicker";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import useTypedContext from "hooks/useTypedContext";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { AwsIntegration } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { convertSecondsToTime, convertTimeToSeconds } from "utils/time";
import { SpacesContext } from "views/Account/SpacesProvider";
import { DEFAULT_SPACE_NAME } from "views/constants";

import {
  DURATION_OPTIONS_DEFAULT,
  DURATION_OPTIONS_FOR_WORKER,
  MAX_DURATION_IN_SECONDS,
  MAX_DURATION_IN_SECONDS_FOR_WORKER,
  MIN_DURATION_IN_SECONDS,
} from "./constants";
import { CREATE_AWS_INTEGRATION, UPDATE_AWS_INTEGRATION } from "./gql";
import { getDurationOptions } from "./utils";
import { getDurationValidator } from "./validators";
import AwsIntegrationTrustPolicyExample from "../TrustPolicyExample";

type FormValues = {
  name: string;
  roleArn: string;
  externalId: string;
  labels: { value: string }[];
  durationSeconds: number;
  generateCredentialsInWorker: boolean;
  space: string;
};

type AwsIntegrationFormDrawerProps = {
  trackAnalytics: (event: string, props?: TrackAnalyticsEventProperties) => void;
  integration?: AwsIntegration;
  refetchQueriesOnEdit?: string[];
};

const AwsIntegrationFormDrawer = createDrawer(
  ({ integration, trackAnalytics, refetchQueriesOnEdit }: AwsIntegrationFormDrawerProps) => {
    const drawer = useModal();
    const isEditMode = !!integration?.id;
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const { manageableSpaces } = useTypedContext(SpacesContext);
    const navigate = useNavigate();

    const builderForm = useForm<FormValues>({
      defaultValues: {
        name: integration?.name || "",
        roleArn: integration?.roleArn || "",
        externalId: integration?.externalId || "",
        labels: integration?.labels?.map((label) => ({ value: label })) || [],
        space:
          integration?.spaceDetails?.id ||
          manageableSpaces.find((s) => s.id === DEFAULT_SPACE_NAME)?.id ||
          manageableSpaces[0]?.id,
        generateCredentialsInWorker: integration?.generateCredentialsInWorker || false,
        durationSeconds: integration?.durationSeconds || MAX_DURATION_IN_SECONDS,
      },
      mode: "onChange",
    });

    const {
      control,
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors, isDirty },
      trigger,
    } = builderForm;

    const generateCredentialsInWorker = watch("generateCredentialsInWorker");

    useEffect(() => {
      const currentDuration = watch("durationSeconds");
      const maxDuration = generateCredentialsInWorker
        ? MAX_DURATION_IN_SECONDS_FOR_WORKER
        : MAX_DURATION_IN_SECONDS;

      if (currentDuration > maxDuration) {
        setValue("durationSeconds", maxDuration);
      }

      trigger("durationSeconds");
    }, [generateCredentialsInWorker, setValue, trigger, watch]);

    useEffect(() => {
      if (!generateCredentialsInWorker) {
        setValue("externalId", "");
      }
    }, [generateCredentialsInWorker, setValue]);

    const [createIntegration, { loading: creatingIntegration }] =
      useMutation(CREATE_AWS_INTEGRATION);

    const [updateIntegration, { loading: updatingIntegration }] = useMutation(
      UPDATE_AWS_INTEGRATION,
      {
        awaitRefetchQueries: true,
        refetchQueries: refetchQueriesOnEdit,
      }
    );

    useEffect(() => {
      trackAnalytics("Open Integration Form", {
        type: "aws",
        mode: isEditMode ? "edit" : "create",
      });
    }, [integration, isEditMode, trackAnalytics]);

    const handleCloseDrawer = () => {
      drawer.resolve();
      drawer.hide();
    };

    const onCreateSubmit = (formData: FormValues) => {
      createIntegration({
        variables: {
          name: formData.name.trim(),
          roleArn: formData.roleArn.trim(),
          externalID: formData.externalId.trim(),
          labels: formData.labels.map((label) => label.value),
          space: formData.space,
          generateCredentialsInWorker: formData.generateCredentialsInWorker,
          durationSeconds: formData.durationSeconds,
        },
      })
        .then(({ data }) => {
          if (data?.awsIntegrationCreate?.id) {
            handleCloseDrawer();
            reportSuccess({ message: "AWS integration was successfully created" });
            trackAnalytics("Create Integration", { type: "aws" });
            navigate(`/cloud-integrations/aws/${data.awsIntegrationCreate.id}`);
          }
        })
        .catch(onError);
    };

    const onUpdateSubmit = (formData: FormValues) => {
      if (!integration) {
        return;
      }

      updateIntegration({
        variables: {
          id: integration.id,
          name: formData.name.trim(),
          roleArn: formData.roleArn.trim(),
          externalID: formData.externalId.trim(),
          labels: formData.labels.map((label) => label.value),
          space: formData.space,
          generateCredentialsInWorker: formData.generateCredentialsInWorker,
          durationSeconds: formData.durationSeconds,
        },
      })
        .then(({ data }) => {
          if (data?.awsIntegrationUpdate?.id) {
            reportSuccess({ message: "AWS integration was successfully updated" });
            handleCloseDrawer();
            trackAnalytics("Update Integration", { type: "aws" });
          }
        })
        .catch(onError);
    };

    const onSubmit = (formData: FormValues) => {
      if (isEditMode) {
        onUpdateSubmit(formData);
      } else {
        onCreateSubmit(formData);
      }
    };

    const onClose = () => {
      trackAnalytics("Close Integration Form", {
        type: "aws",
        mode: isEditMode ? "edit" : "create",
        dirty: isDirty,
      });
    };

    const minDurationTime = useMemo(() => {
      return convertSecondsToTime(MIN_DURATION_IN_SECONDS);
    }, []);

    const maxDurationTime = useMemo(() => {
      return convertSecondsToTime(
        generateCredentialsInWorker ? MAX_DURATION_IN_SECONDS_FOR_WORKER : MAX_DURATION_IN_SECONDS
      );
    }, [generateCredentialsInWorker]);

    const durationOptions = useMemo(() => {
      return getDurationOptions(
        generateCredentialsInWorker ? DURATION_OPTIONS_FOR_WORKER : DURATION_OPTIONS_DEFAULT
      );
    }, [generateCredentialsInWorker]);

    return (
      <DrawerForm isDirty={isDirty} onClose={onClose}>
        <FormProvider {...builderForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle
                title={isEditMode ? "Edit AWS integration" : "Set up AWS integration"}
              />
              <DrawerCloseIcon />
            </DrawerHeader>

            <DrawerBody gap="x-large" fullHeight hasStickyFooter>
              <FormField label="Name" error={errors?.name?.message} noMargin>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Name your integration"
                    error={!!errors?.name}
                    {...register("name", { required: "Name field is required." })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldSpace noMargin />

              <FormField label="Role ARN" error={errors?.roleArn?.message} noMargin>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter role ARN here"
                    error={!!errors?.roleArn}
                    {...register("roleArn", { required: "Role ARN is required." })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <Controller
                name="generateCredentialsInWorker"
                control={control}
                render={({ field }) => (
                  <FormToggleField
                    title="Assume role on worker"
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                  />
                )}
              />

              {generateCredentialsInWorker && (
                <FormField
                  label="External ID"
                  error={errors?.externalId?.message}
                  isOptional
                  noMargin
                >
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Paste external ID"
                      {...register("externalId")}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
              )}

              <Controller
                name="durationSeconds"
                control={control}
                rules={{
                  required: "Duration is required.",
                  validate: getDurationValidator(generateCredentialsInWorker),
                }}
                render={({ field }) => (
                  <FormField
                    label="Duration"
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Duration</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          Set the duration of the role session. The value specified can range from
                          15 minutes up to the maximum session duration that is set in AWS IAM for
                          the role, though no longer than{" "}
                          {generateCredentialsInWorker ? "12 hours" : "1 hour"}.
                        </TooltipModalBody>
                      </>
                    }
                    noMargin
                    tooltipInfoVariant="modal"
                    error={errors?.durationSeconds?.message}
                  >
                    <TimePicker
                      value={convertSecondsToTime(field.value)}
                      onChange={(value) => value && field.onChange(convertTimeToSeconds(value))}
                      minValue={minDurationTime}
                      maxValue={maxDurationTime}
                      timeOptions={durationOptions}
                      hasError={!!errors?.durationSeconds}
                      ariaLabel="Duration"
                      fullWidth
                    />
                  </FormField>
                )}
              />

              <Box direction="column">
                <FormFieldTags
                  stopEnterPropagation
                  label="Labels"
                  tagName="label"
                  name="labels"
                  isOptional
                  noMargin
                />
              </Box>

              {!isEditMode && <AwsIntegrationTrustPolicyExample />}

              <DrawerFooter sticky>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={creatingIntegration || updatingIntegration}
                    loading={creatingIntegration || updatingIntegration}
                  >
                    {isEditMode ? "Save" : "Set up"}
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showAwsIntegrationFormDrawer = createDrawerTrigger(AwsIntegrationFormDrawer);
