import { ReactNode, useId } from "react";

import TableDropdownCell, {
  TableDropdownAction,
} from "ds/components/Table/components/DropdownCell";

import styles from "./styles.module.css";

type IacManagementTableDropdownCellProps = {
  actions: TableDropdownAction[];
  children: ReactNode;
};

const IacManagementTableDropdownCell = ({
  actions,
  children,
}: IacManagementTableDropdownCellProps) => {
  const id = useId();

  return (
    <TableDropdownCell id={id} className={styles.cell} dropdownActions={actions}>
      {children}
    </TableDropdownCell>
  );
};

export default IacManagementTableDropdownCell;
