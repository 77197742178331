import { format, fromUnixTime } from "date-fns/esm";

import styles from "./styles.module.css";

type LoginElementProps = {
  username: string;
  timestamp: number;
};

const LoginElement = ({ username, timestamp }: LoginElementProps) => (
  <li className={styles.login}>
    <div className={styles.loginContent}>
      <span className={styles.loginUsername}>{username}</span>
      <span className={styles.loginTimestamp}>
        {format(fromUnixTime(timestamp), "d MMMM yyyy")}
      </span>
    </div>
  </li>
);

export default LoginElement;
