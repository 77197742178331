import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import FlashContext from "components/FlashMessages/FlashContext";
import { Stack } from "components/icons/generated";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import Icon from "ds/components/Icon";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import Typography from "ds/components/Typography";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { SpaceAccessLevel } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";
import { hasSpaceAccessAtLeast } from "utils/user";
import { withTestId } from "utils/withTestId";

import StackHeader from "../components/Header";
import { StackContext } from "../Context";
import { getStacksBackUrl } from "../helpers";
import DependenciesColumnHeader from "./ColumnHeader";
import DependenciesColumnItem from "./ColumnItem";
import { getDisallowedVendorName, isOutputReferenceAllowedByVendor } from "./ColumnItem/helper";
import DependenciesDropdownV2 from "./DropdownV2";
import { DELETE_STACK_DEPENDENCY } from "./gql";
import styles from "./styles.module.css";
import { SelectedDropdownItem } from "./types";
import useCreateStackDependenciesInBatch from "./useCreateStackDependenciesInBatch";

const StackDependencies = () => {
  const { stack, canManageSpace, stackUrl } = useTypedContext(StackContext);
  const { onError } = useTypedContext(FlashContext);

  useTitle(`Dependencies · ${stack.name}`);

  const spaceAccessRead = stack?.spaceDetails?.accessLevel !== SpaceAccessLevel.None;

  const { handleCreateDependenciesInBatch } = useCreateStackDependenciesInBatch();

  const [deleteStackDependency] = useMutation(DELETE_STACK_DEPENDENCY, {
    refetchQueries: ["GetStack"],
  });

  const dependsOnTooltip = (
    <>
      <TooltipModalTitle>Depends on</TooltipModalTitle>
      <TooltipModalBody align="start">
        <span>
          Depended-on stacks can trigger runs in their dependencies and share outputs. Learn more in{" "}
          <ReadMoreDocsLink
            docsUrl={getDocsUrl("/concepts/stack/stack-dependencies.html")}
            title="Stack Dependencies"
          />
          .
        </span>
      </TooltipModalBody>
    </>
  );

  const dependsOnByTooltip = (stackName: string) => (
    <>
      <TooltipModalTitle>Depended on by</TooltipModalTitle>
      <TooltipModalBody align="start">
        <span>
          Stacks depending on "<b>{stackName}</b>", which can be used to trigger runs downstream and
          optionally share outputs. See more{" "}
          <ReadMoreDocsLink
            docsUrl={getDocsUrl(
              "/concepts/stack/stack-dependencies.html#stack-dependency-reference-limitations"
            )}
            title="here"
          />
          .
        </span>
      </TooltipModalBody>
    </>
  );

  useBreadcrumbs(
    [
      {
        title: "Stacks",
        link: getStacksBackUrl(),
      },
      {
        title: stack.name,
      },
    ],
    [stack]
  );

  const hasDependencies = stack.dependsOn?.length > 0 || stack.isDependedOnBy?.length > 0;

  const [dependsOnList, dependsOnIDs] = useMemo(() => {
    const list = stack.dependsOn || [];

    return [
      list,
      list.map<SelectedDropdownItem>((item) => ({
        stackId: item.dependsOnStack.id,
        dependencyId: item.id,
      })),
    ];
  }, [stack.dependsOn]);

  const [isDependedOnByList, isDependedOnByIDs] = useMemo(() => {
    const list = stack.isDependedOnBy || [];

    return [
      list,
      list.map<SelectedDropdownItem>((item) => ({
        stackId: item.stack.id,
        dependencyId: item.id,
      })),
    ];
  }, [stack.isDependedOnBy]);

  const stacksUsedForDependencies = useMemo(() => {
    return [
      stack.id,
      ...isDependedOnByIDs.map((item) => item.stackId),
      ...dependsOnIDs.map((item) => item.stackId),
    ];
  }, [dependsOnIDs, isDependedOnByIDs, stack.id]);

  const handleCreateDependsOnInBatch = useCallback(
    (selectedIds: string[], callback: () => void) => {
      handleCreateDependenciesInBatch(
        selectedIds.map((dependsOnStackId) => ({
          stackId: stack.id,
          dependsOnStackId,
        }))
      )
        .then(({ data }) => {
          // TODO: success message
          if (data) {
            callback();
          }
        })
        .catch(onError);
    },
    [handleCreateDependenciesInBatch, stack.id, onError]
  );

  const handleCreateDependsOnByInBatch = useCallback(
    (selectedIds: string[], callback: () => void) => {
      handleCreateDependenciesInBatch(
        selectedIds.map((stackId) => ({
          stackId,
          dependsOnStackId: stack.id,
        }))
      )
        .then(({ data }) => {
          // TODO: success message
          if (data) {
            callback();
          }
        })
        .catch(onError);
    },
    [handleCreateDependenciesInBatch, stack.id, onError]
  );

  const handleDeleteDependency = useCallback((dependencyId: string) => {
    deleteStackDependency({
      variables: {
        id: dependencyId,
      },
    }).catch(onError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StackHeader />
      <PageWrapper className={styles.pageWrapper}>
        <PageInfo title="Dependencies">
          {spaceAccessRead && (
            <LinkButton
              variant="secondary"
              to={`${stackUrl}/dependencies/graph`}
              disabled={!hasDependencies}
            >
              Dependencies graph
            </LinkButton>
          )}
        </PageInfo>

        <Box className={styles.columnsWrapper} direction="row" fullWidth>
          <Box direction="column" className={styles.column} {...withTestId("depends-on-column")}>
            <DependenciesColumnHeader title="Depends on" tooltip={dependsOnTooltip}>
              {canManageSpace && (
                <DependenciesDropdownV2
                  onAddDependencies={handleCreateDependsOnInBatch}
                  requiredAccessLevel={SpaceAccessLevel.Read}
                  exclude={stacksUsedForDependencies}
                />
              )}
            </DependenciesColumnHeader>

            <div className={styles.columnList}>
              {!dependsOnList.length && (
                <Typography tag="span" variant="p-body3" className={styles.emptyState}>
                  No dependency.
                </Typography>
              )}

              {dependsOnList.map((item) => (
                <DependenciesColumnItem
                  key={item.id}
                  dependency={item}
                  itemStack={item.dependsOnStack}
                  relatedStack={item.stack}
                  onDelete={canManageSpace ? handleDeleteDependency : undefined}
                  stackIdSourceOfOutputs={item.dependsOnStack.id}
                  isOutputReferencesDisallowed={
                    !isOutputReferenceAllowedByVendor(item.dependsOnStack.vendorConfig?.__typename)
                  }
                  disallowedVendorName={getDisallowedVendorName(
                    item.dependsOnStack.vendorConfig?.__typename
                  )}
                />
              ))}
            </div>
          </Box>

          <Box className={styles.targetStackColumn} direction="column" align="center" fullWidth>
            <div className={styles.targetLine}>&nbsp;</div>
            <Box className={styles.targetStack} direction="row" justify="center" align="center">
              <Icon src={Stack} />

              <Typography tag="h4" variant="p-t7" className={styles.text}>
                THIS STACK
              </Typography>
            </Box>
          </Box>

          <Box direction="column" className={styles.column} {...withTestId("depended-on-column")}>
            <DependenciesColumnHeader
              title="Depended on by"
              tooltip={dependsOnByTooltip(stack.name)}
            >
              {
                <DependenciesDropdownV2
                  onAddDependencies={handleCreateDependsOnByInBatch}
                  requiredAccessLevel={SpaceAccessLevel.Admin}
                  exclude={stacksUsedForDependencies}
                />
              }
            </DependenciesColumnHeader>

            <div className={styles.columnList}>
              {!isDependedOnByList.length && (
                <Typography tag="span" variant="p-body3" className={styles.emptyState}>
                  No dependency.
                </Typography>
              )}

              {isDependedOnByList.map((item) => (
                <DependenciesColumnItem
                  key={item.id}
                  dependency={item}
                  itemStack={item.stack}
                  relatedStack={item.dependsOnStack}
                  onDelete={
                    hasSpaceAccessAtLeast(item.stack.space.accessLevel, SpaceAccessLevel.Admin)
                      ? handleDeleteDependency
                      : undefined
                  }
                  stackIdSourceOfOutputs={stack.id}
                  isOutputReferencesDisallowed={
                    !isOutputReferenceAllowedByVendor(item.dependsOnStack.vendorConfig?.__typename)
                  }
                  disallowedVendorName={getDisallowedVendorName(
                    item.dependsOnStack.vendorConfig?.__typename
                  )}
                />
              ))}
            </div>
          </Box>
        </Box>
      </PageWrapper>
    </>
  );
};

export default StackDependencies;
