import cx from "classnames";

import Link from "ds/components/Link";
import TooltipInfo from "ds/components/TooltipInfo";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

type ReadMoreProps = {
  path: string;
};

const ReadMore = ({ path }: ReadMoreProps) => (
  <Link href={path} rel="noopener noreferrer" target="_blank" size="small" variant="inversed">
    Read more.
  </Link>
);

export const moduleProjectRootTooltipContent = (
  <>
    Project root is the optional directory relative to the repository root containing the module
    source code.
  </>
);

export const projectRootTooltipContent = (
  <>
    Project root is the optional directory relative to the workspace root containing the entrypoint
    to the Stack. <ReadMore path={getDocsUrl("/concepts/stack/stack-settings#project-root")} />
  </>
);

export const RunProposedTooltip = (
  <>
    Proposed runs are previews of changes that would be applied to your infrastructure if the new
    code was to somehow become canonical, for example by pushing it to the tracked branch.{" "}
    <ReadMore path={getDocsUrl("/concepts/run/proposed")} />
  </>
);

export const RunTrackedTooltip = (
  <>
    Tracked runs represent the actual changes to your infrastructure caused by changes to your
    infrastructure definitions and/or configuration.{" "}
    <ReadMore path={getDocsUrl("/concepts/run/tracked")} />
  </>
);

export const RunPrioritizedTooltip = (
  <>
    Prioritized runs are processed before runs from other stacks. However, a stack's standard order
    of run types (tasks, tracked, and proposed runs) execution will still be respected.{" "}
    <ReadMore path={getDocsUrl("/concepts/run/#run-prioritization")} />
  </>
);

export const Seat = ({ iconClassName }: { iconClassName?: string }) => (
  <TooltipInfo iconClassName={cx(styles.infoIcon, iconClassName)}>
    A seat holder is any user who logs in.
  </TooltipInfo>
);

export const Worker = ({ iconClassName }: { iconClassName?: string }) => (
  <TooltipInfo widthMode="maxWidthSm" iconClassName={cx(styles.infoIcon, iconClassName)}>
    Workers let you execute Spacelift workflows on your end.{" "}
    <ReadMore path={getDocsUrl("/concepts/worker-pools/")} />
  </TooltipInfo>
);

export const PrivateWorkerMinutes = ({ iconClassName }: { iconClassName?: string }) => (
  <TooltipInfo iconClassName={cx(styles.infoIcon, iconClassName)}>
    Number of minutes used by your Runs on private workers.
  </TooltipInfo>
);

export const PublicWorkerMinutes = ({ iconClassName }: { iconClassName?: string }) => (
  <TooltipInfo iconClassName={cx(styles.infoIcon, iconClassName)}>
    Number of minutes used by your Runs on public workers.
  </TooltipInfo>
);

export const SecurityEmail = () => (
  <TooltipInfo widthMode="maxWidthSm" iconClassName={styles.infoIcon}>
    This is an email we will use to contact you in case of any security incidents.
  </TooltipInfo>
);

export const AuditTrailHeadersTooltip = () => (
  <TooltipInfo widthMode="maxWidthSm" iconClassName={styles.infoIcon}>
    Headers to be sent with each request to the audit trail endpoint. They're encrypted so we're
    only showing the list of keys here.
  </TooltipInfo>
);

export const OIDCUseSlackIDFromIdpTooltip = () => (
  <>
    <TooltipModalTitle>Slack member ID from IdP</TooltipModalTitle>
    <TooltipModalBody align="start">
      Slack member ID will be set for the user when the identity provider provides that field as an
      attribute with the name slack_member_id
      {/** TODO: add docs */}
      {/* <Link href={getDocsUrl("/")} target="_blank">
        Read more
      </Link> */}
    </TooltipModalBody>
  </>
);
