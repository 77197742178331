import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { FormProvider, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";
import useTypedContext from "hooks/useTypedContext";
import { Context } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { getDocsUrl } from "utils/getDocsUrl";

import { CONTEXT_ANALYTICS_VERSION } from "../constants";
import { UPDATE_CONTEXT } from "../gql";

type UpdateContextFormFields = {
  contextName: string;
  description?: string;
  space: string;
  labels: Record<"value", string>[];
};

type ContextUpdateDrawerProps = {
  context: Context;
  refetchQuery: string;
};

const ContextUpdateDrawer = createDrawer(function ContextUpdateDrawer({
  context,
  refetchQuery,
}: ContextUpdateDrawerProps) {
  const drawer = useModal();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageContext.ContextEdit,

    defaultCallbackTrackProperties: { version: CONTEXT_ANALYTICS_VERSION },
  });

  const builderForm = useForm<UpdateContextFormFields>({
    defaultValues: {
      contextName: context.name,
      space: context.spaceDetails.id,
      description: context.description || "",
      labels: context.labels.map((value) => ({ value })),
    },
    mode: "onChange",
  });

  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = builderForm;

  const [updateContext] = useMutation(UPDATE_CONTEXT, {
    refetchQueries: [refetchQuery],
  });

  const onSubmit = (formData: UpdateContextFormFields) => {
    const variables = {
      id: context.id,
      name: formData.contextName,
      description: formData.description,
      space: formData.space,
      labels: formData.labels.map((item) => item.value),
    };
    updateContext({
      variables,
    })
      .then(({ data }) => {
        if (data?.contextUpdateV2?.id) {
          trackSegmentAnalyticsEvent("Saved", {
            labels: variables.labels,
            space: variables.space,
            name: variables.name,
          });
          reportSuccess({
            message: `Context "${data.contextUpdateV2.name}" was successfully saved`,
          });
        } else {
          reportError({
            message: "Something went wrong while saving context, please try again.",
          });
        }

        reset();
        drawer.hide();
      })
      .catch(onError);
  };

  const onDrawerCancel = () => {
    trackSegmentAnalyticsEvent("Exit", {
      labels: context.labels,
      space: context.space,
      name: context.name,
      method: "Close button click",
    });
  };

  return (
    <DrawerForm isDirty={isDirty}>
      <FormProvider {...builderForm}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Edit details" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <FormField label="Name" error={errors?.contextName?.message}>
              {({ ariaInputProps }) => (
                <Input
                  placeholder="New context name"
                  error={!!errors?.contextName}
                  {...register("contextName", {
                    required: "Type field is required.",
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <FormFieldSpace />

            <FormField label="Description" isOptional>
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Enter description here..."
                  maxRows={15}
                  {...register("description")}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <FormFieldTags
              stopEnterPropagation
              label="Labels"
              tagName="label"
              name="labels"
              isOptional
            />

            <Box margin="large 0 0 0">
              <Banner variant="info">
                <Box direction="column" gap="medium" align="start">
                  <div>
                    Use <strong>autoattach:label</strong> to attach contexts to stacks and modules
                    automatically.
                  </div>
                  <ReadMoreDocsLink
                    // TODO: Add docs about auto-attachments
                    docsUrl={getDocsUrl("/concepts/configuration/context.html#auto-attachments")}
                  />
                </Box>
              </Banner>
            </Box>

            <DrawerFooter>
              <DrawerFooterActions>
                <DrawerCancelButton onClick={onDrawerCancel} />
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </form>
      </FormProvider>
    </DrawerForm>
  );
});

export const openContextUpdateDrawer = createDrawerTrigger(ContextUpdateDrawer);
