import { Label as AriaLabel } from "react-aria-components";

import Typography from "ds/components/Typography";
import { getColors } from "ds/components/Form/Field/utils";
import { FormFieldColor } from "ds/components/Form/Field/types";

import styles from "./styles.module.css";

type FormFieldAriaLabelProps = {
  label: string;
  color?: FormFieldColor;
};

const FormFieldAriaLabel = ({ color, label }: FormFieldAriaLabelProps) => {
  const { primaryTextColor } = getColors(color);

  return (
    <AriaLabel className={styles.ariaLabel}>
      <Typography tag="span" variant="p-t7" color={primaryTextColor}>
        {label}
      </Typography>
    </AriaLabel>
  );
};

FormFieldAriaLabel.displayName = "DS.FormField.AriaLabel";

export default FormFieldAriaLabel;
