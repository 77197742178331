export const DEFAULT_POLLING_INTERVAL_MS = 10000;

export enum PollingIntervalGroups {
  AiSummarization = "aiSummarization",
  Dashboard = "dashboard",
  Default = "default",
  Lists = "lists",
  Long = "long",
  SingleEntities = "singeEntities",
}

export const DEFAULT_POLLING_INTERVAL_GROUPS_MS: Record<PollingIntervalGroups, number> = {
  // These are mostly used in SH that doesn't use launch darkly.
  [PollingIntervalGroups.AiSummarization]: 1000,
  [PollingIntervalGroups.Dashboard]: 30000,
  [PollingIntervalGroups.Default]: 10000,
  [PollingIntervalGroups.Lists]: 10000,
  [PollingIntervalGroups.Long]: 60000,
  [PollingIntervalGroups.SingleEntities]: 5000,
};
