import { useStore } from "zustand";

import useTypedContext from "hooks/useTypedContext";

import { IacManagementStore } from "./types";
import { IacManagementPageStoreContext } from "../PageStoreContext";

const useIacManagementStore = <T>(selector: (state: IacManagementStore) => T): T => {
  const store = useTypedContext(IacManagementPageStoreContext);

  return useStore(store, selector);
};

export default useIacManagementStore;
