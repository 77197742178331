import { memo } from "react";

type TableSkeletonProps = {
  rowsPerPage: number;
  RowElement: React.ElementType;
};

const TableSkeleton = ({ rowsPerPage, RowElement }: TableSkeletonProps) => {
  return (
    <div style={{ display: "table-row-group" }} role="rowgroup">
      {Array.from({ length: rowsPerPage }).map((_, i) => (
        <RowElement key={i} />
      ))}
    </div>
  );
};

TableSkeleton.displayName = "DS.Table.Skeleton";

export default memo(TableSkeleton);
