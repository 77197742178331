import { useModal } from "@ebay/nice-modal-react";
import useLocalStorage from "@rehooks/local-storage";
import { useCallback, useMemo } from "react";

import CustomizeView from "components/CustomizeView";
import { CustomizeViewConfig } from "components/CustomizeView/types";
import { ColumnConfig } from "components/Table/types";
import { getLocalStorageConfigSizesId } from "components/Table/utils";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import { ROW_HEADER_ID, STACKS_COLUMN_ID, STACK_COLUMNS_STORAGE_KEY } from "../constants";
import { useInitialColumnsViewConfig } from "../useInitialColumnsViewConfig";
import useStackListAnalytics from "../useStackListAnalytics";
import { getLabel } from "../utils";
import { showResetConfirmation } from "./ResetConfirmation";

const ALWAYS_VISIBLE_ITEMS = [ROW_HEADER_ID];

type CustomizeViewDrawerProps = {
  columnConfig: ColumnConfig;
  onConfigReset: () => void;
};

const CustomizeViewDrawer = createDrawer(
  ({ columnConfig, onConfigReset }: CustomizeViewDrawerProps) => {
    const drawer = useModal();
    const initialColumnsViewConfig = useInitialColumnsViewConfig();
    const [storageConfig, setStorageConfig, clearOrderConfig] = useLocalStorage<
      CustomizeViewConfig<STACKS_COLUMN_ID>
    >(STACK_COLUMNS_STORAGE_KEY, initialColumnsViewConfig);
    const [, , clearSizesConfig] = useLocalStorage(
      getLocalStorageConfigSizesId(STACK_COLUMNS_STORAGE_KEY)
    );

    const trackAnalytics = useStackListAnalytics();

    const resetConfig = useCallback(() => {
      trackAnalytics("Table Configuration Reset");
      clearOrderConfig();
      clearSizesConfig();
      drawer.hide();
      // Move to end of queue
      setTimeout(onConfigReset);
    }, [clearOrderConfig, clearSizesConfig, drawer, onConfigReset, trackAnalytics]);

    const handleOnChange = useCallback(
      (config: CustomizeViewConfig<STACKS_COLUMN_ID>) => {
        setStorageConfig(config);
        trackAnalytics("Table configuration changed", { ...config, method: "customize drawer" });
      },
      [setStorageConfig, trackAnalytics]
    );

    const staticColumns = useMemo(
      () =>
        Object.keys(columnConfig)
          .filter((key) => columnConfig[key].static)
          .map((key) => key as STACKS_COLUMN_ID),
      [columnConfig]
    );

    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Customize list" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody noPadding>
          <CustomizeView
            visibleSectionTitle="Visible items"
            hiddenSectionTitle="Hidden items"
            config={storageConfig}
            staticItems={staticColumns}
            getLabel={getLabel}
            onChange={handleOnChange}
            alwaysVisibleItems={ALWAYS_VISIBLE_ITEMS}
          />
        </DrawerBody>
        <DrawerFooter>
          <Button
            onPress={() => showResetConfirmation({ onReset: resetConfig })}
            variant="secondary"
          >
            Reset to default
          </Button>
        </DrawerFooter>
      </DrawerSimple>
    );
  }
);

export const showCustomizeViewDrawer = createDrawerTrigger(CustomizeViewDrawer);
