import cx from "classnames";
import capitalize from "lodash-es/capitalize";

import { ChevronRight } from "components/icons/generated";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import Counter from "ds/components/Counter";
import TextEllipsis from "ds/components/TextEllipsis";
import Tree, { TreeBranch, TreeBranchLink } from "ds/components/Tree";
import Typography from "ds/components/Typography";
import { useToggle } from "hooks/useToggle";

import HistoryEntryLabel from "../HistoryEntryLabel";
import styles from "./styles.module.css";

type RunPhaseLogsHooksProps = {
  hooks: string[] | undefined;
  type: "before" | "after";
};

const RunPhaseLogsHooks = ({ type, hooks }: RunPhaseLogsHooksProps) => {
  const [isOpen, toggle] = useToggle(false);

  if (!hooks || hooks.length === 0) {
    return null;
  }

  return (
    <TreeBranch>
      <TreeBranchLink>
        <Box align="center" gap="medium" padding="small 0" fullWidth>
          <ButtonIcon
            icon={ChevronRight}
            onPress={() => toggle()}
            iconRotate={isOpen ? "90" : undefined}
            variant="ghost"
          >
            {isOpen ? "Collapse" : "Expand"}
          </ButtonIcon>

          <HistoryEntryLabel>
            Hooks <i>{capitalize(type)}</i>
          </HistoryEntryLabel>

          <Counter size="small" count={hooks.length} />
        </Box>
      </TreeBranchLink>
      {isOpen && (
        <Tree margin="0 0 medium large">
          {hooks.map((hook, index) => (
            <TreeBranch key={index}>
              <TreeBranchLink padding="small 0">
                <TextEllipsis tooltip={hook} tooltipWidthMode="maxWidthXl">
                  {({ className, ...props }) => (
                    <Typography
                      variant="s-body3"
                      tag="span"
                      className={cx(styles.hook, className)}
                      {...props}
                    >
                      {hook}
                    </Typography>
                  )}
                </TextEllipsis>
              </TreeBranchLink>
            </TreeBranch>
          ))}
        </Tree>
      )}
    </TreeBranch>
  );
};

export default RunPhaseLogsHooks;
