import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import Icon, { IconProps } from "ds/components/Icon";
import Typography from "ds/components/Typography";
import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";

type StackListItemIssuesCellItemProps = {
  children: string;
  icon: IconComponent;
  iconColor: IconProps["color"];
  hideText?: boolean;
};

const StackListItemIssuesCellItem = ({
  children,
  icon,
  iconColor,
  hideText,
}: StackListItemIssuesCellItemProps) => {
  if (hideText) {
    return (
      <ButtonIcon variant="ghost" iconColor={iconColor} icon={icon}>
        {children}
      </ButtonIcon>
    );
  }

  return (
    <Box gap="medium" align="center" className={styles.wrapper}>
      <Icon src={icon} color={iconColor} noShrink />
      <Typography tag="span" variant="p-body3" className={styles.text}>
        {children}
      </Typography>
    </Box>
  );
};

export default StackListItemIssuesCellItem;
