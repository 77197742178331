import { differenceInDays, formatDistance, fromUnixTime, isAfter } from "date-fns/esm";
import { useEffect, useState } from "react";

const getTrialDaysLeft = (onTrialUntil?: number) =>
  onTrialUntil ? differenceInDays(fromUnixTime(onTrialUntil), new Date()) : 0;

const getTrialEndDistanceInDays = (onTrialUntil?: number) =>
  onTrialUntil ? formatDistance(fromUnixTime(onTrialUntil), new Date()) : null;

const useTrialTime = (onTrialUntil?: number) => {
  const [trialDaysLeft, setTrialDaysLeft] = useState(getTrialDaysLeft(onTrialUntil));

  const [distanceInDays, setDistanceInDays] = useState(getTrialEndDistanceInDays(onTrialUntil));

  const isTrialFinished = !onTrialUntil || isAfter(new Date(), fromUnixTime(onTrialUntil));

  useEffect(() => {
    const trialInterval = setInterval(() => {
      setTrialDaysLeft(getTrialDaysLeft(onTrialUntil));
      setDistanceInDays(getTrialEndDistanceInDays(onTrialUntil));
    }, 10000);

    if (isTrialFinished) {
      clearInterval(trialInterval);
      return;
    }

    return () => {
      clearInterval(trialInterval);
    };
  }, [isTrialFinished, onTrialUntil]);

  return { trialDaysLeft, distanceInDays, isTrialFinished };
};

export default useTrialTime;
