import { createContext, useState } from "react";
import { Node } from "reactflow";

import useTypedContext from "hooks/useTypedContext";

import useKeyboardNavigation from "./useKeyboardNavigation";
import { SpaceItemType } from "./types";
import { SpacesViewActionsContext } from "../ActionsContext";

type SpacesViewDiagramActionsContextProps = {
  focusedKey?: string;
};

export const SpacesViewDiagramActionsContext = createContext<
  SpacesViewDiagramActionsContextProps | undefined
>(undefined);
SpacesViewDiagramActionsContext.displayName = "SpacesViewDiagramActionsContext";

type SpacesViewDiagramActionsProviderProps = {
  children: ({ onKeyDown }: { onKeyDown: (e: React.KeyboardEvent) => void }) => JSX.Element;
  nodes: Node<SpaceItemType>[];
};

export const SpacesViewDiagramActionsProvider = ({
  children,
  nodes,
}: SpacesViewDiagramActionsProviderProps) => {
  const { onSelect } = useTypedContext(SpacesViewActionsContext);

  const [focusedKey, setFocusedKey] = useState<string | undefined>();

  const handleKeyDown = useKeyboardNavigation({
    visibleNodes: nodes,
    onSelect,
    onFocus: setFocusedKey,
  });

  return (
    <SpacesViewDiagramActionsContext.Provider
      value={{
        focusedKey,
      }}
    >
      {children({
        onKeyDown: handleKeyDown,
      })}
    </SpacesViewDiagramActionsContext.Provider>
  );
};
