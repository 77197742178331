import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import { OIDCUseSlackIDFromIdpTooltip } from "components/TooltipSnippets";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import FormField from "ds/components/Form/Field";
import FormToggleField from "ds/components/Form/ToggleField";
import Input from "ds/components/Input";
import SecretInput from "ds/components/SecretInput";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { createDefaultMutationHook } from "hooks/useMutationHandler";
import useTypedContext from "hooks/useTypedContext";
import useTypedFlags from "hooks/useTypedFlags";
import setLocation from "shared/setLocation";
import { stringIsRequired, validateRequiredURL } from "utils/formValidators";

import SettingsSingleSignOnConfigInfoOIDC from "../../components/ConfigInfoOIDC";
import { SLACK_MEMBER_ID_CLAIM_MAPPING } from "../constants";
import { CREATE_SSO_OIDC } from "./gql";
import { formDefaultValues, type SettingsSingleSignOnOIDCFormFields } from "./helpers";

const TooltipSlackMemberIDForOIDC = OIDCUseSlackIDFromIdpTooltip();

const useOIDCCreate = createDefaultMutationHook(CREATE_SSO_OIDC);

type SettingsSingleSignOnCreateOIDCProps = {
  setIsDirty: (isDirty: boolean) => void;
};

const SettingsSingleSignOnCreateOIDC = ({ setIsDirty }: SettingsSingleSignOnCreateOIDCProps) => {
  const { useSlackMemberIdFromIdpForUserProfile } = useTypedFlags();
  const modal = useModal();

  const { reportSuccess } = useTypedContext(FlashContext);

  const [createOIDC, { loading }] = useOIDCCreate({
    refetchQueries: ["GetSSOSettings"],
  });

  const {
    register,
    formState: { errors, isDirty },
    control,
    handleSubmit,
  } = useForm<SettingsSingleSignOnOIDCFormFields>({
    mode: "onChange",
    defaultValues: formDefaultValues,
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationOIDC,

    defaultCallbackTrackProperties: {
      method: "OIDC",
    },
  });

  const onSubmit = handleSubmit((data) => {
    trackSegmentAnalyticsEvent("Set Up Save");

    createOIDC(
      {
        clientId: data.clientId,
        clientCredentials: data.clientCredentials,
        identityProviderHost: data.identityProviderHost,
        claimMapping:
          useSlackMemberIdFromIdpForUserProfile && data.claimMapping
            ? SLACK_MEMBER_ID_CLAIM_MAPPING
            : null,
      },
      (data) => {
        if (data?.oidcCreate) {
          // FYI: `/oidc/start` is BE endpoint, do not replace it with "useNavigate", it works only with navigating to the external URL
          setLocation("/oidc/start");
          reportSuccess({ message: "OIDC successfully enabled" });
          modal.hide();
        }
      }
    );
  });

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  return (
    <>
      <DrawerBody hasDivider gap="x-large">
        <SettingsSingleSignOnConfigInfoOIDC />
      </DrawerBody>
      <form onSubmit={onSubmit}>
        <DrawerBody gap="x-large">
          <FormField label="Client ID" error={errors?.clientId?.message} noMargin>
            {({ ariaInputProps }) => (
              <Input
                error={!!errors?.clientId}
                {...register("clientId", {
                  validate: stringIsRequired(),
                  setValueAs: (value: string) => value.trim(),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormField label="Secret" error={errors?.clientCredentials?.message} noMargin>
            {({ ariaInputProps }) => (
              <SecretInput
                error={!!errors?.clientCredentials}
                {...register("clientCredentials", {
                  validate: stringIsRequired(),
                  setValueAs: (value: string) => value.trim(),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormField label="Provider URL" error={errors?.identityProviderHost?.message} noMargin>
            {({ ariaInputProps }) => (
              <Input
                type="url"
                placeholder="https://"
                error={!!errors?.identityProviderHost}
                {...register("identityProviderHost", {
                  validate: validateRequiredURL(),
                  setValueAs: (value: string) => value.trim(),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          {useSlackMemberIdFromIdpForUserProfile && (
            <Controller
              name="claimMapping"
              control={control}
              render={({ field }) => (
                <FormToggleField
                  title="Use provided Slack Member ID"
                  tooltipInfo={TooltipSlackMemberIDForOIDC}
                  variant="switch"
                  onChange={field.onChange}
                  checked={field.value}
                />
              )}
            />
          )}
        </DrawerBody>

        <DrawerFooter fullWidth>
          <DrawerFooterActions>
            <DrawerCancelButton onClick={() => trackSegmentAnalyticsEvent("OIDC Set Up Cancel")} />
            <Button variant="primary" type="submit" loading={loading}>
              Save
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </form>
    </>
  );
};

export default SettingsSingleSignOnCreateOIDC;
