import { ErrorCircleColored } from "components/icons/generated";
import SystemMessage from "components/SystemMessage";
import Link from "ds/components/Link";
import useTitle from "hooks/useTitle";

const LoginLimitExceededPage = () => {
  useTitle("Login limit exceeded");

  return (
    <SystemMessage
      icon={ErrorCircleColored}
      title="Login denied"
      message={
        <>
          Sorry, looks like number of allowed logins has been exceeded. This could well be an error
          - try <Link href="/login">logging in again</Link> to see if that helps.
        </>
      }
    />
  );
};

export default LoginLimitExceededPage;
