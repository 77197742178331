import { forwardRef, ReactNode, useLayoutEffect } from "react";
import cx from "classnames";

import useCellKeyboardNavigation from "ds/components/Table/useKeyboardNavigation/useCellKeyboardNavigation";
import { AriaSort } from "types/Aria";
import useEnsureRef from "hooks/useEnsureRef";

import { TableColumnMeta } from "../../types";
import styles from "../styles.module.css";

type TableHeadColumnProps<Columns extends string> = {
  column: TableColumnMeta<Columns>;
  stickyRight?: boolean;
  stickyLeft?: boolean;
  ariaSort: AriaSort;
  children: ReactNode;
};

const TableHeadColumn = forwardRef<HTMLDivElement, TableHeadColumnProps<string>>(
  function TableHeadColumn({ column, stickyLeft, stickyRight, ariaSort, children }, ref) {
    const keyboardProps = useCellKeyboardNavigation();
    const columnRef = useEnsureRef<HTMLDivElement>(ref);

    useLayoutEffect(() => {
      if (columnRef.current) {
        columnRef.current.style.width = `${column.defaultWidth}px`;
        columnRef.current.style.maxWidth = `${column.maxWidth}px`;
        columnRef.current.style.minWidth = `${column.minWidth}px`;
      }
    }, [column.minWidth, column.maxWidth, column.defaultWidth, columnRef]);

    return (
      <div
        ref={columnRef}
        className={cx(styles.column, {
          [styles.sortable]: column.allowsSorting,
          [styles.interactive]: column.canHide,
          [styles.stickyRight]: stickyRight,
          [styles.stickyLeft]: stickyLeft,
        })}
        role="columnheader"
        aria-sort={ariaSort}
        {...keyboardProps}
      >
        {children}
      </div>
    );
  }
);

TableHeadColumn.displayName = "DS.Table.Head.Column";

export default TableHeadColumn as <Columns extends string>(
  props: TableHeadColumnProps<Columns> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof TableHeadColumn>;
