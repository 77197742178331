import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import ComboBoxHighlightText from "ds/components/ComboBox/HighlightText";
import ComboBoxInputValueItem from "ds/components/ComboBox/InputValueItem";
import ListBoxItem from "ds/components/ListBox/Item";
import CheckboxIcon from "ds/components/Toggle/CheckboxIcon";

type AssignRoleDrawerComboBoxItemProps = {
  /**
   * React-aria required id attribute on the children of the Collection for caching purposes.
   */
  id: string;
  label: string;
  isInputValueItem?: boolean;
  isSelected?: boolean;
  checkboxVisible?: boolean;
};

const AssignRoleDrawerComboBoxItem = ({
  id,
  label,
  isInputValueItem,
  isSelected,
  checkboxVisible,
}: AssignRoleDrawerComboBoxItemProps) => {
  if (isInputValueItem) {
    return (
      <ComboBoxInputValueItem textValue={id}>
        <Typography tag="span" variant="p-body3" color="primary">
          {label}
        </Typography>
      </ComboBoxInputValueItem>
    );
  }

  return (
    <ListBoxItem id={id} textValue={label} aria-describedby={`${id}-description`}>
      <Box gap="medium">
        {checkboxVisible && <CheckboxIcon isSelected={isSelected} />}
        <Box direction="column" gap="small" justify="center" align="start" fullWidth>
          <ComboBoxHighlightText text={label} />
        </Box>
      </Box>
    </ListBoxItem>
  );
};

export default AssignRoleDrawerComboBoxItem;
