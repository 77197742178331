import { useSearchParams } from "react-router-dom";

import useTitle from "hooks/useTitle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";

import IntegrationHeader from "./Header";
import IntegrationUsedBy from "./UsedBy";
import { CloudIntegration } from "../../types";
import { isAzureIntegration } from "../../utils";
import CloudIntegrationsAzureConsentFeedback from "../Azure/ConsentFeedback";

type CloudIntegrationsIntegrationProps = {
  integration: CloudIntegration;
};

const CloudIntegrationsIntegration = ({ integration }: CloudIntegrationsIntegrationProps) => {
  const [searchParams] = useSearchParams();
  const adminConsentFailedParam = searchParams.get("adminConsentFailed");
  const adminConsentFailed =
    !!adminConsentFailedParam && adminConsentFailedParam.toLowerCase() === "true";

  useTitle(`Cloud integrations · ${integration.name || ""}`);

  useBreadcrumbs(
    [
      {
        title: "Cloud integrations",
        link: "/cloud-integrations",
      },
      {
        title: integration?.name || "",
      },
    ],
    [integration]
  );

  return (
    <>
      <IntegrationHeader integration={integration} />
      {isAzureIntegration(integration) && (
        <CloudIntegrationsAzureConsentFeedback
          integration={integration}
          adminConsentFailed={adminConsentFailed}
          type="callout"
        />
      )}
      <IntegrationUsedBy integration={integration} />
    </>
  );
};

export default CloudIntegrationsIntegration;
