import { Action } from "types/generated";

import { AccessSection, FormValues } from "./types";

export const CONFIG: AccessSection[] = [
  {
    title: "Spaces",
    id: "space",
    permissions: [
      {
        action: Action.SpaceRead,
        id: "read",
        description: "Helper text",
      },
      {
        action: Action.SpaceWrite,
        id: "write",
        description: "Helper text",
      },
      {
        action: Action.SpaceAdmin,
        id: "admin",
        description: "Helper text",
      },
    ],
  },
];

export const DEFAULT_VALUES: FormValues = {
  name: "",
  description: "",
  permissions: CONFIG.reduce((acc, next) => {
    return {
      ...acc,
      [next.id]: next.permissions.reduce((acc, next) => ({ ...acc, [next.action]: false }), {}),
    };
  }, {}),
};
