import { gql, TypedDocumentNode } from "@apollo/client";

import {
  MutationAwsIntegrationCreateArgs,
  MutationAwsIntegrationUpdateArgs,
} from "types/generated";
import { AwsIntegration } from "types/generated";

export const CREATE_AWS_INTEGRATION: TypedDocumentNode<
  {
    awsIntegrationCreate: Pick<AwsIntegration, "id">;
  },
  MutationAwsIntegrationCreateArgs
> = gql`
  mutation CreateAwsIntegrationNew(
    $name: String!
    $roleArn: String!
    $generateCredentialsInWorker: Boolean!
    $externalID: String
    $durationSeconds: Int
    $labels: [String!]!
    $space: ID
  ) {
    awsIntegrationCreate(
      name: $name
      roleArn: $roleArn
      generateCredentialsInWorker: $generateCredentialsInWorker
      externalID: $externalID
      durationSeconds: $durationSeconds
      labels: $labels
      space: $space
    ) {
      id
    }
  }
`;

export const UPDATE_AWS_INTEGRATION: TypedDocumentNode<
  {
    awsIntegrationUpdate: Pick<AwsIntegration, "id">;
  },
  MutationAwsIntegrationUpdateArgs
> = gql`
  mutation UpdateAwsIntegrationNew(
    $id: ID!
    $name: String!
    $roleArn: String!
    $generateCredentialsInWorker: Boolean!
    $externalID: String!
    $durationSeconds: Int!
    $labels: [String!]!
    $space: ID
  ) {
    awsIntegrationUpdate(
      id: $id
      name: $name
      roleArn: $roleArn
      generateCredentialsInWorker: $generateCredentialsInWorker
      externalID: $externalID
      durationSeconds: $durationSeconds
      labels: $labels
      space: $space
    ) {
      id
    }
  }
`;
