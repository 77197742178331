import { EmptystatePickaxeColored } from "components/icons/generated";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import EmptyState from "ds/components/EmptyState";
import { getDocsUrl } from "utils/getDocsUrl";

const PrivateWorkerPoolWorkersEmpty = () => {
  return (
    <EmptyState
      title="No workers found"
      icon={EmptystatePickaxeColored}
      caption={
        <>
          No workers in this worker pool are currently connected to our message broker.
          <br />
          <ReadMoreDocsLink
            docsUrl={getDocsUrl("/concepts/worker-pools#setting-up")}
            title="Learn how to set up workers."
          />
        </>
      }
    />
  );
};

export default PrivateWorkerPoolWorkersEmpty;
