import { memo } from "react";

import TimestampHuman from "components/Timestamp/Human";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import { type RenderResourceRowCellProps } from ".";
import IacManagementEmptyCellValue from "../../components/CellEmptyValue";

type IacManagementTableResourceRowCellUpdatedAtProps = Pick<RenderResourceRowCellProps, "row">;

const IacManagementTableResourceRowCellUpdatedAt = memo(
  ({ row }: IacManagementTableResourceRowCellUpdatedAtProps) => {
    const timestamp = row.updater?.updatedAt;
    return (
      <IacManagementTableCell>
        {(timestamp && <TimestampHuman timestamp={timestamp} />) || <IacManagementEmptyCellValue />}
      </IacManagementTableCell>
    );
  }
);

IacManagementTableResourceRowCellUpdatedAt.displayName =
  "IacManagementTableResourceRowCellUpdatedAt";

export default IacManagementTableResourceRowCellUpdatedAt;
