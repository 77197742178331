import Box from "ds/components/Box";
import Link from "ds/components/Link";

import useIntegrationsListAnalytics from "../../useIntegrationsPageAnalytics";
import { CellComponentProps } from "./types";

type IntegrationsListItemNameCellProps = CellComponentProps;

const IntegrationsListItemNameCell = ({ integration }: IntegrationsListItemNameCellProps) => {
  const trackAnalytics = useIntegrationsListAnalytics();

  const integrationUrl = `/integration/${integration.id}`;

  return (
    <Box fullWidth>
      <Link
        size="small"
        variant="secondary"
        href={integrationUrl}
        onPress={() => trackAnalytics("Name link clicked")}
      >
        {integration.name}
      </Link>
    </Box>
  );
};

export default IntegrationsListItemNameCell;
