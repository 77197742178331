import { useContext } from "react";
import { ComboBoxStateContext } from "react-aria-components";

import { ChevronDown } from "components/icons/generated";

import ButtonIcon from "../ButtonIcon";

type ComboBoxClearButtonProps = {
  isDisabled?: boolean;
};
const ComboBoxOpenButton = ({ isDisabled }: ComboBoxClearButtonProps) => {
  const state = useContext(ComboBoxStateContext);

  return (
    <ButtonIcon variant="ghost" icon={ChevronDown} disabled={isDisabled}>
      {state?.isOpen ? "Close" : "Open"}
    </ButtonIcon>
  );
};

ComboBoxOpenButton.displayName = "DS.ComboBox.OpenButton";

export default ComboBoxOpenButton;
