import TextLink from "components/DocumentationSnippets/TextLink";
import { ArrowsLeftRight, Link, Package, Policy, Space } from "components/icons/generated";
import Typography from "ds/components/Typography";
import { IconComponent } from "types/Icon";
import { DocsUrl, getDocsUrl } from "utils/getDocsUrl";

export type KnowledgeBaseItemActionProps =
  | {
      buttonLink: (origin: string) => string;
      drawerContent?: never;
      drawerFullDocs?: never;
      externalLinks?: never;
    }
  | {
      drawerContent: React.ReactNode;
      drawerFullDocs: DocsUrl;
      buttonLink?: never;
      externalLinks?: never;
    }
  | {
      externalLinks: {
        title: string;
        link: string;
      }[];
      buttonLink?: never;
      drawerContent?: never;
      drawerFullDocs?: never;
    };

type KnowledgeBaseItem = {
  title: string;
  description: string;
  icon: IconComponent;
  buttonTitle: string;
} & KnowledgeBaseItemActionProps;

export const RESOURCES: KnowledgeBaseItem[] = [
  {
    title: "How to migrate?",
    description:
      "Learn how you can use our Spacelift Migration Kit to speed up the process of migration.",
    icon: ArrowsLeftRight,
    buttonTitle: "Choose vendor",
    externalLinks: [
      {
        title: "Atlantis",
        link: "https://spacelift.io/blog/atlantis-workflow-on-spacelift",
      },
      {
        title: "TFC/TFE",
        link: "https://spacelift.io/blog/how-to-migrate-from-terraform-cloud",
      },
    ],
  },
  {
    title: "Spacelift Policy Library",
    description:
      "Collection of OPA policy examples that can be directly used with Spacelift, or tweaked to your needs.",
    icon: Policy,
    buttonTitle: "Explore",
    buttonLink: (origin: string) => `${origin}/policies-templates`,
  },
  {
    title: "Hooks within Spacelift",
    description:
      "Hooks are additional commands that can be performed at various stages of the run lifecycle.",
    icon: Link,
    buttonTitle: "Read more",
    drawerContent: (
      <Typography tag="p" variant="p-body2">
        Hooks are additional commands that can be performed at various stages of the run lifecycle.
        These hooks are essentially scripts or commands that are executed before and after certain
        phases in the Spacelift workflow. The key phases where hooks can be applied are:
        <ul>
          <li>
            <b>Initialization</b>: with before_init and after_init hooks.
          </li>
          <li>
            <b>Planning</b>: with before_plan and after_plan hooks.
          </li>
          <li>
            <b>Applying</b>: with before_apply and after_apply hooks.
          </li>
          <li>
            <b>Destroying</b>: with before_destroy and after_destroy hooks.
          </li>
          <li>
            <b>Performing</b>: with before_perform and after_perform hooks.
          </li>
          <li>
            <b>Finally</b>: with after_run hooks, which are executed after each actively processed
            run, regardless of its outcome.
          </li>
        </ul>
        <br />
        <Typography tag="span" variant="p-t5">
          Hooks have multiple purposes
        </Typography>
        <ul>
          <li>Modifying the workspace (like setting up symlinks or moving files).</li>
          <li>Running validations (using tools like tfsec, tflint, or terraform fmt).</li>
          <li>Adding debugging commands such as “pwd” & “ls”</li>
          <li>Adding scripts to your workflow.</li>
        </ul>
        <br />
        <Typography tag="span" variant="p-t5">
          Tips
        </Typography>
        <br />
        <ul>
          <li>
            <b>Avoid Newlines in Hooks</b>
            <br />
            It's advised not to use newlines in hooks because chaining commands with double
            ampersands (&&) could hide a non-zero exit code if the last command in the
            newline-separated block succeeds. Instead, add multiple hooks.
          </li>
          <br />
          <li>
            <b>Custom Runner Image for Persistent Tools</b>
            <br />
            To ensure tool availability after a pause in the run, consider baking the tool into a
            custom runner image.
          </li>
          <br />
          <li>
            <b>Command Customization in GUI</b>
            <br />
            The GUI allows easy customization of commands with features like drag-and-drop and
            in-line editing.
          </li>
          <br />
          <li>
            <b>Script Overriding</b>
            <br />
            Scripts can be overridden by the runtime configuration specified in the
            .spacelift/config.yml file.
          </li>
          <br />
          <li>
            <b>Phase Continuity</b>
            <br />
            Commands run in the same shell session as the phase itself, so any shell variables
            exported by the preceding scripts are accessible in the phase.
          </li>
        </ul>
        <br />
      </Typography>
    ),
    drawerFullDocs: getDocsUrl("/concepts/stack/stack-settings#customizing-workflow"),
  },
  {
    title: "Modules within Spacelift",
    description:
      "Manage Terraform/OpenTofu modules, allowing to abstract common functionality in your infrastructure setup.",
    icon: Package,
    buttonTitle: "Read more",
    drawerContent: (
      <Typography tag="p" variant="p-body2">
        Spacelift's Module Registry is designed for managing Terraform/OpenTofu modules, allowing
        users to abstract common functionality in their infrastructure setups. Modules in Spacelift
        are identified using a unique naming convention and are integrated with various features for
        efficient management.
        <br />
        <br />
        <Typography tag="span" variant="p-t5">
          Advantages of Hosting Modules on Spacelift
        </Typography>
        <ul>
          <li>
            <b>Integration with Spacelift Features</b>
            <br />
            Seamlessly integrates with Environments, Policies, Contexts, and Worker Pools.
          </li>
          <br />
          <li>
            <b>Module Configuration</b>
            <br />
            Modules can include submodules and multiple test cases. The configuration file
            (config.yml) specifies module version, test defaults, and test cases, each with unique
            names.
          </li>
          <br />
          <li>
            <b>Tests</b>
            <br />
            Tests verify module functionality, allowing different runtime environments,
            Terraform/OpenTofu versions, and inputs. Tests are executed in parallel, covering both
            proposed and tracked changes.
          </li>
          <br />
          <li>
            <b>Versions</b>
            <br />
            Spacelift creates new versions upon successful test completion, requiring adherence to
            semantic versioning. You can also manage your versions with git tags with a push policy.
          </li>
        </ul>
        <br />
        <Typography tag="span" variant="p-t5">
          Tips
        </Typography>
        <br />
        <ul>
          <li>
            <b>Repository Structure</b>
            <br />
            Ensure correct repository structure with necessary Terraform files and
            .spacelift/config.yml.
          </li>
          <br />

          <li>
            <b>Module Sharing</b>
            <br />
            Utilize the sharing feature to make modules accessible to other Spacelift accounts.
          </li>
          <br />
          <li>
            <b>Dependabot Usage</b>
            <br />
            Configure Dependabot for automatic module version updates. Old versions can be imported
            using a script and spacectl.
          </li>
        </ul>
        <br />
      </Typography>
    ),
    drawerFullDocs: getDocsUrl("/vendors/terraform/module-registry.html"),
  },
  {
    title: "Policy as Code",
    description:
      "Express rules using a high-level programming language and treat them as you normally treat code.",
    icon: Policy,
    buttonTitle: "Read more",
    drawerContent: (
      <Typography tag="div" variant="p-body2">
        Policy-as-code is the idea of expressing rules using a high-level programming language and
        treating them as you normally treat code, which includes version control as well as
        continuous integration and deployment.
        <br />
        <br />
        Spacelift as a platform is built around this concept and allows defining policies that
        involve various decision points in the application. User can define following policy types:
        <br />
        <br />
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Purpose</th>
              <th>Types</th>
              <th>Returns</th>
              <th>Rules</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TextLink href={getDocsUrl("/concepts/policy/login-policy")}>Login</TextLink>
              </td>
              <td>Allow or deny login, grant admin access</td>
              <td>Positive and negative</td>
              <td>
                <code>boolean</code>
              </td>
              <td>
                <code>allow</code>, <code>admin</code>, <code>deny</code>, <code>deny_admin</code>
              </td>
            </tr>
            <tr>
              <td>
                <TextLink href={getDocsUrl("/concepts/policy/approval-policy")}>Approval</TextLink>
              </td>
              <td>Who can approve or reject a run and how a run can be approved</td>
              <td>Positive and negative</td>
              <td>
                <code>boolean</code>
              </td>
              <td>
                <code>approve</code>, <code>reject</code>
              </td>
            </tr>
            <tr>
              <td>
                <TextLink href={getDocsUrl("/concepts/policy/notification-policy")}>
                  Notification
                </TextLink>
              </td>
              <td>Routes and filters notifications</td>
              <td>Positive</td>
              <td>
                <code>map&lt;string, any&gt;</code>
              </td>
              <td>
                <code>inbox</code>, <code>slack</code>, <code>webhook</code>
              </td>
            </tr>
            <tr>
              <td>
                <TextLink href={getDocsUrl("/concepts/policy/terraform-plan-policy")}>
                  Plan
                </TextLink>
              </td>
              <td>
                Gives feedback on <TextLink href={getDocsUrl("/concepts/run")}>runs</TextLink> after{" "}
                <TextLink href={getDocsUrl("/concepts/run/proposed#planning")}>planning</TextLink>{" "}
                phase
              </td>
              <td>Negative</td>
              <td>
                <code>set&lt;string&gt;</code>
              </td>
              <td>
                <code>deny</code>, <code>warn</code>
              </td>
            </tr>
            <tr>
              <td>
                <TextLink href={getDocsUrl("/concepts/policy/push-policy")}>Push</TextLink>
              </td>
              <td>Determines how a Git push event is interpreted</td>
              <td>Positive and negative</td>
              <td>
                <code>boolean</code>
              </td>
              <td>
                <code>track</code>, <code>propose</code>, <code>ignore</code>,{" "}
                <code>ignore_track</code>, <code>notrigger</code>, <code>notify</code>
              </td>
            </tr>

            <tr>
              <td>
                <TextLink href={getDocsUrl("/concepts/policy/trigger-policy")}>Trigger</TextLink>
              </td>
              <td>
                Selects <TextLink href={getDocsUrl("/concepts/stack")}>stacks</TextLink> for which
                to trigger a{" "}
                <TextLink href={getDocsUrl("/concepts/run/tracked")}>tracked run</TextLink>
              </td>
              <td>Positive</td>
              <td>
                <code>set&lt;string&gt;</code>
              </td>
              <td>
                <code>trigger</code>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        Overall, the impact of using policy-as-code in managing infrastructure is significant. It
        brings automation, consistency, and reliability to the enforcement of rules.
        <br />
        <br />
        <Typography tag="span" variant="p-t6">
          Tips
        </Typography>
        <br />
        <ul>
          <li>
            <b>Sampling & Policy workbench</b>
            <br />
            Each of Spacelift's policies supports an additional boolean rule called sample.
            Returning true from this rule means that the input to the policy evaluation is captured,
            you can then review this input in the policy workbench to test and craft your policies.
          </li>
          <br />
          <li>
            <b>Review the Spacelift Policy Library</b>
            <br />
            Review the Collection of OPA policy examples that can be directly used with Spacelift,
            or tweaked to your needs.
          </li>
        </ul>
        <br />
      </Typography>
    ),
    drawerFullDocs: getDocsUrl("/concepts/policy"),
  },
  {
    title: "How do Spaces work?",
    description:
      "Divide your account into multiple collections of various Spacelift entities like Stacks, Policies, and Contexts.",
    icon: Space,
    buttonTitle: "Read more",
    drawerContent: (
      <Typography tag="p" variant="p-body2">
        As Spacelift usage grows, the need for enhanced access control and self-service becomes
        crucial. Relying on a single team of admins is not scalable with the increasing number of
        users. To address this, Spacelift allows for the delegation of partial admin rights,
        enabling teams to manage their own environments without accessing the entire account or
        other teams' areas. This is achieved by dividing the account into multiple
        'Spaces'—collections of various Spacelift entities like Stacks, Policies, and Contexts.
        <br />
        <br />
        <Typography tag="span" variant="p-t5">
          Overview of Spacelift Access Control
        </Typography>
        <br />
        Spacelift Access Control is managed through Login policies or User Management within Spaces.
        The roles assigned to users for specific spaces, determining their level of access and
        permissions.
        <br />
        <br />
        <Typography tag="span" variant="p-t6">
          There are three primary roles:
        </Typography>
        <br />
        <ul>
          <li>
            <b>Read</b>
            <br />
            Users can view stacks and attachable entities but cannot create or modify them.
          </li>
          <br />
          <li>
            <b>Write</b>
            <br />
            An extension of Read, allowing users to trigger runs in the stacks they can view.
          </li>
          <br />
          <li>
            <b>Admin</b>
            <br />
            Users can create and modify stacks and attachable entities, as well as trigger runs.
          </li>
          <br />
          <li>
            <b>Root Space Admin</b>
            <br />A special role which is assigned to those with Admin permissions in the root
            space, effectively granting them administrative control over the entire account.
          </li>
        </ul>
        <br />A comparison table of what users with given roles are capable of can be found{" "}
        <TextLink href={getDocsUrl("/concepts/spaces/access-control")}>here</TextLink>
        <br />
        <br />
        <Typography tag="span" variant="p-t5">
          Key Points about spaces
        </Typography>
        <ul>
          <li>
            <b>Administrative Stacks</b>
            <br />
            Automatically receive the Admin role in their space.
          </li>
          <br />

          <li>
            <b>Purpose of roles</b>
            <br />
            Control access to Spaces in Spacelift.
          </li>
          <br />
          <li>
            <b>Policy Creation</b>
            <br />
            Login policies can only be created in the root space.
          </li>
          <br />
          <li>
            <b>Inheritance</b>
            <br />
            Determines if a space inherits resources from its parent space.
          </li>
          <br />
          <li>
            <b>Role Propagation</b>
            <br />
            Inheritance also affects how roles are disseminated between spaces.
          </li>
        </ul>
        <br />
        <Typography tag="span" variant="p-t5">
          Tips
        </Typography>
        <br />
        <ul>
          <li>
            <b>Inheritance Use</b>
            <br />
            Utilize inheritance to streamline resource and role sharing across spaces.
          </li>
          <br />
          <li>
            <b>Policy Autoattachment</b>
            <br />
            Use Policy Autoattachment with autoattach:* labels for enforcing policies across
            inherited spaces.
          </li>
        </ul>
        <br />
      </Typography>
    ),
    drawerFullDocs: getDocsUrl("/concepts/spaces"),
  },
];
