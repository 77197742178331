import { Pages } from "./types";

export const enum AnalyticsPageIntegration {
  IntegrationsList = "IntegrationsList",
  IntegrationUsedBy = "IntegrationUsedBy",
}

const Integrations = "Integrations";

export const pagesIntegrations: Pages<AnalyticsPageIntegration> = {
  [AnalyticsPageIntegration.IntegrationsList]: [Integrations, "Integrations list"],
  [AnalyticsPageIntegration.IntegrationUsedBy]: [Integrations, "Integration used by"],
};
