import { NetworkStatus } from "@apollo/client";

import { ConfettiColored } from "components/icons/generated";
import Box from "ds/components/Box";
import Timestamp from "components/time/Timestamp";
import MissingDataBanner from "components/MissingDataBanner";
import usePolledQuery from "apollo/usePolledQuery";
import { PollingIntervalGroups } from "apollo/constants";

import DashboardWidgetsEmptyStateRuns from "../EmptyStateRuns";
import DashboardWidgetsRunTile from "../RunTile";
import DashboardWidgetsRunsSkeleton from "../RunsSkeleton";
import { GetRunsRequiringAttention } from "./types";
import { GET_RUNS_REQUIRING_ATTENTION } from "./gql";
import { Widget } from "../../types";

// TODO: [dashboard] add limit to query once added to API
const LIMIT = 3;

const DashboardWidgetsRunWorkflow = () => {
  const { loading, data, error, refetch, networkStatus } =
    usePolledQuery<GetRunsRequiringAttention>(GET_RUNS_REQUIRING_ATTENTION, {
      pollingGroup: PollingIntervalGroups.Dashboard,
    });

  const showErrorBanner = !!error;

  const isLoading =
    (loading && !data?.metrics?.runsRequiringAttention) || networkStatus === NetworkStatus.refetch;

  const runs = data?.metrics?.runsRequiringAttention?.slice(0, LIMIT);
  let emptyState;

  const isEmpty = !isLoading && !runs?.length;

  const hasRuns = !!data?.metrics?.hasRuns;

  if (!hasRuns) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns showDocs widget={Widget.RunsThatRequireAttention} />
    );
  }

  if (hasRuns && isEmpty) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns
        caption="All set! Your workflow is up-to-date—no approvals needed!"
        icon={ConfettiColored}
        widget={Widget.RunsThatRequireAttention}
        onlyCaption
      />
    );
  }

  if (showErrorBanner) {
    return (
      <MissingDataBanner
        text="Couldn’t load runs data. Please try to refresh or come back later. "
        refreshHandler={refetch}
        fullWidth
      />
    );
  }

  if (isLoading) {
    return <DashboardWidgetsRunsSkeleton />;
  }

  return (
    <Box direction="column" gap="medium" fullWidth>
      {emptyState}

      {!emptyState &&
        runs?.map(({ id, title, createdAt, state, stackTile, needsApproval }, i) => (
          <DashboardWidgetsRunTile
            key={i}
            id={id}
            title={title}
            state={state}
            stackTile={stackTile}
            caption={
              <>
                Committed <Timestamp timestamp={createdAt} />
              </>
            }
            needsApproval={needsApproval}
            analyticsTitle="Runs That Require Attention Widget"
          />
        ))}
    </Box>
  );
};

export default DashboardWidgetsRunWorkflow;
