import { RenderGroupRowProps } from "../../components/Table/types";
import IacManagementTableGroupRowCellName from "./CellName";
import IacManagementTableGroupRowCellResourcesCount from "./CellResourcesCount";
import IacManagementTableGroupRowCellDrifted from "./CellDrifted";
import IacManagementTableGroupRowCellSpace from "./CellSpace";
import IacManagementTableGroupRowCellVendor from "./CellVendor";
import IacManagementTableGroupRowCellUpdatedAt from "./CellUpdatedAt";
import IacManagementTableGroupRowCellActions from "./CellActions";
import { IAC_MANAGEMENTS_TABLE_COLUMNS } from "../types";

export type RenderGroupRowCellProps = Pick<
  RenderGroupRowProps,
  "row" | "stackViewMode" | "fetchDataLoading" | "groupMeta" | "loadResources"
>;

const CELL_PER_COLUMN = {
  [IAC_MANAGEMENTS_TABLE_COLUMNS.NAME]: IacManagementTableGroupRowCellName,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.RESOURCES]: IacManagementTableGroupRowCellResourcesCount,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.DRIFT_DETECTED]: IacManagementTableGroupRowCellDrifted,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.SPACE]: IacManagementTableGroupRowCellSpace,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.VENDOR]: IacManagementTableGroupRowCellVendor,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.UPDATED_AT]: IacManagementTableGroupRowCellUpdatedAt,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.ACTIONS]: IacManagementTableGroupRowCellActions,
};

type IacManagementTableGroupRowProps = RenderGroupRowProps;

const IacManagementTableGroupRow = ({
  columns,
  row,
  stackViewMode,
  fetchDataLoading,
  groupMeta,
  loadResources,
}: IacManagementTableGroupRowProps) => {
  const cellProps = {
    row,
    stackViewMode,
    fetchDataLoading,
    groupMeta,
    loadResources,
  };

  return columns.map((column) => {
    const Cell = CELL_PER_COLUMN[column.id as IAC_MANAGEMENTS_TABLE_COLUMNS];
    return <Cell key={`${row.id}-${column.id}`} {...cellProps} />;
  });
};

export default IacManagementTableGroupRow;
