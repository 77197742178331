import { useSearchParams } from "react-router-dom";
import { CSSProperties, useRef } from "react";
import { Row, Cell } from "react-aria-components";

import EmptyState from "ds/components/EmptyState";
import Table from "components/Table";
import TableRow from "components/Table/Row";
import TableContextProvider from "components/Table/Context";
import useTableSort from "components/Table/useTableSort";
import { SearchQueryOrderDirection } from "types/generated";
import { getSearchQuery } from "components/SearchInput/helpers";
import {
  EmptystateSearchNoResultsColored,
  EmptystateStacksColored,
} from "components/icons/generated";
import Box from "ds/components/Box";
import SearchInput from "components/SearchInput";
import PaginationIndicator from "components/PaginationIndicator";
import PageInfo from "components/PageWrapper/Info";

import { CloudIntegration } from "../../../types";
import {
  INITIAL_COLUMNS_CONFIG,
  SORT_CONFIG,
  USED_BY_COLUMN_CONFIG,
  FILTERS_ORDER_SETTINGS_KEY_USED_BY,
  ROW_HEADER_ID,
  TABLE_COLUMNS_STORAGE_KEY,
  USED_BY_COLUMN_ID,
} from "./constants";
import { useFilteredStacks } from "./useFilteredStacks";
import UsedByTableRow from "./TableRow";
import { AttachedStack } from "./types";

type IntegrationUsedByProps = {
  integration: CloudIntegration;
};

const renderRow = (
  item: Partial<AttachedStack> & {
    virtIndex?: number;
    style?: CSSProperties;
    virtKey?: string;
    id: string;
    height?: number;
    ref?: (node: Element | null | undefined) => void;
  }
) => {
  if ("height" in item && (item.id === "after" || item.id === "before")) {
    return (
      <Row>
        <Cell style={{ height: item.height }} />
      </Row>
    );
  }

  return (
    <TableRow
      index={item.virtIndex}
      ref={item.ref}
      style={item.style}
      key={item.virtKey}
      id={item.id}
      name={item.stackName}
    >
      <UsedByTableRow stack={item as AttachedStack} />
    </TableRow>
  );
};

const IntegrationUsedBy = ({ integration }: IntegrationUsedByProps) => {
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const searchQuery = getSearchQuery(searchParams);

  const { handleSortOptionChange, sortDescriptor } = useTableSort({
    filtersOrderSettingsKey: FILTERS_ORDER_SETTINGS_KEY_USED_BY,
    sortConfig: SORT_CONFIG,
    initialSortOption: SORT_CONFIG[USED_BY_COLUMN_ID.NAME],
    initialSortDirection: SearchQueryOrderDirection.Asc,
  });

  const filteredStacks = useFilteredStacks(integration.attachedStacks, sortDescriptor, searchQuery);
  const totalCount = integration.attachedStacks.length;
  const filteredCount = filteredStacks.length;

  return (
    <>
      <PageInfo title="Used by">
        {integration.attachedStacks.length > 0 && (
          <Box direction="row" align="center" gap="0 large">
            <PaginationIndicator
              currentCount={filteredCount}
              totalCount={totalCount}
              loading={false}
            />
            <SearchInput
              placeholder="Search by name..."
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY_USED_BY}
            />
          </Box>
        )}
      </PageInfo>

      <TableContextProvider
        rowHeaderId={ROW_HEADER_ID}
        localStorageId={TABLE_COLUMNS_STORAGE_KEY}
        columnsConfig={USED_BY_COLUMN_CONFIG}
        initialColumnViewConfig={INITIAL_COLUMNS_CONFIG}
        sortDescriptor={sortDescriptor}
        setSortDescriptor={handleSortOptionChange}
      >
        {filteredStacks.length > 0 && (
          <Table ariaLabel="Used by list" items={filteredStacks} ref={virtualizedListContainerRef}>
            {renderRow}
          </Table>
        )}
      </TableContextProvider>

      {filteredStacks.length === 0 && !searchQuery && (
        <EmptyState
          title="There are no stacks or modules that use this integration yet"
          caption="Attach this integration to stack or module to start using it."
          icon={EmptystateStacksColored}
        />
      )}
      {filteredStacks.length === 0 && searchQuery && (
        <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
      )}
    </>
  );
};

export default IntegrationUsedBy;
