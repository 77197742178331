import { SortDescriptor } from "react-aria-components";

import { PERMISSIONS_COLUMN_ID } from "../constants";
import { Permission } from "../types";

export const sortRoles = (items: Permission[], sortDescriptor: SortDescriptor) => {
  if (!sortDescriptor.column) {
    return items;
  }

  const isAscending = sortDescriptor.direction === "ascending";

  return [...items].sort((a, b) => {
    let valueA: string | undefined;
    let valueB: string | undefined;

    switch (sortDescriptor.column as PERMISSIONS_COLUMN_ID) {
      case PERMISSIONS_COLUMN_ID.ACTION:
        valueA = a.name;
        valueB = b.name;
        break;
      case PERMISSIONS_COLUMN_ID.SUBJECT:
        valueA = a.subject;
        valueB = b.subject;
        break;
      case PERMISSIONS_COLUMN_ID.DESCRIPTION:
        valueA = "";
        valueB = "";
        break;
      case PERMISSIONS_COLUMN_ID.DELETE_ACTION:
        valueA = "";
        valueB = "";
        break;
    }

    valueA = valueA || "";
    valueB = valueB || "";

    return isAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
  });
};
