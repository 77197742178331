import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import CodeEditor from "components/CodeEditor";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import { toBlob } from "components/config/helpers";
import { EmptystateLockColored, ErrorCircleColored } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import useTypedContext from "hooks/useTypedContext";

import { FILE_PREVIEW_MAX_SIZE } from "../../constants";
import { ContextContext } from "../../Context";
import ContextMountedFilesListItemActions from "./Actions";
import DraftForm from "./DraftForm";
import ContextMountedFilesListItemHeader from "./Header";
import styles from "./styles.module.css";
import { ContextMountedFileFormFields, ContextMountedFilesListItemRegularProps } from "./types";

const options = {
  quickSuggestions: false,
};

const ContextMountedFilesListItemRegular = ({
  item,
  onOpenFileDetailsDrawer,
  isCollapsed,
  toggle,
}: ContextMountedFilesListItemRegularProps) => {
  const { canManageContext } = useTypedContext(ContextContext);
  const [isPreviewMode, setPreviewMode] = useState(true);
  const form = useFormContext<ContextMountedFileFormFields>();
  const blob = useMemo(() => toBlob(item.value || ""), [item.value]);

  const { formState, setValue } = form;
  const hasPreviewError = blob.size > FILE_PREVIEW_MAX_SIZE;

  useEffect(() => {
    const processValue = async () => {
      if (hasPreviewError) {
        return;
      }

      if (blob.size > 0) {
        try {
          setValue("fileBody", await blob.text());
        } catch {
          setValue("fileBody", "");
        }
      } else {
        setValue("fileBody", "");
      }
    };

    processValue();
  }, [blob, hasPreviewError, setValue, isCollapsed]);

  const handleEditDrawerOpen = () => {
    onOpenFileDetailsDrawer?.(item);
  };

  const handleCancel = () => {
    toggle(true);
    setPreviewMode(true);
  };

  const handleToggle = () => {
    toggle();
    setPreviewMode(true);
  };

  const showSecretFileDraftForm = !isPreviewMode && (item.writeOnly || hasPreviewError);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={handleToggle}>
      <ContextMountedFilesListItemHeader
        item={item}
        isCollapsed={isCollapsed}
        onOpenEditDrawer={handleEditDrawerOpen}
      >
        <ContextMountedFilesListItemActions
          item={item}
          forceOverwrite={hasPreviewError}
          onEdit={() => {
            setPreviewMode(false);
            toggle(false);
          }}
        />
      </ContextMountedFilesListItemHeader>
      <CollapsiblePanelContent padding="medium large large">
        {showSecretFileDraftForm && <DraftForm onCancel={handleCancel} />}
        {!showSecretFileDraftForm && (
          <Box className={styles.fileWrapper} direction="column">
            <Box justify="between" fullWidth padding="large" align="center" direction="column">
              <Box justify="between" fullWidth align="center">
                <CollapsiblePanelTitle>File</CollapsiblePanelTitle>
                {!isPreviewMode && canManageContext && (
                  <Box gap="medium">
                    <Button variant="secondary" size="small" onPress={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      size="small"
                      disabled={!formState.isDirty}
                      loading={formState.isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              padding={hasPreviewError ? "large" : "0"}
              className={styles.file}
              align="stretch"
              justify="stretch"
              direction="column"
            >
              {isPreviewMode && item.writeOnly && (
                <EmptyState
                  margin="large 0"
                  icon={EmptystateLockColored}
                  title="This is a secret file"
                  caption="This means you can not edit it, only overwrite or upload a new one."
                />
              )}

              {!item.writeOnly && !hasPreviewError && (
                <Controller
                  name="fileBody"
                  render={({ field }) => (
                    <CodeEditor
                      className={styles.variablePreviewEditor}
                      body={field.value}
                      onChange={field.onChange}
                      language="plaintext"
                      readOnly={isPreviewMode}
                      options={options}
                    />
                  )}
                />
              )}
              {hasPreviewError && (
                <EmptyState
                  icon={ErrorCircleColored}
                  title="File too large"
                  caption="We can’t display file preview. File is either larger than 0,5MB or in a wrong format."
                />
              )}
            </Box>
          </Box>
        )}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default ContextMountedFilesListItemRegular;
