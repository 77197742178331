import Timestamp from "components/time/Timestamp";
import Typography from "ds/components/Typography";

import { CellComponentProps } from "./types";

type BlueprintsRowUpdatedAtCellProps = CellComponentProps;

const BlueprintsRowUpdatedAtCell = ({ blueprint }: BlueprintsRowUpdatedAtCellProps) => {
  return (
    <Typography tag="span" variant="p-body3">
      <Timestamp timestamp={blueprint.updatedAt} />
    </Typography>
  );
};

export default BlueprintsRowUpdatedAtCell;
