import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";

import { DETACH_BLUEPRINT_INTEGRATION } from "../../gql";

type DetachConfirmationProps = {
  id: string;
  name: string;
  refetchQueriesOnDelete?: string[];
};

const DetachConfirmation = create(function DetachConfirmation({
  id,
  name,
  refetchQueriesOnDelete,
}: DetachConfirmationProps) {
  const modal = useModal();

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [detachIntegration, { loading, data }] = useMutation(DETACH_BLUEPRINT_INTEGRATION, {
    refetchQueries: refetchQueriesOnDelete,
    awaitRefetchQueries: true,
    variables: {
      id,
    },
  });

  const handleDetachIntegration = () => {
    detachIntegration()
      .then(() => {
        modal.resolve();
        modal.hide();
        reportSuccess({ message: "Integration successfully detached" });
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Detach integration"
      onConfirm={handleDetachIntegration}
      isLoading={loading}
      isDismissable={!loading && !data?.blueprintAttachToIntegration?.id}
      confirmationButtonLabel="Detach"
    >
      <ConfirmationModalMessage>
        Are you sure you want to detach the integration with the name: <strong>{name}</strong>?
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showDetachConfirmation = (props: DetachConfirmationProps) =>
  show(DetachConfirmation, props);
