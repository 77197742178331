import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import FormFieldTagsMagicBanner from "components/FormFields/Tags/MagicLabels";
import FormFieldViewText from "components/FormFields/ViewText";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import useBlockNavigationModal from "hooks/useBlockNavigationModal";

import { ModuleSettingsContext } from "../Context";
import { useUpdateModule } from "../hooks/useUpdateModule";
import { getDefaultValues } from "./utils";

type FormValues = {
  space: string;
  labels: Array<{ value: string }>;
  description: string;
};

const ModuleSettingsDetailsEditForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);
  const { updateModule, loading } = useUpdateModule({ module });

  const builderForm = useForm<FormValues>({
    defaultValues: getDefaultValues(module),
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful },
  } = builderForm;

  useBlockNavigationModal({ isNavigationBlocked: isDirty });

  const onSubmit = useCallback(
    () =>
      handleSubmit((values: FormValues) => {
        return updateModule({
          ...values,
          labels: values.labels.map(({ value }) => value),
        });
      })(),
    [updateModule, handleSubmit]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getDefaultValues(module));
    }
  }, [isSubmitSuccessful, reset, module]);

  return (
    <FormProvider {...builderForm}>
      <FormFieldViewText label="Name" value={module.id} />
      <FormFieldViewText label="Provider" value={module.terraformProvider} />
      <FormFieldSpace />
      <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />
      <FormFieldTagsMagicBanner entityType="module" margin="large 0 0" />
      <FormField label="Description" isOptional>
        {({ ariaInputProps }) => (
          <Textarea
            placeholder="Enter description here..."
            maxRows={15}
            {...register("description")}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <Box justify="end" margin="large 0 0">
        <Button variant="primary" onPress={onSubmit} disabled={loading} loading={loading}>
          Save
        </Button>
      </Box>
    </FormProvider>
  );
};

export default ModuleSettingsDetailsEditForm;
