import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldTags from "components/FormFields/Tags";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import { trackSegmentEvent } from "shared/Analytics";
import { PolicyType } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { validatePolicyName } from "utils/formValidators";

import { LOGIN_POLICY_UPDATE_DRAWER_TEST_ID } from "../LoginPolicyDetails/constants";
import { GET_POLICY, UPDATE_POLICY } from "../LoginPolicyDetails/gql";
import { UpdatePolicyFields } from "../LoginPolicyDetails/types";

type LoginPolicyEditDetailsDrawerProps = {
  name?: string;
  labels?: string[];
  body?: string;
  policyId?: string;
  takenPolicyNames: string[];
};

const LoginPolicyEditDetailsDrawer = createDrawer(
  ({ name, labels, body, policyId, takenPolicyNames }: LoginPolicyEditDetailsDrawerProps) => {
    const drawer = useModal();
    const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

    const [updatePolicy, { loading: updatePolicyLoading }] = useMutation<{
      policyUpdate: { name: string };
    }>(UPDATE_POLICY, {
      refetchQueries: [
        { query: GET_POLICY, variables: { policyId } },
        "GetLoginPolicies",
        "GetPolicyNames",
      ],
      // APOLLO CLIENT UPDATE
      onCompleted: (data) => {
        if (data) {
          trackSegmentEvent("Login Policy Details Updated");
          reportSuccess({ message: `Login policy successfully updated` });
          drawer.hide();
        }
      },
      onError,
    });

    const updatePolicyForm = useForm<UpdatePolicyFields>({
      defaultValues: {
        name,
        labels: labels?.map((label) => ({ value: label })),
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isDirty },
    } = updatePolicyForm;

    useEffect(() => {
      reset({
        name,
        labels: labels?.map((label) => ({ value: label })),
      });
    }, [name, labels, reset]);

    const updateLoginPolicy: SubmitHandler<UpdatePolicyFields> = (formData) => {
      if (typeof body === "undefined" || !formData.name || !policyId) {
        reportError({ message: "Unable to save login policy" });
      } else {
        updatePolicy({
          variables: {
            id: policyId,
            name: formData.name,
            body,
            type: PolicyType.Login,
            labels: formData.labels?.map(({ value }) => value),
          },
        }).catch(onError);
      }
    };

    const namesToValidate = useMemo(
      () => takenPolicyNames.map((value) => value.toLowerCase()),
      [takenPolicyNames]
    );

    return (
      <DrawerForm isDirty={isDirty} dataTestId={LOGIN_POLICY_UPDATE_DRAWER_TEST_ID}>
        <FormProvider {...updatePolicyForm}>
          <form onSubmit={handleSubmit(updateLoginPolicy)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Policy details" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight>
              <FormField label="Name" error={errors?.name?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Name of your policy"
                    error={!!errors?.name}
                    {...register("name", {
                      setValueAs: (value) => value.trim(),
                      validate: validatePolicyName(namesToValidate, name),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldTags
                stopEnterPropagation
                label="Labels"
                tagName="label"
                name="labels"
                isOptional
              />

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button variant="primary" loading={updatePolicyLoading} type="submit">
                    Save changes
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showLoginPolicyEditDetailsDrawer = createDrawerTrigger(LoginPolicyEditDetailsDrawer);
