import { NetworkStatus, useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  ManagedUserInvitation,
  SearchStacksOutput,
  SearchVcsIntegrationsOutput,
  VcsProvider,
} from "types/generated";

import { GET_SAAS_ONBOARDING_PROGRESS } from "./gql";
import { LaunchPadFeatureSaaS } from "./types";
import { LaunchPadsFeatureProgress } from "../types";

const noop = () => void 0;

const useSaaSOnboardingProgress = (skip?: boolean, flashOnError = true) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, error, refetch, networkStatus } = useQuery<{
    providers: VcsProvider[];
    searchVCSIntegrations: SearchVcsIntegrationsOutput;
    searchStacks: SearchStacksOutput;
    azureIntegrations: Array<{ id: string }>;
    awsIntegrations: Array<{ id: string }>;
    managedUsers?: Array<{ latestInvitation: ManagedUserInvitation | null }>;
  }>(GET_SAAS_ONBOARDING_PROGRESS, {
    variables: {
      searchVcsIntegrationsInput: {
        first: 1,
        orderBy: null,
        predicates: null,
      },
      searchStacksInput: {
        first: 1,
        orderBy: null,
        predicates: null,
      },
    },
    onError: flashOnError ? onError : noop,
    skip,
  });

  const isVcsConnected =
    data?.providers.includes(VcsProvider.Github) || !!data?.searchVCSIntegrations?.edges?.length;

  const isCloudIntegrated = !!data?.azureIntegrations?.length || !!data?.awsIntegrations?.length;

  const isStackCreated = !!data?.searchStacks?.edges?.length;

  const hasInvitedUsers =
    !!data?.managedUsers && data.managedUsers.filter((user) => user.latestInvitation).length > 0;

  const progressByFeature: LaunchPadsFeatureProgress<LaunchPadFeatureSaaS> = useMemo(
    () => ({
      [LaunchPadFeatureSaaS.InviteTeammates]: {
        isCompleted: hasInvitedUsers,
      },
      [LaunchPadFeatureSaaS.IntegrateVCS]: {
        isCompleted: isVcsConnected,
      },
      [LaunchPadFeatureSaaS.IntegrateCloud]: {
        isCompleted: isCloudIntegrated,
      },
      [LaunchPadFeatureSaaS.CreateStack]: {
        isCompleted: isStackCreated,
      },
    }),
    [hasInvitedUsers, isVcsConnected, isCloudIntegrated, isStackCreated]
  );

  const isOnboardingComplete = Object.values(progressByFeature).every((value) => value.isCompleted);

  return {
    onboardingProgressInitialLoading: (loading && !data) || networkStatus === NetworkStatus.refetch,
    progressByFeature,
    isOnboardingComplete,
    error,
    refetch,
  };
};

export default useSaaSOnboardingProgress;
