import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageIntegration } from "hooks/useAnalytics/pages/integrations";

export const INTEGRATIONS_COLUMNS_STORAGE_KEY = "integrationsColumnsConfig";

export enum INTEGRATIONS_COLUMN_ID {
  NAME = "NAME",
  SPACE = "SPACE",
  DESCRIPTION = "DESCRIPTION",
  DROPDOWN = "DROPDOWN",
}

export const ROW_HEADER_ID = INTEGRATIONS_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 600;

export const INTEGRATIONS_COLUMN_CONFIG: ColumnConfig = {
  [INTEGRATIONS_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    defaultWidth: 300,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
  [INTEGRATIONS_COLUMN_ID.SPACE]: {
    title: "Space",
    minWidth: 112,
    defaultWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
  [INTEGRATIONS_COLUMN_ID.DESCRIPTION]: {
    title: "Description",
    minWidth: 130,
    defaultWidth: 240,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
};

export const INTEGRATIONS_CUSTOM_END_COLUMN_CONFIG: ColumnConfig = {
  [INTEGRATIONS_COLUMN_ID.DROPDOWN]: {
    title: "More actions",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
  },
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageIntegration.IntegrationsList,
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(INTEGRATIONS_COLUMN_CONFIG) as INTEGRATIONS_COLUMN_ID[],
  hidden: [],
};
