import { LogoMsTeamsColored } from "components/icons/generated";
import LinkButton from "ds/components/Button/LinkButton";
import Tile from "ds/components/Tile";
import { getDocsUrl } from "utils/getDocsUrl";

const IntegrationsMSTeams = () => {
  return (
    <Tile
      title="Microsoft Teams"
      icon={LogoMsTeamsColored}
      indicator={
        <LinkButton
          variant="secondary"
          href={getDocsUrl("/integrations/chatops/msteams")}
          rel="noopener noreferrer"
          target="_blank"
        >
          Go to docs
        </LinkButton>
      }
      description="Microsoft Teams is a Slack alternative, many DevOps teams use it to communicate and
      collaborate on infrastructure and application deployments. Hence, Spacelift has a
      first-class integration with Microsoft Teams."
    />
  );
};

export default IntegrationsMSTeams;
