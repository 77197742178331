import { Spinner } from "components/Spinner";
import Box from "ds/components/Box";
import Callout from "ds/components/Callout";
import Link from "ds/components/Link";
import { Run, RunState } from "types/generated";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";

type VersionMetadataCalloutProps = {
  run: Run;
};

const VersionMetadataCallout = ({ run }: VersionMetadataCalloutProps) => {
  const isFailedState =
    run.state === RunState.Failed ||
    run.state === RunState.Discarded ||
    run.state === RunState.Canceled;

  if (run.state === RunState.Finished) return null;

  if (!isFailedState) {
    return (
      <Callout variant="info" title="Processing source" icon={Spinner}>
        <Box margin="0 0 medium 0">
          We’re currently in the process of loading all version metadata. Below, you can review the
          run that is working on this operation.
        </Box>
        <Box gap="small" align="center">
          <Link href={`../run/${run.id}`} size="small">
            {run.title}
          </Link>
          <RunStateBadge state={run.state} />
        </Box>
      </Callout>
    );
  }

  return (
    <Callout variant="danger" title="The source could not be processed">
      <Box margin="0 0 medium 0">Check the run's execution to find the issue.</Box>
      <Box gap="small" align="center">
        <Link href={`../run/${run.id}`} size="small">
          {run.title}
        </Link>
        <RunStateBadge state={run.state} />
      </Box>
    </Callout>
  );
};

export default VersionMetadataCallout;
