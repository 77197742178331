import { ReactNode } from "react";
import { isValid } from "date-fns/esm";

import Box from "ds/components/Box";
import Icon, { IconProps } from "ds/components/Icon";
import Typography from "ds/components/Typography";
import { Clock } from "components/icons/generated";

import Timestamp from ".";

type TimestampMetaInfoProps = {
  icon?: IconProps["src"];
  timestamp: number;
  prefix?: ReactNode;
  postfix?: ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const TimestampMetaInfo = ({
  timestamp,
  icon = Clock,
  prefix,
  postfix,
  ...props
}: TimestampMetaInfoProps) => {
  if (!isValid(timestamp)) {
    return null;
  }
  return (
    <Typography {...props} variant="p-body2" tag="p" color="secondary">
      <Box fullWidth gap="small" align="center">
        <Icon color="secondary" src={icon} />
        <Box align="center">
          {prefix}
          {prefix && <span>&nbsp;</span>}
          <Timestamp color="secondary" variant="p-body2" timestamp={timestamp} />
          {postfix && <span>&nbsp;</span>}
          {postfix}
        </Box>
      </Box>
    </Typography>
  );
};

export default TimestampMetaInfo;
