import Link from "ds/components/Link";
import { ariaCellInteractionProps } from "ds/components/Table/useKeyboardNavigation/ariaCellInteractionProps";

import useBlueprintsAnalytics from "../../hooksNew/useBlueprintsAnalytics";
import { CellComponentProps } from "./types";

type BlueprintsRowSpaceCellProps = CellComponentProps;

const BlueprintsRowSpaceCell = ({ blueprint }: BlueprintsRowSpaceCellProps) => {
  const trackAnalytics = useBlueprintsAnalytics();

  return (
    <Link
      size="small"
      href={`/spaces/${blueprint.space.id}`}
      onPress={() => trackAnalytics("Space link clicked")}
      {...ariaCellInteractionProps}
    >
      {blueprint.space.name}
    </Link>
  );
};

export default BlueprintsRowSpaceCell;
