import Box from "ds/components/Box";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { showDetachConfirmation } from "./DetachConfirmation";
import { CellComponentProps } from "./types";

type BlueprintIntegrationsListItemActionsDropdownCellProps = CellComponentProps;

const BlueprintIntegrationsListItemActionsDropdownCell = ({
  integration,
}: BlueprintIntegrationsListItemActionsDropdownCellProps) => {
  const refetchQueriesOnDelete = ["GetBlueprintIntegrations"];
  const handleDeleteClick = () => {
    showDetachConfirmation({
      name: integration.integrationName,
      id: integration.id,
      refetchQueriesOnDelete,
    });
  };
  return (
    <Box justify="end">
      <DropdownMenuEllipsis tooltip="Integration actions">
        <DropdownMenuItem onAction={handleDeleteClick} danger>
          Detach
        </DropdownMenuItem>
      </DropdownMenuEllipsis>
    </Box>
  );
};

export default BlueprintIntegrationsListItemActionsDropdownCell;
