import { InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldSpaceTooltipInfo from "components/FormFields/Space/TooltipInfo";
import FormFieldTags from "components/FormFields/Tags";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import useTypedContext from "hooks/useTypedContext";
import { Policy, PolicyType } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { isLegacySpace } from "utils/space";

import { ADD_POLICY_DRAWER_TEST_ID } from "./constants";
import { UPDATE_POLICY } from "./gql";
import { CreatePolicyFields } from "./types";

type PolicyEditDrawerProps = {
  onCloseDrawer?: () => void;
  takenPolicyNames: string[];
  refetchQueries?: InternalRefetchQueriesInclude;
  policy?: Policy;
  analyticsPage: AnalyticsPagePolicy;
};

const PolicyEditDrawer = createDrawer(
  ({
    onCloseDrawer,
    takenPolicyNames,
    refetchQueries,
    policy,
    analyticsPage,
  }: PolicyEditDrawerProps) => {
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const drawer = useModal();
    const [updatePolicy, { loading: updatePolicyLoading }] = useMutation<{
      policyUpdate: { name: string };
    }>(UPDATE_POLICY, {
      refetchQueries,
      // APOLLO CLIENT UPDATE
      onCompleted: (data) => {
        if (data) {
          reportSuccess({ message: "Policy successfully updated" });
          onCloseDrawer?.();
          drawer.hide();
        }
      },
      onError,
    });

    const trackSegmentAnalyticsEvent = useAnalytics({
      page: analyticsPage,
    });

    const updatePolicyForm = useForm<CreatePolicyFields>({
      defaultValues: {
        name: policy?.name,
        space: policy?.spaceDetails.id,
        labels: policy?.labels ? policy?.labels.map((value) => ({ value })) : [],
        description: policy?.description || "",
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isDirty },
      watch,
      setValue,
    } = updatePolicyForm;

    useEffect(() => {
      reset({
        name: policy?.name,
        space: policy?.spaceDetails.id,
        labels: policy?.labels ? policy?.labels.map((value) => ({ value })) : [],
        description: policy?.description || "",
      });
    }, [policy, reset]);

    const type = watch("type");
    const space = watch("space");

    // Allow access policy only for legacy space
    useEffect(() => {
      if (type === PolicyType.Access && !isLegacySpace(space)) {
        setValue("space", "legacy");
      }
    }, [type, space, setValue]);

    const cancelHandler = () => {
      trackSegmentAnalyticsEvent("Edit Canceled");
    };

    const saveChanges: SubmitHandler<CreatePolicyFields> = (formData) => {
      updatePolicy({
        variables: {
          id: policy?.id,
          name: formData.name.trim(),
          body: policy?.body,
          description: formData.description?.trim(),
          space: formData.space,
          labels: formData.labels?.map(({ value }) => value),
        },
      })
        .then(() => {
          trackSegmentAnalyticsEvent("Policy edited");
        })
        .catch(onError);
    };

    const validateName = (value: string) => {
      const name = value && value.trim();

      if (!name) {
        return "Name field is required.";
      }

      if (takenPolicyNames.includes(name.toLowerCase()) && name !== policy?.name) {
        return "Policy with this name already exists";
      }

      return true;
    };

    return (
      <DrawerForm isDirty={isDirty} onClose={onCloseDrawer} dataTestId={ADD_POLICY_DRAWER_TEST_ID}>
        <FormProvider {...updatePolicyForm}>
          <form onSubmit={handleSubmit(saveChanges)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Edit details" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight>
              <FormField label="Name" error={errors?.name?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Name of your policy"
                    error={!!errors?.name}
                    {...register("name", {
                      validate: validateName,
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldSpace
                tooltipAnalyticsPage={AnalyticsPagePolicy.PoliciesList}
                tooltipAnalyticsTitle="Tooltip click"
                tooltipAnalyticsProps={{ location: "Edit drawer", name: "Space" }}
                disabled={policy?.type === PolicyType.Access}
                tooltipInfo={
                  <FormFieldSpaceTooltipInfo>
                    Remember that you will only be able to attach policy to stacks and modules that
                    are in the same space.
                  </FormFieldSpaceTooltipInfo>
                }
              />

              {policy?.type === PolicyType.Access && (
                <Box margin="large 0 0 0" direction="column">
                  <Banner variant="info">
                    Access policy type is available only for legacy space.
                  </Banner>
                </Box>
              )}

              <FormField label="Description" isOptional error={errors?.description?.message}>
                {({ ariaInputProps }) => (
                  <Textarea
                    placeholder="Enter policy description here..."
                    error={!!errors?.description}
                    {...register("description")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldTags
                stopEnterPropagation
                name="labels"
                tagName="label"
                label="Labels"
                isOptional
              />

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton onClick={cancelHandler} />

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!policy?.id}
                    loading={updatePolicyLoading}
                  >
                    Save changes
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showPolicyEditDrawer = createDrawerTrigger(PolicyEditDrawer);
