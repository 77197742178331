import Timestamp from "components/time/Timestamp";
import Typography from "ds/components/Typography";

import { CellComponentProps } from "./types";

type BlueprintVersionsListItemUpdatedAtCellProps = CellComponentProps;

const BlueprintVersionsListItemUpdatedAtCell = ({
  version,
}: BlueprintVersionsListItemUpdatedAtCellProps) => {
  return (
    <Typography tag="span" variant="p-body3">
      <Timestamp timestamp={version.updatedAt} />
    </Typography>
  );
};

export default BlueprintVersionsListItemUpdatedAtCell;
