import { useState } from "react";
import { subMonths } from "date-fns/esm";

import { SavedFilterView } from "components/Filters/types";
import PageInfo from "components/PageWrapper/Info";
import Select from "ds/components/Select";
import Typography from "ds/components/Typography";
import { AspectType } from "types/generated";
import DatepickerRange from "ds/components/Datepicker/Range";

import { GroupByKey, PERIODS, UsageDateRange } from "../../Chart/constants";
import FiltersLayout from "../../FiltersLayout";
import UsageChart from "../../Chart";
import RunMinutesTotals from "../Totals";
import UsageLayoutHeader from "../../Layout/Header";
import { getColorMapper } from "../colorMapper";

// TODO: make sure with BE that this is the correct format or fetch from API
const mockGroupBySelectOptions = [
  { value: GroupByKey.StackSlug, label: "Stack" },
  { value: GroupByKey.RunType, label: "Run type" },
  { value: GroupByKey.RunState, label: "Run state" },
];

type CurrentDateValue = {
  start: Date;
  end: Date;
};

const initialPeriod = UsageDateRange.CurrentBillingPeriod;

const UsageRunMinutesAdvanced = () => {
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>();

  const [currentDateRange, setCurrentDateRange] = useState<CurrentDateValue>(
    PERIODS[initialPeriod].range
  );

  const [currentGroupBy, setCurrentGroupBy] = useState(GroupByKey.RunType);

  const colorMapper = getColorMapper(currentGroupBy);

  const handleGroupByChange = (value: GroupByKey) => {
    setCurrentGroupBy(value);
  };

  const handleDateRangeChange = (dateRange: CurrentDateValue) => {
    setCurrentDateRange(dateRange);
  };

  const timeFilter = { startTime: currentDateRange.start, endTime: currentDateRange.end };

  return (
    <>
      <UsageLayoutHeader title="Usage chart" />
      <PageInfo title="Run minutes chart">
        <DatepickerRange
          isButton
          buttonSize="medium"
          initialPeriod={initialPeriod}
          periods={PERIODS}
          onChange={handleDateRangeChange}
          minDate={subMonths(new Date(), 3)}
          maxDate={new Date()}
          endDate={currentDateRange.end}
          startDate={currentDateRange.start}
        />
        <Select
          value={currentGroupBy}
          options={mockGroupBySelectOptions}
          onChange={handleGroupByChange}
          renderValueNode={(option) => (
            <Typography variant="p-t7" tag="span">
              Grouped by: {option.label}
            </Typography>
          )}
        />
      </PageInfo>
      <FiltersLayout currentSavedView={currentSavedView} setCurrentSavedView={setCurrentSavedView}>
        <RunMinutesTotals timeFilter={timeFilter} />
        <UsageChart
          groupBy={currentGroupBy}
          timeFilter={timeFilter}
          aspectType={AspectType.RunMinutes}
          colorMapper={colorMapper}
        />
      </FiltersLayout>
    </>
  );
};

export default UsageRunMinutesAdvanced;
