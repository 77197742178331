import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";

import { ANALYTICS } from "../constants";
import { CellComponentProps } from "../types";
import { showDeleteConfirmation } from "../../Role/DeleteConfirmation";

type RolesListItemActionsDropdownCellProps = CellComponentProps;

const RolesListItemActionsDropdownCell = ({ role }: RolesListItemActionsDropdownCellProps) => {
  if (role.isSystem) {
    return null;
  }

  const roleUrl = `/settings/role/${role.id}`;

  // TODO: [AAC] update actions and access to them
  return (
    <DropdownMenuEllipsis tooltip="Role actions">
      <DropdownMenuItem {...ANALYTICS} analyticsTitle="Edit clicked" href={`${roleUrl}/edit/`}>
        Edit
      </DropdownMenuItem>
      <CopyFieldDropdownMenuItem
        {...ANALYTICS}
        analyticsTitle="Copy ID clicked"
        title="Copy ID"
        value={role.id}
      />

      <DropdownMenuItem
        {...ANALYTICS}
        danger
        analyticsTitle="Delete clicked"
        onAction={() =>
          showDeleteConfirmation({
            id: role.id,
            name: role.name,
            roleBindingsCount: role.roleBindingsCount,
          })
        }
      >
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default RolesListItemActionsDropdownCell;
