import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { FormProvider, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldViewText from "components/FormFields/ViewText";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import { getDrawerFormFix } from "utils/css";

import { UPDATE_PROVIDER_VERSION } from "../gql";
import TerraformProviderVersionStatusBadge from "../ProvidersVersionStatusBadge";
import {
  ProviderVersionEditDrawerProps,
  UpdateProviderProviderFormFields,
  UpdateProviderVersionGql,
} from "./types";

const ProviderVersionEditDrawer = createDrawer(
  ({ providerVersion }: ProviderVersionEditDrawerProps) => {
    const drawer = useModal();
    const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

    const builderForm = useForm<UpdateProviderProviderFormFields>({
      defaultValues: {
        description: providerVersion.description || "",
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { isDirty },
    } = builderForm;

    const [updateProviderVersion] = useMutation<UpdateProviderVersionGql>(UPDATE_PROVIDER_VERSION, {
      refetchQueries: ["GetProviderWithVersions"],
    });

    const onEditSubmit = (formData: UpdateProviderProviderFormFields) => {
      updateProviderVersion({
        variables: {
          description: formData.description,
          version: providerVersion.id,
        },
      })
        .then(({ data }) => {
          if (data?.terraformProviderVersionUpdate?.number) {
            reportSuccess({
              message: `Terraform provider version "${data.terraformProviderVersionUpdate.number}" was successfully updated`,
            });
          } else {
            reportError({
              message:
                "Something went wrong while saving Terraform provider version, please try again.",
            });
          }

          drawer.hide();
        })
        .catch(onError);
    };

    return (
      <DrawerForm isDirty={isDirty}>
        <FormProvider {...builderForm}>
          <form onSubmit={handleSubmit(onEditSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Edit version" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight>
              <FormFieldViewText label="Version" value={providerVersion.number} withCopy />

              <FormField label="Status">
                <Box>
                  <TerraformProviderVersionStatusBadge status={providerVersion.status} />
                </Box>
              </FormField>

              <FormField label="Description" isOptional>
                {({ ariaInputProps }) => (
                  <Textarea
                    placeholder="Enter description here..."
                    maxRows={15}
                    {...register("description")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showProviderVersionEditDrawer = createDrawerTrigger(ProviderVersionEditDrawer);
