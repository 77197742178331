import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FullScreenModal from "ds/components/FullScreenModal";
import useBlueprint from "shared/Blueprint/useBlueprint";
import { BlueprintState } from "types/generated";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import TemplatePreviewSkeleton from "views/Account/Blueprint/components/TemplateForm/Skeleton";

import CreateStackModalForm from "./Form";

const CreateStackModal = () => {
  const navigate = useNavigate();
  const { blueprintId } = useParams<{ blueprintId: string }>();

  const { data, error, loading: loadingBlueprint } = useBlueprint(blueprintId);
  const blueprint = data?.blueprint;
  const hasBlueprint = !!blueprint;
  const hasError = !!error;
  const isPublished = blueprint?.state === BlueprintState.Published;

  useEffect(() => {
    if (hasError) {
      navigate("..", { replace: true });
    }

    if (hasBlueprint && !isPublished) {
      navigate(`..`, { replace: true });
    }
  }, [hasBlueprint, hasError, isPublished, navigate]);

  return (
    <FullScreenModal title="Create a stack via blueprint" onClose={() => navigate("..")}>
      {hasBlueprint && !loadingBlueprint ? (
        <CreateStackModalForm blueprint={blueprint} />
      ) : (
        <FullScreenModalBody gap="large">
          <TemplatePreviewSkeleton />
        </FullScreenModalBody>
      )}
    </FullScreenModal>
  );
};

export default CreateStackModal;
