import { CSSProperties, ReactNode, forwardRef, memo } from "react";
import cx from "classnames";
import { Row } from "react-aria-components";

import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import TableRowCheckbox from "./RowCheckbox";
import { TableContext } from "./Context";

type TableRowProps = {
  children: ReactNode;
  id: string;
  name?: string;
  style?: CSSProperties;
  isSelectableDisabled?: boolean;
  index?: number;
  getSelectedIds?: (id: string) => string[];
};

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(function TableRow(
  { children, id, name, style, isSelectableDisabled, index, getSelectedIds },
  ref
) {
  const isActive = useDrawerVisibilityForId(id);

  const { selectable } = useTypedContext(TableContext);

  return (
    <Row
      ref={ref}
      style={style}
      key={id}
      id={id}
      className={cx(styles.row, { [styles.active]: isActive })}
      data-index={index}
    >
      {selectable && name && (
        <TableRowCheckbox
          getSelectionIds={getSelectedIds}
          isDisabled={isSelectableDisabled}
          name={name}
          id={id}
        />
      )}
      {children}
    </Row>
  );
});

export default memo(TableRow);
