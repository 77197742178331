import { gql, TypedDocumentNode } from "@apollo/client";

import { SearchSuggestionsOutput } from "types/generated";

type SearchManagedEntitiesSuggestionsData = {
  searchManagedEntitiesSuggestions: SearchSuggestionsOutput;
};

export const SEARCH_MANAGED_ENTITIES_SUGGESTIONS: TypedDocumentNode<SearchManagedEntitiesSuggestionsData> = gql`
  query SearchManagedEntitiesSuggestions($input: SearchSuggestionsInput!) {
    searchManagedEntitiesSuggestions(input: $input) {
      fields {
        filterable
        name
        orderable
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
        type
      }
      filteredCount
    }
  }
`;
