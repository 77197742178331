import { SpaceField } from "./types";

type ValidateSpaceParams = {
  value: string | undefined;
  index: number;
  spaceFields: SpaceField[];
  allowEmpty?: boolean;
};

type ValidateAccessLevelParams = {
  value: string | undefined;
  index: number;
  spaceFields: SpaceField[];
  allowEmpty?: boolean;
};

export const validateSpace = ({ value, index, spaceFields, allowEmpty }: ValidateSpaceParams) => {
  const hasRoleSelected = Boolean(spaceFields[index]?.spaceAccessLevel);
  const isSingleField = spaceFields.length === 1;
  const canBeEmpty = allowEmpty && isSingleField;

  // Rule 1: If role is selected, space is required
  if (hasRoleSelected && !value) {
    return "Space is required";
  }

  // Rule 2: Space is required (unless it can be empty)
  if (!value && !canBeEmpty) {
    return "Space is required";
  }

  // Rule 3: Space values must be unique
  if (value) {
    const spacesWithSameValue = spaceFields.filter((spaceField) => spaceField.space === value);
    const hasDuplicates = spacesWithSameValue.length > 1;

    if (hasDuplicates) {
      return "Space must be unique";
    }
  }

  return true;
};

export const validateAccessLevel = ({
  value,
  index,
  spaceFields,
  allowEmpty,
}: ValidateAccessLevelParams) => {
  const hasSpaceSelected = Boolean(spaceFields[index]?.space);
  const isSingleField = spaceFields.length === 1;
  const canBeEmpty = allowEmpty && isSingleField;

  // Rule 1: If space is selected, role is required
  if (hasSpaceSelected && !value) {
    return "Role is required";
  }

  // Rule 2: Role is required (unless it can be empty)
  if (!value && !canBeEmpty) {
    return "Role is required";
  }

  return true;
};
