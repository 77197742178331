import cx from "classnames";
import { Cell } from "react-aria-components";
import { useShallow } from "zustand/react/shallow";
import { memo, useCallback } from "react";

import Toggle from "ds/components/Toggle";

import useSelectionStore from "./useSelectionStore";
import styles from "./styles.module.css";

type TableRowCheckboxProps = {
  id: string;
  name: string;
  isDisabled?: boolean;
  getSelectionIds?: (id: string) => string[];
};

const TableRowCheckbox = ({ id, name, isDisabled, getSelectionIds }: TableRowCheckboxProps) => {
  const [selected, addSelectedIds, unselectIds, toggle, isSelectionOn] = useSelectionStore(
    useShallow(({ selected, addSelectedIds, toggle, unselectIds }) => [
      selected.has(id),
      addSelectedIds,
      unselectIds,
      toggle,
      selected.size > 0,
    ])
  );

  const onChange = useCallback(
    (isSelected: boolean) => {
      if (getSelectionIds) {
        const selectionIds = getSelectionIds(id);
        const action = isSelected ? addSelectedIds : unselectIds;
        action(selectionIds);
        return;
      }
      toggle(id);
    },
    [getSelectionIds, toggle, id, addSelectedIds, unselectIds]
  );

  return (
    <Cell
      className={cx(
        styles.tableCell,
        styles.checkboxCell,
        isSelectionOn && styles.checkboxCellSticky
      )}
    >
      <Toggle
        isDisabled={isDisabled}
        aria-label={`Select ${name}`}
        slot={null}
        variant="checkbox"
        isSelected={selected}
        onChange={onChange}
      />
    </Cell>
  );
};

export default memo(TableRowCheckbox);
