import {
  closestCorners,
  getFirstCollision,
  KeyboardCode,
  DroppableContainer,
  KeyboardCoordinateGetter,
} from "@dnd-kit/core";
import { subtract } from "@dnd-kit/utilities";

import { Column, Options } from "./types";
import { CONTAINERS } from "./useTwoColumnDND";

const directions: string[] = [
  KeyboardCode.Down,
  KeyboardCode.Right,
  KeyboardCode.Up,
  KeyboardCode.Left,
];

export const coordinateGetter: KeyboardCoordinateGetter = (
  event,
  { context: { active, droppableRects, droppableContainers, collisionRect, scrollableAncestors } }
) => {
  if (directions.includes(event.code)) {
    event.preventDefault();

    if (!active || !collisionRect) {
      return;
    }

    const currentCollisions = closestCorners({
      active,
      collisionRect: collisionRect,
      droppableRects,
      droppableContainers: droppableContainers.getEnabled(),
      pointerCoordinates: null,
    });

    const currentClosestId = getFirstCollision(currentCollisions, "id");

    const canMoveToOtherContainer = [KeyboardCode.Right, KeyboardCode.Left].includes(
      event.code as KeyboardCode
    );

    const filteredContainers: DroppableContainer[] = [];
    droppableContainers.getEnabled().forEach((entry) => {
      if (!entry || entry?.disabled || currentClosestId === entry.id) {
        return;
      }

      const rect = droppableRects.get(entry.id);

      if (!rect) {
        return;
      }

      const data = entry.data.current;

      if (data) {
        const { type, children } = data;

        if (type === "container" && children?.length > 0) {
          if (active.data.current?.type !== "container") {
            return;
          }
        }
      }

      switch (event.code) {
        case KeyboardCode.Down:
          if (collisionRect.top < rect.top) {
            filteredContainers.push(entry);
          }
          break;
        case KeyboardCode.Up:
          if (collisionRect.top > rect.top) {
            filteredContainers.push(entry);
          }
          break;
        case KeyboardCode.Left:
          if (collisionRect.left >= rect.left + rect.width) {
            filteredContainers.push(entry);
          }
          break;
        case KeyboardCode.Right:
          if (collisionRect.left + collisionRect.width <= rect.left) {
            filteredContainers.push(entry);
          }
          break;
      }
    });

    const collisions = closestCorners({
      active,
      collisionRect: collisionRect,
      droppableRects,
      droppableContainers: filteredContainers,
      pointerCoordinates: null,
    });

    const closestId = getFirstCollision(collisions, "id");

    if (closestId != null) {
      const activeDroppable = droppableContainers.get(active.id);
      const newDroppable = droppableContainers.get(closestId);
      const newRect = newDroppable ? droppableRects.get(newDroppable.id) : null;
      const newNode = newDroppable == null ? void 0 : newDroppable.node.current;

      if (newNode && newRect && activeDroppable && newDroppable) {
        const hasSameContainer =
          activeDroppable.data.current?.sortable.containerId ===
          newDroppable.data.current?.sortable.containerId;

        if (!canMoveToOtherContainer && !hasSameContainer) {
          return;
        }

        if (!hasSameContainer) {
          scrollableAncestors.forEach((node) => (node.scrollTop = 0));
        }

        const rectCoordinates = {
          x: newRect.left,
          y: newRect.top,
        };

        if (!hasSameContainer) {
          // It fixes position of item when we have empty container
          if (CONTAINERS.includes(newDroppable.id as string)) {
            return {
              x: newRect.left + 8,
              y: newRect.top + 16,
            };
          }
        }

        const isAfterActive =
          activeDroppable.data.current?.sortable.index < newDroppable.data.current?.sortable.index;
        const offset = !hasSameContainer
          ? {
              x: 0,
              y: 0,
            }
          : {
              x: isAfterActive ? collisionRect.width - newRect.width : 0,
              y: isAfterActive ? collisionRect.height - newRect.height : 0,
            };

        const newCoordinates =
          offset.x && offset.y ? rectCoordinates : subtract(rectCoordinates, offset);

        return newCoordinates;
      }
    }
  }

  return undefined;
};

export const sliceHiddenItems = <T extends string>(columnConfig: Column<T>, options: Options<T>) =>
  columnConfig.reduce(
    ({ visible, hidden }, item) => {
      const option = options[item.value];

      if (item.hidden || !option) {
        return {
          hidden: [...hidden, item],
          visible,
        };
      }

      return {
        hidden,
        visible: [...visible, item],
      };
    },
    { visible: [] as Column<T>, hidden: [] as Column<T> }
  );
