import { useState } from "react";

import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { SchedulingItem } from "shared/Stack/Scheduling/types";
import DrawerForm from "ds/components/DrawerNew/Form";

import StackManageScheduledTask from "../ManageScheduledTask";
import StackManageDriftDetection from "../ManageDriftDetection";
import StackManageScheduledDelete from "../ManageScheduledDelete";
import StackManageScheduledRun from "../ManageScheduledRun";

type StackSchedulingDrawerProps = {
  drawerEntityType: SCHEDULING_TYPE;
  focusedItem?: SchedulingItem;
  stackId: string;
};

const StackSchedulingDrawer = createDrawer(
  ({ drawerEntityType, focusedItem, stackId }: StackSchedulingDrawerProps) => {
    const trackSegmentEvent = useAnalytics({
      page: AnalyticsPageStack.StackScheduling,
    });

    const [isDirty, setIsDirty] = useState(false);

    const handleCancelAction = () => {
      if (focusedItem) {
        trackSegmentEvent("Schedule Edit Cancelled", { type: drawerEntityType });
      } else {
        trackSegmentEvent("Create Schedule Canceled", { type: drawerEntityType });
      }
    };

    return (
      <DrawerForm isDirty={isDirty} onClose={handleCancelAction}>
        {drawerEntityType === SCHEDULING_TYPE.DRIFT_DETECTION && (
          <StackManageDriftDetection
            setIsDirty={setIsDirty}
            integration={
              (focusedItem?.__typename === "DriftDetectionIntegration" && focusedItem) || undefined
            }
            stackId={stackId}
          />
        )}
        {drawerEntityType === SCHEDULING_TYPE.DELETE_STACK && (
          <StackManageScheduledDelete
            setIsDirty={setIsDirty}
            integration={
              (focusedItem?.__typename === "ScheduledDelete" && focusedItem) || undefined
            }
            stackId={stackId}
          />
        )}
        {drawerEntityType === SCHEDULING_TYPE.TASK && (
          <StackManageScheduledTask
            setIsDirty={setIsDirty}
            integration={(focusedItem?.__typename === "ScheduledTask" && focusedItem) || undefined}
            stackId={stackId}
          />
        )}
        {drawerEntityType === SCHEDULING_TYPE.RUN && (
          <StackManageScheduledRun
            setIsDirty={setIsDirty}
            integration={(focusedItem?.__typename === "ScheduledRun" && focusedItem) || undefined}
            stackId={stackId}
          />
        )}
      </DrawerForm>
    );
  }
);

export const showStackSchedulingDrawer = createDrawerTrigger(StackSchedulingDrawer);
