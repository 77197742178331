import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { useCallback, useMemo, useState } from "react";
import { isEqual } from "lodash-es";

import Box from "ds/components/Box";
import { reorderMultipleDNDLists } from "utils/dnd";

import CustomizeViewItems from "./Items";
import styles from "./styles.module.css";
import { CustomizeViewConfig } from "./types";

type CustomizeViewProps<T extends string> = {
  visibleSectionTitle: string;
  hiddenSectionTitle: string;
  config: CustomizeViewConfig<T>;
  minVisibleItemsCount?: number;
  alwaysVisibleItems?: T[];
  staticItems?: T[];
  getLabel: (item: T) => string;
  onChange: (config: CustomizeViewConfig<T>) => void;
};

const CustomizeView = <T extends string>({
  visibleSectionTitle,
  hiddenSectionTitle,
  alwaysVisibleItems,
  minVisibleItemsCount = 0,
  config,
  staticItems,
  getLabel,
  onChange,
}: CustomizeViewProps<T>) => {
  const [isHiddenDropDisabled, setIsHiddenDropDisabled] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);

  const alwaysVisibleIndexes = useMemo(
    () => alwaysVisibleItems?.map((item) => config.visible.indexOf(item)),
    [config, alwaysVisibleItems]
  );

  const handleDragStart = useCallback(
    (start: DragStart) => {
      const limitCondition =
        minVisibleItemsCount > 0 &&
        start.source.droppableId === "visible" &&
        config &&
        config.visible.length <= minVisibleItemsCount;

      const alwaysVisibleCondition =
        start.source.droppableId === "visible" &&
        alwaysVisibleIndexes?.includes(start.source.index);

      if (limitCondition || alwaysVisibleCondition) {
        setIsHiddenDropDisabled(true);
      }

      setIsDragActive(true);
    },
    [alwaysVisibleIndexes, minVisibleItemsCount, config]
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      setIsDragActive(false);

      if (isHiddenDropDisabled) {
        setIsHiddenDropDisabled(false);
      }

      if (!result.destination || !config) {
        return;
      }

      const newConfig = reorderMultipleDNDLists<T>(
        config,
        result.source.droppableId,
        result.destination.droppableId,
        result.source.index,
        result.destination.index
      );

      const oldStaticIndexes = staticItems?.map((item) => config.visible.indexOf(item));
      const newStaticIndexes = staticItems?.map((item) => newConfig.visible.indexOf(item));

      if (!isEqual(oldStaticIndexes, newStaticIndexes)) {
        return;
      }

      onChange({
        visible: newConfig.visible,
        hidden: newConfig.hidden,
      });
    },
    [isHiddenDropDisabled, onChange, staticItems, config]
  );

  return (
    <Box direction="column" grow="1" className={styles.wrapper} fullWidth>
      {config && (
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <Box direction="column" padding="large" gap="large" fullWidth>
            <CustomizeViewItems
              title={visibleSectionTitle}
              type="visible"
              items={config.visible}
              getItemLabel={getLabel}
              disabledItems={staticItems}
            />

            <CustomizeViewItems
              isDropDisabled={isHiddenDropDisabled}
              title={hiddenSectionTitle}
              type="hidden"
              items={config.hidden}
              disabledItems={staticItems}
              isDragActive={isDragActive}
              getItemLabel={getLabel}
            />
          </Box>
        </DragDropContext>
      )}
    </Box>
  );
};

export default CustomizeView;
