import {
  BulkActionActionConfirmationTabs,
  BulkActionResultTabs,
  BulkActionsStep,
} from "components/BulkActions/types";
import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

type BulkActionsDrawerItemLinkProps = {
  link: string;
  text: string;
  step: BulkActionsStep;
  itemCategory: BulkActionActionConfirmationTabs | BulkActionResultTabs | undefined;
};

const BulkActionsDrawerItemLink = ({
  link,
  text,
  step,
  itemCategory,
}: BulkActionsDrawerItemLinkProps) => {
  return (
    <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthXl">
      {(props) => (
        <Link
          href={link}
          target="_blank"
          variant="secondary"
          analyticsPage={AnalyticsPageStack.StacksList}
          analyticsTitle={"Bulk Actions - Drawer - Stack redirected"}
          analyticsProps={{
            bulkActionsStep: step,
            bulkActionsItemCategory: itemCategory,
          }}
        >
          <Typography {...props} tag="p" variant="p-t6">
            {text}
          </Typography>
        </Link>
      )}
    </TextEllipsis>
  );
};

export default BulkActionsDrawerItemLink;
