import { useCallback } from "react";

import { ChevronDown, LogoAws, LogoGcp, LogoMsAzure } from "components/icons/generated";
import Box from "ds/components/Box";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Icon from "ds/components/Icon";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";

import { showAwsIntegrationFormDrawer } from "../../components/Aws/FormDrawer";
import { showAzureIntegrationFormDrawer } from "../../components/Azure/FormDrawer";
import { showGcpDrawer } from "../../components/Gcp/Drawer";
import { useCloudIntegrationsListAnalytics } from "../useCloudIntegrationsListAnalytics";
const CloudIntegrationsSetupDropdownButton = () => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const trackAnalytics = useCloudIntegrationsListAnalytics();

  const createAwsIntegration = useCallback(() => {
    showAwsIntegrationFormDrawer({ trackAnalytics });
  }, [trackAnalytics]);

  const createAzureIntegration = useCallback(() => {
    showAzureIntegrationFormDrawer({ trackAnalytics });
  }, [trackAnalytics]);

  return (
    <DropdownMenu
      triggerComponent={
        <Tooltip
          active={!hasEntityCreateAccess}
          on={(props) => (
            <Button
              {...props}
              variant="primary"
              size="small"
              endIcon={ChevronDown}
              disabled={!hasEntityCreateAccess}
            >
              Set up integration
            </Button>
          )}
        >
          You don't have access to set up integrations
        </Tooltip>
      }
    >
      <DropdownMenuItem onAction={createAwsIntegration}>
        <Box align="center" gap="medium">
          <Icon src={LogoAws} />
          AWS
        </Box>
      </DropdownMenuItem>

      <DropdownMenuItem onAction={createAzureIntegration}>
        <Box align="center" gap="medium">
          <Icon src={LogoMsAzure} />
          Azure
        </Box>
      </DropdownMenuItem>

      <DropdownMenuItem onAction={showGcpDrawer}>
        <Box align="center" gap="medium">
          <Icon src={LogoGcp} />
          GCP
        </Box>
      </DropdownMenuItem>
    </DropdownMenu>
  );
};

export default CloudIntegrationsSetupDropdownButton;
