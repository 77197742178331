import { ReactNode, createContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { Space } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useURLParams from "hooks/useURLParams";

import { SpacesDrawerWriteMode } from "./types";
import { SpacesContext } from "../SpacesProvider";
import { CREATE_NEW_URL_PARAM, PARENT_ID_URL_PARAM } from "./constants";

type SpacesViewContextProps = {
  currentSpace?: Space;
  isDrawerVisible: boolean;
  drawerWriteMode: SpacesDrawerWriteMode;
  isRootAdmin: boolean;
  canNonRootAdminCreateSpace: boolean;
};

export const SpacesViewContext = createContext<SpacesViewContextProps | undefined>(undefined);
SpacesViewContext.displayName = "SpacesViewContext";

type SpacesViewContextProviderProps = {
  children: ReactNode;
};

export const SpacesViewContextProvider = ({ children }: SpacesViewContextProviderProps) => {
  // TODO: consider to move hasRootSpaceAccess and canNonRootAdminCreateSpace out of SpacesViewContextProvider
  const { spaces, hasRootSpaceAccess, canNonRootAdminCreateSpace } = useTypedContext(SpacesContext);

  const { spaceId } = useParams<{ spaceId?: string }>();

  const urlParams = useURLParams();
  const parentId = urlParams.get(PARENT_ID_URL_PARAM);
  const isCreateDrawer = urlParams.has(CREATE_NEW_URL_PARAM);

  const drawerWriteMode = isCreateDrawer
    ? SpacesDrawerWriteMode.Create
    : SpacesDrawerWriteMode.Edit;

  const currentSpace = useMemo(
    () => spaces.find((space) => space.id === spaceId),
    [spaceId, spaces]
  );

  const createFromParent = useMemo(
    () => spaces.find((space) => space.id === parentId),
    [parentId, spaces]
  );

  const isDrawerVisible = isCreateDrawer || !!currentSpace;

  return (
    <SpacesViewContext.Provider
      value={{
        currentSpace: createFromParent || currentSpace,
        isDrawerVisible,
        drawerWriteMode,
        isRootAdmin: hasRootSpaceAccess,
        canNonRootAdminCreateSpace,
      }}
    >
      {children}
    </SpacesViewContext.Provider>
  );
};
