import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { isProductionEnvironment } from "utils/env";
import { TableColumnMeta } from "ds/components/Table/types";

import makeIacTableMetaSlice from "./makeIacTableMetaSlice";
import makeIacTableDataSlice from "./makeIacTableDataSlice";
import { IacTableStoreState } from "./types";

type MakeIacTableStoreInitProps = {
  storageKey: string;
  initialColumns: Record<TableColumnMeta["id"], TableColumnMeta>;
};

const makeIacTableStore = ({ storageKey, initialColumns }: MakeIacTableStoreInitProps) =>
  create<IacTableStoreState>()(
    devtools(
      persist(
        immer((...a) => ({
          ...makeIacTableMetaSlice(initialColumns)(...a),
          ...makeIacTableDataSlice(...a),
        })),
        {
          name: storageKey,
          partialize: (state) => ({
            columnsSizes: state.columnsSizes,
            columnsOrder: state.columnsOrder,
          }),
        }
      ),
      { enabled: !isProductionEnvironment() }
    )
  );

export default makeIacTableStore;
