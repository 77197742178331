import { useModal } from "@ebay/nice-modal-react";
import { useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DragDropFileUpload from "ds/components/FileUpload/DragDropFileUpload";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import { WorkerPool } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { downloadFile } from "utils/file";
import { getDocsUrl } from "utils/getDocsUrl";

import { setFormDefaultValues } from "./helpers";
import { CreateWorkerPoolFields } from "./types";
import useCreateWorkerPool from "./useCreateWorkerPool";
import useEditWorkerPool from "./useEditWorkerPool";

type CreateWorkerPoolProps = {
  workerPool?: WorkerPool;
  refetchQuery?: string;
};

const CreateWorkerPool = createDrawer(({ workerPool, refetchQuery }: CreateWorkerPoolProps) => {
  const isEditMode = !!workerPool;

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const createWorkerPoolForm = useForm<CreateWorkerPoolFields>({
    defaultValues: useMemo(() => setFormDefaultValues(workerPool), [workerPool]),
    mode: "onChange",
  });

  const drawer = useModal();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = createWorkerPoolForm;

  const { onCreate } = useCreateWorkerPool();
  const { onEdit } = useEditWorkerPool(refetchQuery);

  const handleCloseDrawer = () => {
    drawer.hide();
  };

  const onSubmit: SubmitHandler<CreateWorkerPoolFields> = async (formData) => {
    try {
      if (isEditMode) {
        const { data } = await onEdit(formData, workerPool);

        if (data?.workerPoolUpdate) {
          reportSuccess({
            message: `Worker pool "${data.workerPoolUpdate.workerPool.name}" was successfully updated`,
          });

          handleCloseDrawer();
        }
      } else {
        const { data } = await onCreate(formData);

        if (data) {
          downloadFile(
            `worker-pool-${data.workerPoolCreate.workerPool.id}.config`,
            data.workerPoolCreate.config
          );
          reportSuccess({
            message: `Worker pool ${data.workerPoolCreate.workerPool.name} successfully created`,
          });

          handleCloseDrawer();
        }
      }
    } catch (error) {
      onError(error);
    }
  };

  return (
    <DrawerForm isDirty={isDirty}>
      <FormProvider {...createWorkerPoolForm}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle
              title={isEditMode ? "Edit worker pool" : "Create a new worker pool"}
            />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <FormField label="Name" error={errors?.name?.message}>
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter the name here"
                  error={!!errors?.name}
                  {...register("name", { required: "Name field is required." })}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            {!isEditMode && (
              <Controller
                name="certificateFile"
                control={control}
                rules={{ required: "Certificate field is required." }}
                render={({ field, fieldState }) => (
                  <FormField error={fieldState.error?.message} label="Certificate">
                    <DragDropFileUpload
                      ariaLabel="Upload certificate .csr file"
                      caption="Drag and drop the .csr file here"
                      file={field.value}
                      onChange={field.onChange}
                    />
                  </FormField>
                )}
              />
            )}

            <FormFieldSpace />

            <FormField label="Description" isOptional>
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Enter the description here"
                  {...register("description")}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />
            {!isEditMode && (
              <Box margin="large 0 0 0">
                <Banner variant="info">
                  Upon worker pool creation, a file will be generated with the worker pool
                  configuration, encoded using base64. Please save this file somewhere safe as it
                  will never be available again. You will need to make the configuration data
                  available to all workers joining this worker pool.
                  <br />
                  <Box margin="medium 0 0 0">
                    <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/worker-pools#setting-up")} />
                  </Box>
                </Banner>
              </Box>
            )}
            <DrawerFooter>
              <DrawerFooterActions>
                <Button variant="secondary" onPress={handleCloseDrawer} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" loading={isSubmitting}>
                  {isEditMode ? "Save" : "Create"}
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </form>
      </FormProvider>
    </DrawerForm>
  );
});

export const showWorkerPoolFormDrawer = createDrawerTrigger(CreateWorkerPool);
