import Box from "ds/components/Box";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { showVersionDeleteConfirmation } from "../../VersionDeleteConfirmation";
import { showBlueprintVersionDuplicateDrawer } from "../../VersionDuplicateDrawer";
import { showBlueprintVersionEditDetailsDrawer } from "../../VersionEditDetailsDrawer";
import { CellComponentProps } from "./types";
import { showBlueprintVersionDeprecateModal } from "../../VersionDeprecateConfirmation";

type BlueprintVersionsListItemActionsDropdownCellProps = CellComponentProps;

const BlueprintVersionsListItemActionsDropdownCell = ({
  version,
}: BlueprintVersionsListItemActionsDropdownCellProps) => {
  const handleEditDetails = () => {
    showBlueprintVersionEditDetailsDrawer({ version });
  };

  const handleDuplicate = () => {
    showBlueprintVersionDuplicateDrawer({ version });
  };

  const handleDeprecate = () => {
    showBlueprintVersionDeprecateModal({ version });
  };

  const handleDelete = () => {
    showVersionDeleteConfirmation({ version: version.version });
  };

  return (
    <Box justify="end">
      <DropdownMenuEllipsis tooltip="Version actions">
        <DropdownMenuItem onAction={handleEditDetails}>Edit details</DropdownMenuItem>
        <DropdownMenuItem onAction={handleDuplicate}>Duplicate</DropdownMenuItem>
        <DropdownMenuItem onAction={handleDeprecate} danger>
          Deprecate
        </DropdownMenuItem>
        <DropdownMenuItem onAction={handleDelete} danger>
          Delete
        </DropdownMenuItem>
      </DropdownMenuEllipsis>
    </Box>
  );
};

export default BlueprintVersionsListItemActionsDropdownCell;
