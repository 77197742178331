import { useCallback } from "react";

import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuEllipsis, {
  DropdownMenuEllipsisProps,
} from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import Icon from "ds/components/Icon";
import { External } from "components/icons/generated";
import Box from "ds/components/Box";
import { AzureIntegration } from "types/generated";

import { canDeleteCloudIntegration, hasCloudIntegrationManageAccess } from "../../../utils";
import { showAzureIntegrationFormDrawer } from "../FormDrawer";
import { showAzureIntegrationDeleteConfirmation } from "../DeleteConfirmation";
import { showAzureIntegrationDetailsDrawer } from "../DetailsDrawer";

type CloudIntegrationsAzureActionsDropdownProps = {
  integration: AzureIntegration;
  trackAnalytics: (event: string, props?: TrackAnalyticsEventProperties) => void;
  hideSeeDetails?: boolean;
  onDeleteSuccess?: () => void;
  dotsSize?: DropdownMenuEllipsisProps["dotsSize"];
  refetchQueriesOnEdit: string[];
  refetchQueriesOnDelete?: string[];
};

const CloudIntegrationsAzureActionsDropdown = ({
  integration,
  trackAnalytics,
  hideSeeDetails,
  onDeleteSuccess,
  dotsSize = "small",
  refetchQueriesOnEdit,
  refetchQueriesOnDelete,
}: CloudIntegrationsAzureActionsDropdownProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const handleEditIntegration = useCallback(async () => {
    showAzureIntegrationFormDrawer({
      id: integration.id,
      integration,
      trackAnalytics,
      refetchQueriesOnEdit,
    });
  }, [integration, trackAnalytics, refetchQueriesOnEdit]);

  const handleDeleteIntegration = useCallback(() => {
    showAzureIntegrationDeleteConfirmation({
      integration,
      trackAnalytics,
      onDeleteSuccess,
      refetchQueriesOnDelete,
    });
  }, [integration, onDeleteSuccess, refetchQueriesOnDelete, trackAnalytics]);

  const handleSeeDetails = useCallback(() => {
    showAzureIntegrationDetailsDrawer({ id: integration.id, integration, trackAnalytics });
  }, [integration, trackAnalytics]);

  const handleReConsent = useCallback(() => {
    window.location.href = integration.adminConsentURL;
  }, [integration]);

  const hasAccessToManageIntegration = hasCloudIntegrationManageAccess(viewer, integration);
  const canDeleteIntegration = canDeleteCloudIntegration(integration);

  return (
    <DropdownMenuEllipsis tooltip="Integration actions" dotsSize={dotsSize}>
      <CopyFieldDropdownMenuItem
        title="Copy ID"
        value={integration.id}
        onCopy={() => trackAnalytics("Copy ID clicked")}
      />
      {!hideSeeDetails && (
        <DropdownMenuItem onAction={handleSeeDetails}>See details</DropdownMenuItem>
      )}
      <DropdownMenuItem
        onAction={handleEditIntegration}
        tooltip={
          !hasAccessToManageIntegration
            ? "You don't have access to manage this integration"
            : undefined
        }
        isTooltipActive={!hasAccessToManageIntegration}
        isDisabled={!hasAccessToManageIntegration}
        tooltipPlacement="left"
        tooltipWidthMode="maxWidthSm"
      >
        Edit
      </DropdownMenuItem>
      {integration.adminConsentProvided && (
        <DropdownMenuItem
          onAction={handleReConsent}
          isDisabled={!hasAccessToManageIntegration}
          tooltip={
            !hasAccessToManageIntegration
              ? "You don't have access to manage this integration"
              : "Use this to grant admin consent again if you uninstalled the application from your directory by accident. "
          }
          tooltipPlacement="left"
          tooltipWidthMode="maxWidthSm"
        >
          <Box justify="between" align="center" fullWidth>
            Re-consent
            <Icon src={External} />
          </Box>
        </DropdownMenuItem>
      )}
      <DropdownMenuItem
        onAction={handleDeleteIntegration}
        danger
        isDisabled={!hasAccessToManageIntegration || !canDeleteIntegration}
        tooltip={
          !hasAccessToManageIntegration
            ? "You don't have access to manage this integration"
            : "Cannot delete this integration because it is attached to a stack"
        }
        isTooltipActive={!hasAccessToManageIntegration || !canDeleteIntegration}
        tooltipPlacement="left"
        tooltipWidthMode="maxWidthSm"
      >
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default CloudIntegrationsAzureActionsDropdown;
