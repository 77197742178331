import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import Box from "ds/components/Box";
import FormToggleField from "ds/components/Form/ToggleField";
import useTypedContext from "hooks/useTypedContext";

import styles from "../styles.module.css";
import {
  GET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS,
  SET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS,
} from "./gql";

const NonRootApiKeysToggle = () => {
  const { onError } = useTypedContext(FlashContext);
  const { data, loading: isLoading } = useQuery(GET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS, {});

  const [mutate, { loading: isSaving }] = useMutation(SET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS, {
    onError,
  });

  const toggle = useCallback(
    (isSelected: boolean) =>
      mutate({
        variables: { enable: isSelected },
        refetchQueries: [{ query: GET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS }],
        awaitRefetchQueries: true,
      }),
    [mutate]
  );

  return (
    <Box className={styles.managementToggle} fullWidth>
      <FormToggleField
        variant="switch"
        title="API keys management"
        description="Allow API keys management to Space level admins."
        checked={data?.apiKeysManagedByNonRootAdmins || false}
        onChange={toggle}
        disabled={isLoading || isSaving}
      />
    </Box>
  );
};

export default NonRootApiKeysToggle;
