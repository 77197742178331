import { memo } from "react";

import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { ariaCellInteractionProps } from "ds/components/Table/useKeyboardNavigation/ariaCellInteractionProps";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import { type RenderResourceRowCellProps } from ".";
import useIacManagementAnalytics from "../../useIacManagementAnalytics";

type IacManagementTableResourceRowCellNameProps = {
  handleResourceClick: () => void;
} & Pick<RenderResourceRowCellProps, "row">;

const IacManagementTableResourceRowCellName = memo(
  ({ row, handleResourceClick }: IacManagementTableResourceRowCellNameProps) => {
    const { analyticPage } = useIacManagementAnalytics();
    return (
      <IacManagementTableCell role="rowheader" padding="x-large x-large x-large 0" stickyLeft>
        <TextEllipsis tooltip={row.name}>
          {(props) => (
            <Link
              size="small"
              variant="secondary"
              onPress={handleResourceClick}
              {...ariaCellInteractionProps}
              analyticsPage={analyticPage}
              analyticsTitle="Resource Clicked"
              analyticsProps={{ driftDetected: !!row.drifted }}
            >
              <Typography {...props} variant="p-body3" tag="span">
                {row.name}
              </Typography>
            </Link>
          )}
        </TextEllipsis>
      </IacManagementTableCell>
    );
  }
);

IacManagementTableResourceRowCellName.displayName = "IacManagementTableResourceRowCellName";

export default IacManagementTableResourceRowCellName;
