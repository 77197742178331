import { SearchQueryOrder } from "types/generated";

import { IacManagementGroupBy } from "../types";
import {
  SORT_OPTIONS_PROVIDER_GROUP,
  SORT_OPTIONS_RESOURCE_TYPE_GROUP,
  SORT_OPTIONS_STACK_GROUP,
} from "../ListLayout/constants";

type UseIacManagementGroupSortOption = {
  groupBy: IacManagementGroupBy;
  actualSortOption: SearchQueryOrder[];
};

const filterKeysPerGroupBy = {
  [IacManagementGroupBy.Stack]: SORT_OPTIONS_STACK_GROUP,
  [IacManagementGroupBy.Provider]: SORT_OPTIONS_PROVIDER_GROUP,
  [IacManagementGroupBy.Type]: SORT_OPTIONS_RESOURCE_TYPE_GROUP,
};

export const makeGroupSortOption = ({
  actualSortOption,
  groupBy,
}: UseIacManagementGroupSortOption): SearchQueryOrder[] | null => {
  const field = filterKeysPerGroupBy[groupBy][actualSortOption[0].field];
  if (field) {
    return [
      {
        field,
        direction: actualSortOption[0].direction,
      },
    ];
  }

  return null;
};
