import { useState, FunctionComponent } from "react";
import { useModal } from "@ebay/nice-modal-react";

import { AwsIntegration, AzureIntegration } from "types/generated";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";
import WarningContextProvider from "components/WarningContext/Provider";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import AttachCloudForm from "components/AttachCloud/Form";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useAnalytics from "hooks/useAnalytics";
import { AttachCloudEntityType } from "components/AttachCloud/Form/types";

import { REFETCH_STACK_AND_STACK_SETTINGS_QUERIES } from "../../constants";

type StackSettingsIntegrationsCloudDrawerProps = {
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: CLOUD_INTEGRATIONS;
  }>;
  filteredAzureIntegrations?: AzureIntegration[];
  filteredAwsIntegrations?: AwsIntegration[];
  hasData: boolean;
  refetch: () => void;
  refetching: boolean;
  stackId: string;
  refetchQueries?: string[];
  canAttachGcpIntegration?: boolean;
};

const StackSettingsIntegrationsCloudDrawer = createDrawer(
  ({
    filteredAwsIntegrations,
    filteredAzureIntegrations,
    stackId,
    hasData,
    refetch,
    refetching,
    canAttachGcpIntegration,
    cloudConfig,
  }: StackSettingsIntegrationsCloudDrawerProps) => {
    const drawer = useModal();
    const [cloud, setCloud] = useState<CLOUD_INTEGRATIONS>(cloudConfig[0].type);

    const trackSegmentEvent = useAnalytics({
      page: AnalyticsPageStack.StackSettingsIntegrations,
    });

    const onSuccess = () => {
      trackSegmentEvent("Integrated", { type: cloud });
      drawer.hide();
    };

    return (
      <WarningContextProvider>
        <DrawerSimple variant="wide">
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Attach cloud integration" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight hasStickyFooter>
            <AttachCloudForm
              entityId={stackId}
              entityType={AttachCloudEntityType.Stack}
              hasData={hasData}
              refetch={refetch}
              refetching={refetching}
              setCloud={setCloud}
              cloud={cloud}
              cloudConfig={cloudConfig}
              attachableAzureIntegrations={filteredAzureIntegrations}
              attachableAwsIntegrations={filteredAwsIntegrations}
              canAttachGcpIntegration={canAttachGcpIntegration}
              refetchQueries={REFETCH_STACK_AND_STACK_SETTINGS_QUERIES}
              onSuccess={onSuccess}
            />
            <DrawerFooter sticky>
              <DrawerFooterActions>
                <DrawerCancelButton />
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </DrawerSimple>
      </WarningContextProvider>
    );
  }
);

export const showStackSettingsIntegrationsCloudDrawer = createDrawerTrigger(
  StackSettingsIntegrationsCloudDrawer
);
