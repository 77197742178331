import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import { isSelfHostedDistribution } from "utils/distribution";
import useTypedFlags from "hooks/useTypedFlags";

import styles from "./styles.module.css";
import DashboardOverviewColumnOnboardingSaaS from "./OnboardingSaaS";
import DashboardOverviewColumnTestPilot from "./TestPilot";
import DashboardOverviewColumnLeaveFeedback from "./LeaveFeedback";
import DashboardOverviewColumnUserActivity from "./UserActivity";
// import DashboardOverviewColumnAnnouncement from "./Announcement";
import { DashboardContext } from "../context";
import { OverviewStaticWidget } from "../types";
import DashboardOverviewColumnOnboardingSelfHosted from "./OnboardingSelfHosted";
import DashboardOverviewColumnAnnouncement from "./Announcement";
import { getBannerProps, shouldRenderBanner } from "./Announcement/helpers";

const isSelfHosted = isSelfHostedDistribution();

const DashboardOverviewColumn = () => {
  const { feMarketingBanners } = useTypedFlags();

  const { config } = useTypedContext(DashboardContext);
  const tab = config["overview"];
  const widgets = tab.overviewColumn
    ? tab.overviewColumn.reduce(
        (acc, next) => (!next.hidden ? [...acc, next.value] : acc),
        [] as OverviewStaticWidget[]
      )
    : [];

  return (
    <Box
      className={styles.overviewColumn}
      gap="x-large"
      direction="column"
      padding="x-large x-large x-large 0"
    >
      {feMarketingBanners.map((banner) => {
        if (!shouldRenderBanner(banner.expirationDate)) return null;
        const bannerProps = getBannerProps(banner.id);
        if (!bannerProps) return null;

        return <DashboardOverviewColumnAnnouncement key={banner.id} {...bannerProps} />;
      })}
      {widgets.includes(OverviewStaticWidget.LaunchPad) &&
        (isSelfHosted ? (
          <DashboardOverviewColumnOnboardingSelfHosted />
        ) : (
          <DashboardOverviewColumnOnboardingSaaS />
        ))}
      {widgets.includes(OverviewStaticWidget.UserActivity) && (
        <DashboardOverviewColumnUserActivity />
      )}
      <DashboardOverviewColumnTestPilot />
      <DashboardOverviewColumnLeaveFeedback />
    </Box>
  );
};

export default DashboardOverviewColumn;
