import Box from "ds/components/Box";
import ListEntitiesItemDescriptionMultipleLines from "components/ListEntitiesItem/Description/MultipleLines";

import { CellComponentProps } from "../types";
import useRolesListAnalytics from "../useRolesListAnalytics";

type RolesListItemDescriptionCellProps = CellComponentProps;

const RolesListItemDescriptionCell = ({ permission }: RolesListItemDescriptionCellProps) => {
  const trackAnalytics = useRolesListAnalytics();

  const handleShowFullDescription = () => {
    trackAnalytics("Show full description clicked");
  };

  if (!permission.description) {
    return null;
  }

  return (
    <Box direction="row" align="start">
      <ListEntitiesItemDescriptionMultipleLines
        lines={1}
        hasChevronLink
        description={permission.description}
        onShowFullDescription={handleShowFullDescription}
      />
    </Box>
  );
};

export default RolesListItemDescriptionCell;
