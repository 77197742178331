import { useMutation } from "@apollo/client";
import { useEffect, useMemo, useRef, useState } from "react";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import FlashContext from "components/FlashMessages/FlashContext";
import useAnalytics from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import { hasAtLeastModuleWriteAccess } from "shared/Module/utils";
import { hasAtLeastStackWriteAccess } from "shared/Stack/utils";
import { Module, RunSummary, Stack, SummaryProcessingStatus } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import { RunContext } from "views/shared/run/Context";

import { GET_PHASE_SUMMARIZATION, GET_RUN_SUMMARIZATION, TRIGGER_RUN_SUMMARIZATION } from "./gql";

const isStack = (stack: Stack | Module): stack is Stack => (stack as Stack).__typename === "Stack";

export const useRunSummary = () => {
  const { onError } = useTypedContext(FlashContext);
  const { run, stack: stackOrModule } = useTypedContext(RunContext);
  const { acceptedTermsAndConditionsAI } = useTypedContext(AccountContext);
  const summaryContainerRef = useRef<HTMLDivElement>(null);
  const trackSegmentAnalyticsEvent = useAnalytics();
  const [shouldPoll, setShouldPoll] = useState(false);

  const [trigger, { data, error, loading }] = useMutation<{
    summarizeRun: RunSummary;
  }>(TRIGGER_RUN_SUMMARIZATION, {
    variables: { stack: stackOrModule.id, run: run.id },
    onError,
  });

  const { data: pollData, client } = usePolledQuery<{
    stack: Stack;
  }>(GET_RUN_SUMMARIZATION, {
    variables: { stack: stackOrModule.id, run: run.id },
    onError,
    shouldPoll: () => shouldPoll,
    pollingGroup: PollingIntervalGroups.AiSummarization,
    onCompleted: () => {
      client.query({
        query: GET_PHASE_SUMMARIZATION,
        variables: { stack: stackOrModule.id, run: run.id },
      });
    },
  });

  const { summary, date, summarizable, status } = useMemo(() => {
    return {
      summary: run.summary?.summary ?? data?.summarizeRun.summary,
      date: run.summary?.createdAt ?? data?.summarizeRun.createdAt,
      summarizable: run.summarizable,
      status:
        pollData?.stack?.run?.summary?.processingStatus ??
        run.summary?.processingStatus ??
        data?.summarizeRun.processingStatus,
    };
  }, [run, data?.summarizeRun, pollData]);

  useEffect(() => {
    const el = summaryContainerRef.current;
    if (!el) return;

    const handleCopy = () => {
      if (!document.getSelection()?.toString()) {
        return;
      }

      trackSegmentAnalyticsEvent("Run Logs - Suggestion Code Snippets Copied", {
        runId: run.id,
        runState: run.state,
        stackId: stackOrModule.id,
      });
    };

    el.addEventListener("copy", handleCopy);

    return () => {
      el.removeEventListener("copy", handleCopy);
    };
  }, [run.id, run.state, stackOrModule, trackSegmentAnalyticsEvent]);

  useEffect(() => {
    if (status === SummaryProcessingStatus.Failed) {
      onError("Failed to summarize run");
    }
    if (status !== SummaryProcessingStatus.InProgress) {
      setShouldPoll(false);
    }
  }, [status, onError]);

  const triggerSummary = () => {
    trackSegmentAnalyticsEvent("Run Logs - AI Run Suggestions Clicked", {
      stackId: stackOrModule.id,
      runId: run.id,
      runState: run.state,
    });
    trigger().then(() => {
      setShouldPoll(true);
    });
  };

  const canTriggerSummary = isStack(stackOrModule)
    ? hasAtLeastStackWriteAccess(stackOrModule)
    : hasAtLeastModuleWriteAccess(stackOrModule);
  const loadingStatus =
    loading ||
    status === SummaryProcessingStatus.InProgress ||
    (status === SummaryProcessingStatus.Success && !summary);
  const showSummary = !!summary || loadingStatus;
  const showTrigger = summarizable && !showSummary;

  return {
    acceptedTermsAndConditionsAI,
    canTriggerSummary,
    date,
    error,
    loading: loadingStatus,
    showSummary,
    showTrigger,
    summary,
    summaryContainerRef,
    trigger: triggerSummary,
  };
};
