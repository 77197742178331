import { useCallback, useEffect, useRef } from "react";

type UseWidthManagerOptions = {
  initialWidth: number;
  minWidth?: number;
  maxWidth?: number;
  onWidthChange?: (newWidth: number) => void;
};

const useDefaultWidthManager = ({
  initialWidth,
  minWidth = 200,
  maxWidth = 600,
  onWidthChange,
}: UseWidthManagerOptions) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const widthRef = useRef<number>(initialWidth);
  const isCollapsedRef = useRef(false);

  const setWidth = useCallback(
    (width: number) => {
      if (inputRef.current) {
        inputRef.current.setAttribute("aria-valuetext", `${width} pixels`);
        inputRef.current.setAttribute("value", `${width}`);
      }
      widthRef.current = width;

      onWidthChange?.(width);
    },
    [onWidthChange]
  );

  useEffect(() => {
    setWidth(initialWidth);
  }, [initialWidth, setWidth]);

  return {
    setWidth,
    resizeBarProps: {
      inputRef,
      widthRef,
      isCollapsedRef,
      minWidth,
      maxWidth,
      onWidthChange: setWidth,
    },
  };
};

export default useDefaultWidthManager;
