import { useLayoutEffect, useRef } from "react";

import Tag, { TagProps } from "ds/components/Tag";

type TagsListFilterableNewSingleTagProps = {
  setTagSizes: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  containerSize?: number;
  currentSize?: number;
  id: string;
} & TagProps;

const TagValue = ({
  setTagSizes,
  containerSize,
  currentSize,
  id,
  ...rest
}: TagsListFilterableNewSingleTagProps) => {
  const tagRef = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    if (tagRef.current) {
      const newSize = tagRef.current?.clientWidth || 0;
      if (newSize !== currentSize) {
        setTagSizes((tags) => ({ ...tags, [id]: newSize }));
      }
    }
    // Update when container size changed
  }, [id, setTagSizes, containerSize, currentSize]);

  return <Tag ref={tagRef} {...rest} />;
};

export default TagValue;
