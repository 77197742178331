import { useEffect, useRef } from "react";

import useWindowVisibility from "hooks/useWindowVisibility";

import { DEFAULT_POLLING_INTERVAL_MS } from "../constants";

/**
 * Manages polling based on window visibility. Once apollo supports it out of the box, we might refactor this to use that instead -> https://github.com/apollographql/apollo-feature-requests/issues/422.
 */
const usePollingManagement = (
  pollInterval: number,
  startPolling: (interval: number) => void,
  stopPolling: () => void,
  shouldPoll: boolean,
  refetch: () => void
) => {
  const windowVisible = useWindowVisibility();
  const windowHiddenTimestamp = useRef<number | null>(null);
  const finalShouldPoll = shouldPoll && windowVisible;
  useEffect(() => {
    /** Refetch only when tab is reopened and was closed for a while  */
    const shouldRefetch =
      !!windowHiddenTimestamp.current &&
      Date.now() - windowHiddenTimestamp.current > DEFAULT_POLLING_INTERVAL_MS;

    if (windowVisible && shouldRefetch) {
      refetch();
    }
  }, [refetch, windowVisible]);

  useEffect(() => {
    if (windowVisible) {
      windowHiddenTimestamp.current = null;
    } else {
      windowHiddenTimestamp.current = Date.now();
    }
  }, [windowVisible]);

  useEffect(() => {
    if (finalShouldPoll) {
      startPolling(pollInterval);
    } else {
      stopPolling();
    }
  }, [pollInterval, finalShouldPoll, startPolling, stopPolling]);
};

export default usePollingManagement;
