import { ReactNode } from "react";

import styles from "./styles.module.css";
import useTableKeyboardNavigation from "./useKeyboardNavigation/useTableKeyboardNavigation";

type TableProps = {
  children: ReactNode;
  ariaLabel: string;
};

const Table = ({ children, ariaLabel }: TableProps) => {
  const props = useTableKeyboardNavigation();

  return (
    <div aria-label={ariaLabel} className={styles.table} role="grid" {...props}>
      {children}
    </div>
  );
};

Table.displayName = "DS.Table";

export default Table;
