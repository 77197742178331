import { ReactNode, useCallback, useMemo } from "react";
import { cloneDeep } from "lodash-es";
import { useNavigate } from "react-router-dom";

import useUIConfig from "hooks/useUIConfig";
import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import NotFoundPage from "components/error/NotFoundPage";
import useTypedFlags from "hooks/useTypedFlags";

import { DashboardColumns, DashboardConfig, DashboardOverviewStaticColumn } from "./types";
import { DashboardContext } from "./context";
import { getInitialConfig } from "./helpers";
import { VIEWS_LIMIT, DASHBOARD_STORAGE_KEY, DefaultDashboardTabs } from "./constants";
import useCurrentTab from "./useCurrentTab";
import { validateConfig } from "./validateConfig";
import { showDashboardManageDrawer } from "./ManageDrawer";

type DashboardContextProviderProps = {
  children: ReactNode;
};

const DashboardContextProvider = ({ children }: DashboardContextProviderProps) => {
  const { numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend } =
    useTypedFlags();

  const initialConfig = getInitialConfig({
    numberOfDeploymentsWidgetFrontend,
    approvalPolicyEvaluationsWidgetFrontend,
  });

  const { config, updateConfig, configLoading, noData } = useUIConfig<DashboardConfig>(
    DASHBOARD_STORAGE_KEY,
    initialConfig,
    validateConfig({ numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend })
  );

  console.log(config);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageDashboard.Dashboard,
  });

  const tabs = useMemo(
    () =>
      Object.entries(config).map(([id, value]) => ({
        id,
        title: value.title,
      })),
    [config]
  );

  const trackStateChange = useCallback(
    (tabName: string, config: DashboardConfig) => {
      trackSegmentAnalyticsEvent("Dashboard - View changed", {
        tabName,
        ...config[tabName],
      });
    },
    [trackSegmentAnalyticsEvent]
  );

  const updateTabColumnsConfig = useCallback(
    (tab: string, columns: DashboardColumns) => {
      const newConfig = {
        ...config,
        [tab]: {
          ...config[tab],
          ...columns,
        },
      };

      updateConfig(newConfig);
      trackStateChange(tab, newConfig);
    },
    [trackStateChange, updateConfig, config]
  );

  const updateTabOverviewColumnConfig = useCallback(
    (column: DashboardOverviewStaticColumn) => {
      const newConfig = {
        ...config,
        overview: {
          ...config["overview"],
          overviewColumn: column,
        },
      };

      updateConfig(newConfig);
      trackStateChange("overview", newConfig);
    },
    [updateConfig, config, trackStateChange]
  );

  const navigate = useNavigate();
  const currentTab = useCurrentTab();
  const goToFirstTab = useCallback(
    () => navigate(`/dashboard?tab=${DefaultDashboardTabs.Overview}`),
    [navigate]
  );

  const removeTab = useCallback(
    (id: string) => {
      const newConfig = cloneDeep(config);
      if (newConfig[id]) {
        delete newConfig[id];
        updateConfig(newConfig);
        trackStateChange(id, newConfig);

        if (id === currentTab) {
          goToFirstTab();
        }
      }
    },
    [config, goToFirstTab, currentTab, updateConfig, trackStateChange]
  );

  const addNewTab = useCallback(() => {
    trackSegmentAnalyticsEvent("Dashboard - Create New View", {
      method: "dashboard header tab click",
    });
    showDashboardManageDrawer({ initializeNewTab: true });
  }, [trackSegmentAnalyticsEvent]);

  const openManageDrawer = useCallback(
    (tab?: string, analyticsProps?: AnalyticsCommonProps["analyticsProps"]) => {
      trackSegmentAnalyticsEvent("Dashboard - Manage view Clicked", analyticsProps);

      showDashboardManageDrawer({
        tab,
      });
    },
    [trackSegmentAnalyticsEvent]
  );

  if (noData) {
    return <NotFoundPage />;
  }

  return (
    <DashboardContext.Provider
      value={{
        updateTabOverviewColumnConfig,
        updateTabColumnsConfig,
        config,
        openManageDrawer,
        tabs,
        addNewTab,
        removeTab,
        canAddMoreViews: Object.keys(config).length < VIEWS_LIMIT,
        configLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
