import { useForm } from "react-hook-form";
import { useEffect } from "react";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired, validateRequiredURL } from "utils/formValidators";
import useTypedContext from "hooks/useTypedContext";
import { VENDOR_DICTIONARY } from "constants/vendor";
import useBlockNavigationModal from "hooks/useBlockNavigationModal";

import { StackSettingsContextData } from "../../Context";
import { getFormDefaultValues, mapCreateStackVendorUpdateInput } from "./helpers";
import StackSettingsFormFooter from "../../components/FormFooter";
import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";
import { getLoginURLTooltip, getStackNameTooltip } from "./getTooltips";

export type StackSettingsPulumiVendorFormFields = {
  stackName: string;
  loginURL: string;
};

type StackSettingsVendorPulumiEditProps = StackSettingsVendorComponentBaseProps;

const StackSettingsVendorPulumiEdit = ({
  onStackUpdate,
  updateLoading,
}: StackSettingsVendorPulumiEditProps) => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const pulumiVendorForm = useForm<StackSettingsPulumiVendorFormFields>({
    defaultValues: getFormDefaultValues(stackSettings),
    mode: "onChange",
  });

  const {
    register,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
  } = pulumiVendorForm;

  useBlockNavigationModal({ isNavigationBlocked: isDirty });

  const onSubmit = (formData: StackSettingsPulumiVendorFormFields) => {
    onStackUpdate(mapCreateStackVendorUpdateInput(formData));
  };

  useEffect(() => {
    reset(getFormDefaultValues(stackSettings));
  }, [reset, stackSettings]);

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.PULUMI} />
      <FormField
        label="Login URL"
        error={errors?.loginURL?.message}
        {...getLoginURLTooltip()}
        noMargin
      >
        {({ ariaInputProps }) => (
          <Input
            placeholder="Type in login URL"
            {...register("loginURL", {
              validate: validateRequiredURL(),
              setValueAs: (value: string) => value.trim(),
            })}
            error={!!errors?.loginURL?.message}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <FormField
        label="Stack name"
        error={errors?.stackName?.message}
        {...getStackNameTooltip()}
        noMargin
      >
        {({ ariaInputProps }) => (
          <Input
            placeholder="Name stack in the Pulumi state"
            {...register("stackName", {
              validate: stringIsRequired(),
              setValueAs: (value: string) => value.trim(),
            })}
            error={!!errors?.stackName?.message}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <StackSettingsFormFooter
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset(getFormDefaultValues(stackSettings))}
        isDirty={isDirty}
        isSubmitDisabled={updateLoading}
        submitLoading={updateLoading}
      />
    </>
  );
};

export default StackSettingsVendorPulumiEdit;
