import { Cross } from "components/icons/generated";

import ButtonIcon from "../ButtonIcon";

type DrawerCloseIconProps = {
  handleCloseDrawer: () => void;
};

const DrawerCloseIcon = ({ handleCloseDrawer }: DrawerCloseIconProps) => (
  <ButtonIcon icon={Cross} onPress={handleCloseDrawer} variant="ghost">
    Close
  </ButtonIcon>
);

DrawerCloseIcon.displayName = "DS.Drawer.CloseIcon";

export default DrawerCloseIcon;
