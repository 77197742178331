import React from "react";

import PageInfo from "components/PageWrapper/Info";
import SearchInput from "components/SearchInput";
import LinkButton from "ds/components/Button/LinkButton";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";
import TerraformRegistryViewHeader from "views/Account/TerraformRegistry/ViewHeader";
import { AccountContext } from "views/AccountWrapper";

import { FILTERS_ORDER_SETTINGS_KEY } from "../../constants";

type ModulesPageLayoutProps = {
  children: React.ReactNode;
};

const ModulesPageLayout = ({ children }: ModulesPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Modules · ${accountName}`);

  return (
    <>
      <TerraformRegistryViewHeader />
      <PageInfo title="Modules">
        <SearchInput
          placeholder="Search by name, ID, etc..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        />
        {hasEntityCreateAccess && (
          <LinkButton to="/new/module" variant="primary">
            Create module
          </LinkButton>
        )}
      </PageInfo>

      {children}
    </>
  );
};

export default ModulesPageLayout;
