import { useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";

export type BlueprintVersionFormFields = {
  version?: string; // TODO: When versionDescription is removed, this should be required
  versionDescription?: string; // TODO: When versionDescription is removed, this should be required
};

const VersionFormFragment = () => {
  const { register, formState } = useFormContext<BlueprintVersionFormFields>();
  const errors = formState?.errors;

  return (
    <>
      <FormField label="Unique version identifier" error={errors?.version?.message}>
        {({ ariaInputProps }) => (
          <Input
            error={!!errors?.version}
            {...register("version", {
              required: "Unique version identifier is required.",
            })}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <FormField label="Version description" isOptional>
        {({ ariaInputProps }) => (
          <Textarea
            placeholder="Describe your version here..."
            {...register("versionDescription")}
            {...ariaInputProps}
          />
        )}
      </FormField>
    </>
  );
};

export default VersionFormFragment;
