import { useContext } from "react";
import { OverlayTriggerStateContext } from "react-aria-components";

import { Cross } from "components/icons/generated";

import ButtonIcon from "../ButtonIcon";

const DrawerCloseIcon = () => {
  const state = useContext(OverlayTriggerStateContext)!;
  return (
    <ButtonIcon icon={Cross} onPress={() => state.close()} variant="ghost">
      Close
    </ButtonIcon>
  );
};

DrawerCloseIcon.displayName = "DS.DrawerNew.CloseIcon";

export default DrawerCloseIcon;
