import debounce from "lodash-es/debounce";
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import FormFieldHidden from "ds/components/Form/Field/Hidden";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Input from "ds/components/Input";
import SecretInput from "ds/components/SecretInput";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import { GithubEnterpriseIntegration, VcsProvider } from "types/generated";
import { stringIsRequired, validateRequiredURL } from "utils/formValidators";
import VCSIntegrationTypeFieldControl from "views/Account/VCS/components/IntegrationTypeFieldControl";
import VCSWebhookEndpointField from "views/Account/VCS/components/WebhookEndpointField";
import { VCS_INTEGRATION_TYPES } from "views/Account/VCS/constants";
import { AccountContext } from "views/AccountWrapper";

import VCSIntegrationsDocumentationButton from "../../components/DocumentationButton";
import VCSChecksToggle from "../../components/VCSChecksToggle";
import useCloseFullScreenModal from "../../useCloseFullScreenModal";
import useCheckGitHubEnterpriseHasDefaultIntegration from "../useCheckGitHubEnterpriseHasDefaultIntegration";
import useGetGitHunEnterpriseWebhooksEndpoint from "../useGetGitHunEnterpriseWebhooksEndpoint";
import {
  getFormDefaultValues,
  mapCreateGitHubEnterpriseIntegrationInputValues,
  mapUpdateGitHubEnterpriseIntegrationInputValues,
} from "./helpers";
import { ManageGitHubEnterpriseManualFormFields } from "./types";
import useCreateGitHubEnterpriseIntegration from "./useCreateGitHubEnterpriseIntegration";
import useUpdateGitHubEnterpriseIntegration from "./useUpdateGitHubEnterpriseIntegration";

type VCSIntegrationsManageGitHubEnterpriseManualProps = {
  children?: ReactNode;
  integration?: GithubEnterpriseIntegration;
  onIsDirtyChange: (value: boolean) => void;
};

const VCSIntegrationsManageGitHubEnterpriseManual = ({
  children,
  integration,
  onIsDirtyChange,
}: VCSIntegrationsManageGitHubEnterpriseManualProps) => {
  const isEditMode = !!integration;

  const { viewer } = useTypedContext(AccountContext);

  const { handleOnClose } = useCloseFullScreenModal();

  const manageGitHubEnterpriseManualForm = useForm<ManageGitHubEnterpriseManualFormFields>({
    defaultValues: getFormDefaultValues(integration),
    mode: "onChange",
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = manageGitHubEnterpriseManualForm;

  const { createGitHubEnterpriseIntegration, loading: createLoading } =
    useCreateGitHubEnterpriseIntegration();
  const { updateGitHubEnterpriseIntegration, loading: updateLoading } =
    useUpdateGitHubEnterpriseIntegration();

  const { hasDefaultIntegration, loading: checkDefaultIntegrationLoading } =
    useCheckGitHubEnterpriseHasDefaultIntegration(isEditMode);

  const { generateWebhookUrl, webhookUrl } = useGetGitHunEnterpriseWebhooksEndpoint();

  const webhookUrlValue = isEditMode ? integration?.webhookUrl : webhookUrl;

  const withIntegrationTypeControl = !isEditMode && viewer.admin;
  const withSpaceControl = !withIntegrationTypeControl && !integration?.isDefault;

  const integrationTypeValue = watch("integrationType");
  const integrationNameValue = watch("name");

  const onHiddenToggle = useCallback(
    (isHidden: boolean) => {
      if (!isHidden) {
        setValue("privateKey", "", { shouldValidate: true });
      } else {
        // revalidate the whole form with hidden field
        trigger();
      }
    },
    [setValue, trigger]
  );

  const handleSetup = useCallback(
    () =>
      handleSubmit((formData: ManageGitHubEnterpriseManualFormFields) => {
        if (isEditMode) {
          updateGitHubEnterpriseIntegration(
            mapUpdateGitHubEnterpriseIntegrationInputValues(formData, integration.id),
            handleOnClose
          );
        } else {
          createGitHubEnterpriseIntegration(
            mapCreateGitHubEnterpriseIntegrationInputValues(formData),
            handleOnClose
          );
        }
      })(),
    [
      createGitHubEnterpriseIntegration,
      handleOnClose,
      integration?.id,
      isEditMode,
      updateGitHubEnterpriseIntegration,
      handleSubmit,
    ]
  );
  const debouncedIntegrationNameChange = useMemo(
    () => debounce(generateWebhookUrl, 300),
    [generateWebhookUrl]
  );

  const handleIntegrationNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setValue("name", value || "", { shouldValidate: true });

      if (!isEditMode && integrationTypeValue === VCS_INTEGRATION_TYPES.MULTIPLE) {
        debouncedIntegrationNameChange(value);
      }
    },
    [debouncedIntegrationNameChange, integrationTypeValue, isEditMode, setValue]
  );

  useEffect(() => {
    // Generate webhook url on integration type change
    if (!isEditMode) {
      generateWebhookUrl(
        integrationTypeValue === VCS_INTEGRATION_TYPES.DEFAULT ? undefined : integrationNameValue
      );
    }
    // FYI: integrationNameValue is not a dependency because we use debouncedIntegrationNameChange instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationTypeValue, generateWebhookUrl, isEditMode]);

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return (
    <FormProvider {...manageGitHubEnterpriseManualForm}>
      <FullScreenModalBody>
        {children}

        <FormField
          label="Integration name"
          helperText="The integration name is unique and cannot be changed"
          error={errors?.name?.message}
          noMargin
        >
          {({ ariaInputProps }) => (
            <Input
              disabled={isEditMode}
              placeholder="Enter integration name here"
              error={!!errors?.name}
              {...register("name", {
                validate: stringIsRequired("Integration name field is required."),
                onChange: handleIntegrationNameChange,
              })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        {withIntegrationTypeControl && (
          <VCSIntegrationTypeFieldControl
            hasAlreadyDefaultIntegration={hasDefaultIntegration}
            loading={checkDefaultIntegrationLoading}
            provider={VcsProvider.GithubEnterprise}
          />
        )}
        {withSpaceControl && <FormFieldSpace />}

        <FormField label="API host URL" error={errors?.apiHost?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="https://"
              error={!!errors?.apiHost}
              {...register("apiHost", {
                validate: validateRequiredURL(),
              })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormField label="User facing host URL" error={errors?.userFacingHost?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="https://"
              error={!!errors?.userFacingHost}
              {...register("userFacingHost", { validate: validateRequiredURL() })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormField label="App ID" error={errors?.appID?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="Enter the App ID here"
              error={!!errors?.appID}
              {...register("appID", { validate: stringIsRequired("App ID field is required.") })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormField label="Webhook secret" error={errors?.webhookSecret?.message}>
          {({ ariaInputProps }) => (
            <SecretInput
              placeholder="Enter the webhook secret here"
              error={!!errors?.webhookSecret}
              {...register("webhookSecret", {
                validate: stringIsRequired("Webhook secret field is required."),
              })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <VCSWebhookEndpointField
          integrationNameValue={integrationNameValue}
          webhookUrlValue={webhookUrlValue}
        />

        {!isEditMode && (
          <FormField label="Private key" error={errors?.privateKey?.message}>
            {({ ariaInputProps }) => (
              <Textarea
                placeholder="Enter the private key here"
                error={!!errors?.privateKey}
                {...register("privateKey", {
                  validate: stringIsRequired("Private key field is required."),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        )}

        {isEditMode && (
          <FormFieldHidden
            hiddenPlaceholder="Update private key"
            onHiddenToggle={onHiddenToggle}
            label="Private key"
            error={errors?.privateKey?.message}
          >
            {(isHidden) => (
              <Textarea
                placeholder="Enter the private key here"
                error={!!errors?.privateKey}
                {...register("privateKey", {
                  ...(!isHidden && {
                    validate: stringIsRequired("Private key field is required."),
                  }),
                })}
              />
            )}
          </FormFieldHidden>
        )}

        <VCSChecksToggle />

        <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />

        <FormField label="Description" isOptional>
          {({ ariaInputProps }) => (
            <Textarea
              placeholder="Enter description here"
              {...register("description")}
              {...ariaInputProps}
            />
          )}
        </FormField>
      </FullScreenModalBody>
      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.GithubEnterprise} />

        <Button onPress={handleSetup} variant="primary" loading={createLoading || updateLoading}>
          {isEditMode ? "Save" : "Set up"}
        </Button>
      </FullScreenModalFooter>
    </FormProvider>
  );
};

export default VCSIntegrationsManageGitHubEnterpriseManual;
