import TextLink from "components/DocumentationSnippets/TextLink";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

const EMAIL = "ar@spacelift.io";

const BillingSubscriptionManagementTermsAndConditions = () => (
  <Typography tag="p" variant="p-body4" color="secondary">
    By clicking the button, you confirm the changes to your current subscription terms:
    <ul className={styles.list}>
      <li>
        If you upgrade your subscription, you authorize a recurring charge. You can cancel the
        subscription at any time in your billing settings.
      </li>
      <li>
        If you downgrade your subscription, please contact{" "}
        <Link size="inherit" href={`mailto:${EMAIL}`} target="_blank">
          {EMAIL}
        </Link>{" "}
        to request a refund for any prepaid fees related to unused services
      </li>
    </ul>
    Learn more in our{" "}
    <TextLink href={getDocsUrl("/legal/terms")} inheritFont>
      Terms and Conditions
    </TextLink>{" "}
  </Typography>
);

export default BillingSubscriptionManagementTermsAndConditions;
