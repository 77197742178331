import { gql, TypedDocumentNode } from "@apollo/client";

import {
  AzureIntegration,
  MutationAzureIntegrationCreateArgs,
  MutationAzureIntegrationUpdateArgs,
} from "types/generated";

export const CREATE_AZURE_INTEGRATION: TypedDocumentNode<
  {
    azureIntegrationCreate: Pick<AzureIntegration, "id">;
  },
  MutationAzureIntegrationCreateArgs
> = gql`
  mutation CreateAzureIntegrationNew(
    $name: String!
    $tenantID: String!
    $labels: [String!]!
    $defaultSubscriptionId: String
    $space: ID
  ) {
    azureIntegrationCreate(
      name: $name
      tenantID: $tenantID
      labels: $labels
      defaultSubscriptionId: $defaultSubscriptionId
      space: $space
    ) {
      id
    }
  }
`;

export const UPDATE_AZURE_INTEGRATION: TypedDocumentNode<
  {
    azureIntegrationUpdate: Pick<AzureIntegration, "id">;
  },
  MutationAzureIntegrationUpdateArgs
> = gql`
  mutation UpdateAzureIntegrationNew(
    $id: ID!
    $name: String!
    $labels: [String!]!
    $defaultSubscriptionId: String
    $space: ID
  ) {
    azureIntegrationUpdate(
      id: $id
      name: $name
      labels: $labels
      defaultSubscriptionId: $defaultSubscriptionId
      space: $space
    ) {
      id
    }
  }
`;
