import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useState } from "react";

import Button from "components/button/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import FormButton from "components/FormDefault/Button";
import FormFooter from "components/FormDefault/Footer";
import PageLoading from "components/loading/PageLoading";
import PageInfo from "components/PageWrapper/Info";
import WarningBar from "components/warning/WarningBar";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageAuditTrail } from "hooks/useAnalytics/pages/auditTrail";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import { showEditDrawer } from "./EditDrawer";
import { DELETE_AUDIT_TRAIL_WEBHOOK, GET_AUDIT_TRAIL_WEBHOOK } from "./gql";
import styles from "./styles.module.css";
import View from "./View";

const AuditTrailConfig = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [isAskedToDelete, askToDelete] = useState(false);

  const { loading, data, error } = useQuery(GET_AUDIT_TRAIL_WEBHOOK, {
    onError,
  });

  const [deleteWebhook, { loading: isDeleting }] = useMutation(DELETE_AUDIT_TRAIL_WEBHOOK, {
    refetchQueries: [{ query: GET_AUDIT_TRAIL_WEBHOOK }],
  });

  useTitle(`Audit Trail · Configuration · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageAuditTrail.Configuration,
  });

  const integration = data?.auditTrailWebhook;

  const onEditMode = () => {
    trackSegmentAnalyticsEvent("Edit clicked");
    showEditDrawer({
      integration: integration || undefined,
    });
  };

  const onRemove = useCallback(async () => {
    if (!isAskedToDelete) {
      return askToDelete(true);
    }

    if (!isDeleting && isAskedToDelete) {
      try {
        await deleteWebhook();

        askToDelete(false);
        trackSegmentAnalyticsEvent("Delete clicked");
        reportSuccess({ message: "Audit trail integration successfully disabled" });
      } catch (e) {
        onError(e);
      }
    }
  }, [
    isAskedToDelete,
    isDeleting,
    deleteWebhook,
    reportSuccess,
    trackSegmentAnalyticsEvent,
    onError,
  ]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data) {
    return <PageLoading />;
  }

  const isViewMode = !!integration;

  const openCreateForm = () => {
    trackSegmentAnalyticsEvent("Set up clicked");
    showEditDrawer();
  };

  return (
    <>
      <PageInfo title="Configuration" />

      <div className={styles.auditTrailWrapper}>
        <div className={styles.integrationHeaderWrapper}>
          <h3 className={styles.integrationHeaderTitle}>Audit trail configuration</h3>

          {isViewMode && integration && (
            <div className={styles.integrationHeaderWrapperButtons}>
              <Button type="button" onClick={onEditMode} pill>
                Edit
              </Button>

              <Button type="button" danger loading={isDeleting} onClick={onRemove} pill>
                {isAskedToDelete ? "Confirm" : "Delete"}
              </Button>
            </div>
          )}
        </div>

        {isAskedToDelete && (
          <WarningBar>
            <p>
              Note that by disabling the Audit trail you will stop receiving audit event webhooks.
            </p>
          </WarningBar>
        )}

        {integration && <View {...integration} />}

        {!integration && (
          <FormFooter top>
            <FormButton type="button" onClick={openCreateForm} full pill>
              Set up
            </FormButton>
          </FormFooter>
        )}
      </div>
    </>
  );
};

export default AuditTrailConfig;
