import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

export const getTooltipAnalyticsProps = (
  location: string,
  name: string,
  tooltipAnalyticsPage: AnalyticsPageOrganization
) => {
  return {
    tooltipAnalyticsPage,
    tooltipAnalyticsTitle: "Tooltip click",
    tooltipAnalyticsProps: { location, name },
  };
};

export const validateRequired = (fieldTitle: string) => (value?: string) =>
  value?.trim() ? true : `${fieldTitle} field is required.`;
