import { FormProvider, useForm } from "react-hook-form";

import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { getDrawerFormFix } from "utils/css";

import VersionFormFragment, {
  BlueprintVersionFormFields,
} from "../../components/VersionFormFragment";

type DetailsFormDrawerProps = {
  title: string;
  buttonText: string;
  defaultValues: BlueprintVersionFormFields;
  onSubmit: (formData: BlueprintVersionFormFields) => void;
};

const DetailsFormDrawer = ({
  title,
  buttonText,
  onSubmit,
  defaultValues,
}: DetailsFormDrawerProps) => {
  const builderForm = useForm<BlueprintVersionFormFields>({
    defaultValues,
    mode: "onChange",
  });

  const { handleSubmit } = builderForm;

  return (
    <DrawerSimple>
      <FormProvider {...builderForm}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title={title} />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody>
            <VersionFormFragment />
            <DrawerFooter>
              <DrawerFooterActions>
                <DrawerCancelButton />
                <Button variant="primary" type="submit">
                  {buttonText}
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </form>
      </FormProvider>
    </DrawerSimple>
  );
};

export default DetailsFormDrawer;
