import CardWrapper from "components/CardWrapper";
import { EmptystatePuzzleColored, Link as LinkIcon, Magnet } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import TextEllipsis from "ds/components/TextEllipsis";
import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";
import { HookType } from "utils/hooks";

import { ContextHook } from "../../types";
import ContextHookListItem from "../ContextHookListItem";
import ListTitle from "../ListTitle";
import styles from "./styles.module.css";

type ContextHooksListProps = {
  type: HookType;
  hooks: ContextHook[];
};

const ContextHooksList = ({ type, hooks }: ContextHooksListProps) => {
  const setHookPriorityLabel = (hook: ContextHook) => {
    if (!hook.isAutoattached) return hook.priority;

    return type === "before" ? "Z->A" : "A->Z";
  };

  return (
    <CardWrapper direction="column" variant="filled">
      <ListTitle
        title="Context hooks"
        tooltipBody={
          <>
            Hooks coming from either auto or manually attached contexts can not be edited. Their
            order is based on context priority.
            <br />
            <ReadMoreDocsLink
              // TODO: Add docs about hook ordering
              docsUrl={getDocsUrl("/concepts/stack/stack-settings.html#note-on-hook-ordering")}
            />
          </>
        }
      />

      <Box direction="column" gap="medium" margin="medium 0 0">
        {hooks.map((hook, index) => (
          <ContextHookListItem key={hook.text + index}>
            <TextEllipsis tooltip={hook.text} tooltipWidthMode="maxWidthXl">
              {(props) => (
                <Typography {...props} tag="span" variant="p-body3">
                  {hook.text}
                </Typography>
              )}
            </TextEllipsis>
            <Box align="center" gap="large">
              <MetaInfoListItem
                className={styles.contextLink}
                tooltip={
                  hook.isAutoattached ? "Auto-attached context" : "Manually attached context"
                }
                icon={hook.isAutoattached ? Magnet : LinkIcon}
                linkText={hook.contextName}
                href={`/context/${hook.contextId}`}
              />

              <Tooltip
                active={hook.isAutoattached}
                on={(props) => (
                  <Typography variant="p-t7" tag="span" className={styles.priority} {...props}>
                    Priority: {setHookPriorityLabel(hook)}
                  </Typography>
                )}
              >
                {type === "before"
                  ? "Auto-attached contexts are using reversed alphabetical order."
                  : "Auto-attached contexts are using alphabetical order."}
              </Tooltip>
            </Box>
          </ContextHookListItem>
        ))}
        {hooks.length === 0 && (
          <EmptyState
            padding="large 0"
            icon={EmptystatePuzzleColored}
            title="No context hooks attached yet"
            caption={`If you have any hooks added ${type} the phase to the context that is manually attached to the stack or using autoattach label this is where they will appear.`}
          />
        )}
      </Box>
    </CardWrapper>
  );
};

export default ContextHooksList;
