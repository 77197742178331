import { create, show, useModal } from "@ebay/nice-modal-react";
import { useState } from "react";
import YouTube, { YouTubeEvent, YouTubePlayer } from "react-youtube";

import { Cross } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";
import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";

import styles from "./styles.module.css";

type LaunchPadVideoModalProps = {
  videoId: string;
};

const LaunchPadVideoModal = create(function LaunchPadVideoModal({
  videoId,
}: LaunchPadVideoModalProps) {
  const modal = useModal();

  const [player, setPlayer] = useState<YouTubePlayer | null>(null);

  const onReady = (event: YouTubeEvent) => {
    setPlayer(event.target);
  };

  const onModalClose = () => {
    player?.stopVideo();
    modal.hide();
  };

  return (
    <Modal
      isOpen={modal.visible}
      onClose={onModalClose}
      onExit={modal.remove}
      size="x-large"
      withAnimation
    >
      <ModalContent padding="0">
        <ButtonIcon
          className={styles.closePlayerButton}
          onPress={onModalClose}
          icon={Cross}
          size="medium"
          variant="secondary"
        >
          Close video preview
        </ButtonIcon>

        <YouTube
          className={styles.player}
          videoId={videoId}
          onReady={onReady}
          onEnd={() => modal.hide()}
        />
      </ModalContent>
    </Modal>
  );
});

export const showLaunchPadVideoModal = (props: LaunchPadVideoModalProps) =>
  show(LaunchPadVideoModal, props);
