import { ChevronDown } from "components/icons/generated";
import useTypedContext from "hooks/useTypedContext";

import ButtonIcon from "../ButtonIcon";
import DropdownMenu, { DropdownMenuProps } from "../DropdownMenu";
import { SplitControlContext } from "./context";
import styles from "./styles.module.css";

type SplitControlDropdownProps<T> = Omit<DropdownMenuProps<T>, "triggerComponent"> & {
  tooltip: string;
  loading?: boolean;
  disabled?: boolean;
};

const SplitControlDropdown = <T extends object>({
  tooltip,
  loading,
  disabled,
  children,
  ...restProps
}: SplitControlDropdownProps<T>) => {
  const { variant, size } = useTypedContext(SplitControlContext);

  return (
    <DropdownMenu
      {...restProps}
      triggerComponent={
        <ButtonIcon
          justify="center"
          variant={variant}
          size={size}
          loading={loading}
          disabled={disabled}
          className={styles.splitControlDropdownTrigger}
          icon={ChevronDown}
        >
          {tooltip}
        </ButtonIcon>
      }
    >
      {children}
    </DropdownMenu>
  );
};

SplitControlDropdown.displayName = "DS.SplitControl.Dropdown";

export default SplitControlDropdown;
