import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import { BillingTierFeature } from "types/generated";

import useTierFeature from "../../hooks/useTierFeature";
import { getBlueprintFeatureGatingMessage } from "../FeatureGate/Tooltip";
import { BlueprintActions } from "../FeatureGate/types";

type CreateStackButtonProps = {
  isPublished: boolean;
  stackCreateLoading: boolean;
};

const CreateStackButton = ({ isPublished, stackCreateLoading }: CreateStackButtonProps) => {
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);

  return (
    <Tooltip
      active={!isPublished || !isFeatureActive}
      widthMode="maxWidthSm"
      on={(props) => (
        <span {...props}>
          <Button
            variant="primary"
            type="submit"
            disabled={!isPublished || !isFeatureActive}
            loading={stackCreateLoading}
          >
            Create stack
          </Button>
        </span>
      )}
    >
      {!isPublished &&
        "This is just a template preview, you will not be able to create a stack from it."}
      {isPublished &&
        !isFeatureActive &&
        getBlueprintFeatureGatingMessage(BlueprintActions.CreateStack)}
    </Tooltip>
  );
};

export default CreateStackButton;
