import useAnalytics from "hooks/useAnalytics";

import { ANALYTICS } from "./constants";

const useBlueprintIntegrationAnalytics = () => {
  return useAnalytics({
    page: ANALYTICS.analyticsPage,
  });
};

export default useBlueprintIntegrationAnalytics;
