import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { ReactNode } from "react";

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

type DragDropListDroppableContainerProps<T = string> = {
  children: ReactNode;
  id: string;
  items: T[];
};

const DragDropListDroppableContainer = ({
  children,
  id,
  items,
}: DragDropListDroppableContainerProps) => {
  const { setNodeRef, attributes } = useSortable({
    id,
    data: {
      type: "container",
      children: items,
    },
    animateLayoutChanges,
  });

  return (
    <div ref={setNodeRef} {...attributes}>
      {children}
    </div>
  );
};

export default DragDropListDroppableContainer;
