import { dropdownEllipsisWidth } from "ds/components/DropdownMenu/constants";

export const COLUMN_ORDER_VARIABLES = `minmax(45rem, 5fr) minmax(10rem, 5fr) minmax(20rem, 1fr) ${dropdownEllipsisWidth}`;

export const COLUMN_ORDER_MOUNTED_FILES_LIST_ITEM = `minmax(45rem, 5fr) minmax(10rem, 1fr) minmax(15rem, 1fr) ${dropdownEllipsisWidth}`;

export const COLUMN_ORDER_MOUNTED_FILES_HEADER = `0.1rem ${COLUMN_ORDER_MOUNTED_FILES_LIST_ITEM} 0.1rem`;

export const COLUMN_ORDER_USED_BY =
  "minmax(10rem, 1fr) minmax(45rem, 6fr) minmax(10rem, 1fr) minmax(20rem, 1fr) 5rem";

export const FILE_PREVIEW_MAX_SIZE = 524288;

export const CONTEXT_ANALYTICS_VERSION = "Context v1";
