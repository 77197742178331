import { ReactNode, useCallback, Key as ReactKey } from "react";
import { ComboBox as AriaComboBox } from "react-aria-components";

import { AnalyticsCommonProps } from "hooks/useAnalytics";
import useEnsureId from "hooks/useEnsureId";
import { getColors } from "ds/components/Form/Field/utils";
import TooltipInfo from "ds/components/TooltipInfo";
import ListBoxPopover from "ds/components/ListBox/Popover";
import ListBox from "ds/components/ListBox";
import ListBoxScrollableWrapper from "ds/components/ListBox/ScrollableWrapper";
import FormFieldHelperText from "ds/components/Form/Field/HelperText";
import Box from "ds/components/Box";
import FormFieldAriaLabel from "ds/components/Form/Field/AriaLabel";
import ComboBoxEmptyCollection from "ds/components/ComboBox/EmptyCollection";
import { BaseCollection, SectionBaseCollection } from "ds/components/ComboBox/types";
import useSkipDuplicates from "ds/components/ComboBox/useSkipDuplicates";
import styles from "ds/components/ComboBox/styles.module.css";

import ComboBoxMultipleInput, { ComboBoxMultipleInputProps } from "./Input";

type ComboBoxMultipleProps<
  Collection extends BaseCollection | SectionBaseCollection<BaseCollection, SectionProps>,
  Key extends string,
  SectionProps extends object = object,
> = {
  id?: string;
  /**
   * label is optional, but if it's not provided you need to connect the label via ariaLabelledBy attribute
   */
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: string;
  values?: string[] | null;
  items: Array<Collection>;
  onChange: (value: Key | null) => void;
  inputValue?: string;
  renderInput?: (input: ComboBoxMultipleInputProps<Collection, SectionProps>) => ReactNode;
  onInputChange?: (value: string) => void;
  children: (Collection: Collection) => JSX.Element;
  tooltipInfo?: ReactNode;
  tooltipAnalyticsPage?: AnalyticsCommonProps["analyticsPage"];
  tooltipAnalyticsTitle?: AnalyticsCommonProps["analyticsTitle"];
  tooltipAnalyticsProps?: AnalyticsCommonProps["analyticsProps"];
  isDisabled?: boolean;
  isLoading?: boolean;
  size?: ComboBoxMultipleInputProps<Collection, SectionProps>["size"];
  color?: ComboBoxMultipleInputProps<Collection, SectionProps>["color"];
};

const ComboBoxMultiple = <
  Collection extends BaseCollection | SectionBaseCollection<BaseCollection, SectionProps>,
  Key extends string,
  SectionProps extends object = object,
>({
  id: propsId,
  children,
  label,
  helperText,
  placeholder = "Type in or select from the list",
  error,
  values,
  size,
  color,
  renderInput = (props) => <ComboBoxMultipleInput {...props} />,
  onChange,
  inputValue,
  onInputChange,
  items,
  tooltipInfo,
  tooltipAnalyticsPage,
  tooltipAnalyticsTitle,
  tooltipAnalyticsProps,
  isDisabled,
  isLoading,
}: ComboBoxMultipleProps<Collection, Key, SectionProps>) => {
  const id = useEnsureId(propsId);
  const { primaryIconColor } = getColors(color);

  const handleSelectionChange = useCallback(
    (value: ReactKey | null) => {
      if (value === null || value === undefined) {
        return onChange(value);
      }

      return onChange(String(value) as Key);
    },
    [onChange]
  );

  const deduplicatedItems = useSkipDuplicates(items);
  const deduplicatedItemsWithSelection = deduplicatedItems.map((item) => ({
    ...item,
    isSelected: "value" in item && values?.includes(item.value),
  }));

  return (
    <AriaComboBox
      className={styles.comboBox}
      menuTrigger="focus"
      selectedKey={null}
      onSelectionChange={handleSelectionChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      isInvalid={!!error}
      isDisabled={isDisabled}
      allowsEmptyCollection
    >
      <Box gap="small" direction="column">
        {label && (
          <Box gap="small" align="center" fullWidth>
            <FormFieldAriaLabel label={label} color={color} />
            {tooltipInfo && (
              <TooltipInfo
                variant="modal"
                iconColor={primaryIconColor}
                analyticsPage={tooltipAnalyticsPage}
                analyticsTitle={tooltipAnalyticsTitle}
                analyticsProps={tooltipAnalyticsProps}
              >
                {tooltipInfo}
              </TooltipInfo>
            )}
          </Box>
        )}
        {renderInput({
          id,
          placeholder,
          isError: !!error,
          isDisabled,
          isLoading,
          size,
          color,
          values,
          items,
        })}
        <FormFieldHelperText helperText={helperText} error={error} id={id} color={color} />
      </Box>

      <ListBoxPopover>
        <ListBoxScrollableWrapper>
          <ListBox<Collection>
            items={deduplicatedItemsWithSelection}
            renderEmptyState={() => <ComboBoxEmptyCollection isLoading={isLoading} />}
          >
            {children}
          </ListBox>
        </ListBoxScrollableWrapper>
      </ListBoxPopover>
    </AriaComboBox>
  );
};

export default ComboBoxMultiple;
