import Skeleton, { SkeletonProps } from "react-loading-skeleton";

type IacManagementTableSkeletonItemProps = Pick<SkeletonProps, "enableAnimation">;

const IacManagementTableSkeletonItem = ({
  enableAnimation,
}: IacManagementTableSkeletonItemProps) => (
  <Skeleton count={1} height={20} borderRadius={16} enableAnimation={enableAnimation} />
);

export default IacManagementTableSkeletonItem;
