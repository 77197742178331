import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  StackAwsIntegrationAttachment,
  StackAzureIntegrationAttachment,
  StackIntegrationGcp,
} from "types/generated";

import {
  ATTACH_AWS_CLOUD_INTEGRATION,
  ATTACH_AZURE_CLOUD_INTEGRATION,
  ATTACH_GCP_CLOUD_INTEGRATION,
} from "./gql";
import { CLOUD_INTEGRATIONS } from "./types";

type SharedParams = {
  integrationId: string;
  read: boolean;
  write: boolean;
};

type AwsParams = SharedParams & {
  type: CLOUD_INTEGRATIONS.AWS;
};

type AzureParams = SharedParams & {
  type: CLOUD_INTEGRATIONS.Azure;
  azureSubscriptionId?: string;
};

type GcpParams = {
  type: CLOUD_INTEGRATIONS.GCP;
  scopes: string[];
};

type Params = AwsParams | AzureParams | GcpParams;

type Options = {
  entityId: string;
  onSuccess?: () => void;
  refetchQueries?: string[];
};

const useAttachCloudIntegration = ({ onSuccess, entityId, refetchQueries }: Options) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [isIntegrationAwsWarningVisible, setIsIntegrationAwsWarningVisible] = useState(false);

  const [attachAwsIntegration, { loading: attachAwsLoading }] = useMutation<{
    awsIntegrationAttach: StackAwsIntegrationAttachment;
  }>(ATTACH_AWS_CLOUD_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const hideIntegrationAwsWarning = useCallback(() => setIsIntegrationAwsWarningVisible(false), []);

  const [attachAzureIntegration, { loading: attachAzureLoading }] = useMutation<{
    azureIntegrationAttach: StackAzureIntegrationAttachment;
  }>(ATTACH_AZURE_CLOUD_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const [attachGcpIntegration, { loading: attachGcpLoading }] = useMutation<{
    stackIntegrationGcpCreate: StackIntegrationGcp;
  }>(ATTACH_GCP_CLOUD_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const attachAws = useCallback(
    async ({ integrationId, read, write }: AwsParams) => {
      return attachAwsIntegration({
        variables: {
          read,
          write,
          integrationId,
          entityId,
        },
      })
        .then(({ data }) => {
          if (data?.awsIntegrationAttach?.id) {
            reportSuccess({ message: "AWS integration successfully attached" });
            onSuccess?.();
          }
        })
        .catch((e) => {
          if (
            e.message === "you need to configure trust relationship section in your AWS account"
          ) {
            setIsIntegrationAwsWarningVisible(true);
          }
          onError(e);
        });
    },
    [
      attachAwsIntegration,
      reportSuccess,
      onError,
      setIsIntegrationAwsWarningVisible,
      entityId,
      onSuccess,
    ]
  );

  const attachAzure = useCallback(
    async ({ integrationId, read, write, azureSubscriptionId }: AzureParams) => {
      return attachAzureIntegration({
        variables: {
          read,
          write,
          integrationId,
          entityId,
          subscriptionId: azureSubscriptionId,
        },
      })
        .then(({ data }) => {
          if (data?.azureIntegrationAttach?.id) {
            reportSuccess({ message: "Azure integration successfully attached" });
            onSuccess?.();
          }
        })
        .catch(onError);
    },
    [attachAzureIntegration, reportSuccess, onError, entityId, onSuccess]
  );

  const attachGcp = useCallback(
    async ({ scopes }: GcpParams) => {
      return attachGcpIntegration({
        variables: {
          entityId,
          scopes,
        },
      })
        .then(({ data }) => {
          if (data?.stackIntegrationGcpCreate?.activated) {
            reportSuccess({ message: "GCP integration successfully attached" });
            onSuccess?.();
          }
        })
        .catch(onError);
    },
    [attachGcpIntegration, reportSuccess, onError, entityId, onSuccess]
  );

  const attach = useCallback(
    (params: Params) => {
      switch (params.type) {
        case CLOUD_INTEGRATIONS.AWS:
          return attachAws(params);
        case CLOUD_INTEGRATIONS.Azure:
          return attachAzure(params);
        case CLOUD_INTEGRATIONS.GCP:
          return attachGcp(params);
      }
    },
    [attachAws, attachAzure, attachGcp]
  );

  return {
    attach,
    attachLoading: attachAzureLoading || attachAwsLoading || attachGcpLoading,
    isIntegrationAwsWarningVisible,
    hideIntegrationAwsWarning,
  };
};

export default useAttachCloudIntegration;
