import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Blueprint, BlueprintInput, BlueprintInputType } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { BLUEPRINT_CREATE_STACK } from "./gql";
import { BlueprintTemplateFormFields } from "./types";

type UseBlueprintTemplateFormOptions = {
  blueprint: Blueprint;
};

const useBlueprintTemplateForm = ({ blueprint }: UseBlueprintTemplateFormOptions) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [inputs, setInputs] = useState<BlueprintInput[]>(blueprint.inputs);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const hasErrors = errors.length > 0;
  const hasPreview = inputs.length > 0;

  const [createStack, { loading: stackCreateLoading }] = useMutation(BLUEPRINT_CREATE_STACK, {
    onError,
  });

  const defaultValues = useMemo(
    () =>
      inputs.reduce((acc, input) => {
        if (input.type === BlueprintInputType.Boolean) {
          acc[input.id] = input.default === "true";
        } else {
          acc[input.id] = input.default;
        }
        return acc;
      }, {} as BlueprintTemplateFormFields),
    [inputs]
  );

  const builderForm = useForm<BlueprintTemplateFormFields>({
    defaultValues,
    mode: "onChange",
  });

  const {
    reset,
    formState: { isDirty },
  } = builderForm;

  const onSubmit: SubmitHandler<BlueprintTemplateFormFields> = (formData) => {
    createStack({
      variables: {
        id: blueprint.id,
        input: {
          templateInputs: Object.entries(formData).map(([key, value]) => ({
            id: key,
            // FYI: this is a workaround for the backend validation
            value: value?.toString() as string,
          })),
        },
      },
    })
      .then(({ data }) => {
        if (data?.blueprintCreateStack) {
          reportSuccess({ message: `Stack is successfully created` });
          navigate(`/stack/${data.blueprintCreateStack.stackID}`);
        }
      })
      .catch(onError);
  };

  useEffect(() => {
    // update default values when inputs change but only if the form is not dirty
    if (!isDirty) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, isDirty]);

  return {
    builderForm,
    onSubmit,
    stackCreateLoading,
    hasErrors,
    hasPreview,
    errors,
    setErrors,
    inputs,
    setInputs,
  };
};

export default useBlueprintTemplateForm;
