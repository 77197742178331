import { gql, TypedDocumentNode } from "@apollo/client";

import { QueryRoleArgs, Role } from "types/generated";

type RoleData = {
  role: Role;
};

export const GET_ROLE: TypedDocumentNode<RoleData, QueryRoleArgs> = gql`
  query GetRole($id: ID!) {
    role(id: $id) {
      id
      name
      description
      actions
      isSystem
      roleBindingsCount
    }
  }
`;
