import { FormProvider } from "react-hook-form";

import BlueprintTemplateForm from "views/Account/Blueprint/components/TemplateForm";
import { showFullDescription } from "ds/components/FullDescriptionDrawer";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import { Blueprint, BlueprintState } from "types/generated";
import DocumentationIconButton from "components/DocumentationIconButton";
import { getDocsUrl } from "utils/getDocsUrl";
import useBlueprintTemplateForm from "views/Account/Blueprint/components/TemplateForm/useBlueprintTemplateForm";

import CreateStackButton from ".././CreateStackDrawer/CreateStackButton";

type CreateStackModalFormProps = {
  blueprint: Blueprint;
};

const CreateStackModalForm = ({ blueprint }: CreateStackModalFormProps) => {
  // Modal is available only for published blueprints, therefore this check is supposed to be always true
  const isPublished = blueprint.state === BlueprintState.Published;

  const { builderForm, onSubmit, stackCreateLoading, hasErrors, hasPreview, errors, inputs } =
    useBlueprintTemplateForm({
      blueprint,
    });

  const { handleSubmit } = builderForm;

  const handleShowFullDescription = () => {
    showFullDescription({ description: blueprint.description });
  };

  return (
    <FormProvider {...builderForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FullScreenModalBody>
          <BlueprintTemplateForm
            item={blueprint}
            isPublished={isPublished}
            onOpenFullDescription={handleShowFullDescription}
            hasErrors={hasErrors}
            hasPreview={hasPreview}
            errors={errors}
            inputs={inputs}
          />
        </FullScreenModalBody>

        <FullScreenModalFooter gap="medium" justify="between">
          <DocumentationIconButton
            href={getDocsUrl("/concepts/blueprint")}
            tooltipText="Go to blueprints documentation"
          />

          <CreateStackButton isPublished={isPublished} stackCreateLoading={stackCreateLoading} />
        </FullScreenModalFooter>
      </form>
    </FormProvider>
  );
};

export default CreateStackModalForm;
