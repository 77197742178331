import type { ReactNode } from "react";

import Box from "ds/components/Box";
import CopyFieldIcon from "components/CopyField/Icon";

type FormFieldViewTextCopyWrapperProps = {
  children?: ReactNode;
  value: string;
  onCopyCallback?: () => void;
};

const FormFieldViewTextCopyWrapper = ({
  value,
  children,
  onCopyCallback,
}: FormFieldViewTextCopyWrapperProps) => {
  return (
    <Box direction="row" gap="medium" justify="between" align="center">
      {children}
      <CopyFieldIcon title="Copy" value={value} callback={onCopyCallback} />
    </Box>
  );
};

export default FormFieldViewTextCopyWrapper;
