import cx from "classnames";
import { ForwardedRef, forwardRef } from "react";
import { mergeProps } from "@react-aria/utils";

import { AnalyticsCommonProps } from "hooks/useAnalytics";
import { IconComponent } from "types/Icon";

import { BaseActionButtonProps } from "../BaseAction/Button";
import Button, { ButtonProps } from "../Button";
import Tooltip from "../Tooltip";
import { TooltipProps } from "../Tooltip/types";
import styles from "./styles.module.css";

export type ButtonIconProps = BaseActionButtonProps & {
  variant: ButtonProps["variant"];
  size?: ButtonProps["size"];
  active?: ButtonProps["active"];
  disabled?: ButtonProps["disabled"];
  iconColor?: ButtonProps["iconColor"];
  icon: IconComponent;
  iconRotate?: ButtonProps["startIconRotate"];
  tooltipPlacement?: TooltipProps["placement"];
  disableTooltip?: boolean;
  tooltipMode?: TooltipProps["mode"];
  children: string;
} & AnalyticsCommonProps;

const ButtonIcon = forwardRef(function ButtonIcon(
  props: ButtonIconProps,
  ref: ForwardedRef<HTMLElement>
) {
  const {
    size = "small",
    children,
    className,
    icon,
    iconRotate,
    tooltipPlacement,
    disableTooltip,
    tooltipMode,
    ...restProps
  } = props;

  return (
    <Tooltip
      ref={ref}
      mode={tooltipMode}
      placement={tooltipPlacement}
      widthMode="maxWidthSm"
      on={(tooltipProps) => (
        <Button
          className={cx(styles.buttonIcon, styles[size], className)}
          size={size}
          startIcon={icon}
          startIconRotate={iconRotate}
          aria-label={children}
          {...mergeProps(tooltipProps, restProps)} // allows to pass events and merge them with tooltipProps events
        >
          {
            "" /* Use empty string, as button has required children for a11y purposes, ButtonIcon handles label via aria-label */
          }
        </Button>
      )}
      active={!restProps.disabled && !disableTooltip}
      disableAriaLabel
    >
      {children}
    </Tooltip>
  );
});

ButtonIcon.displayName = "DS.ButtonIcon";

export default ButtonIcon;
