import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { UserRoleBinding } from "types/generated";

import { GET_USER_WITH_ROLES } from "./gql";
import { UserViewBindingRole } from "./types";

type UseGetUserProps = {
  id?: string;
};

const useGetUser = ({ id }: UseGetUserProps) => {
  const { onError } = useTypedContext(FlashContext);
  const { data, loading, error } = useQuery(GET_USER_WITH_ROLES, {
    onError,
    variables: {
      id: id!,
    },
    skip: !id,
  });

  const user = useMemo(
    () =>
      data
        ? {
            ...data.managedUser,
            userRoleBindings: data.managedUser.userRoleBindings.reduce(
              (acc, userRoleBinding) => {
                const role = data.roles.find((role) => role.id === userRoleBinding.roleID);
                if (!role) {
                  return acc;
                }

                return [
                  ...acc,
                  {
                    ...userRoleBinding,
                    role,
                  },
                ];
              },
              [] as Array<
                UserRoleBinding & {
                  role: UserViewBindingRole;
                }
              >
            ),
          }
        : undefined,
    [data]
  );

  return { user, loading, error };
};

export default useGetUser;
