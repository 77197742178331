import SystemMessage from "components/SystemMessage";
import { WavingHandColored } from "components/icons/generated";
import LinkButton from "ds/components/Button/LinkButton";
import useTitle from "hooks/useTitle";

const SelfHostedLogout = () => {
  useTitle("See you later");

  return (
    <SystemMessage
      title="See you later!"
      message="You have been successfully logged out. If you would like to log in again use the login button."
      icon={WavingHandColored}
    >
      <LinkButton to="/login" variant="primary" fullWidth>
        Login again
      </LinkButton>
    </SystemMessage>
  );
};

export default SelfHostedLogout;
