import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import useErrorHandle from "hooks/useErrorHandle";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import NotFoundPage from "components/error/NotFoundPage";

import { GET_AWS_INTEGRATION } from "./gql";
import CloudIntegrationsIntegration from "../components/Integration";

const CloudIntegrationsAwsIntegration = () => {
  const { integrationId } = useParams();

  const { data, loading, error } = useQuery(GET_AWS_INTEGRATION, {
    variables: { id: integrationId },
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data) {
    return <PageLayoutSkeleton />;
  }

  if (!data?.awsIntegration) {
    return <NotFoundPage />;
  }

  return <CloudIntegrationsIntegration integration={data.awsIntegration} />;
};

export default CloudIntegrationsAwsIntegration;
