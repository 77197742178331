import type { DocumentNode } from "@apollo/client";

import useTypedFlags from "hooks/useTypedFlags";

import {
  DEFAULT_POLLING_INTERVAL_GROUPS_MS,
  DEFAULT_POLLING_INTERVAL_MS,
  PollingIntervalGroups,
} from "../constants";

const castToMs = (intervalInSeconds: number) => intervalInSeconds * 1000;

const getGroupIntervalInMiliseconds = (
  group?: PollingIntervalGroups,
  ldGroups?: Record<PollingIntervalGroups, number>
) => {
  if (!group || !ldGroups) {
    return undefined;
  }
  const ldInterval = ldGroups[group];
  if (ldInterval) {
    return castToMs(ldInterval);
  }

  return DEFAULT_POLLING_INTERVAL_GROUPS_MS[group];
};

const extractOperationName = (query?: DocumentNode) => {
  const definitions = query?.definitions || [];
  const operationDefinition = definitions.find(
    (def) => def.kind === "OperationDefinition" && def.operation === "query"
  );
  if (!operationDefinition) {
    return undefined;
  }
  return "name" in operationDefinition ? operationDefinition?.name?.value : undefined;
};

const usePollingInterval = (
  query?: DocumentNode,
  group?: PollingIntervalGroups,
  overrideInterval?: number
) => {
  const { frontendPollingSeconds } = useTypedFlags();
  if (overrideInterval) {
    return overrideInterval;
  }

  const operationName = extractOperationName(query);
  const operationIntervalInSeconds = frontendPollingSeconds?.singleQuery?.[operationName || ""];
  if (operationIntervalInSeconds) {
    return castToMs(operationIntervalInSeconds);
  }

  const groupIntervalInMiliseconds = getGroupIntervalInMiliseconds(
    group,
    frontendPollingSeconds?.group
  );
  if (groupIntervalInMiliseconds) {
    return groupIntervalInMiliseconds;
  }

  return DEFAULT_POLLING_INTERVAL_MS;
};

export default usePollingInterval;
