import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationRoleUpdateArgs, Role } from "types/generated";

type RoleUpdateData = {
  roleUpdate: Pick<Role, "id">;
};

export const ROLE_UPDATE: TypedDocumentNode<RoleUpdateData, MutationRoleUpdateArgs> = gql`
  mutation RoleUpdated($id: ID!, $input: RoleUpdateInput!) {
    roleUpdate(id: $id, input: $input) {
      id
    }
  }
`;
