import { Time } from "@internationalized/date";

import { pluralize } from "shared/Pluralize";

export const getDurationOptions = (durationTimes: Time[]) => {
  return durationTimes.map((time) => {
    return {
      label:
        time.hour === 0 ? `${time.minute} min` : `${time.hour} ${pluralize("hour", time.hour)}`,
      value: new Time(time.hour, time.minute),
    };
  });
};
