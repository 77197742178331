import IacManagementTableCell from "../Row/Cell";
import IacManagementTableBodyRow from "../Row";
import IacManagementTableSkeletonItem from "./Item";

type IacManagementTableSkeletonRowProps = {
  dataIndex: number;
};

const IacManagementTableSkeletonRow = ({ dataIndex }: IacManagementTableSkeletonRowProps) => {
  return (
    <IacManagementTableBodyRow dataIndex={dataIndex}>
      <IacManagementTableCell direction="column">
        <IacManagementTableSkeletonItem />
      </IacManagementTableCell>
      <IacManagementTableCell direction="column">
        <IacManagementTableSkeletonItem />
      </IacManagementTableCell>
      <IacManagementTableCell direction="column">
        <IacManagementTableSkeletonItem />
      </IacManagementTableCell>
      <IacManagementTableCell direction="column">
        <IacManagementTableSkeletonItem />
      </IacManagementTableCell>
      <IacManagementTableCell direction="column">
        <IacManagementTableSkeletonItem />
      </IacManagementTableCell>
      <IacManagementTableCell direction="column">
        <IacManagementTableSkeletonItem />
      </IacManagementTableCell>
    </IacManagementTableBodyRow>
  );
};

export default IacManagementTableSkeletonRow;
