import { AnalyticsPageBlueprints } from "hooks/useAnalytics/pages/blueprints";
import { ColumnConfig } from "components/Table/types";

export const VERSIONS_COLUMNS_STORAGE_KEY = "blueprintVersionsColumnsConfig";

export enum VERSIONS_COLUMN_ID {
  STATUS = "STATUS",
  VERSION = "VERSION",
  DESCRIPTION = "DESCRIPTION",
  UPDATED_AT = "UPDATED_AT",
  DROPDOWN = "DROPDOWN",
}

export const ROW_HEADER_ID = VERSIONS_COLUMN_ID.DESCRIPTION;

const MAX_CELL_WIDTH = 600;

export const VERSIONS_COLUMN_CONFIG: ColumnConfig = {
  [VERSIONS_COLUMN_ID.STATUS]: {
    title: "Status",
    minWidth: 120,
    defaultWidth: 120,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
  [VERSIONS_COLUMN_ID.VERSION]: {
    title: "Version",
    minWidth: 120,
    defaultWidth: 170,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
  [VERSIONS_COLUMN_ID.DESCRIPTION]: {
    title: "Version description",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
  [VERSIONS_COLUMN_ID.UPDATED_AT]: {
    title: "Updated at",
    minWidth: 120,
    defaultWidth: 120,
    maxWidth: MAX_CELL_WIDTH,
    static: true,
  },
};

export const VERSIONS_CUSTOM_END_COLUMN_CONFIG: ColumnConfig = {
  [VERSIONS_COLUMN_ID.DROPDOWN]: {
    title: "More blueprint version actions",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
  },
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageBlueprints.BlueprintVersionsList,
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(VERSIONS_COLUMN_CONFIG) as VERSIONS_COLUMN_ID[],
  hidden: [],
};
