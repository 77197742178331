import { ansiToJson } from "anser";
import { camelCase } from "lodash-es";
import { useMemo } from "react";

import ThemedLogsContent from "./Content";
import styles from "./styles.module.css";
import { getLogLineStyles } from "./utils";

type ThemedLogsAnsiContentProps = {
  value: string;
};

const ThemedLogsAnsiContent = ({ value }: ThemedLogsAnsiContentProps) => {
  const decoded = useMemo(
    () =>
      ansiToJson(value, {
        json: true,
        use_classes: true,
        remove_empty: true,
      }),
    [value]
  );

  return (
    <ThemedLogsContent>
      {decoded.map((entry, index) => (
        <span
          className={styles[camelCase(entry.fg)]}
          style={getLogLineStyles(entry)}
          key={`line-${index}`}
        >
          {entry.content}
        </span>
      ))}
    </ThemedLogsContent>
  );
};

export default ThemedLogsAnsiContent;
