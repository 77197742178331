import { useCallback } from "react";

import BulkActions from "components/BulkActions";
import {
  BulkActionItemID,
  BulkActionResultTabs,
  BulkActionsResult,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import useBulkActions from "components/BulkActions/useBulkActions";
import BulkActionsFloatingBar from "components/BulkActions/FloatingBar";
import BulkActionsDrawer from "components/BulkActions/Drawer";
import BulkActionsFloatingBarHeader from "components/BulkActions/FloatingBar/Header";
import BulkActionsFloatingBarSteps from "components/BulkActions/FloatingBar/Steps";
import BulkActionsDrawerActionSteps from "components/BulkActions/Drawer/ActionSteps";
import BulkActionsDrawerActionStepsHeader from "components/BulkActions/Drawer/ActionSteps/Header";
import BulkActionsDrawerActionStepsInfoBanner from "components/BulkActions/Drawer/ActionSteps/InfoBanner";
import BulkActionsDrawerResultsStep from "components/BulkActions/Drawer/ResultsStep";
import BulkActionsDrawerResultsStepHeader from "components/BulkActions/Drawer/ResultsStep/Header";
import BulkActionsDrawerResultsStepBody from "components/BulkActions/Drawer/ResultsStep/Body";
import BulkActionsDrawerResultsStepFooter from "components/BulkActions/Drawer/ResultsStep/Footer";
import useExecutionQueue from "components/BulkActions/useExecutionQueue";
import { type BulkActionItemTriggerResult } from "components/BulkActions/helpers";
import { AnalyticsPageRuns } from "hooks/useAnalytics/pages/runs";
import useAnalytics from "hooks/useAnalytics";
import BulkActionsDrawerResultsStepBodyEmptyTab from "components/BulkActions/Drawer/ResultsStep/Body/EmptyTab";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";
import { useResultTabItems } from "components/BulkActions/useResultTabItems";

import { StackRunEntity } from "../types";
import { BULK_ACTION_RESULTS_TITLE } from "./constants";
import useRunsBulkActions, { type RunsMutationVariables } from "./useRunsBulkActions";
import { RunsBulkActionsAnalyticsPayload } from "./types";
import RunsBulkActionsResultItem from "./ResultItem";
import RunsBulkActionsConfirmationView from "./ConfirmationView";
import RunsBulkActionsSelectedItemsView from "./SelectedItemsView";
import RunsBulkActionsConfirmActionForm from "./ConfirmActionForm";

type RunsBulkActionsProps = {
  selectedSet: Set<BulkActionItemID>;
  runsMap: Map<BulkActionItemID, StackRunEntity>;
  onFinish: () => Promise<unknown>;
};

const RunsBulkActions = ({ selectedSet, runsMap, onFinish }: RunsBulkActionsProps) => {
  const selectedItemsCount = selectedSet.size;

  const stackRunsBulkActions = useRunsBulkActions();
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageRuns.Runs,
  });
  const {
    selectedBulkAction,
    resetSelectedAction,
    availableActions,
    itemsPerAction,
    actionsPerItem,
    selectedEntityItems,
    applicableSelectedEntityItems,
    skippedSelectedEntityItems,
  } = useBulkActions({
    actions: stackRunsBulkActions,
    selectedSet,
    entityItemsMap: runsMap,
    analyticsPage: AnalyticsPageRuns.Runs,
  });

  const onItemTrigger = useCallback(
    (
      id: BulkActionItemID,
      mutationVariables: RunsMutationVariables
    ): Promise<BulkActionItemTriggerResult> => {
      const run = runsMap.get(id);
      if (!run || !selectedBulkAction) {
        return Promise.reject();
      }
      if (!selectedBulkAction.condition(run)) {
        return Promise.resolve(BulkActionsResult.Skipped);
      }
      return selectedBulkAction.mutation(run, mutationVariables);
    },
    [runsMap, selectedBulkAction]
  );

  const {
    triggerExecution,
    bulkActionItemResults,
    stopExecution,
    stopExecutionForItem,
    bulkActionResultsMetadata,
    resetBulkActionsExecutionQueue,
  } = useExecutionQueue({
    onItemTrigger,
    onAllItemsFinished: onFinish,
    analyticsPage: AnalyticsPageRuns.Runs,
  });

  const { resultsTabItems, saveEntityMap, resetEntityMap } =
    useResultTabItems<StackRunEntity>(bulkActionItemResults);

  const onReset = useCallback(() => {
    resetEntityMap();
    resetSelectedAction();
    resetBulkActionsExecutionQueue();
  }, [resetEntityMap, resetBulkActionsExecutionQueue, resetSelectedAction]);

  const handleOnConfirm = useCallback(
    (
      mutationVariables: RunsMutationVariables,
      analyticsPayload: RunsBulkActionsAnalyticsPayload
    ) => {
      if (selectedBulkAction) {
        const items = itemsPerAction.get(selectedBulkAction.key) || [];
        const skippedItems = skippedSelectedEntityItems.map((item) => item.id);
        triggerExecution(items, mutationVariables, skippedItems);
        saveEntityMap(runsMap);
        trackSegmentAnalyticsEvent("Bulk Actions - Action Confirmed", {
          applicableCount: items?.length || 0,
          notApplicableCount: skippedItems.length,
          action: selectedBulkAction.key,
          ...analyticsPayload,
        });
      }
    },
    [
      itemsPerAction,
      runsMap,
      saveEntityMap,
      selectedBulkAction,
      skippedSelectedEntityItems,
      trackSegmentAnalyticsEvent,
      triggerExecution,
    ]
  );

  const handleOnCancel = (variant: BulkActionsVariant) => () => {
    if (selectedBulkAction) {
      resetSelectedAction();
      const items = itemsPerAction.get(selectedBulkAction.key);
      trackSegmentAnalyticsEvent("Bulk actions - Action Cancelled", {
        applicableCount: items?.length || 0,
        notApplicableCount: skippedSelectedEntityItems.length,
        action: selectedBulkAction.key,
        view: variant,
      });
    }
  };

  const handleContinueWith = useCallback(() => {
    resetSelectedAction();
    resetBulkActionsExecutionQueue();
  }, [resetSelectedAction, resetBulkActionsExecutionQueue]);

  return (
    <BulkActions
      onContinueWith={handleContinueWith}
      selectedItemsCount={selectedItemsCount}
      onClose={onReset}
      isExecutingBulkActions={!!resultsTabItems[BulkActionResultTabs.Pending].count}
    >
      <BulkActionsFloatingBar isFixed>
        <BulkActionsFloatingBarHeader
          selectedItemsCount={selectedItemsCount}
          applicableItemsCount={
            selectedBulkAction?.key ? itemsPerAction.get(selectedBulkAction.key)?.length : 0
          }
          analyticsPage={AnalyticsPageRuns.Runs}
          hideClose
        />
        <BulkActionsFloatingBarSteps>
          {!selectedBulkAction && (
            <BulkActionsPatternsActionsList
              variant={BulkActionsVariant.FloatingBar}
              availableActions={availableActions}
            />
          )}

          {selectedBulkAction && (
            <RunsBulkActionsConfirmActionForm
              variant={BulkActionsVariant.FloatingBar}
              action={selectedBulkAction}
              onConfirm={handleOnConfirm}
              onCancel={handleOnCancel(BulkActionsVariant.FloatingBar)}
            />
          )}
        </BulkActionsFloatingBarSteps>
      </BulkActionsFloatingBar>

      <BulkActionsDrawer>
        <BulkActionsDrawerActionSteps>
          <BulkActionsDrawerActionStepsHeader
            hideClose
            analyticsPage={AnalyticsPageRuns.Runs}
            step={
              !selectedBulkAction
                ? BulkActionsStep.ChooseAction
                : BulkActionsStep.ActionConfirmation
            }
          />
          <BulkActionsDrawerActionStepsInfoBanner />

          {!selectedBulkAction && (
            <RunsBulkActionsSelectedItemsView
              items={selectedEntityItems}
              availableActions={availableActions}
              actionsPerItem={actionsPerItem}
            />
          )}
          {selectedBulkAction && (
            <RunsBulkActionsConfirmationView
              applicableItems={applicableSelectedEntityItems}
              skippedItems={skippedSelectedEntityItems}
              selectedBulkAction={selectedBulkAction}
              onConfirm={handleOnConfirm}
              onCancel={handleOnCancel(BulkActionsVariant.Drawer)}
            />
          )}
        </BulkActionsDrawerActionSteps>

        <BulkActionsDrawerResultsStep>
          {(props) => (
            <>
              <BulkActionsDrawerResultsStepHeader
                title={
                  selectedBulkAction?.key
                    ? BULK_ACTION_RESULTS_TITLE[selectedBulkAction.key]
                    : "Results"
                }
                analyticsPage={AnalyticsPageRuns.Runs}
              />
              <BulkActionsDrawerResultsStepBody {...props} counter={resultsTabItems}>
                {({ currentTab }) =>
                  resultsTabItems[currentTab].count === 0 ? (
                    <BulkActionsDrawerResultsStepBodyEmptyTab currentTab={currentTab} />
                  ) : (
                    resultsTabItems[currentTab].entitiesList.map(({ status, items }) =>
                      items.map((item) => (
                        <RunsBulkActionsResultItem
                          key={item.id}
                          tab={currentTab}
                          item={item}
                          resultsMetadata={bulkActionResultsMetadata}
                          stopExecution={() => stopExecutionForItem(item.id)}
                          status={status}
                        />
                      ))
                    )
                  )
                }
              </BulkActionsDrawerResultsStepBody>
              <BulkActionsDrawerResultsStepFooter
                {...props}
                bulkActionItemResults={bulkActionItemResults}
                stopExecution={stopExecution}
                analyticsPage={AnalyticsPageRuns.Runs}
              />
            </>
          )}
        </BulkActionsDrawerResultsStep>
      </BulkActionsDrawer>
    </BulkActions>
  );
};

export default RunsBulkActions;
