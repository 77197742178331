import { Time } from "@internationalized/date";
import { formatDistanceToNow } from "date-fns/esm";

export const getHumanizedTimestamp = (timestampMs: number): string => {
  return formatDistanceToNow(timestampMs, { addSuffix: true });
};

const formatDigits = (value: number, twoDigits: boolean) =>
  twoDigits ? value.toString().padStart(2, "0") : value;

export const getDuration = (
  valueInSeconds?: number | null,
  includeSeconds?: boolean,
  twoDigits: boolean = true
) => {
  if (!valueInSeconds) {
    return undefined;
  }

  const hours = Math.floor(valueInSeconds / 3600);
  const minutes = Math.floor((valueInSeconds % 3600) / 60);
  const seconds = Math.floor(valueInSeconds % 3600) % 60;

  const parsedHours = hours ? `${formatDigits(hours, twoDigits)}h` : "";
  const parsedMinutes = minutes || hours ? `${formatDigits(minutes, twoDigits)}m` : "";
  const parsedSeconds = includeSeconds ? `${formatDigits(seconds, twoDigits)}s` : "";

  return `${parsedHours} ${parsedMinutes} ${parsedSeconds}`.trim();
};

/**
 * Use this function to get the current time in milliseconds.
 * Use the `now` function from `@internationalized/date` to get the current time in a specific timezone.
 * @returns current time in milliseconds
 */
export const getNowTimeMs = () => Date.now();

// transform UNIX timestamp in seconds to milliseconds (frontend libs uses timestamps in milliseconds)
export const unixTimestampToMs = (timestamp: number): number => {
  return timestamp < 10000000000 ? timestamp * 1000 : timestamp;
};

export const convertSecondsToTime = (timeInSeconds: number): Time => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;
  return new Time(hours, minutes, seconds);
};

export const convertTimeToSeconds = (time: Time): number => {
  return time.hour * 3600 + time.minute * 60 + time.second;
};
