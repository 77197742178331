import { gql, TypedDocumentNode } from "@apollo/client";

import { QueryManagedUserArgs, UserRoleBinding } from "types/generated";

import { UserViewBindingRole, UserViewDetails } from "./types";

type GetUserData = {
  managedUser: UserViewDetails & {
    userRoleBindings: UserRoleBinding[];
  };
  roles: UserViewBindingRole[];
};

export const GET_USER_WITH_ROLES: TypedDocumentNode<GetUserData, QueryManagedUserArgs> = gql`
  query GetUserWithRoles($id: ID!) {
    managedUser(id: $id) {
      id
      invitationEmail
      status
      username
      role
      latestGroups
      slackMemberID
      accessRules {
        space
        spaceAccessLevel
      }
      userRoleBindings {
        id
        roleID
        spaceID
        userID
      }
    }
    roles {
      id
      name
      isSystem
      description
    }
  }
`;
