import { useCallback, useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { SearchQueryPredicate } from "types/generated";
import { uniqByKey } from "utils/uniq";
import fetchMoreUpdateQueryPreviousFix from "apollo/fetchMoreUpdateQueryPreviousFix";

import { AnsibleTaskData, SEARCH_ANSIBLE_TASKS } from "./gql";
import { createAnsibleNodesByTasks } from "./utils";
import { LayoutMode } from "./types";

const useSearchAnsibleTasks = (
  input: {
    first: number;
    after: null;
    requestedPage: null;
    fullTextSearch: string;
    predicates: SearchQueryPredicate[];
    orderBy: null;
  },
  layoutMode: LayoutMode,
  skip?: boolean
) => {
  const { onError } = useTypedContext(FlashContext);
  const cachedAnsibleTasksEdges = useRef<AnsibleTaskData[]>([]);
  const location = useLocation();

  const {
    data: tasksData,
    loading: tasksLoading,
    fetchMore,
    networkStatus: tasksNetworkStatus,
    error: tasksError,
  } = useQuery(SEARCH_ANSIBLE_TASKS, {
    variables: { input },
    onError,
    skip,
  });

  const loadMoreItems = useCallback(async () => {
    try {
      if (
        tasksData?.searchAnsibleTasks?.pageInfo.endCursor &&
        tasksData?.searchAnsibleTasks?.pageInfo.hasNextPage
      ) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            const previousData = fetchMoreUpdateQueryPreviousFix(
              !!prev?.searchAnsibleTasks,
              prev,
              tasksData
            );

            if (
              fetchMoreResult &&
              fetchMoreResult?.searchAnsibleTasks?.edges?.length &&
              fetchMoreResult?.searchAnsibleTasks?.edges?.length > 0
            ) {
              return {
                ...fetchMoreResult,
                searchAnsibleTasks: {
                  ...fetchMoreResult.searchAnsibleTasks,
                  edges: uniqByKey(
                    [
                      ...(prev.searchAnsibleTasks!.edges || []),
                      ...fetchMoreResult.searchAnsibleTasks.edges,
                    ],
                    "cursor"
                  ),
                },
              };
            }

            // TODO: [Ansible v2] remove when backend fix search for last bunch of items
            if (fetchMoreResult.searchAnsibleTasks?.pageInfo.hasNextPage === false) {
              return {
                searchAnsibleTasks: {
                  edges: previousData.searchAnsibleTasks?.edges || [],
                  pageInfo: {
                    ...previousData.searchAnsibleTasks?.pageInfo,
                    ...fetchMoreResult.searchAnsibleTasks?.pageInfo,
                  },
                },
              };
            }

            return previousData;
          },
          variables: {
            input: {
              first: input.first,
              after: tasksData.searchAnsibleTasks.pageInfo.endCursor,
              fullTextSearch: input.fullTextSearch,
              predicates: input.predicates,
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  }, [fetchMore, tasksData, input.fullTextSearch, input.predicates, input.first, onError]);

  const ansibleTasks = useMemo(() => {
    const sourceEdges = tasksData?.searchAnsibleTasks?.edges.map((edge) => edge.node) || [];
    const edges =
      tasksLoading && !sourceEdges.length ? cachedAnsibleTasksEdges.current : sourceEdges;

    if (!tasksLoading) {
      cachedAnsibleTasksEdges.current = sourceEdges;
    }

    return edges;
  }, [tasksData?.searchAnsibleTasks?.edges, tasksLoading]);

  const nodes = useMemo(() => {
    const isChart = layoutMode === LayoutMode.Diagram;

    return createAnsibleNodesByTasks(ansibleTasks, isChart, location.pathname);
  }, [ansibleTasks, layoutMode, location.pathname]);

  const isTasksPageLoading =
    tasksLoading && !ansibleTasks.length && tasksNetworkStatus === NetworkStatus.loading;

  const response = useMemo(() => {
    return {
      hasNextPageToLoad: tasksData?.searchAnsibleTasks?.pageInfo.hasNextPage,
      error: tasksError,
      loading: tasksLoading,
      isPageLoading: isTasksPageLoading,
      isPageEmpty: !!(
        tasksData &&
        !ansibleTasks.length &&
        !input.fullTextSearch &&
        input.predicates.length === 0
      ),
      hasNoFilteringResults:
        !!tasksData &&
        !ansibleTasks.length &&
        (!!input.fullTextSearch || input.predicates.length > 0),
    };
  }, [
    ansibleTasks.length,
    input.fullTextSearch,
    input.predicates.length,
    isTasksPageLoading,
    tasksData,
    tasksError,
    tasksLoading,
  ]);

  return {
    nodes,
    predicates: input.predicates,
    loadMoreItems,
    ...response,
  };
};

export default useSearchAnsibleTasks;
