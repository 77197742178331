import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import Link from "ds/components/Link";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import { PERSONAL_SETTINGS_ADMIN_INFO_KEY } from "./constants";

type PersonalSettingsSpacesPageLayoutProps = {
  children: ReactNode;
};

const PersonalSettingsSpacesPageLayout = ({ children }: PersonalSettingsSpacesPageLayoutProps) => {
  const { accountName, viewer } = useTypedContext(AccountContext);

  useTitle(`Personal Settings · Spaces · ${accountName}`);

  const trackAnalyticsSegmentEvent = useAnalytics({
    page: AnalyticsPagePersonal.PersonalSpaces,
  });

  return (
    <>
      {viewer.admin && (
        <Callout
          variant="info"
          title="Invite and manage access"
          storageKey={PERSONAL_SETTINGS_ADMIN_INFO_KEY}
          closeCallback={() => trackAnalyticsSegmentEvent("Organization Settings Callout Close")}
        >
          To invite new users and manage the access of your team members go to Organization
          settings.
          <FeedbackActions>
            <Link
              analyticsPage={AnalyticsPagePersonal.PersonalSpaces}
              analyticsTitle="Organization Settings Callout Click"
              href="/settings/users"
              size="small"
            >
              Organization settings
            </Link>
          </FeedbackActions>
        </Callout>
      )}
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Spaces</ViewHeaderTitle>
      </ViewHeader>
      {children}
    </>
  );
};

export default PersonalSettingsSpacesPageLayout;
