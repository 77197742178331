import { datadogRum } from "@datadog/browser-rum";
import isEqual from "lodash-es/isEqual";
import { useEffect, useId, useMemo, useState } from "react";

import { FiltersContext } from "components/Filters";
import { DEFAULT_VIEW_ID, DEFAULT_VIEW_NAME } from "components/Filters/constants";
import { FiltersApplyView } from "components/Filters/types";
import { useDefaultView } from "components/Filters/useDefaultView";
import { Spinner } from "components/Spinner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Icon from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";

import FiltersSavedViewsCreateForm from "../CreateForm";
import { showDeleteConfirmation } from "../DeleteConfirmation";
import FiltersSavedViewsUpdate from "../Update";
import FiltersSavedViewsUpdateNameForm from "../UpdateNameForm";
import { useSortOptions } from "../useSortOptions";
import FiltersSavedViewsPanelNotificationStatus from "./NotificationStatus";
import styles from "./styles.module.css";

enum Mode {
  Create = "create",
  UpdateName = "updateName",
}

type FiltersSavedViewsPanelProps = {
  applyView: FiltersApplyView;
};

const FiltersSavedViewsPanel = ({ applyView }: FiltersSavedViewsPanelProps) => {
  const [mode, setMode] = useState<undefined | Mode>();
  const {
    currentView,
    defaultEmptyFilterView,
    activeFilters,
    filtersOrder,
    filtersLoading,
    filterType,
  } = useTypedContext(FiltersContext);
  const newViewId = useId();
  const sortOptions = useSortOptions();

  const [forceTooltipRerenderKey, setForceTooltipRerenderKey] = useState(1);

  useEffect(() => {
    setForceTooltipRerenderKey((prev) => prev + 1);
  }, [currentView?.name]);

  const defaultView = useDefaultView(filtersOrder, filterType, defaultEmptyFilterView);

  const canCreate = true;

  const refreshView = () => {
    if (defaultView.data?.id === currentView?.id) {
      defaultView.removeFromStorage();

      applyView("", DEFAULT_VIEW_NAME, DEFAULT_VIEW_ID, false, true);
    } else {
      applyView(
        defaultView.rawData,
        defaultView.data?.name || DEFAULT_VIEW_NAME,
        defaultView.data?.id || DEFAULT_VIEW_ID,
        false,
        true
      );
    }
  };

  const resetMode = () => setMode(undefined);

  const toggleCreateForm = () => {
    datadogRum.addAction("[Saved Views] Click New View");

    setMode(mode === Mode.Create ? undefined : Mode.Create);
  };

  const handleEditName = () => {
    datadogRum.addAction("[Saved Views] Click Edit Name");

    setMode(Mode.UpdateName);
  };

  const onDeleteClick = () => {
    datadogRum.addAction("[Saved Views] Click Delete");

    if (currentView?.id) {
      showDeleteConfirmation({
        id: currentView.id,
        name: currentView.name,
      }).then(() => {
        refreshView();
      });
    }
  };

  const isCurrentViewOutdated = useMemo(() => {
    if (currentView) {
      if (
        !isEqual(currentView.filters, activeFilters) ||
        !isEqual(currentView.order, filtersOrder) ||
        !isEqual(currentView.sortOption, sortOptions.sort.option) ||
        !isEqual(currentView.sortDirection, sortOptions.sort.direction) ||
        !isEqual(currentView.search, sortOptions.text && decodeURI(sortOptions.text))
      ) {
        return true;
      }
    }

    return false;
  }, [currentView, activeFilters, filtersOrder, sortOptions]);

  if (filtersLoading && !currentView) {
    return (
      <div className={styles.panelHeaderWrapper}>
        <Icon src={Spinner} size="large" />
      </div>
    );
  }

  return (
    <Box direction="column" className={styles.panelWrapper}>
      <Box
        justify="between"
        align="center"
        __deprecatedGap="1rem"
        className={styles.panelHeaderWrapper}
      >
        <TextEllipsis
          tooltip={currentView?.name || DEFAULT_VIEW_NAME}
          key={forceTooltipRerenderKey}
          tooltipWidthMode="maxWidthSm"
        >
          {(props) => (
            <Typography tag="p" variant="p-t6" {...props}>
              {currentView?.name || DEFAULT_VIEW_NAME}
            </Typography>
          )}
        </TextEllipsis>
        <Box __deprecatedGap="1rem" justify="end" className={styles.buttons}>
          <Button
            size="small"
            variant={"secondary"}
            type="submit"
            disabled={!canCreate}
            onPress={toggleCreateForm}
            active={mode === Mode.Create}
            aria-label={mode === Mode.Create ? "Dismiss new view" : undefined}
            aria-controls={mode === Mode.Create ? newViewId : undefined}
            aria-expanded={mode === Mode.Create}
          >
            New view
          </Button>
          <FiltersSavedViewsPanelNotificationStatus isVisible={isCurrentViewOutdated}>
            <DropdownMenuEllipsis
              dotsSize="small"
              tooltip={currentView?.isMine ? "Manage view" : "Only admin can manage shared view"}
              disabled={!currentView?.isMine}
              onPress={() => datadogRum.addAction("[Saved Views] Click Manage view")}
            >
              {currentView?.isMine ? (
                <>
                  <FiltersSavedViewsUpdate isMarked={isCurrentViewOutdated} />
                  <DropdownMenuItem onAction={handleEditName}>Edit name</DropdownMenuItem>
                  {currentView?.id && currentView?.id !== DEFAULT_VIEW_ID && (
                    <DropdownMenuItem onAction={onDeleteClick} danger>
                      Delete
                    </DropdownMenuItem>
                  )}
                </>
              ) : null}
            </DropdownMenuEllipsis>
          </FiltersSavedViewsPanelNotificationStatus>
        </Box>
      </Box>

      {mode === Mode.UpdateName && currentView?.isMine && (
        <FiltersSavedViewsUpdateNameForm wrapperId={newViewId} closeForm={resetMode} />
      )}
      {mode === Mode.Create && <FiltersSavedViewsCreateForm closeForm={resetMode} />}
      <div className={styles.divider} />
    </Box>
  );
};

export default FiltersSavedViewsPanel;
