import { useQuery } from "@apollo/client";
import { useState } from "react";

import AttachedContext from "components/AttachedContext";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import EnvironmentEditMode from "components/Environment/edit/EditMode";
import EnvironmentViewMode from "components/Environment/view/ViewMode";
import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import Button from "ds/components/Button";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import { STACK_TYPE } from "views/shared/constants";

import StackHeader from "../components/Header";
import { StackContext } from "../Context";
import { getStacksBackUrl } from "../helpers";
import { GET_ENVIRONMENT } from "./gql";

const StackEnvironment = () => {
  const { onError } = useTypedContext(FlashContext);
  const { stack: stackCtx } = useTypedContext(StackContext);
  const [editing, setEditing] = useState(false);
  const toggleEdit = () => setEditing(!editing);

  const { error, loading, data } = useQuery<{ stack: Stack }>(GET_ENVIRONMENT, {
    onError,
    variables: { stackId: stackCtx.id },
  });

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stackCtx.name,
    },
  ]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  if (!data?.stack) {
    return <NotFoundPage />;
  }

  const stack = { ...stackCtx, ...data?.stack };

  return (
    <>
      <StackHeader />
      <PageInfo title="Environment">
        {stack.canWrite && (
          <Button onPress={toggleEdit} variant={editing ? "primary" : "secondary"}>
            {editing ? "Done" : "Edit"}
          </Button>
        )}
      </PageInfo>
      <PageWrapper>
        <div className="environment">
          <div className="environment-runtime">
            {editing ? (
              <EnvironmentEditMode stack={stack} type={STACK_TYPE} />
            ) : (
              <EnvironmentViewMode stack={stack} />
            )}
          </div>
          <h4 className="environment__attached-contexts">Attached contexts</h4>
          {stack.attachedContexts.map((attachment) => (
            <AttachedContext key={attachment.id} {...attachment} />
          ))}
        </div>
      </PageWrapper>
    </>
  );
};

export default StackEnvironment;
