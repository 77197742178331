import CopyFieldIcon from "components/CopyField/Icon";
import Box from "ds/components/Box";
import FormField, { FormFieldProps } from "ds/components/Form/Field";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type FormFieldViewCodeProps = {
  code: string;
  maxHeight?: string;
} & Pick<
  FormFieldProps,
  "label" | "tooltipInfo" | "tooltipInfoVariant" | "noMargin" | "onCopyCallback"
>;

const FormFieldViewCode = ({
  code,
  label,
  tooltipInfo,
  tooltipInfoVariant,
  noMargin,
  maxHeight,
}: FormFieldViewCodeProps) => {
  return (
    <FormField
      label={label}
      tooltipInfo={tooltipInfo}
      tooltipInfoVariant={tooltipInfoVariant}
      noMargin={noMargin}
    >
      <CopyFieldIcon className={styles.codeCopyButton} value={code} title="Copy" />
      <Box className={styles.codeWrapper} style={{ maxHeight }}>
        <Typography variant="s-body3" tag="code">
          <pre>{code}</pre>
        </Typography>
      </Box>
    </FormField>
  );
};

export default FormFieldViewCode;
