import { useEffect, useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import ComboBoxMultiple from "components/ComboBoxMultiple";
import FormFieldSpace from "components/FormFields/Space";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import AssignRoleDrawerComboBoxItem from "./ComboBoxItem";
import styles from "./styles.module.css";
import { USER_ROLE_BINDING_CREATE } from "./gql";
import { CREATE_FORM_ID } from "./constants";

type AssignRoleFormFields = {
  roleIds: string[];
  space: string;
};

type AssignRoleDrawerFormAddProps = {
  availableRoles: Array<{ id: string; name: string }>;
  setIsDirty: (id: string, isDirty: boolean) => void;
  isLoading?: boolean;
  userId: string;
};

const AssignRoleDrawerFormAdd = ({
  availableRoles,
  setIsDirty,
  isLoading,
  userId,
}: AssignRoleDrawerFormAddProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const assignRoleForm = useForm<AssignRoleFormFields>({
    defaultValues: {
      roleIds: [],
      space: undefined,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = assignRoleForm;

  const [createRoleBinding] = useMutation(USER_ROLE_BINDING_CREATE, {
    refetchQueries: ["UserRoleBindingsWithRoles"],
    awaitRefetchQueries: true,
  });

  const onSubmit: SubmitHandler<AssignRoleFormFields> = (formData) => {
    Promise.all(
      formData.roleIds.map((roleId) => {
        createRoleBinding({
          variables: {
            input: {
              roleID: roleId,
              spaceID: formData.space,
              userID: userId,
            },
          },
        });
      })
    )
      .then(() => {
        reportSuccess({ message: "Roles successfully assigned" });
      })
      .catch(onError);

    reset();
  };

  const options = useMemo(
    () => availableRoles.map(({ id, name }) => ({ value: id, label: name })),
    [availableRoles]
  );

  // TODO consider add a not existing space option to the list of spaces in case editing an output reference with not existing output
  useEffect(() => {
    setIsDirty(CREATE_FORM_ID, isDirty);
  }, [isDirty, setIsDirty]);

  return (
    <FormProvider {...assignRoleForm}>
      <Box direction="column" padding="x-large" gap="x-large" className={styles.formWrapper}>
        <Controller
          name="roleIds"
          control={control}
          rules={{ required: "Role field is required." }}
          render={({ field, fieldState }) => (
            <ComboBoxMultiple
              isLoading={isLoading}
              items={options}
              label="Select role"
              error={fieldState.error?.message}
              values={field.value}
              onChange={(role) => {
                if (role && field.value.includes(role)) {
                  field.onChange(field.value.filter((r) => r !== role));
                } else {
                  field.onChange([...field.value, role]);
                }
              }}
            >
              {(item) => <AssignRoleDrawerComboBoxItem checkboxVisible id={item.value} {...item} />}
            </ComboBoxMultiple>
          )}
        />
        <FormFieldSpace label="Space" noMargin />

        <Box direction="row" justify="end" gap="medium">
          <Button variant="contrast" size="small" onPress={() => handleSubmit(onSubmit)()}>
            Add
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default AssignRoleDrawerFormAdd;
