import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Cell, Row, SortDescriptor } from "react-aria-components";

import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import { getSearchQuery } from "components/SearchInput/helpers";
import { SavedFilterView } from "components/Filters/types";
import TableContextProvider from "components/Table/Context";
import Table from "components/Table";
import TableRow from "components/Table/Row";
import { URL_FILTER_TAB_KEY } from "constants/url_query_keys";
import usePagination from "components/Table/Pagination/usePagination";
import TablePagination from "components/Table/Pagination";
import usePrevious from "hooks/usePrevious";
import { Stack } from "types/generated";
import useTypedFlags from "hooks/useTypedFlags";
import { PAGINATION_OPTIONS } from "components/Table/Pagination/constants";
import useTableSort from "components/Table/useTableSort";
import TableWrapper from "components/Table/Wrapper";
import useShiftSelectionManager from "components/BulkActions/useShiftSelectionManager";

import StackListItem from "./ListItem";
import StacksPageLayout from "./PageLayout";
import StacksEmpty from "./Empty";
import FiltersLayout from "./FiltersLayout";
import {
  ROW_HEADER_ID,
  STACK_COLUMNS_STORAGE_KEY,
  STACKS_CUSTOM_END_COLUMN_CONFIG,
  STACKS_LIMIT,
  // SELECT_ALL_OPTIONS,
  STACK_LIMIT_STORAGE_KEY,
  ANALYTICS,
  FILTERS_ORDER_SETTINGS_KEY,
  SORT_CONFIG,
  initialSortDirection,
  initialSortOption,
} from "./constants";
import StacksBulkActions from "./BulkActions";
import useStacks from "./useStacks";
import useSelectionStore from "../../../components/Table/useSelectionStore";
import useAllAvailableStacks from "./useAllAvailableStacks";
import { useInitialColumnsViewConfig } from "./useInitialColumnsViewConfig";
import { useStacksColumnConfig } from "./useStacksColumnConfig";
import useStackListAnalytics from "./useStackListAnalytics";

const Stacks = () => {
  const [renderTableKey, setRenderTableKey] = useState(1);
  const onConfigReset = useCallback(() => setRenderTableKey((old) => old + 1), []);
  const { stackListIncreasedLimit } = useTypedFlags();
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);

  const stacksColumnConfig = useStacksColumnConfig();
  const initialColumnsViewConfig = useInitialColumnsViewConfig();

  const paginationOptions = useMemo(() => {
    if (stackListIncreasedLimit) {
      return [...PAGINATION_OPTIONS, 100];
    }

    return PAGINATION_OPTIONS;
  }, [stackListIncreasedLimit]);

  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const searchInput = getSearchQuery(searchParams);

  const {
    allAvailableStacks,
    loading: allStacksLoading,
    setAllAvailableStacks,
    fetchAll,
  } = useAllAvailableStacks();

  const {
    initialLoading,
    error,
    stacks,
    loadStacks,
    endCursor,
    startCursor,
    noResults,
    isEmpty,
    loadingContent,
    predicates,
    refetch,
  } = useStacks(setAllAvailableStacks);

  const { selectIds, resetAllSelected, selected, unselect, resetStore } = useSelectionStore();

  const [itemsCount, setItemsCount] = useState<number>(0);

  const paginationAnalyticsProps = useMemo(
    () => ({
      rowTotalCount: itemsCount,
    }),
    [itemsCount]
  );

  const {
    rowsPerPage,
    setRowsPerPage,
    from,
    to,
    lastPage,
    page,
    goToNextPage,
    goToPrevPage,
    goToFirstPage,
    goToLastPage,
    resetToInitialPage: resetPaginationToInitialPage,
  } = usePagination({
    ...ANALYTICS,
    analyticsProps: paginationAnalyticsProps,
    fetchItems: loadStacks,
    endCursor,
    startCursor,
    items: stacks,
    initialLimit: STACKS_LIMIT,
    itemsCount,
    storageKey: STACK_LIMIT_STORAGE_KEY,
  });

  const trackAnalytics = useStackListAnalytics();

  const handleSortCallback = useCallback(
    (descriptor: SortDescriptor) => {
      trackAnalytics("Sort by column", { ...descriptor });
      resetPaginationToInitialPage();
    },
    [trackAnalytics, resetPaginationToInitialPage]
  );

  const { handleSortOptionChange, sortDescriptor } = useTableSort({
    filtersOrderSettingsKey: FILTERS_ORDER_SETTINGS_KEY,
    sortConfig: SORT_CONFIG,
    initialSortOption,
    initialSortDirection,
    callback: handleSortCallback,
  });

  const predefinedFilterTab = searchParams.get(URL_FILTER_TAB_KEY);
  const previousPredefinedFilterTab = usePrevious(predefinedFilterTab);

  // reset pagination and selected when filter changed
  useEffect(() => {
    if (previousPredefinedFilterTab !== predefinedFilterTab) {
      resetPaginationToInitialPage();
      resetAllSelected();
    }
  }, [
    resetPaginationToInitialPage,
    previousPredefinedFilterTab,
    predefinedFilterTab,
    resetAllSelected,
  ]);

  useEffect(() => {
    if (searchParams.size === 0 && page !== 1) {
      goToFirstPage();
    }
  }, [searchParams, page, goToFirstPage]);

  // reset store when unmounted
  useEffect(() => resetStore, [resetStore]);

  const memoizedSelectedStacksMap = useMemo(
    () =>
      new Map(
        Object.keys(allAvailableStacks).length
          ? Object.entries(allAvailableStacks)
          : stacks.map((edge) => [edge.id, edge])
      ),
    [stacks, allAvailableStacks]
  );

  const handleBulkActionsFinish = useCallback(async () => {
    refetch();
    fetchAll();
  }, [refetch, fetchAll]);

  const onBulkContinueWith = useCallback(
    (set: Set<string>) => {
      selectIds(Array.from(set));
    },
    [selectIds]
  );

  const onBulkResetAll = useCallback(() => {
    resetAllSelected();
  }, [resetAllSelected]);

  const getSelectedIds = useShiftSelectionManager({ collection: stacks });

  const renderRow = useCallback(
    (
      item: Partial<Stack> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.name}
            getSelectedIds={getSelectedIds}
          >
            <StackListItem stack={item as Stack} />
          </TableRow>
        );
      }
    },
    [getSelectedIds]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (initialLoading) {
    return (
      <StacksPageLayout>
        <PageLayoutSkeleton />
      </StacksPageLayout>
    );
  }

  if (noResults) {
    return <NotFoundPage />;
  }

  return (
    <TableContextProvider
      selectable
      columnsConfig={stacksColumnConfig}
      endCustomColumns={STACKS_CUSTOM_END_COLUMN_CONFIG}
      initialColumnViewConfig={initialColumnsViewConfig}
      rowHeaderId={ROW_HEADER_ID}
      localStorageId={STACK_COLUMNS_STORAGE_KEY}
      setSortDescriptor={handleSortOptionChange}
      sortDescriptor={sortDescriptor}
      {...ANALYTICS}
    >
      <StacksPageLayout searchCallback={resetPaginationToInitialPage} onConfigReset={onConfigReset}>
        <FiltersLayout
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
          predicates={predicates}
          currentSavedView={currentSavedView}
          setCurrentSavedView={setCurrentSavedView}
          applyFilterCallback={resetPaginationToInitialPage}
        >
          {!!stacks.length && (
            <TableWrapper
              virtualizedListContainerRef={virtualizedListContainerRef}
              paginationSlot={
                <TablePagination
                  from={from}
                  to={to}
                  loading={loadingContent}
                  goToNextPage={goToNextPage}
                  setRowsPerPage={setRowsPerPage}
                  goToPrevPage={goToPrevPage}
                  goToFirstPage={goToFirstPage}
                  goToLastPage={goToLastPage}
                  rowsPerPage={rowsPerPage}
                  itemsCount={itemsCount}
                  lastPage={lastPage}
                  page={page}
                  paginationOptions={paginationOptions}
                />
              }
            >
              <Table
                key={renderTableKey}
                ariaLabel="Stacks list"
                items={stacks}
                loadingContent={loadingContent}
                ref={virtualizedListContainerRef}
                // totalSize={virtualizer.getTotalSize()}
                // TODO: [stack list v2] enable in the next iteration
                // selectAllOptions={SELECT_ALL_OPTIONS}
              >
                {renderRow}
              </Table>

              {(!loadingContent || selected.size > 0 || allStacksLoading) && (
                <StacksBulkActions
                  virtualizedListContainerRef={virtualizedListContainerRef}
                  selectedSet={selected}
                  stacksMap={memoizedSelectedStacksMap}
                  onBulkResetAll={onBulkResetAll}
                  onBulkContinueWith={onBulkContinueWith}
                  onItemDismiss={unselect}
                  onFinish={handleBulkActionsFinish}
                  isLoadingItems={allStacksLoading}
                />
              )}
            </TableWrapper>
          )}
          {isEmpty && <StacksEmpty hasNoResults={!!searchInput || predicates.length > 0} />}
        </FiltersLayout>
      </StacksPageLayout>
    </TableContextProvider>
  );
};

export default Stacks;
