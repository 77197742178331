import { External } from "components/icons/generated";
import Link from "ds/components/Link";
import { generateBranchUrl } from "utils/urls";

import useStackListAnalytics from "../useStackListAnalytics";
import { CellComponentProps } from "./types";

type StackListItemBranchCellProps = CellComponentProps;

const StackListItemBranchCell = ({ stack }: StackListItemBranchCellProps) => {
  const trackAnalytics = useStackListAnalytics();

  if (!stack.apiHost) {
    return null;
  }

  return (
    <Link
      size="small"
      target="_blank"
      href={generateBranchUrl({
        apiHost: stack.apiHost,
        namespace: stack.namespace,
        repository: stack.repository,
        repositoryURL: stack.repositoryURL || "",
        provider: stack.provider,
        branch: stack.branch,
      })}
      onPress={() => trackAnalytics("Branch link clicked")}
      endIcon={External}
    >
      {stack.branch}
    </Link>
  );
};

export default StackListItemBranchCell;
