import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { GET_USER_DETAILS } from "./gql";

type UseGetUserProps = {
  id?: string;
};

const useGetUser = ({ id }: UseGetUserProps) => {
  const { onError } = useTypedContext(FlashContext);
  const { data, loading, error } = useQuery(GET_USER_DETAILS, {
    onError,
    variables: {
      id: id!,
    },
    skip: !id,
  });

  return { user: data?.managedUser, loading, error };
};

export default useGetUser;
