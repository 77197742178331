import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";

import { ROLE_UPDATE } from "../Edit/gql";
import { Permission } from "../types";

type DeleteConfirmationProps = {
  permission: Permission;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  permission,
}: DeleteConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [updateRole, { loading, data }] = useMutation(ROLE_UPDATE, { refetchQueries: ["GetRole"] });

  const onDelete = () => {
    updateRole({
      variables: {
        id: permission.parentRole.id,
        input: {
          name: permission.parentRole.name,
          description: permission.parentRole.description,
          // TODO: update actions to lowercase when BE provide improvement
          Actions: permission.parentRole.actions.filter((id) => id !== permission.id),
        },
      },
    })
      .then(() => {
        reportSuccess({ message: `Permission ${permission.name} successfully deleted` });
        modal.resolve();
        modal.hide();
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete permission"
      name={permission.name}
      onConfirm={onDelete}
      isLoading={loading}
      isDismissable={!loading && !data?.roleUpdate?.id}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
