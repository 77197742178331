import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageCloudIntegrations } from "hooks/useAnalytics/pages/cloudIntegrations";

const useCloudIntegrationsUsedByAnalytics = () => {
  return useAnalytics({
    page: AnalyticsPageCloudIntegrations.CloudIntegrationUsedBy,
  });
};

export default useCloudIntegrationsUsedByAnalytics;
