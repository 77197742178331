import { useCallback, useEffect, useState } from "react";
import { SortDescriptor } from "react-aria-components";
import { useSearchParams } from "react-router-dom";

import { SearchQueryOrderDirection } from "types/generated";
import { getSessionStorageKeys } from "components/Filters/helpers";
import { decodeURIParam } from "utils/urls";
import { URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";

type SortConfig = Record<string, string>;

type UseSortParams = {
  filtersOrderSettingsKey: string;
  sortConfig: SortConfig;
  initialSortOption: string;
  initialSortDirection: SearchQueryOrderDirection;
  callback?: (descriptor: SortDescriptor) => void;
};

const useTableSort = ({
  filtersOrderSettingsKey,
  sortConfig,
  initialSortOption,
  initialSortDirection,
  callback,
}: UseSortParams) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "symbol",
    direction: "ascending",
  });

  const storage = sessionStorage;

  const { storageUrlSortDirection, storageUrlSortKey } =
    getSessionStorageKeys(filtersOrderSettingsKey);

  useEffect(() => {
    const querySortOption = decodeURIParam(searchParams.get(URL_SORT_KEY)) || initialSortOption;
    const querySortDirection = (decodeURIParam(searchParams.get(URL_SORT_DIRECTION)) ||
      initialSortDirection) as SearchQueryOrderDirection;

    const convertedSortConfig = Object.entries(sortConfig).reduce(
      (prev, [key, value]) => ({ ...prev, [value]: key }),
      {} as Record<string, string>
    );

    const column = querySortOption ? convertedSortConfig[querySortOption] : "";
    const direction = querySortDirection === "ASC" ? "ascending" : "descending";

    if (sortDescriptor.column !== column || sortDescriptor.direction !== direction) {
      setSortDescriptor({
        column,
        direction,
      });
    }
  }, [
    searchParams,
    sortDescriptor.column,
    sortDescriptor.direction,
    initialSortOption,
    initialSortDirection,
    sortConfig,
  ]);

  const handleSortOptionChange = useCallback(
    (descriptor: SortDescriptor) => {
      setSortDescriptor(descriptor);

      const column = descriptor.column ? sortConfig[descriptor.column as string] : "";
      const direction = descriptor.direction === "ascending" ? "ASC" : "DESC";

      searchParams.set(URL_SORT_KEY, encodeURIComponent(column));
      searchParams.set(URL_SORT_DIRECTION, encodeURIComponent(direction));
      storage.setItem(storageUrlSortKey, encodeURIComponent(column));
      storage.setItem(storageUrlSortDirection, encodeURIComponent(direction));

      setSearchParams(searchParams);
      callback?.(descriptor);
    },
    [
      searchParams,
      storageUrlSortDirection,
      setSearchParams,
      callback,
      storageUrlSortKey,
      storage,
      sortConfig,
    ]
  );

  return {
    handleSortOptionChange,
    sortDescriptor,
  };
};

export default useTableSort;
