import { memo } from "react";

import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import { RenderGroupRowProps } from "../../components/Table/types";

type IacManagementTableGroupRowCellActionsProps = Pick<
  RenderGroupRowProps,
  "row" | "stackViewMode"
>;

const IacManagementTableGroupRowCellActions = memo(
  ({ row, stackViewMode }: IacManagementTableGroupRowCellActionsProps) => {
    const isStackRow = row.__typename === "ManagedEntitiesGroupedByStacks";

    return (
      <IacManagementTableCell stickyRight>
        {!stackViewMode && isStackRow && (
          <DropdownMenuEllipsis tooltip="Group actions">
            <DropdownMenuItem href={`/stack/${row.id}/resources`}>View stack</DropdownMenuItem>
          </DropdownMenuEllipsis>
        )}
      </IacManagementTableCell>
    );
  }
);

IacManagementTableGroupRowCellActions.displayName = "IacManagementTableGroupRowCellActions";

export default IacManagementTableGroupRowCellActions;
