import { useForm } from "react-hook-form";
import { MouseEvent, useRef, useState } from "react";
import isEqual from "lodash-es/isEqual";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import BeforeAfterCommands from "components/BeforeAfterCommands";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Select from "ds/components/Select";
import Counter from "ds/components/Counter";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Banner from "ds/components/Banner";
import StackHooks from "components/DocumentationSnippets/StackHooks";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import createSelectOptionRef from "ds/components/Select/createSelectOptionRef";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { getDocsUrl } from "utils/getDocsUrl";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { useFormValidations } from "hooks/useFormValidations";

import NewStackFooter from "../Footer";
import { StackFormContext } from "../context";
import { HookCommands, HooksPhases, StackCreationWizardStep, StackHooksFormFields } from "../types";
import {
  adaptCommandsToFields,
  adaptFieldsToCommands,
  adaptFieldsToCompare,
} from "../hooksAdapters";
import { useValidation } from "./useValidation";
import { getTooltipAnalyticsProps } from "../utils";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";
import useStackCreationAnalyticsVersion from "../useStackCreationAnalyticsVersion";

const NewStackAddHooks = () => {
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();
  const [selectedPhase, setSelectedPhase] = useState<HooksPhases>(HooksPhases.Init);
  const { currentStep, updateStepData, formData } = useTypedContext(StackFormContext);
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const stepData = formData[StackCreationWizardStep.Hooks];
  const [hasUnsubmittedChanges, setHasUnsubmittedChanges] = useState(false);

  const [beforeValue, setBeforeValue] = useState("");
  const [afterValue, setAfterValue] = useState("");

  const onBeforeInputChange = (value: string) => {
    setBeforeValue(value);
    setHasUnsubmittedChanges?.(!!(value || afterValue));
  };

  const onAfterInputChange = (value: string) => {
    setAfterValue(value);
    setHasUnsubmittedChanges?.(!!(value || beforeValue));
  };

  useObserveForWarning(
    hasUnsubmittedChanges,
    <>
      You have not added the <strong>command</strong>. Do you want to continue without adding it?
    </>
  );

  // needed for save & continue button to detect changes, because data is stored in formData not in local state
  const initialStepData = useRef(stepData);

  const { bannerError, isWorkflowValidForChosenVendor, isStepOptional } = useValidation();

  const [commandsByPhase, setCommandsByPhase] = useState<Record<HooksPhases, HookCommands>>(
    adaptFieldsToCommands(stepData)
  );

  const builderForm = useForm<StackHooksFormFields>({
    defaultValues: {
      ...stepData,
    },
    mode: "onChange",
  });
  const runFormValidations = useFormValidations(builderForm);

  const processStepData = () => {
    return formData;
  };

  const isDataChanged = !isEqual(
    adaptFieldsToCompare(initialStepData.current),
    adaptFieldsToCompare(stepData)
  );

  const handleCommandsChange = (commands: HookCommands) => {
    setCommandsByPhase({ ...commandsByPhase, [selectedPhase]: commands });
    updateStepData(currentStep, { ...stepData, ...adaptCommandsToFields(commands, selectedPhase) });

    trackSegmentEvent("Hooks modified", {
      phase: selectedPhase,
      before: commands.before?.length,
      after: commands.after.length,
    });
  };

  const phasesOptions = Object.values(HooksPhases).map((phase) => ({
    label: phase,
    value: phase,
  }));

  const currentPhaseCommands = commandsByPhase[selectedPhase];

  const handlePhaseOnChange =
    (value: string, onChange: (value: string) => void, closeSelect?: () => void) =>
    (e?: MouseEvent) => {
      e?.stopPropagation();

      onChange(value);
      closeSelect?.();
    };

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Add hooks {isStepOptional && "(optional)"}
        </Typography>
        <Typography
          tag="p"
          variant="p-body2"
          align="center"
          color="secondary"
          margin="small 0 x-large 0"
        >
          Spacelift workflow can be customized by adding extra commands to be executed before and
          after each of the phases
        </Typography>

        <Box direction="column" gap="medium">
          {bannerError && <Banner variant="danger">{bannerError}</Banner>}

          <FormField
            label="Customize workflow"
            tooltipInfoVariant="modal"
            {...getTooltipAnalyticsProps("Add hooks", "Customize workflow", {
              version: analyticsVersion,
            })}
            tooltipInfo={
              <>
                <TooltipModalTitle>Customize workflow</TooltipModalTitle>
                <TooltipModalBody align="start">
                  <Typography tag="p" variant="p-body3">
                    Customize Spacelift run lifecycle by adding commands to run before and after
                    various execution phases.
                  </Typography>
                  {/** TODO: update docs */}
                  <ReadMoreDocsLink
                    docsUrl={getDocsUrl("/concepts/stack/stack-settings#customizing-workflow")}
                  />
                </TooltipModalBody>
              </>
            }
          >
            {({ ariaInputProps }) => (
              <Select
                value={selectedPhase}
                options={phasesOptions}
                onChange={setSelectedPhase}
                renderOption={({ label, value, onChange, closeSelect, checked }) => {
                  const commands = commandsByPhase[value];
                  const beforeCommandsCount = commands.before?.length ?? 0;
                  const afterCommandsCount = commands.after?.length ?? 0;
                  const totalCount = beforeCommandsCount + afterCommandsCount;
                  const counterVariant = isWorkflowValidForChosenVendor(value)
                    ? "default"
                    : "danger";

                  return (
                    <DropdownSectionItem
                      onClick={handlePhaseOnChange(value, onChange, closeSelect)}
                      key={label}
                      active={checked}
                      innerRef={createSelectOptionRef(checked)}
                    >
                      <Box justify="between" align="center" grow="1">
                        <Typography tag="span" variant="p-body2">
                          {label}
                        </Typography>
                        <Counter variant={counterVariant} count={totalCount} />
                      </Box>
                    </DropdownSectionItem>
                  );
                }}
                ariaInputProps={ariaInputProps}
              />
            )}
          </FormField>
          <BeforeAfterCommands
            onAfterInputChange={onAfterInputChange}
            onBeforeInputChange={onBeforeInputChange}
            commands={currentPhaseCommands}
            onChange={handleCommandsChange}
            // TODO update stack on add
          />
        </Box>
      </FullScreenModalBody>
      <NewStackFooter
        isDataChanged={isDataChanged}
        processStepData={processStepData}
        documentationLink={getDocsUrl("/concepts/stack/stack-settings#customizing-workflow")}
        documentationTitle="Add hooks"
        documentationBody={<StackHooks />}
        runFormValidations={runFormValidations}
      />
    </>
  );
};

export default NewStackAddHooks;
