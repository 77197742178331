import usePolledQuery from "apollo/usePolledQuery";
import FlashContext from "components/FlashMessages/FlashContext";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { PublicWorkerPool, WorkerPool } from "types/generated";
import { PollingIntervalGroups } from "apollo/constants";

import { GET_PUBLIC_WORKER_POOL } from "./gql";
import { WorkerPoolEntryWrapper } from "./Wrapper";

type WorkerPoolEntryProps = {
  workerPool: WorkerPool | null;
  stackId: string;
  runId: string;
  isRunPrioritized: boolean;
  canPrioritize: boolean;
  runQueryToRefetch: string;
};

const WorkerPoolEntry = ({ workerPool, ...rest }: WorkerPoolEntryProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { data } = usePolledQuery<{
    publicWorkerPool: PublicWorkerPool;
  }>(GET_PUBLIC_WORKER_POOL, {
    onError,
    pollingGroup: PollingIntervalGroups.SingleEntities,
    skip: !!workerPool,
    // APOLLO CLIENT UPDATE
  });

  if (!workerPool && !data?.publicWorkerPool) {
    return null;
  }

  if (workerPool && workerPool.workers) {
    // PRIVATE WORKERS
    if (
      workerPool.workers.length === 0 ||
      workerPool.busyWorkers > workerPool.workers.length ||
      workerPool.workers.every((item) => item.busy || item.drained)
    ) {
      const label = (
        <Typography tag="span" variant="p-body3" color="secondary">
          The worker pool{" "}
          <Link href={`/worker-pool/${workerPool.id}`} size="small">
            {workerPool.name}
          </Link>{" "}
          linked to this stack has no available workers.
        </Typography>
      );

      return (
        <WorkerPoolEntryWrapper
          label={label}
          workers={workerPool.workers.length}
          busyWorkers={workerPool.busyWorkers}
          drainedWorkers={workerPool.workers.filter((item) => item.drained).length}
          pendingRuns={workerPool.pendingRuns}
          {...rest}
        />
      );
    }
  } else if (data?.publicWorkerPool) {
    // PUBLIC WORKERS
    if (
      data.publicWorkerPool.parallelism === 0 ||
      data.publicWorkerPool.busyWorkers > data.publicWorkerPool.parallelism
    ) {
      const label = (
        <Typography tag="span" variant="p-body3" color="secondary">
          You’re currently using all the public workers available to you.
        </Typography>
      );

      return (
        <WorkerPoolEntryWrapper
          label={label}
          workers={data.publicWorkerPool.parallelism}
          busyWorkers={data.publicWorkerPool.busyWorkers}
          pendingRuns={data.publicWorkerPool.pendingRuns}
          {...rest}
        />
      );
    }
  }

  return null;
};

export default WorkerPoolEntry;
