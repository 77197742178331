import IaCManagementTableHeadColumn from "../../components/Table/Head/Column";
import { RenderHeadRowProps } from "../../components/Table/types";
import IacManagementTableSettings from "../../components/TableSettings";
import { IAC_MANAGEMENTS_TABLE_COLUMNS } from "../types";

type IacManagementTableHeadRowProps = RenderHeadRowProps;

const IacManagementTableHeadRow = ({ columns }: IacManagementTableHeadRowProps) => {
  return columns.map((column) => {
    return (
      <IaCManagementTableHeadColumn
        key={`thead-column-${column.id}`}
        column={column}
        stickyLeft={column.id === IAC_MANAGEMENTS_TABLE_COLUMNS.NAME}
        stickyRight={column.id === IAC_MANAGEMENTS_TABLE_COLUMNS.ACTIONS}
      >
        {column.id === IAC_MANAGEMENTS_TABLE_COLUMNS.ACTIONS && <IacManagementTableSettings />}
      </IaCManagementTableHeadColumn>
    );
  });
};

export default IacManagementTableHeadRow;
