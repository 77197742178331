import { useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import DocumentationIconButton from "components/DocumentationIconButton";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import FullScreenModal from "ds/components/FullScreenModal";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Input from "ds/components/Input";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";
import Textarea from "ds/components/Textarea";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTypedContext from "hooks/useTypedContext";
import { getDocsUrl } from "utils/getDocsUrl";
import { SpacesContext } from "views/Account/SpacesProvider";

import { CONFIG, DEFAULT_VALUES } from "./constants";
import RoleFullScreenFormSection from "./Section";
import { getTooltipAnalyticsProps, validateRequired } from "./utils";
import { FormValues } from "./types";

type RoleFullScreenFormProps = {
  title: string;
  leaveTitle: string;
  analyticsPage: AnalyticsPageOrganization;
  saveButtonTitle: string;
  isFormDataLoading?: boolean;
  submitLoading?: boolean;
  onSubmit: SubmitHandler<FormValues>;
  initialValues?: FormValues;
};

const RoleFullScreenForm = ({
  title,
  analyticsPage,
  saveButtonTitle,
  isFormDataLoading,
  leaveTitle,
  submitLoading,
  onSubmit,
  initialValues,
}: RoleFullScreenFormProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  // TODO: move it out to Edit component and add functionality to fetch current role
  const roleId = searchParams.get("roleId");

  const [AccountOrAuthErrorContent] = useState<JSX.Element | undefined>();

  const form = useForm({
    defaultValues: initialValues || DEFAULT_VALUES,
    mode: "onChange",
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
  } = form;

  const modalCloseClickHandler = useCallback(async () => {
    if (isDirty) {
      await showSimpleLeaveConfirmation({
        title: leaveTitle,
        message: roleId
          ? "Some of your changes may not be saved."
          : "Your changes will not be saved.",
      });
    }

    if (roleId) {
      navigate(`/settings/role/${roleId}`, { replace: true });
    } else {
      navigate("/settings/roles");
    }
  }, [isDirty, roleId, navigate, leaveTitle]);

  // TODO: async name validation
  // const handleNameChange = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const value = event.target.value;

  //     // if (value.length > 0) {
  //     //   validateName(value);
  //     // }

  //     setValue("name", value);
  //     trigger("name");
  //   },
  //   [setValue, trigger]
  // );

  useEffect(() => {
    if (initialValues) {
      setValue("name", initialValues.name);
      setValue("description", initialValues.description);
      setValue("permissions", initialValues.permissions);
    }
  }, [initialValues, setValue]);

  const handleFormSubmit = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit, handleSubmit]);

  if (AccountOrAuthErrorContent) {
    return AccountOrAuthErrorContent;
  }

  if (!hasEntityCreateAccess) {
    return <NotFoundPage />;
  }

  return (
    <FullScreenModal title={title} onClose={modalCloseClickHandler}>
      <FormProvider {...form}>
        <Box grow="1" fullWidth scrollable>
          <Box fullWidth direction="column" relative>
            {isFormDataLoading ? (
              <PageLoading />
            ) : (
              <FullScreenModalBody>
                <Controller
                  name="name"
                  control={control}
                  rules={{ validate: validateRequired("Name") }}
                  render={({ field, fieldState }) => (
                    <FormField
                      label="Name"
                      error={errors?.name?.message}
                      tooltipInfoVariant="modal"
                      {...getTooltipAnalyticsProps("Details", "Name", analyticsPage)}
                      tooltipInfo={
                        <>
                          <TooltipModalTitle>Name your role</TooltipModalTitle>
                          <TooltipModalBody align="start">
                            Name of this role. It should be unique within one account
                          </TooltipModalBody>
                        </>
                      }
                    >
                      {({ ariaInputProps }) => (
                        <Input
                          placeholder="Name your stack"
                          ref={field.ref}
                          error={!!fieldState.error}
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          name="stackName"
                          {...ariaInputProps}
                        />
                      )}
                    </FormField>
                  )}
                />
                <FormField
                  label="Description"
                  isOptional
                  tooltipInfoVariant="modal"
                  {...getTooltipAnalyticsProps("Details", "Description", analyticsPage)}
                  tooltipInfo={
                    <>
                      <TooltipModalTitle>Describe your module</TooltipModalTitle>
                      <TooltipModalBody align="start">
                        Human-friendly description of this role - supports Markdown. This value is
                        only used to make life easier for you, the user. You can use this space to
                        describe your role, provide useful links, or embed a cat GIF. The only limit
                        is yourself.
                      </TooltipModalBody>
                    </>
                  }
                >
                  {({ ariaInputProps }) => (
                    <Textarea
                      placeholder="Enter module description here..."
                      {...register("description")}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
                <Box direction="column" padding="x-large 0 0 0">
                  <Box gap="large" direction="column" padding="x-large 0 0 0">
                    {CONFIG.map((section, i) => (
                      <RoleFullScreenFormSection key={i} {...section} />
                    ))}
                  </Box>
                </Box>
              </FullScreenModalBody>
            )}
            <FullScreenModalFooter justify="between">
              <Box gap="medium">
                <DocumentationIconButton
                  // TODO: update button link
                  href={getDocsUrl("/")}
                  tooltipText="Open documentation"
                  analyticsPage={analyticsPage}
                  analyticsTitle="Docs button click"
                />
              </Box>
              <Box direction="row" gap="medium">
                <Button
                  disabled={isFormDataLoading}
                  variant="secondary"
                  onPress={modalCloseClickHandler}
                >
                  Cancel
                </Button>

                <Button
                  variant="primary"
                  onPress={handleFormSubmit}
                  loading={submitLoading}
                  disabled={isFormDataLoading}
                >
                  {saveButtonTitle}
                </Button>
              </Box>
            </FullScreenModalFooter>
          </Box>
        </Box>
      </FormProvider>
    </FullScreenModal>
  );
};

export default RoleFullScreenForm;
