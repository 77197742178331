import { createSelector } from "reselect";

import { TableColumnMeta } from "ds/components/Table/types";

import { TableColumnsState } from "./types";

export const createSelectVisibleColumns = <Columns extends string>(
  columnConfig: Record<Columns, TableColumnMeta<Columns>>
) =>
  createSelector(
    (state: TableColumnsState<Columns>) => state.columnsOrder.visible,
    (visible): TableColumnMeta<Columns>[] => visible.map((id) => columnConfig[id])
  );
