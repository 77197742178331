import { useModal } from "@ebay/nice-modal-react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FormFieldSpaces from "components/FormFields/Spaces";
import FormFieldTags from "components/FormFields/Tags";
import { Diamond } from "components/icons/generated";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import BadgeNext from "ds/components/BadgeNext";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Select from "ds/components/Select";
import createSelectOptionRef from "ds/components/Select/createSelectOptionRef";
import Textarea from "ds/components/Textarea";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";
import useTypedContext from "hooks/useTypedContext";
import { ApiKeyType, BillingTier, BillingTierFeature } from "types/generated";
import { validateRegExp, validateURL } from "utils/formValidators";
import { getDocsUrl } from "utils/getDocsUrl";
import useTierFeature from "views/Account/hooks/useTierFeature";
import { getManagementStrategy } from "views/Account/Settings/helpers";
import { UserManagementActivationStatus } from "views/Account/Settings/types";
import { SubscriptionContext } from "views/Account/SubscriptionWrapper";
import { AccountContext } from "views/AccountWrapper";

import { API_KEY_CREATE_DRAWER_TEST_ID } from "./constants";
import { CreateApiKeyFields } from "./types";
import useCreateApiKey from "./useCreateApiKey";

type ApiKeysCreateDrawerProps = {
  activationStatus: UserManagementActivationStatus;
  refetchQueries?: string[];
  analyticsPage: AnalyticsPageOrganization | AnalyticsPagePersonal;
};

const ApiKeysCreateDrawer = createDrawer(
  ({ activationStatus, refetchQueries, analyticsPage }: ApiKeysCreateDrawerProps) => {
    const { viewer } = useTypedContext(AccountContext);
    const { tier } = useTypedContext(SubscriptionContext);

    const drawer = useModal();

    const isOidcApiKeyFeatureActive = useTierFeature(BillingTierFeature.ApiKeysOidc);

    const isWarningShown =
      tier === BillingTier.Cloud || tier === BillingTier.V3Cloud || tier === BillingTier.V4Starter;

    const trackSegmentAnalyticsEvent = useAnalytics({
      page: analyticsPage,
      defaultCallbackTrackProperties: {
        managementStrategy: getManagementStrategy(activationStatus),
      },
    });

    const groupAddForm = useForm<CreateApiKeyFields>({
      defaultValues: {
        description: "",
        name: "",
        spaces: [{ space: undefined, spaceAccessLevel: undefined }],
        groups: undefined,
        type: ApiKeyType.Secret,
        oidcClientId: "",
        oidcIssuer: "",
        oidcSubjectExpression: ".*",
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = groupAddForm;

    const handleCloseDrawer = () => {
      drawer.hide();
    };

    const { onCreate } = useCreateApiKey({
      refetchQueries,
      callback: handleCloseDrawer,
    });

    const handleSubmitClick = () => {
      handleSubmit(onSubmit)();
    };

    const onSubmit: SubmitHandler<CreateApiKeyFields> = (formFields) => {
      trackSegmentAnalyticsEvent("Create Confirm");
      const input = {
        admin: false, // TODO: legacy field
        accessRules: formFields.spaces.filter((space) => !!space.space && !!space.spaceAccessLevel),
        machine: null,
        name: formFields.name,
        teams: formFields.groups?.map((group) => group.value) || [],
        type: formFields.type,
        oidc:
          formFields.type === ApiKeyType.Oidc
            ? {
                clientId: formFields.oidcClientId,
                issuer: formFields.oidcIssuer,
                subjectExpression: formFields.oidcSubjectExpression.trim(),
              }
            : null,
        description: formFields.description.trim(),
      };
      onCreate(input);
    };

    const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;

    const type = watch("type");

    return (
      <DrawerSimple dataTestId={API_KEY_CREATE_DRAWER_TEST_ID}>
        <FormProvider {...groupAddForm}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Create API key" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight hasStickyFooter>
            {!isUserManagementActive && viewer.admin && (
              <Banner variant="warning" title="User management is inactive">
                Setting space and role to root and admin will create API key with account admin
                privileges. You can create different space access rules but they won't take effect
                until you switch to user management.
              </Banner>
            )}
            <FormField label="Name" error={errors?.name?.message}>
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter API key name"
                  error={!!errors?.name}
                  {...register("name", {
                    required: "Name field is required.",
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <Controller
              name="type"
              render={({ field, fieldState }) => (
                <FormField error={fieldState.error?.message} label="Type" fullWidth>
                  {({ ariaInputProps }) => (
                    <Box direction="column" gap="x-large">
                      <Select
                        placeholder="Type in or select type from the list"
                        value={field.value}
                        options={[
                          { label: "Secret", value: ApiKeyType.Secret },
                          {
                            label: "OIDC",
                            value: ApiKeyType.Oidc,
                            disabled: !isOidcApiKeyFeatureActive,
                            tooltip: isOidcApiKeyFeatureActive
                              ? undefined
                              : "Upgrade your plan to benefit from ability to use more secure OIDC-based API keys.",
                          },
                        ]}
                        onChange={field.onChange}
                        error={!!fieldState.error?.message}
                        ariaInputProps={ariaInputProps}
                        renderOption={({
                          label,
                          value,
                          disabled,
                          tooltip,
                          onChange,
                          closeSelect,
                          checked,
                        }) => (
                          <DropdownSectionItem
                            onClick={() => (onChange?.(value), closeSelect?.())}
                            key={label}
                            active={checked}
                            innerRef={createSelectOptionRef(checked)}
                            disabled={disabled}
                            tooltip={tooltip}
                            tooltipPlacement="left"
                            tooltipWidthMode="maxWidthSm"
                          >
                            <Box align="center" gap="medium">
                              {label}
                              {value === ApiKeyType.Oidc && !isOidcApiKeyFeatureActive ? (
                                <BadgeNext
                                  variant="purple"
                                  type="strong"
                                  startIcon={Diamond}
                                  iconOnly
                                />
                              ) : null}
                            </Box>
                          </DropdownSectionItem>
                        )}
                      />
                    </Box>
                  )}
                </FormField>
              )}
            />

            {type === ApiKeyType.Oidc && (
              <>
                <FormField label="Issuer" error={errors?.oidcIssuer?.message}>
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="https://"
                      error={!!errors?.oidcIssuer}
                      {...register("oidcIssuer", {
                        required: "Issuer field is required.",
                        validate: validateURL({ https: true }),
                      })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
                <FormField label="Client ID (audience)" error={errors?.oidcClientId?.message}>
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Enter Client ID (audience)"
                      error={!!errors?.oidcClientId}
                      {...register("oidcClientId", {
                        required: "Client ID (audience) field is required.",
                      })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
                <FormField
                  label="Subject expression"
                  error={errors?.oidcSubjectExpression?.message}
                >
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Enter subject expression"
                      error={!!errors?.oidcSubjectExpression}
                      {...register("oidcSubjectExpression", {
                        required: "Subject expression field is required.",
                        validate: validateRegExp(),
                      })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
              </>
            )}

            <FormFieldSpaces
              analyticsPage={AnalyticsPageOrganization.OrganizationApiKeys}
              allowEmpty={!isUserManagementActive && viewer.admin}
            />

            {viewer.admin && (
              <FormFieldTags
                label="Groups"
                tagName="group"
                name="groups"
                isOptional
                tooltipMode="parent"
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Group name</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      Type in any group name that you have defined in your IdP (e.g. GitHub). It has
                      to be exactly the same.
                      <ReadMoreDocsLink
                        docsUrl={getDocsUrl("/concepts/user-management/user.html#groups")}
                      />
                    </TooltipModalBody>
                  </>
                }
                tooltipInfoVariant="modal"
              />
            )}

            <FormField label="Description" isOptional>
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Enter API key description here..."
                  {...register("description")}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            {type === ApiKeyType.Secret && (
              <Box margin="large 0 0 0">
                <Banner variant="info">
                  Upon API key creation, a file will be generated with API key secret token details.
                  Please save this file somewhere safe as it will never be available again.
                </Banner>
              </Box>
            )}

            {isWarningShown && (
              <Box margin="large 0 0 0">
                <Banner variant="warning">
                  <Typography tag="p" variant="p-body3" margin="0 0 medium 0">
                    API keys are virtual users and are billed like regular users, too. Thus, each
                    API key used (exchanged for a token) during any given billing cycle counts
                    against the total number of users.
                  </Typography>
                  <ReadMoreDocsLink
                    docsUrl={getDocsUrl("/integrations/api#spacelift-api-key-token")}
                  />
                </Banner>
              </Box>
            )}

            <DrawerFooter sticky>
              <DrawerFooterActions>
                <Button
                  variant="secondary"
                  analyticsPage={AnalyticsPageOrganization.OrganizationApiKeys}
                  analyticsTitle="Cancel Create Key"
                  analyticsProps={{ managementStrategy: getManagementStrategy(activationStatus) }}
                  onPress={handleCloseDrawer}
                >
                  Cancel
                </Button>
                <Button variant="primary" onPress={handleSubmitClick}>
                  Create
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showApiKeysCreateDrawer = createDrawerTrigger(ApiKeysCreateDrawer);
