import { Role } from "types/generated";
import { useBulkRoleDelete } from "shared/Role/useDeleteRole";
import { canDeleteRole } from "shared/Role/useDeleteRole/accessValidation";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import { RolesBulkActions } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import { RolesActionItemForBulkActions } from "./types";

const useDeleteRolesBulkAction = (): RolesActionItemForBulkActions => {
  const [deleteRole] = useBulkRoleDelete();
  const { viewer } = useTypedContext(AccountContext);

  return {
    key: RolesBulkActions.Delete,
    title: BULK_ACTION_NAMES[RolesBulkActions.Delete],
    resultTitle: BULK_ACTION_RESULTS_TITLE[RolesBulkActions.Delete],
    condition: () => canDeleteRole(viewer),
    mutation: (role: Role) => deleteRole({ id: role.id }),
  };
};

export default useDeleteRolesBulkAction;
