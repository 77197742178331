import DescriptionDetails from "components/DescriptionDetails";
import FormFieldViewText from "components/FormFields/ViewText";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import { Blueprint, BlueprintInput } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import TemplatePreviewSkeleton from "./Skeleton";
import BlueprintTemplateFormField from "./FormField";
import styles from "./styles.module.css";

type BlueprintTemplateFormProps = {
  item: Blueprint;
  isPublished: boolean;
  hasErrors: boolean;
  hasPreview: boolean;
  loading?: boolean;
  errors: string[];
  inputs: BlueprintInput[];
  /*
   * This is a callback to edit the description field, when not passed - the description will be not be visible if unavailable
   */
  handleEditDescription?: () => void;
  onOpenFullDescription: () => void;
};

const BlueprintTemplateForm = ({
  item,
  isPublished,
  hasPreview,
  hasErrors,
  errors,
  inputs,
  loading,
  handleEditDescription,
  onOpenFullDescription,
}: BlueprintTemplateFormProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const canManageBlueprint = viewer.admin || hasSpaceManageAccess(item.space.accessLevel);

  return (
    <Box direction="column" fullWidth gap="large">
      {isPublished && (
        <>
          <FormFieldViewText label="Blueprint name" value={item.name} noMargin />

          {handleEditDescription && (
            <DescriptionDetails
              noMargin
              label="Blueprint description"
              description={item.description}
              onOpenFullDescription={onOpenFullDescription}
              {...(canManageBlueprint && { onAddDescription: handleEditDescription })}
            />
          )}
        </>
      )}

      {!isPublished && !hasPreview && !hasErrors && (
        <Banner variant="info">
          No preview available. Please add some inputs to the template.
        </Banner>
      )}

      {!isPublished && hasPreview && !hasErrors && (
        <Banner variant="info">
          This is just a template preview, you will not be able to create a stack from it.
        </Banner>
      )}

      {!isPublished &&
        hasErrors &&
        errors.map((error, index) => (
          <Banner aria-live="polite" className={styles.errorBanner} variant="danger" key={index}>
            {error}
          </Banner>
        ))}

      {loading && <TemplatePreviewSkeleton />}
      {!loading && !hasErrors && (
        <Box direction="column" grow="1" fullWidth gap="large">
          {inputs.map((input) => (
            <BlueprintTemplateFormField key={input.id} input={input} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default BlueprintTemplateForm;
