import { useNavigate } from "react-router-dom";

import Box from "ds/components/Box";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import { BillingTierFeature, BlueprintState, SpaceAccessLevel } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useTierFeature from "views/Account/hooks/useTierFeature";
import { AccountContext } from "views/AccountWrapper";
import BlueprintShareDropdownMenuItem from "views/Account/Blueprint/components/ShareButton/DropdownMenuItem";
import { SpacesContext } from "views/Account/SpacesProvider";

import { showCreateBlueprintDrawer } from "../../CreateBlueprintDrawer";
import { showCreateStackDrawer } from "../../CreateStackDrawer";
import { showDeleteConfirmation } from "../../DeleteConfirmation";
import { CellComponentProps } from "./types";

type BlueprintsRowActionsDropdownCellProps = CellComponentProps;

const BlueprintsRowActionsDropdownCell = ({ blueprint }: BlueprintsRowActionsDropdownCellProps) => {
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);
  const { viewer } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const navigate = useNavigate();

  const canEditBlueprint = blueprint.space.accessLevel === SpaceAccessLevel.Admin;

  const canManageBlueprint =
    viewer.admin ||
    blueprint.space.accessLevel === SpaceAccessLevel.Admin ||
    blueprint.space.accessLevel === SpaceAccessLevel.Write;

  const isPublished = blueprint.state === BlueprintState.Published;
  const isDraft = blueprint.state === BlueprintState.Draft;

  const onCreateStack = () => {
    showCreateStackDrawer({
      id: blueprint.id,
      item: blueprint,
      onEditMetadata: onEditDetails,
    });
  };

  const onEditDetails = () => {
    showCreateBlueprintDrawer({ id: blueprint.id, blueprint });
  };

  const onCloneBlueprint = () => {
    showCreateBlueprintDrawer({ id: blueprint.id, blueprint, isCloneMode: true });
  };

  const onEditDraft = () => {
    navigate(`/blueprint/${blueprint.id}`);
  };

  return (
    <Box justify="end">
      <DropdownMenuEllipsis tooltip="Blueprint actions">
        {isFeatureActive && isPublished && hasEntityCreateAccess && (
          <DropdownMenuItem onAction={onCreateStack}>Create stack</DropdownMenuItem>
        )}
        {isFeatureActive && isDraft && canEditBlueprint && (
          <DropdownMenuItem onAction={onEditDraft}>Edit draft</DropdownMenuItem>
        )}
        {isFeatureActive && canManageBlueprint && (
          <DropdownMenuItem onAction={onEditDetails}>Edit details</DropdownMenuItem>
        )}
        {isFeatureActive && canManageBlueprint && (
          <DropdownMenuItem onAction={onCloneBlueprint}>Clone</DropdownMenuItem>
        )}
        {isFeatureActive && isPublished && (
          <BlueprintShareDropdownMenuItem blueprintId={blueprint.id} />
        )}
        {canManageBlueprint && (
          <DropdownMenuItem
            onAction={() => showDeleteConfirmation({ name: blueprint.name, id: blueprint.id })}
            danger
          >
            Delete
          </DropdownMenuItem>
        )}
      </DropdownMenuEllipsis>
    </Box>
  );
};

export default BlueprintsRowActionsDropdownCell;
