import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Textarea from "ds/components/Textarea";
import useAnalytics from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import { getDrawerFormFix } from "utils/css";

import { UPDATE_VERSION_DESCRIPTION } from "../../gql";

type ModuleVersionAddDescriptionDrawerProps = {
  description: string | null;
  moduleId: string;
  versionId: string;
};

type FormValues = {
  description: string | null;
};

const ModuleVersionAddDescriptionDrawer = createDrawer(
  ({ description, moduleId, versionId }: ModuleVersionAddDescriptionDrawerProps) => {
    const drawer = useModal();

    const trackSegmentAnalyticsEvent = useAnalytics();

    const handleCancel = () => {
      trackSegmentAnalyticsEvent("Module Registry - Edit Description Canceled");
    };

    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const [updateVersionDescription] = useMutation(UPDATE_VERSION_DESCRIPTION, {
      refetchQueries: ["GetVersionNext", "GetModuleVersionsNext"],
      awaitRefetchQueries: true,
    });

    const builderForm = useForm<FormValues>({
      defaultValues: {
        description,
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      reset,
      formState: { isDirty, isSubmitSuccessful, isSubmitting },
    } = builderForm;

    const onSubmit = (values: FormValues) => {
      return updateVersionDescription({
        variables: {
          id: versionId,
          module: moduleId,
          description: values.description,
        },
      })
        .then(() => {
          reportSuccess({ message: "Description successfully saved" });
          trackSegmentAnalyticsEvent("Module Registry - Version Description Edited");
          drawer.hide();
        })
        .catch(onError);
    };

    useEffect(() => {
      if (isSubmitSuccessful) {
        reset({
          description,
        });
      }
    }, [isSubmitSuccessful, reset, description]);

    return (
      <DrawerForm isDirty={isDirty} onClose={handleCancel}>
        <FormProvider {...builderForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Edit description" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody>
              <FormField label="Description" isOptional>
                {({ ariaInputProps }) => (
                  <Textarea
                    placeholder="Enter description here..."
                    maxRows={15}
                    {...register("description")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showModuleVersionAddDescriptionDrawer = createDrawerTrigger(
  ModuleVersionAddDescriptionDrawer
);
