import { useCallback, useEffect, useRef } from "react";

type UseShiftSelectionManagerOptions<CollectionItem> = {
  collection?: Array<CollectionItem>;
  getId?: (item: CollectionItem) => string;
};

const defaultGetId = (item: { id?: string }) => item.id!;

const useShiftSelectionManager = <CollectionItem extends object>({
  collection,
  getId = defaultGetId,
}: UseShiftSelectionManagerOptions<CollectionItem>) => {
  const withShift = useRef(false);
  const lastSelectedIndex = useRef<number | null>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        withShift.current = true;
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        withShift.current = false;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return useCallback(
    (id: string) => {
      if (!collection || !collection.length) {
        return [];
      }

      const index = collection.findIndex((item) => getId(item) === id);
      if (index === -1) {
        return [id];
      }
      const selectedIds = new Set<string>([id]);

      if (withShift.current) {
        const [start, end] = [index, lastSelectedIndex.current || 0].sort((a, b) => a - b);
        for (let i = start; i <= end; i++) {
          selectedIds.add(getId(collection[i]));
        }
      }
      lastSelectedIndex.current = index;

      return Array.from(selectedIds);
    },
    [collection, getId]
  );
};

export default useShiftSelectionManager;
