import useLocalStorage from "@rehooks/local-storage";
import { useEffect, useState } from "react";

import { REDUCED_MOTION_LOCAL_STORAGE_KEY } from "./constants";
import { ReducedMotionStorage } from "./types";

const REDUCED_MOTION_ATTRIBUTE = "data-reduced-motion";
const QUERY = "(prefers-reduced-motion: no-preference)";
const getInitialState = () => !window.matchMedia(QUERY).matches;

const DEFAULT_VALUE: ReducedMotionStorage = {
  configValue: false,
  useSystemValue: true,
};

// reference: https://www.joshwcomeau.com/react/prefers-reduced-motion/

export function useReducedMotion() {
  const [storage] = useLocalStorage<ReducedMotionStorage>(REDUCED_MOTION_LOCAL_STORAGE_KEY);
  const [systemValue, setSystemValue] = useState(getInitialState);

  const useSystemValue = storage?.useSystemValue ?? DEFAULT_VALUE.useSystemValue;
  const configValue = storage?.configValue ?? DEFAULT_VALUE.configValue;

  // If the user prefers the system value, listen for the system value
  useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY);

    const listener = (event: MediaQueryListEvent) => {
      setSystemValue(!event.matches);
    };

    mediaQueryList.addEventListener("change", listener);
    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, [useSystemValue]);

  const reducedMotion = useSystemValue ? systemValue : configValue;

  useEffect(() => {
    if (reducedMotion && !document.documentElement.hasAttribute(REDUCED_MOTION_ATTRIBUTE)) {
      document.documentElement.setAttribute(REDUCED_MOTION_ATTRIBUTE, "true");
    } else if (document.documentElement.hasAttribute(REDUCED_MOTION_ATTRIBUTE)) {
      document.documentElement.removeAttribute(REDUCED_MOTION_ATTRIBUTE);
    }
  }, [reducedMotion]);

  return {
    reducedMotion,
    reducedMotionConfigValue: configValue,
    reducedMotionUseSystemValue: useSystemValue,
  };
}
