import { createSelector } from "reselect";

import { TableColumnMeta } from "ds/components/Table/types";

import { IacTableStoreState } from "./types";
import { IacTableColumnsConfig, IacTableGroupMeta } from "./makeIacTableMetaSlice/types";

export const selectVisibleColumns = createSelector(
  (state: IacTableStoreState) => state.columnsState,
  (state: IacTableStoreState) => state.columnsOrder,
  (columnsState, columnsOrder): TableColumnMeta[] => {
    return columnsOrder.visible.reduce<TableColumnMeta[]>((acc, columnId) => {
      const column = columnsState[columnId];
      if (column) {
        acc.push(column);
      }
      return acc;
    }, []);
  }
);

export const selectColumnsConfig = createSelector(
  (state: IacTableStoreState) => state.columnsState,
  (state: IacTableStoreState) => state.columnsOrder,
  (columnsState, columnsOrder): IacTableColumnsConfig => {
    return {
      visibleColumns: columnsOrder.visible,
      hiddenColumns: columnsOrder.hidden,
      staticColumns: Object.keys(columnsState).filter(
        (columnId) => !columnsState[columnId].canHide
      ),
    };
  }
);

export const selectGroupsMeta = createSelector(
  (state: IacTableStoreState, groupId: string) => state.groupsMeta[groupId],
  (result): IacTableGroupMeta | undefined => result
);

const selectOpenGroups = createSelector(
  (state: IacTableStoreState) => state.groupsMeta,
  (groupsMeta): string[] => {
    return Object.keys(groupsMeta).filter((groupId) => groupsMeta[groupId].isOpen);
  }
);

export const selectStickyRanges = createSelector(
  selectOpenGroups,
  (state: IacTableStoreState) => state.resourcesIdsPerGroup,
  (openGroups, resourcesIdsPerGroup): [string, string][] => {
    return openGroups.reduce<[string, string][]>((acc, groupId) => {
      if (resourcesIdsPerGroup[groupId]?.length > 0) {
        //find the last resource in the group
        const lastResource =
          resourcesIdsPerGroup[groupId][resourcesIdsPerGroup[groupId].length - 1];

        acc.push([groupId, lastResource]);
      }
      return acc;
    }, []);
  }
);
