import { useCallback } from "react";

import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuEllipsis, {
  DropdownMenuEllipsisProps,
} from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { AwsIntegration } from "types/generated";

import { canDeleteCloudIntegration, hasCloudIntegrationManageAccess } from "../../../utils";
import { showAwsIntegrationDeleteConfirmation } from "../DeleteConfirmation";
import { showAwsIntegrationFormDrawer } from "../FormDrawer";
import { showAwsIntegrationDetailsDrawer } from "../DetailsDrawer";

type CloudIntegrationsAwsActionsDropdownProps = {
  integration: AwsIntegration;
  trackAnalytics: (event: string, props?: TrackAnalyticsEventProperties) => void;
  hideSeeDetails?: boolean;
  onDeleteSuccess?: () => void;
  dotsSize?: DropdownMenuEllipsisProps["dotsSize"];
  refetchQueriesOnEdit: string[];
  refetchQueriesOnDelete?: string[];
};

const CloudIntegrationsAwsActionsDropdown = ({
  integration,
  trackAnalytics,
  hideSeeDetails,
  onDeleteSuccess,
  dotsSize = "small",
  refetchQueriesOnEdit,
  refetchQueriesOnDelete,
}: CloudIntegrationsAwsActionsDropdownProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const handleEditIntegration = useCallback(async () => {
    showAwsIntegrationFormDrawer({
      id: integration.id,
      integration,
      trackAnalytics,
      refetchQueriesOnEdit,
    });
  }, [integration, refetchQueriesOnEdit, trackAnalytics]);

  const handleDeleteIntegration = useCallback(() => {
    showAwsIntegrationDeleteConfirmation({
      integration,
      trackAnalytics,
      onDeleteSuccess,
      refetchQueriesOnDelete,
    });
  }, [integration, onDeleteSuccess, refetchQueriesOnDelete, trackAnalytics]);

  const handleSeeDetails = useCallback(() => {
    showAwsIntegrationDetailsDrawer({ id: integration.id, integration, trackAnalytics });
  }, [integration, trackAnalytics]);

  const hasAccessToManageIntegration = hasCloudIntegrationManageAccess(viewer, integration);
  const canDeleteIntegration = canDeleteCloudIntegration(integration);

  return (
    <DropdownMenuEllipsis tooltip="Integration actions" dotsSize={dotsSize}>
      <CopyFieldDropdownMenuItem
        title="Copy ID"
        value={integration.id}
        onCopy={() => trackAnalytics("Copy ID clicked")}
      />
      {!hideSeeDetails && (
        <DropdownMenuItem onAction={handleSeeDetails}>See details</DropdownMenuItem>
      )}
      <DropdownMenuItem
        onAction={handleEditIntegration}
        tooltip={
          !hasAccessToManageIntegration
            ? "You don't have access to manage this integration"
            : undefined
        }
        isTooltipActive={!hasAccessToManageIntegration}
        isDisabled={!hasAccessToManageIntegration}
        tooltipPlacement="left"
        tooltipWidthMode="maxWidthSm"
      >
        Edit
      </DropdownMenuItem>
      <DropdownMenuItem
        onAction={handleDeleteIntegration}
        danger
        isDisabled={!hasAccessToManageIntegration || !canDeleteIntegration}
        tooltip={
          !hasAccessToManageIntegration
            ? "You don't have access to manage this integration"
            : "Cannot delete this integration because it is attached to a stack"
        }
        isTooltipActive={!hasAccessToManageIntegration || !canDeleteIntegration}
        tooltipPlacement="left"
        tooltipWidthMode="maxWidthSm"
      >
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default CloudIntegrationsAwsActionsDropdown;
