import Typography from "ds/components/Typography";
import { pluralize } from "shared/Pluralize";

import { CellComponentProps } from "../types";

type UserIdpGroupsListItemSpaceCellProps = CellComponentProps;

const UserIdpGroupsListItemSpaceCell = ({ group }: UserIdpGroupsListItemSpaceCellProps) => {
  return (
    <Typography tag="p" variant="p-body3">
      {group.spaceCount} {pluralize("space", group.spaceCount)}
    </Typography>
  );
};

export default UserIdpGroupsListItemSpaceCell;
