import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import NotFoundPage from "components/error/NotFoundPage";
import { Clock, Space } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Tab from "ds/components/Tab";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedFlags from "hooks/useTypedFlags";
import useBlueprint from "shared/Blueprint/useBlueprint";
import { BillingTierFeature, BlueprintState } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";

import { FILTERS_ORDER_SETTINGS_KEY } from "../Blueprints/constants";
import { showCreateBlueprintDrawer } from "../Blueprints/CreateBlueprintDrawer";
import BlueprintFeatureGateCallout from "../Blueprints/FeatureGate/Callout";
import { showDeleteConfirmation } from "../Blueprints/DeleteConfirmation";
import BlueprintStateBadge from "../Blueprints/StateBadge";
import useTierFeature from "../hooks/useTierFeature";
import { showBlueprintDetailsDrawer } from "./DetailsDrawer";
import BlueprintIntegrations from "./Intergations";
import BlueprintTemplateBody from "./TemplateBody";
import BlueprintVersions from "./Versions";

const BlueprintPage = () => {
  const navigate = useNavigate();
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);
  const { blueprintsVersioning } = useTypedFlags();

  const { blueprintId } = useParams<{ blueprintId: string }>();

  const { error, loading, data } = useBlueprint(blueprintId);

  const handleOpenEditDrawer = () => {
    showCreateBlueprintDrawer({ blueprint: data?.blueprint });
  };

  const handleOpenCloneDrawer = () => {
    showCreateBlueprintDrawer({ blueprint: data?.blueprint, isCloneMode: true });
  };

  const handleOpenDetailsDrawer = () => {
    if (data?.blueprint) {
      showBlueprintDetailsDrawer({
        item: data.blueprint,
        ...(canManageBlueprint && { onEdit: handleOpenEditDrawer }),
      });
    }
  };

  useBreadcrumbs(
    [
      {
        title: "Blueprints",
        link: getBreadcrumbsBackUrl("/blueprints", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: data?.blueprint?.name || "",
      },
    ],
    [data?.blueprint?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.blueprint) {
    return <PageLoading />;
  }

  if (!data?.blueprint) {
    return <NotFoundPage />;
  }

  const blueprint = data.blueprint;
  const blueprintSchema = data.blueprintSchema;
  const canManageBlueprint =
    hasSpaceManageAccess(blueprint.space.accessLevel) && !blueprint.deleted;

  const handleDeleteClick = () => {
    showDeleteConfirmation({ name: blueprint.name, id: blueprint.id }).then(() =>
      navigate("/blueprints")
    );
  };

  const bluePrintUrl = `/blueprint/${data.blueprint.id}`;

  const isDraft = blueprint.state === BlueprintState.Draft;

  return (
    <>
      <ViewHeader noSticky={blueprintsVersioning}>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{blueprint.name}</ViewHeaderTitle>
            <BlueprintStateBadge state={blueprint.state} />
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <Button variant="secondary" onPress={handleOpenDetailsDrawer}>
              Details
            </Button>

            {canManageBlueprint && (
              <DropdownMenuEllipsis tooltip="Blueprint actions" dotsSize="medium">
                {isFeatureActive && (
                  <DropdownMenuItem onAction={handleOpenEditDrawer}>Edit metadata</DropdownMenuItem>
                )}

                {isFeatureActive && (
                  <DropdownMenuItem onAction={handleOpenCloneDrawer}>Clone</DropdownMenuItem>
                )}

                <DropdownMenuItem onAction={handleDeleteClick} danger>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuEllipsis>
            )}
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={Space}
              linkText={blueprint.space.name}
              href={`/spaces/${blueprint.space.id}`}
            />
            <MetaInfoListItem icon={Clock}>
              <Timestamp timestamp={blueprint.updatedAt} />
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>

        <ViewHeaderNavigation>
          <Tab end to={bluePrintUrl} label={blueprintsVersioning ? "Versions" : "Template body"} />
          <Tab to={`${bluePrintUrl}/integrations`} label="Integrations" />
        </ViewHeaderNavigation>
      </ViewHeader>
      <BlueprintFeatureGateCallout />
      <Routes>
        <Route
          index
          element={
            blueprintsVersioning ? (
              <BlueprintVersions blueprint={blueprint} />
            ) : (
              <BlueprintTemplateBody blueprint={blueprint} blueprintSchema={blueprintSchema} />
            )
          }
        />

        <Route
          path="integrations"
          element={
            <BlueprintIntegrations
              blueprintId={blueprint.id}
              blueprintName={blueprint.name}
              space={blueprint.space}
              isDraft={isDraft}
            />
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default BlueprintPage;
