import Box from "ds/components/Box";
import Link from "ds/components/Link";

import useBlueprintIntegrationAnalytics from "../../useBlueprintIntegrationAnalytics";
import { CellComponentProps } from "./types";

type BlueprintIntegrationsListItemNameCellProps = CellComponentProps;

const BlueprintIntegrationsListItemNameCell = ({
  integration,
}: BlueprintIntegrationsListItemNameCellProps) => {
  const trackAnalytics = useBlueprintIntegrationAnalytics();

  const integrationUrl = `/integration/${integration.integrationID}`;

  return (
    <Box fullWidth>
      <Link
        size="small"
        variant="secondary"
        href={integrationUrl}
        onPress={() => trackAnalytics("Name link clicked")}
      >
        {integration.integrationName}
      </Link>
    </Box>
  );
};

export default BlueprintIntegrationsListItemNameCell;
