import { useNavigate } from "react-router-dom";

import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY, URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";
import { decodeURIParam } from "utils/urls";
import { SearchQueryOrderDirection } from "types/generated";

import SpacesTreeGridList from "./List";
import { SpacesViewContext } from "../Context";
import { FilterItemKeys } from "./constants";
import { createNavigationLink } from "../utils";

const SpacesTreeGrid = () => {
  const navigate = useNavigate();
  const { spaces } = useTypedContext(SpacesContext);
  const { currentSpace, isRootAdmin, canNonRootAdminCreateSpace } =
    useTypedContext(SpacesViewContext);
  const urlParams = useURLParams();
  const searchQuery = decodeURIParam(urlParams.get(URL_SEARCH_KEY));

  const sortDirection = urlParams.get(URL_SORT_DIRECTION) as SearchQueryOrderDirection;

  const sortBy: FilterItemKeys = urlParams.get(URL_SORT_KEY) as FilterItemKeys;

  const handleChangeSorting = (field: FilterItemKeys) => {
    const newUrlParams = new URLSearchParams();
    if (field === sortBy) {
      const newDirection =
        sortDirection === SearchQueryOrderDirection.Asc
          ? SearchQueryOrderDirection.Desc
          : SearchQueryOrderDirection.Asc;

      newUrlParams.set(URL_SORT_KEY, field);
      newUrlParams.set(URL_SORT_DIRECTION, newDirection);
    } else {
      newUrlParams.set(URL_SORT_KEY, field);
      newUrlParams.set(URL_SORT_DIRECTION, SearchQueryOrderDirection.Asc);
    }

    navigate(createNavigationLink("./", newUrlParams), { replace: true });
  };

  return (
    <SpacesTreeGridList
      isRootAdmin={isRootAdmin}
      spaces={spaces}
      activeId={currentSpace?.id}
      searchQuery={searchQuery}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onSortChange={handleChangeSorting}
      canNonRootAdminCreateSpace={canNonRootAdminCreateSpace}
    />
  );
};

export default SpacesTreeGrid;
