import cx from "classnames";

import { Space, Trash } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import SpaceAccessBadge from "components/SpaceAccessBadge";
import VendorConfigIcon from "components/VendorIcon";
import BaseAction from "ds/components/BaseAction";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import TextEllipsis from "ds/components/TextEllipsis";
import { SpaceAccessLevel, StackDependencyDetail } from "types/generated";
import { isLegacySpace } from "utils/space";

import DependencyItemName from "./Name";
import { OutputReferences } from "./OutputReferences";
import styles from "./styles.module.css";

type DependencyItemProps = {
  item: StackDependencyDetail;
  onDelete?: () => void;
  className?: string;
  onOutputReferencesEdit?: () => void;
  referenceCount?: number;
  graphMode?: boolean;
  isFocused?: boolean;
  isOutputReferencesDisallowed?: boolean;
  disallowedVendorName?: string;
};

const DependencyItem = ({
  item,
  onDelete,
  className,
  onOutputReferencesEdit,
  referenceCount,
  graphMode,
  isFocused,
  isOutputReferencesDisallowed,
  disallowedVendorName,
}: DependencyItemProps) => {
  // Some customers still doesn't use Spaces but use legacy Access policies unfortunately.
  // In this case let's hide the Space badge since it's not really relevant.
  const showSpaceAccessBadge = !(
    isLegacySpace(item.space.id) && item.space.accessLevel === SpaceAccessLevel.None
  );

  return (
    <Box direction="column" className={cx(styles.item, { [styles.focused]: isFocused }, className)}>
      <Box direction="row" align="center" justify="between" gap="medium" padding="large" fullWidth>
        <Box
          className={styles.stackInfoWrapper}
          direction="column"
          align="start"
          justify="center"
          shrink="1"
          gap="medium"
          fullWidth
        >
          <Box direction="row" align="center" justify="start" fullWidth>
            <VendorConfigIcon vendorConfig={item.vendorConfig} className={styles.vendorIcon} />

            <TextEllipsis tooltip={item.name} tooltipWidthMode="maxWidthSm">
              {(props) =>
                item.space.accessLevel === SpaceAccessLevel.None ? (
                  <DependencyItemName
                    {...props}
                    name={item.name}
                    className={cx(styles.itemName, props.className)}
                  />
                ) : (
                  <BaseAction
                    {...props}
                    to={`/stack/${item.id}/dependencies`}
                    className={cx(styles.itemNameLink, props.className)}
                  >
                    <DependencyItemName name={item.name} className={styles.itemName} />
                  </BaseAction>
                )
              }
            </TextEllipsis>
          </Box>

          {!graphMode && (
            <OutputReferences
              onEdit={onOutputReferencesEdit}
              referenceCount={referenceCount}
              disallowed={isOutputReferencesDisallowed}
              vendorName={disallowedVendorName}
            />
          )}
        </Box>

        {onDelete && (
          <ButtonIcon icon={Trash} onPress={onDelete} variant="ghostDanger">
            Delete dependency
          </ButtonIcon>
        )}
      </Box>

      <Box direction="row" className={styles.secondRow} align="center" justify="between">
        <MetaInfoListItem
          icon={Space}
          linkText={item.space.name}
          href={`/spaces/${item.space.id}`}
        />
        {showSpaceAccessBadge && <SpaceAccessBadge accessLevel={item.space.accessLevel} />}
      </Box>
    </Box>
  );
};

export default DependencyItem;
