import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { VERSIONS_COLUMN_ID } from "../../constants";
import { BlueprintVersion } from "../../types";
import BlueprintVersionsListItemVersionCell from "./VersionCell";
import BlueprintVersionsListItemStatusCell from "./StatusCell";
import BlueprintVersionsListItemDescriptionCell from "./DescriptionCell";
import BlueprintVersionsListItemUpdatedAtCell from "./UpdatedAtCell";
import BlueprintVersionsListItemActionsDropdownCell from "./DropdownActionCell";

const CELLS = {
  [VERSIONS_COLUMN_ID.STATUS]: BlueprintVersionsListItemStatusCell,
  [VERSIONS_COLUMN_ID.VERSION]: BlueprintVersionsListItemVersionCell,
  [VERSIONS_COLUMN_ID.DESCRIPTION]: BlueprintVersionsListItemDescriptionCell,
  [VERSIONS_COLUMN_ID.UPDATED_AT]: BlueprintVersionsListItemUpdatedAtCell,
  [VERSIONS_COLUMN_ID.DROPDOWN]: BlueprintVersionsListItemActionsDropdownCell,
};

type BlueprintVersionListItemProps = {
  version: BlueprintVersion;
};

const BlueprintVersionListItem = (props: BlueprintVersionListItemProps) => {
  const { version } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as VERSIONS_COLUMN_ID];

        const uniqueKey = `${id}-${version.version}`;

        const childrenComponent = <Component version={version} />;

        return (
          <TableCell key={uniqueKey} id={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(BlueprintVersionListItem);
