import { useState } from "react";

import LinkButton from "ds/components/Button/LinkButton";
import Toggle from "ds/components/Toggle";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageBilling } from "hooks/useAnalytics/pages/billing";
import { BillingCycleInterval, BillingTier } from "types/generated";

import { STARTER_ANNUAL_DISCOUNT, URL_INTERVAL_PARAM } from "../../constants";
import useStarterPlanInfo from "../../hooks/useStarterPlanInfo";
import { formatPrice } from "../../utils";
import BillingUpgradeOptionsCard from "../Card";
import styles from "./styles.module.css";

const BillingUpgradeOptionsStarterPlanCard = () => {
  const [interval, setInterval] = useState(BillingCycleInterval.Monthly);

  const { subscription } = useStarterPlanInfo(interval);

  const price = formatPrice(subscription, interval);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageBilling.BillingDashboard,
  });

  const handleIntervalChange = () => {
    const newInterval =
      interval === BillingCycleInterval.Yearly
        ? BillingCycleInterval.Monthly
        : BillingCycleInterval.Yearly;

    setInterval(newInterval);

    trackSegmentEvent("Starter Annual Toggle", { billingInterval: newInterval });
  };

  const ctaButtonTitle = "Upgrade";

  return (
    <BillingUpgradeOptionsCard
      tier={BillingTier.V4Starter}
      title={`${interval === BillingCycleInterval.Monthly ? "Save" : "You're saving"} ${STARTER_ANNUAL_DISCOUNT}% with annual billing`}
      titleColor="success"
      price={price}
      description="For individual and small teams who want to automate their IaC pipelines."
      action={
        <Toggle
          variant="switch"
          isSelected={interval === BillingCycleInterval.Yearly}
          onChange={handleIntervalChange}
          className={styles.toggle}
          initialMinHeight
        >
          Annual
        </Toggle>
      }
      ctaButton={
        <LinkButton
          variant="primary"
          size="small"
          to={`/settings/billing/upgrade?${URL_INTERVAL_PARAM}=${interval}`}
          analyticsPage={AnalyticsPageBilling.BillingDashboard}
          analyticsTitle="Upgrade Options CTA Button Clicked"
          analyticsProps={{
            ctaButtonTitle,
            plan: BillingTier.V4Starter,
            intervalToggle: interval,
          }}
        >
          {ctaButtonTitle}
        </LinkButton>
      }
      className={styles.starterPlanCard}
    />
  );
};

export default BillingUpgradeOptionsStarterPlanCard;
