import Box from "ds/components/Box";
import Link from "ds/components/Link";

import getVersionText from "../../getVersionText";
import useBlueprintVersionsAnalytics from "../../useBlueprintVersionsAnalytics";
import { CellComponentProps } from "./types";

type BlueprintVersionsListItemVersionCellProps = CellComponentProps;

const BlueprintVersionsListItemVersionCell = ({
  version,
}: BlueprintVersionsListItemVersionCellProps) => {
  const trackAnalytics = useBlueprintVersionsAnalytics();

  return (
    <Box fullWidth>
      <Link
        size="small"
        variant="secondary"
        href={`version/${version.version}`}
        onPress={() => trackAnalytics("Description link clicked")}
      >
        {getVersionText(version)}
      </Link>
    </Box>
  );
};

export default BlueprintVersionsListItemVersionCell;
