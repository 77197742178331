import { gql, TypedDocumentNode } from "@apollo/client";

import {
  MutationUserRoleBindingCreateArgs,
  MutationUserRoleBindingUpdateArgs,
  Role,
  UserRoleBinding,
} from "types/generated";

type UserRoleBindingCreateData = {
  userRoleBindingCreate: Pick<UserRoleBinding, "id">;
};

export const USER_ROLE_BINDING_CREATE: TypedDocumentNode<
  UserRoleBindingCreateData,
  MutationUserRoleBindingCreateArgs
> = gql`
  mutation UserRoleBindingCreate($input: UserRoleBindingInput!) {
    userRoleBindingCreate(input: $input) {
      id
    }
  }
`;

type UserRoleBindingUpdateData = {
  userRoleBindingUpdate: Pick<UserRoleBinding, "id">;
};

export const USER_ROLE_BINDING_UPDATE: TypedDocumentNode<
  UserRoleBindingUpdateData,
  MutationUserRoleBindingUpdateArgs
> = gql`
  mutation UserRoleBindingUpdate($id: ID!, $input: UserRoleBindingInput!) {
    userRoleBindingUpdate(id: $id, input: $input) {
      id
    }
  }
`;

type UserRoleBindingsData = {
  managedUser: {
    id: string;
    userRoleBindings: UserRoleBinding[];
  };
  roles: Role[];
};

export const USER_ROLE_BINDINGS_WITH_ROLES: TypedDocumentNode<UserRoleBindingsData> = gql`
  query UserRoleBindingsWithRoles($id: ID!) {
    managedUser(id: $id) {
      id
      userRoleBindings {
        id
        roleID
        spaceID
        userID
      }
    }
    roles {
      id
      name
    }
  }
`;
