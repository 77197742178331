import cx from "classnames";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import { LayoutContext } from "components/layout/Context";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";
import useTypedContext from "hooks/useTypedContext";
import { IdentityProvider } from "types/generated";
import { validateEmail } from "utils/formValidators";
import { AccountContext } from "views/AccountWrapper";

import { showInviteSuccessModal } from "../InviteSuccessModal";
import styles from "./styles.module.css";
import useInviteUser from "./useInviteUser";

type PersonalSettingsInviteFormProps = {
  analyticsPage?: AnalyticsPagePersonal.PersonalSpaces | AnalyticsPagePersonal.PersonalIdpGroups;
};

type InviteUserFields = {
  userName?: string;
  email: string;
};

const PersonalSettingsInviteForm = ({ analyticsPage }: PersonalSettingsInviteFormProps) => {
  const { onError } = useTypedContext(FlashContext);
  const { isExpandedMode } = useTypedContext(LayoutContext);
  const { viewer } = useTypedContext(AccountContext);
  const { onUserInvite, loading } = useInviteUser();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const userInviteForm = useForm<InviteUserFields>({
    defaultValues: {
      userName: "",
      email: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = userInviteForm;

  const handleFormSubmit = useCallback(
    () =>
      handleSubmit((formData) => {
        trackSegmentAnalyticsEvent?.("Send Invite Click");

        // username is either username field value or email field if username field is empty
        const userName = formData.userName || formData.email;
        const email = formData.email;

        onUserInvite(email, userName)
          .then(({ data }) => {
            if (data) {
              reset();
              showInviteSuccessModal().then(() => {
                trackSegmentAnalyticsEvent?.("Invite Sent Click Okay");
              });
            }
          })
          .catch(onError);
      })(),
    [handleSubmit, onError, onUserInvite, reset, trackSegmentAnalyticsEvent]
  );

  const isGithubUser = viewer.identityProvider === IdentityProvider.Github;

  return (
    <FormProvider {...userInviteForm}>
      <Box
        align="center"
        justify="center"
        gap="large"
        className={cx(isGithubUser ? styles.wrapperGithub : styles.wrapper, {
          [styles.wrapperExpanded]: isExpandedMode,
        })}
      >
        {isGithubUser && (
          <FormField
            label="Username"
            error={errors?.userName?.message}
            isOptional={!isGithubUser}
            className={styles.formField}
            tooltipInfoVariant="modal"
            tooltipInfo={
              <TooltipModalBody align="start">
                <Typography tag="p" variant="p-body3">
                  Provide GitHub username
                </Typography>
              </TooltipModalBody>
            }
          >
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter GitHub username"
                className={styles.input}
                error={!!errors?.userName}
                {...register("userName", {
                  ...(isGithubUser && { required: "Username field is required." }),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        )}
        <FormField
          label="Email"
          error={errors?.email?.message}
          noMargin
          className={styles.formField}
          helperText="We will send an invite to this address"
        >
          {({ ariaInputProps }) => (
            <Input
              placeholder="Enter email"
              className={styles.input}
              error={!!errors?.email}
              {...register("email", {
                required: "Email field is required.",
                validate: validateEmail,
              })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <Button
          variant="primary"
          onPress={handleFormSubmit}
          loading={loading}
          className={styles.button}
        >
          Request invite
        </Button>
      </Box>
    </FormProvider>
  );
};

export default PersonalSettingsInviteForm;
