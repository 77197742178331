import { useCallback } from "react";

import useShiftSelectionManager from "./useShiftSelectionManager";

type UseBulkActionsShiftSelection<Item> = {
  collection: Item[];
  getId?: (item: Item) => string;
  selectItem: (id: string) => void;
  unselectItem: (id: string) => void;
};

export const useBulkActionsShiftSelection = <Item extends object>({
  collection,
  getId,
  selectItem,
  unselectItem,
}: UseBulkActionsShiftSelection<Item>) => {
  const getSelection = useShiftSelectionManager({ collection, getId });

  return useCallback(
    (id: string, isSelected: boolean) => {
      const selection = getSelection(id);
      const action = isSelected ? selectItem : unselectItem;
      selection.forEach(action);
    },
    [getSelection, selectItem, unselectItem]
  );
};
