import { memo, useState } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import TableCell from "../Row/Cell";
import styles from "./styles.module.css";

export type TableDropdownAction = { title: string; action: () => void };

type TableDropdownCellProps = {
  id: string;
  children: React.ReactNode;
  className?: string;
  dropdownActions?: Array<TableDropdownAction>;
};

const TableDropdownCell = memo(
  ({ id, children, className, dropdownActions }: TableDropdownCellProps) => {
    const [isActive, setIsActive] = useState(false);

    return (
      <TableCell
        id={id}
        className={cx(
          dropdownActions && styles.cellInteractive,
          isActive && styles.cellActive,
          className
        )}
      >
        <Box justify="between" align="center" gap="medium" fullWidth>
          <Box>
            <Box direction="column" className={styles.cellContent}>
              {children}
            </Box>
          </Box>

          {!!dropdownActions?.length && (
            <DropdownMenuEllipsis
              onOpenChange={setIsActive}
              className={styles.cellDropdown}
              tooltip="Cell options"
            >
              {dropdownActions.map(({ title, action }, i) => (
                <DropdownMenuItem key={i} onAction={action}>
                  {title}
                </DropdownMenuItem>
              ))}
            </DropdownMenuEllipsis>
          )}
        </Box>
      </TableCell>
    );
  }
);

TableDropdownCell.displayName = "TableDropdownCell";

export default TableDropdownCell;
