import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import { CellComponentProps } from "../types";

type UsersListItemGroupsCellProps = CellComponentProps;

const UsersListItemGroupsCell = ({ user }: UsersListItemGroupsCellProps) => {
  return (
    <Box direction="row" align="center">
      <Typography tag="span" variant="p-body2">
        {user.groupCount}
      </Typography>
    </Box>
  );
};

export default UsersListItemGroupsCell;
