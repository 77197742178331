import Box from "ds/components/Box";
import BadgeNext from "ds/components/BadgeNext";
import Typography from "ds/components/Typography";

import { CellComponentProps } from "../types";

type UserRolesListItemNameCellProps = CellComponentProps;

const UserRolesListItemNameCell = ({ roleBinding }: UserRolesListItemNameCellProps) => {
  return (
    <Box fullWidth gap="large">
      <Typography tag="p" variant="p-body3">
        {roleBinding.role.name}
      </Typography>
      {roleBinding.role.isSystem && <BadgeNext type="strong" variant="blue" text="SYSTEM" />}
    </Box>
  );
};

export default UserRolesListItemNameCell;
