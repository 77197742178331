import { gql, TypedDocumentNode } from "@apollo/client";

import { Role } from "types/generated";

type RolesData = {
  roles: Role[];
};

export const GET_ROLES: TypedDocumentNode<RolesData> = gql`
  query GetRoles {
    roles {
      actions
      description
      id
      isSystem
      name
      roleBindingsCount
    }
  }
`;
