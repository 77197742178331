import { fromUnixTime } from "date-fns/esm";
import { useCallback } from "react";

import CollapsibleList from "components/CollapsibleList";
import FormSummaryKeyValueElement from "components/FormSummary/KeyValueElement";
import { Cross, Tick } from "components/icons/generated";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import FeedbackActions from "ds/components/Feedback/Actions";
import Icon from "ds/components/Icon";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import { ScheduledDelete } from "types/generated";
import { formatDateTimeByLocale } from "utils/date";
import { getDocsUrl } from "utils/getDocsUrl";
import { StackContext } from "views/Stack/Context";

import { StackSchedulingContextApi } from "../Context";
import { showStackManageScheduledDeleteConfirmationModal } from "./ConfrimationModal";
import useRemoveScheduledDelete from "./useRemoveScheduledDelete";

type StackManageScheduledDeleteListItemProps = {
  integration: ScheduledDelete;
  hasPrivateWorkerPool: boolean;
  isStackBlocked: boolean;
};

const StackManageScheduledDeleteListItem = ({
  integration,
  hasPrivateWorkerPool,
  isStackBlocked,
}: StackManageScheduledDeleteListItemProps) => {
  const { canManageStackAndRuns } = useTypedContext(StackContext);
  const { onEdit } = useTypedContext(StackSchedulingContextApi);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const { removeScheduledDelete, loading } = useRemoveScheduledDelete();

  const onDelete = useCallback(() => {
    removeScheduledDelete(integration.id, () => {
      trackSegmentEvent("Schedule Delete Saved", { type: SCHEDULING_TYPE.DELETE_STACK });
    });
  }, [removeScheduledDelete, trackSegmentEvent, integration]);

  const handleOnConfirm = useCallback(() => {
    showStackManageScheduledDeleteConfirmationModal({
      onConfirm: onDelete,
    });
  }, [onDelete]);

  const handleEdit = useCallback(() => onEdit(integration), [onEdit, integration]);

  return (
    <CollapsibleList
      ariaLevel={3}
      title="Delete stack"
      initialIsCollapsed={false}
      action={
        canManageStackAndRuns && (
          <DropdownMenuEllipsis tooltip="Scheduled delete actions" buttonVariant="ghost">
            <DropdownMenuItem onAction={handleEdit}>Edit</DropdownMenuItem>
            <DropdownMenuItem
              loading={loading}
              onAction={handleOnConfirm}
              danger
              analyticsPage={AnalyticsPageStack.StackScheduling}
              analyticsTitle="Schedule Delete Clicked"
              analyticsProps={{ type: SCHEDULING_TYPE.DELETE_STACK }}
            >
              Delete
            </DropdownMenuItem>
          </DropdownMenuEllipsis>
        )
      }
      alwaysVisibleContent={
        (!hasPrivateWorkerPool || isStackBlocked) && (
          <Banner
            variant="danger"
            title="Scheduled Delete Stack jobs for a given stack are not scheduling:"
            fullWidth
          >
            {!hasPrivateWorkerPool && (
              <>
                The stack uses an unsupported public worker pool.
                <br />
              </>
            )}
            {isStackBlocked && (
              <>
                The stack is currently being blocked by another run.
                <br />
              </>
            )}

            <FeedbackActions>
              <LinkButton
                variant="secondary"
                size="small"
                href={getDocsUrl("/concepts/stack/scheduling#scheduled-delete-stack-ttl")}
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more
              </LinkButton>
            </FeedbackActions>
          </Banner>
        )
      }
    >
      <Box direction="column">
        <FormSummaryKeyValueElement name="Scheduled date">
          {integration.timestampSchedule &&
            `${formatDateTimeByLocale({
              date: fromUnixTime(integration.timestampSchedule),
              format: "dateTimeShort",
              renderTimezone: "UTC",
            })}`}
        </FormSummaryKeyValueElement>

        <FormSummaryKeyValueElement name="Delete resources">
          {integration.shouldDeleteResources ? (
            <Icon src={Tick} color="success" />
          ) : (
            <Icon src={Cross} color="danger" />
          )}
        </FormSummaryKeyValueElement>
      </Box>
    </CollapsibleList>
  );
};

export default StackManageScheduledDeleteListItem;
