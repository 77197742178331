import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import { LogoAnthropicColored, LogoGeminiColored } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import Box from "ds/components/Box";
import FormToggleField from "ds/components/Form/ToggleField";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { IconComponent } from "types/Icon";
import { BillingTierFeature, LlmVendor, TermsAndConditionsVersion } from "types/generated";
import useTierFeature from "views/Account/hooks/useTierFeature";
import { AccountContext } from "views/AccountWrapper";

import { showAcceptToSModal } from "./AcceptToSModal";
import LlmSelectorTile from "./LlmSelectorTile";
import PageLayout from "./PageLayout";
import { GET_AI_SETTINGS, SELECT_LLM_VENDOR, TOGGLE_AI } from "./gql";

type LlmVendorData = {
  name: string;
  logo: IconComponent;
};

const llmVendorData: Record<LlmVendor, LlmVendorData> = {
  [LlmVendor.AwsBedrock]: {
    name: "AWS Bedrock - Anthropic",
    logo: LogoAnthropicColored,
  },
  [LlmVendor.GoogleAiStudio]: {
    name: "Google Gemini",
    logo: LogoGeminiColored,
  },
};

const llmVendorsList = Object.keys(llmVendorData) as LlmVendor[];

const AISettings = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const hasTierRunSummarization = useTierFeature(BillingTierFeature.RunSummarizationAi);

  useTitle(`Organization Settings · Artificial Intelligence · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationAi,
  });

  const {
    data,
    loading: pageLoading,
    error,
  } = useQuery<{
    hasAIEnabled: boolean;
    acceptedTermsAndConditionsAI: boolean;
    llmVendor?: LlmVendor;
    latestTermsAndConditionsVersionAI: TermsAndConditionsVersion;
  }>(GET_AI_SETTINGS, {
    fetchPolicy: "cache-and-network",
    onError,
  });

  const [toggleAI, { loading: toggleAILoading }] = useMutation<{
    accountToggleEnablingAI: boolean;
  }>(TOGGLE_AI, {
    onError,
    refetchQueries: ["GetAISettings"],
  });

  const [selectVendor] = useMutation(SELECT_LLM_VENDOR, {
    onError,
    refetchQueries: ["GetAISettings"],
  });

  const handleToggleAI = useCallback(async () => {
    try {
      const { data: toggleResult, errors } = await toggleAI();
      if (errors?.length) throw errors;
      trackSegmentAnalyticsEvent("AI Summarization Toggled", {
        status: toggleResult?.accountToggleEnablingAI ? "enabled" : "disabled",
      });
      reportSuccess({ message: "AI settings updated successfully" });
    } catch (e) {
      onError(e);
    }
  }, [onError, reportSuccess, toggleAI, trackSegmentAnalyticsEvent]);

  const ErrorContent = useErrorHandle(error);

  const enableAIAssistField = useCallback(() => {
    if (!data) return;

    if (data.acceptedTermsAndConditionsAI) {
      handleToggleAI();
      return;
    }

    showAcceptToSModal(data.latestTermsAndConditionsVersionAI).then((accepted) => {
      if (accepted && !data.hasAIEnabled) {
        handleToggleAI();
      }
    });
  }, [data, handleToggleAI]);

  const handleSelectVendor = useCallback(
    (vendor: LlmVendor) =>
      selectVendor({
        variables: { vendor },
      }),
    [selectVendor]
  );

  if (ErrorContent) {
    return ErrorContent;
  }

  if (pageLoading && !data) {
    return <PageLoading />;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  const aiAssistChecked = data.hasAIEnabled && data.acceptedTermsAndConditionsAI;

  const getTooltipContent = () => {
    if (!hasTierRunSummarization) {
      return "Your current tier does not support this feature. Please upgrade to Enterprise tier to enable AI features.";
    }

    if (!data.acceptedTermsAndConditionsAI) {
      return "Please accept the Terms of Service to enable AI features.";
    }

    return undefined;
  };

  return (
    <PageLayout>
      <Box direction="column" gap="x-large" fullWidth>
        <FormToggleField
          variant="switch"
          onChange={enableAIAssistField}
          checked={aiAssistChecked}
          disabled={!hasTierRunSummarization || toggleAILoading}
          tooltipInfo={getTooltipContent()}
          tooltipInfoVariant="standard"
          title="Saturnhead Assist"
          description="Enable Saturnhead Assist after a phase completes to summarize key log decisions and exceptions for easy review."
        />
        {aiAssistChecked && (
          <Box direction="column" gap="large" fullWidth>
            {llmVendorsList.map((vendor) => {
              const vendorData = llmVendorData[vendor];

              return (
                <LlmSelectorTile
                  key={vendor}
                  onSelect={() => handleSelectVendor(vendor)}
                  name={vendorData.name}
                  icon={vendorData.logo}
                  isSelected={vendor === data.llmVendor}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </PageLayout>
  );
};

export default AISettings;
