import { gql, TypedDocumentNode } from "@apollo/client";

import {
  AuthorizationScheme,
  ExternalIntegration,
  MutationExternalIntegrationDeleteArgs,
} from "types/generated";

type FetchAccountExternalIntegrationsData = {
  externalIntegration: Omit<ExternalIntegration, "customConfig" | "deleted">;
  authorizationScheme: AuthorizationScheme;
};

export const GET_ACCOUNT_EXTERNAL_INTEGRATIONS: TypedDocumentNode<FetchAccountExternalIntegrationsData> = gql`
  query GetAccountExternalIntegrations {
    externalIntegrations {
      id
      name
      type
      createdAt
      deleted
      description
      spaceDetails {
        id
        name
        accessLevel
      }
      attachedBlueprints {
        id
      }
      apiKey {
        id
        name
      }
      customConfig {
        __typename
        ... on ExternalIntegrationConfigServiceNow {
          baseURL
          username
        }
      }
    }
    authorizationScheme
  }
`;

type FetchExternalIntegrationData = {
  externalIntegrationDelete: Pick<ExternalIntegration, "id" | "name">;
};

export const DELETE_EXTERNAL_INTEGRATION: TypedDocumentNode<
  FetchExternalIntegrationData,
  MutationExternalIntegrationDeleteArgs
> = gql`
  mutation DeleteExternalIntegration($id: ID!) {
    externalIntegrationDelete(id: $id) {
      id
      name
    }
  }
`;
