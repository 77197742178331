import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import { SearchSuggestionsFieldType } from "types/generated";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";

import { SEARCH_MANAGED_ENTITIES_SUGGESTIONS } from "./gql";
import { SEARCH_MANAGED_ENTITIES_SUGGESTIONS_DICTIONARY } from "./constants";
import useFiltersVariables from "../useFiltersVariables";
import { getStackPredicate } from "../helpers";
import { IacManagementGroupBy } from "../types";
import { getFilterKey } from "./helpers";

type UseIacManagementFiltersProps = {
  parentStackId?: string;
  groupBy: IacManagementGroupBy;
};

const useIacManagementFilters = ({ parentStackId, groupBy }: UseIacManagementFiltersProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { filterVariables, filterVariablesLoaded } = useFiltersVariables();

  const predicates = useMemo(() => {
    const stackViewPredicates = parentStackId
      ? [
          // TODO: investigate why the Stack filter is applied on Stack View only with duplicating the predicate
          getStackPredicate(parentStackId),
          getStackPredicate(parentStackId),
        ]
      : [];

    return [...filterVariables.predicates, ...stackViewPredicates];
  }, [filterVariables.predicates, parentStackId]);

  const { data, previousData, refetch, loading } = useQuery(SEARCH_MANAGED_ENTITIES_SUGGESTIONS, {
    variables: {
      input: {
        fields: null,
        fullTextSearch: filterVariables.searchInput,
        predicates,
      },
    },
    onError,
    skip: !filterVariablesLoaded,
  });

  const cachedFiltersData = useMemo(
    () =>
      data?.searchManagedEntitiesSuggestions?.fields ||
      previousData?.searchManagedEntitiesSuggestions?.fields ||
      [],
    [
      data?.searchManagedEntitiesSuggestions?.fields,
      previousData?.searchManagedEntitiesSuggestions?.fields,
    ]
  );

  const [filters, stacksFiltersItemsOptionsMap] = useMemo<
    [FilterItem[], FiltersItemsOptionsMap]
  >(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        .filter((field) => field.filterable)
        .map((field) => {
          let key = field.name;

          if (key === "label") {
            labelsCounter += 1;
            key = `label${labelsCounter}`;
          } else {
            key = SEARCH_MANAGED_ENTITIES_SUGGESTIONS_DICTIONARY[key];
          }

          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key,
            filterName: field.name,
            //SearchSuggestionsFieldType is only available if the field is filterable
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const stackItemsCount = useMemo(() => {
    switch (groupBy) {
      case IacManagementGroupBy.Provider:
        return (
          data?.searchManagedEntitiesSuggestions?.fields.find((item) => item.name === "provider")
            ?.possibleValuesString?.values.length || 0
        );
      case IacManagementGroupBy.Type:
        return (
          data?.searchManagedEntitiesSuggestions?.fields.find((item) => item.name === "type")
            ?.possibleValuesString?.values.length || 0
        );
      case IacManagementGroupBy.Stack:
      default:
        return (
          data?.searchManagedEntitiesSuggestions?.fields.find((item) => item.name === "stack")
            ?.possibleValuesString?.values.length || 0
        );
    }
  }, [data?.searchManagedEntitiesSuggestions?.fields, groupBy]);

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter((field) => field.orderable)
        .map((field) => {
          return {
            value: field.name,
            label: getFilterKey(field.name),
          };
        }) || []
    );
  }, [cachedFiltersData]);

  return {
    filters,
    filtersMap: stacksFiltersItemsOptionsMap,
    refetch,
    loading,
    itemsCount: stackItemsCount,
    sortOptions,
  };
};

export default useIacManagementFilters;
