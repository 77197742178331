import React, { ReactNode } from "react";
import cx from "classnames";

import { TooltipInfoVariant } from "ds/components/TooltipInfo";
import Box, { BoxProps } from "ds/components/Box";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import useEnsureId from "hooks/useEnsureId";
import TooltipInfo from "ds/components/TooltipInfo";
import { TooltipProps } from "ds/components/Tooltip/types";
import CopyFieldIcon from "components/CopyField/Icon";

import useAriaInputProps, { AriaInputProps } from "../useAriaInputProps";
import FormFieldLabel from "./Label";
import styles from "./styles.module.css";
import { FormFieldColor } from "./types";
import { getColors } from "./utils";
import FormFieldHelperText from "./HelperText";

type ChildrenProps = {
  ariaInputProps: AriaInputProps; // Spread these props on the input element
};

export type FormFieldProps = {
  id?: string; // Use only if you need to control the id of the input element, otherwise it will be generated for aria purposes
  tooltipInfo?: string | ReactNode;
  tooltipInfoVariant?: TooltipInfoVariant;
  color?: FormFieldColor;
  label?: string | ReactNode;
  error?: string;
  noMargin?: boolean;
  className?: string;
  isOptional?: boolean;
  helperText?: string | React.ReactNode;
  children: React.ReactNode | ((options: ChildrenProps) => React.ReactNode);
  fullWidth?: boolean;
  tooltipAnalyticsPage?: AnalyticsCommonProps["analyticsPage"];
  tooltipAnalyticsTitle?: AnalyticsCommonProps["analyticsTitle"];
  tooltipAnalyticsProps?: AnalyticsCommonProps["analyticsProps"];
  zeroMinWidth?: boolean;
  tooltipPlacement?: TooltipProps["placement"];
  tooltipWidthMode?: TooltipProps["widthMode"];
  tooltipMode?: TooltipProps["mode"];
  align?: BoxProps["align"];
  labelGrow?: BoxProps["grow"];
  withCopy?: string;
  onCopyCallback?: () => void;
};

const FormField = ({
  id: propsId,
  label,
  children,
  color = "default",
  error,
  helperText,
  noMargin,
  className,
  isOptional,
  tooltipPlacement,
  tooltipWidthMode,
  tooltipInfo,
  tooltipInfoVariant,
  tooltipAnalyticsPage,
  tooltipAnalyticsTitle,
  tooltipAnalyticsProps,
  tooltipMode,
  fullWidth,
  zeroMinWidth,
  align,
  labelGrow,
  withCopy,
}: FormFieldProps) => {
  const { primaryIconColor } = getColors(color);
  const id = useEnsureId(propsId);

  const ariaInputProps = useAriaInputProps({
    id,
    hasHelper: !!helperText,
    hasError: !!error,
    isOptional,
  });

  return (
    <Box
      gap="small"
      direction="column"
      fullWidth={fullWidth}
      zeroMinWidth={zeroMinWidth}
      /* TODO: remove "margin styling" for all form fields and use wrapper with gap property */
      {...(!noMargin && { margin: "x-large 0 0" })}
      className={cx(styles.formField, className)}
      align={align}
      relative
    >
      {label && (
        <Box gap="small" align="center" justify="between" fullWidth>
          <Box gap="small" align="center" grow={labelGrow}>
            <FormFieldLabel color={color} isOptional={isOptional} label={label} htmlFor={id} />

            {tooltipInfo && (
              <TooltipInfo
                mode={tooltipMode}
                placement={tooltipPlacement}
                variant={tooltipInfoVariant}
                iconColor={primaryIconColor}
                analyticsPage={tooltipAnalyticsPage}
                analyticsTitle={tooltipAnalyticsTitle}
                analyticsProps={tooltipAnalyticsProps}
                widthMode={tooltipWidthMode}
              >
                {tooltipInfo}
              </TooltipInfo>
            )}
          </Box>

          {withCopy && <CopyFieldIcon title="Copy" value={withCopy} />}
        </Box>
      )}

      {typeof children === "function" ? children({ ariaInputProps }) : children}

      <FormFieldHelperText error={error} helperText={helperText} color={color} id={id} />
    </Box>
  );
};

FormField.displayName = "DS.Form.Field";

export default FormField;
