import { useLazyQuery } from "@apollo/client";

import shouldPollHelper from "./shouldPoll";
import { UsePolledLazyQuery } from "./types";
import usePollingInterval from "./usePollingInterval";
import usePollingManagement from "./usePollingManagement";

const usePolledLazyQuery: UsePolledLazyQuery = (query, options) => {
  const {
    pollInterval: overridePollInterval,
    pollingGroup,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldPoll, // not needed in useLazyQuery
    ...restOptions
  } = options;
  const queryResult = useLazyQuery(query, restOptions);

  const pollInterval = usePollingInterval(query, pollingGroup, overridePollInterval);

  const [, result] = queryResult;

  usePollingManagement(
    pollInterval,
    result.startPolling,
    result.stopPolling,
    shouldPollHelper(options, result),
    result.refetch
  );

  return queryResult;
};

export default usePolledLazyQuery;
