import cx from "classnames";

import { ExpandLeft, ExpandRight } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";

import styles from "./styles.module.css";
import { BlueprintEditorPanelSide } from "./types";

type BlueprintEditorPanelExpandButtonProps = {
  isExpanded: boolean;
  toggleExpand: () => void;
  side: BlueprintEditorPanelSide;
  wrapperId: string;
};

const BlueprintEditorPanelExpandButton = ({
  side,
  toggleExpand,
  isExpanded,
  wrapperId,
}: BlueprintEditorPanelExpandButtonProps) => {
  return (
    <ButtonIcon
      className={cx(styles.toggle, {
        [styles.expanded]: isExpanded,
      })}
      icon={side === "right" ? ExpandLeft : ExpandRight}
      iconRotate={isExpanded ? "180" : undefined}
      onPress={toggleExpand}
      aria-expanded={isExpanded}
      aria-controls={wrapperId}
      variant="ghost"
    >
      {isExpanded ? "Collapse" : "Expand"}
    </ButtonIcon>
  );
};

export default BlueprintEditorPanelExpandButton;
