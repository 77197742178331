import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { cloneDeep } from "lodash-es";

import { setUpdatedFiltersUrlParams } from "components/Filters/helpers";
import { ActiveFiltersMap } from "components/Filters/types";
import { StackSuggestions } from "constants/stack";
import { SearchSuggestionsFieldType, StackState } from "types/generated";
import { URL_FILTER_TAB_KEY, URL_SEARCH_KEY } from "constants/url_query_keys";
import useTypedContext from "hooks/useTypedContext";
import { FiltersContext } from "components/Filters";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";

export enum PredefinedFilterType {
  AllStacks = "allStacks",
  NeedsAttention = "needsAttention",
  Failed = "failed",
  OnHold = "onHold",
  InProgress = "inProgress",
  Finished = "finished",
}

const usePredefinedFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeFilters } = useTypedContext(FiltersContext);

  const predefinedFilterTab = searchParams.get(URL_FILTER_TAB_KEY);

  const handleUpdateUrlParams = useCallback(
    (activeFilters: ActiveFiltersMap, filterTabKey: string) => {
      if (filterTabKey) {
        searchParams.set(URL_FILTER_TAB_KEY, filterTabKey);
        searchParams.delete(URL_SEARCH_KEY);
      } else {
        searchParams.delete(URL_FILTER_TAB_KEY);
      }

      const updatedParams = setUpdatedFiltersUrlParams(
        activeFilters,
        searchParams,
        sessionStorage,
        FILTERS_ORDER_SETTINGS_KEY
      );

      setSearchParams(updatedParams);
    },
    [searchParams, setSearchParams]
  );

  const setPredefinedFilter = useCallback(
    (type: PredefinedFilterType) => {
      if (
        type === predefinedFilterTab ||
        (type === PredefinedFilterType.AllStacks && !predefinedFilterTab)
      ) {
        return;
      }

      const clonedActiveFilters = cloneDeep(activeFilters);

      switch (type) {
        case PredefinedFilterType.Failed: {
          clonedActiveFilters.set(StackSuggestions.State, {
            key: StackSuggestions.State,
            filterName: StackSuggestions.State,
            type: SearchSuggestionsFieldType.Enum,
            values: [StackState.Failed],
          });

          break;
        }
        case PredefinedFilterType.NeedsAttention: {
          clonedActiveFilters.set(StackSuggestions.State, {
            key: StackSuggestions.State,
            filterName: StackSuggestions.State,
            type: SearchSuggestionsFieldType.Enum,
            values: [StackState.Unconfirmed],
          });
          break;
        }

        case PredefinedFilterType.OnHold: {
          clonedActiveFilters.set(StackSuggestions.State, {
            key: StackSuggestions.State,
            filterName: StackSuggestions.State,
            type: SearchSuggestionsFieldType.Enum,
            values: [StackState.Confirmed, StackState.None, StackState.ReplanRequested],
          });
          break;
        }

        case PredefinedFilterType.InProgress: {
          clonedActiveFilters.set(StackSuggestions.State, {
            key: StackSuggestions.State,
            filterName: StackSuggestions.State,
            type: SearchSuggestionsFieldType.Enum,
            values: [
              StackState.Applying,
              StackState.Destroying,
              StackState.Initializing,
              StackState.Planning,
              StackState.PreparingApply,
              StackState.PreparingReplan,
            ],
          });
          break;
        }
        case PredefinedFilterType.Finished: {
          clonedActiveFilters.set(StackSuggestions.State, {
            key: StackSuggestions.State,
            filterName: StackSuggestions.State,
            type: SearchSuggestionsFieldType.Enum,
            values: [StackState.Finished],
          });
          break;
        }
        case PredefinedFilterType.AllStacks: {
          clonedActiveFilters.delete(StackSuggestions.State);
          break;
        }
        default:
          break;
      }

      handleUpdateUrlParams(clonedActiveFilters, type);
    },
    [handleUpdateUrlParams, predefinedFilterTab, activeFilters]
  );

  return {
    predefinedFilterTab,
    setPredefinedFilter,
  };
};

export default usePredefinedFilters;
