import { Cross } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";

type BulkActionsDrawerItemDismissProps = {
  onDismiss: () => void;
  tooltip?: string;
};

const BulkActionsDrawerItemDismiss = ({
  onDismiss,
  tooltip = "Unselect",
}: BulkActionsDrawerItemDismissProps) => {
  return (
    <ButtonIcon icon={Cross} onPress={onDismiss} variant="ghostDanger">
      {tooltip}
    </ButtonIcon>
  );
};

export default BulkActionsDrawerItemDismiss;
