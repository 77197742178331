import { Download } from "components/icons/generated";
import LinkButton from "ds/components/Button/LinkButton";
import { AspectType } from "types/generated";

import { TimeFilter } from "../types";
import { getCSVDownloadLink } from "./utils";

type CSVDownloadButtonProps = {
  timeFilter: TimeFilter;
  aspectType: AspectType;
};

const CSVDownloadButton = ({ aspectType, timeFilter }: CSVDownloadButtonProps) => {
  const link = getCSVDownloadLink(aspectType, timeFilter);

  return (
    <LinkButton to={link} target="_blank" variant="secondary" startIcon={Download} download>
      Export CSV
    </LinkButton>
  );
};

export default CSVDownloadButton;
