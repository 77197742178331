import { useModal } from "@ebay/nice-modal-react";
import { FormProvider } from "react-hook-form";

import Box from "ds/components/Box";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { Blueprint } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import DrawerForm from "ds/components/DrawerNew/Form";
import BlueprintShareButton from "views/Account/Blueprint/components/ShareButton";
import BlueprintTemplateForm from "views/Account/Blueprint/components/TemplateForm";
import useBlueprintTemplateForm from "views/Account/Blueprint/components/TemplateForm/useBlueprintTemplateForm";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";

import CreateStackButton from "./CreateStackButton";

type TemplatePreviewProps = {
  item: Blueprint;
  onEditMetadata: (item: Blueprint) => void;
};

const blueprintAnalyticsProps = {
  source: "drawer",
};

const CreateStackDrawer = createDrawer(({ item, onEditMetadata }: TemplatePreviewProps) => {
  const drawer = useModal();

  const onOpenFullDescription = useShowFullDescriptionWithBackArrow(item.description);

  const handleEditMetadata = () => {
    drawer.hide();
    onEditMetadata(item);
  };

  const { builderForm, onSubmit, stackCreateLoading, hasErrors, hasPreview, errors, inputs } =
    useBlueprintTemplateForm({ blueprint: item });

  const {
    handleSubmit,
    formState: { isDirty },
  } = builderForm;

  return (
    <DrawerForm isDirty={isDirty}>
      <FormProvider {...builderForm}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Create a stack" />
            <Box direction="row" gap="medium">
              <BlueprintShareButton
                analyticsProps={blueprintAnalyticsProps}
                blueprintId={item.id}
                size="small"
                variant="ghost"
              />
              <DrawerCloseIcon />
            </Box>
          </DrawerHeader>
          <DrawerBody fullHeight shouldFocusOnEnter={true}>
            <BlueprintTemplateForm
              item={item}
              onOpenFullDescription={onOpenFullDescription}
              isPublished={true}
              handleEditDescription={handleEditMetadata}
              hasErrors={hasErrors}
              hasPreview={hasPreview}
              errors={errors}
              inputs={inputs}
            />
            {!hasErrors && (
              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  &nbsp;&nbsp;
                  <CreateStackButton isPublished={true} stackCreateLoading={stackCreateLoading} />
                </DrawerFooterActions>
              </DrawerFooter>
            )}
          </DrawerBody>
        </form>
      </FormProvider>
    </DrawerForm>
  );
});

export const showCreateStackDrawer = createDrawerTrigger(CreateStackDrawer);
