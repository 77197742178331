import { useMemo } from "react";

import CardWrapper from "components/CardWrapper";
import DocumentationButton from "components/DocumentationButton";
import { EmptystateCloudColored } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Callout from "ds/components/Callout";
import EmptyState from "ds/components/EmptyState";
import useTypedContext from "hooks/useTypedContext";
import { isSelfHostedDistribution } from "utils/distribution";
import { getDocsUrl } from "utils/getDocsUrl";
import CloudDetails from "components/AttachCloud/Details";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";
import useAttachableCloudIntegrations from "shared/CloudIntegration/useAttachableCloudIntegrations";

import ModuleSettingsFormWrapper from "../components/FormWrapper";
import { ModuleSettingsContext } from "../Context";
import { showModuleSettingsIntegrationsAttachCloudIntegrationsDrawer } from "./AttachCloudIntegrationsDrawer";
import ModuleSettingsIntegrationsPageLayout from "./components/PageLayout";
import useAttachedCloudIntegrations from "./hooks/useAttachedCloudIntegrations";
import useAttachedGcpIntegration from "./hooks/useAttachedGcpIntegration";

const isSelfHosted = isSelfHostedDistribution();

const ModuleSettingsIntegrationsCloudIntegrations = () => {
  const { module, canManageModule } = useTypedContext(ModuleSettingsContext);

  const {
    loading: cloudConfigLoading,
    cloudConfig,
    attachableAzureIntegrations,
    attachableAwsIntegrations,
    hasData,
    refetch,
    refetching,
  } = useAttachableCloudIntegrations(module.spaceDetails.id);

  const { loading: integrationsLoading, integrations } = useAttachedCloudIntegrations(module.id);

  const { loading: gcpIntegrationLoading, integration: gcpIntegration } = useAttachedGcpIntegration(
    module.id
  );

  const attachedIds = useMemo(
    () => integrations.map((integration) => integration.integrationId),
    [integrations]
  );

  const filteredAwsIntegrations = useMemo(
    () => attachableAwsIntegrations.filter((integration) => !attachedIds.includes(integration.id)),
    [attachableAwsIntegrations, attachedIds]
  );

  const filteredAzureIntegrations = useMemo(
    () =>
      attachableAzureIntegrations.filter((integration) => !attachedIds.includes(integration.id)),
    [attachableAzureIntegrations, attachedIds]
  );

  const hasSomeIntegrationsAttached = integrations.length > 0 || gcpIntegration?.activated;

  const hasAttachableCloudIntegrations = hasData && cloudConfig.length > 0;

  const canAttachGcpIntegration = !gcpIntegration?.activated && !isSelfHosted;

  const canAttach =
    (filteredAwsIntegrations.length > 0 ||
      filteredAzureIntegrations.length > 0 ||
      canAttachGcpIntegration) &&
    canManageModule;

  const loading = cloudConfigLoading || integrationsLoading || gcpIntegrationLoading;

  const handleAttachIntegrationDrawer = () => {
    showModuleSettingsIntegrationsAttachCloudIntegrationsDrawer({
      moduleId: module.id,
      hasData,
      refetch,
      refetching,
      cloudConfig,
      filteredAzureIntegrations,
      filteredAwsIntegrations,
      canAttachGcpIntegration,
    });
  };

  return (
    <>
      <ModuleSettingsIntegrationsPageLayout
        actions={
          <Button
            variant="primary"
            disabled={!canAttach || loading}
            onPress={handleAttachIntegrationDrawer}
          >
            Attach cloud integration
          </Button>
        }
      >
        {!loading && (
          <Callout variant="info">
            You can only attach integrations from the current space and parent spaces that you
            inherit from.
          </Callout>
        )}

        {loading && <PageLoading />}

        {!loading && hasAttachableCloudIntegrations && !hasSomeIntegrationsAttached && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateCloudColored}
                title="You do not have any attached cloud integrations yet"
                caption="You can attach one of the existing ones, or create a new one."
              >
                <Box gap="medium">
                  {canManageModule && (
                    <Button variant="primary" onPress={handleAttachIntegrationDrawer}>
                      Attach cloud integration
                    </Button>
                  )}
                  <DocumentationButton
                    to={getDocsUrl("/integrations/cloud-providers")}
                    label="Documentation"
                  />
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}

        {!loading && !hasAttachableCloudIntegrations && !hasSomeIntegrationsAttached && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateCloudColored}
                title="You do not have any cloud integrations yet"
                caption={
                  <>
                    Cloud Integrations are used to dynamically generate short-lived credentials to
                    authenticate to cloud providers (AWS/Azure/GCP currently supported). In this way
                    you avoid using static credentials and by doing so, you reduce breaches.
                    <br /> Read more in the Documentation.
                  </>
                }
              >
                <DocumentationButton
                  to={getDocsUrl("/integrations/cloud-providers")}
                  label="Documentation"
                />
              </EmptyState>
            </CardWrapper>
          </Box>
        )}

        {!loading && hasSomeIntegrationsAttached && (
          <ModuleSettingsFormWrapper gap="large">
            {integrations.map((integration) => (
              <CloudDetails
                key={integration.id}
                cloudConfig={cloudConfig}
                attachedIntegrationType={
                  integration.__typename === "StackAzureIntegrationAttachment"
                    ? CLOUD_INTEGRATIONS.Azure
                    : CLOUD_INTEGRATIONS.AWS
                }
                attachedIntegration={integration}
                entityId={module.id}
                refetchQueries={["GetAttachedModuleIntegrations"]}
                readOnly={!canManageModule}
              />
            ))}
            {gcpIntegration?.activated && (
              <CloudDetails
                cloudConfig={cloudConfig}
                attachedIntegrationType={CLOUD_INTEGRATIONS.GCP}
                attachedIntegration={gcpIntegration}
                entityId={module.id}
                refetchQueries={["GetModuleAttachedGcpIntegration"]}
                readOnly={!canManageModule}
              />
            )}
          </ModuleSettingsFormWrapper>
        )}
      </ModuleSettingsIntegrationsPageLayout>
    </>
  );
};

export default ModuleSettingsIntegrationsCloudIntegrations;
