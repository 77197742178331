import { AccountContext } from "views/AccountWrapper";
import { BillingTierFeature, Blueprint, BlueprintState, SpaceAccessLevel } from "types/generated";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useTypedContext from "hooks/useTypedContext";
import useTierFeature from "views/Account/hooks/useTierFeature";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import BlueprintShareDropdownMenuItem from "views/Account/Blueprint/components/ShareButton/DropdownMenuItem";

import { showDeleteConfirmation } from "../DeleteConfirmation";

type BlueprintsListItemDropdownProps = {
  item: Blueprint;
  onEditMetadata: (item: Blueprint) => void;
  onCloneBlueprint: (item: Blueprint) => void;
};

/**
 * @deprecated: blueprintsTableRedesign - file to be removed, move to new Table implementation
 */
const BlueprintsListItemDropdown = (props: BlueprintsListItemDropdownProps) => {
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);
  const { item, onCloneBlueprint, onEditMetadata } = props;
  const { viewer } = useTypedContext(AccountContext);

  const canManageBlueprint =
    item.space.accessLevel === SpaceAccessLevel.Admin ||
    item.space.accessLevel === SpaceAccessLevel.Write;

  if (!viewer.admin && !canManageBlueprint) {
    return null;
  }

  return (
    <DropdownMenuEllipsis tooltip="Blueprint actions">
      {isFeatureActive && (
        <DropdownMenuItem onAction={() => onEditMetadata(item)}>Edit metadata</DropdownMenuItem>
      )}
      {isFeatureActive && (
        <DropdownMenuItem onAction={() => onCloneBlueprint(item)}>Clone</DropdownMenuItem>
      )}
      {isFeatureActive && item.state === BlueprintState.Published && (
        <BlueprintShareDropdownMenuItem blueprintId={item.id} />
      )}
      <DropdownMenuItem
        onAction={() => showDeleteConfirmation({ name: item.name, id: item.id })}
        danger
      >
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default BlueprintsListItemDropdown;
