import { useNavigate } from "react-router-dom";

import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import { showDeleteConfirmation } from "views/Account/Integrations/components/ListItem/DeleteConfirmation";
import { ExternalIntegration } from "types/generated";

import { showIntegrationDetailsDrawer } from "../Drawer";

type IntegrationActionDropdownProps = {
  integration: ExternalIntegration;
  size?: "small" | "medium";
  canManageIntegration: boolean;
  refetchQueriesOnDelete?: string[];
  trackAnalytics: (event: string) => void;
  showDetails?: boolean;
};

const IntegrationActionDropdown = ({
  size = "small",
  canManageIntegration,
  refetchQueriesOnDelete,
  trackAnalytics,
  integration,
  showDetails = true,
}: IntegrationActionDropdownProps) => {
  const navigate = useNavigate();

  const copyFieldCallback = () => trackAnalytics("Copy ID");
  const attachedEntities = integration.attachedBlueprints.length;

  const handleDelete = () => {
    trackAnalytics("Start Delete");
    showDeleteConfirmation({
      id: integration.id,
      attachedEntities,
      name: integration.name,
      refetchQueriesOnDelete,
    }).then((finished) => {
      if (finished) {
        navigate(`/integrations`);
        trackAnalytics("Confirm Delete");
      } else {
        trackAnalytics("Cancel Delete");
      }
    });
  };

  const openDetailsDrawer = () => {
    showIntegrationDetailsDrawer({
      integration,
      id: integration.id,
    });
  };

  return (
    <DropdownMenuEllipsis tooltip="Integration actions" dotsSize={size}>
      <CopyFieldDropdownMenuItem
        title="Copy ID"
        value={integration.id}
        onCopy={copyFieldCallback}
      />
      {showDetails && <DropdownMenuItem onAction={openDetailsDrawer}>See details</DropdownMenuItem>}
      <DropdownMenuItem
        tooltip={
          !canManageIntegration
            ? "You don't have an access to manage this integration"
            : "Before deleting integration assure it is not attached to any blueprints."
        }
        isDisabled={!canManageIntegration || !!attachedEntities}
        isTooltipActive={!canManageIntegration || !!attachedEntities}
        onAction={handleDelete}
        danger
      >
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default IntegrationActionDropdown;
