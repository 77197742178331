import { NetworkStatus } from "@apollo/client";

import usePolledQuery from "apollo/usePolledQuery";
import { PollingIntervalGroups } from "apollo/constants";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { PublicWorkerPool } from "types/generated";

import { GET_PUBLIC_WORKER_POOL } from "./gql";

const useSearchPublicWorkerPool = () => {
  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data, networkStatus, previousData } = usePolledQuery<{
    publicWorkerPool: PublicWorkerPool;
  }>(GET_PUBLIC_WORKER_POOL, {
    onError,
    pollingGroup: PollingIntervalGroups.SingleEntities,
    // APOLLO CLIENT UPDATE
  });

  return {
    publicWorkerPool: data?.publicWorkerPool || previousData?.publicWorkerPool,
    error,
    isPageLoading: loading && !data?.publicWorkerPool && networkStatus === NetworkStatus.loading,
  };
};

export default useSearchPublicWorkerPool;
