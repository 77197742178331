import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import { InfoCircle } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import TierInfo from "components/TierInfo";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ButtonIcon from "ds/components/ButtonIcon";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature } from "types/generated";
import { isSaasDistribution } from "utils/distribution";
import useTierFeature from "views/Account/hooks/useTierFeature";
import { SubscriptionContext } from "views/Account/SubscriptionWrapper";
import { AccountContext } from "views/AccountWrapper";

import { SettingsContext } from "../Context";
import { UserManagementActivationStatus } from "../types";
import { SSO_BACKUP_CREDENTIALS_KEY, SSO_SAML_INFO_KEY, SSO_SETTINGS_INFO_KEY } from "./constants";
import { GET_SSO_SETTINGS } from "./gql";
import SettingsSingleSignOnOIDC from "./OIDC";
import SettingsSingleSignOnSAML from "./SAML";

const isSaaS = isSaasDistribution();

const SettingsSingleSignOn = () => {
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Organization Settings · Single sign-on · ${accountName}`);

  const isTierContainSAML = useTierFeature(BillingTierFeature.CustomSsoSaml);

  const navigate = useNavigate();
  const { onError } = useTypedContext(FlashContext);
  const { activationStatus } = useTypedContext(SettingsContext);
  const { isTrial } = useTypedContext(SubscriptionContext);

  const { loading, data, error } = useQuery(GET_SSO_SETTINGS, {
    onError,
  });

  const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSSO,
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data) {
    return <PageLoading />;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  const hasSSOConfig = data.hasSSO;
  const hasSAMLConfig = !!data.samlSettings;
  const hasManagedUsers = data.managedUsers?.length > 0;

  return (
    <>
      {isSaaS && !isTierContainSAML && !hasSAMLConfig && (
        <TierInfo
          variant="promo"
          type="callout"
          feature={BillingTierFeature.CustomSsoSaml}
          title="Upgrade account to use SAML SSO 2.0"
        >
          Add extra layer of security to your account by enabling SAML SSO 2.0
        </TierInfo>
      )}

      {isSaaS && !isTierContainSAML && hasSAMLConfig && (
        <TierInfo
          type="callout"
          variant="danger"
          feature={BillingTierFeature.CustomSsoSaml}
          title="SAML SSO 2.0 is not supported by your plan"
        >
          You can still use or disable the integration, but to make changes, please upgrade to the{" "}
          <strong>Enterprise</strong> plan.
        </TierInfo>
      )}

      {isUserManagementActive && (
        <Callout
          variant="warning"
          title="User management is active"
          storageKey={SSO_SETTINGS_INFO_KEY}
          closeCallback={() => trackSegmentAnalyticsEvent("Callout Close")}
        >
          Please note that switching to single sign-on will invalidate all current user and group
          mapping rules.
        </Callout>
      )}

      {isTrial && (
        <Callout
          variant="warning"
          title="You are using the trial version"
          storageKey={SSO_SAML_INFO_KEY}
        >
          Once your trial has expired and your plan is no longer Enterprise it will not be possible
          to update the SAML configuration.
        </Callout>
      )}

      {!hasSSOConfig && (
        <Callout
          variant="warning"
          title="Ensure you have a backup set of credentials configured."
          storageKey={SSO_BACKUP_CREDENTIALS_KEY}
        >
          This might be useful for SSO misconfigurations or outages.
          <FeedbackActions>
            <Button variant="contrast" onPress={() => navigate("/apikeytoken")} size="small">
              Create an API Key
            </Button>
          </FeedbackActions>
        </Callout>
      )}

      <ViewHeader firstLevel>
        <Box align="center" gap="medium">
          <ViewHeaderTitle tag="h2">Single Sign-on</ViewHeaderTitle>
          <ButtonIcon icon={InfoCircle} variant="ghost">
            SSO allows you to connect your Spacelift account to an external identity provider. This
            allows your users to use their existing user accounts when logging into Spacelift.
          </ButtonIcon>
        </Box>
      </ViewHeader>

      <Box direction="column" padding="x-large" gap="x-large" limitWidth="medium">
        <SettingsSingleSignOnSAML
          integration={data.samlSettings || undefined}
          oidcAlreadyEnabled={!!data.oidcSettings}
        />
        <SettingsSingleSignOnOIDC
          hasManagedUsers={hasManagedUsers}
          integration={data.oidcSettings || undefined}
          samlAlreadyEnabled={hasSAMLConfig}
        />
      </Box>
    </>
  );
};

export default SettingsSingleSignOn;
