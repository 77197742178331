import Typography from "ds/components/Typography";

import { CellComponentProps } from "../types";

type RolePermissionsListItemActionCellProps = CellComponentProps;

const RolePermissionsListItemActionCell = ({
  permission,
}: RolePermissionsListItemActionCellProps) => {
  return (
    <Typography tag="p" variant="p-body3">
      {permission.name}
    </Typography>
  );
};

export default RolePermissionsListItemActionCell;
