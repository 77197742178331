import { ArrowLeft } from "components/icons/generated";
import Button from "ds/components/Button";

type VCSIntegrationsBackButtonProps = {
  onClick: () => void;
};

const VCSIntegrationsBackButton = ({ onClick }: VCSIntegrationsBackButtonProps) => {
  return (
    <Button variant="secondary" startIcon={ArrowLeft} onPress={onClick}>
      Back
    </Button>
  );
};

export default VCSIntegrationsBackButton;
