import WarningContext from "components/WarningContext";
import Button, { ButtonProps } from "ds/components/Button";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import useTypedContext from "hooks/useTypedContext";

import { showNewModuleFooterConfirmation } from "./Confirmation";

type NewModuleFooterContinueButtonProps = {
  onClickHandler: () => void;
} & ButtonProps;

const NewModuleFooterContinueButton = ({
  onClickHandler,
  children,
  ...buttonProps
}: NewModuleFooterContinueButtonProps) => {
  const { warning, clearWarning } = useTypedContext(WarningContext);
  const onContinue = () => {
    onClickHandler();

    if (warning) {
      clearWarning();
    }
  };

  const onModalShow = () => {
    showNewModuleFooterConfirmation({
      onConfirm: onContinue,
      warning,
      confirmationButtonLabel: children?.toString(),
    });
  };

  return (
    <Button
      {...buttonProps}
      analyticsPage={AnalyticsPageModule.ModuleNew}
      onPress={warning ? onModalShow : onContinue}
    >
      {children}
    </Button>
  );
};

export default NewModuleFooterContinueButton;
