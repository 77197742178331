import { NetworkStatus } from "@apollo/client";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import { EmptystateStarColored } from "components/icons/generated";
import MissingDataBanner from "components/MissingDataBanner";
import StackStateBadgeNew from "components/StackStateBadge";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import Link from "ds/components/Link";
import TileContent from "ds/components/Tile/Content";
import TileWrapper from "ds/components/Tile/Wrapper";

import { Widget } from "../../types";
import DashboardWidgetsEmptyStateStacks from "../EmptyStateStacks";
import { GET_FAVORITE_STACKS } from "./gql";
import DashboardWidgetsFavoriteStacksSkeleton from "./Skeleton";
import styles from "./styles.module.css";
import { GetFavoriteStacks } from "./types";

const LIMIT = 5;

const DashboardWidgetsFavoriteStacks = () => {
  const { loading, data, error, refetch, networkStatus } = usePolledQuery<GetFavoriteStacks>(
    GET_FAVORITE_STACKS,
    {
      pollingGroup: PollingIntervalGroups.Dashboard,
    }
  );

  const showErrorBanner = !!error;

  const isLoading =
    (loading && !data?.metrics?.favouriteStacks) || networkStatus === NetworkStatus.refetch;

  const favouriteStacks = data?.metrics?.favouriteStacks?.slice(0, LIMIT);

  const isEmpty = !isLoading && !favouriteStacks?.length;

  const hasStacks = !!data?.metrics?.hasStacks;

  if (showErrorBanner) {
    return (
      <MissingDataBanner
        text="Couldn’t load favorite stacks. Please try to refresh or come back later. "
        refreshHandler={refetch}
        fullWidth
      />
    );
  }

  if (isLoading) {
    return <DashboardWidgetsFavoriteStacksSkeleton />;
  }

  return (
    <Box direction="column" fullWidth>
      {!hasStacks && <DashboardWidgetsEmptyStateStacks widget={Widget.FavoriteStacks} />}

      {hasStacks && isEmpty && (
        <TileWrapper>
          <Box padding="small" fullWidth justify="center">
            <EmptyState
              title="You don’t have any favorite stacks yet"
              icon={EmptystateStarColored}
              caption="Navigate to Stacks, select the ones you want to keep better track of and add them to favourites by clicking on the three dots menu and choosing “Add to favorites."
            >
              {/* TODO: [dashboard] userflow trigger */}
              {/* <Box gap="medium">
                <Button variant="primary">Show me how</Button>
              </Box> */}
            </EmptyState>
          </Box>
        </TileWrapper>
      )}

      {!isEmpty && (
        <Box direction="column" gap="medium">
          {favouriteStacks?.map(({ name, slug, state }, i) => (
            <TileWrapper key={i}>
              <TileContent>
                <Box justify="between" align="center" gap="medium">
                  <Link className={styles.stackLink} variant="secondary" href={`/stack/${slug}`}>
                    {name}
                  </Link>

                  <StackStateBadgeNew state={state} />
                </Box>
              </TileContent>
            </TileWrapper>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DashboardWidgetsFavoriteStacks;
