import EmptyState from "ds/components/EmptyState";
import { EmptystateShareColored } from "components/icons/generated";
import Box from "ds/components/Box";

const RolesEmpty = () => {
  return (
    <Box align="center" justify="center" grow="1">
      <EmptyState title="You don’t have any roles yet" icon={EmptystateShareColored} />
    </Box>
  );
};

export default RolesEmpty;
