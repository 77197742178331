import React, { useCallback, useContext } from "react";

import DocumentationIconButton from "components/DocumentationIconButton";
import { Gear, ThumbUp } from "components/icons/generated";
import SearchInput from "components/SearchInput";
import { TableContext } from "components/Table/Context";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import LinkButton from "ds/components/Button/LinkButton";
import ButtonIcon from "ds/components/ButtonIcon";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { getDocsUrl } from "utils/getDocsUrl";
import { AccountContext } from "views/AccountWrapper";

import { SpacesContext } from "../SpacesProvider";
import {
  FILTERS_ORDER_SETTINGS_KEY,
  STACK_LIST_CUSTOMIZE_VIEW_BUTTON_ID,
  STACK_LIST_NEW_FEEDBACK_ID,
} from "./constants";
import { showCustomizeViewDrawer } from "./CustomizeViewDrawer";
import styles from "./styles.module.css";

type StacksPageLayoutProps = {
  children: React.ReactNode;
  searchCallback?: () => void;
  onConfigReset?: () => void;
};

const StacksPageLayout = ({ children, searchCallback, onConfigReset }: StacksPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const tableContext = useContext(TableContext);

  useTitle(`Stacks · ${accountName}`);

  const handleOpenCustomizeDrawer = useCallback(() => {
    if (!onConfigReset || !tableContext?.mergedColumnsConfig) {
      return;
    }

    showCustomizeViewDrawer({
      columnConfig: tableContext.mergedColumnsConfig,
      onConfigReset,
    });
  }, [onConfigReset, tableContext?.mergedColumnsConfig]);

  return (
    <>
      <ViewHeader firstLevel className={styles.header}>
        <ViewHeaderTitle titleSize="p-t3">Stacks</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <SearchInput
            callback={searchCallback}
            placeholder="Search by name, ID or labels"
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />

          <DocumentationIconButton
            href={getDocsUrl("/concepts/stack")}
            tooltipText="Go to stacks documentation"
          />

          <ButtonIcon
            size="medium"
            variant="secondary"
            id={STACK_LIST_NEW_FEEDBACK_ID}
            icon={ThumbUp}
          >
            Leave feedback
          </ButtonIcon>

          <ButtonIcon
            size="medium"
            variant="secondary"
            onPress={handleOpenCustomizeDrawer}
            icon={Gear}
            id={STACK_LIST_CUSTOMIZE_VIEW_BUTTON_ID}
          >
            Customize list
          </ButtonIcon>

          {hasEntityCreateAccess && (
            <LinkButton
              to="/new/stack"
              variant="primary"
              analyticsPage={AnalyticsPageStack.StacksList}
              analyticsTitle="Create Stack"
            >
              Create stack
            </LinkButton>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>
      {children}
    </>
  );
};

export default StacksPageLayout;
