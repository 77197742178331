import { ReactNode, useCallback } from "react";

import Drawer, { DrawerProps } from "ds/components/Drawer";
import useTypedContext from "hooks/useTypedContext";

import { BulkActionsApiContext, BulkActionsDataContext } from "../Context";
import { BulkActionsStep, BulkActionsVariant } from "../types";
import { showBulkActionsNewDrawerResultsStepCloseConfirmationModal } from "./ResultsStep/CloseConfirmationModal";

type BulkActionsDrawerProps = {
  children: ReactNode;
  isHidden?: boolean;
} & Pick<DrawerProps, "position">;

const BulkActionsDrawer = ({
  children,
  isHidden,
  position = "fixedRight",
}: BulkActionsDrawerProps) => {
  const { isBulkActionsActive, bulkActionsVariant, bulkActionsStep, isExecutingBulkActions } =
    useTypedContext(BulkActionsDataContext);

  const { onContinueWith } = useTypedContext(BulkActionsApiContext);

  const isDrawerActionsStep =
    bulkActionsVariant === BulkActionsVariant.Drawer && bulkActionsStep !== BulkActionsStep.Results;
  const isDrawerResults = bulkActionsStep === BulkActionsStep.Results;

  const isDrawerVisible =
    !isHidden && isBulkActionsActive && (isDrawerActionsStep || isDrawerResults);

  const onOutsideClick = useCallback(() => {
    showBulkActionsNewDrawerResultsStepCloseConfirmationModal({
      isExecutingBulkActions,
      onConfirm: () => {
        onContinueWith("all");
      },
    });
  }, [isExecutingBulkActions, onContinueWith]);

  return (
    <Drawer
      onOutsideClick={bulkActionsStep === BulkActionsStep.Results ? onOutsideClick : undefined}
      disablePreventScroll
      position={position}
      visible={isDrawerVisible}
      mountOnEnter
    >
      {children}
    </Drawer>
  );
};

export default BulkActionsDrawer;
