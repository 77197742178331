import { useId, useRef, useState } from "react";

import { EmptystateStacksColored } from "components/icons/generated";
import IntersectionInfiniteList from "components/InfiniteList/Intersection";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DropdownWrapper from "ds/components/Dropdown/DropdownWrapper";
import DropdownEmpty from "ds/components/Dropdown/Empty";
import DropdownFooterSelection from "ds/components/Dropdown/Footer/Selection";
import DropdownHeaderSearch from "ds/components/Dropdown/Header/Search";
import DropdownList from "ds/components/Dropdown/List";
import EmptyState from "ds/components/EmptyState";
import ListBox from "ds/components/ListBox";
import ListBoxItem from "ds/components/ListBox/Item";
import LoadingIndicator from "ds/components/LoadingIndicator";
import TextEllipsis from "ds/components/TextEllipsis";
import CheckboxIcon from "ds/components/Toggle/CheckboxIcon";
import Typography from "ds/components/Typography";
import useEscapeKeypress from "hooks/useEscapeKeyPress";
import useOutsideClick from "hooks/useOutsideClick";
import { SpaceAccessLevel, Stack } from "types/generated";

import styles from "./styles.module.css";
import useSearchStacks from "./useSearchStacks";

type DependenciesDropdownProps = {
  onAddDependencies: (selectedIds: string[], callback: () => void) => void;
  exclude?: string[];
  requiredAccessLevel: SpaceAccessLevel;
};

const DependenciesDropdownV2 = ({
  onAddDependencies,
  exclude,
  requiredAccessLevel,
}: DependenciesDropdownProps) => {
  const wrapperRef = useRef(null);
  const dropdownId = useId();
  const listboxId = useId();
  const [isVisible, setIsVisible] = useState(false);

  const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set([]));

  const { stacks, handleLoadMore, hasMore, loading, searchInput, setSearchInput, isLoadingMore } =
    useSearchStacks(requiredAccessLevel, exclude);

  const handleToggleDropdown = () => {
    setIsVisible(!isVisible);
  };

  const closeDropdown = () => {
    setIsVisible(false);
  };

  const handleResetAll = () => {
    setSearchInput("");
    setSelectedValues(new Set());
  };

  const handleAddItems = () => {
    onAddDependencies(Array.from(selectedValues) as Array<string>, () => {
      handleResetAll();
      closeDropdown();
    });
  };

  const noSearchResults = stacks.length === 0 && searchInput.length > 0;
  const isEmpty = stacks.length === 0 && searchInput.length === 0;

  useOutsideClick(wrapperRef, closeDropdown);

  useEscapeKeypress(isVisible, closeDropdown);

  return (
    <DropdownWrapper ref={wrapperRef}>
      <Button
        variant="secondary"
        size="small"
        onPress={handleToggleDropdown}
        active={isVisible}
        aria-controls={dropdownId}
        aria-expanded={isVisible}
      >
        Add dependencies
      </Button>

      {isVisible && (
        <DropdownList
          id={dropdownId}
          ariaRole={null}
          active={isVisible}
          position="bottomLeft"
          className={styles.dropdownList}
        >
          <DropdownHeaderSearch
            listboxId={listboxId}
            ariaExpanded={true}
            ariaLabel="Stack autocomplete"
            searchPlaceholder="Search by stack name..."
            searchValue={searchInput}
            setSearchValue={setSearchInput}
            searchLoading={loading}
          />

          <IntersectionInfiniteList onLoadMore={handleLoadMore} hasMore={hasMore} loading={loading}>
            {(anchor) => (
              <Box className={styles.valuesList} direction="column">
                <ListBox<Stack>
                  id={listboxId}
                  aria-label="Stacks"
                  items={stacks}
                  selectionMode="multiple"
                  onSelectionChange={(set) => setSelectedValues(set as Set<string>)}
                  selectedKeys={Array.from(selectedValues)}
                  renderEmptyState={() => (
                    <>
                      {noSearchResults && (
                        <DropdownEmpty
                          icon={EmptystateStacksColored}
                          caption="There are no matching stacks. Try with different keyword."
                          announce
                        />
                      )}
                      {isEmpty && (
                        <EmptyState
                          caption="There are no available stacks."
                          icon={EmptystateStacksColored}
                        />
                      )}
                    </>
                  )}
                >
                  {(item) => (
                    <ListBoxItem id={item.id} textValue={item.name} highlightSelection={false}>
                      {({ isSelected }) => (
                        <Box fullWidth gap="medium">
                          <CheckboxIcon isSelected={isSelected} aria-hidden />
                          <TextEllipsis tooltip={item.name} tooltipWidthMode="maxWidthSm">
                            {(props) => (
                              <Typography
                                tag="span"
                                variant="p-body3"
                                color="primary"
                                align="left"
                                {...props}
                              >
                                {item.name}
                              </Typography>
                            )}
                          </TextEllipsis>
                        </Box>
                      )}
                    </ListBoxItem>
                  )}
                </ListBox>
                {isLoadingMore && (
                  <Box fullWidth align="center" justify="center" padding="medium">
                    <LoadingIndicator loading={isLoadingMore} size="large" />
                  </Box>
                )}
                {anchor}
              </Box>
            )}
          </IntersectionInfiniteList>

          {!isEmpty && (
            <DropdownFooterSelection
              selectedLength={selectedValues.size}
              onResetAll={handleResetAll}
              onAdd={handleAddItems}
            />
          )}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

export default DependenciesDropdownV2;
