import { ChevronDownSmall, ChevronUpDown, ChevronUpSmall } from "components/icons/generated";
import { AriaSort } from "types/Aria";
import { SearchQueryOrderDirection } from "types/generated";

export const getSortIcon = (sortDirection: SearchQueryOrderDirection | undefined) => {
  if (sortDirection === SearchQueryOrderDirection.Asc) {
    return ChevronDownSmall;
  }

  if (sortDirection === SearchQueryOrderDirection.Desc) {
    return ChevronUpSmall;
  }

  return ChevronUpDown;
};

export const getAriaSort = (sortDirection: SearchQueryOrderDirection | undefined): AriaSort => {
  if (sortDirection === SearchQueryOrderDirection.Asc) {
    return "ascending";
  }

  if (sortDirection === SearchQueryOrderDirection.Desc) {
    return "descending";
  }

  return "none";
};

export const reorderColumns = <Columns extends string>(
  columns: Columns[],
  columnId: Columns,
  direction: "left" | "right"
) => {
  const currentIndex = columns.indexOf(columnId);
  const newIndex = direction === "left" ? currentIndex - 1 : currentIndex + 1;

  const newColumnOrder = [...columns];
  newColumnOrder.splice(currentIndex, 1);
  newColumnOrder.splice(newIndex, 0, columnId);
  return newColumnOrder;
};
