import { useMemo, useState } from "react";

import Box from "ds/components/Box";
import DatepickerRange from "ds/components/Datepicker/Range";
import FormField from "ds/components/Form/Field";
import Button from "ds/components/Button";
import { Download } from "components/icons/generated";
import { DateRange } from "ds/components/Datepicker/types";
import { downloadFileFromURL } from "utils/file";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Spinner } from "components/Spinner";

import SelfHostedUsageExportPageLayout from "./PageLayout";
import { INITIAL_PERIOD, PERIODS } from "./constants";
import { validateDateRange } from "./validation";
import { dateToUTCTimestamp, getMinDate, getMaxDate, formatDateString } from "./utils";

const SelfHostedUsageExport = () => {
  const [dateRange, setDateRange] = useState<DateRange>(PERIODS[INITIAL_PERIOD].range);
  const [isExporting, setIsExporting] = useState(false);

  const { reportSuccess, reportError } = useTypedContext(FlashContext);

  const validationError = validateDateRange(dateRange.start, dateRange.end);

  const fileName = `customer_success_metrics_${formatDateString(dateRange.start)}_${formatDateString(dateRange.end)}.json`;

  const maxDate = getMaxDate();
  const minDate = getMinDate();

  const handleExportClick = async () => {
    if (validationError) return;

    try {
      setIsExporting(true);

      // Convert to UTC timestamps just for API
      const startTimestamp = dateToUTCTimestamp(dateRange.start);
      const endTimestamp = dateToUTCTimestamp(dateRange.end, true);

      const response = await fetch(
        `/selfhosted/metrics?start_timestamp=${startTimestamp}&end_timestamp=${endTimestamp}`
      );

      if (!response.ok) {
        throw new Error();
      }

      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
      downloadFileFromURL(url, fileName);

      URL.revokeObjectURL(url);

      reportSuccess({ message: "Usage data exported successfully" });
    } catch {
      reportError({ message: "Failed to export usage data" });
    } finally {
      setIsExporting(false);
    }
  };

  const helperText = useMemo(() => {
    if (isExporting) {
      return "Exporting may take a few minutes. Please keep this tab open.";
    }

    return "Please select the date range for the data you want to export.";
  }, [isExporting]);

  return (
    <SelfHostedUsageExportPageLayout>
      <Box direction="column" gap="xx-large" fullWidth>
        <FormField label="Select date range" error={validationError} helperText={helperText}>
          <DatepickerRange
            startDate={dateRange.start}
            endDate={dateRange.end}
            periods={PERIODS}
            initialPeriod={INITIAL_PERIOD}
            onChange={setDateRange}
            maxDate={maxDate}
            minDate={minDate}
            granularity="day"
            fullWidth
          />
        </FormField>
        <Box justify="end">
          {isExporting ? (
            <Button variant="primary" startIcon={Spinner} startIconSize="large" disabled>
              Exporting...
            </Button>
          ) : (
            <Button variant="primary" startIcon={Download} onPress={handleExportClick}>
              Export
            </Button>
          )}
        </Box>
      </Box>
    </SelfHostedUsageExportPageLayout>
  );
};

export default SelfHostedUsageExport;
