import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageCloudIntegrations } from "hooks/useAnalytics/pages/cloudIntegrations";

export const useCloudIntegrationsListAnalytics = (
  page = AnalyticsPageCloudIntegrations.CloudIntegrationsList
) => {
  return useAnalytics({
    page,
  });
};
