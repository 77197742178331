import { ForwardedRef, RefObject, useRef } from "react";

/**
 * Beware: This doesn't work with callback refs. ref={el => ref.current = el}
 */
const useEnsureRef = <Element>(forwardedRef: ForwardedRef<Element>) => {
  const localRef = useRef<Element>(null);
  return forwardedRef ? (forwardedRef as RefObject<Element>) : localRef;
};

export default useEnsureRef;
