import { createSelector } from "reselect";

import { ManagedEntity } from "types/generated";

import { IacTableStoreState } from "./types";
import { IacTableRow } from "./makeIacTableDataSlice/types";
import { IacTableServiceRowType } from "./makeIacTableMetaSlice/types";

export const selectTableRows = createSelector(
  (state: IacTableStoreState) => state.groups,
  (state: IacTableStoreState) => state.resources,
  (state: IacTableStoreState) => state.resourcesIdsPerGroup,
  (state: IacTableStoreState) => state.groupsMeta,
  (groups, resources, resourcesIdsPerGroup, groupsMeta): IacTableRow[] => {
    return groups.reduce((acc, group) => {
      const isGroupOpen = groupsMeta[group.id]?.isOpen;

      acc.push(group);

      if (isGroupOpen && resourcesIdsPerGroup[group.id]) {
        const items = resourcesIdsPerGroup[group.id];

        if (items.length) {
          acc.push(...items.map((resourceId) => resources[resourceId]));
        }
      }

      if (isGroupOpen && groupsMeta[group.id]?.hasMore) {
        acc.push({
          id: `${group.id}-service-load-more`,
          serviceType: IacTableServiceRowType.LoadMoreResources,
          groupId: group.id,
        });
      }

      return acc;
    }, [] as IacTableRow[]);
  }
);

export const selectTableRowByIndex = createSelector(
  selectTableRows,
  (_, index: number) => index,
  (tableRows, index: number): IacTableRow => tableRows[index]
);

export const selectHasGroupResources = createSelector(
  (state: IacTableStoreState, groupId: string) => state.resourcesIdsPerGroup[groupId],
  (resourcesIds): boolean => !!resourcesIds
);

export const selectResource = createSelector(
  (state: IacTableStoreState, resourceId: string) => state.resources[resourceId],
  (resource): ManagedEntity | undefined => resource
);
