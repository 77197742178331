import FormFieldViewText from "components/FormFields/ViewText";
import { BitbucketCloudIntegration } from "types/generated";

import { VCSCheckLabels } from "./helpers";

type VCSIntegrationsDetailsBitbucketCloudProps = {
  details: BitbucketCloudIntegration;
};

const VCSIntegrationsDetailsBitbucketCloud = ({
  details,
}: VCSIntegrationsDetailsBitbucketCloudProps) => {
  return (
    <>
      <FormFieldViewText label="Username" value={details.username} />

      <FormFieldViewText
        label="Webhook endpoint"
        value={details.webhookUrl}
        withCopy
        noTextEllipsis
        textWordBreak
      />

      <FormFieldViewText label="VCS checks" value={VCSCheckLabels[details.vcsChecks]} />
    </>
  );
};

export default VCSIntegrationsDetailsBitbucketCloud;
