import { ReactNode } from "react";

import PageWrapper from "components/PageWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";

type SelfHostedUsageExportPageLayoutProps = {
  children: ReactNode;
};

const SelfHostedUsageExportPageLayout = ({ children }: SelfHostedUsageExportPageLayoutProps) => {
  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderWrapper direction="row" align="center" fullWidth>
          <ViewHeaderTitle tag="h2">Usage export</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeader>

      <PageWrapper limitWidth="medium">{children}</PageWrapper>
    </>
  );
};

export default SelfHostedUsageExportPageLayout;
