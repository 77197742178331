import { SCHEDULING_TYPE, SchedulingItem } from "shared/Stack/Scheduling/types";
import { getHumanizedTimestamp, getNowTimeMs, unixTimestampToMs } from "utils/time";

export const getScheduleDrawerEntityTypePerItem = (item: SchedulingItem) => {
  if (item.__typename === "DriftDetectionIntegration") {
    return SCHEDULING_TYPE.DRIFT_DETECTION;
  }

  if (item.__typename === "ScheduledDelete") {
    return SCHEDULING_TYPE.DELETE_STACK;
  }

  if (item.__typename === "ScheduledRun") {
    return SCHEDULING_TYPE.RUN;
  }

  return SCHEDULING_TYPE.TASK;
};

export const getNextSchedule = (nextSchedule: number | undefined): string => {
  const timestampMs = nextSchedule && unixTimestampToMs(nextSchedule);
  const nowMs = getNowTimeMs();

  if (timestampMs && timestampMs > nowMs) {
    return `Starts ${getHumanizedTimestamp(timestampMs)}`;
  } else if (timestampMs && nowMs >= timestampMs) {
    return "Is starting";
  }

  return "Is in progress";
};
