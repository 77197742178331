import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { Navigate } from "react-router-dom";

import { EmptystateGridTilesColored } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import useTypedFlags from "hooks/useTypedFlags";
import { DragDropListProps } from "components/DragDropList/types";

import DragDropList from "../../../components/DragDropList";
import { DefaultDashboardTabs } from "./constants";
import { DashboardContext } from "./context";
import { getAllDashboardWidgets } from "./helpers";
import DashboardOverviewColumn from "./OverviewColumn";
import styles from "./styles.module.css";
import { DashboardColumns, Widget } from "./types";
import useCurrentTab from "./useCurrentTab";

const DashboardList = () => {
  const { openManageDrawer } = useTypedContext(DashboardContext);
  const currentTab = useCurrentTab();
  const { numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend } =
    useTypedFlags();

  const allWidgets = useMemo(
    () =>
      getAllDashboardWidgets({
        numberOfDeploymentsWidgetFrontend,
        approvalPolicyEvaluationsWidgetFrontend,
      }),
    [numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend]
  );

  const { configLoading, config, updateTabColumnsConfig } = useTypedContext(DashboardContext);

  const currentTabTitle = config[currentTab]?.title;

  useTitle(`${currentTabTitle ? `${currentTabTitle} · ` : ""}Dashboard`);

  const updateConfig = (columns: DashboardColumns) => updateTabColumnsConfig(currentTab, columns);

  if (configLoading) {
    return (
      <Box direction="column" padding="x-large" gap="x-large">
        <Skeleton width="calc(50% - 161px)" height={262} borderRadius={12} />
        <Skeleton width="calc(50% - 161px)" height={156} borderRadius={12} />
        <Skeleton width="calc(50% - 161px)" height={254} borderRadius={12} />
      </Box>
    );
  }

  const dragDropListConfig = config[currentTab];

  if (!dragDropListConfig) {
    return <Navigate to="/dashboard" />;
  }

  const { left, right, columnMode } = dragDropListConfig;

  return (
    <Box fullWidth className={styles.dashboardListWrapper} grow="1">
      <Box className={styles.dashboardList}>
        {dragDropListConfig && (
          <Box grow="1" className={styles.dashboardListContent}>
            <DragDropList
              options={allWidgets as DragDropListProps<Widget>["options"]}
              key={currentTab}
              onChange={updateConfig}
              columnMode={columnMode}
              leftColumn={left}
              rightColumn={right}
              itemClassName={styles.dashboardListItem}
              emptyState={
                <EmptyState
                  icon={EmptystateGridTilesColored}
                  title="Dashboard without widgets is no fun!"
                  caption={
                    <>
                      Click on the cog icon in the top right section of the header <br /> to manage
                      the widgets displayed on this view.
                    </>
                  }
                >
                  <Button
                    variant="secondary"
                    onPress={() =>
                      openManageDrawer(undefined, { method: "Empty state button click" })
                    }
                  >
                    Manage dashboard
                  </Button>
                </EmptyState>
              }
            />
          </Box>
        )}
        {currentTab === DefaultDashboardTabs.Overview && <DashboardOverviewColumn />}
      </Box>
    </Box>
  );
};

export default DashboardList;
