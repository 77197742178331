import { ThumbUp } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import IconTile from "ds/components/IconTile";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import { isUserflowEnabled } from "shared/Analytics";
import { isSelfHostedDistribution } from "utils/distribution";

import { DASHBOARD_OVERVIEW_COLUMN_PENDO_FEEDBACK_ID } from "../constants";
import styles from "./styles.module.css";

const DashboardOverviewColumnLeaveFeedback = () => {
  const isSelfHosted = isSelfHostedDistribution();
  const isUserflowReady = isUserflowEnabled();

  if (isSelfHosted || !isUserflowReady) {
    return null;
  }

  return (
    <TileWrapper grow="0" noBorder>
      <TileContent direction="column">
        <Box fullWidth margin="0 0 small 0" gap="medium" justify="between" align="start">
          <IconTile className={styles.blueWeakBackground} variant="secondary" icon={ThumbUp} />
          <Button
            size="medium"
            variant="secondary"
            id={DASHBOARD_OVERVIEW_COLUMN_PENDO_FEEDBACK_ID}
            analyticsPage={AnalyticsPageDashboard.Dashboard}
            analyticsTitle="Dashboard - Overview Column Leave Feedback Clicked"
          >
            Leave feedback
          </Button>
        </Box>
        <TileTitle variant="p-t6">Leave feedback</TileTitle>
        <Typography tag="span" variant="p-body3">
          Do you have any ideas or feedback that you would like to share with us?
        </Typography>
      </TileContent>
    </TileWrapper>
  );
};

export default DashboardOverviewColumnLeaveFeedback;
