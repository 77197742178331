import { NetworkStatus } from "@apollo/client";
import cx from "classnames";
import capitalize from "lodash-es/capitalize";
import { useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import MetricCard from "components/MetricCard";
import MissingDataBanner from "components/MissingDataBanner";
import Box from "ds/components/Box";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import { StackState } from "types/generated";

import { GET_STACKS_COUNT_BY_STATE } from "./gql";
import DashboardWidgetsStackStateRecentItems from "./RecentItems";
import styles from "./styles.module.css";
import { GetStacksCountByState } from "./types";

const SUPPORTED_STATES = [StackState.Failed, StackState.Unconfirmed, StackState.Finished];

const DashboardWidgetsStackState = () => {
  const [selectedState, setSelectedState] = useState<StackState | undefined>(StackState.Failed);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageDashboard.Dashboard,
  });

  const { loading, data, error, networkStatus, refetch } = usePolledQuery<GetStacksCountByState>(
    GET_STACKS_COUNT_BY_STATE,
    {
      pollingGroup: PollingIntervalGroups.Dashboard,
    }
  );

  const isLoading = (loading && !data?.metrics) || networkStatus === NetworkStatus.refetch;

  const handleCardSelect = useCallback(
    (value: StackState) => () => {
      trackSegmentAnalyticsEvent("Stack State Widget - Card select", {
        stackState: selectedState === value ? undefined : value,
        visible: String(selectedState !== value),
      });
      setSelectedState(selectedState === value ? undefined : value);
    },
    [selectedState, trackSegmentAnalyticsEvent]
  );

  const stacksCountByState = data?.metrics?.stacksCountByState;

  const metricValueByState: Partial<Record<StackState, number>> =
    stacksCountByState?.reduce(
      (acc, metric) => ({
        ...acc,
        [metric.labels[0]]: metric.value,
      }),
      {}
    ) ?? {};

  const showErrorBanner = !!error;

  return (
    <Box direction="column" fullWidth>
      {showErrorBanner && (
        <Box margin="0 0 x-large 0" direction="column" fullWidth>
          <MissingDataBanner
            text="Couldn’t load stacks state data. Please try to refresh or come back later. "
            refreshHandler={refetch}
          />
        </Box>
      )}

      <Box gap="x-large" fullWidth>
        {Object.values(SUPPORTED_STATES).map((value) => (
          <MetricCard
            key={value}
            grow="1"
            selected={selectedState === value}
            onClick={handleCardSelect(value)}
            titleIcon={<div className={cx(styles.dot, styles[value.toLowerCase()])} />}
            titleColor="primary"
            title={capitalize(value)}
            value={
              isLoading ? (
                <Skeleton count={1} height={28} width={40} style={{ margin: "5px 0" }} />
              ) : (
                (metricValueByState[value] ?? 0)
              )
            }
          />
        ))}
      </Box>

      {!showErrorBanner && selectedState && (
        <DashboardWidgetsStackStateRecentItems stackState={selectedState} />
      )}
    </Box>
  );
};

export default DashboardWidgetsStackState;
