import { memo } from "react";

import Typography from "ds/components/Typography";
import Link from "ds/components/Link";
import { ariaCellInteractionProps } from "ds/components/Table/useKeyboardNavigation/ariaCellInteractionProps";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import { type RenderGroupRowCellProps } from ".";
import IacManagementEmptyCellValue from "../../components/CellEmptyValue";
import useCellSpaceFilterAction from "../useCellSpaceFilterAction";
import IacManagementTableDropdownCell from "../../components/Table/Row/DropdownCell";
import useIacManagementAnalytics from "../../useIacManagementAnalytics";

type IacManagementTableGroupRowCellSpaceProps = Pick<RenderGroupRowCellProps, "row">;

const IacManagementTableGroupRowCellSpace = memo(
  ({ row }: IacManagementTableGroupRowCellSpaceProps) => {
    const { analyticPage } = useIacManagementAnalytics();

    const isStackRow = row.__typename === "ManagedEntitiesGroupedByStacks";
    const spaceId = (isStackRow && row.stackSpaceID) || undefined;
    const spaceName = (isStackRow && row.stackSpaceName) || spaceId;

    const spaceFilterAction = useCellSpaceFilterAction(spaceId);

    if (spaceId) {
      return (
        <IacManagementTableDropdownCell actions={spaceFilterAction}>
          <Link
            {...ariaCellInteractionProps}
            size="small"
            href={`/spaces/root${spaceId}`}
            analyticsPage={analyticPage}
            analyticsTitle="Space name link clicked"
            analyticsProps={{ driftsDetectedCount: row.drifted }}
          >
            <Typography variant="p-body3" tag="span">
              {spaceName}
            </Typography>
          </Link>
        </IacManagementTableDropdownCell>
      );
    }

    return (
      <IacManagementTableCell>
        <IacManagementEmptyCellValue />
      </IacManagementTableCell>
    );
  }
);

IacManagementTableGroupRowCellSpace.displayName = "IacManagementTableGroupRowCellSpace";

export default IacManagementTableGroupRowCellSpace;
