import useLocalStorage from "@rehooks/local-storage";
import { ReactNode, createContext, useCallback, useMemo } from "react";
import { Key, ResizableTableContainerProps, SortDescriptor } from "react-aria-components";

import { TrackAnalyticsEventProperties } from "shared/Analytics";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import { CustomizeViewConfig } from "components/CustomizeView/types";

import { ColumnConfig, ColumnConfigValue, ColumnSize, SavedTableConfig } from "./types";
import { getLocalStorageConfigSizesId } from "./utils";
import { useSyncColumnsConfig } from "./useSyncColumnsConfig";

type TableContextProps = {
  setSavedColumnConfig: (config: SavedTableConfig, method?: string) => void;
  savedColumnConfig: SavedTableConfig | null;
  visibleColumnIds: string[];
  visibleColumns: ColumnConfigValue[];
  mergedColumnsConfig: ColumnConfig;
  configurableColumnIds: string[];
  rowHeaderId: string;
  selectable?: boolean;
  onResizeEnd: ResizableTableContainerProps["onResizeEnd"];
  columnSizesConfig: Record<Key, ColumnSize> | null;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: (descriptor: SortDescriptor) => void;
};

export const TableContext = createContext<TableContextProps | undefined>(undefined);
TableContext.displayName = "TableContext";

type TableContextProviderProps<T extends string> = {
  children: ReactNode;
  columnsConfig: ColumnConfig;
  initialColumnViewConfig: CustomizeViewConfig<T>;
  beginningCustomColumns?: ColumnConfig | null;
  endCustomColumns?: ColumnConfig | null;
  rowHeaderId: string;
  localStorageId: string;
  selectable?: boolean;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: (descriptor: SortDescriptor) => void;
  analyticsPage?: AnalyticsPage;
  analyticsProps?: TrackAnalyticsEventProperties;
};

const TableContextProvider = <T extends string>({
  children,
  columnsConfig,
  initialColumnViewConfig,
  beginningCustomColumns = null,
  endCustomColumns = null,
  rowHeaderId,
  localStorageId,
  selectable,
  sortDescriptor,
  setSortDescriptor,
  analyticsPage,
  analyticsProps,
}: TableContextProviderProps<T>) => {
  const trackAnalytics = useAnalytics({
    page: analyticsPage,
    defaultCallbackTrackProperties: analyticsProps,
  });

  const [savedColumnConfig, setSavedColumnConfig] =
    useLocalStorage<SavedTableConfig>(localStorageId);

  const handleColumnConfigSave = useCallback(
    (config: SavedTableConfig | null, method?: string) => {
      trackAnalytics?.("Table configuration changed", {
        ...config,
        method,
      });
      setSavedColumnConfig(config);
    },
    [setSavedColumnConfig, trackAnalytics]
  );

  const [columnSizesConfig, setColumnSizesConfig] = useLocalStorage<Record<Key, ColumnSize>>(
    getLocalStorageConfigSizesId(localStorageId)
  );

  const trackResizedColumnChange = useCallback(
    (newConfig: Record<Key, ColumnSize>) => {
      if (!trackAnalytics) {
        return;
      }

      if (!columnSizesConfig) {
        return trackAnalytics("Column resized");
      }

      const key = Object.keys(newConfig).find((k) => newConfig[k] !== columnSizesConfig[k]);

      if (key) {
        trackAnalytics("Column resized", { column: key, columnSize: newConfig[key] });
      }
    },
    [trackAnalytics, columnSizesConfig]
  );

  const onResizeEnd = useCallback(
    (widths: Map<Key, ColumnSize>) => {
      const newRecord: Record<Key, ColumnSize> = {};
      for (const [key, value] of widths) {
        newRecord[key] = value;
      }

      setColumnSizesConfig(newRecord);
      trackResizedColumnChange(newRecord);
    },
    [setColumnSizesConfig, trackResizedColumnChange]
  );

  const allColumns = useMemo(() => {
    return { ...beginningCustomColumns, ...columnsConfig, ...endCustomColumns };
  }, [beginningCustomColumns, columnsConfig, endCustomColumns]);

  const visibleColumnIds = useMemo(() => {
    const initialVisibleColumnConfig = Object.keys(columnsConfig);
    const newConfig = [
      ...Object.keys(beginningCustomColumns || {}),
      ...(savedColumnConfig?.visible || initialVisibleColumnConfig),
      ...Object.keys(endCustomColumns || {}),
    ].filter((id) => !!allColumns[id]);

    // Header column needs to be visible
    if (!newConfig.includes(rowHeaderId)) {
      newConfig.push(rowHeaderId);
    }

    return newConfig;
  }, [
    beginningCustomColumns,
    savedColumnConfig?.visible,
    columnsConfig,
    endCustomColumns,
    allColumns,
    rowHeaderId,
  ]);

  const mergedColumnsConfig = useMemo(
    () => ({ ...beginningCustomColumns, ...columnsConfig, ...endCustomColumns }),
    [beginningCustomColumns, columnsConfig, endCustomColumns]
  );
  const visibleColumns = useMemo(() => {
    return visibleColumnIds.map((savedId) => ({
      ...allColumns[savedId],
      id: savedId,
    }));
  }, [visibleColumnIds, allColumns]);

  const configurableColumnIds = useMemo(() => Object.keys(columnsConfig), [columnsConfig]);

  const providerValue = useMemo(
    () => ({
      visibleColumnIds,
      visibleColumns,
      configurableColumnIds,
      mergedColumnsConfig,
      rowHeaderId,
      selectable,
      columnSizesConfig,
      onResizeEnd,
      sortDescriptor,
      setSortDescriptor,
      setSavedColumnConfig: handleColumnConfigSave,
      savedColumnConfig,
    }),
    [
      visibleColumnIds,
      visibleColumns,
      configurableColumnIds,
      mergedColumnsConfig,
      rowHeaderId,
      selectable,
      columnSizesConfig,
      onResizeEnd,
      sortDescriptor,
      setSortDescriptor,
      handleColumnConfigSave,
      savedColumnConfig,
    ]
  );

  useSyncColumnsConfig<T>({
    defaultConfig: initialColumnViewConfig,
    storageKey: localStorageId,
  });

  return <TableContext.Provider value={providerValue}>{children}</TableContext.Provider>;
};

export default TableContextProvider;
