import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { SearchQueryOrderDirection } from "types/generated";

export const ROLES_COLUMNS_STORAGE_KEY = "rolesColumnsConfig";
export const FILTERS_ORDER_SETTINGS_KEY = "rolesFiltersOrder";

export enum ROLES_COLUMN_ID {
  NAME = "NAME",
  DESCRIPTION = "DESCRIPTION",
  DROPDOWN = "DROPDOWN",
}

export const initialSortOption = "name";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const ROW_HEADER_ID = ROLES_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 600;

export const ROLES_COLUMN_CONFIG: ColumnConfig = {
  [ROLES_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    resizable: false,
  },

  [ROLES_COLUMN_ID.DESCRIPTION]: {
    title: "Description",
    minWidth: 130,
    maxWidth: MAX_CELL_WIDTH,
    resizable: false,
    static: true,
  },
};

export const ROLES_CUSTOM_END_COLUMN_CONFIG: ColumnConfig = {
  [ROLES_COLUMN_ID.DROPDOWN]: {
    title: "More actions",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
  },
};

export const SORT_CONFIG: Record<string, string> = {
  [ROLES_COLUMN_ID.NAME]: "name",
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(ROLES_COLUMN_CONFIG) as ROLES_COLUMN_ID[],
  hidden: [],
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageOrganization.OrganizationRoles,
};
