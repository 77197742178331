import { ReactNode } from "react";
import cx from "classnames";

import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";

type DrawerBodyProps = {
  children: ReactNode;
  className?: string;
} & Partial<BoxProps>;

const DrawerSection = ({
  children,
  direction = "column",
  justify = "start",
  padding = "x-large",
  className,
  ...restProps
}: DrawerBodyProps) => {
  return (
    <Box
      direction={direction}
      justify={justify}
      padding={padding}
      fullWidth
      className={cx(styles.section, className)}
      {...restProps}
    >
      {children}
    </Box>
  );
};

DrawerSection.displayName = "DS.Drawer.Section";

export default DrawerSection;
