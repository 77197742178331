import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { CLOUD_INTEGRATIONS_COLUMN_ID } from "../constants";
import CloudIntegrationsListNameCell from "./NameCell";
import CloudIntegrationsListDropdownActionsCell from "./DropdownActionsCell";
import { CloudIntegration } from "../../types";
import CloudIntegrationsListSpaceCell from "./SpaceCell";
import CloudIntegrationsListTypeCell from "./TypeCell";
import styles from "./styles.module.css";

const CELLS = {
  [CLOUD_INTEGRATIONS_COLUMN_ID.TYPE]: CloudIntegrationsListTypeCell,
  [CLOUD_INTEGRATIONS_COLUMN_ID.NAME]: CloudIntegrationsListNameCell,
  [CLOUD_INTEGRATIONS_COLUMN_ID.DROPDOWN]: CloudIntegrationsListDropdownActionsCell,
  [CLOUD_INTEGRATIONS_COLUMN_ID.SPACE]: CloudIntegrationsListSpaceCell,
};

type CloudIntegrationsListTableRowProps = {
  integration: CloudIntegration;
};

const CloudIntegrationsListTableRow = ({ integration }: CloudIntegrationsListTableRowProps) => {
  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as CLOUD_INTEGRATIONS_COLUMN_ID];
        const uniqueKey = `${id}-${integration.id}`;

        return (
          <TableCell key={uniqueKey} id={id} className={styles.tableCell}>
            <Component item={integration} />
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(CloudIntegrationsListTableRow);
