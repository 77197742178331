import { useForm } from "react-hook-form";
import { useEffect } from "react";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired } from "utils/formValidators";
import useTypedContext from "hooks/useTypedContext";
import { VENDOR_DICTIONARY } from "constants/vendor";
import useBlockNavigationModal from "hooks/useBlockNavigationModal";

import { StackSettingsContextData } from "../../Context";
import { getFormDefaultValues, mapCreateStackVendorUpdateInput } from "./helpers";
import StackSettingsFormFooter from "../../components/FormFooter";
import { type StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";
import { getPlaybookTooltip } from "./getTooltips";

export type StackSettingsAnsibleVendorFormFields = {
  playbook: string;
};

type StackSettingsVendorAnsibleEditProps = StackSettingsVendorComponentBaseProps;

const StackSettingsVendorAnsibleEdit = ({
  onStackUpdate,
  updateLoading,
}: StackSettingsVendorAnsibleEditProps) => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const ansibleVendorForm = useForm<StackSettingsAnsibleVendorFormFields>({
    defaultValues: getFormDefaultValues(stackSettings),
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    formState: { isDirty, errors },
    reset,
  } = ansibleVendorForm;

  useBlockNavigationModal({ isNavigationBlocked: isDirty });

  const onSubmit = (formData: StackSettingsAnsibleVendorFormFields) => {
    onStackUpdate(mapCreateStackVendorUpdateInput(formData));
  };

  useEffect(() => {
    reset(getFormDefaultValues(stackSettings));
  }, [reset, stackSettings]);

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.ANSIBLE} />
      <FormField
        label="Playbook"
        error={errors?.playbook?.message}
        {...getPlaybookTooltip()}
        noMargin
      >
        {({ ariaInputProps }) => (
          <Input
            placeholder="Ansible Playbook"
            {...register("playbook", {
              validate: stringIsRequired(),
              setValueAs: (value: string) => value.trim(),
            })}
            error={!!errors?.playbook?.message}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <StackSettingsFormFooter
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset(getFormDefaultValues(stackSettings))}
        isDirty={isDirty}
        isSubmitDisabled={updateLoading}
        submitLoading={updateLoading}
      />
    </>
  );
};

export default StackSettingsVendorAnsibleEdit;
