import {
  ClockRewind,
  Space,
  InfoCircle,
  User,
  Commit,
  FlagFilled,
  GitBranch,
} from "components/icons/generated";
import ManagedStateInfo from "components/ManagedStateInfo";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import MetaInfoProjectRoot from "components/MetaInfoList/ProjectRoot";
import MetaInfoVendor from "components/MetaInfoList/Vendor";
import {
  moduleProjectRootTooltipContent,
  projectRootTooltipContent,
  RunPrioritizedTooltip,
  RunProposedTooltip,
  RunTrackedTooltip,
} from "components/TooltipSnippets";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { RUN_TYPES } from "constants/run";
import { VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import useTypedContext from "hooks/useTypedContext";
import { VcsProvider } from "types/generated";
import TriggerByInfo from "components/TriggerByInfo";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import RunOriginDropdownLink from "views/shared/RunNext/components/RunOriginDropdownLink";
import { getCommmitDigitsCount } from "utils/commit";
import { isAnsibleStackVendor } from "utils/stack";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import TimestampMetaInfo from "components/Timestamp/MetaInfo";

import { RunContext } from "../Context";
import RunHeaderChangesV3Nav from "./ChangesV3Nav";
import styles from "./styles.module.css";
import RunHeaderNonDetailedChangesNav from "./NonDetailedChangesNav";
import RunHeaderAnsibleChangesNav from "./AnsibleChangesNav";
import { showRuntimeConfigDetailsDrawer } from "../RuntimeConfigDetailsDrawer";

type RunHeaderProps = {
  analyticsPage?: AnalyticsPage;
};

const RunHeader = ({ analyticsPage }: RunHeaderProps) => {
  const { run, stack } = useTypedContext(RunContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const isStack = stack.__typename === "Stack";
  const isTask = run.type === "TASK";
  const isAnsibleVendor = isStack && isAnsibleStackVendor(stack);

  const hasDetailedResources = run.supportsDetailedResources;

  const title = isTask ? run.command : run.title;
  const isTerraformVendor =
    isStack && stack.vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAFORM;

  const hasRuntimeConfig = !!run.runtimeConfig || !!run.customRuntimeConfig;

  return (
    <>
      <ViewHeader id="runViewHeader">
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle tooltipDelay={isTask ? 0 : 300}>{title}</ViewHeaderTitle>
            {run.isPrioritized && (
              <Tooltip widthMode="maxWidthSm" on={(props) => <Icon {...props} src={FlagFilled} />}>
                {RunPrioritizedTooltip}
              </Tooltip>
            )}
            <RunStateBadge state={run.state} />
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <DropdownMenuEllipsis tooltip="Run actions" dotsSize="medium">
              <RunOriginDropdownLink runId={run.id} runCreatedAt={run.createdAt} />
              {hasRuntimeConfig && (
                <DropdownMenuItem
                  onAction={() => {
                    trackSegmentAnalyticsEvent?.("Runtime config details clicked");
                    showRuntimeConfigDetailsDrawer({
                      runtimeConfig: run.runtimeConfig?.yaml,
                      customRuntimeConfig: run.customRuntimeConfig?.yaml,
                    });
                  }}
                >
                  Runtime config details
                </DropdownMenuItem>
              )}
              <CopyFieldDropdownMenuItem
                title="Copy run ID"
                value={run.id}
                onCopy={() => {
                  trackSegmentAnalyticsEvent?.("Copy run ID clicked");
                }}
              />
              <CopyFieldDropdownMenuItem
                title="Copy spacectl run logs command"
                value={`spacectl stack logs --id ${stack.id} --run ${run.id}`}
                onCopy={() => {
                  trackSegmentAnalyticsEvent?.("Copy spacectl run logs command clicked");
                }}
              />
            </DropdownMenuEllipsis>
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>
        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            {stack.spaceDetails && (
              <MetaInfoListItem
                icon={Space}
                href={`/spaces/${stack.spaceDetails.id}`}
                linkText={stack.spaceDetails.name}
              />
            )}
            {isStack && (
              <MetaInfoVendor
                vendorConfig={stack.vendorConfig}
                runtimeConfig={run.runtimeConfig}
                runtimeConfigTerraformWorkflowTool={run.runtimeConfig?.terraform?.workflowTool}
                runtimeConfigTerraformVersion={run.runtimeConfig?.terraform?.version || undefined}
              />
            )}

            {isStack && stack.projectRoot && (
              <MetaInfoProjectRoot
                projectRoot={stack.projectRoot}
                tooltip={projectRootTooltipContent}
              />
            )}

            {!isStack && stack.projectRoot && (
              <MetaInfoProjectRoot
                projectRoot={stack.projectRoot}
                tooltip={moduleProjectRootTooltipContent}
              />
            )}

            <MetaInfoListItem icon={GitBranch}>{run.branch}</MetaInfoListItem>

            <MetaInfoListItem
              href={run.commit.url}
              linkText={run.commit.hash.slice(0, getCommmitDigitsCount(stack.provider))}
              icon={Commit}
              target="_blank"
            />

            {run.commit.authorLogin && stack.provider === VcsProvider.Github && (
              <MetaInfoListItem
                icon={User}
                href={`https://github.com/${run.commit.authorLogin}`}
                linkText={run.commit.authorLogin}
                tooltip="Commit author"
              />
            )}

            {!run.commit.authorLogin ||
              (stack.provider !== VcsProvider.Github && (
                <MetaInfoListItem icon={User} tooltip="Commit author">
                  {run.commit.authorName}
                </MetaInfoListItem>
              ))}

            <TimestampMetaInfo
              prefix="Started"
              icon={ClockRewind}
              timestamp={run.createdAt}
              postfix={
                <TriggerByInfo
                  stackId={stack.id}
                  triggeredBy={run.triggeredBy || undefined}
                  driftDetection={run.driftDetection}
                />
              }
            />

            <TimestampMetaInfo
              prefix="Committed"
              icon={ClockRewind}
              timestamp={run.commit.timestamp}
            />

            {isTerraformVendor && stack.managesStateFile && (
              <MetaInfoListItem>
                <ManagedStateInfo />
              </MetaInfoListItem>
            )}

            {run.type === RUN_TYPES.TRACKED && (
              <MetaInfoListItem
                icon={InfoCircle}
                tooltip={RunTrackedTooltip}
                tooltipWidthMode="maxWidthSm"
              >
                <span className={styles.runType}>{run.type.toLocaleLowerCase()}</span>
              </MetaInfoListItem>
            )}
            {run.type === RUN_TYPES.PROPOSED && (
              <MetaInfoListItem
                icon={InfoCircle}
                tooltip={RunProposedTooltip}
                tooltipWidthMode="maxWidthSm"
              >
                <span className={styles.runType}>{run.type.toLocaleLowerCase()}</span>
              </MetaInfoListItem>
            )}
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
        {hasDetailedResources && !isAnsibleVendor && <RunHeaderChangesV3Nav />}
        {!hasDetailedResources && !isAnsibleVendor && <RunHeaderNonDetailedChangesNav />}
        {isAnsibleVendor && <RunHeaderAnsibleChangesNav analyticsPage={analyticsPage} />}
      </ViewHeader>
    </>
  );
};

export default RunHeader;
