import { External } from "components/icons/generated";
import { VCS_PROVIDERS_ICONS } from "constants/provider_icons";
import Link from "ds/components/Link";
import { generateBranchUrl, generateRepoUrlText } from "utils/urls";

import useStackListAnalytics from "../useStackListAnalytics";
import { CellComponentProps } from "./types";

type StackListItemRepositoryCellProps = CellComponentProps;

const StackListItemRepositoryCell = ({ stack }: StackListItemRepositoryCellProps) => {
  const trackAnalytics = useStackListAnalytics();

  if (!stack.apiHost) {
    return null;
  }

  return (
    <Link
      size="small"
      target="_blank"
      href={generateBranchUrl({
        apiHost: stack.apiHost,
        branch: stack.branch,
        namespace: stack.namespace,
        repository: stack.repository,
        repositoryURL: stack.repositoryURL || "",
        provider: stack.provider,
        projectRoot: stack.projectRoot,
      })}
      onPress={() => trackAnalytics("Repository link clicked")}
      startIcon={VCS_PROVIDERS_ICONS[stack.provider]}
      endIcon={External}
    >
      {generateRepoUrlText({
        namespace: stack.namespace,
        repository: stack.repository,
      })}
    </Link>
  );
};

export default StackListItemRepositoryCell;
