import React, { useCallback, useEffect, useMemo } from "react";
import { Location } from "react-router-dom";

import DocumentationIconButton from "components/DocumentationIconButton";
import { Diagram, List } from "components/icons/generated";
import SearchInput from "components/SearchInput";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Icon from "ds/components/Icon";
import SegmentedControl from "ds/components/SegmentedControl";
import Typography from "ds/components/Typography";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { getDocsUrl } from "utils/getDocsUrl";
import { isLegacySpace, isRootSpace } from "utils/space";
import { hasSpaceManageAccess } from "utils/user";
import { AccountContext } from "views/AccountWrapper";

import { SpacesContext } from "../SpacesProvider";
import { SpacesViewActionsContext } from "./ActionsContext";
import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import { SpacesViewContext } from "./Context";
import { showSpaceDrawer } from "./SpacesDrawer";
import styles from "./styles.module.css";
import { SpacesLayoutMode } from "./types";

const layoutModeOptions = [
  {
    label: (
      <Box gap="medium" align="center">
        <Icon src={List} />
        <Typography tag="span" variant="p-t7">
          List
        </Typography>
      </Box>
    ),
    value: SpacesLayoutMode.List,
    tooltip: "Switch to list view",
  },
  {
    label: (
      <Box gap="medium" align="center">
        <Icon src={Diagram} />
        <Typography tag="span" variant="p-t7">
          Diagram
        </Typography>
      </Box>
    ),
    value: SpacesLayoutMode.Diagram,
    tooltip: "Switch to diagram view",
  },
];

type SpacesPageLayoutProps = {
  children: React.ReactNode;
  setLayoutMode: (mode: SpacesLayoutMode) => void;
  layoutMode: SpacesLayoutMode;
};

const SpacesPageLayout = ({ children, setLayoutMode, layoutMode }: SpacesPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { manageableSpaces } = useTypedContext(SpacesContext);
  const {
    isDrawerVisible,
    currentSpace,
    drawerWriteMode,
    isRootAdmin,
    canNonRootAdminCreateSpace,
  } = useTypedContext(SpacesViewContext);
  const { onCreate: onSpaceCreate } = useTypedContext(SpacesViewActionsContext);

  useTitle(`Spaces · ${accountName}`);

  const { onCloseDrawer } = useTypedContext(SpacesViewActionsContext);

  const isCreateMode = drawerWriteMode === "create";
  const isEditMode = drawerWriteMode === "edit";

  const isPredefinedSpace =
    !!currentSpace?.id && (isRootSpace(currentSpace.id) || isLegacySpace(currentSpace.id));

  const shouldCloseOnLocationChange = useCallback(({ current }: { current: Location }) => {
    return !current.pathname.includes("/spaces");
  }, []);

  const userHasAccessToPredefinedSpace =
    currentSpace && hasSpaceManageAccess(currentSpace.accessLevel);

  const userHasAccessToSpaces = !!manageableSpaces.length;

  const canCreateSpaceFromScratch = !currentSpace && userHasAccessToSpaces;

  const isNonAdminRootCreateEnabled =
    isCreateMode &&
    canNonRootAdminCreateSpace &&
    (userHasAccessToPredefinedSpace || canCreateSpaceFromScratch);

  const currentSpaceParent =
    currentSpace?.parentSpace && manageableSpaces.find(({ id }) => id === currentSpace.parentSpace);
  const userHasAccessToCurrentSpaceParent =
    currentSpaceParent && hasSpaceManageAccess(currentSpaceParent.accessLevel);

  const isNonAdminRootEditEnabled =
    isEditMode && canNonRootAdminCreateSpace && userHasAccessToCurrentSpaceParent;

  const conditionalDrawerWriteMode = useMemo(() => {
    const isLegacyCurrentSpace = !!currentSpace?.id && isLegacySpace(currentSpace.id);
    const isRootCurrentSpace = !!currentSpace?.id && isRootSpace(currentSpace.id);

    if (isLegacyCurrentSpace || (isEditMode && isRootCurrentSpace)) {
      return undefined;
    }

    if (isRootAdmin || isNonAdminRootCreateEnabled || isNonAdminRootEditEnabled) {
      return drawerWriteMode;
    }

    return undefined;
  }, [
    currentSpace,
    isEditMode,
    isRootAdmin,
    isNonAdminRootCreateEnabled,
    isNonAdminRootEditEnabled,
    drawerWriteMode,
  ]);

  useEffect(() => {
    if (isDrawerVisible) {
      showSpaceDrawer({
        drawerWriteMode: conditionalDrawerWriteMode,
        space: currentSpace,
        isRootAdmin: isRootAdmin,
        layoutMode,
        onClose: onCloseDrawer,
        shouldCloseOnLocationChange,
      });
    }
  }, [
    isDrawerVisible,
    currentSpace,
    isCreateMode,
    conditionalDrawerWriteMode,
    isPredefinedSpace,
    isRootAdmin,
    layoutMode,
    onCloseDrawer,
    shouldCloseOnLocationChange,
    isNonAdminRootCreateEnabled,
  ]);

  const canCreateSpace = isRootAdmin || (canNonRootAdminCreateSpace && userHasAccessToSpaces);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Spaces</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          {layoutMode === SpacesLayoutMode.List && (
            <SearchInput
              placeholder="Search spaces by name or id"
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />
          )}

          <SegmentedControl
            optionClassName={styles.layoutSwitchOption}
            onChange={setLayoutMode}
            value={layoutMode}
            options={layoutModeOptions}
          />

          <DocumentationIconButton
            href={getDocsUrl("/concepts/spaces")}
            tooltipText="Go to spaces documentation"
          />

          {canCreateSpace && (
            <Button onPress={() => onSpaceCreate()} variant="primary">
              Create space
            </Button>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>

      {children}
    </>
  );
};

export default SpacesPageLayout;
