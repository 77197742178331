import BulkActionsDrawerItem from "components/BulkActions/Drawer/Item";
import BulkActionsDrawerItemLink from "components/BulkActions/Drawer/Item/Link";
import BulkActionsDrawerItemStatusIcon from "components/BulkActions/Drawer/Item/StatusIcon";
import BulkActionsDrawerItemStopExecutionButton from "components/BulkActions/Drawer/Item/StopExecutionButton";
import { BulkActionResultsMetadataMap } from "components/BulkActions/helpers";
import {
  BulkActionResultTabs,
  BulkActionsResult,
  BulkActionsStep,
} from "components/BulkActions/types";
import Box from "ds/components/Box";
import Feedback from "ds/components/Feedback";
import { Role } from "types/generated";

type RolesBulkActionsResultItemProps = {
  role: Role;
  tab: BulkActionResultTabs;
  resultsMetadata: BulkActionResultsMetadataMap;
  status: BulkActionsResult;
  stopExecution: () => void;
};

const RolesBulkActionsResultItem = ({
  role,
  tab,
  resultsMetadata,
  status,
  stopExecution,
}: RolesBulkActionsResultItemProps) => {
  const feedbackVariant = status === BulkActionsResult.Failed ? "danger" : "info";

  return (
    <BulkActionsDrawerItem key={role.id} direction="column" align="start">
      <Box align="center" gap="medium" fullWidth zeroMinWidth>
        <BulkActionsDrawerItemStatusIcon result={status} />
        <BulkActionsDrawerItemLink
          link={`/role/${role.id}`}
          text={role.name}
          step={BulkActionsStep.Results}
          itemCategory={tab}
        />
        <BulkActionsDrawerItemStopExecutionButton onStopExecution={stopExecution} status={status} />
      </Box>

      {resultsMetadata.has(role.id) && (
        <Feedback type="banner" variant={feedbackVariant} fullWidth>
          {resultsMetadata.get(role.id)!.message}
        </Feedback>
      )}
    </BulkActionsDrawerItem>
  );
};

export default RolesBulkActionsResultItem;
