import IconTile from "ds/components/IconTile";
import { LogoSlackColored } from "components/icons/generated";

import { CellComponentProps } from "../types";

type UserIntegrationsListItemTypeCellProps = CellComponentProps;

const UserIntegrationsListItemTypeCell = ({
  integration,
}: UserIntegrationsListItemTypeCellProps) => {
  if (integration.type === "Slack") {
    return <IconTile icon={LogoSlackColored} />;
  }

  return null;
};

export default UserIntegrationsListItemTypeCell;
