import { memo } from "react";

import { ChevronDown } from "components/icons/generated";
import Button from "ds/components/Button";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import { StackContext } from "views/Stack/Context";

import { StackSchedulingContextApi, StackSchedulingContextData } from "../Context";

type StackSchedulingCreateDropdownProps = {
  componentLocation?: "Header" | "Empty state";
};

const StackSchedulingCreateDropdown = ({
  componentLocation,
}: StackSchedulingCreateDropdownProps) => {
  const { canManageStackAndRuns } = useTypedContext(StackContext);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const { driftDetectionExist } = useTypedContext(StackSchedulingContextData);
  const { onCreate } = useTypedContext(StackSchedulingContextApi);

  const handleCreating = (type: SCHEDULING_TYPE) => () => {
    onCreate(type);
    trackSegmentEvent("Create Schedule Clicked", { type, buttonLocation: componentLocation });
  };

  if (!canManageStackAndRuns) return null;

  return (
    <DropdownMenu
      triggerComponent={
        <Button variant="primary" endIcon={ChevronDown}>
          Create schedule
        </Button>
      }
    >
      {!driftDetectionExist && (
        <DropdownMenuItem onAction={handleCreating(SCHEDULING_TYPE.DRIFT_DETECTION)}>
          Drift detection
        </DropdownMenuItem>
      )}
      <DropdownMenuItem onAction={handleCreating(SCHEDULING_TYPE.DELETE_STACK)}>
        Stack deletion
      </DropdownMenuItem>
      <DropdownMenuItem onAction={handleCreating(SCHEDULING_TYPE.TASK)}>Task</DropdownMenuItem>
      <DropdownMenuItem onAction={handleCreating(SCHEDULING_TYPE.RUN)}>Run</DropdownMenuItem>
    </DropdownMenu>
  );
};

export default memo(StackSchedulingCreateDropdown);
