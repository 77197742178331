import { ReactNode } from "react";

import PageInfo from "components/PageWrapper/Info";
import Button from "ds/components/Button";
import useTitle from "hooks/useTitle";

type BlueprintVersionsPageLayoutProps = {
  children: ReactNode;
  onCreate?: () => void;
  blueprintName: string;
};

const BlueprintVersionsPageLayout = ({
  children,
  onCreate,
  blueprintName,
}: BlueprintVersionsPageLayoutProps) => {
  useTitle(`Versions · ${blueprintName}`);

  return (
    <>
      <PageInfo title="Versions">
        <Button onPress={onCreate} variant="primary">
          Create new version
        </Button>
      </PageInfo>
      {children}
    </>
  );
};

export default BlueprintVersionsPageLayout;
