import { ReactNode } from "react";

export enum ColumnMode {
  Single = "Single",
  Double = "Double",
}

export type Column<T = string> = Array<{
  value: T;
  hidden: boolean;
}>;

export type Side = "left" | "right";

export type OptionValue = {
  title: string;
  component: () => ReactNode;
  moreActions?: Array<{ title: string; link: string }>;
  infoTooltip?: string;
};

export type Options<T extends string> = Record<T, OptionValue>;

export type DragDropListProps<T extends string> = {
  columnMode: ColumnMode;
  children?: ReactNode;
  onChange: (props: { left: Column<T>; right: Column<T> }) => void;
  options: Options<T>;
  leftColumn: Column<T>;
  rightColumn: Column<T>;
  itemClassName?: string;
  emptyState?: JSX.Element;
};
