import Box from "ds/components/Box";
import ListEntitiesItemDescriptionMultipleLines from "components/ListEntitiesItem/Description/MultipleLines";

import { CellComponentProps } from "../types";
import useUserRolesListAnalytics from "../useUserRolesListAnalytics";

type UserRolesListItemDescriptionCellProps = CellComponentProps;

const UserRolesListItemDescriptionCell = ({
  roleBinding,
}: UserRolesListItemDescriptionCellProps) => {
  const trackAnalytics = useUserRolesListAnalytics();

  const handleShowFullDescription = () => {
    trackAnalytics("Show full description clicked");
  };

  if (!roleBinding.role.description) {
    return null;
  }

  return (
    <Box direction="row" align="start">
      <ListEntitiesItemDescriptionMultipleLines
        lines={1}
        hasChevronLink
        description={roleBinding.role.description}
        onShowFullDescription={handleShowFullDescription}
      />
    </Box>
  );
};

export default UserRolesListItemDescriptionCell;
