import { useEffect, useRef } from "react";

import { Space as SpaceIcon } from "components/icons/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import MetaInfoListItem from "components/MetaInfoList/Item";
import SpaceAccessBadge from "components/SpaceAccessBadge";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { Space } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import { AccountContext } from "views/AccountWrapper";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import styles from "./styles.module.css";

type SpacesListItemProps = {
  item: Space;
  handleRequestRoleChange: (space: Space) => unknown;
  setRowHeight?: (size: number) => void;
};

const SpacesListItem = ({ item, setRowHeight, handleRequestRoleChange }: SpacesListItemProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  const hasAdminAccess = viewer.admin || hasSpaceManageAccess(item.accessLevel);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
    >
      <Box direction="row" align="center">
        <MetaInfoListItem
          className={styles.metaInfo}
          icon={SpaceIcon}
          linkText={item.name}
          href={`/spaces/${item.id}`}
        />
      </Box>

      <Box direction="row" align="center">
        <SpaceAccessBadge accessLevel={item.accessLevel} />
      </Box>

      <Box direction="row" align="center" justify="end">
        {!hasAdminAccess && (
          <Button variant="secondary" onPress={() => handleRequestRoleChange(item)}>
            Request role change
          </Button>
        )}
      </Box>
    </ListEntitiesItem>
  );
};

export default SpacesListItem;
