import { gql } from "@apollo/client";

export const GET_STACKS_COUNT_BY_STATE = gql`
  query GetStacksCountByState {
    metrics {
      stacksCountByState {
        labels
        value
      }
    }
  }
`;
