import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";

import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { AzureIntegration } from "types/generated";
import useAttachCloudIntegration from "shared/CloudIntegration/useAttachCloudIntegration";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";

import CardForm from "./CardForm";
import IntegrationField from "./Fields/IntegrationField";
import AzureSubscriptionIDField from "./Fields/AzureSubscriptionIDField";
import ReadToggleField from "./Fields/ReadToggleField";
import WriteToggleField from "./Fields/WriteToggleField";
import WriteOnlyWarning from "./WriteOnlyWarning";
import SubmitButton from "./SubmitButton";
import NoPermissionError from "./NoPermissionError";

type AttachCloudFormAzureIntegrationProps = {
  attachableIntegrations: AzureIntegration[] | undefined;
  entityId: string;
  onSuccess: () => void;
  refetchQueries?: string[];
};

type FormValues = {
  azureSubscriptionId: string;
  integrationId: string;
  read: boolean;
  write: boolean;
};

const AttachCloudFormAzureIntegration = ({
  attachableIntegrations,
  onSuccess,
  entityId,
  refetchQueries,
}: AttachCloudFormAzureIntegrationProps) => {
  const form = useForm<FormValues>({
    defaultValues: {
      azureSubscriptionId: "",
      integrationId: "",
      read: false,
      write: false,
    },
  });

  const {
    setValue,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = form;

  const { attach } = useAttachCloudIntegration({
    entityId,
    onSuccess,
    refetchQueries,
  });

  const integrationId = watch("integrationId");

  useEffect(() => {
    if (integrationId) {
      const subscriptionId = attachableIntegrations?.find(
        ({ id }) => id === integrationId
      )?.defaultSubscriptionId;

      if (subscriptionId) {
        setValue("azureSubscriptionId", subscriptionId);
      }
    }
  }, [integrationId, attachableIntegrations, setValue]);

  useObserveForWarning(
    isDirty,
    <>
      You have not attached the <b>selected Azure integration</b>. Do you want to continue without
      attaching it?
    </>
  );

  const onSubmit = (data: FormValues) =>
    attach({
      type: CLOUD_INTEGRATIONS.Azure,
      integrationId: data.integrationId,
      read: data.read,
      write: data.write,
      azureSubscriptionId: data.azureSubscriptionId,
    });

  const options = useMemo(() => {
    return attachableIntegrations
      ? attachableIntegrations.map((value) => ({ value: value.id, label: value.name }))
      : [];
  }, [attachableIntegrations]);

  return (
    <FormProvider {...form}>
      <CardForm onSubmit={handleSubmit(onSubmit)}>
        <IntegrationField options={options} />
        <AzureSubscriptionIDField />
        <ReadToggleField />
        <WriteToggleField />
        <WriteOnlyWarning />
        <NoPermissionError />
        <SubmitButton />
      </CardForm>
    </FormProvider>
  );
};

export default AttachCloudFormAzureIntegration;
