import BulkActionsDrawerItem from "components/BulkActions/Drawer/Item";
import BulkActionsDrawerItemDismiss from "components/BulkActions/Drawer/Item/Dismiss";
import BulkActionsDrawerItemLink from "components/BulkActions/Drawer/Item/Link";
import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsActionDescriptor,
  BulkActionsStep,
} from "components/BulkActions/types";
import Box from "ds/components/Box";
import useAnalytics from "hooks/useAnalytics";
import { ActionAvailabilityBanner } from "components/BulkActions/Drawer/Item/ActionAvailabiltyBanner";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { Role } from "types/generated";

import { RolesBulkActions } from "./types";

type RolesBulkActionsSelectedItemProps =
  | {
      item: Role;
      onDismiss: (id: BulkActionItemID) => void;
      step: BulkActionsStep.ActionConfirmation;
      itemCategory: BulkActionActionConfirmationTabs;
      itemActions?: never;
      allActionsQty?: never;
    }
  | {
      item: Role;
      onDismiss: (id: BulkActionItemID) => void;
      step: BulkActionsStep.ChooseAction;
      itemActions: BulkActionsActionDescriptor<RolesBulkActions>[] | undefined;
      allActionsQty: number;
      itemCategory?: never;
    };

const RolesBulkActionsSelectedItem = ({
  item,
  itemActions,
  onDismiss,
  allActionsQty,
  step,
  itemCategory,
}: RolesBulkActionsSelectedItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationRoles,
  });

  const handleDismiss = () => {
    onDismiss(item.id);
    trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Item dismissed");
  };

  const isChooseActionStep = step === BulkActionsStep.ChooseAction;
  const actionTexts = (itemActions || []).map((action) => action.title);

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box gap="medium" align="start" justify="between" fullWidth>
        <BulkActionsDrawerItemLink
          link={`/role/${item.id}`}
          text={item.name}
          step={step}
          itemCategory={itemCategory}
        />
        <BulkActionsDrawerItemDismiss onDismiss={handleDismiss} />
      </Box>

      {isChooseActionStep && (
        <ActionAvailabilityBanner itemActions={actionTexts} allActionsQty={allActionsQty} />
      )}
    </BulkActionsDrawerItem>
  );
};

export default RolesBulkActionsSelectedItem;
