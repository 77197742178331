import cx from "classnames";

import Draggable from "components/DragAndDrop/Draggable";
import DraggableItem from "ds/components/DraggableItem";

import DragDropListItem from "./Item";
import styles from "./styles.module.css";
import { OptionValue, Side } from "./types";
type DragDropListDraggableItemProps<T extends string> = {
  id: T;
  className?: string;
  disabled: boolean;
  option: OptionValue;
  hideItem: (side: Side, id: T) => void;
  side: Side;
};

const DragDropListDraggableItem = <T extends string>({
  id,
  disabled,
  option,
  hideItem,
  side,
  className,
}: DragDropListDraggableItemProps<T>) => {
  const Component = option.component;

  return (
    <Draggable id={id} disabled={disabled}>
      {({
        isDragging,
        setActivatorNodeRef,
        setNodeRef,
        transform,
        transition,
        listeners,
        attributes,
      }) => {
        const style = transform
          ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
              transition,
            }
          : undefined;
        return (
          <DraggableItem
            noBorder
            ref={setNodeRef}
            size="small"
            margin="0 0 x-large 0"
            dragging={isDragging}
            className={cx(styles.draggable, className)}
            style={style}
          >
            <DragDropListItem
              dragging={isDragging}
              handleRef={setActivatorNodeRef}
              onHide={() => hideItem(side, id)}
              text={option.title}
              moreActions={option.moreActions}
              infoTooltip={option.infoTooltip}
              handleListeners={listeners}
              handleAttributes={attributes}
            >
              <Component />
            </DragDropListItem>
          </DraggableItem>
        );
      }}
    </Draggable>
  );
};

export default DragDropListDraggableItem;
