import { useMutation } from "@apollo/client";
import cx from "classnames";
import { FormEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import FlashContext from "components/FlashMessages/FlashContext";
import FormGroup from "components/FormDefault/Group";
import formStyles from "components/FormDefault/styles.module.css";
import PageWrapper from "components/PageWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "components/box/Box";
import NoAccessPage from "components/error/NoAccessPage";
import InputText from "components/input/InputText";
import InputTextarea from "components/input/InputTextarea";
import Button from "ds/components/Button";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { downloadFile } from "utils/file";
import { AccountContext } from "views/AccountWrapper";

import { CREATE_VCS_AGENT_POOL } from "./query";

const NewVCSAgentPool = () => {
  const navigate = useNavigate();

  const { accountName, viewer } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  useTitle(`New VCS Agent Pool · ${accountName}`);

  const [formValues, setFormValue] = useState({
    name: "",
    description: "",
  });

  const handleInputChange = useCallback(
    (field: string) => (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.currentTarget; // even.persist()

      setFormValue((fields) => {
        return { ...fields, [field]: value };
      });
    },
    [setFormValue]
  );

  const [createVCSAgentPool, { loading }] = useMutation(CREATE_VCS_AGENT_POOL);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
        const {
          data: {
            vcsAgentPoolCreate: { config, id, name },
          },
        } = await createVCSAgentPool({ variables: formValues });

        downloadFile(`vcs-agent-pool-${id}.config`, config);
        reportSuccess({ message: `VCS agent pool ${name} successfully created` });

        navigate(`/vcs-agent-pool/${id}`);
      } catch (e) {
        onError(e);
      }
    },
    [createVCSAgentPool, formValues, navigate, onError, reportSuccess]
  );

  const disabled = formValues.name.length === 0;

  useBreadcrumbs([
    {
      title: "VCS agent pools",
      link: "/vcs-agent-pools",
    },
    {
      title: "New VCS agent pool",
    },
  ]);

  if (!viewer.admin) {
    return <NoAccessPage />;
  }

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderTitle>Create new VCS agent pool</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeader>

      <PageWrapper>
        <Box>
          <form className={formStyles.form} onSubmit={handleSubmit}>
            <section className={cx(formStyles.body, formStyles.center)}>
              <div className={formStyles.content}>
                <FormGroup labelText="Name:">
                  <InputText
                    className={formStyles.input}
                    id="name"
                    name="name"
                    placeholder="VCS agent pool name (required)"
                    onChange={handleInputChange("name")}
                    value={formValues.name}
                  />
                </FormGroup>

                <FormGroup labelText="Description:" top>
                  <InputTextarea
                    className={cx(formStyles.input, "chromatic-ignore")}
                    id="description"
                    name="description"
                    placeholder="VCS agent pool description (optional, supports markdown)"
                    onChange={handleInputChange("description")}
                    value={formValues.description}
                  />
                </FormGroup>
              </div>
            </section>

            {/* TODO: wait for VCS Agent Pool documentation */}
            {/* <InfoBox>
            Upon VCS agent pool creation, a file will be generated with VCS agent pool configuration
            encoded using base64 encoding. Please save this file somewhere safe as it will never be
            available again. You will need to make the configuration data available to all
            connections joining this VCS agent pool. Please read more about starting VCS agents{" "}
            <a
              href={getDocsUrl("/concepts/worker-pools#setting-up")}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </InfoBox> */}

            <section className={formStyles.footer}>
              <div className={cx(formStyles.group, formStyles.buttons)}>
                <Button
                  type="submit"
                  className={formStyles.button}
                  loading={loading}
                  disabled={disabled}
                  variant="primary"
                >
                  Create new VCS agent pool
                </Button>
              </div>
            </section>
          </form>
        </Box>
      </PageWrapper>
    </>
  );
};

export default NewVCSAgentPool;
