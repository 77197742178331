import { useNavigate } from "react-router-dom";

import Banner from "ds/components/Banner";
import Button from "ds/components/Button";
import FeedbackActions from "ds/components/Feedback/Actions";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";

import { StackSettingsContextData } from "../Context";

const StackSettingsBehaviorInfoBanner = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const navigate = useNavigate();

  const navigateToHooksTab = () => navigate(`/stack/${stackSettings.id}/hooks`);
  const navigateToPoliciesTab = () => navigate(`/stack/${stackSettings.id}/policies`);

  return (
    <Banner title="Hooks and policies are now placed directly on the stack view" variant="info">
      <Typography tag="p" variant="p-body3" margin="0 0 medium 0">
        To make it more convenient, we have moved hooks and policies to their own tab in the stack
        view.
      </Typography>

      <FeedbackActions>
        <Button variant="secondary" size="small" onPress={navigateToHooksTab}>
          Go to Hooks
        </Button>
        <Button variant="secondary" size="small" onPress={navigateToPoliciesTab}>
          Go to Policies
        </Button>
      </FeedbackActions>
    </Banner>
  );
};

export default StackSettingsBehaviorInfoBanner;
