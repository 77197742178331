import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import differenceWith from "lodash-es/differenceWith";

import { ExternalIntegration } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { SelectOption } from "ds/components/Select/types";

import { GET_ATTACHABLE_INTEGRATIONS_IN_SPACE } from "./gql";

type AttachableIntegrationOption = SelectOption & {
  value: ExternalIntegration["name"];
};

type UseGetAttachableIntegrationsProps = {
  spaceId: string;
  attachedIntegrationsIds: string[];
  skip?: boolean;
};

type UseGetAttachableIntegrationsData = {
  integrations: AttachableIntegrationOption[];
  loading: boolean;
};

const useGetAttachableIntegrations = ({
  spaceId,
  attachedIntegrationsIds,
}: UseGetAttachableIntegrationsProps): UseGetAttachableIntegrationsData => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading } = useQuery<{
    attachableExternalIntegrationInSpace: ExternalIntegration[];
  }>(GET_ATTACHABLE_INTEGRATIONS_IN_SPACE, {
    onError,
    variables: { id: spaceId },
  });

  const integrations = useMemo(
    () =>
      differenceWith(
        (data?.attachableExternalIntegrationInSpace || []).map((integration) => ({
          value: integration.id,
          label: integration.name,
        })),
        attachedIntegrationsIds,
        (a, b) => a.value === b
      ),
    [data?.attachableExternalIntegrationInSpace, attachedIntegrationsIds]
  );

  return {
    integrations,
    loading,
  };
};

export default useGetAttachableIntegrations;
