import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

import useCopyToClipboard from "hooks/useCopyToClipboard";
import useMakeResourceDetailsUrlPath from "views/Account/IacManagement/useMakeResourceDetailsUrlPath";

import { RenderResourceRowProps } from "../../components/Table/types";
import IacManagementTableResourceRowCellName from "./CellName";
import IacManagementTableResourceRowCellResourcesCount from "./CellResourcesCount";
import IacManagementTableResourceRowCellDrifted from "./CellDrifted";
import IacManagementTableResourceRowCellSpace from "./CellSpace";
import IacManagementTableResourceRowCellVendor from "./CellVendor";
import IacManagementTableResourceRowCellUpdatedAt from "./CellUpdatedAt";
import IacManagementTableResourceRowCellActions from "./CellActions";
import { IAC_MANAGEMENTS_TABLE_COLUMNS } from "../types";

export type RenderResourceRowCellProps = {
  row: RenderResourceRowProps["row"];
  handleResourceClick: () => void;
  handleCopyLink: () => void;
};

const CELL_PER_COLUMN = {
  [IAC_MANAGEMENTS_TABLE_COLUMNS.NAME]: IacManagementTableResourceRowCellName,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.RESOURCES]: IacManagementTableResourceRowCellResourcesCount,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.DRIFT_DETECTED]: IacManagementTableResourceRowCellDrifted,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.SPACE]: IacManagementTableResourceRowCellSpace,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.VENDOR]: IacManagementTableResourceRowCellVendor,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.UPDATED_AT]: IacManagementTableResourceRowCellUpdatedAt,
  [IAC_MANAGEMENTS_TABLE_COLUMNS.ACTIONS]: IacManagementTableResourceRowCellActions,
};

type IacManagementTableResourceRowProps = RenderResourceRowProps;

const IacManagementTableResourceRow = ({ columns, row }: IacManagementTableResourceRowProps) => {
  const navigate = useNavigate();

  const { pathWithSearch, fullLink } = useMakeResourceDetailsUrlPath(row.id);
  const handleCopyLink = useCopyToClipboard(fullLink);

  const handleResourceClick = useCallback(() => {
    navigate(pathWithSearch);
  }, [navigate, pathWithSearch]);

  const cellProps = {
    row,
    handleResourceClick,
    handleCopyLink,
  };

  return columns.map((column) => {
    const Cell = CELL_PER_COLUMN[column.id as IAC_MANAGEMENTS_TABLE_COLUMNS];
    return <Cell key={`${row.parent}-${row.id}-${column.id}`} {...cellProps} />;
  });
};

export default IacManagementTableResourceRow;
