import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SortDescriptor } from "react-aria-components";

import { getSearchQuery } from "components/SearchInput/helpers";

import { sortIntegrations } from "./sort";
import { Integration } from "../types";

export const useFilteredEntities = (entities: Integration[], sortDescriptor: SortDescriptor) => {
  const [searchParams] = useSearchParams();
  const searchInput = getSearchQuery(searchParams);

  return useMemo(() => {
    let filteredEntities = [...entities];
    // Apply search filter if exists
    filteredEntities = filteredEntities.filter((entity) =>
      entity.id.toLowerCase().includes(searchInput.toLowerCase())
    );

    // Apply sorting if descriptor exists
    if (sortDescriptor.column) {
      filteredEntities = sortIntegrations(filteredEntities, sortDescriptor);
    }

    return filteredEntities;
  }, [entities, searchInput, sortDescriptor]);
};
