import cx from "classnames";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { Handle, NodeProps, Position, useReactFlow } from "reactflow";

import CopyFieldIcon from "components/CopyField/Icon";
import { CodeBrowser, Lock, Plus, Stack, Trash, Webhook } from "components/icons/generated";
import SpaceAccessBadge from "components/SpaceAccessBadge";
import BaseAction from "ds/components/BaseAction";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Icon from "ds/components/Icon";
import PopoverActionBar from "ds/components/PopoverActionBar";
import TextEllipsis from "ds/components/TextEllipsis";
import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { isLegacySpace } from "utils/space";
import { hasSpaceManageAccess } from "utils/user";
import { withTestId } from "utils/withTestId";

import { SpacesViewActionsContext } from "../ActionsContext";
import { INTERACTIVE_ATTRIBUTE_FOR_DRAWER } from "../constants";
import { SpacesViewContext } from "../Context";
import { SpacesViewDiagramActionsContext } from "../Diagram/ActionsContext";
import { SPACE_NODE_TEST_ID } from "../Diagram/constants";
import { SpaceItemType } from "../Diagram/types";
import { isPredefinedSpace } from "../utils";
import styles from "./styles.module.css";

const SpaceNode = ({ data }: NodeProps<SpaceItemType>) => {
  const { currentSpace, isRootAdmin, canNonRootAdminCreateSpace } =
    useTypedContext(SpacesViewContext);
  const [isControlVisible, setIsControlVisible] = useState(false);

  const {
    onSelect: onSpaceSelect,
    onCreate: onSpaceCreate,
    onFilter,
    onDelete,
  } = useTypedContext(SpacesViewActionsContext);

  const { focusedKey } = useTypedContext(SpacesViewDiagramActionsContext);

  const isActive = data.item.id === currentSpace?.id;

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onSpaceSelect(data.item.id);
  };

  const handleCreateSpace = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    onSpaceCreate(data.item.id);
  };

  const handleFilter = (id: string, path: string) => (e?: MouseEvent) => {
    e?.stopPropagation();
    return onFilter(id, path);
  };

  const hasAccessToParentSpace =
    data.item.parentSpaceAccessLevel && hasSpaceManageAccess(data.item.parentSpaceAccessLevel);

  const canBeDeleted =
    (isRootAdmin || (canNonRootAdminCreateSpace && hasAccessToParentSpace)) &&
    !isPredefinedSpace(data.item.id) &&
    data.children?.length === 0;
  const itemRef = useRef<HTMLDivElement>(null);

  const diagram = useReactFlow();

  useEffect(() => {
    if (focusedKey === data.item.id) {
      itemRef.current?.parentElement?.focus();
      diagram.fitView({
        nodes: [{ id: data.item.id }],
        minZoom: diagram.getZoom(),
        maxZoom: diagram.getZoom(),
      });
    }
  }, [focusedKey, diagram, data.item.id, data.position.x, data.position.y]);

  const canAddChildSpace =
    isRootAdmin || (canNonRootAdminCreateSpace && hasSpaceManageAccess(data.item.accessLevel));

  return (
    <>
      <Box
        direction="row"
        align="center"
        justify="start"
        gap="medium"
        className={cx(styles.node, { [styles.active]: isActive })}
        onClick={handleOnClick}
        {...{ [INTERACTIVE_ATTRIBUTE_FOR_DRAWER]: true }}
        {...withTestId(SPACE_NODE_TEST_ID)}
        ref={itemRef}
      >
        {data.item.parentSpace && (
          <Handle
            id={data.item.id}
            type="target"
            position={Position.Top}
            className={styles.topPoint}
          />
        )}

        <TextEllipsis tooltip={data.item.name} tooltipWidthMode="maxWidthXl" tooltipMode="parent">
          {(props) => (
            <Typography {...props} tag="span" variant="p-t6">
              {data.item.name}
            </Typography>
          )}
        </TextEllipsis>

        {isPredefinedSpace(data.item.id) && isRootAdmin && (
          <Tooltip mode="parent" on={(tooltipProps) => <Icon {...tooltipProps} src={Lock} />}>
            This space is not editable.
          </Tooltip>
        )}

        {!isRootAdmin && <SpaceAccessBadge accessLevel={data.item.accessLevel} />}

        {canAddChildSpace && !isLegacySpace(data.item.id) && (
          <Tooltip
            mode="parent"
            on={(tooltipProps) => (
              <BaseAction
                {...tooltipProps}
                className={styles.createIcon}
                onClick={handleCreateSpace}
              >
                <Icon src={Plus} />
              </BaseAction>
            )}
            placement="bottom"
          >
            Create space
          </Tooltip>
        )}

        <PopoverActionBar className={cx(styles.controls, { [styles.visible]: isControlVisible })}>
          <DropdownMenuEllipsis
            onOpenChange={setIsControlVisible}
            tooltip="Show more actions"
            buttonVariant="ghost"
          >
            <DropdownMenuItem
              className={styles.menuItem}
              onAction={handleFilter(data.item.id, "/stacks")}
            >
              <Icon src={Stack} />
              View Stacks
            </DropdownMenuItem>
            <DropdownMenuItem
              className={styles.menuItem}
              onAction={handleFilter(data.item.id, "/blueprints")}
            >
              <Icon src={CodeBrowser} />
              View Blueprints
            </DropdownMenuItem>
            <DropdownMenuItem
              className={styles.menuItem}
              onAction={handleFilter(data.item.id, "/webhooks")}
            >
              <Icon src={Webhook} />
              View Webhooks
            </DropdownMenuItem>
          </DropdownMenuEllipsis>

          <CopyFieldIcon tooltipMode="parent" title="Copy Space ID" value={data.item.id} />

          {canBeDeleted && (
            <ButtonIcon
              icon={Trash}
              variant="ghostDanger"
              onPress={() => onDelete(data.item.id)}
              tooltipMode="parent"
            >
              Delete space
            </ButtonIcon>
          )}
        </PopoverActionBar>
      </Box>

      <Handle
        id={data.item.id}
        className={cx(styles.sourceHandle, { [styles.visible]: data.children.length > 0 })}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </>
  );
};

export default SpaceNode;
