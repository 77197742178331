import { Upload } from "components/icons/generated";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { StackContext } from "views/Stack/Context";

import { showStackImportStateModal } from "../ImportStateModal";

const StackStateUploadButton = () => {
  const { stack, canManageStackAndRuns } = useTypedContext(StackContext);
  const { viewer } = useTypedContext(AccountContext);

  const canImportState =
    stack.stateIsTerminal && stack.lockedBy === viewer.id && canManageStackAndRuns;

  const onUploadClick = () => {
    showStackImportStateModal({ stackId: stack.id });
  };

  let tooltipText = "";
  if (!stack.stateIsTerminal) {
    tooltipText = "Stack should have no unfinished runs to import a state";
  } else if (!canManageStackAndRuns) {
    tooltipText = "Only stack admins can import a state";
  } else if (!stack.lockedBy) {
    tooltipText = "Stack should be locked to import a state";
  } else if (stack.lockedBy !== viewer.id) {
    tooltipText = `Stack is locked by another user: ${stack.lockedBy}`;
  }

  return (
    <Tooltip
      active={!canImportState}
      on={(props) => (
        <Button
          {...props}
          disabled={!canImportState}
          onPress={onUploadClick}
          startIcon={Upload}
          variant="secondary"
        >
          Import state
        </Button>
      )}
    >
      {tooltipText}
    </Tooltip>
  );
};

export default StackStateUploadButton;
