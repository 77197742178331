import { memo, ReactNode } from "react";

import styles from "./styles.module.css";

type TableBodyProps = {
  children: ReactNode;
};

const TableBody = memo(({ children }: TableBodyProps) => {
  return (
    <div className={styles.tableBody} role="rowgroup">
      {children}
    </div>
  );
});

TableBody.displayName = "DS.Table.Body";

export default TableBody;
