import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import DocumentationIconButton from "components/DocumentationIconButton";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { getDocsUrl } from "utils/getDocsUrl";
import SearchInput from "components/SearchInput";

import { ANALYTICS, FILTERS_ORDER_SETTINGS_KEY_CLOUD_INTEGRATIONS } from "./constants";
import CloudIntegrationsSetupDropdownButton from "./SetupDropdownButton";

type CloudIntegrationsPageLayoutProps = {
  children: ReactNode;
};

const CloudIntegrationsPageLayout = ({ children }: CloudIntegrationsPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Cloud integrations · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Cloud integrations</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <SearchInput
            placeholder="Search by name..."
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY_CLOUD_INTEGRATIONS}
          />
          <DocumentationIconButton
            href={getDocsUrl("/integrations/cloud-providers")}
            tooltipText="Go to cloud integrations documentation"
            analyticsPage={ANALYTICS.analyticsPage}
            analyticsTitle="Documentation button clicked"
          />
          <CloudIntegrationsSetupDropdownButton />
        </ViewHeaderWrapper>
      </ViewHeader>
      {children}
    </>
  );
};

export default CloudIntegrationsPageLayout;
