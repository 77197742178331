import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import UserStatusBadge from "components/UserStatusBadge";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import Tab from "ds/components/Tab";
import { AccountContext } from "views/AccountWrapper";
import Button from "ds/components/Button";

import { showUserListRevokeAccessConfirmation } from "../Users/ListNew/Item/Dropdown/ConfirmationModal";
import { UserViewDetails } from "./types";
import { showUsersDetailsDrawer } from "../Users/DetailsDrawer";
import { SettingsContext } from "../Context";

type UserPageLayoutProps = {
  children: ReactNode;
  user: UserViewDetails;
};

const UserPageLayout = ({ children, user }: UserPageLayoutProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { activationStatus } = useTypedContext(SettingsContext);

  const userUrl = `/settings/user/${user.id}`;

  return (
    <>
      <ViewHeader firstLevel>
        <Box fullWidth justify="between" align="center">
          <Box gap="medium" align="center">
            <ViewHeaderTitle tag="h2">{user.username}</ViewHeaderTitle>
            <UserStatusBadge status={user.status} />
          </Box>

          <Box gap="medium" align="center">
            <Button
              variant="secondary"
              onPress={() =>
                showUsersDetailsDrawer({
                  id: user.id,
                  userId: user.id,
                  activationStatus,
                })
              }
            >
              Details
            </Button>
            {viewer.id !== user.username && viewer.admin && (
              <Button
                variant="dangerSecondary"
                onPress={() =>
                  showUserListRevokeAccessConfirmation({
                    id: user.id,
                    userName: user.username,
                  })
                }
              >
                Revoke access
              </Button>
            )}
          </Box>
        </Box>
        <ViewHeaderNavigation role="tablist">
          <Tab end to={userUrl} label="Roles" />
          <Tab to={`${userUrl}/idp-groups`} label="IdP groups" />
          <Tab to={`${userUrl}/integrations`} label="Integrations" />
        </ViewHeaderNavigation>
      </ViewHeader>

      {children}
    </>
  );
};

export default UserPageLayout;
