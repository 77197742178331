import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import { MFA_REGISTER_KEY_COMPATIBILITY_MESSAGE } from "constants/mfa";
import Banner from "ds/components/Banner";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import useMFA from "hooks/useMFA";
import useTypedContext from "hooks/useTypedContext";
import { getDrawerFormFix } from "utils/css";

type RegisterKeyDrawerProps = {
  handleSuccess: () => void;
};

type RegisterKeyFormFields = {
  name: string;
};

const NameFieldTooltipInfo = () => (
  <>
    <TooltipModalTitle>Security keys</TooltipModalTitle>
    <TooltipModalBody>
      Security keys are hardware devices that can be used as your second factor of authentication.
      Name your security key to be able to manage them after.
    </TooltipModalBody>
  </>
);

const RegisterKeyDrawer = createDrawer(({ handleSuccess }: RegisterKeyDrawerProps) => {
  const {
    handleRegister,
    isLoading,
    canAbort,
    abort,
    isUserVerifyingPlatformAuthenticatorAvailable,
  } = useMFA();
  const { reportSuccess, reportError } = useTypedContext(FlashContext);

  const form = useForm<RegisterKeyFormFields>({
    mode: "onChange",
  });
  const drawer = useModal();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (drawer.visible) {
      setFocus("name");
    }
  }, [setFocus, drawer.visible]);

  const handleClose = () => {
    abort();
  };

  const onSubmit: SubmitHandler<RegisterKeyFormFields> = async (formData) => {
    const { success, message } = await handleRegister(formData.name);

    if (success) {
      drawer.hide();
      handleSuccess();
      reportSuccess({ message });
    } else {
      reportError({ message });
    }
  };

  return (
    <DrawerSimple onClose={handleClose} isDismissable={canAbort}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Register key" />
            {canAbort && <DrawerCloseIcon />}
          </DrawerHeader>

          <DrawerBody>
            {isUserVerifyingPlatformAuthenticatorAvailable === false && (
              <Banner variant="info" fullWidth>
                {MFA_REGISTER_KEY_COMPATIBILITY_MESSAGE}
              </Banner>
            )}
            <FormField
              label="Key name"
              tooltipInfo={<NameFieldTooltipInfo />}
              tooltipInfoVariant="modal"
              error={errors?.name?.message}
              helperText="Name your security key"
            >
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter key name"
                  error={!!errors?.name}
                  disabled={isLoading}
                  {...register("name", { required: "Name field is required." })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </DrawerBody>

          <DrawerFooter>
            <DrawerFooterActions>
              <DrawerCancelButton disabled={!canAbort} />
              <Button variant="primary" type="submit" disabled={isLoading} loading={isLoading}>
                Add key
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerSimple>
  );
});

export const showRegisterKeyDrawer = createDrawerTrigger(RegisterKeyDrawer);
