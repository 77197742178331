import { Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import DescriptionDetails from "components/DescriptionDetails";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import { ExternalIntegration } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";

type IntegrationDetailsDrawerProps = {
  integration: ExternalIntegration;
};

const IntegrationDetailsDrawer = createDrawer(({ integration }: IntegrationDetailsDrawerProps) => {
  const onOpenFullDescription = useShowFullDescriptionWithBackArrow(integration.description);

  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Integration details" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody>
        <FormFieldViewText label="ID" value={integration.id} withCopy />
        <FormFieldViewText label="Name" value={integration.name} />

        <FormField label="Space">
          <MetaInfoListItem
            icon={Space}
            linkText={integration.spaceDetails.name}
            href={`/spaces/${integration.spaceDetails.id}`}
          />
        </FormField>

        <DescriptionDetails
          description={integration.description}
          onOpenFullDescription={onOpenFullDescription}
        />

        <FormFieldViewText label="Endpoint URL" value={integration.customConfig.baseURL} withCopy />
        <FormFieldViewText label="Authentication type" value="Basic" />
        <FormFieldViewText label="Username" value={integration.customConfig.username} />
        <FormFieldViewText label="Password" value="***" />
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showIntegrationDetailsDrawer = createDrawerTrigger(IntegrationDetailsDrawer);
