import { useNavigate } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import Button, { ButtonProps } from "ds/components/Button";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import SplitControlButton from "ds/components/SplitControl/Button";
import SplitControlDropdown from "ds/components/SplitControl/Dropdown";
import SplitControlWrapper from "ds/components/SplitControl/Wrapper";
import Tooltip from "ds/components/Tooltip";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import useTriggerStack from "shared/Stack/useTriggerStack";
import { canTriggerStack } from "shared/Stack/useTriggerStack/accessValidation";
import { getDisabledTriggerMessage } from "shared/Stack/utils";
import { Stack } from "types/generated";
import { getVendorName } from "utils/vendor";
import { AccountContext } from "views/AccountWrapper";

import styles from "./styles.module.css";
import { showTriggerWithCustomConfigDrawer } from "./TriggerWithCustomConfigDrawer";

type TriggerButtonProps = {
  stack: Stack;
  text: string;
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  allowCustomConfig?: boolean;
  analyticsPage?: AnalyticsPage;
};

const TriggerButton = ({
  stack,
  text,
  size = "medium",
  variant = "primary",
  allowCustomConfig = false,
  analyticsPage,
}: TriggerButtonProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { reportSuccess } = useTypedContext(FlashContext);

  const navigate = useNavigate();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const [triggerRun, { loading }] = useTriggerStack();

  const canTrigger = canTriggerStack(stack, viewer);

  const disableTriggerButtonMessage = getDisabledTriggerMessage(stack);

  const onClick = (customRuntimeConfig: string | null = null) =>
    triggerRun(
      {
        stack: stack.id,
        runtimeConfig: customRuntimeConfig ? { yaml: customRuntimeConfig } : null,
      },
      (data) => {
        const id = data?.runTrigger.id;
        if (id) {
          reportSuccess({ message: `Run ${id} successfully triggered` });
          trackSegmentAnalyticsEvent?.("Run Triggered", {
            stackVendor: getVendorName(stack.vendorConfig?.__typename),
            customRuntimeConfig: !!customRuntimeConfig,
          });
          navigate(`/stack/${stack.id}/run/${id}`);
        }
      }
    );

  const openCustomTriggerConfigDrawer = () => {
    showTriggerWithCustomConfigDrawer({
      onTrigger: onClick,
    });
  };

  if (!canTrigger) {
    return null;
  }

  return (
    <>
      {allowCustomConfig ? (
        <SplitControlWrapper size={size} variant={variant}>
          <SplitControlButton
            tooltip={disableTriggerButtonMessage}
            disabled={loading || !!disableTriggerButtonMessage}
            loading={loading}
            id="stack-view--trigger-split-button"
            onPress={() => onClick()}
          >
            {text}
          </SplitControlButton>
          <SplitControlDropdown
            tooltip={disableTriggerButtonMessage || "More trigger options"}
            disabled={loading || !!disableTriggerButtonMessage}
            placement="bottom right"
            className={styles.dropdownButton}
          >
            <DropdownMenuItem
              onAction={() => {
                onClick();
              }}
            >
              {text}
            </DropdownMenuItem>
            <DropdownMenuItem onAction={openCustomTriggerConfigDrawer}>
              Trigger with custom runtime config
            </DropdownMenuItem>
          </SplitControlDropdown>
        </SplitControlWrapper>
      ) : (
        <Tooltip
          active={!!disableTriggerButtonMessage}
          on={(tooltipProps) => (
            <Button
              {...tooltipProps}
              disabled={loading || !!disableTriggerButtonMessage}
              loading={loading}
              variant={variant}
              id="trigger-button"
              size={size}
              onPress={() => onClick()}
            >
              {text}
            </Button>
          )}
          placement="top"
        >
          {disableTriggerButtonMessage}
        </Tooltip>
      )}
    </>
  );
};

export default TriggerButton;
