import { useModal } from "@ebay/nice-modal-react";
import { useEffect, useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FormFieldSpace from "components/FormFields/Space";
import FormFieldSpaceTooltipInfo from "components/FormFields/Space/TooltipInfo";
import FormFieldTags from "components/FormFields/Tags";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { AVAILABLE_POLICY_TYPES, POLICY_TYPES_FEATURES } from "constants/policy";
import Banner from "ds/components/Banner";
import Button from "ds/components/Button";
import ComboBox from "ds/components/ComboBox";
import ComboBoxDefaultItem from "ds/components/ComboBox/DefaultItem";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FeedbackActions from "ds/components/Feedback/Actions";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import useTypedContext from "hooks/useTypedContext";
import { PolicyType } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import { validatePolicyName } from "utils/formValidators";
import { getDocsUrl } from "utils/getDocsUrl";
import { isLegacySpace } from "utils/space";

import { POLICY_TYPES_DATA } from "../Policies/helpers";
import { SpacesContext } from "../SpacesProvider";
import { SubscriptionContext } from "../SubscriptionWrapper";
import { ADD_POLICY_DRAWER_TEST_ID } from "./constants";
import { CreatePolicyFields } from "./types";

type PolicyCreateDrawerProps = {
  autoSelectFirstType?: boolean;
  forceValidationOnOpen?: boolean;
  takenPolicyNames: string[];
  forcedType?: PolicyType;
  templateId?: string;
  title?: string;
  submitButtonTitle?: string;
  defaultValues?: Partial<CreatePolicyFields>;
};

const PolicyCreateDrawer = createDrawer(
  ({
    autoSelectFirstType = true,
    takenPolicyNames,
    forcedType,
    templateId,
    title = "Create policy",
    submitButtonTitle = "Continue",
    defaultValues,
    forceValidationOnOpen,
  }: PolicyCreateDrawerProps) => {
    const { manageableSpacesSelectOptions } = useTypedContext(SpacesContext);
    const navigate = useNavigate();

    const drawer = useModal();

    const { tierFeatures } = useTypedContext(SubscriptionContext);

    const trackSegmentAnalyticsEvent = useAnalytics({
      page: AnalyticsPagePolicy.PoliciesList,
    });

    const hasLegacySpace = useMemo(() => {
      return !!manageableSpacesSelectOptions.find((option) => isLegacySpace(option.value));
    }, [manageableSpacesSelectOptions]);

    const typeOptions = useMemo(
      () =>
        AVAILABLE_POLICY_TYPES.filter((item) => {
          if (!hasLegacySpace && item === PolicyType.Access) {
            return false;
          }

          const billingTierFeature = POLICY_TYPES_FEATURES[item];
          if (!billingTierFeature) {
            return true;
          }

          return tierFeatures.includes(billingTierFeature);
        }).map((value) => ({
          label: POLICY_TYPES_DATA[value]?.name || "",
          value: value,
        })),
      [hasLegacySpace, tierFeatures]
    );

    const defaultPolicyPlan = useMemo(
      () =>
        typeOptions.find(({ value }) => value === PolicyType.Plan)
          ? PolicyType.Plan
          : typeOptions[0].value,
      [typeOptions]
    );

    const createPolicyForm = useForm<CreatePolicyFields>({
      defaultValues: {
        name: "",
        labels: [],
        space: "",
        type: autoSelectFirstType ? defaultPolicyPlan : undefined,
        description: "",
        ...defaultValues,
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isDirty },
      control,
      watch,
      getValues,
      setValue,
      trigger,
    } = createPolicyForm;

    const type = watch("type");
    const space = watch("space");

    // Allow access policy only for legacy space
    useEffect(() => {
      if (type === PolicyType.Access && !isLegacySpace(space)) {
        setValue("space", "legacy");
      }
    }, [type, space, setValue]);

    // Force policy type
    useEffect(() => {
      if (forcedType && type !== forcedType) {
        setValue("type", forcedType);
      }
    }, [forcedType, type, setValue]);

    useEffect(() => {
      if (forceValidationOnOpen) {
        trigger("name");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (defaultValues) {
        const currentValues = getValues();

        // TODO: update when react-hook-form upgraded
        reset({
          ...currentValues,
          ...defaultValues,
        });
      }
    }, [defaultValues, getValues, reset]);

    const cancelHandler = () => {
      trackSegmentAnalyticsEvent("Create Cancel");
    };

    const redirectToCreateView: SubmitHandler<CreatePolicyFields> = (formData) => {
      trackSegmentAnalyticsEvent("Create Continue");

      try {
        const labelsQuery = formData.labels
          ? `&labels=${btoa(encodeURIComponent(JSON.stringify(formData.labels)))}`
          : "";

        const descriptionQuery = formData.description
          ? `&description=${btoa(encodeURIComponent(formData.description))}`
          : "";

        const templateIdQuery = templateId
          ? `&templateId=${btoa(encodeURIComponent(templateId))}`
          : "";

        navigate(
          `/new/policy?name=${btoa(encodeURIComponent(formData.name.trim()))}&space=${btoa(
            encodeURIComponent(formData.space)
          )}&type=${btoa(
            encodeURIComponent(formData.type)
          )}${labelsQuery}${descriptionQuery}${templateIdQuery}`
        );
      } catch {
        return reportError({
          message: "Something went wrong and we cannot navigate you to policy creation",
        });
      }

      drawer.hide();
    };

    const namesToValidate = useMemo(
      () => takenPolicyNames.map((value) => value.toLowerCase()),
      [takenPolicyNames]
    );

    return (
      <DrawerForm onClose={cancelHandler} isDirty={isDirty} dataTestId={ADD_POLICY_DRAWER_TEST_ID}>
        <FormProvider {...createPolicyForm}>
          <form onSubmit={handleSubmit(redirectToCreateView)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title={title} />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight gap="large">
              <FormField label="Name" error={errors?.name?.message} noMargin>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Name of your new policy"
                    error={!!errors?.name}
                    {...register("name", {
                      setValueAs: (value) => value.trim(),
                      validate: validatePolicyName(namesToValidate),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <Controller
                name="type"
                control={control}
                rules={{ required: "Type field is required." }}
                render={({ field, fieldState }) => (
                  <ComboBox
                    placeholder="Type in or select type from the list"
                    value={field.value}
                    items={typeOptions}
                    onChange={field.onChange}
                    isDisabled={!!forcedType || !!templateId}
                    error={fieldState.error?.message}
                    label="Type"
                    tooltipAnalyticsPage={AnalyticsPagePolicy.PoliciesList}
                    tooltipAnalyticsTitle="Tooltip click"
                    tooltipAnalyticsProps={{ location: "Create drawer", name: "Type" }}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Choose policy type</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          Policies come in different types and flavour:
                          <ul>
                            <li>
                              <b>Access</b>: who gets to access individual Stacks and with what
                              level of access;
                            </li>
                            <li>
                              <b>Approval</b>: who can approve or reject a run and how a run can be
                              approved;
                            </li>
                            <li>
                              <b>Notification</b>: routing and filtering notifications;
                            </li>
                            <li>
                              <b>Plan</b>: which changes can be applied;
                            </li>
                            <li>
                              <b>Push</b>: how Git push events are interpreted;
                            </li>
                            <li>
                              <b>Trigger</b>: what happens when blocking runs terminate;
                            </li>
                          </ul>
                          <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/policy")} />
                        </TooltipModalBody>
                      </>
                    }
                  >
                    {(item) => <ComboBoxDefaultItem id={item.value} label={item.label} />}
                  </ComboBox>
                )}
              />

              <FormFieldSpace
                tooltipAnalyticsPage={AnalyticsPagePolicy.PoliciesList}
                tooltipAnalyticsTitle="Tooltip click"
                tooltipAnalyticsProps={{ location: "Create drawer", name: "Space" }}
                disabled={type === PolicyType.Access}
                tooltipInfo={
                  <FormFieldSpaceTooltipInfo>
                    Remember that you will only be able to attach policy to stacks and modules that
                    are in the same space.
                  </FormFieldSpaceTooltipInfo>
                }
                noMargin
              />

              {type === PolicyType.Access && (
                <Banner variant="info">
                  Access policy type is available only for legacy space.
                </Banner>
              )}

              <FormField
                label="Description"
                isOptional
                error={errors?.description?.message}
                noMargin
              >
                {({ ariaInputProps }) => (
                  <Textarea
                    placeholder="Enter policy description here..."
                    error={!!errors?.description}
                    {...register("description")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldTags
                stopEnterPropagation
                name="labels"
                tagName="label"
                label="Labels"
                isOptional
                noMargin
              />

              <Banner variant="info">
                Use <strong>autoattach:label</strong> to attach policy to stacks or modules
                automatically
                <FeedbackActions>
                  <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/policy#automatically")} />
                </FeedbackActions>
              </Banner>
            </DrawerBody>
            <DrawerFooter>
              <DrawerFooterActions>
                <DrawerCancelButton />

                <Button variant="primary" type="submit">
                  {submitButtonTitle}
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showPolicyCreateDrawer = createDrawerTrigger(PolicyCreateDrawer);
