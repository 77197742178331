import cx from "classnames";
import useLocalStorage from "@rehooks/local-storage";
import { differenceInDays, fromUnixTime } from "date-fns/esm";

import AccessPolicyWarning from "components/AccessPolicyWarning";
import DocumentationBar from "components/DocumentationBar";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import SideNavigation from "components/SideNavigation";
import { DOCUMENTATION_BAR_CLOSED } from "constants/local_storage_keys";
import { getNowTimeMs } from "utils/time";
import SelfHostedLicenseWarning from "components/SelfHostedLicenseWarning";

import styles from "./styles.module.css";
import { LayoutContext } from "./Context";

type LayoutProps = {
  className?: string;
  children: React.ReactNode;
};

const Layout = ({ children, className }: LayoutProps) => {
  const [isExpandedMode, toggleMode] = useLocalStorage("sideNavigationExpandedMode", true);
  const [documentationBarClosed] = useLocalStorage(DOCUMENTATION_BAR_CLOSED);

  const {
    viewer: { since, cannotEvaluateAccessPolicies },
  } = useTypedContext(AccountContext);

  const firstLoginTime = since ? fromUnixTime(since) : null;

  const shouldShowDocumentationBar =
    !documentationBarClosed &&
    firstLoginTime &&
    differenceInDays(getNowTimeMs(), firstLoginTime) < 14;

  const isDocumentationBarVisible = !!shouldShowDocumentationBar;

  return (
    <LayoutContext.Provider
      value={{
        isExpandedMode: !!isExpandedMode,
        isDocumentationBarVisible,
      }}
    >
      <main className={cx(styles.layout, className)}>
        <SideNavigation onChange={toggleMode} />

        <div
          id="layoutContent"
          className={cx(styles.content, { [styles.hasExpandedSideMenu]: isExpandedMode })}
        >
          {isDocumentationBarVisible && <DocumentationBar />}
          {cannotEvaluateAccessPolicies && <AccessPolicyWarning />}
          <SelfHostedLicenseWarning type="callout" storageKey="licenseWarningCallout" />
          {children}
        </div>
      </main>
    </LayoutContext.Provider>
  );
};

export default Layout;
