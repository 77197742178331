import { ReactNode, useState } from "react";

import Filters from "components/Filters";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import {
  FilterItem,
  FiltersItemsOptionsMap,
  SavedFilterView,
  SortOption,
} from "components/Filters/types";
import { InfoCircle } from "components/icons/generated";
import PaginationIndicator from "components/PaginationIndicator";
import SearchInput from "components/SearchInput";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import Link from "ds/components/Link";
import PageInfoHeader from "ds/components/PageInfoHeader";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";

import PoliciesFeatureGateCallout from "../../FeatureGateCallout";
import {
  FILTERS_ORDER_SETTINGS_KEY,
  FILTERS_TYPE,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
} from "../constants";
import PoliciesTemplatesFiltersLayoutHeader from "./Header";

type PoliciesTemplatesFiltersLayoutProps = {
  children: ReactNode;
  renderedEntitiesCount: number;
  isPageEmpty: boolean;
  loadingIndication: boolean;
  filteredCount: number;
  openPolicyTypesDrawer: () => void;
  openPolicyInfoDrawer: () => void;
  filters: FilterItem[];
  filtersMap: FiltersItemsOptionsMap;
  sortOptions: SortOption[];
  filtersLoading: boolean;
  refetchActiveSections: (fields: string[]) => void;
};

const PoliciesTemplatesFiltersLayout = ({
  filters,
  filtersMap,
  sortOptions,
  filtersLoading,
  refetchActiveSections,
  children,
  renderedEntitiesCount,
  isPageEmpty,
  loadingIndication,
  filteredCount,
  openPolicyTypesDrawer,
  openPolicyInfoDrawer,
}: PoliciesTemplatesFiltersLayoutProps) => {
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);

  return (
    <>
      <PageInfoHeader
        title={
          <Box align="center" gap="medium">
            <ViewHeaderTitle tag="h2">Templates</ViewHeaderTitle>
            <ButtonIcon onPress={openPolicyInfoDrawer} icon={InfoCircle} variant="ghost">
              Open info drawer
            </ButtonIcon>
          </Box>
        }
      >
        <Link onPress={openPolicyTypesDrawer} startIcon={InfoCircle} size="small">
          Policy types
        </Link>
        {!isPageEmpty && (
          <Box direction="row" align="center" gap="0 large">
            <PaginationIndicator
              currentCount={renderedEntitiesCount}
              totalCount={filteredCount}
              loading={loadingIndication}
              minimumLoadingDuration={200}
            />

            <SearchInput
              placeholder="Search templates"
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />
          </Box>
        )}
      </PageInfoHeader>
      <PoliciesFeatureGateCallout />
      <Filters
        filters={filters}
        filtersItemsOptionsMap={filtersMap}
        filtersLoading={filtersLoading}
        sortOptions={sortOptions}
        initialSortOption={INITIAL_SORT_OPTION}
        initialSortDirection={INITIAL_SORT_DIRECTION}
        pollActiveSections={refetchActiveSections}
        filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        filtersType={FILTERS_TYPE}
        currentSavedView={currentSavedView}
        setCurrentSavedView={setCurrentSavedView}
      >
        <FiltersSplit>
          <FiltersSidebar analyticsPage={AnalyticsPagePolicy.PoliciesTemplates} />

          <FiltersContentWrapper>
            {!isPageEmpty && <PoliciesTemplatesFiltersLayoutHeader />}

            {children}
          </FiltersContentWrapper>
        </FiltersSplit>
      </Filters>
    </>
  );
};

export default PoliciesTemplatesFiltersLayout;
