import { ReactNode, useMemo, useState } from "react";

import DocumentationIconButton from "components/DocumentationIconButton";
import { ArrowRight } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Icon from "ds/components/Icon";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import useTypedContext from "hooks/useTypedContext";

import { ModuleFormContext } from "../context";
import { ModuleCreationWizardStep, ModuleFormFields } from "../types";
import { areAllNextStepsOptional, isSkipToSummaryVisible } from "../utils";
import NewModuleFooterBackButton from "./BackButton";
import NewModuleFooterContinueButton from "./ContinueButton";
import { showNewModuleFooterDocumentationDrawer } from "./DocumentationDrawer";
import styles from "./styles.module.css";
import { GetData } from "./types";
import { useContinueAndCreateModule } from "./useContinueAndCreateModule";
import { useUpdateModule } from "./useUpdateModule";

// this helps mostly stories to render properly
const allowedIntermediateModuleCreationSteps = [
  ModuleCreationWizardStep.Details, // default
  ModuleCreationWizardStep.Behavior, // for self hosted
];

type NewModuleFooterProps = {
  loading?: boolean;
  handleOnCreateModuleClick?: () => void;
  processStepData?: GetData;
  documentationBody?: ReactNode;
  documentationTitle?: string;
  documentationLink?: string;
  analyticsDocsLocation?: string;
  isDataChanged?: boolean;
  localFormData?: ModuleFormFields;
  runFormValidations?: () => Promise<boolean>;
};

const NewModuleFooter = ({
  loading,
  handleOnCreateModuleClick,
  documentationTitle,
  documentationBody,
  documentationLink,
  analyticsDocsLocation,
  isDataChanged,
  processStepData,
  runFormValidations = async () => true,
}: NewModuleFooterProps) => {
  const {
    availableSteps,
    currentStep,
    currentStepIndex,
    createdModuleId,
    setIsIntermediateStepActive,
    isIntermediateStepActive,
    goToNextStep,
    goToPreviousStep,
    goToSummary,
  } = useTypedContext(ModuleFormContext);
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const { isLastStep, isFirstStep } = useMemo(() => {
    return {
      isLastStep: currentStep === ModuleCreationWizardStep.Summary,
      isFirstStep: currentStepIndex === 0,
    };
  }, [currentStep, currentStepIndex]);

  const isCreateAndContinueButtonEnabled =
    areAllNextStepsOptional(currentStep, availableSteps) &&
    !createdModuleId &&
    allowedIntermediateModuleCreationSteps.includes(currentStep);

  const skipToSummaryFromIntermediate = () => {
    setIsIntermediateStepActive(false);
    goToSummary();
  };

  const updateModuleHandler = useUpdateModule(isDataChanged);

  const skipToSummaryHandler = async () => {
    const isValidForm = await runFormValidations();
    if (!isValidForm) return;

    if (isIntermediateStepActive) {
      skipToSummaryFromIntermediate();
      return;
    }

    updateModuleHandler({
      getData: processStepData,
      setLoading: setIsSkipLoading,
      callback: goToSummary,
    });
  };

  const onContinue = async () => {
    const isValidForm = await runFormValidations();
    if (isValidForm) {
      updateModuleHandler({
        setLoading: setIsContinueLoading,
        callback: goToNextStep,
        getData: processStepData,
      });
    }
  };

  const continueAndCreateStack = useContinueAndCreateModule(setIsContinueLoading, processStepData);

  const continueAndCreateModuleHandler = async () => {
    const isValidForm = await runFormValidations();
    if (isValidForm) {
      continueAndCreateStack();
    }
  };

  const isSkipToSummaryEnabled =
    isSkipToSummaryVisible(currentStep, availableSteps) && createdModuleId;

  const isUpdateActive = createdModuleId && isDataChanged;

  const showDocumentationDrawer = () => {
    showNewModuleFooterDocumentationDrawer({
      documentationTitle,
      documentationBody,
      documentationLink,
      analyticsDocsLocation,
      currentStep,
    });
  };

  return (
    <FullScreenModalFooter className={styles.footerWrapper} justify="between">
      <Box gap="medium">
        {documentationTitle && documentationBody && !isLastStep && (
          <DocumentationIconButton
            onClick={showDocumentationDrawer}
            tooltipText="Open documentation"
            analyticsPage={AnalyticsPageModule.ModuleNew}
            analyticsTitle="Docs button click"
            analyticsProps={{
              location: currentStep,
            }}
          />
        )}
      </Box>
      <Box direction="row" gap="medium">
        {isSkipToSummaryEnabled && (
          <NewModuleFooterContinueButton
            analyticsTitle="Skip to summary click"
            analyticsProps={{
              location: currentStep,
            }}
            variant="secondary"
            loading={isSkipLoading}
            disabled={loading || isContinueLoading || isSkipLoading}
            onClickHandler={skipToSummaryHandler}
          >
            {isUpdateActive ? "Save & skip to summary" : "Skip to summary"}
          </NewModuleFooterContinueButton>
        )}

        {!isFirstStep && (
          <NewModuleFooterBackButton
            disabled={loading || isSkipLoading || isContinueLoading}
            callback={goToPreviousStep}
          />
        )}

        {!isLastStep && !isCreateAndContinueButtonEnabled && (
          <NewModuleFooterContinueButton
            analyticsTitle={`Continue click on ${currentStep} step`}
            variant="primary"
            onClickHandler={onContinue}
            loading={loading || isContinueLoading}
            disabled={isSkipLoading || loading || isContinueLoading}
            endIcon={({ className }) => <Icon src={ArrowRight} className={className} />}
          >
            {isUpdateActive ? "Save & continue" : "Continue"}
          </NewModuleFooterContinueButton>
        )}

        {isCreateAndContinueButtonEnabled && (
          <NewModuleFooterContinueButton
            analyticsTitle={`Create and continue click on ${currentStep} step`}
            variant="primary"
            onClickHandler={continueAndCreateModuleHandler}
            loading={loading || isContinueLoading}
            disabled={loading || isContinueLoading}
            endIcon={({ className }) => <Icon src={ArrowRight} className={className} />}
          >
            Create & continue
          </NewModuleFooterContinueButton>
        )}

        {isLastStep && (
          <Button
            variant="primary"
            onPress={handleOnCreateModuleClick}
            loading={loading}
            disabled={loading}
          >
            Confirm
          </Button>
        )}
      </Box>
    </FullScreenModalFooter>
  );
};

export default NewModuleFooter;
