import { gql, TypedDocumentNode } from "@apollo/client";

import { ExternalIntegration, MutationExternalIntegrationCreateArgs } from "types/generated";

type FetchAccountExternalIntegrationsData = {
  externalIntegrationCreate: Pick<ExternalIntegration, "id">;
};

export const CREATE_EXTERNAL_INTEGRATION: TypedDocumentNode<
  FetchAccountExternalIntegrationsData,
  MutationExternalIntegrationCreateArgs
> = gql`
  mutation CreateExternalIntegration($input: ExternalIntegrationInput!) {
    externalIntegrationCreate(input: $input) {
      id
    }
  }
`;

// This is just pleaceholder, the actual query is not provided
export const UPDATE_WEBHOOK_INTEGRATION = gql`
  mutation UpdateWebhookIntegrationForModule(
    $id: ID!
    $moduleId: ID!
    $input: WebhooksIntegrationInput!
  ) {
    webhooksIntegrationUpdate(id: $id, stack: $moduleId, input: $input) {
      id
    }
  }
`;
