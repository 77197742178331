import { getManagementStrategy } from "views/Account/Settings/helpers";
import { ManagedUser, UserStatus } from "types/generated";
import { SettingsContext } from "views/Account/Settings/Context";
import useTypedContext from "hooks/useTypedContext";

import UsersListItemInviteDropdown from "./InviteDropdown";
import UsersListItemDropdown from "./Dropdown";
import { showUsersDetailsDrawer } from "../../DetailsDrawer";
import useUsersListAnalytics from "../useUsersListAnalytics";
import { CellComponentProps } from "../types";

type UsersListItemActionsDropdownCellProps = CellComponentProps;

const UsersListItemActionsDropdownCell = ({
  user,
  managedUserGroups,
}: UsersListItemActionsDropdownCellProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const trackAnalytics = useUsersListAnalytics();

  const hasQueuedStatus = user.status === UserStatus.Queued;
  const hasPendingStatue = user.status === UserStatus.Pending;

  const isInvitation = hasQueuedStatus || hasPendingStatue;

  const handleUserDetailsClick = (user: ManagedUser) => {
    trackAnalytics("Access Details Click", {
      managementStrategy: getManagementStrategy(activationStatus),
    });
    showUsersDetailsDrawer({ id: user.id, userId: user.id, activationStatus, managedUserGroups });
  };

  if (!isInvitation) {
    return <UsersListItemDropdown handleUserDetailsClick={handleUserDetailsClick} item={user} />;
  }

  if (user.invitationEmail) {
    return (
      <UsersListItemInviteDropdown handleUserDetailsClick={handleUserDetailsClick} item={user} />
    );
  }

  return null;
};

export default UsersListItemActionsDropdownCell;
