import { useQuery } from "@apollo/client";

import shouldPollHelper from "./shouldPoll";
import { UsePolledQuery } from "./types";
import usePollingInterval from "./usePollingInterval";
import usePollingManagement from "./usePollingManagement";

const usePolledQuery: UsePolledQuery = (query, options) => {
  const {
    pollInterval: overridePollInterval,
    pollingGroup,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldPoll, // not needed in useLazyQuery
    ...restOptions
  } = options;
  const queryResult = useQuery(query, restOptions);

  const pollInterval = usePollingInterval(query, pollingGroup, overridePollInterval);

  usePollingManagement(
    pollInterval,
    queryResult.startPolling,
    queryResult.stopPolling,
    shouldPollHelper(options, queryResult),
    queryResult.refetch
  );

  return queryResult;
};

export default usePolledQuery;
