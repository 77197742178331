import { useId } from "react";

import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Box from "ds/components/Box";
import { useToggle } from "hooks/useToggle";
import { getDocsUrl } from "utils/getDocsUrl";

import { FAQ_ITEMS } from "./content";
import LaunchPadFAQItem from "./Item";

const LaunchPadFAQ = () => {
  const [isCollapsed, handleToggle] = useToggle(false);

  const titleId = useId();

  return (
    <CollapsiblePanel
      variant="section"
      onToggle={handleToggle}
      isCollapsed={isCollapsed}
      withTransition
    >
      <CollapsiblePanelHeader justify="between" padding="x-large" align="center">
        <Box grow="1" align="center" gap="medium">
          <CollapsiblePanelToggleTrigger ariaLabelledby={titleId} ariaLevel={2} />
          <CollapsiblePanelTitle id={titleId} variant="p-t5">
            FAQ
          </CollapsiblePanelTitle>
        </Box>
        <ReadMoreDocsLink docsUrl={getDocsUrl("/faq")} title="See all" />
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent padding="0 x-large x-large x-large" gap="large">
        {FAQ_ITEMS.map(({ title, content, learnMoreContent, learnMoreTitle }) => (
          <LaunchPadFAQItem
            key={title}
            title={title}
            learnMoreContent={learnMoreContent}
            learnMoreTitle={learnMoreTitle}
          >
            {content}
          </LaunchPadFAQItem>
        ))}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default LaunchPadFAQ;
