import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { AwsIntegration, AzureIntegration } from "types/generated";

import { SEARCH_CLOUD_INTEGRATIONS } from "./gql";

const useSearchCloudIntegrations = () => {
  const { onError } = useTypedContext(FlashContext);

  const { loading, error, data } = useQuery(SEARCH_CLOUD_INTEGRATIONS, {
    onError,
  });

  const entities = useMemo(() => {
    if (!data) return [];

    const awsIntegrations = (data.awsIntegrations || []).map((integration: AwsIntegration) => ({
      ...integration,
    }));

    const azureIntegrations = (data.azureIntegrations || []).map(
      (integration: AzureIntegration) => ({
        ...integration,
      })
    );

    return [...awsIntegrations, ...azureIntegrations];
  }, [data]);

  return {
    integrations: entities,
    error,
    isPageLoading: loading && !data,
  };
};

export default useSearchCloudIntegrations;
