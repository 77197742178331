import { NetworkStatus } from "@apollo/client";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import usePolledQuery from "apollo/usePolledQuery";
import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import ListEntitiesNew from "components/ListEntitiesNew";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { SharedModule } from "types/generated";
import { fuzzySearch } from "utils/fuzzySearch";

import { PollingIntervalGroups } from "../../../apollo/constants";
import SharedModulesEmptyState from "./components/EmptyState";
import ModuleVirtualizedListItem from "./components/ListItem/Virtualized";
import SharedModulesPageLayout from "./components/PageLayout";
import { GET_SHARED_MODULES } from "./gql";

const SharedModules = () => {
  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data, networkStatus } = usePolledQuery<{
    sharedModules: SharedModule[];
  }>(GET_SHARED_MODULES, {
    onError,
    pollingGroup: PollingIntervalGroups.Lists,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get(URL_SEARCH_KEY);

  const modules = useMemo(() => {
    const filteredModules = data?.sharedModules || [];

    if (searchQuery) {
      return fuzzySearch(filteredModules, searchQuery.trim(), {
        keys: ["description", "id", "labels", "name"],
        scoreThreshold: -1000,
      });
    }

    return filteredModules;
  }, [data, searchQuery]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && networkStatus === NetworkStatus.loading && !data?.sharedModules) {
    return (
      <SharedModulesPageLayout>
        <PageLayoutSkeleton />
      </SharedModulesPageLayout>
    );
  }

  if (networkStatus !== NetworkStatus.refetch && !loading && !data?.sharedModules) {
    return <NotFoundPage />;
  }

  return (
    <SharedModulesPageLayout>
      {data && !modules.length && <SharedModulesEmptyState didPerformSearch={!!searchQuery} />}

      {data && modules.length > 0 && (
        <ListEntitiesNew
          itemCount={modules.length}
          itemProps={{
            items: modules,
          }}
          virtualizedItem={ModuleVirtualizedListItem}
          itemKey={(index) => modules[index].id}
        />
      )}
    </SharedModulesPageLayout>
  );
};

export default SharedModules;
