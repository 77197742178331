import { create } from "zustand";
import { persist } from "zustand/middleware";

import { IacManagementGroupBy } from "../types";
import { IacManagementStore } from "./types";

type MakeIacManagementStoreProps = {
  storageKey: string;
  parentStackId?: string;
};

const makeIacManagementStore = ({ storageKey, parentStackId }: MakeIacManagementStoreProps) =>
  create<IacManagementStore>()(
    persist(
      (set) => ({
        groupBy: IacManagementGroupBy.Stack,
        setGroupBy: (groupBy) => set({ groupBy }),
        allItemsQty: 0,
        setAllItemsQty: (allItemsQty) => set({ allItemsQty }),
        pageIsEmpty: false,
        setPageIsEmpty: (pageIsEmpty) => set({ pageIsEmpty }),
        parentStackId,
      }),
      {
        name: storageKey,
        partialize: (state) => ({
          groupBy: state.groupBy,
        }),
      }
    )
  );

export default makeIacManagementStore;
