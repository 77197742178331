import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { SearchQueryOrderDirection } from "types/generated";

export const USER_ROLES_COLUMNS_STORAGE_KEY = "userRolesColumnsConfig";
export const FILTERS_ORDER_SETTINGS_KEY = "userRolesFiltersOrder";

export const initialSortOption = "name";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export enum USER_ROLES_COLUMN_ID {
  NAME = "NAME",
  DESCRIPTION = "DESCRIPTION",
  GRANTED_AT = "GRANTED_AT",
  DROPDOWN = "DROPDOWN",
}

export const ROW_HEADER_ID = USER_ROLES_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 600;

export const USER_ROLES_COLUMN_CONFIG: ColumnConfig = {
  [USER_ROLES_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    resizable: false,
  },

  [USER_ROLES_COLUMN_ID.DESCRIPTION]: {
    title: "Description",
    minWidth: 130,
    maxWidth: MAX_CELL_WIDTH,
    resizable: false,
    static: true,
  },

  [USER_ROLES_COLUMN_ID.GRANTED_AT]: {
    title: "Granted at",
    minWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
    static: true,
    resizable: false,
  },
};

export const USER_ROLES_CUSTOM_END_COLUMN_CONFIG: ColumnConfig = {
  [USER_ROLES_COLUMN_ID.DROPDOWN]: {
    title: "More actions",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
  },
};

export const SORT_CONFIG: Record<string, string> = {
  [USER_ROLES_COLUMN_ID.NAME]: "name",
  [USER_ROLES_COLUMN_ID.GRANTED_AT]: "grantedAt",
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(USER_ROLES_COLUMN_CONFIG) as USER_ROLES_COLUMN_ID[],
  hidden: [],
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageOrganization.OrganizationUserRoles,
};
