import { StateCreator } from "zustand";

import { reorderColumns } from "ds/components/Table/Head/helpers";
import { TableColumnMeta } from "ds/components/Table/types";

import { type IacTableMetaSlice } from "./types";
import { DEFAULT_GROUP_META } from "./constants";
import { IacTableStoreState } from "../types";

const makeIacTableMetaSlice: (
  initialColumns: Record<TableColumnMeta["id"], TableColumnMeta>
) => StateCreator<
  IacTableStoreState,
  [["zustand/immer", never]],
  [["zustand/persist", never]],
  IacTableMetaSlice
> = (initialColumns) => (set) => {
  const initialColumnsState = initialColumns;
  const initialColumnsSizes = Object.fromEntries(
    Object.entries(initialColumns).map(([key, value]) => [key, value.defaultWidth])
  );
  const initialColumnsOrder = {
    visible: Object.keys(initialColumns),
    hidden: [],
  };

  return {
    columnsState: initialColumnsState,
    columnsSizes: initialColumnsSizes,
    setColumnSize: (columnId, size) => {
      set((state) => {
        state.columnsSizes[columnId] = size;
      });
    },
    columnsOrder: initialColumnsOrder,
    reorderVisibleColumns: (columnId, direction) => {
      set((state) => {
        state.columnsOrder.visible = reorderColumns(
          state.columnsOrder.visible,
          columnId,
          direction
        );
      });
    },
    hideColumn: (columnId) => {
      set((state) => {
        state.columnsOrder = {
          visible: state.columnsOrder.visible.filter((id) => id !== columnId),
          hidden: state.columnsOrder.hidden.includes(columnId)
            ? state.columnsOrder.hidden
            : [...state.columnsOrder.hidden, columnId],
        };
      });
    },
    updateColumnsConfig: (visibleColumns, hiddenColumns) => {
      set({
        columnsOrder: {
          visible: visibleColumns,
          hidden: hiddenColumns,
        },
      });
    },
    resetColumnsConfig: () => {
      set((state) => {
        state.columnsState = initialColumnsState;
        state.columnsSizes = initialColumnsSizes;
        state.columnsOrder = initialColumnsOrder;
      });
    },
    groupsMeta: {},
    toggleGroupMeta: (groupId) =>
      set((state) => {
        const groupMeta = state.groupsMeta[groupId] || DEFAULT_GROUP_META;
        state.groupsMeta[groupId] = {
          ...groupMeta,
          isOpen: !groupMeta.isOpen,
        };
      }),
    openGroup: (groupId) =>
      set((state) => {
        const groupMeta = state.groupsMeta[groupId] || DEFAULT_GROUP_META;
        state.groupsMeta[groupId] = {
          ...groupMeta,
          isOpen: true,
        };
      }),
    updateGroupMeta: (groupId, meta) =>
      set((state) => {
        const groupMeta = state.groupsMeta[groupId] || DEFAULT_GROUP_META;
        state.groupsMeta[groupId] = { ...groupMeta, ...meta };
      }),
  };
};

export default makeIacTableMetaSlice;
