import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Policy } from "types/generated";

import PoliciesListItem from "./Item";

type PoliciesListVirtualizedItemProps = {
  items: Policy[];
  onEdit: (policy: Policy) => void;
};

const PoliciesListVirtualizedItem = (
  props: ListChildComponentProps<PoliciesListVirtualizedItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <PoliciesListItem
        key={data.items[index].id}
        name={data.items[index].name}
        type={data.items[index].type}
        labels={data.items[index].labels}
        createdAt={data.items[index].createdAt}
        updatedAt={data.items[index].updatedAt}
        description={data.items[index].description}
        id={data.items[index].id}
        spaceDetails={data.items[index].spaceDetails}
        setRowHeight={handleSetRowHeight}
        onEdit={() => data.onEdit(data.items[index])}
        hasAttachedEntities={data.items[index].hasAttachedStacks}
      />
    </div>
  );
};

export default memo(PoliciesListVirtualizedItem, areEqual);
