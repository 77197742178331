import { Gear } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";

import { showIacManagementTableSettingsDrawer } from "./Drawer";

const IacManagementTableSettings = () => {
  const handleOpenCustomizeDrawer = () => showIacManagementTableSettingsDrawer();
  return (
    <>
      <span className="sr-only">Resources actions</span>
      <ButtonIcon size="small" variant="ghost" onPress={handleOpenCustomizeDrawer} icon={Gear}>
        Customize table layout
      </ButtonIcon>
    </>
  );
};

export default IacManagementTableSettings;
