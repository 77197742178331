import { memo } from "react";

import IacManagementTableSkeletonRow from "./Row";

type IacManagementTableSkeletonProps = {
  rowsPerPage: number;
};

const IacManagementTableSkeleton = ({ rowsPerPage }: IacManagementTableSkeletonProps) => {
  return (
    <div style={{ display: "table-row-group" }} role="rowgroup">
      {Array.from({ length: rowsPerPage }).map((_, i) => (
        <IacManagementTableSkeletonRow key={i} dataIndex={i} />
      ))}
    </div>
  );
};

export default memo(IacManagementTableSkeleton);
