import { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpaces from "components/FormFields/Spaces";
import Banner from "ds/components/Banner";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import { getDrawerFormFix } from "utils/css";

import { UserManagementActivationStatus } from "../../types";
import { GRANT_SLACK_ACCESS_DRAWER_TEST_ID, commonDefaultValues } from "./constants";
import { CreateIntegrationFields } from "./types";
import useCreateIntegration from "./useCreateIntegration";

type ManageSlackAccessCreateDrawerProps = {
  activationStatus: UserManagementActivationStatus;
};

const ManageSlackAccessCreateDrawer = createDrawer(
  ({ activationStatus }: ManageSlackAccessCreateDrawerProps) => {
    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const groupIntegrationCreateForm = useForm<CreateIntegrationFields>({
      defaultValues: commonDefaultValues,
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = groupIntegrationCreateForm;

    const { onCreate } = useCreateIntegration();
    const drawer = useModal();

    const onSubmit: SubmitHandler<CreateIntegrationFields> = (formData) => {
      const input = {
        integrationType: formData.integrationType,
        integrationName: formData.name,
        accessRules: formData.spaces,
        slackChannelID: formData.slackChannelID || "",
      };
      onCreate(input)
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: `Slack access was granted`,
            });

            drawer.hide();
          }
        })
        .catch(onError);
    };

    return (
      <DrawerSimple dataTestId={GRANT_SLACK_ACCESS_DRAWER_TEST_ID}>
        <FormProvider {...groupIntegrationCreateForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Grant access" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight hasStickyFooter>
              {activationStatus === UserManagementActivationStatus.INACTIVE && (
                <Banner variant="warning" title="User management is inactive">
                  Access rules wouldn’t take effect until you switch to user management.
                </Banner>
              )}
              <FormField label="Name" error={errors?.name?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter the name here"
                    error={!!errors?.name}
                    {...register("name", {
                      required: "Name field is required.",
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormField label="Slack channel ID" error={errors?.slackChannelID?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter the Slack channel ID here"
                    error={!!errors?.slackChannelID}
                    {...register("slackChannelID", {
                      required: "Slack channel ID field is required.",
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldSpaces />

              <DrawerFooter sticky>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button variant="primary" type="submit">
                    Add
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showManageSlackAccessCreateDrawer = createDrawerTrigger(ManageSlackAccessCreateDrawer);
