import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";

import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import Counter from "ds/components/Counter";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerForm from "ds/components/DrawerNew/Form";
import { SpacesContext } from "views/Account/SpacesProvider";
import useTypedContext from "hooks/useTypedContext";

import AssignRoleDrawerListItem from "./ListItem";
import AssignRoleDrawerFormAdd from "./AddForm";
import { USER_ROLE_BINDINGS_WITH_ROLES } from "./gql";
import { CREATE_FORM_ID } from "./constants";

type AssignRoleDrawerProps = {
  userId: string;
};

const AssignRoleDrawer = createDrawer(({ userId }: AssignRoleDrawerProps) => {
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({
    [CREATE_FORM_ID]: false,
  });
  const { manageableSpaces } = useTypedContext(SpacesContext);

  const setIsDirtyHandler = useCallback(
    (formId: string, value: boolean) => setDirtyFields((prev) => ({ ...prev, [formId]: value })),
    []
  );

  const { data, loading } = useQuery(USER_ROLE_BINDINGS_WITH_ROLES, {
    variables: {
      id: userId,
    },
  });

  const assignedRoles = data?.managedUser.userRoleBindings || [];
  const assignedRolesCount = assignedRoles.length;

  const availableRoles = useMemo(() => data?.roles || [], [data?.roles]);

  const allRolesById = useMemo(
    () =>
      data?.roles?.reduce(
        (prev, next) => ({ ...prev, [next.id]: next.name }),
        {} as Record<string, string>
      ) || {},
    [data?.roles]
  );

  const isDirty = useMemo(() => !!Object.values(dirtyFields).filter(Boolean).length, [dirtyFields]);

  return (
    <DrawerForm isDirty={isDirty}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Manage roles" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Box direction="column" gap="large">
          <AssignRoleDrawerFormAdd
            isLoading={loading}
            setIsDirty={setIsDirtyHandler}
            availableRoles={availableRoles}
            userId={userId}
          />

          {assignedRolesCount > 0 && (
            <Box direction="column">
              <Box gap="small">
                <Typography tag="h5" variant="p-t5">
                  Roles assigned
                </Typography>

                <Counter count={assignedRolesCount} size="small" />
              </Box>
            </Box>
          )}

          {assignedRoles.map((roleAssignment) => (
            <AssignRoleDrawerListItem
              userId={userId}
              setIsDirty={setIsDirtyHandler}
              roleAssignmentId={roleAssignment.id}
              key={roleAssignment.roleID}
              roleId={roleAssignment.roleID}
              roleName={allRolesById[roleAssignment.roleID]}
              spaceId={roleAssignment.spaceID}
              spaceName={
                manageableSpaces.find(({ id }) => id === roleAssignment.spaceID)?.name || ""
              }
              availableRoles={availableRoles}
            />
          ))}
        </Box>
      </DrawerBody>
    </DrawerForm>
  );
});

export const showAssignRoleDrawer = createDrawerTrigger(AssignRoleDrawer);
