import { CSSProperties, useCallback, useMemo, useRef } from "react";
import { Cell, Row } from "react-aria-components";
import { useParams } from "react-router-dom";

import TableContextProvider from "components/Table/Context";
import Table from "components/Table";
import TableRow from "components/Table/Row";
import useTableSort from "components/Table/useTableSort";
import TableWrapper from "components/Table/Wrapper";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import usePolledQuery from "apollo/usePolledQuery";
import { PollingIntervalGroups } from "apollo/constants";
import useErrorHandle from "hooks/useErrorHandle";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";

import PermissionsListItem from "./PermissionsListItem";
import RolePageLayout from "./PageLayout";
import {
  ANALYTICS,
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_COLUMNS_VIEW_CONFIG,
  initialSortDirection,
  initialSortOption,
  PERMISSIONS,
  PERMISSIONS_COLUMN_CONFIG,
  PERMISSIONS_COLUMNS_STORAGE_KEY,
  PERMISSIONS_CUSTOM_END_COLUMN_CONFIG,
  ROW_HEADER_ID,
  SORT_CONFIG,
} from "./constants";
import { Permission } from "./types";
import { GET_ROLE } from "./gql";
import { useFilteredEntities } from "./useFilteredEntities";

const Role = () => {
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const { onError } = useTypedContext(FlashContext);
  const { roleId } = useParams<{ roleId: string }>();
  const { handleSortOptionChange, sortDescriptor } = useTableSort({
    filtersOrderSettingsKey: FILTERS_ORDER_SETTINGS_KEY,
    sortConfig: SORT_CONFIG,
    initialSortOption,
    initialSortDirection,
  });

  const { data, loading, error } = usePolledQuery(GET_ROLE, {
    variables: {
      id: roleId!,
    },
    skip: !roleId,
    onError,
    pollingGroup: PollingIntervalGroups.Default,
  });

  const renderRow = useCallback(
    (
      item: Partial<Permission> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.id}
          >
            <PermissionsListItem permission={item as Permission} />
          </TableRow>
        );
      }
    },
    []
  );

  const permissions = useMemo(
    () =>
      data?.role.actions.map((action) => ({
        ...PERMISSIONS[action],
        id: action,
        deleteDisabled: data?.role.actions.length === 1,
        parentRole: data.role,
      })) || [],
    [data?.role]
  );

  const filteredPermissions = useFilteredEntities(permissions, sortDescriptor);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (!data?.role) {
    return loading ? <PageLoading /> : <NotFoundPage />;
  }

  // Exclude system role if someone try to access it
  if (data?.role.isSystem) {
    return <NotFoundPage />;
  }

  return (
    <TableContextProvider
      columnsConfig={PERMISSIONS_COLUMN_CONFIG}
      endCustomColumns={PERMISSIONS_CUSTOM_END_COLUMN_CONFIG}
      initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
      rowHeaderId={ROW_HEADER_ID}
      localStorageId={PERMISSIONS_COLUMNS_STORAGE_KEY}
      setSortDescriptor={handleSortOptionChange}
      sortDescriptor={sortDescriptor}
      {...ANALYTICS}
    >
      <RolePageLayout
        name={data.role.name}
        id={data.role.id}
        roleBindingsCount={data.role.roleBindingsCount}
      >
        {!!filteredPermissions?.length && (
          <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
            <Table
              ariaLabel="Permissions"
              items={filteredPermissions}
              ref={virtualizedListContainerRef}
            >
              {renderRow}
            </Table>
          </TableWrapper>
        )}
        {!filteredPermissions.length && !!permissions?.length && (
          <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
        )}
      </RolePageLayout>
    </TableContextProvider>
  );
};

export default Role;
