import { CSSProperties, useCallback, useEffect, useMemo, useRef } from "react";
import { Cell, Row } from "react-aria-components";

import TableContextProvider from "components/Table/Context";
import Table from "components/Table";
import useSelectionStore from "components/Table/useSelectionStore";
import TableRow from "components/Table/Row";
import useTableSort from "components/Table/useTableSort";
import TableWrapper from "components/Table/Wrapper";
import usePolledQuery from "apollo/usePolledQuery";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { PollingIntervalGroups } from "apollo/constants";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import { Role } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import NotFoundPage from "components/error/NotFoundPage";
import useErrorHandle from "hooks/useErrorHandle";

import RolesListItem from "./ListItem";
import RolesPageLayout from "./PageLayout";
import {
  ANALYTICS,
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_COLUMNS_VIEW_CONFIG,
  initialSortDirection,
  initialSortOption,
  ROLES_COLUMN_CONFIG,
  ROLES_COLUMNS_STORAGE_KEY,
  ROLES_CUSTOM_END_COLUMN_CONFIG,
  ROW_HEADER_ID,
  SORT_CONFIG,
} from "./constants";
import RolesBulkActions from "./BulkActions";
import { GET_ROLES } from "./gql";
import { useFilteredEntities } from "./useFilteredEntities";
import RolesEmpty from "./Empty";

const Roles = () => {
  const { resetAllSelected, selected, unselect, resetStore } = useSelectionStore();
  const { onError } = useTypedContext(FlashContext);
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const { handleSortOptionChange, sortDescriptor } = useTableSort({
    filtersOrderSettingsKey: FILTERS_ORDER_SETTINGS_KEY,
    sortConfig: SORT_CONFIG,
    initialSortOption,
    initialSortDirection,
  });

  const { data, loading, refetch, error } = usePolledQuery(GET_ROLES, {
    onError,
    pollingGroup: PollingIntervalGroups.Lists,
  });

  const handleBulkActionsFinish = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const onBulkResetAll = useCallback(() => {
    resetAllSelected();
  }, [resetAllSelected]);

  // reset store when unmounted
  useEffect(() => resetStore, [resetStore]);

  const renderRow = useCallback(
    (
      item: Partial<Role> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.name}
            isSelectableDisabled={item.isSystem}
          >
            <RolesListItem role={item as Role} />
          </TableRow>
        );
      }
    },
    []
  );

  const roles = useMemo(
    () => data?.roles.map((item) => ({ ...item, isSelectionDisabled: item.isSystem })) || [],
    [data?.roles]
  );

  // Exclude system roles
  const nonSelectableIds = useMemo(
    () =>
      roles.reduce(
        (prev, next) => (next.isSelectionDisabled ? [...prev, next.id] : prev),
        [] as string[]
      ),
    [roles]
  );

  const memoizedSelectedRolesMap = useMemo(
    () =>
      new Map(
        data?.roles
          .filter((edge) => !nonSelectableIds.includes(edge.id))
          .map((edge) => [edge.id, edge])
      ),
    [data?.roles, nonSelectableIds]
  );

  const filteredRoles = useFilteredEntities(roles, sortDescriptor);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.roles) {
    return (
      <RolesPageLayout>
        <PageLayoutSkeleton />
      </RolesPageLayout>
    );
  }

  if (!loading && !data?.roles) {
    return <NotFoundPage />;
  }

  return (
    <TableContextProvider
      selectable
      columnsConfig={ROLES_COLUMN_CONFIG}
      endCustomColumns={ROLES_CUSTOM_END_COLUMN_CONFIG}
      initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
      rowHeaderId={ROW_HEADER_ID}
      localStorageId={ROLES_COLUMNS_STORAGE_KEY}
      setSortDescriptor={handleSortOptionChange}
      sortDescriptor={sortDescriptor}
      {...ANALYTICS}
    >
      <RolesPageLayout>
        {!!filteredRoles?.length && (
          <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
            <Table
              nonSelectableIds={nonSelectableIds}
              ariaLabel="Roles list"
              items={filteredRoles}
              ref={virtualizedListContainerRef}
            >
              {renderRow}
            </Table>

            {selected.size > 0 && (
              <RolesBulkActions
                virtualizedListContainerRef={virtualizedListContainerRef}
                selectedSet={selected}
                rolesMap={memoizedSelectedRolesMap}
                onBulkResetAll={onBulkResetAll}
                onItemDismiss={unselect}
                onFinish={handleBulkActionsFinish}
                isLoadingItems={loading}
              />
            )}
          </TableWrapper>
        )}
        {!roles?.length && <RolesEmpty />}
        {!filteredRoles.length && !!roles?.length && (
          <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
        )}
      </RolesPageLayout>
    </TableContextProvider>
  );
};

export default Roles;
