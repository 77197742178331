import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import CollapsibleSectionPanel from "components/CollapsibleSectionPanel";
import PageInfo from "components/PageWrapper/Info";
import NotFoundPage from "components/error/NotFoundPage";
import { EmptystateHelmetColored, EmptystateMagnetColored } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import useGetAttachedPolicies from "shared/Policy/useGetAttachedPolicies";

import { ModuleContext } from "../Context";
import { showPoliciesAttachDrawer } from "./AttachDrawer";
import ModulePoliciesListItem from "./ListItem";
import { EMPTY_STATE_CAPTION } from "./constants";

const ModulePolicies = () => {
  const { module, canManageModule } = useTypedContext(ModuleContext);

  useTitle(`Policies · ${module.name}`);

  useBreadcrumbs([
    {
      title: "Modules",
      link: "/modules",
    },
    {
      title: module.id,
    },
  ]);

  const {
    manuallyAttachedPolicies,
    autoAttachedPolicies,
    allAttachedPoliciesSet,
    isPageLoading,
    isPageNotFound,
    error,
  } = useGetAttachedPolicies({
    entityId: module.id,
    entityType: "module",
  });

  const handleOpenAttachDrawer = () => {
    showPoliciesAttachDrawer({
      alreadyAttachedPolicies: allAttachedPoliciesSet,
      entityId: module.id,
      spaceId: module.spaceDetails.id,
    });
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <>
      <PageInfo title="Policies">
        {canManageModule && (
          <Button variant="primary" onPress={handleOpenAttachDrawer}>
            Attach policy
          </Button>
        )}
      </PageInfo>

      <Box direction="column" gap="x-large">
        <CollapsibleSectionPanel
          ariaLevel={3}
          title="Manually attached"
          count={manuallyAttachedPolicies.length}
          initialIsCollapsed={false}
        >
          {manuallyAttachedPolicies.map((policy) => (
            <ModulePoliciesListItem key={policy.id} policy={policy} />
          ))}

          {manuallyAttachedPolicies.length === 0 && (
            <EmptyState
              padding="large"
              icon={EmptystateHelmetColored}
              title="No policies attached yet."
              caption={EMPTY_STATE_CAPTION}
            />
          )}
        </CollapsibleSectionPanel>

        <CollapsibleSectionPanel
          ariaLevel={3}
          title="Auto-attached"
          count={autoAttachedPolicies.length}
          initialIsCollapsed={false}
        >
          {autoAttachedPolicies.map((policy) => (
            <ModulePoliciesListItem key={policy.id} policy={policy} moduleLabels={module.labels} />
          ))}

          {autoAttachedPolicies.length === 0 && (
            <EmptyState
              padding="large"
              icon={EmptystateMagnetColored}
              title="No policies auto-attached yet."
              caption={EMPTY_STATE_CAPTION}
            />
          )}
        </CollapsibleSectionPanel>
      </Box>
    </>
  );
};

export default ModulePolicies;
