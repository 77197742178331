import { useMutation } from "@apollo/client";
import { useEffect, useMemo, useRef, useState } from "react";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import FlashContext from "components/FlashMessages/FlashContext";
import useAnalytics from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import { hasAtLeastModuleWriteAccess } from "shared/Module/utils";
import { hasAtLeastStackWriteAccess } from "shared/Stack/utils";
import { Module, RunStateSummary, Stack, SummaryProcessingStatus } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import { RunContext } from "views/shared/run/Context";

import { GET_PHASE_SUMMARIZATION, TRIGGER_PHASE_SUMMARIZATION } from "./gql";

const isStack = (stack: Stack | Module): stack is Stack => (stack as Stack).__typename === "Stack";

export const usePhaseSummary = (stateVersion: number) => {
  const { onError } = useTypedContext(FlashContext);
  const { run, stack: stackOrModule } = useTypedContext(RunContext);
  const { acceptedTermsAndConditionsAI } = useTypedContext(AccountContext);
  const summaryContainerRef = useRef<HTMLDivElement>(null);
  const trackSegmentAnalyticsEvent = useAnalytics();
  const [shouldPoll, setShouldPoll] = useState(false);

  const [trigger, { data, error, loading }] = useMutation<{
    summarizeRunState: RunStateSummary;
  }>(TRIGGER_PHASE_SUMMARIZATION, {
    variables: { stack: stackOrModule.id, run: run.id, stateVersion },
    onError,
  });

  const { data: pollData } = usePolledQuery<{
    stack: Stack;
  }>(GET_PHASE_SUMMARIZATION, {
    pollingGroup: PollingIntervalGroups.AiSummarization,
    shouldPoll: () => shouldPoll,
    variables: { stack: stackOrModule.id, run: run.id },
    onError,
  });

  const { summary, date, summarizable, status } = useMemo(() => {
    const phase = run.history?.find((phase) => phase.stateVersion === stateVersion);

    const phaseSummary = phase?.summary;
    const summarizable = !!phase?.summarizable;

    const pollPhase = pollData?.stack?.run?.history?.find(
      (phase) => phase.stateVersion === stateVersion
    );

    return {
      summary: phaseSummary?.summary ?? data?.summarizeRunState.summary,
      date: phaseSummary?.createdAt ?? data?.summarizeRunState.createdAt,
      summarizable,
      status:
        pollPhase?.summary?.processingStatus ??
        phaseSummary?.processingStatus ??
        data?.summarizeRunState.processingStatus,
    };
  }, [run, data?.summarizeRunState, pollData, stateVersion]);

  useEffect(() => {
    const el = summaryContainerRef.current;
    if (!el) return;

    const handleCopy = () => {
      if (!document.getSelection()?.toString()) {
        return;
      }

      trackSegmentAnalyticsEvent("Run Logs - Suggestion Code Snippets Copied", {
        runId: run.id,
        runState: run.state,
        stackId: stackOrModule.id,
        stateVersion,
      });
    };

    el.addEventListener("copy", handleCopy);

    return () => {
      el.removeEventListener("copy", handleCopy);
    };
  }, [run.id, run.state, stackOrModule, stateVersion, trackSegmentAnalyticsEvent]);

  useEffect(() => {
    if (status === SummaryProcessingStatus.Failed) {
      onError("Failed to summarize run");
    }
    if (status !== SummaryProcessingStatus.InProgress) {
      setShouldPoll(false);
    }
  }, [status, onError]);

  const triggerSummary = () => {
    trackSegmentAnalyticsEvent("Run Logs - AI Phase Summarization Clicked", {
      stackId: stackOrModule.id,
      runId: run.id,
      runState: run.state,
      stateVersion,
    });
    trigger().then(() => {
      setShouldPoll(true);
    });
  };

  const canTriggerSummary = isStack(stackOrModule)
    ? hasAtLeastStackWriteAccess(stackOrModule)
    : hasAtLeastModuleWriteAccess(stackOrModule);
  const loadingStatus =
    loading ||
    status === SummaryProcessingStatus.InProgress ||
    (status === SummaryProcessingStatus.Success && !summary);
  const showSummary = !!summary || loadingStatus;
  const showTrigger = summarizable && !showSummary;

  return {
    acceptedTermsAndConditionsAI,
    canTriggerSummary,
    date,
    error,
    loading: loadingStatus,
    showSummary,
    showTrigger,
    summary,
    summaryContainerRef,
    trigger: triggerSummary,
  };
};
