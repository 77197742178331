import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { Action } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import useErrorHandle from "hooks/useErrorHandle";

import RoleFullScreenForm from "../FullScreenForm";
import { ROLE_UPDATE } from "./gql";
import { FormValues } from "../FullScreenForm/types";
import { CONFIG } from "../FullScreenForm/constants";
import { GET_ROLE } from "../gql";

const RoleEdit = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { roleId } = useParams<{ roleId: string }>();

  const navigate = useNavigate();

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(GET_ROLE, {
    variables: {
      id: roleId!,
    },
    skip: !roleId,
    onError,
  });

  const initialValues: FormValues = useMemo(() => {
    return {
      name: data?.role.name || "",
      description: data?.role.description || "",
      permissions: CONFIG.reduce((acc, next) => {
        return {
          ...acc,
          [next.id]: next.permissions.reduce(
            (acc, next) => ({ ...acc, [next.action]: data?.role.actions.includes(next.action) }),
            {}
          ),
        };
      }, {}),
    };
  }, [data?.role.actions, data?.role.description, data?.role.name]);

  const [updateRole, { loading }] = useMutation(ROLE_UPDATE);

  const onSubmit = useCallback(
    (formValues: FormValues) => {
      if (!roleId) {
        // TODO: [ACC] error
        return;
      }

      const actions = Object.keys(formValues.permissions).reduce((acc, next) => {
        const value = formValues.permissions[next];

        return [...acc, ...(Object.keys(value) as Action[]).filter((key) => !!value[key])];
      }, [] as Action[]);

      const input = {
        name: formValues.name,
        description: formValues.description,
        // TODO: [ACC] update after BE update typo
        Actions: actions,
      };

      updateRole({
        variables: {
          id: roleId,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.roleUpdate.id) {
            navigate(`/settings/role/${data?.roleUpdate.id}`, { replace: true });
            reportSuccess({ message: "Role has been successfully updated" });
          }
        })
        .catch(onError);
    },
    [updateRole, roleId, navigate, reportSuccess, onError]
  );

  useTitle(`Settings · Edit role · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (!loading && !data?.role) {
    return <NotFoundPage />;
  }

  return (
    <RoleFullScreenForm
      title="Edit role"
      saveButtonTitle="Save"
      leaveTitle="Do you want to leave role edition process?"
      analyticsPage={AnalyticsPageOrganization.OrganizationRoleUpdate}
      submitLoading={loading}
      onSubmit={onSubmit}
      isFormDataLoading={queryLoading}
      initialValues={initialValues}
    />
  );
};

export default RoleEdit;
