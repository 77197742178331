import { useCallback, useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { SearchQueryPredicate } from "types/generated";
import { uniqByKey } from "utils/uniq";
import fetchMoreUpdateQueryPreviousFix from "apollo/fetchMoreUpdateQueryPreviousFix";

import { AnsibleHostData, SEARCH_ANSIBLE_HOSTS } from "./gql";
import { createAnsibleNodesByHost } from "./utils";
import { LayoutMode } from "./types";

const useSearchAnsibleHosts = (
  input: {
    first: number;
    after: null;
    requestedPage: null;
    fullTextSearch: string;
    predicates: SearchQueryPredicate[];
    orderBy: null;
  },
  layoutMode: LayoutMode,
  skip?: boolean
) => {
  const { onError } = useTypedContext(FlashContext);
  const cachedAnsibleHostsEdges = useRef<AnsibleHostData[]>([]);

  const {
    data: hostsData,
    loading: hostsLoading,
    networkStatus: hostsNetworkStatus,
    error: hostsError,
    fetchMore,
  } = useQuery(SEARCH_ANSIBLE_HOSTS, {
    variables: { input },
    onError,
    skip,
  });

  const loadMoreItems = useCallback(async () => {
    try {
      if (
        hostsData?.searchAnsibleHosts?.pageInfo.endCursor &&
        hostsData?.searchAnsibleHosts?.pageInfo.hasNextPage
      ) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            const previousData = fetchMoreUpdateQueryPreviousFix(
              !!prev?.searchAnsibleHosts,
              prev,
              hostsData
            );

            if (
              fetchMoreResult?.searchAnsibleHosts?.edges?.length &&
              fetchMoreResult?.searchAnsibleHosts?.edges?.length > 0
            ) {
              return {
                searchAnsibleHosts: {
                  ...fetchMoreResult.searchAnsibleHosts,
                  edges: uniqByKey(
                    [
                      ...(prev.searchAnsibleHosts!.edges || []),
                      ...fetchMoreResult.searchAnsibleHosts.edges,
                    ],
                    "cursor"
                  ),
                },
              };
            }

            // TODO: [Ansible v2] remove when backend fix search for last bunch of items
            if (fetchMoreResult.searchAnsibleHosts?.pageInfo.hasNextPage === false) {
              return {
                searchAnsibleHosts: {
                  edges: previousData.searchAnsibleHosts?.edges || [],
                  pageInfo: {
                    ...previousData.searchAnsibleHosts?.pageInfo,
                    ...fetchMoreResult.searchAnsibleHosts?.pageInfo,
                  },
                },
              };
            }

            return previousData;
          },
          variables: {
            input: {
              first: input.first,
              after: hostsData.searchAnsibleHosts.pageInfo.endCursor,
              fullTextSearch: input.fullTextSearch,
              predicates: input.predicates,
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  }, [hostsData, fetchMore, input.fullTextSearch, input.predicates, input.first, onError]);

  const ansibleHosts = useMemo(() => {
    const sourceEdges = hostsData?.searchAnsibleHosts?.edges.map((edge) => edge.node) || [];
    const edges =
      hostsLoading && !sourceEdges.length ? cachedAnsibleHostsEdges.current : sourceEdges;

    if (!hostsLoading) {
      cachedAnsibleHostsEdges.current = sourceEdges;
    }

    return edges;
  }, [hostsData?.searchAnsibleHosts?.edges, hostsLoading]);

  const location = useLocation();

  const nodes = useMemo(() => {
    const isChart = layoutMode === LayoutMode.Diagram;

    return createAnsibleNodesByHost(ansibleHosts, isChart, location.pathname);
  }, [ansibleHosts, layoutMode, location.pathname]);

  const isHostsPageLoading =
    hostsLoading && !ansibleHosts.length && hostsNetworkStatus === NetworkStatus.loading;

  const response = useMemo(() => {
    return {
      hasNextPageToLoad: hostsData?.searchAnsibleHosts?.pageInfo.hasNextPage,
      error: hostsError,
      loading: hostsLoading,
      isPageLoading: isHostsPageLoading,
      isPageEmpty: !!(
        hostsData &&
        !ansibleHosts.length &&
        !input.fullTextSearch &&
        input.predicates.length === 0
      ),
      hasNoFilteringResults:
        !!hostsData &&
        !ansibleHosts.length &&
        (!!input.fullTextSearch || input.predicates.length > 0),
    };
  }, [
    ansibleHosts.length,
    hostsData,
    hostsError,
    hostsLoading,
    input.fullTextSearch,
    input.predicates.length,
    isHostsPageLoading,
  ]);

  return {
    nodes,
    loadMoreItems,
    predicates: input.predicates,
    ...response,
  };
};

export default useSearchAnsibleHosts;
