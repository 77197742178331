import { Time } from "@internationalized/date";
import cx from "classnames";
import { useMemo, useRef, useState } from "react";
import {
  DateInput,
  DateSegment,
  Dialog,
  TimeField,
  TimeFieldProps,
  TimeValue,
} from "react-aria-components";

import { Clock } from "components/icons/generated";

import Box from "../Box";
import ButtonIcon from "../ButtonIcon";
import ListBox from "../ListBox";
import ListBoxItem from "../ListBox/Item";
import ListBoxPopover from "../ListBox/Popover";
import ListBoxScrollableWrapper from "../ListBox/ScrollableWrapper";
import Typography from "../Typography";
import styles from "./styles.module.css";
import { TimeOption } from "./types";
import { useTriggerWidth } from "./useTriggerWidth";

type TimePickerProps = {
  value?: Time;
  onChange?: (value: Time | null) => void;
  timeOptions?: TimeOption[];
  disabled?: boolean;
  hasError?: boolean;
  fullWidth?: boolean;
  labelId?: string;
  ariaLabel?: string;
} & Pick<TimeFieldProps<TimeValue>, "minValue" | "maxValue">;

const TimePicker = ({
  disabled,
  hasError,
  fullWidth,
  timeOptions,
  value,
  onChange,
  minValue,
  maxValue,
  ariaLabel = "Choose time",
}: TimePickerProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownTriggerRef = useRef(null);

  const triggerWidth = useTriggerWidth(dropdownTriggerRef);

  const listBoxStyle = useMemo(() => {
    return {
      width: triggerWidth,
    };
  }, [triggerWidth]);

  const handleTimeOptionClick = (value: Time) => {
    setDropdownOpen(false);
    onChange?.(value);
  };

  return (
    <TimeField
      value={value}
      onChange={onChange}
      isDisabled={disabled}
      isInvalid={hasError}
      className={cx({
        [styles.fullWidth]: fullWidth,
      })}
      minValue={minValue}
      maxValue={maxValue}
      hourCycle={24}
      shouldForceLeadingZeros
      hideTimeZone
      aria-label={ariaLabel}
    >
      <Box
        ref={dropdownTriggerRef}
        className={cx(styles.input, {
          [styles.disabled]: disabled,
          [styles.error]: hasError,
          [styles.dropdownOpen]: isDropdownOpen,
        })}
        align="center"
        gap="small"
        fullWidth={fullWidth}
      >
        <DateInput className={styles.inputInner}>
          {(segment) => <DateSegment segment={segment} />}
        </DateInput>

        {!!timeOptions?.length && (
          <ButtonIcon
            icon={Clock}
            variant="ghost"
            onPress={() => setDropdownOpen(true)}
            disabled={disabled}
          >
            Show options
          </ButtonIcon>
        )}
      </Box>

      {!!timeOptions?.length && (
        <ListBoxPopover
          triggerRef={dropdownTriggerRef}
          isOpen={isDropdownOpen}
          onOpenChange={setDropdownOpen}
        >
          <Dialog aria-label={ariaLabel}>
            <ListBoxScrollableWrapper style={listBoxStyle}>
              <ListBox<TimeOption> items={timeOptions} aria-label="Select time" autoFocus>
                {(item) => (
                  <ListBoxItem
                    id={item.label}
                    textValue={item.label}
                    onAction={() => handleTimeOptionClick(item.value)}
                    isSelected={value && item.value.compare(value) === 0}
                  >
                    <Typography tag="span" variant="p-body3" color="primary">
                      {item.label}
                    </Typography>
                  </ListBoxItem>
                )}
              </ListBox>
            </ListBoxScrollableWrapper>
          </Dialog>
        </ListBoxPopover>
      )}
    </TimeField>
  );
};

TimePicker.displayName = "DS.TimePicker";

export default TimePicker;
