import Timestamp from "components/Timestamp";
import Box from "ds/components/Box";

import { CellComponentProps } from "./types";

type StackListItemUpdatedAtCellProps = CellComponentProps;

const StackListItemUpdatedAtCell = ({ stack }: StackListItemUpdatedAtCellProps) => {
  if (!stack.stateSetAt) {
    return null;
  }

  return (
    <Box>
      <Timestamp timestamp={stack.stateSetAt!} />
    </Box>
  );
};

export default StackListItemUpdatedAtCell;
