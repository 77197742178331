import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

const useShare = (blueprintId: string) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  return {
    onShare: async () => {
      try {
        await navigator.clipboard.writeText(
          `${window.location.origin}/blueprint/${blueprintId}/create`
        );
        reportSuccess({ message: "Stack creation view link copied to clipboard" });
      } catch {
        onError({ message: "Could not copy the stack creation view link" });
      }
    },
  };
};

export default useShare;
