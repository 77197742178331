import { gql, TypedDocumentNode } from "@apollo/client";

type ToggleAllowNonRootAdminSpaceCreationData = {
  accountToggleAllowNonRootAdminSpaceCreation: boolean;
};

export const TOGGLE_ALLOW_NON_ROOT_ADMIN_SPACE_CREATION: TypedDocumentNode<ToggleAllowNonRootAdminSpaceCreationData> = gql`
  mutation ToggleAllowNonRootAdminSpaceCreation {
    accountToggleAllowNonRootAdminSpaceCreation
  }
`;
