import { CSSProperties, useRef } from "react";
import { Cell, Row } from "react-aria-components";
import { useSearchParams } from "react-router-dom";

import EmptyState from "ds/components/EmptyState";
import Table from "components/Table";
import TableRow from "components/Table/Row";
import TableContextProvider from "components/Table/Context";
import useTableSort from "components/Table/useTableSort";
import { SearchQueryOrderDirection } from "types/generated";
import { getSearchQuery } from "components/SearchInput/helpers";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import useErrorHandle from "hooks/useErrorHandle";

import CloudIntegrationsPageLayout from "./PageLayout";
import {
  INITIAL_COLUMNS_CONFIG,
  CLOUD_INTEGRATIONS_END_COLUMN_CONFIG,
  SORT_CONFIG,
  FILTERS_ORDER_SETTINGS_KEY_CLOUD_INTEGRATIONS,
  CLOUD_INTEGRATIONS_COLUMN_ID,
} from "./constants";
import { CLOUD_INTEGRATIONS_COLUMN_CONFIG } from "./constants";
import CloudIntegrationsListTableRow from "./TableRow";
import { ROW_HEADER_ID, TABLE_COLUMNS_STORAGE_KEY } from "./constants";
import CloudIntegrationsEmpty from "./Empty";
import { useFilteredIntegrations } from "./useFilteredEntities";
import { CloudIntegration } from "../types";
import useSearchCloudIntegrations from "./useSearchCloudIntegrations";

const renderRow = (
  item: Partial<CloudIntegration> & {
    virtIndex?: number;
    style?: CSSProperties;
    virtKey?: string;
    id: string;
    height?: number;
    ref?: (node: Element | null | undefined) => void;
  }
) => {
  if ("height" in item && (item.id === "after" || item.id === "before")) {
    return (
      <Row>
        <Cell style={{ height: item.height }} />
      </Row>
    );
  }

  return (
    <TableRow
      index={item.virtIndex}
      ref={item.ref}
      style={item.style}
      key={item.virtKey}
      id={item.id}
      name={item.name}
    >
      <CloudIntegrationsListTableRow integration={item as CloudIntegration} />
    </TableRow>
  );
};

const CloudIntegrationsList = () => {
  const { isPageLoading, error, integrations } = useSearchCloudIntegrations();

  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();

  const isSearchActive = !!getSearchQuery(searchParams);

  const { handleSortOptionChange, sortDescriptor } = useTableSort({
    filtersOrderSettingsKey: FILTERS_ORDER_SETTINGS_KEY_CLOUD_INTEGRATIONS,
    sortConfig: SORT_CONFIG,
    initialSortOption: SORT_CONFIG[CLOUD_INTEGRATIONS_COLUMN_ID.NAME],
    initialSortDirection: SearchQueryOrderDirection.Asc,
  });

  const filteredIntegrations = useFilteredIntegrations(integrations, sortDescriptor);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return (
      <CloudIntegrationsPageLayout>
        <PageLayoutSkeleton />
      </CloudIntegrationsPageLayout>
    );
  }

  const isPageEmpty = filteredIntegrations.length === 0;

  return (
    <CloudIntegrationsPageLayout>
      <TableContextProvider
        rowHeaderId={ROW_HEADER_ID}
        localStorageId={TABLE_COLUMNS_STORAGE_KEY}
        columnsConfig={CLOUD_INTEGRATIONS_COLUMN_CONFIG}
        endCustomColumns={CLOUD_INTEGRATIONS_END_COLUMN_CONFIG}
        initialColumnViewConfig={INITIAL_COLUMNS_CONFIG}
        sortDescriptor={sortDescriptor}
        setSortDescriptor={handleSortOptionChange}
      >
        {filteredIntegrations.length > 0 && (
          <Table
            ref={virtualizedListContainerRef}
            ariaLabel="Cloud integrations list"
            items={filteredIntegrations}
          >
            {renderRow}
          </Table>
        )}
      </TableContextProvider>

      {isPageEmpty && !isSearchActive && <CloudIntegrationsEmpty />}
      {isPageEmpty && isSearchActive && (
        <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
      )}
    </CloudIntegrationsPageLayout>
  );
};

export default CloudIntegrationsList;
