import CloudIntegrationsActionsDropdown from "../../components/ActionsDropdown";
import { useCloudIntegrationsListAnalytics } from "../useCloudIntegrationsListAnalytics";
import { CellComponentProps } from "./types";

type CloudIntegrationsListDropdownActionsCellProps = CellComponentProps;

const REFETCH_QUERIES = ["SearchCloudIntegrations"];

const CloudIntegrationsListDropdownActionsCell = ({
  item,
}: CloudIntegrationsListDropdownActionsCellProps) => {
  const trackAnalytics = useCloudIntegrationsListAnalytics();

  return (
    <CloudIntegrationsActionsDropdown
      integration={item}
      trackAnalytics={trackAnalytics}
      refetchQueriesOnEdit={REFETCH_QUERIES}
      refetchQueriesOnDelete={REFETCH_QUERIES}
    />
  );
};

export default CloudIntegrationsListDropdownActionsCell;
