import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";
import { ManagedUser, ManagedUserGroup } from "types/generated";

import { USERS_COLUMN_ID } from "../constants";
import UsersListItemActionsDropdownCell from "./DropdownActionsCell";
import UsersListItemNameCell from "./NameCell";
import UsersListItemRoleCell from "./RoleCell";
import UsersListItemGroupsCell from "./GroupsCell";
import UsersListItemMethodCell from "./MethodCell";
import UsersListItemStatusCell from "./StatusCell";
import UsersListItemLastLoginCell from "./LastLoginCell";

const CELLS = {
  [USERS_COLUMN_ID.NAME]: UsersListItemNameCell,
  [USERS_COLUMN_ID.DROPDOWN]: UsersListItemActionsDropdownCell,
  [USERS_COLUMN_ID.ROLE]: UsersListItemRoleCell,
  [USERS_COLUMN_ID.GROUPS]: UsersListItemGroupsCell,
  [USERS_COLUMN_ID.METHOD]: UsersListItemMethodCell,
  [USERS_COLUMN_ID.STATUS]: UsersListItemStatusCell,
  [USERS_COLUMN_ID.LAST_LOGIN]: UsersListItemLastLoginCell,
};

type UsersListItemProps = {
  user: ManagedUser;
  managedUserGroups: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
};

const UsersListItem = ({ user, managedUserGroups }: UsersListItemProps) => {
  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as USERS_COLUMN_ID];
        const uniqueKey = `${id}-${user.id}`;

        const childrenComponent = <Component user={user} managedUserGroups={managedUserGroups} />;

        return (
          <TableCell key={uniqueKey} id={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(UsersListItem);
