import { ReactNode } from "react";

import { Dots } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";
import DropdownMenu from "ds/components/DropdownMenu";

import styles from "../styles.module.css";

type TableHeadColumnOptionsDropdownProps = {
  children: ReactNode;
};

const TableHeadColumnOptionsDropdown = ({ children }: TableHeadColumnOptionsDropdownProps) => {
  return (
    <DropdownMenu
      triggerComponent={
        <ButtonIcon className={styles.columnDropdownEllipsis} icon={Dots} variant="ghost">
          Column options
        </ButtonIcon>
      }
    >
      {children}
    </DropdownMenu>
  );
};

TableHeadColumnOptionsDropdown.displayName = "DS.Table.Head.Column.Options.Dropdown";

export default TableHeadColumnOptionsDropdown;
