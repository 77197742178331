import cx from "classnames";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";

import Box from "ds/components/Box";

import styles from "./styles.module.css";
import { ColumnMode, DragDropListProps } from "./types";
import DragDropListDroppableContainer from "./DroppableContainer";
import DragDropListOverlay from "./Overlay";
import DragDropListDraggableItem from "./DraggableItem";
import useTwoColumnDND from "./useTwoColumnDND";
import { coordinateGetter } from "./utils";

const DragDropList = <T extends string>({
  columnMode,
  onChange,
  leftColumn,
  rightColumn,
  options,
  itemClassName,
  emptyState,
}: DragDropListProps<T>) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const {
    hideItem,
    isEmpty,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    handleDragOver,
    collisionDetectionStrategy,
    containersState,
    activeId,
    isSortingContainer,
  } = useTwoColumnDND({
    onChange,
    leftColumn,
    rightColumn,
    options,
  });

  if (isEmpty && emptyState) {
    return emptyState;
  }

  return (
    // Key allows us to recalculate size of items (mostly applicable to charts)
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragAbort={handleDragCancel}
      onDragCancel={handleDragCancel}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      collisionDetection={collisionDetectionStrategy}
      key={columnMode}
    >
      <Box className={cx(styles.container, columnMode == ColumnMode.Single && styles.singleColumn)}>
        <SortableContext items={["left", "right"]} strategy={verticalListSortingStrategy}>
          <DragDropListDroppableContainer key="left" id="left" items={containersState["left"]}>
            <SortableContext strategy={verticalListSortingStrategy} items={containersState["left"]}>
              <Box
                className={styles.column}
                direction="column"
                margin={
                  columnMode === ColumnMode.Double ? "x-large medium x-large x-large" : "x-large"
                }
              >
                {containersState["left"].map((id) => (
                  <DragDropListDraggableItem
                    key={id}
                    side="left"
                    disabled={isSortingContainer}
                    className={itemClassName}
                    id={id}
                    hideItem={hideItem}
                    option={options[id]}
                  />
                ))}
              </Box>
            </SortableContext>
          </DragDropListDroppableContainer>

          {columnMode === ColumnMode.Double && (
            <DragDropListDroppableContainer key="right" id="right" items={containersState["right"]}>
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={containersState["right"]}
              >
                <Box direction="column" margin="x-large x-large x-large medium">
                  {containersState["right"].map((id) => (
                    <DragDropListDraggableItem
                      key={id}
                      side="right"
                      disabled={isSortingContainer}
                      className={itemClassName}
                      id={id}
                      hideItem={hideItem}
                      option={options[id]}
                    />
                  ))}
                </Box>
              </SortableContext>
            </DragDropListDroppableContainer>
          )}
        </SortableContext>
      </Box>
      {createPortal(
        <DragOverlay>
          {activeId && <DragDropListOverlay option={options[activeId]} className={itemClassName} />}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

export default DragDropList;
