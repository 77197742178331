import cx from "classnames";
import useLocalStorage from "@rehooks/local-storage";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import FormToggleField from "ds/components/Form/ToggleField";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { REDUCED_MOTION_LOCAL_STORAGE_KEY } from "views/Theme/constants";
import { ReducedMotionStorage } from "views/Theme/types";
import { ThemeContext } from "views/Theme";

import styles from "./styles.module.css";

const AccessibilitySettings = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { reducedMotion, reducedMotionConfigValue, reducedMotionUseSystemValue } =
    useTypedContext(ThemeContext);

  const [storage, setStorage] = useLocalStorage<ReducedMotionStorage>(
    REDUCED_MOTION_LOCAL_STORAGE_KEY
  );

  const setReducedMotion = (value: boolean | "system") => {
    if (value === "system") {
      setStorage({ configValue: storage?.configValue ?? false, useSystemValue: true });
    } else {
      setStorage({ configValue: value, useSystemValue: false });
    }
  };

  useTitle(`Personal Settings · Accessibility · ${accountName}`);

  const toggleSystemMode = (isSelected: boolean) => {
    if (isSelected) {
      setReducedMotion("system");
    } else {
      setReducedMotion(reducedMotionConfigValue);
    }
  };

  const toggleConfigSetting = (isSelected: boolean) => {
    setReducedMotion(isSelected);
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Accessibility</ViewHeaderTitle>
      </ViewHeader>

      <Box
        direction="column"
        grow="1"
        className={styles.wrapper}
        padding="x-large"
        limitWidth="medium"
      >
        <Box direction="column" gap="x-large" fullWidth>
          <Typography variant="p-t5" tag="h3" margin="0 0 small 0">
            Prefer reduced motion
          </Typography>

          <Typography variant="p-body2" tag="p" color="secondary">
            When enabled, the animations will minimize the amount of non-essential motion.
          </Typography>

          <FormToggleField
            variant="switch"
            onChange={toggleSystemMode}
            checked={reducedMotionUseSystemValue}
            title="Sync with system"
            description="Follow system preferences for reduced motion."
          />
          {!reducedMotionUseSystemValue && (
            <FormToggleField
              variant="switch"
              onChange={toggleConfigSetting}
              checked={reducedMotionConfigValue}
              title="Enable reduced motion"
              description="If checked, the user interface animations (such as popup transitions) will be disabled."
            />
          )}
          <div className={styles.reducedMotionExample}>
            <div className={cx(styles.reducedMotionObject, !reducedMotion && styles.animate)} />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default AccessibilitySettings;
