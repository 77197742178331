import { useMemo } from "react";

import { BaseCollection, SectionBaseCollection } from "./types";

const useSkipDuplicates = <
  Collection extends BaseCollection | SectionBaseCollection<BaseCollection, SectionProps>,
  SectionProps extends object = object,
>(
  items: Collection[]
) => {
  return useMemo(() => {
    const seen = new Set();
    return items.filter((item) => {
      if (!("value" in item)) {
        // ignore section combo boxes for now
        return true;
      }
      const wasSeen = seen.has(item.value);
      if (wasSeen) {
        return false;
      } else {
        seen.add(item.value);
        return true;
      }
    });
  }, [items]);
};

export default useSkipDuplicates;
