import Skeleton from "react-loading-skeleton";

import Box from "ds/components/Box";

const SkeletonItem = () => (
  <Box align="center" justify="between" grow="1" zeroMinWidth fullWidth>
    <Box direction="column" fullWidth>
      <Skeleton width={`${Math.floor(Math.random() * 21) + 70}%`} height={40} borderRadius={8} />
    </Box>
    <Box direction="column">
      <Skeleton width={30} height={30} borderRadius={8} />
    </Box>
  </Box>
);

const IacManagementResourceDetailsDrawerSkeleton = () => {
  return (
    <>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </>
  );
};

export default IacManagementResourceDetailsDrawerSkeleton;
