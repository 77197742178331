import useAnalytics from "hooks/useAnalytics";

import { ANALYTICS } from "./constants";

const useRolesListAnalytics = () => {
  return useAnalytics({
    page: ANALYTICS.analyticsPage,
  });
};

export default useRolesListAnalytics;
