import { ReactNode } from "react";

import { Blueprint } from "types/generated";
import TableDropdownCell from "ds/components/Table/components/DropdownCell";

import useCellActionsConfig, { BlueprintColumnWithActions } from "./useCellActionsConfig";

type BlueprintListTableDropdownCellProps = {
  id: BlueprintColumnWithActions;
  className?: string;
  blueprint: Blueprint;
  children: ReactNode;
};

const BlueprintsListTableDropdownCell = ({
  id,
  className,
  blueprint,
  children,
}: BlueprintListTableDropdownCellProps) => {
  const cellActionsConfig = useCellActionsConfig(blueprint);
  const dropdownActions = cellActionsConfig[id];

  return (
    <TableDropdownCell id={id} className={className} dropdownActions={dropdownActions}>
      {children}
    </TableDropdownCell>
  );
};

export default BlueprintsListTableDropdownCell;
