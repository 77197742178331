import { Lock } from "components/icons/generated";
import Button from "ds/components/Button";
import { AnalyticsPage } from "hooks/useAnalytics";
import { Stack } from "types/generated";

import { showStackLockNoteModal } from "../LockNoteModal";

type LockButtonProps = {
  stackId: Stack["id"];
  analyticsPage?: AnalyticsPage;
};

const LockButton = ({ stackId, analyticsPage }: LockButtonProps) => {
  const onClick = () => {
    showStackLockNoteModal({
      stackId,
      analyticsPage,
    });
  };

  return (
    <Button onPress={onClick} startIcon={Lock} variant="secondary">
      Lock
    </Button>
  );
};

export default LockButton;
