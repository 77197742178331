import { useEffect, useRef } from "react";
import throttle from "lodash-es/throttle";

import useTypedFlags from "hooks/useTypedFlags";
import { ThemeContext } from "views/Theme";
import useTypedContext from "hooks/useTypedContext";

import { useHideOnScrollContext } from "./HideOnScrollContext";
import css from "./styles.module.css";

const disableOverflow = (selector: string): (() => void) => {
  // disable overflow to prevent scrollbars from appearing
  document
    .querySelector<HTMLDivElement>(selector)
    ?.style.setProperty("overflow", "clip", "important");

  const enableOverflow = () => {
    // fallback to the initial value set by the CSS
    document.querySelector<HTMLDivElement>(selector)?.style.removeProperty("overflow");
  };

  return enableOverflow;
};

type HideOnScrollProps = {
  children: React.ReactNode;
  containerRef?: React.RefObject<HTMLElement>;
  disabled?: boolean;
  transitionTime?: number;
};

/**
 * HideOnScroll component:
 * Hides the children when the user scrolls down and shows them when the user scrolls up.
 * Check out storybook for more examples.
 *
 * @param props.children - The children to be hidden or shown.
 * @param props.containerRef - The ref of the container that will be scrolled. Default is window. If a context is provided, `containerRef` takes priority as the parent element.
 * @param props.disabled - If true, the children will always be shown. Default is false.
 * @param props.transitionTime - The time it takes for the children to hide or show in milliseconds. Default is 300ms.
 */
const HideOnScroll = ({
  children,
  containerRef,
  disabled = false,
  transitionTime = 300,
}: HideOnScrollProps) => {
  const { stickyViewHeader } = useTypedFlags();

  const hidingEl = useRef<HTMLDivElement>(null);
  const prevScrollPos = useRef(0);

  const { reducedMotion } = useTypedContext(ThemeContext);

  const { scrollableElement, isHideOnScrollContext } = useHideOnScrollContext();

  const disableAnimations = stickyViewHeader === false || reducedMotion || disabled;

  useEffect(() => {
    let parent: HTMLElement | Window = window;
    let enableOverflow: () => void | undefined;

    if (containerRef?.current) {
      parent = containerRef.current;
    } else if (scrollableElement) {
      parent = scrollableElement;

      enableOverflow = disableOverflow("#layoutContent");
    }

    const hideElement = throttle((el: HTMLDivElement, shouldHide: boolean) => {
      el.setAttribute("aria-hidden", `${shouldHide}`);

      if (shouldHide) el.setAttribute("inert", "true");
      else el.removeAttribute("inert");
    }, transitionTime);

    const handleScroll = (e: Event) => {
      let elPosition: number | undefined = 0;

      if (isHideOnScrollContext || parent instanceof HTMLElement) {
        elPosition = (e.currentTarget as HTMLElement)?.scrollTop;
      } else {
        elPosition = window.scrollY;
      }

      const shouldHide = elPosition > prevScrollPos.current;
      const isAboveThreshold = Math.abs(elPosition - prevScrollPos.current) > 100;
      const hasNotMoved = elPosition === prevScrollPos.current;

      // prevents funky scroll when the user scrolls to the last element
      if (!isAboveThreshold || hasNotMoved) return;

      if (hidingEl.current) hideElement(hidingEl.current, shouldHide);

      prevScrollPos.current = elPosition;
    };

    // throttle prevent flakiness on fast scroll events. Set to 110% of transition time to ensure animations have finished.
    if (!disableAnimations) parent.addEventListener("scroll", handleScroll);

    return () => {
      parent.removeEventListener("scroll", handleScroll);
      enableOverflow?.();
      hideElement.cancel();
    };
  }, [containerRef, disableAnimations, isHideOnScrollContext, scrollableElement, transitionTime]);

  return (
    <div
      ref={hidingEl}
      className={css.hidingElement}
      style={
        !disableAnimations ? { transition: `grid-template-rows ${transitionTime}ms` } : undefined
      }
    >
      <div className={css.content}>{children}</div>
    </div>
  );
};

export default HideOnScroll;
