import { useMemo } from "react";

import CardWrapper from "components/CardWrapper";
import DocumentationButton from "components/DocumentationButton";
import { EmptystateCloudColored } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import Feedback from "ds/components/Feedback";
import useTypedContext from "hooks/useTypedContext";
import { CLOUD_INTEGRATIONS, CloudIntegrationAttachment } from "shared/CloudIntegration/types";
import useAttachableCloudIntegrations from "shared/CloudIntegration/useAttachableCloudIntegrations";
import { isSelfHostedDistribution } from "utils/distribution";
import { getDocsUrl } from "utils/getDocsUrl";
import AttachCloudDetails from "components/AttachCloud/Details";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import StackSettingsFormWrapper from "../../components/FormWrapper";
import { REFETCH_STACK_SETTINGS_QUERIES } from "../../constants";
import { StackSettingsContextData } from "../../Context";
import StackSettingsIntegrationsWrapper from "../components/Wrapper";
import { showStackSettingsIntegrationsCloudDrawer } from "./Drawer";

const isSelfHosted = isSelfHostedDistribution();

const StackSettingsIntegrationsCloud = () => {
  const { stackSettings, canManageStack } = useTypedContext(StackSettingsContextData);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackSettingsIntegrations,
  });

  const {
    cloudConfig,
    attachableAzureIntegrations,
    attachableAwsIntegrations,
    hasData,
    refetch,
    refetching,
  } = useAttachableCloudIntegrations(stackSettings.spaceDetails.id);

  const onSuccessDetach = (type: CLOUD_INTEGRATIONS) => trackSegmentEvent("Detached", { type });

  const integrations = useMemo(() => {
    const awsIntegrations = stackSettings?.integrations?.awsV2 || [];
    const azureIntegrations = stackSettings?.integrations?.azure || [];

    return ([] as Array<CloudIntegrationAttachment>)
      .concat(awsIntegrations)
      .concat(azureIntegrations);
  }, [stackSettings?.integrations]);

  const gcpIntegration = stackSettings?.integrations?.gcp;

  const attachedIds = useMemo(
    () => (integrations || []).map((integration) => integration.integrationId),
    [integrations]
  );

  const filteredAwsIntegrations = useMemo(
    () => attachableAwsIntegrations.filter((integration) => !attachedIds.includes(integration.id)),
    [attachableAwsIntegrations, attachedIds]
  );

  const filteredAzureIntegrations = useMemo(
    () =>
      attachableAzureIntegrations.filter((integration) => !attachedIds.includes(integration.id)),
    [attachableAzureIntegrations, attachedIds]
  );

  const hasSomeIntegrationsAttached = integrations.length > 0 || gcpIntegration?.activated;
  const hasAttachableCloudIntegrations = hasData && cloudConfig.length > 0;

  const canAttachGcpIntegration = !gcpIntegration?.activated && !isSelfHosted;

  const canAttach =
    (filteredAwsIntegrations.length > 0 ||
      filteredAzureIntegrations.length > 0 ||
      canAttachGcpIntegration) &&
    canManageStack;

  const handleAttachIntegrationDrawer = () => {
    showStackSettingsIntegrationsCloudDrawer({
      stackId: stackSettings.id,
      hasData,
      refetch,
      refetching,
      cloudConfig,
      filteredAzureIntegrations,
      filteredAwsIntegrations,
      canAttachGcpIntegration,
    });
  };

  return (
    <StackSettingsIntegrationsWrapper
      actions={
        <Button variant="primary" disabled={!canAttach} onPress={handleAttachIntegrationDrawer}>
          Attach cloud integration
        </Button>
      }
    >
      <Feedback type="callout" variant="info">
        You can only attach integrations from the current space and parent spaces that you inherit
        from.
      </Feedback>
      <StackSettingsFormWrapper withLimitWidth={false} padding="0">
        {hasAttachableCloudIntegrations && !hasSomeIntegrationsAttached && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateCloudColored}
                title="You do not have any attached cloud integrations yet"
                caption="You can attach one of the existing ones, or create a new one."
              >
                <Box gap="medium">
                  {canAttach && (
                    <Button variant="primary" onPress={handleAttachIntegrationDrawer}>
                      Attach cloud integration
                    </Button>
                  )}
                  <DocumentationButton
                    to={getDocsUrl("/integrations/cloud-providers")}
                    label="Documentation"
                  />
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}

        {!hasAttachableCloudIntegrations && !hasSomeIntegrationsAttached && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateCloudColored}
                title="You do not have any cloud integrations yet"
                caption={
                  <>
                    Cloud Integrations are used to dynamically generate short-lived credentials to
                    authenticate to cloud providers (AWS/Azure/GCP currently supported). In this way
                    you avoid using static credentials and by doing so, you reduce breaches.
                  </>
                }
              >
                <DocumentationButton
                  to={getDocsUrl("/integrations/cloud-providers")}
                  label="Documentation"
                />
              </EmptyState>
            </CardWrapper>
          </Box>
        )}

        {hasSomeIntegrationsAttached && (
          <Box direction="column" gap="x-large" padding="x-large" limitWidth="medium">
            {integrations.map((integration) => (
              <AttachCloudDetails
                key={integration.id}
                cloudConfig={cloudConfig}
                attachedIntegrationType={
                  integration.__typename === "StackAzureIntegrationAttachment"
                    ? CLOUD_INTEGRATIONS.Azure
                    : CLOUD_INTEGRATIONS.AWS
                }
                attachedIntegration={integration}
                entityId={stackSettings.id}
                refetchQueries={REFETCH_STACK_SETTINGS_QUERIES}
                readOnly={!canManageStack}
                onSuccessDetach={() =>
                  onSuccessDetach(
                    integration.__typename === "StackAzureIntegrationAttachment"
                      ? CLOUD_INTEGRATIONS.Azure
                      : CLOUD_INTEGRATIONS.AWS
                  )
                }
              />
            ))}
            {gcpIntegration?.activated && (
              <AttachCloudDetails
                cloudConfig={cloudConfig}
                attachedIntegrationType={CLOUD_INTEGRATIONS.GCP}
                attachedIntegration={gcpIntegration}
                entityId={stackSettings.id}
                refetchQueries={REFETCH_STACK_SETTINGS_QUERIES}
                readOnly={!canManageStack}
                onSuccessDetach={() => onSuccessDetach(CLOUD_INTEGRATIONS.GCP)}
              />
            )}
          </Box>
        )}
      </StackSettingsFormWrapper>
    </StackSettingsIntegrationsWrapper>
  );
};

export default StackSettingsIntegrationsCloud;
