import { useQuery } from "@apollo/client";
import { CSSProperties, useMemo, useRef, useCallback } from "react";
import { Cell, Row, SortDirection } from "react-aria-components";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import EmptyState from "ds/components/EmptyState";
import { EmptystateMagnetColored } from "components/icons/generated";
import { Space } from "types/generated";
import PageLoading from "components/loading/PageLoading";
import TableContextProvider from "components/Table/Context";
import Table from "components/Table";
import TableRow from "components/Table/Row";
import TableWrapper from "components/Table/Wrapper";

import { GET_BLUEPRINT_INTEGRATIONS } from "./gql";
import IntegrationsPageLayout from "./components/PageLayout";
import { GetBlueprintIntegrationsGql, BlueprintIntegrationData } from "./types";
import { showIntegrationDrawerForm } from "./components/AttachDrawer";
import {
  INTEGRATIONS_COLUMN_CONFIG,
  INTEGRATIONS_CUSTOM_END_COLUMN_CONFIG,
  ROW_HEADER_ID,
  INTEGRATIONS_COLUMNS_STORAGE_KEY,
  ANALYTICS,
  INITIAL_COLUMNS_VIEW_CONFIG,
} from "./constants";
import IntegrationListItem from "./components/ListItem";

type BlueprintIntegrationsProps = {
  blueprintId: string;
  blueprintName: string;
  space: Space;
  isDraft: boolean;
};

const BlueprintIntegrations = ({
  blueprintId,
  blueprintName,
  space,
  isDraft,
}: BlueprintIntegrationsProps) => {
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const { onError } = useTypedContext(FlashContext);

  const { loading, data } = useQuery<GetBlueprintIntegrationsGql>(GET_BLUEPRINT_INTEGRATIONS, {
    onError,
    variables: {
      id: blueprintId,
    },
    skip: isDraft,
  });

  const integrations = useMemo(() => {
    const items = data?.blueprint?.externalIntegrations || [];

    const itemsWithSpace = items.map((item) => ({ ...item, space }));

    return itemsWithSpace;
  }, [data?.blueprint?.externalIntegrations, space]);

  const handleOnCreateClick = () => {
    showIntegrationDrawerForm({ blueprintId, spaceId: space.id });
  };

  const sortDescriptor = {
    column: "symbol",
    direction: "ascending" as SortDirection,
  };

  const renderRow = useCallback(
    (
      item: Partial<BlueprintIntegrationData> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.integrationID}
          >
            <IntegrationListItem integration={item as BlueprintIntegrationData} />
          </TableRow>
        );
      }
    },
    []
  );

  return (
    <TableContextProvider
      columnsConfig={INTEGRATIONS_COLUMN_CONFIG}
      endCustomColumns={INTEGRATIONS_CUSTOM_END_COLUMN_CONFIG}
      initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
      rowHeaderId={ROW_HEADER_ID}
      localStorageId={INTEGRATIONS_COLUMNS_STORAGE_KEY}
      setSortDescriptor={() => null}
      sortDescriptor={sortDescriptor}
      {...ANALYTICS}
    >
      <IntegrationsPageLayout
        onCreate={handleOnCreateClick}
        blueprintName={blueprintName}
        isDraft={isDraft}
      >
        {loading && !data && <PageLoading />}

        {!!integrations.length && (
          <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
            <Table
              ref={virtualizedListContainerRef}
              ariaLabel="Integrations list"
              items={integrations}
              loadingContent={loading}
            >
              {renderRow}
            </Table>
          </TableWrapper>
        )}

        {integrations.length === 0 && !loading && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateMagnetColored}
                title={
                  isDraft
                    ? "Please publish the blueprint before attaching any integrations."
                    : "No integrations attached yet."
                }
              />
            </CardWrapper>
          </Box>
        )}
      </IntegrationsPageLayout>
    </TableContextProvider>
  );
};

export default BlueprintIntegrations;
