import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  arrow,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from "@floating-ui/react";
import cx from "classnames";
import { useMemo, useRef, useState } from "react";

import { Cross } from "components/icons/generated";
import Box from "ds/components/Box";
import { ignoreOutsideClick } from "hooks/useOutsideClick";

import ButtonIcon from "../ButtonIcon";
import patchedAutoUpdate from "../Tooltip/patchedAutoUpdate";
import styles from "./styles.module.css";
import { TooltipModalProps, TooltipModalReferenceProps } from "./types";

const TooltipModal = ({
  on,
  children,
  placement = "top",
  active = true,
  disableClose,
  className,
  closeOnOutsideClick = true,
  tooltipMode = "portal",
  shouldOpenOnHover = false,
  hoverDelay = 300,
  forceOpen,
}: TooltipModalProps) => {
  const arrowRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  let open = false;

  if (forceOpen) {
    open = true;
  } else if (active) {
    open = isOpen;
  }

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open,
    onOpenChange: setIsOpen,
    middleware: [
      offset(8),
      shift({ padding: 8 }),
      flip(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: patchedAutoUpdate,
  });

  const closeHandler = () => {
    setIsOpen(false);
  };

  const hover = useHover(context, {
    enabled: shouldOpenOnHover,
    move: false,
    delay: {
      open: hoverDelay,
      close: 100,
    },
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, {
    enabled: closeOnOutsideClick === true,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss, hover]);

  const { isMounted, styles: tooltipStyles } = useTransitionStyles(context, {
    duration: 100,
  });

  const triggerProps = useMemo(() => {
    const props: TooltipModalReferenceProps = {
      ref: refs.setReference,
    };

    if (!isOpen) {
      // If the tooltip is closed, the target element does not have aria-controls attribute
      // which makes aria-expanded attribute invalid, we should remove it.
      props["aria-expanded"] = undefined;
    }

    return getReferenceProps(props);
  }, [getReferenceProps, refs.setReference, isOpen]);

  const trigger = on(getReferenceProps(triggerProps) as TooltipModalReferenceProps, isOpen);

  if (!isMounted) {
    return <>{trigger}</>;
  }

  const tooltip = (
    <FloatingFocusManager context={context} initialFocus={refs.floating}>
      <div
        className={cx(styles.floatingTooltip, className)}
        ref={refs.setFloating}
        style={{ ...floatingStyles, ...tooltipStyles }}
        aria-labelledby="tooltip-modal-title"
        aria-describedby="tooltip-modal-body"
        {...getFloatingProps()}
        aria-modal="true"
        {...ignoreOutsideClick}
      >
        <Box direction="column" className={styles.content}>
          {!disableClose && (
            <ButtonIcon
              icon={Cross}
              onPress={closeHandler}
              className={styles.closeAction}
              variant="ghost"
            >
              Close
            </ButtonIcon>
          )}
          {typeof children === "function" ? children({ closeTooltip: closeHandler }) : children}
        </Box>

        <FloatingArrow
          ref={arrowRef}
          context={context}
          className={styles.floatingArrow}
          strokeWidth={1}
        />
      </div>
    </FloatingFocusManager>
  );

  if (tooltipMode === "parent") {
    return (
      <>
        {trigger}
        {tooltip}
      </>
    );
  }

  return (
    <>
      {trigger}
      {<FloatingPortal id="portal-root">{tooltip}</FloatingPortal>}
    </>
  );
};

TooltipModal.displayName = "DS.TooltipModal";

export default TooltipModal;
