import cx from "classnames";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";
import { ANSIBLE_DICTIONARY } from "../constants";
import { STATUS_ORDER } from "../utils";

const ConfigManagementTreeGridLegend = () => {
  return (
    <Box padding="medium x-large" className={styles.legendScrollContainer}>
      <Box className={styles.legendContainer}>
        {STATUS_ORDER.map((value) => (
          <Box key={value} gap="medium" align="center">
            <div className={cx(styles.legendItemColor, styles[value.toLowerCase()])} />
            <Typography tag="span" variant="p-body3">
              {ANSIBLE_DICTIONARY[value]}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ConfigManagementTreeGridLegend;
