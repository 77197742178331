import cx from "classnames";

import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";

import styles from "./styles.module.css";
import { BreadcrumbsContextItem } from "./types";

type BreadcrumbsItemProps = {
  item: BreadcrumbsContextItem;
};

const BreadcrumbsItem = ({ item }: BreadcrumbsItemProps) => {
  return (
    <TextEllipsis tooltip={item.title} tooltipPlacement="bottom" tooltipWidthMode="maxWidthXl">
      {(tooltipRefProps) => (
        <li
          {...tooltipRefProps}
          className={cx(styles.breadcrumbItem, tooltipRefProps.className, {
            [styles.fullWidth]: item.title.length < 100,
          })}
        >
          {!!item.link && (
            <Link className={styles.link} href={item.link}>
              {item.title}
            </Link>
          )}
          {!item.link && item.title}
        </li>
      )}
    </TextEllipsis>
  );
};

export default BreadcrumbsItem;
