import cx from "classnames";
import capitalize from "lodash-es/capitalize";
import lowerCase from "lodash-es/lowerCase";
import { useEffect, useMemo, useRef } from "react";
import { FocusScope, usePreventScroll } from "react-aria";

import DocumentationIconButton from "components/DocumentationIconButton";
import { Cross, Expand } from "components/icons/generated";
import ThemedLogs from "components/ThemedLogs";
import ThemedLogsAnsiContent from "components/ThemedLogs/AnsiContent";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import useEscapeKeypress from "hooks/useEscapeKeyPress";
import { useToggle } from "hooks/useToggle";
import useTypedContext from "hooks/useTypedContext";
import { RunState } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";
import { RunContext } from "views/shared/run/Context";

import DownloadLogsButton from "../DownloadLogsButton";
import { processLogs } from "./helpers";
import styles from "./styles.module.css";
import { useAutoScroll } from "./useAutoScroll";
import { useGetRunLogs } from "./useGetRunLogs";

type RunLogsProps = {
  state: RunState;
  stateVersion?: number | null;
  until: number | null;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
};

const RunLogs = ({ state, stateVersion, until, runId, stackId, isModuleRun }: RunLogsProps) => {
  const [fullScreen, toggleFullScreen] = useToggle(false);
  const container = useRef<HTMLDivElement>(null);
  const trackSegmentAnalyticsEvent = useAnalytics();
  const {
    run: { state: runState },
  } = useTypedContext(RunContext);

  const { runLogs, error, loading } = useGetRunLogs({
    until,
    state,
    stateVersion,
    isModuleRun,
    runId,
    stackId,
  });

  useEffect(() => {
    const el = container.current;
    if (!el) return;

    const handleCopy = () => {
      if (!document.getSelection()?.toString()) {
        return;
      }

      trackSegmentAnalyticsEvent("Run Logs - copy logs", {
        isFullscreen: fullScreen,
        logsState: state,
        runState,
      });
    };

    el.addEventListener("copy", handleCopy);

    return () => {
      el.removeEventListener("copy", handleCopy);
    };
  }, [fullScreen, state, runState, trackSegmentAnalyticsEvent]);

  const toggle = () => {
    trackSegmentAnalyticsEvent("Run Logs - toggle fullscreen", {
      isFullscreen: !fullScreen, // state after toggling
      logsState: state,
      runState,
    });
    toggleFullScreen();
  };

  useEscapeKeypress(fullScreen, toggle);

  usePreventScroll({ isDisabled: !fullScreen });

  const { handleScroll, autoScrollToBottom } = useAutoScroll(container);

  const content = useMemo(
    () =>
      processLogs({
        logsMessages: runLogs?.messages,
        exists: !!runLogs?.exists,
        expired: !!runLogs?.expired,
        loading: loading && !runLogs,
        error: !!error,
      }),
    [runLogs, loading, error]
  );

  useEffect(() => {
    if (content) {
      autoScrollToBottom();
    }
  }, [content, autoScrollToBottom]);

  const expired = runLogs?.expired !== false;

  return (
    <FocusScope contain={fullScreen}>
      {fullScreen && (
        <Box justify="between" align="center" className={styles.header}>
          <Typography variant="p-t3" tag="h3" color="primary">
            {capitalize(lowerCase(state))}
          </Typography>

          <Box gap="medium">
            <ButtonIcon icon={Cross} onPress={toggle} variant="ghost">
              Close
            </ButtonIcon>
          </Box>
        </Box>
      )}
      <ThemedLogs
        ref={container}
        onScroll={handleScroll}
        className={cx(styles.logs, {
          [styles.fullScreen]: fullScreen,
        })}
      >
        {!fullScreen && (
          <Box gap="medium" className={styles.actions}>
            <ButtonIcon
              disabled={expired}
              variant="secondary"
              icon={Expand}
              onPress={toggle}
              size="small"
              aria-expanded={fullScreen}
            >
              Full screen mode
            </ButtonIcon>
            <DownloadLogsButton
              expired={expired}
              state={state}
              stateVersion={stateVersion}
              runId={runId}
              stackId={stackId}
              isModuleRun={isModuleRun}
            />
          </Box>
        )}
        <ThemedLogsAnsiContent value={content} />
      </ThemedLogs>

      {fullScreen && (
        <FullScreenModalFooter justify="between" className={styles.footer}>
          <DocumentationIconButton
            href={getDocsUrl("/concepts/policy#policy-workbench")}
            tooltipText="Go to policies workbench documentation"
          />
          <DownloadLogsButton
            expired={expired}
            state={state}
            stateVersion={stateVersion}
            runId={runId}
            stackId={stackId}
            isModuleRun={isModuleRun}
          />
        </FullScreenModalFooter>
      )}
    </FocusScope>
  );
};

export default RunLogs;
