import Box from "ds/components/Box";
import FormField, { FormFieldProps } from "ds/components/Form/Field";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import { StackVendor } from "types/generated";

import { getIacManagementStackVendorMeta } from "../helpers";

type FormFieldViewResourceVendorProps = {
  vendor: StackVendor;
} & Pick<FormFieldProps, "label" | "tooltipInfo" | "tooltipInfoVariant" | "noMargin">;

const FormFieldViewResourceVendor = ({
  vendor,
  label,
  tooltipInfo,
  tooltipInfoVariant,
  noMargin,
}: FormFieldViewResourceVendorProps) => {
  const vendorMeta = getIacManagementStackVendorMeta(vendor);

  return (
    <FormField
      label={label}
      tooltipInfo={tooltipInfo}
      tooltipInfoVariant={tooltipInfoVariant}
      noMargin={noMargin}
    >
      <Box gap="small">
        {!vendorMeta && (
          <Typography variant="p-body2" tag="span">
            -
          </Typography>
        )}
        {vendorMeta && (
          <>
            <Icon src={vendorMeta.icon} noShrink />
            <Typography variant="p-body2" tag="span">
              {vendorMeta.text}
            </Typography>
          </>
        )}
      </Box>
    </FormField>
  );
};

export default FormFieldViewResourceVendor;
