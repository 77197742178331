import { gql } from "@apollo/client";

export const GET_STACKS_BY_STATE = gql`
  query GetStacksByState($states: [StackState!]!) {
    metrics {
      recentStacks(states: $states) {
        name
        slug
        spaceTile {
          name
          slug
        }
      }
      hasStacks
    }
  }
`;
