import { useCallback } from "react";

import Button from "ds/components/Button";

type DeleteButtonProps = {
  active: boolean;
  setIsDeletingMode: (value: boolean) => unknown;
  handleDelete: () => unknown;
  disabledDelete?: boolean;
  disabledMutation?: boolean;
  idDeleting?: boolean;
};

const DeleteButton = (props: DeleteButtonProps) => {
  const { active, disabledDelete, disabledMutation, setIsDeletingMode, handleDelete, idDeleting } =
    props;

  const handleIsDeletingMode = useCallback(
    (value: boolean) => () => setIsDeletingMode(value),
    [setIsDeletingMode]
  );

  return active ? (
    <>
      <Button
        onPress={handleDelete}
        loading={idDeleting}
        disabled={disabledMutation}
        variant="secondary"
      >
        Please Delete
      </Button>
      <Button onPress={handleIsDeletingMode(false)} variant="secondary">
        Cancel
      </Button>
    </>
  ) : (
    <Button disabled={disabledDelete} onPress={handleIsDeletingMode(true)} variant="secondary">
      Delete
    </Button>
  );
};

export default DeleteButton;
