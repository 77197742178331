import { useMutation } from "@apollo/client";
import cx from "classnames";

import CodeEditor from "components/CodeEditor";
import FlashContext from "components/FlashMessages/FlashContext";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import PageInfoHeader from "ds/components/PageInfoHeader";
import useTypedContext from "hooks/useTypedContext";
import { trackSegmentEvent } from "shared/Analytics";
import { Policy } from "types/generated";

import { UPDATE_POLICY } from "../gql";
import styles from "./styles.module.css";

type PolicyDetailsBodyContentProps = {
  policy: Policy;
  body: string;
  handleEditorChange: (value?: string) => void;
  isBodyReadyToUpdate: boolean;
  isSimulationVisible: boolean;
  canManagePolicies: boolean;
};

const PolicyDetailsBodyContent = ({
  body,
  handleEditorChange,
  isBodyReadyToUpdate,
  isSimulationVisible,
  policy,
  canManagePolicies,
}: PolicyDetailsBodyContentProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [updatePolicy] = useMutation<{
    policyCreate: { name: string };
  }>(UPDATE_POLICY, {
    variables: {
      id: policy.id,
      name: policy.name,
      space: policy.spaceDetails.id,
      type: policy.type,
      labels: policy.labels,
      description: policy.description,
    },
    onError,
    // APOLLO CLIENT UPDATE
    onCompleted: (data) => {
      if (data) {
        trackSegmentEvent("Policy Body Updated");
        reportSuccess({ message: "Policy successfully updated" });
      }
    },
    refetchQueries: ["GetPolicy"],
  });

  const handleSaveChanges = () => {
    updatePolicy({ variables: { body } }).catch(onError);
  };

  return (
    <Box
      direction="column"
      className={cx(
        styles.bodyWrapper,
        !canManagePolicies && styles.withoutSimulationPanel,
        isSimulationVisible && styles.narrowed
      )}
    >
      <PageInfoHeader title="Policy body">
        {canManagePolicies && (
          <Button variant="primary" disabled={!isBodyReadyToUpdate} onPress={handleSaveChanges}>
            Save changes
          </Button>
        )}
      </PageInfoHeader>
      <CodeEditor
        readOnly={!canManagePolicies}
        body={body}
        onChange={handleEditorChange}
        language="rego"
      />
    </Box>
  );
};

export default PolicyDetailsBodyContent;
