import Link from "ds/components/Link";
import { SpacesContext } from "views/Account/SpacesProvider";
import useTypedContext from "hooks/useTypedContext";

import { CellComponentProps } from "../types";
import useUserRolesListAnalytics from "../useUserRolesListAnalytics";

type UserRolesListItemGrantedAtCellProps = CellComponentProps;

const UserRolesListItemGrantedAtCell = ({ roleBinding }: UserRolesListItemGrantedAtCellProps) => {
  const trackAnalytics = useUserRolesListAnalytics();
  const { spaces } = useTypedContext(SpacesContext);
  const space = spaces.find(({ id }) => id === roleBinding.spaceID);

  if (!space) {
    return null;
  }

  return (
    <Link
      size="small"
      href={`/spaces/${space.id}`}
      onPress={() => trackAnalytics("Space link clicked")}
    >
      {space.name}
    </Link>
  );
};

export default UserRolesListItemGrantedAtCell;
