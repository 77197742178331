import { useState } from "react";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerForm from "ds/components/DrawerNew/Form";

type SettingsSingleSignOnDrawerProps = {
  children: ({ setIsDirty }: { setIsDirty: (isDirty: boolean) => void }) => JSX.Element;
  title: string;
  isDismissable?: boolean;
};

const SettingsSingleSignOnDrawer = createDrawer(function SingleSignOnSetupDrawer({
  title,
  children,
  isDismissable,
}: SettingsSingleSignOnDrawerProps) {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <DrawerForm isDirty={isDirty} isDismissable={isDismissable}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={title} />
        <DrawerCloseIcon />
      </DrawerHeader>

      {children({ setIsDirty })}
    </DrawerForm>
  );
});

export const openSingleSignOnDrawer = createDrawerTrigger(SettingsSingleSignOnDrawer);
