import { gql, TypedDocumentNode } from "@apollo/client";

import { AzureIntegration } from "types/generated";

type GetAzureIntegrationData = {
  azureIntegration: AzureIntegration;
};

export const GET_AZURE_INTEGRATION: TypedDocumentNode<GetAzureIntegrationData> = gql`
  query GetAzureIntegration($id: ID!) {
    azureIntegration(id: $id) {
      adminConsentProvided
      adminConsentURL
      applicationId
      updatedAt
      displayName
      id
      labels
      name
      tenantId
      defaultSubscriptionId
      spaceDetails {
        id
        name
        accessLevel
      }
      hasAttachedStacks
      attachedStacks {
        id
        stackId
        stackName
        isModule
      }
    }
  }
`;
