import { gql } from "@apollo/client";

export const GET_STACK_CONFIGS = gql`
  query GetStackConfigs($id: ID!) {
    stack(id: $id) {
      id
      attachedContexts {
        contextName
        id
        contextId
        isAutoattached
        priority
      }
      config {
        id
        runtime
        type
        createdAt
        writeOnly
        description
        value
      }
      hooks {
        afterApply
        beforeApply
        afterInit
        beforeInit
        afterPlan
        beforePlan
        afterPerform
        beforePerform
        afterDestroy
        afterRun
        beforeDestroy
      }
    }
  }
`;
