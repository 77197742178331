export const handleFocus = (element?: Element | null) => {
  if (element instanceof HTMLElement) {
    element.focus();
  }
};

export const captureEvent = (e: React.KeyboardEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

export const getGrid = (element: Element): Element | null => {
  if (!element.parentElement) {
    return null;
  }

  if (element.parentElement.getAttribute("role") === "grid") {
    return element.parentElement;
  }
  return getGrid(element.parentElement);
};

export const getRows = (grid: Element | null): Element[] => {
  if (!grid) {
    return [];
  }

  return Array.from(grid.querySelectorAll("[role='row']"));
};

export const getSiblingRow = (row: Element, direction: "prev" | "next"): Element | null => {
  /**
   * Can't use previousElementSibling/nextElementSibling, as header row is contained within a separate rowgroup
   */
  const rows = getRows(getGrid(row));
  const currentIndex = Array.from(rows).indexOf(row);
  if (currentIndex >= 0) {
    return rows[currentIndex + (direction === "prev" ? -1 : 1)] || null;
  }
  return null;
};
