import { StackAzureIntegrationAttachment } from "types/generated";
import KeyValue from "components/KeyValue";

type AttachCloudDetailsAzureFieldsType = {
  integration: StackAzureIntegrationAttachment;
};

const AttachCloudDetailsAzureFields = ({ integration }: AttachCloudDetailsAzureFieldsType) => {
  return (
    <>
      <KeyValue name="Name">{integration.integrationName}</KeyValue>
      <KeyValue name="Subscription ID">
        {integration.subscriptionId || integration.defaultSubscriptionId}
      </KeyValue>
    </>
  );
};

export default AttachCloudDetailsAzureFields;
