import { useCallback, useMemo } from "react";

import { captureEvent, getGrid, getSiblingRow, handleFocus } from "./utils";

type UseRowKeyboardNavigationOptions = {
  // Mark the group rows with a data-group attribute, so we can navigate to the nearest group in the table
  isGroup: boolean;
};

const handleRowFocus = (row?: Element | null) => {
  if (!row) {
    return;
  }
  handleFocus(row);

  row.scrollIntoView({
    behavior: "instant",
    inline: "start",
    block: "center",
  });
};

const focusNearestGroupSibling = (element: Element) => {
  if (!element.previousElementSibling) {
    // No more previous siblings, focus the grid instead, shouldn't really happen
    handleRowFocus(getGrid(element));
    return;
  }

  if (element.previousElementSibling.getAttribute("data-group") === "true") {
    // Focus the group wrapper
    handleRowFocus(element.previousElementSibling);
    return;
  }

  focusNearestGroupSibling(element.previousElementSibling);
};

const useRowKeyboardNavigation = ({ isGroup }: UseRowKeyboardNavigationOptions) => {
  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === "ArrowUp") {
      captureEvent(e);

      // Focus previous row
      handleRowFocus(getSiblingRow(e.currentTarget, "prev"));
      return;
    }

    if (e.key === "ArrowDown") {
      captureEvent(e);

      // Focus next row
      handleRowFocus(getSiblingRow(e.currentTarget, "next"));
      return;
    }

    if (e.key === "ArrowRight") {
      captureEvent(e);

      // Focus first cell in the row
      handleRowFocus(e.currentTarget.firstElementChild);
      return;
    }

    if (e.key === "ArrowLeft") {
      captureEvent(e);

      if (e.currentTarget.getAttribute("data-group") === "false") {
        focusNearestGroupSibling(e.currentTarget);
        return;
      }

      // On a group - focus the grid
      handleRowFocus(getGrid(e.currentTarget));

      return;
    }
  }, []);

  return useMemo(
    () => ({ onKeyDown, tabIndex: -1, ["data-group"]: isGroup }),
    [isGroup, onKeyDown]
  );
};

export default useRowKeyboardNavigation;
