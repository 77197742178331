import EmptyState from "ds/components/EmptyState";
import { EmptystateShareColored } from "components/icons/generated";
import Box from "ds/components/Box";

const UserRolesEmpty = () => {
  return (
    <Box align="center" justify="center" grow="1">
      <EmptyState title="You don’t have any roles assigned yet" icon={EmptystateShareColored} />
    </Box>
  );
};

export default UserRolesEmpty;
