import { memo } from "react";

import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import { type RenderResourceRowCellProps } from ".";

type IacManagementTableResourceRowCellActionsProps = Pick<
  RenderResourceRowCellProps,
  "handleResourceClick" | "handleCopyLink"
>;

const IacManagementTableResourceRowCellActions = memo(
  ({ handleResourceClick, handleCopyLink }: IacManagementTableResourceRowCellActionsProps) => {
    return (
      <IacManagementTableCell stickyRight>
        <DropdownMenuEllipsis tooltip="Resource actions">
          <DropdownMenuItem onAction={handleCopyLink}>Copy link</DropdownMenuItem>
          <DropdownMenuItem onAction={handleResourceClick}>See details</DropdownMenuItem>
        </DropdownMenuEllipsis>
      </IacManagementTableCell>
    );
  }
);

IacManagementTableResourceRowCellActions.displayName = "IacManagementTableResourceRowCellActions";

export default IacManagementTableResourceRowCellActions;
