import MetaInfoListItem from "components/MetaInfoList/Item";
import TriggerByInfo from "components/TriggerByInfo";
import { Run } from "types/generated";
import Timestamp from "components/Timestamp";

import RunTriggerCommit from "../RunTriggerCommit";
import styles from "./styles.module.css";

type RunMetaInfoListItemProps = {
  run: Run;
  stackId: string;
};

const RunMetaInfoListItem = ({ run, stackId }: RunMetaInfoListItemProps) => (
  <MetaInfoListItem className={styles.wrapper} fullWidth>
    <span className={styles.iconWrapper}>
      <RunTriggerCommit className={styles.icon} triggeredBy={run.triggeredBy || undefined} />
    </span>
    {run.finished ? "finished" : "started"}{" "}
    <Timestamp timestamp={run.finished ? run.updatedAt : run.createdAt} />
    {run.finished ? ", started " : " "}
    <TriggerByInfo
      stackId={stackId}
      triggeredBy={run.triggeredBy || undefined}
      driftDetection={run.driftDetection}
    />
  </MetaInfoListItem>
);

export default RunMetaInfoListItem;
