import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";

import { useRunShortcut } from "../../hooks/useRunShortcut";
import { STOP_RUN } from "./gql";

type StopRunButtonProps = {
  runId: string;
  stackId: string;
  runQueryToRefetch?: string;
};

const StopRunButton = ({ runId, stackId, runQueryToRefetch }: StopRunButtonProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stopRuns, { loading, data }] = useMutation(STOP_RUN, {
    variables: { stackId, runId },
    refetchQueries: [runQueryToRefetch || "GetRun"],
    awaitRefetchQueries: true,
  });

  const onClick = useCallback(() => {
    stopRuns()
      .then(({ data }) => {
        if (data?.runStop?.id) {
          reportSuccess({ message: `Run ${data.runStop.id} successfully stopped` });
        }
      })
      .catch(onError);
  }, [stopRuns, onError, reportSuccess]);

  const isLoading = loading || !!data?.runStop?.id;

  useRunShortcut({
    label: "Stop",
    code: "alt+s",
    callback: onClick,
    loading: isLoading,
  });

  return (
    <Button
      variant="secondary"
      disabled={isLoading}
      loading={isLoading}
      onPress={onClick}
      size="small"
    >
      Stop
    </Button>
  );
};

export default StopRunButton;
