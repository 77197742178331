import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";

import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { AwsIntegration } from "types/generated";
import useAttachCloudIntegration from "shared/CloudIntegration/useAttachCloudIntegration";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";

import AWSIntegrationWarning from "./AWSIntegrationWarning";
import CardForm from "./CardForm";
import IntegrationField from "./Fields/IntegrationField";
import ReadToggleField from "./Fields/ReadToggleField";
import WriteToggleField from "./Fields/WriteToggleField";
import WriteOnlyWarning from "./WriteOnlyWarning";
import SubmitButton from "./SubmitButton";
import NoPermissionError from "./NoPermissionError";
import { AttachCloudEntityType } from "./types";
import AttachCloudAwsExternalIdBanner from "./AwsExternalIdBanner";
type AttachCloudAWSIntegrationProps = {
  attachableIntegrations: AwsIntegration[] | undefined;
  entityId: string;
  entityType: AttachCloudEntityType;
  onSuccess: () => void;
  refetchQueries?: string[];
};

type FormValues = {
  integrationId: string;
  read: boolean;
  write: boolean;
};

const AttachCloudFormAWSIntegration = ({
  attachableIntegrations,
  entityId,
  entityType,
  onSuccess,
  refetchQueries,
}: AttachCloudAWSIntegrationProps) => {
  const form = useForm<FormValues>({
    defaultValues: {
      integrationId: "",
      read: false,
      write: false,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty },
  } = form;

  const { attach, isIntegrationAwsWarningVisible, hideIntegrationAwsWarning } =
    useAttachCloudIntegration({
      entityId,
      onSuccess,
      refetchQueries,
    });

  useEffect(() => {
    const subscription = watch(hideIntegrationAwsWarning);
    return subscription.unsubscribe;
  }, [hideIntegrationAwsWarning, watch]);

  useObserveForWarning(
    isDirty,
    <>
      You have not attached the <b>selected AWS integration</b>. Do you want to continue without
      attaching it?
    </>
  );

  const onSubmit = (data: FormValues) =>
    attach({
      type: CLOUD_INTEGRATIONS.AWS,
      integrationId: data.integrationId,
      read: data.read,
      write: data.write,
    });

  const options = useMemo(() => {
    return attachableIntegrations
      ? attachableIntegrations.map((value) => ({ value: value.id, label: value.name }))
      : [];
  }, [attachableIntegrations]);

  return (
    <FormProvider {...form}>
      <CardForm onSubmit={handleSubmit(onSubmit)}>
        <IntegrationField options={options} />
        <ReadToggleField />
        <WriteToggleField />
        <WriteOnlyWarning />
        <NoPermissionError />
        <SubmitButton />
      </CardForm>
      {!isIntegrationAwsWarningVisible && (
        <AttachCloudAwsExternalIdBanner entityId={entityId} entityType={entityType} />
      )}
      {isIntegrationAwsWarningVisible && <AWSIntegrationWarning entityId={entityId} />}
    </FormProvider>
  );
};

export default AttachCloudFormAWSIntegration;
