import cx from "classnames";
import { ReactNode, useCallback, useId, useRef, useState } from "react";
import { Transition } from "react-transition-group";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import ResizeBar from "components/ResizeBar";
import useCollapsibleWidthManager from "components/ResizeBar/useCollapsibleWidthManager";

import BlueprintEditorPanelExpandButton from "./ExpandButton";
import styles from "./styles.module.css";
import { BlueprintEditorPanelSide } from "./types";

type BlueprintEditorPanelProps = {
  title: string;
  actions?: ReactNode;
  side: BlueprintEditorPanelSide;
  onExpanded?: (expanded: boolean) => void;
  children: ReactNode;
};

const sizeProps = {
  initialWidth: 400,
  minWidth: 320,
  maxWidth: 600,
};

const animateExpandCollapse = (wrapper?: HTMLDivElement | null) => {
  if (wrapper) {
    wrapper.style.transition = `width 200ms`;
  }
  setTimeout(() => {
    if (wrapper) {
      wrapper.style.transition = "";
    }
  }, 200);
};

const BlueprintEditorPanel = ({
  title,
  actions,
  children,
  onExpanded,
  side,
}: BlueprintEditorPanelProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const wrapperId = useId();

  const onWidthChange = useCallback((width: number) => {
    if (wrapperRef.current) {
      wrapperRef.current.style.width = `${width}px`;
    }
  }, []);

  const onCollapse = useCallback(() => {
    animateExpandCollapse(wrapperRef.current);
    setIsExpanded(false);
    onExpanded?.(false);
  }, [onExpanded]);

  const onExpand = useCallback(() => {
    animateExpandCollapse(wrapperRef.current);
    setIsExpanded(true);
    onExpanded?.(true);
  }, [onExpanded]);

  const { setWidth, toggleExpand, resizeBarProps } = useCollapsibleWidthManager({
    ...sizeProps,
    onWidthChange,
    onCollapse,
    onExpand,
  });

  return (
    <Transition
      in={isExpanded}
      timeout={{
        appear: 0,
        enter: 200,
        exit: 0,
      }}
    >
      {(state) => (
        <Box
          ref={wrapperRef}
          className={cx(styles.panel, styles[side], {
            [styles.collapsed]: state === "exited",
          })}
          id={wrapperId}
        >
          {side === "right" && (
            <ResizeBar {...sizeProps} {...resizeBarProps} onWidthChange={setWidth} reverse />
          )}
          <Box className={styles.panelContent} direction="column">
            <Box
              relative
              fullWidth
              justify="between"
              align="center"
              padding="large x-large"
              className={styles.header}
            >
              <Typography
                variant="p-t6"
                tag="h3"
                className={cx(styles.title, {
                  [styles.hidden]: state !== "entered",
                })}
              >
                {title}
              </Typography>

              <Box className={styles.actionWrapper} gap="medium">
                {actions}
                <BlueprintEditorPanelExpandButton
                  wrapperId={wrapperId}
                  isExpanded={isExpanded}
                  toggleExpand={toggleExpand}
                  side={side}
                />
              </Box>
            </Box>

            <Box relative grow="0" direction="column" zeroMinWidth>
              <Typography
                tag="span"
                variant="p-t6"
                className={cx(styles.sideTitle, styles[side], {
                  [styles.hidding]: isExpanded,
                  [styles.hidden]: state === "entered",
                })}
              >
                {title}
              </Typography>
              <Box
                grow="0"
                direction="column"
                padding="medium x-large"
                className={cx(styles.jsonWrapper, styles.hasNavigation, {
                  [styles.hidden]: state !== "entered",
                })}
                zeroMinWidth
              >
                {children}
              </Box>
            </Box>
          </Box>
          {side === "left" && <ResizeBar {...resizeBarProps} onWidthChange={setWidth} />}
        </Box>
      )}
    </Transition>
  );
};

export default BlueprintEditorPanel;
