import { BulkActionsApplicableAction } from "components/BulkActions/ChooseActionStep/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActions/Drawer/ActionSteps/Footer";
import {
  BulkActionItemID,
  BulkActionsActionsPerItem,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import BulkActionsDrawerActionStepsItems from "components/BulkActions/Drawer/ActionSteps/Items";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";
import { Role } from "types/generated";

import RolesBulkActionsSelectedItem from "./SelectedRoleItem";
import { RolesBulkActions } from "./types";

type RolesBulkActionsSelectedItemsViewProps = {
  items: Role[];
  onItemDismiss: (id: BulkActionItemID) => void;
  availableActions: Array<BulkActionsApplicableAction<RolesBulkActions>>;
  actionsPerItem: BulkActionsActionsPerItem<RolesBulkActions>;
  onEmptyActionsCancel: () => void;
};

const RolesBulkActionsSelectedItemsView = ({
  items,
  onItemDismiss,
  actionsPerItem,
  availableActions,
  onEmptyActionsCancel,
}: RolesBulkActionsSelectedItemsViewProps) => {
  return (
    <>
      <BulkActionsDrawerActionStepsItems selectedItemsCount={items.length}>
        {items.map((item) => (
          <RolesBulkActionsSelectedItem
            key={item.id}
            item={item}
            onDismiss={onItemDismiss}
            itemActions={actionsPerItem.get(item.id)}
            allActionsQty={availableActions.length}
            step={BulkActionsStep.ChooseAction}
          />
        ))}
      </BulkActionsDrawerActionStepsItems>

      <BulkActionsDrawerActionStepsFooter>
        <BulkActionsPatternsActionsList
          variant={BulkActionsVariant.Drawer}
          availableActions={availableActions}
          onEmptyActionsCancel={onEmptyActionsCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default RolesBulkActionsSelectedItemsView;
