import DescriptionDetails from "components/DescriptionDetails";
import FormFieldViewText from "components/FormFields/ViewText";
import { Incognito, Network, Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { hasSpaceManageAccess } from "utils/user";
import { AccountContext } from "views/AccountWrapper";

import { ProviderDetailsDrawerProps } from "./types";

const ProviderDetailsDrawer = createDrawer(({ provider, onEdit }: ProviderDetailsDrawerProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const onOpenFullDescription = useShowFullDescriptionWithBackArrow(provider.description);

  const canManageProvider = viewer.admin || hasSpaceManageAccess(provider.spaceDetails.accessLevel);

  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Details" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody>
        <FormFieldViewText label="Type" value={provider.id} withCopy />

        <FormField label="Space">
          <MetaInfoListItem
            icon={Space}
            linkText={provider.spaceDetails.name}
            href={`/spaces/${provider.space}`}
          />
        </FormField>

        <FormField label="Provider type">
          <Box align="center" __deprecatedGap="0.6rem">
            <Icon src={provider.public ? Network : Incognito} />
            <Typography tag="p" variant="p-body2">
              {provider.public ? "public" : "private"}
            </Typography>
          </Box>
        </FormField>

        <FormFieldViewText label="Latest version" value={provider.latestVersionNumber || "-"} />

        <DescriptionDetails
          description={provider.description}
          onOpenFullDescription={onOpenFullDescription}
          {...(canManageProvider && { onAddDescription: onEdit })}
        />

        {provider.labels.length > 0 && (
          <FormField label="Labels">
            <TagsList tags={provider.labels} alwaysExpanded />
          </FormField>
        )}

        <DrawerFooter>
          <DrawerFooterActions>
            <DrawerCancelButton />
            {canManageProvider && (
              <Button variant="secondary" onPress={onEdit}>
                Edit
              </Button>
            )}
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showProviderDetailsDrawer = createDrawerTrigger(ProviderDetailsDrawer);
