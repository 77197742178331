// TODO: Extract to a common hook?
import useLocalStorage from "@rehooks/local-storage";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { z } from "zod";

import {
  SavedFilter,
  SearchQueryOrderDirection,
  SearchQueryPredicate,
  SearchSuggestionsFieldType,
} from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  getDefaultViewStorageKey,
  makeConstraintByType,
  makeConstraintValuesByType,
} from "components/Filters/helpers";
import { SAVED_VIEW } from "components/Filters/gql";
import { DEFAULT_VIEW_ID } from "components/Filters/constants";

const DEFAULT_VIEW_SCHEMA = z
  .object({
    key: z.string().catch(""),
    value: z.string().catch(""),
  })
  .catch({ key: "", value: "" });

const DEFAULT_VIEW_VALUE_SCHEMA = z
  .object({
    filters: z
      .array(
        z.tuple([
          z.string(),
          z.object({
            values: z.array(z.string()).catch([]),
            key: z.string(),
            filterName: z.string(),
            type: z.nativeEnum(SearchSuggestionsFieldType),
          }),
        ])
      )
      .catch([])
      .optional()
      .catch(undefined),
    text: z.string().catch("").optional().catch(undefined),
    sort: z
      .object({
        option: z.string(),
        direction: z.nativeEnum(SearchQueryOrderDirection),
      })
      .optional()
      .catch(undefined),
  })
  .catch({});

const getFiltersFromSavedData = (filter?: SavedFilter["data"]) => {
  if (!filter) {
    return {};
  }

  try {
    const parsedFilter = DEFAULT_VIEW_SCHEMA.parse(JSON.parse(filter));

    const parsedFilterValue = DEFAULT_VIEW_VALUE_SCHEMA.parse(JSON.parse(parsedFilter.value));
    const parsedFilters = parsedFilterValue.filters;
    let labelsCounter = 0;

    const predicates = new Map(
      parsedFilters?.map(([initialKey, field]): [string, SearchQueryPredicate] => {
        let key = initialKey;

        if (field.filterName === "label") {
          labelsCounter += 1;
          key = `label${labelsCounter}`;
        }

        return [
          key,
          {
            field: field.filterName,
            exclude: null,
            constraint: makeConstraintByType(
              field.type,
              makeConstraintValuesByType(field.type, field.values)
            ),
          },
        ];
      })
    );

    return {
      sortOption: parsedFilterValue.sort?.option,
      sortDirection: parsedFilterValue.sort?.direction,
      predicates,
      search: parsedFilterValue.text && decodeURI(parsedFilterValue.text),
    };
  } catch {
    return {};
  }
};

const useInitialDefaultView = (filtersType: string) => {
  const { onError } = useTypedContext(FlashContext);

  const defaultViewIdStorageKey = getDefaultViewStorageKey(filtersType);

  const [defaultViewId] = useLocalStorage<string>(defaultViewIdStorageKey);

  const shouldSkip = !defaultViewId || defaultViewId === DEFAULT_VIEW_ID;

  const { loading: defaultFilterLoading, data: defaultFilterData } = useQuery<{
    savedFilter: SavedFilter;
  }>(SAVED_VIEW, {
    onError,
    variables: {
      id: defaultViewId,
    },
    skip: shouldSkip,
    // APOLLO CLIENT UPDATE notes
  });

  const data = useMemo(
    () => getFiltersFromSavedData(defaultFilterData?.savedFilter?.data),
    [defaultFilterData?.savedFilter?.data]
  );

  if (defaultFilterLoading && !defaultFilterData) {
    return {
      loaded: false,
      data: undefined,
    };
  }

  return {
    loaded: shouldSkip ? true : !!defaultFilterData,
    data: data,
  };
};

export default useInitialDefaultView;
