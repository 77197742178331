import Box from "ds/components/Box";
import Link from "ds/components/Link";

import { CellComponentProps } from "./types";
import useBlueprintsAnalytics from "../../hooksNew/useBlueprintsAnalytics";

type BlueprintsRowNameCellProps = CellComponentProps;

const BlueprintsRowNameCell = ({ blueprint }: BlueprintsRowNameCellProps) => {
  const trackAnalytics = useBlueprintsAnalytics();

  return (
    <Box direction="row" align="start">
      <Link
        size="small"
        variant="secondary"
        href={`../blueprint/${blueprint.id}`}
        onPress={() => trackAnalytics("Link clicked")}
      >
        {blueprint.name}
      </Link>
    </Box>
  );
};

export default BlueprintsRowNameCell;
