import { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FormFieldTags from "components/FormFields/Tags";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { getDrawerFormFix } from "utils/css";
import { validatePolicyName } from "utils/formValidators";

import { getManagementStrategy } from "../../helpers";
import { UserManagementActivationStatus } from "../../types";
import { ADD_LOGIN_POLICY_DRAWER_TEST_ID } from "./constants";
import { CreatePolicyFields } from "./types";

type LoginPoliciesCreateDrawerProps = {
  activationStatus: UserManagementActivationStatus;
  takenPolicyNames: string[];
};

const LoginPoliciesCreateDrawer = createDrawer(
  ({ activationStatus, takenPolicyNames }: LoginPoliciesCreateDrawerProps) => {
    const navigate = useNavigate();

    const trackSegmentAnalyticsEvent = useAnalytics({
      page: AnalyticsPageOrganization.OrganizationLoginPoliciesList,
      defaultCallbackTrackProperties: {
        managementStrategy: getManagementStrategy(activationStatus),
      },
    });

    const createPolicyForm = useForm<CreatePolicyFields>({
      defaultValues: {
        name: "",
        labels: [],
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { errors, isDirty },
    } = createPolicyForm;

    const cancelHandler = () => {
      trackSegmentAnalyticsEvent("Create Cancel");
    };
    const drawer = useModal();

    const redirectToCreateView: SubmitHandler<CreatePolicyFields> = (formData) => {
      trackSegmentAnalyticsEvent("Create Continue");

      const labelsQuery =
        formData.labels && `&labels=${btoa(encodeURIComponent(JSON.stringify(formData.labels)))}`;

      drawer.hide();
      navigate(`/new/login-policy?name=${btoa(encodeURIComponent(formData.name))}${labelsQuery}`);
    };

    return (
      <DrawerForm isDirty={isDirty} dataTestId={ADD_LOGIN_POLICY_DRAWER_TEST_ID}>
        <FormProvider {...createPolicyForm}>
          <form onSubmit={handleSubmit(redirectToCreateView)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Create policy" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight>
              <FormField label="Name" error={errors?.name?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Name of your new policy"
                    error={!!errors?.name}
                    {...register("name", {
                      required: "Name field is required.",
                      setValueAs: (value) => value.trim(),
                      validate: validatePolicyName(takenPolicyNames),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldTags
                stopEnterPropagation
                name="labels"
                tagName="label"
                label="Labels"
                isOptional
              />

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton onClick={cancelHandler} />
                  <Button variant="primary" type="submit">
                    Continue
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showLoginPoliciesCreateDrawer = createDrawerTrigger(LoginPoliciesCreateDrawer);
