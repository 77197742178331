import { CSSProperties, useCallback, useRef } from "react";
import { Cell, Row } from "react-aria-components";

import { ManagedUser, ManagedUserGroup } from "types/generated";
import TableContextProvider from "components/Table/Context";
import Table from "components/Table";
import useTableSort from "components/Table/useTableSort";
import TableRow from "components/Table/Row";
import TableWrapper from "components/Table/Wrapper";

import {
  ANALYTICS,
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_COLUMNS_VIEW_CONFIG,
  initialSortDirection,
  initialSortOption,
  ROW_HEADER_ID,
  SORT_CONFIG,
  USERS_COLUMN_CONFIG,
  USERS_COLUMNS_STORAGE_KEY,
  USERS_CUSTOM_END_COLUMN_CONFIG,
} from "./constants";
import UsersListItem from "./Item";

type UsersListProps = {
  users: ManagedUser[];
  managedUserGroups: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
};

const UsersList = ({ users, managedUserGroups }: UsersListProps) => {
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const { handleSortOptionChange, sortDescriptor } = useTableSort({
    filtersOrderSettingsKey: FILTERS_ORDER_SETTINGS_KEY,
    sortConfig: SORT_CONFIG,
    initialSortOption,
    initialSortDirection,
  });

  const renderRow = useCallback(
    (
      item: Partial<ManagedUser> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.username}
          >
            <UsersListItem user={item as ManagedUser} managedUserGroups={managedUserGroups} />
          </TableRow>
        );
      }
    },
    [managedUserGroups]
  );

  return (
    <TableContextProvider
      columnsConfig={USERS_COLUMN_CONFIG}
      endCustomColumns={USERS_CUSTOM_END_COLUMN_CONFIG}
      initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
      rowHeaderId={ROW_HEADER_ID}
      localStorageId={USERS_COLUMNS_STORAGE_KEY}
      setSortDescriptor={handleSortOptionChange}
      sortDescriptor={sortDescriptor}
      {...ANALYTICS}
    >
      <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
        <Table ariaLabel="Roles list" items={users} ref={virtualizedListContainerRef}>
          {renderRow}
        </Table>
      </TableWrapper>
    </TableContextProvider>
  );
};

export default UsersList;
