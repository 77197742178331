import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type AssignRoleDrawerDrawerDeleteActionProps = {
  onDelete: () => void;
};

const AssignRoleDrawerDrawerDeleteAction = ({
  onDelete,
}: AssignRoleDrawerDrawerDeleteActionProps) => {
  return (
    <DropdownMenuItem onAction={onDelete} danger>
      Delete role
    </DropdownMenuItem>
  );
};

export default AssignRoleDrawerDrawerDeleteAction;
