import { memo, ReactNode, Ref } from "react";
import cx from "classnames";

import { RESOURCE_DRAWER_TARGET_WHITE_LIST } from "views/Account/IacManagement/constants";
import TableBodyRow from "ds/components/Table/Row";

import styles from "./styles.module.css";

type IacManagementTableBodyRowProps = {
  dataIndex: number;
  children: ReactNode;
  forwardRef?: Ref<HTMLDivElement>;
  className?: string;
};

const IacManagementTableBodyRow = memo(function IacManagementTableBodyRow({
  dataIndex,
  children,
  className,
  forwardRef,
  ...props
}: IacManagementTableBodyRowProps) {
  return (
    <TableBodyRow
      {...props}
      className={cx(styles.row, className)}
      data-index={dataIndex}
      ref={forwardRef}
      {...{ [RESOURCE_DRAWER_TARGET_WHITE_LIST]: true }}
    >
      {children}
    </TableBodyRow>
  );
});

export default IacManagementTableBodyRow;
