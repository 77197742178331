import { ReactNode } from "react";

import DocumentationIconButton from "components/DocumentationIconButton";
import TierInfo from "components/TierInfo";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";
import useTierFeature from "views/Account/hooks/useTierFeature";
import { SpacesContext } from "views/Account/SpacesProvider";
import { AccountContext } from "views/AccountWrapper";

type WorkerPoolsPageLayoutProps = {
  children: ReactNode;
  onCreate?: () => void;
};

const IntegrationsPageLayout = ({ children, onCreate }: WorkerPoolsPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const isServiceNowActive = useTierFeature(BillingTierFeature.ServiceNowIntegration);

  useTitle(`ServiceNow · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">
          <Box justify="center" align="center" gap="medium">
            ServiceNow <BadgeNext variant="blue" text="BETA" type="strong" />
          </Box>
        </ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <DocumentationIconButton
            href={getDocsUrl("/integrations/external-integrations/servicenow")}
            tooltipText="Go to ServiceNow integration documentation"
          />

          {isServiceNowActive && hasEntityCreateAccess && onCreate && (
            <Button onPress={onCreate} variant="primary">
              Create integration
            </Button>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>
      <TierInfo
        type="callout"
        variant="danger"
        title="Upgrade plan to use ServiceNow integrations"
        feature={BillingTierFeature.ServiceNowIntegration}
      >
        ServiceNow integrations are not available in your current plan. Upgrade to{" "}
        <strong>Enterprise</strong> plan to use the feature.
      </TierInfo>
      {children}
    </>
  );
};

export default IntegrationsPageLayout;
