import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import { Blueprint, BlueprintState } from "types/generated";
import { getDrawerFormFix } from "utils/css";
import DrawerSection from "ds/components/Drawer/Section";
import useTypedFlags from "hooks/useTypedFlags";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import BadgeNext from "ds/components/BadgeNext";
import VersionFormFragment, {
  BlueprintVersionFormFields,
} from "views/Account/Blueprint/components/VersionFormFragment";
import getBlueprintVersionDefaults from "views/Account/Blueprint/components/VersionFormFragment/getBlueprintVersionDefaults";

import { REFETCH_QUERIES_LIST } from "../constants";
import { CLONE_BLUEPRINT, CREATE_BLUEPRINT, UPDATE_BLUEPRINT } from "../gql";

type CreateBlueprintFormFields = BlueprintVersionFormFields & {
  name: string;
  description?: string;
  space: string;
  labels: Record<"value", string>[];
};

type CreateBlueprintDrawerProps = {
  blueprint?: Blueprint;
  isCloneMode?: boolean;
};

const getPrimaryButtonText = (isEditMode: boolean, isCloneMode?: boolean): string => {
  if (isEditMode) {
    return "Save";
  }
  if (isCloneMode) {
    return "Clone";
  }

  return "Create blueprint draft";
};

const CreateBlueprintDrawer = createDrawer(
  ({ blueprint, isCloneMode }: CreateBlueprintDrawerProps) => {
    const drawer = useModal();
    const { blueprintsVersioning } = useTypedFlags();

    const isEditMode = !!blueprint && !isCloneMode;

    const navigate = useNavigate();

    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const blueprintName = isCloneMode ? `${blueprint?.name} (clone)` : blueprint?.name;

    const builderForm = useForm<CreateBlueprintFormFields>({
      defaultValues: {
        name: blueprint ? blueprintName : "",
        description: blueprint?.description || "",
        space: blueprint?.space.id || "",
        labels: blueprint?.labels.map((value) => ({ value })) || [],
        ...getBlueprintVersionDefaults(blueprint, blueprintsVersioning),
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { errors, isDirty },
    } = builderForm;

    const [createBlueprint] = useMutation<{ blueprintCreate: Blueprint }>(CREATE_BLUEPRINT, {
      refetchQueries: REFETCH_QUERIES_LIST,
    });

    const [updateBlueprint] = useMutation<{ blueprintUpdate: Blueprint }>(UPDATE_BLUEPRINT, {
      onError,
      refetchQueries: REFETCH_QUERIES_LIST,
    });

    const [cloneBlueprint] = useMutation<{ blueprintClone: Blueprint }>(CLONE_BLUEPRINT, {
      onError,
    });

    const onCreateSubmit = (formData: CreateBlueprintFormFields) => {
      createBlueprint({
        variables: {
          input: {
            name: formData.name,
            description: formData.description,
            space: formData.space,
            labels: formData.labels.map((item) => item.value),
            state: BlueprintState.Draft,
            template: "",
            ...(blueprintsVersioning
              ? {
                  version: formData.version,
                  versionDescription: formData.versionDescription,
                }
              : {}),
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintCreate) {
            reportSuccess({
              message: `Blueprint "${data.blueprintCreate.name}" is successfully created`,
            });

            drawer.hide();
            navigate(`/blueprint/${data.blueprintCreate.id}`);
          }
        })
        .catch(onError);
    };

    const onCloneSubmit = (formData: CreateBlueprintFormFields, blueprintItem: Blueprint) => {
      cloneBlueprint({
        variables: {
          id: blueprintItem.id,
          input: {
            name: formData.name,
            description: formData.description,
            space: formData.space,
            labels: formData.labels.map((item) => item.value),
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintClone) {
            reportSuccess({
              message: `Blueprint "${data.blueprintClone.name}" is successfully created`,
            });

            drawer.hide();

            navigate(`/blueprint/${data.blueprintClone.id}`);
          }
        })
        .catch(onError);
    };

    const onEditSubmit = (formData: CreateBlueprintFormFields, blueprintItem: Blueprint) => {
      updateBlueprint({
        variables: {
          id: blueprintItem.id,
          input: {
            name: formData.name,
            description: formData.description,
            space: formData.space,
            labels: formData.labels.map((item) => item.value),
            state: blueprintItem.state,
            template: blueprintItem.rawTemplate,
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintUpdate) {
            reportSuccess({
              message: `Blueprint "${data.blueprintUpdate.name}" is successfully saved`,
            });

            drawer.hide();
          }
        })
        .catch(onError);
    };

    const onSubmit: SubmitHandler<CreateBlueprintFormFields> = (formData) => {
      if (isEditMode && blueprint?.id) {
        onEditSubmit(formData, blueprint);
      } else if (isCloneMode && blueprint?.id) {
        onCloneSubmit(formData, blueprint);
      } else {
        onCreateSubmit(formData);
      }
    };

    return (
      <DrawerForm isDirty={isDirty}>
        <FormProvider {...builderForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              {!isEditMode && !isCloneMode && <DrawerHeaderTitle title="Create new blueprint" />}
              {isEditMode && <DrawerHeaderTitle title="Edit blueprint" />}
              {isCloneMode && <DrawerHeaderTitle title="Clone blueprint" />}
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight noPadding>
              <DrawerSection>
                <FormField label="Name" error={errors?.name?.message}>
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Enter blueprint name here..."
                      error={!!errors?.name}
                      {...register("name", { required: "Name field is required." })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>

                <FormFieldSpace />

                <FormField label="Description" isOptional>
                  {({ ariaInputProps }) => (
                    <Textarea
                      placeholder="Enter blueprint description here..."
                      {...register("description")}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>

                <FormFieldTags stopEnterPropagation label="Labels" tagName="label" name="labels" />
              </DrawerSection>
              {!isEditMode && blueprintsVersioning && (
                <DrawerSection>
                  <Box gap="medium" align="center">
                    <Typography tag="h3" variant="p-t5">
                      Versioning
                    </Typography>
                    <BadgeNext variant="blue" type="strong" text="New" />
                  </Box>
                  <VersionFormFragment />
                </DrawerSection>
              )}
              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <Button variant="primary" type="submit">
                    {getPrimaryButtonText(isEditMode, isCloneMode)}
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showCreateBlueprintDrawer = createDrawerTrigger(CreateBlueprintDrawer);
