import { z } from "zod";

import { PollingIntervalGroups } from "apollo/constants";

export const flags = {
  bananaMode: false,
  "billing-usage": false,
  "diff-view-for-json-encoded-values": false,
  "fe-marketing-banners": [],
  "frontend-polling-seconds": {},
  "multiple-vcs-with-redesign": false,
  "enable-api-key-creation-for-non-root-admins-frontend": false,
  "use-slack-member-id-from-idp-for-user-profile": false,
  "dashboard-refresh-interval-seconds": 10,
  "use-billing-v-4": false,
  "use-new-topological-sort-in-runs-view": false,
  "sticky-view-header": false,
  "show-leaking-sensitive-outputs-through-dependencies": false,
  "vcs-events-show-ignored-runs": false,
  "number-of-deployments-widget-frontend": false,
  "approval-policy-evaluations-widget-frontend": false,
  "run-changes-diff-improvements": false,
  "run-summarization-ai": false,
  "stack-list-increased-limit": false,
  "resources-redesign": false,
  "stack-list-tag-column": false,
  "custom-access-roles": false,
  "blueprints-versioning": false,
  "blueprints-table-redesign": false,
};

export const selfHostedFlags = {};
export const FEMarketingBanner = z.object({
  id: z.string(),
  expirationDate: z.string().optional(),
});

export type FEMarketingBanner = z.infer<typeof FEMarketingBanner>;

type JsonTypes = {
  "fe-marketing-banners": FEMarketingBanner[];
  "frontend-polling-seconds": {
    group?: Record<PollingIntervalGroups, number>;
    singleQuery?: Record<string, number>; // string is the Operation name
  };
};

type KebabToCamelCase<S extends string> = S extends `${infer P1}-${infer P2}${infer P3}`
  ? `${P1}${Uppercase<P2>}${KebabToCamelCase<P3>}`
  : S;

type ObjectKeysFromKebabToCamelCase<ObjectType> = {
  [K in keyof ObjectType as K extends string ? KebabToCamelCase<K> : K]: ObjectType[K];
};

type FlagsWithJsonValues = Omit<typeof flags, keyof JsonTypes> & JsonTypes;

export type Flags = ObjectKeysFromKebabToCamelCase<FlagsWithJsonValues>;
export type SefHostedFlags = ObjectKeysFromKebabToCamelCase<typeof selfHostedFlags>;
