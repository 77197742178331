import SearchHighlight from "components/SearchHighlight";
import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";
import { TooltipProps } from "ds/components/Tooltip/types";
import Typography, { TypographyProps } from "ds/components/Typography";

import { DEFAULT_TITLE_VARIANT } from "./constants";

type ListEntitiesItemLinkUrlProps = {
  href: string;
};

type ListEntitiesItemLinkProps = ListEntitiesItemLinkUrlProps & {
  title: string;
  titleTag?: TypographyProps["tag"];
  titleVariant?: TypographyProps["variant"];
  titleColor?: TypographyProps["color"];
  tooltip?: string;
  tooltipWidthMode?: TooltipProps["widthMode"];
  searchQuery?: string;
  onClick?: () => void;
};

const ListEntitiesItemLink = ({
  title,
  href,
  titleTag = "span",
  titleVariant = DEFAULT_TITLE_VARIANT,
  titleColor,
  tooltip = title,
  tooltipWidthMode = "maxWidthSm",
  searchQuery,
  onClick,
}: ListEntitiesItemLinkProps) => {
  return (
    <TextEllipsis tooltip={tooltip} tooltipWidthMode={tooltipWidthMode}>
      {(props) => (
        <Link variant="secondary" href={href} onPress={onClick}>
          <Typography {...props} variant={titleVariant} tag={titleTag} color={titleColor}>
            {searchQuery ? <SearchHighlight fullText={title} searchQuery={searchQuery} /> : title}
          </Typography>
        </Link>
      )}
    </TextEllipsis>
  );
};

export default ListEntitiesItemLink;
