import { HourglassColored } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Icon from "ds/components/Icon";
import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";
import Typography from "ds/components/Typography";
import TypographyHeading from "ds/components/Typography/Heading";

type SessionHasExpiredModalModalProps = {
  isModalVisible: boolean;
  onConfirm?: () => void;
};

const SessionHasExpiredModal = ({
  isModalVisible,
  onConfirm,
}: SessionHasExpiredModalModalProps) => {
  return (
    <Modal role="alertdialog" isOpen={isModalVisible} isDismissable={false}>
      <ModalContent>
        <Box padding="x-large" direction="column" align="center" justify="center" fullWidth>
          <Icon src={HourglassColored} size="xx-large" />
          <TypographyHeading variant="p-t2" margin="x-large 0 small" slot="title">
            Your session has expired
          </TypographyHeading>
          <Typography variant="p-body2" tag="p" margin="0 0 x-large" color="secondary">
            Click button below to log in again.
          </Typography>
          <Button variant="primary" onPress={onConfirm}>
            Login
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default SessionHasExpiredModal;
