import { SCHEDULE_DEMO_LINK } from "constants/url";
import { getDocsUrl } from "utils/getDocsUrl";

import { Announcement } from "./types";

export const announcements: Record<string, Announcement> = {
  "AWS-24-April": {
    content:
      "Enjoy an action-packed day at AWS Summit London 2024, where the latest in cloud innovation comes to life.",
    preTitle: "24 April",
    title: "Join us at AWS Summit in London!",
    primaryLink: SCHEDULE_DEMO_LINK,
    primaryLinkText: "Request a meeting",
  },
  "ai-summarization-banner": {
    content:
      "Enable Saturnhead Assist to allow your team to summarize key log details, policy decisions, and exceptions for easy review after a run fails.",
    title: "Saturnhead Assist available in Spacelift",
    primaryLink: "/settings/ai",
    primaryLinkText: "Settings",
    secondaryLink: getDocsUrl("/concepts/run/ai"),
    secondaryLinkText: "Documentation",
  },
};
