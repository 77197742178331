import { SearchQueryOrder } from "types/generated";

import { SORT_OPTIONS_RESOURCES } from "../ListLayout/constants";

export const makeResourcesSortOption = (
  actualSortOption: SearchQueryOrder[]
): SearchQueryOrder[] | null => {
  const field = SORT_OPTIONS_RESOURCES[actualSortOption[0].field];
  if (field) {
    return [
      {
        field,
        direction: actualSortOption[0].direction,
      },
    ];
  }

  return null;
};
