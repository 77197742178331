import FlashContext from "components/FlashMessages/FlashContext";
import { ArrowCircle } from "components/icons/generated";
import Button, { ButtonProps } from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";
import useStackSyncCommit from "shared/Stack/useSyncCommit";
import { canSyncCommit } from "shared/Stack/useSyncCommit/accessValidation";
import { StackContext } from "views/Stack/Context";

type SyncButtonProps = {
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
  showsIgnoredRunCallout?: boolean;
};

const SyncButton = ({ size, variant = "secondary", showsIgnoredRunCallout }: SyncButtonProps) => {
  const { stack } = useTypedContext(StackContext);
  const { reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksRunList,
  });

  const [syncStackCommit, { loading }] = useStackSyncCommit();

  const handleClick = () =>
    syncStackCommit({ id: stack.id }, (data) => {
      if (data?.stackSyncCommit) {
        trackSegmentAnalyticsEvent("Stack synced", {
          ignoredRunCalloutVisible: showsIgnoredRunCallout,
        });
        reportSuccess({ message: "Tracked commit successfully synced" });
      }
    });

  const canSync = canSyncCommit(stack);

  if (!canSync) return null;

  return (
    <Tooltip
      on={(props) => (
        <Button
          {...props}
          size={size}
          variant={variant}
          onPress={handleClick}
          startIcon={ArrowCircle}
          disabled={loading}
        >
          Sync
        </Button>
      )}
    >
      Synchronize tracked commit with head branch.
    </Tooltip>
  );
};

export default SyncButton;
