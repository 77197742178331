import Link from "ds/components/Link";

import useIntegrationUsedByAnalytics from "../useIntegrationUsedByPageAnalytics";
import { CellComponentProps } from "./types";

type IntegrationsListItemSpaceCellProps = CellComponentProps;

const IntegrationUsedByListItemSpaceCell = ({ blueprint }: IntegrationsListItemSpaceCellProps) => {
  const trackAnalytics = useIntegrationUsedByAnalytics();

  if (!blueprint.blueprintSpaceDetails) {
    return null;
  }

  return (
    <Link
      size="small"
      href={`/spaces/${blueprint.blueprintSpaceDetails.id}`}
      onPress={() => trackAnalytics("Space link clicked")}
    >
      {blueprint.blueprintSpaceDetails.name}
    </Link>
  );
};

export default IntegrationUsedByListItemSpaceCell;
