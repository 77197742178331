import { ColumnConfig } from "components/Table/types";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { SearchQueryOrderDirection } from "types/generated";

export const USER_INTEGRATIONS_COLUMNS_STORAGE_KEY = "userIntegrationsColumnsConfig";
export const FILTERS_ORDER_SETTINGS_KEY = "userIntegrationsFiltersOrder";

export const initialSortOption = "name";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export enum USER_INTEGRATIONS_COLUMN_ID {
  TYPE = "TYPE",
  ID = "ID",
  DROPDOWN = "DROPDOWN",
}

export const ROW_HEADER_ID = USER_INTEGRATIONS_COLUMN_ID.ID;

export const USER_INTEGRATIONS_COLUMN_CONFIG: ColumnConfig = {
  [USER_INTEGRATIONS_COLUMN_ID.TYPE]: {
    title: "Type",
    minWidth: 68,
    maxWidth: 68,
    width: 68,
    allowsSorting: true,
    resizable: false,
    static: true,
  },

  [USER_INTEGRATIONS_COLUMN_ID.ID]: {
    title: "ID",
    minWidth: 130,
    allowsSorting: true,
    resizable: false,
    static: true,
  },
};

export const USER_INTEGRATIONS_CUSTOM_END_COLUMN_CONFIG: ColumnConfig = {
  [USER_INTEGRATIONS_COLUMN_ID.DROPDOWN]: {
    title: "More actions",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
  },
};

export const SORT_CONFIG: Record<string, string> = {
  [USER_INTEGRATIONS_COLUMN_ID.TYPE]: "type",
  [USER_INTEGRATIONS_COLUMN_ID.ID]: "id",
};

export const INITIAL_COLUMNS_VIEW_CONFIG = {
  visible: Object.keys(USER_INTEGRATIONS_COLUMN_CONFIG) as USER_INTEGRATIONS_COLUMN_ID[],
  hidden: [],
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageOrganization.OrganizationUserIntegrations,
};
