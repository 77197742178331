import { useEffect, useRef, useState } from "react";
import * as Comlink from "comlink";

import RedirectToLogin from "components/redirect/RedirectToLogin";
import SessionHasExpiredModal from "components/SessionHasExpiredModal";
import { getNowTimeMs } from "utils/time";

import Worker from "./NotifySessionHasExpired.worker.ts?worker";
import { WorkerInterface } from "./types";

// notify 3 seconds before session expiring
const PADDING = 3000;

type NotifySessionHasExpiredProps = {
  validUntil: number;
};

const webWorker = new Worker();

const refreshTimeout = (validUntil: number) => validUntil * 1000 - getNowTimeMs() - PADDING;

const NotifySessionHasExpired = ({ validUntil }: NotifySessionHasExpiredProps) => {
  const storage = localStorage;

  const remoteWorker = useRef(Comlink.wrap<WorkerInterface>(webWorker));

  const [shouldLogin, setShouldLogin] = useState(false);

  const [isModalVisible, setModalVisibility] = useState(false);

  const handleRedirectToLogin = () => {
    setModalVisibility(false);
    setShouldLogin(true);
  };

  const handleSetItem = (key: string, value: string) => storage.setItem(key, value);

  const onSessionExpiring = () => {
    setModalVisibility(true);
  };

  useEffect(() => {
    const cleanUp = remoteWorker.current.stopTimer;

    void remoteWorker.current.startTimer(
      Comlink.proxy(onSessionExpiring),
      refreshTimeout(validUntil)
    );

    return () => {
      void cleanUp();
    };
  }, [validUntil]);

  if (shouldLogin) {
    return <RedirectToLogin setItem={handleSetItem} />;
  }

  return (
    <SessionHasExpiredModal isModalVisible={isModalVisible} onConfirm={handleRedirectToLogin} />
  );
};

export default NotifySessionHasExpired;
