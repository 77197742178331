import { useCallback, useMemo } from "react";

import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { ModuleInput, TerraformWorkflowTool } from "types/generated";

import { generateModuleExample } from "./helpers";
import styles from "./styles.module.css";

type ModuleVersionInstructionsDrawerProps = {
  inputs: ModuleInput[] | undefined;
  moduleName: string;
  type: string;
  submodulePath?: string;
  versionNumber: string;
  moduleSource: string;
  workflowTool: TerraformWorkflowTool;
};

const ModuleVersionInstructionsDrawer = createDrawer(
  ({
    inputs,
    moduleName,
    submodulePath,
    type,
    versionNumber,
    moduleSource,
    workflowTool,
  }: ModuleVersionInstructionsDrawerProps) => {
    const sourceUrl = type === "module" ? moduleSource : `${moduleSource}//${submodulePath}`;

    const moduleExample = useMemo(() => {
      return generateModuleExample(moduleName, sourceUrl, versionNumber, inputs);
    }, [moduleName, sourceUrl, versionNumber, inputs]);

    const trackSegmentAnalyticsEvent = useAnalytics();

    const trackInstructionCopyEvent = useCallback(() => {
      trackSegmentAnalyticsEvent("Module Registry - Instructions Copied");
    }, [trackSegmentAnalyticsEvent]);

    const handleCopy = useCopyToClipboard(moduleExample, trackInstructionCopyEvent);

    return (
      <DrawerSimple variant="wide">
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Instructions" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody>
          <Typography variant="p-body2" tag="p">
            Copy and paste into your{" "}
            {workflowTool === TerraformWorkflowTool.OpenTofu ? "OpenTofu" : "Terraform"}{" "}
            configuration
          </Typography>
          <Box margin="x-large 0 0 0" surfaceColor="quaternary" className={styles.code}>
            <Box className={styles.codeInner} padding="x-large">
              <Typography variant="p-body3" tag="pre">
                <code>{moduleExample}</code>
              </Typography>
            </Box>

            <Button
              variant="secondary"
              onPress={handleCopy}
              size="small"
              className={styles.copyToClipboardButton}
            >
              Copy
            </Button>
          </Box>
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showModuleVersionInstructionsDrawer = createDrawerTrigger(
  ModuleVersionInstructionsDrawer
);
