import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import DocumentationButton from "components/DocumentationButton";
import FlashContext from "components/FlashMessages/FlashContext";
import {
  EmptystateCodeFileColored,
  EmptystateSearchNoResultsColored,
} from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import PageInfo from "components/PageWrapper/Info";
import SearchInput from "components/SearchInput";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import PrivateProviderRegistryTierInfo from "components/TierInfo/PrivateProviderRegistryTierInfo";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import useURLParams from "hooks/useURLParams";
import { SearchQueryOrderDirection, TerraformProvider } from "types/generated";
import { fuzzySearch } from "utils/fuzzySearch";
import { getDocsUrl } from "utils/getDocsUrl";
import { AccountContext } from "views/AccountWrapper";

import { SpacesContext } from "../SpacesProvider";
import { COLUMN_ORDER_PROVIDERS, FILTERS_ORDER_SETTINGS_KEY_PROVIDERS } from "./constants";
import { GET_PROVIDERS } from "./gql";
import { showProviderDrawer } from "./ProviderDrawer";
import ProviderListItem from "./ProviderListItem";
import { GetProvidersGql } from "./types";
import TerraformRegistryViewHeader from "./ViewHeader";

const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Type",
  },
  {
    value: "space",
    label: "Space",
  },
  {
    value: "latestVersionNumber",
    label: "Latest version",
    static: true,
  },
];

function Providers() {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  const handleOpenCreateDrawer = () => {
    showProviderDrawer();
  };

  const handleOpenUpdateDrawer = (provider: TerraformProvider) => {
    showProviderDrawer({
      id: provider.id,
      provider,
    });
  };

  const { error, loading, data } = useQuery<GetProvidersGql>(GET_PROVIDERS, { onError });
  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const providers = useMemo(() => {
    const filteredProviders = data?.terraformProviders || [];

    if (searchQuery) {
      return fuzzySearch(filteredProviders, searchQuery.trim(), {
        keys: ["description", "id", "labels"],
        scoreThreshold: -1000,
      });
    }

    return filteredProviders;
  }, [data, searchQuery]);

  useTitle(`Providers · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  const isLoading = loading;
  const isPageEmpty = !isLoading && !error && data?.terraformProviders.length === 0;
  const hasNoQueryResults = providers.length === 0 && searchQuery;

  return (
    <>
      <TerraformRegistryViewHeader />
      <PageInfo title="Providers">
        <SearchInput
          placeholder="Search by ID, description and labels..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY_PROVIDERS}
        />
        {hasEntityCreateAccess && (
          <Button onPress={handleOpenCreateDrawer} variant="primary" disabled={isLoading}>
            Create provider
          </Button>
        )}
      </PageInfo>
      <PrivateProviderRegistryTierInfo type="callout" />
      {isLoading && <PageLoading />}

      {!isLoading && isPageEmpty && !hasNoQueryResults && (
        <EmptyState
          title="Create your first Terraform provider"
          icon={EmptystateCodeFileColored}
          caption="You can use Spacelift to host your private Terraform providers."
        >
          <Box gap="medium">
            <DocumentationButton
              to={getDocsUrl("/vendors/terraform/provider-registry")}
              label="Documentation"
            />
            {hasEntityCreateAccess && (
              <Button variant="primary" onPress={handleOpenCreateDrawer}>
                Create provider
              </Button>
            )}
          </Box>
        </EmptyState>
      )}
      {!isLoading && hasNoQueryResults && (
        <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} />
      )}
      {!isLoading && !isPageEmpty && (
        <SortableTable
          ariaLabel="Providers"
          items={providers}
          columns={columns}
          columnOrder={COLUMN_ORDER_PROVIDERS}
          renderItems={(sorted) =>
            sorted.map((item) => (
              <ProviderListItem key={item.id} item={item} onEdit={handleOpenUpdateDrawer} />
            ))
          }
          initialSortBy="revokedAt"
          initialDirection={SearchQueryOrderDirection.Desc}
        />
      )}
    </>
  );
}

export default Providers;
