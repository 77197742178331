import useIacManagementStore from "./useIacManagementStore";

const useMakeResourceDetailsUrlPath = (resourceId: string) => {
  const parentStackId = useIacManagementStore((state) => state.parentStackId);

  const resourcePath = parentStackId
    ? `/stack/${parentStackId}/resources/resource/${encodeURIComponent(resourceId)}`
    : `/resources/iac-management/resource/${encodeURIComponent(resourceId)}`;

  return {
    fullLink: `${window.location.origin}${resourcePath}${window.location.search}`,
    path: resourcePath,
    pathWithSearch: `${resourcePath}${window.location.search}`,
  };
};

export default useMakeResourceDetailsUrlPath;
