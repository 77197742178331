import { Space, SpaceAccessLevel } from "types/generated";

export enum SpacesDrawerWriteMode {
  Edit = "edit",
  Create = "create",
}

export enum SpacesLayoutMode {
  List = "List",
  Diagram = "Diagram",
}

export type SpaceWithParentAccessLevel = Space & { parentSpaceAccessLevel?: SpaceAccessLevel };
