import {
  ListBoxItem as AriaListBoxItem,
  ListBoxItemProps as AriaListBoxItemProps,
} from "react-aria-components";
import cx from "classnames";

import styles from "./styles.module.css";

type ListBoxItemProps = AriaListBoxItemProps & {
  highlightSelection?: boolean;
  isSelected?: boolean;
};

const ListBoxItem = ({
  highlightSelection = true,
  isSelected = false,
  ...props
}: ListBoxItemProps) => (
  <AriaListBoxItem
    className={cx(styles.listBoxItem, {
      [styles.highlightSelection]: highlightSelection,
      [styles.selected]: isSelected,
    })}
    {...props}
  />
);

ListBoxItem.displayName = "DS.ListBox.Item";

export default ListBoxItem;
