import Box from "ds/components/Box";

import BlueprintStateBadge from "../../StateBadge";
import { CellComponentProps } from "./types";

type BlueprintsRowStatusCellProps = CellComponentProps;

const BlueprintsRowStatusCell = ({ blueprint }: BlueprintsRowStatusCellProps) => {
  return (
    <Box fullWidth>
      <BlueprintStateBadge state={blueprint.state} />
    </Box>
  );
};

export default BlueprintsRowStatusCell;
