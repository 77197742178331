import { useParams } from "react-router-dom";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useTitle from "hooks/useTitle";
import WorkerPoolUsedByView from "views/Account/WorkerPool/UsedByView";
import PageLoading from "components/loading/PageLoading";

import useSearchPrivateWorkerPoolUsedBy from "./useSearchPrivateWorkerPoolUsedBy";
import PrivateWorkerPoolHeader from "../Header";
import useSearchPrivateWorkerPoolUsedBySuggestions from "./useSearchPrivateWorkerPoolUsedBySuggestions";

const PrivateWorkerPoolUsedBy = () => {
  const { workerPoolId = "" } = useParams<{ workerPoolId: string }>();

  const {
    workerPool,
    entities,
    isPageRefetching,
    error,
    loadMoreItems,
    isPageEmpty,
    hasNoFilteringResults,
    isPageLoading,
  } = useSearchPrivateWorkerPoolUsedBy(workerPoolId);

  const { sortOptions } = useSearchPrivateWorkerPoolUsedBySuggestions({ workerPoolId });

  useTitle(`Used by · ${workerPool?.name || ""}`);

  useBreadcrumbs(
    [
      {
        title: "Worker pools",
        link: "/worker-pools",
      },
      {
        title: workerPool?.name || "",
      },
    ],
    [workerPool]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (!workerPool) {
    return <NotFoundPage />;
  }

  return (
    <>
      <PrivateWorkerPoolHeader
        workerPool={workerPool}
        refetchQuery="SearchPrivateWorkerPoolUsedBy"
      />

      <WorkerPoolUsedByView
        entities={entities}
        loadingIndication={isPageRefetching}
        loadMoreEntities={loadMoreItems}
        isPageEmpty={isPageEmpty}
        hasNoFilteringResults={hasNoFilteringResults}
        sortOptions={sortOptions}
        totalUsersCount={workerPool.usersCount}
        isPublic={false}
      />
    </>
  );
};

export default PrivateWorkerPoolUsedBy;
