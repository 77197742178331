import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import { EmptystateNotificationsColored } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import InfiniteScroll from "components/scroll/InfiniteScroll";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { SearchNotificationsOutput, SearchQueryPredicate } from "types/generated";
import { uniqByKey } from "utils/uniq";

import StackHeader from "../components/Header";
import { StackContext } from "../Context";
import { getStacksBackUrl } from "../helpers";
import { ITEMS_LIMIT } from "./constants";
import { DISMISS_NOTIFICATIONS, SEARCH_NOTIFICATIONS } from "./gql";
import StackNotificationItem from "./Item";
import styles from "./styles.module.css";

const ORDER_BY_OPTION = {
  field: "timestamp",
  direction: "DESC",
};

const StackNotifications = () => {
  const { stack } = useTypedContext(StackContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  useTitle(`Notifications · ${stack.name}`);
  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  const predicates = useMemo((): SearchQueryPredicate => {
    return {
      field: "target",
      exclude: null,
      constraint: {
        booleanEquals: null,
        enumEquals: null,
        hierarchyNodeValueEquals: null,
        timeInRange: null,
        timeInLast: null,
        stringMatches: [`stack/${stack.id}`],
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    error,
    loading,
    data,
    fetchMore: fetchMoreNotifications,
  } = usePolledQuery<{
    searchNotifications: SearchNotificationsOutput;
  }>(SEARCH_NOTIFICATIONS, {
    variables: {
      input: {
        first: ITEMS_LIMIT,
        after: null,
        predicates,
        orderBy: ORDER_BY_OPTION,
      },
    },
    onError,
    pollingGroup: PollingIntervalGroups.Lists,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [dismissNotifications] = useMutation(DISMISS_NOTIFICATIONS, {
    refetchQueries: ["SearchStackNotifications"],
  });

  const hasMore = Boolean(
    data?.searchNotifications.pageInfo.endCursor && data?.searchNotifications.pageInfo.hasNextPage
  );

  const hasNotDismissedNotifications = useMemo(() => {
    return data?.searchNotifications?.edges.some(({ node }) => !node.dismissed);
  }, [data?.searchNotifications?.edges]);

  const loadMoreItems = async () => {
    try {
      if (hasMore) {
        await fetchMoreNotifications({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.searchNotifications.edges.length > 0) {
              return {
                searchNotifications: {
                  ...fetchMoreResult.searchNotifications,
                  edges: uniqByKey(
                    [
                      ...(prev.searchNotifications.edges || []),
                      ...fetchMoreResult.searchNotifications.edges,
                    ],
                    "cursor"
                  ),
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: data?.searchNotifications.pageInfo.endCursor,
              predicates,
              orderBy: ORDER_BY_OPTION,
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleDismissAllNotifications = useCallback(() => {
    const ids = data?.searchNotifications?.edges
      .filter((edge) => !edge.node.dismissed)
      .map(({ node }) => node.id);

    if (ids) {
      dismissNotifications({ variables: { ids } })
        .then(() => reportSuccess({ message: "Notifications successfully dismissed" }))
        .catch(onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.searchNotifications?.edges]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  // Do not show 'loading' when polling in the background.
  if (loading && !data?.searchNotifications) {
    return <PageLoading />;
  }

  if (!data?.searchNotifications) {
    return <NotFoundPage />;
  }

  const notificationsList = data.searchNotifications.edges;
  const hasNotifications = notificationsList.length > 0;

  return (
    <InfiniteScroll onScrollEnd={loadMoreItems} hasMore={hasMore}>
      <StackHeader />
      <PageInfo title="Notifications">
        <Button
          variant="dangerPrimary"
          disabled={!hasNotDismissedNotifications}
          onPress={handleDismissAllNotifications}
        >
          Dismiss all
        </Button>
      </PageInfo>
      <PageWrapper>
        <div className={styles.notificationsList}>
          {notificationsList.map((edge) => (
            <StackNotificationItem key={edge.node.id} item={edge.node} />
          ))}
        </div>
        {!hasNotifications && (
          <EmptyState
            icon={EmptystateNotificationsColored}
            title="No notifications yet"
            caption="You currently don't have any notifications. We'll inform you if any appear."
          />
        )}
      </PageWrapper>
    </InfiniteScroll>
  );
};

export default StackNotifications;
