import DocumentationButton from "components/DocumentationButton";
import TierInfo from "components/TierInfo";
import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";
import { AccountContext } from "views/AccountWrapper";

import styles from "./styles.module.css";

const BlueprintsUpSell = () => {
  const { viewer } = useTypedContext(AccountContext);

  return (
    <Box direction="column" align="center" fullWidth grow="1">
      <TierInfo
        type="callout"
        variant="promo"
        feature={BillingTierFeature.Blueprints}
        title="Upgrade plan to use Blueprints"
      >
        Blueprints are not available in your current plan. Upgrade to{" "}
        <strong>Business or Enterprise</strong> plan to use the feature.
      </TierInfo>

      <Box direction="column" align="center" padding="large" className={styles.upSellWrapper}>
        <Box padding="0 0 large 0">
          <img src="/images/blueprint.png" alt="Blueprint illustration" className={styles.image} />
        </Box>
        <Box direction="column" align="center" padding="large 0 large" gap="small">
          <Typography tag="h1" variant="p-t5">
            Empower Self-Service Infrastructure with Blueprints
          </Typography>
          <Typography tag="p" variant="p-body2" color="secondary">
            Create your first Blueprint draft to test the feature and upgrade your plan to be able
            to use Blueprints and enable your team to create stacks faster!
          </Typography>
        </Box>
        <Box padding="large 0 0 0" align="center" gap="large">
          <DocumentationButton label="Documentation" to={getDocsUrl("/concepts/blueprint")} />
          {viewer.admin && (
            <LinkButton to="/settings/billing" variant="contrast">
              Upgrade
            </LinkButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BlueprintsUpSell;
