import { memo, useEffect, useMemo, useRef, useState } from "react";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledLazyQuery from "apollo/usePolledQuery/usePolledLazyQuery";
import Filters from "components/Filters";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import { useCachedFilterFields } from "components/Filters/hooks";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import { FilterItem, FiltersItemsOptionsMap, SavedFilterView } from "components/Filters/types";
import FlashContext from "components/FlashMessages/FlashContext";
import { getSearchQuery } from "components/SearchInput/helpers";
import { searchAnsibleHostsSuggestionsDictionary } from "constants/ansibleHosts";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";
import useURLParams from "hooks/useURLParams";
import {
  SearchQueryPredicate,
  SearchSuggestionsField,
  SearchSuggestionsFieldType,
} from "types/generated";

import { DEPRECATED_FILTER_NAMES, FILTERS_ORDER_SETTINGS_KEY } from "../constants";
import { SEARCH_ANSIBLE_HOSTS_SUGGESTIONS, SEARCH_ANSIBLE_TASKS_SUGGESTIONS } from "../gql";
import { AnsibleGroupBy } from "../types";
import { getFilterKey } from "../utils";

type ConfigManagementFiltersLayoutProps = {
  predicates: SearchQueryPredicate[];
  children: React.ReactNode;
  groupBy: AnsibleGroupBy;
  hiddenFilters?: string[];
  filtersType: string;
};

const ConfigManagementFiltersLayout = ({
  predicates,
  children,
  groupBy,
  hiddenFilters,
  filtersType,
}: ConfigManagementFiltersLayoutProps) => {
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>();
  const fieldsRef = useRef<string[] | null>(null);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const { onError } = useTypedContext(FlashContext);

  const [
    loadSearchAnsibleHostsSuggestions,
    { data: filteringHostsData, loading: hostsLoading, refetch: hostsRefetch },
  ] = usePolledLazyQuery(SEARCH_ANSIBLE_HOSTS_SUGGESTIONS, {
    pollingGroup: PollingIntervalGroups.Lists,
  });

  const [
    loadSearchAnsibleTasksSuggestions,
    { data: filteringTasksData, loading: tasksLoading, refetch: tasksRefetch },
  ] = usePolledLazyQuery(SEARCH_ANSIBLE_TASKS_SUGGESTIONS, {
    pollingGroup: PollingIntervalGroups.Lists,
  });

  const cachedHostsFiltersData = useCachedFilterFields(
    filteringHostsData?.searchAnsibleHostsSuggestions?.fields as SearchSuggestionsField[]
  );

  const cachedTasksFiltersData = useCachedFilterFields(
    filteringTasksData?.searchAnsibleTasksSuggestions?.fields as SearchSuggestionsField[]
  );

  const cachedFiltersData =
    groupBy === AnsibleGroupBy.Hosts ? cachedHostsFiltersData : cachedTasksFiltersData;

  const [filters, filtersItemsOptionsMap] = useMemo<[FilterItem[], FiltersItemsOptionsMap]>(() => {
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        .filter((field) => field.filterable)
        .map((field) => {
          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key: getFilterKey(field.name),
            filterName: field.name,
            //SearchSuggestionsFieldType is only available if the field is filterable
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const loadSearchSuggestions = (initial = false) => {
    try {
      const variables = {
        input: {
          fullTextSearch: searchInput,
          predicates,
          fields: initial ? null : fieldsRef.current,
        },
      };

      if (groupBy === AnsibleGroupBy.Hosts) {
        loadSearchAnsibleHostsSuggestions({
          variables,
        });
      }

      if (groupBy === AnsibleGroupBy.Tasks) {
        loadSearchAnsibleTasksSuggestions({
          variables,
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const handlePollingActiveSections = (fields: string[]) => {
    fieldsRef.current = fields;
    const input = {
      fullTextSearch: searchInput,
      predicates,
      fields,
    };

    return groupBy === AnsibleGroupBy.Hosts
      ? hostsRefetch({
          input,
        })
      : tasksRefetch({
          input,
        });
  };

  useEffect(() => {
    loadSearchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predicates, searchInput]);

  // initial request with nullish fields
  useEffect(() => {
    loadSearchSuggestions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deprecatedFilterNames = useMemo(
    () =>
      hiddenFilters ? [...DEPRECATED_FILTER_NAMES, ...hiddenFilters] : DEPRECATED_FILTER_NAMES,
    [hiddenFilters]
  );

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={filtersItemsOptionsMap}
      filtersLoading={hostsLoading || tasksLoading}
      // FYI: decided to removing sorting for now as we are migrating to new filter names and it can cause issues when switch host/tasks filtering
      sortOptions={[]}
      initialSortOption={null}
      initialSortDirection={null}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType={filtersType}
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
      deprecatedFilterNames={deprecatedFilterNames}
      filtersDictionary={searchAnsibleHostsSuggestionsDictionary}
    >
      <FiltersSplit>
        <FiltersSidebar analyticsPage={AnalyticsPageStack.Ansible} />
        <FiltersContentWrapper>{children}</FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default memo(ConfigManagementFiltersLayout);
