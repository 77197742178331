import cx from "classnames";
import { mergeRefs } from "@react-aria/utils";
import { formatDistance, format, isThisYear, formatDistanceStrict } from "date-fns/esm";

import TextEllipsis from "ds/components/TextEllipsis";
import Tooltip from "ds/components/Tooltip";
import Typography, { TypographyProps } from "ds/components/Typography";
import { getNowTimeMs, unixTimestampToMs } from "utils/time";
import { TooltipReferenceProps } from "ds/components/Tooltip/types";

type TimestampProps = {
  timestamp: number;
  color?: TypographyProps["color"];
  variant?: TypographyProps["variant"];
  strict?: boolean;
};

const Timestamp = ({ timestamp, color, variant = "p-body3", strict }: TimestampProps) => {
  const timestampMs = unixTimestampToMs(timestamp);
  const shouldShowYear = !isThisYear(timestampMs);
  const finalFormat = shouldShowYear ? "MMM do, yyyy, pp" : "MMM do, pp";
  const now = getNowTimeMs();

  const parsedValue = format(timestampMs, finalFormat);
  const distanceValue = strict
    ? formatDistanceStrict(timestampMs, now, { addSuffix: true })
    : formatDistance(timestampMs, now, { addSuffix: true, includeSeconds: true });

  return (
    <TextEllipsis tooltip={`${parsedValue} (${distanceValue})`}>
      {(parentProps, isActive) => (
        <Tooltip
          active={!isActive}
          on={(props: TooltipReferenceProps) => (
            <Typography
              ref={mergeRefs(props.ref, parentProps.ref)}
              className={cx(props.className, parentProps.className)}
              tag="span"
              variant={variant}
              color={color}
            >
              {parsedValue}
            </Typography>
          )}
        >
          {distanceValue}
        </Tooltip>
      )}
    </TextEllipsis>
  );
};

export default Timestamp;
