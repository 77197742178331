import { ReactNode } from "react";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { DraggableAttributes } from "@dnd-kit/core";

import Box from "ds/components/Box";
import DraggableItemHandle from "ds/components/DraggableItem/HandleNew";
import Typography from "ds/components/Typography";
import TooltipInfo from "ds/components/TooltipInfo";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type DragDropListItemProps = {
  text: string;
  children: ReactNode;
  onHide?: () => void;
  moreActions?: Array<{ title: string; link: string } & AnalyticsCommonProps>;
  infoTooltip?: string;
  dragging?: boolean;
  handleListeners?: SyntheticListenerMap;
  handleAttributes?: DraggableAttributes;
  handleRef?: React.ForwardedRef<HTMLDivElement>;
};

const DragDropListItem = ({
  text,
  children,
  onHide,
  moreActions,
  infoTooltip,
  dragging,
  handleRef,
  handleListeners,
  handleAttributes,
}: DragDropListItemProps) => {
  return (
    <Box justify="between" fullWidth direction="column">
      <Box align="center">
        <DraggableItemHandle
          dragging={dragging}
          padding="small medium"
          variant="p-t6"
          ref={handleRef}
          handleListeners={handleListeners}
          handleAttributes={handleAttributes}
        />
        <Box justify="between" align="center" grow="1">
          <Box align="center" gap="large">
            <Typography tag="span" variant="p-t5">
              {text}
            </Typography>
            {infoTooltip && <TooltipInfo>{infoTooltip}</TooltipInfo>}
          </Box>
          <DropdownMenuEllipsis tooltip="Settings" buttonVariant="ghost">
            <DropdownMenuItem onAction={onHide}>Hide</DropdownMenuItem>
            {moreActions?.map(
              ({ link, title, analyticsPage, analyticsTitle, analyticsProps }, i) => (
                <DropdownMenuItem
                  analyticsPage={analyticsPage}
                  analyticsTitle={analyticsTitle}
                  analyticsProps={analyticsProps}
                  key={i}
                  href={link}
                >
                  {title}
                </DropdownMenuItem>
              )
            )}
          </DropdownMenuEllipsis>
        </Box>
      </Box>

      <Box padding="small large large large" fullWidth>
        {children}
      </Box>
    </Box>
  );
};

export default DragDropListItem;
