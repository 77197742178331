import { ReactNode, useState } from "react";

import Link from "ds/components/Link";

import FormField, { FormFieldProps } from ".";

type FormFieldHiddenProps = {
  hiddenPlaceholder: string;
  onHiddenToggle?: (isHidden: boolean) => void;
  children: (isHidden: boolean) => ReactNode;
} & Omit<FormFieldProps, "children">;

const FormFieldHidden = ({
  hiddenPlaceholder,
  onHiddenToggle,
  noMargin,
  label,
  ...restProps
}: FormFieldHiddenProps) => {
  const [isFieldHidden, setFieldHidden] = useState(true);

  const handleShow = () => {
    setFieldHidden(false);
    onHiddenToggle?.(false);
  };

  const handleHide = () => {
    setFieldHidden(true);
    onHiddenToggle?.(true);
  };

  return isFieldHidden ? (
    <FormField noMargin={noMargin}>
      <Link size="medium" onPress={handleShow}>
        {hiddenPlaceholder}
      </Link>
    </FormField>
  ) : (
    <FormField
      label={
        <>
          {label}&nbsp;&nbsp;&nbsp;
          <Link size="small" onPress={handleHide}>
            hide the field
          </Link>
        </>
      }
      noMargin={noMargin}
      {...restProps}
    >
      {restProps.children(isFieldHidden)}
    </FormField>
  );
};

FormFieldHidden.displayName = "DS.Form.Field.Hidden";

export default FormFieldHidden;
