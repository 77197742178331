import { useState } from "react";

import { ManagedUserGroup } from "types/generated";
import LoadingIndicator from "ds/components/LoadingIndicator";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import Box from "ds/components/Box";
import DrawerForm from "ds/components/DrawerNew/Form";

import { UserManagementActivationStatus } from "../../types";
import useGetGroup from "./useGetGroup";
import IdpGroupMappingDetailsDrawerContent from "./Content";
import { UPDATE_GROUP_DRAWER_TEST_ID } from "./constants";

type IdpGroupMappingDetailsDrawerProps = {
  currentGroup?: ManagedUserGroup;
  activationStatus: UserManagementActivationStatus;
};

const IdpGroupMappingDetailsDrawer = createDrawer(
  ({ currentGroup, activationStatus }: IdpGroupMappingDetailsDrawerProps) => {
    const [isDirty, setIsDirty] = useState(false);
    const { group, loading, error } = useGetGroup({
      id: currentGroup?.id,
    });

    if (error) {
      return null;
    }

    return (
      <DrawerForm isDirty={isDirty} dataTestId={UPDATE_GROUP_DRAWER_TEST_ID}>
        {loading && (
          <Box grow="1" justify="center" align="center">
            <LoadingIndicator size="x-large" loading />
          </Box>
        )}
        {group && !loading && (
          <IdpGroupMappingDetailsDrawerContent
            setIsDirty={setIsDirty}
            group={group}
            activationStatus={activationStatus}
          />
        )}
      </DrawerForm>
    );
  }
);

export const showIdpGroupMappingDetailsDrawer = createDrawerTrigger(IdpGroupMappingDetailsDrawer);
