import { type ReactNode } from "react";

import { BulkActionsApiContext } from "components/BulkActions/Context";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import { IconComponent } from "types/Icon";

import { BulkActionsVariant } from "../../types";
import { BUTTON_PROPS_BY_VARIANT } from "../helpers";

export type BulkActionsChooseActionStepActionItemProps<Action extends string> = {
  action: Action;
  variant: BulkActionsVariant;
  tooltip?: ReactNode;
  showTooltip?: boolean;
  startIcon?: IconComponent;
  children: string;
  onClick: () => void;
  applicableCount: number;
  notApplicableCount: number;
  analyticsPage: AnalyticsPage;
};

const BulkActionsChooseActionStepActionItem = <Action extends string>({
  children,
  action,
  variant,
  onClick,
  tooltip,
  showTooltip,
  startIcon,
  applicableCount,
  notApplicableCount,
  analyticsPage,
}: BulkActionsChooseActionStepActionItemProps<Action>) => {
  const { onSelectAction } = useTypedContext(BulkActionsApiContext);
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });
  const handleOnClick = () => {
    onClick();
    onSelectAction();
    trackSegmentAnalyticsEvent("Bulk Actions - Action Clicked", {
      applicableCount,
      notApplicableCount,
      action,
      view: variant,
      variant: "button",
    });
  };

  return (
    <Tooltip
      textAlign="center"
      active={showTooltip}
      on={(tooltipProps) => (
        <Button
          onPress={handleOnClick}
          startIcon={startIcon}
          {...tooltipProps}
          {...BUTTON_PROPS_BY_VARIANT[variant]}
        >
          {children}
        </Button>
      )}
    >
      {tooltip}
    </Tooltip>
  );
};

export default BulkActionsChooseActionStepActionItem;
