import { ApolloError, gql } from "@apollo/client";
import { createContext, ReactNode, useCallback, useMemo, useState } from "react";

import usePolledQuery from "apollo/usePolledQuery";
import FlashContext from "components/FlashMessages/FlashContext";
import SkeletonLoader from "components/loading/SkeletonLoader";
import { SelectOption } from "ds/components/Select/types";
import useTypedContext from "hooks/useTypedContext";
import { Space, SpaceAccessLevel } from "types/generated";
import { generateSpaceHierarchyMap, getFullSpaceNamePath, isRootSpace } from "utils/space";
import { hasSpaceManageAccess } from "utils/user";
import { AccountContext } from "views/AccountWrapper";

import { PollingIntervalGroups } from "../../apollo/constants";

export type SpacesContextType = {
  spaces: Space[];
  spaceHierarchy: Record<string, string>;
  hasManageableSpaces: boolean;
  hasEntityCreateAccess: boolean;
  manageableSpaces: Space[];
  manageableSpacesSelectOptions: SelectOption[];
  error?: ApolloError;
  startSpacesPolling: () => void;
  stopSpacesPolling: () => void;
  hasRootSpaceAccess: boolean;
  canNonRootAdminCreateSpace: boolean;
};

export const GET_SPACES = gql`
  query GetSpaces {
    spaces {
      id
      name
      description
      parentSpace
      inheritEntities
      accessLevel
      labels
    }
    allowNonRootAdminSpaceCreation
  }
`;

export const SpacesContext = createContext<SpacesContextType | undefined>(undefined);
SpacesContext.displayName = "SpacesContext";

type SpacesProviderProps = {
  children: ReactNode;
};

const SpacesProvider = ({ children }: SpacesProviderProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);
  const [shouldPoll, setShouldPoll] = useState(false);

  const { data, error } = usePolledQuery<{
    spaces: Space[];
    allowNonRootAdminSpaceCreation: boolean;
  }>(GET_SPACES, {
    shouldPoll: () => shouldPoll,
    pollingGroup: PollingIntervalGroups.Lists,
    onError,
  });

  const startSpacesPolling = useCallback(() => {
    setShouldPoll(true);
  }, []);

  const stopSpacesPolling = useCallback(() => {
    setShouldPoll(false);
  }, []);

  const spaces = data?.spaces;

  const manageableSpaces = useMemo(() => {
    if (spaces) {
      return spaces.filter((space) => hasSpaceManageAccess(space.accessLevel));
    }

    return [];
  }, [spaces]);

  const hasRootSpaceAdminLevel = useMemo(() => {
    return !!manageableSpaces.find(
      (space) => isRootSpace(space.id) && space.accessLevel === SpaceAccessLevel.Admin
    );
  }, [manageableSpaces]);

  const spaceHierarchy = useMemo(() => generateSpaceHierarchyMap(spaces), [spaces]);

  const hasManageableSpaces = manageableSpaces.length > 0;

  const manageableSpacesSelectOptions = useMemo(
    () =>
      manageableSpaces.map((item) => ({
        value: item.id,
        // TODO: Remove this once backend returns full space path
        label: getFullSpaceNamePath(item.id, spaceHierarchy, spaces ?? []),
      })),
    [spaces, manageableSpaces, spaceHierarchy]
  );

  const isLoading = !data;

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <SpacesContext.Provider
      value={{
        spaces: data.spaces,
        manageableSpaces,
        manageableSpacesSelectOptions,
        hasManageableSpaces,
        spaceHierarchy,
        // FYI: use for creating a new entity only, not for editing - in editing mode, first of all, should be verified an item space access level
        hasEntityCreateAccess: viewer.admin || hasRootSpaceAdminLevel || hasManageableSpaces,
        hasRootSpaceAccess: viewer.admin || hasRootSpaceAdminLevel,
        canNonRootAdminCreateSpace: !!data?.allowNonRootAdminSpaceCreation,
        startSpacesPolling,
        stopSpacesPolling,
        error,
      }}
    >
      {children}
    </SpacesContext.Provider>
  );
};

export default SpacesProvider;
