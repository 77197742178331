import cx from "classnames";
import { forwardRef } from "react";

import BaseAction, { BaseActionProps } from "ds/components/BaseAction";
import styles from "ds/components/Button/styles.module.css";
import Icon, { IconProps } from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import { IconComponent } from "types/Icon";

export type LinkButtonProps = BaseActionProps & {
  variant:
    | "primary"
    | "secondary"
    | "contrast"
    | "dangerPrimary"
    | "dangerSecondary"
    | "ghost"
    | "ghostInversed"
    | "ghostDanger"
    | "outlineOnDark";
  size?: "small" | "medium";
  startIcon?: IconComponent;
  startIconRotate?: IconProps["rotate"];
  endIcon?: IconComponent;
  endIconRotate?: IconProps["rotate"];
  iconColor?: IconProps["color"];
  active?: boolean;
  disabled?: boolean;
  withTextEllipsis?: boolean;
  children: string;
} & AnalyticsCommonProps;

const LinkButton = forwardRef(function Button(
  props: LinkButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    size = "medium",
    variant,
    children,
    className,
    startIcon,
    startIconRotate,
    endIcon,
    endIconRotate,
    active,
    withTextEllipsis,
    iconColor,
    ...restProps
  } = props;

  return (
    <BaseAction
      ref={ref}
      className={cx(
        styles.button,
        styles[size],
        styles[variant],
        {
          [styles.active]: active,
          [styles.disabled]: restProps.disabled,
          [styles.textEllipsis]: withTextEllipsis,
        },
        className
      )}
      {...restProps}
    >
      {startIcon && (
        <Icon color={iconColor} noShrink src={startIcon} aria-hidden rotate={startIconRotate} />
      )}
      {withTextEllipsis ? (
        <TextEllipsis tooltip={children} tooltipWidthMode="maxWidthSm" tooltipPlacement="bottom">
          {(props) => <span {...props}>{children}</span>}
        </TextEllipsis>
      ) : (
        children
      )}
      {endIcon && (
        <Icon color={iconColor} noShrink src={endIcon} aria-hidden rotate={endIconRotate} />
      )}
    </BaseAction>
  );
});

LinkButton.displayName = "DS.LinkButton";

export default LinkButton;
