import React, { ReactNode } from "react";
import { matchPath } from "react-router-dom";
import { createRoot } from "react-dom/client";
import Bugsnag, { Event } from "@bugsnag/js";
import BugsnagPluginReact, { BugsnagPluginReactResult } from "@bugsnag/plugin-react";
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import userflow from "userflow.js";

import { initHubspot, initGtm, initPylon } from "shared/Analytics";
import setLocation from "shared/setLocation";
import { initStatuspage } from "shared/ThirdPartyScripts";
import { addNonceToInlineScripts } from "utils/csp";
import { isSelfHostedDistribution } from "utils/distribution";
import { initBeamerTooltipPositionHack } from "shared/BeamerTooltip";
import { initApolloClient } from "apollo/client";
import { AppEnvironments } from "constants/app_environments";

import ErrorBoundaryFallback from "./ErrorBoundaryFallback";
import App from "./App";

import "react-loading-skeleton/dist/skeleton.css";
import "./ds/styles/fonts.css";
import "./ds/styles/variables.css";
import "./ds/styles/tokens.css";
import "./ds/styles/globals.css";
import "reactflow/dist/style.css";

const APP_VERSION = process.env.REACT_APP_REVISION_SHA1;
const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY || "";
const GTM_ID = process.env.REACT_APP_GTM_ID || "";
const HUBSPOT_HUB_ID = process.env.REACT_APP_HUBSPOT_HUB_ID;
const RUM_APP_ID = process.env.REACT_APP_RUM_APP_ID;
const RUM_ENV = process.env.REACT_APP_RUM_ENV || AppEnvironments.Development;
const RUM_CLIENT_TOKEN = process.env.REACT_APP_RUM_CLIENT_TOKEN;
const RUM_SERVICE = process.env.REACT_APP_RUM_SERVICE || "spacelift.tf";
const RUM_SITE = (process.env.REACT_APP_RUM_SITE || "datadoghq.eu") as RumInitConfiguration["site"];
const USERFLOW_TOKEN = process.env.REACT_APP_USERFLOW_TOKEN;
const PYLON_APP_ID = process.env.REACT_APP_PYLON_APP_ID;
const nonceHash = process.env.REACT_APP_MANUAL_INLINE_JS_NONCE_HASH || undefined;
const isSelfHosted = isSelfHostedDistribution();

(function initApp() {
  /**
   * Remove double slashes from the URL
   * This is a workaround for a react-router bug
   * https://github.com/remix-run/react-router/issues/11911
   */
  const { pathname, search, hash } = window.location;
  if (pathname.includes("//")) {
    setLocation(pathname.replace(/\/{2,}/g, "/") + search + hash);
    return;
  }

  addNonceToInlineScripts(nonceHash);
  initGtm(GTM_ID);
  initHubspot(HUBSPOT_HUB_ID);
  initPylon(PYLON_APP_ID);
  initStatuspage(isSelfHosted);
  initBeamerTooltipPositionHack();

  if (RUM_APP_ID) {
    datadogRum.init({
      applicationId: RUM_APP_ID,
      clientToken: RUM_CLIENT_TOKEN as string,
      site: RUM_SITE,
      env: RUM_ENV,
      service: RUM_SERVICE,
      version: APP_VERSION,
      defaultPrivacyLevel: "mask-user-input",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      allowedTracingUrls: [
        new RegExp(`http[s]?://app.${RUM_SERVICE}`),
        new RegExp(`http[s]?://[\\w-]+.app.${RUM_SERVICE}`),
      ],
      enableExperimentalFeatures: ["clickmap", "feature_flags"],
      startSessionReplayRecordingManually: true,
    });
  }

  if (USERFLOW_TOKEN) {
    userflow.init(USERFLOW_TOKEN);
    userflow.setResourceCenterLauncherHidden(true);

    // It limits events for automatic detection of url change for navigation between runs using keyboard
    userflow.setUrlFilter((url) => {
      const isRun = matchPath("/stack/:id/run/:runId", location.pathname);

      if (isRun && "id" in isRun.params) {
        return `${location.origin}/stack/${isRun.params.id}/run/?`;
      }

      return url;
    });
  }

  const onError = (event: Event) => {
    const ignoredErrorMessages = [
      "Failed to fetch",
      "unauthorized",
      "Failed to load Stripe.js",
      "Could not load Userflow.js",
    ];
    // All these are to get ride of 3rd party errors we receiving a lot, and that are just noise.
    // Instead of error message that can be useful in other places we use stacktrace file name.
    // The assumption is as follow, if the last stacktrace file name includes some 3rd party file name
    // we should skip it, because it means that error is coming from 3rd script itself not our app.
    const ignoredStacktracePaths = ["gtm.js", ".lfeeder.com", "webkit-masked-url://hidden/"];
    const ignoredErrorClasses = ["NetworkError"];

    const isMonacoCanceledError =
      event.errors[0].errorMessage === "Canceled" &&
      event.errors[0].errorClass === "Canceled" &&
      String(event.errors[0].stacktrace[0]?.file).toLowerCase().includes("monaco");

    const isLaunchDarklyNetworkError =
      String(event.errors[0].errorMessage).toLowerCase().includes("network error") &&
      event.errors[0].errorClass === "LaunchDarklyFlagFetchError";

    const isIgnoredError =
      isMonacoCanceledError ||
      isLaunchDarklyNetworkError ||
      (event.errors[0].errorMessage.includes("Illegal invocation") && event.context === "/login") ||
      ignoredErrorClasses.some((item) => event.errors[0].errorClass === item) ||
      ignoredErrorMessages.some((item) => event.errors[0].errorMessage.includes(item)) ||
      ignoredStacktracePaths.some((item) => event.errors[0].stacktrace[0]?.file.includes(item));

    if (isIgnoredError) return false;

    return undefined;
  };

  const container = document.getElementById("root");
  const root = createRoot(container!);

  if (BUGSNAG_API_KEY) {
    const bugsnagClient = Bugsnag.start({
      apiKey: BUGSNAG_API_KEY,
      appVersion: APP_VERSION,
      plugins: [new BugsnagPluginReact(React)],
      releaseStage: process.env.NODE_ENV,
      metadata: {
        dataDog: {
          env: RUM_ENV,
          dataDogSessionId: datadogRum.getInternalContext()?.session_id,
          dataDogSessionReplayLink: datadogRum.getSessionReplayLink(),
        },
      },
      onError,
    });

    const plugin = bugsnagClient.getPlugin("react") as BugsnagPluginReactResult;
    const errorCallback = (error: string) => bugsnagClient.notify(error);
    const BugsnagErrorBoundary = plugin.createErrorBoundary();
    const client = initApolloClient(errorCallback);

    const ErrorBoundary = ({ children }: { children: ReactNode }) => {
      return (
        <BugsnagErrorBoundary onError={onError} FallbackComponent={ErrorBoundaryFallback}>
          {children}
        </BugsnagErrorBoundary>
      );
    };

    root.render(
      <ErrorBoundary>
        <App client={client} ErrorBoundary={ErrorBoundary} />
      </ErrorBoundary>
    );
  } else {
    const client = initApolloClient();
    root.render(<App client={client} />);
  }
})();
