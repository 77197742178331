import TableCell, { TableCellProps } from "ds/components/Table/Row/Cell";
import { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

export type IacManagementTableCellProps = {
  withAction?: boolean;
} & Pick<TableCellProps, "children" | "role" | "stickyRight" | "stickyLeft"> &
  BoxProps;

const IacManagementTableCell = ({
  children,
  role,
  stickyRight,
  stickyLeft,
  ...boxProps
}: IacManagementTableCellProps) => {
  return (
    <TableCell
      className={styles.cell}
      role={role}
      stickyLeft={stickyLeft}
      stickyRight={stickyRight}
      {...boxProps}
    >
      {children}
    </TableCell>
  );
};

export default IacManagementTableCell;
