import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { USED_BY_COLUMN_ID } from "../constants";
import UsedByNameCell from "./NameCell";
import UsedByTypeCell from "./TypeCell";
import { AttachedStack } from "../types";

const CELLS = {
  [USED_BY_COLUMN_ID.TYPE]: UsedByTypeCell,
  [USED_BY_COLUMN_ID.NAME]: UsedByNameCell,
};

type UsedByTableRowProps = {
  stack: AttachedStack;
};

const UsedByTableRow = ({ stack }: UsedByTableRowProps) => {
  const { visibleColumnIds } = useTypedContext(TableContext);

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as USED_BY_COLUMN_ID];
        const uniqueKey = `${id}-${stack.id}`;

        return (
          <TableCell key={uniqueKey} id={id}>
            <Component item={stack} />
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(UsedByTableRow);
