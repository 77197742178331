import Box from "ds/components/Box";
import ListEntitiesItemDescriptionMultipleLines from "components/ListEntitiesItem/Description/MultipleLines";

import { CellComponentProps } from "./types";
import useIntegrationsListAnalytics from "../../useIntegrationsPageAnalytics";

type IntegrationsListItemDescriptionCellProps = CellComponentProps;

const IntegrationsListItemDescriptionCell = ({
  integration,
}: IntegrationsListItemDescriptionCellProps) => {
  const trackAnalytics = useIntegrationsListAnalytics();

  const handleShowFullDescription = () => {
    trackAnalytics("Show full description clicked");
  };

  if (!integration.description) {
    return null;
  }

  return (
    <Box direction="row" align="start">
      <ListEntitiesItemDescriptionMultipleLines
        lines={1}
        hasChevronLink
        description={integration.description}
        onShowFullDescription={handleShowFullDescription}
      />
    </Box>
  );
};

export default IntegrationsListItemDescriptionCell;
