import { CSSProperties, useCallback, useRef } from "react";
import { Cell, Row } from "react-aria-components";

import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageInfo from "components/PageWrapper/Info";
import SearchInput from "components/SearchInput";
import Table from "components/Table";
import TableContextProvider from "components/Table/Context";
import TableRow from "components/Table/Row";
import TableWrapper from "components/Table/Wrapper";
import useTableSort from "components/Table/useTableSort";
import Button from "ds/components/Button";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";
import { AccountContext } from "views/AccountWrapper";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";

import { showAssignRoleDrawer } from "../AssignRoleDrawer";
import RolesListItem from "./ListItem";
import {
  ANALYTICS,
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_COLUMNS_VIEW_CONFIG,
  initialSortDirection,
  initialSortOption,
  ROW_HEADER_ID,
  SORT_CONFIG,
  USER_ROLES_COLUMN_CONFIG,
  USER_ROLES_COLUMNS_STORAGE_KEY,
  USER_ROLES_CUSTOM_END_COLUMN_CONFIG,
} from "./constants";
import { RoleBinding } from "./types";
import { useFilteredEntities } from "./useFilteredEntities";
import UserRolesEmpty from "./Empty";

type UserRolesProps = {
  roleBindings: RoleBinding[];
  userName: string;
  userId: string;
};

const UserRoles = ({ roleBindings, userName, userId }: UserRolesProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const { handleSortOptionChange, sortDescriptor } = useTableSort({
    filtersOrderSettingsKey: FILTERS_ORDER_SETTINGS_KEY,
    sortConfig: SORT_CONFIG,
    initialSortOption,
    initialSortDirection,
  });

  const filteredRoles = useFilteredEntities(roleBindings, sortDescriptor);

  const isPageEmpty = filteredRoles.length === 0;

  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Organization Settings · User · ${accountName}`);

  useBreadcrumbs(
    [
      {
        title: "Organization Settings Users",
        link: getBreadcrumbsBackUrl("/settings/users", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: userName,
      },
    ],
    []
  );

  const renderRow = useCallback(
    (
      item: Partial<RoleBinding> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.id}
          >
            <RolesListItem roleBinding={item as RoleBinding} />
          </TableRow>
        );
      }
    },
    []
  );

  return (
    <>
      <PageInfo title="Roles">
        <SearchInput placeholder="Search..." filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY} />

        {hasEntityCreateAccess && (
          <Button variant="primary" onPress={() => showAssignRoleDrawer({ userId })}>
            Manage roles
          </Button>
        )}
      </PageInfo>
      <TableContextProvider
        columnsConfig={USER_ROLES_COLUMN_CONFIG}
        endCustomColumns={USER_ROLES_CUSTOM_END_COLUMN_CONFIG}
        initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
        rowHeaderId={ROW_HEADER_ID}
        localStorageId={USER_ROLES_COLUMNS_STORAGE_KEY}
        setSortDescriptor={handleSortOptionChange}
        sortDescriptor={sortDescriptor}
        {...ANALYTICS}
      >
        {!!filteredRoles.length && (
          <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
            <Table ariaLabel="Roles" items={filteredRoles} ref={virtualizedListContainerRef}>
              {renderRow}
            </Table>
          </TableWrapper>
        )}
        {!roleBindings?.length && <UserRolesEmpty />}
        {isPageEmpty && !!roleBindings?.length && (
          <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
        )}
      </TableContextProvider>
    </>
  );
};

export default UserRoles;
