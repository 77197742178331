import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import { AnalyticsPageBilling } from "hooks/useAnalytics/pages/billing";
import useTypedContext from "hooks/useTypedContext";
import { BillingTier } from "types/generated";

import BillingFeatureItem from "../components/FeatureItem";
import { BillingContext } from "../Context";
import { PLAN_FEATURES_BY_TIER } from "./constants";

const BillingPlanDetailsTile = () => {
  const { tier, isTrial } = useTypedContext(BillingContext);

  const features = PLAN_FEATURES_BY_TIER[isTrial ? BillingTier.V4Enterprise : tier];

  if (!features) {
    return null;
  }

  return (
    <TileWrapper>
      <TileContent gap="x-large">
        <Box justify="between" align="center" gap="medium">
          <TileTitle variant="p-t5">Included in your plan</TileTitle>
          <LinkButton
            href="https://spacelift.io/pricing"
            target="_blank"
            variant="secondary"
            size="small"
            analyticsPage={AnalyticsPageBilling.BillingDashboard}
            analyticsTitle="Plan Details Button Clicked"
          >
            Plan details
          </LinkButton>
        </Box>
        <Box grid gridTemplate="1fr 1fr" gap="medium">
          {features.map(({ text, negative, tooltip }, index) => (
            <BillingFeatureItem key={index} text={text} infoTooltip={tooltip} negative={negative} />
          ))}
        </Box>
      </TileContent>
    </TileWrapper>
  );
};

export default BillingPlanDetailsTile;
