import { ReactNode } from "react";

import { Cross } from "components/icons/generated";

import Box from "../Box";
import ButtonIcon from "../ButtonIcon";
import Typography from "../Typography";
import styles from "./styles.module.css";

type FullScreenModalHeaderProps = {
  children: ReactNode;
  onClose: () => void;
};

const FullScreenModalHeader = ({ children, onClose }: FullScreenModalHeaderProps) => {
  return (
    <Box direction="row" justify="between" padding="large" align="center" className={styles.header}>
      <Typography variant="p-t4" tag="h1">
        {children}
      </Typography>
      <ButtonIcon icon={Cross} onPress={onClose} variant="ghost">
        Close
      </ButtonIcon>
    </Box>
  );
};

FullScreenModalHeader.displayName = "DS.FullScreenModal.Header";

export default FullScreenModalHeader;
