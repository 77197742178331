import Link from "ds/components/Link";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageAuditTrail } from "hooks/useAnalytics/pages/auditTrail";
import { AuditActor } from "types/generated";

import { AuditTrailEntrySuggestions } from "../../constants";

type AuditTrailListItemCreatedByProps = {
  value: AuditActor;
  applyFilter: (filterName: AuditTrailEntrySuggestions) => (value: string) => void;
};

const AuditTrailListItemCreatedBy = ({ value, applyFilter }: AuditTrailListItemCreatedByProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageAuditTrail.Logs,
  });

  const onLinkClicked = () => {
    trackSegmentAnalyticsEvent("Link clicked", {
      column: "Created by",
    });
  };

  return (
    <>
      {value.run === null && (
        <Link
          onPress={() => {
            applyFilter(AuditTrailEntrySuggestions.Username)(value.username);
            onLinkClicked();
          }}
        >
          {value.username}
        </Link>
      )}

      {value.run && (
        <Link href={`/stack/${value.run.stackId}/run/${value.run.id}`} onPress={onLinkClicked}>
          Run: {value.run.id}
        </Link>
      )}
    </>
  );
};

export default AuditTrailListItemCreatedBy;
