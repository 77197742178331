import { useFormContext } from "react-hook-form";

import Banner from "ds/components/Banner";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import useGetCloudIntegrationExternalId from "shared/CloudIntegration/useGetCloudIntegrationExternalId";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { AttachCloudEntityType } from "./types";

type AttachCloudAwsExternalIdBannerProps = {
  entityId: string;
  entityType: AttachCloudEntityType;
};

const AttachCloudAwsExternalIdBanner = ({
  entityId,
  entityType,
}: AttachCloudAwsExternalIdBannerProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);
  const { watch } = useFormContext<{ read: boolean; write: boolean; integrationId: string }>();
  const [read, write, integrationId] = watch(["read", "write", "integrationId"]);
  const { fetchExternalId, loading } = useGetCloudIntegrationExternalId();

  const handleCopyExternalId = () => {
    if (!integrationId) {
      return;
    }

    fetchExternalId({
      variables: {
        entityId,
        integrationId,
        read,
        write,
      },
    }).then(async ({ data }) => {
      if (data?.awsIntegration?.externalIdForStack?.externalId) {
        await navigator.clipboard.writeText(data.awsIntegration.externalIdForStack.externalId);
        reportSuccess({ message: "External ID copied to clipboard" });
      }
    });
  };

  if (!window.isSecureContext || !integrationId || (!read && !write)) {
    return null;
  }

  return (
    <Banner variant="info" title={`Assume role by a single ${entityType}`}>
      If you would like the integration to be used only by this stack copy its external ID and
      replace it in Trust relationship section of your Role.
      <FeedbackActions>
        <Button variant="contrast" size="small" onPress={handleCopyExternalId} loading={loading}>
          Copy External ID
        </Button>
        <DocumentationButton
          to={getDocsUrl(
            "/integrations/cloud-providers/aws#optionally-configure-further-constraints-on-the-trust-policy"
          )}
          size="small"
          label="Documentation"
        />
      </FeedbackActions>
    </Banner>
  );
};

export default AttachCloudAwsExternalIdBanner;
