import { useCallback, useMemo } from "react";
import { Route, Routes } from "react-router-dom";

import Button from "ds/components/Button";
import PageInfoHeader from "ds/components/PageInfoHeader";
import useAnalytics from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import { VersionState } from "types/generated";

import ModuleVersionChangelog from "../Changelog";
import { showModuleVersionInstructionsDrawer } from "../components/InstructionsDrawer";
import ModuleVersionSidebarLayout from "../components/SidebarLayout";
import { VersionContext } from "../Context";
import ModuleVersionDependencies from "../Dependencies";
import ModuleVersionInputs from "../Inputs";
import ModuleVersionOutputs from "../Outputs";
import ModuleVersionReadme from "../Readme";
import ModuleVersionResources from "../Resources";
import ModuleVersionConsumers from "./Consumers";
import ModuleVersionTestCases from "./TestCases";

const ModuleVersionOverview = () => {
  const { version, module } = useTypedContext(VersionContext);

  const trackSegmentAnalyticsEvent = useAnalytics();

  const inputs = useMemo(
    () => version.metadata?.root?.inputs || [],
    [version.metadata?.root?.inputs]
  );

  const outputs = useMemo(
    () => version.metadata?.root?.outputs || [],
    [version.metadata?.root?.outputs]
  );

  const moduleDependencies = useMemo(
    () => version.metadata?.root?.dependencies || [],
    [version.metadata?.root?.dependencies]
  );

  const providerDependencies = useMemo(
    () => version.metadata?.root?.providerDependencies || [],
    [version.metadata?.root?.providerDependencies]
  );

  const resources = useMemo(
    () => version.metadata?.root?.resources || [],
    [version.metadata?.root?.resources]
  );

  const sidebar = useMemo(
    () => [
      {
        to: "..",
        label: "Readme",
        end: true,
      },
      {
        to: "../changelog",
        label: "Changelog",
      },
      {
        to: "../inputs",
        label: "Inputs",
        count: inputs.length,
      },
      {
        to: "../outputs",
        label: "Outputs",
        count: outputs.length,
      },
      {
        to: "../dependencies",
        label: "Dependencies",
        count: moduleDependencies.length + providerDependencies.length,
      },
      {
        to: "../resources",
        label: "Resources",
        count: resources.length || 0,
      },
      {
        to: "../consumers",
        label: "Consumers",
        count: version?.consumerCount || 0,
      },
      {
        to: "../tests",
        label: "Test cases",
        count: version?.runs.length || 0,
      },
    ],
    [
      inputs.length,
      outputs.length,
      moduleDependencies.length,
      providerDependencies.length,
      resources.length,
      version?.consumerCount,
      version?.runs.length,
    ]
  );

  const handleOpenInstructions = useCallback(() => {
    if (
      !version.yanked &&
      version.state === VersionState.Active &&
      version?.metadata?.root.inputs
    ) {
      showModuleVersionInstructionsDrawer({
        moduleName: module.name,
        inputs: version.metadata.root.inputs,
        versionNumber: version.number,
        type: "module",
        workflowTool: module.workflowTool,
        moduleSource: module.moduleSource,
      });
      trackSegmentAnalyticsEvent("Module Registry - Instructions Clicked");
    }
  }, [trackSegmentAnalyticsEvent, module, version]);

  return (
    <>
      <PageInfoHeader title="Version overview">
        {!version.yanked &&
          version.state === VersionState.Active &&
          version?.metadata?.root.inputs && (
            <Button variant="secondary" onPress={handleOpenInstructions}>
              Instructions
            </Button>
          )}
      </PageInfoHeader>

      <ModuleVersionSidebarLayout sidebar={sidebar}>
        <Routes>
          <Route index element={<ModuleVersionReadme content={version.metadata?.root?.readme} />} />
          <Route
            path="changelog"
            element={<ModuleVersionChangelog content={version.metadata?.root.changelog} />}
          />
          <Route path="inputs" element={<ModuleVersionInputs inputs={inputs} />} />
          <Route path="outputs" element={<ModuleVersionOutputs outputs={outputs} />} />
          <Route path="resources" element={<ModuleVersionResources resources={resources} />} />
          <Route
            path="dependencies"
            element={
              <ModuleVersionDependencies
                providerDependencies={providerDependencies}
                moduleDependencies={moduleDependencies}
              />
            }
          />
          <Route path="tests" element={<ModuleVersionTestCases />} />
          <Route path="consumers" element={<ModuleVersionConsumers />} />
        </Routes>
      </ModuleVersionSidebarLayout>
    </>
  );
};

export default ModuleVersionOverview;
