import { BulkActionsApiContext } from "components/BulkActions/Context";
import {
  BulkActionsCloseMethod,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import { File } from "components/icons/generated";
import Box from "ds/components/Box";
import LinkButtonIcon from "ds/components/ButtonIcon/LinkButtonIcon";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import { AnalyticsPage } from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import { getDocsUrl } from "utils/getDocsUrl";

type BulkActionsDrawerResultsStepHeaderProps = {
  title: string;
  analyticsPage: AnalyticsPage;
};

const BulkActionsDrawerResultsStepHeader = ({
  title,
  analyticsPage,
}: BulkActionsDrawerResultsStepHeaderProps) => {
  const { closeBulkActions } = useTypedContext(BulkActionsApiContext);

  return (
    <DrawerHeader justify="between">
      <DrawerHeaderTitle title={title} />
      <Box align="end">
        <LinkButtonIcon
          href={getDocsUrl("/product/bulk-actions.html")}
          target="_blank"
          icon={File}
          analyticsPage={analyticsPage}
          analyticsTitle={"Bulk actions - Drawer - Documentation clicked"}
          analyticsProps={{ step: BulkActionsStep.Results }}
          variant="ghost"
        >
          Go to documentation
        </LinkButtonIcon>
        <DrawerCloseIcon
          handleCloseDrawer={() =>
            closeBulkActions(BulkActionsVariant.Drawer, BulkActionsCloseMethod.Cross)
          }
        />
      </Box>
    </DrawerHeader>
  );
};

export default BulkActionsDrawerResultsStepHeader;
