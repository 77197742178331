import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import { ConfirmationModalProps } from "ds/components/ConfirmationModal/types";

type NewConfigValueConfirmationModalProps = Pick<ConfirmationModalProps, "onConfirm">;

const NewConfigValueConfirmationModal = create(function NewConfigValueConfirmationModal({
  onConfirm,
}: NewConfigValueConfirmationModalProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    onConfirm();
    modal.resolve();
    modal.hide();
  };

  return (
    <ConfirmationModal
      title="Variable already exists"
      confirmationButtonLabel="Proceed"
      confirmationButtonVariant="primary"
      onConfirm={handleOnConfirm}
    >
      <ConfirmationModalMessage>
        A variable with the same name already exists. Do you want to proceed and overwrite it?
      </ConfirmationModalMessage>
    </ConfirmationModal>
  );
});

export const showNewConfigValueConfirmationModal = (props: NewConfigValueConfirmationModalProps) =>
  show(NewConfigValueConfirmationModal, props);
