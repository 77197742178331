import { useLazyQuery } from "@apollo/client";
import capitalize from "lodash-es/capitalize";
import { useCallback, useId, useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import { Spinner } from "components/Spinner";
import TagsList from "components/TagsList";
import { ChevronRight } from "components/icons/generated";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import Icon from "ds/components/Icon";
import Link from "ds/components/Link";
import { TreeBranch, TreeBranchLink } from "ds/components/Tree";
import { useToggle } from "hooks/useToggle";
import useTypedContext from "hooks/useTypedContext";
import { Module, PolicyType, RunPolicyReceipt, Stack } from "types/generated";
import SimulationPanel from "views/shared/run/SimulationPanel";

import HistoryEntryLabel from "../HistoryEntryLabel";
import { GET_MODULE_POLICY_RECEIPT_SAMPLE, GET_STACK_POLICY_RECEIPT_SAMPLE } from "./gql";
import { getEntityPolicyReceiptSample } from "./helpers";
import styles from "./styles.module.css";

type HistoryEntryPolicyProps = {
  item: RunPolicyReceipt;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
};

const HistoryEntryPolicy = ({ item, runId, stackId, isModuleRun }: HistoryEntryPolicyProps) => {
  const [isOpen, toggle] = useToggle(false);
  const simulationPanelId = useId();
  const hasSample = item.sampled && !item.sampleExpired;
  let toggleIconTooltip = isOpen ? "Collapse" : "Expand";

  const { onError } = useTypedContext(FlashContext);

  const [getPolicyReceiptSample, { data, loading }] = useLazyQuery<
    { stack: Stack } | { module: Module }
  >(isModuleRun ? GET_MODULE_POLICY_RECEIPT_SAMPLE : GET_STACK_POLICY_RECEIPT_SAMPLE, {
    onError,
    variables: { stackId, runId, receiptId: item.id },
    onCompleted() {
      toggle(true);
    },
  });

  if (loading) {
    toggleIconTooltip = "";
  } else if (item.sampleExpired) {
    toggleIconTooltip = "Sample has expired";
  } else if (!item.sampled) {
    toggleIconTooltip = "No sample available";
  }

  const policyReceiptSample = useMemo(() => getEntityPolicyReceiptSample(data), [data]);

  const handleToggle = useCallback(() => {
    if (!isOpen && !policyReceiptSample) {
      getPolicyReceiptSample();
    } else {
      toggle();
    }
  }, [toggle, isOpen, policyReceiptSample, getPolicyReceiptSample]);

  const isToggleIconDisabled = !hasSample || loading;

  let policy = <i>{item.policyName}</i>;

  if (item.policySlug && !item.policyDeleted) {
    policy = (
      <Link href={`/policy/${item.policySlug}`} size="small">
        {item.policyName}
      </Link>
    );
  }

  if (item.policyDeleted) {
    policy = <i>{item.policyName} (deleted)</i>;
  }

  const result = item.policyType === PolicyType.Notification ? "routed" : "evaluated";

  return (
    <TreeBranch>
      <TreeBranchLink>
        <Box gap="small" align="center" padding="small 0">
          <ButtonIcon
            icon={ChevronRight}
            variant="ghost"
            onPress={handleToggle}
            iconRotate={isOpen ? "90" : undefined}
            disabled={isToggleIconDisabled}
            aria-expanded={isOpen}
            aria-controls={simulationPanelId}
          >
            {toggleIconTooltip}
          </ButtonIcon>

          <HistoryEntryLabel>
            Policy {policy} {result} to <i>{capitalize(item.outcome)}</i>
          </HistoryEntryLabel>

          {loading && <Icon src={Spinner} />}
        </Box>
      </TreeBranchLink>
      {item.flags.length > 0 && (
        <TagsList tags={item.flags} withTopMargin={false} className={styles.flags} />
      )}
      {isOpen && policyReceiptSample && (
        <Box id={simulationPanelId} className={styles.simulationPanel}>
          <SimulationPanel sample={policyReceiptSample} policyReceipt={item} />
        </Box>
      )}
    </TreeBranch>
  );
};

export default HistoryEntryPolicy;
