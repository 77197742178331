export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type ApiKeyInputOidc = {
  clientId: Scalars["String"]["input"];
  issuer: Scalars["String"]["input"];
  subjectExpression: Scalars["String"]["input"];
};

/** APIKeyType represents the type of an API key. */
export enum ApiKeyType {
  /**
   * API key that can dynamically generate tokens using an external,
   * OIDC-compliant identity provider.
   */
  Oidc = "OIDC",
  /** Classic API key with a secret. */
  Secret = "SECRET",
}

/**
 * AccountType represents a type of Spacelift account. It's no longer used, but
 * it's left for backwards compatibility reasons.
 */
export enum AccountType {
  /** Organization account. */
  Org = "ORG",
  /** Invalid "zero value" for the account type. */
  Unknown = "UNKNOWN",
  /** Individual user account. */
  User = "USER",
}

/** Action represents an action in Spacelift that can be performed by a user. */
export enum Action {
  SpaceAdmin = "SPACE_ADMIN",
  SpaceRead = "SPACE_READ",
  SpaceWrite = "SPACE_WRITE",
}

/** Possible Ansible entity types. */
export type AnsibleEntity = AnsibleResource;

export type AnsibleHost = {
  __typename?: "AnsibleHost";
  architecture: Maybe<Scalars["String"]["output"]>;
  distribution: Maybe<Scalars["String"]["output"]>;
  distributionVersion: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  machineID: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** searchAnsibleTaskExecutions allows to search ansibleTaskExecutions of an ansibleHost. */
  searchAnsibleTaskExecutions: Maybe<SearchAnsibleTaskExecutionsOutput>;
  /** Information about fields available for searching AnsibleTaskExecution. */
  searchAnsibleTaskExecutionsSuggestions: Maybe<SearchSuggestionsOutput>;
  taskExecutions: Array<AnsibleTaskExecution>;
};

export type AnsibleHostSearchAnsibleTaskExecutionsArgs = {
  input: SearchInput;
};

export type AnsibleHostSearchAnsibleTaskExecutionsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type AnsibleHostsConnection = {
  __typename?: "AnsibleHostsConnection";
  cursor: Scalars["String"]["output"];
  node: AnsibleHost;
};

export type AnsiblePlaybook = {
  __typename?: "AnsiblePlaybook";
  name: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
};

/** Metadata for a Ansible resource. */
export type AnsibleResource = {
  __typename?: "AnsibleResource";
  /** Data of the Ansible resource. */
  data: Scalars["String"]["output"];
};

export type AnsibleRole = {
  __typename?: "AnsibleRole";
  name: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
};

export type AnsibleTask = {
  __typename?: "AnsibleTask";
  action: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
  playbook: AnsiblePlaybook;
  resolvedAction: Scalars["String"]["output"];
  role: Maybe<AnsibleRole>;
  taskExecutions: Array<AnsibleTaskExecution>;
};

export type AnsibleTaskExecution = {
  __typename?: "AnsibleTaskExecution";
  /** Was task executed in check mode */
  checkMode: Scalars["Boolean"]["output"];
  /** Diff (if present) */
  diff: Scalars["String"]["output"];
  /** AnsibleHost on which AnsibleTask was executed */
  host: AnsibleHost;
  /** Run ID */
  runId: Scalars["ID"]["output"];
  /** Stack slug */
  stackId: Scalars["ID"]["output"];
  /** Execution AnsibleTaskStatus */
  status: AnsibleTaskStatus;
  /** Task output */
  stdout: Scalars["String"]["output"];
  /** Executed AnsibleTask */
  task: AnsibleTask;
  /** Timestamp of the task execution */
  timestamp: Scalars["Int"]["output"];
};

export type AnsibleTaskExecutionsConnection = {
  __typename?: "AnsibleTaskExecutionsConnection";
  cursor: Scalars["String"]["output"];
  node: AnsibleTaskExecution;
};

export enum AnsibleTaskStatus {
  Changed = "CHANGED",
  Failed = "FAILED",
  Ignored = "IGNORED",
  Ok = "OK",
  Rescued = "RESCUED",
  Skipped = "SKIPPED",
  Unreachable = "UNREACHABLE",
}

export type AnsibleTasksConnection = {
  __typename?: "AnsibleTasksConnection";
  cursor: Scalars["String"]["output"];
  node: AnsibleTask;
};

/** ApiKey represents a user-generated API key. */
export type ApiKey = {
  __typename?: "ApiKey";
  /** List of permissions given explicitly to this api key. */
  accessRules: Array<SpaceAccessRule>;
  /** Should tokens generated from this API key have admin permissions? */
  admin: Scalars["Boolean"]["output"];
  /** Creation timestamp. */
  createdAt: Scalars["Int"]["output"];
  /** The creator of the API key. */
  creator: Scalars["String"]["output"];
  /** Indicates whether the API key is deleted. */
  deleted: Scalars["Boolean"]["output"];
  /** Free-form description of the API key. */
  description: Scalars["String"]["output"];
  /** Globally unique ID of the API key. */
  id: Scalars["ID"]["output"];
  /** Indicates whether the api key is a machine user. */
  isMachineUser: Scalars["Boolean"]["output"];
  /** Last usage (token generation) timestamp, if any. */
  lastUsedAt: Maybe<Scalars["Int"]["output"]>;
  /** Human-readable name of this API key. */
  name: Scalars["String"]["output"];
  /** The OIDC settings for this API key. */
  oidcSettings: Maybe<ApiKeyOidcSettings>;
  /**
   * API key secret - only visible when the key is created and not persisted on
   * Spacelift end. Make sure to save it!
   */
  secret: Maybe<Scalars["String"]["output"]>;
  /** Number of spaces api key was explicitly given permissions to. */
  spaceCount: Scalars["Int"]["output"];
  /** Number of teams this key "belongs to" */
  teamCount: Scalars["Int"]["output"];
  /**
   * List of teams this key "belongs to". This allows non-admin API keys to be
   * treated the same way as regular users by stack-level policies.
   */
  teams: Array<Scalars["String"]["output"]>;
  /** Type of the API key. */
  type: ApiKeyType;
};

export type ApiKeyInput = {
  /** Access rules for api key. */
  accessRules: InputMaybe<Array<SpaceAccessRuleInput>>;
  admin: Scalars["Boolean"]["input"];
  description: InputMaybe<Scalars["String"]["input"]>;
  machine: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  oidc: InputMaybe<ApiKeyInputOidc>;
  teams: Array<Scalars["String"]["input"]>;
};

/** ApiKeyOIDCSettings represents the OIDC settings for an API key. */
export type ApiKeyOidcSettings = {
  __typename?: "ApiKeyOIDCSettings";
  /** The OIDC client ID (audience). */
  clientId: Scalars["String"]["output"];
  /** The OIDC issuer URL. */
  issuer: Scalars["String"]["output"];
  /**
   * The regular expression against which the subject of the OIDC token will be
   * matched.
   */
  subjectExpression: Scalars["String"]["output"];
};

/** This type represents one aspect data point */
export type AspectDataPoint = {
  __typename?: "AspectDataPoint";
  /** group name, i.e. stack name */
  name: Scalars["String"]["output"];
  timestamp: Scalars["Int"]["output"];
  value: Scalars["Float"]["output"];
};

/** What granularity to use for aggregating data points */
export enum AspectGranularity {
  Day = "DAY",
  Month = "MONTH",
  Week = "WEEK",
  Year = "YEAR",
}

/** Arguments used to query for account usage data */
export type AspectInput = {
  /** Granularity of the aspect */
  granularity: AspectGranularity;
  /**
   * How to group the data
   * If there is no group by argument passed, we will assume that we are only grouping by granularity.
   */
  groupBy: InputMaybe<Scalars["String"]["input"]>;
  /** Start and end time of the aspect */
  timeFilter: AspectTimeFilter;
  /** Aspect type */
  type: AspectType;
};

/** A collection of data points of some aspect */
export type AspectOutput = {
  __typename?: "AspectOutput";
  /** List of data points */
  data: Array<AspectDataPoint>;
  /** What granularity to use for aggregating data points, e.g. DAY */
  granularity: AspectGranularity;
  /** Summary of the data points */
  summary: AspectSummary;
  /** What aspect of the account usage to return data for, e.g. RUN_MINUTES */
  type: AspectType;
};

/** Percentile calculated for the returned data points. */
export type AspectPercentile = {
  __typename?: "AspectPercentile";
  /** Which percentile this is, e.g. 95th, 99th */
  percentile: Scalars["Float"]["output"];
  value: Scalars["Float"]["output"];
};

/**
 * Summary returned together with data points.
 * Contains metadata generated from the data points.
 */
export type AspectSummary = {
  __typename?: "AspectSummary";
  percentiles: Array<AspectPercentile>;
  totals: Array<AspectTotal>;
};

export type AspectTimeFilter = {
  endTime: Scalars["Int"]["input"];
  startTime: Scalars["Int"]["input"];
};

/** Total sum of all data points */
export type AspectTotal = {
  __typename?: "AspectTotal";
  name: Scalars["String"]["output"];
  value: Scalars["Float"]["output"];
};

/** What aspect of the account usage to return data for */
export enum AspectType {
  RunMinutes = "RUN_MINUTES",
  WorkersCount = "WORKERS_COUNT",
}

export type AuditActor = {
  __typename?: "AuditActor";
  /**
   * Contains details of the Spacelift run that performed the action. Only populated for audit
   * entries created by runs in Administrative stacks.
   */
  run: Maybe<AuditRunActor>;
  /**
   * Username of the user / API key who created the entry. If the action was triggered by a
   * run on an administrative stack, this field will be set to `spacelift`, and the `run`
   * field will be populated.
   */
  username: Scalars["String"]["output"];
};

export type AuditRunActor = {
  __typename?: "AuditRunActor";
  /** The run ID. */
  id: Scalars["ID"]["output"];
  /** The ID of the run's stack. */
  stackId: Scalars["ID"]["output"];
};

export type AuditTrailApiKeyMetadata = {
  __typename?: "AuditTrailAPIKeyMetadata";
  /** The ID of the API key. */
  id: Scalars["ID"]["output"];
};

export type AuditTrailAwsIntegrationMetadata = {
  __typename?: "AuditTrailAWSIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailAccountMetadata = {
  __typename?: "AuditTrailAccountMetadata";
  /** The subdomain of the Spacelift account. */
  subdomain: Scalars["ID"]["output"];
};

export type AuditTrailAzureDevOpsRepoIntegrationMetadata = {
  __typename?: "AuditTrailAzureDevOpsRepoIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailAzureIntegrationMetadata = {
  __typename?: "AuditTrailAzureIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailBitbucketCloudIntegrationMetadata = {
  __typename?: "AuditTrailBitbucketCloudIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailBitbucketDatacenterIntegrationMetadata = {
  __typename?: "AuditTrailBitbucketDatacenterIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailBlueprintMetadata = {
  __typename?: "AuditTrailBlueprintMetadata";
  /** The ID of the blueprint. */
  id: Scalars["ID"]["output"];
  /** The name of the blueprint. */
  name: Scalars["String"]["output"];
};

export type AuditTrailBlueprintVersionedGroupMetadata = {
  __typename?: "AuditTrailBlueprintVersionedGroupMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailContextMetadata = {
  __typename?: "AuditTrailContextMetadata";
  /** The ID of the context. */
  id: Scalars["ID"]["output"];
  /** The name of the context. */
  name: Scalars["String"]["output"];
};

export type AuditTrailDriftDetectionIntegrationMetadata = {
  __typename?: "AuditTrailDriftDetectionIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The ID of the stack the integration is defined on. */
  stackId: Scalars["ID"]["output"];
};

export type AuditTrailEntriesConnection = {
  __typename?: "AuditTrailEntriesConnection";
  cursor: Scalars["String"]["output"];
  node: AuditTrailEntry;
};

export type AuditTrailEntry = {
  __typename?: "AuditTrailEntry";
  /** The audit action. */
  action: Scalars["String"]["output"];
  /** The entity that performed the action being audited. */
  actor: AuditActor;
  /** The resource that was affected by the event. */
  affectedResource: AuditTrailResource;
  /** Body of the event */
  body: Maybe<Scalars["String"]["output"]>;
  /** CreatedAt is unix timestamp (in seconds) at which the entry was created */
  createdAt: Scalars["Int"]["output"];
  /** The type of the event which was triggered */
  eventType: AuditTrailEventType;
  /** AuditTrailEntry ID, used to identify an entry */
  id: Scalars["ID"]["output"];
  /**
   * A resource that wasn't affected by the event, but is related to the affected resource. For
   * example a Blueprint that was used to create a stack. This is only populated for certain events.
   */
  relatedResource: Maybe<AuditTrailResource>;
  /** UpdatedAt is unix timestamp (in seconds) at which the entry was updated */
  updatedAt: Scalars["Int"]["output"];
};

export enum AuditTrailEventType {
  /** A new resource was created. */
  Create = "CREATE",
  /** An existing resource was deleted. */
  Delete = "DELETE",
  /** No changes were made - this audit entry is informational only. */
  None = "NONE",
  /** The event type is unknown. */
  Unknown = "UNKNOWN",
  /** An existing resource was updated. */
  Update = "UPDATE",
}

export type AuditTrailExternalIntegrationMetadata = {
  __typename?: "AuditTrailExternalIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailGpgKeyMetadata = {
  __typename?: "AuditTrailGPGKeyMetadata";
  /** The ID of the key. */
  id: Scalars["ID"]["output"];
  /** The name of the key. */
  name: Scalars["String"]["output"];
};

export type AuditTrailGenericFormMetadata = {
  __typename?: "AuditTrailGenericFormMetadata";
  /** The name of the generic form. */
  name: Scalars["String"]["output"];
};

export type AuditTrailGithubAppInstallationMetadata = {
  __typename?: "AuditTrailGithubAppInstallationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
};

export type AuditTrailGithubEnterpriseIntegrationMetadata = {
  __typename?: "AuditTrailGithubEnterpriseIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailGitlabIntegrationMetadata = {
  __typename?: "AuditTrailGitlabIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailLoginMetadata = {
  __typename?: "AuditTrailLoginMetadata";
  /** The username. */
  username: Scalars["String"]["output"];
};

export type AuditTrailModuleMetadata = {
  __typename?: "AuditTrailModuleMetadata";
  /** The ID of the module. */
  id: Scalars["ID"]["output"];
  /** The name of the module. */
  name: Scalars["String"]["output"];
};

export type AuditTrailNamedWebhooksIntegrationMetadata = {
  __typename?: "AuditTrailNamedWebhooksIntegrationMetadata";
  /** The ID of the integration. */
  id: Scalars["ID"]["output"];
  /** The name of the integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailPolicyMetadata = {
  __typename?: "AuditTrailPolicyMetadata";
  /** The ID of the policy. */
  id: Scalars["ID"]["output"];
  /** The name of the policy. */
  name: Scalars["String"]["output"];
};

export type AuditTrailResource = {
  __typename?: "AuditTrailResource";
  /** Contains additional information about the resource that has changed. This is primarily used to generate links to the resource in the Spacelift UI. */
  metadata: AuditTrailResourceMetadata;
  /** The ID of the resource, or null if the event affected multiple resources. */
  resourceId: Maybe<Scalars["String"]["output"]>;
  /** Resource type which was affected */
  resourceType: AuditTrailResourceType;
};

/**
 * AuditTrailResourceMetadata is a union of all the types of resource metadata that can be provided.
 * Each AuditTrailResourceType has a corresponding metadata type containing additional information
 * about that resource.
 */
export type AuditTrailResourceMetadata =
  | AuditTrailApiKeyMetadata
  | AuditTrailAwsIntegrationMetadata
  | AuditTrailAccountMetadata
  | AuditTrailAzureDevOpsRepoIntegrationMetadata
  | AuditTrailAzureIntegrationMetadata
  | AuditTrailBitbucketCloudIntegrationMetadata
  | AuditTrailBitbucketDatacenterIntegrationMetadata
  | AuditTrailBlueprintMetadata
  | AuditTrailBlueprintVersionedGroupMetadata
  | AuditTrailContextMetadata
  | AuditTrailDriftDetectionIntegrationMetadata
  | AuditTrailExternalIntegrationMetadata
  | AuditTrailGpgKeyMetadata
  | AuditTrailGenericFormMetadata
  | AuditTrailGithubAppInstallationMetadata
  | AuditTrailGithubEnterpriseIntegrationMetadata
  | AuditTrailGitlabIntegrationMetadata
  | AuditTrailLoginMetadata
  | AuditTrailModuleMetadata
  | AuditTrailNamedWebhooksIntegrationMetadata
  | AuditTrailPolicyMetadata
  | AuditTrailRunMetadata
  | AuditTrailScheduledDeleteMetadata
  | AuditTrailScheduledRunMetadata
  | AuditTrailScheduledTaskMetadata
  | AuditTrailSecurityKeyMetadata
  | AuditTrailSessionMetadata
  | AuditTrailSpaceMetadata
  | AuditTrailStackMetadata
  | AuditTrailTaskMetadata
  | AuditTrailTerraformProviderMetadata
  | AuditTrailTerraformProviderVersionMetadata
  | AuditTrailUserGroupIntegrationMetadata
  | AuditTrailUserGroupMetadata
  | AuditTrailUserMetadata
  | AuditTrailVersionMetadata
  | AuditTrailWebhookMetadata
  | AuditTrailWorkerMetadata
  | AuditTrailWorkerPoolMetadata;

export enum AuditTrailResourceType {
  Account = "ACCOUNT",
  ApiKey = "API_KEY",
  AwsIntegration = "AWS_INTEGRATION",
  AzureDevopsRepoIntegration = "AZURE_DEVOPS_REPO_INTEGRATION",
  AzureIntegration = "AZURE_INTEGRATION",
  BitbucketCloudIntegration = "BITBUCKET_CLOUD_INTEGRATION",
  BitbucketDatacenterIntegration = "BITBUCKET_DATACENTER_INTEGRATION",
  Blueprint = "BLUEPRINT",
  BlueprintVersionedGroup = "BLUEPRINT_VERSIONED_GROUP",
  Context = "CONTEXT",
  DriftDetectionIntegration = "DRIFT_DETECTION_INTEGRATION",
  ExternalIntegration = "EXTERNAL_INTEGRATION",
  GenericForm = "GENERIC_FORM",
  GithubAppInstallation = "GITHUB_APP_INSTALLATION",
  GithubEnterpriseIntegration = "GITHUB_ENTERPRISE_INTEGRATION",
  GitlabIntegration = "GITLAB_INTEGRATION",
  GpgKey = "GPG_KEY",
  Login = "LOGIN",
  Module = "MODULE",
  NamedWebhooksIntegration = "NAMED_WEBHOOKS_INTEGRATION",
  Notification = "NOTIFICATION",
  Policy = "POLICY",
  Run = "RUN",
  ScheduledDelete = "SCHEDULED_DELETE",
  ScheduledRun = "SCHEDULED_RUN",
  ScheduledTask = "SCHEDULED_TASK",
  SecurityKey = "SECURITY_KEY",
  Session = "SESSION",
  Space = "SPACE",
  Stack = "STACK",
  Task = "TASK",
  TerraformProvider = "TERRAFORM_PROVIDER",
  TerraformProviderVersion = "TERRAFORM_PROVIDER_VERSION",
  Unknown = "UNKNOWN",
  User = "USER",
  UserGroup = "USER_GROUP",
  UserGroupIntegration = "USER_GROUP_INTEGRATION",
  Version = "VERSION",
  Webhook = "WEBHOOK",
  Worker = "WORKER",
  WorkerPool = "WORKER_POOL",
}

export type AuditTrailRunMetadata = {
  __typename?: "AuditTrailRunMetadata";
  /** The ID of the run. */
  id: Scalars["ID"]["output"];
  /** The stack ID of the run. */
  stackId: Scalars["ID"]["output"];
};

export type AuditTrailScheduledDeleteMetadata = {
  __typename?: "AuditTrailScheduledDeleteMetadata";
  /** The ID of the scheduled delete. */
  id: Scalars["ID"]["output"];
  /** The ID of the stack the schedule is defined on. */
  stackId: Scalars["ID"]["output"];
};

export type AuditTrailScheduledRunMetadata = {
  __typename?: "AuditTrailScheduledRunMetadata";
  /** The ID of the scheduled run. */
  id: Scalars["ID"]["output"];
  /** The ID of the stack the schedule is defined on. */
  stackId: Scalars["ID"]["output"];
};

export type AuditTrailScheduledTaskMetadata = {
  __typename?: "AuditTrailScheduledTaskMetadata";
  /** The ID of the scheduled task. */
  id: Scalars["ID"]["output"];
  /** The ID of the stack the schedule is defined on. */
  stackId: Scalars["ID"]["output"];
};

export type AuditTrailSecurityKeyMetadata = {
  __typename?: "AuditTrailSecurityKeyMetadata";
  /** The ID of the security key. */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type AuditTrailSessionMetadata = {
  __typename?: "AuditTrailSessionMetadata";
  /** The ID of the session. */
  id: Scalars["ID"]["output"];
};

export type AuditTrailSpaceMetadata = {
  __typename?: "AuditTrailSpaceMetadata";
  /** The ID of the space. */
  id: Scalars["ID"]["output"];
  /** The name of the space. */
  name: Scalars["String"]["output"];
};

export type AuditTrailStackMetadata = {
  __typename?: "AuditTrailStackMetadata";
  /** The ID of the stack. */
  id: Scalars["ID"]["output"];
  /** The name of the stack. */
  name: Scalars["String"]["output"];
};

export type AuditTrailTaskMetadata = {
  __typename?: "AuditTrailTaskMetadata";
  /** The ID of the task. */
  id: Scalars["ID"]["output"];
  /** The stack ID of the task. */
  stackId: Scalars["ID"]["output"];
};

export type AuditTrailTerraformProviderMetadata = {
  __typename?: "AuditTrailTerraformProviderMetadata";
  /** The Terraform provider type. */
  type: Scalars["String"]["output"];
};

export type AuditTrailTerraformProviderVersionMetadata = {
  __typename?: "AuditTrailTerraformProviderVersionMetadata";
  /** The ID of the version. */
  id: Scalars["ID"]["output"];
  /** The Terraform provider type. */
  type: Scalars["String"]["output"];
  /** The version number. */
  versionNumber: Scalars["String"]["output"];
};

export type AuditTrailUserGroupIntegrationMetadata = {
  __typename?: "AuditTrailUserGroupIntegrationMetadata";
  /** The ID of the group integration. */
  id: Scalars["ID"]["output"];
  /** The name of the group integration. */
  name: Scalars["String"]["output"];
};

export type AuditTrailUserGroupMetadata = {
  __typename?: "AuditTrailUserGroupMetadata";
  /** The ID of the group. */
  id: Scalars["ID"]["output"];
  /** The name of the group. */
  name: Scalars["String"]["output"];
};

export type AuditTrailUserMetadata = {
  __typename?: "AuditTrailUserMetadata";
  /** The user's username. */
  username: Scalars["String"]["output"];
};

export type AuditTrailVersionMetadata = {
  __typename?: "AuditTrailVersionMetadata";
  /** The ID of the version. */
  id: Scalars["ID"]["output"];
  /** The ID of the module. */
  moduleId: Scalars["ID"]["output"];
};

/** AuditTrailWebhook represents audit trail webhook configuration for the account. */
export type AuditTrailWebhook = {
  __typename?: "AuditTrailWebhook";
  /**
   * Custom headers is a list of header keys which are set for this webhook.
   * Only the keys are returned as values potentially have sensitive data.
   */
  customHeaders: Array<Scalars["String"]["output"]>;
  /**
   * Paginated list of audit trail deliveries. The optional opaque token is used
   * for pagination and should be copied from the previous WebhookDeliveries
   * response.
   */
  deliveries: Maybe<WebhookDeliveries>;
  /** Extended details of a single delivery. */
  deliveryDetails: Maybe<WebhookDeliveryDetails>;
  /** Enable or disable audit trail. */
  enabled: Scalars["Boolean"]["output"];
  /** User endpoint to send audit trail webhooks. */
  endpoint: Scalars["String"]["output"];
  /**
   * Controls whether the global webhooks should also receive run state change
   * notifications.
   */
  includeRuns: Scalars["Boolean"]["output"];
  /**
   *  Secret used to validate incoming webhook.
   * @deprecated This property is no longer used.
   */
  secret: Scalars["String"]["output"];
};

/** AuditTrailWebhook represents audit trail webhook configuration for the account. */
export type AuditTrailWebhookDeliveriesArgs = {
  token: InputMaybe<Scalars["String"]["input"]>;
};

/** AuditTrailWebhook represents audit trail webhook configuration for the account. */
export type AuditTrailWebhookDeliveryDetailsArgs = {
  key: Scalars["String"]["input"];
};

export type AuditTrailWebhookDeleteHeadersInput = {
  customHeaderKeys: Array<Scalars["String"]["input"]>;
};

export type AuditTrailWebhookInput = {
  /** Map of key values strings, that will be passed as headers with audit trail call. */
  customHeaders: InputMaybe<StringMapInput>;
  /** Enable or disable audit trail. */
  enabled: Scalars["Boolean"]["input"];
  /** User endpoint to send audit trail webhooks. */
  endpoint: Scalars["String"]["input"];
  /**
   * Controls whether the global webhooks should also receive run state change
   * notifications.
   */
  includeRuns: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Secret used to validate incoming webhook. */
  secret: InputMaybe<Scalars["String"]["input"]>;
};

export type AuditTrailWebhookMetadata = {
  __typename?: "AuditTrailWebhookMetadata";
  /** The ID of the webhook. */
  id: Scalars["ID"]["output"];
  /** The ID of the module. Only populated for webhooks defined on modules. */
  moduleId: Maybe<Scalars["ID"]["output"]>;
  /** The ID of the stack. Only populated for webhooks defined on stacks. */
  stackId: Maybe<Scalars["ID"]["output"]>;
};

export type AuditTrailWebhookSetHeadersInput = {
  customHeaders: StringMapInput;
};

export type AuditTrailWorkerMetadata = {
  __typename?: "AuditTrailWorkerMetadata";
  /** The ID of the worker. */
  id: Scalars["ID"]["output"];
  /** The ID of the worker pool. */
  workerPoolId: Scalars["ID"]["output"];
};

export type AuditTrailWorkerPoolMetadata = {
  __typename?: "AuditTrailWorkerPoolMetadata";
  /** The ID of the worker pool. */
  id: Scalars["ID"]["output"];
  /** The name of the worker pool. */
  name: Scalars["String"]["output"];
};

export enum AuthorizationScheme {
  LoginPolicies = "LOGIN_POLICIES",
  ManagedUsers = "MANAGED_USERS",
}

/**
 * AutoattachmentTarget is a stack or module that is autoattached to an
 * entity that supports autoattachment.
 */
export type AutoattachmentTarget = {
  __typename?: "AutoattachmentTarget";
  /** The ID (slug) of the target. */
  id: Scalars["ID"]["output"];
  /** True if the target is a module. */
  isModule: Scalars["Boolean"]["output"];
  /** List of labels on the target. */
  labels: Array<Scalars["String"]["output"]>;
  /** Name of the target. */
  name: Scalars["String"]["output"];
  /** ID of the space to which the target belongs. */
  space: Scalars["ID"]["output"];
  /** Details of the space this autoattachment target belongs to. */
  spaceDetails: Space;
};

/** AutoattachmentTargets is the list of autoattached stacks and modules. */
export type AutoattachmentTargets = {
  __typename?: "AutoattachmentTargets";
  /** Total count of results. */
  count: Scalars["Int"]["output"];
  /** Top 100 results. */
  sample: Array<AutoattachmentTarget>;
};

export type AwarenessSourceSurvey = {
  __typename?: "AwarenessSourceSurvey";
  completed: Scalars["Boolean"]["output"];
  response: Maybe<Scalars["String"]["output"]>;
};

/**
 * AwsIntegration is an integration with AWS
 * that you can attach to your stack
 */
export type AwsIntegration = Notifiable & {
  __typename?: "AwsIntegration";
  /** Retrieve an individual stack attachment by its ID (slug). */
  attachedStack: Maybe<AwsIntegrationStackAttachment>;
  /** The stacks attached to this integration. */
  attachedStacks: Array<AwsIntegrationStackAttachment>;
  /** Duration in seconds for which the assumed role credentials should be valid. */
  durationSeconds: Maybe<Scalars["Int"]["output"]>;
  /** AWS external ID (only valid when generating credentials in private workers). */
  externalId: Scalars["String"]["output"];
  /** Generates the external ID that would be used for role assumption when attached to the specified stack. */
  externalIdForStack: Maybe<ExternalIdForStackResult>;
  /** AWS credentials will be generated in the private worker. */
  generateCredentialsInWorker: Scalars["Boolean"]["output"];
  /** Indicates whether any stacks are attached to this policy, either directly or via auto-attachment. */
  hasAttachedStacks: Scalars["Boolean"]["output"];
  /** The unique ID for managing the integration within Spacelift. */
  id: Scalars["String"]["output"];
  /** User-defined labels set on the integration. */
  labels: Array<Scalars["String"]["output"]>;
  /**
   * Indicates that this integration was automatically created via the stackIntegrationAwsCreate mutation
   * and is bound to a single stack.
   */
  legacy: Scalars["Boolean"]["output"];
  /** The friendly name of the integration. */
  name: Scalars["String"]["output"];
  /** Number of the aws integration notifications. */
  notificationCount: Scalars["Int"]["output"];
  /** AWS IAM role to assume. */
  roleArn: Scalars["String"]["output"];
  /** ID of the space this AwsIntegration belongs to. */
  space: Scalars["ID"]["output"];
  /** Details of the space this AWS integration belongs to. */
  spaceDetails: Space;
};

/**
 * AwsIntegration is an integration with AWS
 * that you can attach to your stack
 */
export type AwsIntegrationAttachedStackArgs = {
  id: Scalars["ID"]["input"];
};

/**
 * AwsIntegration is an integration with AWS
 * that you can attach to your stack
 */
export type AwsIntegrationExternalIdForStackArgs = {
  read: Scalars["Boolean"]["input"];
  stackId: Scalars["ID"]["input"];
  write: Scalars["Boolean"]["input"];
};

/** AwsIntegrationStackAttachment is the stack attachment viewed from the integration. */
export type AwsIntegrationStackAttachment = {
  __typename?: "AwsIntegrationStackAttachment";
  /** Globally unique attachment identifier. */
  id: Scalars["ID"]["output"];
  /** True if the attached stack is type of Module. */
  isModule: Scalars["Boolean"]["output"];
  /** Whether the integration is used for read operations. */
  read: Scalars["Boolean"]["output"];
  /** Slug of the attached stack. */
  stackId: Scalars["ID"]["output"];
  /** Name of the attached stack. */
  stackName: Scalars["String"]["output"];
  /** Whether the integration is used for write operations. */
  write: Scalars["Boolean"]["output"];
};

export type AzureDevOpsRepoIntegration = {
  __typename?: "AzureDevOpsRepoIntegration";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  labels: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  /** The API host where requests will be sent. */
  organizationURL: Scalars["String"]["output"];
  space: Maybe<Space>;
  userFacingHost: Scalars["String"]["output"];
  vcsChecks: VcsCheck;
  /** Autogenerated password for Azure DevOps webhook integration */
  webhookPassword: Scalars["String"]["output"];
  webhookUrl: Scalars["String"]["output"];
};

/** AzureIntegration represents an integration with an Azure AD tenant. */
export type AzureIntegration = {
  __typename?: "AzureIntegration";
  /** Indicates whether admin consent has been performed for the AAD Application. */
  adminConsentProvided: Scalars["Boolean"]["output"];
  /** The URL to use to provide admin consent to the application in the customer's tenant. */
  adminConsentURL: Scalars["String"]["output"];
  /** The applicationId of the Azure AD application used by the integration. */
  applicationId: Scalars["String"]["output"];
  /** Retrieve an individual stack attachment by its ID (slug). */
  attachedStack: Maybe<AzureIntegrationStackAttachment>;
  /** The stacks attached to this integration. */
  attachedStacks: Array<AzureIntegrationStackAttachment>;
  /** Unix timestamp representing the integration creation. */
  createdAt: Scalars["Int"]["output"];
  /** The default subscription ID to use, if one isn't specified at the stack level. */
  defaultSubscriptionId: Maybe<Scalars["String"]["output"]>;
  /**
   * The display name for the application in Azure. This is automatically generated when the
   * integration is created, and cannot be changed without deleting and recreating the integration.
   */
  displayName: Scalars["String"]["output"];
  /** Indicates whether any stacks are attached to this policy, either directly or via auto-attachment. */
  hasAttachedStacks: Scalars["Boolean"]["output"];
  /** The unique ID for managing the integration within Spacelift. */
  id: Scalars["String"]["output"];
  /** User-defined labels set on the integration. */
  labels: Array<Scalars["String"]["output"]>;
  /** The friendly name of the integration. */
  name: Scalars["String"]["output"];
  /** ID of the space this AzureIntegration belongs to. */
  space: Scalars["ID"]["output"];
  /** Details of the space this Azure integration belongs to. */
  spaceDetails: Space;
  /** The Azure AD tenant ID. */
  tenantId: Scalars["String"]["output"];
  /** Unix timestamp representing the last update to the integration. */
  updatedAt: Scalars["Int"]["output"];
};

/** AzureIntegration represents an integration with an Azure AD tenant. */
export type AzureIntegrationAttachedStackArgs = {
  id: Scalars["ID"]["input"];
};

/** AzureIntegrationStackAttachment is the stack attachment viewed from the integration. */
export type AzureIntegrationStackAttachment = {
  __typename?: "AzureIntegrationStackAttachment";
  /** Globally unique attachment identifier. */
  id: Scalars["ID"]["output"];
  /** True if the attached stack is type of Module. */
  isModule: Scalars["Boolean"]["output"];
  /** True if this attachment is used for read operations. */
  read: Scalars["Boolean"]["output"];
  /** Slug of the attached stack. */
  stackId: Scalars["ID"]["output"];
  /** Name of the attached stack. */
  stackName: Scalars["String"]["output"];
  /**
   * Contains the Azure subscription ID to use with this Stack. Overrides the defaultSubscriptionId
   * set at the account level.
   */
  subscriptionId: Maybe<Scalars["String"]["output"]>;
  /** True if this attachment is used for write operations. */
  write: Scalars["Boolean"]["output"];
};

/** BillableRunState represents a state that is billed. */
export enum BillableRunState {
  /** A worker is currently applying the run's changes. */
  Applying = "APPLYING",
  /**
   * The worker is currently performing a destroy operation on the managed
   * resources.
   */
  Destroying = "DESTROYING",
  /** The run's workspace is currently initializing on the worker. */
  Initializing = "INITIALIZING",
  /** The worker is currently performing the task requested by the user. */
  Performing = "PERFORMING",
  /** The worker is planning the run. */
  Planning = "PLANNING",
  /** The workspace is currently being prepared for the run. */
  Preparing = "PREPARING",
  /** The workspace is currently being prepared for the change deployment. */
  PreparingApply = "PREPARING_APPLY",
  /** The run is being prepared to be replanned. */
  PreparingReplan = "PREPARING_REPLAN",
}

/** BillingAddon represents an addon that can be bought along with the plan. */
export type BillingAddon = {
  __typename?: "BillingAddon";
  /** Monthly price of the addon in cents. */
  monthlyPrice: Scalars["Int"]["output"];
  /** Name of the addon. */
  name: BillingAddonType;
  /** Tiers that are eligible for buying the addon. */
  tiers: Array<BillingTier>;
  /** Yearly price of the addon in cents. */
  yearlyPrice: Scalars["Int"]["output"];
};

/**
 * BillingAddonType represents the addon types that are available to be subscribed to.
 * This only applies to the V4_STARTER tier.
 */
export enum BillingAddonType {
  AdditionalPublicWorker = "ADDITIONAL_PUBLIC_WORKER",
  UnlimitedSeats = "UNLIMITED_SEATS",
}

/**
 * BillingCycleInterval represents the billing cycle interval for a subscription.
 * This only applies to the V4_STARTER tier.
 */
export enum BillingCycleInterval {
  Monthly = "MONTHLY",
  Yearly = "YEARLY",
}

/** BillingInfo represents account billing details stored in Stripe */
export type BillingInfo = {
  __typename?: "BillingInfo";
  /** Billing address */
  address: Scalars["String"]["output"];
  /** Billing city */
  city: Scalars["String"]["output"];
  /** Company name */
  companyName: Scalars["String"]["output"];
  /** Country code (ISO 3166-1 alpha2) */
  country: Scalars["String"]["output"];
  /** Billing email */
  email: Scalars["String"]["output"];
  /** Full name of the billing contact */
  fullName: Scalars["String"]["output"];
  /** State (US-only) */
  state: Scalars["String"]["output"];
  /** Zip code */
  zipCode: Scalars["String"]["output"];
};

/** BillingPlan represents a tier that can be bought. */
export type BillingPlan = {
  __typename?: "BillingPlan";
  /** Monthly price of the plan in cents. */
  monthlyPrice: Scalars["Int"]["output"];
  /** Tier of the plan */
  tier: BillingTier;
  /** Yearly price of the plan in cents. */
  yearlyPrice: Scalars["Int"]["output"];
};

export type BillingSubscription = {
  __typename?: "BillingSubscription";
  /** Addons of the subscription. */
  addons: Array<BillingSubscriptionAddon>;
  /** Price amount for the subscription. In cents. */
  billingAmount: Maybe<Scalars["Int"]["output"]>;
  /** Timestamp of start of the current billing cycle */
  billingCycleStart: Maybe<Scalars["Int"]["output"]>;
  /** Billing info provided by the user which is attached to each invoice by Stripe */
  billingInfo: Maybe<BillingInfo>;
  /** Interval of the subscription. One of "month" or "year". */
  billingInterval: Maybe<BillingCycleInterval>;
  /** Timestamp of the renewal date of the subscription */
  billingRenewalDate: Maybe<Scalars["Int"]["output"]>;
  /** isActive describes if the account has active (premium) subscription */
  isActive: Scalars["Boolean"]["output"];
  /** URL to Stripe-hosted portal which allows to manage subscription */
  selfServePortalUrl: Scalars["String"]["output"];
  /** Status of the subscription */
  status: StripeSubscriptionStatus;
};

export type BillingSubscriptionAddon = {
  __typename?: "BillingSubscriptionAddon";
  name: BillingAddonType;
};

/**
 * BillingTier represents a billing tier for a user. Please see https://spacelift.io/pricing for
 * more information about each tier.
 */
export enum BillingTier {
  /** Cloud billing tier. */
  Cloud = "CLOUD",
  /** Enterprise billing tier. */
  Enterprise = "ENTERPRISE",
  /** Free billing tier. */
  Free = "FREE",
  /** Cloud billing tier. */
  V3Cloud = "V3_CLOUD",
  /** Free billing tier. */
  V3Free = "V3_FREE",
  /** BUSINESS Billing tier. */
  V4Business = "V4_BUSINESS",
  /** BUSINESS Billing tier. */
  V4Enterprise = "V4_ENTERPRISE",
  /** Free Billing tier. */
  V4Free = "V4_FREE",
  /** Starter Billing tier. */
  V4Starter = "V4_STARTER",
  /** Starter Plus Billing tier. */
  V4StarterPlus = "V4_STARTER_PLUS",
}

/** BillingTierFeature lists various features that are feature-gated by billing tier. */
export enum BillingTierFeature {
  AccessPolicies = "ACCESS_POLICIES",
  AccountAiControl = "ACCOUNT_AI_CONTROL",
  AdditionalPublicWorker = "ADDITIONAL_PUBLIC_WORKER",
  AdvancedScheduling = "ADVANCED_SCHEDULING",
  AggregatedVcsChecks = "AGGREGATED_VCS_CHECKS",
  ApiKeysOidc = "API_KEYS_OIDC",
  /** Indicates whether your account can use audit functionality, including audit webhooks. */
  Auditing = "AUDITING",
  /** Obsolete - you can check for the AUDITING feature instead */
  AuditWebhooks = "AUDIT_WEBHOOKS",
  Blueprints = "BLUEPRINTS",
  CanUpdateSsoSaml = "CAN_UPDATE_SSO_SAML",
  CloudCredentialGeneration = "CLOUD_CREDENTIAL_GENERATION",
  CustomSsoSaml = "CUSTOM_SSO_SAML",
  DriftDetection = "DRIFT_DETECTION",
  Mfa = "MFA",
  MultipleVcs = "MULTIPLE_VCS",
  NotificationPolicies = "NOTIFICATION_POLICIES",
  OidcFederationTokenGeneration = "OIDC_FEDERATION_TOKEN_GENERATION",
  PrivateModuleSharing = "PRIVATE_MODULE_SHARING",
  PrivateVcs = "PRIVATE_VCS",
  PrivateWorkers = "PRIVATE_WORKERS",
  RunIgnoredTriggers = "RUN_IGNORED_TRIGGERS",
  RunStateChangeWebhooks = "RUN_STATE_CHANGE_WEBHOOKS",
  RunSummarizationAi = "RUN_SUMMARIZATION_AI",
  ServiceNowIntegration = "SERVICE_NOW_INTEGRATION",
  TargetedReplan = "TARGETED_REPLAN",
  TerraformProviderVersions = "TERRAFORM_PROVIDER_VERSIONS",
  UnlimitedSeats = "UNLIMITED_SEATS",
  WorkflowPolicies = "WORKFLOW_POLICIES",
}

/** BitbucketCloudIntegration shows Bitbucket Cloud integration details. */
export type BitbucketCloudIntegration = {
  __typename?: "BitbucketCloudIntegration";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  labels: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  space: Maybe<Space>;
  /**  Username to be used for authentication. */
  username: Scalars["String"]["output"];
  vcsChecks: VcsCheck;
  webhookUrl: Scalars["String"]["output"];
};

/** BitbucketDatacenterIntegration shows Bitbucket Datacenter integration details. */
export type BitbucketDatacenterIntegration = {
  __typename?: "BitbucketDatacenterIntegration";
  /** The API host where requests will be sent. */
  apiHost: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  labels: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  space: Maybe<Space>;
  /** User Facing Host which will be user for all user-facing URLs displayed in the Spacelift UI. */
  userFacingHost: Scalars["String"]["output"];
  /** Username which will be used to authenticate requests for cloning repositories. */
  username: Scalars["String"]["output"];
  vcsChecks: VcsCheck;
  /** Secret for webhooks originating from Bitbucket repositories. */
  webhookSecret: Scalars["String"]["output"];
  /** URL for webhooks originating from Bitbucket repositories. */
  webhookURL: Scalars["String"]["output"];
};

export type Blueprint = {
  __typename?: "Blueprint";
  /** CreatedAt is unix timestamp (in seconds) at which the blueprint was created */
  createdAt: Scalars["Int"]["output"];
  /** Indicates whether the blueprint has been deleted. */
  deleted: Scalars["Boolean"]["output"];
  /** Description of the blueprint. */
  description: Maybe<Scalars["String"]["output"]>;
  externalIntegrations: Array<BlueprintIntegrationAttachment>;
  /** Blueprint ID, used to identify a blueprint by name (slug) */
  id: Scalars["ID"]["output"];
  /** Inputs of the blueprint. */
  inputs: Array<BlueprintInput>;
  /** Labels associated with the blueprint. */
  labels: Array<Scalars["String"]["output"]>;
  /** Name of the blueprint */
  name: Scalars["String"]["output"];
  /** PublishedAt is unix timestamp (in seconds) at which the blueprint was published */
  publishedAt: Maybe<Scalars["Int"]["output"]>;
  /** RawTemplate of the blueprint. */
  rawTemplate: Scalars["String"]["output"];
  /** Space to which this blueprint belongs. */
  space: Space;
  /** List of keys that are used in the blueprint. */
  stackKeys: Array<Scalars["String"]["output"]>;
  /** State of the blueprint. Published or Draft. */
  state: BlueprintState;
  /** Unique identifier of the blueprint (ULID) */
  ulid: Scalars["String"]["output"];
  /** UpdatedAt is unix timestamp (in seconds) at which the blueprint was updated */
  updatedAt: Scalars["Int"]["output"];
};

export type BlueprintAttachInput = {
  /** External ID to attach */
  externalIntegration: Scalars["ID"]["input"];
};

export type BlueprintCloneInput = {
  /** Blueprint description */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Blueprint labels */
  labels: Array<Scalars["String"]["input"]>;
  /** Blueprint name */
  name: Scalars["String"]["input"];
  /** Space of the blueprint. */
  space: Scalars["ID"]["input"];
};

export type BlueprintCreateInput = {
  /** Blueprint description */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Blueprint labels */
  labels: Array<Scalars["String"]["input"]>;
  /** Blueprint name */
  name: Scalars["String"]["input"];
  /** Space of the blueprint. */
  space: Scalars["ID"]["input"];
  /** State of the blueprint */
  state: BlueprintState;
  /** Blueprint body */
  template: InputMaybe<Scalars["String"]["input"]>;
};

export enum BlueprintFormat {
  /** Invalid "zero value" for the type. */
  Unknown = "UNKNOWN",
  /** YAML format */
  Yaml = "YAML",
}

export type BlueprintInput = {
  __typename?: "BlueprintInput";
  /** Default of the blueprint input. */
  default: Scalars["String"]["output"];
  /** Description of the blueprint input. */
  description: Scalars["String"]["output"];
  /** Blueprint ID input. */
  id: Scalars["ID"]["output"];
  /** Name of the blueprint input. */
  name: Scalars["String"]["output"];
  /** Options of the blueprint input. */
  options: Array<Scalars["String"]["output"]>;
  /** Type of the blueprint input. */
  type: BlueprintInputType;
};

export enum BlueprintInputType {
  /** A boolean value */
  Boolean = "BOOLEAN",
  /** A floating point number */
  Float = "FLOAT",
  /** Long text input */
  LongText = "LONG_TEXT",
  /** An integer number */
  Number = "NUMBER",
  /** Secret text input */
  Secret = "SECRET",
  /** Multiple choice input */
  Select = "SELECT",
  /** Short text input */
  ShortText = "SHORT_TEXT",
}

export type BlueprintIntegrationAttachment = {
  __typename?: "BlueprintIntegrationAttachment";
  /** ID of the blueprint */
  blueprintID: Scalars["ID"]["output"];
  /** Name of the blueprint */
  blueprintName: Scalars["String"]["output"];
  blueprintSpaceDetails: Space;
  /** Is the attachment deleted */
  deleted: Scalars["Boolean"]["output"];
  /** ID of the attachment */
  id: Scalars["ID"]["output"];
  /** ID of the integration */
  integrationID: Scalars["ID"]["output"];
  /** Name of the integration */
  integrationName: Scalars["String"]["output"];
};

export type BlueprintParseResult = {
  __typename?: "BlueprintParseResult";
  /** List of errors */
  errors: Array<Scalars["String"]["output"]>;
  /** Inputs of the blueprint. */
  inputs: Array<BlueprintInput>;
};

export type BlueprintStackCreateInput = {
  templateInputs: InputMaybe<Array<BlueprintStackCreateInputPair>>;
};

export type BlueprintStackCreateInputPair = {
  id: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type BlueprintStackCreation = {
  __typename?: "BlueprintStackCreation";
  /**
   * An optional run ID which is returned if a run was started.
   * @deprecated Use the runIds field instead.
   */
  runID: Maybe<Scalars["ID"]["output"]>;
  /** Created runs after stack creation. */
  runIds: Array<Scalars["ID"]["output"]>;
  /**
   * Slug of the created stack.
   * @deprecated Use the stackIds field instead.
   */
  stackID: Scalars["ID"]["output"];
  /** Slug of the created stacks. */
  stackIds: Array<Scalars["ID"]["output"]>;
};

export enum BlueprintState {
  /** The blueprint is deleted */
  Deleted = "DELETED",
  /** Blueprint is in draft state */
  Draft = "DRAFT",
  /** Blueprint is in published state. */
  Published = "PUBLISHED",
  /** Invalid "zero value" for the type. */
  Unknown = "UNKNOWN",
}

export type BlueprintVersionedGroup = {
  __typename?: "BlueprintVersionedGroup";
  /** CreatedAt is unix timestamp (in seconds) at which the blueprint was created */
  createdAt: Scalars["Int"]["output"];
  /** Description of the blueprint. */
  description: Maybe<Scalars["String"]["output"]>;
  /** Blueprint ID, used to identify a blueprint by name (slug) */
  id: Scalars["ID"]["output"];
  /** Labels associated with the blueprint. */
  labels: Array<Scalars["String"]["output"]>;
  /** Name of the blueprint */
  name: Scalars["String"]["output"];
  /** Space to which this blueprint belongs. */
  space: Space;
  /** Unique identifier of the blueprint (ULID) */
  ulid: Scalars["String"]["output"];
  /** UpdatedAt is unix timestamp (in seconds) at which the blueprint was updated */
  updatedAt: Scalars["Int"]["output"];
};

export type BlueprintVersionedGroupCreateInput = {
  /** Blueprint description */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Blueprint labels */
  labels: Array<Scalars["String"]["input"]>;
  /** Blueprint name */
  name: Scalars["String"]["input"];
  /** Space of the blueprint. */
  space: Scalars["ID"]["input"];
};

export type BlueprintVersionedGroupsConnection = {
  __typename?: "BlueprintVersionedGroupsConnection";
  cursor: Scalars["String"]["output"];
  node: BlueprintVersionedGroup;
};

export type BlueprintWithGroupCreateInput = {
  /** Blueprint description */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Group in which the blueprint is created. */
  group: Scalars["ID"]["input"];
  /** Blueprint labels */
  labels: Array<Scalars["String"]["input"]>;
  /** State of the blueprint */
  state: BlueprintState;
  /** Blueprint body */
  template: InputMaybe<Scalars["String"]["input"]>;
  /** Version of the blueprint. Must be provided if published. */
  version: InputMaybe<Scalars["String"]["input"]>;
};

export type BlueprintWithGroupUpdateInput = {
  /** Blueprint description */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Blueprint labels */
  labels: Array<Scalars["String"]["input"]>;
  /** State of the blueprint */
  state: BlueprintState;
  /** Blueprint body */
  template: InputMaybe<Scalars["String"]["input"]>;
  /** Version of the blueprint. Must be provided if published. */
  version: InputMaybe<Scalars["String"]["input"]>;
};

export type BlueprintsConnection = {
  __typename?: "BlueprintsConnection";
  cursor: Scalars["String"]["output"];
  node: Blueprint;
};

export type ChangeValue = ChangeValueBoolean | ChangeValueFloat | ChangeValueString;

export type ChangeValueBoolean = {
  __typename?: "ChangeValueBoolean";
  value: Scalars["Boolean"]["output"];
};

export type ChangeValueFloat = {
  __typename?: "ChangeValueFloat";
  value: Scalars["Float"]["output"];
};

export type ChangeValueString = {
  __typename?: "ChangeValueString";
  value: Scalars["String"]["output"];
};

export type ClaimMapping = {
  __typename?: "ClaimMapping";
  entries: Array<ClaimMappingEntry>;
};

export type ClaimMappingEntry = {
  __typename?: "ClaimMappingEntry";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ClaimMappingEntryInput = {
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type ClaimMappingInput = {
  entries: Array<ClaimMappingEntryInput>;
};

/** Possible CloudFormation entity types. */
export type CloudFormationEntity = CloudFormationOutput | CloudFormationResource;

/** Metadata for a CloudFormation output. */
export type CloudFormationOutput = {
  __typename?: "CloudFormationOutput";
  description: Maybe<Scalars["String"]["output"]>;
  export: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

/** Metadata for a CloudFormation resource. */
export type CloudFormationResource = {
  __typename?: "CloudFormationResource";
  /** Logical resource ID. */
  logicalResourceId: Scalars["String"]["output"];
  /** Physical resource ID. */
  physicalResourceId: Scalars["String"]["output"];
  /** Definition of resource. */
  template: Scalars["String"]["output"];
};

/** Commit represents a GitHub commit. */
export type Commit = {
  __typename?: "Commit";
  /** GitHub username of the Commit author (optional). */
  authorLogin: Maybe<Scalars["String"]["output"]>;
  /** Name of the Commit author (required). */
  authorName: Scalars["String"]["output"];
  /** Git commit SHA. */
  hash: Scalars["String"]["output"];
  /** Short message associated with the commit (only the first line!); */
  message: Scalars["String"]["output"];
  /** Git commit tag */
  tag: Scalars["String"]["output"];
  /** Unix timestamp of the commit. */
  timestamp: Scalars["Int"]["output"];
  /** Full URL linking to the commit in GitHub. */
  url: Scalars["String"]["output"];
};

/**
 * ConfigElement is a stack runtime configuration element: environment variable
 * or file mount.
 */
export type ConfigElement = {
  __typename?: "ConfigElement";
  /** SHA256 checksum of the value. */
  checksum: Scalars["String"]["output"];
  /**
   * Unix timestamp representing the creation of the config element. Will be
   * empty if the element is computed at runtime (eg. by a cloud integration).
   * Note that config elements are considered immutable, so this value is reset
   * when the value changes.
   */
  createdAt: Maybe<Scalars["Int"]["output"]>;
  /** Description of the config element. */
  description: Scalars["String"]["output"];
  /** File mode (user/group/other) for the file mount. */
  fileMode: Maybe<Scalars["String"]["output"]>;
  /** Environment variable name or file mount relative path. */
  id: Scalars["ID"]["output"];
  /** Whether the config element is only known at runtime. */
  runtime: Scalars["Boolean"]["output"];
  /** Config element type. */
  type: ConfigType;
  /**
   * Environment variable value, only exposed if the variable is not
   * marked as write-only.
   */
  value: Maybe<Scalars["String"]["output"]>;
  /**
   * When a variable is marked as write-only, its value is only accessible
   * to the Run, but not in the API or the UI.
   */
  writeOnly: Scalars["Boolean"]["output"];
};

export type ConfigElementWithSource = {
  __typename?: "ConfigElementWithSource";
  /** Optional context if the element is coming from a context. */
  context: Maybe<StackContextAttachment>;
  /** Wrapped config element. */
  element: ConfigElement;
  /** Optional dependency if the element is coming from a dependent stack. */
  stackDependency: Maybe<StackDependency>;
};

/** Arguments used to create and update an individual ConfigElement. */
export type ConfigInput = {
  description: InputMaybe<Scalars["String"]["input"]>;
  fileMode: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  type: ConfigType;
  value: Scalars["String"]["input"];
  writeOnly: Scalars["Boolean"]["input"];
};

/** ConfigType is the type of the configuration element. */
export enum ConfigType {
  /** Environment variable. */
  EnvironmentVariable = "ENVIRONMENT_VARIABLE",
  /** File mounted in the workspace. */
  FileMount = "FILE_MOUNT",
}

export type Context = Hookable & {
  __typename?: "Context";
  /** Single stack attachment for this context. */
  attachedStack: Maybe<ContextStackAttachment>;
  /** List of stacks attached to this context. */
  attachedStacks: Array<ContextStackAttachment>;
  /** Full list of stack config elements. */
  config: Array<ConfigElement>;
  /** Single context config element. */
  configElement: Maybe<ConfigElement>;
  /** Unix timestamp representing the context creation. */
  createdAt: Scalars["Int"]["output"];
  /** Free-form context description for the users, supports Markdown. */
  description: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether any stacks are attached to this policy, either directly or via auto-attachment. */
  hasAttachedStacks: Scalars["Boolean"]["output"];
  /** List of hooks extending this context. */
  hooks: Hooks;
  /** Unique (within a single organization), immutable name of the context. */
  id: Scalars["ID"]["output"];
  /** User-defined labels set on the context. */
  labels: Array<Scalars["String"]["output"]>;
  /** Fancy, mutable name of the context to show in the UI. */
  name: Scalars["String"]["output"];
  /** ID of the space this Context belongs to. */
  space: Scalars["ID"]["output"];
  /** Details of the space this Context belongs to. */
  spaceDetails: Space;
  /** Unix timestamp of the last context update. */
  updatedAt: Scalars["Int"]["output"];
};

export type ContextAttachedStackArgs = {
  id: Scalars["ID"]["input"];
};

export type ContextConfigElementArgs = {
  id: Scalars["ID"]["input"];
};

/** ContextInput represents the data used to configure a Context. */
export type ContextInput = {
  configAttachments: InputMaybe<Array<ConfigInput>>;
  description: InputMaybe<Scalars["String"]["input"]>;
  hooks: InputMaybe<HooksInput>;
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
  stackAttachments: InputMaybe<Array<ContextStackAttachmentInput>>;
};

/** ContextStackAttachment is the stack attachment viewed from the Context. */
export type ContextStackAttachment = {
  __typename?: "ContextStackAttachment";
  /** Creation timestamp of the attachment. */
  createdAt: Scalars["Int"]["output"];
  /** Globally unique attachment identifier. */
  id: Scalars["ID"]["output"];
  /** Whether the stack is autoattached. */
  isAutoattached: Scalars["Boolean"]["output"];
  /** True if the attached stack is type of Module. */
  isModule: Scalars["Boolean"]["output"];
  /** The priority of the attachment. */
  priority: Scalars["Int"]["output"];
  /** Slug of the attached stack. */
  stackId: Scalars["ID"]["output"];
  /** Name of the attached stack. */
  stackName: Scalars["String"]["output"];
  /** Space details of the attached stack. */
  stackSpaceDetails: Space;
};

export type ContextStackAttachmentInput = {
  priority: Scalars["Int"]["input"];
  stack: Scalars["ID"]["input"];
};

export type ContextsConnection = {
  __typename?: "ContextsConnection";
  cursor: Scalars["String"]["output"];
  node: Context;
};

export type CustomVcsInput = {
  /** Free-form description of the integration. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** isDefault indicates whether this integration should be used as the default. If not provided, defaults to false. */
  isDefault: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Labels for the integration. */
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** The name of the integration */
  name: Scalars["String"]["input"];
  /** The space this VCS integration belongs to. */
  spaceID: Scalars["ID"]["input"];
  /** vcsChecks contains all the VCS checks enabled for this integration. If not provided, defaults to INDIVIDUAL */
  vcsChecks: InputMaybe<VcsCheck>;
};

export type CustomVcsUpdateInput = {
  /** Free-form description of the integration. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** The slug of the integration that should be updated. */
  id: Scalars["ID"]["input"];
  /** Labels for the integration. */
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * The slug of the space that the integration will belong to after
   * the update.
   */
  space: Scalars["ID"]["input"];
  /** vcsChecks contains all the VCS checks enabled for this integration. If not provided, defaults to 1 (Individual). */
  vcsChecks: InputMaybe<VcsCheck>;
};

export type DataPoint = {
  __typename?: "DataPoint";
  labels: Array<Scalars["String"]["output"]>;
  timestamp: Scalars["Int"]["output"];
  value: Scalars["Float"]["output"];
};

export type DebugInfo = {
  __typename?: "DebugInfo";
  /** Describes git commit of the current binary. Returns empty string in SaaS. */
  commitSha: Scalars["String"]["output"];
  /** Describes the self-hosted licence representation. If it's null, it means customer doesnt have a license.  Not valid in SaaS. */
  selfHostedLicence: Maybe<SelfHostedLicence>;
  /** Describes spacelift self-hosted version. Returns empty string in SaaS. */
  selfHostedVersion: Scalars["String"]["output"];
};

/** Delta is the number of resources affected by a Run in various ways. */
export type Delta = {
  __typename?: "Delta";
  /** Count of resources to create. */
  addCount: Scalars["Int"]["output"];
  /** Count of resources to modify in-place. */
  changeCount: Scalars["Int"]["output"];
  /** Count of resources to destroy. */
  deleteCount: Scalars["Int"]["output"];
  /** Count of failed resources. */
  failedCount: Scalars["Int"]["output"];
  /** Count of resources to be forgotten. */
  forgetCount: Scalars["Int"]["output"];
  /** Count of ignored resources. */
  ignoreCount: Scalars["Int"]["output"];
  /** Count of resources to import. */
  importCount: Scalars["Int"]["output"];
  /** Count of resources to be moved. */
  moveCount: Scalars["Int"]["output"];
  /** Count of ok resources. */
  okCount: Scalars["Int"]["output"];
  /** Count of rescued resources. */
  rescueCount: Scalars["Int"]["output"];
  /** Total number of resources in the state before applying the change. */
  resources: Scalars["Int"]["output"];
  /** Count of skipped resources. */
  skipCount: Scalars["Int"]["output"];
  /** Count of unreachable resources. */
  unreachableCount: Scalars["Int"]["output"];
};

/** DriftDetectionIntegration describes a Stack's drift detection configuration. */
export type DriftDetectionIntegration = {
  __typename?: "DriftDetectionIntegration";
  /** Deleted boolean */
  deleted: Scalars["Boolean"]["output"];
  /**
   * Whether to ignore stack state when running drift detection.
   * If False, drift detection only runs on green finished stacks.
   */
  ignoreState: Scalars["Boolean"]["output"];
  /**
   * When the next detection run will be scheduled.
   * Null if detection or reconciliation is in progress.
   */
  nextSchedule: Maybe<Scalars["Int"]["output"]>;
  /** Whether to schedule a reconciliation run when drift has been detected. */
  reconcile: Scalars["Boolean"]["output"];
  /** Drift detection will be scheduled according to this cron schedule expression. */
  schedule: Array<Scalars["String"]["output"]>;
  /** Cron schedule expressions will apply for this timezone. */
  timezone: Scalars["String"]["output"];
};

/** Arguments used to create and update an individual DriftDetectionIntegration configuration. */
export type DriftDetectionIntegrationInput = {
  /**
   * Whether to ignore stack state when running drift detection.
   * If False, drift detection only runs on green finished stacks.
   */
  ignoreState: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Whether to schedule a reconciliation run when drift has been detected. */
  reconcile: Scalars["Boolean"]["input"];
  /** Drift detection will be scheduled according to this cron schedule expression. */
  schedule: Array<Scalars["String"]["input"]>;
  /** Cron schedule expressions will apply for this timezone. Defaults to UTC. */
  timezone: InputMaybe<Scalars["String"]["input"]>;
};

export type DriftDetectionIntegrationTile = {
  __typename?: "DriftDetectionIntegrationTile";
  driftDetetionIntegration: DriftDetectionIntegration;
  stackTile: StackTile;
};

/** EffectiveTerragruntVersionResult represents the effective Terragrunt and Terraform versions to be used in a stack. */
export type EffectiveTerragruntVersionResult = {
  __typename?: "EffectiveTerragruntVersionResult";
  /** Effective Terraform version to be used in a stack. Returns null if tool is set to MANUALLY_PROVISIONED. */
  effectiveTerraformVersion: Maybe<Scalars["String"]["output"]>;
  /** Effective Terragrunt version to be used in a stack. Returns null if tool is set to MANUALLY_PROVISIONED. */
  effectiveTerragruntVersion: Maybe<Scalars["String"]["output"]>;
  /** The tool used to perform the compatibility check. */
  tool: TerragruntTool;
};

export type EntityAttributeChange = {
  __typename?: "EntityAttributeChange";
  changeForPhase: EntityAttributeChangeForPhase;
  /** JSON path to the changed property */
  path: Scalars["String"]["output"];
};

export type EntityAttributeChangeForPhase = {
  __typename?: "EntityAttributeChangeForPhase";
  /** The property value after, nil if removed. */
  after: Maybe<ChangeValue>;
  /** The property value before, nil if added. */
  before: Maybe<ChangeValue>;
  metadata: EntityAttributeChangeForPhaseMetadata;
};

export type EntityAttributeChangeForPhaseMetadata = {
  __typename?: "EntityAttributeChangeForPhaseMetadata";
  phase: EntityChangePhase;
  type: EntityChangeType;
};

export type EntityChangeCollectionInputV2 = {
  /**
   * List the entity changes after this ID
   * When paginating the PageInfo.endCursor returned contains the next after value
   */
  after: InputMaybe<Scalars["String"]["input"]>;
  /** List the entity changes with in this change phase */
  changePhase: InputMaybe<EntityChangePhase>;
  /** List the entity changes with this change type */
  changeType: InputMaybe<EntityChangeType>;
  /** List the first * items */
  first: InputMaybe<Scalars["Int"]["input"]>;
};

export type EntityChangeConnection = {
  __typename?: "EntityChangeConnection";
  cursor: Scalars["String"]["output"];
  node: EntityChangeV3;
};

export type EntityChangeConnectionV2 = {
  __typename?: "EntityChangeConnectionV2";
  cursor: Scalars["String"]["output"];
  node: EntityChangeV2;
};

export type EntityChangeMetadata = {
  __typename?: "EntityChangeMetadata";
  moved: Scalars["Boolean"]["output"];
  result: EntityChangeResult;
  type: EntityChangeType;
};

export enum EntityChangePhase {
  /** entity change was performed during the applying phase */
  Apply = "APPLY",
  /** entity change was performed during a drift detection run */
  Drift = "DRIFT",
  /** entity change was performed during the planning phase */
  Plan = "PLAN",
}

/** Status of a entity change. */
export enum EntityChangeResult {
  /** Change was planned and has not been applied. */
  Failed = "FAILED",
  /**
   * Change was planned and has been applied but has partially failed.
   * Change type is RECREATE and either ADD or DELETE has not been applied.
   */
  Partial = "PARTIAL",
  /** Change has not been applied. */
  Planned = "PLANNED",
  /** Change was planned and has been applied. */
  Successful = "SUCCESSFUL",
  /** Change result is unknown */
  Unknown = "UNKNOWN",
  /** Change which wasn't planned but is being applied. */
  Unplanned = "UNPLANNED",
}

/** Type of a entity change. */
export enum EntityChangeType {
  /** A new entity will be created. */
  Add = "ADD",
  /** An existing entity will be updated in-place. */
  Change = "CHANGE",
  /** An existing entity will be deleted. */
  Delete = "DELETE",
  /** An existing entity will be removed from the state but not destroyed. */
  Forget = "FORGET",
  /** A task that resulted with status ignored. */
  Ignored = "IGNORED",
  /** A new entity will be imported. */
  Import = "IMPORT",
  /** An existing entity will be moved. */
  Move = "MOVE",
  /** A no-op change. */
  Noop = "NOOP",
  /**
   * Ansible specific types
   * A task that resulted with status ok.
   */
  Ok = "OK",
  /** A read, no-op change. */
  Read = "READ",
  /** An existing entity will be deleted and added. */
  Recreate = "RECREATE",
  /** An existing entity will replaced by first creating a replacement and then deleting the original. */
  ReplaceCreateBeforeDestroy = "REPLACE_CREATE_BEFORE_DESTROY",
  /** An existing entity will replaced by first deleting the original and then creating a replacement. */
  ReplaceDestroyBeforeCreate = "REPLACE_DESTROY_BEFORE_CREATE",
  /** A task that resulted with status rescued. */
  Rescued = "RESCUED",
  /** A task that resulted with status skipped. */
  Skipped = "SKIPPED",
}

/** A Terraform managed entity change description. */
export type EntityChangeV2 = {
  __typename?: "EntityChangeV2";
  /** ManagedEntity after change. */
  entity: ManagedEntityV2;
  /** Phase */
  phase: EntityChangePhase;
  /** Change type */
  type: EntityChangeType;
};

/** A Terraform managed entity change description. */
export type EntityChangeV3 = {
  __typename?: "EntityChangeV3";
  /** The address of the resource */
  address: Scalars["String"]["output"];
  /**
   * Changes here is a list of the changes that happened to the individual resource
   * For example: if someone edited the tags on an EC2 instance, this would be a list of the tags that changed
   */
  attributeChanges: Array<EntityAttributeChange>;
  /** This holds the resultant change type for the resource as a whole (ie, replaced, created etc) */
  metadata: EntityChangeMetadata;
  /** The previous address of the resource in the case of the resource being moved */
  previousAddress: Scalars["String"]["output"];
};

export type EntityChangesInputV3 = {
  changePhase: InputMaybe<EntityChangePhase>;
  changeType: InputMaybe<EntityChangeType>;
};

export type EntityChangesV2 = {
  __typename?: "EntityChangesV2";
  edges: Array<EntityChangeConnectionV2>;
  pageInfo: PageInfo;
};

/** Vendor-specific managed entity metadata. */
export type EntityVendor =
  | EntityVendorAnsible
  | EntityVendorCloudFormation
  | EntityVendorKubernetes
  | EntityVendorPulumi
  | EntityVendorTerraform;

export type EntityVendorAnsible = {
  __typename?: "EntityVendorAnsible";
  /** Type-specific managed entity metadata. */
  ansible: AnsibleEntity;
};

export type EntityVendorCloudFormation = {
  __typename?: "EntityVendorCloudFormation";
  /** Type-specific managed entity metadata. */
  cloudFormation: CloudFormationEntity;
};

export type EntityVendorKubernetes = {
  __typename?: "EntityVendorKubernetes";
  /** Type-specific managed entity metadata. */
  kubernetes: KubernetesEntity;
};

export type EntityVendorPulumi = {
  __typename?: "EntityVendorPulumi";
  /** Type-specific managed entity metadata. */
  pulumi: PulumiEntity;
};

export type EntityVendorTerraform = {
  __typename?: "EntityVendorTerraform";
  /** Type-specific managed entity metadata. */
  terraform: TerraformEntity;
};

export type EnvVar = {
  __typename?: "EnvVar";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type EnvVarInput = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

/** A generic environment variable structure */
export type EnvironmentVariable = {
  /** Key of an environment variable */
  key: Scalars["String"]["input"];
  /** Value of an environment variable */
  value: Scalars["String"]["input"];
};

/**
 * ExternalIdForStackResult contains information needed for creating AWS AssumeRole
 * policies for an integration stack attachment.
 */
export type ExternalIdForStackResult = {
  __typename?: "ExternalIdForStackResult";
  /** An example AssumeRole policy statement that allows the stack to assume the role. */
  assumeRolePolicyStatement: Scalars["String"]["output"];
  /** The external ID that would be used when assuming the role for the stack. */
  externalId: Scalars["String"]["output"];
};

export type ExternalIntegration = {
  __typename?: "ExternalIntegration";
  /** API key related to the integration */
  apiKey: ApiKey;
  attachedBlueprints: Array<BlueprintIntegrationAttachment>;
  /** Timestamp of when the integration was created */
  createdAt: Scalars["Int"]["output"];
  /** Type-specific configuration */
  customConfig: ExternalIntegrationConfig;
  /** Whether the integration is deleted */
  deleted: Scalars["Boolean"]["output"];
  /** Description of the integration */
  description: Maybe<Scalars["String"]["output"]>;
  /** Unique identifier for the integration */
  id: Scalars["ID"]["output"];
  /** Name of the integration */
  name: Scalars["String"]["output"];
  /** Associated space */
  spaceDetails: Space;
  /** Type of the integration */
  type: Scalars["String"]["output"];
};

export type ExternalIntegrationConfig = ExternalIntegrationConfigServiceNow;

export type ExternalIntegrationConfigServiceNow = {
  __typename?: "ExternalIntegrationConfigServiceNow";
  /** Base URL for the ServiceNow instance */
  baseURL: Scalars["String"]["output"];
  /** Username for authentication */
  username: Scalars["String"]["output"];
};

export type ExternalIntegrationInput = {
  /** Additional integration-specific data */
  customConfigData: IntegrationCustomConfigData;
  /** Description of the integration (optional) */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Name of the integration */
  name: Scalars["String"]["input"];
  /** Space to use for this integration */
  space: Scalars["ID"]["input"];
  /** Type of the integration */
  type: Scalars["String"]["input"];
};

/** Used to evaluate a boolean flag. */
export type FeatureFlagBoolEvaluation = {
  /** The default value to be returned if no value is set for the flag. */
  defaultValue: Scalars["Boolean"]["input"];
  /** The name of the flag. */
  name: Scalars["String"]["input"];
};

/**
 * Represents an individual flag that you want to evaluate. Only one of intEvaluation
 * or boolEvaluation should be provided, depending on the type of flag you're evaluating.
 */
export type FeatureFlagEvaluation = {
  /** boolEvaluation is used when evaluating a boolean feature flag. */
  boolEvaluation: InputMaybe<FeatureFlagBoolEvaluation>;
  /** intEvaluation is used when evaluating an int feature flag. */
  intEvaluation: InputMaybe<FeatureFlagIntEvaluation>;
};

/** The input to the evaluateFeatureFlags request. Used to evaluate feature flags in Self-Hosted instances. */
export type FeatureFlagEvaluationInput = {
  /** The set of flags to evaluate. */
  flags: Array<FeatureFlagEvaluation>;
};

/** The result of evaluating a feature flag. */
export type FeatureFlagEvaluationResult = {
  __typename?: "FeatureFlagEvaluationResult";
  /** The name of the flag. */
  name: Scalars["String"]["output"];
  /** The value of the flag. */
  value: Scalars["String"]["output"];
};

/** Used to evaluate an int flag. */
export type FeatureFlagIntEvaluation = {
  /** The default value to be returned if no value is set for the flag. */
  defaultValue: Scalars["Int"]["input"];
  /** The name of the flag. */
  name: Scalars["String"]["input"];
};

export type GpgKey = {
  __typename?: "GPGKey";
  /** Timestamp of when the key was created. */
  createdAt: Scalars["Int"]["output"];
  /** Login of the user who created the key. */
  createdBy: Scalars["String"]["output"];
  /** Optional, human-readable description of the GPG key. */
  description: Maybe<Scalars["String"]["output"]>;
  /** Globally unique key identifier. */
  id: Scalars["ID"]["output"];
  /** Name of the key. */
  name: Scalars["String"]["output"];
  /** Timestamp of when the key was revoked, if it has been revoked. */
  revokedAt: Maybe<Scalars["Int"]["output"]>;
  /** Login of the user who revoked the key, if it has been revoked. */
  revokedBy: Maybe<Scalars["String"]["output"]>;
  /** Timestamp of when the key was last updated. */
  updatedAt: Scalars["Int"]["output"];
};

export type GenericFormDescription = {
  __typename?: "GenericFormDescription";
  name: Scalars["String"]["output"];
  /** JSON encoded properties of the form */
  properties: Maybe<Scalars["String"]["output"]>;
};

/** GitHubAppManifest represents the data needed by the GitHub app creation form. */
export type GitHubAppManifest = {
  __typename?: "GitHubAppManifest";
  /** Endpoint for the POST request to GitHub, including temporary state. */
  endpoint: Scalars["String"]["output"];
  /** The content of the manifest. */
  manifest: Scalars["String"]["output"];
};

/** Arguments used to create a GitHub App manifest form. */
export type GitHubAppManifestInput = {
  /** API host. If not provided, uses https://api.github.com */
  apiHost: InputMaybe<Scalars["String"]["input"]>;
  /** GUI host name. If not provided, uses https://github.com */
  host: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Name of the GitHub account that is going to be the owner of the application.
   * Empty for personal accounts.
   */
  ownerName: InputMaybe<Scalars["String"]["input"]>;
  /** Type of the GitHub account that is going to be the owner of the application. */
  ownerType: AccountType;
};

export enum GitHubAppStatus {
  AppNotFound = "APP_NOT_FOUND",
  CouldNotDecode = "COULD_NOT_DECODE",
  Error = "ERROR",
  Ok = "OK",
  VcsAgentError = "VCS_AGENT_ERROR",
  VcsAgentErrorForbidden = "VCS_AGENT_ERROR_FORBIDDEN",
  VcsAgentErrorNoPoolAgentConnected = "VCS_AGENT_ERROR_NO_POOL_AGENT_CONNECTED",
  VcsAgentErrorTimeout = "VCS_AGENT_ERROR_TIMEOUT",
  VcsAgentPoolNotFound = "VCS_AGENT_POOL_NOT_FOUND",
}

/** GithubEnterpriseIntegration shows GitHub Enterprise integration details. */
export type GithubEnterpriseIntegration = {
  __typename?: "GithubEnterpriseIntegration";
  apiHost: Scalars["String"]["output"];
  appID: Scalars["String"]["output"];
  appUrl: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  githubAppExists: Scalars["Boolean"]["output"];
  githubAppStatus: GitHubAppStatus;
  id: Scalars["ID"]["output"];
  installations: Array<GithubEnterpriseIntegrationInstallation>;
  isDefault: Scalars["Boolean"]["output"];
  labels: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  space: Maybe<Space>;
  userFacingHost: Scalars["String"]["output"];
  vcsChecks: VcsCheck;
  webhookSecret: Scalars["String"]["output"];
  webhookUrl: Scalars["String"]["output"];
};

/**
 * GithubEnterpriseIntegrationInstallation represents a single GitHub Enterprise
 * custom app installation.
 */
export type GithubEnterpriseIntegrationInstallation = {
  __typename?: "GithubEnterpriseIntegrationInstallation";
  accountName: Scalars["String"]["output"];
  accountType: Maybe<Scalars["String"]["output"]>;
  installationId: Scalars["String"]["output"];
  installationUrl: Maybe<Scalars["String"]["output"]>;
};

/** GithubIntegration shows build-in GitHub integration details. */
export type GithubIntegration = {
  __typename?: "GithubIntegration";
  vcsChecks: VcsCheck;
};

/** GitlabIntegration shows Gitlab integration details. */
export type GitlabIntegration = {
  __typename?: "GitlabIntegration";
  apiHost: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  labels: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  space: Maybe<Space>;
  userFacingHost: Scalars["String"]["output"];
  vcsChecks: VcsCheck;
  webhookSecret: Scalars["String"]["output"];
  webhookUrl: Scalars["String"]["output"];
};

export type Hookable = {
  /** Hooks extending this entity. */
  hooks: Hooks;
};

/** Hooks represents a collection of hooks extending the standard run lifecycle. */
export type Hooks = {
  __typename?: "Hooks";
  /** List of after-apply scripts. */
  afterApply: Array<Scalars["String"]["output"]>;
  /** List of after-destroy scripts. */
  afterDestroy: Array<Scalars["String"]["output"]>;
  /** List of after-init scripts. */
  afterInit: Array<Scalars["String"]["output"]>;
  /** List of after-perform scripts. */
  afterPerform: Array<Scalars["String"]["output"]>;
  /** List of after-plan scripts. */
  afterPlan: Array<Scalars["String"]["output"]>;
  /** List of after-run scripts. */
  afterRun: Array<Scalars["String"]["output"]>;
  /** List of before-apply scripts. */
  beforeApply: Array<Scalars["String"]["output"]>;
  /** List of before-destroy scripts. */
  beforeDestroy: Array<Scalars["String"]["output"]>;
  /** List of before-init scripts. */
  beforeInit: Array<Scalars["String"]["output"]>;
  /** List of before-perform scripts. */
  beforePerform: Array<Scalars["String"]["output"]>;
  /** List of before-plan scripts. */
  beforePlan: Array<Scalars["String"]["output"]>;
};

export type HooksInput = {
  afterApply: Array<Scalars["String"]["input"]>;
  afterDestroy: Array<Scalars["String"]["input"]>;
  afterInit: Array<Scalars["String"]["input"]>;
  afterPerform: Array<Scalars["String"]["input"]>;
  afterPlan: Array<Scalars["String"]["input"]>;
  afterRun: Array<Scalars["String"]["input"]>;
  beforeApply: Array<Scalars["String"]["input"]>;
  beforeDestroy: Array<Scalars["String"]["input"]>;
  beforeInit: Array<Scalars["String"]["input"]>;
  beforePerform: Array<Scalars["String"]["input"]>;
  beforePlan: Array<Scalars["String"]["input"]>;
};

export enum IdentityProvider {
  Github = "GITHUB",
  Gitlab = "GITLAB",
  Google = "GOOGLE",
  Microsoft = "MICROSOFT",
  Oidc = "OIDC",
  Saml = "SAML",
  Unknown = "UNKNOWN",
}

export type IntegrationCustomConfigData = {
  /** ServiceNow integration-specific data (optional) */
  serviceNow: InputMaybe<ServiceNowExtraDataInput>;
};

export type KeyValuePair = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type KeyValuePairType = {
  __typename?: "KeyValuePairType";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

/** Possible Kubernetes entity types. */
export type KubernetesEntity = KubernetesResource | KubernetesRoot;

/** Metadata for a Kubernetes resource. */
export type KubernetesResource = {
  __typename?: "KubernetesResource";
  /** Data of the Kubernetes resource. */
  data: Scalars["String"]["output"];
};

/** Metadata for a Kubernetes infra root. */
export type KubernetesRoot = {
  __typename?: "KubernetesRoot";
  /**
   * This field exists solely for the purpose of adhering to the GraphQL spec,
   * which requires presence of at least one field in a type.
   */
  phantom: Scalars["Boolean"]["output"];
};

export enum KubernetesWorkflowTool {
  /** A custom workflow tool. */
  Custom = "CUSTOM",
  /** Standard workflow using kubectl. */
  Kubernetes = "KUBERNETES",
}

export enum LlmVendor {
  AwsBedrock = "AWS_BEDROCK",
  GoogleAiStudio = "GOOGLE_AI_STUDIO",
}

export type LicenseDateRange = {
  __typename?: "LicenseDateRange";
  /** Describes the start time of the license. */
  begin: Scalars["Int"]["output"];
  /** Describes the end time of the license. May be null if the license has no end date. */
  end: Maybe<Scalars["Int"]["output"]>;
};

export type LocalWorkspaceUpload = {
  __typename?: "LocalWorkspaceUpload";
  id: Scalars["ID"]["output"];
  /** HTTP headers that should be sent with the upload request. */
  uploadHeaders: Maybe<StringMap>;
  /** URL to upload the archived workspace to. */
  uploadUrl: Scalars["String"]["output"];
};

/** LogMessage represents a single log line. */
export type LogMessage = {
  __typename?: "LogMessage";
  /** Actual message. */
  message: Scalars["String"]["output"];
  /** Unix timestamp representing log line creation. */
  timestamp: Scalars["Int"]["output"];
};

export type LogsDownloadUrl = {
  __typename?: "LogsDownloadURL";
  expired: Scalars["Boolean"]["output"];
  url: Maybe<Scalars["String"]["output"]>;
};

/** LogsResponse is a response to a query for a batch of logs. */
export type LogsResponse = {
  __typename?: "LogsResponse";
  /** Whether the log stream even exists. */
  exists: Scalars["Boolean"]["output"];
  /** Whether the logs stream has expired. */
  expired: Scalars["Boolean"]["output"];
  /** Is the log stream finished. */
  finished: Scalars["Boolean"]["output"];
  /** Is more messages available. */
  hasMore: Scalars["Boolean"]["output"];
  /** List of messages. */
  messages: Array<LogMessage>;
  /** Token used to retrieve the next batch of log messages. */
  nextToken: Maybe<Scalars["String"]["output"]>;
};

export type ManagedEntitiesConnection = {
  __typename?: "ManagedEntitiesConnection";
  cursor: Scalars["String"]["output"];
  node: ManagedEntity;
};

export type ManagedEntitiesGroup = {
  count: Scalars["Int"]["output"];
  drifted: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type ManagedEntitiesGroupedByProviders = ManagedEntitiesGroup & {
  __typename?: "ManagedEntitiesGroupedByProviders";
  /** Number of resources belonging to this provider */
  count: Scalars["Int"]["output"];
  /** Number of drifted resources beloning to this provider */
  drifted: Scalars["Int"]["output"];
  /** Provider name */
  id: Scalars["ID"]["output"];
  /** Last updated resource belonging to this provider */
  updatedAt: Scalars["Int"]["output"];
};

export type ManagedEntitiesGroupedByProvidersConnection = {
  __typename?: "ManagedEntitiesGroupedByProvidersConnection";
  cursor: Scalars["String"]["output"];
  node: ManagedEntitiesGroupedByProviders;
};

export type ManagedEntitiesGroupedByStacks = ManagedEntitiesGroup & {
  __typename?: "ManagedEntitiesGroupedByStacks";
  /** Number of resources in this stack */
  count: Scalars["Int"]["output"];
  /** Number of drifted resources in this stack */
  drifted: Scalars["Int"]["output"];
  /** Stack ID */
  id: Scalars["ID"]["output"];
  /** Name of the stack to which the resource belongs */
  stackName: Scalars["String"]["output"];
  /** Space ID of the stack to which the resource belongs */
  stackSpaceID: Scalars["ID"]["output"];
  /** Space name of the stack to which the resource belongs */
  stackSpaceName: Scalars["String"]["output"];
  /** Vendor of the stack to which the resource belongs */
  stackVendor: StackVendor;
  /** Last updated resource in this stack */
  updatedAt: Scalars["Int"]["output"];
};

export type ManagedEntitiesGroupedByStacksConnection = {
  __typename?: "ManagedEntitiesGroupedByStacksConnection";
  cursor: Scalars["String"]["output"];
  node: ManagedEntitiesGroupedByStacks;
};

export type ManagedEntitiesGroupedByTypes = ManagedEntitiesGroup & {
  __typename?: "ManagedEntitiesGroupedByTypes";
  /** Number of resources of this type */
  count: Scalars["Int"]["output"];
  /** Number of drifted resources of this type */
  drifted: Scalars["Int"]["output"];
  /** Resource type */
  id: Scalars["ID"]["output"];
  /** Last updated resource of this type */
  updatedAt: Scalars["Int"]["output"];
};

export type ManagedEntitiesGroupedByTypesConnection = {
  __typename?: "ManagedEntitiesGroupedByTypesConnection";
  cursor: Scalars["String"]["output"];
  node: ManagedEntitiesGroupedByTypes;
};

/** Managed infrastructure entity */
export type ManagedEntity = {
  __typename?: "ManagedEntity";
  /** Address of the ManagedEntity in the module. */
  address: Scalars["String"]["output"];
  /** Run which created this entity. */
  creator: Maybe<Run>;
  /** Unix timestamp of when drift was detected on this entity, if ever. */
  drifted: Maybe<Scalars["Int"]["output"]>;
  /** ID of the ManagedEntity in Spacelift. */
  id: Scalars["ID"]["output"];
  /** ManagedEntity Name. */
  name: Scalars["String"]["output"];
  /** Parent of this entity. May be a module or may be another entity. */
  parent: Scalars["String"]["output"];
  /** Stack id of this entity. */
  stackId: Scalars["ID"]["output"];
  /** Stack name of this entity. */
  stackName: Scalars["String"]["output"];
  /** Stack vendor of this entity. */
  stackVendor: StackVendor;
  /** Custom metadata attached to the entity by a third-party integration. */
  thirdPartyMetadata: Maybe<Scalars["String"]["output"]>;
  /** ManagedEntity Type. */
  type: Scalars["String"]["output"];
  /** UpdatedAt is unix timestamp (in seconds) at which the entity was updated */
  updatedAt: Scalars["Int"]["output"];
  /** Run which last updated this entity. */
  updater: Maybe<Run>;
  /** Vendor specific metadata of this entity. */
  vendor: EntityVendor;
};

/** Managed infrastructure entity V2 */
export type ManagedEntityV2 = {
  __typename?: "ManagedEntityV2";
  /** Address of the ManagedEntity in the module. */
  address: Scalars["String"]["output"];
  /** Run which created this entity. */
  creator: Maybe<Run>;
  /** Unix timestamp of when drift was detected on this entity, if ever. */
  drifted: Maybe<Scalars["Int"]["output"]>;
  /** ID of the ManagedEntity in Spacelift. */
  id: Scalars["ID"]["output"];
  /** ManagedEntity Name. */
  name: Scalars["String"]["output"];
  /** Parent of this entity. May be a module or may be another entity. */
  parent: Scalars["String"]["output"];
  /** Custom metadata attached to the entity by a third-party integration. */
  thirdPartyMetadata: Maybe<Scalars["String"]["output"]>;
  /** ManagedEntity Type. */
  type: Scalars["String"]["output"];
  /** Run which last updated this entity. */
  updater: Maybe<Run>;
};

/** This represent a managed state version */
export type ManagedStateVersion = {
  __typename?: "ManagedStateVersion";
  /** The unique ID of the state version */
  id: Scalars["ID"]["output"];
  /** True if the state has been manually imported */
  imported: Scalars["Boolean"]["output"];
  /** When the version is a rollback to a manually imported state this will be set to imported state timestamp */
  importedTimestamp: Maybe<Scalars["Int"]["output"]>;
  /** The username that initiated the import */
  importedUsername: Maybe<Scalars["String"]["output"]>;
  /** Unix timestamp of the last time the state has been modified */
  lastModified: Scalars["Int"]["output"];
  /** Indicate if this state is a rollback */
  rollback: Scalars["Boolean"]["output"];
  /** The username that initiated the rollback */
  rollbackUsername: Maybe<Scalars["String"]["output"]>;
  /**
   * Represents the run that has generated the current version of the state
   * If the version is a rollback, the run will be the one that generated the rolled back version
   */
  run: Maybe<Run>;
  /** The size of the state in bytes */
  size: Scalars["Int"]["output"];
};

/** ManagedStateVersionConnection represents a single worker pool in a page of results. */
export type ManagedStateVersionConnection = {
  __typename?: "ManagedStateVersionConnection";
  cursor: Scalars["String"]["output"];
  node: ManagedStateVersion;
};

export type ManagedStateVersionOutput = {
  __typename?: "ManagedStateVersionOutput";
  edges: Array<ManagedStateVersionConnection>;
  pageInfo: PageInfo;
};

export type ManagedUser = {
  __typename?: "ManagedUser";
  /** List of permissions given explicitly to given user. */
  accessRules: Array<SpaceAccessRule>;
  /** Number of groups in your identity provider that this user was a member of on last login. */
  groupCount: Scalars["Int"]["output"];
  /** Globally unique, immutable ID of ManagedUser. */
  id: Scalars["ID"]["output"];
  /** Email through which user was invited to given account. Can be empty. */
  invitationEmail: Maybe<Scalars["String"]["output"]>;
  /** Unix timestamp (in seconds) at which user has last authenticated. */
  lastLoginTime: Maybe<Scalars["Int"]["output"]>;
  /** Groups in your identity provider that this user was a member of on last login. */
  latestGroups: Array<Scalars["String"]["output"]>;
  /** Invitation details for the latest invitation sent to given user. Can be empty. */
  latestInvitation: Maybe<ManagedUserInvitation>;
  /** Identity provider used for authenticating given user. */
  loginMethod: IdentityProvider;
  /** Role describes if user is an admin or ordinary user. */
  role: UserRole;
  /** ID of slack user that will have the same permissions as given managed user when using Spacelift through Slack integration. */
  slackMemberID: Maybe<Scalars["String"]["output"]>;
  /** Number of spaces user was explicitly given permissions to. */
  spaceCount: Scalars["Int"]["output"];
  /** Status is user status. */
  status: UserStatus;
  /** Roles that are binded to this user. */
  userRoleBindings: Array<UserRoleBinding>;
  /** Username from account's identity provider. */
  username: Scalars["String"]["output"];
};

export type ManagedUserGroup = {
  __typename?: "ManagedUserGroup";
  /** List of permissions given explicitly to given user group. */
  accessRules: Array<SpaceAccessRule>;
  /** Name of user group from account's identity provider. */
  groupName: Scalars["String"]["output"];
  /** Globally unique, immutable ID of ManagedUserGroup. */
  id: Scalars["ID"]["output"];
  /** Identity provider authorized with group permissions. */
  identityProvider: IdentityProvider;
  /** Unix timestamp (in seconds) at which user from this group was last authenticated. */
  lastLoginTime: Maybe<Scalars["Int"]["output"]>;
  /** Number of members of this group who logged into this account. */
  memberCount: Scalars["Int"]["output"];
  /** Members of this group */
  members: Array<Scalars["String"]["output"]>;
  /** Number of spaces user group was explicitly given permissions to. */
  spaceCount: Scalars["Int"]["output"];
  /** Status is user group status. */
  status: UserGroupStatus;
};

export type ManagedUserGroupCreateInput = {
  /** new access rules */
  accessRules: Array<SpaceAccessRuleInput>;
  groupName: Scalars["String"]["input"];
};

export type ManagedUserGroupIntegration = {
  __typename?: "ManagedUserGroupIntegration";
  /** List of permissions given explicitly to given user group. */
  accessRules: Array<SpaceAccessRule>;
  /** Globally unique, immutable ID of ManagedUserGroupIntegration. */
  id: Scalars["ID"]["output"];
  /** Human-readable name of the integration. */
  integrationName: Scalars["String"]["output"];
  /** Specifies which fields of this integration are relevant and how they should be utilized. */
  integrationType: ManagedUserGroupIntegrationType;
  /** ID of slack channel that this ManagedUserGroupIntegration provides permissions to. */
  slackChannelID: Maybe<Scalars["String"]["output"]>;
};

export type ManagedUserGroupIntegrationCreateInput = {
  /** new access rules */
  accessRules: Array<SpaceAccessRuleInput>;
  /** user-picked human-readable integration name used for display purposes */
  integrationName: Scalars["String"]["input"];
  /** type of integration that should be created */
  integrationType: ManagedUserGroupIntegrationType;
  /** id of slack channel that should be granted specified permissions */
  slackChannelID: Scalars["String"]["input"];
};

export enum ManagedUserGroupIntegrationType {
  Slack = "SLACK",
}

export type ManagedUserGroupIntegrationUpdateInput = {
  /** new access rules */
  accessRules: Array<SpaceAccessRuleInput>;
  /** unique identifier of modified user group integration */
  id: Scalars["ID"]["input"];
  /** user-picked human-readable integration name used for display purposes */
  integrationName: Scalars["String"]["input"];
  /** id of slack channel that should be granted specified permissions */
  slackChannelID: Scalars["String"]["input"];
};

export type ManagedUserGroupUpdateInput = {
  /** new access rules */
  accessRules: Array<SpaceAccessRuleInput>;
  /** Globally unique, immutable ID of ManagedUserGroup. */
  id: Scalars["ID"]["input"];
};

export type ManagedUserInvitation = {
  __typename?: "ManagedUserInvitation";
  /** Unix timestamp (in seconds) at which this invitation was issued. */
  createdAt: Scalars["Int"]["output"];
  /** Unix timestamp (in seconds) at which the invitation was sent. Can be empty for unsent invitations. */
  sentAt: Maybe<Scalars["Int"]["output"]>;
};

export type ManagedUserInviteInput = {
  /** Access rules for the user. */
  accessRules: Array<SpaceAccessRuleInput>;
  /** Email address of the user to invite. */
  email: InputMaybe<Scalars["String"]["input"]>;
  /** Email address of the user to invite. */
  invitationEmail: InputMaybe<Scalars["String"]["input"]>;
  /** new slack member ID */
  slackMemberID: InputMaybe<Scalars["String"]["input"]>;
  /** Unique identifier of a user within an identity provider. */
  username: Scalars["String"]["input"];
};

export type ManagedUserInviteInputV2 = {
  /** Email address of the user to invite. */
  invitationEmail: InputMaybe<Scalars["String"]["input"]>;
  /** Access rules for the user. */
  roleBindings: Array<RoleBindingInput>;
  /** new slack member ID */
  slackMemberID: InputMaybe<Scalars["String"]["input"]>;
  /** Unique identifier of a user within an identity provider. */
  username: Scalars["String"]["input"];
};

export type ManagedUserUpdateInput = {
  /** new access rules */
  accessRules: Array<SpaceAccessRuleInput>;
  /** Globally unique, immutable ID of ManagedUser to update. */
  id: Scalars["ID"]["input"];
  /** new slack member ID */
  slackMemberID: InputMaybe<Scalars["String"]["input"]>;
};

export type MetricsInput = {
  /** End time of the metrics */
  endTime: Scalars["Int"]["input"];
  /** Start time of the metrics */
  startTime: Scalars["Int"]["input"];
};

/** Module is a versioned and tested reusable collection of resources */
export type Module = {
  __typename?: "Module";
  /** Administrative modules can manage other Stacks at runtime. */
  administrative: Scalars["Boolean"]["output"];
  /**
   * API host, useful for building links in the GUI. It may be empty if the
   * Module's original VCS provider has been removed.
   */
  apiHost: Maybe<Scalars["String"]["output"]>;
  /** List of aws integrations that can be attached to this Module. */
  attachableAwsIntegrations: Array<AwsIntegration>;
  /** List of azure integrations that can be attached to this Module. */
  attachableAzureIntegrations: Array<AzureIntegration>;
  /** List of contexts that can be attached to this Module. */
  attachableContexts: Array<Context>;
  /** List of policies that can be attached to this Module. */
  attachablePolicies: Array<Policy>;
  /** List of vcs integrations that can be attached to this Module. */
  attachableVcsIntegrations: Array<VcsIntegration>;
  /** List of worker pools that can be attached to this Module. */
  attachableWorkerPools: Array<WorkerPool>;
  /** List of Azure integrations attached to this Module. */
  attachedAzureIntegrations: Array<StackAzureIntegrationAttachment>;
  /** List of contexts attached to this Module. */
  attachedContexts: Array<StackContextAttachment>;
  /** List of policies attached to this Module. */
  attachedPolicies: Array<StackPolicyAttachment>;
  /** Git branch of the repo that the Module is tracking. */
  branch: Scalars["String"]["output"];
  /** Whether the user has write privileges for this Module. */
  canWrite: Scalars["Boolean"]["output"];
  /** Full list of module config elements. */
  config: Array<ConfigElement>;
  /** Single Module config element. */
  configElement: Maybe<ConfigElement>;
  /** Timestamp representing the module creation. */
  createdAt: Scalars["Int"]["output"];
  /** Current module version. */
  current: Maybe<Version>;
  /** Free-form module description for the users, supports Markdown. */
  description: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether the GitHub Checks "Deploy" action is enabled. */
  githubActionDeploy: Scalars["Boolean"]["output"];
  /** Unique (within a single organization), immutable name of the Module. */
  id: Scalars["ID"]["output"];
  /** Integrations between a Module and external systems. */
  integrations: Maybe<StackIntegrations>;
  /** If module is disabled, it will not be able to create any runs. */
  isDisabled: Scalars["Boolean"]["output"];
  /** Labels applied to a Module. */
  labels: Array<Scalars["String"]["output"]>;
  /** Latest module version. */
  latest: Maybe<Version>;
  /** Whether triggering local workspace based Versions on this Module is enabled. */
  localPreviewEnabled: Scalars["Boolean"]["output"];
  /** The value to use for the Terraform `source` property when accessing the module. */
  moduleSource: Scalars["String"]["output"];
  /** Provider-independent name of the Terraform module. */
  name: Scalars["String"]["output"];
  /** Repository namespace. */
  namespace: Scalars["String"]["output"];
  /**
   * Subdomain of the account owner - if the module is owned by another account
   * and just shared with the current one, this will be used to point the user
   * to the "canonical" location of the module.
   */
  ownerSubdomain: Scalars["String"]["output"];
  /** Retrieve module policy attachment by a known ID. */
  policyAttachment: Maybe<StackPolicyAttachment>;
  /**
   * Project root is the optional directory relative to the workspace root
   * containing the root directory of the module.
   */
  projectRoot: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether deletion protection is currently "on" for the stack. */
  protectFromDeletion: Scalars["Boolean"]["output"];
  /** Provider of the repository */
  provider: VcsProvider;
  /**
   * Public indicates that the Module is shared with the entire world. Public Modules
   * cannot be deleted or marked private.
   */
  public: Scalars["Boolean"]["output"];
  /**
   * List of up to 50 most recent open Pull Requests targeting Module's tracked
   * branch.
   */
  pullRequests: Array<PullRequest>;
  /** GitHub repository tracked by this Module, as account/repo. */
  repository: Scalars["String"]["output"];
  /** A list of tags for the tracked repository. */
  repositoryTags: Array<Scalars["String"]["output"]>;
  /** Git repository URL tracked by this Module. */
  repositoryURL: Maybe<Scalars["String"]["output"]>;
  /** Individual Run (or task) by ID. */
  run: Maybe<Run>;
  /** Optional runner image used to process the module metadata during parsing Run. */
  runnerImage: Maybe<Scalars["String"]["output"]>;
  /**
   * List of runs from a start timestamp inclusive to an end timestamp exclusive.
   * Caveat: It uses Ints serialized to Strings, because serializing Ints to JSON
   * is apparently really hard in JavaScript.
   * Also, note that the maximum supported range is 8 days (691,200 seconds).
   */
  runsInRange: Array<Run>;
  /**
   * Calculated runtime config containing elements coming from contexts,
   * Module and various integrations.
   */
  runtimeConfig: Array<ConfigElementWithSource>;
  searchModuleVersions: SearchVersionsOutput;
  searchModuleVersionsSuggestions: SearchSuggestionsOutput;
  /** List of the accounts (subdomains) which have access to the Module. */
  sharedAccounts: Array<Scalars["String"]["output"]>;
  /** ID of the space this Module belongs to. */
  space: Scalars["ID"]["output"];
  /** Details of the space this Module belongs to. */
  spaceDetails: Space;
  /** Is the module starred by the current user. */
  starred: Scalars["Boolean"]["output"];
  /** TerraformProvider represents Module's Terraform provider */
  terraformProvider: Scalars["String"]["output"];
  /**
   * The VCS integration used by this module. This may either be a default integration,
   * or a space-level integration. If a space-level integration is being used by the module,
   * and that integration is deleted, this property will be null. Additionally, if the
   * module is using a default integration, and none exists, this property will be null.
   */
  vcsIntegration: Maybe<VcsIntegration>;
  /** Retrieve a single Version by its ID. */
  version: Maybe<Version>;
  /**
   * Paginate through all Versions of the current Module. By default, only
   * ACTIVE and PENDING versions are shown, but setting includeFailed to true
   * will show all versions.
   */
  versions: Array<Version>;
  /**
   * VersionsCount returns the number of versions of the module in a specified state.
   * If state is not provided, it will return the count of active versions.
   */
  versionsCount: Scalars["Int"]["output"];
  /**
   * WorkerPool associated with the Module, if any. If the Module is associated
   * with the shared default worker pool, this field is empty.
   */
  workerPool: Maybe<WorkerPool>;
  /** The tool used by the stack for executing workflow stages. */
  workflowTool: TerraformWorkflowTool;
};

/** Module is a versioned and tested reusable collection of resources */
export type ModuleConfigElementArgs = {
  id: Scalars["ID"]["input"];
};

/** Module is a versioned and tested reusable collection of resources */
export type ModulePolicyAttachmentArgs = {
  id: Scalars["ID"]["input"];
};

/** Module is a versioned and tested reusable collection of resources */
export type ModuleRunArgs = {
  id: Scalars["ID"]["input"];
};

/** Module is a versioned and tested reusable collection of resources */
export type ModuleRunsInRangeArgs = {
  end: Scalars["String"]["input"];
  start: Scalars["String"]["input"];
};

/** Module is a versioned and tested reusable collection of resources */
export type ModuleSearchModuleVersionsArgs = {
  input: SearchInput;
};

/** Module is a versioned and tested reusable collection of resources */
export type ModuleSearchModuleVersionsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

/** Module is a versioned and tested reusable collection of resources */
export type ModuleVersionArgs = {
  id: Scalars["ID"]["input"];
};

/** Module is a versioned and tested reusable collection of resources */
export type ModuleVersionsArgs = {
  before: InputMaybe<Scalars["ID"]["input"]>;
  includeFailed: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Module is a versioned and tested reusable collection of resources */
export type ModuleVersionsCountArgs = {
  state: InputMaybe<VersionState>;
};

/** ModuleConnection represents a single module in a page of results. */
export type ModuleConnection = {
  __typename?: "ModuleConnection";
  cursor: Scalars["String"]["output"];
  node: Module;
};

/** All arguments to create an individual Module. */
export type ModuleCreateInput = {
  /** Module name. */
  name: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Namespace is the optional namespace which the repository belongs to. If not
   * provided, the name of the linked GitHub account is used.
   */
  namespace: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Provider is the optional VCS provider that provides access to the Git
   * repository linked to the Module.
   */
  provider: InputMaybe<VcsProvider>;
  /** Name of the repository to track. */
  repository: Scalars["String"]["input"];
  /** URL of the repository to track. */
  repositoryURL: InputMaybe<Scalars["String"]["input"]>;
  /** DEPRECATED! ID of the Space this Stack should be in. */
  space: InputMaybe<Scalars["ID"]["input"]>;
  /** Terraform provider for which this module is meant. */
  terraformProvider: InputMaybe<Scalars["String"]["input"]>;
  updateInput: ModuleUpdateInput;
  /**
   * The ID of the VCS integration used by this stack. This should only be provided if the default (account-level)
   * integration is not being used (i.e. a space-level integration is being used).
   */
  vcsIntegrationId: InputMaybe<Scalars["ID"]["input"]>;
  /** Defines the tool that will be used to execute the workflow. */
  workflowTool: InputMaybe<TerraformWorkflowTool>;
};

/** ModuleInput represents a single module input variable in ModuleMetadata. */
export type ModuleInput = {
  __typename?: "ModuleInput";
  default: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
  type: Scalars["String"]["output"];
};

/** ModuleMetadata represents a root module or a submodule within the module repository. */
export type ModuleMetadata = {
  __typename?: "ModuleMetadata";
  changelog: Scalars["String"]["output"];
  dependencies: Array<Scalars["String"]["output"]>;
  empty: Scalars["Boolean"]["output"];
  inputs: Array<ModuleInput>;
  name: Scalars["String"]["output"];
  outputs: Array<ModuleOutput>;
  path: Scalars["String"]["output"];
  providerDependencies: Array<ModuleProviderDependency>;
  readme: Scalars["String"]["output"];
  resources: Array<ModuleResource>;
};

/** ModuleOutput represents a single module output in ModuleMetadata. */
export type ModuleOutput = {
  __typename?: "ModuleOutput";
  description: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** ModuleProviderDependency represents a single module's provider dependency. */
export type ModuleProviderDependency = {
  __typename?: "ModuleProviderDependency";
  name: Scalars["String"]["output"];
  namespace: Scalars["String"]["output"];
  source: Scalars["String"]["output"];
  version: Scalars["String"]["output"];
};

/** ModuleRepositoryMetadata represents the entire module repository metadata. */
export type ModuleRepositoryMetadata = {
  __typename?: "ModuleRepositoryMetadata";
  examples: Array<ModuleMetadata>;
  root: ModuleMetadata;
  submodules: Array<ModuleMetadata>;
};

/** ModuleResource represents a single resource managed by a module. */
export type ModuleResource = {
  __typename?: "ModuleResource";
  name: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

/** Arguments used to create and update an individual Module. */
export type ModuleUpdateInput = {
  /** Administrative stacks can manage other Stacks at runtime. */
  administrative: Scalars["Boolean"]["input"];
  /** Name of the branch to track. */
  branch: Scalars["String"]["input"];
  /** Optional free-form description of the Module. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** When set to true, the "Deploy" action will be enabled on GitHub Checks Run (default: true). */
  githubActionDeploy: InputMaybe<Scalars["Boolean"]["input"]>;
  /** When set to true, the module will not be able to create runs. */
  isDisabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Optional list of Module labels. */
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Whether triggering local workspace based Versions on this Module is enabled */
  localPreviewEnabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Project root is the optional directory relative to the workspace root
   * containing the root directory of the module.
   */
  projectRoot: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates whether deletion protection is currently "on" for the module. */
  protectFromDeletion: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Optional runner image used to process the module metadata during parsing Run. */
  runnerImage: InputMaybe<Scalars["String"]["input"]>;
  /** Optional list of the accounts (subdomains) which have access to the Module. */
  sharedAccounts: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** ID of the Space this Stack should be in. */
  space: InputMaybe<Scalars["ID"]["input"]>;
  /** ID of the worker pool for the Module to use. */
  workerPool: InputMaybe<Scalars["ID"]["input"]>;
};

/** Arguments used to update an individual Module. */
export type ModuleUpdateV2Input = {
  /** Administrative stacks can manage other Stacks at runtime. */
  administrative: Scalars["Boolean"]["input"];
  /** Name of the branch to track. */
  branch: Scalars["String"]["input"];
  /** Optional free-form description of the Module. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** When set to true, the "Deploy" action will be enabled on GitHub Checks Run (default: true). */
  githubActionDeploy: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Optional list of Module labels. */
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Whether triggering local workspace based Versions on this Module is enabled. */
  localPreviewEnabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Namespace is the optional namespace which the repository belongs to. If not
   * provided, the name of the linked GitHub account is used.
   */
  namespace: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Project root is the optional directory relative to the workspace root
   * containing the root directory of the module.
   */
  projectRoot: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates whether deletion protection is currently "on" for the module. */
  protectFromDeletion: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Provider is the optional VCS provider that provides access to the Git
   * repository linked to the Module.
   */
  provider: InputMaybe<VcsProvider>;
  /** Name of the repository to track. */
  repository: Scalars["String"]["input"];
  /** Optional URL of the repository to track. */
  repositoryURL: InputMaybe<Scalars["String"]["input"]>;
  /** Optional runner image used to process the module metadata during parsing Run. */
  runnerImage: InputMaybe<Scalars["String"]["input"]>;
  /** Optional list of the accounts (subdomains) which have access to the Module. */
  sharedAccounts: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** ID of the Space this Stack should be in. */
  space: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * The ID of the VCS integration used by this stack. This should only be provided if the default (account-level)
   * integration is not being used (i.e. a space-level integration is being used).
   */
  vcsIntegrationId: InputMaybe<Scalars["ID"]["input"]>;
  /** ID of the worker pool for the Module to use. */
  workerPool: InputMaybe<Scalars["ID"]["input"]>;
  /** Defines the tool that will be used to execute the workflow. */
  workflowTool: InputMaybe<TerraformWorkflowTool>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** AccountAcceptTermsAndConditionsVersionForAI marks the account as accepting the terms and conditions for the given version of AI usage. */
  accountAcceptTermsAndConditionsVersionForAI: Scalars["Boolean"]["output"];
  /** Delete the account */
  accountConfirmDelete: Scalars["Boolean"]["output"];
  /** Allow/Disallow non-root admins to create subspaces. */
  accountToggleAllowNonRootAdminSpaceCreation: Scalars["Boolean"]["output"];
  /** Mark/unmark the Account for deletion. */
  accountToggleDeletionMark: Scalars["Boolean"]["output"];
  /** Enable/Disable AI usage at account level */
  accountToggleEnablingAI: Scalars["Boolean"]["output"];
  /** Enable/disable enforcing MFA for the Account. */
  accountToggleEnforcingMFA: Scalars["Boolean"]["output"];
  /** Update the authorization schema used by the account */
  accountUpdateAuthorizationScheme: AuthorizationScheme;
  /** Update Account's Awareness Source */
  accountUpdateAwarenessSourceSurvey: Maybe<AwarenessSourceSurvey>;
  /** Update Account's Security Email */
  accountUpdateSecurityEmail: Scalars["String"]["output"];
  /** Update limit of runs created by a single VCS event for this account */
  accountUpdateVCSEventTriggeredRunsLimit: Maybe<Scalars["Int"]["output"]>;
  /** Create an API key. */
  apiKeyCreate: ApiKey;
  /** Delete an API key. */
  apiKeyDelete: Maybe<ApiKey>;
  /**
   * Enable/disable non-root managers for API keys. Settings this to true means
   * that admins of non-root spaces can create an API key up to their current
   * level of permissions. Also, if this is set to true, then the API key
   * creator can delete the API key regardless of their current permissions.
   */
  apiKeyEnableNonRootManagers: Scalars["Boolean"]["output"];
  /** Exchange an API token for a Spacelift User. */
  apiKeyUser: Maybe<User>;
  /** Delete audit trail webhook. */
  auditTrailDeleteWebhook: Maybe<AuditTrailWebhook>;
  /** Set audit trail webhook for the Account. */
  auditTrailSetWebhook: AuditTrailWebhook;
  auditTrailWebhookDeleteHeaders: AuditTrailWebhook;
  auditTrailWebhookSetHeaders: AuditTrailWebhook;
  /**
   * Attaches the specified AWS integration to a Stack. Note that at least one of either the 'read'
   * or 'write' parameters must be true, and a Stack can have at most one read, and one write attachment.
   * A single integration can be attached for both read and write.
   */
  awsIntegrationAttach: AwsIntegrationStackAttachment;
  /** Updates an AWS integration attachment. */
  awsIntegrationAttachmentUpdate: AwsIntegrationStackAttachment;
  /** Creates an AWS integration. */
  awsIntegrationCreate: AwsIntegration;
  /** Deletes the integration with the specified ID. */
  awsIntegrationDelete: Maybe<AwsIntegration>;
  /** Detaches an AWS integration from a Stack, using the unique ID of the attachment. */
  awsIntegrationDetach: Maybe<AwsIntegrationStackAttachment>;
  /** Updates the integration with the specified ID. */
  awsIntegrationUpdate: AwsIntegration;
  /** Creates the Azure DevOps Repo integration. */
  azureDevOpsRepoIntegrationCreate: AzureDevOpsRepoIntegration;
  /** Deletes the Azure DevOps Repo integration. */
  azureDevOpsRepoIntegrationDelete: Maybe<AzureDevOpsRepoIntegration>;
  /** Tests the Azure DevOps Repo integration. */
  azureDevOpsRepoIntegrationTest: Scalars["Boolean"]["output"];
  /** Updates the Azure DevOps Repo integration with new properties. */
  azureDevOpsRepoIntegrationUpdate: AzureDevOpsRepoIntegration;
  /**
   * Attaches the specified Azure integration to a Stack. Note that at least one of either the 'read'
   * or 'write' parameters must be true, and a Stack can have at most one read, and one write attachment.
   * A single integration can be attached for both read and write. The subscriptionId can be
   * set when attaching an integration to override the default Azure subscription Id configured
   * against the integration.
   */
  azureIntegrationAttach: AzureIntegrationStackAttachment;
  /** Updates an Azure integration attachment. */
  azureIntegrationAttachmentUpdate: AzureIntegrationStackAttachment;
  /** Creates an Azure integration. */
  azureIntegrationCreate: AzureIntegration;
  /** Deletes the integration with the specified ID. */
  azureIntegrationDelete: Maybe<AzureIntegration>;
  /** Detaches an Azure integration from a Stack, using the unique ID of the attachment. */
  azureIntegrationDetach: Maybe<AzureIntegrationStackAttachment>;
  /** Updates the integration with the specified ID. */
  azureIntegrationUpdate: AzureIntegration;
  /**
   * Creates a new billing subscription
   * addons argument can be ignored because it has not implemented yet.
   */
  billingSubscriptionCreate: BillingSubscription;
  /** Cancels current subscription */
  billingSubscriptionDelete: Maybe<BillingSubscription>;
  /** Updates the billing info of an existing subscription */
  billingSubscriptionUpdateInfo: BillingSubscription;
  /** Updates the billing tier of an existing billing subscription */
  billingSubscriptionUpdateTier: BillingSubscription;
  /** Updates an existing billing subscription */
  billingSubscriptionUpdateV2: BillingSubscription;
  /** Creates the Bitbucket Cloud integration. */
  bitbucketCloudIntegrationCreate: BitbucketCloudIntegration;
  /** Deletes the Bitbucket Cloud integration. */
  bitbucketCloudIntegrationDelete: Maybe<BitbucketCloudIntegration>;
  /** Tests the Bitbucket Cloud integration. */
  bitbucketCloudIntegrationTest: Scalars["Boolean"]["output"];
  /** Updates the Bitbucket Cloud integration with new properties. */
  bitbucketCloudIntegrationUpdate: BitbucketCloudIntegration;
  /** Creates the Bitbucket Datacenter integration. */
  bitbucketDatacenterIntegrationCreate: BitbucketDatacenterIntegration;
  /** Deletes the Bitbucket Datacenter integration. */
  bitbucketDatacenterIntegrationDelete: Maybe<BitbucketDatacenterIntegration>;
  /** Tests the Bitbucket Datacenter integration. */
  bitbucketDatacenterIntegrationTest: Scalars["Boolean"]["output"];
  /** Updates the Bitbucket Datacenter integration with new properties. */
  bitbucketDatacenterIntegrationUpdate: BitbucketDatacenterIntegration;
  blueprintAttachToIntegration: BlueprintIntegrationAttachment;
  blueprintClone: Blueprint;
  /** Create a blueprint. */
  blueprintCreate: Blueprint;
  blueprintCreateStack: BlueprintStackCreation;
  /** Delete an existing blueprint. */
  blueprintDelete: Maybe<Blueprint>;
  blueprintDetachIntegration: BlueprintIntegrationAttachment;
  blueprintParseTemplate: BlueprintParseResult;
  /** Update an existing blueprint. */
  blueprintUpdate: Blueprint;
  blueprintVersionedGroupCreate: BlueprintVersionedGroup;
  blueprintVersionedGroupDelete: Maybe<BlueprintVersionedGroup>;
  blueprintVersionedGroupUpdate: BlueprintVersionedGroup;
  /** Create a blueprint. */
  blueprintWithGroupCreate: Blueprint;
  /** Delete an existing blueprint. */
  blueprintWithGroupDelete: Maybe<Blueprint>;
  /** Update an existing blueprint. */
  blueprintWithGroupUpdate: Blueprint;
  /** ChangeLLMVendor changes the selected LLM vendor for the account */
  changeLLMVendor: LlmVendor;
  /** Generates a confirmation token for CLI auth. */
  cliConfirmationToken: Scalars["String"]["output"];
  /** Complete generic form with the data provided in input */
  completeGenericForm: Scalars["Boolean"]["output"];
  /** Attach context to a Stack. */
  contextAttach: ContextStackAttachment;
  /** Add a context config element. */
  contextConfigAdd: ConfigElement;
  /** Delete a context config element. */
  contextConfigDelete: Maybe<ConfigElement>;
  /**
   * Create a new context.
   * @deprecated Please use contextCreateV2.
   */
  contextCreate: Context;
  /** Create a new context (v2). */
  contextCreateV2: Context;
  /** Delete an existing context. */
  contextDelete: Maybe<Context>;
  /** Detach context from a a Stack. */
  contextDetach: Maybe<ContextStackAttachment>;
  /**
   * Update an existing context.
   * @deprecated Please use contextUpdateV2.
   */
  contextUpdate: Context;
  /**
   * Update an existing context (v2).
   *
   * replaceConfigElements allow to specify if we want to also update config elements.
   * When it's not specified or false, input.configAttachments will be ignored and no config elements will be altered.
   * When true, the config elements attached to the context will be replaced with the values included in the request.
   * If an existing element is not included, it will be deleted from the context.
   *
   */
  contextUpdateV2: Context;
  /** Deletes a run state summary */
  deleteRunStateSummary: Maybe<RunStateSummary>;
  /** Deletes a run summary */
  deleteRunSummary: Maybe<RunSummary>;
  /** Dismisses all notifications. */
  dismissAllNotifications: Maybe<Scalars["Int"]["output"]>;
  /** Dismisses notifications by grouping key. */
  dismissNotificationGroup: Array<Notification>;
  /** Dismisses notifications. */
  dismissNotifications: Array<Notification>;
  externalIntegrationCreate: ExternalIntegration;
  externalIntegrationDelete: Maybe<ExternalIntegration>;
  /** Create a GitHub integration using an app created from a manifest. */
  githubAppCreateFromManifest: GithubEnterpriseIntegration;
  /** Create data for the GitHub app creation form - internal. */
  githubAppGenerateManifest: GitHubAppManifest;
  /** Creates the GitHub Enterprise integration */
  githubEnterpriseIntegrationCreate: GithubEnterpriseIntegration;
  /** Deletes the GitHub Enterprise integration */
  githubEnterpriseIntegrationDelete: Maybe<GithubEnterpriseIntegration>;
  /** Tests the GitHub Enterprise integration */
  githubEnterpriseIntegrationTest: Scalars["Boolean"]["output"];
  /** Updates the GitHub Enterprise integration */
  githubEnterpriseIntegrationUpdate: GithubEnterpriseIntegration;
  /** Updates the build-in GitHub integration */
  githubIntegrationUpdate: GithubIntegration;
  /** Creates the GitLab integration. */
  gitlabIntegrationCreate: GitlabIntegration;
  /** Deletes the GitLab integration. */
  gitlabIntegrationDelete: Maybe<GitlabIntegration>;
  /** Tests the GitLab integration. */
  gitlabIntegrationTest: Scalars["Boolean"]["output"];
  /** Updates the GitLab integration with a new access token and (optionally) API host or User Facing Host. */
  gitlabIntegrationUpdate: GitlabIntegration;
  /** Creates a new GPG key to be used for signing binaries. */
  gpgKeyCreate: GpgKey;
  /** Revokes a GPG key to be used for signing binaries. */
  gpgKeyRevoke: GpgKey;
  /** Updates the description on the GPG key. */
  gpgKeyUpdate: GpgKey;
  /** Group user event */
  groupUserEvent: Scalars["Boolean"]["output"];
  /** Identify user event */
  identifyUserEvent: Scalars["Boolean"]["output"];
  /** End the current session. */
  logout: Maybe<Session>;
  managedUserDelete: ManagedUser;
  managedUserGroupCreate: ManagedUserGroup;
  managedUserGroupDelete: ManagedUserGroup;
  managedUserGroupIntegrationCreate: ManagedUserGroupIntegration;
  managedUserGroupIntegrationDelete: ManagedUserGroupIntegration;
  managedUserGroupIntegrationUpdate: ManagedUserGroupIntegration;
  managedUserGroupUpdate: ManagedUserGroup;
  managedUserInvite: ManagedUser;
  managedUserInviteV2: ManagedUser;
  managedUserRequestInvite: Scalars["Boolean"]["output"];
  managedUserRequestRoleChange: Scalars["Boolean"]["output"];
  managedUserResendInvite: ManagedUser;
  managedUserUpdate: ManagedUser;
  /** Create a new Module. */
  moduleCreate: Module;
  /** Delete an existing Module, assuming it has no active versions. */
  moduleDelete: Maybe<Module>;
  /** Disable the module, so it cannot create new runs. */
  moduleDisable: Module;
  /** Enable the module. */
  moduleEnable: Module;
  /** Change Terraform registry from private to public. */
  modulePublish: Module;
  /** Update an existing Module (v1 API, does not support updating source). */
  moduleUpdate: Module;
  /** Update an existing Module (v2 API). */
  moduleUpdateV2: Module;
  /** Create a new NamedWebhooksIntegration for a space */
  namedWebhooksIntegrationCreate: NamedWebhooksIntegration;
  /** Delete a NamedWebhooksIntegration from a space */
  namedWebhooksIntegrationDelete: NamedWebhooksIntegration;
  /** Remove secret headers for a named webhook */
  namedWebhooksIntegrationDeleteHeaders: NamedWebhooksIntegration;
  /** Set secret headers for a named webhook to use when sending requests */
  namedWebhooksIntegrationSetHeaders: NamedWebhooksIntegration;
  /** Update a NamedWebhooksIntegration for a space */
  namedWebhooksIntegrationUpdate: NamedWebhooksIntegration;
  /** Get a GitHub URL to redirect to. */
  oauthRedirect: OAuthRedirect;
  /** Exchange code and temporary token for a GitHub token. */
  oauthToken: Scalars["String"]["output"];
  /** Exchange GitHub token for a Spacelift User. */
  oauthUser: User;
  /** Set up OIDC integration. */
  oidcCreate: OidcSettings;
  /** Delete OIDC integration. */
  oidcDelete: Maybe<OidcSettings>;
  /** Update OIDC settings to use slack member id from idp. */
  oidcUpdate: OidcSettings;
  /** Page user event */
  pageUserEvent: Scalars["Boolean"]["output"];
  /** Attach Policy to a Stack. */
  policyAttach: PolicyStackAttachment;
  /**
   * Create a new Policy.
   * @deprecated Use PolicyCreatev2 instead.
   */
  policyCreate: Policy;
  /** Create a new Policy (v2 API). */
  policyCreatev2: Policy;
  /** Delete an existing Policy. */
  policyDelete: Maybe<Policy>;
  /** Detach Policy from a Stack. */
  policyDetach: Maybe<PolicyStackAttachment>;
  /** Simulate policy evaluation. */
  policySimulate: Scalars["String"]["output"];
  /**
   * Update an existing Policy.
   * @deprecated Use PolicyUpdatev2 instead.
   */
  policyUpdate: Policy;
  /** Update a Policy (v2 API). */
  policyUpdatev2: Policy;
  /**
   * Trigger a proposed Run. By default, this mutation triggers a proposed run for the
   * commit currently tracked by a Stack or, if the Stack is not yet tracking any
   * commit, against the HEAD of the Stack's tracked branch. These defaults can
   * be overridden. In contrary to the generic runTrigger mutation, proposeWithOverrides allows
   * overriding environment variables in the proposed runs to enable more complex testing environment.
   * Bear in mind that these environment variables are NOT supposed to be secrets, therefore are not treated as such.
   */
  proposeWithOverrides: Run;
  /** Create a new Role. */
  roleCreate: Role;
  roleDelete: Maybe<Role>;
  roleUpdate: Maybe<Role>;
  /** Cancel a Run that has not yet started. */
  runCancel: Run;
  /** Comment on the run. */
  runComment: RunComment;
  /**
   * Confirm a Run that's waiting to be applied. If the Run ID is not passed, the
   * Run that's currently blocking the stack will be confirmed.
   */
  runConfirm: Run;
  /**
   * Discard a Run that's waiting to be applied. If the Run ID is not passed, the
   * Run that's currently blocking the stack will be discarded.
   */
  runDiscard: Run;
  /** Experimental: Mark a run external dependency as completed. */
  runExternalDependencyMarkAsCompleted: RunExternalDependencyMarkAsCompletedResponse;
  /**
   * Sets the prioritization value of a run.
   * When prioritized, the run will take precedence over other queued runs for the worker pool it is assigned to.
   */
  runPrioritizeSet: Run;
  /** Promote a proposed Run to a tracked one. */
  runPromote: Run;
  /** Create proposed run based on provided local workspace. */
  runProposeLocalWorkspace: Run;
  /**
   * Stub for the Terraform provider for creating runs. The Terraform provider
   * only needs to be able to trigger a run but will not follow it, so we do not
   * expose anything else than the ID. By default, a tracked run is created, but
   * this can be overridden by setting the proposed argument to true.
   */
  runResourceCreate: Scalars["ID"]["output"];
  /** Retry a PR Run that has finished. */
  runRetry: Run;
  /** Add a run review. */
  runReview: RunReview;
  /** Stop a Run that's currently in progress. */
  runStop: Run;
  /** Set a run state to `PENDING_PLAN` to force a replan. */
  runTargetedReplan: Run;
  /**
   * Trigger a Run. By default, this mutation triggers a tracked run for the
   * commit currently tracked by a Stack or, if the Stack is not yet tracking any
   * commit, against the HEAD of the Stack's tracked branch. These defaults can
   * be overridden.
   */
  runTrigger: Run;
  /** Set up SAML integration. */
  samlCreate: SamlSettings;
  /** Delete SAML integration. */
  samlDelete: Maybe<SamlSettings>;
  /** Create a new saved filter. */
  savedFilterCreate: SavedFilter;
  /** Delete a saved filter. */
  savedFilterDelete: Maybe<SavedFilter>;
  /** Update a saved filter. */
  savedFilterUpdate: SavedFilter;
  /** Delete a security key. */
  securityKeyDelete: Maybe<SecurityKey>;
  /** Invalidate a Session. NOTE: this functionality is not available in the Starter tier. */
  sessionDelete: Maybe<Session>;
  /** Invalidate all account sessions except for the session making the request. Returns the deleted sessions. */
  sessionDeleteAll: Array<Maybe<Session>>;
  /**
   * Deletes the instance-wide Slack configuration. Only available on Self-Hosted instances.
   * Calling this mutation on a Spacelift SaaS account will result in an error.
   */
  slackAppConfigDelete: Maybe<SlackAppConfig>;
  /**
   * Sets the instance-wide Slack App configuration. This is used to connect your Spacelift installation
   * to a Slack App that can be used for the Slack integration. Only available on Self-Hosted instances.
   * Calling this mutation on a Spacelift SaaS account will result in an error.
   */
  slackAppConfigSet: SlackAppConfig;
  /** Get a Slack URL to redirect to. */
  slackOauthRedirect: OAuthRedirect;
  /** Creates a new space */
  spaceCreate: Space;
  /** Deletes space */
  spaceDelete: Space;
  /** Updates space */
  spaceUpdate: Space;
  /** Add a stack config element. */
  stackConfigAdd: ConfigElement;
  /** Delete a stack config element. */
  stackConfigDelete: Maybe<ConfigElement>;
  /**
   * Create a new stack. The third argument (stackObjectID) is optional, and
   * should only be used when creating a Stack with pre-existing state that is
   * handed over to Spacelift for further management.
   */
  stackCreate: Stack;
  /** Delete an existing stack. */
  stackDelete: Maybe<Stack>;
  /** Adds reference output/input */
  stackDependenciesAddReference: StackDependencyReference;
  /** Adds multiple references output/input in a single request. */
  stackDependenciesAddReferences: Array<StackDependencyReference>;
  /** Creates multiple stack dependencies in a single request. */
  stackDependenciesCreateInBatch: Array<StackDependency>;
  /** Deletes reference output/input from stack dependency */
  stackDependenciesDeleteReference: Maybe<StackDependencyReference>;
  /** Deletes multiple references from stack dependency. */
  stackDependenciesDeleteReferences: Array<StackDependencyReference>;
  /** Updates reference output/input */
  stackDependenciesUpdateReference: StackDependencyReference;
  /** Updates multiple references output/input in a single request. */
  stackDependenciesUpdateReferences: Array<StackDependencyReference>;
  /** Create a new stack dependency. */
  stackDependencyCreate: StackDependency;
  /** Delete a stack dependency. */
  stackDependencyDelete: Maybe<StackDependency>;
  /** Disable the stack, so it cannot create new runs. */
  stackDisable: Stack;
  /** Enable the stack. */
  stackEnable: Stack;
  /** Create a stack AWS integration by setting a role ARN. */
  stackIntegrationAwsCreate: StackIntegrationAws;
  /** Delete an AWS integration by unsetting a role ARN. */
  stackIntegrationAwsDelete: Maybe<StackIntegrationAws>;
  /** Create a new DriftDetectionIntegration for stack */
  stackIntegrationDriftDetectionCreate: DriftDetectionIntegration;
  /** Delete DriftDetectionIntegration for stack */
  stackIntegrationDriftDetectionDelete: Maybe<DriftDetectionIntegration>;
  /** Update DriftDetectionIntegration for stack */
  stackIntegrationDriftDetectionUpdate: DriftDetectionIntegration;
  /**
   * Create a stack GCP integration by generating a service account. If the
   * service account already exists, it is not changed.
   * @deprecated Use OIDC integration instead.
   */
  stackIntegrationGcpCreate: StackIntegrationGcp;
  /**
   * Delete a stack GCP integration by deleting a service account.
   * @deprecated Use OIDC integration instead.
   */
  stackIntegrationGcpDelete: Maybe<StackIntegrationGcp>;
  /** Lock the stack for exclusive use. */
  stackLock: Stack;
  stackManagedStateImport: Maybe<Scalars["Boolean"]["output"]>;
  stackManagedStateRollback: ManagedStateVersion;
  /**
   * Change the slug of an existing stack. The slug will be generated from the
   * current stack name. WARNING: if you're referring to this stack by ID from
   * Terraform resources then some of those resources will be recreated. Make
   * sure you know exactly what you're doing!
   */
  stackReslug: Stack;
  /** Create a new ScheduledDelete for stack */
  stackScheduledDeleteCreate: ScheduledDelete;
  /** Delete ScheduledDelete for stack */
  stackScheduledDeleteDelete: Maybe<ScheduledDelete>;
  /** Update ScheduledDelete for stack */
  stackScheduledDeleteUpdate: ScheduledDelete;
  /** Create a new ScheduledRun for stack */
  stackScheduledRunCreate: ScheduledRun;
  /** Delete ScheduledRun for stack */
  stackScheduledRunDelete: Maybe<ScheduledRun>;
  /** Update ScheduledRun for stack */
  stackScheduledRunUpdate: ScheduledRun;
  /** Create a new ScheduledTask for stack */
  stackScheduledTaskCreate: ScheduledTask;
  /** Delete ScheduledTask for stack */
  stackScheduledTaskDelete: Maybe<ScheduledTask>;
  /** Update ScheduledTask for stack */
  stackScheduledTaskUpdate: ScheduledTask;
  /**
   * Set the current commit on an existing stack. The current commit is what is
   * used for tasks and - by default - for triggering tracked runs (deployments).
   */
  stackSetCurrentCommit: Stack;
  /** Sync the tracked commit of the stack. */
  stackSyncCommit: Stack;
  /** Unlock the stack. */
  stackUnlock: Stack;
  /** Update an existing stack. */
  stackUpdate: Stack;
  /** Sets or unsets star on a Stack or Module. */
  starSet: Stack;
  /** Generate pre-signed state upload URL. */
  stateUploadUrl: StateUploadUrl;
  /** Summarizes a given run */
  summarizeRun: Maybe<RunSummary>;
  /** Summarizes the run state for a given run */
  summarizeRunState: Maybe<RunStateSummary>;
  /** Create a Task from a command. */
  taskCreate: Run;
  /**
   * Creates a new Terraform provider. Note that the type needs to be unique within
   * the account, and it can then be used as the provider's ID.
   */
  terraformProviderCreate: TerraformProvider;
  /** Deletes a Terraform Provider */
  terraformProviderDelete: Maybe<TerraformProvider>;
  /** Changes public visibility of the Terraform Provider */
  terraformProviderSetVisibility: TerraformProvider;
  /** Updates an existing Terraform Provider */
  terraformProviderUpdate: TerraformProvider;
  /** Create a new Terraform provider version. */
  terraformProviderVersionCreate: NewTerraformProviderVersion;
  /** Delete a draft Terraform provider version. */
  terraformProviderVersionDelete: TerraformProviderVersion;
  /** Publish an existing Terraform provider version. */
  terraformProviderVersionPublish: TerraformProviderVersion;
  /**
   * Register a platform supported by the version. The result is presigned
   * upload URL that can be used to upload the platform binary.
   * This call is idempotent so registering the same platform multiple times will
   * not create multiple copies, but once a platform is registered, the binary
   * must be uploaded.
   */
  terraformProviderVersionRegisterPlatform: Scalars["String"]["output"];
  /**
   * This mutation is similar to terraformProviderVersionRegisterPlatform,
   * but it also returns the optional uploadHeaders required for the upload request.
   */
  terraformProviderVersionRegisterPlatformV2: TerraformProviderVersionRegisterPlatformV2Output;
  /** Revoke a published Terraform provider version. */
  terraformProviderVersionRevoke: TerraformProviderVersion;
  /** Update an existing Terraform provider version. */
  terraformProviderVersionUpdate: TerraformProviderVersion;
  /** Track user event */
  trackUserEvent: Scalars["Boolean"]["output"];
  /**
   * Upload a persistent UI config. Consecutive calls will overwrite the previous config.
   * UI config is a JSON blob.
   */
  uiConfigStore: Scalars["Boolean"]["output"];
  /** Upload workspace for later processing in other mutations. */
  uploadLocalWorkspace: LocalWorkspaceUpload;
  userRoleBindingCreate: UserRoleBinding;
  userRoleBindingDelete: Maybe<UserRoleBinding>;
  userRoleBindingUpdate: UserRoleBinding;
  /** Delete all security keys for the current user. */
  userSecurityKeyDeleteAll: Array<Maybe<SecurityKey>>;
  /** Create a new VCSAgentPool. */
  vcsAgentPoolCreate: VcsAgentPool;
  /** Delete an existing VCSAgentPool. */
  vcsAgentPoolDelete: Maybe<VcsAgentPool>;
  /** Recreate the credentials necessary to connect VCSAgentPool's agents to the gateways. */
  vcsAgentPoolReset: VcsAgentPool;
  /** Update an existing VCSAgentPool. */
  vcsAgentPoolUpdate: VcsAgentPool;
  /** Set notes on the Version. */
  versionAnnotate: Version;
  /** Create Version based on optional SHA or branch head. */
  versionCreate: Version;
  /** Delete version. This action cannot be undone. */
  versionDelete: Maybe<Version>;
  /** Create Version test run based on provided local workspace. */
  versionProposeLocalWorkspace: Array<Run>;
  /** Trigger failed Version. */
  versionTrigger: Version;
  /** Yank the version (mark it as bad). This action cannot be undone. */
  versionYank: Version;
  /** Create a new WebhooksIntegration for stack */
  webhooksIntegrationCreate: WebhooksIntegration;
  /** Delete WebhooksIntegration for stack */
  webhooksIntegrationDelete: Maybe<WebhooksIntegration>;
  /** Update WebhooksIntegration for stack */
  webhooksIntegrationUpdate: WebhooksIntegration;
  /** Toggles draining on the worker. */
  workerDrainSet: Worker;
  /** Create a new WorkerPool. */
  workerPoolCreate: WorkerPool;
  /** Cycles the workerpool */
  workerPoolCycle: Scalars["Boolean"]["output"];
  /** Delete an existing WorkerPool. */
  workerPoolDelete: Maybe<WorkerPool>;
  /**
   * Recreate the credentials necessary to connect WorkerPool's workers to the
   * control plane.
   */
  workerPoolReset: WorkerPool;
  /** Update an existing WorkerPool. */
  workerPoolUpdate: WorkerPool;
};

export type MutationAccountAcceptTermsAndConditionsVersionForAiArgs = {
  version: Scalars["Int"]["input"];
};

export type MutationAccountUpdateAuthorizationSchemeArgs = {
  schema: AuthorizationScheme;
};

export type MutationAccountUpdateAwarenessSourceSurveyArgs = {
  completed: Scalars["Boolean"]["input"];
  response: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAccountUpdateSecurityEmailArgs = {
  securityEmail: Scalars["String"]["input"];
};

export type MutationAccountUpdateVcsEventTriggeredRunsLimitArgs = {
  limit: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationApiKeyCreateArgs = {
  input: ApiKeyInput;
};

export type MutationApiKeyDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationApiKeyEnableNonRootManagersArgs = {
  enable: Scalars["Boolean"]["input"];
};

export type MutationApiKeyUserArgs = {
  id: Scalars["ID"]["input"];
  secret: Scalars["String"]["input"];
};

export type MutationAuditTrailSetWebhookArgs = {
  input: AuditTrailWebhookInput;
};

export type MutationAuditTrailWebhookDeleteHeadersArgs = {
  input: AuditTrailWebhookDeleteHeadersInput;
};

export type MutationAuditTrailWebhookSetHeadersArgs = {
  input: AuditTrailWebhookSetHeadersInput;
};

export type MutationAwsIntegrationAttachArgs = {
  id: Scalars["ID"]["input"];
  read: Scalars["Boolean"]["input"];
  stack: Scalars["ID"]["input"];
  write: Scalars["Boolean"]["input"];
};

export type MutationAwsIntegrationAttachmentUpdateArgs = {
  id: Scalars["ID"]["input"];
  read: Scalars["Boolean"]["input"];
  write: Scalars["Boolean"]["input"];
};

export type MutationAwsIntegrationCreateArgs = {
  durationSeconds: InputMaybe<Scalars["Int"]["input"]>;
  externalID: InputMaybe<Scalars["String"]["input"]>;
  generateCredentialsInWorker: Scalars["Boolean"]["input"];
  labels: Array<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  roleArn: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationAwsIntegrationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAwsIntegrationDetachArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAwsIntegrationUpdateArgs = {
  durationSeconds: Scalars["Int"]["input"];
  externalID: Scalars["String"]["input"];
  generateCredentialsInWorker: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
  labels: Array<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  roleArn: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationAzureDevOpsRepoIntegrationCreateArgs = {
  customInput: InputMaybe<CustomVcsInput>;
  organizationURL: Scalars["String"]["input"];
  personalAccessToken: Scalars["String"]["input"];
  userFacingHost: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAzureDevOpsRepoIntegrationDeleteArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationAzureDevOpsRepoIntegrationTestArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationAzureDevOpsRepoIntegrationUpdateArgs = {
  customInput: InputMaybe<CustomVcsUpdateInput>;
  organizationURL: Scalars["String"]["input"];
  personalAccessToken: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAzureIntegrationAttachArgs = {
  id: Scalars["ID"]["input"];
  read: Scalars["Boolean"]["input"];
  stack: Scalars["ID"]["input"];
  subscriptionId: InputMaybe<Scalars["String"]["input"]>;
  write: Scalars["Boolean"]["input"];
};

export type MutationAzureIntegrationAttachmentUpdateArgs = {
  id: Scalars["ID"]["input"];
  read: Scalars["Boolean"]["input"];
  subscriptionId: InputMaybe<Scalars["String"]["input"]>;
  write: Scalars["Boolean"]["input"];
};

export type MutationAzureIntegrationCreateArgs = {
  defaultSubscriptionId: InputMaybe<Scalars["String"]["input"]>;
  labels: Array<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
  tenantID: Scalars["String"]["input"];
};

export type MutationAzureIntegrationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAzureIntegrationDetachArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAzureIntegrationUpdateArgs = {
  defaultSubscriptionId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  labels: Array<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationBillingSubscriptionCreateArgs = {
  addons: InputMaybe<Array<BillingAddonType>>;
  input: SubscriptionInput;
  interval: InputMaybe<BillingCycleInterval>;
  paymentMethodID: Scalars["String"]["input"];
  tier: InputMaybe<BillingTier>;
};

export type MutationBillingSubscriptionUpdateInfoArgs = {
  input: SubscriptionInput;
};

export type MutationBillingSubscriptionUpdateTierArgs = {
  interval: InputMaybe<BillingCycleInterval>;
  tier: BillingTier;
};

export type MutationBillingSubscriptionUpdateV2Args = {
  input: SubscriptionUpdateV2Input;
};

export type MutationBitbucketCloudIntegrationCreateArgs = {
  appPassword: Scalars["String"]["input"];
  customInput: InputMaybe<CustomVcsInput>;
  username: Scalars["String"]["input"];
};

export type MutationBitbucketCloudIntegrationDeleteArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationBitbucketCloudIntegrationTestArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationBitbucketCloudIntegrationUpdateArgs = {
  appPassword: InputMaybe<Scalars["String"]["input"]>;
  customInput: InputMaybe<CustomVcsUpdateInput>;
  username: Scalars["String"]["input"];
};

export type MutationBitbucketDatacenterIntegrationCreateArgs = {
  accessToken: Scalars["String"]["input"];
  apiHost: Scalars["String"]["input"];
  customInput: InputMaybe<CustomVcsInput>;
  userFacingHost: Scalars["String"]["input"];
  username: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationBitbucketDatacenterIntegrationDeleteArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationBitbucketDatacenterIntegrationTestArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationBitbucketDatacenterIntegrationUpdateArgs = {
  accessToken: InputMaybe<Scalars["String"]["input"]>;
  apiHost: Scalars["String"]["input"];
  customInput: InputMaybe<CustomVcsUpdateInput>;
  userFacingHost: Scalars["String"]["input"];
  username: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationBlueprintAttachToIntegrationArgs = {
  id: Scalars["ID"]["input"];
  input: BlueprintAttachInput;
};

export type MutationBlueprintCloneArgs = {
  id: Scalars["ID"]["input"];
  input: BlueprintCloneInput;
};

export type MutationBlueprintCreateArgs = {
  input: BlueprintCreateInput;
};

export type MutationBlueprintCreateStackArgs = {
  id: Scalars["ID"]["input"];
  input: BlueprintStackCreateInput;
};

export type MutationBlueprintDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationBlueprintDetachIntegrationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationBlueprintParseTemplateArgs = {
  template: Scalars["String"]["input"];
};

export type MutationBlueprintUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: BlueprintCreateInput;
};

export type MutationBlueprintVersionedGroupCreateArgs = {
  input: BlueprintVersionedGroupCreateInput;
};

export type MutationBlueprintVersionedGroupDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationBlueprintVersionedGroupUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: BlueprintVersionedGroupCreateInput;
};

export type MutationBlueprintWithGroupCreateArgs = {
  input: BlueprintWithGroupCreateInput;
};

export type MutationBlueprintWithGroupDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationBlueprintWithGroupUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: BlueprintWithGroupUpdateInput;
};

export type MutationChangeLlmVendorArgs = {
  vendor: LlmVendor;
};

export type MutationCliConfirmationTokenArgs = {
  pubKey: Scalars["String"]["input"];
};

export type MutationCompleteGenericFormArgs = {
  data: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationContextAttachArgs = {
  id: Scalars["ID"]["input"];
  priority: Scalars["Int"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationContextConfigAddArgs = {
  config: ConfigInput;
  context: Scalars["ID"]["input"];
};

export type MutationContextConfigDeleteArgs = {
  context: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationContextCreateArgs = {
  description: InputMaybe<Scalars["String"]["input"]>;
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationContextCreateV2Args = {
  input: ContextInput;
};

export type MutationContextDeleteArgs = {
  force: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationContextDetachArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationContextUpdateArgs = {
  description: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationContextUpdateV2Args = {
  id: Scalars["ID"]["input"];
  input: ContextInput;
  replaceConfigElements: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationDeleteRunStateSummaryArgs = {
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
  stateVersion: Scalars["Int"]["input"];
};

export type MutationDeleteRunSummaryArgs = {
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationDismissNotificationGroupArgs = {
  groupingKey: Scalars["ID"]["input"];
};

export type MutationDismissNotificationsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationExternalIntegrationCreateArgs = {
  input: ExternalIntegrationInput;
};

export type MutationExternalIntegrationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationGithubAppCreateFromManifestArgs = {
  code: Scalars["String"]["input"];
  customInput: InputMaybe<CustomVcsInput>;
  state: Scalars["String"]["input"];
};

export type MutationGithubAppGenerateManifestArgs = {
  input: GitHubAppManifestInput;
};

export type MutationGithubEnterpriseIntegrationCreateArgs = {
  apiHost: Scalars["String"]["input"];
  appID: Scalars["String"]["input"];
  customInput: InputMaybe<CustomVcsInput>;
  privateKey: Scalars["String"]["input"];
  userFacingHost: InputMaybe<Scalars["String"]["input"]>;
  webhookSecret: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationGithubEnterpriseIntegrationDeleteArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationGithubEnterpriseIntegrationTestArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationGithubEnterpriseIntegrationUpdateArgs = {
  apiHost: Scalars["String"]["input"];
  customInput: InputMaybe<CustomVcsUpdateInput>;
  privateKey: InputMaybe<Scalars["String"]["input"]>;
  userFacingHost: InputMaybe<Scalars["String"]["input"]>;
  webhookSecret: Scalars["String"]["input"];
};

export type MutationGithubIntegrationUpdateArgs = {
  vcsChecks: VcsCheck;
};

export type MutationGitlabIntegrationCreateArgs = {
  apiHost: Scalars["String"]["input"];
  customInput: InputMaybe<CustomVcsInput>;
  privateToken: Scalars["String"]["input"];
  userFacingHost: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationGitlabIntegrationDeleteArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationGitlabIntegrationTestArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationGitlabIntegrationUpdateArgs = {
  apiHost: InputMaybe<Scalars["String"]["input"]>;
  customInput: InputMaybe<CustomVcsUpdateInput>;
  privateToken: InputMaybe<Scalars["String"]["input"]>;
  userFacingHost: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationGpgKeyCreateArgs = {
  asciiArmor: Scalars["String"]["input"];
  description: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationGpgKeyRevokeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationGpgKeyUpdateArgs = {
  description: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationGroupUserEventArgs = {
  context: InputMaybe<Scalars["String"]["input"]>;
  traits: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationIdentifyUserEventArgs = {
  context: InputMaybe<Scalars["String"]["input"]>;
  traits: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationManagedUserDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationManagedUserGroupCreateArgs = {
  input: ManagedUserGroupCreateInput;
};

export type MutationManagedUserGroupDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationManagedUserGroupIntegrationCreateArgs = {
  input: ManagedUserGroupIntegrationCreateInput;
};

export type MutationManagedUserGroupIntegrationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationManagedUserGroupIntegrationUpdateArgs = {
  input: ManagedUserGroupIntegrationUpdateInput;
};

export type MutationManagedUserGroupUpdateArgs = {
  input: ManagedUserGroupUpdateInput;
};

export type MutationManagedUserInviteArgs = {
  input: ManagedUserInviteInput;
};

export type MutationManagedUserInviteV2Args = {
  input: ManagedUserInviteInputV2;
};

export type MutationManagedUserRequestInviteArgs = {
  email: Scalars["String"]["input"];
  userName: Scalars["String"]["input"];
};

export type MutationManagedUserRequestRoleChangeArgs = {
  requestedAccessLevel: SpaceAccessLevel;
  spaceId: Scalars["ID"]["input"];
};

export type MutationManagedUserResendInviteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationManagedUserUpdateArgs = {
  input: ManagedUserUpdateInput;
};

export type MutationModuleCreateArgs = {
  input: ModuleCreateInput;
};

export type MutationModuleDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationModuleDisableArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationModuleEnableArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationModulePublishArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationModuleUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: ModuleUpdateInput;
};

export type MutationModuleUpdateV2Args = {
  id: Scalars["ID"]["input"];
  input: ModuleUpdateV2Input;
};

export type MutationNamedWebhooksIntegrationCreateArgs = {
  input: NamedWebhooksIntegrationInput;
};

export type MutationNamedWebhooksIntegrationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationNamedWebhooksIntegrationDeleteHeadersArgs = {
  headerKeys: Array<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationNamedWebhooksIntegrationSetHeadersArgs = {
  id: Scalars["ID"]["input"];
  input: NamedWebhookHeaderInput;
};

export type MutationNamedWebhooksIntegrationUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: NamedWebhooksIntegrationInput;
};

export type MutationOauthTokenArgs = {
  code: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  temporaryToken: Scalars["String"]["input"];
};

export type MutationOauthUserArgs = {
  token: Scalars["String"]["input"];
};

export type MutationOidcCreateArgs = {
  claimMapping: InputMaybe<ClaimMappingInput>;
  clientCredentials: Scalars["String"]["input"];
  clientId: Scalars["String"]["input"];
  identityProviderHost: Scalars["String"]["input"];
};

export type MutationOidcUpdateArgs = {
  input: OidcUpdateInput;
};

export type MutationPageUserEventArgs = {
  context: InputMaybe<Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  properties: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPolicyAttachArgs = {
  id: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationPolicyCreateArgs = {
  body: Scalars["String"]["input"];
  description: InputMaybe<Scalars["String"]["input"]>;
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
  type: PolicyType;
};

export type MutationPolicyCreatev2Args = {
  input: PolicyCreateInput;
};

export type MutationPolicyDeleteArgs = {
  force: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationPolicyDetachArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationPolicySimulateArgs = {
  body: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
  libraries: InputMaybe<PolicyLibrariesInput>;
  type: PolicyType;
};

export type MutationPolicyUpdateArgs = {
  body: Scalars["String"]["input"];
  description: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationPolicyUpdatev2Args = {
  id: Scalars["ID"]["input"];
  input: PolicyUpdateInput;
};

export type MutationProposeWithOverridesArgs = {
  commitSha: InputMaybe<Scalars["String"]["input"]>;
  environmentVarsOverrides: InputMaybe<Array<EnvironmentVariable>>;
  stack: Scalars["ID"]["input"];
};

export type MutationRoleCreateArgs = {
  input: RoleInput;
};

export type MutationRoleDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRoleUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: RoleUpdateInput;
};

export type MutationRunCancelArgs = {
  note: InputMaybe<Scalars["String"]["input"]>;
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationRunCommentArgs = {
  body: Scalars["String"]["input"];
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationRunConfirmArgs = {
  note: InputMaybe<Scalars["String"]["input"]>;
  run: InputMaybe<Scalars["ID"]["input"]>;
  stack: Scalars["ID"]["input"];
};

export type MutationRunDiscardArgs = {
  note: InputMaybe<Scalars["String"]["input"]>;
  run: InputMaybe<Scalars["ID"]["input"]>;
  stack: Scalars["ID"]["input"];
};

export type MutationRunExternalDependencyMarkAsCompletedArgs = {
  dependency: Scalars["ID"]["input"];
  status: RunExternalDependencyStatus;
};

export type MutationRunPrioritizeSetArgs = {
  prioritize: Scalars["Boolean"]["input"];
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationRunPromoteArgs = {
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationRunProposeLocalWorkspaceArgs = {
  environmentVarsOverrides: InputMaybe<Array<EnvironmentVariable>>;
  stack: Scalars["ID"]["input"];
  workspace: Scalars["ID"]["input"];
};

export type MutationRunResourceCreateArgs = {
  commitSha: InputMaybe<Scalars["String"]["input"]>;
  proposed: InputMaybe<Scalars["Boolean"]["input"]>;
  stack: Scalars["ID"]["input"];
};

export type MutationRunRetryArgs = {
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationRunReviewArgs = {
  decision: RunReviewDecision;
  note: InputMaybe<Scalars["String"]["input"]>;
  run: InputMaybe<Scalars["ID"]["input"]>;
  stack: Scalars["ID"]["input"];
};

export type MutationRunStopArgs = {
  note: InputMaybe<Scalars["String"]["input"]>;
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
  state: InputMaybe<RunState>;
};

export type MutationRunTargetedReplanArgs = {
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
  targets: Array<Scalars["String"]["input"]>;
};

export type MutationRunTriggerArgs = {
  commitSha: InputMaybe<Scalars["String"]["input"]>;
  runType: InputMaybe<RunType>;
  runtimeConfig: InputMaybe<RuntimeConfigInput>;
  stack: Scalars["ID"]["input"];
};

export type MutationSamlCreateArgs = {
  dynamic: Scalars["Boolean"]["input"];
  metadata: Scalars["String"]["input"];
  nameIDFormat: InputMaybe<SamlNameIdFormat>;
};

export type MutationSavedFilterCreateArgs = {
  input: SavedFilterInput;
};

export type MutationSavedFilterDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationSavedFilterUpdateArgs = {
  data: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  isPublic: Scalars["Boolean"]["input"];
  name: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSecurityKeyDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationSessionDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationSlackAppConfigSetArgs = {
  input: SlackAppConfigInput;
};

export type MutationSpaceCreateArgs = {
  input: SpaceInput;
};

export type MutationSpaceDeleteArgs = {
  space: Scalars["ID"]["input"];
};

export type MutationSpaceUpdateArgs = {
  input: SpaceInput;
  space: Scalars["ID"]["input"];
};

export type MutationStackConfigAddArgs = {
  config: ConfigInput;
  stack: Scalars["ID"]["input"];
};

export type MutationStackConfigDeleteArgs = {
  id: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationStackCreateArgs = {
  input: StackInput;
  manageState: Scalars["Boolean"]["input"];
  slug: InputMaybe<Scalars["String"]["input"]>;
  stackObjectID: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationStackDeleteArgs = {
  destroyResources: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type MutationStackDependenciesAddReferenceArgs = {
  reference: StackDependencyReferenceInput;
  stackDependencyID: Scalars["ID"]["input"];
};

export type MutationStackDependenciesAddReferencesArgs = {
  references: Array<StackDependencyReferenceInput>;
  stackDependencyID: Scalars["ID"]["input"];
};

export type MutationStackDependenciesCreateInBatchArgs = {
  input: StackDependencyBatchInput;
};

export type MutationStackDependenciesDeleteReferenceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStackDependenciesDeleteReferencesArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MutationStackDependenciesUpdateReferenceArgs = {
  reference: StackDependencyReferenceUpdateInput;
};

export type MutationStackDependenciesUpdateReferencesArgs = {
  references: Array<StackDependencyReferenceUpdateInput>;
};

export type MutationStackDependencyCreateArgs = {
  input: StackDependencyInput;
};

export type MutationStackDependencyDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStackDisableArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStackEnableArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStackIntegrationAwsCreateArgs = {
  durationSeconds: InputMaybe<Scalars["Int"]["input"]>;
  externalID: InputMaybe<Scalars["String"]["input"]>;
  generateCredentialsInWorker: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  roleArn: Scalars["String"]["input"];
};

export type MutationStackIntegrationAwsDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStackIntegrationDriftDetectionCreateArgs = {
  input: DriftDetectionIntegrationInput;
  stack: Scalars["ID"]["input"];
};

export type MutationStackIntegrationDriftDetectionDeleteArgs = {
  stack: Scalars["ID"]["input"];
};

export type MutationStackIntegrationDriftDetectionUpdateArgs = {
  input: DriftDetectionIntegrationInput;
  stack: Scalars["ID"]["input"];
};

export type MutationStackIntegrationGcpCreateArgs = {
  id: Scalars["ID"]["input"];
  tokenScopes: Array<Scalars["String"]["input"]>;
};

export type MutationStackIntegrationGcpDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStackLockArgs = {
  id: Scalars["ID"]["input"];
  note: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationStackManagedStateImportArgs = {
  stackId: Scalars["ID"]["input"];
  state: Scalars["String"]["input"];
};

export type MutationStackManagedStateRollbackArgs = {
  managedStateVersionId: Scalars["String"]["input"];
  stackId: Scalars["ID"]["input"];
};

export type MutationStackReslugArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStackScheduledDeleteCreateArgs = {
  input: ScheduledDeleteInput;
  stack: Scalars["ID"]["input"];
};

export type MutationStackScheduledDeleteDeleteArgs = {
  scheduledDelete: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationStackScheduledDeleteUpdateArgs = {
  input: ScheduledDeleteInput;
  scheduledDelete: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationStackScheduledRunCreateArgs = {
  input: ScheduledRunInput;
  stack: Scalars["ID"]["input"];
};

export type MutationStackScheduledRunDeleteArgs = {
  scheduledRun: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationStackScheduledRunUpdateArgs = {
  input: ScheduledRunInput;
  scheduledRun: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationStackScheduledTaskCreateArgs = {
  input: ScheduledTaskInput;
  stack: Scalars["ID"]["input"];
};

export type MutationStackScheduledTaskDeleteArgs = {
  scheduledTask: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationStackScheduledTaskUpdateArgs = {
  input: ScheduledTaskInput;
  scheduledTask: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationStackSetCurrentCommitArgs = {
  id: Scalars["ID"]["input"];
  sha: Scalars["String"]["input"];
};

export type MutationStackSyncCommitArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStackUnlockArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStackUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: StackInput;
};

export type MutationStarSetArgs = {
  id: Scalars["ID"]["input"];
  star: Scalars["Boolean"]["input"];
};

export type MutationSummarizeRunArgs = {
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationSummarizeRunStateArgs = {
  run: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
  stateVersion: Scalars["Int"]["input"];
};

export type MutationTaskCreateArgs = {
  command: Scalars["String"]["input"];
  skipInitialization: InputMaybe<Scalars["Boolean"]["input"]>;
  stack: Scalars["ID"]["input"];
};

export type MutationTerraformProviderCreateArgs = {
  description: InputMaybe<Scalars["String"]["input"]>;
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  space: Scalars["ID"]["input"];
  type: Scalars["ID"]["input"];
};

export type MutationTerraformProviderDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationTerraformProviderSetVisibilityArgs = {
  id: Scalars["ID"]["input"];
  public: Scalars["Boolean"]["input"];
};

export type MutationTerraformProviderUpdateArgs = {
  description: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  space: Scalars["ID"]["input"];
};

export type MutationTerraformProviderVersionCreateArgs = {
  input: TerraformProviderVersionInput;
  provider: Scalars["ID"]["input"];
};

export type MutationTerraformProviderVersionDeleteArgs = {
  version: Scalars["ID"]["input"];
};

export type MutationTerraformProviderVersionPublishArgs = {
  version: Scalars["ID"]["input"];
};

export type MutationTerraformProviderVersionRegisterPlatformArgs = {
  input: TerraformProviderVersionPlatformInput;
  version: Scalars["ID"]["input"];
};

export type MutationTerraformProviderVersionRegisterPlatformV2Args = {
  input: TerraformProviderVersionPlatformInput;
  version: Scalars["ID"]["input"];
};

export type MutationTerraformProviderVersionRevokeArgs = {
  version: Scalars["ID"]["input"];
};

export type MutationTerraformProviderVersionUpdateArgs = {
  description: Scalars["String"]["input"];
  version: Scalars["ID"]["input"];
};

export type MutationTrackUserEventArgs = {
  context: InputMaybe<Scalars["String"]["input"]>;
  name: InputMaybe<Scalars["String"]["input"]>;
  properties: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUiConfigStoreArgs = {
  input: Scalars["String"]["input"];
};

export type MutationUploadLocalWorkspaceArgs = {
  stack: Scalars["ID"]["input"];
};

export type MutationUserRoleBindingCreateArgs = {
  input: UserRoleBindingInput;
};

export type MutationUserRoleBindingDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUserRoleBindingUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: UserRoleBindingInput;
};

export type MutationVcsAgentPoolCreateArgs = {
  description: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationVcsAgentPoolDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationVcsAgentPoolResetArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationVcsAgentPoolUpdateArgs = {
  description: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationVersionAnnotateArgs = {
  id: Scalars["ID"]["input"];
  module: Scalars["ID"]["input"];
  notes: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationVersionCreateArgs = {
  commitSha: InputMaybe<Scalars["String"]["input"]>;
  module: Scalars["ID"]["input"];
  version: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationVersionDeleteArgs = {
  id: Scalars["ID"]["input"];
  module: Scalars["ID"]["input"];
};

export type MutationVersionProposeLocalWorkspaceArgs = {
  module: Scalars["ID"]["input"];
  testIds: InputMaybe<Array<Scalars["String"]["input"]>>;
  workspace: Scalars["ID"]["input"];
};

export type MutationVersionTriggerArgs = {
  id: Scalars["ID"]["input"];
  module: Scalars["ID"]["input"];
};

export type MutationVersionYankArgs = {
  id: Scalars["ID"]["input"];
  module: Scalars["ID"]["input"];
  note: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationWebhooksIntegrationCreateArgs = {
  input: WebhooksIntegrationInput;
  stack: Scalars["ID"]["input"];
};

export type MutationWebhooksIntegrationDeleteArgs = {
  id: Scalars["ID"]["input"];
  stack: Scalars["ID"]["input"];
};

export type MutationWebhooksIntegrationUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: WebhooksIntegrationInput;
  stack: Scalars["ID"]["input"];
};

export type MutationWorkerDrainSetArgs = {
  drain: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
  workerPool: Scalars["ID"]["input"];
};

export type MutationWorkerPoolCreateArgs = {
  certificateSigningRequest: Scalars["String"]["input"];
  description: InputMaybe<Scalars["String"]["input"]>;
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationWorkerPoolCycleArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationWorkerPoolDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationWorkerPoolResetArgs = {
  certificateSigningRequest: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationWorkerPoolUpdateArgs = {
  description: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
  space: InputMaybe<Scalars["ID"]["input"]>;
};

export type NamedWebhookHeaderInput = {
  entries: Array<KeyValuePair>;
};

/** A NamedWebhooksIntegration is a webhooks integration attached to a space with a name that can be referenced via notification policies. Each space can have one or more integration, allowing notification policies to route notifications to different webhook endpoints. */
export type NamedWebhooksIntegration = {
  __typename?: "NamedWebhooksIntegration";
  /** CreatedAt is unix timestamp (in seconds) at which the webhook was created */
  createdAt: Scalars["Int"]["output"];
  /** Indicates whether the integration has been deleted. */
  deleted: Scalars["Boolean"]["output"];
  /**
   * Paginated list of deliveries from this integration. The optional opaque
   * token is used for pagination and should be copied from the previous
   * WebhookDeliveries response.
   */
  deliveries: Maybe<WebhookDeliveries>;
  /** Extended details of a single delivery. */
  deliveryDetails: Maybe<WebhookDeliveryDetails>;
  /** Enable or disable sending webhooks */
  enabled: Scalars["Boolean"]["output"];
  /** User provided endpoint to send webhooks to */
  endpoint: Scalars["String"]["output"];
  /** Named Webhooks Integration ID */
  id: Scalars["ID"]["output"];
  /** Labels associated with the endpoint. This can be used for matching in notification policies. */
  labels: Array<Scalars["String"]["output"]>;
  /** Name of the webhooks endpoint */
  name: Scalars["String"]["output"];
  /**
   * Secret to be hashed and sent with payload
   * @deprecated This property is no longer used.
   */
  secret: Scalars["String"]["output"];
  /**
   * Secret headers is a list of header keys which are set for this webhook.
   * Only the keys are returned as values potentially have sensitive data.
   */
  secretHeaders: Array<Scalars["String"]["output"]>;
  /** Space to which this integration belongs. */
  space: Space;
};

/** A NamedWebhooksIntegration is a webhooks integration attached to a space with a name that can be referenced via notification policies. Each space can have one or more integration, allowing notification policies to route notifications to different webhook endpoints. */
export type NamedWebhooksIntegrationDeliveriesArgs = {
  token: InputMaybe<Scalars["String"]["input"]>;
};

/** A NamedWebhooksIntegration is a webhooks integration attached to a space with a name that can be referenced via notification policies. Each space can have one or more integration, allowing notification policies to route notifications to different webhook endpoints. */
export type NamedWebhooksIntegrationDeliveryDetailsArgs = {
  key: Scalars["String"]["input"];
};

export type NamedWebhooksIntegrationConnection = {
  __typename?: "NamedWebhooksIntegrationConnection";
  cursor: Scalars["String"]["output"];
  node: NamedWebhooksIntegration;
};

export type NamedWebhooksIntegrationInput = {
  /** Enable or disable sending webhooks */
  enabled: Scalars["Boolean"]["input"];
  /** User endpoint to send webhooks */
  endpoint: Scalars["String"]["input"];
  /** Labels for the integration */
  labels: Array<Scalars["String"]["input"]>;
  /** Name for the endpoint */
  name: Scalars["String"]["input"];
  /** Secret to be hashed and sent with payload */
  secret: InputMaybe<Scalars["String"]["input"]>;
  /** Space to use for this integration */
  space: Scalars["ID"]["input"];
};

/** A newly created version of a managed Terraform provider. */
export type NewTerraformProviderVersion = {
  __typename?: "NewTerraformProviderVersion";
  /**
   * Upload Headers for the SHA256SUMS.sig file, that should be send with the presign request.
   * Only populated when creating the version.
   */
  sha256SumsSigUploadHeaders: Maybe<StringMap>;
  /**
   * Upload URL for the SHA256SUMS.sig file. Only populated when creating the
   * version.
   */
  sha256SumsSigUploadURL: Scalars["String"]["output"];
  /**
   * Upload Headersfor the SHA256SUMS file that should be send with the presign request.
   * Only populated when creating the version.
   */
  sha256SumsUploadHeaders: Maybe<StringMap>;
  /**
   * Upload URL for the SHA256SUMS file. Only populated when creating the
   * version.
   */
  sha256SumsUploadURL: Scalars["String"]["output"];
  /** Newly created version. */
  version: Maybe<TerraformProviderVersion>;
};

export type Notifiable = {
  notificationCount: Scalars["Int"]["output"];
};

/** Notification represents a single notification. */
export type Notification = {
  __typename?: "Notification";
  /** Body of the notification. */
  body: Scalars["String"]["output"];
  /** Dismissed indicates whether a user dismissed a notification. */
  dismissed: Scalars["Boolean"]["output"];
  /** GroupingKey is for marking the same issues. */
  groupingKey: Scalars["ID"]["output"];
  /** Globally unique ID of the notification. */
  id: Scalars["ID"]["output"];
  /** Unix timestamp representing the last occurrence of the notification. */
  lastOccurrenceAt: Scalars["Int"]["output"];
  /** Number of occurrences of the notification. */
  occurrencesCount: Scalars["Int"]["output"];
  /** Notification severity. */
  severity: NotificationSeverity;
  /** Entity to which notification is attached. */
  target: Scalars["ID"]["output"];
  /** Unix timestamp representing Notification creation. */
  timestamp: Scalars["Int"]["output"];
  /** Title of the notification. */
  title: Scalars["String"]["output"];
  /** Type of the notification. */
  type: NotificationType;
};

/** NotificationConnection represents a single notification in a page of results. */
export type NotificationConnection = {
  __typename?: "NotificationConnection";
  cursor: Scalars["String"]["output"];
  node: Notification;
};

export enum NotificationSeverity {
  Error = "ERROR",
  Info = "INFO",
  Warning = "WARNING",
}

export enum NotificationType {
  Account = "ACCOUNT",
  AwsIntegration = "AWS_INTEGRATION",
  Module = "MODULE",
  Policy = "POLICY",
  Stack = "STACK",
  WorkerPool = "WORKER_POOL",
}

/** OAuthRedirect is a response to an OAuth redirect query. */
export type OAuthRedirect = {
  __typename?: "OAuthRedirect";
  /**
   * enabled indicates whether logging in via the OAuth redirect is enabled or not. It
   * can be disabled for Self-Hosted accounts where no SSO configuration has been setup yet.
   */
  enabled: Scalars["Boolean"]["output"];
  /** Full URL to the identity provider (GitHub or Slack). */
  providerUrl: Scalars["String"]["output"];
  /** Temporary token to be persisted in the browser. */
  temporaryToken: Scalars["String"]["output"];
};

/** OIDCSettings represent account's OIDC settings */
export type OidcSettings = {
  __typename?: "OIDCSettings";
  /**
   * Login of the user who always gets an administrative access to the account.
   * This is meant to prevent locking yourself out of the account if the settings
   * are incorrect.
   */
  adminLogin: Maybe<Scalars["String"]["output"]>;
  /** contains custom claims mapping */
  claimMapping: ClaimMapping;
  /**  client id authorized by Identity Provider to initialize Oauth 2.0 authorization flow */
  clientId: Scalars["String"]["output"];
  /** address of the Identity Provider to initialize Oauth 2.0 authorization flow (e.g. https://accounts.google.com) */
  identityProviderHost: Scalars["String"]["output"];
};

export type OidcUpdateInput = {
  claimMapping: InputMaybe<ClaimMappingInput>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  /** Cursor of the last item in the list */
  endCursor: Scalars["String"]["output"];
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** Cursor of the first item in the list */
  startCursor: Scalars["String"]["output"];
};

/** The outcome of a collection of plan policies for a Run. */
export type PlanPoliciesOutcome = {
  __typename?: "PlanPoliciesOutcome";
  /** Unix timestamp representing plan policy evaluation time. */
  createdAt: Scalars["Int"]["output"];
  /** List of deny messages. */
  deny: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  /** List of warning messages. */
  warn: Array<Scalars["String"]["output"]>;
};

/**
 * Policy is a rule or collection of rules that allow injecting custom logic into
 * Spacelift's decision points.
 */
export type Policy = Notifiable & {
  __typename?: "Policy";
  /** Single stack attachment for this Policy. */
  attachedStack: Maybe<PolicyStackAttachment>;
  /** List of Stacks attached to this Policy. */
  attachedStacks: Array<PolicyStackAttachment>;
  /** Body of the policy. */
  body: Scalars["String"]["output"];
  /** Unix timestamp representing the policy creation. */
  createdAt: Scalars["Int"]["output"];
  /** Description of the policy. */
  description: Maybe<Scalars["String"]["output"]>;
  /**
   * List of all the policy evaluation records available in the account,
   * associated with this policy.
   */
  evaluationRecords: Array<PolicyEvaluationRecord>;
  /** Returns a single policy evaluation sample. */
  evaluationSample: Maybe<PolicyEvaluationSample>;
  /** Indicates whether any stacks are attached to this policy, either directly or via auto-attachment. */
  hasAttachedStacks: Scalars["Boolean"]["output"];
  /** Unique (within a single organization), immutable name of the policy. */
  id: Scalars["ID"]["output"];
  /** User-defined labels set on the Policy. */
  labels: Array<Scalars["String"]["output"]>;
  /** Name of the policy. */
  name: Scalars["String"]["output"];
  /** Number of the new policy notifications. */
  notificationCount: Scalars["Int"]["output"];
  /** ID of the space this Policy belongs to. */
  space: Scalars["ID"]["output"];
  /** Details of the space this Policy belongs to. */
  spaceDetails: Space;
  /** Type of the policy. */
  type: PolicyType;
  /** Unix timestamp of the last policy update. */
  updatedAt: Scalars["Int"]["output"];
};

/**
 * Policy is a rule or collection of rules that allow injecting custom logic into
 * Spacelift's decision points.
 */
export type PolicyAttachedStackArgs = {
  id: Scalars["ID"]["input"];
};

/**
 * Policy is a rule or collection of rules that allow injecting custom logic into
 * Spacelift's decision points.
 */
export type PolicyEvaluationSampleArgs = {
  key: Scalars["String"]["input"];
};

/** PolicyConnection represents a single policy in a page of results. */
export type PolicyConnection = {
  __typename?: "PolicyConnection";
  cursor: Scalars["String"]["output"];
  node: Policy;
};

/** All arguments to create an individual Policy. */
export type PolicyCreateInput = {
  /** Body of the policy. */
  body: Scalars["String"]["input"];
  /** Description of the policy. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** User-defined labels for the Policy. */
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Name of the policy. */
  name: Scalars["String"]["input"];
  /** Optional ULID of a template that is used to create the policy. */
  policyTemplateULID: InputMaybe<Scalars["String"]["input"]>;
  /** ID of the space this Policy will belong to. */
  space: InputMaybe<Scalars["ID"]["input"]>;
  /** Type of the policy. */
  type: PolicyType;
};

/**
 * PolicyEvaluationRecord is a record of policy evaluation. It contains a key,
 * which can be separately used to retrieve the associated
 * PolicyEvaluationSample.
 */
export type PolicyEvaluationRecord = {
  __typename?: "PolicyEvaluationRecord";
  /** Key of the policy input. */
  key: Scalars["String"]["output"];
  /** Outcome of evaluating policy. */
  outcome: Scalars["String"]["output"];
  /** Unix timestamp of policy evaluation. */
  timestamp: Scalars["Int"]["output"];
};

/** PolicyEvaluationSample represents a single instance of policy evaluation. */
export type PolicyEvaluationSample = {
  __typename?: "PolicyEvaluationSample";
  /** Body of the original policy. */
  body: Scalars["String"]["output"];
  /** Error of the policy evaluation. */
  error: Scalars["String"]["output"];
  /** Input to the original policy evaluation. */
  input: Scalars["String"]["output"];
  /** List of libraries used while evaluating the policy. */
  libraries: Array<SampledPolicyLibrary>;
  /** Synthetic (single-word) representation of the policy outcome. */
  outcome: Scalars["String"]["output"];
  /** String representation of Rego/OPA policy evaluation results. */
  results: Scalars["String"]["output"];
};

/**
 * PolicyLibrariesInput is the input containing policy libraries used to
 * evaluate the main policy body.
 */
export type PolicyLibrariesInput = {
  libraries: Array<PolicyLibraryInput>;
};

/**
 * PolicyLibraryInput is the input type containing the data for a single policy
 * library used to evaluate the main policy body.
 */
export type PolicyLibraryInput = {
  /** The body of the library. */
  body: Scalars["String"]["input"];
  /** The name of the library. */
  name: Scalars["String"]["input"];
};

/** PolicyRuntimeDetails represents the versions of the policy runtime used */
export type PolicyRuntimeDetails = {
  __typename?: "PolicyRuntimeDetails";
  /** The version of open-policy-agent used to evaluate policies */
  openPolicyAgentVersion: Scalars["String"]["output"];
};

/** PolicyStackAttachment is the policy-stack attachment viewed from the Policy. */
export type PolicyStackAttachment = {
  __typename?: "PolicyStackAttachment";
  /** Globally unique policy attachment identifier. */
  id: Scalars["ID"]["output"];
  /** True if the policy is autoattached. */
  isAutoattached: Scalars["Boolean"]["output"];
  /** True if the attached stack is type of Module. */
  isModule: Scalars["Boolean"]["output"];
  /** Slug of the attached stack. */
  stackId: Scalars["ID"]["output"];
  /** Name of the attached stack. */
  stackName: Scalars["String"]["output"];
  stackSpaceDetails: Space;
};

export type PolicyTemplate = {
  __typename?: "PolicyTemplate";
  /** Body of the policy template. */
  body: Scalars["String"]["output"];
  /** Description of the policy template. */
  description: Maybe<Scalars["String"]["output"]>;
  /** Unique ID of the policy template. */
  id: Scalars["ID"]["output"];
  /** Labels of the policy template. */
  labels: Array<Scalars["String"]["output"]>;
  /** Name of the policy template. */
  name: Scalars["String"]["output"];
  /** Slug of the policy template. */
  slug: Scalars["String"]["output"];
  /** Type of the policy template. */
  type: PolicyType;
};

export type PolicyTemplateConnection = {
  __typename?: "PolicyTemplateConnection";
  cursor: Scalars["String"]["output"];
  node: PolicyTemplate;
};

/** PolicyType represents a type of Spacelift policy. */
export enum PolicyType {
  /** Access policy - stack or module level RBAC. */
  Access = "ACCESS",
  /** Approval policy, allows approving or rejecting runs and tasks. */
  Approval = "APPROVAL",
  /** Git push policy - customizing Spacelift's reaction to VCS events. */
  GitPush = "GIT_PUSH",
  /** Initialization policy - allows blocking runs from executing. */
  Initialization = "INITIALIZATION",
  /** Login policy - account-level RBAC. */
  Login = "LOGIN",
  /** Notification policy, allows to configure notifications and how they're sent. */
  Notification = "NOTIFICATION",
  /** Plan policy - introspects Terraform changes for hard or soft violations. */
  Plan = "PLAN",
  /** Deprecated - use ACCESS instead. */
  StackAccess = "STACK_ACCESS",
  /** Task policy - allows blocking tasks from executing. */
  Task = "TASK",
  /** Deprecated - use TASK instead. */
  TaskRun = "TASK_RUN",
  /** Deprecated - use PLAN instead. */
  TerraformPlan = "TERRAFORM_PLAN",
  /** Trigger policy, allows creating event-driven workflows between stacks. */
  Trigger = "TRIGGER",
}

/** All arguments to update an individual Policy. */
export type PolicyUpdateInput = {
  /** Body of the policy. */
  body: Scalars["String"]["input"];
  /** Description of the policy. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** User-defined labels for the Policy. */
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Name of the policy. */
  name: Scalars["String"]["input"];
  /** ID of the space this Policy will belong to. */
  space: InputMaybe<Scalars["ID"]["input"]>;
};

/** PublicWorkerPool contains details of public worker pool for account. */
export type PublicWorkerPool = {
  __typename?: "PublicWorkerPool";
  /**
   * Number of currently executing runs on the public worker pool for this account.
   * @deprecated Use busyWorkers fields instead.
   */
  activeRuns: Scalars["Int"]["output"];
  /** Number of currently busy workers in the public worker pool for this account. */
  busyWorkers: Scalars["Int"]["output"];
  /** Maximum number of simultaneously executing runs on the public worker pool for account. */
  parallelism: Scalars["Int"]["output"];
  /** Number of runs on the public worker ready for processing for this account. */
  pendingRuns: Scalars["Int"]["output"];
  /**
   * Count of queued runs on the public worker pool workers for this account.
   * @deprecated Use 'schedulableRunsCount' instead.
   */
  queuedRunsCount: Scalars["Int"]["output"];
  /** Count of schedulable runs on the public worker pool workers for this account. */
  schedulableRunsCount: Scalars["Int"]["output"];
  /** Search Runs for this WorkerPool. */
  searchSchedulableRuns: Maybe<SearchSchedulableRunsOutput>;
  /** Search Runs Suggestions for this WorkerPool. */
  searchSchedulableRunsSuggestions: Maybe<SearchSuggestionsOutput>;
  /** Search stacks/modules using this WorkerPool. */
  searchUsers: SearchWorkerPoolUsersOutput;
  /** Search stacks/modules suggestions using this WorkerPool. */
  searchUsersSuggestions: SearchSuggestionsOutput;
  /** Count of users on the public worker pool workers for this account. */
  usersCount: Scalars["Int"]["output"];
};

/** PublicWorkerPool contains details of public worker pool for account. */
export type PublicWorkerPoolSearchSchedulableRunsArgs = {
  input: SearchInput;
};

/** PublicWorkerPool contains details of public worker pool for account. */
export type PublicWorkerPoolSearchSchedulableRunsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

/** PublicWorkerPool contains details of public worker pool for account. */
export type PublicWorkerPoolSearchUsersArgs = {
  input: SearchInput;
};

/** PublicWorkerPool contains details of public worker pool for account. */
export type PublicWorkerPoolSearchUsersSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

/** PullRequest is Spacelift's representation of Pull Request on GitHub. */
export type PullRequest = {
  __typename?: "PullRequest";
  /** GitHub username of the PullRequest creator. */
  authorLogin: Scalars["String"]["output"];
  /** Unix timestamp representing Run creation. */
  createdAt: Scalars["Int"]["output"];
  /** Draft status of the PullRequest. */
  draft: Scalars["Boolean"]["output"];
  /** Immutable name of the branch from which the Pull Request is created. */
  headBranch: Scalars["String"]["output"];
  /** Web URL of the branch from which the Pull Request is created. */
  headBranchURL: Scalars["String"]["output"];
  /** Git commit SHA of the latest commit to the branch. */
  headSHA: Scalars["String"]["output"];
  /**
   * Locally (within the scope of a Stack) unique identifier of the PullRequest,
   * given by GitHub.
   */
  id: Scalars["ID"]["output"];
  /**
   * Most recent Run on the Pull Request.
   * DEPRECATED, use Runs instead.
   */
  run: Run;
  /** Most recent Runs on the Pull Request. */
  runs: Array<Run>;
  /** Current title of the Pull Request. */
  title: Scalars["String"]["output"];
  /** Unix timestamp of the last update. */
  updatedAt: Scalars["Int"]["output"];
  /** Immutable URL of the Pull Request on GitHub. */
  url: Scalars["String"]["output"];
};

/** Possible Pulumi entity types. */
export type PulumiEntity = PulumiOutput | PulumiResource | PulumiStack;

/** Metadata for a Pulumi output. */
export type PulumiOutput = {
  __typename?: "PulumiOutput";
  /** Hash of the value, always available. */
  hash: Scalars["String"]["output"];
  /** If the output is marked as sensitive. */
  sensitive: Scalars["Boolean"]["output"];
  /** Value of the output. Only available if it's not marked as sensitive, we don't store it otherwise. */
  value: Maybe<Scalars["String"]["output"]>;
};

/** Metadata for a Pulumi resource. */
export type PulumiResource = {
  __typename?: "PulumiResource";
  /** Pulumi ID of resource. */
  id: Scalars["String"]["output"];
  /** Property values. As a JSON-encoded string. */
  outputs: Scalars["String"]["output"];
  /** Parent object of the resource. */
  parent: Scalars["String"]["output"];
  /** Provider the resource stems from. */
  provider: Scalars["String"]["output"];
  /** Pulumi URN of resource. */
  urn: Scalars["String"]["output"];
};

/** Metadata for a Pulumi stack. */
export type PulumiStack = {
  __typename?: "PulumiStack";
  /**
   * This field exists solely for the purpose of adhering to the GraphQL spec,
   * which requires presence of at least one field in a type.
   */
  phantom: Scalars["Boolean"]["output"];
};

export type Query = {
  __typename?: "Query";
  /** AcceptedTermsAndConditionsAI informs if the account has  accepted the AI usage terms and conditions */
  acceptedTermsAndConditionsAI: Scalars["Boolean"]["output"];
  /** Whether the grace period of deletion has ended and account can be deleted */
  accountCanBeDeleted: Scalars["Boolean"]["output"];
  /** Timestamp when the account can be deleted. */
  accountCanBeDeletedAt: Maybe<Scalars["Int"]["output"]>;
  /** Account-level setting indicating if non-root admins can create subspaces. */
  allowNonRootAdminSpaceCreation: Scalars["Boolean"]["output"];
  /** Individual AnsibleHost by ID. */
  ansibleHost: Maybe<AnsibleHost>;
  /** Returns a list of related Ansible Hosts. */
  ansibleHosts: Array<AnsibleHost>;
  /** Returns a list of related Ansible Tasks. */
  ansibleTasks: Array<AnsibleTask>;
  /** List of all API keys for the account. */
  apiKeys: Array<ApiKey>;
  /**
   * Account-level setting indicating if API keys can be managed by non-root
   * admins.
   */
  apiKeysManagedByNonRootAdmins: Scalars["Boolean"]["output"];
  /** List of aws integrations that can be attached Stacks/Modules in given space. */
  attachableAwsIntegrationsInSpace: Array<AwsIntegration>;
  /** List of azure integrations that can be attached Stacks/Modules in given space. */
  attachableAzureIntegrationsInSpace: Array<AzureIntegration>;
  /** List of contexts that can be attached Stacks/Modules in given space. */
  attachableContextsInSpace: Array<Context>;
  attachableExternalIntegrationInSpace: Array<ExternalIntegration>;
  /** List of policies that can be attached to Stacks/Modules in given space. */
  attachablePoliciesInSpace: Array<Policy>;
  /** List of VCS integrations that can be attached Stacks/Modules in given space. */
  attachableVCSIntegrationsForProviderInSpace: Array<VcsIntegration>;
  /** List of worker pools that can be attached Stacks/Modules in given space. */
  attachableWorkerPoolsInSpace: Array<WorkerPool>;
  /** How many days built-in audit trails are stored */
  auditTrailRetentionDays: Scalars["Int"]["output"];
  /** Show details of AuditTrailWebhook. */
  auditTrailWebhook: Maybe<AuditTrailWebhook>;
  /** Authorization scheme for the account */
  authorizationScheme: AuthorizationScheme;
  /**
   * Given the space and the labels, returns the list of autoattachment targets.
   * The list is not exhaustive, it only contains the 100 most recently updated
   * targets.
   */
  autoattachmentForLabels: AutoattachmentTargets;
  /** availableBillingAddons returns the list of available billing addons. */
  availableBillingAddons: Array<BillingAddon>;
  /** availableSelfServicePlans returns the list of billing plans that can be bought via self-service. */
  availableSelfServicePlans: Array<BillingPlan>;
  /** Returns all user groups that are reported by the identity provider when users log in to Spacelift. */
  availableUserGroups: Array<Scalars["String"]["output"]>;
  awarenessSourceSurvey: Maybe<AwarenessSourceSurvey>;
  /** Details of a single AWS integration. */
  awsIntegration: Maybe<AwsIntegration>;
  /** Details of a single AWS integration looked up by the integration name. */
  awsIntegrationByName: Maybe<AwsIntegration>;
  /**
   * Lists any AWS integrations connected to the account. By default this excludes any integrations
   * that were created via the stackIntegrationAwsCreate mutation. You can return all integrations
   * by setting includeLegacy to true.
   */
  awsIntegrations: Array<AwsIntegration>;
  /** Shows Azure DevOps Repos integration details. */
  azureDevOpsRepoIntegration: Maybe<AzureDevOpsRepoIntegration>;
  /** Endpoint for incoming Azure DevOps webhooks. */
  azureDevOpsWebhooksEndpoint: Scalars["String"]["output"];
  /**
   * Details of a single Azure integration. If the id is not provided, it will return
   * the default Azure Integration.
   */
  azureIntegration: Maybe<AzureIntegration>;
  /** Details of a single Azure integration looked up by the integration name. */
  azureIntegrationByName: Maybe<AzureIntegration>;
  /** Lists any Azure integrations connected to the account. */
  azureIntegrations: Array<AzureIntegration>;
  /** Whether the account is billed externally (not through Stripe) */
  billedExternally: Scalars["Boolean"]["output"];
  /** Shows Stripe Billing subscription details */
  billingSubscription: Maybe<BillingSubscription>;
  /** Shows Bitbucket Cloud integration details. */
  bitbucketCloudIntegration: Maybe<BitbucketCloudIntegration>;
  /** Endpoint for incoming Bitbucket Cloud webhooks. */
  bitbucketCloudWebhooksEndpoint: Scalars["String"]["output"];
  /** Shows Bitbucket Datacenter integration details. */
  bitbucketDatacenterIntegration: Maybe<BitbucketDatacenterIntegration>;
  /** Endpoint for incoming Bitbucket Datacenter webhooks. */
  bitbucketDatacenterWebhooksEndpoint: Scalars["String"]["output"];
  /** Returns a single blueprint. */
  blueprint: Maybe<Blueprint>;
  /** The json schema of a blueprint. */
  blueprintSchema: Scalars["String"]["output"];
  /** Returns a single blueprint group details. */
  blueprintVersionedGroup: Maybe<BlueprintVersionedGroup>;
  /** List of branches for a given repository. */
  branches: Array<Scalars["String"]["output"]>;
  /** Details of a single Context. */
  context: Maybe<Context>;
  /** List of all the contexts in the account. */
  contexts: Array<Context>;
  /** Info about git build related data, self-hosted licence. Not valid in SaaS. */
  debugInfo: DebugInfo;
  /** EnforceMFA determines whether MFA is required for all users in the account. */
  enforceMFA: Scalars["Boolean"]["output"];
  /**
   * evaluateFeatureFlags returns the current value of the specified feature flags. This API is
   * only available on Self-Hosted installations, and trying to use it on Spacelift SaaS
   * will result in an empty array being returned.
   */
  evaluateFeatureFlags: Array<FeatureFlagEvaluationResult>;
  externalIntegration: ExternalIntegration;
  externalIntegrations: Array<ExternalIntegration>;
  /** Returns a list of available generic forms */
  genericFormsList: Array<GenericFormDescription>;
  /**
   * GitHub Enterprise Integration settings. If the id is not provided, it will return
   * the default GitHub Enterprise Integration.
   */
  githubEnterpriseIntegration: Maybe<GithubEnterpriseIntegration>;
  /** The webhooks endpoint to use in your GitHub Enterprise App */
  githubEnterpriseWebhooksEndpoint: Scalars["String"]["output"];
  /** Build-in GitHub Integration settings. */
  githubIntegration: Maybe<GithubIntegration>;
  /**
   * Shows Gitlab integration details. If the id is not provided, it will return
   * the default Gitlab Integration.
   */
  gitlabIntegration: Maybe<GitlabIntegration>;
  /** Endpoint for incoming Gitlab webhooks. */
  gitlabWebhooksEndpoint: Scalars["String"]["output"];
  /** List the GPG keys used to sign binaries. */
  gpgKeys: Array<GpgKey>;
  /** HasAIEnabled determines whether the account has AI enabled/disabled */
  hasAIEnabled: Scalars["Boolean"]["output"];
  /** True if the account has at least one Ansible stack */
  hasAnsibleStacks: Scalars["Boolean"]["output"];
  /** Returns true if the account has SSO enabled. */
  hasSSO: Scalars["Boolean"]["output"];
  /** ID of the account. */
  id: Scalars["ID"]["output"];
  /** ID of the GitHub App installation linked to the current account. */
  installationId: Maybe<Scalars["String"]["output"]>;
  /** Returns true if the generic form is completed */
  isGenericFormCompleted: Scalars["Boolean"]["output"];
  /** List of all supported kubectl versions. */
  kubectlVersions: Array<Scalars["String"]["output"]>;
  /** LatestTermsAndConditionsVersionAI returns the latest version of the AI usage terms and conditions. */
  latestTermsAndConditionsVersionAI: Maybe<TermsAndConditionsVersion>;
  /** llmVendor returns the selected LLM vendor for the account */
  llmVendor: Maybe<LlmVendor>;
  /** Details about a single managed user. */
  managedUser: ManagedUser;
  /** Details about a single user group. */
  managedUserGroup: ManagedUserGroup;
  /** Managed user group integration specified by id. */
  managedUserGroupIntegration: ManagedUserGroupIntegration;
  /** List of managed user group integrations. Allows for optional integrationType parameter to get integrations of specific type only. */
  managedUserGroupIntegrations: Array<ManagedUserGroupIntegration>;
  /** List of user groups in this account. */
  managedUserGroups: Array<ManagedUserGroup>;
  /** List of users that were invited or logged into the account. */
  managedUsers: Array<ManagedUser>;
  /** Tells if the account is marked for deletion. */
  markedForDeletion: Scalars["Boolean"]["output"];
  /** Return the current value for a given metric. */
  metrics: Maybe<SpaceliftMetrics>;
  /** Return the values for a given metric in a given range. */
  metricsRange: Maybe<SpaceliftMetrics>;
  /** Details of a single Module. */
  module: Maybe<Module>;
  /** List of all the Modules managed by this account. */
  modules: Array<Module>;
  /** Name of the account. */
  name: Maybe<Scalars["String"]["output"]>;
  namedWebhooksIntegration: NamedWebhooksIntegration;
  /** Total number of new Notifications. */
  notificationCount: Scalars["Int"]["output"];
  /** OIDC settings, if the account is using OIDC-based SSO. */
  oidcSettings: Maybe<OidcSettings>;
  /** If the account is on the trial period, this indicates the end of it. */
  onTrialUntil: Maybe<Scalars["Int"]["output"]>;
  /** Given version constraints, calculate the latest version that satisfies it. */
  openTofuEffectiveVersion: Scalars["String"]["output"];
  /** List of all supported OpenTofu versions. */
  openTofuVersions: Array<Scalars["String"]["output"]>;
  /** List of Spacelift's outgoing addresses. */
  outgoingIPAddresses: Array<Scalars["String"]["output"]>;
  /** List of all the polices in the account. */
  policies: Array<Policy>;
  /** Details of a single Policy. */
  policy: Maybe<Policy>;
  /** List Policy Runtime Details */
  policyRuntime: PolicyRuntimeDetails;
  policyTemplate: Maybe<PolicyTemplate>;
  /** List of all the VCS providers available in the account. */
  providers: Array<VcsProvider>;
  /** List of all the VCS providers available in a specific space. */
  providersInSpace: Array<VcsProvider>;
  /** Shows public worker pool details */
  publicWorkerPool: Maybe<PublicWorkerPool>;
  /** List of repositories available to the account. */
  repositories: Array<Repository>;
  role: Role;
  /** Returns all roles for the account that are accessible by the requester. */
  roles: Array<Role>;
  /** Return the stack the run belongs to. */
  runStack: Maybe<Stack>;
  /** SAML settings, if the account is using SAML-based SSO. */
  samlSettings: Maybe<SamlSettings>;
  /** Details of a single saved filter */
  savedFilter: Maybe<SavedFilter>;
  /** List of saved filters */
  savedFilters: Array<SavedFilter>;
  /** searchAnsibleHosts allows to search ansibleHosts. */
  searchAnsibleHosts: Maybe<SearchAnsibleHostsOutput>;
  /** Information about fields available for searching AnsibleHosts. */
  searchAnsibleHostsSuggestions: Maybe<SearchSuggestionsOutput>;
  /** searchAnsibleTasks allows to search AnsibleTasks. */
  searchAnsibleTasks: Maybe<SearchAnsibleTasksOutput>;
  /** Information about fields available for searching AnsibleTasks. */
  searchAnsibleTasksSuggestions: Maybe<SearchSuggestionsOutput>;
  /** Searches for audit trail entries. */
  searchAuditTrailEntries: SearchAuditTrailEntriesOutput;
  /** Information about fields available for searching audit trail entries. */
  searchAuditTrailEntriesSuggestions: SearchSuggestionsOutput;
  searchBlueprintVersionedGroups: SearchBlueprintVersionedGroupsOutput;
  searchBlueprintVersionedGroupsSuggestions: SearchSuggestionsOutput;
  searchBlueprints: SearchBlueprintsOutput;
  searchBlueprintsSuggestions: SearchSuggestionsOutput;
  searchContexts: SearchContextsOutput;
  searchContextsSuggestions: SearchSuggestionsOutput;
  /** Search managed entities with a set of predicates, ordering and paging. */
  searchManagedEntities: SearchManagedEntitiesOutput;
  /** Search managed entities grouped by providers with a set of predicates, ordering and paging. */
  searchManagedEntitiesGroupByProviders: SearchManagedEntitiesGroupedByProvidersOutput;
  /** Search managed entities grouped by stacks with a set of predicates, ordering and paging. */
  searchManagedEntitiesGroupByStacks: SearchManagedEntitiesGroupedByStacksOutput;
  /** Search managed entities grouped by types with a set of predicates, ordering and paging. */
  searchManagedEntitiesGroupByTypes: SearchManagedEntitiesGroupedByTypesOutput;
  /** Information about fields available for searching managed entities. */
  searchManagedEntitiesSuggestions: SearchSuggestionsOutput;
  /** Search Modules with a set of predicates, ordering and paging. */
  searchModules: SearchModulesOutput;
  /** Information about fields available for searching Modules. */
  searchModulesSuggestions: SearchSuggestionsOutput;
  searchNamedWebhooksIntegrations: SearchNamedWebhooksIntegrationOutput;
  searchNamedWebhooksIntegrationsSuggestions: SearchSuggestionsOutput;
  /** Search Notifications with a set of predicates, ordering and paging. */
  searchNotifications: SearchNotificationsOutput;
  /** Information about fields available for searching Notifications. */
  searchNotificationsSuggestions: SearchSuggestionsOutput;
  searchPolicies: SearchPoliciesOutput;
  searchPoliciesSuggestions: SearchSuggestionsOutput;
  searchPolicyTemplates: SearchPolicyTemplatesOutput;
  searchPolicyTemplatesSuggestions: SearchSuggestionsOutput;
  /** Search Stacks with a set of predicates, ordering and paging. */
  searchStacks: SearchStacksOutput;
  /** Information about fields available for searching Stacks. */
  searchStacksSuggestions: SearchSuggestionsOutput;
  searchVCSIntegrations: SearchVcsIntegrationsOutput;
  searchVCSIntegrationsSuggestions: SearchSuggestionsOutput;
  searchWorkerPools: SearchWorkerPoolsOutput;
  searchWorkerPoolsSuggestions: SearchSuggestionsOutput;
  /** Returns the seat limits and the number of in-use seats. */
  seats: Seats;
  /** Returns security email for the account */
  securityEmail: Scalars["String"]["output"];
  /** Returns list of the security keys in the account. */
  securityKeys: Array<SecurityKey>;
  /**
   * List of all the active sessions in the account. NOTE: this functionality is not available in the Starter
   * tier. An empty array will be returned for Starter tier accounts.
   */
  sessions: Array<Session>;
  /** List of all the Modules shared with this account. */
  sharedModules: Array<SharedModule>;
  /**
   * Returns the instance-wide Slack App config information for a Self-Hosted instance. This is used by
   * the Spacelift Slack integration to integrate with your Slack workspace. Always returns null on SaaS.
   */
  slackAppConfig: Maybe<SlackAppConfig>;
  /**
   * Returns the app manifest that should be used when creating a Slack App to integrate
   * Spacelift with Slack. This is only useful on Self-Hosted instances. Always returns empty on SaaS.
   */
  slackAppManifest: Scalars["String"]["output"];
  /** Shows the details of the integration between your Slack workspace and your Spacelift account. */
  slackIntegration: Maybe<SlackIntegration>;
  /** Details of a single Space. */
  space: Maybe<Space>;
  /**
   * Returns the AWS account used by Spacelift during role assumption. This can be used for
   * generating AssumeRole policies.
   */
  spaceliftAwsAccountId: Scalars["String"]["output"];
  /** Details of all the spaces in the Account accessible for the user. */
  spaces: Array<Space>;
  /** Details of a single Stack. */
  stack: Maybe<Stack>;
  /** List of all the stacks in the account. */
  stacks: Array<Stack>;
  /** Given version constraints, calculate the latest version that satisfies it. */
  terraformEffectiveVersion: Scalars["String"]["output"];
  /** Details of a single Terraform provider. */
  terraformProvider: Maybe<TerraformProvider>;
  /** List of all Terraform providers managed by this account. */
  terraformProviders: Array<TerraformProvider>;
  /** List of all supported Terraform versions. */
  terraformVersions: Array<Scalars["String"]["output"]>;
  /**
   * Given version constraints, calculate the latest version that satisfies it. NOTE: the only
   * valid values for the "tool" parameter are TERRAFORM_FOSS or OPEN_TOFU, and when no tool
   * is specified it defaults to TERRAFORM_FOSS.
   */
  terragruntEffectiveVersion: EffectiveTerragruntVersionResult;
  /** List of all supported Terragrunt versions. */
  terragruntVersions: Array<Scalars["String"]["output"]>;
  /** Billing tier of the account. */
  tier: BillingTier;
  /** The list of features available based on the billing tier of the account. */
  tierFeatures: Array<BillingTierFeature>;
  /** Type of the GitHub account. */
  type: Maybe<AccountType>;
  /**
   * Get the stored UI configuration for the user.
   * If no configuration is stored, empty string will be returned.
   */
  uiConfigGet: Scalars["String"]["output"];
  /** Usage for the last 30 days. */
  usage: Usage;
  /**
   * List of data points containing information about the account's usage along with some metadata.
   * AspectInput is used to specify the aspect of the usage to be returned.
   * The data points are returned in reverse chronological order.
   */
  usageAspect: AspectOutput;
  userRoleBinding: UserRoleBinding;
  /** Details of a single VCSAgentPool. */
  vcsAgentPool: Maybe<VcsAgentPool>;
  /** List of all the VCSAgentPools in the account. */
  vcsAgentPools: Array<VcsAgentPool>;
  /** Maximum number of runs triggered by a VCS event for this account. */
  vcsEventTriggeredRunsLimit: Maybe<Scalars["Int"]["output"]>;
  /** Get VCS integrations by provider and id */
  vcsIntegration: Maybe<VcsIntegration>;
  /** List of all VCS integrations available in the account. */
  vcsIntegrations: Array<VcsIntegration>;
  /** Current user, if any. */
  viewer: Maybe<User>;
  /** Details of a single WorkerPool. */
  workerPool: Maybe<WorkerPool>;
  /** List of all the WorkerPools in the account. */
  workerPools: Array<WorkerPool>;
};

export type QueryAnsibleHostArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAnsibleHostsArgs = {
  input: InputMaybe<RunOrStackArgs>;
};

export type QueryAnsibleTasksArgs = {
  input: InputMaybe<RunOrStackArgs>;
};

export type QueryAttachableAwsIntegrationsInSpaceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAttachableAzureIntegrationsInSpaceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAttachableContextsInSpaceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAttachableExternalIntegrationInSpaceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAttachablePoliciesInSpaceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAttachableVcsIntegrationsForProviderInSpaceArgs = {
  id: Scalars["ID"]["input"];
  provider: VcsProvider;
};

export type QueryAttachableWorkerPoolsInSpaceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAutoattachmentForLabelsArgs = {
  labels: Array<Scalars["String"]["input"]>;
  space: Scalars["ID"]["input"];
};

export type QueryAwsIntegrationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAwsIntegrationByNameArgs = {
  name: Scalars["String"]["input"];
};

export type QueryAwsIntegrationsArgs = {
  includeLegacy: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryAzureDevOpsRepoIntegrationArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryAzureIntegrationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAzureIntegrationByNameArgs = {
  name: Scalars["String"]["input"];
};

export type QueryBitbucketCloudIntegrationArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryBitbucketDatacenterIntegrationArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryBlueprintArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBlueprintVersionedGroupArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBranchesArgs = {
  namespace: Scalars["String"]["input"];
  provider: VcsProvider;
  repository: Scalars["String"]["input"];
  repositoryURL: InputMaybe<Scalars["String"]["input"]>;
  vcsIntegrationID: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryContextArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryEvaluateFeatureFlagsArgs = {
  input: FeatureFlagEvaluationInput;
};

export type QueryExternalIntegrationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGithubEnterpriseIntegrationArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGithubEnterpriseWebhooksEndpointArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryGitlabIntegrationArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryIsGenericFormCompletedArgs = {
  name: Scalars["String"]["input"];
};

export type QueryManagedUserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryManagedUserGroupArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryManagedUserGroupIntegrationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryManagedUserGroupIntegrationsArgs = {
  integrationType: InputMaybe<ManagedUserGroupIntegrationType>;
};

export type QueryMetricsRangeArgs = {
  input: InputMaybe<MetricsInput>;
};

export type QueryModuleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryNamedWebhooksIntegrationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOpenTofuEffectiveVersionArgs = {
  constraints: Scalars["String"]["input"];
};

export type QueryPolicyArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPolicyTemplateArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryProvidersInSpaceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryRepositoriesArgs = {
  provider: InputMaybe<VcsProvider>;
  vcsIntegrationID: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryRoleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryRunStackArgs = {
  runId: Scalars["ID"]["input"];
};

export type QuerySavedFilterArgs = {
  id: Scalars["ID"]["input"];
};

export type QuerySavedFiltersArgs = {
  type: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchAnsibleHostsArgs = {
  input: SearchInput;
};

export type QuerySearchAnsibleHostsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchAnsibleTasksArgs = {
  input: SearchInput;
};

export type QuerySearchAnsibleTasksSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchAuditTrailEntriesArgs = {
  input: SearchInput;
};

export type QuerySearchAuditTrailEntriesSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchBlueprintVersionedGroupsArgs = {
  input: SearchInput;
};

export type QuerySearchBlueprintVersionedGroupsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchBlueprintsArgs = {
  input: SearchInput;
};

export type QuerySearchBlueprintsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchContextsArgs = {
  input: SearchInput;
};

export type QuerySearchContextsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchManagedEntitiesArgs = {
  input: SearchInput;
};

export type QuerySearchManagedEntitiesGroupByProvidersArgs = {
  input: SearchInput;
};

export type QuerySearchManagedEntitiesGroupByStacksArgs = {
  input: SearchInput;
};

export type QuerySearchManagedEntitiesGroupByTypesArgs = {
  input: SearchInput;
};

export type QuerySearchManagedEntitiesSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchModulesArgs = {
  input: SearchInput;
};

export type QuerySearchModulesSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchNamedWebhooksIntegrationsArgs = {
  input: SearchInput;
};

export type QuerySearchNamedWebhooksIntegrationsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchNotificationsArgs = {
  input: SearchInput;
};

export type QuerySearchNotificationsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchPoliciesArgs = {
  input: SearchInput;
};

export type QuerySearchPoliciesSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchPolicyTemplatesArgs = {
  input: SearchInput;
};

export type QuerySearchPolicyTemplatesSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchStacksArgs = {
  input: SearchInput;
};

export type QuerySearchStacksSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchVcsIntegrationsArgs = {
  input: SearchInput;
};

export type QuerySearchVcsIntegrationsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySearchWorkerPoolsArgs = {
  input: SearchInput;
};

export type QuerySearchWorkerPoolsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type QuerySpaceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStackArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTerraformEffectiveVersionArgs = {
  constraints: Scalars["String"]["input"];
};

export type QueryTerraformProviderArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTerragruntEffectiveVersionArgs = {
  terraformConstraints: Scalars["String"]["input"];
  terragruntConstraints: Scalars["String"]["input"];
  tool: InputMaybe<TerragruntTool>;
};

export type QueryUsageAspectArgs = {
  input: AspectInput;
};

export type QueryUserRoleBindingArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryVcsAgentPoolArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryVcsIntegrationArgs = {
  id: InputMaybe<Scalars["ID"]["input"]>;
  provider: VcsProvider;
};

export type QueryWorkerPoolArgs = {
  id: Scalars["ID"]["input"];
};

/** GitHub repository with branches. */
export type Repository = {
  __typename?: "Repository";
  /** Name of the repository. */
  id: Scalars["ID"]["output"];
  /** Repository namespace. */
  namespace: Scalars["String"]["output"];
};

export type ResourceChange = {
  __typename?: "ResourceChange";
  resources: Array<EntityChangeV3>;
};

export type Role = {
  __typename?: "Role";
  actions: Array<Action>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isSystem: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  roleBindingsCount: Scalars["Int"]["output"];
};

export type RoleBindingInput = {
  /** ID of the role. */
  roleID: Scalars["ID"]["input"];
  /** ID of the space. */
  spaceID: Scalars["ID"]["input"];
};

export type RoleInput = {
  /** Permissions associated with the role. */
  actions: Array<Action>;
  /** Human-readable, free-form description. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Human-readable, free-form name. */
  name: Scalars["String"]["input"];
};

export type RoleUpdateInput = {
  /** Permissions associated with the role. */
  Actions: InputMaybe<Array<Action>>;
  /** Human-readable, free-form description. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Human-readable, free-form name. */
  name: InputMaybe<Scalars["String"]["input"]>;
};

/** Run represents the main concept in Spacelift. */
export type Run = {
  __typename?: "Run";
  /** Name of the branch for which this Run has been created. */
  branch: Scalars["String"]["output"];
  /** Checks if a run can be confirmed. */
  canConfirm: Scalars["Boolean"]["output"];
  /** Checks if a proposed run can be promoted to a tracked one. */
  canPromote: Scalars["Boolean"]["output"];
  /** Checks if a run can be retried. */
  canRetry: Scalars["Boolean"]["output"];
  /** Entities changed by this Run. */
  changesV2: Maybe<EntityChangesV2>;
  /** Entities changed by this Run. If phase is not passed, the changes for latest phase are returned. */
  changesV3: Array<ResourceChange>;
  /** Command - only useful for Tasks. */
  command: Maybe<Scalars["String"]["output"]>;
  /** Comments on the Run, if any. */
  comments: Array<RunComment>;
  /**
   * Commit representing the HEAD of the branch at the time of triggering
   * the Run.
   */
  commit: Commit;
  /** Returns a message explaining why a run cannot be confirmed. */
  confirmBlocker: Maybe<Scalars["String"]["output"]>;
  /** Unix timestamp representing Run creation. */
  createdAt: Scalars["Int"]["output"];
  /** Customer provided runtime configuration for this Run. */
  customRuntimeConfig: Maybe<RuntimeConfig>;
  /**
   * Change in resources tracked by the Stack's state that will be caused
   * by this Run.
   */
  delta: Maybe<Delta>;
  /** The run that this run depends on. */
  dependsOn: Array<RunDependency>;
  /** Indicates whether this run belongs to a drift detection workflow. */
  driftDetection: Scalars["Boolean"]["output"];
  /**
   * If the run is a module test case, is the test case expected to fail? This
   * field will be null for non-module runs.
   */
  expectFailure: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * Expired run is one that's been waiting for user approval for long enough to
   * have its pending plan file garbage-collected.
   */
  expired: Scalars["Boolean"]["output"];
  /** any external dependencies that this run has */
  externalDependencies: Array<RunExternalDependency>;
  /** Convenience helper, allowing a quick check on whether the Run has finished. */
  finished: Scalars["Boolean"]["output"];
  /** Run history as a list of state transitions. */
  history: Array<RunStateTransition>;
  /** Globally unique ID (ULID) of the Run. */
  id: Scalars["ID"]["output"];
  /** The runs that depend on this run. */
  isDependedOnBy: Array<RunDependency>;
  /**
   * Indicates whether the run is the most recent one its current Stack - ie. it
   * is the one that has last changed the stack state.
   */
  isMostRecent: Scalars["Boolean"]["output"];
  /**
   * Indicates whether the run has been prioritized.
   * By default, runs are not prioritized. However, if a run is prioritized, it will take precedence over other queued runs for the worker pool it is assigned to.
   */
  isPrioritized: Scalars["Boolean"]["output"];
  /**
   * Paginated slice of Run logs associated with one of the current Run's
   * (past or present) states.
   */
  logs: Maybe<LogsResponse>;
  /** A download URL for the logs of a run state */
  logsDownloadURL: Maybe<LogsDownloadUrl>;
  /** Indicates whether the Run needs approval before it can proceed. */
  needsApproval: Scalars["Boolean"]["output"];
  /**
   * Next run, if any. Next indicates a run of the same type, on the same branch,
   * created right after the current one.
   */
  next: Maybe<Run>;
  /** List of outcomes for policies evaluated against this runs plan. */
  planPoliciesOutcomes: Array<PlanPoliciesOutcome>;
  /** Policy evaluation sample for a given key, if available. */
  policyReceiptSample: Maybe<PolicyEvaluationSample>;
  /** List of policy evaluations events that affected this run. */
  policyReceipts: Array<RunPolicyReceipt>;
  /**
   * Previous run, if any. Previous indicates a run of the same type, on the same
   * branch, created right before the current one.
   */
  previous: Maybe<Run>;
  /** Reason why the run cannot be promoted, if any. */
  promoteBlocker: Maybe<Scalars["String"]["output"]>;
  /** Reason why the run cannot be retried. */
  retryBlocker: Maybe<Scalars["String"]["output"]>;
  /** List of reviews for this run. */
  reviews: Array<RunReview>;
  /** Individual runtime configuration for this Run. */
  runtimeConfig: Maybe<RuntimeConfig>;
  /** searchEntityChanges allows to search changes of a run. */
  searchEntityChanges: SearchEntityChangeOutput;
  /** searchEntityChangesSuggestions returns search suggestions for run changes. */
  searchEntityChangesSuggestions: SearchSuggestionsOutput;
  /** Current state of the Run. */
  state: RunState;
  /** Indicates whether the run can be summarized with AI */
  summarizable: Scalars["Boolean"]["output"];
  /** Run summary generated with AI */
  summary: Maybe<RunSummary>;
  /** Indicates whether the run supports detailed resources. */
  supportsDetailedResources: Scalars["Boolean"]["output"];
  /** Title of the Run for UI consumption. */
  title: Scalars["String"]["output"];
  /** The user who manually triggered this Run, if any. */
  triggeredBy: Maybe<Scalars["String"]["output"]>;
  /** Type of the Run. */
  type: RunType;
  /** Unix timestamp of the last update. */
  updatedAt: Scalars["Int"]["output"];
};

/** Run represents the main concept in Spacelift. */
export type RunChangesV2Args = {
  input: EntityChangeCollectionInputV2;
};

/** Run represents the main concept in Spacelift. */
export type RunChangesV3Args = {
  input: EntityChangesInputV3;
};

/** Run represents the main concept in Spacelift. */
export type RunLogsArgs = {
  state: RunState;
  stateVersion: InputMaybe<Scalars["Int"]["input"]>;
  token: InputMaybe<Scalars["String"]["input"]>;
};

/** Run represents the main concept in Spacelift. */
export type RunLogsDownloadUrlArgs = {
  state: RunState;
  stateVersion: InputMaybe<Scalars["Int"]["input"]>;
};

/** Run represents the main concept in Spacelift. */
export type RunPolicyReceiptSampleArgs = {
  id: Scalars["ID"]["input"];
};

/** Run represents the main concept in Spacelift. */
export type RunSearchEntityChangesArgs = {
  changePhase: InputMaybe<Scalars["String"]["input"]>;
  input: SearchInput;
};

/** Run represents the main concept in Spacelift. */
export type RunSearchEntityChangesSuggestionsArgs = {
  changePhase: InputMaybe<Scalars["String"]["input"]>;
  input: SearchSuggestionsInput;
};

/** RunComment represents a single comment on a Run. */
export type RunComment = {
  __typename?: "RunComment";
  /** Body of the comment, supports Markdown. */
  body: Scalars["String"]["output"];
  /** Comment creation timestamp. */
  createdAt: Scalars["Int"]["output"];
  /** Username of the commenter. */
  username: Scalars["String"]["output"];
};

export type RunDependency = {
  __typename?: "RunDependency";
  /** Identifier of the run dependency. */
  id: Scalars["ID"]["output"];
  /** The ID of the run. */
  runId: Scalars["ID"]["output"];
  /** Unix timestamp of the last run update. */
  runUpdatedAt: Scalars["Int"]["output"];
  /** The stack id of the run. */
  stackId: Scalars["ID"]["output"];
  /** The state of the run. */
  state: RunState;
};

export type RunExternalDependency = {
  __typename?: "RunExternalDependency";
  /** when the dependency has been marked as completed. */
  completedAt: Maybe<Scalars["Int"]["output"]>;
  /** when the dependency has been created. */
  createdAt: Scalars["Int"]["output"];
  /** The identifier of the dependency. */
  id: Scalars["ID"]["output"];
  /** Status of the dependency. */
  status: RunExternalDependencyStatus;
};

/** Experimental: Response to marking a dependency as complete. */
export type RunExternalDependencyMarkAsCompletedResponse = {
  __typename?: "RunExternalDependencyMarkAsCompletedResponse";
  /** there needs to be at least one field */
  phantom: Scalars["Boolean"]["output"];
};

export enum RunExternalDependencyStatus {
  Failed = "FAILED",
  Finished = "FINISHED",
  Pending = "PENDING",
}

export type RunIgnoredTrigger = {
  __typename?: "RunIgnoredTrigger";
  commit: Commit;
  createdAt: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  policyAttachments: Array<RunIgnoredTriggerPolicyAttachments>;
  reasonType: RunIgnoredTriggerReasonType;
};

export type RunIgnoredTriggerPolicyAttachments = {
  __typename?: "RunIgnoredTriggerPolicyAttachments";
  policy: Maybe<Policy>;
  policyName: Maybe<Scalars["String"]["output"]>;
};

export enum RunIgnoredTriggerReasonType {
  CustomPolicy = "CUSTOM_POLICY",
  DisabledByLabel = "DISABLED_BY_LABEL",
  NoAffectedFilesInProjectRoot = "NO_AFFECTED_FILES_IN_PROJECT_ROOT",
  TagsIgnoredByDefault = "TAGS_IGNORED_BY_DEFAULT",
}

export type RunIgnoredTriggersConnection = {
  __typename?: "RunIgnoredTriggersConnection";
  cursor: Scalars["String"]["output"];
  node: RunIgnoredTrigger;
};

export type RunOrStackArgs = {
  run: InputMaybe<Scalars["String"]["input"]>;
  stack: InputMaybe<Scalars["String"]["input"]>;
};

/** RunPolicyReceipt represents a single policy evaluation event that affected a Run. */
export type RunPolicyReceipt = {
  __typename?: "RunPolicyReceipt";
  /** Timestamp representing the moment of policy evaluation. */
  createdAt: Scalars["Int"]["output"];
  /** Flags generated by the policy evaluation, if any. */
  flags: Array<Scalars["String"]["output"]>;
  /** Globally unique ID of this policy receipt. */
  id: Scalars["ID"]["output"];
  /** Basic string representation of the policy's evaluation result. */
  outcome: Scalars["String"]["output"];
  /** Indicates whether the policy was deleted. */
  policyDeleted: Scalars["Boolean"]["output"];
  /** Name of the policy that affected the Run. */
  policyName: Scalars["String"]["output"];
  /** Slug of the policy that affected the Run. */
  policySlug: Maybe<Scalars["String"]["output"]>;
  /** Type of the evaluated policy. */
  policyType: PolicyType;
  /** Indicates whether sample has expired. */
  sampleExpired: Scalars["Boolean"]["output"];
  /** Indicates whether the policy evaluation was sampled. */
  sampled: Scalars["Boolean"]["output"];
};

/**
 * RunResourceState represents a state of the Run.
 * It's for Terraform-Provider, to allow spacelift run to read the state of another run.
 */
export type RunResourceState = {
  __typename?: "RunResourceState";
  /** Convenience helper, allowing a quick check on whether the Run has finished. */
  finished: Scalars["Boolean"]["output"];
  /** Globally unique ID (ULID) of the Run. */
  id: Scalars["ID"]["output"];
  /** Current state of the Run. */
  state: RunState;
};

/** RunReview represents a single Run review event. */
export type RunReview = {
  __typename?: "RunReview";
  /** Author of the review. */
  author: Scalars["String"]["output"];
  /** Decision made by the reviewer. */
  decision: RunReviewDecision;
  /** Unique ID of the run review. */
  id: Scalars["ID"]["output"];
  /** Optional note, if any. */
  note: Maybe<Scalars["String"]["output"]>;
  /** State of the Run at the time of the review. */
  state: RunState;
  /** StateVersion of the Run at the time of the review. */
  stateVersion: Scalars["Int"]["output"];
  /** Unix timestamp of the review. */
  timestamp: Scalars["Int"]["output"];
};

/** RunReviewDecision values represent available run review decision. */
export enum RunReviewDecision {
  /** The reviewer approved the Run. */
  Approve = "APPROVE",
  /** The reviewer rejected the Run. */
  Reject = "REJECT",
}

/** RunState represents a discrete state of a Run. */
export enum RunState {
  /** A worker is currently applying the run's changes. */
  Applying = "APPLYING",
  /** The run has been canceled by the user. */
  Canceled = "CANCELED",
  /**
   * The run's plan has been confirmed by the user. The run is now waiting for
   * the worker to pick it up and start processing.
   */
  Confirmed = "CONFIRMED",
  /**
   * The worker is currently performing a destroy operation on the managed
   * resources.
   */
  Destroying = "DESTROYING",
  /** The run's plan has been rejected by the user. */
  Discarded = "DISCARDED",
  /** The run has failed. */
  Failed = "FAILED",
  /** The run or task has finished successfully. */
  Finished = "FINISHED",
  /** The run's workspace is currently initializing on the worker. */
  Initializing = "INITIALIZING",
  /** Deprecated state. */
  Pending = "PENDING",
  /** The proposed run is waiting for post-planning approval policy sign-off. */
  PendingReview = "PENDING_REVIEW",
  /** The worker is currently performing the task requested by the user. */
  Performing = "PERFORMING",
  /** The worker is planning the run. */
  Planning = "PLANNING",
  /** The workspace is currently being prepared for the run. */
  Preparing = "PREPARING",
  /** The workspace is currently being prepared for the change deployment. */
  PreparingApply = "PREPARING_APPLY",
  /** The run is being prepared to be replanned. */
  PreparingReplan = "PREPARING_REPLAN",
  /**
   * The run is queued and is either waiting for an available worker or for the
   * stack lock to be released by another run.
   */
  Queued = "QUEUED",
  /** The run is ready to be started. */
  Ready = "READY",
  /** The run is pending a replan and should get picked up by the scheduler. */
  ReplanRequested = "REPLAN_REQUESTED",
  /** The run was skipped. */
  Skipped = "SKIPPED",
  /** The run has been stopped by the user. */
  Stopped = "STOPPED",
  /**
   * The run has planned successfully, it reports changes and is waiting for the
   * user to review those.
   */
  Unconfirmed = "UNCONFIRMED",
  /** An invalid "zero-value" for the run state. */
  Unknown = "UNKNOWN",
}

export type RunStateSummary = {
  __typename?: "RunStateSummary";
  createdAt: Scalars["Int"]["output"];
  deleted: Scalars["Boolean"]["output"];
  processingStatus: SummaryProcessingStatus;
  summary: Scalars["String"]["output"];
};

/** Change in the Run state, along with its metadata. */
export type RunStateTransition = {
  __typename?: "RunStateTransition";
  /** Indicates whether the state transition has logs. */
  hasLogs: Scalars["Boolean"]["output"];
  /** Optional note about the state transition. */
  note: Maybe<Scalars["String"]["output"]>;
  /** New state of the Run. */
  state: RunState;
  /**
   * New state version of the Run.
   * Old runs are not guaranteed to have this value set,
   * but backend will accept `null` as input where this value is needed.
   */
  stateVersion: Maybe<Scalars["Int"]["output"]>;
  /** Indicates whether the run state can be summarized with AI */
  summarizable: Scalars["Boolean"]["output"];
  /** Run state summary generated with AI */
  summary: Maybe<RunSummary>;
  /** Whether the transition finishes the run. */
  terminal: Scalars["Boolean"]["output"];
  /** Unix timestamp of the Run. */
  timestamp: Scalars["Int"]["output"];
  /** Unix timestamp of the subsequent state transition, if any. */
  until: Maybe<Scalars["Int"]["output"]>;
  /** GitHub username of the individual who caused the change, if any. */
  username: Maybe<Scalars["String"]["output"]>;
};

export type RunSummary = {
  __typename?: "RunSummary";
  createdAt: Scalars["Int"]["output"];
  deleted: Scalars["Boolean"]["output"];
  processingStatus: SummaryProcessingStatus;
  summary: Scalars["String"]["output"];
};

export type RunTile = {
  __typename?: "RunTile";
  createdAt: Scalars["Int"]["output"];
  /** ULID of the run. */
  id: Scalars["ID"]["output"];
  needsApproval: Scalars["Boolean"]["output"];
  stackTile: StackTile;
  state: RunState;
  title: Scalars["String"]["output"];
  type: RunType;
};

export type RunTileWithApproval = {
  __typename?: "RunTileWithApproval";
  approval: Scalars["Boolean"]["output"];
  runTile: RunTile;
};

/** RunType is a type of the Run. */
export enum RunType {
  /** Resource destruction job. */
  Destroy = "DESTROY",
  /** Source code parsing job. */
  Parse = "PARSE",
  /** A run that's creating a change preview or drift detection. */
  Proposed = "PROPOSED",
  /** One-off run started by the user. */
  Task = "TASK",
  /** Test case for a module. */
  Testing = "TESTING",
  /** A deployment candidate. */
  Tracked = "TRACKED",
  /** An invalid "zero-value" for the run type. */
  Unknown = "UNKNOWN",
}

/** RunWithPosition is a wrapper for run with its position in the queue. */
export type RunWithPosition = {
  __typename?: "RunWithPosition";
  isModule: Scalars["Boolean"]["output"];
  position: Scalars["Int"]["output"];
  /** Provider of the stacks' repository. */
  provider: VcsProvider;
  run: Run;
  stackId: Scalars["ID"]["output"];
  stackName: Scalars["String"]["output"];
  version: Maybe<Scalars["String"]["output"]>;
};

/** RunWithPositionConnection represents a single queued run in a page of results. */
export type RunWithPositionConnection = {
  __typename?: "RunWithPositionConnection";
  cursor: Scalars["String"]["output"];
  node: RunWithPosition;
};

/**
 * RuntimeConfig is the runtime configuration pulled from GitHub for each
 * Run separately.
 */
export type RuntimeConfig = {
  __typename?: "RuntimeConfig";
  /** List of after-apply scripts. */
  afterApply: Array<Scalars["String"]["output"]>;
  /** List of after-destroy scripts. */
  afterDestroy: Array<Scalars["String"]["output"]>;
  /** List of after-init scripts. */
  afterInit: Array<Scalars["String"]["output"]>;
  /** List of after-perform scripts. */
  afterPerform: Array<Scalars["String"]["output"]>;
  /** List of after-plan scripts. */
  afterPlan: Array<Scalars["String"]["output"]>;
  /** List of after-run scripts. */
  afterRun: Array<Scalars["String"]["output"]>;
  /** List of before-apply scripts. */
  beforeApply: Array<Scalars["String"]["output"]>;
  /** List of before-destroy scripts. */
  beforeDestroy: Array<Scalars["String"]["output"]>;
  /** List of before-init scripts. */
  beforeInit: Array<Scalars["String"]["output"]>;
  /** List of before-perform scripts. */
  beforePerform: Array<Scalars["String"]["output"]>;
  /** List of before-plan scripts. */
  beforePlan: Array<Scalars["String"]["output"]>;
  /** Environment variables */
  environment: Array<EnvVar>;
  /** Indicates where the root of the Terraform project is. */
  projectRoot: Scalars["String"]["output"];
  /** Name of the Docker image used to process the Run. */
  runnerImage: Scalars["String"]["output"];
  /** Contains any Terraform-specific configuration options used by the run. */
  terraform: Maybe<TerraformRuntimeConfig>;
  /** Terraform version used to process the Run. */
  terraformVersion: Scalars["String"]["output"];
  /**
   * Terraform workflow tool used to process the Run.
   * TODO: ideally we'd make this field optional because it doesn't make sense for non-TF stacks. Same with the version.
   */
  terraformWorkflowTool: TerraformWorkflowTool;
  /**
   * Contains any Terragrunt-specific configuration options used by the run. This field
   * may not be populated for older runs created before the Terragrunt runtime config began being
   * stored.
   */
  terragrunt: Maybe<TerragruntRuntimeConfig>;
  /** runtime configuration in YAML format */
  yaml: Scalars["String"]["output"];
};

export type RuntimeConfigInput = {
  /** List of after-apply scripts. */
  afterApply: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of after-destroy scripts. */
  afterDestroy: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of after-init scripts. */
  afterInit: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of after-perform scripts. */
  afterPerform: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of after-plan scripts. */
  afterPlan: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of after-run scripts. */
  afterRun: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of before-apply scripts. */
  beforeApply: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of before-destroy scripts. */
  beforeDestroy: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of before-init scripts. */
  beforeInit: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of before-perform scripts. */
  beforePerform: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of before-plan scripts. */
  beforePlan: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Environment variables */
  environment: InputMaybe<Array<EnvVarInput>>;
  /** Indicates where the root of the Terraform project is. */
  projectRoot: InputMaybe<Scalars["String"]["input"]>;
  /** Name of the Docker image used to process the Run. */
  runnerImage: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Runtime configuration in YAML format.
   * The yaml property, or the other properties must be set, but not both.
   */
  yaml: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * SAMLNameIDFormat is the format for the NameID to use when making requests to the SAML
 * identity provider.
 */
export enum SamlNameIdFormat {
  /** An email address. */
  EmailAddress = "EMAIL_ADDRESS",
  /** An opaque identifier that remains the same between logins. */
  Persistent = "PERSISTENT",
  /** An opaque identifier that does not remain the same between logins. */
  Transient = "TRANSIENT",
}

/** SAMLSettings represent account's SAML settings */
export type SamlSettings = {
  __typename?: "SAMLSettings";
  /**
   * Login of the user who always gets an administrative access to the account.
   * This is meant to prevent locking yourself out of the account if the settings
   * are incorrect.
   */
  adminLogin: Maybe<Scalars["String"]["output"]>;
  /** Identity provider metadata, if statically provided. */
  idpMetadata: Maybe<Scalars["String"]["output"]>;
  /** Identity provider metadata URL, if the provider supports dynamic metadata retrieval. */
  idpMetadataURL: Maybe<Scalars["String"]["output"]>;
  /** The format used when requesting the NameID (user/login name) from the identity provider. */
  nameIDFormat: SamlNameIdFormat;
};

/**
 * SampledPolicyLibrary represents a single policy library captured as part of a
 * policy evaluation sample.
 */
export type SampledPolicyLibrary = {
  __typename?: "SampledPolicyLibrary";
  /** Body of the sampled library. */
  Body: Scalars["String"]["output"];
  /** Name of the sampled library. */
  Name: Scalars["String"]["output"];
};

export type SavedFilter = {
  __typename?: "SavedFilter";
  /** Login of the user who created the saved filter */
  createdBy: Scalars["String"]["output"];
  /** Data is the JSON representation of the filter data */
  data: Scalars["String"]["output"];
  /** Globally unique ID of the saved filter */
  id: Scalars["ID"]["output"];
  /** Toggle whether the filter is public or not */
  isPublic: Scalars["Boolean"]["output"];
  /** Name of the saved filter */
  name: Scalars["String"]["output"];
  /** Type describes the type of the filter. It is used to determine which view the filter is for */
  type: Scalars["String"]["output"];
};

export type SavedFilterInput = {
  /** Data is the JSON representation of the filter data */
  data: Scalars["String"]["input"];
  /** Toggle whether the filter is public or not */
  isPublic: Scalars["Boolean"]["input"];
  /** Name of the saved filter */
  name: Scalars["String"]["input"];
  /** Type describes the type of the filter. It is used to determine which view the filter is for */
  type: Scalars["String"]["input"];
};

export type ScheduledDelete = {
  __typename?: "ScheduledDelete";
  /**
   * Apply will be scheduled according to this cron schedule expression if specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  cronSchedule: Maybe<Array<Scalars["String"]["output"]>>;
  /** Globally unique schedule identifier. */
  id: Scalars["ID"]["output"];
  /**
   * When the next detection run will be scheduled.
   * Null if done or is in progress.
   */
  nextSchedule: Maybe<Scalars["Int"]["output"]>;
  /** Indicates whether this job should delete stack resources as well */
  shouldDeleteResources: Scalars["Boolean"]["output"];
  /** Stack that the schedule will be run against */
  stack: Scalars["ID"]["output"];
  /**
   * Apply will be scheduled at this timestamp specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  timestampSchedule: Maybe<Scalars["Float"]["output"]>;
  /** Cron schedule expressions will apply for this timezone. */
  timezone: Maybe<Scalars["String"]["output"]>;
};

export type ScheduledDeleteInput = {
  cronSchedule: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Indicates whether this job should delete stack resources as well */
  shouldDeleteResources: Scalars["Boolean"]["input"];
  /**
   * Apply will be scheduled at this timestamp specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  timestampSchedule: InputMaybe<Scalars["Int"]["input"]>;
  timezone: InputMaybe<Scalars["String"]["input"]>;
};

export type ScheduledRun = {
  __typename?: "ScheduledRun";
  /**
   * Apply will be scheduled according to this cron schedule expression if specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  cronSchedule: Maybe<Array<Scalars["String"]["output"]>>;
  /** Customer provided runtime configuration for this scheduled run. */
  customRuntimeConfig: Maybe<RuntimeConfig>;
  /** Globally unique schedule identifier. */
  id: Scalars["ID"]["output"];
  /** Name of the scheduled run */
  name: Scalars["String"]["output"];
  /**
   * When the next detection run will be scheduled.
   * Null if done or is in progress.
   */
  nextSchedule: Maybe<Scalars["Int"]["output"]>;
  /**
   * Apply will be scheduled at this timestamp specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  timestampSchedule: Maybe<Scalars["Float"]["output"]>;
  /** Cron schedule expressions will apply for this timezone. */
  timezone: Maybe<Scalars["String"]["output"]>;
};

/** Arguments used to create and update an individual ScheduledRun configuration. */
export type ScheduledRunInput = {
  /**
   * Apply will be scheduled according to this cron schedule expression if specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  cronSchedule: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Name of the scheduled run */
  name: Scalars["String"]["input"];
  /** Customer provided runtime configuration for this scheduled run. */
  runtimeConfig: InputMaybe<RuntimeConfigInput>;
  /**
   * Apply will be scheduled at this timestamp specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  timestampSchedule: InputMaybe<Scalars["Int"]["input"]>;
  /** Cron schedule expressions will apply for this timezone. */
  timezone: InputMaybe<Scalars["String"]["input"]>;
};

export type ScheduledTask = {
  __typename?: "ScheduledTask";
  /** Command to be run as a task */
  command: Scalars["String"]["output"];
  /**
   * Apply will be scheduled according to this cron schedule expression if specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  cronSchedule: Maybe<Array<Scalars["String"]["output"]>>;
  /** Globally unique schedule identifier. */
  id: Scalars["ID"]["output"];
  /**
   * When the next detection run will be scheduled.
   * Null if done or is in progress.
   */
  nextSchedule: Maybe<Scalars["Int"]["output"]>;
  /** Stack that the schedule will be run against */
  stack: Scalars["ID"]["output"];
  /**
   * Apply will be scheduled at this timestamp specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  timestampSchedule: Maybe<Scalars["Float"]["output"]>;
  /** Cron schedule expressions will apply for this timezone. */
  timezone: Maybe<Scalars["String"]["output"]>;
};

export type ScheduledTaskInput = {
  /** Command to be run as a task */
  command: Scalars["String"]["input"];
  /**
   * Apply will be scheduled according to this cron schedule expression if specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  cronSchedule: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * Apply will be scheduled at this timestamp specified.
   * Either timestampSchedule or cronSchedule must not be empty.
   */
  timestampSchedule: InputMaybe<Scalars["Int"]["input"]>;
  /** Cron schedule expressions will apply for this timezone. */
  timezone: InputMaybe<Scalars["String"]["input"]>;
};

export type SearchAnsibleHostsOutput = {
  __typename?: "SearchAnsibleHostsOutput";
  edges: Array<AnsibleHostsConnection>;
  pageInfo: PageInfo;
};

export type SearchAnsibleTaskExecutionsOutput = {
  __typename?: "SearchAnsibleTaskExecutionsOutput";
  edges: Array<AnsibleTaskExecutionsConnection>;
  pageInfo: PageInfo;
};

export type SearchAnsibleTasksOutput = {
  __typename?: "SearchAnsibleTasksOutput";
  edges: Array<AnsibleTasksConnection>;
  pageInfo: PageInfo;
};

export type SearchAuditTrailEntriesOutput = {
  __typename?: "SearchAuditTrailEntriesOutput";
  edges: Array<AuditTrailEntriesConnection>;
  pageInfo: PageInfo;
};

export type SearchBlueprintVersionedGroupsOutput = {
  __typename?: "SearchBlueprintVersionedGroupsOutput";
  edges: Array<BlueprintVersionedGroupsConnection>;
  pageInfo: PageInfo;
};

export type SearchBlueprintsOutput = {
  __typename?: "SearchBlueprintsOutput";
  edges: Array<BlueprintsConnection>;
  pageInfo: PageInfo;
};

export type SearchContextsOutput = {
  __typename?: "SearchContextsOutput";
  edges: Array<ContextsConnection>;
  pageInfo: PageInfo;
};

export type SearchEntityChangeOutput = {
  __typename?: "SearchEntityChangeOutput";
  edges: Array<EntityChangeConnection>;
  pageInfo: PageInfo;
};

export type SearchInput = {
  /**
   * List the entity changes after this ID
   * When paginating the PageInfo.endCursor returned contains the next cursor value
   */
  after: InputMaybe<Scalars["String"]["input"]>;
  /** List the first * items */
  first: InputMaybe<Scalars["Int"]["input"]>;
  /** A full text search phrase. */
  fullTextSearch: InputMaybe<Scalars["String"]["input"]>;
  /** Ordering is unimplemented right now. */
  orderBy: InputMaybe<Array<SearchQueryOrder>>;
  /** The list of field predicates to match. */
  predicates: InputMaybe<Array<SearchQueryPredicate>>;
  /** Which page to return. */
  requestedPage: InputMaybe<SearchQueryRequestedPage>;
};

export type SearchManagedEntitiesGroupedByProvidersOutput = {
  __typename?: "SearchManagedEntitiesGroupedByProvidersOutput";
  edges: Array<ManagedEntitiesGroupedByProvidersConnection>;
  pageInfo: PageInfo;
};

export type SearchManagedEntitiesGroupedByStacksOutput = {
  __typename?: "SearchManagedEntitiesGroupedByStacksOutput";
  edges: Array<ManagedEntitiesGroupedByStacksConnection>;
  pageInfo: PageInfo;
};

export type SearchManagedEntitiesGroupedByTypesOutput = {
  __typename?: "SearchManagedEntitiesGroupedByTypesOutput";
  edges: Array<ManagedEntitiesGroupedByTypesConnection>;
  pageInfo: PageInfo;
};

export type SearchManagedEntitiesOutput = {
  __typename?: "SearchManagedEntitiesOutput";
  edges: Array<ManagedEntitiesConnection>;
  pageInfo: PageInfo;
};

/** SearchModulesOutput is a page of Modules. */
export type SearchModulesOutput = {
  __typename?: "SearchModulesOutput";
  edges: Array<ModuleConnection>;
  pageInfo: PageInfo;
};

export type SearchNamedWebhooksIntegrationOutput = {
  __typename?: "SearchNamedWebhooksIntegrationOutput";
  edges: Array<NamedWebhooksIntegrationConnection>;
  pageInfo: PageInfo;
};

/** SearchNotificationsOutput is a page of Notifications. */
export type SearchNotificationsOutput = {
  __typename?: "SearchNotificationsOutput";
  edges: Array<NotificationConnection>;
  pageInfo: PageInfo;
};

export type SearchPoliciesOutput = {
  __typename?: "SearchPoliciesOutput";
  edges: Array<PolicyConnection>;
  pageInfo: PageInfo;
};

export type SearchPolicyTemplatesOutput = {
  __typename?: "SearchPolicyTemplatesOutput";
  edges: Array<PolicyTemplateConnection>;
  pageInfo: PageInfo;
};

/** SearchQueryFieldConstraint constraints the values of a field. */
export type SearchQueryFieldConstraint = {
  /** booleanEquals constraints the field to be equal to one of the given values. */
  booleanEquals: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  /** enumEquals constraints the field to be equal to one of the given values. */
  enumEquals: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * hierarchyNodeValueEquals constraints the field to be contained in one of the given values
   * ID fields of SearchSuggestionsPossibleValuesHierarchyNode's are expected here
   */
  hierarchyNodeValueEquals: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * stringMatches constraints the field to match one of the given values.
   * '*' wildcards can be used in the values to indicate arbitrary numbers of arbitrary characters.
   */
  stringMatches: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** timeInLast constrains the field to predefined time ranges. */
  timeInLast: InputMaybe<SearchQueryFieldConstraintTimeInLast>;
  /** timeInRange constraints the field to be within the time range. */
  timeInRange: InputMaybe<SearchQueryFieldConstraintTimeInRange>;
};

export enum SearchQueryFieldConstraintTimeInLast {
  Last1D = "LAST1D",
  Last1H = "LAST1H",
  Last4H = "LAST4H",
  Last7D = "LAST7D",
  Last14D = "LAST14D",
  Last15M = "LAST15M",
  Last30M = "LAST30M",
}

export type SearchQueryFieldConstraintTimeInRange = {
  /** End of the time range. */
  end: InputMaybe<Scalars["Int"]["input"]>;
  /** Start of the time range. */
  start: InputMaybe<Scalars["Int"]["input"]>;
};

/** SearchQueryOrder is used to order the results of a search query. */
export type SearchQueryOrder = {
  /** direction to order by, defaults to ASC */
  direction: InputMaybe<SearchQueryOrderDirection>;
  /** field name to order by */
  field: Scalars["String"]["input"];
};

/** SearchQueryOrderDirection is the direction of the ordering. */
export enum SearchQueryOrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

/** SearchQueryPredicate is used to filter the results of a search query. */
export type SearchQueryPredicate = {
  /** constraint is the constraint on the field value. */
  constraint: SearchQueryFieldConstraint;
  /** exclude indicates whether the predicate should exclude fields values matching the constraint. */
  exclude: InputMaybe<Scalars["Boolean"]["input"]>;
  /** field is the field name to constraint. */
  field: Scalars["String"]["input"];
};

export enum SearchQueryRequestedPage {
  /** The first page of results. */
  First = "FIRST",
  /** The last page of results. */
  Last = "LAST",
  /** The next page of results. */
  Next = "NEXT",
  /** The previous page of results. */
  Previous = "PREVIOUS",
}

export type SearchRunIgnoredTriggersOutput = {
  __typename?: "SearchRunIgnoredTriggersOutput";
  edges: Array<RunIgnoredTriggersConnection>;
  pageInfo: PageInfo;
};

export type SearchSchedulableRunsOutput = {
  __typename?: "SearchSchedulableRunsOutput";
  edges: Array<RunWithPositionConnection>;
  pageInfo: PageInfo;
};

/** SearchStacksOutput is a page of Stacks. */
export type SearchStacksOutput = {
  __typename?: "SearchStacksOutput";
  edges: Array<StackConnection>;
  pageInfo: PageInfo;
};

/** SearchSuggestionsField describes a field you can use for searching. */
export type SearchSuggestionsField = {
  __typename?: "SearchSuggestionsField";
  /** filterable indicates whether the field can be used for filtering. */
  filterable: Scalars["Boolean"]["output"];
  /** name of the field. */
  name: Scalars["String"]["output"];
  /** orderable indicates whether the field can be used for search result ordering. */
  orderable: Scalars["Boolean"]["output"];
  /** One of the below will be non-null if the field is filterable. */
  possibleValuesBoolean: Maybe<SearchSuggestionsPossibleValuesBoolean>;
  possibleValuesEnum: Maybe<SearchSuggestionsPossibleValuesEnum>;
  possibleValuesHierarchy: Maybe<SearchSuggestionsPossibleValuesHierarchy>;
  possibleValuesString: Maybe<SearchSuggestionsPossibleValuesString>;
  possibleValuesTime: Maybe<Scalars["Boolean"]["output"]>;
  /** type of the field, only available if the field is filterable. */
  type: Maybe<SearchSuggestionsFieldType>;
};

export enum SearchSuggestionsFieldType {
  Boolean = "BOOLEAN",
  Enum = "ENUM",
  Hierarchy = "HIERARCHY",
  String = "STRING",
  Time = "TIME",
}

export type SearchSuggestionsInput = {
  /**
   * An optional list of fields to get the possible values for.
   * If a specified field doesn't exist, it will be ignored.
   */
  fields: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** A full text search phrase. */
  fullTextSearch: InputMaybe<Scalars["String"]["input"]>;
  /** The list of field predicates to match. */
  predicates: InputMaybe<Array<SearchQueryPredicate>>;
};

/** SearchSuggestionsOutput describes fields you can use for searching. */
export type SearchSuggestionsOutput = {
  __typename?: "SearchSuggestionsOutput";
  fields: Array<SearchSuggestionsField>;
  filteredCount: Scalars["Int"]["output"];
};

/** SearchSuggestionsPossibleValuesBoolean describes possible boolean values of a field. */
export type SearchSuggestionsPossibleValuesBoolean = {
  __typename?: "SearchSuggestionsPossibleValuesBoolean";
  /** counts of the respective values in the current result set. */
  counts: Array<Scalars["Int"]["output"]>;
  /** values are the possible values of the field. */
  values: Array<Scalars["Boolean"]["output"]>;
};

/** SearchSuggestionsPossibleValuesEnum describes possible enum values of a field. */
export type SearchSuggestionsPossibleValuesEnum = {
  __typename?: "SearchSuggestionsPossibleValuesEnum";
  /** counts of the respective values in the current result set. */
  counts: Array<Scalars["Int"]["output"]>;
  /** values are the possible values of the field. */
  values: Array<Scalars["String"]["output"]>;
};

/** SearchSuggestionsPossibleValuesHierarchy describes possible values of a hierarchical field. */
export type SearchSuggestionsPossibleValuesHierarchy = {
  __typename?: "SearchSuggestionsPossibleValuesHierarchy";
  /** counts of the respective values in the current result set. */
  counts: Array<Scalars["Int"]["output"]>;
  /** values are the possible values of the field. */
  values: Array<SearchSuggestionsPossibleValuesHierarchyNode>;
};

/** SearchSuggestionsPossibleValuesHierarchyNode describes a single possible value node of a hierarchical field. */
export type SearchSuggestionsPossibleValuesHierarchyNode = {
  __typename?: "SearchSuggestionsPossibleValuesHierarchyNode";
  /** the value that this single node represents in the context of the parent */
  displayValue: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  /** ID of the parent; null for the root node */
  parentId: Maybe<Scalars["ID"]["output"]>;
};

/** SearchSuggestionsPossibleValuesString describes possible string values of a field. */
export type SearchSuggestionsPossibleValuesString = {
  __typename?: "SearchSuggestionsPossibleValuesString";
  /** counts of the respective values in the current result set. */
  counts: Array<Scalars["Int"]["output"]>;
  /** values are the possible values of the field. */
  values: Array<Scalars["String"]["output"]>;
};

export type SearchVcsIntegrationUsersOutput = {
  __typename?: "SearchVCSIntegrationUsersOutput";
  edges: Array<VcsIntegrationUserConnection>;
  pageInfo: PageInfo;
};

export type SearchVcsIntegrationsOutput = {
  __typename?: "SearchVCSIntegrationsOutput";
  edges: Array<VcsIntegrationsConnection>;
  pageInfo: PageInfo;
};

export type SearchVersionsOutput = {
  __typename?: "SearchVersionsOutput";
  edges: Array<VersionsConnection>;
  pageInfo: PageInfo;
};

export type SearchWorkerPoolUsersOutput = {
  __typename?: "SearchWorkerPoolUsersOutput";
  edges: Array<WorkerPoolUserConnection>;
  pageInfo: PageInfo;
};

export type SearchWorkerPoolsOutput = {
  __typename?: "SearchWorkerPoolsOutput";
  edges: Array<WorkerPoolConnection>;
  pageInfo: PageInfo;
};

export type SearchWorkersOutput = {
  __typename?: "SearchWorkersOutput";
  edges: Array<WorkerConnection>;
  pageInfo: PageInfo;
};

export type SeatLimit = {
  __typename?: "SeatLimit";
  /** The number of seats currently in use. */
  inUse: Scalars["Int"]["output"];
  /** The total number of seats available, -1 for unlimited. */
  limit: Scalars["Int"]["output"];
};

export type Seats = {
  __typename?: "Seats";
  /** API key seat limit. */
  apiKey: SeatLimit;
  /** User seat limit. */
  user: SeatLimit;
};

/** SecurityKey represents hardware device that supports the FIDO2 protocol. */
export type SecurityKey = {
  __typename?: "SecurityKey";
  /** Timestamp representing the security key registration. */
  createdAt: Scalars["Int"]["output"];
  /** currentOwner returns true if the current user is the owner of the security key. */
  currentOwner: Scalars["Boolean"]["output"];
  /** Unique ID of the security key. */
  id: Scalars["ID"]["output"];
  /** Name of the security key. */
  name: Scalars["String"]["output"];
  /** Owner of the security key. */
  owner: Scalars["String"]["output"];
};

export type SelfHostedLicence = {
  __typename?: "SelfHostedLicence";
  /** Describes the date range of the license. */
  validity: LicenseDateRange;
};

export type ServiceNowExtraDataInput = {
  /** Base URL for the ServiceNow instance */
  baseURL: Scalars["String"]["input"];
  /** Password for authentication */
  password: Scalars["String"]["input"];
  /** Username for authentication */
  username: Scalars["String"]["input"];
};

/** Session represents a single user session with a Spacelift account. */
export type Session = {
  __typename?: "Session";
  /** Admin means that the user is an admin of the organization. */
  admin: Scalars["Boolean"]["output"];
  /** Time of the session creation. */
  createdAt: Scalars["Int"]["output"];
  /** Current indicates whether the session is the current one. */
  current: Scalars["Boolean"]["output"];
  /** Email of the session creator if applicable. */
  email: Scalars["String"]["output"];
  /** Time of the session expiration. */
  expiresAt: Scalars["Int"]["output"];
  /** Unique session ID, the same as JWT ID. */
  id: Scalars["ID"]["output"];
  /** IP address of the session creator. */
  ipAddress: Scalars["String"]["output"];
  /** Indicates whether the session is a machine user. */
  isMachineUser: Scalars["Boolean"]["output"];
  /** Login of the session creator. */
  login: Scalars["String"]["output"];
  /** Full name of the session creator. */
  name: Scalars["String"]["output"];
  /** Team names which the user has access to. */
  teams: Array<Scalars["String"]["output"]>;
  /** Type of the session */
  type: Scalars["String"]["output"];
};

/**
 * SharedModule contains information about a module an account has access to that is shared
 * with it by another Spacelift account.
 */
export type SharedModule = {
  __typename?: "SharedModule";
  /** Current module version. */
  current: Maybe<SharedVersion>;
  /** Free-form module description for the users, supports Markdown. */
  description: Maybe<Scalars["String"]["output"]>;
  /** Unique (within a single organization), immutable name of the Module. */
  id: Scalars["ID"]["output"];
  /** Labels applied to a Module. */
  labels: Array<Scalars["String"]["output"]>;
  /** Latest module version. */
  latest: Maybe<SharedVersion>;
  /** Provider-independent name of the Terraform module. */
  name: Scalars["String"]["output"];
  /**
   * Subdomain of the account owner - if the module is owned by another account
   * and just shared with the current one, this will be used to point the user
   * to the "canonical" location of the module.
   */
  ownerSubdomain: Scalars["String"]["output"];
  /** TerraformProvider represents Module's Terraform provider */
  terraformProvider: Scalars["String"]["output"];
  /** Retrieve a single Version by its ID. */
  version: Maybe<SharedVersion>;
  /**
   * Paginate through all Versions of the current Module. By default, only
   * ACTIVE and PENDING versions are shown, but setting includeFailed to true
   * will show all versions.
   */
  versions: Array<SharedVersion>;
};

/**
 * SharedModule contains information about a module an account has access to that is shared
 * with it by another Spacelift account.
 */
export type SharedModuleVersionArgs = {
  id: Scalars["ID"]["input"];
};

/**
 * SharedModule contains information about a module an account has access to that is shared
 * with it by another Spacelift account.
 */
export type SharedModuleVersionsArgs = {
  before: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 * SharedModule contains information about a module version that has been shared with this
 * account by another Spacelift account.
 */
export type SharedVersion = {
  __typename?: "SharedVersion";
  /** Unique ID of the Version. */
  id: Scalars["ID"]["output"];
  /**
   * String representation of the metadata generated by parsing the module
   * source code.
   */
  metadata: Maybe<ModuleRepositoryMetadata>;
  /** User-provided notes associated with the version. */
  notes: Maybe<Scalars["String"]["output"]>;
  /**
   * Number of the version conforming to the semantic versioning scheme
   * (major, minor, patch).
   */
  number: Scalars["String"]["output"];
  /** Current state of the Version. */
  state: VersionState;
  /** Note associated with the version when it was marked as "bad", if it has */
  yankNote: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether the version has been marked as "bad". */
  yanked: Scalars["Boolean"]["output"];
  /** Login of the user who marked the version as "bad", if it has been marked */
  yankedBy: Maybe<Scalars["String"]["output"]>;
};

/** Returns information about the Slack App used to integrate Spacelift with Slack. */
export type SlackAppConfig = {
  __typename?: "SlackAppConfig";
  /** Indicates whether the Slack App configuration can be deleted via slackAppConfigDelete */
  canDelete: Scalars["Boolean"]["output"];
  /**
   * Indicates whether the Slack App configuration can be modified via slackAppConfigSet
   * and slackAppConfigDelete. This will return false if the Slack App config is provided
   * via environment variables.
   */
  canUpdate: Scalars["Boolean"]["output"];
  /** The client ID of the Slack App. Returns null when no Slack App is configured. */
  clientId: Maybe<Scalars["String"]["output"]>;
};

export type SlackAppConfigInput = {
  clientId: Scalars["String"]["input"];
  clientSecret: Scalars["String"]["input"];
  signingSecret: Scalars["String"]["input"];
};

/** SlackIntegration represents account's Slack integration */
export type SlackIntegration = {
  __typename?: "SlackIntegration";
  /** ID of the Slack workspace */
  teamID: Scalars["String"]["output"];
  /** Name of the Slack workspace */
  teamName: Scalars["String"]["output"];
};

export type Space = {
  __typename?: "Space";
  /** This user's access level to this Space. */
  accessLevel: SpaceAccessLevel;
  /** Description of the space */
  description: Scalars["String"]["output"];
  /** Unique (within a single organization), immutable name of the space. */
  id: Scalars["ID"]["output"];
  /** Toggle whether the space should inherit entities from its parent space */
  inheritEntities: Scalars["Boolean"]["output"];
  /** Optional list of Space labels. */
  labels: Array<Scalars["String"]["output"]>;
  /** Fancy, mutable name of the Space to show in the UI. */
  name: Scalars["String"]["output"];
  /** Immutable identifier of the space that this space is child of */
  parentSpace: Maybe<Scalars["ID"]["output"]>;
};

/** The level of access a user has to the given space. */
export enum SpaceAccessLevel {
  Admin = "ADMIN",
  None = "NONE",
  Read = "READ",
  Write = "WRITE",
}

export type SpaceAccessRule = {
  __typename?: "SpaceAccessRule";
  /** Slug of the space that this access rule affects. */
  space: Scalars["ID"]["output"];
  /** Access level to be granted within the space. */
  spaceAccessLevel: SpaceAccessLevel;
};

export type SpaceAccessRuleInput = {
  /** Slug of the space that this access rule affects. */
  space: Scalars["ID"]["input"];
  /** Access level to be granted within the space. */
  spaceAccessLevel: SpaceAccessLevel;
};

/** SpaceActions define which actions are allowed in a space. */
export type SpaceActions = {
  __typename?: "SpaceActions";
  actions: Array<Action>;
  spaceID: Scalars["ID"]["output"];
};

/** Arguments used to create and update an individual Space. */
export type SpaceInput = {
  /** Description of the space */
  description: Scalars["String"]["input"];
  /** Toggle whether the space should inherit entities from its parent space */
  inheritEntities: Scalars["Boolean"]["input"];
  /** Optional list of Space labels. */
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Name of the space */
  name: Scalars["String"]["input"];
  /** Slug of the space that this space will be child of */
  parentSpace: Scalars["ID"]["input"];
};

export type SpaceTile = {
  __typename?: "SpaceTile";
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
};

export type SpaceliftMetrics = {
  __typename?: "SpaceliftMetrics";
  /**
   * Runs
   * Average run duration in nanoseconds
   */
  averageRunDuration: Array<DataPoint>;
  averageRunDurationRange: Array<DataPoint>;
  avgStackSizeByResourceCount: Array<DataPoint>;
  driftDetectionCoverage: Array<DataPoint>;
  favouriteModules: Array<StackTile>;
  favouriteStacks: Array<StackTile>;
  hasRuns: Scalars["Boolean"]["output"];
  hasStacks: Scalars["Boolean"]["output"];
  largestStacks: Array<StackTileWithResources>;
  /** Stacks */
  maxStackSizeByResourceCount: Array<DataPoint>;
  medianRunDuration: Array<DataPoint>;
  medianRunDurationRange: Array<DataPoint>;
  needsApprovalRuns: Array<RunTile>;
  numberOfDeploymentsRange: Array<DataPoint>;
  recentDriftDetectionProposedRuns: Array<RunTile>;
  recentStacks: Array<StackTile>;
  resourcesCountByDrift: Array<DataPoint>;
  /** Resources */
  resourcesCountByVendor: Array<DataPoint>;
  resourcesCountRange: Array<DataPoint>;
  runsRecentlyApproved: Array<RunTileWithApproval>;
  runsRequiringAttention: Array<RunTile>;
  stackFailuresRange: Array<DataPoint>;
  stacksCountByState: Array<DataPoint>;
  /** DriftDetection */
  upcomingDriftDetectionSchedules: Array<DriftDetectionIntegrationTile>;
};

export type SpaceliftMetricsRecentStacksArgs = {
  states: Array<StackState>;
};

/** Stack represents a single Spacelift project. */
export type Stack = Hookable &
  Notifiable & {
    __typename?: "Stack";
    /**
     * List of globs that when changed trigger a run.
     * It similar to projectRoot but instead of being a concrete path it is a
     * a list of paths defined using the glob pattern.
     */
    additionalProjectGlobs: Array<Scalars["String"]["output"]>;
    /** Administrative stacks can manage other Stacks at runtime. */
    administrative: Scalars["Boolean"]["output"];
    /**
     * List of after-apply scripts.
     * @deprecated Use hooks.afterApply instead.
     */
    afterApply: Array<Scalars["String"]["output"]>;
    /**
     * List of after-destroy scripts.
     * @deprecated Use hooks.afterDestroy instead.
     */
    afterDestroy: Array<Scalars["String"]["output"]>;
    /**
     * List of after-init scripts.
     * @deprecated Use hooks.afterInit instead.
     */
    afterInit: Array<Scalars["String"]["output"]>;
    /**
     * List of after-perform scripts.
     * @deprecated Use hooks.afterPerform instead.
     */
    afterPerform: Array<Scalars["String"]["output"]>;
    /**
     * List of after-plan scripts.
     * @deprecated Use hooks.afterPlan instead.
     */
    afterPlan: Array<Scalars["String"]["output"]>;
    /**
     * List of after-run scripts.
     * @deprecated Use hooks.afterRun instead.
     */
    afterRun: Array<Scalars["String"]["output"]>;
    /**
     * API host, useful for building links in the GUI. It may be empty if the
     * Stack's original VCS provider has been removed.
     */
    apiHost: Maybe<Scalars["String"]["output"]>;
    /** List of aws integrations that can be attached to this Stack. */
    attachableAwsIntegrations: Array<AwsIntegration>;
    /** List of azure integrations that can be attached to this Stack. */
    attachableAzureIntegrations: Array<AzureIntegration>;
    /** List of contexts that can be attached to this Stack. */
    attachableContexts: Array<Context>;
    /** List of policies that can be attached to this Stack. */
    attachablePolicies: Array<Policy>;
    /** List of worker pools that can be attached to this Stack. */
    attachableWorkerPools: Array<WorkerPool>;
    /** Any AWS integrations attached to this stack. */
    attachedAwsIntegrations: Array<StackAwsIntegrationAttachment>;
    /** Any Azure integrations attached to this stack. */
    attachedAzureIntegrations: Array<StackAzureIntegrationAttachment>;
    /** List of contexts attached to this Stack. */
    attachedContexts: Array<StackContextAttachment>;
    /** List of policies attached to this Stack. */
    attachedPolicies: Array<StackPolicyAttachment>;
    /** Will the stack automatically deploy successful plans. */
    autodeploy: Scalars["Boolean"]["output"];
    /** Will the stack automatically retry invalidated Pull Request Runs. */
    autoretry: Scalars["Boolean"]["output"];
    /**
     * List of before-apply scripts.
     * @deprecated Use hooks.beforeApply instead.
     */
    beforeApply: Array<Scalars["String"]["output"]>;
    /**
     * List of before-destroy scripts.
     * @deprecated Use hooks.beforeDestroy instead.
     */
    beforeDestroy: Array<Scalars["String"]["output"]>;
    /**
     * List of before-init scripts.
     * @deprecated Use hooks.beforeInit instead.
     */
    beforeInit: Array<Scalars["String"]["output"]>;
    /**
     * List of before-perform scripts.
     * @deprecated Use hooks.beforePerform instead.
     */
    beforePerform: Array<Scalars["String"]["output"]>;
    /**
     * List of before-plan scripts.
     * @deprecated Use hooks.beforePlan instead.
     */
    beforePlan: Array<Scalars["String"]["output"]>;
    /** Indicates whether the stack is currently blocked by a Run. */
    blocked: Scalars["Boolean"]["output"];
    /** The Run that's currently holding the stack lock, if any. */
    blocker: Maybe<Run>;
    /** Will point to a blueprint if the stack has been created using one. */
    blueprint: Maybe<Blueprint>;
    /** Git branch of the repo that the Stack is tracking. */
    branch: Scalars["String"]["output"];
    /** Whether the user has write privileges for this Stack. */
    canWrite: Scalars["Boolean"]["output"];
    /** Full list of stack config elements. */
    config: Array<ConfigElement>;
    /** Single stack config element. */
    configElement: Maybe<ConfigElement>;
    /** Timestamp representing the stack creation. */
    createdAt: Scalars["Int"]["output"];
    /** Whether the stack has been deleted. */
    deleted: Scalars["Boolean"]["output"];
    /** Whether the stack is pending deletion. */
    deleting: Scalars["Boolean"]["output"];
    /**
     * Change in resources tracked by the Stack's state that will be caused
     * by an unconfirmed run that is blocking the stack.
     */
    delta: Maybe<Delta>;
    /**
     * All stack dependencies this stack depends on, or are depended on by,
     * including transitive dependencies.
     */
    dependenciesFullGraph: Array<StackDependency>;
    /** An individual stack dependency. */
    dependency: Maybe<StackDependency>;
    /** Dependencies of the stack. */
    dependsOn: Array<StackDependency>;
    /** Free-form stack description for the users, supports Markdown. */
    description: Maybe<Scalars["String"]["output"]>;
    /**
     * When the Terraform version is a version range, this is the highest version
     * in the range that will be used for the next run. If the version is not a
     * range, or is not set, this field will be nil.
     */
    effectiveTerraformVersion: Maybe<Scalars["String"]["output"]>;
    /** Indicates whether uploading sensitive outputs is enabled for this stack. */
    enableSensitiveOutputUpload: Scalars["Boolean"]["output"];
    /** Indicates whether the masking for secret is enabled for this stack. */
    enableWellKnownSecretMasking: Scalars["Boolean"]["output"];
    /** Entities managed by this stack. */
    entities: Array<ManagedEntity>;
    /** Individual entity managed by this stack (id is the address of the entity). */
    entity: Maybe<ManagedEntity>;
    /** Number of entities managed by this stack. */
    entityCount: Scalars["Int"]["output"];
    /** Indicates whether the GitHub Checks "Deploy" action is enabled. */
    githubActionDeploy: Scalars["Boolean"]["output"];
    /** List of hooks extending this stack. */
    hooks: Hooks;
    /** Unique (within a single organization), immutable name of the stack. */
    id: Scalars["ID"]["output"];
    /** Integrations between a Stack and external systems. */
    integrations: Maybe<StackIntegrations>;
    /** Stacks that are depending on this stack. */
    isDependedOnBy: Array<StackDependency>;
    /** If stack is disabled, it will not be able to create any runs. */
    isDisabled: Scalars["Boolean"]["output"];
    /** If there are any entities in the stack that are drifted, this value will be true. */
    isDrifted: Scalars["Boolean"]["output"];
    /** Return true if the stack is currently using a rolled-back state */
    isStateRollback: Scalars["Boolean"]["output"];
    /** Labels applied to a Stack. */
    labels: Array<Scalars["String"]["output"]>;
    /** Whether triggering local workspace based Runs on this Stack is enabled. */
    localPreviewEnabled: Scalars["Boolean"]["output"];
    /** Note associated with the lock, if any. */
    lockNote: Maybe<Scalars["String"]["output"]>;
    /** Timestamp of the time when the stack was locked, if it is locked. */
    lockedAt: Maybe<Scalars["Int"]["output"]>;
    /** Username of the person who locked the Stack for their exclusive use. */
    lockedBy: Maybe<Scalars["String"]["output"]>;
    /** Is Spacelift responsible for managing the Terraform state file. */
    managesStateFile: Scalars["Boolean"]["output"];
    /** Versions of modules hosted on Spacelift the latest run on this Stack uses. */
    moduleVersionsUsed: Array<Version>;
    /** Fancy, mutable name of the Stack to show in the UI. */
    name: Scalars["String"]["output"];
    /** Repository namespace. */
    namespace: Scalars["String"]["output"];
    /** Indicates whether the Stack has a run that needs approval. */
    needsApproval: Scalars["Boolean"]["output"];
    /** Number of the new stack notifications. */
    notificationCount: Scalars["Int"]["output"];
    /** The list of stack outputs, if any. */
    outputs: Array<StackOutput>;
    /** Retrieve stack policy attachment by a known ID. */
    policyAttachment: Maybe<StackPolicyAttachment>;
    /**
     * Project root is the optional directory relative to the workspace root
     * containing the entrypoint to the Stack.
     */
    projectRoot: Maybe<Scalars["String"]["output"]>;
    /** Indicates whether deletion protection is currently "on" for the stack. */
    protectFromDeletion: Scalars["Boolean"]["output"];
    /** Provider of the repository */
    provider: VcsProvider;
    /**
     * List of up to 50 most recent open Pull Requests targeting Stack's tracked
     * branch.
     */
    pullRequests: Array<PullRequest>;
    /** GitHub repository tracked by this stack, as account/repo. */
    repository: Scalars["String"]["output"];
    /** A list of tags for the tracked repository. */
    repositoryTags: Array<Scalars["String"]["output"]>;
    /** Git repository URL tracked by this stack. */
    repositoryURL: Maybe<Scalars["String"]["output"]>;
    /** Individual Run (or task) by ID. */
    run: Maybe<Run>;
    runResourceState: Maybe<RunResourceState>;
    /** Optional runner image used to process the Run. */
    runnerImage: Maybe<Scalars["String"]["output"]>;
    /** Paginated list of Runs targeting the tracked branch. */
    runs: Array<Run>;
    /**
     * List of runs from a start timestamp inclusive to an end timestamp exclusive.
     * Caveat: It uses Ints serialized to Strings, because serializing Ints to JSON
     * is apparently really hard in JavaScript.
     * Also, note that the maximum supported range is 8 days (691,200 seconds).
     */
    runsInRange: Array<Run>;
    /**
     * Calculated runtime config containing elements coming from contexts,
     * stack and various integrations.
     */
    runtimeConfig: Array<ConfigElementWithSource>;
    /** Individual ScheduledDelete created for this stack */
    scheduledDelete: Maybe<ScheduledDelete>;
    /** Deletes that are scheduled for this stack */
    scheduledDeletes: Array<ScheduledDelete>;
    /** Individual ScheduledRun created for this stack */
    scheduledRun: Maybe<ScheduledRun>;
    /** Runs that are scheduled for this stack */
    scheduledRuns: Array<ScheduledRun>;
    /** Individual ScheduledTask created for this stack */
    scheduledTask: Maybe<ScheduledTask>;
    /** Tasks that are scheduled for this stack */
    scheduledTasks: Array<ScheduledTask>;
    /** Returns managed state versions */
    searchManagedStateVersions: Maybe<ManagedStateVersionOutput>;
    /**
     * Searches run ignored triggers. This query can only be fetched by admins.
     * Note: this is an experimental functionality.
     */
    searchRunIgnoredTriggers: Maybe<SearchRunIgnoredTriggersOutput>;
    /** ID of the space this Stack belongs to. */
    space: Scalars["ID"]["output"];
    /** Details of the space this Stack belongs to. */
    spaceDetails: Space;
    /** Starred stack is showed first on the stack list */
    starred: Scalars["Boolean"]["output"];
    /** Current state of the Stack. */
    state: StackState;
    /** Is set to true when there are no ongoing runs. */
    stateIsTerminal: Scalars["Boolean"]["output"];
    /** Timestamp of the last change to the Stack state. */
    stateSetAt: Maybe<Scalars["Int"]["output"]>;
    /** ID of the last run that changed the Stack state. */
    stateSetBy: Maybe<Scalars["ID"]["output"]>;
    /** Paginated list of Tasks. */
    tasks: Array<Run>;
    /**
     * Terraform version set by the admin (can be a version range) or last used to
     * apply changes (always a version).
     */
    terraformVersion: Maybe<Scalars["String"]["output"]>;
    /** Current tracked branch head, if any. */
    trackedBranchHead: Maybe<Commit>;
    /**
     * Current commit tracked by the Stack - that is the latest commit pushed that
     * affects it.
     */
    trackedCommit: Maybe<Commit>;
    /** Note about who or what set the current tracked commit, if anything. */
    trackedCommitSetBy: Scalars["String"]["output"];
    /** VCSDetached indicates whether the stack's VCS integration has been deleted. */
    vcsDetached: Scalars["Boolean"]["output"];
    /**
     * The VCS integration used by this stack. This may either be a default integration,
     * or a space-level integration. If a space-level integration is being used by the stack,
     * and that integration is deleted, this property will be null. Additionally, if the
     * stack is using a default integration, and none exists, this property will be null.
     */
    vcsIntegration: Maybe<VcsIntegration>;
    /** Stack configuration which is specific to the vendor used. */
    vendorConfig: Maybe<StackConfigVendor>;
    /**
     * WorkerPool associated with the Stack, if any. If the Stack is associated
     * with the shared default worker pool, this field is empty.
     */
    workerPool: Maybe<WorkerPool>;
  };

/** Stack represents a single Spacelift project. */
export type StackConfigElementArgs = {
  id: Scalars["ID"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackDependencyArgs = {
  id: Scalars["ID"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackEntityArgs = {
  id: Scalars["ID"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackPolicyAttachmentArgs = {
  id: Scalars["ID"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackRunArgs = {
  id: Scalars["ID"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackRunResourceStateArgs = {
  id: Scalars["ID"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackRunsArgs = {
  before: InputMaybe<Scalars["ID"]["input"]>;
};

/** Stack represents a single Spacelift project. */
export type StackRunsInRangeArgs = {
  end: Scalars["String"]["input"];
  start: Scalars["String"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackScheduledDeleteArgs = {
  id: Scalars["ID"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackScheduledRunArgs = {
  id: Scalars["ID"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackScheduledTaskArgs = {
  id: Scalars["ID"]["input"];
};

/** Stack represents a single Spacelift project. */
export type StackSearchManagedStateVersionsArgs = {
  input: SearchInput;
};

/** Stack represents a single Spacelift project. */
export type StackSearchRunIgnoredTriggersArgs = {
  input: SearchInput;
};

/** Stack represents a single Spacelift project. */
export type StackTasksArgs = {
  before: InputMaybe<Scalars["ID"]["input"]>;
};

/** StackAwsIntegrationAttachment is the AWS integration attachment viewed from the Stack. */
export type StackAwsIntegrationAttachment = {
  __typename?: "StackAwsIntegrationAttachment";
  /** Duration in seconds for which the assumed role credentials should be valid. */
  durationSeconds: Scalars["Int"]["output"];
  /**
   * AWS external ID. This will contain your custom external ID when using role-assumption on the
   * worker, and otherwise will contain an external ID generated by Spacelift.
   */
  externalId: Scalars["String"]["output"];
  /** AWS credentials will be generated in the private worker. */
  generateCredentialsInWorker: Scalars["Boolean"]["output"];
  /** Globally unique attachment identifier. */
  id: Scalars["ID"]["output"];
  /** ID of the attached integration. */
  integrationId: Scalars["ID"]["output"];
  /** Whether the integration is a legacy integration or not. */
  legacy: Scalars["Boolean"]["output"];
  /** The friendly name of the integration. */
  name: Scalars["String"]["output"];
  /** Whether the integration is used for read operations. */
  read: Scalars["Boolean"]["output"];
  /** AWS IAM role to assume. */
  roleArn: Scalars["String"]["output"];
  /** Whether the integration is used for write operations. */
  write: Scalars["Boolean"]["output"];
};

/** StackAzureIntegrationAttachment is the Azure integration attachment viewed from the Stack. */
export type StackAzureIntegrationAttachment = {
  __typename?: "StackAzureIntegrationAttachment";
  /** The default Azure subscription to use if none has been specified at the Stack level. */
  defaultSubscriptionId: Maybe<Scalars["String"]["output"]>;
  /** Globally unique attachment identifier. */
  id: Scalars["ID"]["output"];
  /** The display name of the Azure AD application. */
  integrationDisplayName: Scalars["String"]["output"];
  /** ID of the attached integration. */
  integrationId: Scalars["ID"]["output"];
  /** Name of the attached integration. */
  integrationName: Scalars["String"]["output"];
  /** Whether the integration is used for read operations. */
  read: Scalars["Boolean"]["output"];
  /** The Azure subscription Id to use for this stack. Overrides the defaultSubscriptionId. */
  subscriptionId: Maybe<Scalars["String"]["output"]>;
  /** Whether the integration is used for write operations. */
  write: Scalars["Boolean"]["output"];
};

/** Configuration which varies depending on the used vendor. */
export type StackConfigVendor =
  | StackConfigVendorAnsible
  | StackConfigVendorCloudFormation
  | StackConfigVendorKubernetes
  | StackConfigVendorPulumi
  | StackConfigVendorTerraform
  | StackConfigVendorTerragrunt;

/** Configuration specific to Ansible stacks. */
export type StackConfigVendorAnsible = {
  __typename?: "StackConfigVendorAnsible";
  /** The main Ansible playbook file. */
  playbook: Scalars["String"]["output"];
};

/** Configuration specific to Ansible stacks. */
export type StackConfigVendorAnsibleInput = {
  /** The main Ansible playbook file. */
  playbook: Scalars["String"]["input"];
};

/** Configuration specific to CloudFormation stacks. */
export type StackConfigVendorCloudFormation = {
  __typename?: "StackConfigVendorCloudFormation";
  /** The main CloudFormation template file, which gets to be the root stack. */
  entryTemplateFile: Scalars["String"]["output"];
  /** The region in which the CloudFormation stack should be. */
  region: Scalars["String"]["output"];
  /** CloudFormation root stack name. */
  stackName: Scalars["String"]["output"];
  /** The S3 Bucket CloudFormation should store its templates in. */
  templateBucket: Scalars["String"]["output"];
};

/** Configuration specific to CloudFormation stacks. */
export type StackConfigVendorCloudFormationInput = {
  /** The main CloudFormation template file, which gets to be the root stack. */
  entryTemplateFile: Scalars["String"]["input"];
  /** The region in which the CloudFormation stack should be. */
  region: Scalars["String"]["input"];
  /** CloudFormation root stack name. */
  stackName: Scalars["String"]["input"];
  /** The S3 Bucket CloudFormation should store its templates in. */
  templateBucket: Scalars["String"]["input"];
};

/**
 * Configuration which varies depending on the used vendor.
 * All but one field should be left empty.
 */
export type StackConfigVendorInput = {
  /** Set if Ansible is the Stack vendor. */
  ansible: InputMaybe<StackConfigVendorAnsibleInput>;
  /** Set if CloudFormation is the Stack vendor. */
  cloudFormation: InputMaybe<StackConfigVendorCloudFormationInput>;
  /** Set if Kubernetes is the Stack vendor. */
  kubernetes: InputMaybe<StackConfigVendorKubernetesInput>;
  /** Set if Pulumi is the Stack vendor. */
  pulumi: InputMaybe<StackConfigVendorPulumiInput>;
  /** Set if Terraform is the Stack vendor. */
  terraform: InputMaybe<StackConfigVendorTerraformInput>;
  /** Set if Terragrunt is the Stack vendor. */
  terragrunt: InputMaybe<StackConfigVendorTerragruntInput>;
};

/** Configuration specific to Kubernetes stacks. */
export type StackConfigVendorKubernetes = {
  __typename?: "StackConfigVendorKubernetes";
  /** The version of kubectl to be used. */
  kubectlVersion: Scalars["String"]["output"];
  /** The tool used by the stack for executing workflow stages. */
  kubernetesWorkflowTool: KubernetesWorkflowTool;
  /** The namespace to run kubectl in. */
  namespace: Scalars["String"]["output"];
};

/** Configuration specific to Kubernetes stacks. */
export type StackConfigVendorKubernetesInput = {
  /** The version of kubectl to be used. */
  kubectlVersion: InputMaybe<Scalars["String"]["input"]>;
  /** Defines the tool that will be used to execute the workflow. */
  kubernetesWorkflowTool: InputMaybe<KubernetesWorkflowTool>;
  /** The Kubernetes namespace to run kubectl in. */
  namespace: Scalars["String"]["input"];
};

/** Configuration specific to Pulumi stacks. */
export type StackConfigVendorPulumi = {
  __typename?: "StackConfigVendorPulumi";
  /** The URL to log into. */
  loginURL: Scalars["String"]["output"];
  /** Root stack name. */
  stackName: Scalars["String"]["output"];
};

/** Configuration specific to Pulumi stacks. */
export type StackConfigVendorPulumiInput = {
  /** The URL to log into. */
  loginURL: Scalars["String"]["input"];
  /** Root stack name. */
  stackName: Scalars["String"]["input"];
};

/** Configuration specific to Terraform stacks. */
export type StackConfigVendorTerraform = {
  __typename?: "StackConfigVendorTerraform";
  /** Indicates whether you can access the Stack state file from other stacks or outside of Spacelift. */
  externalStateAccessEnabled: Scalars["Boolean"]["output"];
  /**
   * If Smart sanitization should be used for sanitizing terraform plans and resources.
   * Smart sanitization will only sanitize values that terraform has marked as sensitive.
   */
  useSmartSanitization: Scalars["Boolean"]["output"];
  /** Terraform version to use. */
  version: Maybe<Scalars["String"]["output"]>;
  /** The tool used by the stack for executing workflow stages. */
  workflowTool: TerraformWorkflowTool;
  /** Terraform workspace to select (not compatible with some backends). */
  workspace: Maybe<Scalars["String"]["output"]>;
};

/** Configuration specific to Terraform stacks. */
export type StackConfigVendorTerraformInput = {
  /** Indicates whether you can access the Stack state file from other stacks or outside of Spacelift. */
  externalStateAccessEnabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * If Smart sanitization should be used for sanitizing terraform plans and resources.
   * Smart sanitization will only sanitize values that terraform has marked as sensitive.
   */
  useSmartSanitization: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Terraform version to use. */
  version: InputMaybe<Scalars["String"]["input"]>;
  /** Defines the tool that will be used to execute the workflow. */
  workflowTool: InputMaybe<TerraformWorkflowTool>;
  /** Terraform workspace to select (not compatible with some backends). */
  workspace: InputMaybe<Scalars["String"]["input"]>;
};

/** Configuration specific to Terragrunt stacks. */
export type StackConfigVendorTerragrunt = {
  __typename?: "StackConfigVendorTerragrunt";
  /** Effective terragrunt and terraform versions */
  effectiveVersions: EffectiveTerragruntVersionResult;
  /** Terraform version to use. */
  terraformVersion: Maybe<Scalars["String"]["output"]>;
  /** Terragrunt version to use. */
  terragruntVersion: Maybe<Scalars["String"]["output"]>;
  /** TerragruntTool defines the underlying tool run by Terragrunt. */
  tool: TerragruntTool;
  /** If set to true, Terragrunt backend will execute commands in run-all mode. */
  useRunAll: Scalars["Boolean"]["output"];
  /**
   * If Smart sanitization should be used for sanitizing terraform plans and resources.
   * Smart sanitization will only sanitize values that terraform has marked as sensitive.
   */
  useSmartSanitization: Scalars["Boolean"]["output"];
};

export type StackConfigVendorTerragruntInput = {
  /** Terraform version to use. */
  terraformVersion: InputMaybe<Scalars["String"]["input"]>;
  /** Terragrunt version to use. */
  terragruntVersion: InputMaybe<Scalars["String"]["input"]>;
  /**
   * The IaC tool used by Terragrunt. When set to MANUALLY_PROVISIONED, an environment variable
   * called TERRAGRUNT_TFPATH must be available to runs to tell them where to find the tool.
   * Defaults to TERRAFORM_FOSS if not provided.
   */
  tool: InputMaybe<TerragruntTool>;
  /** If set to true, Terragrunt backend will execute commands in run-all mode. */
  useRunAll: Scalars["Boolean"]["input"];
  /**
   * If Smart sanitization should be used for sanitizing terraform plans and resources.
   * Smart sanitization will only sanitize values that terraform has marked as sensitive.
   */
  useSmartSanitization: Scalars["Boolean"]["input"];
};

/** StackConnection represents a single stack in a page of results. */
export type StackConnection = {
  __typename?: "StackConnection";
  cursor: Scalars["String"]["output"];
  node: Stack;
};

/** StackContextAttachment is the stack attachment viewed from the Stack. */
export type StackContextAttachment = {
  __typename?: "StackContextAttachment";
  /** Full list of stack config elements. */
  config: Array<ConfigElement>;
  /** Description of the attached context. */
  contextDescription: Maybe<Scalars["String"]["output"]>;
  /** Hooks of the attached context. */
  contextHooks: Hooks;
  /** ID of the attached context. */
  contextId: Scalars["ID"]["output"];
  /** Labels of the attached context. */
  contextLabels: Array<Scalars["String"]["output"]>;
  /** Name of the attached context. */
  contextName: Scalars["String"]["output"];
  /** Globally unique attachment identifier. */
  id: Scalars["ID"]["output"];
  /** Whether the context is autoattached to the stack. */
  isAutoattached: Scalars["Boolean"]["output"];
  /** Context in question. */
  priority: Scalars["Int"]["output"];
};

/** StackDependency represents a dependency between two stacks. */
export type StackDependency = {
  __typename?: "StackDependency";
  /** Details of the dependency stack. */
  dependsOnStack: StackDependencyDetail;
  /**
   * ID of the dependency.
   * @deprecated Use dependsOnStack field instead.
   */
  dependsOnStackId: Scalars["ID"]["output"];
  /** Slug of the dependency. Immutable. */
  id: Scalars["ID"]["output"];
  /** An individual stack dependency reference. */
  reference: Maybe<StackDependencyReference>;
  /** Number of references */
  referenceCount: Scalars["Int"]["output"];
  /** Output/Input references */
  references: Array<StackDependencyReference>;
  /** Details of the stack that has a dependency. */
  stack: StackDependencyDetail;
  /**
   * ID of the stack that has a dependency.
   * @deprecated Use stack field instead.
   */
  stackId: Scalars["ID"]["output"];
};

/** StackDependency represents a dependency between two stacks. */
export type StackDependencyReferenceArgs = {
  id: Scalars["ID"]["input"];
};

export type StackDependencyBatchInput = {
  /** The list of dependencies to add. */
  dependencies: Array<StackDependencyInput>;
};

export type StackDependencyDetail = {
  __typename?: "StackDependencyDetail";
  /** ID of the stack. */
  id: Scalars["ID"]["output"];
  /** Name of the stack. */
  name: Scalars["String"]["output"];
  /** Space of the stack. */
  space: Space;
  /** Stack configuration which is specific to the vendor used. */
  vendorConfig: Maybe<StackConfigVendor>;
};

export type StackDependencyInput = {
  /** ID of the dependency. */
  dependsOnStackId: Scalars["ID"]["input"];
  /** The Stack which has a dependency. */
  stackId: Scalars["ID"]["input"];
};

export type StackDependencyReference = {
  __typename?: "StackDependencyReference";
  /** ID of the stack dependency reference */
  id: Scalars["ID"]["output"];
  /** Input name */
  inputName: Scalars["String"]["output"];
  /** Output name */
  outputName: Scalars["String"]["output"];
  /**
   * TriggerAlways
   * When enabled, downstream stacks will be triggered even if the value of the
   * output has not changed.
   */
  triggerAlways: Scalars["Boolean"]["output"];
  /** Type */
  type: ConfigType;
};

export type StackDependencyReferenceInput = {
  inputName: Scalars["String"]["input"];
  outputName: Scalars["String"]["input"];
  triggerAlways: InputMaybe<Scalars["Boolean"]["input"]>;
  type: InputMaybe<ConfigType>;
};

export type StackDependencyReferenceUpdateInput = {
  id: Scalars["ID"]["input"];
  inputName: Scalars["String"]["input"];
  outputName: Scalars["String"]["input"];
  triggerAlways: InputMaybe<Scalars["Boolean"]["input"]>;
  type: InputMaybe<ConfigType>;
};

/** Arguments used to create and update an individual Stack. */
export type StackInput = {
  /**
   * AdditionalProjectGlobs is a list of globs that when changed trigger a run.
   * It similar to ProjectRoot but instead of being a concrete path it is a
   * a list of paths defined using the glob pattern.
   */
  additionalProjectGlobs: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Administrative stacks can manage other Stacks at runtime. */
  administrative: Scalars["Boolean"]["input"];
  /** Optional list of after-apply scripts. */
  afterApply: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optional list of after-destroy scripts. */
  afterDestroy: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optional list of after-init scripts. */
  afterInit: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optional list of after-perform scripts. */
  afterPerform: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optional list of after-plan scripts. */
  afterPlan: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optional list of after-run scripts. */
  afterRun: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * When set to true, stack will automatically deploy successful plans to
   * tracked branches.
   */
  autodeploy: InputMaybe<Scalars["Boolean"]["input"]>;
  /** When set to true, stack will automatically retry invalidated Pull Request Runs. */
  autoretry: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Optional list of before-apply scripts. */
  beforeApply: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optional list of before-destroy scripts. */
  beforeDestroy: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optional list of before-init scripts. */
  beforeInit: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optional list of before-perform scripts. */
  beforePerform: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optional list of before-plan scripts. */
  beforePlan: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Name of the branch to track. */
  branch: Scalars["String"]["input"];
  /** Optional free-form description of the Stack. */
  description: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates whether runs on the stack will attempt to upload sensitive output potentially needed for stack dependencies */
  enableSensitiveOutputUpload: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether the masking for secret is enabled for this stack. */
  enableWellKnownSecretMasking: InputMaybe<Scalars["Boolean"]["input"]>;
  /** When set to true, the "Deploy" action will be enabled on GitHub Checks Run (default: true). */
  githubActionDeploy: InputMaybe<Scalars["Boolean"]["input"]>;
  /** When set to true, the stack will not be able to create runs. */
  isDisabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Optional list of Stack labels. */
  labels: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Whether triggering local workspace based Runs on this Stack is enabled */
  localPreviewEnabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Human-friendly, mutable stack name. */
  name: Scalars["String"]["input"];
  /**
   * Namespace is the optional namespace which the repository belongs to. If not
   * provided, the name of the linked GitHub account is used.
   */
  namespace: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Project root is the optional directory relative to the workspace root
   * containing the entrypoint to the Stack.
   */
  projectRoot: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates whether deletion protection is currently "on" for the stack. */
  protectFromDeletion: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Provider is the optional VCS provider that provides access to the Git
   * repository linked to the Stack.
   */
  provider: InputMaybe<VcsProvider>;
  /** Name of the repository to track. */
  repository: Scalars["String"]["input"];
  /** Optional URL of the repository to track. */
  repositoryURL: InputMaybe<Scalars["String"]["input"]>;
  /** Optional runner image used to process the Run. */
  runnerImage: InputMaybe<Scalars["String"]["input"]>;
  /** ID of the Space this Stack should be in. */
  space: InputMaybe<Scalars["ID"]["input"]>;
  /** Terraform version to use. NOTE: this takes precedence over vendorConfig.terraformVersion */
  terraformVersion: InputMaybe<Scalars["String"]["input"]>;
  /**
   * The ID of the VCS integration used by this stack. This should only be provided if the default (account-level)
   * integration is not being used (i.e. a space-level integration is being used).
   */
  vcsIntegrationId: InputMaybe<Scalars["ID"]["input"]>;
  /** Stack configuration which is specific to the vendor used. */
  vendorConfig: InputMaybe<StackConfigVendorInput>;
  /** ID of the worker pool for the Stack to use. */
  workerPool: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 * AWS integration - with that set up, a Stack can assume an arbitrary AWS IAM
 * role without setting up static credentials.
 */
export type StackIntegrationAws = {
  __typename?: "StackIntegrationAws";
  /**
   * Is this integration activated. If not, assumedRoleARN is expected to be
   * empty.
   */
  activated: Scalars["Boolean"]["output"];
  /** AWS IAM assume role creation link. */
  assumeRoleLink: Scalars["String"]["output"];
  /** AWS IAM assume role policy statement. */
  assumeRolePolicyStatement: Scalars["String"]["output"];
  /** AWS IAM role to assume, if set. */
  assumedRoleArn: Maybe<Scalars["String"]["output"]>;
  /** Duration in seconds for which the assumed role credentials should be valid. */
  durationSeconds: Maybe<Scalars["Int"]["output"]>;
  /** AWS external ID (works only for private workers). */
  externalID: Maybe<Scalars["String"]["output"]>;
  /** AWS credentials will be generated in the private worker. */
  generateCredentialsInWorker: Scalars["Boolean"]["output"];
};

/**
 * GCP integration - with that set up, a Stack gets its dedicated GCP service
 * account and each run and tasks automatically gets its temporary credentials.
 */
export type StackIntegrationGcp = {
  __typename?: "StackIntegrationGcp";
  /**
   * Is this integration activated. If not, serviceAccountEmail and scopes are
   * expected to be empty.
   */
  activated: Scalars["Boolean"]["output"];
  /**
   * Email of the GCP service account created for this stack. Runs and tasks will
   * get temporary access token for this account.
   */
  serviceAccountEmail: Maybe<Scalars["String"]["output"]>;
  /**
   * List of scopes to request when generating temporary access token for the
   * service account. It's empty when the integration is not activated.
   */
  tokenScopes: Array<Scalars["String"]["output"]>;
};

/**
 * Slack integration - with that set up, a Stack or Module can send notifications
 * about various events to a number of Slack channels.
 */
export type StackIntegrationSlack = {
  __typename?: "StackIntegrationSlack";
  /**
   * Is this integration activated. If not, channelNames are expected to be
   * empty.
   */
  activated: Scalars["Boolean"]["output"];
  /**
   * List of names of Slack channels which are subscribed to events in this
   * Module or Stack.
   */
  channelNames: Array<Scalars["String"]["output"]>;
};

/** StackIntegrations represents external integrations for an individual Stack. */
export type StackIntegrations = {
  __typename?: "StackIntegrations";
  /** AWS integration. */
  aws: StackIntegrationAws;
  /** AWS integrations. */
  awsV2: Array<StackAwsIntegrationAttachment>;
  /** Azure integrations. */
  azure: Array<StackAzureIntegrationAttachment>;
  /** Drift detection configuration for the Stack, null if not enabled. */
  driftDetection: Maybe<DriftDetectionIntegration>;
  /**
   * GCP integration.
   * @deprecated Use OIDC integration instead.
   */
  gcp: StackIntegrationGcp;
  /** Slack integration. */
  slack: StackIntegrationSlack;
  /** Details of a single webhook integration. */
  webhook: Maybe<WebhooksIntegration>;
  /** List of webhooks integrations. */
  webhooks: Array<WebhooksIntegration>;
};

/** StackIntegrations represents external integrations for an individual Stack. */
export type StackIntegrationsWebhookArgs = {
  id: Scalars["ID"]["input"];
};

/** StackOutput represents a single stack output field. */
export type StackOutput = {
  __typename?: "StackOutput";
  /** Brief explanation of output's purpose or value. */
  description: Maybe<Scalars["String"]["output"]>;
  /** ID (name) of the output. */
  id: Scalars["ID"]["output"];
  /**
   * Indicates whether the output is sensitive. For sensitive outputs, we do not
   * have the value, so we cannot display it.
   */
  sensitive: Scalars["Boolean"]["output"];
  /**
   * String representation of the value of the output. If the output is sensitive,
   * this field is empty.
   */
  value: Maybe<Scalars["String"]["output"]>;
};

/** StackPolicyAttachment is the stack policy attachment viewed from the Stack. */
export type StackPolicyAttachment = {
  __typename?: "StackPolicyAttachment";
  /**
   * List of all the policy evaluation records associated with this policy
   * attachment.
   */
  evaluationRecords: Array<PolicyEvaluationRecord>;
  /** Returns a single policy evaluation sample */
  evaluationSample: Maybe<PolicyEvaluationSample>;
  /** Globally unique Policy attachment identifier. */
  id: Scalars["ID"]["output"];
  /** True if the policy is autoattached. */
  isAutoattached: Scalars["Boolean"]["output"];
  /** Body of the attached Policy. */
  policyBody: Scalars["String"]["output"];
  /** ID of the attached Policy. */
  policyId: Scalars["ID"]["output"];
  /** Labels of the attached Policy. */
  policyLabels: Array<Scalars["String"]["output"]>;
  /** Name of the attached Policy. */
  policyName: Scalars["String"]["output"];
  /** Type of the attached Policy. */
  policyType: PolicyType;
};

/** StackPolicyAttachment is the stack policy attachment viewed from the Stack. */
export type StackPolicyAttachmentEvaluationSampleArgs = {
  key: Scalars["String"]["input"];
};

/**
 * StackState represents a discrete state of a Stack, which is also the state of
 * its most currently running Run.
 */
export enum StackState {
  /** The current tracked run is now applying. */
  Applying = "APPLYING",
  /** The current tracked run has been confirmed by the user. */
  Confirmed = "CONFIRMED",
  /** The current destroy job is now deleting all managed resources. */
  Destroying = "DESTROYING",
  /** The last tracked run has been discarded by the user. */
  Discarded = "DISCARDED",
  /** The last tracked run has failed. */
  Failed = "FAILED",
  /** The last tracked run has finished successfully. */
  Finished = "FINISHED",
  /** The current tracked run is now initializing. */
  Initializing = "INITIALIZING",
  /** No tracked run has been started yet, so the stack has no meaningful state. */
  None = "NONE",
  /** The current tracked run is now planning. */
  Planning = "PLANNING",
  /** The current tracked run is now preparing for execution. */
  Preparing = "PREPARING",
  /** The current tracked run is now preparing to apply the change. */
  PreparingApply = "PREPARING_APPLY",
  /** The current tracked run is being prepared to be replanned. */
  PreparingReplan = "PREPARING_REPLAN",
  /** The current tracked run is pending a replan. */
  ReplanRequested = "REPLAN_REQUESTED",
  /** The last tracked run has been stopped. */
  Stopped = "STOPPED",
  /** The current tracked run is awaiting human confirmation. */
  Unconfirmed = "UNCONFIRMED",
}

export type StackTile = {
  __typename?: "StackTile";
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
  spaceTile: SpaceTile;
  state: StackState;
};

export type StackTileWithResources = {
  __typename?: "StackTileWithResources";
  resourcesCount: Scalars["Int"]["output"];
  stackTile: StackTile;
};

export enum StackVendor {
  Ansible = "Ansible",
  CloudFormation = "CloudFormation",
  Kubernetes = "Kubernetes",
  OpenTofu = "OpenTofu",
  Pulumi = "Pulumi",
  Terraform = "Terraform",
  Terragrunt = "Terragrunt",
}

/**
 * StateUploadUrl represents a pre-signed URL that can be used to upload an
 * existing state file.
 */
export type StateUploadUrl = {
  __typename?: "StateUploadUrl";
  /** Temporary object ID to be passed when creating the Stack. */
  objectId: Scalars["String"]["output"];
  /** HTTP headers that should be sent with the upload request. */
  uploadHeaders: Maybe<StringMap>;
  /** Pre-signed URL to use to upload the file. */
  url: Scalars["String"]["output"];
};

export type StringMap = {
  __typename?: "StringMap";
  entries: Array<KeyValuePairType>;
};

export type StringMapInput = {
  entries: Array<KeyValuePair>;
};

/**
 * StripeSubscriptionStatus represents all states of a Stripe subscription.
 * https://docs.stripe.com/api/subscriptions/object#subscription_object-status
 * the convention of casing is kept to match the Stripe API.
 */
export enum StripeSubscriptionStatus {
  Active = "active",
  Cancelled = "cancelled",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  Unknown = "unknown",
  Unpaid = "unpaid",
}

export type SubscriptionInput = {
  /** Address visible on invoice */
  address: Scalars["String"]["input"];
  /** City visible on invoice */
  city: Scalars["String"]["input"];
  /** Company name */
  companyName: InputMaybe<Scalars["String"]["input"]>;
  /** ISO-3166-1 alpha-2 code of the country */
  country: Scalars["String"]["input"];
  /** Billing email */
  email: Scalars["String"]["input"];
  /** Full name of the representative */
  fullName: Scalars["String"]["input"];
  /** State name (US-only) */
  state: InputMaybe<Scalars["String"]["input"]>;
  /** Zip code */
  zipCode: Scalars["String"]["input"];
};

export type SubscriptionUpdateV2Input = {
  /** addons that the subscription should have (can be ignored because it has not implemented yet). */
  addons: InputMaybe<Array<BillingAddonType>>;
  /** billing cycle interval the subscription should be changed to. */
  interval: InputMaybe<BillingCycleInterval>;
  /** billing tier the subscription should be changed to. */
  tier: BillingTier;
};

export enum SummaryProcessingStatus {
  Failed = "Failed",
  InProgress = "InProgress",
  Success = "Success",
  Unknown = "Unknown",
}

export type TermsAndConditionsVersion = {
  __typename?: "TermsAndConditionsVersion";
  content: Scalars["String"]["output"];
  version: Scalars["Int"]["output"];
};

/** Possible Terraform entity types. */
export type TerraformEntity = TerraformModule | TerraformOutput | TerraformResource;

/** Metadata for a Terraform module. */
export type TerraformModule = {
  __typename?: "TerraformModule";
  /**
   * This field exists solely for the purpose of adhering to the GraphQL spec,
   * which requires presence of at least one field in a type.
   */
  phantom: Scalars["Boolean"]["output"];
};

/** Metadata for a Terraform output. */
export type TerraformOutput = {
  __typename?: "TerraformOutput";
  /** Hash of the value, always available. */
  hash: Scalars["String"]["output"];
  /** If the output is marked as sensitive. */
  sensitive: Scalars["Boolean"]["output"];
  /** Value of the output. Only available if it's not marked as sensitive, we don't store it otherwise. */
  value: Maybe<Scalars["String"]["output"]>;
};

/**
 * TerraformProvider represents a Terraform provider managed using Spacelift's
 * Terraform provider registry.
 */
export type TerraformProvider = {
  __typename?: "TerraformProvider";
  /** Creation timestamp of the provider. */
  createdAt: Scalars["Int"]["output"];
  /** Whether the provider has just been deleted. */
  deleted: Scalars["Boolean"]["output"];
  /**
   * Human-readable description of the provider, if set by the user. Supports
   * Markdown.
   */
  description: Maybe<Scalars["String"]["output"]>;
  /** Immutable ID of the provider, unique within the scope of a single account. */
  id: Scalars["ID"]["output"];
  /** Set of labels attached to the provider. */
  labels: Array<Scalars["String"]["output"]>;
  /** The semver number of the last version of the provider that was published. */
  latestVersionNumber: Maybe<Scalars["String"]["output"]>;
  /** Whether the provider can be consumed outside of this account. */
  public: Scalars["Boolean"]["output"];
  /** ID of the space this provider belongs to. */
  space: Scalars["ID"]["output"];
  /** Details of the space this provider belongs to. */
  spaceDetails: Space;
  /** Last update timestamp of the provider. */
  updatedAt: Scalars["Int"]["output"];
  /** Get a particular version by its semver number. */
  version: Maybe<TerraformProviderVersion>;
  /** List of versions of the provider. */
  versions: Array<TerraformProviderVersion>;
};

/**
 * TerraformProvider represents a Terraform provider managed using Spacelift's
 * Terraform provider registry.
 */
export type TerraformProviderVersionArgs = {
  number: Scalars["String"]["input"];
};

/** Platform supported by Spacelift. */
export type TerraformProviderPlatform = {
  __typename?: "TerraformProviderPlatform";
  /** Architecture of the platform. */
  architecture: Scalars["String"]["output"];
  /** Operating system of the platform. */
  os: Scalars["String"]["output"];
};

/** A single version of a managed Terraform provider. */
export type TerraformProviderVersion = {
  __typename?: "TerraformProviderVersion";
  /** Temporary presigned URL for downloading the artifact. */
  artifactDownloadUrl: Scalars["String"]["output"];
  /** List of artifacts associated with this provider version. */
  artifacts: Array<TerraformProviderVersionArtifact>;
  /** Creation timestamp of the provider version. */
  createdAt: Scalars["Int"]["output"];
  /** Indicates whether the version is deleted. */
  deleted: Scalars["Boolean"]["output"];
  /**
   * Free-form, human-readable description of the provider version. Supports
   * Markdown. Can be used for info like release notes, changelog, warnings or
   * deprecations.
   */
  description: Maybe<Scalars["String"]["output"]>;
  /** Immutable, globally unique ID of the provider version. */
  id: Scalars["ID"]["output"];
  /** Semantically versioned number. */
  number: Scalars["String"]["output"];
  /** List of platforms for which the provider was built. */
  platforms: Array<TerraformProviderPlatform>;
  /**
   * List of Terraform plugin protocol versions supported by the provider
   * binaries.
   */
  protocolVersions: Array<Scalars["String"]["output"]>;
  /** ID of the GPG key used to sign provider binaries. */
  signingKeyId: Scalars["ID"]["output"];
  /** Current status of the Terraform provider version. */
  status: TerraformProviderVersionStatus;
  /** Last update timestamp of the provider version. */
  updatedAt: Scalars["Int"]["output"];
};

/** A single version of a managed Terraform provider. */
export type TerraformProviderVersionArtifactDownloadUrlArgs = {
  filename: Scalars["String"]["input"];
};

/** A single artifact associated with a Terraform provider version. */
export type TerraformProviderVersionArtifact = {
  __typename?: "TerraformProviderVersionArtifact";
  /** Name of the artifact. */
  filename: Scalars["String"]["output"];
  /** Artifact size in bytes. */
  sizeBytes: Scalars["Int"]["output"];
};

export type TerraformProviderVersionInput = {
  /** Semantically versioned number, unique for this provider. */
  number: Scalars["String"]["input"];
  /**
   * List of Terraform plugin protocol versions supported by the provider
   * binaries. These must correspond to whatever is supported by Terraform, and
   * Spacelift does not validate these; Terraform will. You can learn more about
   * Terraform plugin protocol versions at https://developer.hashicorp.com/terraform/plugin/how-terraform-works#terraform-plugin-protocol
   */
  protocolVersions: Array<Scalars["String"]["input"]>;
  /** Hex-encoded SHA 256 checksum of the checksums file. */
  shaSumsFileSHA: Scalars["String"]["input"];
  /** Hex-encoded SHA 256 checksum of the checksums signature file. */
  signatureFileSHA: Scalars["String"]["input"];
  /**
   * ID of the GPG key used to sign the provider binaries. This key must be
   * registered with the Spacelift account, and may not be revoked.
   */
  signingKeyId: Scalars["ID"]["input"];
};

export type TerraformProviderVersionPlatformInput = {
  /**
   * CPU architecture for which the provider binary is built. Note that Spacelift
   * does not validate the correctness of the reported architecture; Terraform
   * will do it.
   */
  architecture: Scalars["String"]["input"];
  /** Hex-encoded SHA 256 checksum of the zip archive. */
  archiveChecksum: Scalars["String"]["input"];
  /** Hex-encoded SHA 256 checksum of the archived binary. */
  binaryChecksum: Scalars["String"]["input"];
  /**
   * Operating system for which the provider binary is built. Note that Spacelift
   * does not validate the correctness of the reported operating system;
   * Terraform will do it.
   */
  os: Scalars["String"]["input"];
};

export type TerraformProviderVersionRegisterPlatformV2Output = {
  __typename?: "TerraformProviderVersionRegisterPlatformV2Output";
  uploadHeaders: Maybe<StringMap>;
  uploadUrl: Scalars["String"]["output"];
};

/** Current status of the Terraform provider version. */
export enum TerraformProviderVersionStatus {
  /** The version currently being built. */
  Draft = "DRAFT",
  /** The version is published, and ready to be used. */
  Published = "PUBLISHED",
  /** The version has been revoked and can no longer be used. */
  Revoked = "REVOKED",
}

/** Metadata for a Terraform resource. */
export type TerraformResource = {
  __typename?: "TerraformResource";
  /** Address in module. */
  address: Scalars["String"]["output"];
  /** Mode of resource. */
  mode: Scalars["String"]["output"];
  /** Module the resource is in. */
  module: Scalars["String"]["output"];
  /** Provider the resource stems from. */
  provider: Scalars["String"]["output"];
  /** Taint status. */
  tainted: Scalars["Boolean"]["output"];
  /** Property values. As a JSON-encoded string. */
  values: Scalars["String"]["output"];
};

export type TerraformRuntimeConfig = {
  __typename?: "TerraformRuntimeConfig";
  /**
   * The version of the tool used (e.g. OpenTofu or Terraform)
   * If the tool is CUSTOM, it's not defined.
   */
  version: Maybe<Scalars["String"]["output"]>;
  /** Defines the tool that will be used to execute the workflow. */
  workflowTool: TerraformWorkflowTool;
};

export enum TerraformWorkflowTool {
  /** A custom workflow tool. */
  Custom = "CUSTOM",
  /** OpenTofu. */
  OpenTofu = "OPEN_TOFU",
  /** The open source licensed version of Terraform. */
  TerraformFoss = "TERRAFORM_FOSS",
}

export type TerragruntRuntimeConfig = {
  __typename?: "TerragruntRuntimeConfig";
  /** The version of the tool used by Terragrunt (e.g. OpenTofu or Terraform). */
  terraformVersion: Maybe<Scalars["String"]["output"]>;
  /** The Terragrunt version used. */
  terragruntVersion: Scalars["String"]["output"];
  /** The underlying tool executed by Terragrunt for the run. */
  tool: TerragruntTool;
  /** Indicates whether the run used Terragrunt run-all. */
  useRunAll: Scalars["Boolean"]["output"];
  /** Indicates whether smart-sanitization was enabled for the run. */
  useSmartSanitization: Scalars["Boolean"]["output"];
};

/** TerragruntTool defines the underlying tool run by Terragrunt. */
export enum TerragruntTool {
  /**
   * Indicates that no tool will automatically be downloaded or configured, and that users are
   * reponsible for manually provisioning the tool themselves, for example via a custom runner
   * image or a pre-init hook.
   */
  ManuallyProvisioned = "MANUALLY_PROVISIONED",
  /** OpenTofu. */
  OpenTofu = "OPEN_TOFU",
  /** The open source licensed version of Terraform. */
  TerraformFoss = "TERRAFORM_FOSS",
}

export type Usage = {
  __typename?: "Usage";
  /** Number of minutes that can be used within the billing period. */
  allowedMinutes: Scalars["Int"]["output"];
  /** Number of users who can log in within the billing period. */
  allowedSeats: Scalars["Int"]["output"];
  /** The end date of the current billing period. */
  billingPeriodEnd: Maybe<Scalars["Int"]["output"]>;
  /** The start date of the current billing period. */
  billingPeriodStart: Maybe<Scalars["Int"]["output"]>;
  /** Logins of users who logged in to any of the stack */
  logins: Array<UserLogin>;
  /** The unit amount (in cents) to be charged per run-minute. */
  pricePerRunMinute: Maybe<Scalars["Int"]["output"]>;
  /** The unit amount (in cents) to be charged per seat monthly */
  pricePerSeat: Maybe<Scalars["Int"]["output"]>;
  /** The unit amount (in cents) to be charged per worker monthly */
  pricePerWorker: Maybe<Scalars["Int"]["output"]>;
  /** The unit amount (in cents) to be monthly charged for the subscription */
  subscriptionPrice: Maybe<Scalars["Int"]["output"]>;
  /** Cost forecast of the upcoming invoice. */
  upcomingInvoiceTotalAmount: Scalars["Int"]["output"];
  /** Number of minutes used within the current billing period. */
  usedMinutes: Scalars["Int"]["output"];
  /** Number of private minutes used within the current billing period. */
  usedPrivateMinutes: Scalars["Int"]["output"];
  /** Number of public minutes used within the current billing period. */
  usedPublicMinutes: Scalars["Int"]["output"];
  /** Number of users who last logged in during current billing period. */
  usedSeats: Scalars["Int"]["output"];
  /** Maximum number of concurrent self-hosted workers in the current billing period. */
  usedWorkers: Scalars["Int"]["output"];
};

/** Spacelift user. */
export type User = {
  __typename?: "User";
  /** actions that the user can perform in spaces. */
  actions: Array<SpaceActions>;
  /** Whether the user has org-wide admin privileges. */
  admin: Scalars["Boolean"]["output"];
  /** List of API keys created by the user. */
  apiKeys: Array<ApiKey>;
  /** URL of the user avatar. */
  avatarURL: Scalars["String"]["output"];
  /** Whether the access policies can be evaluated for this user. */
  cannotEvaluateAccessPolicies: Scalars["Boolean"]["output"];
  /** Email of the user, may be empty. */
  email: Scalars["String"]["output"];
  /** Username. */
  id: Scalars["ID"]["output"];
  /** Identity provider. */
  identityProvider: Scalars["String"]["output"];
  /** Indicates whether the user is the first to log in for this account. Is guaranteed to be true only for the first user to log in and before any other user has logged in. */
  isFirstUser: Scalars["Boolean"]["output"];
  /** Temporary access token as a string. */
  jwt: Scalars["String"]["output"];
  /** Full name of the GitHub user. */
  name: Scalars["String"]["output"];
  /** Returns list of the security keys for the current user. */
  securityKeys: Array<SecurityKey>;
  /** Timestamp of the first login, only populated for human users. */
  since: Maybe<Scalars["Int"]["output"]>;
  /** Team names which the user has access to. */
  teams: Array<Scalars["String"]["output"]>;
  /** Unix timestamp of token validity expiration. */
  validUntil: Scalars["Int"]["output"];
};

export enum UserGroupStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

/** UserLogin represents the last login attempt for a single GitHub user. */
export type UserLogin = {
  __typename?: "UserLogin";
  /** Last login timestamp. */
  timestamp: Scalars["Int"]["output"];
  /** GitHub username of the user. */
  username: Scalars["String"]["output"];
};

export enum UserRole {
  Admin = "ADMIN",
  Owner = "OWNER",
  User = "USER",
}

export type UserRoleBinding = {
  __typename?: "UserRoleBinding";
  id: Scalars["ID"]["output"];
  roleID: Scalars["ID"]["output"];
  spaceID: Scalars["ID"]["output"];
  userID: Scalars["ID"]["output"];
};

export type UserRoleBindingInput = {
  /** ID of the role. */
  roleID: Scalars["ID"]["input"];
  /** ID of the space. */
  spaceID: Scalars["ID"]["input"];
  /** ID of the user. */
  userID: Scalars["ID"]["input"];
};

export enum UserStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Pending = "PENDING",
  Queued = "QUEUED",
}

/** VCSAgentConnection represents a single connection from a VCS Agent. */
export type VcsAgentConnection = {
  __typename?: "VCSAgentConnection";
  /** Globally unique, immutable ID of the agent connection. */
  id: Scalars["ID"]["output"];
  /** Metadata associated with the agent connection. */
  metadata: Maybe<Scalars["String"]["output"]>;
};

export type VcsAgentPool = {
  __typename?: "VCSAgentPool";
  /** List of agent connections belonging to this VCSAgentPool. */
  agentConnections: Array<VcsAgentConnection>;
  /**
   * Config represents a configuration for the agents that must be passed to the agents.
   * Only available right after creation or reset.
   */
  config: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether the VCSAgentPool has just been deleted. */
  deleted: Scalars["Boolean"]["output"];
  /** Optional description of the VCSAgentPool, supports markdown. */
  description: Maybe<Scalars["String"]["output"]>;
  /** Globally unique, immutable ID of the VCSAgentPool. */
  id: Scalars["ID"]["output"];
  /** Mutable name of the VCSAgentPool, unique within the scope of a single account. */
  name: Scalars["String"]["output"];
};

export enum VcsCheck {
  /** a single VCS check aggregating all the stacks checks */
  Aggregated = "AGGREGATED",
  /** INDIVIDUAL and AGGREGATED checks */
  All = "ALL",
  /** a VCS check for each stack */
  Individual = "INDIVIDUAL",
}

export type VcsIntegration = {
  __typename?: "VCSIntegration";
  /** CreatedAt is unix timestamp (in seconds) at which the blueprint was created */
  createdAt: Scalars["Int"]["output"];
  /** Free-form description of the integration. */
  description: Scalars["String"]["output"];
  /** Provider-specific data is available only with admin access. */
  details: Maybe<VcsIntegrationDetails>;
  /**
   * The ID of the integration. Unique per Spacelift account and VCS type. NOTE: this
   * field will not be populated for the managed GitHub provider (`GITHUB`), but will
   * be populated for all other provider types.
   */
  id: Scalars["ID"]["output"];
  /**
   * Whether the integration is the default integration for the VCS provider type. Only
   * one default integration may exist per account and VCS type.
   */
  isDefault: Scalars["Boolean"]["output"];
  /** Any labels attached to the integration. */
  labels: Array<Scalars["String"]["output"]>;
  /** The name of the integration. */
  name: Scalars["String"]["output"];
  /** The VCS provider that this integration supports. */
  provider: VcsProvider;
  /** Search stacks/modules using this VCS integration. */
  searchUsers: SearchVcsIntegrationUsersOutput;
  /** Search stacks/modules suggestions using this VCS integration. */
  searchUsersSuggestions: SearchSuggestionsOutput;
  /**
   * The space this VCS integration belongs to. Default integrations always belong to the
   * root space.
   */
  space: Space;
  /** UpdatedAt is unix timestamp (in seconds) at which the integration was updated */
  updatedAt: Scalars["Int"]["output"];
};

export type VcsIntegrationSearchUsersArgs = {
  input: SearchInput;
};

export type VcsIntegrationSearchUsersSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

export type VcsIntegrationDetails =
  | AzureDevOpsRepoIntegration
  | BitbucketCloudIntegration
  | BitbucketDatacenterIntegration
  | GithubEnterpriseIntegration
  | GithubIntegration
  | GitlabIntegration;

/** VCSIntegrationUser represents a user (module or stacks) of the VCS integration. */
export type VcsIntegrationUser = {
  __typename?: "VCSIntegrationUser";
  /** True if the attached project is a module. */
  isModule: Scalars["Boolean"]["output"];
  /** Space details of the attached stack or module. */
  spaceDetails: Space;
  /** Slug of the attached stack or module. */
  stackId: Scalars["ID"]["output"];
  /** Name of the attached stack or module. */
  stackName: Scalars["String"]["output"];
};

/** VCSIntegrationUserConnection represents a single stack/module in a page of results for a VCS integration. */
export type VcsIntegrationUserConnection = {
  __typename?: "VCSIntegrationUserConnection";
  cursor: Scalars["String"]["output"];
  node: VcsIntegrationUser;
};

export type VcsIntegrationsConnection = {
  __typename?: "VCSIntegrationsConnection";
  cursor: Scalars["String"]["output"];
  node: VcsIntegration;
};

/** VCSProvider is the type of VCS provider. */
export enum VcsProvider {
  /** Azure DevOps. */
  AzureDevops = "AZURE_DEVOPS",
  /** Bitbucket Cloud (bitbucket.org). */
  BitbucketCloud = "BITBUCKET_CLOUD",
  /** Self-hosted Bitbucket Datacenter. */
  BitbucketDatacenter = "BITBUCKET_DATACENTER",
  /** Git link VCS provider. */
  Git = "GIT",
  /** Managed GitHub (github.com). */
  Github = "GITHUB",
  /**
   * GitHub Enterprise - either self-hosted or as an extra organization on
   * github.com.
   */
  GithubEnterprise = "GITHUB_ENTERPRISE",
  /** GitLab - either self-hosted or managed (gitlab.com). */
  Gitlab = "GITLAB",
  /** Spacelift-managed virtual VCS provider used for onboarding new users. */
  Showcase = "SHOWCASE",
}

/** Version represents a single version of the Module. */
export type Version = {
  __typename?: "Version";
  /** Commit which triggered this version. */
  commit: Commit;
  /** Number of stacks, regardless of account, using this module version. */
  consumerCount: Scalars["Int"]["output"];
  /** Stacks in your account which use this module version. */
  consumers: Array<Stack>;
  /** CreatedAt is unix timestamp (in seconds) at which the version was created */
  createdAt: Scalars["Int"]["output"];
  /**
   * Link to the source code to download the version. Will only work for ACTIVE
   * versions.
   */
  downloadLink: Maybe<Scalars["String"]["output"]>;
  /** Unique ID of the Version. */
  id: Scalars["ID"]["output"];
  /**
   * String representation of the metadata generated by parsing the module
   * source code.
   */
  metadata: Maybe<ModuleRepositoryMetadata>;
  /**
   * Run parsing the module source code to generate the metadata. This will
   * only be populated if we're parsing the module source on the worker.
   */
  metadataParsingRun: Maybe<Run>;
  /** Module to which this version belongs. */
  module: VersionModuleInfo;
  /** User-provided notes associated with the version. */
  notes: Maybe<Scalars["String"]["output"]>;
  /**
   * Number of the version conforming to the semantic versioning scheme
   * (major, minor, patch).
   */
  number: Scalars["String"]["output"];
  /** List of runs */
  runs: Array<Run>;
  /** Link to the source code from which the version was built. */
  sourceURL: Scalars["String"]["output"];
  /** Current state of the Version. */
  state: VersionState;
  /** The user who manually triggered this Version, if any. */
  triggeredBy: Maybe<Scalars["String"]["output"]>;
  /** Terraform versions tested for this version. */
  versionCount: Scalars["Int"]["output"];
  /** Note associated with the version when it was marked as "bad", if it has */
  yankNote: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether the version has been marked as "bad". */
  yanked: Scalars["Boolean"]["output"];
  /** Login of the user who marked the version as "bad", if it has been marked */
  yankedBy: Maybe<Scalars["String"]["output"]>;
};

/** Contains information about the module that a version was created from. */
export type VersionModuleInfo = {
  __typename?: "VersionModuleInfo";
  /** The module's ID. */
  id: Scalars["ID"]["output"];
  /** Provider-independent name of the Terraform module. */
  name: Scalars["String"]["output"];
  /** The name of the module owner. */
  owner: Scalars["String"]["output"];
};

/** VersionState represents the current state of the Version. */
export enum VersionState {
  /** The version is active and can be used. */
  Active = "ACTIVE",
  /** Testing the version failed. */
  Failed = "FAILED",
  /** The version is currently being tested. */
  Pending = "PENDING",
}

export type VersionsConnection = {
  __typename?: "VersionsConnection";
  cursor: Scalars["String"]["output"];
  node: Version;
};

/** WebhookDeliveries represents a paginated list of webhook deliveries. */
export type WebhookDeliveries = {
  __typename?: "WebhookDeliveries";
  /**
   * Token to use to fetch the next batch of webhook deliveries. If the token is
   * empty, the end of the list has been reached.
   */
  nextToken: Maybe<Scalars["String"]["output"]>;
  /** Next batch of webhook deliveries. */
  nodes: Array<WebhookDelivery>;
};

/** WebhookDelivery represents a single webhook or audit trail delivery. */
export type WebhookDelivery = {
  __typename?: "WebhookDelivery";
  /** Unique ID of the delivery. */
  id: Scalars["ID"]["output"];
  /** HTTP status of the delivery, 0 if the delivery failed. */
  status: Scalars["Int"]["output"];
  /** Unix timestamp of the delivery. */
  timestamp: Scalars["Int"]["output"];
};

/** WebhookDeliveryDetails represents extended details for a single webhook delivery. */
export type WebhookDeliveryDetails = {
  __typename?: "WebhookDeliveryDetails";
  /** Body of the request. */
  requestBody: Scalars["String"]["output"];
  /** A list of headers from the request. */
  requestHeaders: Array<WebhookDeliveryHeader>;
  /** Body of the response. */
  responseBody: Scalars["String"]["output"];
  /**
   * Response error, if any. Response error means that the payload was not
   * delivered.
   */
  responseError: Maybe<Scalars["String"]["output"]>;
  /** A list of headers from the response. */
  responseHeaders: Array<WebhookDeliveryHeader>;
};

/**
 * WebhookDeliveryHeader represents a single header (request or response) in a
 * webhook delivery.
 */
export type WebhookDeliveryHeader = {
  __typename?: "WebhookDeliveryHeader";
  /** Name of the header. */
  name: Scalars["String"]["output"];
  /** Values of the header. */
  values: Array<Scalars["String"]["output"]>;
};

/** Webhooks integration info to send webhooks for stack */
export type WebhooksIntegration = {
  __typename?: "WebhooksIntegration";
  /** Deleted boolean */
  deleted: Scalars["Boolean"]["output"];
  /**
   * Paginated list of deliveries from this integration. The optional opaque
   * token is used for pagination and should be copied from the previous
   * WebhookDeliveries response.
   */
  deliveries: Maybe<WebhookDeliveries>;
  /** Extended details of a single delivery. */
  deliveryDetails: Maybe<WebhookDeliveryDetails>;
  /** Enable or disable sending webhooks */
  enabled: Scalars["Boolean"]["output"];
  /** User endpoint to send webhooks */
  endpoint: Scalars["String"]["output"];
  /** WebhooksIntegration ID */
  id: Scalars["ID"]["output"];
  /**
   * Secret to count SHA1
   * @deprecated This property is no longer used.
   */
  secret: Scalars["String"]["output"];
};

/** Webhooks integration info to send webhooks for stack */
export type WebhooksIntegrationDeliveriesArgs = {
  token: InputMaybe<Scalars["String"]["input"]>;
};

/** Webhooks integration info to send webhooks for stack */
export type WebhooksIntegrationDeliveryDetailsArgs = {
  key: Scalars["String"]["input"];
};

export type WebhooksIntegrationInput = {
  /** Enable or disable sending webhooks */
  enabled: Scalars["Boolean"]["input"];
  /** User endpoint to send webhooks */
  endpoint: Scalars["String"]["input"];
  /** Secret to count SHA1 */
  secret: InputMaybe<Scalars["String"]["input"]>;
};

/** Worker represents a single worker in the WorkerPool. */
export type Worker = {
  __typename?: "Worker";
  /** Busy indicates whether the Worker is currently processing a Run. */
  busy: Scalars["Boolean"]["output"];
  /** Unix timestamp representing the worker creation. */
  createdAt: Scalars["Int"]["output"];
  /** Drained indicates that the worker is not accepting new work. */
  drained: Scalars["Boolean"]["output"];
  /** Globally unique, immutable ID of the Worker. */
  id: Scalars["ID"]["output"];
  /** Metadata associated with the worker. */
  metadata: Maybe<Scalars["String"]["output"]>;
  /** Status indicates the status of the worker */
  status: WorkerStatus;
};

/** WorkerConnection represents a single worker in a page of results. */
export type WorkerConnection = {
  __typename?: "WorkerConnection";
  cursor: Scalars["String"]["output"];
  node: Worker;
};

/** WorkerPool represents a single WorkerPool. */
export type WorkerPool = Notifiable & {
  __typename?: "WorkerPool";
  /**
   * Number of runs currently being processed by this WorkerPool.
   * @deprecated Use busyWorkers fields instead.
   */
  activeRuns: Scalars["Int"]["output"];
  /** Number of currently busy workers in this worker pool. */
  busyWorkers: Scalars["Int"]["output"];
  /** Config represents a configuration for the workers that must be passed to the workers. */
  config: Scalars["String"]["output"];
  /** CreatedAt is unix timestamp (in seconds) at which the worker pool was created. */
  createdAt: Scalars["Int"]["output"];
  /** Indicates whether the WorkerPool has just been deleted. */
  deleted: Scalars["Boolean"]["output"];
  /** Optional description of the WorkerPool, supports markdown. */
  description: Maybe<Scalars["String"]["output"]>;
  /** Globally unique, immutable ID of the WorkerPool. */
  id: Scalars["ID"]["output"];
  /** Labels applied to a WorkerPool. */
  labels: Array<Scalars["String"]["output"]>;
  /** Mutable name of the WorkerPool, unique within the scope of a single account. */
  name: Scalars["String"]["output"];
  /** Number of the new worker pool notifications. */
  notificationCount: Scalars["Int"]["output"];
  /** Number of runs ready for processing by this WorkerPool. */
  pendingRuns: Scalars["Int"]["output"];
  /**
   * Count of queued runs on the public worker pool workers for this account.
   * @deprecated Use 'schedulableRunsCount' instead.
   */
  queuedRunsCount: Scalars["Int"]["output"];
  /** Count of schedulable runs on the worker pool workers for this account. */
  schedulableRunsCount: Scalars["Int"]["output"];
  /** Search Runs for this WorkerPool. */
  searchSchedulableRuns: Maybe<SearchSchedulableRunsOutput>;
  /** Search Runs Suggestions for this WorkerPool. */
  searchSchedulableRunsSuggestions: Maybe<SearchSuggestionsOutput>;
  /** Search stacks/modules using this WorkerPool. */
  searchUsers: SearchWorkerPoolUsersOutput;
  /** Search stacks/modules suggestions using this WorkerPool. */
  searchUsersSuggestions: SearchSuggestionsOutput;
  /** Search workers belonging to this WorkerPool. */
  searchWorkers: Maybe<SearchWorkersOutput>;
  /** Search workers suggestions belonging to this WorkerPool. */
  searchWorkersSuggestions: Maybe<SearchSuggestionsOutput>;
  /** ID of the space this WorkerPool belongs to. */
  space: Scalars["ID"]["output"];
  /** Details of the space this WorkerPool belongs to. */
  spaceDetails: Space;
  /** UpdatedAt is unix timestamp (in seconds) at which the worker pool was updated. */
  updatedAt: Scalars["Int"]["output"];
  /**
   * List of users (modules or stacks) of this WorkerPool.
   * It will return an empty result for the public worker pool.
   */
  users: Array<WorkerPoolUser>;
  /** Count of users on the public worker pool workers for this account. */
  usersCount: Scalars["Int"]["output"];
  /** Compatibility */
  workerPool: WorkerPool;
  /** List of workers belonging to this WorkerPool. */
  workers: Array<Worker>;
  /** Count of workers. */
  workersCount: Scalars["Int"]["output"];
};

/** WorkerPool represents a single WorkerPool. */
export type WorkerPoolSearchSchedulableRunsArgs = {
  input: SearchInput;
};

/** WorkerPool represents a single WorkerPool. */
export type WorkerPoolSearchSchedulableRunsSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

/** WorkerPool represents a single WorkerPool. */
export type WorkerPoolSearchUsersArgs = {
  input: SearchInput;
};

/** WorkerPool represents a single WorkerPool. */
export type WorkerPoolSearchUsersSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

/** WorkerPool represents a single WorkerPool. */
export type WorkerPoolSearchWorkersArgs = {
  input: SearchInput;
};

/** WorkerPool represents a single WorkerPool. */
export type WorkerPoolSearchWorkersSuggestionsArgs = {
  input: SearchSuggestionsInput;
};

/** WorkerPoolConnection represents a single worker pool in a page of results. */
export type WorkerPoolConnection = {
  __typename?: "WorkerPoolConnection";
  cursor: Scalars["String"]["output"];
  node: WorkerPool;
};

/** WorkerPoolUser represents a user (module or stacks) of the worker pool. */
export type WorkerPoolUser = {
  __typename?: "WorkerPoolUser";
  /** True if the attached project is a module. */
  isModule: Scalars["Boolean"]["output"];
  /**
   * Space details of the attached stack or module.
   * It will return a useless result for the public worker pool.
   */
  spaceDetails: Space;
  /** Slug of the attached stack or module. */
  stackId: Scalars["ID"]["output"];
  /** Name of the attached stack or module. */
  stackName: Scalars["String"]["output"];
};

/** WorkerPoolUserConnection represents a single stack/module in a page of results for a worker pool. */
export type WorkerPoolUserConnection = {
  __typename?: "WorkerPoolUserConnection";
  cursor: Scalars["String"]["output"];
  node: WorkerPoolUser;
};

export enum WorkerStatus {
  /** Worker is currently processing a worker. */
  Busy = "BUSY",
  /** Worker is not accepting new work. */
  Drained = "DRAINED",
  Idle = "IDLE",
}

export type _Service = {
  __typename?: "_Service";
  sdl: Scalars["String"]["output"];
};
