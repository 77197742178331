import { memo } from "react";

import TableBody from "ds/components/Table/Body";
import { Blueprint } from "types/generated";

import BlueprintListItem from "../Row";

type BlueprintsTableBodyProps = {
  blueprints: Array<Blueprint>;
};

const BlueprintsTableBody = ({ blueprints }: BlueprintsTableBodyProps) => {
  return (
    <TableBody>
      {blueprints.map((blueprint) => (
        <BlueprintListItem key={blueprint.id} blueprint={blueprint} />
      ))}
    </TableBody>
  );
};

export default memo(BlueprintsTableBody);
