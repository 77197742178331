import { memo } from "react";

import Timestamp from "components/Timestamp";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import { type RenderGroupRowCellProps } from ".";

type IacManagementTableGroupRowCellUpdatedAtProps = Pick<RenderGroupRowCellProps, "row">;

const IacManagementTableGroupRowCellUpdatedAt = memo(
  ({ row }: IacManagementTableGroupRowCellUpdatedAtProps) => {
    return (
      <IacManagementTableCell>
        <Timestamp timestamp={row.updatedAt} />
      </IacManagementTableCell>
    );
  }
);

IacManagementTableGroupRowCellUpdatedAt.displayName = "IacManagementTableGroupRowCellUpdatedAt";

export default IacManagementTableGroupRowCellUpdatedAt;
