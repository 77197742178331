import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import FlashContext from "components/FlashMessages/FlashContext";
import { GitBranch, Space } from "components/icons/generated";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import MetaInfoProjectRoot from "components/MetaInfoList/ProjectRoot";
import ModuleVersionStateBadge from "components/ModuleVersionStateBadge";
import { projectRootTooltipContent } from "components/TooltipSnippets";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { VCS_PROVIDERS_ICONS } from "constants/provider_icons";
import Button from "ds/components/Button";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Tab from "ds/components/Tab";
import useTypedContext from "hooks/useTypedContext";
import useSetModuleFavorite from "shared/Module/useSetModuleFavorite";
import { Module } from "types/generated";
import { generateBranchUrl, generateRepoUrl, generateRepoUrlText } from "utils/urls";
import { hasSpaceManageAccess } from "utils/user";

import DisableButton from "../../Versions/Components/DisableButton";
import EnableButton from "../../Versions/Components/EnableButton";
import YankedBadge from "../../Versions/Components/YankedBadge";

type ModuleHeaderProps = {
  module: Module;
  onDetailsButtonClick: () => void;
};

const refetchQueries = ["GetModule"];

const ModuleHeader = ({ module, onDetailsButtonClick }: ModuleHeaderProps) => {
  const {
    id,
    apiHost,
    namespace,
    repository,
    provider,
    branch,
    administrative,
    latest,
    spaceDetails,
    canWrite,
    isDisabled,
    current,
    repositoryURL,
    projectRoot,
  } = module;

  const { reportSuccess } = useTypedContext(FlashContext);

  const moduleLatestVersionUrl = latest ? `../version/latest` : undefined;

  const canManageModule = canWrite || hasSpaceManageAccess(module.spaceDetails.accessLevel);

  const [setModuleFavorite, { loading: setFavoriteLoading }] = useSetModuleFavorite({
    refetchQueries,
  });

  const handleStarModule = () => {
    setModuleFavorite({ id: module.id, star: !module.starred }, (data) => {
      if (typeof data?.starSet?.starred === "boolean") {
        reportSuccess({
          message: data.starSet.starred
            ? "Module added to favorites"
            : "Module removed from favorites",
        });
      }
    });
  };

  return (
    <ViewHeader>
      <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
        <ViewHeaderWrapper align="center">
          <ViewHeaderTitle>{id}</ViewHeaderTitle>
          <ModuleVersionStateBadge state={current?.state} number={current?.number} />
          {current?.yanked && <YankedBadge notes={current.yankNote} />}
        </ViewHeaderWrapper>

        <ViewHeaderWrapper>
          <Button variant="secondary" onPress={onDetailsButtonClick}>
            Details
          </Button>

          <DropdownMenuEllipsis tooltip="Module actions" dotsSize="medium">
            <CopyFieldDropdownMenuItem title="Copy ID" value={id} />
            {canManageModule && !isDisabled && (
              <DisableButton moduleId={id} refetchQueries={refetchQueries} />
            )}
            {canManageModule && isDisabled && (
              <EnableButton moduleId={id} refetchQueries={refetchQueries} />
            )}
            <DropdownMenuItem
              onAction={handleStarModule}
              loading={setFavoriteLoading}
              isDisabled={setFavoriteLoading}
            >
              {module.starred ? "Remove from favorites" : "Add to favorites"}
            </DropdownMenuItem>
            <DropdownMenuItem href={`/module/${module.id}/settings`}>Settings</DropdownMenuItem>
            <DropdownMenuItem danger href={`/module/${module.id}/settings/deletion`}>
              Delete
            </DropdownMenuItem>
          </DropdownMenuEllipsis>
        </ViewHeaderWrapper>
      </ViewHeaderWrapper>

      <ViewHeaderScrollCollapse>
        <MetaInfoList>
          {spaceDetails && (
            <MetaInfoListItem
              icon={Space}
              linkText={spaceDetails.name}
              href={`/spaces/${spaceDetails.id}`}
            />
          )}
          {apiHost && (
            <MetaInfoListItem
              linkText={generateRepoUrlText({
                namespace,
                repository,
              })}
              href={generateRepoUrl({
                apiHost,
                namespace,
                repository,
                repositoryURL,
                provider,
              })}
              icon={VCS_PROVIDERS_ICONS[provider]}
              target="_blank"
            />
          )}
          {projectRoot && (
            <MetaInfoProjectRoot projectRoot={projectRoot} tooltip={projectRootTooltipContent} />
          )}
          {apiHost && (
            <MetaInfoListItem
              href={generateBranchUrl({
                apiHost,
                namespace,
                repository,
                repositoryURL,
                provider,
                branch,
              })}
              linkText={branch}
              icon={GitBranch}
              target="_blank"
            />
          )}
          {administrative && <MetaInfoListItem>Administrative</MetaInfoListItem>}
          {moduleLatestVersionUrl && (
            <MetaInfoListItem
              to={moduleLatestVersionUrl}
              linkText="see the latest stable version"
            />
          )}
        </MetaInfoList>
      </ViewHeaderScrollCollapse>

      <ViewHeaderNavigation>
        <Tab end to=".." label="Versions" />
        <Tab to="../environment" label="Environment" />
        <Tab to="../policies" label="Policies" />
        <Tab to="../contexts" label="Contexts" />
        <Tab to="../prs" label="PRs" />
        {/* TODO: enable notifications once the API is ready
          <Tab to="../notifications" label="Notifications"  />
        */}
      </ViewHeaderNavigation>
    </ViewHeader>
  );
};

export default ModuleHeader;
