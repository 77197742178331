import { useForm, FormProvider, Controller } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import isEqual from "lodash-es/isEqual";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import FormField from "ds/components/Form/Field";
import LabelsInfo from "components/LabelsInfo";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import FormFieldTags from "components/FormFields/Tags";
import Banner from "ds/components/Banner";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Box from "ds/components/Box";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { SpacesContext } from "views/Account/SpacesProvider";
import FormFieldSpace from "components/FormFields/Space";
import { getDocsUrl } from "utils/getDocsUrl";
import FormFieldTagsMagicBanner from "components/FormFields/Tags/MagicLabels";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { useFormValidations } from "hooks/useFormValidations";

import { StackCreationWizardStep, StackDetailsFormFields } from "../types";
import { StackFormContext } from "../context";
import NewStackFooter from "../Footer";
import useAsyncValidation from "./useAsyncValidation";
import Documentation from "./Documentation";
import { validateRequired, getTooltipAnalyticsProps } from "../utils";
import useErrorHandlerNewStack from "../useErrorHandlerNewStack";
import useStackCreationAnalyticsVersion from "../useStackCreationAnalyticsVersion";
import useHasEntities from "./useHasEntities";

const NewStackDetails = () => {
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const [hasUnsubmittedLabelsChanges, setHasUnsubmittedLabelsChanges] = useState(false);
  const {
    asyncValidationLoading,
    currentStep,
    updateStepData,
    formData,
    setInternalFormData,
    internalData,
  } = useTypedContext(StackFormContext);
  const { manageableSpacesSelectOptions, error } = useTypedContext(SpacesContext);

  useObserveForWarning(
    hasUnsubmittedLabelsChanges,
    <>
      You have not saved changes in <strong>labels</strong> field. Do you want to continue without
      saving them?
    </>
  );

  useErrorHandlerNewStack(error);

  const stepData = formData[StackCreationWizardStep.Details];

  const builderForm = useForm<StackDetailsFormFields>({
    defaultValues: {
      ...stepData,
    },
    mode: "onChange",
  });

  const {
    register,
    control,
    formState: { errors },
    setError,
    setValue,
    trigger,
    watch,
  } = builderForm;
  const runFormValidations = useFormValidations(builderForm);

  const newStepData = watch();
  const { validateName } = useAsyncValidation(setError, stepData.name);

  // validate name shared via link
  useEffect(() => {
    if (newStepData.name.length > 0) {
      validateName(newStepData.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entitiesLoading, hasEntities } = useHasEntities();

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (value.length > 0) {
        validateName(value);
      }

      setValue("name", value);
      trigger("name");
    },
    [setValue, trigger, validateName]
  );

  const processStepData = async () => {
    const spaceSelectOption = manageableSpacesSelectOptions.find(
      ({ value }) => value === newStepData.space
    );
    setInternalFormData({ ...internalData, spaceLabel: spaceSelectOption?.label });

    return updateStepData(currentStep, newStepData);
  };

  const isDataChanged = !isEqual(newStepData, stepData);

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Add stack details
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          Name your stack, choose the space that it will belong to and add labels for improved
          organization and filtering
        </Typography>
        <Box direction="column" margin="x-large 0 large 0">
          <FormProvider {...builderForm}>
            <Controller
              name="name"
              control={control}
              rules={{ validate: validateRequired("Name") }}
              render={({ field, fieldState }) => (
                <FormField
                  label="Name"
                  error={errors?.name?.message}
                  tooltipInfoVariant="modal"
                  {...getTooltipAnalyticsProps("Details", "Name", { version: analyticsVersion })}
                  tooltipInfo={
                    <>
                      <TooltipModalTitle>Name your stack</TooltipModalTitle>
                      <TooltipModalBody align="start">
                        Name of this stack. It should be unique within one account, and we recommend
                        that it closely represents the body of resources managed by this stack.
                        <ReadMoreDocsLink
                          docsUrl={getDocsUrl("/concepts/stack/creating-a-stack#name-your-stack")}
                        />
                      </TooltipModalBody>
                    </>
                  }
                >
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Name your stack"
                      ref={field.ref}
                      error={!!fieldState.error}
                      type="text"
                      value={field.value}
                      onChange={handleNameChange}
                      name="stackName"
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
              )}
            />

            <FormFieldSpace
              loading={entitiesLoading}
              disabled={entitiesLoading || hasEntities}
              {...getTooltipAnalyticsProps("Details", "Space", { version: analyticsVersion })}
            />

            {hasEntities && (
              <Box margin="large 0 0 0" direction="column">
                <Banner variant="info" title="Changing space is disabled">
                  Detach your manually attached policies, contexts and integrations if you want to
                  change the space.
                </Banner>
              </Box>
            )}
            <FormFieldTags
              label={
                <Box gap="small" align="center">
                  Labels
                  <Typography tag="span" variant="p-body3" color="secondary">
                    (Optional)
                  </Typography>
                  <LabelsInfo
                    analyticsPage={AnalyticsPageStack.StackNew}
                    analyticsTitle="Tooltip click"
                    analyticsProps={{
                      location: "Details",
                      name: "Labels",
                      version: analyticsVersion,
                    }}
                  />
                </Box>
              }
              tagName="label"
              name="labels"
              inputSize="regular"
              analyticsPage={AnalyticsPageStack.StackNew}
              analyticsProps={{ version: analyticsVersion }}
              onInputChange={(value) => setHasUnsubmittedLabelsChanges(!!value)}
            />

            <FormFieldTagsMagicBanner entityType="stack" margin="x-large 0 0" />

            <FormField
              label="Description"
              isOptional
              tooltipInfoVariant="modal"
              {...getTooltipAnalyticsProps("Details", "Description", { version: analyticsVersion })}
              tooltipInfo={
                <>
                  <TooltipModalTitle>Describe your stack</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    Human-friendly description of this stack - supports Markdown. This value is only
                    used to make life easier for you, the user. You can use this space to describe
                    your stack, provide useful links, or embed a cat GIF. The only limit is
                    yourself.
                    <ReadMoreDocsLink
                      docsUrl={getDocsUrl("/concepts/stack/stack-settings#name-and-description")}
                    />
                  </TooltipModalBody>
                </>
              }
            >
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Enter stack description here..."
                  {...register("description")}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </FormProvider>
        </Box>
      </FullScreenModalBody>
      <NewStackFooter
        isDataChanged={isDataChanged}
        loading={asyncValidationLoading}
        processStepData={processStepData}
        documentationLink={getDocsUrl("/concepts/stack")}
        documentationTitle="Stack"
        documentationBody={<Documentation />}
        runFormValidations={runFormValidations}
      />
    </>
  );
};

export default NewStackDetails;
