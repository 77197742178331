import Box, { BoxProps } from "ds/components/Box";

import SkeletonItem from "./Item";

type CodeEditorLoadingSkeletonProps = Omit<BoxProps, "children"> & {
  count?: number;
};

const SkeletonLoader = ({ count = 10, ...props }: CodeEditorLoadingSkeletonProps) => {
  const array = Array(count).fill(0);
  return (
    <Box direction="column" gap="large" {...props}>
      {array.map((_num, index) => (
        <SkeletonItem key={index} />
      ))}
    </Box>
  );
};

export default SkeletonLoader;
