import { useStore } from "zustand";

import useTypedContext from "hooks/useTypedContext";
import { IacManagementTableStoreContext } from "views/Account/IacManagement/TableStoreContext";

import { IacTableStoreState } from "./types";

const useIacTableStore = <T,>(selector: (state: IacTableStoreState) => T): T => {
  const store = useTypedContext(IacManagementTableStoreContext);

  return useStore(store, selector);
};

export default useIacTableStore;
