import { useParams } from "react-router-dom";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import RunNext from "views/shared/RunNext";

import { ModuleContext } from "../../Context";
import { GET_VERSION_RUN } from "./gql";

type GetVersionRunGql = {
  module: Module;
};

type VersionRunRouteParams = { runId: string; versionId: string };

const ModuleVersionRun = () => {
  const { runId, versionId } = useParams<VersionRunRouteParams>();
  const { onError } = useTypedContext(FlashContext);
  const { module } = useTypedContext(ModuleContext);

  const { data, error, loading } = usePolledQuery<GetVersionRunGql>(GET_VERSION_RUN, {
    onError,
    shouldPoll: (data) => data?.module?.run?.finished === false,
    pollingGroup: PollingIntervalGroups.SingleEntities,
    variables: { moduleId: module.id, runId, versionId },
  });

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module.id,
        link: `/module/${module.id}`,
      },
      {
        title: (data?.module?.version?.number && `Version ${data?.module?.version?.number}`) || "",
        link: `/module/${module.id}/version/${versionId}/overview/tests`,
      },
      {
        title: data?.module?.run?.title || "",
      },
    ],
    [data?.module?.run?.title, data?.module?.version?.number]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module?.run) {
    return <PageLoading />;
  }

  if (!data?.module || !data?.module?.run) {
    return <NotFoundPage />;
  }

  return <RunNext run={data.module.run} stack={module} versionId={versionId} />;
};

export default ModuleVersionRun;
