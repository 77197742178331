import { FunctionComponent } from "react";

import CardWrapper from "components/CardWrapper";
import KeyValue from "components/KeyValue";
import { Spinner } from "components/Spinner";
import { Trash } from "components/icons/generated";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import IconTile from "ds/components/IconTile";
import Typography from "ds/components/Typography";
import {
  StackAwsIntegrationAttachment,
  StackAzureIntegrationAttachment,
  StackIntegrationGcp,
} from "types/generated";
import useDetachCloudIntegration from "shared/CloudIntegration/useDetachCloudIntegration";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";

import AWSFields from "./AWSFields";
import AzureFields from "./AzureFields";
import GCPFields from "./GCPFields";
import SwitchIcon from "./SwitchIcon";

type AttachCloudDetailsProps = {
  attachedIntegration:
    | StackAwsIntegrationAttachment
    | StackAzureIntegrationAttachment
    | StackIntegrationGcp;
  attachedIntegrationType: CLOUD_INTEGRATIONS;
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: CLOUD_INTEGRATIONS;
  }>;
  className?: string;
  entityId?: string;
  refetchQueries?: string[];
  readOnly?: boolean;
  onSuccessDetach?: () => void;
};

const AttachCloudDetails = ({
  attachedIntegration,
  attachedIntegrationType,
  cloudConfig,
  className,
  entityId,
  refetchQueries,
  readOnly,
  onSuccessDetach,
}: AttachCloudDetailsProps) => {
  const { detach, detachLoading } = useDetachCloudIntegration({
    refetchQueries,
  });

  const handleDetach = () => {
    switch (attachedIntegrationType) {
      case CLOUD_INTEGRATIONS.AWS:
      case CLOUD_INTEGRATIONS.Azure:
        if ("id" in attachedIntegration) {
          detach(
            { type: attachedIntegrationType, attachmentId: attachedIntegration.id },
            onSuccessDetach
          );
        }
        break;
      case CLOUD_INTEGRATIONS.GCP:
        if (entityId) {
          detach({ type: attachedIntegrationType, entityId }, onSuccessDetach);
        }
    }
  };

  const config = cloudConfig.find(({ type }) => type === attachedIntegrationType);

  if (!config) {
    return null;
  }

  const Logo = config.logo;

  return (
    <CardWrapper variant="outlined" direction="column" className={className}>
      <Box justify="between" align="center" margin="0 0 large 0">
        <Box align="center" gap="medium">
          <IconTile icon={Logo} />
          <Typography tag="p" variant="p-t5">
            {config.name} integration
          </Typography>
        </Box>
        {!readOnly && (
          <ButtonIcon
            disabled={detachLoading}
            icon={detachLoading ? Spinner : Trash}
            onPress={handleDetach}
            variant={detachLoading ? "ghost" : "ghostDanger"}
          >
            Detach
          </ButtonIcon>
        )}
      </Box>
      {attachedIntegrationType === CLOUD_INTEGRATIONS.AWS && (
        <AWSFields integration={attachedIntegration as StackAwsIntegrationAttachment} />
      )}

      {attachedIntegrationType === CLOUD_INTEGRATIONS.Azure && (
        <AzureFields integration={attachedIntegration as StackAzureIntegrationAttachment} />
      )}

      {attachedIntegrationType === CLOUD_INTEGRATIONS.GCP && (
        <GCPFields integration={attachedIntegration as StackIntegrationGcp} />
      )}

      {"read" in attachedIntegration && "write" in attachedIntegration && (
        <>
          <KeyValue align="center" name="Read">
            <SwitchIcon checked={attachedIntegration.read} />
          </KeyValue>
          <KeyValue align="center" name="Write">
            <SwitchIcon checked={attachedIntegration.write} />
          </KeyValue>
        </>
      )}
    </CardWrapper>
  );
};

export default AttachCloudDetails;
