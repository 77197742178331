import {
  subMonths,
  startOfMonth,
  endOfMonth,
  subWeeks,
  startOfWeek,
  endOfWeek,
} from "date-fns/esm";

import { PeriodValue } from "ds/components/Datepicker/types";
enum SelfHostedUsageExportDateRange {
  LastWeek = "LastWeek",
  LastMonth = "LastMonth",
}

export const PERIODS: Record<SelfHostedUsageExportDateRange, PeriodValue> = {
  [SelfHostedUsageExportDateRange.LastWeek]: {
    label: "Last week",
    range: () => {
      const now = new Date();
      const lastWeek = subWeeks(now, 1);
      return {
        start: startOfWeek(lastWeek, { weekStartsOn: 1 }), // Start on Monday
        end: endOfWeek(lastWeek, { weekStartsOn: 1 }), // End on Sunday
      };
    },
  },
  [SelfHostedUsageExportDateRange.LastMonth]: {
    label: "Last month",
    range: () => {
      const now = new Date();
      const lastMonth = subMonths(now, 1);
      return {
        start: startOfMonth(lastMonth),
        end: endOfMonth(lastMonth),
      };
    },
  },
};

export const INITIAL_PERIOD = SelfHostedUsageExportDateRange.LastWeek;
