import useAnalytics from "hooks/useAnalytics";

import { ANALYTICS } from "./constants";

const useUsersListAnalytics = () => {
  return useAnalytics({
    page: ANALYTICS.analyticsPage,
  });
};

export default useUsersListAnalytics;
