import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import LinkButton from "ds/components/Button/LinkButton";
import { SpacesContext } from "views/Account/SpacesProvider";
import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import SearchInput from "components/SearchInput";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";

type RolesPageLayoutProps = {
  children: ReactNode;
};

const RolesPageLayout = ({ children }: RolesPageLayoutProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Organization Settings · Roles · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <Box fullWidth justify="between" align="center">
          <ViewHeaderTitle tag="h2">Roles</ViewHeaderTitle>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            {/* TODO: [AAC] filtersOrderSettingsKey and search results when BE done */}
            <SearchInput
              placeholder="Search..."
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />

            {hasEntityCreateAccess && (
              <LinkButton variant="primary" to="../new/role">
                Create role
              </LinkButton>
            )}
          </ViewHeaderWrapper>
        </Box>
      </ViewHeader>
      {children}
    </>
  );
};

export default RolesPageLayout;
