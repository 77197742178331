import { gql, TypedDocumentNode } from "@apollo/client";

import { AwsIntegration } from "types/generated";

type GetAwsIntegrationData = {
  awsIntegration: AwsIntegration;
};

export const GET_AWS_INTEGRATION: TypedDocumentNode<GetAwsIntegrationData> = gql`
  query GetAwsIntegration($id: ID!) {
    awsIntegration(id: $id) {
      id
      name
      roleArn
      durationSeconds
      generateCredentialsInWorker
      externalId
      labels
      spaceDetails {
        id
        name
        accessLevel
      }
      hasAttachedStacks
      attachedStacks {
        id
        stackId
        stackName
        isModule
      }
    }
  }
`;
