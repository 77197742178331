import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { AwsIntegration } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { TrackAnalyticsEventProperties } from "shared/Analytics";

import AwsIntegrationDeleteConfirmationContent from "./Content";
import { DELETE_AWS_INTEGRATION } from "./gql";

type DeleteConfirmationProps = {
  integration: AwsIntegration;
  trackAnalytics: (event: string, props?: TrackAnalyticsEventProperties) => void;
  refetchQueriesOnDelete?: string[];
  onDeleteSuccess?: () => void;
};

const AwsIntegrationDeleteConfirmation = create(function DeleteConfirmation({
  integration,
  onDeleteSuccess,
  trackAnalytics,
  refetchQueriesOnDelete,
}: DeleteConfirmationProps) {
  const modal = useModal();

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [deleteIntegration, { loading }] = useMutation(DELETE_AWS_INTEGRATION, {
    awaitRefetchQueries: true,
    refetchQueries: refetchQueriesOnDelete,
  });

  useEffect(() => {
    trackAnalytics("Open Delete Confirmation", { type: "aws" });
  }, [trackAnalytics]);

  const onConfirm = () => {
    deleteIntegration({ variables: { id: integration.id } })
      .then((data) => {
        if (data?.data?.awsIntegrationDelete?.id) {
          reportSuccess({ message: "Integration successfully removed" });
          modal.hide();
          onDeleteSuccess?.();
          trackAnalytics("Integration deleted", { type: "aws" });
        }
      })
      .catch(onError);
  };

  const onClose = () => {
    trackAnalytics("Cancel Delete", { type: "aws" });
  };

  return (
    <DeleteConfirmationModal
      size="large"
      title="Delete integration"
      onConfirm={onConfirm}
      onClose={onClose}
      isLoading={loading}
      isDismissable={!loading}
    >
      <AwsIntegrationDeleteConfirmationContent integration={integration} />
    </DeleteConfirmationModal>
  );
});

export const showAwsIntegrationDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(AwsIntegrationDeleteConfirmation, props);
