import { NetworkStatus } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";

import usePolledQuery from "apollo/usePolledQuery";
import { getSortOptionFromURI } from "components/Filters/helpers";
import FlashContext from "components/FlashMessages/FlashContext";
import useAnalytics from "hooks/useAnalytics";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import useURLParams from "hooks/useURLParams";
import { Module, Version, VersionState } from "types/generated";
import { uniqByKey } from "utils/uniq";
import { PollingIntervalGroups } from "apollo/constants";

import { ModuleContext } from "../Context";
import { INITIAL_SORT_DIRECTION, INITIAL_SORT_OPTION, ITEMS_LIMIT } from "./constants";
import { GET_MODULE_VERSIONS } from "./gql";

type ModuleData = {
  ErrorContent?: JSX.Element;
  hasNoFilteringResults: boolean;
  hideFailedVersions: boolean;
  isItemLoaded: (value: number) => boolean;
  isPageEmpty: boolean;
  loading: boolean;
  loadMoreItems: () => Promise<void>;
  module?: Module;
  toggleHideFailedVersions: () => void;
  versions: Version[];
};

const useVersionsList = (): ModuleData => {
  const { module } = useTypedContext(ModuleContext);
  const { onError } = useTypedContext(FlashContext);
  const urlParams = useURLParams();
  const [hideFailedVersions, setHideFailedVersions] = useState(true);

  // TODO: predicates implementation should be replaced when we move to proper filers
  // check out https://github.com/spacelift-io/frontend/pull/4280/files#diff-de10f615d75d0c47ee72802ecb177f7cd66824777af2e23b2df2a72080676f16R35-R42
  const predicates = useMemo(() => {
    return hideFailedVersions
      ? [
          {
            field: "state",
            exclude: true,
            constraint: { enumEquals: VersionState.Failed },
          },
        ]
      : [];
  }, [hideFailedVersions]);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const { loading, data, error, fetchMore, networkStatus, previousData } = usePolledQuery<{
    module: Module;
  }>(GET_MODULE_VERSIONS, {
    onError,
    variables: {
      id: module.id,
      searchModuleVersionsInput: {
        first: ITEMS_LIMIT,
        orderBy: sortOptionFields,
        predicates,
      },
    },
    nextFetchPolicy: "cache-first",
    pollingGroup: PollingIntervalGroups.Lists,
  });

  const moduleData = useMemo(() => {
    if (!data?.module && !previousData?.module) {
      // wait until the module is fetched, otherwise data won't be full.
      return undefined;
    }
    return {
      ...module,
      ...(data?.module ?? previousData?.module),
    };
  }, [data?.module, module, previousData?.module]);

  const ErrorContent = useErrorHandle(error);
  const versions: Version[] = useMemo(
    () => moduleData?.searchModuleVersions.edges.map((edge) => edge.node) ?? [],
    [moduleData]
  );

  const loadMoreItems = async () => {
    try {
      if (
        data?.module?.searchModuleVersions.pageInfo.hasNextPage &&
        data?.module?.searchModuleVersions.pageInfo.endCursor
      ) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult.module.searchModuleVersions.edges.length) {
              return {
                module: {
                  ...fetchMoreResult.module,
                  searchModuleVersions: {
                    ...fetchMoreResult.module.searchModuleVersions,
                    edges: uniqByKey(
                      [
                        ...prev.module.searchModuleVersions.edges,
                        ...fetchMoreResult.module.searchModuleVersions.edges,
                      ],
                      "cursor"
                    ),
                  },
                },
              };
            }
            return prev;
          },
          variables: {
            id: module.id,
            searchModuleVersionsInput: {
              first: ITEMS_LIMIT,
              after: data.module.searchModuleVersions.pageInfo.endCursor,
              orderBy: sortOptionFields,
              predicates,
            },
          },
        });
      }
    } catch (e) {
      onError(e);
    }
  };

  const isItemLoaded = useCallback((value: number) => value < versions.length, [versions.length]);

  const trackSegmentAnalyticsEvent = useAnalytics();

  const toggleHideFailedVersions = useCallback(() => {
    const newState = !hideFailedVersions;

    setHideFailedVersions(newState);

    trackSegmentAnalyticsEvent("Module Registry - Include Failed Versions Toggle Clicked", {
      state: newState,
    });
  }, [hideFailedVersions, trackSegmentAnalyticsEvent]);

  return {
    ErrorContent,
    hasNoFilteringResults: !!(data && !versions.length && predicates.length > 0),
    hideFailedVersions,
    isItemLoaded,
    isPageEmpty: !moduleData?.current,
    loading: loading && networkStatus === NetworkStatus.loading,
    loadMoreItems,
    module: moduleData,
    toggleHideFailedVersions,
    versions,
  };
};

export default useVersionsList;
