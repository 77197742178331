import { StateCreator } from "zustand";

import { reorderColumns } from "ds/components/Table/Head/helpers";

import { TableColumnMeta } from "../types";
import { type TableColumnsState } from "./types";

type MakeTableMetaSliceConfig<Columns extends string> = {
  initialOrder: {
    visible: Columns[];
    hidden: Columns[];
  };
  initialSizes: Record<Columns, number>;
  config: Record<Columns, TableColumnMeta<Columns>>;
};

// typescript workaround, immer doesn't work with generics
const genericState = <Columns extends string>(state: unknown) =>
  state as TableColumnsState<Columns>;

const makeTableMetaSlice =
  <Columns extends string>({
    initialOrder,
    initialSizes,
    config,
  }: MakeTableMetaSliceConfig<Columns>): StateCreator<
    TableColumnsState<Columns>,
    [["zustand/immer", never]],
    [["zustand/persist", never]],
    TableColumnsState<Columns>
  > =>
  (set) => ({
    columnsState: config,
    columnsOrder: initialOrder,
    columnsSizes: initialSizes,
    resetConfig: () => {
      set({
        columnsState: config,
        columnsOrder: initialOrder,
        columnsSizes: initialSizes,
      });
    },
    setColumnSize: (columnId, size) => {
      set((state) => {
        genericState(state).columnsSizes[columnId] = size;
      });
    },
    setColumnsOrder: (hidden, visible) => {
      set({
        columnsOrder: {
          hidden,
          visible,
        },
      });
    },
    reorderVisibleColumns: (columnId, direction) => {
      set((state) => {
        genericState(state).columnsOrder.visible = reorderColumns(
          genericState(state).columnsOrder.visible,
          columnId,
          direction
        );
      });
    },
    hideColumn: (columnId) => {
      set((state) => {
        state.columnsOrder.visible = state.columnsOrder.visible.filter((id) => id !== columnId);
        if (!genericState(state).columnsOrder.hidden.includes(columnId)) {
          genericState(state).columnsOrder.hidden = [
            ...genericState(state).columnsOrder.hidden,
            columnId,
          ];
        }
      });
    },
  });

export default makeTableMetaSlice;
