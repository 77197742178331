import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteLoader from "react-window-infinite-loader";

import fetchMoreUpdateQueryPreviousFix from "apollo/fetchMoreUpdateQueryPreviousFix";
import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import { SavedFilterView } from "components/Filters/types";
import FlashContext from "components/FlashMessages/FlashContext";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import ListEntitiesNew from "components/ListEntitiesNew";
import PageInfo from "components/PageWrapper/Info";
import PaginationIndicator from "components/PaginationIndicator";
import SearchInput from "components/SearchInput";
import TierInfo from "components/TierInfo";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import useURLParams from "hooks/useURLParams";
import { BillingTierFeature, RunState, Stack } from "types/generated";
import { getChangeId } from "utils/changes";
import { uniqByKey } from "utils/uniq";
import useTierFeature from "views/Account/hooks/useTierFeature";

import { RunContext } from "../Context";
import ChangesBulkActions from "./BulkActions";
import {
  CHANGES_PER_PAGE,
  FILTERS_ORDER_SETTINGS_KEY,
  initialSortDirection,
  initialSortOption,
  TARGETED_REPLAN_PROMO_KEY,
} from "./constants";
import FiltersLayout from "./FiltersLayout";
import { GET_RUN_CHANGES, GET_RUN_CHANGES_SUGGESTIONS, RUN_TARGETED_REPLAN } from "./gql";
import RunChangesListItem from "./ListItem";
import styles from "./styles.module.css";
import { EntityChangeWithId } from "./types";

function Changes() {
  const urlParams = useURLParams();
  const { onError } = useTypedContext(FlashContext);
  const navigate = useNavigate();
  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, initialSortOption, initialSortDirection),
    [urlParams]
  );
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);

  const isTargetedReplanFeatureActive = useTierFeature(BillingTierFeature.TargetedReplan);

  const [selectedSet, updateSelectedSet] = useState<Set<string>>(new Set());

  const { run, stack } = useTypedContext(RunContext);

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const searchInput = urlParams.get(URL_SEARCH_KEY) || "";

  const {
    data,
    error,
    loading,
    previousData,
    fetchMore: fetchMoreChanges,
  } = useQuery<{ stack: Stack }>(GET_RUN_CHANGES, {
    onError,
    nextFetchPolicy: "cache-first",
    variables: {
      stackId: stack.id,
      runId: run.id,
      input: {
        first: CHANGES_PER_PAGE,
        after: null,
        fullTextSearch: searchInput,
        predicates,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    // APOLLO CLIENT UPDATE
  });

  const {
    data: suggestionsData,
    loading: filteringLoading,
    refetch: refetchSuggestions,
    called,
    previousData: previousSuggestionsData,
  } = usePolledQuery<{
    stack: Stack;
  }>(GET_RUN_CHANGES_SUGGESTIONS, {
    pollingGroup: PollingIntervalGroups.Lists,
    fetchPolicy: "cache-and-network",
    onError,
    variables: {
      runId: run.id,
      stackId: stack.id,
      input: {
        fullTextSearch: searchInput,
        predicates,
        fields: null,
      },
    },
    // APOLLO CLIENT UPDATE
  });

  // This updates filter suggestions when user opens one of filter sections
  const refreshSuggestions = useCallback(
    (fields?: string[]) => {
      if (!called) {
        refetchSuggestions?.({
          runId: run.id,
          stackId: stack.id,
          input: {
            fullTextSearch: searchInput,
            predicates,
            fields,
          },
        });
      }
    },
    [run.id, stack.id, searchInput, predicates, called, refetchSuggestions]
  );

  const handleResetSelection = useCallback(() => {
    updateSelectedSet(new Set());
    setAllSelected(false);
  }, [updateSelectedSet]);

  // TODO: This line and the next block should be moved to a separate hook and reused in other places
  const cachedEntityChangeEdges = useRef<EntityChangeWithId[]>([]);

  const changes = useMemo(() => {
    const sourceEdges =
      data?.stack?.run?.searchEntityChanges?.edges.map((edge) => ({
        ...edge.node,
        id: getChangeId(edge.node.address, edge.node.metadata.type),
      })) || [];
    const edges = loading && !sourceEdges.length ? cachedEntityChangeEdges.current : sourceEdges;

    if (!loading) {
      cachedEntityChangeEdges.current = sourceEdges;
    }

    return edges;
  }, [data?.stack?.run?.searchEntityChanges?.edges, loading]);

  const loadMoreItems = async () => {
    try {
      if (
        data?.stack?.run?.searchEntityChanges?.pageInfo.endCursor &&
        data?.stack?.run?.searchEntityChanges?.pageInfo.hasNextPage
      ) {
        await fetchMoreChanges({
          updateQuery: (prev, { fetchMoreResult }) => {
            const previousData = fetchMoreUpdateQueryPreviousFix(
              !!prev?.stack?.run?.searchEntityChanges,
              prev,
              data
            );

            if (!fetchMoreResult || !fetchMoreResult?.stack || !fetchMoreResult?.stack?.run)
              return previousData;

            const result = fetchMoreResult?.stack?.run?.searchEntityChanges;

            if (!result || !result?.edges?.length) return previousData;

            const edges = uniqByKey(
              [...(previousData?.stack?.run?.searchEntityChanges?.edges || []), ...result.edges],
              "cursor"
            );

            return {
              stack: {
                ...fetchMoreResult.stack,
                run: {
                  ...fetchMoreResult.stack.run,
                  searchEntityChanges: {
                    ...result,
                    edges,
                  },
                },
              },
            };
          },
          variables: {
            input: {
              first: CHANGES_PER_PAGE,
              after: data.stack.run.searchEntityChanges.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const isItemLoaded = (value: number) => value < changes.length;

  const [allSelected, setAllSelected] = useState(false);

  const selectedChanges = useMemo<EntityChangeWithId[]>(
    () => changes.filter((change) => selectedSet.has(change.address)),
    [selectedSet, changes]
  );

  const replanTargets = useMemo<string[]>(
    () =>
      selectedChanges.reduce<Array<string>>((acc, change) => {
        if (change.metadata.moved) {
          // If change is a move, we should select both source and target
          acc.push(change.previousAddress);
        }

        acc.push(change.address);

        return acc;
      }, []),
    [selectedChanges]
  );

  const [replan, { loading: processing }] = useMutation(RUN_TARGETED_REPLAN, {
    refetchQueries: ["GetRun", "GetRunV3"],
    onError,
    variables: {
      stackId: stack.id,
      runId: run.id,
      targets: replanTargets,
    },
    // APOLLO CLIENT UPDATE
    onCompleted() {
      navigate(`/stack/${stack.id}/run/${run.id}`);
    },
  });

  const previousPredicated = useRef(predicates);

  useEffect(() => {
    const availableChanges = changes.map((change) => change.address);

    updateSelectedSet((previousSelectedSet) => {
      const changesToSync =
        allSelected && predicates === previousPredicated.current
          ? // If the "select all" is active, we want to include all available changes,
            // and those that may be loaded later with infinite scroll.
            availableChanges
          : // Otherwise, we want to sync selected changes with the available ones.
            availableChanges.filter((change) => previousSelectedSet.has(change));

      return new Set(changesToSync);
    });

    previousPredicated.current = predicates;
  }, [allSelected, changes, predicates]);

  const handleSelectAll = useCallback(() => {
    setAllSelected(true);
  }, []);

  const handleItemSelect = (item: EntityChangeWithId, checked: boolean) =>
    updateSelectedSet((state) => {
      if (checked) {
        return new Set([...state, item.address]);
      }

      state.delete(item.address);

      if (allSelected) {
        setAllSelected(false);
      }

      return new Set([...state]);
    });

  const handleUnselectItem = (id: string) => {
    setAllSelected(false);
    updateSelectedSet((state) => {
      state.delete(id);

      return new Set([...state]);
    });
  };

  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);

  const areChangesSelectable = isTargetedReplanFeatureActive && run.state === RunState.Unconfirmed;

  const ErrorContent = useErrorHandle(error);

  const memoizedChangesMap = useMemo(
    () => new Map(changes.map((change) => [change.address, { ...change, id: change.address }])), // reassign id to address, so it's consistent with bulk actions
    [changes]
  );

  const handleReplanChanges = useCallback(() => {
    replan().then(handleResetSelection);
  }, [handleResetSelection, replan]);

  if (ErrorContent) {
    return ErrorContent;
  }

  const totalCount =
    (data?.stack?.run || previousData?.stack?.run)?.changesV3?.[0]?.resources?.length || 0;

  const currentCount =
    suggestionsData?.stack?.run?.searchEntityChangesSuggestions?.filteredCount ||
    previousSuggestionsData?.stack?.run?.searchEntityChangesSuggestions?.filteredCount ||
    0;

  return (
    <>
      <PageInfo title="Changes">
        <Box direction="row" align="center" gap="0 large">
          <PaginationIndicator
            currentCount={currentCount}
            totalCount={totalCount}
            loading={loading}
            minimumLoadingDuration={200}
          />

          <SearchInput
            placeholder="Search by name, status and type..."
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />
        </Box>
      </PageInfo>
      <FiltersLayout
        allSelected={allSelected}
        onSelectAll={handleSelectAll}
        onResetAll={handleResetSelection}
        hasItems={changes.length > 0}
        currentSavedView={currentSavedView}
        setCurrentSavedView={setCurrentSavedView}
        suggestions={suggestionsData?.stack?.run?.searchEntityChangesSuggestions}
        loading={filteringLoading}
        handlePollingActiveSections={refreshSuggestions}
        selectable={areChangesSelectable}
        calloutSlot={
          <>
            {!isTargetedReplanFeatureActive && run.state === RunState.Unconfirmed && (
              <TierInfo
                type="callout"
                variant="promo"
                title="Upgrade plan to use Targeted replans"
                feature={BillingTierFeature.TargetedReplan}
                storageKey={TARGETED_REPLAN_PROMO_KEY}
              >
                Spacelift’s Targeted Replans, you can select which planned changes you want to
                apply, allowing you to roll out changes in a step-by-step manner iteratively.
              </TierInfo>
            )}
          </>
        }
      >
        {!loading && changes.length === 0 && (
          <EmptyState
            title="No changes found"
            icon={EmptystateSearchNoResultsColored}
            caption="Try using different filters or search term."
            announce
          />
        )}

        {changes.length > 0 && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={changes.length + CHANGES_PER_PAGE}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={changes.length}
                itemProps={{
                  resources: changes,
                  onCheckItem: handleItemSelect,
                  selectedSet,
                  selectable: areChangesSelectable,
                }}
                virtualizedItem={RunChangesListItem}
                itemKey={(index) => changes[index].id}
                onItemsRendered={onItemsRendered}
                listClassName={styles.listEntities}
                outerContainerRef={virtualizedListContainerRef}
              />
            )}
          </InfiniteLoader>
        )}

        <ChangesBulkActions
          listRef={virtualizedListContainerRef}
          isProcessing={processing}
          onReplan={handleReplanChanges}
          entityChangesMap={memoizedChangesMap}
          onItemDismiss={handleUnselectItem}
          onBulkResetAll={handleResetSelection}
          selectedSet={selectedSet}
        />
      </FiltersLayout>
    </>
  );
}

export default Changes;
