import { Pages } from "./types";

export const enum AnalyticsPageCloudIntegrations {
  CloudIntegrationsList = "CloudIntegrationsList",
  CloudIntegrationUsedBy = "CloudIntegrationUsedBy",
}

const cloudIntegrations = "Cloud Integrations";

export const pagesCloudIntegrations: Pages<AnalyticsPageCloudIntegrations> = {
  [AnalyticsPageCloudIntegrations.CloudIntegrationsList]: [cloudIntegrations, "List"],
  [AnalyticsPageCloudIntegrations.CloudIntegrationUsedBy]: [cloudIntegrations, "Used by"],
};
