import cx from "classnames";
import { KeyboardEvent, useCallback, useRef } from "react";

import { Cross, Magnify } from "components/icons/generated";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import Icon from "ds/components/Icon";
import AriaInput from "ds/components/Input/AriaInput";
import LoadingIndicator from "ds/components/LoadingIndicator";

import DropdownHeader from ".";
import styles from "./styles.module.css";

type DropdownHeaderSearchProps = {
  className?: string;
  listboxId: string;
  ariaExpanded: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  searchLoading?: boolean;
  searchPlaceholder?: string;
  ariaLabel?: string;
};
const LOADING_MINIMUM_DURATION = 200;

const DropdownHeaderSearch = ({
  searchValue,
  listboxId,
  ariaExpanded,
  setSearchValue,
  searchLoading,
  searchPlaceholder,
  ariaLabel,
}: DropdownHeaderSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const focusListbox = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (["ArrowDown", "ArrowUp"].includes(event.key)) {
        event.stopPropagation();
        event.preventDefault();

        const listbox = document.getElementById(listboxId);
        if (listbox && listbox.firstChild instanceof HTMLElement) {
          listbox.firstChild.focus();
        }
      }
    },
    [listboxId]
  );

  return (
    <DropdownHeader>
      <Box className={styles.autocomplete} fullWidth align="center">
        <AriaInput
          ref={inputRef}
          role="combobox"
          autoFocus={true}
          aria-autocomplete="list"
          aria-expanded={ariaExpanded}
          aria-controls={listboxId}
          aria-label={ariaLabel || "Search"}
          className={cx(styles.input, {
            [styles.withClear]: searchValue !== "",
          })}
          placeholder={searchPlaceholder || "Search"}
          value={searchValue}
          onKeyDown={focusListbox}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <Box className={styles.prefix} align="center" justify="center" padding="medium">
          <LoadingIndicator loading={searchLoading} minimumDuration={LOADING_MINIMUM_DURATION}>
            <Icon src={Magnify} />
          </LoadingIndicator>
        </Box>
        {!!searchValue && (
          <ButtonIcon
            className={styles.clearButton}
            variant="ghost"
            slot={null}
            aria-label="Clear"
            icon={Cross}
            onPress={() => setSearchValue("")}
          >
            Clear
          </ButtonIcon>
        )}
      </Box>
    </DropdownHeader>
  );
};

DropdownHeaderSearch.displayName = "DS.Dropdown.Header.Search";

export default DropdownHeaderSearch;
