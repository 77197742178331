import { Cross } from "components/icons/generated";
import Countdown from "components/time/Countdown";
import ButtonIcon from "ds/components/ButtonIcon";

import styles from "./styles.module.css";

type ToastCloseProps = {
  timeout?: number;
  isPaused?: boolean;
  onClose: () => void;
};

const ToastClose = ({ timeout, isPaused, onClose }: ToastCloseProps) => {
  return (
    <div className={styles.toastClose}>
      {!!timeout && (
        <Countdown ttl={timeout} className={styles.toastCloseCountdown} isPaused={isPaused} />
      )}

      <ButtonIcon
        icon={Cross}
        onPress={onClose}
        variant="ghostInversed"
        className={styles.toastCloseButton}
      >
        Close
      </ButtonIcon>
    </div>
  );
};

ToastClose.displayName = "DS.Toast.Close";

export default ToastClose;
