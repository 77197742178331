import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";

import CardWrapper from "components/CardWrapper";
import DocumentationButton from "components/DocumentationButton";
import FlashContext from "components/FlashMessages/FlashContext";
import useErrorHandlerSourceCode from "components/Forms/SourceCode/useErrorHandlerSourceCode";
import MissingDataBanner from "components/MissingDataBanner";
import FormLoading from "components/form/components/loading";
import {
  EmptystateLinkColored,
  EmptystateMagnetColored,
  EmptystatePuzzleColored,
  Puzzle,
} from "components/icons/generated";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { ContextStackAttachment } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";

import NewModuleAttachedContextsPanel from "../AttachedContextsPanel";
import { showEnvironmentPreviewDrawer } from "../EnvironmentPreview/Drawer";
import NewModuleFooter from "../Footer";
import { ModuleFormContext } from "../context";
import { ModuleCreationWizardStep } from "../types";
import { useNewModuleAnalyticsSegmentEvent } from "../useNewModuleAnalyticsSegmentEvent";
import Documentation from "./Documentation";
import AttachContextForm from "./Form";
import { ATTACH_CONTEXT, DETACH_CONTEXT } from "./gql";
import useAttachedContexts from "./useAttachedContexts";
import useSpaceContexts from "./useSpaceContexts";

const NewModuleAttachContext = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const { createdModuleId, formData, setAccountOrAuthErrorContent } =
    useTypedContext(ModuleFormContext);

  const moduleSpace = formData[ModuleCreationWizardStep.Vcs].space;

  const moduleLabels = useMemo(
    () => formData[ModuleCreationWizardStep.Details].labels.map((item) => item.value),
    [formData]
  );

  const [attachContext] = useMutation<{
    contextAttach?: ContextStackAttachment;
  }>(ATTACH_CONTEXT, {
    onError,
    refetchQueries: ["GetModuleAttachedContexts"],
    awaitRefetchQueries: true,
  });

  const [detachContext] = useMutation<{
    contextDetach?: ContextStackAttachment;
  }>(DETACH_CONTEXT, {
    onError,
    refetchQueries: ["GetModuleAttachedContexts"],
    awaitRefetchQueries: true,
  });

  const {
    spaceContexts,
    loading: spaceContextsLoading,
    refetching: spaceContextsRefetching,
    hasData: hasSpaceContextsData,
    refetch: refetchSpaceContexts,
    error: spaceContextsError,
  } = useSpaceContexts(moduleSpace);

  const {
    autoAttachedContexts,
    manuallyAttachedContexts,
    manuallyAttachedContextIds,
    loading: attachedContextsLoading,
    refetching: attachedContextsRefetching,
    hasData: hasAttachedContextsData,
    refetch: refetchAttachedContexts,
    error: attachedContextsError,
  } = useAttachedContexts(createdModuleId);

  const handleOpenEnvironmentDrawer = () => {
    showEnvironmentPreviewDrawer({
      moduleId: createdModuleId,
      onCloseDrawer: handleCloseEnvironmentDrawer,
    });
    trackSegmentEvent("Open context environment drawer");
  };

  const handleCloseEnvironmentDrawer = () => {
    trackSegmentEvent("Close context environment drawer");
  };

  const handleAttachContext = async (contextId: string, priority: number) => {
    const res = await attachContext({
      variables: {
        stackId: createdModuleId,
        contextId: contextId,
        priority: priority,
      },
    });

    return !!res?.data?.contextAttach?.id;
  };

  const handleDetachContext = useCallback(
    async (id: string) => {
      try {
        const res = await detachContext({
          variables: {
            id,
          },
        });

        if (res?.data?.contextDetach?.id) {
          reportSuccess({ message: "Context successfully detached" });
          trackSegmentEvent("Context detached");
        }
      } catch (error) {
        onError(error);
      }
    },
    [detachContext, onError, reportSuccess, trackSegmentEvent]
  );

  const handleChangePriority = useCallback(
    async (id: string, priority: number) => {
      const res = await attachContext({
        variables: {
          stackId: createdModuleId,
          contextId: id,
          priority,
        },
      });

      if (res?.data?.contextAttach?.id) {
        reportSuccess({ message: "Context priority successfully changed" });
        trackSegmentEvent("Context priority updated");
      }
    },

    [attachContext, createdModuleId, reportSuccess, trackSegmentEvent]
  );

  const hasNoContexts = hasSpaceContextsData && spaceContexts.length === 0;

  useErrorHandlerSourceCode(setAccountOrAuthErrorContent, attachedContextsError);
  useErrorHandlerSourceCode(setAccountOrAuthErrorContent, spaceContextsError);

  if (spaceContextsLoading || attachedContextsLoading) {
    return <FormLoading />;
  }

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Attach contexts (optional)
        </Typography>
        {hasNoContexts ? (
          <Box direction="column" gap="large" margin="large 0 0 0">
            <Banner variant="info">
              You can only attach contexts from the current space and parent spaces that you inherit
              from.
            </Banner>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystatePuzzleColored}
                title="You do not have any contexts yet"
                caption={
                  <>
                    Contexts are reusable elements composed of environment variables and mounted
                    files. They can be attached to any number of modules and you have the
                    possibility of defining the values as either plain-text or secrets. Apart from
                    being reusable, they minimize human-errors and provide a secure way of storing
                    your variables and files. <br />
                    Read more in the Documentation.
                  </>
                }
              >
                <DocumentationButton
                  to={getDocsUrl("/concepts/configuration/context")}
                  label="Documentation"
                />
              </EmptyState>
            </CardWrapper>
          </Box>
        ) : (
          <>
            <Typography
              tag="p"
              variant="p-body2"
              align="center"
              color="secondary"
              margin="small 0 large 0"
            >
              Context is a bundle of configuration elements you can attach to module
            </Typography>

            <Box direction="column" gap="large">
              <AttachContextForm
                spaceContexts={spaceContexts}
                onAttachContext={handleAttachContext}
                attachedContextIds={manuallyAttachedContextIds}
              >
                {!hasSpaceContextsData && (
                  <MissingDataBanner
                    text="Couldn't load contexts list. Please try to refresh or come back later."
                    refreshHandler={refetchSpaceContexts}
                    refreshLoading={spaceContextsRefetching}
                  />
                )}
              </AttachContextForm>

              <Box direction="column" align="end" gap="medium">
                {!hasAttachedContextsData && (
                  <Box direction="column" fullWidth>
                    <MissingDataBanner
                      text="Couldn't load attached contexts. Please try to refresh or come back later."
                      refreshHandler={refetchAttachedContexts}
                      refreshLoading={attachedContextsRefetching}
                    />
                  </Box>
                )}
                <Link onPress={handleOpenEnvironmentDrawer} startIcon={Puzzle}>
                  See environment
                </Link>
                <NewModuleAttachedContextsPanel
                  title="Manually attached"
                  contexts={manuallyAttachedContexts}
                  moduleLabels={moduleLabels}
                  emptyPlaceholder={
                    <EmptyState
                      padding="large"
                      icon={EmptystateLinkColored}
                      title="No contexts are attached to this module"
                      caption="Use contexts list to attach a context to module."
                    />
                  }
                  onPriorityChange={handleChangePriority}
                  onDetach={handleDetachContext}
                />
              </Box>

              <NewModuleAttachedContextsPanel
                title="Auto-attached"
                contexts={autoAttachedContexts}
                moduleLabels={moduleLabels}
                emptyPlaceholder={
                  <EmptyState
                    padding="large"
                    icon={EmptystateMagnetColored}
                    title="No contexts are auto-attached to this module"
                    caption="Use auto-attach label to attach context automatically."
                  />
                }
              />
            </Box>
          </>
        )}
      </FullScreenModalBody>
      <NewModuleFooter
        documentationLink={getDocsUrl("/concepts/configuration/context#attaching-and-detaching")} // TODO: update docs
        documentationTitle="Attach context"
        documentationBody={<Documentation />}
      />
    </>
  );
};

export default NewModuleAttachContext;
