import { useMemo } from "react";
import { SortDescriptor } from "react-aria-components";

import { USED_BY_COLUMN_ID } from "./constants";
import { AttachedStack } from "./types";

const sortStacks = (items: AttachedStack[], sortDescriptor: SortDescriptor) => {
  if (!sortDescriptor.column) {
    return items;
  }

  const isAscending = sortDescriptor.direction === "ascending";

  return [...items].sort((a, b) => {
    let valueA: string | undefined;
    let valueB: string | undefined;

    switch (sortDescriptor.column as USED_BY_COLUMN_ID) {
      case USED_BY_COLUMN_ID.TYPE:
        valueA = a.isModule ? "Module" : "Stack";
        valueB = b.isModule ? "Module" : "Stack";
        break;
      case USED_BY_COLUMN_ID.NAME:
        valueA = a.stackName;
        valueB = b.stackName;
        break;
    }

    valueA = valueA || "";
    valueB = valueB || "";

    return isAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
  });
};

export const useFilteredStacks = (
  stacks: AttachedStack[],
  sortDescriptor: SortDescriptor,
  searchQuery: string
) => {
  return useMemo(() => {
    let filteredStacks = [...stacks];

    // Apply search filter if exists
    if (searchQuery) {
      filteredStacks = filteredStacks.filter((stack) =>
        stack.stackName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply sorting if descriptor exists
    if (sortDescriptor.column) {
      filteredStacks = sortStacks(filteredStacks, sortDescriptor);
    }

    return filteredStacks;
  }, [stacks, searchQuery, sortDescriptor]);
};
