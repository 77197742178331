import { useCallback, useMemo } from "react";

import { UsePaginationFetchMoreProps } from "components/Table/Pagination/types";

import { IacManagementGroupBy } from "../types";
import useIacManagementStackGroups from "./useIacManagementStackGroups";
import useFiltersVariables from "../useFiltersVariables";
import useIacManagementProvidersGroups from "./useIacManagementProvidersGroups";
import useIacManagementTypesGroups from "./useIacManagementTypesGroups";
import { getStackPredicate } from "../helpers";
import { makeGroupSortOption } from "./helpers";

type UseIacManagementGroupsProps = {
  groupBy: IacManagementGroupBy;
  parentStackId?: string;
};

const useIacManagementGroups = ({ groupBy, parentStackId }: UseIacManagementGroupsProps) => {
  // TODO: merge these hooks to a generic one
  const stackGroupsHook = useIacManagementStackGroups();
  const providerGroupsHook = useIacManagementProvidersGroups();
  const providerTypesHook = useIacManagementTypesGroups();

  const { filterVariables, sortingMounted } = useFiltersVariables();

  const currentHook = useMemo(() => {
    switch (groupBy) {
      case IacManagementGroupBy.Provider:
        return providerGroupsHook;
      case IacManagementGroupBy.Type:
        return providerTypesHook;
      case IacManagementGroupBy.Stack:
      default:
        return stackGroupsHook;
    }
  }, [groupBy, providerGroupsHook, stackGroupsHook, providerTypesHook]);

  const { lazyFetch } = currentHook;

  const isEmpty = !!(
    currentHook.dataIsEmpty &&
    !filterVariables.searchInput &&
    !filterVariables.predicates.length
  );
  const searchIsEmpty =
    currentHook.dataIsEmpty &&
    !!(filterVariables.searchInput || filterVariables.predicates.length > 0);

  const fetchData = useCallback(
    ({ cursor, requestedPage, rowsPerPage }: UsePaginationFetchMoreProps) => {
      const predicates =
        (parentStackId && [...filterVariables.predicates, getStackPredicate(parentStackId)]) ||
        filterVariables.predicates;

      if (sortingMounted) {
        lazyFetch({
          variables: {
            input: {
              after: cursor,
              first: rowsPerPage,
              fullTextSearch: filterVariables.searchInput,
              orderBy: makeGroupSortOption({
                groupBy,
                actualSortOption: filterVariables.sortOptionFields,
              }),
              predicates,
              requestedPage: requestedPage,
            },
          },
        });
      }
    },
    [
      filterVariables.predicates,
      filterVariables.searchInput,
      filterVariables.sortOptionFields,
      groupBy,
      lazyFetch,
      parentStackId,
      sortingMounted,
    ]
  );

  return {
    fetchData,
    isEmpty,
    searchIsEmpty,
    groups: currentHook.groups,
    loading: currentHook.loading && !currentHook.groups.length,
    endCursor: currentHook.endCursor,
    startCursor: currentHook.startCursor,
  };
};

export default useIacManagementGroups;
