import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledQuery from "apollo/usePolledQuery";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DeleteButton from "components/DeleteButton";
import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import { EmptystateVcsColored } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import Markdown from "components/markdown/Markdown";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import ResetButton from "components/ResetButton";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import WarningBar from "components/warning/WarningBar";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import EmptyState from "ds/components/EmptyState";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { VcsAgentPool } from "types/generated";
import { downloadFile } from "utils/file";
import { getDocsUrl } from "utils/getDocsUrl";
import { AccountContext } from "views/AccountWrapper";

import AgentItem from "./AgentItem";
import { DELETE_VCS_AGENT_POOL, GET_VCS_AGENT_POOL, RESET_VCS_AGENT_POOL } from "./gql";
import styles from "./styles.module.css";

const VCSAgentPool = () => {
  const { vcsAgentPoolId } = useParams<{ vcsAgentPoolId: string }>();

  const { accountName } = useTypedContext(AccountContext);
  const { reportSuccess, onError } = useTypedContext(FlashContext);

  const navigate = useNavigate();

  const [isResettingMode, setIsResettingMode] = useState(false);
  const [isDeletingMode, setIsDeletingMode] = useState(false);

  const { data, loading, error } = usePolledQuery<{
    vcsAgentPool: VcsAgentPool;
  }>(GET_VCS_AGENT_POOL, {
    onError,
    variables: { vcsAgentPoolId },
    pollingGroup: PollingIntervalGroups.SingleEntities,
  });
  // APOLLO CLIENT UPDATE
  const [resetVCSAgentPool, { loading: isResetting }] = useMutation<{
    vcsAgentPoolReset: VcsAgentPool;
  }>(RESET_VCS_AGENT_POOL);
  const [deleteVCSAgentPool, { loading: isDeleting }] = useMutation(DELETE_VCS_AGENT_POOL);

  useTitle(`${data?.vcsAgentPool?.name || "VCS agent pool"} · ${accountName}`);

  const handleReset = useCallback(async () => {
    try {
      const { data } = await resetVCSAgentPool({ variables: { vcsAgentPoolId } });

      if (data) {
        const {
          vcsAgentPoolReset: { id, config },
        } = data;

        if (config) {
          downloadFile(`vcs-agent-pool-${id}.config`, config);
        }
        reportSuccess({ message: "VCS agent pool was reset" });
      }
    } catch (e) {
      onError(e);
    }
  }, [resetVCSAgentPool, onError, reportSuccess, vcsAgentPoolId]);

  const handleDelete = useCallback(async () => {
    try {
      await deleteVCSAgentPool({ variables: { vcsAgentPoolId } });
      reportSuccess({ message: "VCS agent pool was deleted" });

      navigate("/vcs-agent-pools");
    } catch (e) {
      onError(e);
    }
  }, [deleteVCSAgentPool, navigate, onError, reportSuccess, vcsAgentPoolId]);

  useBreadcrumbs(
    [
      {
        title: "VCS agent pools",
        link: "/vcs-agent-pools",
      },
      {
        title: data?.vcsAgentPool?.name || "",
      },
    ],
    [data?.vcsAgentPool?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.vcsAgentPool) {
    return <PageLoading />;
  }

  if (!data?.vcsAgentPool) {
    return <NotFoundPage />;
  }

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderTitle>{data.vcsAgentPool.name}</ViewHeaderTitle>

          <DropdownMenuEllipsis tooltip="Agent pool actions" dotsSize="medium">
            <CopyFieldDropdownMenuItem title="Copy agent pool ID" value={data.vcsAgentPool.id} />
          </DropdownMenuEllipsis>
        </ViewHeaderWrapper>
      </ViewHeader>
      <PageInfo title="View VCS agent pool">
        {!isDeletingMode && (
          <ResetButton
            active={isResettingMode}
            setIsResettingMode={setIsResettingMode}
            isResetting={isResetting}
            handleReset={handleReset}
          />
        )}
        {!isResettingMode && (
          <DeleteButton
            active={isDeletingMode}
            setIsDeletingMode={setIsDeletingMode}
            idDeleting={isDeleting}
            handleDelete={handleDelete}
          />
        )}
      </PageInfo>
      <PageWrapper>
        {isDeletingMode && (
          <WarningBar>
            <p>
              Just a gentle reminder that deleting the VCS Agent Pool is an irreversible operation
              and you will not be able to reuse its credentials for other VCS Agent Pools.
            </p>
          </WarningBar>
        )}

        {isResettingMode && (
          <WarningBar>
            <p>
              Resetting this VCS Agent Pool means causes that existing agents will no longer be able
              to connect to Spacelift and will need to be restarted with the new configuration.
            </p>
          </WarningBar>
        )}

        {data.vcsAgentPool.description && (
          <div className={styles.agentDescription}>
            <Markdown markup={data.vcsAgentPool.description} />
          </div>
        )}

        {data.vcsAgentPool.agentConnections.length === 0 && (
          <EmptyState
            icon={EmptystateVcsColored}
            title="No belonging agents yet"
            caption={
              <>
                No agents belonging to this VCS Agent Pool are currently connected to our gateway.
                Feel free to refer to the docs on{" "}
                <ReadMoreDocsLink
                  docsUrl={getDocsUrl(
                    /* @ignore-checking-sh-docs */
                    "/concepts/vcs-agent-pools"
                  )}
                  title="how to set up VCS agents"
                />
                .
              </>
            }
          />
        )}
        {data.vcsAgentPool.agentConnections.length > 0 &&
          data.vcsAgentPool.agentConnections.map((item) => <AgentItem key={item.id} {...item} />)}
      </PageWrapper>
    </>
  );
};

export default VCSAgentPool;
