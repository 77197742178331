import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import PageInfo from "components/PageWrapper/Info";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import useBlockNavigationModal from "hooks/useBlockNavigationModal";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { Blueprint, BlueprintParseResult, BlueprintState } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import { INITIAL_TEMPLATE } from "views/Account/Blueprints/constants";
import { showCreateBlueprintDrawer } from "views/Account/Blueprints/CreateBlueprintDrawer";
import { showCreateStackDrawer } from "views/Account/Blueprints/CreateStackDrawer";
import BlueprintsFeatureGateTooltip from "views/Account/Blueprints/FeatureGate/Tooltip";
import { BlueprintActions } from "views/Account/Blueprints/FeatureGate/types";
import { UPDATE_BLUEPRINT } from "views/Account/Blueprints/gql";
import { SpacesContext } from "views/Account/SpacesProvider";

import BlueprintShareButton from "../components/ShareButton";
import { BlueprintVersionFormFields } from "../components/VersionFormFragment";
import BlueprintEditor from "../Editor";
import { VALIDATE_BLUEPRINT_TEMPLATE } from "../gql";
import { showPublishConfirmationModal } from "../PublishConfirmationModal";
import styles from "./styles.module.css";

type BlueprintTemplateBodyProps = {
  blueprint: Blueprint;
  blueprintSchema: string;
};

const blueprintAnalyticsProps = {
  source: "blueprint-view",
};

const BlueprintTemplateBody = ({ blueprint, blueprintSchema }: BlueprintTemplateBodyProps) => {
  const [editorBody, setEditorBody] = useState<string>(INITIAL_TEMPLATE);
  const { reportSuccess, onError } = useTypedContext(FlashContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const [errors, setErrors] = useState<string[]>([]);

  const [validateTemplate] = useMutation<{
    blueprintParseTemplate: BlueprintParseResult;
  }>(VALIDATE_BLUEPRINT_TEMPLATE);

  const editorHasUnsavedChanges = editorBody !== blueprint.rawTemplate;

  useBlockNavigationModal({
    title: "Leave confirmation",
    description: "Are you sure you want to leave? You have unsubmitted changes.",
    isNavigationBlocked: editorHasUnsavedChanges,
  });

  const [updateBlueprint, { loading: saveLoading }] = useMutation<{ blueprintUpdate: Blueprint }>(
    UPDATE_BLUEPRINT,
    {
      onError,
    }
  );

  const handleUpdateBlueprint = (publish?: boolean, versionData?: BlueprintVersionFormFields) => {
    if (blueprint) {
      updateBlueprint({
        variables: {
          id: blueprint.id,
          input: {
            name: blueprint.name,
            description: blueprint.description,
            labels: blueprint.labels,
            space: blueprint.space.id,
            state: publish ? BlueprintState.Published : blueprint.state,
            template: editorBody,
            ...versionData,
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintUpdate?.name) {
            reportSuccess({
              message: `Blueprint "${data.blueprintUpdate.name}" is successfully saved`,
            });
          }
        })
        .catch(onError);
    }
  };

  const handleValidateTemplate = useCallback(
    (template: string) => {
      setErrors([]);

      return validateTemplate({
        variables: {
          template,
        },
      })
        .then(({ data }) => {
          setErrors(data?.blueprintParseTemplate?.errors || []);

          return {
            inputs: data?.blueprintParseTemplate?.inputs || [],
            errors: data?.blueprintParseTemplate?.errors || [],
          };
        })
        .catch(onError);
    },
    [onError, validateTemplate]
  );

  const handleSaveTemplate = useCallback(async () => {
    await handleUpdateBlueprint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorBody, blueprint]);

  const handleOpenCreateStackDrawer = () => {
    showCreateStackDrawer({
      item: blueprint,
      onEditMetadata: handleOpenEditDrawer,
    });
  };

  const handleEditorChange = (value?: string) => {
    setEditorBody(value || "");
  };

  const handleOpenEditDrawer = () => {
    showCreateBlueprintDrawer({ blueprint: blueprint });
  };

  useEffect(() => {
    if (blueprint?.rawTemplate) {
      setEditorBody(blueprint.rawTemplate);
      handleValidateTemplate(blueprint.rawTemplate);
    } else {
      handleValidateTemplate(editorBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blueprint?.rawTemplate]);

  useTitle(`Template body · ${blueprint?.name}`);

  const isPublished = blueprint.state === BlueprintState.Published;
  const canManageBlueprint =
    hasSpaceManageAccess(blueprint.space.accessLevel) && !blueprint.deleted;

  const onPublishConfirmation = () => {
    showPublishConfirmationModal({
      onPublish: (versionData) => handleUpdateBlueprint(true, versionData),
      blueprint,
    });
  };

  return (
    <>
      <Box direction="column" grow="1" fullWidth relative>
        <PageInfo title="Template body">
          {isPublished && blueprint?.id && (
            <BlueprintShareButton
              analyticsProps={blueprintAnalyticsProps}
              blueprintId={blueprint.id}
              size="medium"
              variant="secondary"
            />
          )}
          {isPublished && hasEntityCreateAccess && (
            <Button variant="primary" size="medium" onPress={handleOpenCreateStackDrawer}>
              Create stack
            </Button>
          )}

          {!isPublished && canManageBlueprint && (
            <>
              <BlueprintsFeatureGateTooltip
                action={BlueprintActions.Edit}
                on={({ isDisabled, ...props }) => (
                  <Button
                    {...props}
                    variant="secondary"
                    size="medium"
                    onPress={handleSaveTemplate}
                    disabled={saveLoading || isDisabled || !editorHasUnsavedChanges}
                    loading={saveLoading}
                  >
                    Save
                  </Button>
                )}
              />

              <BlueprintsFeatureGateTooltip
                action={BlueprintActions.Publish}
                on={({ isDisabled, ...props }) => (
                  <Button
                    {...props}
                    variant="primary"
                    size="medium"
                    onPress={onPublishConfirmation}
                    disabled={isDisabled || errors.length > 0}
                  >
                    Publish
                  </Button>
                )}
              />
            </>
          )}
        </PageInfo>

        <Box direction="column" grow="1" fullWidth relative className={styles.editorWrapper}>
          <BlueprintEditor
            blueprint={blueprint}
            validate={handleValidateTemplate}
            body={editorBody}
            schema={blueprintSchema}
            onChange={handleEditorChange}
            readOnly={isPublished || blueprint.deleted}
          />
        </Box>
      </Box>
    </>
  );
};

export default BlueprintTemplateBody;
