import { format } from "date-fns/esm";
import { StringLike } from "@visx/scale";
import { ReactNode, useCallback } from "react";
import Skeleton from "react-loading-skeleton";

import Box from "ds/components/Box";
import MetricCardGroup from "components/MetricCard/Group";
import MetricCard from "components/MetricCard";
import BarChart from "ds/components/Charts/BarChart";
import { Datum } from "ds/components/Charts/BarChart/Chart";
import { getDuration } from "utils/time";

type DashboardWidgetsRunDurationProps = {
  data?: Datum[];
  duration?: number;
  emptyState?: ReactNode;
  errorBanner?: ReactNode;
  cardTitle: string;
  tooltipHeader: string;
  isLoading: boolean;
};

const DashboardWidgetsRunDuration = ({
  data,
  duration = 0,
  cardTitle,
  tooltipHeader,
  isLoading,
  emptyState,
  errorBanner,
}: DashboardWidgetsRunDurationProps) => {
  const formatTooltipValue = useCallback((data: Datum) => {
    return (data.value && getDuration(data.value * 60, true, false)) || "0s";
  }, []);

  // Nanoseconds to seconds
  const durationFormatted = getDuration(Math.round(duration / 1000000000), true) || "0s";

  return (
    <Box direction="column" gap="x-large" fullWidth>
      {errorBanner}
      <MetricCardGroup padding="0" gap="x-large">
        <MetricCard
          title={cardTitle}
          value={
            isLoading ? (
              <Skeleton count={1} height={28} width={40} style={{ margin: "5px 0" }} />
            ) : (
              durationFormatted
            )
          }
          grow="1"
        />
      </MetricCardGroup>
      {!isLoading && emptyState}

      {!emptyState && !errorBanner && !!data && (
        <BarChart
          data={data}
          items={["value"]}
          colors={["var(--chart-non-semantic-bar-1st-segment-rest-background-color)"]}
          inactiveColors={["var(--chart-non-semantic-bar-1st-segment-inactive-background-color)"]}
          xKey="timestamp"
          formatXAxisLabel={(value: StringLike) => format((value as number) * 1000, "EEE d MMM")}
          formatTooltipValue={formatTooltipValue}
          tooltipHeader={tooltipHeader}
          showItemsInTooltip={false}
          leftAxisLabel="Minutes"
          aspectRatio={0.5}
          leftNumTicks={8}
          showGridRows={false}
          tooltipReactToScroll
          fixedHeight={360}
          minBarHeight={10}
        />
      )}
    </Box>
  );
};

export default DashboardWidgetsRunDuration;
