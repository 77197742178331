import { useRef } from "react";
import NiceModal from "@ebay/nice-modal-react";

import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import IacManagement from "../IacManagement";
import { IacManagementTableStoreContext } from "../IacManagement/TableStoreContext";
import makeIacTableStore from "../IacManagement/components/Table/useIacTableStore/makeIacTableStore";
import { IacManagementPageStoreContext } from "../IacManagement/PageStoreContext";
import makeIacManagementStore from "../IacManagement/useIacManagementStore/makeIacManagementStore";
import { TABLE_COLUMNS } from "../IacManagement/ListLayout/constants";

const ResourcesIacManagement = () => {
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`IaC Management · ${accountName}`);

  const pageStore = useRef(
    makeIacManagementStore({ storageKey: "iac-resources-page-store" })
  ).current;
  const tableStore = useRef(
    makeIacTableStore({ storageKey: "iac-resources-table-store", initialColumns: TABLE_COLUMNS })
  ).current;

  return (
    <IacManagementPageStoreContext.Provider value={pageStore}>
      <IacManagementTableStoreContext.Provider value={tableStore}>
        <NiceModal.Provider>
          <IacManagement />
        </NiceModal.Provider>
      </IacManagementTableStoreContext.Provider>
    </IacManagementPageStoreContext.Provider>
  );
};

export default ResourcesIacManagement;
