import { gql, TypedDocumentNode } from "@apollo/client";

import { ManagedEntitiesGroupedByTypesConnection, PageInfo } from "types/generated";

type SearchManagedEntitiesGroupByTypesData = {
  searchManagedEntitiesGroupByTypes: {
    edges: Array<ManagedEntitiesGroupedByTypesConnection>;
    pageInfo: Pick<PageInfo, "endCursor" | "startCursor" | "hasNextPage">;
  };
};

export const SEARCH_MANAGED_ENTITIES_GROUP_BY_TYPES: TypedDocumentNode<SearchManagedEntitiesGroupByTypesData> = gql`
  query SearchManagedEntitiesGroupByTypes($input: SearchInput!) {
    searchManagedEntitiesGroupByTypes(input: $input) {
      edges {
        cursor
        node {
          id
          count
          drifted
          updatedAt
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
`;
