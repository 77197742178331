import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FormFieldTextConfirm from "components/FormFields/Confirm";
import { VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Counter from "ds/components/Counter";
import Feedback from "ds/components/Feedback";
import FeedbackActions from "ds/components/Feedback/Actions";
import Link from "ds/components/Link";
import Tile from "ds/components/Tile";
import TileTitle from "ds/components/Tile/Title";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";
import useDeleteStack from "shared/Stack/useDeleteStack";
import { isAnsibleStackVendor } from "utils/stack";

import StackSettingsFormWrapper from "../components/FormWrapper";
import StackSettingsPageLayout from "../components/PageLayout";
import { StackSettingsContextData } from "../Context";

const DELETION_CONFIRMATION_TEXT = "delete";

type StackDeletionFormFields = {
  shouldDeleteResources: boolean;
  deleteConfirmationText: string;
};

const StackSettingsDeletion = () => {
  const navigate = useNavigate();
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackSettingsDeletion,
  });

  const isAnsibleVendor = isAnsibleStackVendor(stackSettings);
  const managesStateFile =
    stackSettings.managesStateFile &&
    stackSettings.vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAFORM;

  const { deleteStack, loading } = useDeleteStack();

  const stackDeletionForm = useForm<StackDeletionFormFields>({
    defaultValues: {
      shouldDeleteResources: false,
      deleteConfirmationText: "",
    },
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = stackDeletionForm;

  const navigateStacksPage = () => navigate("/stacks");

  const handleDeleteStack = (formData: StackDeletionFormFields) => {
    deleteStack({
      stackId: stackSettings.id,
      successCallback: () => {
        navigateStacksPage();
        trackSegmentEvent("Stack Deleted", {
          resources: formData.shouldDeleteResources ? "delete" : "keep",
        });
      },
      ...(!isAnsibleVendor && { shouldDeleteResources: formData.shouldDeleteResources }),
    });
  };

  return (
    <StackSettingsPageLayout title="Stack deletion">
      {managesStateFile && (
        <Feedback type="callout" variant="warning">
          Note that Spacelift manages state for this stack. Once the stack is gone, so is the state
          file. If you just want to migrate this stack off of Spacelift, please consider retrieving
          the state file first using a Task.
          <FeedbackActions>
            <Link size="small" href={`/stack/${stackSettings.id}/tasks`}>
              Go to Tasks
            </Link>
          </FeedbackActions>
        </Feedback>
      )}

      {isAnsibleVendor && (
        <Feedback type="callout" variant="warning">
          Note that deleting this stack does not clean up any configuration done by this Ansible
          stack. If you wish to reverse the applied configuration, please do this manually.
        </Feedback>
      )}

      {stackSettings.protectFromDeletion && (
        <Feedback type="callout" variant="danger">
          Stack is protected from deletion. Go to behavior tab and switch “Protect from deletion”
          toggle off to be able to remove the stack.
          <FeedbackActions>
            <Link size="small" href={`/stack/${stackSettings.id}/settings/behavior`}>
              Go to Behavior tab
            </Link>
          </FeedbackActions>
        </Feedback>
      )}
      <form onSubmit={handleSubmit(handleDeleteStack)}>
        <StackSettingsFormWrapper>
          <FormProvider {...stackDeletionForm}>
            {!isAnsibleVendor && (
              <Controller
                name="shouldDeleteResources"
                control={control}
                render={({ field }) => (
                  <Box gap="x-large">
                    <Tile
                      title={
                        <Box gap="medium" align="center">
                          <TileTitle>Delete resources</TileTitle>
                          <Counter
                            count={stackSettings.entityCount}
                            variant={field.value ? "inversed" : undefined}
                          />
                        </Box>
                      }
                      indicator="radio"
                      description="All resources that belong to this stack will be removed with it."
                      selected={field.value}
                      onClick={() => field.onChange(true)}
                      disabled={stackSettings.protectFromDeletion || loading}
                    />

                    <Tile
                      title="Keep resources"
                      indicator="radio"
                      description="Resources that belong to this stack will remain available when the stack is removed."
                      selected={!field.value}
                      onClick={() => field.onChange(false)}
                      disabled={stackSettings.protectFromDeletion || loading}
                    />
                  </Box>
                )}
              />
            )}

            <FormFieldTextConfirm
              fieldName="deleteConfirmationText"
              textToConfirm={DELETION_CONFIRMATION_TEXT}
              disabled={stackSettings.protectFromDeletion || loading}
            />

            <Box justify="end">
              <Button
                variant="dangerPrimary"
                type="submit"
                loading={loading}
                disabled={!isValid || stackSettings.protectFromDeletion || loading}
              >
                Delete
              </Button>
            </Box>
          </FormProvider>
        </StackSettingsFormWrapper>
      </form>
    </StackSettingsPageLayout>
  );
};

export default StackSettingsDeletion;
