import Link from "ds/components/Link";

import { CellComponentProps } from "./types";
import { ANALYTICS } from "../constants";

type CloudIntegrationsListSpaceCellProps = CellComponentProps;

const CloudIntegrationsListSpaceCell = ({ item }: CloudIntegrationsListSpaceCellProps) => {
  return (
    <Link
      size="small"
      href={`/spaces/${item.spaceDetails.id}`}
      analyticsPage={ANALYTICS.analyticsPage}
      analyticsTitle="Space link clicked"
      analyticsProps={{
        name: item.spaceDetails.name,
        type: item.__typename,
      }}
    >
      {item.spaceDetails.name}
    </Link>
  );
};

export default CloudIntegrationsListSpaceCell;
