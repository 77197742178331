import { useRef } from "react";

import Box from "ds/components/Box";
import useTableKeyboardNavigation from "ds/components/Table/useKeyboardNavigation/useTableKeyboardNavigation";

import IaCManagementTableHead from "./Head";
import IaCManagementTableBody from "./Body";
import styles from "./styles.module.css";
import IacManagementTableSkeleton from "./Skeleton";
import { RenderGroupRowContent, RenderHeadRowContent, RenderResourceRowContent } from "./types";

type IacManagementTableProps = {
  rowsPerPage: number;
  groupsLoading: boolean;
  renderHeadRow: RenderHeadRowContent;
  renderGroupRow: RenderGroupRowContent;
  renderResourceRow: RenderResourceRowContent;
};

const IacManagementTable = ({
  rowsPerPage,
  groupsLoading,
  renderHeadRow,
  renderGroupRow,
  renderResourceRow,
}: IacManagementTableProps) => {
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const props = useTableKeyboardNavigation();

  return (
    <Box ref={tableWrapperRef} className={styles.tableWrapper}>
      <div aria-label="IaC Management Table" className={styles.table} role="grid" {...props}>
        <IaCManagementTableHead renderHeadRow={renderHeadRow} />
        {groupsLoading && <IacManagementTableSkeleton rowsPerPage={rowsPerPage} />}
        {!groupsLoading && (
          <IaCManagementTableBody
            tableWrapperRef={tableWrapperRef}
            renderGroupRow={renderGroupRow}
            renderResourceRow={renderResourceRow}
            rowsPerPage={rowsPerPage}
          />
        )}
      </div>
    </Box>
  );
};

export default IacManagementTable;
