import { ReactNode } from "react";

import PageInfo from "components/PageWrapper/Info";
import Button from "ds/components/Button";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";

type BlueprintIntegrationsPageLayoutProps = {
  children: ReactNode;
  onCreate?: () => void;
  blueprintName: string;
  isDraft: boolean;
};

const BlueprintIntegrationsPageLayout = ({
  children,
  onCreate,
  blueprintName,
  isDraft,
}: BlueprintIntegrationsPageLayoutProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Integrations · ${blueprintName}`);

  return (
    <>
      <PageInfo title="Integrations">
        <Button onPress={onCreate} disabled={!hasEntityCreateAccess || isDraft} variant="primary">
          Attach integration
        </Button>
      </PageInfo>
      {children}
    </>
  );
};

export default BlueprintIntegrationsPageLayout;
