import { memo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useParams } from "react-router-dom";
import cx from "classnames";

import useRowKeyboardNavigation from "ds/components/Table/useKeyboardNavigation/useRowKeyboardNavigation";

import IacManagementTableBodyRow from ".";
import styles from "./styles.module.css";
import { RenderResourceRowContent } from "../types";
import useIacTableStore from "../useIacTableStore";
import { selectVisibleColumns } from "../useIacTableStore/tableMetaSelectors";
import { selectTableRowByIndex } from "../useIacTableStore/tableDataSelectors";

type IacManagementTableRowResourceProps = {
  rowIndex: number;
  measureRef: (node: HTMLDivElement) => void;
  renderContent: RenderResourceRowContent;
};

const IacManagementTableRowResource = ({
  rowIndex,
  measureRef,
  renderContent,
}: IacManagementTableRowResourceProps) => {
  const { resourceId } = useParams<{ resourceId?: string }>();

  const { columns, row } = useIacTableStore(
    useShallow((state) => ({
      columns: selectVisibleColumns(state),
      row: selectTableRowByIndex(state, rowIndex),
    }))
  );

  const isActive = resourceId === row.id;

  const keyboardProps = useRowKeyboardNavigation({ isGroup: false });

  if (!("thirdPartyMetadata" in row)) {
    return null;
  }

  return (
    <IacManagementTableBodyRow
      {...keyboardProps}
      forwardRef={measureRef}
      dataIndex={rowIndex}
      className={cx(styles.resourceRow, { [styles.isActive]: isActive })}
    >
      {renderContent({
        columns,
        row,
      })}
    </IacManagementTableBodyRow>
  );
};

export default memo(IacManagementTableRowResource);
