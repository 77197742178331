import { StateUploadUrl } from "types/generated";

const mapHeaders = (headers: StateUploadUrl["uploadHeaders"]) => {
  if (!headers) {
    return {};
  }
  return headers.entries.reduce<Record<string, string>>((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});
};

export const uploadFileToS3 = async (
  file: File,
  url: string,
  headers: StateUploadUrl["uploadHeaders"]
) => {
  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "application/json",
      ...mapHeaders(headers),
    },
  });

  if (!response.ok) {
    throw new Error("Failed to upload file to S3");
  }
};
