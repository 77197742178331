import useLocalStorage from "@rehooks/local-storage";
import { useEffect } from "react";
import { getTime } from "date-fns/esm";

import {
  createPlainDataLayer,
  getSubdomain,
  updateHubspotUser,
  updateSegmentUser,
} from "shared/Analytics";
import { AccountContext } from "views/AccountWrapper";

import useTypedContext from "../../hooks/useTypedContext";

const useFirstUserSignUp = () => {
  const { viewer, accountId } = useTypedContext(AccountContext);
  // we need to make sure that we only send the first user sign up event once as
  // it can be triggered multiple times for the same user if he is the only one logged in for account
  // The backend says siFirstUser is guaranteed to be true only for the first user to log in and before any other user has logged in.
  const [isFirstUserSet, setIsFirstUserSet] = useLocalStorage<boolean>("isFirstUserSet");

  useEffect(() => {
    // First user signup event
    if (!isFirstUserSet && viewer.isFirstUser) {
      createPlainDataLayer({
        event: "product_signup_firstuser",
        hostname: getSubdomain(),
        identityProvider: viewer.identityProvider,
        accountId,
      });

      updateHubspotUser(viewer.email, "first_login", "true");
      updateHubspotUser(viewer.email, "first_login_date", `${getTime(new Date())}`);
      updateSegmentUser("isFirstUser", true);
      setIsFirstUserSet(true);
    }
  }, [viewer, accountId, isFirstUserSet, setIsFirstUserSet]);
};

export default useFirstUserSignUp;
