import Link from "ds/components/Link";
import Box from "ds/components/Box";

import { CellComponentProps } from "./types";
import { ANALYTICS } from "../constants";
import CloudIntegrationsAzureConsentFeedback from "../../components/Azure/ConsentFeedback";
import { isAwsIntegration, isAzureIntegration } from "../../utils";

type CloudIntegrationsListNameCellProps = CellComponentProps;

const CloudIntegrationsListNameCell = ({ item }: CloudIntegrationsListNameCellProps) => {
  return (
    <Box align="center" fullWidth>
      <Box direction="column" gap="medium" fullWidth>
        <Link
          size="small"
          variant="secondary"
          href={`/cloud-integrations/${isAwsIntegration(item) ? "aws" : "azure"}/${item.id}`}
          analyticsPage={ANALYTICS.analyticsPage}
          analyticsTitle="Integration name link clicked"
          analyticsProps={{
            name: item.name,
            type: item.__typename,
          }}
        >
          {item.name}
        </Link>
        {isAzureIntegration(item) && (
          <CloudIntegrationsAzureConsentFeedback integration={item} type="banner" />
        )}
      </Box>
    </Box>
  );
};

export default CloudIntegrationsListNameCell;
