import type { OperationVariables, QueryResult } from "@apollo/client";

import { AdditionalHookOptions } from "./types";

type Options<TData = unknown> = AdditionalHookOptions<TData> & {
  skip?: boolean;
};

const shouldPoll = <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  options: Options<TData>,
  result?: QueryResult<TData, TVariables>
) => {
  if (options.skip) {
    return false;
  }

  if (result?.error) {
    return false;
  }

  if (options.shouldPoll) {
    return options.shouldPoll(result?.data);
  }

  return true;
};

export default shouldPoll;
