import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ComboBox from "ds/components/ComboBox";
import useComboBoxInputValueItem from "ds/components/ComboBox/useInputValueItem";
import FormField from "ds/components/Form/Field";
import FormToggleField from "ds/components/Form/ToggleField";
import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import { StackDependency, StackDependencyReferenceUpdateInput } from "types/generated";
import { stringIsRequired } from "utils/formValidators";
import { isStackVendorTerraform } from "utils/vendor";

import OutputReferencesDrawerComboBoxItem from "./ComboBoxItem";
import { NON_EXISTING_OUTPUT_OPTION_DESCRIPTION } from "./constants";
import { StackDependenciesOutputReferencesContextData } from "./Context";
import styles from "./styles.module.css";
import { SelectOptionOutput } from "./types";
import useAddOutputReference from "./useAddOutputReference";
import useUpdateOutputReference from "./useUpdateOutputReference";

type OutputReferenceFormFields = {
  outputName: string;
  inputName: string;
  triggerAlways: boolean;
};

type OutputReferencesDrawerFormProps = {
  outputReference?: StackDependencyReferenceUpdateInput;
  stackDependencyId: StackDependency["id"];
  onEditCancel?: () => void;
  onEditSuccess?: () => void;
  vendorTypename?: string;
  setIsDirty: (isDirty: boolean) => void;
};

const INPUT_VALUE_OPTION_PROPS = {
  description: "",
  label: "Can't find your output? Select this option to add it anyway.",
};

const OutputReferencesDrawerForm = ({
  stackDependencyId,
  outputReference,
  onEditCancel,
  onEditSuccess,
  vendorTypename,
  setIsDirty,
}: OutputReferencesDrawerFormProps) => {
  const isEditMode = !!outputReference;

  const [notExistingOutputOption, setNotExistingOutputOption] = useState<
    SelectOptionOutput | undefined
  >(undefined);

  const outputReferenceForm = useForm<OutputReferenceFormFields>({
    defaultValues: {
      outputName: outputReference?.outputName || "",
      inputName: outputReference?.inputName || "",
      triggerAlways: outputReference?.triggerAlways || false,
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isDirty },
  } = outputReferenceForm;

  const isTerraformVendor = isStackVendorTerraform(vendorTypename);
  const currentOutputName = watch("outputName");
  const inputNamePlaceholder =
    currentOutputName && isTerraformVendor ? `TF_VAR_${currentOutputName}` : "Enter input name";

  const { outputsOptions, loading } = useTypedContext(StackDependenciesOutputReferencesContextData);
  const { addOutputReference } = useAddOutputReference();
  const { updateOutputReference } = useUpdateOutputReference();

  const onSubmit: SubmitHandler<OutputReferenceFormFields> = (formData) => {
    if (isEditMode && outputReference) {
      updateOutputReference(
        [
          {
            ...outputReference,
            outputName: formData.outputName,
            inputName: formData.inputName,
            triggerAlways: formData.triggerAlways,
          },
        ],
        onEditSuccess
      );
    } else {
      addOutputReference(
        stackDependencyId,
        [
          {
            outputName: formData.outputName,
            inputName: formData.inputName,
            triggerAlways: formData.triggerAlways,
            type: null,
          },
        ],
        reset
      );
    }
  };

  const options = useMemo(
    () => [...(notExistingOutputOption ? [notExistingOutputOption] : []), ...outputsOptions],
    [notExistingOutputOption, outputsOptions]
  );

  const onInputValueSelected = useCallback(
    (value: string) => {
      setNotExistingOutputOption({
        value,
        label: value,
        description: NON_EXISTING_OUTPUT_OPTION_DESCRIPTION,
      });
    },
    [setNotExistingOutputOption]
  );

  const { comboBoxProps } = useComboBoxInputValueItem({
    onInputValueSelected,
    options,
    inputValueItemProps: INPUT_VALUE_OPTION_PROPS,
  });

  useEffect(() => {
    // add a not existing output option to the list of outputs in case editing an output reference with not existing output
    if (
      isEditMode &&
      outputReference &&
      !outputsOptions.find((option) => option.value === outputReference.outputName)
    ) {
      setNotExistingOutputOption({
        value: outputReference.outputName,
        label: outputReference.outputName,
        description: NON_EXISTING_OUTPUT_OPTION_DESCRIPTION,
      });
    }
  }, [isEditMode, outputReference, outputsOptions]);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  return (
    <Box direction="column" padding="large" gap="large" className={styles.formWrapper}>
      <Controller
        name="outputName"
        control={control}
        rules={{ required: "Output field is required." }}
        render={({ field, fieldState }) => (
          <ComboBox
            label="Select output"
            error={fieldState.error?.message}
            value={field.value}
            isLoading={loading}
            {...comboBoxProps}
            onChange={comboBoxProps.onChange(field.onChange)}
          >
            {(item) => <OutputReferencesDrawerComboBoxItem id={item.value} {...item} />}
          </ComboBox>
        )}
      />
      <FormField
        label="Input name"
        tooltipInfo="A name that this output maps to in the environment variables view."
        error={errors?.inputName?.message}
        noMargin
      >
        {({ ariaInputProps }) => (
          <Input
            placeholder={inputNamePlaceholder}
            error={!!errors?.inputName}
            {...register("inputName", {
              validate: stringIsRequired("Input name field is required."),
            })}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <Controller
        name="triggerAlways"
        control={control}
        render={({ field }) => (
          <FormToggleField
            variant="checkbox"
            title="Trigger always"
            description="when enabled, the dependent stack will be triggered regardless of whether the output has changed or not."
            checked={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Box direction="row" justify="end" gap="medium">
        {isEditMode && onEditCancel && (
          <Button variant="secondary" size="small" onPress={onEditCancel}>
            Cancel
          </Button>
        )}

        <Button variant="contrast" size="small" onPress={() => handleSubmit(onSubmit)()}>
          {isEditMode ? "Save" : "Add"}
        </Button>
      </Box>
    </Box>
  );
};

export default OutputReferencesDrawerForm;
