import { useCallback, CSSProperties, useRef } from "react";
import { Cell, Row, SortDirection } from "react-aria-components";

import Box from "ds/components/Box";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import CardWrapper from "components/CardWrapper";
import DocumentationButton from "components/DocumentationButton";
import EmptyState from "ds/components/EmptyState";
import { BlueprintIntegrationAttachment } from "types/generated";
import useTitle from "hooks/useTitle";
import TableContextProvider from "components/Table/Context";
import Table from "components/Table";
import TableRow from "components/Table/Row";
import PageInfoHeader from "ds/components/PageInfoHeader";
import { getDocsUrl } from "utils/getDocsUrl";
import TableWrapper from "components/Table/Wrapper";

import {
  INTEGRATION_USED_BY_COLUMN_CONFIG,
  INITIAL_COLUMNS_VIEW_CONFIG,
  ROW_HEADER_ID,
  INTEGRATION_USED_BY_COLUMNS_STORAGE_KEY,
  ANALYTICS,
} from "./constants";
import IntegrationUsedByListItem from "./ListItem";

type PolicyDetailsUsedByProps = {
  name: string;
  attachedBluePrints: BlueprintIntegrationAttachment[];
  loading: boolean;
};

const IntegrationUsedBy = ({ name, attachedBluePrints, loading }: PolicyDetailsUsedByProps) => {
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);

  useTitle(`Used by · ${name}`);

  const sortDescriptor = {
    column: "symbol",
    direction: "ascending" as SortDirection,
  };

  const renderRow = useCallback(
    (
      item: Partial<BlueprintIntegrationAttachment> & {
        virtIndex?: number;
        style?: CSSProperties;
        virtKey?: string;
        id: string;
        height?: number;
        ref?: (node: Element | null | undefined) => void;
      }
    ) => {
      {
        if ("height" in item && (item.id === "after" || item.id === "before")) {
          return (
            <Row>
              <Cell style={{ height: item.height }} />
            </Row>
          );
        }

        return (
          <TableRow
            index={item.virtIndex}
            ref={item.ref}
            style={item.style}
            key={item.virtKey}
            id={item.id}
            name={item.blueprintName}
          >
            <IntegrationUsedByListItem blueprint={item as BlueprintIntegrationAttachment} />
          </TableRow>
        );
      }
    },
    []
  );

  return (
    <>
      <TableContextProvider
        columnsConfig={INTEGRATION_USED_BY_COLUMN_CONFIG}
        initialColumnViewConfig={INITIAL_COLUMNS_VIEW_CONFIG}
        rowHeaderId={ROW_HEADER_ID}
        localStorageId={INTEGRATION_USED_BY_COLUMNS_STORAGE_KEY}
        setSortDescriptor={() => null}
        sortDescriptor={sortDescriptor}
        {...ANALYTICS}
      >
        <PageInfoHeader title="Used by" />

        {!!attachedBluePrints.length && (
          <TableWrapper virtualizedListContainerRef={virtualizedListContainerRef}>
            <Table
              ref={virtualizedListContainerRef}
              ariaLabel="Integrations list"
              items={attachedBluePrints}
              loadingContent={loading}
            >
              {renderRow}
            </Table>
          </TableWrapper>
        )}

        {attachedBluePrints.length === 0 && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateSearchNoResultsColored}
                title="Integrate with ServiceNow"
              >
                <Box gap="medium">
                  <DocumentationButton
                    to={getDocsUrl("/integrations/webhooks")}
                    label="Documentation"
                  />
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}
      </TableContextProvider>
    </>
  );
};

export default IntegrationUsedBy;
