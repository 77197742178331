import { Controller, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";

const AttachCloudFormAzureSubscriptionIDField = () => {
  const { control } = useFormContext<{ azureSubscriptionId: string }>();

  return (
    <Controller
      name="azureSubscriptionId"
      rules={{ required: true }}
      control={control}
      render={({ field }) => (
        <FormField noMargin label="Subscription ID">
          {({ ariaInputProps }) => <Input {...field} {...ariaInputProps} />}
        </FormField>
      )}
    />
  );
};

export default AttachCloudFormAzureSubscriptionIDField;
