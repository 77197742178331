import { Trash } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";

import { ANALYTICS } from "../constants";
import { CellComponentProps } from "../types";
import { showDeleteConfirmation } from "./DeleteConfirmation";

type PermissionsListItemActioDeletensDropdrops = CellComponentProps;

const PermissionsListItemDeleteActionsCell = ({
  permission,
}: PermissionsListItemActioDeletensDropdrops) => {
  return (
    <ButtonIcon
      {...ANALYTICS}
      variant="dangerSecondary"
      analyticsTitle="Delete clicked"
      icon={Trash}
      disabled={permission.deleteDisabled}
      onPress={() => showDeleteConfirmation({ permission })}
    >
      Delete
    </ButtonIcon>
  );
};

export default PermissionsListItemDeleteActionsCell;
