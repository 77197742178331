import Box from "ds/components/Box";

import styles from "./styles.module.css";

type BlueprintsTableWrapperProps = {
  children: React.ReactNode;
};

/** Scrolling container for Table */
const TableWrapper = ({ children }: BlueprintsTableWrapperProps) => {
  return <Box className={styles.tableWrapper}>{children}</Box>;
};

TableWrapper.displayName = "DS.Table.Wrapper";
export default TableWrapper;
