import { memo, useEffect, useMemo, useRef } from "react";

import { PollingIntervalGroups } from "apollo/constants";
import usePolledLazyQuery from "apollo/usePolledQuery/usePolledLazyQuery";
import Filters from "components/Filters";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import { useCachedFilterFields } from "components/Filters/hooks";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import {
  FilterItem,
  FiltersItemsOptionsMap,
  SavedFilterView,
  SortOption,
} from "components/Filters/types";
import FlashContext from "components/FlashMessages/FlashContext";
import { getSearchQuery } from "components/SearchInput/helpers";
import { AnalyticsPageBlueprints } from "hooks/useAnalytics/pages/blueprints";
import useTypedContext from "hooks/useTypedContext";
import useURLParams from "hooks/useURLParams";
import { SearchQueryPredicate, SearchSuggestionsFieldType } from "types/generated";

import { FILTERS_ORDER_SETTINGS_KEY, initialSortDirection, initialSortOption } from "../constants";
import { SEARCH_BLUEPRINTS_SUGGESTIONS } from "../gql";
import { blueprintsSuggestionsDictionary, sortItemOrder } from "./constants";

type BlueprintsFiltersLayoutProps = {
  predicates: SearchQueryPredicate[];
  children: React.ReactNode;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => unknown;
  setItemsCount: (count: number) => void;
  itemsCount: number;
  titleId?: string;
};

const BlueprintFiltersLayout = ({
  predicates,
  children,
  currentSavedView,
  setCurrentSavedView,
  setItemsCount,
  itemsCount,
  titleId,
}: BlueprintsFiltersLayoutProps) => {
  const fieldsRef = useRef<string[] | null>(null);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const { onError } = useTypedContext(FlashContext);

  const [loadBlueprintsSuggestions, { data: filteringData, loading, refetch }] = usePolledLazyQuery(
    SEARCH_BLUEPRINTS_SUGGESTIONS,
    { pollingGroup: PollingIntervalGroups.Lists }
  );

  const cachedFiltersData = useCachedFilterFields(
    filteringData?.searchBlueprintsSuggestions?.fields
  );

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter(
          (field) => field.orderable && field.name !== "slug" && sortItemOrder.includes(field.name)
        )
        .map((field) => {
          return {
            value: field.name,
            label: blueprintsSuggestionsDictionary[field.name] || field.name,
          };
        })
        .sort((a, b) => sortItemOrder.indexOf(a.value) - sortItemOrder.indexOf(b.value)) || []
    );
  }, [cachedFiltersData]);

  const [filters, blueprintsFiltersItemsOptionsMap] = useMemo<
    [FilterItem[], FiltersItemsOptionsMap]
  >(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        // timestamp is not a filter, it's a date range option
        .filter((field) => field.filterable && field.name !== "timestamp")
        .map((field) => {
          let key = field.name;

          if (key === "label") {
            labelsCounter += 1;
            key = `label${labelsCounter}`;
          } else {
            key = blueprintsSuggestionsDictionary[key] || key;
          }

          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key,
            filterName: field.name,
            //SearchSuggestionsFieldType is only available if the field is filterable
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const loadSearchSuggestions = (initial = false) => {
    try {
      loadBlueprintsSuggestions({
        variables: {
          input: {
            fullTextSearch: searchInput,
            predicates,
            fields: initial ? null : fieldsRef.current,
          },
        },
        onCompleted: (data) => {
          if (
            data?.searchBlueprintsSuggestions.filteredCount &&
            data?.searchBlueprintsSuggestions.filteredCount !== itemsCount
          ) {
            setItemsCount(data?.searchBlueprintsSuggestions.filteredCount);
          }
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  const handlePollingActiveSections = (fields: string[]) => {
    fieldsRef.current = fields;

    refetch({
      input: {
        fullTextSearch: searchInput,
        predicates,
        fields,
      },
    }).then(({ data }) => {
      if (
        data?.searchBlueprintsSuggestions.filteredCount &&
        data?.searchBlueprintsSuggestions.filteredCount !== itemsCount
      ) {
        setItemsCount(data?.searchBlueprintsSuggestions.filteredCount);
      }
    });
  };

  useEffect(() => {
    loadSearchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predicates, searchInput]);

  // initial request with nullish fields
  useEffect(() => {
    loadSearchSuggestions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={blueprintsFiltersItemsOptionsMap}
      filtersLoading={loading}
      sortOptions={sortOptions}
      initialSortOption={initialSortOption}
      initialSortDirection={initialSortDirection}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersDictionary={blueprintsSuggestionsDictionary}
      filtersType="blueprints"
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
    >
      <FiltersSplit>
        <FiltersSidebar analyticsPage={AnalyticsPageBlueprints.BlueprintsList} />

        <FiltersContentWrapper
          titleId={titleId}
          rowCount={filteringData?.searchBlueprintsSuggestions.filteredCount}
          role="table"
        >
          {children}
        </FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default memo(BlueprintFiltersLayout);
