import { gql, useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import Button from "ds/components/Button";
import useSpaceliftLoginsCookie from "hooks/useSpaceliftLoginsCookie";
import useTypedContext from "hooks/useTypedContext";
import setLocation from "shared/setLocation";
import { AccountContext } from "views/AccountWrapper";

export const CONFIRM_ACCOUNT_DELETE = gql`
  mutation ConfirmAccountDelete {
    accountConfirmDelete
  }
`;

const ConfirmDeleteButton = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const context = useTypedContext(AccountContext);
  const { cookieLogins, setCookieLogins } = useSpaceliftLoginsCookie();

  const removeDeletedAccountFromCookies = () => {
    const filteredLogins = (cookieLogins as string[]).filter(
      (item) => item !== context.accountName
    );
    setCookieLogins(filteredLogins);
  };

  const [confirmAccountDelete, { loading }] = useMutation(CONFIRM_ACCOUNT_DELETE);

  const onClick = () => {
    const asyncFn = async () => {
      try {
        await confirmAccountDelete();
        reportSuccess({ message: "Account was removed" });
        removeDeletedAccountFromCookies();
        setLocation("https://spacelift.io");
      } catch (error) {
        onError(error);
      }
    };

    void asyncFn();
  };

  return (
    <Button variant="dangerPrimary" onPress={onClick} disabled={loading} loading={loading}>
      Delete
    </Button>
  );
};

export default ConfirmDeleteButton;
