import { useModal } from "@ebay/nice-modal-react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ComboBox from "ds/components/ComboBox";
import ComboBoxDefaultItem from "ds/components/ComboBox/DefaultItem";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import Typography from "ds/components/Typography";
import useAttachContext from "shared/Context/useAttachContext";
import useGetAttachableContexts from "shared/Context/useGetAttachableContexts";

import { AttachContextDrawerProps, AttachContextFormFields } from "./types";

const priorityFieldOptions = {
  valueAsNumber: true,
  min: 0,
  required: true,
  validate: (value: number) => /^[0-9]+$/.test(value.toString()),
};

const AttachContextDrawer = createDrawer(
  ({ attachedContextIds, stackId, spaceId }: AttachContextDrawerProps) => {
    const drawer = useModal();
    const form = useForm<AttachContextFormFields>({
      defaultValues: {
        contextId: "",
        priority: 0,
      },
      mode: "onChange",
    });

    const { control, register, handleSubmit, formState } = form;

    const { contexts, loading } = useGetAttachableContexts({
      spaceId,
      attachedContextIds,
    });

    const { attachContext, loading: attachContextLoading } = useAttachContext([
      "GetStackAttachedContexts",
    ]);

    const onDrawerClose = () => {
      drawer.hide();
    };

    const handleAttach = async (formData: AttachContextFormFields) => {
      attachContext(formData.contextId, stackId, formData.priority, onDrawerClose);
    };

    const handleFormSubmit = () => {
      handleSubmit(handleAttach)();
    };

    const isFormSubmitting = formState.isSubmitting || attachContextLoading;

    return (
      <DrawerSimple>
        <FormProvider {...form}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Attach context" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <Banner variant="info">
              You can only attach contexts from the current space and parent spaces that you inherit
              from.
            </Banner>

            <Box margin="large 0 0" gap="large" grid gridTemplate="1fr 8rem">
              <Controller
                name="contextId"
                control={control}
                rules={{ required: "Context is required." }}
                render={({ field, fieldState }) => (
                  <ComboBox
                    label="Select context"
                    error={fieldState.error?.message}
                    items={contexts}
                    value={field.value}
                    isLoading={loading}
                    onChange={field.onChange}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Context</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            A context can only be attached once to a given stack, so if it's already
                            attached, it will not be visible in the dropdown menu.
                          </Typography>
                        </TooltipModalBody>
                      </>
                    }
                  >
                    {(item) => <ComboBoxDefaultItem id={item.value} label={item.label} />}
                  </ComboBox>
                )}
              />

              <FormField
                noMargin
                label="Priority"
                tooltipInfoVariant="modal"
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Priority</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      <Typography tag="p" variant="p-body3">
                        All the contexts attached to a stack are sorted by priority (lowest first),
                        though values don't need to be unique.
                      </Typography>
                    </TooltipModalBody>
                  </>
                }
              >
                {({ ariaInputProps }) => (
                  <Input
                    error={!!formState.errors.priority}
                    {...register("priority", priorityFieldOptions)}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            </Box>

            <DrawerFooter>
              <DrawerFooterActions>
                <Button variant="secondary" onPress={onDrawerClose} disabled={isFormSubmitting}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  onPress={handleFormSubmit}
                  loading={isFormSubmitting}
                  disabled={!formState.isValid || attachContextLoading}
                >
                  Attach
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </FormProvider>
      </DrawerSimple>
    );
  }
);
export const showAttachContextDrawer = createDrawerTrigger(AttachContextDrawer);
