import { memo } from "react";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import IacManagementEmptyCellValue from "../../components/CellEmptyValue";

const IacManagementTableResourceRowCellResourcesCount = memo(() => {
  return (
    <IacManagementTableCell>
      <IacManagementEmptyCellValue />
    </IacManagementTableCell>
  );
});

IacManagementTableResourceRowCellResourcesCount.displayName =
  "IacManagementTableResourceRowCellResourcesCount";

export default IacManagementTableResourceRowCellResourcesCount;
