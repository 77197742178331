import { AwsIntegration, AzureIntegration, SpaceAccessLevel } from "types/generated";
import { AccountViewer } from "components/form/graphql/types";

import { CloudIntegration } from "./types";

export const isAwsIntegration = (integration: CloudIntegration): integration is AwsIntegration => {
  return integration.__typename === "AwsIntegration";
};

export const isAzureIntegration = (
  integration: CloudIntegration
): integration is AzureIntegration => {
  return integration.__typename === "AzureIntegration";
};

export const hasCloudIntegrationManageAccess = (
  viewer: AccountViewer,
  integration: CloudIntegration
) => {
  const canManageItem = integration.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  return viewer.admin || canManageItem;
};

export const canDeleteCloudIntegration = (integration: CloudIntegration) => {
  return !integration.hasAttachedStacks;
};
