import Typography from "ds/components/Typography";
import { AzureIntegration } from "types/generated";

import styles from "./styles.module.css";

type DeleteConfirmationContentProps = {
  integration: AzureIntegration;
};

const AzureIntegrationDeleteConfirmationContent = ({
  integration,
}: DeleteConfirmationContentProps) => {
  return (
    <div>
      <Typography className={styles.text} tag="p" variant="p-body2" margin="0 0 large 0">
        Are you sure you want to delete the integration:
      </Typography>
      <div className={styles.details}>
        <Typography className={styles.detailsLabel} tag="p" variant="p-body2">
          Name
        </Typography>
        <Typography tag="p" variant="p-body2">
          {integration.name}
        </Typography>
        <Typography className={styles.detailsLabel} tag="p" variant="p-body2">
          Type
        </Typography>
        <Typography tag="p" variant="p-body2">
          Azure
        </Typography>
        <Typography className={styles.detailsLabel} tag="p" variant="p-body2">
          Tenant
        </Typography>
        <Typography tag="p" variant="p-body2">
          {integration.tenantId}
        </Typography>
        <Typography className={styles.detailsLabel} tag="p" variant="p-body2">
          Default subscription
        </Typography>
        <Typography tag="p" variant="p-body2">
          {integration.defaultSubscriptionId}
        </Typography>
      </div>
      <Typography className={styles.text} tag="p" variant="p-body2">
        Note that deleting the integration will not remove the Enterprise Application from your
        Azure account, but Spacelift will no-longer be able to access it.
      </Typography>
    </div>
  );
};

export default AzureIntegrationDeleteConfirmationContent;
