import { InfoCircle } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import { showLabelsInfoDrawer } from "./Drawer";
import { LABELS_INFO_DRAWER_ID } from "./constants";

const LabelsInfo = ({ analyticsPage, analyticsTitle, analyticsProps }: AnalyticsCommonProps) => {
  const isDrawerVisible = useDrawerVisibilityForId(LABELS_INFO_DRAWER_ID);

  const openDrawer = () => {
    showLabelsInfoDrawer({ id: LABELS_INFO_DRAWER_ID });
  };

  return (
    <ButtonIcon
      analyticsPage={analyticsPage}
      analyticsTitle={analyticsTitle}
      analyticsProps={analyticsProps}
      icon={InfoCircle}
      onPress={openDrawer}
      active={isDrawerVisible}
      disableTooltip={isDrawerVisible}
      variant="ghost"
    >
      Open documentation drawer
    </ButtonIcon>
  );
};

export default LabelsInfo;
