import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import { getProviderLogo } from "views/Account/Settings/Users/helpers";

import { CellComponentProps } from "../types";

type UserIdpGroupsLoginMethodCellProps = CellComponentProps;

const UserIdpGroupsLoginMethodCell = ({ group }: UserIdpGroupsLoginMethodCellProps) => {
  const ProviderIcon = getProviderLogo(group.identityProvider);

  return (
    <Box direction="row" align="center">
      {ProviderIcon && (
        <Typography tag="span" variant="p-body2">
          {typeof ProviderIcon === "string" ? (
            ProviderIcon
          ) : (
            <Icon size="large" src={ProviderIcon} />
          )}
        </Typography>
      )}
    </Box>
  );
};

export default UserIdpGroupsLoginMethodCell;
