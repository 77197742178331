import { mapDictionaryKey } from "components/Filters/helpers";
import {
  AuditTrailAccountMetadata,
  AuditTrailApiKeyMetadata,
  AuditTrailAwsIntegrationMetadata,
  AuditTrailAzureDevOpsRepoIntegrationMetadata,
  AuditTrailAzureIntegrationMetadata,
  AuditTrailBitbucketCloudIntegrationMetadata,
  AuditTrailBitbucketDatacenterIntegrationMetadata,
  AuditTrailBlueprintMetadata,
  AuditTrailContextMetadata,
  AuditTrailDriftDetectionIntegrationMetadata,
  AuditTrailExternalIntegrationMetadata,
  AuditTrailGenericFormMetadata,
  AuditTrailGithubAppInstallationMetadata,
  AuditTrailGithubEnterpriseIntegrationMetadata,
  AuditTrailGitlabIntegrationMetadata,
  AuditTrailLoginMetadata,
  AuditTrailModuleMetadata,
  AuditTrailNamedWebhooksIntegrationMetadata,
  AuditTrailPolicyMetadata,
  AuditTrailResourceMetadata,
  AuditTrailResourceType,
  AuditTrailRunMetadata,
  AuditTrailScheduledDeleteMetadata,
  AuditTrailScheduledRunMetadata,
  AuditTrailScheduledTaskMetadata,
  AuditTrailSecurityKeyMetadata,
  AuditTrailSessionMetadata,
  AuditTrailSpaceMetadata,
  AuditTrailStackMetadata,
  AuditTrailTaskMetadata,
  AuditTrailTerraformProviderMetadata,
  AuditTrailTerraformProviderVersionMetadata,
  AuditTrailUserGroupIntegrationMetadata,
  AuditTrailUserGroupMetadata,
  AuditTrailUserMetadata,
  AuditTrailVersionMetadata,
  AuditTrailWebhookMetadata,
  AuditTrailWorkerMetadata,
  AuditTrailWorkerPoolMetadata,
} from "types/generated";

import { FILTER_ITEMS_DICTIONARY, FilterItemKeys } from "./constants";

export const getFilterKey = (key: FilterItemKeys | string) => {
  return mapDictionaryKey(key, FILTER_ITEMS_DICTIONARY);
};

export const titleCase = (str: string) => {
  const separated = str.replace(/_/g, " ");

  if (separated.length === 0) {
    return "";
  }

  return separated.charAt(0).toUpperCase() + separated.slice(1).toLowerCase();
};

type Resources = {
  [AuditTrailResourceType.Stack]: AuditTrailStackMetadata;
  [AuditTrailResourceType.Run]: AuditTrailRunMetadata;
  [AuditTrailResourceType.Context]: AuditTrailContextMetadata;
  [AuditTrailResourceType.GitlabIntegration]: AuditTrailGitlabIntegrationMetadata;
  [AuditTrailResourceType.Policy]: AuditTrailPolicyMetadata;
  [AuditTrailResourceType.Space]: AuditTrailSpaceMetadata;
  [AuditTrailResourceType.Blueprint]: AuditTrailBlueprintMetadata;
  [AuditTrailResourceType.Module]: AuditTrailModuleMetadata;
  [AuditTrailResourceType.WorkerPool]: AuditTrailWorkerPoolMetadata;
  [AuditTrailResourceType.NamedWebhooksIntegration]: AuditTrailNamedWebhooksIntegrationMetadata;
  [AuditTrailResourceType.GithubEnterpriseIntegration]: AuditTrailGithubEnterpriseIntegrationMetadata;
  [AuditTrailResourceType.TerraformProvider]: AuditTrailTerraformProviderMetadata;
  [AuditTrailResourceType.AzureDevopsRepoIntegration]: AuditTrailAzureDevOpsRepoIntegrationMetadata;
  [AuditTrailResourceType.Account]: AuditTrailAccountMetadata;
  [AuditTrailResourceType.BitbucketDatacenterIntegration]: AuditTrailBitbucketDatacenterIntegrationMetadata;
  [AuditTrailResourceType.BitbucketCloudIntegration]: AuditTrailBitbucketCloudIntegrationMetadata;
  [AuditTrailResourceType.Task]: AuditTrailTaskMetadata;
  [AuditTrailResourceType.GpgKey]: AuditTrailSpaceMetadata;
  [AuditTrailResourceType.Session]: AuditTrailSessionMetadata;
  [AuditTrailResourceType.ScheduledDelete]: AuditTrailScheduledDeleteMetadata;
  [AuditTrailResourceType.ScheduledTask]: AuditTrailScheduledTaskMetadata;
  [AuditTrailResourceType.ScheduledRun]: AuditTrailScheduledRunMetadata;
  [AuditTrailResourceType.ApiKey]: AuditTrailApiKeyMetadata;
  [AuditTrailResourceType.User]: AuditTrailUserMetadata;
  [AuditTrailResourceType.Version]: AuditTrailVersionMetadata;
  [AuditTrailResourceType.DriftDetectionIntegration]: AuditTrailDriftDetectionIntegrationMetadata;
  [AuditTrailResourceType.Notification]: unknown;
  [AuditTrailResourceType.SecurityKey]: AuditTrailSecurityKeyMetadata;
  [AuditTrailResourceType.UserGroup]: AuditTrailUserGroupMetadata;
  [AuditTrailResourceType.AwsIntegration]: AuditTrailAwsIntegrationMetadata;
  [AuditTrailResourceType.AzureIntegration]: AuditTrailAzureIntegrationMetadata;
  [AuditTrailResourceType.TerraformProviderVersion]: AuditTrailTerraformProviderVersionMetadata;
  [AuditTrailResourceType.Webhook]: AuditTrailWebhookMetadata;
  [AuditTrailResourceType.Login]: AuditTrailLoginMetadata;
  [AuditTrailResourceType.Unknown]: unknown;
  [AuditTrailResourceType.GithubAppInstallation]: AuditTrailGithubAppInstallationMetadata;
  [AuditTrailResourceType.Worker]: AuditTrailWorkerMetadata;
  [AuditTrailResourceType.UserGroupIntegration]: AuditTrailUserGroupIntegrationMetadata;
  [AuditTrailResourceType.GenericForm]: AuditTrailGenericFormMetadata;
  [AuditTrailResourceType.ExternalIntegration]: AuditTrailExternalIntegrationMetadata;
};

type Resource = {
  metadata: AuditTrailResourceMetadata;
  resourceType: AuditTrailResourceType;
  resourceId: string | null;
};

const isResourceOfType = <T extends keyof Resources>(
  resource: Resource,
  wantedResourceType: T
): resource is Resource & { resourceType: T; metadata: Resources[T] } => {
  return resource.resourceType === wantedResourceType;
};

export const formatMetadata = (resource: Resource | null) => {
  if (!resource || !resource.metadata || !resource.resourceType) {
    return {
      typeLabel: "-",
      linkLabel: null,
      linkUrl: null,
    };
  }

  let typeLabel = titleCase(resource.resourceType);
  let detailLabel = null;
  let detailUrl = null;

  if (isResourceOfType(resource, AuditTrailResourceType.Stack)) {
    detailLabel = resource.metadata.name;
    detailUrl = `/stack/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Run)) {
    detailLabel = resource.metadata.id;
    detailUrl = `/stack/${resource.metadata.stackId}/run/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Context)) {
    detailLabel = resource.metadata.name;
    detailUrl = `/context/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.GitlabIntegration)) {
    typeLabel = "GitLab Integration";
    detailLabel = resource.metadata.name;
    detailUrl = `/vcs/integration/gitlab/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Policy)) {
    detailLabel = resource.metadata.name;
    detailUrl = `/policy/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Space)) {
    detailLabel = resource.metadata.name;
    detailUrl = `/spaces/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Blueprint)) {
    detailLabel = resource.metadata.name;
    detailUrl = `/blueprint/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Module)) {
    detailLabel = resource.metadata.name;
    detailUrl = `/module/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.WorkerPool)) {
    typeLabel = "Worker Pool";
    detailLabel = resource.metadata.name;
    detailUrl = `/worker-pool/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.NamedWebhooksIntegration)) {
    typeLabel = "Webhook";
    detailLabel = resource.metadata.name;
    detailUrl = `/webhook/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.GithubEnterpriseIntegration)) {
    typeLabel = "GitHub Enterprise Integration";
    detailLabel = resource.metadata.name;
    detailUrl = `/vcs/integration/github-enterprise/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.TerraformProvider)) {
    typeLabel = "Terraform Provider";
    detailLabel = resource.metadata.type;
    detailUrl = `/provider/${resource.metadata.type}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.AzureDevopsRepoIntegration)) {
    typeLabel = "Azure DevOps Repo Integration";
    detailLabel = resource.metadata.name;
    detailUrl = `/vcs/integration/azure-devops/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Account)) {
    typeLabel = "Account";
    detailLabel = resource.metadata.subdomain;
  } else if (isResourceOfType(resource, AuditTrailResourceType.BitbucketDatacenterIntegration)) {
    typeLabel = "Bitbucket Data Center Integration";
    detailLabel = resource.metadata.name;
    detailUrl = `/vcs/integration/bitbucket-data-center/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.BitbucketCloudIntegration)) {
    typeLabel = "Bitbucket Cloud Integration";
    detailLabel = resource.metadata.name;
    detailUrl = `/vcs/integration/bitbucket-cloud/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Task)) {
    detailUrl = `/stack/${resource.metadata.stackId}/run/${resource.metadata.id}`;
    detailLabel = resource.metadata.id;
  } else if (isResourceOfType(resource, AuditTrailResourceType.GpgKey)) {
    detailLabel = resource.metadata.name;
    typeLabel = "GPG Key";
  } else if (isResourceOfType(resource, AuditTrailResourceType.Session)) {
    detailLabel = resource.metadata.id;
  } else if (
    isResourceOfType(resource, AuditTrailResourceType.ScheduledDelete) ||
    isResourceOfType(resource, AuditTrailResourceType.ScheduledTask) ||
    isResourceOfType(resource, AuditTrailResourceType.ScheduledRun) ||
    isResourceOfType(resource, AuditTrailResourceType.DriftDetectionIntegration)
  ) {
    detailLabel = resource.metadata.stackId;
  } else if (isResourceOfType(resource, AuditTrailResourceType.ApiKey)) {
    typeLabel = "API Key";
    detailLabel = resource.metadata.id;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Version)) {
    detailLabel = resource.metadata.moduleId;
    detailUrl = `/module/${resource.metadata.moduleId}/version/${resource.metadata.id}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.User)) {
    detailLabel = resource.resourceId;
  } else if (isResourceOfType(resource, AuditTrailResourceType.SecurityKey)) {
    typeLabel = "Security Key";
    detailLabel = resource.metadata.name;
  } else if (isResourceOfType(resource, AuditTrailResourceType.UserGroup)) {
    typeLabel = "User Group";
    detailLabel = resource.metadata.name;
  } else if (isResourceOfType(resource, AuditTrailResourceType.AwsIntegration)) {
    typeLabel = "AWS Integration";
    detailLabel = resource.metadata.name;
  } else if (isResourceOfType(resource, AuditTrailResourceType.AzureIntegration)) {
    detailLabel = resource.metadata.name;
  } else if (isResourceOfType(resource, AuditTrailResourceType.TerraformProviderVersion)) {
    typeLabel = "Terraform Provider Version";
    detailLabel = `${resource.metadata.type} v${resource.metadata.versionNumber}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.GithubAppInstallation)) {
    typeLabel = "GitHub App Installation";
    detailLabel = resource.metadata.id;
  } else if (isResourceOfType(resource, AuditTrailResourceType.Worker)) {
    detailLabel = resource.metadata.id;
    detailUrl = `/worker-pool/${resource.metadata.workerPoolId}`;
  } else if (isResourceOfType(resource, AuditTrailResourceType.UserGroupIntegration)) {
    typeLabel = "User Group Integration";
    detailLabel = resource.metadata.name;
  }

  return {
    typeLabel,
    detailLabel,
    detailUrl,
  };
};
