import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Button from "ds/components/Button";

type MissingDataBannerProps = {
  refreshHandler: () => void;
  refreshLoading?: boolean;
  text?: string;
  fullWidth?: boolean;
};

const MissingDataBanner = ({
  refreshHandler,
  text = "Couldn't load data, please refresh or come back later",
  refreshLoading = false,
  fullWidth = false,
}: MissingDataBannerProps) => (
  <Banner variant="danger" fullWidth={fullWidth}>
    <Box gap="medium" direction="column" align="start">
      {text}
      <Button
        loading={refreshLoading}
        disabled={refreshLoading}
        size="small"
        onPress={() => refreshHandler()}
        variant="secondary"
      >
        Refresh
      </Button>
    </Box>
  </Banner>
);

export default MissingDataBanner;
