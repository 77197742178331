import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { GET_BLUEPRINT } from "./gql";

const useBlueprint = (blueprintId?: string) => {
  const { onError } = useTypedContext(FlashContext);

  return useQuery(GET_BLUEPRINT, {
    variables: {
      id: blueprintId!,
    },
    onError,
    // APOLLO CLIENT UPDATE
    nextFetchPolicy: "cache-first",
  });
};

export default useBlueprint;
