import SystemMessage from "components/SystemMessage";
import { ErrorCircleColored } from "components/icons/generated";
import { AppEnvironments } from "constants/app_environments";
import Link from "ds/components/Link";

const environment = process.env.REACT_APP_RUM_ENV || AppEnvironments.Development;

const SPACELIFT_SIGNUP_LINK =
  environment === AppEnvironments.ProductionUs
    ? "https://spacelift.io/free-trial-us"
    : "https://spacelift.io/free-trial";

const AccountNotFoundError = () => {
  return (
    <SystemMessage
      icon={ErrorCircleColored}
      title="Account not found"
      message={
        <>
          Looks like you don't have account yet.
          <br></br>
          You can <Link href={SPACELIFT_SIGNUP_LINK}>create one</Link>
        </>
      }
    />
  );
};

export default AccountNotFoundError;
