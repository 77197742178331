import { Blueprint } from "types/generated";

const getBlueprintVersionDefaults = (
  blueprint: Blueprint | undefined,
  blueprintsVersioning: boolean
) =>
  blueprintsVersioning
    ? {
        // @ts-expect-error TODO: When versionDescription is removed and api is ready this should not be ignored
        version: blueprint?.version || "0.0.1",
        // @ts-expect-error TODO: When versionDescription is removed and api is ready this should not be ignored
        versionDescription: blueprint?.versionDescription || "",
      }
    : {};

export default getBlueprintVersionDefaults;
