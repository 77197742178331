import Typography from "ds/components/Typography";

const BlueprintIntegrationsListItemTypeCell = () => {
  return (
    <Typography variant="p-body3" tag="p">
      ServiceNow
    </Typography>
  );
};

export default BlueprintIntegrationsListItemTypeCell;
