import Box from "ds/components/Box";
import BadgeNext from "ds/components/BadgeNext";

import { CellComponentProps } from "../types";

type PermissionsListItemSubjectCellProps = CellComponentProps;

const PermissionsLisSubjectNameCell = ({ permission }: PermissionsListItemSubjectCellProps) => {
  return (
    <Box fullWidth>
      <BadgeNext type="strong" variant="gray" text={permission.subject} />
    </Box>
  );
};

export default PermissionsLisSubjectNameCell;
