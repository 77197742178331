export enum ResourcesSuggestions {
  StackName = "stack",
  Parent = "parent",
  Provider = "provider",
  Drifted = "drifted",
  Label = "label",
  Space = "space",
  EntityId = "id",
  ResourcesCount = "count",
  ResourceType = "type",
  Vendor = "vendor",
  UpdatedAt = "updatedAt",
}

export const SEARCH_MANAGED_ENTITIES_SUGGESTIONS_DICTIONARY: Record<string, string> = {
  [ResourcesSuggestions.Parent]: "parent entity",
  [ResourcesSuggestions.Provider]: "provider",
  [ResourcesSuggestions.StackName]: "stack name",
  [ResourcesSuggestions.ResourceType]: "resource type",
  [ResourcesSuggestions.Drifted]: "drifted",
  [ResourcesSuggestions.Space]: "space",
  [ResourcesSuggestions.Vendor]: "vendor",
  [ResourcesSuggestions.UpdatedAt]: "updated at",
};
