import { useCallback, useMemo, useRef } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { PolicyTemplate, SearchPolicyTemplatesOutput } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import { uniqByKey } from "utils/uniq";
import fetchMoreUpdateQueryPreviousFix from "apollo/fetchMoreUpdateQueryPreviousFix";

import { ENTITIES_PER_PAGE, INITIAL_SORT_DIRECTION, INITIAL_SORT_OPTION } from "./constants";
import { SEARCH_TEMPLATES } from "./gql";

const useSearchTemplates = () => {
  const { onError } = useTypedContext(FlashContext);
  const cachedEdges = useRef<PolicyTemplate[]>([]);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { error, loading, data, previousData, fetchMore } = useQuery<{
    searchPolicyTemplates: SearchPolicyTemplatesOutput;
  }>(SEARCH_TEMPLATES, {
    variables: {
      input: {
        predicates,
        fullTextSearch: searchInput,
        first: ENTITIES_PER_PAGE,
        after: null,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    onError,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const rawSearchTemplates = useMemo(() => {
    const dataPolicyTemplates = data?.searchPolicyTemplates || previousData?.searchPolicyTemplates;

    return dataPolicyTemplates;
  }, [data?.searchPolicyTemplates, previousData?.searchPolicyTemplates]);

  const memoizedEntities = useMemo(() => {
    const sourceEdges = rawSearchTemplates?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedEdges.current : sourceEdges;

    if (!loading) {
      cachedEdges.current = sourceEdges;
    }

    return edges;
  }, [rawSearchTemplates?.edges, loading]);

  const isItemLoaded = useCallback(
    (value: number) => value < memoizedEntities.length,
    [memoizedEntities.length]
  );

  const loadMoreItems = async () => {
    try {
      if (
        data?.searchPolicyTemplates?.pageInfo.endCursor &&
        data?.searchPolicyTemplates?.pageInfo.hasNextPage
      ) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            const previousData = fetchMoreUpdateQueryPreviousFix(
              !!prev?.searchPolicyTemplates,
              prev,
              data
            );

            if (!fetchMoreResult || !fetchMoreResult?.searchPolicyTemplates) return previousData;

            const result = fetchMoreResult?.searchPolicyTemplates;

            if (!result || !result?.edges?.length) return previousData;

            const edges = uniqByKey(
              [...(previousData?.searchPolicyTemplates?.edges || []), ...result.edges],
              "cursor"
            );

            return {
              searchPolicyTemplates: {
                ...result,
                edges,
              },
            };
          },
          variables: {
            input: {
              first: ENTITIES_PER_PAGE,
              after: data.searchPolicyTemplates.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  return {
    isItemLoaded,
    loadMoreItems,
    entities: memoizedEntities,
    error,
    loading,
    isPageEmpty: !!(data && !memoizedEntities.length && !searchInput && predicates.length === 0),
    hasNoFilteringResults:
      !!data && !memoizedEntities.length && (!!searchInput || predicates.length > 0),
  };
};

export default useSearchTemplates;
