import Box from "ds/components/Box";
import Timestamp from "components/Timestamp";

import { CellComponentProps } from "../types";

type UsersListItemLastLoginCellProps = CellComponentProps;

const UsersListItemLastLoginCell = ({ user }: UsersListItemLastLoginCellProps) => {
  if (!user.lastLoginTime) {
    return null;
  }

  return (
    <Box direction="row" align="center">
      {user.lastLoginTime && <Timestamp timestamp={user.lastLoginTime} />}
    </Box>
  );
};

export default UsersListItemLastLoginCell;
