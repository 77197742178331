import { gql, TypedDocumentNode } from "@apollo/client";

import {
  MutationBlueprintAttachToIntegrationArgs,
  BlueprintIntegrationAttachment,
  ExternalIntegration,
  QueryAttachableExternalIntegrationInSpaceArgs,
} from "types/generated";

type AttachBlueprintIntegrationsData = {
  blueprintAttachToIntegration: Pick<BlueprintIntegrationAttachment, "id">;
};

export const ATTACH_BLUEPRINT_INTEGRATION: TypedDocumentNode<
  AttachBlueprintIntegrationsData,
  MutationBlueprintAttachToIntegrationArgs
> = gql`
  mutation AttachBlueprintIntegration($id: ID!, $input: BlueprintAttachInput!) {
    blueprintAttachToIntegration(id: $id, input: $input) {
      id
    }
  }
`;

type FetchBlueprintIntegrationsInSpaceData = {
  attachableExternalIntegrationInSpace: Pick<
    ExternalIntegration,
    "id" | "name" | "description" | "createdAt"
  >[];
};

export const GET_ATTACHABLE_INTEGRATIONS_IN_SPACE: TypedDocumentNode<
  FetchBlueprintIntegrationsInSpaceData,
  QueryAttachableExternalIntegrationInSpaceArgs
> = gql`
  query GetAttachableIntegrationsInSpace($id: ID!) {
    attachableExternalIntegrationInSpace(id: $id) {
      id
      name
      description
      createdAt
    }
  }
`;
