import { SortDescriptor } from "react-aria-components";

import { USER_IDP_GROUPS_COLUMN_ID } from "../constants";
import { IdpGroup } from "../types";

export const sortGroups = (items: IdpGroup[], sortDescriptor: SortDescriptor) => {
  if (!sortDescriptor.column) {
    return items;
  }

  const isAscending = sortDescriptor.direction === "ascending";

  return [...items].sort((a, b) => {
    let valueA: string | undefined;
    let valueB: string | undefined;

    switch (sortDescriptor.column as USER_IDP_GROUPS_COLUMN_ID) {
      case USER_IDP_GROUPS_COLUMN_ID.NAME:
        valueA = a.groupName;
        valueB = b.groupName;
        break;
      case USER_IDP_GROUPS_COLUMN_ID.SPACE:
        valueA = a.spaceCount.toString();
        valueB = b.spaceCount.toString();
        break;
      case USER_IDP_GROUPS_COLUMN_ID.STATUS:
        valueA = a.status;
        valueB = b.status;
        break;
      case USER_IDP_GROUPS_COLUMN_ID.LOGIN_METHOD:
        valueA = "";
        valueB = "";
        break;
    }

    valueA = valueA || "";
    valueB = valueB || "";

    return isAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
  });
};
