import { gql } from "@apollo/client";

export const GET_ATTACHED_STACK_INTEGRATIONS = gql`
  query GetAttachedStackIntegrations($stackId: ID!) {
    stack(id: $stackId) {
      integrations {
        awsV2 {
          id
          read
          write
          durationSeconds
          externalId
          name
          roleArn
        }
        azure {
          id
          read
          write
          defaultSubscriptionId
          subscriptionId
          integrationName
        }
      }
    }
  }
`;
