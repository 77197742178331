import { Routes, Route, Navigate } from "react-router-dom";

import useTypedFlags from "hooks/useTypedFlags";
import { ApiKey } from "types/generated";
import NotFoundPage from "components/error/NotFoundPage";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";

import SettingsSingleSignOn from "./SingleSignOn";
import ApiKeys from "./ApiKeysNew";
import Billing from "./billing/Billing";
import SecuritySettings from "./SecuritySettings";
import Sessions from "./Sessions";
import Users from "./Users";
import ManageMFA from "./ManageMFA";
import IdpGroupMapping from "./IdpGroupMapping";
import ManagementStrategy from "./ManagementStrategy";
import LoginPolicies from "./LoginPolicies";
import SettingsIntegrations from "./Integrations";
import Usage from "./Usage";
import Limits from "./Limits";
import BillingNew from "./BillingNew";
import { isV4BillingTier } from "./BillingNew/utils";
import { SubscriptionContext } from "../SubscriptionWrapper";
import AuditTrailInfo from "./AuditTrail/Info";
import AISettings from "./AI";
import Spaces from "./Spaces";
import { SpacesContext } from "../SpacesProvider";
import Roles from "./Roles";
import RoleCreate from "./Role/Create";
import SelfHostedUsageExport from "./SelfHostedUsageExport";
import RoleEdit from "./Role/Edit";
import Role from "./Role";
import User from "./User";

const isSaas = isSaasDistribution();
const isSelfHosted = isSelfHostedDistribution();

type SettingsRoutesSelfHostedProps = {
  apiKeys: ApiKey[];
  securityEmail: undefined;
};
type SettingsRoutesSaaSProps = SettingsRoutesSelfHostedProps & {
  securityEmail: string;
};

type SettingsRoutesProps = SettingsRoutesSaaSProps | SettingsRoutesSelfHostedProps;

const SettingsRoutes = ({ apiKeys, securityEmail }: SettingsRoutesProps) => {
  const { billingUsage, useBillingV4, runSummarizationAi, customAccessRoles } = useTypedFlags();

  const { hasRootSpaceAccess } = useTypedContext(SpacesContext);

  const { tier } = useTypedContext(SubscriptionContext);

  return (
    <Routes>
      <Route index element={<Navigate to="users" replace />} />

      <Route path="sso">
        <Route index path="*" element={<SettingsSingleSignOn />} />
      </Route>

      <Route path="mfa" element={<ManageMFA />} />
      <Route path="slack" element={<Navigate to="/settings/integrations" replace />} />
      <Route path="api-keys" element={<ApiKeys apiKeys={apiKeys} />} />
      {hasRootSpaceAccess && <Route path="spaces" element={<Spaces />} />}
      <Route path="login-policy" element={<LoginPolicies />} />
      <Route path="audit-trail" element={<AuditTrailInfo />} />
      {isSaas && <Route path="limits" element={<Limits />} />}
      {isSaas && (
        <Route path="billing">
          <Route
            index
            path="*"
            element={useBillingV4 && isV4BillingTier(tier) ? <BillingNew /> : <Billing />}
          />
        </Route>
      )}
      {isSaas && billingUsage && (
        <Route path="usage">
          <Route index path="*" element={<Usage />} />
        </Route>
      )}

      {isSaas && typeof securityEmail !== "undefined" && (
        <Route path="security" element={<SecuritySettings securityEmail={securityEmail} />} />
      )}
      <Route path="sessions" element={<Sessions />} />
      <Route path="users" element={<Users />} />
      <Route path="idp-group-mapping" element={<IdpGroupMapping />} />
      <Route path="management-strategy" element={<ManagementStrategy />} />

      {customAccessRoles && (
        <>
          <Route path="roles" element={<Roles />} />
          <Route path="new/role" element={<RoleCreate />} />
          <Route path="role/:roleId/edit" element={<RoleEdit />} />
          <Route path="role/:roleId" element={<Role />} />
          <Route path="user/:userId">
            <Route index path="*" element={<User />} />
          </Route>
        </>
      )}
      <Route path="integrations" element={<SettingsIntegrations />} />
      {runSummarizationAi && <Route path="ai" element={<AISettings />} />}
      {isSelfHosted && <Route path="usage-export" element={<SelfHostedUsageExport />} />}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default SettingsRoutes;
