import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { GET_USER_GROUPS } from "./gql";

type UseGetUserGroupsProps = {
  id?: string;
};

const useGetUserGroups = ({ id }: UseGetUserGroupsProps) => {
  const { onError } = useTypedContext(FlashContext);
  const { data, loading, error } = useQuery(GET_USER_GROUPS, {
    onError,
    variables: {
      id: id!,
    },
    skip: !id,
  });

  const groups = useMemo(
    () =>
      data?.managedUserGroups.filter((group) =>
        data?.managedUser.latestGroups.includes(group.groupName)
      ),
    [data?.managedUserGroups, data?.managedUser.latestGroups]
  );

  return { groups, loading, error, user: data?.managedUser };
};

export default useGetUserGroups;
