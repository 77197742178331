import Box from "ds/components/Box";
import UserGroupStatusBadge from "views/Account/Settings/IdpGroupMapping/UserGroupStatusBadge";

import { CellComponentProps } from "../types";

type UserIdpGroupsListItemStatusCellProps = CellComponentProps;

const UserIdpGroupsListItemStatusCell = ({ group }: UserIdpGroupsListItemStatusCellProps) => {
  return (
    <Box>
      <UserGroupStatusBadge status={group.status} />
    </Box>
  );
};

export default UserIdpGroupsListItemStatusCell;
