import { create, show, useModal } from "@ebay/nice-modal-react";

import CodeEditor from "components/CodeEditor";
import Button from "ds/components/Button";
import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import ModalHeader from "ds/components/Modal/Header";
import ModalSimple from "ds/components/ModalSimple";

import { prettify } from "./helpers";
import styles from "./styles.module.css";
import { EncodedJsonValue } from "./types";

type JsonModalProps = {
  value: EncodedJsonValue | null;
};

const JsonModal = create(function JsonModal({ value }: JsonModalProps) {
  const modal = useModal();

  const handleClose = () => {
    modal.hide();
    modal.resolve();
  };

  return (
    <ModalSimple size="large">
      <ModalHeader title="JSON View" />
      <ModalContent>
        {value && (
          <CodeEditor
            body={prettify(value)}
            language="json"
            className={styles.jsonViewEditor}
            options={{
              glyphMargin: false,
              unicodeHighlight: {
                invisibleCharacters: false,
              },
            }}
            readOnly
          />
        )}
      </ModalContent>
      <ModalFooter>
        <Button variant="primary" onPress={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalSimple>
  );
});

export const showJsonModal = (props: JsonModalProps) => show(JsonModal, props);
