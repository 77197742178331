import { CalendarCell, CalendarGrid, Heading } from "react-aria-components";

import { ChevronLeft, ChevronRight } from "components/icons/generated";

import Box from "../Box";
import ButtonIcon from "../ButtonIcon";
import styles from "./styles.module.css";

const DatepickerCalendarContent = () => (
  <>
    <Box align="center" justify="between" margin="0 0 large 0">
      <ButtonIcon slot="previous" icon={ChevronLeft} tooltipMode="parent" variant="ghost">
        Previous
      </ButtonIcon>
      <Heading className={styles.calendarHeading} />
      <ButtonIcon slot="next" icon={ChevronRight} tooltipMode="parent" variant="ghost">
        Next
      </ButtonIcon>
    </Box>
    <CalendarGrid weekdayStyle="short">
      {(date) => <CalendarCell className={styles.calendarCell} date={date} />}
    </CalendarGrid>
  </>
);

DatepickerCalendarContent.displayName = "DS.Datepicker.CalendarContent";

export default DatepickerCalendarContent;
