import { useQuery } from "@apollo/client";
import { useId } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import FlashContext from "components/FlashMessages/FlashContext";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import PageInfo from "components/PageWrapper/Info";
import PrivateProviderRegistryTierInfo from "components/TierInfo/PrivateProviderRegistryTierInfo";
import ViewHeader from "components/ViewHeader";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import NotFoundPage from "components/error/NotFoundPage";
import { Incognito, Network, Space } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import EmptyState from "ds/components/EmptyState";
import Link from "ds/components/Link";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { SpaceAccessLevel } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";
import { AccountContext } from "views/AccountWrapper";

import { showProviderDeleteConfirmation } from "./ProviderDeleteConfirmation";
import { showProviderDetailsDrawer } from "./ProviderDetailsDrawer";
import { showProviderDrawer } from "./ProviderDrawer";
import ProviderVersion from "./ProviderVersion";
import { FILTERS_ORDER_SETTINGS_KEY_PROVIDERS } from "./constants";
import { GET_PROVIDER_WITH_VERSIONS } from "./gql";
import styles from "./styles.module.css";
import { GetProviderWithVersionsGql } from "./types";
import useDeleteProvider from "./useDeleteProvider";

const Provider = () => {
  const { providerId } = useParams<{ providerId: string }>();
  const { viewer } = useTypedContext(AccountContext);
  const { reportSuccess, reportError } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const handleOpenUpdateProviderDrawer = () => {
    showProviderDrawer({
      provider,
    });
  };

  const [deleteProvider] = useDeleteProvider({
    refetchQueries: ["GetProviders"],
  });

  const handleDeleteProvider = () => {
    if (providerId) {
      deleteProvider({ id: providerId }, (data) => {
        if (data?.terraformProviderDelete?.deleted) {
          navigate("/providers");
          reportSuccess({
            message: `Terraform provider "${data.terraformProviderDelete.id}" was successfully deleted`,
          });
        } else {
          reportError({
            message: "Something went wrong while deleting Terraform provider, please try again.",
          });
        }
      });
    }
  };

  const handleDeleteProviderConfirmation = () => {
    if (providerId) {
      showProviderDeleteConfirmation({
        providerId,
        onConfirm: handleDeleteProvider,
      });
    }
  };

  const handleOpenDetailsDrawer = () => {
    if (!provider) {
      return;
    }

    showProviderDetailsDrawer({
      provider,
      onEdit: handleOpenUpdateProviderDrawer,
    });
  };

  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data } = useQuery<GetProviderWithVersionsGql>(
    GET_PROVIDER_WITH_VERSIONS,
    {
      variables: {
        id: providerId,
      },
      onError,
      nextFetchPolicy: "cache-first",
    }
    // APOLLO CLIENT UPDATE
  );

  const provider = data?.terraformProvider;

  const canManageProvider =
    viewer.admin || provider?.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  useBreadcrumbs(
    [
      {
        title: "Terraform registry",
        link: getBreadcrumbsBackUrl("/modules", FILTERS_ORDER_SETTINGS_KEY_PROVIDERS),
      },
      {
        title: "Providers",
        link: getBreadcrumbsBackUrl("/providers", FILTERS_ORDER_SETTINGS_KEY_PROVIDERS),
      },
      {
        title: provider?.id || "",
      },
    ],
    [provider?.id]
  );

  useTitle(`Provider · ${provider?.id}`);

  const ErrorContent = useErrorHandle(error);

  const versionTitle = useId();

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !provider?.id) {
    return <PageLoading />;
  }

  if (!provider?.id) {
    return <NotFoundPage />;
  }

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{provider.id}</ViewHeaderTitle>
          </ViewHeaderWrapper>
          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <Button variant="secondary" onPress={handleOpenDetailsDrawer}>
              Details
            </Button>

            {canManageProvider && (
              <DropdownMenuEllipsis tooltip="Provider actions" dotsSize="medium">
                <DropdownMenuItem onAction={handleOpenUpdateProviderDrawer}>Edit</DropdownMenuItem>

                <DropdownMenuItem onAction={handleDeleteProviderConfirmation} danger>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuEllipsis>
            )}
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={Space}
              linkText={provider.spaceDetails.name}
              href={`/spaces/${provider.space}`}
            />
            <MetaInfoListItem icon={provider?.public ? Network : Incognito}>
              {provider.public ? "Public" : "Private"}
            </MetaInfoListItem>
            {provider.latestVersionNumber && (
              <MetaInfoListItem>{provider.latestVersionNumber}</MetaInfoListItem>
            )}
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
      </ViewHeader>
      <PrivateProviderRegistryTierInfo type="callout" />
      <Box direction="column" grow="1" fullWidth>
        <PageInfo title="Versions" titleId={versionTitle} />

        {!loading && provider.versions.length === 0 && (
          <EmptyState
            title="You have not published any versions of this provider"
            caption={
              <>
                Publishing is normally done through a CI/CD system, but you can also do it manually
                from your workstation. You can learn more about publishing Terraform providers{" "}
                <Link
                  size="small"
                  href={getDocsUrl("/vendors/terraform/provider-registry#publishing-a-provider")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </Link>
                .
              </>
            }
          />
        )}

        <Box
          direction="column"
          gap="large"
          className={styles.list}
          role="table"
          aria-colcount={-1}
          aria-labelledby={versionTitle}
        >
          {provider.versions.map((version) => (
            <ProviderVersion key={version.id} version={version} provider={provider} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Provider;
