import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";

import { useRunShortcut } from "../../hooks/useRunShortcut";
import { RETRY_RUN } from "./gql";

type RetryRunButtonProps = {
  runId: string;
  stackId: string;
  runRetryBlocker: string | null;
};

const RetryRunButton = ({ runId, stackId, runRetryBlocker }: RetryRunButtonProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const [retryRun, { loading, data }] = useMutation(RETRY_RUN, {
    variables: { stackId, runId },
    refetchQueries: ["GetRun"],
    awaitRefetchQueries: true,
  });

  const onClick = useCallback(() => {
    retryRun()
      .then(({ data }) => {
        if (data?.runRetry?.id) {
          const runId = data.runRetry.id;
          reportSuccess({ message: `Retry run ${runId} successfully triggered` });
          navigate(`/stack/${stackId}/run/${runId}`);
        }
      })
      .catch(onError);
  }, [retryRun, onError, reportSuccess, navigate, stackId]);

  const isLoading = loading || !!data?.runRetry?.id;

  useRunShortcut({
    label: "Retry",
    code: "alt+r",
    callback: onClick,
    disabled: !!runRetryBlocker,
    loading: isLoading,
  });

  return (
    <Tooltip
      on={(props) => (
        <Button
          {...props}
          disabled={isLoading || !!runRetryBlocker}
          loading={isLoading}
          onPress={onClick}
          variant="secondary"
          size="small"
        >
          Retry
        </Button>
      )}
      active={!!runRetryBlocker}
    >
      {runRetryBlocker}
    </Tooltip>
  );
};

export default RetryRunButton;
