import { ReactNode } from "react";

import Box from "ds/components/Box";

type ViewHeaderNavigationProps = {
  children: ReactNode;
  role?: string;
};

const ViewHeaderNavigation = ({ children, role }: ViewHeaderNavigationProps) => {
  return (
    <Box align="center" justify="start" margin="medium 0 0" gap="medium" fullWidth role={role}>
      {children}
    </Box>
  );
};

export default ViewHeaderNavigation;
