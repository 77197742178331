import { NetworkStatus } from "@apollo/client/core/networkStatus";
import { useCallback, useEffect, useMemo, useState } from "react";

import usePolledQuery from "apollo/usePolledQuery";
import FlashContext from "components/FlashMessages/FlashContext";
import { useDebounce } from "hooks/useDebounce";
import useTypedContext from "hooks/useTypedContext";
import { SearchStacksOutput, SpaceAccessLevel } from "types/generated";
import { uniqByKey } from "utils/uniq";
import { hasSpaceAccessAtLeast } from "utils/user";
import { PollingIntervalGroups } from "apollo/constants";

import { SEARCH_DEPENDENCIES_STACKS } from "../gql";

const STACKS_LIMIT = 50;

const useSearchStacks = (requiredAccessLevel: SpaceAccessLevel, exclude: string[] = []) => {
  const [searchInput, setSearchInput] = useState("");
  const debouncedSearchInput = useDebounce(searchInput);

  const { onError } = useTypedContext(FlashContext);

  const { data, fetchMore, networkStatus, refetch, loading, previousData } = usePolledQuery<{
    searchStacks: SearchStacksOutput;
  }>(SEARCH_DEPENDENCIES_STACKS, {
    variables: {
      input: {
        after: null,
        first: STACKS_LIMIT,
        fullTextSearch: debouncedSearchInput,
        orderBy: { field: "name", direction: "DESC" },
      },
    },
    onError,
    pollingGroup: PollingIntervalGroups.Lists,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const stacks = useMemo(
    () => (data?.searchStacks || previousData?.searchStacks)?.edges.map((edge) => edge.node) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.searchStacks?.edges]
  );

  const filteredStacks = useMemo(
    () =>
      stacks.filter(
        (stack) =>
          !exclude.includes(stack.id) &&
          hasSpaceAccessAtLeast(stack.spaceDetails.accessLevel, requiredAccessLevel)
      ),
    [stacks, exclude, requiredAccessLevel]
  );

  const hasMore =
    !!data?.searchStacks.pageInfo.endCursor && !!data?.searchStacks.pageInfo.hasNextPage;

  const handleLoadMore = async () => {
    try {
      if (hasMore) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.searchStacks.edges.length > 0) {
              return {
                searchStacks: {
                  ...fetchMoreResult.searchStacks,
                  edges: uniqByKey(
                    [...(prev.searchStacks.edges || []), ...fetchMoreResult.searchStacks.edges],
                    "cursor"
                  ),
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: STACKS_LIMIT,
              after: data.searchStacks.pageInfo.endCursor,
              fullTextSearch: debouncedSearchInput,
              orderBy: { field: "name", direction: "DESC" },
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const stacksQueryRefetch = useCallback(
    async (fullTextSearch: string) => {
      try {
        await refetch({
          input: {
            first: STACKS_LIMIT,
            after: null,
            fullTextSearch,
            orderBy: { field: "name", direction: "DESC" },
          },
        });
      } catch (e) {
        onError(e);
      }
    },
    [onError, refetch]
  );

  useEffect(() => {
    void stacksQueryRefetch(debouncedSearchInput);
  }, [debouncedSearchInput, stacksQueryRefetch]);

  return {
    stacks: filteredStacks,
    loading,
    isLoadingMore: networkStatus === NetworkStatus.fetchMore,
    handleLoadMore,
    hasMore,
    searchInput,
    setSearchInput,
  };
};

export default useSearchStacks;
