import { FormEventHandler, ReactNode } from "react";

import CardWrapper from "components/CardWrapper";

type AttachCloudFormCardFormProps = {
  children: ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
};

const AttachCloudFormCardForm = ({ children, onSubmit }: AttachCloudFormCardFormProps) => {
  return (
    <form onSubmit={onSubmit}>
      <CardWrapper variant="filled" gap="large" direction="column" margin="large 0">
        {children}
      </CardWrapper>
    </form>
  );
};

export default AttachCloudFormCardForm;
