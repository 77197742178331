import Typography from "ds/components/Typography";

import { CellComponentProps } from "../types";

type UserIdpGroupsListItemNameCellProps = CellComponentProps;

const UserIdpGroupsListItemNameCell = ({ group }: UserIdpGroupsListItemNameCellProps) => {
  return (
    <Typography tag="p" variant="p-body3">
      {group.groupName}
    </Typography>
  );
};

export default UserIdpGroupsListItemNameCell;
