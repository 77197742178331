import cx from "classnames";
import debounce from "lodash-es/debounce";
import { useCallback, useEffect, useId, useMemo, useState } from "react";

import { FiltersContext } from "components/Filters";
import {
  ActiveFilter,
  FilterItem,
  FilterItemOption,
  HierarchyFilterItemOption,
} from "components/Filters/types";
import { ChevronRight, Cross } from "components/icons/generated";
import { Spinner } from "components/Spinner";
import Autocomplete from "ds/components/Autocomplete";
import BaseActionButton from "ds/components/BaseAction/Button";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";
import Counter from "ds/components/Counter";
import Icon from "ds/components/Icon";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import { SearchSuggestionsFieldType } from "types/generated";
import { escapeRegex } from "utils/strings";

import { projectRootFilter } from "../../helpers";
import { ADD_ANOTHER_LABEL_TEXT } from "../constants";
import FilterOptionsList from "./FilterOptionsList";
import HierarchyFilterOptionsList from "./HierarchyFilterOptionsList";
import FilterSectionContentActions from "./SectionActions";
import FilterSectionContentHeader from "./SectionHeader";
import styles from "./styles.module.css";

type FilterSectionContentProps = {
  isActive: boolean;
  isOpen: boolean;
  isLabelFilter: boolean;
  isLastLabelsFilter: boolean;
  filterName: string;
  filterItem: FilterItem;
  toggleSectionOpen: () => void;
  addAnotherLabelFilter: () => void;
  activeFilter: ActiveFilter | undefined;
  analyticsPage?: AnalyticsPage;
  disabled?: boolean;
};

const FilterSectionContent = ({
  isLabelFilter,
  isLastLabelsFilter,
  filterName,
  filterItem,
  toggleSectionOpen,
  addAnotherLabelFilter,
  activeFilter,
  analyticsPage,
  disabled,
  isActive,
  isOpen,
}: FilterSectionContentProps) => {
  const { setActiveFilter, filtersItemsOptionsMap, filtersLoading, deleteActiveFilter } =
    useTypedContext(FiltersContext);
  const titleId = useId();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const [propertyValues, syncPropertyValues] = useState(
    () => filtersItemsOptionsMap.get(filterItem.filterName) || []
  );

  const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set(activeFilter?.values));
  const [searchInput, setSearchInput] = useState("");

  // Sync filter values for clickable metadata
  useEffect(() => {
    setSelectedValues(new Set(activeFilter?.values));
  }, [activeFilter?.values, setSelectedValues]);

  const filteredPropertyValues = useMemo<FilterItemOption[] | HierarchyFilterItemOption[]>(() => {
    const propertyValuesMap = new Map(
      propertyValues.map((item: FilterItemOption | HierarchyFilterItemOption) => [
        "id" in item ? item.id : item.value,
        item,
      ])
    );

    activeFilter?.values.forEach((value) => {
      if (
        !propertyValuesMap.has(value) &&
        activeFilter.type !== SearchSuggestionsFieldType.Hierarchy
      ) {
        propertyValuesMap.set(value, {
          value,
          count: undefined,
          type: activeFilter.type,
        });
      }
    });

    // sort wildcards to the top of list
    const propertyValuesUniqueByValue = [...propertyValuesMap.values()].sort((a, b) => {
      if (a.type === SearchSuggestionsFieldType.String && (a.value as string).includes("*")) {
        return -1;
      }

      if (b.type === SearchSuggestionsFieldType.String && (b.value as string).includes("*")) {
        return 1;
      }

      return 0;
    });

    if (!searchInput) return propertyValuesUniqueByValue;

    const reg = new RegExp(escapeRegex(searchInput), "i");

    return propertyValuesUniqueByValue.filter((item) => reg.test(item.value.toString())) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyValues, activeFilter?.values, searchInput]);

  const handleApplyFilter = useCallback(
    (values: string[]) => {
      setActiveFilter({
        ...filterItem,
        values,
      });
      trackSegmentAnalyticsEvent?.("Filter applied", {
        filterName: filterItem.filterName,
      });
    },
    [filterItem, setActiveFilter, trackSegmentAnalyticsEvent]
  );

  const debouncedApplyFilter = useMemo(() => {
    return debounce(handleApplyFilter, 500);
  }, [handleApplyFilter]);

  // TODO: uncomment when backend optimize "select all" behavior
  // const handleSelectAll = (e: MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation();
  //   const allValues = filteredPropertyValues.map((item) => item.value.toString());
  //   setSelectedValues(new Set(allValues));
  //   debouncedApplyFilter(allValues);
  // };

  const handleResetAll = useCallback(() => {
    setSelectedValues(new Set());
    debouncedApplyFilter([]);
    setSearchInput("");
  }, [debouncedApplyFilter]);

  const handleSelectValue = useCallback(
    (value: string) => {
      if (selectedValues.has(value)) {
        selectedValues.delete(value);
      } else {
        selectedValues.add(value);
      }

      setSelectedValues(new Set(selectedValues));
      debouncedApplyFilter(Array.from(selectedValues));
    },
    [selectedValues, debouncedApplyFilter]
  );

  const handleAddWildcard = useCallback((value: string) => {
    handleSelectValue(value);
    setSearchInput("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isHierarchy = filterItem.type === SearchSuggestionsFieldType.Hierarchy;
  const isSearchable = filterItem.type === SearchSuggestionsFieldType.String || isHierarchy;
  const searchInputIsWildcard = searchInput.includes("*");
  const isProjectRootFilter = projectRootFilter(filterItem.filterName);
  // TODO: uncomment when backend optimize "select all" behavior
  // const allSelected = selectedValues.size === filteredPropertyValues.length;

  const handleAutocompleteEnterKey = useCallback(() => {
    if (searchInputIsWildcard && !isHierarchy) {
      handleAddWildcard(searchInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const handleDeleteFilter = useCallback(() => {
    if (activeFilter) {
      deleteActiveFilter(activeFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);

  useEffect(() => {
    if (!filtersLoading && isOpen) {
      syncPropertyValues(filtersItemsOptionsMap.get(filterItem.filterName) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersLoading, filtersItemsOptionsMap, isOpen]);

  return useMemo(() => {
    return (
      <>
        <div
          className={cx(styles.section, {
            [styles.labelFilter]: isLabelFilter,
          })}
        >
          <div className={styles.sectionHeader}>
            <Box align="center" gap="medium">
              <ButtonIcon
                aria-expanded={isOpen}
                aria-label={filterName}
                variant="ghost"
                icon={ChevronRight}
                iconRotate={isOpen ? "90" : undefined}
                onPress={toggleSectionOpen}
              >
                {isOpen ? "Collapse filter options" : "Show filter options"}
              </ButtonIcon>
              <Box justify="start" grow="1" gap="medium">
                <FilterSectionContentHeader id={titleId} name={filterName} />
                {activeFilter && isActive && !isOpen && (
                  <Counter size="small" count={activeFilter.values.length} />
                )}
                {isOpen && filtersLoading && (
                  <Box align="center">
                    <Icon src={Spinner} />
                  </Box>
                )}
              </Box>
              {!disabled && isActive && !isOpen && (
                <ButtonIcon
                  icon={Cross}
                  className={styles.deleteFilter}
                  onPress={handleDeleteFilter}
                  variant="ghost"
                >
                  Remove filter
                </ButtonIcon>
              )}
              {isOpen && !disabled && (
                <FilterSectionContentActions
                  selectedSize={selectedValues.size}
                  handleResetAll={handleResetAll}
                />
              )}
            </Box>

            {isOpen && isSearchable && (
              <Box direction="column">
                <Autocomplete
                  className={styles.autocomplete}
                  placeholder={isHierarchy ? "Search" : "Search or create a wildcard"}
                  query={searchInput}
                  onChange={setSearchInput}
                  onEnterKey={handleAutocompleteEnterKey}
                />
              </Box>
            )}
          </div>
          {isOpen && (
            <>
              {!isHierarchy && (
                <FilterOptionsList
                  titleId={titleId}
                  options={filteredPropertyValues}
                  selectedValues={selectedValues}
                  isProjectRootFilter={isProjectRootFilter}
                  handleSelectValue={handleSelectValue}
                  handleAddWildcard={handleAddWildcard}
                  searchInputIsWildcard={searchInputIsWildcard}
                  isSearchable={isSearchable}
                  searchInput={searchInput}
                  disabled={disabled}
                />
              )}

              {isHierarchy && (
                <HierarchyFilterOptionsList
                  titleId={titleId}
                  options={filteredPropertyValues as HierarchyFilterItemOption[]}
                  handleSelectValue={handleSelectValue}
                  selectedValues={selectedValues}
                  searchInput={searchInput}
                />
              )}
            </>
          )}
        </div>

        {(isActive || isOpen) && isLastLabelsFilter && (
          <BaseActionButton
            onPress={addAnotherLabelFilter}
            className={cx(styles.labelAddMore, styles.labelFilter)}
            disabled={selectedValues.size === 0}
          >
            {ADD_ANOTHER_LABEL_TEXT}
          </BaseActionButton>
        )}
      </>
    );
  }, [
    addAnotherLabelFilter,
    filterName,
    filteredPropertyValues,
    handleAddWildcard,
    handleAutocompleteEnterKey,
    handleResetAll,
    handleSelectValue,
    isHierarchy,
    isLabelFilter,
    isLastLabelsFilter,
    isProjectRootFilter,
    isSearchable,
    searchInput,
    searchInputIsWildcard,
    selectedValues,
    toggleSectionOpen,
    disabled,
    titleId,
    activeFilter,
    handleDeleteFilter,
    isActive,
    isOpen,
    filtersLoading,
  ]);
};

export default FilterSectionContent;
