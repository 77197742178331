import { memo } from "react";

import Typography from "ds/components/Typography";

import IacManagementTableCell from "../../components/Table/Row/Cell";
import { type RenderGroupRowCellProps } from ".";

type IacManagementTableGroupRowCellResourcesCountProps = Pick<RenderGroupRowCellProps, "row">;

const IacManagementTableGroupRowCellResourcesCount = memo(
  ({ row }: IacManagementTableGroupRowCellResourcesCountProps) => {
    return (
      <IacManagementTableCell>
        <Typography variant="p-body3" tag="span">
          {row.count}
        </Typography>
      </IacManagementTableCell>
    );
  }
);

IacManagementTableGroupRowCellResourcesCount.displayName =
  "IacManagementTableGroupRowCellResourcesCount";

export default IacManagementTableGroupRowCellResourcesCount;
