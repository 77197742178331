import { isBefore } from "date-fns/esm";

import { AnnouncementProps } from "./types";
import { announcements } from "./announcements";

export const shouldRenderBanner = (expirationDate?: string): boolean => {
  if (!expirationDate) return true;
  const expiration = new Date(expirationDate);

  // If the expiration date is invalid, we should not render the banner
  if (isNaN(expiration.getTime())) return false;

  const today = new Date();

  return isBefore(today, expiration);
};

export const getBannerProps = (bannerId: string): AnnouncementProps | null => {
  const banner = announcements[bannerId];
  if (!banner) return null;
  return { bannerId, ...banner };
};

export const isExternalLink = (link: string) => link.startsWith("http");
