import Typography from "ds/components/Typography";
import { AwsIntegration } from "types/generated";
import { getDuration } from "utils/time";

import styles from "./styles.module.css";

type AwsIntegrationDeleteConfirmationContentProps = {
  integration: AwsIntegration;
};

const AwsIntegrationDeleteConfirmationContent = (
  props: AwsIntegrationDeleteConfirmationContentProps
) => {
  const { integration } = props;

  return (
    <div>
      <Typography className={styles.text} tag="p" variant="p-body2">
        Are you sure you want to delete the integration:
      </Typography>
      <div className={styles.details}>
        <Typography className={styles.detailsLabel} tag="p" variant="p-body2">
          Name
        </Typography>
        <Typography tag="p" variant="p-body2">
          {integration.name}
        </Typography>
        <Typography className={styles.detailsLabel} tag="p" variant="p-body2">
          Type
        </Typography>
        <Typography tag="p" variant="p-body2">
          AWS
        </Typography>
        <Typography className={styles.detailsLabel} tag="p" variant="p-body2">
          Role ARN
        </Typography>
        <Typography tag="p" variant="p-body2">
          {integration.roleArn}
        </Typography>
        <Typography className={styles.detailsLabel} tag="p" variant="p-body2">
          Duration
        </Typography>
        <Typography tag="p" variant="p-body2">
          {getDuration(integration.durationSeconds)}
        </Typography>
        <Typography className={styles.detailsLabel} tag="p" variant="p-body2">
          External ID
        </Typography>
        <Typography tag="p" variant="p-body2">
          {integration.externalId}
        </Typography>
      </div>
    </div>
  );
};

export default AwsIntegrationDeleteConfirmationContent;
