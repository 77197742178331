import { ReactNode, useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";

import Filters from "components/Filters";
import { SavedFilterView } from "components/Filters/types";
import FiltersSplit from "components/Filters/Split";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersContentWrapper from "components/Filters/ContentWrapper";

import useIacManagementFilters from "../useIacManagementFilters";
import useIacManagementStore from "../useIacManagementStore";
import {
  FILTERS_ORDER_SETTINGS_KEY,
  FILTERS_TYPE,
  initialSortDirection,
  initialSortOption,
} from "../ListLayout/constants";
import useIacManagementAnalytics from "../useIacManagementAnalytics";

type IacManagementFiltersLayoutProps = {
  children: ReactNode;
};

const IacManagementFiltersLayout = ({ children }: IacManagementFiltersLayoutProps) => {
  const { analyticPage } = useIacManagementAnalytics();

  const { setAllItemsQty, groupBy, parentStackId } = useIacManagementStore(
    useShallow((state) => ({
      setAllItemsQty: state.setAllItemsQty,
      groupBy: state.groupBy,
      parentStackId: state.parentStackId,
    }))
  );

  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>();

  const { filters, filtersMap, loading, itemsCount, sortOptions } = useIacManagementFilters({
    parentStackId,
    groupBy,
  });

  useEffect(() => {
    setAllItemsQty(itemsCount);
  }, [itemsCount, setAllItemsQty]);

  return (
    <Filters
      filtersLoading={loading}
      filters={filters}
      filtersItemsOptionsMap={filtersMap}
      sortOptions={sortOptions}
      initialSortOption={initialSortOption}
      initialSortDirection={initialSortDirection}
      pollActiveSections={() => undefined}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType={FILTERS_TYPE}
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
    >
      <FiltersSplit>
        <FiltersSidebar analyticsPage={analyticPage} />
        <FiltersContentWrapper>{children}</FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default IacManagementFiltersLayout;
