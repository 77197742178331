import { useState } from "react";

import { ManagedUserGroup } from "types/generated";
import LoadingIndicator from "ds/components/LoadingIndicator";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerForm from "ds/components/DrawerNew/Form";

import useGetUser from "./useGetUser";
import UsersDetailsDrawerContent from "./Content";
import { UserManagementActivationStatus } from "../../types";

type UsersDetailsDrawerProps = {
  userId: string;
  activationStatus: UserManagementActivationStatus;
  managedUserGroups?: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
};

const UsersDetailsDrawer = createDrawer(
  ({ userId, activationStatus, managedUserGroups }: UsersDetailsDrawerProps) => {
    const [isDirty, setIsDirty] = useState(false);

    const { user, loading, error } = useGetUser({
      id: userId,
    });

    if (error) {
      return null;
    }

    return (
      <DrawerForm isDirty={isDirty}>
        {loading && <LoadingIndicator />}
        {user && (
          <UsersDetailsDrawerContent
            setIsDirty={setIsDirty}
            activationStatus={activationStatus}
            managedUserGroups={managedUserGroups}
            user={user}
          />
        )}
      </DrawerForm>
    );
  }
);

export const showUsersDetailsDrawer = createDrawerTrigger(UsersDetailsDrawer);
