import { Outlet } from "react-router-dom";

import IacManagementHeader from "./components/Header";
import IacManagementFiltersLayout from "./FiltersLayout";
import IacManagementListLayout from "./ListLayout";

const IacManagement = () => {
  return (
    <>
      <IacManagementHeader />
      <IacManagementFiltersLayout>
        <IacManagementListLayout />
      </IacManagementFiltersLayout>

      <Outlet />
    </>
  );
};

export default IacManagement;
