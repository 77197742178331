import { create, show, useModal } from "@ebay/nice-modal-react";

import { ConfettiColored } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Icon from "ds/components/Icon";
import Link from "ds/components/Link";
import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";
import Typography from "ds/components/Typography";
import TypographyHeading from "ds/components/Typography/Heading";

import styles from "./styles.module.css";

const MAIL = "ar@spacelift.io";

const BillingUpgradeSuccessModal = create(function BillingUpgradeSuccessModal() {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} size="large" onExit={modal.remove}>
      <ModalContent>
        <Box direction="column" align="center" justify="center" padding="large">
          <Icon src={ConfettiColored} size="xx-large" />
          <TypographyHeading variant="p-t3" className={styles.title}>
            Welcome Starter plan!
          </TypographyHeading>
          <Typography variant="p-body2" tag="p" className={styles.info}>
            Your plan is now active. If you have any subscription related questions please reach out
            to{" "}
            <Link href={`mailto:${MAIL}`} target="_blank">
              {MAIL}
            </Link>
          </Typography>

          <Button variant="primary" onPress={modal.hide}>
            Okay
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export const showBillingUpgradeSuccessModal = () => show(BillingUpgradeSuccessModal);
