import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationRoleCreateArgs, Role } from "types/generated";

type RoleCreateData = {
  roleCreate: Pick<Role, "id">;
};

export const ROLE_CREATE: TypedDocumentNode<RoleCreateData, MutationRoleCreateArgs> = gql`
  mutation RoleCreate($input: RoleInput!) {
    roleCreate(input: $input) {
      id
    }
  }
`;
