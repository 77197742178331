import { useCallback, useMemo, useState } from "react";
import { cloneDeep, isEqual } from "lodash-es";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

import { ColumnMode } from "components/DragDropList/types";
import useUIConfig from "hooks/useUIConfig";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import useTypedFlags from "hooks/useTypedFlags";
import useAnalytics from "hooks/useAnalytics";

import { DashboardColumns, DashboardConfig, DashboardOverviewStaticColumn } from "../types";
import {
  createInitialConfig,
  getAllDashboardWidgets,
  getInitialConfig,
  splitColumnsConfigBaseOnMode,
} from "../helpers";
import { VIEWS_LIMIT, DASHBOARD_STORAGE_KEY, DefaultDashboardTabs } from "../constants";
import useCurrentTab from "../useCurrentTab";
import { validateConfig } from "../validateConfig";

const useConfig = () => {
  const { numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend } =
    useTypedFlags();

  const allWidgets = useMemo(
    () =>
      getAllDashboardWidgets({
        numberOfDeploymentsWidgetFrontend,
        approvalPolicyEvaluationsWidgetFrontend,
      }),
    [numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend]
  );

  const initialConfig = getInitialConfig({
    numberOfDeploymentsWidgetFrontend,
    approvalPolicyEvaluationsWidgetFrontend,
  });

  const { config, updateConfig, configLoading } = useUIConfig<DashboardConfig>(
    DASHBOARD_STORAGE_KEY,
    initialConfig,
    validateConfig({ numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend })
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageDashboard.Dashboard,
  });

  const [shouldFocusOnInput, setShouldFocusOnInput] = useState(false);
  const [managementDrawerConfig, setManagementDrawerConfig] = useState(config);

  const managementDrawerTabs = Object.entries(managementDrawerConfig).map(([id, value]) => ({
    id,
    title: value.title,
  }));

  const updateManagementDrawerTabColumnsConfig = useCallback(
    (tab: string, columns: DashboardColumns) => {
      const newConfig = {
        ...managementDrawerConfig,
        [tab]: {
          ...managementDrawerConfig[tab],
          ...columns,
        },
      };

      setManagementDrawerConfig(newConfig);
    },
    [managementDrawerConfig]
  );

  const updateManagementDrawerTabColumnModeConfig = useCallback(
    (tab: string, columnMode: ColumnMode) => {
      setManagementDrawerConfig({
        ...managementDrawerConfig,
        [tab]: {
          ...managementDrawerConfig[tab],
          ...splitColumnsConfigBaseOnMode(
            [...managementDrawerConfig[tab].left, ...managementDrawerConfig[tab].right],
            columnMode
          ),
        },
      });
    },
    [managementDrawerConfig]
  );

  const updateManagementDrawerTabOverviewColumnConfig = useCallback(
    (column: DashboardOverviewStaticColumn) => {
      setManagementDrawerConfig({
        ...managementDrawerConfig,
        overview: {
          ...managementDrawerConfig["overview"],
          overviewColumn: column,
        },
      });
    },
    [managementDrawerConfig]
  );

  const navigate = useNavigate();
  const currentTab = useCurrentTab();
  const goToFirstTab = useCallback(
    () => navigate(`/dashboard?tab=${DefaultDashboardTabs.Overview}`),
    [navigate]
  );

  const saveManagementDrawerChanges = useCallback(() => {
    updateConfig(managementDrawerConfig);
    trackSegmentAnalyticsEvent("Dashboard - Configuration changed", managementDrawerConfig);

    if (!Object.keys(managementDrawerConfig).includes(currentTab)) {
      goToFirstTab();
    }
  }, [updateConfig, managementDrawerConfig, currentTab, goToFirstTab, trackSegmentAnalyticsEvent]);

  const [currentManageDrawerTab, setCurrentManageDrawerTab] = useState(currentTab);

  const setCurrentManageDrawerTabHandler = useCallback((tab: string) => {
    setCurrentManageDrawerTab(tab);
    setShouldFocusOnInput(false);
  }, []);

  const addNewTabToManagementDrawerConfig = useCallback(
    (prevConfig: DashboardConfig) => {
      const newKey = nanoid();
      const newConfig = {
        ...prevConfig,
        [newKey]: {
          ...createInitialConfig(allWidgets, ColumnMode.Double),
          title: "New tab",
        },
      };

      setManagementDrawerConfig(newConfig);

      if (Object.keys(newConfig).length === VIEWS_LIMIT) {
        trackSegmentAnalyticsEvent("Dashboard - Custom View Limit Exceeded");
      }

      setCurrentManageDrawerTab(newKey);
    },
    [trackSegmentAnalyticsEvent, allWidgets]
  );

  const addNewTab = useCallback(() => {
    addNewTabToManagementDrawerConfig(managementDrawerConfig);
    setShouldFocusOnInput(true);
  }, [managementDrawerConfig, addNewTabToManagementDrawerConfig]);

  const addNewTabInDrawer = useCallback(() => {
    trackSegmentAnalyticsEvent("Dashboard - Create New View", {
      method: "manage dashboard drawer tab click",
    });
    addNewTab();
  }, [addNewTab, trackSegmentAnalyticsEvent]);

  const changeTabName = useCallback(
    (id: string, value: string) => {
      setManagementDrawerConfig({
        ...managementDrawerConfig,
        [id]: {
          ...managementDrawerConfig[id],
          title: value,
        },
      });
    },
    [managementDrawerConfig]
  );

  const removeTabInDrawer = useCallback(
    (id: string) => {
      const newmanagementDrawerConfig = cloneDeep(managementDrawerConfig);
      if (newmanagementDrawerConfig[id]) {
        delete newmanagementDrawerConfig[id];
        setManagementDrawerConfig(newmanagementDrawerConfig);

        if (id === currentManageDrawerTab) {
          setCurrentManageDrawerTab(DefaultDashboardTabs.Overview);
        }
      }
    },
    [managementDrawerConfig, currentManageDrawerTab]
  );

  const isManagementDrawerConfigModified = useMemo(
    () => !isEqual(managementDrawerConfig, config),
    [managementDrawerConfig, config]
  );

  const canAddMoreManagementDrawerViews = useMemo(
    () => Object.keys(managementDrawerConfig).length < VIEWS_LIMIT,
    [managementDrawerConfig]
  );

  return {
    managementDrawerTabs,
    updateManagementDrawerTabColumnsConfig,
    updateManagementDrawerTabOverviewColumnConfig,
    updateManagementDrawerTabColumnModeConfig,
    saveManagementDrawerChanges,
    managementDrawerConfig,
    isManagementDrawerConfigModified,
    addNewTabInDrawer,
    removeTabInDrawer,
    changeTabName,
    setCurrentManageDrawerTab: setCurrentManageDrawerTabHandler,
    currentManageDrawerTab,
    canAddMoreManagementDrawerViews,
    configLoading,
    addNewTab,
    shouldFocusOnInput,
  };
};

export default useConfig;
