import { useEffect, useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ComboBoxMultiple from "components/ComboBoxMultiple";
import FormFieldSpace from "components/FormFields/Space";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import AssignRoleDrawerComboBoxItem from "views/Account/Settings/User/AssignRoleDrawer/ComboBoxItem";

import styles from "./styles.module.css";
import { InviteDrawerAssignRoleFormFields } from "./types";

type InviteDrawerAssignRoleFormAddProps = {
  availableRoles: Array<{ id: string; name: string }>;
  setIsDirty: (isDirty: boolean) => void;
  onSubmit: (formData: InviteDrawerAssignRoleFormFields) => void;
  isLoading: boolean;
};

const InviteDrawerAssignRoleFormAdd = ({
  availableRoles,
  setIsDirty,
  onSubmit,
  isLoading,
}: InviteDrawerAssignRoleFormAddProps) => {
  const assignRoleForm = useForm<InviteDrawerAssignRoleFormFields>({
    defaultValues: {
      roleIds: [],
      space: undefined,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = assignRoleForm;

  const onSubmitHandler: SubmitHandler<InviteDrawerAssignRoleFormFields> = (formData) => {
    onSubmit(formData);
    reset();
  };

  const options = useMemo(
    () => availableRoles.map(({ id, name }) => ({ value: id, label: name })),
    [availableRoles]
  );

  // TODO consider add a not existing space option to the list of spaces in case editing an output reference with not existing output
  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  return (
    <FormProvider {...assignRoleForm}>
      <Box direction="column" padding="x-large" gap="x-large" className={styles.formWrapper}>
        <Controller
          name="roleIds"
          control={control}
          rules={{ required: "Role field is required." }}
          render={({ field, fieldState }) => (
            <ComboBoxMultiple
              isLoading={isLoading}
              items={options}
              label="Select role"
              error={fieldState.error?.message}
              values={field.value}
              onChange={(role) => {
                if (role && field.value.includes(role)) {
                  field.onChange(field.value.filter((r) => r !== role));
                } else {
                  field.onChange([...field.value, role]);
                }
              }}
            >
              {(item) => <AssignRoleDrawerComboBoxItem checkboxVisible id={item.value} {...item} />}
            </ComboBoxMultiple>
          )}
        />
        <FormFieldSpace label="Space" noMargin />

        <Box direction="row" justify="end" gap="medium">
          <Button variant="primary" size="small" onPress={() => handleSubmit(onSubmitHandler)()}>
            Add
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default InviteDrawerAssignRoleFormAdd;
