import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";
import { SearchQueryOrderDirection } from "types/generated";
import { decodeURIParam } from "utils/urls";

import { SortStateDescriptor } from "./types";
import { getSessionStorageKeys } from "./helpers";

const useSortState = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const applySortState = useCallback(
    ({ sortDirection, sortKey }: SortStateDescriptor, sessionStorageKey: string) => {
      const storage = sessionStorage;
      const { storageUrlSortDirection, storageUrlSortKey } =
        getSessionStorageKeys(sessionStorageKey);

      searchParams.set(URL_SORT_KEY, encodeURIComponent(sortKey));
      searchParams.set(URL_SORT_DIRECTION, encodeURIComponent(sortDirection));

      storage.setItem(storageUrlSortKey, encodeURIComponent(sortKey));
      storage.setItem(storageUrlSortDirection, encodeURIComponent(sortDirection));

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const sortState = useMemo<Partial<SortStateDescriptor>>(() => {
    const querySortDirection = decodeURIParam(searchParams.get(URL_SORT_DIRECTION)) as
      | SearchQueryOrderDirection
      | undefined;

    return {
      sortKey: decodeURIParam(searchParams.get(URL_SORT_KEY)),
      sortDirection: querySortDirection,
    };
  }, [searchParams]);

  return { applySortState, sortState };
};

export default useSortState;
