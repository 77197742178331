import { gql, TypedDocumentNode } from "@apollo/client";

import { Blueprint, QueryBlueprintArgs } from "types/generated";

type FetchBlueprintData = {
  blueprint: Blueprint;
  blueprintSchema: string;
};

export const GET_BLUEPRINT: TypedDocumentNode<FetchBlueprintData, QueryBlueprintArgs> = gql`
  query GetBlueprint($id: ID!) {
    blueprint(id: $id) {
      id
      deleted
      name
      description
      createdAt
      updatedAt
      state
      inputs {
        id
        name
        default
        description
        options
        type
      }
      space {
        id
        name
        accessLevel
      }
      labels
      rawTemplate
    }
    blueprintSchema
  }
`;
