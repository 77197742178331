import { useState } from "react";
import { subMonths } from "date-fns/esm";

import { SavedFilterView } from "components/Filters/types";
import PageInfo from "components/PageWrapper/Info";
import { AspectType } from "types/generated";
import DatepickerRange from "ds/components/Datepicker/Range";

import FiltersLayout from "../../FiltersLayout";
import UsageChart from "../../Chart";
import UsageLayoutHeader from "../../Layout/Header";
import { colorMapper } from "../colorMapper";
import { PERIODS, UsageDateRange } from "../../Chart/constants";

const initialPeriod = UsageDateRange.CurrentBillingPeriod;

type CurrentDateValue = {
  start: Date;
  end: Date;
};

const UsageWorkersAdvanced = () => {
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>();

  const [currentDateRange, setCurrentDateRange] = useState<CurrentDateValue>(
    PERIODS[initialPeriod].range
  );

  const handleDateRangeChange = (dateRange: CurrentDateValue) => {
    setCurrentDateRange(dateRange);
  };

  return (
    <>
      <UsageLayoutHeader title="Usage chart" />
      <PageInfo title="Workers chart">
        <DatepickerRange
          isButton
          buttonSize="medium"
          periods={PERIODS}
          onChange={handleDateRangeChange}
          minDate={subMonths(new Date(), 3)}
          maxDate={new Date()}
          initialPeriod={initialPeriod}
          endDate={currentDateRange.end}
          startDate={currentDateRange.start}
        />
      </PageInfo>
      <FiltersLayout currentSavedView={currentSavedView} setCurrentSavedView={setCurrentSavedView}>
        <UsageChart
          timeFilter={{ startTime: currentDateRange.start, endTime: currentDateRange.end }}
          aspectType={AspectType.WorkersCount}
          colorMapper={colorMapper}
        />
      </FiltersLayout>
    </>
  );
};

export default UsageWorkersAdvanced;
