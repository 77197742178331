import { useMutation } from "@apollo/client";
import { useEffect, useId, useRef, useState } from "react";

import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import Box from "ds/components/Box";
import FormToggleField from "ds/components/Form/ToggleField";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { SpacesContext } from "views/Account/SpacesProvider";

import SpacesPageLayout from "./PageLayout";
import { TOGGLE_ALLOW_NON_ROOT_ADMIN_SPACE_CREATION } from "./gql";

const Spaces = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { canNonRootAdminCreateSpace } = useTypedContext(SpacesContext);
  const [isActive, setIsActive] = useState(canNonRootAdminCreateSpace);
  const inputRef = useRef<HTMLInputElement>(null);
  useTitle(`Organization Settings · Spaces · ${accountName}`);

  const switchToggleTitleId = useId();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationLimits,
  });

  const [updateSpaces, { loading: updateSpacesLoading }] = useMutation(
    TOGGLE_ALLOW_NON_ROOT_ADMIN_SPACE_CREATION,
    {
      refetchQueries: ["GetSpaces"],
    }
  );

  const handleActiveChange = (active: boolean) => {
    updateSpaces({ variables: { limit: null } })
      .then(({ data }) => {
        if (data) {
          setIsActive(active);

          const message = `Ability for space admins to create child spaces has been turned ${active ? "on" : "off"}`;
          reportSuccess({ message });
          trackSegmentAnalyticsEvent(message);
        }
      })
      .catch(onError);
  };

  useEffect(() => {
    if (!updateSpacesLoading && inputRef.current && inputRef.current.dataset.focus) {
      inputRef.current.focus();
      delete inputRef.current.dataset.focus;
    }
  }, [updateSpacesLoading]);

  return (
    <SpacesPageLayout>
      <Box align="start" justify="between" fullWidth>
        <FormToggleField
          variant="switch"
          onChange={handleActiveChange}
          checked={isActive}
          title="Allow space admins to create and edit child spaces"
          description="Inheritance settings can not be overwritten by space admins"
          titleId={switchToggleTitleId}
        />
      </Box>
    </SpacesPageLayout>
  );
};

export default Spaces;
