import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  ChevronDown,
  LogoAzure,
  LogoBitbucket,
  LogoGithub,
  LogoGitlab,
} from "components/icons/generated";
import Button from "ds/components/Button";
import DropdownMenu from "ds/components/DropdownMenu";
import Icon from "ds/components/Icon";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import useTypedContext from "hooks/useTypedContext";
import { VcsProvider } from "types/generated";
import { getManageIntegrationURL } from "views/Account/VCS/helpers";

import { VCSIntegrationsListContext } from "../Context";
import VCSIntegrationsSetupDropdownOption from "./Option";

type VCSIntegrationsSetupDropdownProps = {
  analyticsLocation?: "on-page-empty-state" | "on-page-header";
};

const VCSIntegrationsSetupDropdown = ({
  analyticsLocation = "on-page-header",
}: VCSIntegrationsSetupDropdownProps) => {
  const navigate = useNavigate();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    defaultCallbackTrackProperties: { location: analyticsLocation },
  });

  const triggerDropdownClickHandler = useCallback(() => {
    trackSegmentAnalyticsEvent("Setup Start Click");
  }, [trackSegmentAnalyticsEvent]);

  const chooseProviderClickHandler = useCallback(
    (provider: VcsProvider) => () => {
      navigate(getManageIntegrationURL(provider));
      trackSegmentAnalyticsEvent("Setup Integration Select", { integration: provider });
    },
    [navigate, trackSegmentAnalyticsEvent]
  );

  const { isDefaultIntegrationsLoading, isSetupDisabled } = useTypedContext(
    VCSIntegrationsListContext
  );

  return (
    <DropdownMenu
      triggerComponent={
        <Button
          onPress={triggerDropdownClickHandler}
          variant="primary"
          loading={isDefaultIntegrationsLoading}
          disabled={isDefaultIntegrationsLoading}
          endIcon={ChevronDown}
        >
          Set up integration
        </Button>
      }
    >
      <VCSIntegrationsSetupDropdownOption
        onAction={chooseProviderClickHandler(VcsProvider.AzureDevops)}
        isDisabled={isSetupDisabled(VcsProvider.AzureDevops)}
      >
        <Icon src={LogoAzure} />
        Azure DevOps
      </VCSIntegrationsSetupDropdownOption>

      <VCSIntegrationsSetupDropdownOption
        onAction={chooseProviderClickHandler(VcsProvider.BitbucketCloud)}
        isDisabled={isSetupDisabled(VcsProvider.BitbucketCloud)}
      >
        <Icon src={LogoBitbucket} />
        Bitbucket Cloud
      </VCSIntegrationsSetupDropdownOption>

      <VCSIntegrationsSetupDropdownOption
        onAction={chooseProviderClickHandler(VcsProvider.BitbucketDatacenter)}
        isDisabled={isSetupDisabled(VcsProvider.BitbucketDatacenter)}
      >
        <Icon src={LogoBitbucket} />
        Bitbucket Data Center
      </VCSIntegrationsSetupDropdownOption>

      <VCSIntegrationsSetupDropdownOption
        onAction={chooseProviderClickHandler(VcsProvider.GithubEnterprise)}
        isDisabled={isSetupDisabled(VcsProvider.GithubEnterprise)}
      >
        <Icon src={LogoGithub} />
        GitHub
      </VCSIntegrationsSetupDropdownOption>

      <VCSIntegrationsSetupDropdownOption
        onAction={chooseProviderClickHandler(VcsProvider.Gitlab)}
        isDisabled={isSetupDisabled(VcsProvider.Gitlab)}
      >
        <Icon src={LogoGitlab} />
        GitLab
      </VCSIntegrationsSetupDropdownOption>
    </DropdownMenu>
  );
};

export default VCSIntegrationsSetupDropdown;
