import { Link } from "components/icons/generated";
import ButtonIcon, { ButtonIconProps } from "ds/components/ButtonIcon";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import useShare from "./useShare";

type BlueprintShareButtonProps = {
  blueprintId: string;
  variant: ButtonIconProps["variant"];
  size: ButtonIconProps["size"];
  analyticsProps: AnalyticsCommonProps["analyticsProps"];
};

const BlueprintShareButton = ({
  blueprintId,
  variant,
  size,
  analyticsProps,
}: BlueprintShareButtonProps) => {
  const { onShare } = useShare(blueprintId);

  return (
    <ButtonIcon
      analyticsTitle="Copy stack creation view clicked"
      analyticsProps={analyticsProps}
      icon={Link}
      onPress={onShare}
      variant={variant}
      size={size}
    >
      Copy link to stack creation view
    </ButtonIcon>
  );
};

export default BlueprintShareButton;
