import useTypedContext from "hooks/useTypedContext";
import { ApplyFiltersContext } from "components/Filters";
import { TableDropdownAction } from "ds/components/Table/components/DropdownCell";

import { ADD_TO_FILTERS } from "./constants";
import { ResourcesSuggestions } from "../useIacManagementFilters/constants";
import useIacManagementAnalytics from "../useIacManagementAnalytics";

const useCellVendorFilterAction = (vendorName?: string): TableDropdownAction[] => {
  const { applyFilter } = useTypedContext(ApplyFiltersContext);
  const { trackAnalytics } = useIacManagementAnalytics();

  if (!vendorName) return [];

  return [
    {
      title: ADD_TO_FILTERS,
      action: () => {
        applyFilter(ResourcesSuggestions.Vendor, {})(vendorName);
        trackAnalytics("Add to filters clicked", { type: ResourcesSuggestions.Vendor });
      },
    },
  ];
};

export default useCellVendorFilterAction;
