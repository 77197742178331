/**
 * Apollo fetchMore updateQuery attribute has an issue where in some cases previous data comes as empty, even though
 * data is available. This function is a workaround that uses the data returned from useQuery as a fallback.
 *
 * This issue might be fixed once apollo does something with this bug report here -> https://github.com/apollographql/apollo-client/issues/5897
 *
 * To reproduce the issue, open an infinite loading page and try to scroll down fast. It doesn't reproduce every time, but when it does, the first batch of data is lost. See the PR for a recording of the reproduction.
 */
const fetchMoreUpdateQueryPreviousFix = <Previous, Data>(
  check: boolean,
  previous: Previous,
  data: Data
) => (check ? previous : data);

export default fetchMoreUpdateQueryPreviousFix;
