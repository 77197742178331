import CodeEditor from "components/CodeEditor";
import Box from "ds/components/Box";
import { Blueprint, BlueprintParseResult, BlueprintState } from "types/generated";

import FormPreview from "./FormPreview";
import SchemaPreview from "./SchemaPreview";

type BlueprintEditorProps = {
  body: string;
  schema: string;
  onChange: (body?: string) => void;
  readOnly?: boolean;
  blueprint: Blueprint;
  validate: (template: string) => Promise<void | BlueprintParseResult>;
};

const BlueprintEditor = ({
  validate,
  blueprint,
  body,
  schema,
  onChange,
  readOnly,
}: BlueprintEditorProps) => {
  return (
    <Box grow="1" justify="between">
      <SchemaPreview schema={schema} />
      <Box direction="column" grow="1" zeroMinWidth>
        <CodeEditor body={body} onChange={onChange} language="yaml" readOnly={readOnly} />
      </Box>
      {blueprint.state === BlueprintState.Draft && (
        <FormPreview validate={validate} blueprint={blueprint} body={body} />
      )}
    </Box>
  );
};

export default BlueprintEditor;
