import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import FormFieldHidden from "ds/components/Form/Field/Hidden";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Input from "ds/components/Input";
import SecretInput from "ds/components/SecretInput";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import { AzureDevOpsRepoIntegration, VcsProvider } from "types/generated";
import { stringIsRequired, validateRequiredURL } from "utils/formValidators";
import { AccountContext } from "views/AccountWrapper";

import VCSIntegrationTypeFieldControl from "../../components/IntegrationTypeFieldControl";
import VCSIntegrationsDocumentationButton from "../components/DocumentationButton";
import VCSChecksToggle from "../components/VCSChecksToggle";
import useCloseFullScreenModal from "../useCloseFullScreenModal";
import {
  getFormDefaultValues,
  mapCreateAzureDevOpsIntegrationInputValues,
  mapUpdateAzureDevOpsIntegrationInputValues,
} from "./helpers";
import { ManageAzureDevOpsFormFields } from "./types";
import useCheckAzureDevOpsHasDefaultIntegration from "./useCheckAzureDevOpsHasDefaultIntegration";
import useCreateAzureDevOpsIntegration from "./useCreateAzureDevOpsIntegration";
import useUpdateAzureDevOpsIntegration from "./useUpdateAzureDevOpsIntegration";

type VCSIntegrationsManageAzureDevOpsFormProps = {
  integration?: AzureDevOpsRepoIntegration;
  onIsDirtyChange: (value: boolean) => void;
};

const VCSIntegrationsManageAzureDevOpsForm = ({
  integration,
  onIsDirtyChange,
}: VCSIntegrationsManageAzureDevOpsFormProps) => {
  const isEditMode = !!integration;

  const { viewer } = useTypedContext(AccountContext);

  const { handleOnClose } = useCloseFullScreenModal();

  const manageAzureDevOpsForm = useForm<ManageAzureDevOpsFormFields>({
    defaultValues: getFormDefaultValues(integration),
    mode: "onChange",
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    trigger,
  } = manageAzureDevOpsForm;

  const { createAzureDevOpsIntegration, loading: createLoading } =
    useCreateAzureDevOpsIntegration();
  const { updateAzureDevOpsIntegration, loading: updateLoading } =
    useUpdateAzureDevOpsIntegration();

  const { hasDefaultIntegration, loading: checkDefaultIntegrationLoading } =
    useCheckAzureDevOpsHasDefaultIntegration(isEditMode);

  const withIntegrationTypeControl = !isEditMode && viewer.admin;
  const withSpaceControl = !withIntegrationTypeControl && !integration?.isDefault;

  const onHiddenToggle = useCallback(
    (isHidden: boolean) => {
      if (!isHidden) {
        setValue("personalAccessToken", "", { shouldValidate: true });
      } else {
        // revalidate the whole form with hidden field
        trigger();
      }
    },
    [setValue, trigger]
  );

  const handleSetup = useCallback(
    () =>
      handleSubmit((formData: ManageAzureDevOpsFormFields) => {
        if (isEditMode) {
          updateAzureDevOpsIntegration(
            mapUpdateAzureDevOpsIntegrationInputValues(formData, integration.id),
            handleOnClose
          );
        } else {
          createAzureDevOpsIntegration(
            mapCreateAzureDevOpsIntegrationInputValues(formData),
            handleOnClose
          );
        }
      })(),
    [
      createAzureDevOpsIntegration,
      handleOnClose,
      integration?.id,
      isEditMode,
      updateAzureDevOpsIntegration,
      handleSubmit,
    ]
  );

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return (
    <FormProvider {...manageAzureDevOpsForm}>
      <FullScreenModalBody>
        <FormField
          label="Integration name"
          helperText="The integration name is unique and cannot be changed"
          error={errors?.name?.message}
          noMargin
        >
          {({ ariaInputProps }) => (
            <Input
              placeholder="Enter integration name here"
              error={!!errors?.name}
              {...register("name", {
                validate: stringIsRequired("Integration name field is required."),
              })}
              disabled={isEditMode}
              {...ariaInputProps}
            />
          )}
        </FormField>

        {withIntegrationTypeControl && (
          <VCSIntegrationTypeFieldControl
            hasAlreadyDefaultIntegration={hasDefaultIntegration}
            loading={checkDefaultIntegrationLoading}
            provider={VcsProvider.AzureDevops}
          />
        )}
        {withSpaceControl && <FormFieldSpace />}

        <FormField label="Organization URL" error={errors?.organizationURL?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="https://"
              error={!!errors?.organizationURL}
              {...register("organizationURL", {
                validate: validateRequiredURL(),
              })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormField label="User facing host URL" error={errors?.userFacingHost?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="https://"
              error={!!errors?.userFacingHost}
              {...register("userFacingHost", { validate: validateRequiredURL() })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        {!isEditMode && (
          <FormField label="Personal access token" error={errors?.personalAccessToken?.message}>
            {({ ariaInputProps }) => (
              <SecretInput
                placeholder="Enter personal access token here"
                error={!!errors?.personalAccessToken}
                {...register("personalAccessToken", {
                  validate: stringIsRequired("Personal access token field is required."),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        )}

        {isEditMode && (
          <FormFieldHidden
            hiddenPlaceholder="Update personal access token"
            onHiddenToggle={onHiddenToggle}
            label="Personal access token"
            error={errors?.personalAccessToken?.message}
          >
            {(isHidden) => (
              <SecretInput
                placeholder="Enter personal access token here"
                error={!!errors?.personalAccessToken}
                {...register("personalAccessToken", {
                  ...(!isHidden && {
                    validate: stringIsRequired("Personal access token field is required."),
                  }),
                })}
              />
            )}
          </FormFieldHidden>
        )}

        <VCSChecksToggle />

        <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />

        <FormField label="Description" isOptional>
          {({ ariaInputProps }) => (
            <Textarea
              placeholder="Enter description here"
              {...register("description")}
              {...ariaInputProps}
            />
          )}
        </FormField>
      </FullScreenModalBody>
      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.AzureDevops} />

        <Button onPress={handleSetup} variant="primary" loading={createLoading || updateLoading}>
          {isEditMode ? "Save" : "Set up"}
        </Button>
      </FullScreenModalFooter>
    </FormProvider>
  );
};

export default VCSIntegrationsManageAzureDevOpsForm;
