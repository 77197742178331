import cx from "classnames";
import { formatDistanceStrict, fromUnixTime } from "date-fns/esm";

import { formatDateTimeByLocale } from "utils/date";
import { getNowTimeMs } from "utils/time";

type TimestampProps = {
  timestamp: number;
  className?: string;
  prefix?: string;
};

/**
 * @deprecated use components/Timestamp instead
 */
const Timestamp = ({ timestamp, className, prefix }: TimestampProps) => {
  const timestampDate = fromUnixTime(timestamp);

  return (
    <span
      className={cx("timestamp", className)}
      title={formatDateTimeByLocale({
        date: timestampDate,
        format: "dateTimeFull",
      })}
    >
      {prefix}
      {formatDistanceStrict(timestampDate, getNowTimeMs(), { addSuffix: true })}
    </span>
  );
};

export default Timestamp;
