import { useResizeObserver } from "@react-aria/utils";
import { RefObject, useCallback, useState } from "react";

export const useTriggerWidth = (triggerRef: RefObject<HTMLElement>) => {
  const [triggerWidth, setTriggerWidth] = useState<string>();

  const onResize = useCallback(() => {
    if (triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();

      setTriggerWidth(triggerRect.width + "px");
    }
  }, [triggerRef, setTriggerWidth]);

  useResizeObserver({
    ref: triggerRef,
    onResize: onResize,
  });

  return triggerWidth;
};
