import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import useShare from "./useShare";

type BlueprintShareDropdownMenuItemProps = {
  blueprintId: string;
};

const BlueprintShareDropdownMenuItem = ({ blueprintId }: BlueprintShareDropdownMenuItemProps) => {
  const { onShare } = useShare(blueprintId);

  return <DropdownMenuItem onAction={onShare}>Copy link to stack creation</DropdownMenuItem>;
};

export default BlueprintShareDropdownMenuItem;
