import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import CardWrapper from "components/CardWrapper";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormNameField from "components/ContextConfigForm/NameField";
import ContextConfigFormValueField from "components/ContextConfigForm/ValueField";
import Box from "ds/components/Box";
import Button from "ds/components/Button";

import { ContextAttachedVariable, ContextFormFields } from "../types";

type ContextVariablesEditFormProps = {
  onSave: (item: ContextAttachedVariable) => void;
  index: number;
};

const ContextVariablesEditForm = ({ index, onSave }: ContextVariablesEditFormProps) => {
  const {
    handleSubmit,
    formState: { isValid },
    watch,
  } = useFormContext<ContextFormFields>();

  const onSubmit = useCallback(
    () =>
      handleSubmit((formData: ContextFormFields) => onSave(formData["attachedVariables"][index]))(),
    [onSave, index, handleSubmit]
  );

  return (
    <CardWrapper variant="filled" direction="column">
      <Box gap="medium" fullWidth margin="0 0 large">
        <ContextConfigFormNameField name={`attachedVariables.${index}.variableName`} />
        <ContextConfigFormValueField
          name={`attachedVariables.${index}.value`}
          isSecret={watch(`attachedVariables.${index}.isSecret`)}
        />
      </Box>

      <ContextConfigFormIsSecretField name={`attachedVariables.${index}.isSecret`} />

      <ContextConfigFormDescriptionField name={`attachedVariables.${index}.description`} />

      <Box justify="end" fullWidth margin="large 0 0">
        <Button variant="contrast" type="submit" onPress={onSubmit} disabled={!isValid}>
          Save
        </Button>
      </Box>
    </CardWrapper>
  );
};

export default ContextVariablesEditForm;
