import { create, show, useModal } from "@ebay/nice-modal-react";
import { FormProvider, useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import FormField from "ds/components/Form/Field";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import Textarea from "ds/components/Textarea";

import { BlueprintVersion } from "./types";

type BlueprintVersionDeprecateFormFields = {
  deprecationNote: string;
};

type BlueprintVersionDeprecateModalProps = {
  version: BlueprintVersion;
};

const BlueprintVersionDeprecateModal = create(
  ({ version }: BlueprintVersionDeprecateModalProps) => {
    const modal = useModal();
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const builderForm = useForm<BlueprintVersionDeprecateFormFields>({
      defaultValues: {
        deprecationNote: "",
      },
      mode: "onChange",
    });

    const { register, getValues, handleSubmit } = builderForm;

    // TODO: mutation to deprecate blueprint version

    const onSubmit = (formData: BlueprintVersionDeprecateFormFields) => {
      // TODO: deprecate version
      return Promise.resolve(formData)
        .then(() => {
          if (version.version) {
            reportSuccess({
              message: `Blueprint version ${version.version} is successfully deprecated`,
            });
            modal.hide();
          }
        })
        .catch(onError);
    };

    const onConfirm = () => onSubmit(getValues());

    return (
      <ConfirmationModal
        title="Deprecation confirmation"
        onConfirm={onConfirm}
        confirmationButtonVariant="dangerPrimary"
        confirmationButtonLabel="Deprecate"
      >
        <ConfirmationModalMessage>
          Are you sure you want to deprecate {version.version}?{" "}
          <strong>This action cannot be undone.</strong>
        </ConfirmationModalMessage>
        <Box padding="large 0 0 0" direction="column">
          <FormProvider {...builderForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormField label="Deprecation note" isOptional>
                {({ ariaInputProps }) => (
                  <Textarea
                    placeholder="Enter deprecation details here..."
                    {...register("deprecationNote")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            </form>
          </FormProvider>
        </Box>
      </ConfirmationModal>
    );
  }
);

export const showBlueprintVersionDeprecateModal = (props: BlueprintVersionDeprecateModalProps) =>
  show(BlueprintVersionDeprecateModal, props);
