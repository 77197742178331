import { useMemo } from "react";

import { STACKS_COLUMN_ID } from "./constants";
import { useStacksColumnConfig } from "./useStacksColumnConfig";

export const useInitialColumnsViewConfig = () => {
  const stacksColumnsConfig = useStacksColumnConfig();

  return useMemo(() => {
    const config = {
      visible: Object.keys(stacksColumnsConfig) as STACKS_COLUMN_ID[],
      hidden: [],
    };

    return config;
  }, [stacksColumnsConfig]);
};
