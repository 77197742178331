import cx from "classnames";
import { ReactNode } from "react";

import { InfoCircle } from "components/icons/generated";
import { ButtonProps } from "ds/components/Button";
import Icon, { IconProps } from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import { TooltipProps } from "ds/components/Tooltip/types";
import TooltipModal from "ds/components/TooltipModal";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import ButtonIcon from "../ButtonIcon";
import styles from "./styles.module.css";

export type TooltipInfoVariant = "standard" | "modal";

type TooltipInfoProps = Omit<TooltipProps, "on" | "children"> & {
  children: ReactNode;
  variant?: TooltipInfoVariant;
  widthMode?: TooltipProps["widthMode"];
  iconClassName?: string;
  iconColor?: ButtonProps["variant"];
  standardIconColor?: IconProps["color"]; // Should be removed when TooltipInfo uses the new ButtonIcon
  mode?: TooltipProps["mode"];
} & AnalyticsCommonProps;

const TooltipInfo = ({
  children,
  iconClassName,
  variant = "standard",
  iconColor = "ghost",
  standardIconColor,
  analyticsTitle,
  analyticsPage,
  analyticsProps,
  widthMode,
  ...tooltipProps
}: TooltipInfoProps) => {
  if (variant === "standard") {
    return (
      <Tooltip
        widthMode={widthMode}
        on={(props) => (
          <Icon
            tabIndex={0}
            {...props}
            color={standardIconColor}
            src={InfoCircle}
            className={cx(iconClassName, styles.tooltipInfo)}
          />
        )}
        {...tooltipProps}
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <TooltipModal
      on={(props, isOpen) => (
        <ButtonIcon
          {...props}
          active={isOpen}
          icon={InfoCircle}
          variant={iconColor}
          className={iconClassName}
          analyticsPage={analyticsPage}
          analyticsTitle={analyticsTitle}
          analyticsProps={analyticsProps}
          disableTooltip={isOpen}
        >
          Open more information
        </ButtonIcon>
      )}
      {...tooltipProps}
    >
      {children}
    </TooltipModal>
  );
};

TooltipInfo.displayName = "DS.TooltipInfo";

export default TooltipInfo;
