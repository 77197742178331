import { Copy } from "components/icons/generated";
import { ButtonProps } from "ds/components/Button";
import ButtonIcon, { ButtonIconProps } from "ds/components/ButtonIcon";
import useCopyToClipboard from "hooks/useCopyToClipboard";

type CopyFieldIconProps = {
  title: string;
  value: string;
  callback?: () => void;
  variant?: ButtonProps["variant"];
} & Pick<ButtonIconProps, "disabled" | "tooltipMode" | "className">;

const CopyFieldIcon = ({
  title,
  value,
  callback,
  variant = "ghost",
  ...restProps
}: CopyFieldIconProps) => {
  const handleCopy = useCopyToClipboard(value, callback);

  return (
    <ButtonIcon {...restProps} onPress={handleCopy} icon={Copy} variant={variant}>
      {title}
    </ButtonIcon>
  );
};

export default CopyFieldIcon;
