import { useCallback } from "react";

import { BlueprintSuggestions, searchBlueprintSuggestionsDictionary } from "constants/blueprint";
import useApplyFilter from "components/Filters/useApplyFilter";
import TagsListFilterableNew from "components/TagsListFilterableNew";

import useBlueprintsAnalytics from "../../hooksNew/useBlueprintsAnalytics";
import { CellComponentProps } from "./types";

type BlueprintsRowLabelsCellProps = CellComponentProps;

const APPLY_FILTERS_SUGGESTIONS = {
  searchSuggestionsDictionary: searchBlueprintSuggestionsDictionary,
};

const BlueprintsRowLabelsCell = ({ blueprint }: BlueprintsRowLabelsCellProps) => {
  const { applyFilter, applyLabelFilter } =
    useApplyFilter<BlueprintSuggestions>(APPLY_FILTERS_SUGGESTIONS);

  const trackAnalytics = useBlueprintsAnalytics();

  const handleApplyLabelFilter = useCallback(
    (value: string) => {
      trackAnalytics("Label clicked", { type: BlueprintSuggestions.Label });
      applyLabelFilter(BlueprintSuggestions.Label)(value);
    },
    [trackAnalytics, applyLabelFilter]
  );

  const handleApplyFolderFilter = useCallback(
    (value: string) => {
      trackAnalytics("Label clicked", { type: BlueprintSuggestions.Folder });
      applyFilter(BlueprintSuggestions.Folder)(value);
    },
    [trackAnalytics, applyFilter]
  );
  return (
    <TagsListFilterableNew
      tags={blueprint.labels}
      applyLabelFilter={handleApplyLabelFilter}
      applyFolderFilter={handleApplyFolderFilter}
    />
  );
};

export default BlueprintsRowLabelsCell;
