import { isAfter, isBefore } from "date-fns/esm";

import { getMaxDate, getMinDate } from "./utils";

export const validateDateRange = (start: Date, end: Date): string | undefined => {
  const maxDate = getMaxDate();
  const minDate = getMinDate();

  if (isBefore(start, minDate)) {
    return "Start date cannot be more than 1 year ago";
  }

  if (isAfter(end, maxDate)) {
    return "End date cannot be in the future";
  }

  if (!isAfter(end, start)) {
    return "End date must be after start date";
  }

  return undefined;
};
