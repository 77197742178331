import type { AriaToastProps } from "@react-aria/toast";
import { useToast } from "@react-aria/toast";
import type { ToastState } from "@react-stately/toast";
import { useRef } from "react";

import Button from "ds/components/Button";
import ToastNew from "ds/components/ToastNew";
import ToastActions from "ds/components/ToastNew/Actions";
import ToastDescription from "ds/components/ToastNew/Description";
import ToastTitle from "ds/components/ToastNew/Title";
import { ignoreOutsideClick } from "hooks/useOutsideClick";
import { FlashEvent } from "types/FlashMessage";

import styles from "./styles.module.css";
import { ToastTimer } from "./types";

type FlashMessageProps<T> = AriaToastProps<T> & {
  state: ToastState<T>;
};

const FlashMessage = (props: FlashMessageProps<FlashEvent>) => {
  const { title, message, variant, actionTitle, actionCallback } = props.toast.content;

  const action =
    (actionTitle &&
      actionCallback && {
        actionTitle,
        callback: actionCallback,
      }) ||
    undefined;

  const timer = props.toast.timer as ToastTimer | undefined;
  const isPaused = timer?.timerId === null;

  const ref = useRef(null);
  const { toastProps, titleProps, descriptionProps } = useToast(props, props.state, ref);

  const dismiss = () => props.state.close(props.toast.key);

  const handleAction = () => {
    if (action && action.callback) {
      action.callback();
    }

    dismiss();
  };

  return (
    <div
      ref={ref}
      className={styles.flashMessage}
      data-animation={props.toast.animation}
      onAnimationEnd={() => {
        // Remove the toast when the exiting animation completes.
        if (props.toast.animation === "exiting") {
          props.state.remove(props.toast.key);
        }
      }}
      {...toastProps}
      {...ignoreOutsideClick}
    >
      <ToastNew
        variant={variant}
        timeout={props.toast.timeout ? props.toast.timeout + 50 : undefined}
        isPaused={isPaused}
        onClose={dismiss}
      >
        <ToastTitle ariaProps={titleProps}>{title}</ToastTitle>
        <ToastDescription ariaProps={descriptionProps}>{message}</ToastDescription>
        {action && (
          <ToastActions>
            <Button onPress={handleAction} variant="contrast" size="small">
              {action.actionTitle}
            </Button>
          </ToastActions>
        )}
      </ToastNew>
    </div>
  );
};

export default FlashMessage;
