import { FunctionComponent, useMemo } from "react";

import Box from "ds/components/Box";
import { AwsIntegration, AzureIntegration } from "types/generated";
import Tile from "ds/components/Tile";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import IconTile from "ds/components/IconTile";
import TileTitle from "ds/components/Tile/Title";
import TileCheckboxGrid from "components/Forms/SourceCode/TileCheckboxGrid";
import MissingDataBanner from "components/MissingDataBanner";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";

import AWSIntegrationForm from "./AWSIntegration";
import AzureIntegrationForm from "./AzureIntegration";
import GCPIntegration from "./GCPIntegration";
import { AttachCloudEntityType } from "./types";

type AttachCloudFormProps = {
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: CLOUD_INTEGRATIONS;
  }>;
  attachableAzureIntegrations?: AzureIntegration[];
  attachableAwsIntegrations?: AwsIntegration[];
  cloud: CLOUD_INTEGRATIONS;
  setCloud: (value: CLOUD_INTEGRATIONS) => void;
  hasData: boolean;
  refetch: () => void;
  refetching: boolean;
  entityId: string;
  entityType: AttachCloudEntityType;
  onSuccess?: () => void;
  refetchQueries?: string[];
  canAttachGcpIntegration?: boolean;
};

const AttachCloudForm = ({
  cloudConfig,
  attachableAzureIntegrations,
  attachableAwsIntegrations,
  cloud,
  setCloud,
  hasData,
  refetch,
  refetching,
  entityId,
  entityType,
  onSuccess,
  refetchQueries,
  canAttachGcpIntegration,
}: AttachCloudFormProps) => {
  const handleSubmit = () => {
    onSuccess?.();
  };

  const filteredCloudConfig = useMemo(() => {
    if (!canAttachGcpIntegration) {
      return cloudConfig.filter(({ type }) => type !== CLOUD_INTEGRATIONS.GCP);
    }

    return cloudConfig;
  }, [canAttachGcpIntegration, cloudConfig]);

  return (
    <>
      {!hasData && (
        <Box margin="large 0 0 0" direction="column">
          <MissingDataBanner
            text="Couldn't load cloud integrations. Please try to refresh or come back later."
            refreshHandler={refetch}
            refreshLoading={refetching}
          />
        </Box>
      )}
      <Box direction="column" gap="x-large" margin="x-large 0 large 0">
        <TileCheckboxGrid>
          {filteredCloudConfig.map(({ name, logo, type }) =>
            filteredCloudConfig.length === 1 ? (
              <TileWrapper key={type} selected={cloud === type} onClick={() => setCloud(type)}>
                <TileContent direction="row" align="center">
                  <IconTile icon={logo} variant={cloud === type ? "primary" : "secondary"} />
                  <TileTitle>{name}</TileTitle>
                </TileContent>
              </TileWrapper>
            ) : (
              <Tile
                key={type}
                selected={cloud === type}
                title={name}
                icon={logo}
                onClick={() => setCloud(type)}
                indicator="radio"
              />
            )
          )}
        </TileCheckboxGrid>
      </Box>

      {cloud === CLOUD_INTEGRATIONS.AWS && (
        <AWSIntegrationForm
          attachableIntegrations={attachableAwsIntegrations}
          entityId={entityId}
          onSuccess={handleSubmit}
          refetchQueries={refetchQueries}
          entityType={entityType}
        />
      )}

      {cloud === CLOUD_INTEGRATIONS.Azure && (
        <AzureIntegrationForm
          attachableIntegrations={attachableAzureIntegrations}
          entityId={entityId}
          onSuccess={handleSubmit}
          refetchQueries={refetchQueries}
        />
      )}

      {cloud === CLOUD_INTEGRATIONS.GCP && canAttachGcpIntegration && (
        <GCPIntegration
          entityId={entityId}
          onSuccess={handleSubmit}
          refetchQueries={refetchQueries}
        />
      )}
    </>
  );
};

export default AttachCloudForm;
