import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import CollapsibleSectionPanel from "components/CollapsibleSectionPanel";
import NotFoundPage from "components/error/NotFoundPage";
import { EmptystateHelmetColored, EmptystateMagnetColored } from "components/icons/generated";
import PageLoading from "components/loading/PageLoading";
import PageInfo from "components/PageWrapper/Info";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import useGetAttachedPolicies from "shared/Policy/useGetAttachedPolicies";

import StackHeader from "../components/Header";
import { StackContext } from "../Context";
import { getStacksBackUrl } from "../helpers";
import { showPoliciesAttachDrawer } from "./AttachDrawer";
import { EMPTY_STATE_CAPTION } from "./constants";
import StackPoliciesListItem from "./ListItem";

const StackPolicies = () => {
  const { stack, canManageStackAndRuns } = useTypedContext(StackContext);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StacksPolicies,
  });

  useTitle(`Policies · ${stack.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  const {
    manuallyAttachedPolicies,
    autoAttachedPolicies,
    allAttachedPoliciesSet,
    isPageLoading,
    isPageNotFound,
    error,
  } = useGetAttachedPolicies({
    entityId: stack.id,
    entityType: "stack",
  });

  const handleAttachAction = () => {
    showPoliciesAttachDrawer({
      entityId: stack.id,
      spaceId: stack.spaceDetails.id,
      alreadyAttachedPolicies: allAttachedPoliciesSet,
    });

    trackSegmentEvent("Attach Policy Clicked");
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <>
      <StackHeader />
      <PageInfo title="Policies">
        {canManageStackAndRuns && (
          <Button variant="primary" onPress={handleAttachAction}>
            Attach policy
          </Button>
        )}
      </PageInfo>

      <Box direction="column" gap="x-large">
        <CollapsibleSectionPanel
          ariaLevel={3}
          title="Manually attached"
          count={manuallyAttachedPolicies.length}
          initialIsCollapsed={false}
        >
          {manuallyAttachedPolicies.map((policy) => (
            <StackPoliciesListItem key={policy.id} policy={policy} />
          ))}

          {manuallyAttachedPolicies.length === 0 && (
            <EmptyState
              padding="large"
              icon={EmptystateHelmetColored}
              title="No policies attached yet."
              caption={EMPTY_STATE_CAPTION}
            />
          )}
        </CollapsibleSectionPanel>

        <CollapsibleSectionPanel
          ariaLevel={3}
          title="Auto-attached"
          count={autoAttachedPolicies.length}
          initialIsCollapsed={false}
        >
          {autoAttachedPolicies.map((policy) => (
            <StackPoliciesListItem key={policy.id} policy={policy} stackLabels={stack.labels} />
          ))}

          {autoAttachedPolicies.length === 0 && (
            <EmptyState
              padding="large"
              icon={EmptystateMagnetColored}
              title="No policies auto-attached yet."
              caption={EMPTY_STATE_CAPTION}
            />
          )}
        </CollapsibleSectionPanel>
      </Box>
    </>
  );
};

export default StackPolicies;
