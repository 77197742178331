import Typography from "ds/components/Typography";

const IacManagementEmptyCellValue = () => {
  return (
    <Typography variant="p-body3" tag="span">
      -
    </Typography>
  );
};

export default IacManagementEmptyCellValue;
