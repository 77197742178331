import { memo, RefObject, useCallback, useMemo, useRef } from "react";
import { defaultRangeExtractor, Range, useVirtualizer, VirtualItem } from "@tanstack/react-virtual";
import { useShallow } from "zustand/react/shallow";

import IacManagementTableRowResource from "../Row/Resource";
import IacManagementTableRowGroup from "../Row/Group";
import IacManagementTableRowService from "../Row/Service";
import { calculateStickyRanges, calculateVirtualPadding } from "./helpers";
import { RenderGroupRowContent, RenderResourceRowContent } from "../types";
import useIacTableStore from "../useIacTableStore";
import { selectTableRows } from "../useIacTableStore/tableDataSelectors";
import { selectStickyRanges } from "../useIacTableStore/tableMetaSelectors";

type IacManagementTableBodyProps = {
  tableWrapperRef: RefObject<HTMLDivElement>;
  renderGroupRow: RenderGroupRowContent;
  renderResourceRow: RenderResourceRowContent;
  rowsPerPage: number;
};

const IacManagementTableBody = ({
  tableWrapperRef,
  renderGroupRow,
  renderResourceRow,
  rowsPerPage,
}: IacManagementTableBodyProps) => {
  const stickyGroupsVisibleOnTableRef = useRef<Map<number, number>>(new Map());
  const stickyGroupOutsideVirtualRangeRef = useRef<number | undefined>(undefined);

  const { tableRows, stickyRanges, groupsMeta } = useIacTableStore(
    useShallow((state) => ({
      tableRows: selectTableRows(state),
      stickyRanges: selectStickyRanges(state),
      groupsMeta: state.groupsMeta,
    }))
  );

  const stickyRangesIndexes = useMemo(
    () =>
      stickyRanges.map<[number, number]>(([groupId, lastChildId]) => {
        const groupHasMore = groupsMeta[groupId]?.hasMore;
        const groupIndex = tableRows.findIndex((row) => row.id === groupId);
        const lastChildIndex = tableRows.findIndex((row) => row.id === lastChildId);

        // Include "Load More" button if group has more items
        return [groupIndex, groupHasMore ? lastChildIndex + 1 : lastChildIndex];
      }),
    [groupsMeta, stickyRanges, tableRows]
  );

  // render the sticky rows even if they are outside the virtual range
  const rangeExtractor = useCallback(
    (range: Range) => {
      const defaultRange = defaultRangeExtractor(range);
      const visibleRange: [number, number] = [range.startIndex, range.endIndex];

      const { stickyGroups, stickyGroupOutsideVirtualRange, newRange } = calculateStickyRanges(
        visibleRange,
        defaultRange,
        stickyRangesIndexes
      );

      stickyGroupsVisibleOnTableRef.current = stickyGroups;
      stickyGroupOutsideVirtualRangeRef.current = stickyGroupOutsideVirtualRange;

      return newRange;
    },
    [stickyRangesIndexes]
  );

  const virtualizer = useVirtualizer({
    count: tableRows.length,
    getScrollElement: () => tableWrapperRef.current,
    estimateSize: () => 56,
    overscan: 30,
    rangeExtractor,
  });

  const virtualizedItems = virtualizer.getVirtualItems();

  // calculate before & after padding to keep the right scroll position
  const [before, after] = useMemo(() => {
    if (!tableRows.length || !virtualizedItems.length) {
      return [0, 0];
    }

    return calculateVirtualPadding(
      virtualizedItems,
      virtualizer.getTotalSize(),
      virtualizer.options.scrollMargin,
      stickyGroupsVisibleOnTableRef.current.size
    );
  }, [virtualizedItems, tableRows, virtualizer]);

  const renderRow = useCallback(
    (virtualRow: VirtualItem) => {
      const row = tableRows[virtualRow.index];

      // Group Row
      if ("count" in row) {
        // the key points to the first group index
        // the stickyGroup value points to the last group child index
        const stickyGroup = stickyGroupsVisibleOnTableRef.current.get(virtualRow.index);
        const nextGroupAfterSticky = stickyGroup
          ? virtualizer.measurementsCache[stickyGroup + 1]
          : undefined;
        // the value to detect sticky overlapping starts
        const scrollTopTarget = nextGroupAfterSticky
          ? nextGroupAfterSticky.start - virtualizer.measurementsCache[virtualRow.index]?.size + 1
          : undefined;

        return (
          <IacManagementTableRowGroup
            key={virtualRow.key}
            rowIndex={virtualRow.index}
            measureRef={virtualizer.measureElement}
            isActiveSticky={!!stickyGroup}
            nextAfterSticky={scrollTopTarget}
            tableRef={tableWrapperRef}
            data-index={virtualRow.index}
            renderContent={renderGroupRow}
            rowsPerPage={rowsPerPage}
          />
        );
      }

      // Service Row (e.g load more button)
      if ("serviceType" in row) {
        return (
          <IacManagementTableRowService
            key={virtualRow.key}
            rowIndex={virtualRow.index}
            measureRef={virtualizer.measureElement}
            rowsPerPage={rowsPerPage}
          />
        );
      }

      // Resource Row
      return (
        <IacManagementTableRowResource
          key={virtualRow.key}
          rowIndex={virtualRow.index}
          measureRef={virtualizer.measureElement}
          renderContent={renderResourceRow}
        />
      );
    },
    [
      tableRows,
      virtualizer.measureElement,
      virtualizer.measurementsCache,
      renderResourceRow,
      tableWrapperRef,
      renderGroupRow,
      rowsPerPage,
    ]
  );

  return (
    <div style={{ display: "table-row-group" }} role="rowgroup">
      {before > 0 && <div style={{ display: "table-row", height: before }}></div>}
      {virtualizedItems.map(renderRow)}
      {after > 0 && <div style={{ display: "table-row", height: after }}></div>}
    </div>
  );
};

export default memo(IacManagementTableBody);
