import { ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import PageInfo from "components/PageWrapper/Info";
import ViewCustomizationContextProvider from "components/ViewCustomization/Context";
import SegmentedControl from "ds/components/SegmentedControl";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageResources } from "hooks/useAnalytics/pages/resources";
import Typography from "ds/components/Typography";
import Select from "ds/components/Select";

import {
  ANSIBLE_GROUP_BY_OPTIONS,
  ANSIBLE_INITIAL_ITEMS_SETTINGS,
  ANSIBLE_LIST_VIEW_ITEMS_SETTINGS_KEY,
  FILTERS_ORDER_SETTINGS_KEY,
  LAYOUT_MODE_OPTIONS,
} from "./constants";
import styles from "./styles.module.css";
import { LayoutMode } from "./types";
import { AnsibleGroupBy } from "./types";

type ConfigManagementPageLayoutProps = {
  children: ReactNode;
  setLayoutMode: (newValue: LayoutMode) => void;
  layoutMode: LayoutMode;
  hideSearch?: boolean;
  loading?: boolean;
  groupBy: AnsibleGroupBy;
  setGroupBy: (groupBy: AnsibleGroupBy) => void;
};

const ConfigManagementPageLayout = ({
  children,
  layoutMode,
  setLayoutMode,
  hideSearch,
  loading,
  groupBy,
  setGroupBy,
}: ConfigManagementPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageResources.ConfigurationManagement,
  });

  useTitle(`Configuration Management · ${accountName}`);

  return (
    <ViewCustomizationContextProvider
      localStorageKey={ANSIBLE_LIST_VIEW_ITEMS_SETTINGS_KEY}
      initialItems={ANSIBLE_INITIAL_ITEMS_SETTINGS}
    >
      <PageInfo title="Configuration Management">
        {!hideSearch && (
          <>
            <SearchInput
              disabled={loading}
              placeholder="Search by host or stack name"
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />
            <Select
              value={groupBy}
              options={ANSIBLE_GROUP_BY_OPTIONS}
              onChange={(value) => {
                trackSegmentAnalyticsEvent?.("Group by changed", { groupBy: value });
                setGroupBy(value);
              }}
              renderValueNode={(option) => (
                <Typography variant="p-t7" tag="span">
                  Grouped by: {option.label}
                </Typography>
              )}
            />
            <SegmentedControl
              optionClassName={styles.layoutSwitchOption}
              onChange={(value) => {
                trackSegmentAnalyticsEvent?.("View Changed", { type: value });
                setLayoutMode(value);
              }}
              value={layoutMode}
              options={LAYOUT_MODE_OPTIONS}
            />
          </>
        )}
      </PageInfo>

      {children}
    </ViewCustomizationContextProvider>
  );
};

export default ConfigManagementPageLayout;
