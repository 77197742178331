import { useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import Button from "ds/components/Button";

const AttachCloudFormSubmitButton = () => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Box justify="end">
      <Button type="submit" variant="contrast" loading={isSubmitting} disabled={isSubmitting}>
        Attach
      </Button>
    </Box>
  );
};

export default AttachCloudFormSubmitButton;
