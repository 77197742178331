import dedent from "dedent";
import partition from "lodash-es/partition";

import { ModuleInput } from "types/generated";

const renderInput = (item: ModuleInput, longestName: number, optional?: boolean) => {
  const indentation = 2;
  const nameLength = item.name.length;
  const paddingCount = longestName - nameLength;
  const padding = ` `.repeat(paddingCount);

  const commentPrefix = "# ";
  const indentPrefix = " ".repeat(indentation);
  const commentedLinePrefix = indentPrefix + commentPrefix;

  const itemType = item.type
    .split("\n")
    .map((line, index) => {
      if (index === 0) return line;

      // trim leading spaces from original indentation in tf file
      if (line.startsWith("  ")) line = line.slice(2);
      return `${commentedLinePrefix}${line}`;
    })
    .join("\n");

  if (optional) return `${indentPrefix}${commentPrefix}${item.name}${padding} = ${itemType}`;

  return `${indentPrefix}${item.name}${padding} = # ${itemType}`;
};

const renderInputs = (inputs: ModuleInput[], optional?: boolean) => {
  if (!inputs) return "";

  const longest = inputs.reduce(function (a, b) {
    return a.name.length > b.name.length ? a : b;
  }).name;

  return `${inputs.map((item) => renderInput(item, longest.length, optional)).join("\n")}`;
};

export const generateModuleExample = (
  moduleName: string,
  sourceUrl: string,
  versionNumber: string,
  inputs: ModuleInput[] | undefined
) => {
  const [requiredInputs, optionalInputs] = partition(inputs, "required");

  const renderRequiredInputs = () => {
    if (requiredInputs.length === 0) {
      return "";
    }

    return `${`\n`}${`\n`}  # Required inputs${`\n`}` + renderInputs(requiredInputs);
  };

  const renderOptionalInputs = () => {
    if (optionalInputs.length === 0) {
      return "";
    }

    return `${`\n`}${`\n`}  # Optional inputs${`\n`}` + renderInputs(optionalInputs, true);
  };

  return (
    dedent`
    module "${moduleName}" {
      source  = "${sourceUrl}"
      version = "${versionNumber}"
    ` +
    renderRequiredInputs() +
    renderOptionalInputs() +
    `${`\n`}}`
  );
};
