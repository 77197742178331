import Box from "ds/components/Box";
import Button from "ds/components/Button";

type StackSettingsFormFooterProps = {
  onSubmit: () => void;
  onCancel: () => void;
  isDirty: boolean;
  isSubmitDisabled: boolean;
  submitLoading: boolean;
};

const StackSettingsFormFooter = ({
  onSubmit,
  onCancel,
  isDirty,
  isSubmitDisabled,
  submitLoading,
}: StackSettingsFormFooterProps) => {
  return (
    <Box justify="end" gap="large">
      {isDirty && (
        <Button variant="secondary" onPress={onCancel}>
          Cancel
        </Button>
      )}

      <Button
        variant="primary"
        onPress={onSubmit}
        loading={submitLoading}
        disabled={isSubmitDisabled}
      >
        Save
      </Button>
    </Box>
  );
};

export default StackSettingsFormFooter;
