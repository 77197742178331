import { Cross, Tick } from "components/icons/generated";
import Icon from "ds/components/Icon";

type AttachCloudDetailsSwitchIconProps = {
  checked: boolean;
};

const AttachCloudDetailsSwitchIcon = ({ checked }: AttachCloudDetailsSwitchIconProps) =>
  checked ? (
    <Icon src={Tick} size="large" color="success" />
  ) : (
    <Icon src={Cross} size="large" color="danger" />
  );

export default AttachCloudDetailsSwitchIcon;
