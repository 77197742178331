import { StackConfigVendorInput } from "types/generated";

import { VENDOR_KEY } from "./vendor";

export const SEARCH_STACK_SUGGESTIONS_DICTIONARY: Partial<Record<StackSuggestions, string>> = {
  label: "labels",
  starred: "favorite",
  createdAt: "created at",
  stateSetAt: "updated at",
  slug: "ID",
  projectRoot: "project root",
  workerPool: "worker pool",
  needsApproval: "needs approval",
  integration: "Source code integration",
  tag: "git tag",
};

export enum StackSuggestions {
  Label = "label",
  Folder = "folder",
  Commit = "commit",
  Starred = "starred",
  CreatedAt = "createdAt",
  StateSetAt = "stateSetAt",
  Slug = "slug",
  ProjectRoot = "projectRoot",
  WorkerPool = "workerPool",
  NeedsApproval = "needsApproval",
  Administrative = "administrative",
  Branch = "branch",
  Repository = "repository",
  GitTag = "tag",
  State = "state",
  Space = "space",
  Integration = "integration",
}

export const EMPTY_STACK_CONFIG_VENDOR_INPUT: StackConfigVendorInput = {
  [VENDOR_KEY.ANSIBLE]: null,
  [VENDOR_KEY.CLOUD_FORMATION]: null,
  [VENDOR_KEY.KUBERNETES]: null,
  [VENDOR_KEY.PULUMI]: null,
  [VENDOR_KEY.TERRAFORM]: null,
  [VENDOR_KEY.TERRAGRUNT]: null,
};
