import Box from "ds/components/Box";
import ListEntitiesItemDescriptionMultipleLines from "components/ListEntitiesItem/Description/MultipleLines";

import useBlueprintsAnalytics from "../../hooksNew/useBlueprintsAnalytics";
import { CellComponentProps } from "./types";

type BlueprintsRowDescriptionCellProps = CellComponentProps;

const BlueprintsRowDescriptionCell = ({ blueprint }: BlueprintsRowDescriptionCellProps) => {
  const trackAnalytics = useBlueprintsAnalytics();

  const handleShowFullDescription = () => {
    trackAnalytics("Show full description clicked");
  };

  if (!blueprint.description) {
    return null;
  }

  return (
    <Box direction="row" align="start">
      <ListEntitiesItemDescriptionMultipleLines
        lines={1}
        hasChevronLink
        description={blueprint.description}
        onShowFullDescription={handleShowFullDescription}
      />
    </Box>
  );
};

export default BlueprintsRowDescriptionCell;
