import { useMemo } from "react";

import useTitle from "hooks/useTitle";
import { ConfigType, Module, Stack } from "types/generated";
import { filterOutputReferences } from "utils/config";

import { configArray } from "../configArray";
import EditConfigMap from "./ConfigMap";
import NewConfig from "./NewConfig";
import { EnvironmentType } from "../types";
import EnvironmentCollapsibleSection from "../CollapsibleSection";

type EnvironmentEditModeProps = {
  stack: Module | Stack;
  type: EnvironmentType;
};

const EnvironmentEditMode = ({ stack, type }: EnvironmentEditModeProps) => {
  useTitle(`Editing environment · ${stack.name}`);

  const [filteredRuntimeConfigElements, outputReferenceElementsMap] = filterOutputReferences(
    stack.runtimeConfig
  );
  const [config, spaceliftConfig] = configArray(filteredRuntimeConfigElements);

  const { existingEnvironmentVariableNames, existingMountedFilesNames } = useMemo(
    () =>
      [...config, ...spaceliftConfig].reduce<{
        existingEnvironmentVariableNames: string[];
        existingMountedFilesNames: string[];
      }>(
        (acc, configElement) => {
          if (configElement.element.type === ConfigType.EnvironmentVariable) {
            acc.existingEnvironmentVariableNames.push(configElement.element.id);
          }
          if (configElement.element.type === ConfigType.FileMount) {
            acc.existingMountedFilesNames.push(configElement.element.id);
          }
          return acc;
        },
        { existingEnvironmentVariableNames: [], existingMountedFilesNames: [] }
      ),
    [config, spaceliftConfig]
  );

  return (
    <>
      <NewConfig
        stackId={stack.id}
        type={type}
        existingEnvironmentVariableNames={existingEnvironmentVariableNames}
        existingMountedFilesNames={existingMountedFilesNames}
      />
      <div className="environment-edit__config">
        <EditConfigMap config={config} stackId={stack.id} type={type} />

        <h4 className="environment-runtime__view-sp-env-title">Spacelift environment</h4>
        <EditConfigMap config={spaceliftConfig} stackId={stack.id} type={type} />

        {outputReferenceElementsMap.size > 0 && (
          <>
            <h4 className="environment-runtime__view-sp-env-title">Output references</h4>

            {[...outputReferenceElementsMap].map(([key, config]) => (
              <EnvironmentCollapsibleSection
                key={key}
                config={config}
                sectionName={key}
                editMode
                stackId={stack.id}
                type={type}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default EnvironmentEditMode;
