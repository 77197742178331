import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { showUnassignConfirmation } from "../Roles/UnassignConfirmation";

type AssignRoleDrawerDrawerDeleteActionProps = {
  id: string;
  name: string;
};

const AssignRoleDrawerDrawerDeleteAction = ({
  name,
  id,
}: AssignRoleDrawerDrawerDeleteActionProps) => {
  const onModalShow = () => {
    showUnassignConfirmation({ name, id });
  };

  return (
    <DropdownMenuItem onAction={onModalShow} danger>
      Delete role
    </DropdownMenuItem>
  );
};

export default AssignRoleDrawerDrawerDeleteAction;
