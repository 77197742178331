import { ArrowRight } from "components/icons/generated";
import Button from "ds/components/Button";

type VCSIntegrationsContinueButtonProps = {
  onClick: () => void;
  loading?: boolean;
};

const VCSIntegrationsContinueButton = ({
  onClick,
  loading,
}: VCSIntegrationsContinueButtonProps) => {
  return (
    <Button variant="primary" endIcon={ArrowRight} onPress={onClick} loading={loading}>
      Continue
    </Button>
  );
};

export default VCSIntegrationsContinueButton;
