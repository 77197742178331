import { useMemo } from "react";

import { RolesActionItemForBulkActions } from "./types";
import useDeleteRolesBulkAction from "./useDeleteRolesBulkAction";

const useRolesBulkActions = (): RolesActionItemForBulkActions[] => {
  const confirmStackRunAction = useDeleteRolesBulkAction();

  return useMemo(() => [confirmStackRunAction], [confirmStackRunAction]);
};

export default useRolesBulkActions;
