import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";

import { showDismissAllNotificationsConfirmation } from "./DismissAllConfirmation";
import { DISMISS_ALL_NOTIFICATIONS } from "./gql";

const DismissAllNotificationsButton = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  // TODO: implement dismissing only filtered notifications not all available even if they are not on the list
  const [dismissAllNotifications, { loading }] = useMutation(DISMISS_ALL_NOTIFICATIONS, {
    refetchQueries: ["SearchNotifications"],
    awaitRefetchQueries: true,
    onError,
    onCompleted: () => {
      reportSuccess({
        message: `All notifications have been dismissed`,
      });
    },
  });

  const onModalShow = () => {
    showDismissAllNotificationsConfirmation({
      onConfirm: dismissAllNotifications,
    });
  };

  return (
    <Button
      variant="secondary"
      onPress={onModalShow}
      loading={loading}
      disabled={loading}
      size="small"
    >
      Dismiss all notifications
    </Button>
  );
};

export default DismissAllNotificationsButton;
